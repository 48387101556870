import React, { useEffect, useCallback } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import ReactHookFormInput from '../form-components/ReactHookFormInput';
import { Box, Button, Checkbox, FormControlLabel, Grid, debounce } from '@mui/material';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactHookFormSearchableSelect from '../form-components/ReactHookFormSearchableSelect';
import { paymentType, paymentTypeWithBoth, legacyJurisdictions, getMilkType, getBasis, getCategory, AssignmentCategories, getDumpedMilkRate, fixedBasis, percentBasis, dumpedMilkBasis, milkAmountBasis, grossAndNetPayBasis } from '../form-components/AssigneeSelects';
import { state } from '../form-components/ReactHookProducerFormSelects';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import { addAssignment, editAssignment, showMessage, addRecurringAssignment, editRecurringAssignment } from '../../store/actions';
import Region from 'app/constants/region';
import { generateTestId, getUserRegion } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    dropdown: {
        marginTop: '-8px',
    },
    date: {
        marginTop: '8px', // formatting for Prairie only
    },
}));

const AssignmentForm = ({ assignees, editData, producers }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const region = getUserRegion();
    const classes = useStyles();

    const reactHookFormMethods = useForm({
        mode: 'all',
        defaultValues: {
            assignee: _.find(assignees, (z) => z.value === editData?.assignee_id),
            date: editData?.date ? moment(editData?.date, 'YYYY-MM') : undefined,
            description: editData?.description || undefined,
            recurring_start_date: editData?.recurring_start_date ? moment(editData?.recurring_start_date) : undefined,
            recurring_end_date: editData?.recurring_end_date ? moment(editData?.recurring_end_date) : undefined,
            category: _.find(getCategory(region), (z) => z.value === editData?.category),
            payment_type: _.find(paymentType, (z) => z.value === editData?.payment_type),
            payment_rate: editData?.payment_rate || undefined,
            monetary_value: editData?.monetary_value || undefined,
            milk_amount: editData?.milk_amount || undefined,
            type_of_charge: _.find(getBasis(region), (z) => z.value === editData?.type_of_charge),
            jurisdiction: _.find(legacyJurisdictions, (z) => z.value === editData?.jurisdiction),
            milk_type: _.find(getMilkType(region), (z) => z.value === editData?.milk_type), // prairie and legacy milk types
            producer: _.find(producers, (z) => z.value === editData?.producer_id),
            recurring_assignment_id: editData?.recurring_assignment_id || undefined,
            state: _.find(state, (z) => z.value === editData?.state),
            recurringAssignment: !!editData?.recurring_start_date,
            updateRecurringAssignment: false,
            account: editData?.account || undefined,
            reference: editData?.reference || undefined,
        },
    });

    const { handleSubmit, watch, setValue, errors, control, getValues } = reactHookFormMethods;

    const basisWatch = watch('type_of_charge');
    const producerWatch = watch('producer');
    const producersWatch = watch('producers');
    const jurisdictionWatch = watch('jurisdiction');
    const stateWatch = watch('state');
    const recurringAssignmentWatch = watch('recurringAssignment');
    const dateWatch = watch('date');

    useEffect(() => {
        if (fixedBasis.has(basisWatch?.value)) {
            setValue('payment_rate', '');
        } else {
            setValue('monetary_value', '');
        }
        if (!milkAmountBasis.has(basisWatch?.value)) {
            setValue('milk_type', undefined);
        }
        if (grossAndNetPayBasis.has(basisWatch?.value)) {
            setValue(
                'category',
                _.find(getCategory(region), (z) => z.value === AssignmentCategories.Assignment)
            );
        }
        if (dumpedMilkBasis.has(basisWatch?.value)) {
            setValue(
                'category',
                _.find(getCategory(region), (z) => z.value === AssignmentCategories.Premium)
            );
        } else {
            setValue('milk_amount', undefined);
        }
    }, [basisWatch]);

    useEffect(() => {
        if (producerWatch?.value || producersWatch?.[0]) {
            setValue('jurisdiction', '');
        }
        if (jurisdictionWatch?.value) {
            setValue('producers', '');
        }
        if (stateWatch?.value) {
            setValue('producers', '');
        }
    }, [producerWatch, jurisdictionWatch, producersWatch, stateWatch]);

    useEffect(() => {
        getDumpedMilkRate(getValues, setValue, editData, region);
    }, [producerWatch, producersWatch, basisWatch, dateWatch]);

    const handleRecurringAssignmentChange = (event) => {
        setValue('recurringAssignment', event.target.checked);
        if (event.target.checked) {
            setValue('date', null);
        } else {
            setValue('recurring_start_date', null);
            setValue('recurring_end_date', null);
        }
    };

    const transformProducerLabel = (option) => {
        if (option.license_number && option.name) {
            return `${option.license_number} - ${option.name}`;
        }
        return option.name;
    };

    const transformAssigneeLabel = (option) => {
        return [option?.assignee_number, option?.name, option?.classification_code].filter((part) => part != null && part !== '').join(' - ');
    };

    const post = (submitModel) => {
        const recurringAssignment = Boolean(submitModel.recurringAssignment);
        const model = submitModel;
        delete model.recurringAssignment;
        delete model.updateRecurringAssignment;
        dispatch(recurringAssignment ? addRecurringAssignment(model) : addAssignment(model))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Added Assignment' }));
                history.replace({ pathname: '/assignments' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not add Assignment' }));
            });
    };

    const put = (submitModel) => {
        const updateRecurringAssignment = Boolean(submitModel.updateRecurringAssignment);
        const { _id } = editData;
        const model = submitModel;
        delete model.recurringAssignment;
        delete model.updateRecurringAssignment;
        dispatch(updateRecurringAssignment ? editRecurringAssignment(model, _id) : editAssignment(model, _id))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Edited Assignment' }));
                history.replace({ pathname: '/assignments' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not edit Assignment' }));
            });
    };

    const onSubmit = (model) => {
        let submitModel = { ...model };
        // This function applies to the searchable select components and passes the value of the selection to the model
        Object.keys(submitModel).forEach((key) => {
            if (submitModel[key]?.value) {
                submitModel[key] = submitModel[key].value;
            }
        });

        if (!editData && !_.isEmpty(submitModel?.producers)) {
            submitModel.producers = submitModel.producers.map((item) => item.value);
        }
        submitModel.date = submitModel?.date ? moment(submitModel.date).format('YYYY-MM') : undefined;
        submitModel.recurring_start_date = submitModel?.recurring_start_date ? moment(submitModel.recurring_start_date).startOf('month').toDate() : undefined;
        submitModel.recurring_end_date = submitModel?.recurring_end_date ? moment(submitModel.recurring_end_date).endOf('month').toDate() : undefined;
        submitModel = _.omitBy(submitModel, (field) => _.isUndefined(field) || _.isNull(field) || field === '');

        if (editData) {
            put(submitModel);
        } else {
            post(submitModel);
        }
    };

    const debounceSubmit = useCallback(debounce(onSubmit, 500), []);
    const render = () => {
        return (
            <div>
                <FormProvider {...reactHookFormMethods}>
                    <form onSubmit={handleSubmit(debounceSubmit, errors)}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} className={classes.dropdown}>
                                <ReactHookFormSearchableSelect variant="outlined" label="Assignee" name="assignee" options={assignees.sort((a, b) => Number(a.assignee_number) - Number(b.assignee_number))} customRender={transformAssigneeLabel} required type="text" fullWidth />
                            </Grid>

                            <Grid item xs={6}>
                                <ReactHookFormInput variant="outlined" label="Description" name="description" type="text" fullWidth />
                            </Grid>

                            {[Region.PRAIRIE].includes(region) && (
                                <>
                                    {' '}
                                    <Grid item xs={12} md={6}>
                                        <ReactHookFormInput label="Account" name="account" type="text" variant="outlined" />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <ReactHookFormInput label="Reference" name="reference" type="text" variant="outlined" />
                                    </Grid>
                                </>
                            )}

                            {!editData && (
                                <Grid item xs={6} className={classes.dropdown}>
                                    <ReactHookFormSearchableSelect variant="outlined" label="Producer" defaultValue={[]} name="producers" options={producers} type="text" multiple selectAll chipLimit={1} customRender={transformProducerLabel} fullWidth disabled={!!jurisdictionWatch || !!stateWatch?.value} />
                                </Grid>
                            )}

                            {editData && (
                                <Grid item xs={12} className={classes.dropdown}>
                                    <ReactHookFormSearchableSelect variant="outlined" label={'Producer'} name={'producer'} options={producers} type={'text'} fullWidth customRender={transformProducerLabel} required={!!editData} />
                                </Grid>
                            )}

                            {[Region.LEGACY].includes(region) && !editData && (
                                <Grid item xs={6} className={classes.dropdown}>
                                    <ReactHookFormSearchableSelect variant="outlined" label={'Jurisdiction'} name={'jurisdiction'} options={legacyJurisdictions} type={'text'} fullWidth disabled={!!producerWatch || !!producersWatch?.[0] || !!stateWatch?.value} />
                                </Grid>
                            )}

                            {[Region.PRAIRIE].includes(region) && !editData && (
                                <Grid item xs={6} className={classes.dropdown}>
                                    <ReactHookFormSearchableSelect variant="outlined" label={'State'} name={'state'} options={state} type={'text'} fullWidth disabled={!!jurisdictionWatch || !!producerWatch?.value || !!producersWatch?.[0]} />
                                </Grid>
                            )}

                            <Grid item xs={4} className={classes.dropdown}>
                                <ReactHookFormSearchableSelect variant="outlined" label={'Payment Type'} name={'payment_type'} required options={!editData && recurringAssignmentWatch ? paymentTypeWithBoth : paymentType} />
                            </Grid>

                            <Grid item xs={4} className={classes.dropdown}>
                                <ReactHookFormSearchableSelect variant="outlined" label={'Basis'} name={'type_of_charge'} required options={getBasis(region)} />
                            </Grid>

                            <Grid item xs={4} className={classes.dropdown}>
                                <ReactHookFormSearchableSelect variant="outlined" name={'category'} label={'Category'} options={getCategory(region)} required disabled={percentBasis.has(basisWatch?.value)} />
                            </Grid>

                            <Grid item xs={4}>
                                {dumpedMilkBasis.has(basisWatch?.value) ? (
                                    <ReactHookFormInput variant="outlined" name="milk_amount" label="Milk Amount (lbs)" type="text" required={dumpedMilkBasis.has(basisWatch?.value)} />
                                ) : (
                                    <ReactHookFormSearchableSelect variant="outlined" label={'Milk Type'} name={'milk_type'} options={getMilkType(region)} required={milkAmountBasis.has(basisWatch?.value)} disabled={!milkAmountBasis.has(basisWatch?.value)} />
                                )}
                            </Grid>

                            <Grid item xs={4}>
                                <ReactHookFormInput variant="outlined" name="payment_rate" label={`Payment Rate${percentBasis.has(basisWatch?.value) ? ' (%)' : ''}`} type="text" required={!fixedBasis.has(basisWatch?.value)} fieldOpts={{ disabled: fixedBasis.has(basisWatch?.value) }} />
                            </Grid>

                            <Grid item xs={4}>
                                <ReactHookFormInput variant="outlined" name="monetary_value" label="Monetary Value" type="text" required={fixedBasis.has(basisWatch?.value)} fieldOpts={{ disabled: !fixedBasis.has(basisWatch?.value) }} />
                            </Grid>

                            {(!recurringAssignmentWatch || !!editData) && (
                                <Grid item xs={4} className={classes.date}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Controller
                                            name="date"
                                            control={control}
                                            defaultValue={null}
                                            render={({ field: { onChange, value } }) => {
                                                return (
                                                    <DatePicker
                                                        data-testId={generateTestId('Assignment Date', 'date-picker')}
                                                        className={classes.date}
                                                        inputVariant="outlined"
                                                        fullWidth
                                                        openTo="year"
                                                        label="Assignment Date *"
                                                        disableToolbar
                                                        views={['year', 'month']}
                                                        format="MMM YYYY"
                                                        value={value}
                                                        onChange={onChange} //
                                                    />
                                                );
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            )}

                            {recurringAssignmentWatch && !editData && (
                                <>
                                    <Grid item xs={4}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <Controller
                                                name="recurring_start_date"
                                                control={control}
                                                defaultValue={null}
                                                render={({ field: { onChange, value } }) => {
                                                    return (
                                                        <DatePicker
                                                            data-testid={generateTestId('Recurring Start Date', 'date-picker')}
                                                            className={classes.date}
                                                            inputVariant="outlined"
                                                            openTo="year"
                                                            label="Recurring Start Date *"
                                                            disableToolbar
                                                            views={['year', 'month']}
                                                            format="MMMM YYYY"
                                                            value={value}
                                                            onChange={onChange} //
                                                        />
                                                    );
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <Controller
                                                name="recurring_end_date"
                                                control={control}
                                                defaultValue={null}
                                                render={({ field: { onChange, value } }) => {
                                                    return (
                                                        <DatePicker
                                                            data-testid={generateTestId('Recurring End Date', 'date-picker')}
                                                            className={classes.date}
                                                            inputVariant="outlined"
                                                            openTo="year"
                                                            label="Recurring End Date"
                                                            disableToolbar
                                                            views={['year', 'month']}
                                                            format="MMMM YYYY"
                                                            maxDate={moment().add(5, 'years').endOf('month')}
                                                            value={value}
                                                            onChange={onChange} //
                                                        />
                                                    );
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </>
                            )}

                            <Grid item xs={4}></Grid>

                            {/* Recurring assignment logic */}
                            {!editData && (
                                <Grid item xs={12}>
                                    <Controller
                                        name="recurringAssignment"
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return <FormControlLabel control={<Checkbox color="primary" onChange={handleRecurringAssignmentChange} checked={value} />} label="Recurring Assignment?" />;
                                        }}
                                    />
                                </Grid>
                            )}

                            {editData && !!recurringAssignmentWatch && (
                                <Grid item xs={12}>
                                    <Controller
                                        name="updateRecurringAssignment"
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return <FormControlLabel control={<Checkbox color="primary" onChange={(e) => setValue('updateRecurringAssignment', e.target.checked)} checked={value} />} label="Edit future recurring assignments?" />;
                                        }}
                                    />
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <Box display="flex" fullWidth justifyContent="flex-end">
                                    <Button type="submit" variant="contained" color="primary">
                                        Save
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </div>
        );
    };
    return render();
};

export default AssignmentForm;
