import axios from 'axios';
import _ from 'lodash';
import { getRouteSessionsQuery } from './queries';
import { buildExpressQuery } from './queries/builders';

const getRouteMapping = async (sessionID) => {
    try {
        const sessionQuery = getRouteSessionsQuery({ ids: [sessionID], fields: ['route_points', 'total_time', 'total_distance'], queryBuilder: buildExpressQuery });
        const response = await axios.get(sessionQuery);

        const { data } = response;
        return { routePoints: _.sortBy(data[0].route_points, 'time'), total_time: data[0].total_time, total_distance: data[0].total_distance };
    } catch (error) {
        throw new Error(`Unable to find route points: ${error}`);
    }
};

export default getRouteMapping;
