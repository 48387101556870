import { authRoles } from 'app/auth';
import RegisterMFA from './RegisterMFA';

const MFAConfig = {
    settings: {
        layout: {
            config: {
                navbar: { display: false },
                toolbar: { display: false },
                footer: { display: false },
                leftSidePanel: { display: false },
                rightSidePanel: { display: false },
            },
        },
    },
    auth: authRoles.onlyGuest,
    routes: [{ path: '/register-mfa', component: RegisterMFA }],
};

export default [MFAConfig];
