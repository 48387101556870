import React from 'react';
import { Grid } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';
import _ from 'lodash';
import lightGreyBgColour from './MilkPriceFormStyles';

const MilkPriceFormAdvanceBasePrice = ({ overrideFieldNames }) => (
    <>
        <Grid item xs={12} sm={3} style={lightGreyBgColour}>
            <ReactHookFormInput fullWidth label="Base Price" name={overrideFieldNames?.['advance_milk_prices.base_price'] ?? 'advance_milk_prices.base_price'} type="number" />
        </Grid>
        <Grid item xs={12} sm={9} style={lightGreyBgColour}></Grid>
    </>
);

export default MilkPriceFormAdvanceBasePrice;
