import {
    Dashboard,
    ListAdmins,
    ListProducers,
    ListProcessors,
    ListTrailers,
    ListHaulingCompanies,
    ViewAccountingReports,
    ListDrivers,
    ListRouteSessions,
    ListDropoffs,
    ListPickups,
    ListLabReports,
    ListSilos,
    ListCIPWash,
    ListManifests,
    ListUserGroups,
    GetMilkScheduleDetails,
    ListSharedFiles,
    ViewDriverLocations,
    UploadDownload,
    ListReceivers,
    GetFluidScheduleDetails,
    ManageCloseDay,
    ManageDeferrals,
    ManageAssignments,
    ManageAssignees,
    ManageMilkPrices,
    ManageHaulingRates,
    ManageProducerPayReports,
    ListBaseTransfers,
    ListPickupsAndLabs,
    ListMonthlyReports,
    ListBactoAlert,
    ListSubProcessors,
    ListSubProducers,
    ListSubAdmins,
    ManageEquity,
    ListSubHauling,
    ListScheduleRoutes,
    ViewReceivingDropoffs,
    ManageDeliveryReports,
    ViewDocumentation,
    ViewMemberCenter,
    AccessPlantManagement,
    AccessHaulerManagement,
    AccessProducerManagement,
} from './permissions';
import LABELS from './constants';

const region = 'CDI';

const SubAdminManagement = {
    label: LABELS.Management,
    permissions: [
        ListAdmins,
        ListProducers,
        ListDrivers,
        ListProcessors(region),
        ListHaulingCompanies(region),
        ListTrailers,
        ListSubAdmins,
        // force wrap
    ],
};

const SubAdminGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListSilos,
        ListUserGroups,
        ViewDriverLocations,
        ListScheduleRoutes,
        ManageCloseDay,
        ListPickups(),
        ListDropoffs(),
        ListCIPWash,
        ListLabReports(),
        ManageEquity,
        UploadDownload,
        ListRouteSessions(region),
        ManageDeliveryReports,
        ListManifests,
        GetMilkScheduleDetails(),
        GetFluidScheduleDetails,
        ViewReceivingDropoffs,
        ListSharedFiles,
        ManageCloseDay,
        ViewDocumentation,
        ViewMemberCenter,
        AccessPlantManagement,
        AccessHaulerManagement,
        AccessProducerManagement,
        // force-wrap
    ],
};

const SubAdminAccounting = {
    label: LABELS.Accounting,
    permissions: [
        ViewAccountingReports,
        ManageAssignments,
        ManageAssignees,
        ManageMilkPrices,
        ManageHaulingRates,
        ManageProducerPayReports,
        ListBaseTransfers(region),
        ManageDeferrals,
        // force wrap
    ],
};

const SubProducerManagement = {
    label: LABELS.Management,
    permissions: [ListSubProducers],
};

const SubProducerGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListPickups('View', ''),
        ListPickupsAndLabs,
        ListLabReports('View', ''),
        ListBactoAlert,
        UploadDownload,
        ListSharedFiles,
        ViewMemberCenter,
        AccessProducerManagement,
        // force wrap
    ],
};

const SubProducerAccounting = {
    label: LABELS.Accounting,
    permissions: [ListMonthlyReports(region)],
};

const SubProcessorManagement = {
    label: LABELS.Management,
    permissions: [ListReceivers, ListSubProcessors(region)],
};

const SubProcessorGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListSilos,
        ListPickups('View', ''),
        ListDropoffs('View', ''),
        ListRouteSessions(region, 'View', ''),
        GetMilkScheduleDetails('Manage', '(View, Create Demand)'),
        GetFluidScheduleDetails,
        ViewReceivingDropoffs,
        ListSharedFiles,
        AccessPlantManagement,
        // force wrap
    ],
};

const SubHaulingManagement = {
    label: LABELS.Management,
    permissions: [ListDrivers, ListTrailers, ListSubHauling(region)],
};

const SubHaulingGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ViewDriverLocations,
        ListPickups(),
        ListDropoffs(),
        ListRouteSessions(region, 'View', ''),
        ListCIPWash,
        GetMilkScheduleDetails('View', ''),
        GetFluidScheduleDetails,
        ViewReceivingDropoffs,
        ListSharedFiles,
        AccessHaulerManagement,
        // force wrap
    ],
};

const SubAdmin = [SubAdminManagement, SubAdminGeneral, SubAdminAccounting];
const SubProducer = [SubProducerManagement, SubProducerGeneral, SubProducerAccounting];
const SubProcessor = [SubProcessorManagement, SubProcessorGeneral];
const SubHauling = [SubHaulingManagement, SubHaulingGeneral];

const REQUIRED_PERMISSIONS = {
    Admin: [
        ListSilos,
        ListUserGroups,
        ViewReceivingDropoffs,
        ListManifests,
        GetMilkScheduleDetails(),
        GetFluidScheduleDetails,
        ListTrailers,
        // force-wrap
    ],
};

const CDI = {
    Admin: { permissions: SubAdmin, required: REQUIRED_PERMISSIONS.Admin },
    'Sub-Admin': { permissions: SubAdmin },
    'Sub-Producer': { permissions: SubProducer },
    Accountant: { permissions: SubProducer },
    'Feed-Provider': { permissions: SubProducer },
    Nutritionist: { permissions: SubProducer },
    'Co-Owner': { permissions: SubProducer },
    'Sub-Processor': { permissions: SubProcessor },
    'Sub-Hauling': { permissions: SubHauling },
};

export default CDI;
