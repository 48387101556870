import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
    symbol: {
        display: 'block',
        margin: 'auto',
    },
});

const SymbolProcessor = () => {
    const classes = useStyles();
    return (
        <svg width="30" height="38" viewBox="0 0 30 38" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.symbol}>
            <g filter="url(#filter0_ddd_53_20515)">
                <path
                    d="M14.8881 33.4074L14.8878 33.4071C14.6616 33.1795 11.8082 30.2824 9.01525 26.4511C7.61877 24.5354 6.24582 22.3976 5.22384 20.2521C4.19873 18.1001 3.54602 15.98 3.54602 14.0892H3.54605L3.546 14.0839C3.53123 12.5641 3.81749 11.0566 4.38795 9.6497C4.95839 8.24284 5.80151 6.96498 6.86777 5.8908C7.93402 4.81662 9.20198 3.96768 10.5974 3.39344C11.9927 2.8192 13.4876 2.53114 14.9946 2.546L14.9946 2.54611L15.0054 2.546C16.5124 2.53114 18.0073 2.8192 19.4026 3.39344C20.798 3.96768 22.066 4.81662 23.1322 5.8908C24.1985 6.96498 25.0416 8.24284 25.6121 9.6497C26.1825 11.0566 26.4688 12.5641 26.454 14.0839H26.454V14.0892C26.454 15.98 25.8013 18.1001 24.7762 20.2521C23.7542 22.3976 22.3812 24.5354 20.9847 26.4511C18.1918 30.2824 15.3384 33.1795 15.1122 33.4071L15.1119 33.4074C15.0815 33.4381 15.0411 33.4545 14.9999 33.4545L15 34L15.0001 33.4545C14.9589 33.4545 14.9185 33.4381 14.8881 33.4074Z"
                    fill="#00BCD4"
                    stroke="white"
                    strokeWidth="1.09091"
                />
                <path
                    d="M19.9994 12.9995V18.9995H9.99939V12.9995L13.4994 11.4995V12.4995L15.9994 11.4995V12.9995H19.9994ZM17.5994 12.2495L17.9994 8.99951H19.4994L19.8994 12.2495H17.5994ZM14.4994 16.9995H15.4994V14.9995H14.4994V16.9995ZM12.4994 16.9995H13.4994V14.9995H12.4994V16.9995ZM17.4994 14.9995H16.4994V16.9995H17.4994V14.9995Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter id="filter0_ddd_53_20515" x="0" y="0" width="30" height="38" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_53_20515" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_53_20515" result="effect2_dropShadow_53_20515" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_dropShadow_53_20515" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow_53_20515" result="effect3_dropShadow_53_20515" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_53_20515" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default SymbolProcessor;
