import axios from 'axios';
import _ from 'lodash';
import { arrayToMap, renameKey } from '../../utils';
import { getDriversQuery, getRouteSessionsQuery, getPickupsQuery, getDropoffsQuery, getRoutesQuery, bulkQuery, getProcessorsQuery, getTrailersQuery } from './queries';
import moment from 'moment-timezone';

const getRouteSessionDetails = (id, start, end, filterType, filterLocked) =>
    new Promise((resolve, reject) => {
        const driverQuery = getDriversQuery({ fields: ['id', 'name'], haulingId: id });
        const driverRequest = axios.get(driverQuery);

        driverRequest
            .then((driverResponse) => {
                const drivers = driverResponse.data;
                const driverIds = _.uniq(_.map(drivers, 'id'));

                const routeSessionQuery = bulkQuery(
                    getRouteSessionsQuery,
                    {
                        start,
                        end,
                        driverIds,
                        filterDeleted: true,
                        filterType,
                        filterLocked,
                        hint: 'created_by_1_created_at_-1_type_of_fluid_1',
                    },
                    'driverIds'
                );
                const routeSessionRequest = axios.post(routeSessionQuery.url, routeSessionQuery.body);

                axios.all([routeSessionRequest]).then(
                    axios.spread((...response) => {
                        const routeSessions = response[0].data;
                        renameKey(routeSessions, '_id', 'id');
                        const routeSessionIds = _.uniq(_.map(routeSessions, 'id'));
                        const routeIds = _.uniq(_.map(routeSessions, 'route_id'));
                        const processorIds = _.uniq(..._.map(routeSessions, 'pre_delivery_report_processor_recipients'));
                        const trailerIds = _.uniq(_.map(routeSessions, 'trailer_id'));

                        const pickupsQuery = bulkQuery(
                            getPickupsQuery,
                            {
                                fields: ['route_session_id', 'volume'],
                                routeSessionIds,
                                filterDeleted: true,
                                hint: 'route_session_id_1_deleted_at_1_created_at_1',
                            },
                            'routeSessionIds'
                        );
                        const dropoffsQuery = bulkQuery(
                            getDropoffsQuery,
                            {
                                fields: ['route_session_id', 'metered', 'volume'],
                                routeSessionIds,
                                filterDeleted: true,
                            },
                            'routeSessionIds'
                        );
                        const routeQuery = bulkQuery(getRoutesQuery, { fields: ['id', 'name'], ids: routeIds }, 'ids');
                        const processorQuery = bulkQuery(getProcessorsQuery, { fields: ['id', 'name'], ids: processorIds });
                        const trailerQuery = bulkQuery(getTrailersQuery, { ids: trailerIds });

                        const routeRequest = axios.post(routeQuery.url, routeQuery.body);
                        const pickupsRequest = axios.post(pickupsQuery.url, pickupsQuery.body);
                        const dropoffsRequest = axios.post(dropoffsQuery.url, dropoffsQuery.body);
                        const processorRequest = axios.post(processorQuery.url, processorQuery.body);
                        const trailerRequest = axios.get(trailerQuery);

                        const requests = [routeRequest, pickupsRequest, dropoffsRequest, processorRequest, trailerRequest];

                        return axios.all(requests).then(
                            axios.spread((...responses) => {
                                const routes = responses[0].data;
                                const pickups = responses[1].data;
                                const dropoffs = responses[2].data;
                                const processors = responses?.[3]?.data;
                                const trailers = responses?.[4]?.data;

                                renameKey(routes, '_id', 'id');
                                renameKey(pickups, '_id', 'id');
                                renameKey(dropoffs, '_id', 'id');
                                renameKey(processors, '_id', 'id');

                                const pickupTotals = _.mapValues(_.groupBy(pickups, 'route_session_id'), (p) => _.sumBy(p, 'volume'));
                                const dropoffTotals = _.mapValues(_.groupBy(dropoffs, 'route_session_id'), (d) => _.sumBy(d, 'volume'));
                                const meteredTotals = _.mapValues(_.groupBy(dropoffs, 'route_session_id'), (m) => _.sumBy(m, 'metered'));
                                const dropoffMap = _.groupBy(dropoffs, 'route_session_id');
                                const processorsMap = arrayToMap(processors, 'id');
                                const trailerMap = arrayToMap(trailers, 'id');

                                const sessionData = _.map(routeSessions, (rs) => ({
                                    BOL: rs.BOL,
                                    date: rs.created_at,
                                    route_id: rs.route_id || '',
                                    driver_id: rs.created_by || '',
                                    trailer_id: rs.trailer_id || '',
                                    route_number: rs.route_number,
                                    dropoffVolume: dropoffTotals[rs.id] ? dropoffTotals[rs.id].toString() : '0',
                                    firstDropoffDate: dropoffMap[rs.id]?.[0] ? moment(dropoffMap[rs.id][0].created_at) : 'No Drop',
                                    metered: meteredTotals[rs.id] || 0,
                                    flagged: !dropoffTotals[rs.id] || !pickupTotals[rs.id] || dropoffTotals[rs.id] !== pickupTotals[rs.id],
                                    id: rs.id,
                                    name: _.find(drivers, { id: rs.created_by }) ? _.find(drivers, { id: rs.created_by }).name : '',
                                    route: _.find(routes, { id: rs.route_id }) ? _.find(routes, { id: rs.route_id }).name : '',
                                    route_session_status: _.capitalize(rs.status),
                                    volume: pickupTotals[rs.id] ? pickupTotals[rs.id] : 0,
                                    type_of_fluid: rs.type_of_fluid,
                                    pre_delivery_report_processor_recipients: processorsMap?.[rs?.pre_delivery_report_processor_recipients?.[rs?.pre_delivery_report_processor_recipients?.length - 1]]?.name,
                                    manifest_number: rs.manifest_number || '',
                                    trailer_number: trailerMap?.[rs.trailer_id]?.trailer_number || '',
                                    po_number: rs.po_number || '',
                                    kosher: rs.kosher || false,
                                }));

                                // pickups - artifact of UI and old API?
                                resolve({ sessions: sessionData });
                            })
                        );
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getRouteSessionDetails;
