import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment-timezone';

const useStyles = makeStyles({
    box: {
        display: 'flex',
        flexDirection: 'column',
        padding: '6px',
        justifyContent: 'flex-start',
        borderStyle: 'none',
        borderRadius: '5px',
        backgroundColor: '#EEEEEE',
        color: 'black',
    },
    blue: {
        backgroundColor: '#00A2F514',
    },
});

const DataItem = ({ name, data, asNew, ...rest }) => {
    const classes = useStyles();
    let formattedData = data;

    if (['start_date', 'end_date', 'Date', 'date', 'pickup_ready_time'].includes(name)) {
        const datetime = moment(data);
        formattedData = datetime.format('MMM Do YYYY h:mm A');
    }

    return (
        <Box className={`${classes.box} ${asNew && classes.blue}`} {...rest}>
            <Typography variant="p">{formattedData}</Typography>
        </Box>
    );
};

export default DataItem;
