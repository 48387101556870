/**
 * @template T
 * @param {(a: T, b: T) => number} compare - A function that accepts two values and returns a number
 * @returns {{
 *  asc: {(a: T, b: T) => number},
 *  desc: {(a: T, b: T) => number}
 * }}
 *      asc: a sort function that sorts in ascending order
 *      desc: a sort function that sorts in descending order
 *
 * example usage:
 * const directionalValueComparison = directionalCompare(compareDates);
 *
 * sorted_dates_asc = dates.sort(directionalValueComparison.asc);
 * sorted_dates_desc = dates.sort(directionalValueComparison.desc);
 */
export const directionalCompare = (compare) => {
    return {
        asc: compare,
        desc: (a, b) => compare(b, a),
    };
};

/**
 * @template T, M
 * @param {(x: T) => M} map - A function to map to the specified value to use for comparison
 * @param {(a: M, b: M) => number} compare - A function that accepts two values and returns a number
 * @returns {(a: T, b: T) => number} A function used to sort
 *
 * example usage:
 * const mappingFunction = (obj) => moment(obj.date).toDate();
 * const dateComparison = mapCompare(mappingFunction, compareDates);
 *
 * dates.sort(dateComparison);
 */
export const mapCompare = (map, compare) => {
    return (a, b) => compare(map(a), map(b));
};

/**
 * @template T, M
 * @param {(x: T) => M} map - A function to map to the specified value to use for comparison
 * @param {(a: M, b: M) => number} compare - A function that accepts two values and returns a number
 * @returns {{
 *  asc: {(a: T, b: T) => number},
 *  desc: {(a: T, b: T) => number}
 * }}
 *      asc: a sort function that sorts in ascending order
 *      desc: a sort function that sorts in descending order
 *
 * example usage:
 * const mappingFunction = (obj) => moment(obj.date).toDate();
 * const directionalDateComparison = directionalMapCompare(mappingFunction, compareDates);
 *
 * sorted_dates_asc = dates.sort(directionalDateComparison.asc);
 * sorted_dates_desc = dates.sort(directionalDateComparison.desc);
 */
export const directionalMapCompare = (map, compare) => {
    return directionalCompare(mapCompare(map, compare));
};

/**
 *
 * @param {Date} date1 - the first date to compare
 * @param {Date} date2 - the second date to compare
 * @returns {number} the difference of the dates
 */
export const compareDates = (date1, date2) => {
    return date1.valueOf() - date2.valueOf();
};
