import axios from 'axios';
import { editAssignmentConfigurationQuery } from './queries';

const editAssignmentConfiguration = async (model, key) => {
    const query = editAssignmentConfigurationQuery({ model, key });
    return axios
        .put(query.url, { ...query.body })
        .then((response) => {
            return { data: response.data };
        })
        .catch((errors) => {
            // eslint-disable-next-line no-console
            console.log(errors);
            throw new Error('Unable to edit Assignment');
        });
};

export default editAssignmentConfiguration;
