import axios from 'axios';
import { editIPDOQuery } from './inprogressDropoff.queries';

const editIPDO = async (model, key) => {
    try {
        const query = editIPDOQuery({ key, model });
        const response = await axios.patch(query.url, { ...query.body });

        return { data: response.data };
    } catch (errors) {
        // eslint-disable-next-line no-console
        console.log(errors);
        throw new Error('Unable to edit In-Progress Dropoff');
    }
};

export default editIPDO;
