import React from 'react';
import { AppBar, Typography } from '@mui/material';
import connect from 'react-redux/es/connect/connect';
import { ProducerSelector } from 'app/custom-components';
import { bindActionCreators } from 'redux';
import * as Actions from '../../store/actions';
import { getUserDisplayName, getUserLicenseNumber } from '../../helpers/users/utils';

const UserTitleSx = {
    fontSize: '1.6rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    fontWeight: 600,
};

const UserEmailSx = {
    fontSize: '1.0rem',
    paddingLeft: '2rem',
    paddingRight: '2rem',
    opacity: 0.7,
};

const AppBarSx = {
    backgroundColor: (theme) => theme.palette.secondary.extraDark,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '12px',
    paddingBottom: '20px',
    zIndex: 10,
};

const UserNavbarHeader = ({ user, role, classes, foldedOpen, folded, getAccessList, accessListDetails, region }) => {
    if (role !== 'guest' && !accessListDetails) {
        getAccessList();
    }
    const listOfValues =
        accessListDetails && accessListDetails.accesses
            ? accessListDetails.accesses
                  .filter((value) => !value.deleted_at)
                  .map((u) => {
                      const license_number = getUserLicenseNumber(u);
                      const label = getUserDisplayName(u.label, license_number, true);
                      return { label, value: u.id, role: u.type };
                  })
            : [];

    // prettier-ignore
    const showSelector =
        (!!sessionStorage.getItem('isAdmin')      
        || (listOfValues && listOfValues.length > 0)) 
        && (foldedOpen || !folded);

    return (
        <AppBar position="static" sx={AppBarSx} elevation={0} className="relative">
            <Typography sx={UserTitleSx} variant="body" color="inherit">
                {user.data.name}
            </Typography>
            <Typography variant="caption" sx={UserEmailSx}>
                {user.data.email}
            </Typography>
            {
                <ProducerSelector
                    show={showSelector}
                    initialValue={
                        sessionStorage.getItem('selected_user')
                            ? {
                                  value: sessionStorage.getItem('selected_user'),
                                  label: sessionStorage.getItem('selected_name'), //
                              }
                            : undefined
                    }
                    listOfValues={listOfValues} //
                />
            }
        </AppBar>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getAccessList: Actions.getAccessList }, dispatch);
}

function mapStateToProps({ fuse, persisted, mainReducer }) {
    return {
        folded: fuse.settings.current.layout.config.navbar.folded,
        foldedOpen: fuse.navbar.foldedOpen,
        user: persisted.auth.user,
        role: persisted.auth.user.role,
        region: persisted.auth.user.data.region,
        accessListDetails: persisted.accessListDetails.data,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserNavbarHeader);
