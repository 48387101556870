import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getProducersQuery, getMonthlyAveragesQuery, getProcessorMonthlyAveragesQuery, getLabReportsQuery, getLastPickupsQuery, getPickupsQuery } from './queries';
import { getAlertData, getTimeSinceLastPickup, getDashboardGraphDetails } from './utils';
import { LAB_STANDARDS, arrayToMap, renameKey } from '../../utils';
import { buildExpressQuery } from './queries/builders';
import { getQualityStatusAndOverride } from './qualityStatusAndOverrides';

const requiresTwoYearsData = (region) => {
    switch (region) {
        case 'RF':
            return true;
        default:
            return false;
    }
};

const getAveragesYears = (region) => {
    switch (region) {
        case 'RF':
            return 3;
        default:
            return 2;
    }
};

const requiresPondviewAdjustments = (region) => {
    switch (region) {
        case 'NL':
            return true;
        default:
            return false;
    }
};

const getGraphDetailsRoutes = (region) => {
    switch (region) {
        case 'NL':
            return ['DL', 'SJ', 'BSG', 'MGT'];
        case 'CDI':
            return ['1', '2', '3', '4'];
        default:
            return [];
    }
};

const requirePenaltiesByPercentage = (region) => {
    switch (region) {
        case 'NL':
            return true;
        default:
            return false;
    }
};

const requiresWeightedAverages = (region) => {
    switch (region) {
        case 'NL':
        case 'PEI':
            return false;
        default:
            return true;
    }
};

const generateEmptyGraphDetailsArray = () => Array(12).fill(0);

/* eslint-disable */
const initializeData = (region) => ({
    fluidSales: { thisMonth: '0', lastMonth: '0' },
    fluidSalesChange: 0,
    graphDetails: {
        ...(requiresTwoYearsData(region) && {
            twoYear: {
                production: generateEmptyGraphDetailsArray(),
                fat: generateEmptyGraphDetailsArray(),
                solids_not_fat: generateEmptyGraphDetailsArray(),
                lactose: generateEmptyGraphDetailsArray(),
                protein: generateEmptyGraphDetailsArray(),
                LOS: generateEmptyGraphDetailsArray(),
                scc: generateEmptyGraphDetailsArray(),
                bmcc: generateEmptyGraphDetailsArray(),
                bactoscan: generateEmptyGraphDetailsArray(),
                thermo: generateEmptyGraphDetailsArray(),
                other_solids: generateEmptyGraphDetailsArray(),
            },
        }),
        lastYear: {
            production: generateEmptyGraphDetailsArray(),
            fat: generateEmptyGraphDetailsArray(),
            solids_not_fat: generateEmptyGraphDetailsArray(),
            lactose: generateEmptyGraphDetailsArray(),
            protein: generateEmptyGraphDetailsArray(),
            LOS: generateEmptyGraphDetailsArray(),
            scc: generateEmptyGraphDetailsArray(),
            bmcc: generateEmptyGraphDetailsArray(),
            bactoscan: generateEmptyGraphDetailsArray(),
            thermo: generateEmptyGraphDetailsArray(),
            standard_plate_count: generateEmptyGraphDetailsArray(),
            other_solids: generateEmptyGraphDetailsArray(),
        },
        thisYear: {
            production: generateEmptyGraphDetailsArray(),
            fat: generateEmptyGraphDetailsArray(),
            solids_not_fat: generateEmptyGraphDetailsArray(),
            lactose: generateEmptyGraphDetailsArray(),
            protein: generateEmptyGraphDetailsArray(),
            LOS: generateEmptyGraphDetailsArray(),
            scc: generateEmptyGraphDetailsArray(),
            bmcc: generateEmptyGraphDetailsArray(),
            bactoscan: generateEmptyGraphDetailsArray(),
            thermo: generateEmptyGraphDetailsArray(),
            standard_plate_count: generateEmptyGraphDetailsArray(),
            other_solids: generateEmptyGraphDetailsArray(),
        },
        routes: _.reduce(getGraphDetailsRoutes(region), (acc, val) => ({ ...acc, [val]: generateEmptyGraphDetailsArray() }), {}),
    },
    industryDetails: {
        previousMonth: {
            fluid_production: 0,
            dropoffs_total: 0,
            fat: 0,
            solids_not_fat: 0,
            lactose: 0,
            protein: 0,
            LOS: 0,
            scc: 0,
            bmcc: 0,
            bacto_scan: 0,
            thermo: 0,
            standard_plate_count: 0,
            other_solids: 0,
        },
        thisMonth: {
            fluid_production: 0,
            dropoffs_total: 0,
            fat: 0,
            solids_not_fat: 0,
            lactose: 0,
            protein: 0,
            LOS: 0,
            scc: 0,
            bmcc: 0,
            thermo: 0,
            bacto_scan: 0,
            standard_plate_count: 0,
            other_solids: 0,
        },
    },
    std_plot: {
        bacto: {
            outliers: {},
            above_standard_rf: {},
            cutoff_low: 0,
            cutoff_high: 0,
            data: [],
        },
        scc: {
            outliers: {},
            above_standard_rf: {},
            cutoff_low: 0,
            cutoff_high: 0,
            data: [],
        },
        bmcc: {
            above_standard_rf: {},
            cutoff_high: LAB_STANDARDS.RF.BMCC_WARNING,
            data: [],
        },
        thermo: {
            above_standard_rf: {},
            cutoff_high: LAB_STANDARDS.RF.THERMO_PC_WARNING,
            data: [],
        },
        temperature: {
            above_standard_rf: {},
            cutoff_high: LAB_STANDARDS.RF.TEMPERATURE_ERROR,
            data: [],
        },
        inhibitory: {
            above_standard_rf: {},
            cutoff_high: true,
            data: [],
        },
    },
    tableDetails: [],
});

// TODO refactor these two functions into general case
function assignThreeYearGraphDetails(i, data, industryGraphDetails, isCurrentMonth, lastMonthDropoffsTotal, currentMonthVolume, thisMonthDropoffsTotal, lastPickupDate) {
    if (i < 12) {
        data.graphDetails.twoYear.fat[i] = industryGraphDetails.fat;
        data.graphDetails.twoYear.solids_not_fat[i] = industryGraphDetails.solids_not_fat;
        data.graphDetails.twoYear.lactose[i] = industryGraphDetails.lactose;
        data.graphDetails.twoYear.protein[i] = industryGraphDetails.protein;
        data.graphDetails.twoYear.LOS[i] = industryGraphDetails.LOS;
        data.graphDetails.twoYear.scc[i] = industryGraphDetails.scc;
        data.graphDetails.twoYear.bmcc[i] = industryGraphDetails.bmcc;
        data.graphDetails.twoYear.bactoscan[i] = industryGraphDetails.bactoscan;
        data.graphDetails.twoYear.production[i] = industryGraphDetails.production;
        data.graphDetails.twoYear.thermo[i] = industryGraphDetails.thermo;
        data.graphDetails.twoYear.other_solids[i] = industryGraphDetails.other_solids;
    } else if (i < 24) {
        data.graphDetails.lastYear.fat[i % 12] = industryGraphDetails.fat;
        data.graphDetails.lastYear.solids_not_fat[i % 12] = industryGraphDetails.solids_not_fat;
        data.graphDetails.lastYear.lactose[i % 12] = industryGraphDetails.lactose;
        data.graphDetails.lastYear.protein[i % 12] = industryGraphDetails.protein;
        data.graphDetails.lastYear.LOS[i % 12] = industryGraphDetails.LOS;
        data.graphDetails.lastYear.scc[i % 12] = industryGraphDetails.scc;
        data.graphDetails.lastYear.bmcc[i % 12] = industryGraphDetails.bmcc;
        data.graphDetails.lastYear.bactoscan[i % 12] = industryGraphDetails.bactoscan;
        data.graphDetails.lastYear.production[i % 12] = industryGraphDetails.production;
        data.graphDetails.lastYear.thermo[i % 12] = industryGraphDetails.thermo;
        data.graphDetails.lastYear.other_solids[i % 12] = industryGraphDetails.other_solids;
    } else {
        data.graphDetails.thisYear.fat[i % 12] = industryGraphDetails.fat;
        data.graphDetails.thisYear.solids_not_fat[i % 12] = industryGraphDetails.solids_not_fat;
        data.graphDetails.thisYear.lactose[i % 12] = industryGraphDetails.lactose;
        data.graphDetails.thisYear.protein[i % 12] = industryGraphDetails.protein;
        data.graphDetails.thisYear.LOS[i % 12] = industryGraphDetails.LOS;
        data.graphDetails.thisYear.scc[i % 12] = industryGraphDetails.scc;
        data.graphDetails.thisYear.bmcc[i % 12] = industryGraphDetails.bmcc;
        data.graphDetails.thisYear.bactoscan[i % 12] = industryGraphDetails.bactoscan;
        data.graphDetails.thisYear.production[i % 12] = industryGraphDetails.production;
        data.graphDetails.thisYear.thermo[i % 12] = industryGraphDetails.thermo;
        data.graphDetails.thisYear.other_solids[i % 12] = industryGraphDetails.other_solids;
        if (i === 34) {
            data.industryDetails.previousMonth.fat = industryGraphDetails.fat;
            data.industryDetails.previousMonth.solids_not_fat = industryGraphDetails.solids_not_fat;
            data.industryDetails.previousMonth.lactose = industryGraphDetails.lactose;
            data.industryDetails.previousMonth.protein = industryGraphDetails.protein;
            data.industryDetails.previousMonth.LOS = industryGraphDetails.LOS;
            data.industryDetails.previousMonth.scc = industryGraphDetails.scc;
            data.industryDetails.previousMonth.bmcc = industryGraphDetails.bmcc;
            data.industryDetails.previousMonth.bacto_scan = industryGraphDetails.bactoscan;
            data.industryDetails.previousMonth.thermo = industryGraphDetails.thermo;
            data.industryDetails.previousMonth.other_solids = industryGraphDetails.other_solids;
            if (isCurrentMonth) {
                data.industryDetails.previousMonth.fluid_production = _.round((industryGraphDetails.production / moment().daysInMonth()) * moment().date(), 2);
                data.industryDetails.previousMonth.dropoffs_total = _.round((lastMonthDropoffsTotal / moment().daysInMonth()) * moment().date(), 2);
            } else {
                data.industryDetails.previousMonth.fluid_production = industryGraphDetails.production;
                data.industryDetails.previousMonth.dropoffs_total = lastMonthDropoffsTotal;
            }
        }
        if (i === 35) {
            data.industryDetails.thisMonth.fat = industryGraphDetails.fat;
            data.industryDetails.thisMonth.solids_not_fat = industryGraphDetails.solids_not_fat;
            data.industryDetails.thisMonth.lactose = industryGraphDetails.lactose;
            data.industryDetails.thisMonth.protein = industryGraphDetails.protein;
            data.industryDetails.thisMonth.LOS = industryGraphDetails.LOS;
            data.industryDetails.thisMonth.scc = industryGraphDetails.scc;
            data.industryDetails.thisMonth.bmcc = industryGraphDetails.bmcc;
            data.industryDetails.thisMonth.bacto_scan = industryGraphDetails.bactoscan;
            data.industryDetails.thisMonth.thermo = industryGraphDetails.thermo;
            data.industryDetails.thisMonth.fluid_production = currentMonthVolume;
            data.industryDetails.thisMonth.dropoffs_total = thisMonthDropoffsTotal;
            data.industryDetails.thisMonth.other_solids = industryGraphDetails.other_solids;
            if (isCurrentMonth) {
                const pickupDay = moment(lastPickupDate).month === moment().month ? moment(lastPickupDate).date() : moment().date();
                data.graphDetails.thisYear.projection = _.round((data.graphDetails.thisYear.production[i % 12] / pickupDay) * moment().daysInMonth(), 2);
            }
        }
    }
}

function assignTwoYearGraphDetails(i, data, industryGraphDetails, isCurrentMonth, lastMonthDropoffsTotal, currentMonthVolume, thisMonthDropoffsTotal) {
    if (i < 12) {
        data.graphDetails.lastYear.fat[i] = industryGraphDetails.fat;
        data.graphDetails.lastYear.solids_not_fat[i] = industryGraphDetails.solids_not_fat;
        data.graphDetails.lastYear.lactose[i] = industryGraphDetails.lactose;
        data.graphDetails.lastYear.protein[i] = industryGraphDetails.protein;
        data.graphDetails.lastYear.LOS[i] = industryGraphDetails.LOS;
        data.graphDetails.lastYear.scc[i] = industryGraphDetails.scc;
        data.graphDetails.lastYear.bmcc[i] = industryGraphDetails.bmcc;
        data.graphDetails.lastYear.bactoscan[i] = industryGraphDetails.bactoscan;
        data.graphDetails.lastYear.production[i] = industryGraphDetails.production;
        data.graphDetails.lastYear.thermo[i] = industryGraphDetails.thermo;
        data.graphDetails.lastYear.standard_plate_count[i] = industryGraphDetails.standard_plate_count;
        data.graphDetails.lastYear.other_solids[i] = industryGraphDetails.other_solids;
    } else {
        data.graphDetails.thisYear.fat[i % 12] = industryGraphDetails.fat;
        data.graphDetails.thisYear.solids_not_fat[i % 12] = industryGraphDetails.solids_not_fat;
        data.graphDetails.thisYear.lactose[i % 12] = industryGraphDetails.lactose;
        data.graphDetails.thisYear.protein[i % 12] = industryGraphDetails.protein;
        data.graphDetails.thisYear.LOS[i % 12] = industryGraphDetails.LOS;
        data.graphDetails.thisYear.scc[i % 12] = industryGraphDetails.scc;
        data.graphDetails.thisYear.bmcc[i % 12] = industryGraphDetails.bmcc;
        data.graphDetails.thisYear.bactoscan[i % 12] = industryGraphDetails.bactoscan;
        data.graphDetails.thisYear.production[i % 12] = industryGraphDetails.production;
        data.graphDetails.thisYear.thermo[i % 12] = industryGraphDetails.thermo;
        data.graphDetails.thisYear.standard_plate_count[i % 12] = industryGraphDetails.standard_plate_count;
        data.graphDetails.thisYear.other_solids[i % 12] = industryGraphDetails.other_solids;
        if (i === 22) {
            data.industryDetails.previousMonth.fat = industryGraphDetails.fat;
            data.industryDetails.previousMonth.solids_not_fat = industryGraphDetails.solids_not_fat;
            data.industryDetails.previousMonth.lactose = industryGraphDetails.lactose;
            data.industryDetails.previousMonth.protein = industryGraphDetails.protein;
            data.industryDetails.previousMonth.LOS = industryGraphDetails.LOS;
            data.industryDetails.previousMonth.scc = industryGraphDetails.scc;
            data.industryDetails.previousMonth.bmcc = industryGraphDetails.bmcc;
            data.industryDetails.previousMonth.bacto_scan = industryGraphDetails.bactoscan;
            data.industryDetails.previousMonth.thermo = industryGraphDetails.thermo;
            data.industryDetails.previousMonth.standard_plate_count = industryGraphDetails.standard_plate_count;
            data.industryDetails.previousMonth.other_solids = industryGraphDetails.other_solids;
            if (isCurrentMonth) {
                data.industryDetails.previousMonth.fluid_production = _.round((industryGraphDetails.production / moment().daysInMonth()) * moment().date(), 2);
                data.industryDetails.previousMonth.dropoffs_total = _.round((lastMonthDropoffsTotal / moment().daysInMonth()) * moment().date(), 2);
            } else {
                data.industryDetails.previousMonth.fluid_production = industryGraphDetails.production;
                data.industryDetails.previousMonth.dropoffs_total = lastMonthDropoffsTotal;
            }
        }
        if (i === 23) {
            data.industryDetails.thisMonth.fat = industryGraphDetails.fat;
            data.industryDetails.thisMonth.solids_not_fat = industryGraphDetails.solids_not_fat;
            data.industryDetails.thisMonth.lactose = industryGraphDetails.lactose;
            data.industryDetails.thisMonth.protein = industryGraphDetails.protein;
            data.industryDetails.thisMonth.LOS = industryGraphDetails.LOS;
            data.industryDetails.thisMonth.scc = industryGraphDetails.scc;
            data.industryDetails.thisMonth.bmcc = industryGraphDetails.bmcc;
            data.industryDetails.thisMonth.bacto_scan = industryGraphDetails.bactoscan;
            data.industryDetails.thisMonth.thermo = industryGraphDetails.thermo;
            data.industryDetails.thisMonth.standard_plate_count = industryGraphDetails.standard_plate_count;
            data.industryDetails.thisMonth.fluid_production = currentMonthVolume;
            data.industryDetails.thisMonth.dropoffs_total = thisMonthDropoffsTotal;
            data.industryDetails.thisMonth.other_solids = industryGraphDetails.other_solids;

            if (isCurrentMonth) {
                data.graphDetails.thisYear.projection = _.round((data.graphDetails.thisYear.production[i % 12] / moment().date()) * moment().daysInMonth(), 2);
            }
        }
    }
}
/* eslint-enable */

const stats = (array) => {
    const mean = _.mean(array);
    const std = Math.sqrt(_.sum(_.map(array, (i) => (i - mean) ** 2)) / array.length);
    return { mean, std };
};

const calculateDropoffTotals = (processorMonthlyAveragesMap, date) => {
    const lastMonthAverages = processorMonthlyAveragesMap[date.clone().subtract(1, 'month').format('YYYY-MM')];
    const thisMonthAverages = processorMonthlyAveragesMap[date.format('YYYY-MM')];

    const lastMonthDropoffsTotal = lastMonthAverages ? lastMonthAverages.count_dropoff_volume * lastMonthAverages.average_dropoff_volume : 0;
    const thisMonthDropoffsTotal = thisMonthAverages ? thisMonthAverages.count_dropoff_volume * thisMonthAverages.average_dropoff_volume : 0;

    const dropoffTotals = [lastMonthDropoffsTotal, thisMonthDropoffsTotal];
    return dropoffTotals;
};

const getAdminDashboardDetails = (month, year, region) =>
    new Promise((resolve, reject) => {
        const data = initializeData(region);

        const averagesYears = getAveragesYears(region);

        const end = moment(`${year}-${month}`, 'YYYY-MM').endOf('month');
        const start = moment(`${year}-${month}`, 'YYYY-MM').startOf('month');
        const oneYearStart = end.clone().subtract(1, 'years').add(1, 'month');
        const averagesStart = end.clone().subtract(averagesYears, 'years').add(1, 'month');
        // const threeYearStart = end.clone().sub
        const monthStart = end.clone().startOf('month');
        const threeMonthStart = monthStart.clone().subtract(3, 'months');
        const twoMonthStart = monthStart.clone().subtract(2, 'months');

        const isCurrentMonth = moment().isSame(`${year}-${month}`, 'month');

        const isNL = region === 'NL';

        const producersQuery = getProducersQuery({
            orderBy: 'order',
            filterDeleted: true,
            queryBuilder: buildExpressQuery,
        });
        const producersRequest = axios.get(producersQuery);

        const producersMonthAveragesQuery = getMonthlyAveragesQuery({
            start: monthStart.format('YYYY-MM'),
            end: end.format('YYYY-MM'),
            allUsers: true,
            fields: [
                'user_id',
                'total_pickup_volume',
                'average_pickup_volume',
                'count_pickup_volume',
                'production_by_day',
                'id',
                'date',
                'weighted_average_fat',
                'weighted_average_other_solids',
                'weighted_average_solids_not_fat',
                'weighted_average_lactose',
                'weighted_average_protein',
                'weighted_average_bacto',
                'weighted_average_milk_urea_nitrogen',
                'weighted_average_scc',
            ],
        });
        const producersMonthAveragesRequest = axios.get(producersMonthAveragesQuery);

        const labReportsQuery = getLabReportsQuery({
            start: threeMonthStart.format(),
            end: end.format(),
            order: 'date ASC',
            bySampleDate: true,
            filterOutliers: true,
            filterDeleted: true,
            queryBuilder: buildExpressQuery,
        });
        const labReportsRequest = axios.get(labReportsQuery);

        const industryMonthlyAveragesQuery = getMonthlyAveragesQuery({ start: averagesStart.format('YYYY-MM'), end: end.format('YYYY-MM') });
        const industryMonthlyAveragesRequest = axios.get(industryMonthlyAveragesQuery);

        const processorMonthlyAveragesQuery = getProcessorMonthlyAveragesQuery({ start: twoMonthStart.format('YYYY-MM'), end: end.format('YYYY-MM') });
        const processorMonthlyAveragesRequest = axios.get(processorMonthlyAveragesQuery);

        const lastPickupsRequest = axios.get(getLastPickupsQuery());
        let qualityStatusAndOverride = new Promise((res, rej) => {
            res();
        });
        if (region === 'MMPA') {
            qualityStatusAndOverride = getQualityStatusAndOverride(start.format());
        }

        axios
            .all([producersRequest, producersMonthAveragesRequest, labReportsRequest, processorMonthlyAveragesRequest, industryMonthlyAveragesRequest, lastPickupsRequest, qualityStatusAndOverride])
            .then(
                axios.spread((...responses) => {
                    const producers = responses[0].data;

                    renameKey(producers, '_id', 'id');

                    let producersMonthAverages = responses[1].data;
                    if (region === 'NL') {
                        producersMonthAverages = producersMonthAverages.filter((average) => average.total_pickup_volume != null);
                    }
                    const producersMonthAveragesMap = arrayToMap(producersMonthAverages, 'user_id');

                    const labReports = responses[2].data;
                    renameKey(labReports, '_id', 'id');
                    const processorMonthlyAverages = responses[3].data;
                    const processorMonthlyAveragesMap = arrayToMap(processorMonthlyAverages, 'date');
                    const industryMonthlyAverages = responses[4].data;
                    const industryMonthlyAveragesMap = arrayToMap(industryMonthlyAverages, 'date');
                    const lastPickups = responses[5].data;
                    data.tableDetails = producers;

                    const averagesAndOverrides = responses[6];
                    let averagesAndOverridesMap = [];
                    if (region === 'MMPA') {
                        averagesAndOverridesMap = arrayToMap(averagesAndOverrides, 'producer_id');
                    }

                    data.tableDetails = data.tableDetails.map((producer) => {
                        const avg = producersMonthAveragesMap[producer.id];
                        return {
                            ...producer,
                            volume: avg ? (avg.total_pickup_volume ? avg.total_pickup_volume : avg.average_pickup_volume && avg.count_pickup_volume ? avg.average_pickup_volume * avg.count_pickup_volume : 0) : 0,
                            num_days_pickup: avg && avg.production_by_day ? Object.keys(avg.production_by_day).length : 0,
                        };
                    });

                    const [lastMonthDropoffsTotal, thisMonthDropoffsTotal] = calculateDropoffTotals(processorMonthlyAveragesMap, end);

                    const regionAverageCalculations =
                        region === 'NL'
                            ? new Promise((res, rej) => {
                                  const regions = _.keys(data.graphDetails.routes);
                                  const producerIdsByRegion = _.mapValues(
                                      _.groupBy(producers, (value) => value.route_region),
                                      (ps) => _.map(ps, (p) => p.id)
                                  );
                                  if (regions) {
                                      const regionProducerIds = _.uniq(_.reduce(regions, (acc, val) => [...acc, ...producerIdsByRegion[val]], []));
                                      const fields = ['id', 'user_id', 'date', 'average_pickup_volume', 'count_pickup_volume', 'total_pickup_volume'];
                                      const producersMonthlyAveragesQuery = getMonthlyAveragesQuery({
                                          fields,
                                          userIds: regionProducerIds,
                                          start: averagesStart.format('YYYY-MM'),
                                          end: end.format('YYYY-MM'),
                                      });
                                      const producersMonthlyAveragesRequest = axios.get(producersMonthlyAveragesQuery);
                                      const producerMap = arrayToMap(producers, 'id');

                                      producersMonthlyAveragesRequest.then((response) => {
                                          const monthlyAverages = response.data;
                                          if (!_.isEmpty(monthlyAverages)) {
                                              const monthlyAveragesByRegion = _.groupBy(monthlyAverages, (m) => producerMap[m.user_id].route_region);

                                              const monthyAveragesByRegionByMonth = _.mapValues(monthlyAveragesByRegion, (mavgs) => _.groupBy(mavgs, (m) => m.date));
                                              _.forEach(_.range(12), (i) => {
                                                  const date = oneYearStart.clone().add(i, 'months').format('YYYY-MM');
                                                  _.forEach(regions, (r) => {
                                                      if (!monthyAveragesByRegionByMonth[r] || !monthyAveragesByRegionByMonth[r][date]) {
                                                          data.graphDetails.routes[r][i] = 0;
                                                      } else {
                                                          const avgs = monthyAveragesByRegionByMonth[r][date];
                                                          const production = _.reduce(avgs, (acc, val) => (!!val.total_pickup_volume ? val.total_pickup_volume : !!val.average_pickup_volume && !!val.count_pickup_volume ? val.average_pickup_volume * val.count_pickup_volume : 0 + acc), 0);
                                                          data.graphDetails.routes[r][i] = production;
                                                      }
                                                  });
                                              });
                                          }
                                          res();
                                      });
                                  } else {
                                      res();
                                  }
                              })
                            : region === 'CDI'
                            ? new Promise((res, rej) => {
                                  const regions = _.keys(data.graphDetails.routes);
                                  const fields = ['id', 'user_id', 'date', 'average_pickup_volume', 'count_pickup_volume', 'total_pickup_volume', 'route_region'];
                                  const regionsMonthlyAveragesQuery = getMonthlyAveragesQuery({
                                      fields,
                                      routeRegions: regions,
                                      start: averagesStart.format('YYYY-MM'),
                                      end: end.format('YYYY-MM'),
                                  });
                                  const regionsMonthlyAveragesRequest = axios.get(regionsMonthlyAveragesQuery);

                                  regionsMonthlyAveragesRequest.then((response) => {
                                      const monthlyAverages = response.data;

                                      const monthlyAveragesByRegion = _.groupBy(monthlyAverages, (m) => m.route_region);
                                      const monthyAveragesByRegionByMonth = _.mapValues(monthlyAveragesByRegion, (mavgs) => _.groupBy(mavgs, (m) => m.date));

                                      _.forEach(_.range(12), (i) => {
                                          const date = oneYearStart.clone().add(i, 'months').format('YYYY-MM');
                                          _.forEach(regions, (r) => {
                                              if (!monthyAveragesByRegionByMonth[r] || !monthyAveragesByRegionByMonth[r][date]) {
                                                  data.graphDetails.routes[r][i] = 0;
                                              } else {
                                                  const avgs = monthyAveragesByRegionByMonth[r][date][0];
                                                  const production = !!avgs.total_pickup_volume ? avgs.total_pickup_volume : !!avgs.average_pickup_volume && !!avgs.count_pickup_volume ? avgs.average_pickup_volume * avgs.count_pickup_volume : 0;
                                                  data.graphDetails.routes[r][i] = production;
                                              }
                                          });
                                      });
                                  });
                                  res();
                              })
                            : new Promise((res, rej) => {
                                  res();
                              });

                    const dateRange = {
                        start: end.clone().add(1, 'seconds').subtract(3, 'months'),
                        end,
                    };

                    // penalty to details
                    const labReportsByProducer = _.groupBy(labReports, 'producer_id');

                    _.forEach(data.tableDetails, (detail) => {
                        const reports = labReportsByProducer[detail.id] ? labReportsByProducer[detail.id] : [];
                        const alertDetails = getAlertData(dateRange, reports, region);
                        const penalties = requirePenaltiesByPercentage(region)
                            ? {
                                  bacto_penalty: alertDetails.bacto_percentage,
                                  scc_penalty: alertDetails.scc_percentage,
                                  freezing_penalty: null,
                              }
                            : {
                                  bacto_penalty: alertDetails.bacto_scan_count_over_standard,
                                  scc_penalty: alertDetails.scc_count_over_standard,
                                  freezing_penalty: alertDetails.freezing_point_count_over_standard,
                              };

                        _.assign(detail, penalties);
                    });
                    //

                    // TODO rather than calculate averages from labs, use monthly averages collection
                    // TODO currently hacked to only get data for RF
                    const fields = [
                        'id',
                        'user_id',
                        'date',
                        'weighted_average_fat',
                        'weighted_average_other_solids',
                        'weighted_average_solids_not_fat',
                        'weighted_average_lactose',
                        'weighted_average_protein',
                        'weighted_average_thermo',
                        'weighted_average_bacto',
                        'weighted_average_bmcc',
                        'weighted_average_cfu',
                        'gmean_3_month_bmcc',
                        'gmean_2_month_bacto',
                    ];

                    const producersMonthlyAveragesQuery = getMonthlyAveragesQuery({
                        fields,
                        userIds: region === 'RF' ? _.map(producers, (producer) => producer.id) : [],
                        start: end.format('YYYY-MM'),
                        end: end.format('YYYY-MM'),
                    });
                    const producersMonthlyAveragesRequest = axios.get(producersMonthlyAveragesQuery);

                    producersMonthlyAveragesRequest.then((producersMonthlyAveragesResponse) => {
                        const producersMonthlyAverages = producersMonthlyAveragesResponse.data;
                        const producersMonthlyAveragesMap = arrayToMap(producersMonthlyAverages, 'user_id');

                        // component averages to details
                        const labReportsInMonth = _.filter(labReports, (r) => moment(r.date).format('YYYY-MM') === end.format('YYYY-MM'));
                        const labReportsInMonthByProducer = _.groupBy(labReportsInMonth, 'producer_id');
                        // id's of producers who share an account with another producer, filter to avoid duplicates in table and graph
                        const producersToFilter = ['5fd301234520520895d9e1ff', '5fd301234520520895d9e20f'];
                        const labReportsInMonthWithoutDuplicates = _.filter(labReportsInMonth, (lab) => !producersToFilter.includes(lab.producer_id));

                        const sccLabValues = labReportsInMonth.reduce((arr, value) => {
                            if (!!value.somatic_cell_count) {
                                arr.push(value.somatic_cell_count);
                            }
                            return arr;
                        }, []);

                        const bactoLabValues = labReportsInMonthWithoutDuplicates.reduce((arr, value) => {
                            if (!!value.bacto_scan) {
                                arr.push(value.bacto_scan);
                            }
                            return arr;
                        }, []);

                        const bmccLabValues = labReportsInMonthWithoutDuplicates.reduce((arr, value) => {
                            if (!!value.bulk_milk_cell_count) {
                                arr.push(value.bulk_milk_cell_count);
                            }
                            return arr;
                        }, []);

                        const thermoLabValues = labReportsInMonthWithoutDuplicates.reduce((arr, value) => {
                            if (!!value.thermo_plate_count) {
                                arr.push(value.thermo_plate_count);
                            }
                            return arr;
                        }, []);

                        const tempLabValues = labReportsInMonthWithoutDuplicates.reduce((arr, value) => {
                            if (!!value.temperature) {
                                arr.push(value.temperature);
                            }
                            return arr;
                        }, []);

                        const inhibLabValues = labReportsInMonthWithoutDuplicates.reduce((arr, value) => {
                            if (!!value.inhibitory_substances_present) {
                                arr.push(value.inhibitory_substances_present);
                            }
                            return arr;
                        }, []);

                        const sccStats = stats(sccLabValues);
                        const bactoStats = stats(bactoLabValues);

                        const sccCutoffs = { cutoff_high: sccStats.mean + 2 * sccStats.std, cutoff_low: sccStats.mean - 2 * sccStats.std < 0 ? 0 : sccStats.mean - 2 * sccStats.std };
                        const bactoCutoffs = { cutoff_high: bactoStats.mean + 2 * bactoStats.std, cutoff_low: bactoStats.mean - 2 * bactoStats.std < 0 ? 0 : bactoStats.mean - 2 * bactoStats.std };

                        data.std_plot.bacto.cutoff_low = bactoCutoffs.cutoff_low;
                        data.std_plot.bacto.cutoff_high = region === 'RF' ? LAB_STANDARDS.RF.BACTO_WARNING : bactoCutoffs.cutoff_high;
                        data.std_plot.bacto.data = bactoLabValues;

                        const producerMap = arrayToMap(producers, 'id');

                        data.std_plot.bacto.outliers = labReportsInMonth.reduce((arr, value) => {
                            if (!!value.bacto_scan && (value.bacto_scan < bactoCutoffs.cutoff_low || value.bacto_scan > bactoCutoffs.cutoff_high)) {
                                const producerName = producerMap[value.producer_id]?.name || '';
                                if (producerName in arr) {
                                    arr[producerName].push({ value: value.bacto_scan, id: value.id });
                                } else {
                                    // eslint-disable-next-line no-param-reassign
                                    arr[producerName] = [{ value: value.bacto_scan, id: value.id }];
                                }
                            }
                            return arr;
                        }, {});

                        data.std_plot.bacto.above_standard_rf = labReportsInMonthWithoutDuplicates.reduce((arr, value) => {
                            if (value.bacto_scan && value.bacto_scan > LAB_STANDARDS.RF.BACTO_WARNING) {
                                const producerName = producerMap[value.producer_id]?.name || '';
                                if (producerName in arr) {
                                    arr[producerName].push({
                                        value: value.bacto_scan,
                                        id: value.id,
                                        date: value.date,
                                    });
                                } else {
                                    // eslint-disable-next-line no-param-reassign
                                    arr[producerName] = [
                                        {
                                            value: value.bacto_scan,
                                            id: value.id,
                                            date: value.date,
                                        },
                                    ];
                                }
                            }
                            return arr;
                        }, {});

                        data.std_plot.scc.cutoff_low = sccCutoffs.cutoff_low;
                        data.std_plot.scc.cutoff_high = sccCutoffs.cutoff_high;
                        data.std_plot.scc.data = sccLabValues;

                        data.std_plot.scc.outliers = labReportsInMonth.reduce((arr, value) => {
                            if (!!value.somatic_cell_count && (value.somatic_cell_count < sccCutoffs.cutoff_low || value.somatic_cell_count > sccCutoffs.cutoff_high)) {
                                const producerName = producerMap[value.producer_id]?.name || '';
                                if (producerName in arr) {
                                    arr[producerName].push({ value: value.somatic_cell_count, id: value.id });
                                } else {
                                    // eslint-disable-next-line no-param-reassign
                                    arr[producerName] = [{ value: value.somatic_cell_count, id: value.id }];
                                }
                            }
                            return arr;
                        }, {});

                        data.std_plot.bmcc.data = bmccLabValues;
                        data.std_plot.bmcc.above_standard_rf = labReportsInMonthWithoutDuplicates.reduce((arr, value) => {
                            if (value.bulk_milk_cell_count && value.bulk_milk_cell_count > LAB_STANDARDS.RF.BMCC_WARNING) {
                                const producerName = producerMap[value.producer_id]?.name || '';
                                if (producerName in arr) {
                                    arr[producerName].push({
                                        value: value.bulk_milk_cell_count,
                                        id: value.id,
                                        date: value.date,
                                    });
                                } else {
                                    // eslint-disable-next-line no-param-reassign
                                    arr[producerName] = [
                                        {
                                            value: value.bulk_milk_cell_count,
                                            id: value.id,
                                            date: value.date,
                                        },
                                    ];
                                }
                            }
                            return arr;
                        }, {});

                        data.std_plot.thermo.data = thermoLabValues;
                        data.std_plot.thermo.above_standard_rf = labReportsInMonthWithoutDuplicates.reduce((arr, value) => {
                            if (value.thermo_plate_count && value.thermo_plate_count > LAB_STANDARDS.RF.THERMO_PC_WARNING) {
                                const producerName = producerMap[value.producer_id]?.name || '';
                                if (producerName in arr) {
                                    arr[producerName].push({
                                        value: value.thermo_plate_count,
                                        id: value.id,
                                        date: value.date,
                                    });
                                } else {
                                    // eslint-disable-next-line no-param-reassign
                                    arr[producerName] = [
                                        {
                                            value: value.thermo_plate_count,
                                            id: value.id,
                                            date: value.date,
                                        },
                                    ];
                                }
                            }
                            return arr;
                        }, {});

                        data.std_plot.temperature.data = tempLabValues;
                        data.std_plot.temperature.above_standard_rf = labReportsInMonthWithoutDuplicates.reduce((arr, value) => {
                            if (value.temperature && value.temperature > LAB_STANDARDS.RF.TEMPERATURE_ERROR) {
                                const producerName = producerMap[value.producer_id]?.name || '';
                                if (producerName in arr) {
                                    arr[producerName].push({
                                        value: value.temperature,
                                        id: value.id,
                                        date: value.date,
                                    });
                                } else {
                                    // eslint-disable-next-line no-param-reassign
                                    arr[producerName] = [
                                        {
                                            value: value.temperature,
                                            id: value.id,
                                            date: value.date,
                                        },
                                    ];
                                }
                            }
                            return arr;
                        }, {});

                        data.std_plot.inhibitory.data = inhibLabValues;
                        data.std_plot.inhibitory.above_standard_rf = labReportsInMonthWithoutDuplicates.reduce((arr, value) => {
                            if (!!value.inhibitory_substances_present) {
                                const producerName = producerMap[value.producer_id]?.name || '';
                                if (producerName in arr) {
                                    arr[producerName].push({
                                        value: 'Yes',
                                        id: value.id,
                                        date: value.date,
                                    });
                                } else {
                                    // eslint-disable-next-line no-param-reassign
                                    arr[producerName] = [
                                        {
                                            value: 'Yes',
                                            id: value.id,
                                            date: value.date,
                                        },
                                    ];
                                }
                            }
                            return arr;
                        }, {});

                        _.forEach(data.tableDetails, (value, key) => {
                            if (labReportsInMonthByProducer[value.id]) {
                                const filteredSccLabValues = labReportsInMonthByProducer[value.id].reduce((arr, val) => {
                                    if (!!val.somatic_cell_count) {
                                        arr.push(val.somatic_cell_count);
                                    }
                                    return arr;
                                }, []);

                                const filteredBmccLabValues = labReportsInMonthByProducer[value.id].reduce((arr, val) => {
                                    if (!!val.bulk_milk_cell_count) {
                                        arr.push(val.bulk_milk_cell_count);
                                    }
                                    return arr;
                                }, []);

                                const filteredBactoLabValues = labReportsInMonthByProducer[value.id].reduce((arr, val) => {
                                    if (!!val.bacto_scan) {
                                        arr.push(val.bacto_scan);
                                    }
                                    return arr;
                                }, []);

                                const filteredThermoLabValues = labReportsInMonthByProducer[value.id].reduce((arr, val) => {
                                    if (!!val.thermo_plate_count) {
                                        arr.push(val.thermo_plate_count);
                                    }
                                    return arr;
                                }, []);

                                const filteredTempLabValues = labReportsInMonthByProducer[value.id].reduce((arr, val) => {
                                    if (!!val.temperature) {
                                        arr.push(val.temperature);
                                    }
                                    return arr;
                                }, []);

                                const labReportsPerDay = _.uniqBy(labReportsInMonthByProducer[value.id], (lab) => moment(lab.date).format('YYYY-MM-DD'));
                                const demeritPoints = _.sumBy(labReportsInMonthByProducer[value.id], 'demerit_points');

                                // currently only relevant for RF
                                const producerMonthlyAverages = producersMonthlyAveragesMap[value.id];

                                data.tableDetails[key] = {
                                    ...value,
                                    bacto_plc: 0.0,
                                    bacto_scan: region === 'RF' ? _.round(producerMonthlyAverages.weighted_average_bacto, 2) : ['MMPA'].includes(region) ? (averagesAndOverridesMap[value.id] ? averagesAndOverridesMap[value.id].rbc_premium ?? averagesAndOverridesMap[value.id].rbc_avg : null) : _.round(_.mean(filteredBactoLabValues), 2),
                                    butter_fat:
                                        region === 'RF'
                                            ? _.round(producerMonthlyAverages.weighted_average_fat, 2)
                                            : requiresWeightedAverages(region)
                                            ? _.round(producersMonthAveragesMap?.[value.id]?.weighted_average_fat, 2)
                                            : _.round(
                                                  _.meanBy(
                                                      labReportsInMonthByProducer[value.id].filter((val) => !!val.fat),
                                                      (v) => v.fat
                                                  ),
                                                  2
                                              ),
                                    fat_weight: _.round(
                                        _.sumBy(
                                            labReportsInMonthByProducer[value.id].filter((val) => !!val.fat_weight),
                                            (v) => v.fat_weight
                                        ),
                                        2
                                    ),
                                    protein_weight: _.round(
                                        _.sumBy(
                                            labReportsInMonthByProducer[value.id].filter((val) => !!val.protein_weight),
                                            (v) => v.protein_weight
                                        ),
                                        2
                                    ),
                                    scc: requiresWeightedAverages(region) ? _.round(producersMonthAveragesMap?.[value.id]?.weighted_average_scc, 2) : _.round(_.mean(filteredSccLabValues), 2),
                                    bmcc: region === 'RF' ? _.round(producerMonthlyAverages.weighted_average_bmcc, 2) : _.round(_.mean(filteredBmccLabValues)),
                                    los: _.round(
                                        _.meanBy(
                                            labReportsInMonthByProducer[value.id].filter((val) => !!val.LOS),
                                            (v) => v.LOS
                                        ),
                                        2
                                    ),
                                    lactose: requiresWeightedAverages(region)
                                        ? _.round(producersMonthAveragesMap?.[value.id]?.weighted_average_lactose, 2)
                                        : _.round(
                                              _.meanBy(
                                                  labReportsInMonthByProducer[value.id].filter((val) => !!val.lactose),
                                                  (v) => v.lactose
                                              ),
                                              2
                                          ),
                                    somatic_cell_count: requiresWeightedAverages(region)
                                        ? _.round(producersMonthAveragesMap?.[value.id]?.weighted_average_scc, 2)
                                        : ['MMPA'].includes(region)
                                        ? averagesAndOverridesMap[value.id]
                                            ? averagesAndOverridesMap[value.id].scc_premium ?? averagesAndOverridesMap[value.id].scc_avg
                                            : null
                                        : _.round(
                                              _.meanBy(
                                                  labReportsInMonthByProducer[value.id].filter((val) => !!val.somatic_cell_count),
                                                  (v) => v.somatic_cell_count
                                              ),
                                              2
                                          ),
                                    total_solids: _.round(
                                        _.meanBy(
                                            labReportsInMonthByProducer[value.id].filter((val) => !!val.total_solids),
                                            (v) => v.total_solids
                                        ),
                                        2
                                    ),
                                    preliminary_incubation: ['MMPA'].includes(region)
                                        ? averagesAndOverridesMap[value.id]
                                            ? averagesAndOverridesMap[value.id].pic_premium ?? averagesAndOverridesMap[value.id].pic_avg
                                            : null
                                        : _.round(
                                              _.meanBy(
                                                  labReportsInMonthByProducer[value.id].filter((val) => !!val.preliminary_incubation),
                                                  (v) => v.preliminary_incubation
                                              ),
                                              2
                                          ),
                                    other_solids: !['CDI', 'LEGACY'].includes(region)
                                        ? requiresWeightedAverages(region)
                                            ? _.round(producersMonthAveragesMap?.[value.id]?.weighted_average_other_solids, 2)
                                            : _.round(
                                                  _.meanBy(
                                                      labReportsInMonthByProducer[value.id].filter((val) => !!val.other_solids),
                                                      (v) => v.other_solids
                                                  ),
                                                  2
                                              )
                                        : _.round(
                                              _.meanBy(
                                                  labReportsInMonthByProducer[value.id].filter((val) => !!val.solids_not_fat && !!val.protein),
                                                  (v) => v.solids_not_fat - v.protein
                                              ),
                                              2
                                          ),
                                    solids_not_fat: requiresWeightedAverages(region)
                                        ? _.round(producersMonthAveragesMap?.[value.id]?.weighted_average_solids_not_fat, 2)
                                        : _.round(
                                              _.meanBy(
                                                  labReportsInMonthByProducer[value.id].filter((val) => !!val.solids_not_fat),
                                                  (v) => v.solids_not_fat
                                              ),
                                              2
                                          ),
                                    protein:
                                        region === 'RF'
                                            ? _.round(producerMonthlyAverages.weighted_average_protein, 2)
                                            : requiresWeightedAverages(region)
                                            ? _.round(producersMonthAveragesMap?.[value.id]?.weighted_average_protein, 2)
                                            : _.round(
                                                  _.meanBy(
                                                      labReportsInMonthByProducer[value.id].filter((val) => !!val.protein),
                                                      (v) => v.protein
                                                  ),
                                                  2
                                              ),
                                    thermo: region === 'RF' ? _.round(producerMonthlyAverages.weighted_average_thermo) : _.round(_.mean(filteredThermoLabValues)),
                                    lab_pasteurization_count: _.round(
                                        _.meanBy(
                                            labReportsInMonthByProducer[value.id].filter((val) => !!val.lab_pasteurization_count),
                                            (v) => v.lab_pasteurization_count
                                        ),
                                        2
                                    ),
                                    coliform_count: _.round(
                                        _.meanBy(
                                            labReportsInMonthByProducer[value.id].filter((val) => !!val.coliform_count),
                                            (v) => v.coliform_count
                                        ),
                                        2
                                    ),
                                    milk_urea_nitrogen: requiresWeightedAverages(region)
                                        ? _.round(producersMonthAveragesMap?.[value.id]?.weighted_average_milk_urea_nitrogen, 2)
                                        : _.round(
                                              _.meanBy(
                                                  labReportsInMonthByProducer[value.id].filter((val) => !!val.milk_urea_nitrogen),
                                                  (v) => v.milk_urea_nitrogen
                                              ),
                                              2
                                          ),
                                    standard_plate_count: _.round(
                                        _.meanBy(
                                            labReportsInMonthByProducer[value.id].filter((val) => !!val.standard_plate_count),
                                            (v) => v.standard_plate_count
                                        ),
                                        2
                                    ),
                                    demerit_points: demeritPoints,
                                    temperature: _.round(_.mean(filteredTempLabValues)),
                                    ...(region === 'RF' && {
                                        gmean_bmcc: producerMonthlyAverages.gmean_3_month_bmcc,
                                        gmean_bacto: producerMonthlyAverages.gmean_2_month_bacto,
                                        colony_forming_unit: producerMonthlyAverages.weighted_average_cfu,
                                    }),
                                };
                            }
                        });
                        //
                        regionAverageCalculations.then(() => {
                            const results = lastPickups.map((p) => {
                                return { id: p.producer_id, time_since_last_pickup: getTimeSinceLastPickup(p) };
                            });

                            _.forEach(data.tableDetails, (value, key) => {
                                const result = _.find(results, { id: value.id });
                                if (!!result) {
                                    data.tableDetails[key] = { ...value, last_pickup_time: result.time_since_last_pickup };
                                }
                            });

                            let currentMonthVolume = 0;
                            const currentMonthTotalAvg = industryMonthlyAveragesMap[end.format('YYYY-MM')];
                            if (currentMonthTotalAvg) currentMonthVolume = !!currentMonthTotalAvg.total_pickup_volume ? currentMonthTotalAvg.total_pickup_volume : !!currentMonthTotalAvg.average_pickup_volume && !!currentMonthTotalAvg.count_pickup_volume ? currentMonthTotalAvg.average_pickup_volume * currentMonthTotalAvg.count_pickup_volume : 0;

                            _.forEach(_.range(averagesYears * 12), (i) => {
                                const date = averagesStart.clone().add(i, 'months').format('YYYY-MM');

                                const industryAverages = industryMonthlyAveragesMap[date];

                                const industryGraphDetails = getDashboardGraphDetails(industryAverages, region);

                                if (averagesYears === 2) {
                                    assignTwoYearGraphDetails(i, data, industryGraphDetails, isCurrentMonth, lastMonthDropoffsTotal, currentMonthVolume, thisMonthDropoffsTotal);
                                } else {
                                    // averagesYears === 3
                                    const lastPickupDate = lastPickups ? moment(_.orderBy(lastPickups, 'created_at')[lastPickups.length - 1].created_at).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
                                    assignThreeYearGraphDetails(i, data, industryGraphDetails, isCurrentMonth, lastMonthDropoffsTotal, currentMonthVolume, thisMonthDropoffsTotal, lastPickupDate);
                                }
                            });

                            const forestPondAdjustment = requiresPondviewAdjustments(region)
                                ? new Promise((res, rej) => {
                                      const pondviewFarmsIndex = data.tableDetails.findIndex((producer) => producer.CMTNumber === '103900');
                                      const forestPondRoadIndex = data.tableDetails.findIndex((producer) => producer.CMTNumber === '103200');

                                      const pondViewFarms = _.clone(data.tableDetails[pondviewFarmsIndex]);
                                      const pickupsQuery = getPickupsQuery({
                                          start: monthStart,
                                          end,
                                          order: 'date ASC',
                                          producerId: pondViewFarms.id,
                                          filterDeleted: true,
                                      });
                                      const pickupsRequest = axios.get(pickupsQuery);
                                      pickupsRequest.then((pickupResults) => {
                                          const numPickups = pickupResults.data.length;

                                          data.tableDetails[forestPondRoadIndex].volume = 1300 * numPickups;
                                          data.tableDetails[pondviewFarmsIndex].volume = pondViewFarms.volume - 1300 * numPickups;

                                          data.tableDetails[forestPondRoadIndex].butter_fat = pondViewFarms.butter_fat;
                                          data.tableDetails[forestPondRoadIndex].lactose = pondViewFarms.lactose;
                                          data.tableDetails[forestPondRoadIndex].solids_not_fat = pondViewFarms.solids_not_fat;
                                          data.tableDetails[forestPondRoadIndex].protein = pondViewFarms.protein;
                                          data.tableDetails[forestPondRoadIndex].los = pondViewFarms.los;
                                          data.tableDetails[forestPondRoadIndex].scc = pondViewFarms.scc;
                                          data.tableDetails[forestPondRoadIndex].bacto_scan = pondViewFarms.bacto_scan;
                                          data.tableDetails[forestPondRoadIndex].bacto_plc = pondViewFarms.bacto_plc;
                                          data.tableDetails[forestPondRoadIndex].scc_penalty = pondViewFarms.scc_penalty;
                                          data.tableDetails[forestPondRoadIndex].bacto_penalty = pondViewFarms.bacto_penalty;
                                          data.tableDetails[forestPondRoadIndex].freezing_penalty = pondViewFarms.freezing_penalty;
                                          data.tableDetails[forestPondRoadIndex].other_solids = pondViewFarms.other_solids;

                                          res();
                                      });
                                  })
                                : new Promise((res, rej) => {
                                      res();
                                  });

                            forestPondAdjustment.then(() => resolve(data));
                        });
                    });
                })
            )
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getAdminDashboardDetails;
