import React from 'react';
import { dataTableSort } from '../../../../utils';

const getColumns = () => [
    {
        title: 'Processor',
        field: 'processor',
    },
    {
        title: 'Category',
        field: 'category',
    },
    {
        title: 'No. of Loads',
        field: 'count',
        type: 'numeric',
        render: (rowData) => {
            return <div>{rowData.count ? parseInt(rowData.count) : '-'}</div>;
        },
        customSort: (a, b) => dataTableSort(a, b, 'count'),
    },
    {
        title: 'Amount (lbs)',
        field: 'amount',
        type: 'numeric',
        render: (rowData) => {
            return <div>{rowData.amount ? parseFloat(rowData.amount).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'}</div>;
        },
        customSort: (a, b) => dataTableSort(a, b, 'amount'),
    },
    {
        title: 'SCC',
        field: 'scc_average',
    },
    {
        title: 'Fat %',
        field: 'fat_average',
        render: (rowData) => {
            return <div>{parseFloat(rowData.fat_average).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>;
        },
    },
    {
        title: 'Fat (lbs)',
        field: 'fat_weight',
        render: (rowData) => {
            return <div>{parseFloat(rowData.fat_weight).toLocaleString('us')}</div>;
        },
    },
    {
        title: 'Protein %',
        field: 'protein_average',
        render: (rowData) => {
            return <div>{parseFloat(rowData.protein_average).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>;
        },
    },
    {
        title: 'Protein (lbs)',
        field: 'protein_weight',
        render: (rowData) => {
            return <div>{parseFloat(rowData.protein_weight).toLocaleString('us')}</div>;
        },
    },
    {
        title: 'OS %',
        field: 'other_solids_average',
        render: (rowData) => {
            return <div>{parseFloat(rowData.other_solids_average).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>;
        },
    },
    {
        title: 'OS (lbs)',
        field: 'other_solids_weight',
        render: (rowData) => {
            return <div>{parseFloat(rowData.other_solids_weight).toLocaleString('us')}</div>;
        },
    },
];

const getData = (data) => data || [];

export default {
    getColumns,
    getData,
};
