import axios from 'axios';
import { getExpressAPIHost } from '../../utils';

const uploadPooledPickups = async (formData, month, year) => {
    try {
        const response = await axios.post(`${getExpressAPIHost()}/pickups/upload-pooled-pickups/${month}/${year}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        return response;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to upload file. Please contact support.');
    }
};

export default uploadPooledPickups;
