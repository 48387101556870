import React, { useState } from 'react';
import { withStyles, Icon, Table, TableBody, TableCell, TableRow, Card, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import SharedFilesDialog from './dialogs/SharedFilesDialog';

const styles = (theme) => ({
    typeIcon: {
        '&.folder:before': { content: "'folder'", color: '#FFB300' },
        '&.document:before': { content: "'insert_drive_file'", color: '#1565C0' },
        '&.spreadsheet:before': { content: "'insert_chart'", color: '#4CAF50' },
        '&.documents:before': { content: "'dehaze'", color: '#1565C0' },
    },
    dialogActions: { marginTop: '2rem' },
    dialogActionButton: { padding: '1rem 2rem' },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
});

const SharedFilesCard = ({ files }) => {
    // eslint-disable-next-line global-require
    const uuidv4 = require('uuid/v4');
    const [showDialog, setShowDialog] = useState(false);
    const [fileName, setFileName] = useState('');

    const onRowClick = (event, selectedFile) => {
        setShowDialog(true);
        setFileName(selectedFile.generated_name);
    };

    const handleClose = () => {
        setShowDialog(false);
        setFileName('');
    };

    return (
        <Card className="w-full rounded-8 shadow-none border-1 h-full">
            <div className="flex items-center justify-between px-16 py-16">
                <Typography className="text-20 font-bold">Shared Files</Typography>
            </div>
            <div className="flex items-center whitespace-no-wrap overflow-hidden py-2 mb-0">
                <Table>
                    <TableBody>
                        {files.length === 0 && (
                            <TableRow key={uuidv4()} className="h-full">
                                <TableCell className="text-center" style={{ fontSize: 12 }}>
                                    No files available
                                </TableCell>
                            </TableRow>
                        )}
                        {files.map((file) => (
                            <TableRow key={uuidv4()} hover selected onClick={(ev) => onRowClick(ev, file)} className="cursor-pointer">
                                <TableCell className="text-center">
                                    <Icon className="text-blue text-12 sm-text-6">insert_drive_file</Icon>
                                </TableCell>
                                <TableCell className="text-left text-12 sm-text-6">{file.name.substring(0, 25)}</TableCell>
                                <TableCell className="text-left text-12 sm:text-6">{moment(file.created_at).format('MMM Do, YYYY')}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
            <div>{showDialog && <SharedFilesDialog file={fileName} closeDialog={() => handleClose()} />}</div>
        </Card>
    );
};

export default withStyles(styles, { withTheme: true })(withRouter(SharedFilesCard));
