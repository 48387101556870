import axios from 'axios';
import { editAdhocAdvanceReportQuery } from './queries';

const editAdhocAdvanceReport = async (id, model) => {
    try {
        const query = editAdhocAdvanceReportQuery(id, model);
        const request = await axios.patch(query.url, { ...query.body });
        return request.data;
    } catch (err) {
        return { error: 'Cannot edit adhoc advance report' };
    }
};

export default editAdhocAdvanceReport;
