import axios from 'axios';
import { getProducersQuery } from './queries';

const getBulkChartsForProducer = async (id) => {
    try {
        const query = getProducersQuery({ ids: [id], fields: ['id', 'bulk_charts'] });
        const request = axios.get(query);
        const response = await request;
        return response?.data?.[0]?.bulk_charts;
    } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getBulkChartsForProducer;
