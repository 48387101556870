import React from 'react';
import TitledPage from './TitledPage';
import { FuseAnimate } from '@fuse';

const AnimatedTitledPage = ({ title, subtitle, children }) => (
    <TitledPage title={title} subtitle={subtitle}>
        <FuseAnimate animation="transition.slideUpIn" delay={200}>
            <div className="flex flex-col md:flex-row sm:p-8 container">
                <div className="flex flex-1 flex-col min-w-0 p-12">{children}</div>
            </div>
        </FuseAnimate>
    </TitledPage>
);

export default AnimatedTitledPage;
