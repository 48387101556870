import Typography from '@material-ui/core/Typography';
import React from 'react';
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        // eslint-disable-next-line react/state-in-constructor
        this.state = { hasError: false };
    }

    componentDidCatch(error, info) {
        // Display fallback UI in case error occurs
        this.setState({ hasError: true });
        // You can also log the error to an error reporting service
        // eslint-disable-next-line no-console
        console.log(info);
    }

    handleClick = ({ history }) => {
        const { region } = this.props;
        history.replace(region === 'BORDEN' ? { pathname: '/dropoffs/' } : { pathname: '/dashboard/' });
        window.location.reload();
    };

    goToHomepage() {
        const { region } = this.props;
        const homepage = region === 'BORDEN' ? 'Go to Dropoffs page' : 'Go to Dashboard';
        return homepage;
    }

    render() {
        const { history } = this.props;
        // eslint-disable-next-line react/destructuring-assignment
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="flex flex-col flex-1 items-center justify-center p-16">
                    <div className="max-w-512 text-center">
                        <div className="m-auto">
                            <img height="300" width="250" src="assets/New Logo/moorice_sad.png" alt="logo" />
                        </div>
                        <Typography variant="h3" style={{ color: 'white' }} className="mb-16">
                            Sorry but we could not find the page you are looking for.
                        </Typography>

                        <Button
                            style={{
                                pointerEvents: 'auto',
                                alignSelf: 'end',
                                backgroundColor: '#00a2f5',
                                padding: '10px',
                                color: '#ffffff',
                            }}
                            variant="contained"
                            size="large"
                            onClick={() => this.handleClick({ history })}
                        >
                            {this.goToHomepage()}
                        </Button>
                    </div>
                </div>
            );
        }
        // eslint-disable-next-line react/destructuring-assignment
        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
