import authRoles from 'app/auth/authRoles';
import { FuseLoadable } from '@fuse';

export const LabChartConfig = {
    auth: authRoles.hauling,
    routes: [
        {
            exact: true,
            path: '/lab-charts',
            component: FuseLoadable({ loader: () => import('./LabChartApp') }),
        },
    ],
};

export default [LabChartConfig];
