import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import getProcessorFmmoMonthlyTotals from '../../repository/getProcessorFmmoMonthlyTotals';
import { Grid, Box } from '@material-ui/core';
import { getFmmoName, numberFormat, sumAllProperties } from '../../../utils';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { formatProcessorTotalsValue } from './utils';

const insertBottomBorder = { borderBottomWidth: '1px', borderStyle: 'solid' };
const insertTopBorder = { borderTopWidth: '1px', borderTopStyle: 'solid' };
const insertTab = { display: 'inline-block', marginLeft: '20px' };
const theme = createTheme({
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    fontSize: '1.25rem',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    marginLeft: '6%',
                    marginBottom: '6%',
                },
            },
        },
    },
});

const seperatePoolingDataAndCalculateTotals = ({ processorTotalsData }) => {
    const pooledProcessors = [];
    const nonPooledProcessors = [];
    const otherPooledProcessors = [];
    let pooledTotals = {};
    let nonPooledTotals = {};
    let otherPooledTotals = {};
    let grandTotals = {};

    if (processorTotalsData) {
        processorTotalsData.forEach((item) => {
            if (item.isPooled) {
                if (item.fmmo !== item.processor_pooling_fmmo) {
                    otherPooledProcessors.push(item);
                } else {
                    pooledProcessors.push(item);
                }
            } else {
                nonPooledProcessors.push(item);
            }
        });
        const props = ['total_amount', 'total_bf_amount', 'total_protein_amount', 'total_other_solids_amount', 'somatic_fmmo_value'];
        pooledTotals = sumAllProperties(pooledProcessors, props);
        nonPooledTotals = sumAllProperties(nonPooledProcessors, props);
        otherPooledTotals = sumAllProperties(otherPooledProcessors, props);
        grandTotals = sumAllProperties(processorTotalsData, props);
    }
    return {
        pooledProcessors,
        nonPooledProcessors,
        pooledTotals,
        nonPooledTotals,
        otherPooledProcessors,
        otherPooledTotals,
        grandTotals,
    };
};

const ProcessorFmmoTotalsTable = ({ processorFmmoMonthlyTotalsContent, selectedFmmo }) => {
    return (
        <ThemeProvider theme={theme}>
            <TableContainer component={Paper}>
                <Box ml={'6%'} m={'6%'} mb={2} style={{ flexDirection: 'column' }}>
                    <div>FMMO #: {selectedFmmo}</div>
                    <div>FMMO Name: {getFmmoName(selectedFmmo)}</div>
                </Box>
                <Table
                    sx={{
                        maxWidth: '85%',
                        [`& .${tableCellClasses.root}`]: {
                            borderBottom: 'none',
                        },
                    }}
                    aria-label="spanning table"
                >
                    <TableHead>
                        <TableRow>
                            <TableCell align="right" rowSpan={3}></TableCell>
                            <TableCell align="right" style={insertBottomBorder}>
                                Total (Lbs)
                            </TableCell>
                            <TableCell align="right" style={insertBottomBorder}>
                                Butterfat (Lbs)
                            </TableCell>
                            <TableCell align="right" style={insertBottomBorder}>
                                Protein (Lbs)
                            </TableCell>
                            <TableCell align="right" style={insertBottomBorder}>
                                Other Solids (Lbs)
                            </TableCell>
                            <TableCell align="right" style={insertBottomBorder}>
                                Somatic Value ($)
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <ProcessorFmmoMonthlyValuesSubTable subTitle={'Deliveries to Pool Plants:'} rows={processorFmmoMonthlyTotalsContent.pooledProcessors} selectedFmmo={selectedFmmo} />
                        <ProcessorTotalsSubTable typeOfTotal={'Total Deliveries to Pool Plants:'} processorTotalsContent={processorFmmoMonthlyTotalsContent.pooledTotals} selectedFmmo={selectedFmmo} />
                        <ProcessorFmmoMonthlyValuesSubTable subTitle={'Deliveries to Non Pool Plants:'} rows={processorFmmoMonthlyTotalsContent.nonPooledProcessors} selectedFmmo={selectedFmmo} />
                        <ProcessorTotalsSubTable typeOfTotal={'Total Deliveries to Non Pool Plants:'} processorTotalsContent={processorFmmoMonthlyTotalsContent.nonPooledTotals} selectedFmmo={selectedFmmo} />
                        <ProcessorFmmoMonthlyValuesSubTable subTitle={'Deliveries to Other Pool Plants:'} rows={processorFmmoMonthlyTotalsContent.otherPooledProcessors} selectedFmmo={selectedFmmo} />
                        <ProcessorTotalsSubTable typeOfTotal={'Total Deliveries to Other Pool Plants:'} processorTotalsContent={processorFmmoMonthlyTotalsContent.otherPooledTotals} selectedFmmo={selectedFmmo} />
                        <TableRow></TableRow>
                        <ProcessorTotalsSubTable typeOfTotal={'Grand Total for FMMO #: ' + `${selectedFmmo}`} processorTotalsContent={processorFmmoMonthlyTotalsContent.grandTotals} selectedFmmo={selectedFmmo} />
                    </TableBody>
                </Table>
            </TableContainer>
        </ThemeProvider>
    );
};

const ProcessorFmmoMonthlyValuesSubTable = ({ subTitle, rows, selectedFmmo }) => {
    return (
        <>
            <TableRow>
                <TableCell sx={{ fontWeight: 600 }} align="left">
                    {subTitle}
                </TableCell>
            </TableRow>
            {rows.map((row) => (
                <>
                    <TableRow>
                        <TableCell align="left">
                            <span style={insertTab}></span>
                            {row.processor.name}
                        </TableCell>

                        <TableCell align="right">{formatProcessorTotalsValue(row?.total_amount, 2)}</TableCell>
                        <TableCell align="right">{formatProcessorTotalsValue(row?.total_bf_amount, 2)}</TableCell>
                        <TableCell align="right">{formatProcessorTotalsValue(row?.total_protein_amount, 2)}</TableCell>
                        <TableCell align="right">{formatProcessorTotalsValue(row?.total_other_solids_amount, 2)}</TableCell>
                        <TableCell align="right">{formatProcessorTotalsValue(row?.somatic_fmmo_value, 2)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left">
                            <span style={insertTab}></span>
                            <span style={insertTab}></span>
                            {row.processor.name} (Member Producers)
                        </TableCell>

                        <TableCell align="right">{formatProcessorTotalsValue(row?.member_producer_amounts?.total_amount)}</TableCell>
                        <TableCell align="right">{formatProcessorTotalsValue(row?.member_producer_amounts?.total_bf_amount)}</TableCell>
                        <TableCell align="right">{formatProcessorTotalsValue(row?.member_producer_amounts?.total_protein_amount)}</TableCell>
                        <TableCell align="right">{formatProcessorTotalsValue(row?.member_producer_amounts?.total_other_solids_amount)}</TableCell>
                        <TableCell align="right">{formatProcessorTotalsValue(row?.member_producer_amounts?.somatic_fmmo_value)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="left">
                            <span style={insertTab}></span>
                            <span style={insertTab}></span>
                            {row.processor.name} (Outside Milk & Payroll Paid)
                        </TableCell>

                        <TableCell align="right">{formatProcessorTotalsValue(row?.outside_milk_payroll_paid_amounts?.total_amount)}</TableCell>
                        <TableCell align="right">{formatProcessorTotalsValue(row?.outside_milk_payroll_paid_amounts?.total_bf_amount)}</TableCell>
                        <TableCell align="right">{formatProcessorTotalsValue(row?.outside_milk_payroll_paid_amounts?.total_protein_amount)}</TableCell>
                        <TableCell align="right">{formatProcessorTotalsValue(row?.outside_milk_payroll_paid_amounts?.total_other_solids_amount)}</TableCell>
                        <TableCell align="right">{formatProcessorTotalsValue(row?.outside_milk_payroll_paid_amounts?.somatic_fmmo_value)}</TableCell>
                    </TableRow>
                </>
            ))}
        </>
    );
};

const ProcessorTotalsSubTable = ({ typeOfTotal, processorTotalsContent, selectedFmmo }) => {
    let totalsBorderStyle = insertTopBorder;
    if (typeOfTotal.includes('Grand')) {
        totalsBorderStyle = insertBottomBorder;
    }
    return (
        <>
            <TableRow size="small">
                <TableCell sx={{ fontWeight: 600 }} align="left">
                    {typeOfTotal}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }} align="right" style={totalsBorderStyle}>
                    {formatProcessorTotalsValue(processorTotalsContent?.total_amount)}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }} align="right" style={totalsBorderStyle}>
                    {formatProcessorTotalsValue(processorTotalsContent?.total_bf_amount)}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }} align="right" style={totalsBorderStyle}>
                    {formatProcessorTotalsValue(processorTotalsContent?.total_protein_amount)}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }} align="right" style={totalsBorderStyle}>
                    {formatProcessorTotalsValue(processorTotalsContent?.total_other_solids_amount)}
                </TableCell>
                <TableCell sx={{ fontWeight: 600 }} align="right" style={totalsBorderStyle}>
                    {formatProcessorTotalsValue(processorTotalsContent?.somatic_fmmo_value)}
                </TableCell>
            </TableRow>
        </>
    );
};

const ProcessorFmmoMonthlyTotalsTable = ({ showTable, startDate, endDate, selectedFmmo }) => {
    const [processorFmmoMonthlyTotalsContent, setProcessorFmmoMonthlyTotalsContent] = useState('');

    useEffect(() => {
        const fetchProcessorFmmoMonthlyTotals = async () => {
            const processorTotalsData = await getProcessorFmmoMonthlyTotals({ startDate, endDate, selectedFmmo });
            setProcessorFmmoMonthlyTotalsContent(seperatePoolingDataAndCalculateTotals({ processorTotalsData }));
        };
        if (selectedFmmo && startDate) {
            fetchProcessorFmmoMonthlyTotals();
        }
    }, [selectedFmmo, startDate]);

    return (
        <>
            {showTable && processorFmmoMonthlyTotalsContent ? (
                <ProcessorFmmoTotalsTable processorFmmoMonthlyTotalsContent={processorFmmoMonthlyTotalsContent} selectedFmmo={selectedFmmo} />
            ) : (
                <Grid container spacing={0} alignItems="center" justify="center" style={{ minHeight: '50vh' }}>
                    Please select a FMMO.
                </Grid>
            )}
        </>
    );
};

export default ProcessorFmmoMonthlyTotalsTable;
