import axios from 'axios';
import { getUnreadSharedFilesCountQuery } from './queries';

const getSharedUnreadCount = (id) =>
    new Promise((resolve, reject) => {
        const query = getUnreadSharedFilesCountQuery({ id });
        const request = axios.get(query);

        request
            .then((response) => {
                resolve({ success: true, unreadCount: response.data.count });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getSharedUnreadCount;
