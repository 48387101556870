const transformLabReport = (labReport, producer) => ({
    id: labReport.id,
    producer: {
        name: producer.name,
        tank_number: producer.tank,
        latitude: producer.latitude,
        longitude: producer.longitude,
        CMTNumber: producer.CMTNumber,
        id: producer.id,
    },
    protein: labReport.protein ? labReport.protein : 0,
    bacteria_PLC: labReport.bacteria_PLC ? labReport.bacteria_PLC.toFixed(2) : 'N/A',
    temperature: labReport.temperature ? labReport.temperature.toFixed(3) : 'N/A',
    date: labReport.date,
    tank: labReport.tank,
    fat: labReport.fat ? labReport.fat : 0,
    LOS: labReport.LOS ? labReport.LOS : 0,
    LPC: labReport.lab_pasteurization_count ? labReport.lab_pasteurization_count : 0,
    CC: labReport.coliform_count ? labReport.coliform_count : 0,
    SPC: labReport.standard_plate_count ? labReport.standard_plate_count : 0,
    pickup_id: labReport.pickup_id,
    bacto_scan: labReport.bacto_scan ? labReport.bacto_scan.toFixed(2) : 'N/A',
    somatic_cell_count: labReport.somatic_cell_count ? labReport.somatic_cell_count.toFixed(2) : 'N/A',
    bmcc: labReport.bulk_milk_cell_count ? labReport.bulk_milk_cell_count.toFixed(2) : 'N/A',
    thermo_plate_count: labReport.thermo_plate_count ? labReport.thermo_plate_count : 0,
    colostrum_percentage: labReport.colostrum_percentage ? labReport.colostrum_percentage.toFixed(2) : 'N/A',
    density: labReport.density,
    created_at: labReport.created_at,
    updated_at: labReport.updated_at,
    deleted_at: labReport.deleted_at,
    is_outlier: labReport.is_outlier,
});

export default transformLabReport;
