const MM_COLORS = {
    MuiSecondaryTransparent: '#42a5f539',
    altGrey: '#f2f2f2',
    defaultBackground: '#F1F3F8',
    successGreen: (transparency = 1) => `rgba(64, 180, 129, ${transparency})`,
    warningOrange: (transparency = 1) => `rgba(255, 163, 81, ${transparency})`,
    infoBlue: (transparency = 1) => `rgba(0, 200, 255, ${transparency})`,
    errorRed: (transparency = 1) => `rgba(226, 99, 101, ${transparency})`,
    tertiaryDark: (transparency = 1) => `rgba(0, 131, 148, ${transparency})`,
};

export default MM_COLORS;
