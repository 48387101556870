import axios from 'axios';
import { getSharedFileByIdQuery } from './queries';

const getSharedFileById = async (fileId) => {
    const query = getSharedFileByIdQuery({ fileId });
    const resp = await axios.get(query);
    return resp.data;
};

export default getSharedFileById;
