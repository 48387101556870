import axios from 'axios';
import { getInprogressDropoffQuery } from './inprogressDropoff.queries';

const getInprogressDropoffs = async (params) => {
    try {
        const query = getInprogressDropoffQuery(params);
        // TODO better error handling
        // eslint-disable-next-line no-console
        const response = await axios.get(query).catch((err) => console.log(err));
        return response;
    } catch (error) {
        throw new Error('Unable to retrieve inprogress dropoffs');
    }
};

export default getInprogressDropoffs;
