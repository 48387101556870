import * as Actions from '../../actions';

const initialState = { status: 'loading', error: null };

const splashScreenReducer = (state = initialState, action) => {
    switch (action.type) {
        case Actions.SET_LOADING:
            return { status: 'loading', error: null };
        case Actions.SET_ERROR:
            return { status: 'error', error: action.payload };
        default:
            return state;
    }
};

export default splashScreenReducer;
