import { CircularProgress } from '@material-ui/core';
import React from 'react';

const SaveProgress = () => (
    <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, borderRadius: 4, background: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <CircularProgress size={20} color="secondary" disableShrink />
    </div>
);

export default SaveProgress;
