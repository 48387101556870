import _ from 'lodash';
import getPickupsByDropoffId from './getPickupsByDropoffId';
import { combinePickups } from '../../../utils';

const getPickupsWithDropoffId = (pickupsByDropoffId) => {
    const dropoffIds = _.keys(pickupsByDropoffId);
    // eslint-disable-next-line no-console
    const pickups = _.reduce(
        dropoffIds,
        (acc, val) => {
            const newPickups = _.map(pickupsByDropoffId[val], (pickup) => ({ ...pickup, dropoff_id: val }));
            return [...acc, ...newPickups];
        },
        []
    );
    return pickups;
};

const getPickupWithClosestVolume = (volume, potentialPickups) => {
    let closestPickup = null;
    let minDist = null;
    _.forEach(potentialPickups, (pickup) => {
        if (!closestPickup) {
            closestPickup = pickup;
            minDist = Math.abs(pickup.volume - volume);
        } else {
            const dist = Math.abs(pickup.volume - volume);
            if (dist < minDist) {
                closestPickup = pickup;
                minDist = dist;
            }
        }
    });
    return closestPickup;
};

const getPickupIdToDropoffIdMap = (pickups, combinedPickupsWithDropoffId) =>
    _.reduce(
        pickups,
        (acc, val) => {
            const potentialPickups = _.filter(combinedPickupsWithDropoffId, (pickup) => pickup.created_at === val.created_at && pickup.producer_id === val.producer_id && pickup.tank_number === val.tank_number);
            if (potentialPickups.length === 0) return acc;
            const closestPickup = getPickupWithClosestVolume(val.volume, potentialPickups);
            _.remove(combinedPickupsWithDropoffId, closestPickup);
            acc[val.id] = closestPickup.dropoff_id;
            return acc;
        },
        {}
    );

const transformDropoffIdToProcessorName = (pickupIdToDropoffIdMap, dropoffs, processors) =>
    _.mapValues(pickupIdToDropoffIdMap, (dropoffId) => {
        const dropoff = _.find(dropoffs, { id: dropoffId });
        if (!dropoff) return '';
        const processor = _.find(processors, { id: dropoff.processor_id });
        if (!processor) return '';
        return processor.name;
    });

const getProcessorsByPickupId = (pickups, dropoffs, processors) => {
    const combinedPickups = combinePickups(pickups);
    const combinedPickupsByDropoffId = getPickupsByDropoffId(dropoffs, combinedPickups);
    const combinedPickupsWithDropoffId = getPickupsWithDropoffId(combinedPickupsByDropoffId);
    const pickupIdToDropoffId = getPickupIdToDropoffIdMap(pickups, combinedPickupsWithDropoffId);
    return transformDropoffIdToProcessorName(pickupIdToDropoffId, dropoffs, processors);
};

export default getProcessorsByPickupId;
