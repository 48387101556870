import addUser from './addUser';

const addAdmin = (model) =>
    addUser({
        ...model,
        role: 'admin',
        subuser: false,
    });

export default addAdmin;
