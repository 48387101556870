import axios from 'axios';
import { createCloseDayQuery } from './queries';
import moment from 'moment-timezone';

const putCloseDayDetails = (date, is_locked) =>
    new Promise((resolve, reject) => {
        const query = createCloseDayQuery({ date, is_locked });
        const request = axios.put(query.url, { ...query.body });
        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default putCloseDayDetails;
