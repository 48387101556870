import axios from 'axios';
import { getWashFacilitiesQuery } from './queries';

const getWashFacilityDetails = async () => {
    try {
        const query = getWashFacilitiesQuery({ filterDeleted: true });
        const washFacilityResponse = await axios.get(query);
        const washFacilities = washFacilityResponse.data.filter((i) => !i.deleted_at);
        return { washFacilities };
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        throw new Error('Unable to get wash facilities');
    }
};

export default getWashFacilityDetails;
