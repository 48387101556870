import axios from 'axios';
import _ from 'lodash';
import { getPickupsQuery, getDropoffsQuery } from './queries';

const getRemainingVolume = (sessionID) =>
    new Promise((resolve, reject) => {
        const pickupsQuery = getPickupsQuery({ routeSessionIds: [sessionID], filterDeleted: true });
        const pickupRequest = axios.get(pickupsQuery);
        const dropoffQuery = getDropoffsQuery({ routeSessionIds: [sessionID], filterDeleted: true });
        const dropoffRequest = axios.get(dropoffQuery);

        const requests = [pickupRequest, dropoffRequest];

        axios
            .all(requests)
            .then(
                axios.spread((...responses) => {
                    const [pickupRespons, dropoffResponse] = responses;
                    const pickups = pickupRespons.data;
                    const dropoffs = dropoffResponse.data;
                    const frontPickups = _.filter(pickups, (pickup) => pickup.compartment === 'Front');
                    const frontDropoffs = _.filter(dropoffs, (dropoff) => dropoff.compartment === 'Front');
                    const middlePickups = _.filter(pickups, (pickup) => pickup.compartment === 'Middle');
                    const middleDropoffs = _.filter(dropoffs, (dropoff) => dropoff.compartment === 'Middle');
                    const backPickups = _.filter(pickups, (pickup) => pickup.compartment === 'Back');
                    const backDropoffs = _.filter(dropoffs, (dropoff) => dropoff.compartment === 'Back');
                    const splitPickups = _.filter(pickups, (pickup) => pickup.compartment === 'Split');
                    const splitDropoffs = _.filter(dropoffs, (dropoff) => dropoff.compartment === 'Split');
                    const frontVolume = _.sumBy(frontPickups, 'volume') + _.sumBy(splitPickups, 'volume_a') - (_.sumBy(frontDropoffs, 'volume') + _.sumBy(splitDropoffs, 'volume_a'));
                    const middleVolume = _.sumBy(middlePickups, 'volume') + _.sumBy(splitPickups, 'volume_b') - (_.sumBy(middleDropoffs, 'volume') + _.sumBy(splitDropoffs, 'volume_b'));
                    const backVolume = _.sumBy(backPickups, 'volume') + _.sumBy(splitPickups, 'volume_c') - (_.sumBy(backDropoffs, 'volume') + _.sumBy(splitDropoffs, 'volume_c'));
                    resolve({
                        front: frontVolume,
                        middle: middleVolume,
                        back: backVolume,
                    });
                })
            )
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error(errors.toString()));
            });
    });

export default getRemainingVolume;
