import axios from 'axios';
import { createProcessedFluidAdjustmentsQuery } from './queries';

const addProcessedFluidAdjustments = async (model) => {
    const query = createProcessedFluidAdjustmentsQuery({ model });

    try {
        const adjustmentsDetails = await axios.post(query.url, { ...query.body });
        const adjustments = adjustmentsDetails?.data;

        return { data: adjustments };
    } catch (e) {
        return { error: e.message };
    }
};

export default addProcessedFluidAdjustments;
