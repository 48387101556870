import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';

const ReactHookFormInput = ({ name, label, rules, fieldOpts, placeholder, required, type, ...rest }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const error = _.get(errors, name);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, onChange }) => (
                <TextField
                    type={type}
                    placeholder={placeholder}
                    onChange={(_event, item) => {
                        onChange(item);
                    }}
                    inputProps={{ ...rest?.inputProps, 'aria-label': name }}
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '100%', margin: '8px 0' }}
                    {...field}
                    {...fieldOpts}
                    error={!!error}
                    required={required}
                    label={label}
                    helperText={error?.message || ''}
                    {...rest}
                />
            )}
        />
    );
};

export default ReactHookFormInput;
