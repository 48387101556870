import CACIQUE from './cacique-config';
import CDI from './cdi-config';
import LEGACY from './legacy-config';
import MMPA from './mmpa-config';
import UDA from './uda-config';
import PRAIRIE from './prairie-config';
import TCJ from './tcj-config';
import UNC from './unc-config';
import RF from './rf-config';
import NL from './nl-config';
import PEI from './pei-config';
import USDEMO from './us-demo-config';
import DEFAULT from './default-config';
import { getUserPermissions } from './UserPermissions/user-based-permissions';
import { getUser } from '../../../../utils';

const Permissions = {
    CACIQUE,
    CDI,
    LEGACY,
    MMPA,
    PRAIRIE,
    'TCJ-LIBERTY': TCJ,
    'TCJ-WHITEEAGLE': TCJ,
    'TCJ-GPDA': TCJ,
    'TCJ-ERIE': TCJ,
    'TCJ-NEBRASKA': TCJ,
    'TCJ-KYTN': TCJ,
    UDA,
    UNC,
    RF,
    NL,
    PEI,
    USDEMO,
    DEMO: USDEMO,
};

const getPermissions = (userRole) => {
    const user = getUser();
    const regionConfig = Permissions[user.region] ?? DEFAULT;
    const roleConfig = regionConfig[userRole];
    const userPermissions = getUserPermissions(userRole);
    const requiredPermissions = (roleConfig.required ?? []).reduce((acc, p) => acc.add(p.value), new Set());
    return {
        permissionGroups: [...roleConfig.permissions, ...userPermissions],
        requiredPermissions,
        // force-wrap
    };
};

export default getPermissions;
