import React, { Component } from 'react';
import { matchRoutes } from 'react-router-config';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import AppContext from 'app/AppContext';
import Region from '../../../app/constants/region';

export const getInitialRoute = ({ region, role, permissions, subuser }) => {
    if (!region || !role || !permissions) return '/logout';

    if (['producer_business'].includes(role)) {
        return '/monthly-pay-reports';
    } else if ([Region.CDI].includes(region) && ['admin'].includes(role)) {
        return '/route-sessions';
    } else if ([Region.CDI].includes(region) && ['producer'].includes(role) && (!subuser || (subuser && permissions.includes('ViewMemberCenter')))) {
        return '/member-center';
    } else if ([Region.CDI, Region.UDA].includes(region) && ['producer'].includes(role) && permissions.includes('ListPickupsAndLabs')) {
        return '/pickups-and-labs';
    } else if (permissions.includes('GetDashboardDetails')) {
        return '/dashboard';
    } else if (permissions.includes('ListDropoffs')) {
        return '/dropoffs';
    } else if (permissions.includes('ListLabReports')) {
        return '/lab-reports';
    } else if (permissions.includes('ListPickups')) {
        return '/pickups';
    } else if (permissions.includes('ListMonthlyReports')) {
        return '/monthly-reports';
    } else if (permissions.includes('ListBactoAlert')) {
        return '/penalty-status';
    } else if (permissions.includes('ListRouteSessions')) {
        return '/route-sessions';
    } else if (permissions.includes('GetMilkScheduleDetails')) {
        return '/milk-schedule';
    } else if (permissions.includes('GetFluidScheduleDetails')) {
        return '/fluid-schedule';
    } else if (permissions.includes('ListRoutes')) {
        return '/list-routes';
    } else if (permissions.includes('ListAdmins')) {
        return '/list-admins';
    } else if (permissions.includes('ListProducers')) {
        return '/list-producers';
    } else if (permissions.includes('ListDrivers')) {
        return '/list-drivers';
    } else if (permissions.includes('ListProcessors')) {
        return '/list-processors';
    } else if (permissions.includes('ListHaulingCompanies')) {
        return '/list-hauling-companies';
    } else if (permissions.includes('ListTrailers')) {
        return '/list-trailers';
    } else if (permissions.includes('ListCIPWash')) {
        return '/wash-facilities';
    } else if (permissions.includes('ListBaseTransfers')) {
        return '/base-transfers';
    } else if (permissions.includes('ListMonthlyReports')) {
        return '/monthly-pay-reports';
    } else if (permissions.includes('ListSharedFiles')) {
        return '/shared-files/received';
    } else if (permissions.includes('ListPickupsAndLabs')) {
        return '/pickups-and-labs';
    } else if (permissions.includes('ManageRapidTest')) {
        return '/awaiting-dropoff-rapid-test';
    } else {
        return '/logout';
    }
};

class FuseAuthorization extends Component {
    constructor(props, context) {
        super(props);
        const { routes } = context;
        this.state = {
            accessGranted: false,
            routes,
        };
    }

    componentDidMount() {
        if (!this.state.accessGranted) {
            this.redirectRoute(this.props);
        }
    }

    componentDidUpdate() {
        if (!this.state.accessGranted) {
            this.redirectRoute(this.props);
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { location, user } = props;
        const { pathname } = location;

        const matched = matchRoutes(state.routes, pathname)[0];

        const accessGranted = matched && matched.route.auth && matched.route.auth.length > 0 ? matched.route.auth.includes(user.role) : true;

        return {
            accessGranted,
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextState.accessGranted !== this.state.accessGranted;
    }

    redirectRoute(props) {
        const { location, user, history } = props;
        const { pathname, state } = location;
        /*
        User is guest
        Redirect to Login Page
        */
        if (user.role === 'guest') {
            history.push({
                pathname: '/login',
                state: { redirectUrl: pathname },
            });
        } else {
            /*
        User is member
        User must be on unAuthorized page or just logged in
        Redirect to dashboard or redirectUrl
        */
            let redirectUrl = state && state.redirectUrl ? state.redirectUrl : getInitialRoute(user.data);

            history.push({
                pathname: redirectUrl,
            });
        }
    }

    render() {
        const { children } = this.props;
        const { accessGranted } = this.state;
        // console.info('Fuse Authorization rendered', accessGranted);
        return accessGranted ? <React.Fragment>{children}</React.Fragment> : null;
    }
}

function mapStateToProps({ persisted }) {
    return {
        user: persisted.auth.user,
    };
}

FuseAuthorization.contextType = AppContext;

export default withRouter(connect(mapStateToProps)(FuseAuthorization));
