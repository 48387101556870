import React from 'react';
import { Polyline } from '@react-google-maps/api';

const MapPolyline = ({ index, shape, geodesic = true, strokeColor = '#00A2F5', strokeWeight = 2, handlePolylineLoad }) => {
    return (
        <Polyline
            key={index}
            options={{ geodesic, strokeColor, strokeWeight }}
            path={shape.coordinates}
            onLoad={(line) => handlePolylineLoad(line, shape)}
            // Force Line Wrap Comment
        />
    );
};

export default React.memo(MapPolyline);
