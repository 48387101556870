import axios from 'axios';
import { getLoansQuery } from '../queries';
import parseError from '../errors/utils';

const getLoanById = async (id) => {
    try {
        const loans = await axios.get(getLoansQuery({ id }));
        return { loan: loans?.data?.[0] };
    } catch (error) {
        return { error: parseError({ error }) };
    }
};

export default getLoanById;
