import axios from 'axios';
import { getExpressAPIHost } from '../../utils';

const clearEFTPayments = async (issueDate, clearedDate) => {
    try {
        const data = {
            issue_date: issueDate,
            cleared_date: clearedDate,
        };
        const response = await axios.post(`${getExpressAPIHost()}/payment-status/clear-eft-payments/`, data);
        return response;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request. Please contact support.');
    }
};

export default clearEFTPayments;
