import { getExpressAPIHost } from '../../../../utils';
import { convertParamsToAQPQueryString } from './utils';

const buildExpressQuery = (endpoint, { params, body }) => {
    if (body) {
        return {
            url: getExpressAPIHost() + endpoint,
            body,
        };
    }
    return params && Object.keys(params).length !== 0 ? `${getExpressAPIHost() + endpoint}?${convertParamsToAQPQueryString(params)}` : `${getExpressAPIHost() + endpoint}`;
};

export default buildExpressQuery;
