import axios from 'axios';
import { editHaulerAssignmentQuery } from './queries';

const editHaulerAssignment = async (model, key) => {
    try {
        const query = editHaulerAssignmentQuery({ model, key });
        const request = axios.put(query.url, { ...query.body });
        const { data } = await request;
        return { data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to edit Hauler Assignment');
    }
};
export default editHaulerAssignment;
