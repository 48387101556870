import React, { useContext } from 'react';
import { Grid } from '@mui/material';
import { isCircleGeofence, isPolygonGeofence } from '../maps/GeofenceManager/GeofenceManager.helpers';
import { MapContext } from '../maps/MapContext';
import LatLngInput from './LatLngInput';

const LatLngDisplay = ({ center, canEdit, handleChange }) => {
    const { fences, selectedIndex, map } = useContext(MapContext);
    const canInput = canEdit && !isPolygonGeofence(fences[selectedIndex]);

    const panToCoordinates = (coord, event) => {
        const newCoordinates = { ...center };
        newCoordinates[coord] = Number(event.target.value);
        if (isCircleGeofence(fences[selectedIndex])) {
            fences[selectedIndex].instance.setCenter(newCoordinates);
            if (handleChange) handleChange('radialGeofence', selectedIndex);
        }
        map.panTo(newCoordinates);
    };

    return (
        <>
            <Grid item xs={6}>
                <LatLngInput type="latitude" fullWidth disabled={!canInput} onChange={(event) => panToCoordinates('lat', event)} value={center?.lat ?? ''} />
            </Grid>
            <Grid item xs={6}>
                <LatLngInput type="longitude" fullWidth disabled={!canInput} onChange={(event) => panToCoordinates('lng', event)} value={center?.lng ?? ''} />
            </Grid>
        </>
    );
};

export default LatLngDisplay;
