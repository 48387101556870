import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import useReactHookFormStyles from '../../../../custom-components/form-components/ReactHookForm.styles';
import { isNonEmptyArray } from '../../../../../utils';

const PremiumProgramMultiselect = ({ name, labelKey, optionIdKeys, keysToRemove, formatLabel, loading, props: { label, rules, options, variant, disabled, defaultValue, required, optionLabel, chipLimit } }) => {
    const {
        control,
        formState: { errors },
        getValues,
        updateSelectedValues,
    } = useFormContext();

    const classes = useReactHookFormStyles();

    const getId = (option) => {
        if (isNonEmptyArray(optionIdKeys)) {
            return optionIdKeys.reduce((acc, curr) => {
                return acc + option[curr];
            }, '');
        }
        return option.id || option._id;
    };

    useEffect(() => {
        const value = getValues(name);
        // Update context with full object value when default value exists and/or options change
        if (value && updateSelectedValues) {
            updateSelectedValues(
                name,
                options?.find((opt) => getId(opt) === value)
            );
        }
    }, [options]);

    const parseLabel = (option, optionList) => {
        const foundOption = typeof option === 'object' ? option : optionList?.find((opt) => getId(opt) === option);

        const values = optionLabel.keys.map((val) => {
            const splitKey = val.split('.');
            return splitKey.reduce((acc, curr) => acc?.[curr], foundOption);
        });
        return values.join(optionLabel.connector);
    };

    const removeKeys = (keys, object) => {
        const newObject = { ...object };
        keys.forEach((key) => {
            delete newObject[key];
        });
        return newObject;
    };

    const getOptions = isNonEmptyArray(keysToRemove)
        ? options?.map((opt) => {
              return removeKeys(keysToRemove, opt);
          })
        : options;

    const getOptionLabel = (option) => {
        if (!option) {
            return '';
        }
        const key = labelKey || 'name';
        if (isNonEmptyArray(optionIdKeys)) {
            const foundOption = options?.find((opt) => getId(opt) === getId(option));
            return foundOption?.[key] || '';
        }
        if (formatLabel) {
            return formatLabel(option, options);
        }
        if (optionLabel) {
            return parseLabel(option, options);
        }
        if (option[key]) {
            return option[key];
        }
        const foundOption = options?.find((opt) => getId(opt) === option);
        return foundOption?.[key] || '';
    };

    const renderInput = (props) => (
        <TextField
            {...props}
            label={label}
            required={required}
            variant={variant || 'outlined'}
            error={errors[name]}
            helperText={errors[name]?.message}
            // wrap formatting
        />
    );

    const renderTags = (val, getTagProps) => {
        const numTags = val.length;
        return (
            <>
                {(chipLimit ? val.slice(0, chipLimit) : val).map((option, index) => (
                    <Chip className={classes.root} margin="normal" {...getTagProps({ index })} label={getOptionLabel(option)} />
                ))}

                {numTags > chipLimit && ` +${numTags - chipLimit}`}
            </>
        );
    };

    if (loading) {
        return (
            <TextField
                value="Loading options"
                disabled
                label={label}
                variant={variant || 'outlined'}
                className={classes.textField}
                // wrap formatting
            />
        );
    }

    return (
        <FormControl className={classes.formController}>
            <Controller
                name={name}
                control={control}
                rules={rules}
                defaultValue={defaultValue}
                render={({ field: { onChange, value, ...rest } }) => (
                    <Autocomplete
                        value={value || []}
                        onChange={(e, data) => onChange(data)}
                        options={getOptions}
                        disabled={disabled}
                        chipLimit={chipLimit}
                        renderInput={renderInput}
                        renderTags={renderTags}
                        getOptionLabel={getOptionLabel}
                        getOptionSelected={(opt, val) => {
                            const valId = getId(val) || val;
                            const optId = getId(opt) || opt;
                            return valId === optId;
                        }}
                        multiple
                        {...rest}
                        // wrap formatting
                    />
                )}
            />
        </FormControl>
    );
};

export default PremiumProgramMultiselect;
