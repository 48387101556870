import authRoles from '../../../auth/authRoles';
import { getActiveInprogressDropoffs } from '.';

const getReceivingIPDOs = async ({ id, role, subuser, processor_id, hauling_id, dateRange }) => {
    if (authRoles.admin.includes(role)) {
        const response = await getActiveInprogressDropoffs({ filterDeleted: true, dateRange });

        return response;
    }

    if (authRoles.processor.includes(role)) {
        const processorId = subuser ? processor_id : id;
        const response = await getActiveInprogressDropoffs({ processorId, filterDeleted: true, dateRange });

        return response;
    }

    if (authRoles.hauling.includes(role)) {
        const haulingId = subuser ? hauling_id : id;
        const response = await getActiveInprogressDropoffs({ haulingId, filterDeleted: true, dateRange });

        return response;
    }
};

export default getReceivingIPDOs;
