import React, { Component } from 'react';
import { withStyles, Card, CardContent, Typography } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
/* eslint-disable-next-line */
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FuseAnimate } from '@fuse';
import JWTRegisterTab from './JWTRegisterTab';

const styles = (theme) => ({ root: { background: `radial-gradient(${darken(theme.palette.primary.dark, 0.5)} 0%, ${theme.palette.primary.dark} 80%)`, color: theme.palette.primary.contrastText } });

class RegisterPage extends Component {
    state = {};

    render() {
        const { classes } = this.props;

        return (
            <div className={classNames(classes.root, 'flex flex-col flex-auto flex-no-shrink items-center justify-center p-32')}>
                <div className="flex flex-col items-center justify-center w-full">
                    <FuseAnimate animation="transition.expandIn">
                        <Card className="w-full max-w-384">
                            <CardContent className="flex flex-col items-center justify-center p-32">
                                <img className="w-228 m-32" src="assets/New Logo/Digital (RGB)/Full Colour/Horizontal/MM Logo - Horizontal (Digital, Jpg).jpg" alt="logo" />

                                <Typography variant="h6" className="mt-16 mb-32">
                                    CREATE AN ACCOUNT
                                </Typography>

                                <JWTRegisterTab />

                                <div className="flex flex-col items-center justify-center pt-32 pb-24">
                                    <span className="font-medium text-black">Already have an account?</span>
                                    <Link className="font-medium" to="/login">
                                        Login
                                    </Link>
                                </div>
                            </CardContent>
                        </Card>
                    </FuseAnimate>
                </div>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(RegisterPage);
