import axios from 'axios';
import _ from 'lodash';
import { createUserQuery } from './queries';

const addUser = (model) =>
    new Promise((resolve, reject) => {
        const userModel = _.omit(model, 'password_confirmation');
        // coerce username to lowercase on both login + signup
        userModel.username = userModel.username.toLowerCase();
        const query = createUserQuery({ model: userModel });
        const request = axios.post(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((error) => {
                reject(error.response.data.error);
            });
    });

export default addUser;
