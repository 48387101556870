import React, { useContext } from 'react';
import { withStyles, Icon, ListItem, ListItemText } from '@material-ui/core';
import { NavLink, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'app/store/actions';
import FuseNavBadge from './../FuseNavBadge';
import LocalizationContext from '../../../../app/localization/LocalizationContext';
import { verifyVerticalNav } from '../../../../app/main/apps/ConfiguredApps/navigation/navigation-utils';

const propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        icon: PropTypes.string,
        url: PropTypes.string,
        subPermissionOnly: PropTypes.bool,
        requireAllPermissions: PropTypes.bool,
    }),
};

const defaultProps = {};

const styles = (theme) => ({
    item: {
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingRight: 12,
        '&.active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText + '!important',
            pointerEvents: 'none',
            transition: 'border-radius .15s cubic-bezier(0.4,0.0,0.2,1)',
            '& .list-item-text-primary': {
                color: 'inherit',
            },
            '& .list-item-icon': {
                color: 'inherit',
            },
        },
        '&.square, &.active.square': {
            width: '100%',
            borderRadius: '0',
        },
        '& .list-item-icon': {},
        '& .list-item-text': {},
        color: 'inherit!important',
        textDecoration: 'none!important',
    },
});

function FuseNavVerticalItem({ item, classes, nestedLevel, user, userName, navbarCloseMobile, active }) {
    const localization = useContext(LocalizationContext);

    if (!verifyVerticalNav(item, user)) {
        return null;
    }

    if (item.id === 'settings' && userName === 'DFNS') {
        return null;
    }

    let paddingValue = 40 + nestedLevel * 16;
    const listItemPadding = nestedLevel > 0 ? 'pl-' + (paddingValue > 80 ? 80 : paddingValue) : 'pl-24';

    return (
        <ListItem button component={NavLink} to={item.url} activeClassName="active" id={item.id} className={classNames(classes.item, listItemPadding, 'list-item', active)} onClick={navbarCloseMobile} exact={item.exact}>
            {item.icon && (
                <Icon className="list-item-icon text-16 flex-no-shrink" color="action">
                    {item.icon}
                </Icon>
            )}
            &nbsp; &nbsp; &nbsp;
            <ListItemText style={{ color: 'white' }} className="list-item-text" primary={localization ? localization.navigation[item.title] || item.title : item.title} classes={{ primary: 'text-14 list-item-text-primary' }} />
            {item.badge && <FuseNavBadge badge={item.badge} />}
        </ListItem>
    );
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            navbarCloseMobile: Actions.navbarCloseMobile,
        },
        dispatch
    );
}

function mapStateToProps({ persisted }) {
    return {
        user: persisted.auth.user,
        userName: persisted.auth.user.data.name,
    };
}

FuseNavVerticalItem.propTypes = propTypes;
FuseNavVerticalItem.defaultProps = defaultProps;

const NavVerticalItem = withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps, mapDispatchToProps)(FuseNavVerticalItem)));

export default NavVerticalItem;
