import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as userActions from 'app/auth/store/actions';
import { bindActionCreators } from 'redux';
import * as Actions from 'app/store/actions';
import jwtService from 'app/services/jwtService';

class Auth extends Component {
    /* eslint-disable-next-line no-useless-constructor */
    constructor(props) {
        super(props);

        /**
         * Comment the line if you do not use JWt
         */
        this.jwtCheck({ ...props });
    }

    jwtCheck = ({ setUserData, showMessage, logoutUser }) => {
        jwtService.on('onAutoLogin', () => {
            /**
             * Sign in and retrieve user data from Api
             */
            jwtService
                .signInWithCognitoSession()
                .then((user) => {
                    setUserData(user);
                })
                .catch((error) => {
                    showMessage({ message: error.message });
                });
        });

        jwtService.on('onAutoLogout', (message) => {
            if (message) {
                showMessage({ message });
            }
            logoutUser();
        });

        jwtService.init();
    };

    render() {
        const { children } = this.props;

        return (
            /* eslint-disable-next-line react/jsx-fragments */
            <React.Fragment>{children}</React.Fragment>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            logoutUser: userActions.logoutUser,
            setUserData: userActions.setUserData,
            showMessage: Actions.showMessage,
            hideMessage: Actions.hideMessage,
        },
        dispatch
    );
}

export default connect(null, mapDispatchToProps)(Auth);
