import axios from 'axios';
import { createMultisiteAccessQuery } from './queries';

export default async (model) => {
    try {
        const query = createMultisiteAccessQuery({ model });
        return await axios.post(query.url, { ...query.body });
    } catch (errors) {
        // eslint-disable-next-line no-console
        console.log(errors);
        throw new Error('Unable to add link');
    }
};
