import axios from 'axios';
import { createHaulerAssignmentQuery } from './queries';

const addHaulerAssignment = async (model) => {
    try {
        const query = createHaulerAssignmentQuery({ model });
        const request = axios.post(query.url, { ...query.body });
        const { data } = await request;
        return { data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to add hauler assignment');
    }
};

export default addHaulerAssignment;
