import { cloneDeep } from 'lodash';

/* eslint-disable no-param-reassign */
const parseGeofence = (geofence) => {
    const copy = cloneDeep(geofence);
    delete copy._id;
    delete copy.created_at;
    delete copy.deleted_at;
    delete copy.updated_at;
    delete copy.__v;

    if (copy?.user_id?._id) copy.user_id = copy.user_id._id;

    return copy;
};

export default parseGeofence;
