import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = (theme) => ({
    container: { display: 'flex', flexWrap: 'wrap' },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    },
});

function DateSelector(props) {
    const { classes, id, label, className, onChange, value } = props;

    return <TextField id={id} label={label} type="date" defaultValue={value} className={classes.textField + className} InputLabelProps={{ shrink: true }} onChange={onChange} />;
}

export default withStyles(styles)(DateSelector);
