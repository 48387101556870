import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import * as FuseActions from 'app/store/actions';
import { bindActionCreators } from 'redux';

function PaperComponent(props) {
    return (
        // <Draggable>
        <Paper {...props} />
        // </Draggable>
    );
}

class InternetExplorerDialog extends React.Component {
    state = { open: true };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { open } = this.state;

        return (
            <div>
                <Dialog open={open} onClose={this.handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="md" aria-labelledby="draggable-dialog-title">
                    <DialogTitle id="draggable-dialog-title">Browser Not Supported</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-black">You are using a web browser we no longer support.</DialogContentText>
                        <DialogContentText className="text-black">For a safer and faster user experience we recommend a modern browser like Chrome, Firefox, or Edge.</DialogContentText>
                        <DialogContentText className="text-black">Click one of the icons below to download the respective browser or continue without upgrading (not recommended).</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className="flex w-full">
                            <div className="flex flex-col w-1/4 w-200">
                                <Button onClick={() => window.open('https://www.google.com/intl/en_ca/chrome/', '_blank')}>
                                    <img src="https://www.google.com/chrome/static/images/chrome-logo.svg" alt="" width="50%" style={{ display: 'block', margin: 'auto' }} />
                                </Button>
                                <p style={{ textAlign: 'center' }}>Chrome</p>
                            </div>
                            <div className="flex flex-col w-1/4 w-200">
                                <Button onClick={() => window.open('https://www.mozilla.org/en-CA/firefox/new/', '_blank')}>
                                    <img src="https://www.flaticon.com/svg/static/icons/svg/179/179320.svg" alt="" width="50%" style={{ display: 'block', margin: 'auto' }} />
                                </Button>
                                <p style={{ textAlign: 'center' }}>Firefox</p>
                            </div>
                            <div className="flex flex-col w-1/4 w-200">
                                <Button onClick={() => window.open('https://www.microsoft.com/en-us/edge', '_blank')}>
                                    <img src="https://www.flaticon.com/svg/static/icons/svg/732/732219.svg" alt="" width="50%" style={{ display: 'block', margin: 'auto' }} />
                                </Button>
                                <p style={{ textAlign: 'center' }}>Edge</p>
                            </div>
                            <div className="flex flex-col w-1/4 w-200">
                                <Button onClick={this.handleClose} variant="contained" color="secondary" className="w-50 h-50" style={{ display: 'block', margin: 'auto' }}>
                                    Continue Without Updating
                                </Button>
                                <p style={{ textAlign: 'center' }}>(Not Recommended)</p>
                            </div>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: FuseActions.showMessage }, dispatch);
}

export default connect(null, mapDispatchToProps)(InternetExplorerDialog);
