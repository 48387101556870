const commodities = {
    cream: 'Cream',
    buttermilk: 'Buttermilk',
    condensed: 'Condensed',
    skim: 'Skim',
};

const config = {
    commodities,
};

export default config;
