import axios from 'axios';
import _ from 'lodash';
import { batchQuery, getSilosQuery, getUsersQuery } from './queries';
import { getRole, getId } from '../../utils';

const getSiloListDetails = (id) =>
    new Promise((resolve, reject) => {
        const role = getRole();
        const userId = getId();
        const params =
            role === 'admin'
                ? {
                      ...(!!id && { processorId: id }),
                      filterDeleted: true,
                  }
                : {
                      filterDeleted: true,
                      processorId: userId,
                  };
        const query = getSilosQuery(params);
        const request = axios.get(query);

        request
            .then((response) => {
                const silos = response.data;
                const processorIds = _.uniq(_.map(silos, 'processor_id'));

                const processorQuery = batchQuery(
                    getUsersQuery,
                    {
                        filterDeleted: true,
                        fields: ['id', 'name'],
                        ids: processorIds,
                    },
                    'ids'
                );
                const processorRequest = _.map(processorQuery, (q) => axios.get(q));

                axios.all(processorRequest).then(
                    axios.spread((...responses) => {
                        const processors = _.reduce(responses, (acc, val) => [...acc, ...val.data], []);
                        const siloResponse = _.orderBy(
                            _.map(silos, (silo) => {
                                const processor = _.find(processors, { id: silo.processor_id }) ? _.find(processors, { id: silo.processor_id }).name : '';
                                return { ...silo, processor };
                            }),
                            ['name'],
                            ['asc']
                        );
                        resolve({ silos: siloResponse });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getSiloListDetails;
