import axios from 'axios';
import { editProducerBusinessQuery } from './queries';

const editProducerBusiness = (model, key) =>
    new Promise((resolve, reject) => {
        const query = editProducerBusinessQuery({ key, model });
        const request = axios.put(query.url, { ...query.body });
        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                if (errors?.response?.data?.statusCode === 422) {
                    reject(new Error(errors?.response?.data?.message));
                }
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to edit producer business'));
            });
    });

export default editProducerBusiness;
