import React from 'react';
import _ from 'lodash';
import { Tab, Tabs } from '@material-ui/core';
import { PaymentType, PayReportPageType } from 'app/constants/report';
import { getUserRegion } from '../../../utils';
import { DEFERRAL_PAY_STATEMENT_REGIONS } from '../../constants/bulkRegion';
import Split from '../../../Split';

const PayReportTabs = ({ currentTabSelected, setCurrentTabSelected, payReportPageType }) => {
    const region = getUserRegion();
    const hasWeeklyPayFrequency = Split.getBooleanTreatment('can_use_weekly_pay_frequency');
    const canAccessAdhocReports = Split.getBooleanTreatment('can_access_adhoc_reports');

    const isProducerTabs = () => {
        return payReportPageType === PayReportPageType.producer;
    };

    return (
        <Tabs value={currentTabSelected} onChange={(_event, tabSelected) => setCurrentTabSelected(tabSelected)} indicatorColor="secondary" textColor="inherit" variant="fullWidth">
            <Tab label="Available Settlement Reports" value={PaymentType.settlement} className="noWrap" key={PaymentType.settlement} />
            <Tab label="Available Advance Reports" value={PaymentType.advance} key={PaymentType.advance} />
            {isProducerTabs() && hasWeeklyPayFrequency && <Tab label="Available Weekly Advance Reports" value={PaymentType.weeklyAdvance} key={PaymentType.weeklyAdvance} />}
            {isProducerTabs() && canAccessAdhocReports && <Tab label="Available Ad Hoc Reports" value={PaymentType.adhoc} key={PaymentType.adhoc} />}
            {isProducerTabs() && DEFERRAL_PAY_STATEMENT_REGIONS.includes(region) && <Tab label="Available Deferral Reports" value={PaymentType.deferral} key={PaymentType.deferral} />}
        </Tabs>
    );
};

export default PayReportTabs;
