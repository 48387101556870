import React, { useState, useEffect } from 'react';
import { Grid, Box, TextField, Typography, Button, Icon } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useForm, Form } from './useForm';
import Formsy from 'formsy-react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage, addMilkPrices, getMilkPrices } from 'app/store/actions';
import { getUserRegion } from '../../../utils';

const UDAMilkPriceForm = ({ priceData }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState(useSelector((state) => moment(`${state.persisted.dateDetails.selectedDate}`, 'YYYY-MM')));
    const startDate = useSelector((state) => moment(`${state.persisted.dateDetails.startDate}`, 'YYYY-MM'));
    const region = getUserRegion();

    const populateInitialValues = (data) => {
        const vals = {
            /* eslint-disable camelcase */
            standardQuotaPrice: data?.report_data?.milk_prices?.skim_milk_quota || '',
            standardOverQuotaPrice: data?.report_data?.milk_prices?.skim_milk_over_quota || '',
            quotaBFvalue: data?.report_data?.milk_prices?.butterfat_quota || '',
            overQuotaBFValue: data?.report_data?.milk_prices?.butterfat_over_quota || '',
            boardApprovedPremium: data?.report_data?.board_approved_rBST_premium || 0.19,
            rBSTAdjustmentAmount: data?.report_data?.rBST_adjustment || 0,
            poolAdjustment: data?.report_data?.pool_adjustment || 0,

            advancedStandardQuotaPrice: data?.report_data?.advance_milk_prices?.skim_milk_quota || '',
            advancedStandardOverQuotaPrice: data?.report_data?.advance_milk_prices?.skim_milk_over_quota || '',
            advancedQuotaBFvalue: data?.report_data?.advance_milk_prices?.butterfat_quota || '',
            advancedOverQuotaBFValue: data?.report_data?.advance_milk_prices?.butterfat_over_quota || '',

            classPricei: data?.report_data?.milk_prices?.class1 || '',
            classPriceii: data?.report_data?.milk_prices?.class2 || '',
            classPriceiii: data?.report_data?.milk_prices?.class3 || '',
            classPriceiv: data?.report_data?.milk_prices?.class4 || '',
            skimPricei: data?.report_data?.milk_prices?.class1_skim_milk || '',
            skimPriceii: data?.report_data?.milk_prices?.class2_skim_milk || '',
            skimPriceiii: data?.report_data?.milk_prices?.class3_skim_milk || '',
            skimPriceiv: data?.report_data?.milk_prices?.class4_skim_milk || '',
            butterfatPricei: data?.report_data?.milk_prices?.class1_butterfat || '',
            butterfatPriceii: data?.report_data?.milk_prices?.class2_butterfat || '',
            butterfatPriceiii: data?.report_data?.milk_prices?.class3_butterfat || '',
            butterfatPriceiv: data?.report_data?.milk_prices?.class4_butterfat || '',

            producer_657: data?.report_data?.split_producers?.producer_657 || 0,
            producer_723: data?.report_data?.split_producers?.producer_723 || 0,
            producer_724: data?.report_data?.split_producers?.producer_724 || 0,
            producer_725: data?.report_data?.split_producers?.producer_725 || 0,
            producer_783: data?.report_data?.split_producers?.producer_783 || 0,
            producer_819: data?.report_data?.split_producers?.producer_819 || 0,

            rBST_daisy: data?.report_data?.rBST_daisy || 0,
            rBST_safeway: data?.report_data?.rBST_safeway || 0,
            rBST_shamrock: data?.report_data?.rBST_shamrock || 0,
            rBST_other: data?.report_data?.rBST_other || 0,
            /* eslint-enable camelcase */
        };
        return vals;
    };

    let initialValues = populateInitialValues(priceData);

    const { values, setValues, handleInputChange } = useForm(initialValues);

    const transformModel = (model) => {
        const data = {
            milk_prices: {
                skim_milk_quota: +model.standardQuotaPrice || undefined,
                butterfat_quota: +model.quotaBFvalue || undefined,
                skim_milk_over_quota: +model.standardOverQuotaPrice || undefined,
                butterfat_over_quota: +model.overQuotaBFValue || undefined,
                class1: +values.classPricei || undefined,
                class2: +values.classPriceii || undefined,
                class3: +values.classPriceiii || undefined,
                class4: +values.classPriceiv || undefined,
                class1_skim_milk: +values.skimPricei || undefined,
                class2_skim_milk: +values.skimPriceii || undefined,
                class3_skim_milk: +values.skimPriceiii || undefined,
                class4_skim_milk: +values.skimPriceiv || undefined,
                class1_butterfat: +values.butterfatPricei || undefined,
                class2_butterfat: +values.butterfatPriceii || undefined,
                class3_butterfat: +values.butterfatPriceiii || undefined,
                class4_butterfat: +values.butterfatPriceiv || undefined,
            },
            advance_milk_prices: {
                skim_milk_quota: +model.advancedStandardQuotaPrice || undefined,
                butterfat_quota: +model.advancedQuotaBFvalue || undefined,
                skim_milk_over_quota: +model.advancedStandardOverQuotaPrice || undefined,
                butterfat_over_quota: +model.advancedOverQuotaBFValue || undefined,
            },
            rBST_adjustment: +model.rBSTAdjustmentAmount || 0,
            board_approved_rBST_premium: +model.boardApprovedPremium || 0.19,
            pool_adjustment: +model.poolAdjustment || 0,
            split_producers: {
                producer_657: +values.producer_657 || 0,
                producer_723: +values.producer_723 || 0,
                producer_724: +values.producer_724 || 0,
                producer_725: +values.producer_725 || 0,
                producer_783: +values.producer_783 || 0,
                producer_819: +values.producer_819 || 0,
            },
            rBST_daisy: +values.rBST_daisy || 0,
            rBST_safeway: +values.rBST_safeway || 0,
            rBST_shamrock: +values.rBST_shamrock || 0,
            rBST_other: +values.rBST_other || 0,
        };
        return data;
    };

    useEffect(() => {
        dispatch(getMilkPrices(selectedDate))
            .then((value) => {
                initialValues = populateInitialValues(value.payload.prices);
                setValues(initialValues);
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                dispatch(showMessage({ message: err.message }));
            });
    }, [selectedDate]);

    const handleSubmit = () => {
        const submitModel = transformModel({ ...values });

        dispatch(addMilkPrices(submitModel, selectedDate))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Updated Milk Prices.' }));
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                dispatch(showMessage({ message: err.message }));
            });
    };
    if (region !== 'CDI') {
        return (
            <Formsy onValidSubmit={handleSubmit}>
                <Helmet>
                    <title>Milk Prices - Milk Moovement</title>
                </Helmet>
                <Box sx={{ mt: 0.5 }}>
                    <Typography variant="h6">Milk Price</Typography>
                </Box>
                <Grid container spacing={12} justifyContent="flex-end">
                    <Grid item>
                        <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={'Save'}>
                            {'Save Milk Prices'}
                        </Button>
                    </Grid>
                    <div className="p-12">
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <Button onClick={() => setSelectedDate(selectedDate.clone().subtract(1, 'month'))} disabled={moment(startDate).format('YYYY MM') === moment(selectedDate).format('YYYY MM')}>
                                <Icon>navigate_before</Icon>
                            </Button>

                            <MuiDatePicker disableFuture variant="inline" openTo="month" label="Select Date" views={['year', 'month']} value={selectedDate} minDate={startDate} onChange={(date) => setSelectedDate(date)} />

                            <Button onClick={() => setSelectedDate(selectedDate.clone().add(1, 'month'))} disabled={moment(selectedDate).format('YYYY MM') === moment().format('YYYY MM')}>
                                <Icon>navigate_next</Icon>
                            </Button>
                        </MuiPickersUtilsProvider>
                    </div>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={2}>
                        <Typography>Settlement Milk Price</Typography>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            label="Quota Pounds Value"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.standardQuotaPrice}
                            name="standardQuotaPrice"
                            onChange={handleInputChange}
                        ></TextField>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            label="Over Quota Pounds Value"
                            type="number"
                            name="standardOverQuotaPrice"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.standardOverQuotaPrice}
                            onChange={handleInputChange}
                        ></TextField>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Quota BF Value"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="quotaBFvalue"
                            value={values.quotaBFvalue}
                            onChange={handleInputChange}
                        ></TextField>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Over Quota BF Value"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.overQuotaBFValue}
                            name="overQuotaBFValue"
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>Advance Milk Price</Typography>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            label="Quota Pounds Value"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.advancedStandardQuotaPrice}
                            name="advancedStandardQuotaPrice"
                            onChange={handleInputChange}
                        ></TextField>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            label="Over Quota Pounds Value"
                            type="number"
                            name="advancedStandardOverQuotaPrice"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.advancedStandardOverQuotaPrice}
                            onChange={handleInputChange}
                        ></TextField>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Quota BF Value"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="advancedQuotaBFvalue"
                            value={values.advancedQuotaBFvalue}
                            onChange={handleInputChange}
                        ></TextField>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Over Quota BF Value"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.advancedOverQuotaBFValue}
                            name="advancedOverQuotaBFValue"
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <Typography>Class Price</Typography>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Class i"
                            name="classPricei"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.classPricei}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Class ii"
                            name="classPriceii"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.classPriceii}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Class iii"
                            name="classPriceiii"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.classPriceiii}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Class iv"
                            name="classPriceiv"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.classPriceiv}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <Typography>Skim Price</Typography>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Class i"
                            name="skimPricei"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.skimPricei}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Class ii"
                            name="skimPriceii"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.skimPriceii}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Class iii"
                            name="skimPriceiii"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.skimPriceiii}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Class iv"
                            name="skimPriceiv"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.skimPriceiv}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <Typography>Butterfat Price</Typography>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Class i"
                            name="butterfatPricei"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.butterfatPricei}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Class ii"
                            name="butterfatPriceii"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.butterfatPriceii}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Class iii"
                            name="butterfatPriceiii"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.butterfatPriceiii}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Class iv"
                            name="butterfatPriceiv"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.butterfatPriceiv}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                </Grid>

                <Typography>Deduction Adjustments</Typography>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label="Board Approved rBST Free Premium"
                            name="boardApprovedPremium"
                            value={values.boardApprovedPremium}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="rBST Adjustment"
                            name="rBSTAdjustmentAmount"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.rBSTAdjustmentAmount}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Pool Adjustment"
                            name="poolAdjustment"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.poolAdjustment}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs></Grid>
                    <Grid item xs></Grid>
                </Grid>
                <Typography>rBST Processor Adjustments</Typography>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Daisy"
                            name="rBST_daisy"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.rBST_daisy}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Safeway"
                            name="rBST_safeway"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.rBST_safeway}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Shamrock"
                            name="rBST_shamrock"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.rBST_shamrock}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Other"
                            name="rBST_other"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.rBST_other}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs></Grid>
                </Grid>
                <Typography>Split Producer Daily Production</Typography>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="657 - Double D Dairy"
                            name="producer_657"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.producer_657}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="723 - Anthony Dugan"
                            name="producer_723"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.producer_723}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="724 - Thomas Dugan Jr"
                            name="producer_724"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.producer_724}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="725 - Timothy Dugan"
                            name="producer_725"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.producer_725}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="783 - JG Dairy"
                            name="producer_783"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.producer_783}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="819 - Quarter Circle G Dairy"
                            name="producer_819"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.producer_819}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                </Grid>
            </Formsy>
        );
    }
};

export default withRouter(UDAMilkPriceForm);
