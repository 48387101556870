import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { renameKey } from '../../utils';
import { getUsersQuery, getLabReportsQuery, getMonthlyReportsQuery } from './queries';
import { getAlertData } from './utils/getAlertData';
import { buildExpressQuery } from './queries/builders';

const getThreeMonthDateRanges = (date) =>
    _.times(14, (i) => ({
        start: date.clone().subtract(i, 'months'),
        end: date
            .clone()
            .subtract(i - 3, 'months')
            .subtract(1, 'seconds'),
    }));

const getOneMonthDateRanges = (date) =>
    _.times(12, (i) => ({
        start: date.clone().subtract(i, 'months'),
        end: date
            .clone()
            .subtract(i - 1, 'months')
            .subtract(1, 'seconds'),
    }));

const calculateAdjusted = (demerits, dateRange, labReports) => {
    const start = dateRange.start.subtract(1, 'second');
    const endDate = dateRange.end;
    const labReportsInDateRange = _.filter(labReports, (labReport) => moment(labReport.date).isBetween(start, endDate) && !labReport.deleted_at);
    return Math.round((demerits * endDate.daysInMonth()) / labReportsInDateRange.length);
};

const getBactoAlertDetails = (id, region, month, year) =>
    new Promise((resolve, reject) => {
        const userQuery = getUsersQuery({ fields: ['id', 'name', 'subuser'], ids: [id], queryBuilder: buildExpressQuery });
        const userRequest = axios.get(userQuery);

        userRequest
            .then((userResponse) => {
                const user = _.find(userResponse.data);
                renameKey(user, '_id', 'id');
                const producerId = user.subuser ? user.producer_id : id;
                const startOfMonth = moment(`${year}-${month}`, 'YYYY-MM');
                const dateRanges = region === 'RF' ? getOneMonthDateRanges(startOfMonth) : getThreeMonthDateRanges(startOfMonth);
                const { start } = dateRanges.slice(-1)[0];
                const endOfEarliestDateRange = dateRanges.slice(-1)[0].end;
                const { end } = dateRanges[0];

                const labReportQuery = getLabReportsQuery({
                    fields: ['producer_id', 'date', 'bacto_scan', 'somatic_cell_count', 'is_outlier', 'bacteria_PLC', 'temperature', 'deleted_at', 'demerit_points'], // TODO
                    start: region === 'PEI' ? endOfEarliestDateRange.clone().add(1, 'seconds').subtract(1, 'years') : start,
                    end,
                    producerIds: [producerId],
                    filterDeleted: true,
                    queryBuilder: buildExpressQuery,
                });

                const labReportRequest = axios.get(labReportQuery);

                const monthlyReportQuery = getMonthlyReportsQuery({
                    producerId: id,
                    filterForPayment: true,
                });
                const monthlyReportRequest = axios.get(monthlyReportQuery);

                axios.all([labReportRequest, monthlyReportRequest]).then(
                    axios.spread((...responses) => {
                        const labReports = responses[0].data;
                        renameKey(labReports, '_id', 'id');
                        const monthlyReports = responses[1].data;

                        const results = _.map(dateRanges, (dateRange) => {
                            const bactoAlertData = getAlertData(dateRange, labReports, region);
                            return {
                                label: `${dateRange.start.format('MMM')} - ${dateRange.end.format('MMM')} , ${dateRange.end.format('YYYY')}`,
                                date: dateRange.end.format('YYYY-MM-DD'),
                                bacto_status: bactoAlertData.bacto_status,
                                bacto_percentage: bactoAlertData.bacto_percentage,
                                scc_status: bactoAlertData.scc_status,
                                scc_percentage: bactoAlertData.scc_percentage,
                                scc_count_over_standard: bactoAlertData.scc_count_over_standard,
                                bacto_scan_count_over_standard: bactoAlertData.bacto_scan_count_over_standard,
                                freezing_point_count_over_standard: bactoAlertData.freezing_point_count_over_standard,
                                freezing_point_status: bactoAlertData.freezing_point_status,
                                demerit_points: bactoAlertData.demerit_points,
                                ...(region === 'RF' && { adjusted_points: calculateAdjusted(bactoAlertData.demerit_points, dateRange, labReports) }),
                                ...(region === 'RF' && { reports: monthlyReports }),
                            };
                        });
                        const lastDateRange = { start: startOfMonth.clone().subtract(2, 'months'), end: startOfMonth.clone().endOf('month') };
                        const bactoAlertData = getAlertData(lastDateRange, labReports, region);
                        resolve({
                            response: {
                                results,
                                scc_status: bactoAlertData.scc_status,
                                scc_percent: bactoAlertData.scc_percentage,
                                spc_percent: bactoAlertData.spc_percentage,
                                lpc_percent: bactoAlertData.lpc_percentage,
                                coliform_percent: bactoAlertData.coliform_percentage,
                                bacto_status: bactoAlertData.bacto_status,
                                bacto_percent: bactoAlertData.bacto_percentage,
                            },
                        });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getBactoAlertDetails;
