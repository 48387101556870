import axios from 'axios';
import generateErrorMessage from './utils/generateErrorMessage';
import { getExpressAPIHost } from '../../utils';

const downloadAdminReports = async ({ start, end, type, identifier, identifierName, sort, secondIdentifier, secondIdentifierName, thirdIdentifier, thirdIdentifierName, fourthIdentifier, fourthIdentifierName, fifthIdentifier, fifthIdentifierName, payReportDownloadStatus, reportDownloadSource }) => {
    try {
        const params = {
            startDate: start,
            endDate: end,
            reportName: type,
            ...(identifier && { [identifierName || 'id']: identifier }),
            ...(secondIdentifier !== undefined && { [secondIdentifierName]: secondIdentifier }),
            ...(thirdIdentifier !== undefined && { [thirdIdentifierName]: thirdIdentifier }),
            ...(fourthIdentifier !== undefined && { [fourthIdentifierName]: fourthIdentifier }),
            ...(fifthIdentifier !== undefined && { [fifthIdentifierName]: fifthIdentifier }),
            filter: sort,
            payReportDownloadStatus,
            reportDownloadSource,
        };
        const response = await axios.get(`${getExpressAPIHost()}/reports/`, { params });
        return response;
    } catch (err) {
        const formattedError = generateErrorMessage(err);
        if (formattedError?.message?.includes('ReportServiceError: ')) {
            const [, messageToDisplay] = formattedError.message.split(/ReportServiceError: /);
            // Limit error to readable length
            formattedError.message = `${messageToDisplay?.slice(0, 500)} ...`;
        } else if (err?.response?.data?.statusCode === 422) {
            throw err.response.data;
        } else {
            formattedError.message = 'There was a problem downloading your file. Please contact support.';
        }
        throw formattedError;
    }
};
export default downloadAdminReports;
