import addUser from './addUser';

const addProcessor = (model) =>
    addUser({
        ...model,
        role: 'processor',
        subuser: false,
    });

export default addProcessor;
