import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

export const Index = {
    auth: authRoles.admin,
    routes: [
        {
            path: '/assignments',
            component: FuseLoadable({
                loader: () => import('./AssignmentApp'),
            }),
        },
        {
            path: '/producer-assignments',
            component: FuseLoadable({
                loader: () => import('./ProducerAssignmentsApp'),
            }),
        },
        {
            path: '/hauler-assignments',
            component: FuseLoadable({
                loader: () => import('./HaulerAssignmentsApp'),
            }),
        },
        {
            path: '/create-assignment-configuration/:userRole',
            component: FuseLoadable({
                loader: () => import('./MutateAssignmentConfiguration'),
            }),
        },
        {
            path: '/edit-assignment-configuration/:userRole/:id',
            component: FuseLoadable({
                loader: () => import('./MutateAssignmentConfiguration'),
            }),
        },
    ],
};

export default [Index];
