import axios from 'axios';
import { copyFluidItemQuery } from './queries';

const copyFluidItem = async ({ id, dates }) => {
    try {
        const query = copyFluidItemQuery({ id, dates });
        const request = axios.put(query.url, { ...query.body });
        const { data } = await request;
        return { ids: data };
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        throw new Error('Unable to copy schedule item');
    }
};

export default copyFluidItem;
