import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    center: {
        display: 'block',
        margin: 'auto',
    },
});

const ActionAddIcon = () => {
    const classes = useStyles();
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.center}>
            <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17 13H13V17H11V13H7V11H11V7H13V11H17V13Z" fill="#40B481" />
        </svg>
    );
};

export default ActionAddIcon;
