import React, { useState, useEffect } from 'react';
import { Grid, Box, TextField, Typography, Button, Icon } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useForm, Form } from './useForm';
import Formsy from 'formsy-react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { withRouter, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage, addMilkPrices, getMilkPrices } from 'app/store/actions';

const CDIHaulingRateForm = ({ priceData }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedDate, setSelectedDate] = useState(useSelector((state) => moment(`${state.persisted.dateDetails.selectedDate}`, 'YYYY-MM')));
    const startDate = useSelector((state) => moment(`${state.persisted.dateDetails.startDate}`, 'YYYY-MM'));

    const formatInitial = (val) => {
        return val === 0 ? 0 : val || '';
    };

    const populateInitialValues = (data) => {
        const vals = {
            /* eslint-disable camelcase */
            baseZoneA: formatInitial(data?.report_data?.milk_prices?.hauling_zoneA),
            baseZoneB: formatInitial(data?.report_data?.milk_prices?.hauling_zoneB),
            baseZoneC: formatInitial(data?.report_data?.milk_prices?.hauling_zoneC),
            baseZoneD: formatInitial(data?.report_data?.milk_prices?.hauling_zoneD),
            baseSelfHaul: formatInitial(data?.report_data?.milk_prices?.hauling_self_haul),

            fuelSurchargeZoneA: formatInitial(data?.report_data?.milk_prices?.fuel_surcharge_zoneA),
            fuelSurchargeZoneB: formatInitial(data?.report_data?.milk_prices?.fuel_surcharge_zoneB),
            fuelSurchargeZoneC: formatInitial(data?.report_data?.milk_prices?.fuel_surcharge_zoneC),
            fuelSurchargeZoneD: formatInitial(data?.report_data?.milk_prices?.fuel_surcharge_zoneD),

            adjustmentZoneA: formatInitial(data?.report_data?.milk_prices?.hauling_adjustment_zoneA),
            adjustmentZoneB: formatInitial(data?.report_data?.milk_prices?.hauling_adjustment_zoneB),
            adjustmentZoneC: formatInitial(data?.report_data?.milk_prices?.hauling_adjustment_zoneC),
            adjustmentZoneD: formatInitial(data?.report_data?.milk_prices?.hauling_adjustment_zoneD),

            stopCharge: formatInitial(data?.report_data?.milk_prices?.stop_charge),

            /* eslint-enable camelcase */
        };
        return vals;
    };

    let initialValues = populateInitialValues(priceData);

    const { values, setValues, handleInputChange } = useForm(initialValues);

    const formatSubmit = (val) => {
        return val === '' ? undefined : +val === 0 ? +val : +val || undefined;
    };

    const transformModel = (model) => {
        const data = {
            milk_prices: {
                hauling_zoneA: formatSubmit(model.baseZoneA),
                hauling_zoneB: formatSubmit(model.baseZoneB),
                hauling_zoneC: formatSubmit(model.baseZoneC),
                hauling_zoneD: formatSubmit(model.baseZoneD),
                hauling_self_haul: formatSubmit(model.baseSelfHaul),

                fuel_surcharge_zoneA: formatSubmit(model.fuelSurchargeZoneA),
                fuel_surcharge_zoneB: formatSubmit(model.fuelSurchargeZoneB),
                fuel_surcharge_zoneC: formatSubmit(model.fuelSurchargeZoneC),
                fuel_surcharge_zoneD: formatSubmit(model.fuelSurchargeZoneD),

                hauling_adjustment_zoneA: formatSubmit(model.adjustmentZoneA),
                hauling_adjustment_zoneB: formatSubmit(model.adjustmentZoneB),
                hauling_adjustment_zoneC: formatSubmit(model.adjustmentZoneC),
                hauling_adjustment_zoneD: formatSubmit(model.adjustmentZoneD),

                stop_charge: formatSubmit(model.stopCharge),
            },
        };
        return data;
    };

    useEffect(() => {
        dispatch(getMilkPrices(selectedDate))
            .then((value) => {
                initialValues = populateInitialValues(value.payload.prices);
                setValues(initialValues);
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                dispatch(showMessage({ message: err.message }));
            });
    }, [selectedDate]);

    const handleSubmit = () => {
        const submitModel = transformModel({ ...values });

        dispatch(addMilkPrices(submitModel, selectedDate))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Updated Hauling Rates.' }));
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                dispatch(showMessage({ message: err.message }));
            });
    };

    return (
        <Formsy onValidSubmit={handleSubmit}>
            <Helmet>
                <title>Hauling Rates - Milk Moovement</title>
            </Helmet>
            <Box sx={{ mt: 0.5 }}>
                <Typography variant="h6">Hauling Rates</Typography>
            </Box>
            <Grid container spacing={12} justifyContent="flex-end">
                <Grid item>
                    <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={'Save'}>
                        {'Save'}
                    </Button>
                </Grid>
                <div className="p-12">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Button onClick={() => setSelectedDate(selectedDate.clone().subtract(1, 'month'))} disabled={moment(startDate).format('YYYY MM') === moment(selectedDate).format('YYYY MM')}>
                            <Icon>navigate_before</Icon>
                        </Button>

                        <MuiDatePicker disableFuture variant="inline" openTo="month" label="Select Date" views={['year', 'month']} value={selectedDate} minDate={startDate} onChange={(date) => setSelectedDate(date)} />

                        <Button onClick={() => setSelectedDate(selectedDate.clone().add(1, 'month'))} disabled={moment(selectedDate).format('YYYY MM') === moment().format('YYYY MM')}>
                            <Icon>navigate_next</Icon>
                        </Button>
                    </MuiPickersUtilsProvider>
                </div>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Typography>Base</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Zone A (cwt)"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.baseZoneA}
                        name="baseZoneA"
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Zone B (cwt)"
                        type="number"
                        name="baseZoneB"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.baseZoneB}
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Zone C (cwt)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="baseZoneC"
                        value={values.baseZoneC}
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Zone D (cwt)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="baseZoneD"
                        value={values.baseZoneD}
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Self Haul (cwt)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="baseSelfHaul"
                        value={values.baseSelfHaul}
                        onChange={handleInputChange}
                    ></TextField>
                </Grid>
                <Grid item xs={3}>
                    <Typography>Fuel Surcharge</Typography>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Zone A (cwt)"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.fuelSurchargeZoneA}
                        name="fuelSurchargeZoneA"
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Zone B (cwt)"
                        type="number"
                        name="fuelSurchargeZoneB"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.fuelSurchargeZoneB}
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Zone C (cwt)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="fuelSurchargeZoneC"
                        value={values.fuelSurchargeZoneC}
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Zone D (cwt)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.fuelSurchargeZoneD}
                        name="fuelSurchargeZoneD"
                        onChange={handleInputChange}
                    ></TextField>
                </Grid>
                <Grid item xs={3}>
                    <Typography>Zone Adjustment</Typography>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Zone A (cwt)"
                        name="adjustmentZoneA"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.adjustmentZoneA}
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Zone B (cwt)"
                        name="adjustmentZoneB"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.adjustmentZoneB}
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Zone C (cwt)"
                        name="adjustmentZoneC"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.adjustmentZoneC}
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Zone D (cwt)"
                        name="adjustmentZoneD"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.adjustmentZoneD}
                        onChange={handleInputChange}
                    ></TextField>
                </Grid>

                <Grid item xs={3}>
                    <Typography>Stop Charge</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Stop Charge ($/stop)"
                        name="stopCharge"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.stopCharge}
                        onChange={handleInputChange}
                    ></TextField>
                </Grid>
            </Grid>
        </Formsy>
    );
};

export default withRouter(CDIHaulingRateForm);
