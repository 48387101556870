import { object, number, date, array } from 'yup';

const SplitProducerAgreementDetails = object({
    basis: object({}).required(),
    split_portion: object({}).required(),
    split_rate: number()
        .required()
        .min(0, 'Split rate must be greater than zero')
        .typeError('Split rate must be a number')
        .when('basis', {
            is: (basis) => basis?.value === 'percent',
            then: (schema) => schema.max(100, 'Percentage must be less than 100'),
        }),
});

const SplitProducerSchema = object({
    parent_producer: object().required().typeError(''),
    child_producer: object().required().typeError(''),
    start_date: date().required(),
    end_date: date().optional().nullable(),
    split_producer_agreement_details: array().min(1, 'A minimum of one agreement is required').of(SplitProducerAgreementDetails),
});

export default SplitProducerSchema;
