import React, { useEffect, useRef, useState } from 'react';
import ReactHookFormSearchableSelect from './ReactHookFormSearchableSelect';
import { Stack } from '@mui/material';
import { Button, Box, makeStyles, Typography, Divider, IconButton, Dialog, DialogActions, DialogTitle, DialogContent, Accordion, AccordionDetails, AccordionSummary, List, ListItem, ListItemText } from '@material-ui/core';
import { AddBox, Delete } from '@material-ui/icons';
import DraggablePaper from '../../custom-widgets/dialogs/DraggablePaper';
import getHaulerRatesForHaulingContract from '../../repository/getHaulerRatesForHaulingContract';
import useAsync from 'app/hooks/useAsync';
import { MMSplashScreen } from 'app/custom-components';
import _ from '@lodash';
import moment from 'moment-timezone';
import { useFieldArray, useFormContext } from 'react-hook-form';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    dialogPaper: {
        minWidth: 500,
    },
}));

const ExistingHaulerRates = ({ haulerRatesFromForm }) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { control, watch, setValue } = useFormContext();
    const { remove } = useFieldArray({
        control,
        name: 'haulerRates',
    });

    const startDateHistory = useRef([]);
    const startDate = watch('start_date');

    const haulerHistory = useRef([]);
    const hauler = watch('hauler');

    const endDateHistory = useRef([]);
    const endDate = watch('end_date');

    const [deleteModal, setDeleteModal] = useState(false);
    const deleteIdx = useRef(0);

    useEffect(() => {
        const hasStartDateChanged = startDateHistory?.current.length !== 0 && startDate?.format('MM-DD-YYYY') !== startDateHistory?.current.format('MM-DD-YYYY');
        if (hasStartDateChanged && startDateHistory.current != null) {
            setValue('haulerRates', []);
        }
        startDateHistory.current = startDate;
    }, [startDate]);

    useEffect(() => {
        const hasEndDateChanged = endDateHistory?.current.length !== 0 && endDate?.format('MM-DD-YYYY') !== endDateHistory?.current.format('MM-DD-YYYY');
        if (hasEndDateChanged && endDateHistory.current != null) {
            setValue('haulerRates', []);
        }
        endDateHistory.current = endDate ?? [];
    }, [endDate]);

    useEffect(() => {
        const hasHauledChanged = haulerHistory?.current.length !== 0 && hauler?.id !== haulerHistory?.id;
        if (hasHauledChanged && haulerHistory.current != null) {
            setValue('haulerRates', []);
        }
        haulerHistory.current = hauler ?? [];
    }, [hauler]);

    const [haulerRateOptions] = useAsync(() => getHaulerRatesForHaulingContract(hauler?.id, startDate, endDate), [hauler?.id, startDate, endDate]);

    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const onDeleteClick = (idx) => {
        deleteIdx.current = idx;
        setDeleteModal(true);
    };

    const onDeleteComfirmation = (confirmation) => {
        if (confirmation) {
            remove(deleteIdx.current);
        }
        setDeleteModal(false);
    };

    const haulerRateLabel = (option) => {
        return `${option.rate_name} - ${_.startCase(option?.type?.replace(/_/g, ' '))}`;
    };

    let haulerRateOptionLabels = [];
    if (haulerRateOptions?.status !== 'pending') {
        haulerRateOptionLabels = haulerRateOptions.data.haulerRates.map((haulerRate) => {
            return { ...haulerRate, name: haulerRate.rate_name };
        });
    }

    const render = () => {
        return (
            <>
                <Stack direction="row" alignItems="center" spacing={2} sx={{ paddingTop: '25px' }}>
                    <Typography variant="body1">Existing Rate</Typography>
                    <IconButton aria-label="Add" component="span" onClick={handleClickOpen}>
                        <AddBox />
                    </IconButton>
                </Stack>
                <Divider />

                <Dialog open={open} onClose={handleClose} classes={{ paper: classes.dialogPaper }} fullWidth maxWidth={'md'} PaperComponent={DraggablePaper}>
                    <DialogTitle id="draggable-dialog-title">
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <div>Select Existing Hauler Rates</div>
                        </Box>
                    </DialogTitle>
                    <p></p>
                    <DialogContent>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            {haulerRateOptions?.status === 'pending' ? <MMSplashScreen /> : <ReactHookFormSearchableSelect label={'Hauler Rates'} name={'haulerRates'} defaultValue={[]} options={haulerRateOptionLabels} multiple fullWidth type={'text'} customRender={haulerRateLabel} required />}
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
                {haulerRatesFromForm?.map((haulerRate, idx) => {
                    return (
                        <Accordion key={haulerRate.id} expanded>
                            <AccordionSummary aria-controls="panel1a-content">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography>
                                        {haulerRate.rate_name} - {_.startCase(haulerRate?.type?.replace(/_/g, ' '))}
                                    </Typography>
                                    <IconButton
                                        color="primary"
                                        aria-label="Add"
                                        value={idx}
                                        component="span"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            onDeleteClick(idx);
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>Rate Details</Typography>
                                <List>
                                    {haulerRate.effectiveHaulerRates?.map((effectiveRate) => {
                                        return (
                                            <ListItem>
                                                <ListItemText
                                                    primary={`${moment(effectiveRate.effective_date).format('MMM-DD-YYYY')} - ${effectiveRate.basis !== 'percent' ? '$' : ''}${effectiveRate.rate}${effectiveRate.basis === 'percent' ? '%' : ''} - ${_.startCase(effectiveRate.basis?.replace(/_/g, ' '))} - ${_.startCase(
                                                        effectiveRate.basis_parameter?.replace(/_/g, ' ')
                                                    )}`}
                                                />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}

                <Dialog open={deleteModal}>
                    <DialogTitle>
                        <Typography>Are you sure you want to delete this item?</Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => onDeleteComfirmation(false)}>
                                No
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => onDeleteComfirmation(true)}>
                                Yes
                            </Button>
                        </Stack>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    return render();
};

export default ExistingHaulerRates;
