import axios from 'axios';
import { createClosePeriodQuery } from './queries';

const putClosePeriodDetails = async (is_locked, startDate, endDate) => {
    const query = createClosePeriodQuery({ is_locked, startDate, endDate });
    const { data } = await axios.put(query.url, { ...query.body });
    return data;
};

export default putClosePeriodDetails;
