import React, { useContext } from 'react';
import { Circle, Polygon } from '@react-google-maps/api';
import {
    shapeOptions,
    isCircleGeofence,
    isPolygonGeofence,
    // wrap
} from './GeofenceManager.helpers';
import { MapContext } from '../MapContext';

const GeofenceArtist = ({ handleChange, canEdit, onLoad }) => {
    const { fences: geofences, setFences, selectedIndex, setSelectedIndex } = useContext(MapContext);

    const setFenceInstance = (instance, index) => {
        const newFences = [...geofences];
        newFences[index].instance = instance;
        setFences(newFences);
    };

    return geofences.map((geofence, index) => {
        const selected = selectedIndex === index;
        if (isCircleGeofence(geofence)) {
            const circleCenter = {
                lat: geofence.center.coordinates[1],
                lng: geofence.center.coordinates[0],
            };
            return (
                <Circle
                    key={`${index + 1}-circle`}
                    center={circleCenter}
                    radius={geofence.radius_meters}
                    options={{ ...shapeOptions }}
                    editable={canEdit && selected}
                    draggable={canEdit && selected}
                    onDragEnd={() => handleChange('radialGeofence', index)}
                    onRadiusChanged={() => handleChange('radialGeofence', index)}
                    onLoad={(instance) => setFenceInstance(instance, index)}
                    onClick={() => setSelectedIndex(index)}
                    // wrap
                />
            );
        }
        if (isPolygonGeofence(geofence)) {
            return (
                <Polygon
                    key={`${index + 1}-polygon`}
                    paths={geofence.geometry.coordinates[0].map((point) => ({
                        lat: point[1],
                        lng: point[0],
                    }))}
                    options={{ ...shapeOptions }}
                    onDragEnd={() => handleChange('polygonalGeofence', index)}
                    onMouseUp={() => handleChange('polygonalGeofence', index)}
                    editable={canEdit && selected}
                    draggable={canEdit && selected}
                    onLoad={(instance) => setFenceInstance(instance, index)}
                    onClick={() => setSelectedIndex(index)}
                    // wrap
                />
            );
        }
        return null;
    });
};

export default GeofenceArtist;
