import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';
import Big from 'big.js';
import { getPayGroupDisplayName } from './utils';

/** Pay group, location adjustment and final ppd rate */
const MilkPriceFormPPDAdjustment = ({ payGroup, federalOrder, watch, setValue }) => {
    const locationAdjustmentWatch = watch(`milk_prices.pay_group_${payGroup}.location_adjustment`);
    const basePPDWatch = watch(`milk_prices.federal_order_${federalOrder}.base_ppd_rate`);
    useEffect(() => {
        setValue(`milk_prices.pay_group_${payGroup}.ppd_rate`, new Big(basePPDWatch || 0).plus(new Big(locationAdjustmentWatch || 0)));
    }, [locationAdjustmentWatch, basePPDWatch]);

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">Pay Group {getPayGroupDisplayName(payGroup)}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <ReactHookFormInput label="Location Adj. ($/cwt)" name={`milk_prices.pay_group_${payGroup}.location_adjustment`} type="number" />
            </Grid>
            <Grid item xs={12} sm={3}>
                <ReactHookFormInput label="Final PPD ($/cwt)" name={`milk_prices.pay_group_${payGroup}.ppd_rate`} type="number" fieldOpts={{ disabled: true }} />
            </Grid>
        </>
    );
};

export default MilkPriceFormPPDAdjustment;
