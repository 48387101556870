import React from 'react';
import { IconButton } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import * as Actions from 'app/store/actions';
import connect from 'react-redux/es/connect/connect';

const NavbarMobileToggleButton = ({ navbarToggleMobile, children }) => (
    <IconButton className="justify-right w-64 h-64" onClick={navbarToggleMobile} color="inherit">
        {children}
    </IconButton>
);

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ navbarToggleMobile: Actions.navbarToggleMobile }, dispatch);
}

NavbarMobileToggleButton.defaultProps = { children: <i className="material-icons">menu</i> };

export default connect(null, mapDispatchToProps)(NavbarMobileToggleButton);
