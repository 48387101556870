import moment from 'moment-timezone';
import { IN_PROGRESS_DROPOFF_STATUS } from '../../../constants/logistics/dropoff/in_progress_dropoff_status';
import { buildExpressQuery } from '../../queries/builders';

export const getInprogressDropoffQuery = ({ processorId, routeSessionId, filterDeleted, status, filterDates }) => {
    const endpoint = '/in-progress/dropoffs/awaiting';
    const { TEST_IN_QA, AWAITING_TEST_RESULT, TEST_IN_PROGRESS } = IN_PROGRESS_DROPOFF_STATUS;

    const params = {};

    switch (status) {
        case AWAITING_TEST_RESULT:
        case TEST_IN_PROGRESS:
            params.where = { status: { eq: [AWAITING_TEST_RESULT, TEST_IN_PROGRESS] } };
            break;
        case 'all':
            params.where = { status: { eq: Object.values(IN_PROGRESS_DROPOFF_STATUS) } };
            break;
        default:
            params.where = {
                status: { eq: [TEST_IN_QA] },
            };
    }

    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }

    if (processorId != null) {
        params.where = { ...params.where, destination_processor_id: processorId };
    }

    if (routeSessionId != null) {
        params.where = { ...params.where, route_session_id: routeSessionId };
    }

    if (filterDates != null) {
        const { frequency, unit } = filterDates;

        params.where = { ...params.where, created_at: { between: [moment().subtract(frequency, unit), moment().add(24, 'hours')] } };
    }

    return buildExpressQuery(endpoint, { params });
};

export const getActiveInProgressDropoffQuery = ({ dateRange, filterDeleted, routeSessionId, processorId, haulingId }) => {
    const endpoint = '/in-progress/dropoffs/active';
    const params = {};

    if (dateRange) {
        params.where = { ...params.where, ...dateRange };
    }

    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }

    if (routeSessionId != null) {
        params.where = { ...params.where, route_session_id: routeSessionId };
    }

    if (processorId != null) {
        params.where = { ...params.where, processor_id: processorId };
    }

    if (haulingId != null) {
        params.where = { ...params.where, hauling_id: haulingId };
    }

    return buildExpressQuery(endpoint, { params });
};

export const completeIPDOQuery = ({ key, model }) => {
    const endpoint = `/in-progress/dropoffs/complete/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editIPDOQuery = ({ key, model }) => {
    const endpoint = `/in-progress/dropoffs/${key}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const getDeleteInProgressDropoffQuery = (id) => {
    const endpoint = `/in-progress/dropoffs/${id}`;
    return buildExpressQuery(endpoint, {});
};
