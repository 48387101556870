import React from 'react';
import MapCircle from './MapCircle';
import MapLocationPin from './MapLocationPin';
import MapPointMarker from './MapPointMarker';
import MapPolyline from './MapPolyline';
import MapPointPickup from './MapPointPickup';
import MapPointDropoff from './MapPointDropoff';
import MapMarker from './MapMarker';
import MapPolygon from './MapPolygon';

/**
 * MapArtist component is responsible for rendering all shapes on the map
 * @param {Object} props
 * @param {Object[]} props.shapes
 * @param {string} props.selectedShape
 * @param {function} props.handleEditCircleFromMap
 * @param {function} props.handleCircleLoad
 * @param {function} props.handleMarkerLoad
 * @param {Object[]} props.circleInstances
 * @param {Object[]} props.markerInstances
 * @returns MapArtist component
 */

const MapArtist = ({
    shapes,
    selectedShape,
    handleEditCircleFromMap,
    handleCircleLoad,
    handleMarkerClick,
    circleInstances,
    handleMapMarkerLoad,
    handlePolylineLoad,
    handleInfoWindowLoad,
    producerIds,
    processorIds,
    polylineStroke,
    // wrap
}) => {
    const drawings = [];

    shapes.forEach((shape, index) => {
        if (shape.type === 'radialGeofence') {
            drawings.push(
                <MapCircle
                    key={JSON.stringify(shape)}
                    index={index}
                    shape={shape}
                    selectedShape={selectedShape}
                    handleEditCircleFromMap={handleEditCircleFromMap}
                    handleCircleLoad={handleCircleLoad}
                    circleInstances={circleInstances}
                    producerIds={producerIds}
                    processorIds={processorIds}
                    // Force Line Wrap Comment
                />
            );
        } else if (shape.type === 'polygonalGeofence') {
            drawings.push(
                <MapPolygon
                    key={JSON.stringify(shape)}
                    index={index}
                    shape={shape}
                    selectedShape={selectedShape}
                    handleMapMarkerLoad={handleMapMarkerLoad}
                    handleMarkerClick={handleMarkerClick}
                    // Force Line Wrap Comment
                />
            );
        } else if (shape.location && shape.location?.type === 'Point') {
            drawings.push(
                <MapPointMarker
                    key={JSON.stringify(shape)}
                    index={index}
                    shape={shape}
                    handleMapMarkerLoad={handleMapMarkerLoad}
                    handleInfoWindowLoad={handleInfoWindowLoad}
                    // Force Line Wrap Comment
                />
            );
        } else if (shape.type === 'polyline') {
            drawings.push(
                <MapPolyline
                    key={JSON.stringify(shape)}
                    index={index}
                    shape={shape}
                    handlePolylineLoad={handlePolylineLoad}
                    strokeWeight={polylineStroke || 2}
                    // Force Line Wrap Comment
                />
            );
        } else if (shape.type === 'rawMilkPickup' && shape.center.lng && shape.center.lat) {
            drawings.push(<MapPointPickup key={JSON.stringify(shape)} index={index} shape={shape} />);
        } else if (shape.type === 'rawMilkDropoff' && shape.center.lng && shape.center.lat) {
            drawings.push(<MapPointDropoff key={JSON.stringify(shape)} index={index} shape={shape} />);
        }
        if (shape.type === 'marker') {
            drawings.push(<MapMarker key={JSON.stringify(shape)} index={index} shape={shape} selectedShape={selectedShape} />);
        }
    });

    return drawings;
};

export default React.memo(MapArtist);
