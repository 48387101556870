import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

/**
 * Define all of your environment configs here
 */

const local = new Environment({
    name: stages.LOCAL,
    evaluationFn: (attributes) => {
        if (['NL', 'PEI', 'RF'].includes(attributes.region)) return 'off';
        return 'on';
    },
});

const dev = new Environment({
    name: stages.DEV,
    evaluationFn: (attributes) => {
        if (['NL', 'PEI', 'RF'].includes(attributes.region)) return 'off';
        return 'on';
    },
});

/**
 * When overriding evaluationFn it is not necessary to add the regions param
 * Regions is solely leveraged for the defaultEvaluationFn that is a basic region checker
 */
const staging = new Environment({
    name: stages.STAGING,
    evaluationFn: (attributes) => {
        if (attributes.region === 'CDI' && ['admin', 'sub-admin', 'transport', 'sub-transport'].includes(attributes.role)) return 'on';
        return 'off';
    },
});

const demo = new Environment({
    name: stages.DEMO,
    evaluationFn: (attributes) => {
        if (['NL', 'PEI', 'RF'].includes(attributes.region)) return 'off';
        return 'on';
    },
});

const prod = new Environment({
    name: stages.PRODUCTION,
    evaluationFn: (attributes) => {
        if (['NL', 'PEI', 'RF'].includes(attributes.region)) return 'off';
        return 'on';
    },
});

/**
 * Create the new MockSplit
 */
const can_use_new_splits = new MockSplit({
    name: 'can_use_new_splits',
    environments: [local, dev, staging, demo, prod],
});

export default can_use_new_splits;
