import kinks from '@turf/kinks';
import { polygon } from '@turf/helpers';

export const shapeOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    visible: true,
    zIndex: 1,
};

export const drawingModeOptions = [
    // { label: 'Select type', value: null },
    // Instead of using the drawing manager, GeofenceMap will add a default circle onClick
    { label: 'Circle', value: undefined },
    { label: 'Polygon', value: 'polygon' },
];

export const isCircleGeofence = (geofence) => geofence?.type === 'radialGeofence';
export const isPolygonGeofence = (geofence) => geofence?.type === 'polygonalGeofence';

export const calculatePolygonCenter = (paths) => {
    if (!paths) return;
    let sumLat = 0;
    let sumLng = 0;

    paths.forEach((point) => {
        sumLat += point.lat;
        sumLng += point.lng;
    });

    const centerLat = sumLat / paths.length;
    const centerLng = sumLng / paths.length;

    return { lat: centerLat, lng: centerLng };
};

export const getShapeCenter = (geofence) => {
    if (!geofence) return;

    if (isCircleGeofence(geofence)) {
        if (!geofence.center) return;
        return {
            lat: geofence.center.coordinates[1],
            lng: geofence.center.coordinates[0],
        };
    }
    if (isPolygonGeofence(geofence)) {
        const paths = geofence.geometry.coordinates[0].map((point) => ({
            lat: point[1],
            lng: point[0],
        }));
        return calculatePolygonCenter(paths);
    }
    return null;
};

export const GeoJSONPoint = (coordinates, type = 'Point') => ({
    type,
    coordinates,
});

const pathsClose = (geoJSON) => {
    const first = geoJSON[0];
    const last = geoJSON[geoJSON.length - 1];
    return first[0] === last[0] && first[1] === last[1];
};

export const convertPathsArrayToGeoJSON = (paths) => {
    const geoJSON = paths?.map((path) => {
        const coords = path.toJSON();
        return [Number(coords.lng), Number(coords.lat)];
    });
    if (!pathsClose(geoJSON)) {
        const firstPoint = [...geoJSON[0]];
        geoJSON.push(firstPoint);
    }
    return [geoJSON];
};

export const isValidPolygon = (paths) => {
    const geoJSON = convertPathsArrayToGeoJSON(paths);
    if (geoJSON?.[0].length < 4) {
        return {
            valid: false,
            error: 'Invalid polygon. Polygon must have at least 3 points.',
            create: false,
        };
    }
    let poly;
    try {
        poly = polygon(geoJSON);
    } catch (e) {
        return {
            valid: false,
            error: e.message,
            create: true,
        };
    }
    const selfIntersections = kinks(poly);
    return {
        valid: !selfIntersections.features.length,
        error: 'Invalid polygon. Polygon cannot intersect itself.',
        create: true,
    };
};
