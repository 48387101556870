import React from 'react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import moment from 'moment-timezone';

const NewDatePicker = ({ setSelectedDate, selectedDate, startDate, disableFutureDate, disablePastDate = true, views, navigateTo, style }) => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        <Button
            data-testid="new-date-picker-backward-button"
            onClick={() => {
                setSelectedDate(selectedDate.clone().subtract(1, navigateTo === 'year' ? 'year' : 'month'));
            }}
            disabled={disablePastDate && moment(startDate).format('YYYY MM') === moment(selectedDate).format('YYYY MM')}
        >
            <Icon>navigate_before</Icon>
        </Button>

        <MuiDatePicker
            disableFuture={disableFutureDate ?? true}
            variant="inline"
            openTo="month"
            label="Select Date"
            views={views ?? ['year', 'month']}
            value={selectedDate}
            minDate={startDate}
            onChange={(date) => setSelectedDate(date)}
            leftArrowButtonProps={{
                classes: {
                    root: 'fs-ignore-rage-clicks',
                },
            }}
            rightArrowButtonProps={{
                classes: {
                    root: 'fs-ignore-rage-clicks',
                },
            }}
            style={{ ...style }}
        />

        <Button
            data-testid="new-date-picker-forward-button"
            onClick={() => {
                setSelectedDate(selectedDate.clone().add(1, navigateTo === 'year' ? 'year' : 'month'));
            }}
            disabled={disableFutureDate && moment(selectedDate).format('YYYY MM') === moment().format('YYYY MM')}
        >
            <Icon>navigate_next</Icon>
        </Button>
    </MuiPickersUtilsProvider>
);

export default React.memo(NewDatePicker);
