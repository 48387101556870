import jwtService from 'app/services/jwtService';
import * as UserActions from './user.actions';

export const REGISTER_ERROR = 'REGISTER_ERROR';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

export function submitRegister({ name, password, email }) {
    return (dispatch) =>
        jwtService
            .createUser({
                name,
                password,
                email,
            })
            .then((user) => {
                dispatch(UserActions.setUserData(user));
                return dispatch({ type: REGISTER_SUCCESS });
            })
            .catch((error) => dispatch({ type: REGISTER_ERROR, payload: error }));
}
