import axios from 'axios';
import { createNotificationEventQuery } from './queries';

const sendNotificationEvent = async (eventType, model, sourceUserIds) => {
    try {
        const query = createNotificationEventQuery({ eventType, model, sourceUserIds });
        const request = axios.post(query.url, { ...query.body });
        return await request;
    } catch (error) {
        throw new Error('Unable to send notification event');
    }
};

export default sendNotificationEvent;
