import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Divider, InputAdornment } from '@material-ui/core';
import Formsy from 'formsy-react';
import { showMessage, addSilo, editSilo } from 'app/store/actions';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import { TextFieldFormsy } from '@fuse';

const SiloForm = ({ editData, processors, disabled }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [canSubmit, setCanSubmit] = useState(false);

    const disableButton = () => {
        setCanSubmit(false);
    };

    const enableButton = () => {
        setCanSubmit(true);
    };

    const onSubmit = (model) => {
        const submitModel = { ...model };
        submitModel.capacity = parseFloat(submitModel.capacity);

        if (editData) {
            dispatch(editSilo(submitModel, editData.id))
                .then(() => {
                    dispatch(showMessage({ message: 'Successfully Edited Silo.' }));
                    history.push({ pathname: '/list-silos' });
                })
                .catch((err) => {
                    dispatch(showMessage({ message: err.message }));
                });
        } else {
            dispatch(addSilo(submitModel))
                .then(() => {
                    dispatch(showMessage({ message: 'Successfully Added Silo.' }));
                    history.push({ pathname: '/list-silos' });
                })
                .catch((err) => {
                    dispatch(showMessage({ message: err.message }));
                });
        }
    };

    return (
        <div className="min-w-3/4 max-w-3/4">
            <Divider />

            <Formsy
                onValidSubmit={onSubmit}
                onValid={enableButton}
                onInvalid={disableButton}
                /* eslint-disable-next-line no-return-assign */
                ref={(form) => form}
                className="flex flex-col justify-center"
            >
                <TextFieldFormsy
                    className="my-16"
                    type="text"
                    name="silo_number"
                    label="Silo Number"
                    value={editData ? editData.silo_number : ''}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <i className="text-20 material-icons" color="action">
                                    person
                                </i>
                            </InputAdornment>
                        ),
                    }}
                    required
                    variant="outlined"
                />
                <SelectSearchFormsy
                    className="my-16"
                    name="processor_id"
                    label="Owner"
                    options={[processors.length === 1 ? { value: processors[0].value, name: processors[0].name } : { value: ' ', name: 'None' }, ...processors.map((entry) => ({ value: entry.value, name: entry.name }))]}
                    variant="standard"
                    value={editData ? editData.processor_id : processors.length === 1 ? processors[0].value : ' '}
                    validations="minLength:2"
                    validationError="Please select a Processor"
                    disabled={!!editData || processors.length === 1}
                    on
                />

                <TextFieldFormsy
                    className="my-16"
                    type="text"
                    name="capacity"
                    label="Capacity"
                    value={editData ? editData.capacity : ''}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <i className="text-20 material-icons" color="action">
                                    bookmark
                                </i>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                />

                <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={editData ? 'EDIT SUB USER' : 'ADD SUB USER'} disabled={!canSubmit}>
                    {editData ? 'Edit Silo' : 'Add Silo'}
                </Button>
            </Formsy>
        </div>
    );
};

export default withRouter(SiloForm);
