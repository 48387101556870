import axios from 'axios';
import { addGeofenceQuery } from './queries';
import generateErrorMessage from '../utils/generateErrorMessage';
import parseGeofence from './utils';

const addGeofence = async (model) => {
    try {
        const parsedFence = parseGeofence(model);
        const query = addGeofenceQuery({ model: parsedFence });

        const { data } = await axios.post(query.url, { ...query.body });

        return data;
    } catch (error) {
        return generateErrorMessage(error);
    }
};

export default addGeofence;
