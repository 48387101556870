import axios from 'axios';
import { getAPIHost } from '../../utils';
import { getSignedURLQuery } from './queries';

const getFileURL = async (key, isDownload = false, useExpressEndpoint = false) => {
    let filepath = key;
    try {
        // If the URI is already signed then keep original
        if (filepath.includes('X-Amz-Signature')) {
            return filepath;
        }
        // If a full S3 URL is passed rather than path then extract the file path
        if (filepath.includes('amazonaws.com')) {
            filepath = new URL(key).pathname.substring(1);
        }

        if (useExpressEndpoint) {
            const res = await axios.get(getSignedURLQuery(filepath));
            return res.data.s3Url;
        }

        const response = await axios.get(`${getAPIHost()}/get-signed-url/${encodeURIComponent(filepath)}?isDownload=${isDownload}`);
        return response.data;
    } catch (e) {
        throw new Error('Unable to find file. Please contact support.');
    }
};
export default getFileURL;
