import addUser from './addUser';

const addDriver = (model) =>
    addUser({
        ...model,
        role: 'driver',
        subuser: false,
    });

export default addDriver;
