import axios from 'axios';
import generateErrorMessage from './utils/generateErrorMessage';
import { editPaymentStatusQuery } from './queries';

const editPaymentStatus = async (model, key) => {
    try {
        const query = editPaymentStatusQuery({ model, key });
        const request = axios.put(query.url, { ...query.body }, { params: { useNewPutRoute: true } });
        const { data } = await request;
        return { data };
    } catch (err) {
        const formattedError = generateErrorMessage(err);
        if (formattedError?.message?.includes('PaymentStatusError')) {
            const [, messageToDisplay] = formattedError.message.split(/PaymentStatusError: /);
            formattedError.message = messageToDisplay;
        } else {
            formattedError.message = 'Unable to process request.';
        }
        throw formattedError;
    }
};
export default editPaymentStatus;
