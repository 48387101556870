import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import * as FuseActions from 'app/store/actions';
import { bindActionCreators } from 'redux';
import { DialogContentText } from '@material-ui/core';
import moment from 'moment-timezone';
import Papa from 'papaparse';
import { downloadCSV } from '../../../utils';
import { uploadPaymentSheet } from '../../repository';

function PaperComponent(props) {
    return (
        // <Draggable>
        <Paper {...props} />
        // </Draggable>
    );
}

class PaymentDialog extends React.Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
    }

    state = {
        open: false,
        enableButton: false,
        selectedDate: [moment().format('M'), moment().format('YYYY')],
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedDate } = this.props;

        if (prevProps.selectedDate !== selectedDate) {
            this.setState({ selectedDate: [selectedDate[0].toString(), selectedDate[1].toString()] });
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleEnableButton = () => {
        this.setState({ enableButton: true });
    };

    handleUpload = ({ showMessage }) => {
        const { showLoadingDialog, hideLoadingDialog, selectedDate, adjustedPayment } = this.props;

        const obj = { file: this.fileInput.current.files[0] };

        if (showLoadingDialog) {
            showLoadingDialog();
        }

        if (obj.file) {
            if (obj.file.name.slice(-5) !== '.xlsx' && obj.file.name.slice(-4) !== '.csv') {
                if (hideLoadingDialog) {
                    hideLoadingDialog();
                }
                showMessage({ message: 'Please provide a valid .csv or .xlsx file.' });
                return;
            }

            const formData = new FormData();
            formData.append('file', obj.file);
            formData.append('isAdjusted', !!adjustedPayment ? 'true' : 'false');

            const [month, year] = selectedDate;

            uploadPaymentSheet(formData, 'Payment', year, month)
                .then(() => {
                    showMessage({ message: 'Successfully uploaded your Payment file.' });
                    if (hideLoadingDialog) {
                        hideLoadingDialog();
                    }
                })
                .catch(() => {
                    showMessage({ message: 'There was a problem uploading your file. Please contact support.' });
                    if (hideLoadingDialog) {
                        hideLoadingDialog();
                    }
                });

            this.setState({ open: false });
        }
    };

    downloadCurrentMonth = (data, props) => {
        this.convertDataToCSV(this.transformForCsv(data), props);
    };

    transformForCsv = (details) => {
        const { region } = this.props;
        if (details) {
            let map = null;

            if (region === 'NL') {
                map = details.map((value) => ({
                    'CMT Number': value.CMTNumber,
                    'Producer Name': value.name,
                    'Daily Fluid Milk Quota': value.daily_fluid_milk_quota,
                    'Fat %': value.butter_fat,
                    'Protein %': value.protein,
                    'LOS %': value.los,
                    'Total Volume': value.volume,
                }));
            } else {
                map = details.map((value) => ({
                    'CMT Number': value.CMTNumber,
                    'Producer Name': value.name,
                    Quota: value.daily_fluid_milk_quota,
                    'Fat %': value.butter_fat,
                    'Protein %': value.protein,
                    'LOS %': value.los,
                    'Total Volume': value.volume,
                }));
            }

            return map;
        }
        return [];
    };

    convertDataToCSV = (data = null, { selectedDate }) => {
        const CSVContent = Papa.unparse(data);
        downloadCSV(CSVContent, `Monthly_Overview_${`${moment(`${selectedDate[1].toString()} ${selectedDate[0].toString()} 01`, 'YYYY MM DD').format('MMMM')}_${selectedDate[1]}`}.csv`);
    };

    render() {
        const { open, enableButton, selectedDate } = this.state;
        const { currentMonthData, disabled, region, adjustedPayment } = this.props;

        return (
            <div className="inline-flex">
                <Button type="submit" variant="contained" color="secondary" className="m-4 ml-4" aria-label="Payment" onClick={this.handleClickOpen} disabled={disabled}>
                    {adjustedPayment ? 'Adjusted Payment' : 'Payment'}
                </Button>
                <Dialog open={open} onClose={this.handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
                    <DialogTitle id="draggable-dialog-title">{adjustedPayment ? 'Upload Adjusted Payment' : 'Upload Payment'}</DialogTitle>
                    {region === 'NL' && !adjustedPayment && (
                        <DialogContent>
                            <DialogContentText className="text-black">
                                Download Selected Month Information:{' '}
                                <Button onClick={() => this.downloadCurrentMonth(currentMonthData, { ...this.props, ...this.state })} variant="contained" color="secondary">
                                    Download {`${moment(`${selectedDate[1].toString()} ${selectedDate[0].toString()} 01`, 'YYYY MM DD').format('MMMM')}, ${selectedDate[1]}`}
                                </Button>
                            </DialogContentText>
                        </DialogContent>
                    )}

                    <DialogContent>
                        <DialogContentText className="text-black">
                            Upload File &nbsp;&nbsp;&nbsp;
                            <input type="file" id="csvFile" ref={this.fileInput} onChange={this.handleEnableButton} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleUpload(this.props)} variant="contained" color="secondary" disabled={!enableButton}>
                            Upload
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps({ mainReducer, persisted }) {
    return {
        pickupDetails: mainReducer.pickupDetails.data,
        dropoffDetails: mainReducer.dropoffDetails.data,
        region: persisted.auth.user.data.region,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: FuseActions.showMessage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDialog);
