import React from 'react';
import Box from '@material-ui/core/Box';
import { Typography, Chip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

const Steps = ({ number, label, active = false, done = false }) => {
    const isPending = !active && !done;
    const mutedChipColor = 'rgba(0,0,0,.38)';
    const mutedTextColor = 'rgba(0,0,0,.6)';
    const activeTextColor = 'rgba(0,0,0,.87)';

    const chipBgColor =
        active || done
            ? {
                  color: 'primary',
                  sx: {
                      ...(done && {
                          '& .MuiChip-label': {
                              display: 'flex',
                              p: 0,
                          },
                      }),
                  },
              }
            : {
                  sx: {
                      backgroundColor: mutedChipColor,
                  },
              };

    return (
        <Box className="flex flex-row items-center" sx={{ fontSize: '14px' }}>
            <Chip className="mr-8" {...chipBgColor} size="small" label={done ? <CheckIcon /> : number} />
            <Typography sx={{ color: isPending ? mutedTextColor : activeTextColor, fontWeight: isPending ? 400 : 600 }} fontSize="inherit">
                {label}
            </Typography>
        </Box>
    );
};

export default Steps;
