import axios from 'axios';
import _, { drop } from 'lodash';
import moment from 'moment-timezone';
import { getDropoffsQuery, getSilosQuery, getDriversQuery, getProcessorsQuery, getProducersQuery, getRouteSessionsQuery, getPickupsQuery, getRoutesQuery, getLabReportsQuery, getTrailersQuery } from './queries';
import { getBatchedQueryData, calculateComponents, getPickupsByDropoffId } from './utils';
import { arrayToMap, getMonthDateRange, formatLabsForComponentCalculation } from '../../utils';
import store from '../store';

/**
 * Get pickups by dropoffs by routeSessionId
 *
 * @param {array} pickups
 * @param {array} dropoffs
 * @param {array} routeSessionIds
 * @returns {object} pickups by dropoffs by routeSessionId
 */
const getPickupsByDropoffIdByRouteSessionId = (pickups, dropoffs, routeSessionIds) => {
    const pickupsByRouteSessionId = _.groupBy(pickups, 'route_session_id');
    const dropoffsByRouteSessionId = _.groupBy(dropoffs, 'route_session_id');

    const pickupsDropoffsByRouteSessionId = _.mapValues(
        _.groupBy(
            _.map(routeSessionIds, (id) => {
                const rsDropoffs = dropoffsByRouteSessionId[id];
                const rsPickups = pickupsByRouteSessionId[id] ? pickupsByRouteSessionId[id] : [];
                return {
                    route_session_id: id,
                    dropoffs: rsDropoffs,
                    pickups: rsPickups,
                };
            }),
            'route_session_id'
        ),
        (o) => _.pick(o[0], ['pickups', 'dropoffs'])
    );

    const pickupsByDropoffIdByRouteSessionId = _.mapValues(
        _.groupBy(
            _.map(routeSessionIds, (id) => {
                const { pickups: p, dropoffs: d } = pickupsDropoffsByRouteSessionId[id];
                return { route_session_id: id, pickupsByDropoffId: d ? getPickupsByDropoffId(d, p) : [] };
            }),
            'route_session_id'
        ),
        (o) => o[0].pickupsByDropoffId
    );

    return pickupsByDropoffIdByRouteSessionId;
};

/**
 * Get lab reports from pickups
 *
 * @param {array} pickups
 * @returns {array} labReports
 */
const getLabReports = async (pickups) => {
    const firstPickup = _.minBy(pickups, 'created_at');
    const lastPickup = _.maxBy(pickups, 'created_at');

    const labReportStart = firstPickup ? moment(firstPickup.created_at).startOf('day').toString() : null;
    const labReportEnd = lastPickup ? moment(lastPickup.created_at).endOf('day').toString() : null;

    const labReportsQuery = getLabReportsQuery({
        start: labReportStart,
        end: labReportEnd,
        filterDeleted: true,
    });
    return (await axios.get(labReportsQuery)).data;
};

/**
 * Get all related dropoffs and routeSessions
 *
 * @param {string} region
 * @param {string} start start boundary (datestring)
 * @param {string} end end boundary (datestring)
 * @param {string} filterType ("milk" || "cream")
 * @returns {object} { dropoffs, routeSessions }
 */
const getDropoffsAndRouteSessions = async (start, end) => {
    const dropoffsQuery = getDropoffsQuery({
        start,
        end,
        filterDeleted: true,
        filterType: 'milk',
    });
    const dropoffsResponse = await axios.get(dropoffsQuery);
    const dropoffs = _.filter(dropoffsResponse.data, (i) => !i.deleted_at);

    const linkingIds = _.uniq(_.map(dropoffs, 'route_session_id'));

    const routeSessions = await getBatchedQueryData(getRouteSessionsQuery, { ids: linkingIds }, 'ids');

    return { dropoffs, routeSessions };
};

const getSilos = async () => {
    const silosQuery = getSilosQuery({
        fields: ['id', 'silo_number'],
        filterDeleted: true,
    });
    const silosResponse = await axios.get(silosQuery);
    const silos = _.filter(silosResponse.data, (i) => !i.deleted_at);

    return silos;
};

/**
 * Get all related information and creates an objected used to create a csv
 *
 * @param {string} start start boundary (datestring)
 * @param {string} end end boundary (datestring)
 * @param {boolean} mid whether it's mid or full month report
 * @returns {object} (csv sheet data)
 */

const downloadBordenReport = async (startDate, endDate, mid = true) => {
    try {
        const region = 'BORDEN';
        const end = !!mid ? moment(startDate).set('date', '15').endOf('day') : endDate;
        const { dropoffs, routeSessions } = await getDropoffsAndRouteSessions(startDate, end);
        const siloListDetails = await getSilos();

        const driverIds = _.uniq(_.map(dropoffs, 'driver_id'));
        const processorIds = _.uniq(_.map(dropoffs, 'processor_id'));
        const routeIds = _.uniq(_.map(routeSessions, 'route_id'));
        const trailerIds = _.uniq(_.map(routeSessions, 'trailer_id'));

        const driversRequest = getBatchedQueryData(getDriversQuery, { fields: ['id', 'name'], ids: driverIds }, 'ids');
        const processorsRequest = getBatchedQueryData(getProcessorsQuery, { fields: ['id', 'name', 'latitude', 'longitude', 'geofence'], ids: processorIds }, 'ids');
        const routesRequest = getBatchedQueryData(getRoutesQuery, { fields: ['id', 'name'], ids: routeIds }, 'ids');
        const trailerRequest = getBatchedQueryData(getTrailersQuery, { ids: trailerIds }, 'ids');

        const [drivers, processors, routes, trailers] = await Promise.all([driversRequest, processorsRequest, routesRequest, trailerRequest]);
        const driversMap = arrayToMap(drivers, 'id');
        const processorsMap = arrayToMap(processors, 'id');
        const routeSessionsMap = arrayToMap(routeSessions, 'id');
        const routesMap = arrayToMap(routes, 'id');
        const trailerMap = arrayToMap(trailers, 'id');

        const routeSessionIds = _.uniq(_.map(routeSessions, 'id'));
        const pickups = await getBatchedQueryData(getPickupsQuery, { routeSessionIds, filterDeleted: true }, 'routeSessionIds');
        const labReports = await getLabReports(pickups);
        const [groupedLabReports, labsByPickupIdMap] = formatLabsForComponentCalculation(labReports, region);

        const pickupsByDropoffIdByRouteSessionId = getPickupsByDropoffIdByRouteSessionId(pickups, dropoffs, routeSessionIds);
        const sheet = [];

        _.map(dropoffs, (dropoff) => {
            const processor = [processorsMap[dropoff.processor_id]];
            const driver = [driversMap[dropoff.driver_id]];
            const routeSession = routeSessionsMap[dropoff.route_session_id];
            const route = routesMap[routeSession.route_id];
            const trailer = trailerMap[routeSession.trailer_id];

            const pickupsForDropoff = pickupsByDropoffIdByRouteSessionId[routeSession.id][dropoff.id];
            const components = calculateComponents(pickupsForDropoff, groupedLabReports, region, labsByPickupIdMap);
            sheet.push({
                'Received Date': moment(dropoff.created_at).format('YYYY/MM/DD'), // dropoff
                'Received Time': moment(dropoff.created_at).format('HHmm'), // dropoff
                'LT Number': routeSession && routeSession.route_number ? routeSession.route_number : '', // session
                'Manifest Number': routeSession && routeSession.manifest_number ? routeSession.manifest_number : '', // session
                'Contract Supplier': 'Select Milk',
                'Manifest Supplier': routeSession && routeSession.manifest_number && routeSession.manifest_number.toString()[0] === '1' ? 'Select Milk' : 'DFA',
                'Driver Name': driver && driver[0] && driver[0].name ? driver[0].name : 'Borden Driver',
                'Route Name': route && route.name ? route.name : '',
                Trailer: trailer && trailer.trailer_number ? trailer.trailer_number : '', // session
                Plant: processor && processor[0] && processor[0].name ? processor[0].name : '', // dropoff
                'Manifest Weight': dropoff && dropoff.volume ? dropoff.volume : '', // dropoff
                'Plant Scale Weight': dropoff && dropoff.metered ? dropoff.metered : '', // dropoff
                'Shrink %': dropoff && dropoff.volume ? parseFloat(((dropoff.volume - dropoff.metered) / dropoff.volume) * 100).toLocaleString('us', { maximumFractionDigits: 4, minimumFractionDigits: 4 }) : '', // dropoff
                'Shrink Amount': dropoff && dropoff.volume ? parseFloat(dropoff.volume - dropoff.metered).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '', // dropoff
                'Delivery Temp': pickupsForDropoff && pickupsForDropoff.reduce((a, b) => a + parseInt(b.temperature), 0) !== 0 ? _.round(pickupsForDropoff.reduce((a, b) => a + parseInt(b.temperature), 0) / pickupsForDropoff.length) : '', // pickup
                'Supplier BF': components && components.fat ? parseFloat(components.fat).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '', // lab
                'Supplier Pro': components && components.protein ? parseFloat(components.protein).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '', // lab
                'Supplier SNF': components && components.solids_not_fat ? parseFloat(components.solids_not_fat).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '', // lab
                'Plant Temp': dropoff && dropoff.temperature ? dropoff.temperature : '', // dropoff
                'Plant BF': dropoff && dropoff.fat_percentage ? dropoff.fat_percentage : '', // dropoff
                'Plant Pro': dropoff && dropoff.protein_percentage ? dropoff.protein_percentage : '', // dropoff
                'Plant SNF': dropoff && dropoff.solids_not_fat_percentage ? dropoff.solids_not_fat_percentage : '', // dropoff
                'Plant Cryo': dropoff && dropoff.cryo ? dropoff.cryo : '', // dropoff
                'Plant Sediment': dropoff && dropoff.sediment ? dropoff.sediment : '', // dropoff
                'Plant TA': dropoff && dropoff.titratable_acidity ? dropoff.titratable_acidity : '', // dropoff
                'Plant Antibiotics': dropoff && dropoff.antibiotics ? dropoff.antibiotics : '', // dropoff
                'Plant SPC': dropoff && dropoff.standard_plate_count ? dropoff.standard_plate_count : '', // dropoff
                'Plant DMC': dropoff && dropoff.direct_microscope_count ? dropoff.direct_microscope_count : '', // dropoff
                // match to silos
                'Silo #1': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[0].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[0].id)[0].amount : '', // silo + dropoff
                'Silo #2': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[1].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[1].id)[0].amount : '', // silo + dropoff
                'Silo #3': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[2].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[2].id)[0].amount : '', // silo + dropoff
                'Silo #4': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[3].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[3].id)[0].amount : '', // silo + dropoff
                'Silo #5': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[4].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[4].id)[0].amount : '', // silo + dropoff
                'Silo #101': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[5].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[5].id)[0].amount : '', // silo + dropoff
                'Silo #102': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[6].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[6].id)[0].amount : '', // silo + dropoff
                'Silo #103': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[7].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[7].id)[0].amount : '', // silo + dropoff
                'Silo #104': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[8].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[8].id)[0].amount : '', // silo + dropoff
                'Silo #105': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[9].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[9].id)[0].amount : '', // silo + dropoff
                'Silo #106': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[10].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[10].id)[0].amount : '', // silo + dropoff
                'Silo #108': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[11].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[11].id)[0].amount : '', // silo + dropoff
                'Silo #120': dropoff && dropoff.split_details && dropoff.split_details.filter((split) => split.silo === siloListDetails[12].id).length > 0 ? dropoff.split_details.filter((split) => split.silo === siloListDetails[12].id)[0].amount : '', // silo + dropoff

                BTU: '', // ?
                'Departure Date': routeSession && routeSession.created_at ? moment(routeSession.created_at).format('YYYY/MM/DD') : '', // session
                'Departure Time': routeSession && routeSession.created_at ? moment(routeSession.created_at).format('HHmm') : '', // session
                'Supplier BF Pounds': components && components.volume && components.fat ? ((components.volume * components.fat) / 100).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '', // lab
                'Plant BF Pounds': dropoff && dropoff.volume && dropoff.fat_percentage ? ((dropoff.volume * dropoff.fat_percentage) / 100).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '', // dropoff
                'Rejected?': dropoff && dropoff.is_rejected ? 'Y' : 'N', // dropoff
                'Destination of Rejected Load': processor && processor.name ? processor.name : '', // dropoff
            });
        });

        return sheet;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default downloadBordenReport;
