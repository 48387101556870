import axios from 'axios';
import { addSampleBarcodeBatchQuery, editSampleBarcodeBatchQuery, getSampleBarcodeBatchQuery, getBatchInfoForBarcodeQuery } from './queries';
import _ from 'lodash';

export const addSampleBarcodeBatch = async (model) => {
    const query = addSampleBarcodeBatchQuery({ model });
    const request = axios.post(query.url, { ...query.body });
    const { data } = await request;
    return data;
};

export const editSampleBarcodeBatch = async (key, model) => {
    const batchBody = _.omit(model, ['created_at', 'updated_at', 'deleted_at', '__v', '_id']);
    const query = editSampleBarcodeBatchQuery(key, batchBody);
    const request = axios.patch(query.url, { ...query.body });
    const { data } = await request;
    return data;
};

export const getSampleBarcodeBatchById = async (id) => {
    const query = getSampleBarcodeBatchQuery({ id });
    const request = axios.get(query);
    const { data } = await request;
    return data[0];
};

export const getSampleBarcodeBatches = async (startDate, endDate, id) => {
    const query = getSampleBarcodeBatchQuery({ id, startDate, endDate });
    const request = axios.get(query);
    const { data } = await request;
    return data;
};

export const getBatchInfoForBarcode = async (barcode) => {
    const query = getBatchInfoForBarcodeQuery({ barcode });
    const request = axios.get(query);
    const { data } = await request;
    return data;
};
