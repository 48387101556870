import Environment from '../../Environment';
import stages from '../stages';
import { ALL_REGIONS } from '../../../app/constants/bulkRegion';
import MockSplit from '../../MockSplit';

const local = new Environment({
    name: stages.LOCAL,
    regions: ALL_REGIONS,
});

const dev = new Environment({
    name: stages.DEV,
    regions: ALL_REGIONS,
});

const stg = new Environment({
    name: stages.STAGING,
    regions: ALL_REGIONS,
});

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: [],
});

const can_access_plant_management = new MockSplit({
    name: 'can_access_plant_management',
    environments: [local, dev, stg, prod],
});

export default can_access_plant_management;
