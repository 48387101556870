import generateErrorMessage from './utils/generateErrorMessage';
import { getPooledPickupsQuery } from './queries';
import axios from 'axios';

async function getPoolingDecisionsPickups({ startDate, endDate }) {
    try {
        const { data: pickups } = await axios.get(
            getPooledPickupsQuery({
                startDate,
                endDate,
            })
        );
        return pickups;
    } catch (error) {
        const formattedError = generateErrorMessage(error);

        // eslint-disable-next-line no-console
        console.error(formattedError);
        throw formattedError;
    }
}

export default getPoolingDecisionsPickups;
