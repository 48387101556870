import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { renameKey, formatLabsForComponentCalculation } from '../../utils';
import { getPickupsQuery, getDropoffsQuery, getDriversQuery, getProducersQuery, getRouteSessionsQuery, getRoutesQuery, getTrailersQuery, getLabReportsQuery, getProcessorsQuery, getHaulingCompaniesQuery } from './queries';
import { buildExpressQuery } from './queries/builders';
import { calculateComponents, getProcessorByPickupId } from './utils';

const getRouteSessionPickups = (key, region) =>
    new Promise((resolve, reject) => {
        const pickupsQuery = getPickupsQuery({ routeSessionIds: [key], filterDeleted: true, queryBuilder: buildExpressQuery });
        const pickupsRequest = axios.get(pickupsQuery);

        const dropoffsQuery = getDropoffsQuery({ routeSessionIds: [key], filterDeleted: true });
        const dropoffsRequest = axios.get(dropoffsQuery);

        const routeSessionQuery = getRouteSessionsQuery({
            fields: ['id', 'BOL', 'status', 'route_id', 'trailer_id', 'route_number', 'hauling_id', 'truck_id'],
            ids: [key],
            filterDeleted: true,
        });
        const routeSessionRequest = axios.get(routeSessionQuery);

        const requests1 = [pickupsRequest, dropoffsRequest, routeSessionRequest];

        axios
            .all(requests1)
            .then(
                axios.spread((...responses1) => {
                    const [pickupsResponse, dropoffsResponse, routeSessionResponse] = responses1;
                    const pickups = pickupsResponse.data;
                    renameKey(pickups, '_id', 'id');
                    const dropoffs = dropoffsResponse.data;
                    const routeSession = _.find(routeSessionResponse.data);

                    const firstPickup = _.minBy(pickups, 'created_at');
                    const lastPickup = _.maxBy(pickups, 'created_at');

                    const labReportStart = firstPickup ? moment(firstPickup.created_at).startOf('day').toString() : moment().toString();
                    const labReportEnd = lastPickup ? moment(lastPickup.created_at).endOf('day').toString() : moment().toString();

                    const driverIds = _.uniq(_.map(pickups, 'driver_id'));
                    const producerIds = _.uniq(_.map(pickups, 'producer_id'));
                    const processorIds = _.uniq(_.map(dropoffs, 'processor_id'));

                    const routeId = routeSession.route_id;
                    const trailerId = routeSession.trailer_id;
                    const haulerId = routeSession.hauling_id;

                    const driverQuery = getDriversQuery({ fields: ['id', 'name'], ids: driverIds });
                    const producerQuery = getProducersQuery({ fields: ['id', 'name', 'latitude', 'longitude', 'geofence', 'lab_number', 'number_of_tanks', 'license_number'], ids: producerIds });
                    const processorQuery = getProcessorsQuery({ fields: ['id', 'name', 'latitude', 'longitude', 'geofence'], ids: processorIds });
                    const routeQuery = getRoutesQuery({ fields: ['id', 'name', 'users'], ids: [routeId] });
                    const trailerQuery = getTrailersQuery({ fields: ['trailer_number'], ids: [trailerId] });
                    const haulerQuery = getHaulingCompaniesQuery({ fields: ['id', 'name'], ids: [haulerId] });

                    const labReportQuery =
                        region === 'BORDEN'
                            ? getLabReportsQuery({
                                  start: labReportStart,
                                  end: labReportEnd,
                                  filterDeleted: true,
                              })
                            : getLabReportsQuery({
                                  start: labReportStart,
                                  end: labReportEnd,
                                  producerIds,
                                  filterDeleted: true,
                              });

                    const driverRequest = axios.get(driverQuery);
                    const producerRequest = axios.get(producerQuery);
                    const processorRequest = axios.get(processorQuery);
                    const labReportRequest = axios.get(labReportQuery);
                    const routeRequest = axios.get(routeQuery);
                    const trailerRequest = axios.get(trailerQuery);
                    const haulerRequest = axios.get(haulerQuery);

                    const requests2 = [driverRequest, producerRequest, processorRequest, labReportRequest, routeRequest, trailerRequest, haulerRequest];
                    return axios.all(requests2).then(
                        axios.spread((...responses2) => {
                            const [driverResponse, producerResponse, processorResponse, labReportResponse, routeResponse, trailerResponse, haulerResponse] = responses2;
                            const drivers = driverResponse.data;
                            const producers = producerResponse.data;
                            const processors = processorResponse.data;
                            const labReports = labReportResponse.data;
                            const route = _.find(routeResponse.data);
                            const trailer = _.find(trailerResponse.data);

                            const routeProducerQuery = getProducersQuery({ fields: ['created_at', 'id', 'latitude', 'longitude', 'name', 'number_of_tanks'], ids: route ? route.users : [] });
                            const routeProducerRequest = axios.get(routeProducerQuery);

                            return routeProducerRequest.then((routeProducerResponse) => {
                                const routeProducers = routeProducerResponse.data;
                                const [groupedLabReports, labsByPickupIdMap] = formatLabsForComponentCalculation(labReports, region);

                                const processorsByPickupId = getProcessorByPickupId(pickups, dropoffs, processors);

                                const pickupsData = _.map(pickups, (pickup) => {
                                    const components = calculateComponents([pickup], groupedLabReports, region, labsByPickupIdMap);

                                    const driver = _.find(drivers, { id: pickup.driver_id });
                                    const producer = _.find(producers, { id: pickup.producer_id });
                                    const hauler = _.find(haulerResponse.data);
                                    return {
                                        ...pickup,
                                        bacto_barcode: pickup.bacto_barcode || '',
                                        bmcc: components.bmcc,
                                        comment: pickup.comment,
                                        compartment: pickup.compartment,
                                        component_barcode: pickup.component_barcode || '',
                                        created_at: pickup.created_at,
                                        deleted_at: pickup.deleted_at,
                                        dip_level: pickup.dip_level ? pickup.dip_level.toString() : '',
                                        driver: [driver],
                                        // dropoff_date: '-1', // Not needed
                                        fat: components.fat,
                                        hourly_production: -1, // TODO
                                        id: pickup.id,
                                        hauling_company: hauler?.name || '',
                                        scale_weight: pickup?.scale_weight || null,
                                        tare_weight: pickup?.tare_weight || null,
                                        is_rejected: pickup.is_rejected,
                                        latitude: pickup.latitude,
                                        longitude: pickup.longitude,
                                        los: components.LOS,
                                        outside: pickup.outside,
                                        partial: pickup.partial,
                                        processor: routeSession.status === 'closed' ? (processorsByPickupId[pickup.id] ? processorsByPickupId[pickup.id] : '') : '',
                                        producer: [_.pick(producer, ['lab_number', 'geofence', 'id', 'latitude', 'longitude', 'name', 'tank', 'CMTNumber', 'number_of_tanks', 'license_number'])],
                                        protein: components.protein,
                                        rejection_reason: pickup.rejection_reason,
                                        route_session: route ? [_.assign(_.pick(routeSession, ['BOL', 'id', 'status', 'route_number']), { route: route.name })] : '',
                                        run_number: pickup.run_number,
                                        scc: components.scc,
                                        slip: pickup.slip,
                                        tank_number: pickup.tank_number,
                                        temperature: pickup.temperature,
                                        trailer: trailer ? parseInt(trailer.trailer_number) : 0, // TODO number or string?
                                        updated_at: pickup.updated_at,
                                        volume: pickup.volume ? pickup.volume.toString() : '',
                                        volume_a: pickup.volume_a,
                                        volume_b: pickup.volume_b,
                                        volume_c: pickup.volume_c,
                                        type_of_fluid: pickup.type_of_fluid,
                                        lactose: components.lactose,
                                        other_solids: region === 'CDI' ? components.solids_not_fat - components.protein : components.other_solids,
                                        solids_not_fat: components.solids_not_fat,
                                        total_solids: components.total_solids,
                                        sample_barcodes: pickup?.sample_barcodes || undefined,
                                    };
                                });

                                const pickupTotals = _.mapValues(_.groupBy(pickups, 'producer_id'), (o) => _.sumBy(o, 'volume'));

                                const producersData = _.map(routeProducers, (producer) => ({
                                    created_at: producer.created_at,
                                    id: producer.id,
                                    latitude: producer.latitude,
                                    longitude: producer.longitude,
                                    producer: [{ name: producer.name }],
                                    number_of_tanks: producer.number_of_tanks,
                                    volume: pickupTotals[producer.id] ? pickupTotals[producer.id].toString() : '0',
                                }));

                                resolve({ pickups: pickupsData, producers: producersData });
                            });
                        })
                    );
                })
            )
            .catch((errors) => {
                // eslint-disable-next-line no-console
                // console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getRouteSessionPickups;
