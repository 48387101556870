import React, { useContext } from 'react';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import parseError from './repository/errors/utils';
import { NotificationContext } from './NotificationContext';
import axios from 'axios';

export const QueryProvider = ({ children }) => {
    const { showSnackbar } = useContext(NotificationContext);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: true,
                refetchOnMount: 'always',
                retry: 1,
            },
        },
        queryCache: new QueryCache({
            onError: (error) => {
                if (axios.isCancel(error)) return;
                const { message } = parseError({ error });
                showSnackbar({ message, severity: 'error' });
            },
        }),
    });
    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

export default QueryProvider;
