import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

/**
 * @type EnvironmentParams['evaluationFn']
 */
const evaluationFn = (attributes) => {
    if (['PRAIRIE'].includes(attributes.region) && ['admin', 'sub-admin'].includes(attributes.role)) {
        return 'on';
    }
    return 'off';
};

const whitelistedEnvironments = [stages.LOCAL, stages.DEV, stages.STAGING, stages.PRODUCTION].map(
    (stage) =>
        new Environment({
            name: stage,
            evaluationFn,
        })
);

const blacklistedEnvironments = [].map(
    (stage) =>
        new Environment({
            name: stage,
            evaluationFn: () => 'off',
        })
);

const can_access_stocks = new MockSplit({
    name: 'can_access_stocks',
    environments: [...whitelistedEnvironments, ...blacklistedEnvironments],
});

export default can_access_stocks;
