import axios from 'axios';
import { getCloseDayQuery } from './queries';

const getCloseDayDetails = async ({ date }) => {
    try {
        const closeDayQuery = getCloseDayQuery({ date });
        const closeDayResponse = await axios.get(closeDayQuery);
        const closeDays = closeDayResponse.data;
        return { closeDays };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getCloseDayDetails;
