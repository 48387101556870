export const getNLLabGraphDetails = (averages) => ({
    fat: !!averages && !!averages.average_fat ? averages.average_fat : 0,
    protein: !!averages && !!averages.average_protein ? averages.average_protein : 0,
    LOS: !!averages && !!averages.average_LOS ? averages.average_LOS : 0,
    scc: !!averages && !!averages.average_scc_2std ? averages.average_scc_2std : 0,
    bactoscan: !!averages && !!averages.average_bacto_2std ? averages.average_bacto_2std : 0,
});

export const getWeightedLabGraphDetails = (averages) => ({
    fat: !!averages && !!averages.weighted_average_fat ? averages.weighted_average_fat : 0,
    protein: !!averages && !!averages.weighted_average_protein ? averages.weighted_average_protein : 0,
    solids_not_fat: !!averages && !!averages.weighted_average_solids_not_fat ? averages.weighted_average_solids_not_fat : 0,
    LOS: !!averages && !!averages.weighted_average_LOS ? averages.weighted_average_LOS : 0,
    scc: !!averages && !!averages.weighted_average_scc ? averages.weighted_average_scc : 0,
    bmcc: !!averages && !!averages.weighted_average_bmcc ? averages.weighted_average_bmcc : 0,
    bactoscan: !!averages && !!averages.weighted_average_bacto ? averages.weighted_average_bacto : 0,
    thermo: !!averages && !!averages.weighted_average_thermo ? averages.weighted_average_thermo : 0,
    lactose: !!averages && !!averages.weighted_average_lactose ? averages.weighted_average_lactose : 0,
    other_solids: !!averages && !!averages.weighted_average_solids_not_fat && !!averages.weighted_average_protein ? averages.weighted_average_solids_not_fat - averages.weighted_average_protein : 0,
});

export const getLabGraphDetails = (averages) => ({
    fat: !!averages && !!averages.average_fat_no_outliers ? averages.average_fat_no_outliers : 0,
    protein: !!averages && !!averages.average_protein_no_outliers ? averages.average_protein_no_outliers : 0,
    LOS: !!averages && !!averages.average_LOS_no_outliers ? averages.average_LOS_no_outliers : 0,
    scc: !!averages && !!averages.average_scc_no_outliers ? averages.average_scc_no_outliers : 0,
    bactoscan: !!averages && !!averages.average_bacto_no_outliers ? averages.average_bacto_no_outliers : 0,
    bmcc: !!averages && !!averages.average_bmcc ? averages.average_bmcc : 0,
    thermo: !!averages && !!averages.average_thermo ? averages.average_thermo : 0,
});

const getDashboardGraphDetails = (averages, region) => {
    const production = !!averages ? (!!averages.total_pickup_volume ? averages.total_pickup_volume : averages.average_pickup_volume && averages.count_pickup_volume ? averages.average_pickup_volume * averages.count_pickup_volume : 0) : 0;
    switch (region) {
        case 'NL':
            return { production, ...getNLLabGraphDetails(averages) };
        case 'PEI':
            return { production, ...getLabGraphDetails(averages) };
        default:
            return { production, ...getWeightedLabGraphDetails(averages) };
    }
};

export default getDashboardGraphDetails;
