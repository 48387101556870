import { FuseLoadable } from '@fuse';

export const Index = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/hauler-rate/:date?',
            component: FuseLoadable({
                loader: () => import('./HaulerRateApp'),
            }),
        },
    ],
};

export default [Index];
