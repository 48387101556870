import React, { useEffect, useState } from 'react';
import { Circle, OverlayView } from '@react-google-maps/api';
import SymbolProcessor from '../symbols/SymbolProcessor';
import SymbolProducer from '../symbols/SymbolProducer';
import { Box } from '@material-ui/core';
import SymbolProducerInactive from '../symbols/SymbolProducerInactive';
import SymbolProcessorInactive from '../symbols/SymbolProcessorInactive';
import { defaultShapeOptions, processorShapeOptions, producerShapeOptions, selectedShapeOptions } from './MapShapes.helpers';

/**
 * MapCircle component is a custom circle component
 *
 * @param {number} index
 * @param {Object} shape
 * @param {string} selectedShape
 * @param {function} handleEditCircleFromMap
 * @param {function} handleCircleLoad
 * @param {Object[]} circleInstances
 * @returns
 */
const MapCircle = ({ index, shape, selectedShape, handleEditCircleFromMap, handleCircleLoad, circleInstances, producerIds = [], processorIds = [] }) => {
    const [isSelected, setIsSelected] = useState(false);
    const [isPickup, setIsPickup] = useState(false);
    const [isDropoff, setIsDropoff] = useState(false);
    const [isProducer, setIsProducer] = useState(false);
    const [isProcessor, setIsProcessor] = useState(false);

    let { center } = shape;
    if (center.coordinates) {
        center = {
            lat: shape.center.coordinates[1],
            lng: shape.center.coordinates[0],
        };
    }

    useEffect(() => {
        const checkConditions = () => {
            const { user_id, geofenceType } = shape;
            if (user_id?.role === 'producer') {
                setIsProducer(true);
                if (producerIds.includes(user_id._id)) {
                    setIsPickup(true);
                }
            }
            if (user_id?.role === 'processor') {
                setIsProcessor(true);
                if (processorIds.includes(user_id._id)) {
                    setIsDropoff(true);
                }
            }
            if (geofenceType === 'pickup') {
                setIsPickup(true);
            }
            if (geofenceType === 'dropoff') {
                setIsDropoff(true);
            }
        };
        checkConditions();
    }, []);

    useEffect(() => {
        setIsSelected(shape._id === selectedShape);
    }, [selectedShape]);

    const handleCircleRadiusChange = () => {
        const circleInstance = circleInstances[shape._id];
        if (!circleInstance) return; // handle onLoad race condition

        const newRadius = circleInstance.getRadius();
        if (newRadius === shape.radius_meters) {
            return;
        }

        handleEditCircleFromMap(index, {
            ...shape,
            radius_meters: Math.floor(newRadius),
        });
    };

    const handleCircleCenterChange = () => {
        const circleInstance = circleInstances[shape._id];
        if (!circleInstance) return; // handle onLoad race condition

        const newCenter = circleInstance.getCenter().toJSON();
        if (newCenter.lat === shape.center?.coordinates?.[1] && newCenter.lng === shape.center?.coordinates?.[0]) {
            return;
        }

        handleEditCircleFromMap(index, {
            ...shape,
            center: {
                type: 'Point',
                coordinates: [Number(newCenter.lng), Number(newCenter.lat)],
            },
        });
    };

    const getPixelPositionOffset = (width, height) => ({
        x: -(width / 2),
        y: -height, // Adjust the value to position the bottom center of the SVG
    });

    return (
        <>
            <Circle
                key={shape._id}
                center={center}
                radius={shape.radius_meters}
                options={{
                    ...defaultShapeOptions,
                    ...(isSelected ? selectedShapeOptions : {}),
                    ...(isPickup ? producerShapeOptions : {}),
                    ...(isDropoff ? processorShapeOptions : {}),
                }}
                editable={isSelected}
                draggable={isSelected}
                /**
                 * The onLoad prop is called when the component is mounted but onCenterChanged
                 *  and onRadiusChanged are called before the component is mounted
                 */
                onLoad={(circle) => handleCircleLoad(circle, shape)}
                onCenterChanged={handleCircleCenterChange}
                onRadiusChanged={handleCircleRadiusChange}
            />
            <OverlayView key={index} position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} getPixelPositionOffset={getPixelPositionOffset}>
                <Box display="flex">{isProducer ? isPickup ? <SymbolProducer /> : <SymbolProducerInactive /> : isProcessor && (isDropoff ? <SymbolProcessor /> : <SymbolProcessorInactive />)}</Box>
            </OverlayView>
        </>
    );
};

export default React.memo(MapCircle);
