import React from 'react';
import { withStyles, Card, Typography, Box } from '@material-ui/core';
import { numberFormat, secondsToTimeConverter, requiresDistanceInMi, requiresVolumeInLbs, getUserRegion } from '../../utils';
import store from '../store';

const DataBox = ({ title, value, trend, twin, colour, status, scc, bacto, fluidSales, driverStats, timeValue, dataType, loading, useDateRange }) => {
    // eslint-disable-next-line no-param-reassign
    value = value === 0 ? (['fat', 'scc', 'bacto_scan', 'bmcc', 'protein', 'los', 'thermo'].includes(trend ? trend.type : '') ? '-' : '0.00') : !!value ? value : null;
    // eslint-disable-next-line no-param-reassign
    timeValue = !!timeValue ? timeValue || '0.00' : null;
    const thisMonth = trend ? parseFloat(trend.thisMonthAmount) : 0;
    const lastMonth = trend ? parseFloat(trend.lastMonthAmount) : 0;
    // values of equal immportance
    const total = twin ? parseFloat(twin.total) : 0;
    const averagePercent = twin ? parseFloat(twin.percent) : 0;
    const averageKg = twin ? parseFloat(twin.kg) : 0;

    const region = getUserRegion();

    return (
        <Card className="w-full rounded-8 shadow-none border-1 py-8 h-full">
            <div className="p-8 flex flex-row flex-wrap items-center">
                <div className="w-full">
                    <Typography className="h2" color="textSecondary">
                        {title}
                    </Typography>
                    {loading && (
                        <div className="animate-pulse">
                            <Box className="py-8 w-full bg-grey-light" />
                            <Box className="py-8 w-full" />
                            <Box className="py-8 w-full bg-grey-light" />
                        </div>
                    )}
                    {!loading && (
                        <div>
                            {value && (
                                <Typography className="text-32 text-bold font-300 leading-none mt-8 pr-12 float-left whitespace-no-wrap" color={colour}>
                                    {value === '-' ? value : !!driverStats && driverStats.type === 'time' ? secondsToTimeConverter(value) : numberFormat(value, trend && trend.decimalPlaces !== undefined ? trend.decimalPlaces : !!driverStats && driverStats.type === 'shrink' && region !== 'RF' ? 4 : 2)}
                                    {!!driverStats && driverStats.type === 'distance'
                                        ? requiresDistanceInMi(region)
                                            ? 'mi'
                                            : 'km'
                                        : ((!!driverStats && driverStats.type === 'shrink') || (!!trend && trend.type === 'fat') || (!!trend && trend.type === 'protein') || (!!trend && trend.type === 'los') || (!!trend && trend.type === 'other_solids') || (!!trend && trend.type === 'snf')) && value !== '-'
                                        ? '%'
                                        : ''}
                                    {dataType === 'volume' ? (requiresVolumeInLbs(region) ? '' : ' ') : dataType === 'distance' ? (requiresDistanceInMi(region) ? ' mi' : ' km') : dataType === 'percent' ? '%' : ''}
                                </Typography>
                            )}

                            {timeValue && (
                                <Typography className="text-32 text-bold font-300 leading-none mt-8 pr-12 float-left" color={colour}>
                                    {secondsToTimeConverter(parseInt(timeValue))}
                                </Typography>
                            )}

                            {fluidSales && (
                                <Typography className="text-32 text-bold font-300 leading-none mt-8 pr-12 float-left" color={colour}>
                                    {fluidSales.value !== '-1' ? numberFormat(fluidSales.value, 3, '.', ',') : '--'}
                                    {fluidSales.value !== '-1' ? fluidSales.capSymbol : ''}
                                </Typography>
                            )}

                            {trend && (
                                <div className="lg:text-60 flex-row float-left">
                                    <div className="lg:text-60 flex">
                                        <Typography className="font-bold ml-4">
                                            {useDateRange ? 'Last Period:' : 'Last Month:'} {numberFormat(lastMonth, trend && trend.decimalPlaces !== undefined ? trend.decimalPlaces : 2)}
                                            {trend.type === 'fat' || trend.type === 'protein' || trend.type === 'los' || trend.type === 'other_solids' || trend.type === 'snf' ? '%' : ''}
                                        </Typography>
                                    </div>
                                    <div className="lg:text-60 flex">
                                        {thisMonth > lastMonth && (
                                            <i
                                                className={
                                                    trend.type === 'scc' || trend.type === 'bacto_scan' || trend.type === 'plc' || trend.type === 'LPC' || trend.type === 'CC' || trend.type === 'bmcc' || trend.type === 'thermo' || trend.type === 'demerit_points' || trend.type === 'SPC'
                                                        ? 'text-red mr-4 material-icons'
                                                        : 'text-green mr-4 material-icons'
                                                }
                                            >
                                                trending_up
                                            </i>
                                        )}
                                        {thisMonth < lastMonth && (
                                            <i
                                                className={
                                                    trend.type === 'scc' || trend.type === 'bacto_scan' || trend.type === 'plc' || trend.type === 'LPC' || trend.type === 'CC' || trend.type === 'bmcc' || trend.type === 'thermo' || trend.type === 'demerit_points' || trend.type === 'SPC'
                                                        ? 'text-green mr-4 material-icons'
                                                        : 'text-red mr-4 material-icons'
                                                }
                                            >
                                                trending_down
                                            </i>
                                        )}
                                        {thisMonth === lastMonth && <i className="text-grey mr-4 material-icons">trending_flat</i>}
                                        <Typography>
                                            {(!!fluidSales && trend.type === 'fluid_sales') || ['fat', 'protein', 'los', 'other_solids', 'snf'].includes(trend.type)
                                                ? numberFormat(Math.abs(thisMonth - lastMonth), trend.type === 'fluid_sales' ? 3 : 2)
                                                : numberFormat(Math.abs(((thisMonth - lastMonth) / lastMonth) * 100), trend.decimalPlaces !== undefined ? trend.decimalPlaces : 2)}
                                            %
                                        </Typography>
                                        <Typography className="ml-4 whitespace-no-wrap"> </Typography>
                                    </div>
                                </div>
                            )}

                            {twin && (
                                <div className="lg:text-60 flex-row float-left">
                                    <div className="lg:text-60 flex">
                                        <Typography className="text-16 text-bold font-300 leading-none mt-8 pr-12 float-left p-4" color={colour}>
                                            {total ? numberFormat(total, twin && twin.decimalPlaces !== undefined ? twin.decimalPlaces : 2) : '-'}
                                            {'Kg This Month'}
                                        </Typography>
                                    </div>
                                    <div className="lg:text-60 flex">
                                        <Typography className="ml-4">
                                            {averagePercent ? numberFormat(averagePercent, twin && twin.decimalPlaces !== undefined ? twin.decimalPlaces : 2) : '-'}
                                            {'% This Month'}
                                        </Typography>
                                    </div>
                                    <div className="lg:text-60 flex">
                                        <Typography className="ml-4">
                                            {averageKg ? numberFormat(averageKg, twin && twin.decimalPlaces !== undefined ? twin.decimalPlaces : 2) : '-'}
                                            {'Kg Per Load'}
                                        </Typography>
                                    </div>
                                </div>
                            )}

                            {driverStats && driverStats.type !== 'shrink' && (
                                <div className="lg:text-50 flex-row float-left">
                                    <div className="lg:text-50 flex">
                                        <Typography className="font-bold ml-2">
                                            Route Average: {driverStats.type === 'time' ? secondsToTimeConverter(driverStats.routeAverage) : numberFormat(driverStats.routeAverage, 2)}
                                            {driverStats.type === 'distance' ? (requiresDistanceInMi(region) ? 'mi' : 'km') : ''}
                                        </Typography>
                                    </div>
                                    <div className="lg:text-50 flex">
                                        {driverStats.total > driverStats.routeAverage && <i className={driverStats.type === 'volume' ? 'text-green mr-4 material-icons' : 'text-red mr-4 material-icons'}>trending_up</i>}
                                        {driverStats.total < driverStats.routeAverage && <i className={driverStats.type === 'volume' ? 'text-red mr-4 material-icons' : 'text-green mr-4 material-icons'}>trending_down</i>}
                                        {driverStats.total === driverStats.routeAverage && <i className="text-grey mr-4 material-icons">trending_flat</i>}
                                        <Typography>{numberFormat(Math.abs(((driverStats.total - driverStats.routeAverage) / driverStats.routeAverage) * 100), 2)} %</Typography>
                                        <Typography className="ml-4 whitespace-no-wrap"> </Typography>
                                    </div>
                                </div>
                            )}

                            {driverStats && driverStats.type === 'shrink' && (
                                <div className="lg:text-50 flex-row float-left">
                                    <div className="lg:text-50 flex">
                                        <Typography className="font-bold ml-2">Differential: {numberFormat(driverStats.differential, 2)}</Typography>
                                    </div>
                                </div>
                            )}

                            {status && scc && (
                                <div
                                    style={{
                                        fontSize: '20px',
                                        fontWeight: 'normal',
                                        paddingTop: '12px',
                                    }}
                                >
                                    SCC: &nbsp;
                                    {!scc.status ? (
                                        <div style={{ display: 'inline-block' }}>
                                            <img width="75" src="assets/MMAssets/icons/SAFE.jpg" alt="logo" />
                                            <i>{scc.percentage}%</i>
                                        </div>
                                    ) : scc.status > 1 ? (
                                        <div style={{ display: 'inline-block' }}>
                                            <img width="75" src="assets/MMAssets/icons/PENALTY.jpg" alt="logo" />
                                            <i>{scc.percentage}%</i>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'inline-block' }}>
                                            <img width="75" src="assets/MMAssets/icons/WARNING.jpg" alt="logo" />
                                            <i>{scc.percentage}%</i>
                                        </div>
                                    )}
                                    <br />
                                    BactoScan: &nbsp;
                                    {!bacto.status ? (
                                        <div style={{ display: 'inline-block' }}>
                                            <img width="75" src="assets/MMAssets/icons/SAFE.jpg" alt="logo" />
                                            <i>{bacto.percentage}%</i>
                                        </div>
                                    ) : bacto.status > 1 ? (
                                        <div style={{ display: 'inline-block' }}>
                                            <img width="75" src="assets/MMAssets/icons/PENALTY.jpg" alt="logo" />
                                            <i>{bacto.percentage}%</i>
                                        </div>
                                    ) : (
                                        <div style={{ display: 'inline-block' }}>
                                            <img width="75" src="assets/MMAssets/icons/WARNING.jpg" alt="logo" />
                                            <i>{bacto.percentage}%</i>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Card>
    );
};

export default withStyles(null, { withTheme: true })(DataBox);
