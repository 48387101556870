import { AbilityBuilder, PureAbility } from '@casl/ability';

function UDACloseMonth(user) {
    const { can, cannot, rules } = new AbilityBuilder(PureAbility);
    const {
        role,
        data: { region, id, processor_id, permissions },
    } = user;

    if (region === 'UDA') {
        if (permissions.includes('UDAManageCloseMonth')) {
            can('mutate', 'close_month');
        }
    }

    return rules;
}

export default UDACloseMonth;
