import DriverPerformanceConfig from './driver-performance';
import DriverStatsConfig from './driver-stats';
import GeneralStatsConfig from './general-stats';
import PEIReportConfig from './peiReport';
import PickupsProcessorConfig from './pickups-for-processor';
import BaseTransfersConfig from './base-transfers';
import RouteStatsConfig from './route-stats';
import RoutesConfig from './routes';
import SchedulingRoutesConfig from './scheduling-routes';
import UploadDownloadConfig from './upload-download';
import LabChartApp from './lab-charts';
import DropoffStatsApp from './dropoff-stats';
import PickupStatsApp from './pickups-stats';
import ProductionStatsConfig from './production-stats';
import ProcessorStatsConfig from './processor-stats';
import HaulingStatsApp from './hauling-stats';
import CreateUDAMilkPriceApp from './milk-prices';
import CIPWashesConfig from './cip-washes';
import DeferralsConfig from './deferrals';
import AssigneeTableConfig from './assignees';
import AssignmentTableConfig from './assignments';
import MerchandiseConfig from './merchandise';
import PoolingConfig from './pooling';
import CloseDayConfig from './close-day';
import CloseMonthConfig from './close-month';
import SplitProducerApp from './split-producers';
import HaulingChargesApp from './hauling-charges';
import HaulerRateApp from './hauler-rates';
import UserGroupsApp from './user-groups';
import PaymentStatusApp from './payment-status';
import ConfigurePresetButtons from './configure-preset-buttons';
import HaulingContractApp from './hauling-contracts';
import ProcessorFmmoMonthlyTotalsApp from './processor-fmmo-monthly-totals';
import ClosePeriodApp from './close-period';
import RouteCenterpointApp from './route-centerpoints';

export default [
    ...DriverPerformanceConfig,
    ...DriverStatsConfig,
    ...BaseTransfersConfig,
    ...GeneralStatsConfig,
    ...PEIReportConfig,
    ...PickupsProcessorConfig,
    ...RouteStatsConfig,
    ...RoutesConfig,
    ...SchedulingRoutesConfig,
    ...UploadDownloadConfig,
    ...LabChartApp,
    ...DropoffStatsApp,
    ...PickupStatsApp,
    ...ProductionStatsConfig,
    ...ProcessorStatsConfig,
    ...HaulingStatsApp,
    ...CreateUDAMilkPriceApp,
    ...CIPWashesConfig,
    ...DeferralsConfig,
    ...AssigneeTableConfig,
    ...AssignmentTableConfig,
    ...MerchandiseConfig,
    ...PoolingConfig,
    ...CloseDayConfig,
    ...CloseMonthConfig,
    ...SplitProducerApp,
    ...HaulingChargesApp,
    ...HaulerRateApp,
    ...UserGroupsApp,
    ...PaymentStatusApp,
    ...ConfigurePresetButtons,
    ...HaulingContractApp,
    ...ProcessorFmmoMonthlyTotalsApp,
    ...ClosePeriodApp,
    ...RouteCenterpointApp,
];
