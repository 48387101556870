import React from 'react';
import { Grid } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';

const PrairieFarmsRbstPremium = () => (
    <Grid item xs={12} sm={3}>
        <ReactHookFormInput label="rBST Free ($/cwt)" name={'milk_prices.rbst_free_premium'} type="number" />
    </Grid>
);

export default PrairieFarmsRbstPremium;
