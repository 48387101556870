import React from 'react';
import DataItem from './data-helpers/DataItem';
import ObjectDataItem from './data-helpers/ObjectDataItem';

const DocumentHistoryItemData = ({ name, data, asNew }) => {
    if (!data && typeof data !== 'number') {
        return <></>;
    }

    if (Array.isArray(data)) {
        return (
            <>
                {data.map((item) => {
                    if (typeof item === 'object') {
                        return <ObjectDataItem data={data} asNew={asNew} />;
                    }
                    return <DataItem name={name} data={data} asNew={asNew} sx={{ marginBottom: '5px' }} />;
                })}
            </>
        );
    }

    if (typeof data === 'object' && !Array.isArray(data)) {
        return <ObjectDataItem data={data} asNew={asNew} />;
    }

    return <DataItem name={name} data={data} asNew={asNew} />;
};

export default DocumentHistoryItemData;
