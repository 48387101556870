import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

const Bays = { auth: [...authRoles.admin, ...authRoles.processor], routes: [{ path: '/bays/:id?', component: FuseLoadable({ loader: () => import('./ProcessorBaysPage') }) }] };

const CreateBay = { auth: [...authRoles.admin, ...authRoles.processor], routes: [{ path: '/create-bay/:processor_id', component: FuseLoadable({ loader: () => import('./MutateBay') }) }] };

const EditBay = { auth: [...authRoles.admin, ...authRoles.processor], routes: [{ path: '/edit-bay/:id', component: FuseLoadable({ loader: () => import('./MutateBay') }) }] };

export default [Bays, CreateBay, EditBay];
