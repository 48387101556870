import { buildExpressBulkQuery } from './builders';
import store from '../../store';

/**
 * Designed to be used in place of batchQuery as a direct plug and play.
 *
 * @param {function} queryFn
 * @param {object} params
 * @returns {query} query to be POSTed
 */
const bulkQuery = (queryFn, params) => {
    const { region } = store.getState().persisted.auth.user.data;
    const newParams = params;
    newParams.queryBuilder = buildExpressBulkQuery;
    const query = queryFn(newParams);
    return query;
};

export default bulkQuery;
