/**
 * Determines whether dropoff is of type meter/scale and calulcates shrink amount accordingly
 * @param {Dropoff} dropoff
 * @returns {number} shrink amount
 */
export const calculateShrinkAmount = (dropoff) => {
    if (!dropoff.volume) return 0;
    let actualDelivered = 0;
    if (dropoff.metered) actualDelivered = dropoff.metered;
    else if (dropoff.net_weight) actualDelivered = dropoff.net_weight;
    return dropoff.volume - actualDelivered;
};

/**
 * Determines whether dropoff is of type meter/scale and calulcates shrink percentage accordingly
 * @param {Dropoff} dropoff
 * @returns {number} shrink percentage
 */
export const calculateShrinkPercentage = (dropoff) => {
    if (!dropoff.volume) return 0;
    const shrinkAmount = calculateShrinkAmount(dropoff);
    return (shrinkAmount / dropoff.volume) * 100;
};
