/**
 * Authorization Roles
 */
const authRoles = {
    admin: ['admin', 'sub-admin', 'producer_business'],
    subAdmin: ['sub-admin'],
    producer: ['admin', 'producer', 'sub-producer', 'producer_business'],
    subProducer: ['sub-producer'],
    hauling: ['admin', 'transport', 'sub-transport'],
    subHauling: ['sub-transport'],
    processor: ['admin', 'processor', 'sub-processor'],
    subProcessor: ['sub-processor'],
    labProvider: ['admin', 'lab-provider', 'sub-lab-provider'],

    everyoneButGuest: ['admin', 'sub-admin', 'producer', 'sub-producer', 'producer_business', 'processor', 'sub-processor', 'transport', 'sub-transport', 'lab-provider'],
    onlyGuest: ['guest'],
};

export default authRoles;
