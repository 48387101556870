const FMMO = {
    Nonpooled: -1,
    Depooled: 0,
    Northeast: 1,
    Appalachian: 5,
    Florida: 6,
    Southeast: 7,
    UpperMidwest: 30,
    Central: 32,
    Mideast: 33,
    California: 51,
    PacificNorthwest: 124,
    Southwest: 126,
    WesternNewYork: 127,
    Arizona: 131,
};

export default FMMO;
