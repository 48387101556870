import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getLabReportsQuery, getUsersQuery, getMonthlyAveragesQuery, getRouteSessionsQuery, getPickupsQuery } from './queries';
import { buildExpressQuery } from './queries/builders';
import { arrayToMap, renameKey, getPrairieTestType } from '../../utils';
import { getBulkQueryData } from './utils';

const getMonthAverages = async (id, region, start, end) => {
    try {
        const labReportQuery = start ? getLabReportsQuery({ producerIds: [id], start, end, queryBuilder: buildExpressQuery, filterDeleted: true }) : getLabReportsQuery({ producerIds: [id], queryBuilder: buildExpressQuery, filterDeleted: true });
        const labReportRequest = axios.get(labReportQuery);

        const pickupsQuery = getPickupsQuery({ fields: ['id', 'route_session_id', 'volume'], producerId: id, start, end, filterDeleted: true, queryBuilder: buildExpressQuery });
        const pickupsRequest = axios.get(pickupsQuery);

        const producerMonthlyAveragesQuery = getMonthlyAveragesQuery({
            userIds: [id],
            start: moment(start).format('YYYY-MM'),
            end: moment(end).format('YYYY-MM'),
        });
        const producerMonthlyAveragesRequest = axios.get(producerMonthlyAveragesQuery);

        const industryMonthlyAveragesQuery = getMonthlyAveragesQuery({ start: moment(start).format('YYYY-MM'), end: moment(end).format('YYYY-MM') });
        const industryMonthlyAveragesRequest = axios.get(industryMonthlyAveragesQuery);

        const [labReportsResponse, pickupsResponse, producerAverageResponse, industryAverageResponse] = await Promise.all([labReportRequest, pickupsRequest, producerMonthlyAveragesRequest, industryMonthlyAveragesRequest]);
        const labReports = labReportsResponse.data;
        const pickups = pickupsResponse.data;
        const [producerAverages] = producerAverageResponse.data;
        const [industryAverages] = industryAverageResponse.data;

        renameKey(labReports, '_id', 'id');
        renameKey(pickups, '_id', 'id');

        const routeSessionIds = _.uniq(_.map(pickups, 'route_session_id'));
        const routeSessions = await getBulkQueryData(getRouteSessionsQuery, { ids: routeSessionIds, fields: ['id', 'BOL'] });

        const pickupsMap = arrayToMap(pickups, 'id');
        const routeSessionsMap = arrayToMap(routeSessions, 'id');

        const producerIds = _.uniq(_.map(labReports, 'producer_id'));
        const producers = await getBulkQueryData(getUsersQuery, { fields: ['id', 'name'], ids: producerIds }, 'ids');
        const producersMap = arrayToMap(producers, 'id');

        labReports.forEach((lab, index) => {
            const pickup = pickupsMap?.[labReports[index]?.pickup_id] || null;
            labReports[index].pickup = pickup || '';
            labReports[index].volume = pickup?.volume || '';
            const routeSession = routeSessionsMap?.[pickup?.route_session_id] || null;
            labReports[index].BOL = routeSession?.BOL || '';
            const testType = getPrairieTestType(lab);
            labReports[index].test_type = testType || '';
            const producer = [producersMap[lab.producer_id]];
            labReports[index].producer = producer || '';
        });

        // TODO refactor this !!
        const avgFat = industryAverages ? (['NL'].includes(region) ? industryAverages.average_fat : ['UDA'].includes(region) ? industryAverages.weighted_average_fat : industryAverages.average_fat_no_outliers) : 0;
        const avgLactose = industryAverages ? (['UDA'].includes(region) ? industryAverages.average_lactose : industryAverages.average_lactose_no_outliers) : 0;
        const avgScc = industryAverages ? (['NL'].includes(region) ? industryAverages.average_scc_2std : ['UDA'].includes(region) ? industryAverages.weighted_average_scc : industryAverages.average_scc_no_outliers) : 0;
        const avgBacto = industryAverages ? (['NL'].includes(region) ? industryAverages.average_bacto_2std : industryAverages.average_bacto_no_outliers) : 0;
        const avgProtein = industryAverages ? (['NL'].includes(region) ? industryAverages.average_protein_2std : ['UDA'].includes(region) ? industryAverages.weighted_average_protein : industryAverages.average_protein_no_outliers) : 0;
        const avgLos = industryAverages ? (['NL'].includes(region) ? industryAverages.average_LOS_2std : industryAverages.average_LOS_no_outliers) : 0;
        const avgBmcc = industryAverages && industryAverages.average_bmcc ? industryAverages.average_bmcc : 0;
        const avgProduction = industryAverages ? (industryAverages.total_pickup_volume ? industryAverages.total_pickup_volume : industryAverages.average_pickup_volume && industryAverages.count_pickup_volume ? industryAverages.average_pickup_volume * industryAverages.count_pickup_volume : 0) : 0;
        const avgThermo = industryAverages && industryAverages.average_thermo ? industryAverages.average_thermo : 0;
        const ownAvgFat = producerAverages && producerAverages.average_fat_no_outliers ? (['RF', 'UDA'].includes(region) ? producerAverages.weighted_average_fat : producerAverages.average_fat_no_outliers) : 0;
        const ownFatPerDay = producerAverages && producerAverages.fat_by_day ? producerAverages.fat_by_day : [];
        const ownAvgScc = producerAverages && producerAverages.average_scc_no_outliers ? (['UDA'].includes(region) ? producerAverages.weighted_average_scc : producerAverages.average_scc_no_outliers) : 0;
        const ownAvgBacto = producerAverages && producerAverages.average_bacto_no_outliers ? producerAverages.average_bacto_no_outliers : 0;
        const ownBactoPerDay = producerAverages && producerAverages.bacto_by_day ? producerAverages.bacto_by_day : [];
        const ownAvgProtein = producerAverages && producerAverages.average_protein_no_outliers ? (['RF', 'UDA'].includes(region) ? producerAverages.weighted_average_protein : producerAverages.average_protein_no_outliers) : 0;
        const ownProteinPerDay = producerAverages && producerAverages.protein_by_day ? producerAverages.protein_by_day : [];
        const ownAvgBmcc = producerAverages && producerAverages.average_bmcc ? producerAverages.average_bmcc : 0;
        const ownBmccPerDay = producerAverages && producerAverages.bmcc_by_day ? producerAverages.bmcc_by_day : [];
        const ownAvgThermo = producerAverages && producerAverages.average_thermo ? producerAverages.average_thermo : 0;
        const ownThermoPerDay = producerAverages && producerAverages.thermo_by_day ? producerAverages.thermo_by_day : [];
        const ownAvgTemp = producerAverages && producerAverages.average_temperature ? producerAverages.average_temperature : 0;
        const ownTempPerDay = producerAverages && producerAverages.temperature_by_day ? producerAverages.temperature_by_day : [];
        const ownAvgLos = producerAverages && producerAverages.average_LOS ? producerAverages.average_LOS : 0;
        const ownLosPerDay = producerAverages && producerAverages.LOS_by_day ? producerAverages.LOS_by_day : [];
        const totalMonthlyProduction = producerAverages ? (producerAverages.total_pickup_volume ? producerAverages.total_pickup_volume : producerAverages.average_pickup_volume && producerAverages.count_pickup_volume ? producerAverages.average_pickup_volume * producerAverages.count_pickup_volume : 0) : 0;
        const dailyProduction = producerAverages && producerAverages.production_by_day ? producerAverages.production_by_day : 0;
        // TODO update these using producer averages once back-end changes are complete
        const ownPlcReports = _.filter(labReports, (report) => !!report.bacteria_PLC);
        const ownAvgPlc = _.meanBy(ownPlcReports, 'bacteria_PLC') || 0;
        const ownLpcReports = _.filter(labReports, (report) => !!report.lab_pasteurization_count);
        const ownAvgLpc = _.meanBy(ownLpcReports, 'lab_pasteurization_count') || 0;
        const ownCCReports = _.filter(labReports, (report) => !!report.coliform_count);
        const ownAvgCC = _.meanBy(ownCCReports, 'coliform_count') || 0;
        const ownColostrumReports = _.filter(labReports, (report) => !!report.colostrum_percentage);
        const ownAvgColostrum = _.meanBy(ownColostrumReports, 'colostrum_percentage') || 0;
        const ownLactoseReports = _.filter(labReports, (report) => !!report.lactose);
        const ownAvgLactose = _.meanBy(ownLactoseReports, 'lactose') || 0;
        const ownLactosePerDay = producerAverages && producerAverages.lactose_by_day ? producerAverages.lactose_by_day : [];
        const ownSPCReports = _.filter(labReports, (report) => !!report.standard_plate_count);
        const ownAvgSPC = _.meanBy(ownSPCReports, 'standard_plate_count') * 1000 || 0;
        const ownData = {
            fat: ownAvgFat,
            fat_by_day: ownFatPerDay,
            lactose: ownAvgLactose,
            lactose_by_day: ownLactosePerDay,
            bacto: ownAvgBacto,
            bacto_by_day: ownBactoPerDay,
            scc: ownAvgScc,
            protein: ownAvgProtein,
            protein_by_day: ownProteinPerDay,
            los: ownAvgLos,
            los_by_day: ownLosPerDay,
            plc: ownAvgPlc,
            lpc: ownAvgLpc,
            cc: ownAvgCC,
            spc: ownAvgSPC,
            bmcc: ownAvgBmcc,
            bmcc_by_day: ownBmccPerDay,
            thermo: ownAvgThermo,
            thermo_by_day: ownThermoPerDay,
            temp: ownAvgTemp,
            temperature_by_day: ownTempPerDay,
            colostrum: ownAvgColostrum,
            production: totalMonthlyProduction,
            dailyProduction,
        };
        return {
            fat: avgFat || 0,
            lactose: avgLactose || 0,
            bacto: avgBacto || 0,
            scc: avgScc || 0,
            protein: avgProtein || 0,
            bmcc: avgBmcc || 0,
            thermo: avgThermo || 0,
            production: avgProduction || 0,
            los: avgLos || 0,
            relevantReports: labReports,
            ownData,
        };
    } catch (errors) {
        // eslint-disable-next-line no-console
        console.log(errors);
        throw new Error('Unable to process request.');
    }
};

const getProducerLabDetails = ({ id, region, start, end, months }) =>
    new Promise((resolve, reject) => {
        const monthAveragePromises = [...Array(months).keys()].map((__, index) => getMonthAverages(id, region, moment(start).subtract(index, 'month').format(), moment(end).subtract(index, 'month').format()));
        Promise.all(monthAveragePromises).then((response) => resolve(response));
    });

export default getProducerLabDetails;
