import store from 'app/store';
import authRoles from 'app/auth/authRoles';
import { FuseLoadable } from '@fuse';

const ListAdminAppConfig = {
    auth: authRoles.everyoneButGuest,
    routes: [
        {
            path: '/manage/sub-users',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                            return import('./AdminManageSubUserApp');
                        case 'producer':
                        case 'sub-producer':
                            return import('./ProducerManageSubUserApp');
                        case 'processor':
                        case 'sub-processor':
                            return import('./ProcessorManageSubUserApp');
                        case 'transport':
                        case 'sub-transport':
                            return import('./HaulingManageSubUserApp');
                        default:
                            break;
                    }
                },
            }),
        },
        {
            path: '/manage/link-users',
            component: FuseLoadable({ loader: () => import('./AdminLinkedUserApp') }),
        },
        {
            path: '/manage/create-link-user',
            component: FuseLoadable({ loader: () => import('../create/CreateMultisiteUserLinkApp') }),
        },
    ],
};

export default [ListAdminAppConfig];
