import React from 'react';
import { RegisterMFA } from '@milkmoovement/common_cognito_package';
import { useHistory } from 'react-router-dom';
import { onCognitoLogin } from '../../../auth/store/actions';
import { useDispatch } from 'react-redux';
import { getRegion } from '../../../../utils';

const RegisterMFAPage = () => {
    const region = getRegion(window.location.hostname);
    const history = useHistory();
    const dispatch = useDispatch();
    const handleNavigateBack = () => history.push({ pathname: '/login' });
    const handleSucessfulLogin = async () => {
        dispatch(await onCognitoLogin());
    };

    return (
        <RegisterMFA
            onSucessfulLogin={handleSucessfulLogin}
            onNavigateBack={handleNavigateBack}
            metadata={{
                coreSystem: region,
            }}
        />
    );
};

export default RegisterMFAPage;
