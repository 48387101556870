import React from 'react';
import { Paper } from '@material-ui/core';
import Draggable from 'react-draggable';

/**
 *
 * @param {*} props
 * @returns Component used as the PaperComponent for a draggable Dialog
 */
const PaperComponent = (props) => (
    <Draggable handle="#draggable-dialog-title">
        <Paper {...props} />
    </Draggable>
);

export default PaperComponent;
