/* eslint-disable import/prefer-default-export */
export const createBasisTheoryToken = async (bt, data, region) => {
    const res = await bt.tokens.create({
        type: 'bank',
        data,
        metadata: { region },
        containers: [`/${region}/bank/high/`],
    });

    const sharedResponseData = {
        token_id: res.id,
        type: res.type,
        fingerprint: res.fingerprint,
    };

    return {
        bank_account_number: {
            ...sharedResponseData,
            masked_data: res.data.account_number,
        },
        bank_routing_number: {
            ...sharedResponseData,
            masked_data: res.data.routing_number,
        },
    };
};

export const createSocialSecurityToken = async (bt, data, region) => {
    const res = await bt.tokens.create({
        type: 'social_security_number',
        data,
        metadata: { region },
        containers: [`/${region}/pii/high/`],
    });

    const sharedResponseData = {
        token_id: res.id,
        type: res.type,
        fingerprint: res.fingerprint,
    };

    return {
        ...sharedResponseData,
        masked_data: res.data,
    };
};

export const createEmployeeIdToken = async (bt, data, region) => {
    const res = await bt.tokens.create({
        type: 'employer_id_number',
        data,
        metadata: { region },
        containers: [`/${region}/pii/high/`],
    });

    const sharedResponseData = {
        token_id: res.id,
        type: res.type,
        fingerprint: res.fingerprint,
    };

    return {
        ...sharedResponseData,
        masked_data: res.data,
    };
};
