import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

/**
 * Enable Processed Fluids in Staging and Dev environment (this needs to be manually plugged in where needed)
 * Places where used should be removed once the feature is fully tested and ready to be released
 */

const prod = new Environment({
    name: stages.PRODUCTION,
    evaluationFn: (attributes) => {
        return 'off';
    },
});

const demo = new Environment({
    ...prod,
    name: stages.DEMO,
});

const staging = new Environment({
    evaluationFn: (attributes) => {
        return 'on';
    },
    name: stages.STAGING,
});

const local = new Environment({
    ...staging,
    name: stages.LOCAL,
});

const dev = new Environment({
    ...staging,
    name: stages.DEV,
});

const dark_launch_processed_fluids = new MockSplit({
    name: 'dark_launch_processed_fluids',
    environments: [local, dev, staging, demo, prod],
});

export default dark_launch_processed_fluids;
