import axios from 'axios';
import { getDropoffStatisticsQuery } from './queries';

const getDropoffStatistics = async (start, end, haulingId) => {
    try {
        const dropoffStatisticsRequest = getDropoffStatisticsQuery({ start, end, haulingId });
        return (await axios.get(dropoffStatisticsRequest)).data;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getDropoffStatistics;
