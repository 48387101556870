import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import { getProducersQuery, getPickupsQuery, getLabReportsQuery, getMonthlyAveragesQuery, getMonthlyQuotaTotalsQuery, getReceivedFilesQuery, getMonthlyReportsQuery } from './queries';
import { getDashboardGraphDetails } from './utils';
import { buildExpressQuery } from './queries/builders';
import { arrayToMap, combinePickups, renameKey, getId } from '../../utils';

const generateEmptyGraphDetailsArray = () => Array(12).fill(0);

/* eslint-disable */
const initializeData = () => ({
    fluidSales: { thisMonth: '0', lastMonth: '0' },
    fluidSalesChange: 0,
    lastPickup: {
        time: 'N/A',
        volume: 0,
    },
    monthlyReports: [],
    lastLab: {
        fat: 0,
        lactose: 0,
        protein: 0,
        scc: 0,
        bmcc: 0,
        bacto: 0,
        thermo: 0,
        temp: 0,
        date: 'N/A',
        lactose: 0,
    },
    demeritPoints: 0,
    receivedFiles: [],
    producerBFQuota: 0,
    productionDetails: {
        averageDay: {
            currentMonth: 0,
            previousMonth: 0,
        },
        averageWeek: {
            currentMonth: 0,
            previousMonth: 0,
        },
        totalMonth: {
            currentMonth: 0,
            previousMonth: 0,
        },
    },
    hasDistrictAverages: false,
    graphDetails: {
        lastYear: {
            production: generateEmptyGraphDetailsArray(),
            fat: generateEmptyGraphDetailsArray(),
            lactose: generateEmptyGraphDetailsArray(),
            protein: generateEmptyGraphDetailsArray(),
            scc: generateEmptyGraphDetailsArray(),
            bmcc: generateEmptyGraphDetailsArray(),
            bactoscan: generateEmptyGraphDetailsArray(),
            protein: generateEmptyGraphDetailsArray(),
            thermo: generateEmptyGraphDetailsArray(),
        },
        thisYear: {
            production: generateEmptyGraphDetailsArray(),
            fat: generateEmptyGraphDetailsArray(),
            lactose: generateEmptyGraphDetailsArray(),
            protein: generateEmptyGraphDetailsArray(),
            scc: generateEmptyGraphDetailsArray(),
            bmcc: generateEmptyGraphDetailsArray(),
            bactoscan: generateEmptyGraphDetailsArray(),
            protein: generateEmptyGraphDetailsArray(),
            thermo: generateEmptyGraphDetailsArray(),
        },
        industry: {
            lastYear: {
                production: generateEmptyGraphDetailsArray(),
                fat: generateEmptyGraphDetailsArray(),
                lactose: generateEmptyGraphDetailsArray(),
                protein: generateEmptyGraphDetailsArray(),
                scc: generateEmptyGraphDetailsArray(),
                bmcc: generateEmptyGraphDetailsArray(),
                bactoscan: generateEmptyGraphDetailsArray(),
                protein: generateEmptyGraphDetailsArray(),
                thermo: generateEmptyGraphDetailsArray(),
            },
            thisYear: {
                production: generateEmptyGraphDetailsArray(),
                fat: generateEmptyGraphDetailsArray(),
                lactose: generateEmptyGraphDetailsArray(),
                protein: generateEmptyGraphDetailsArray(),
                scc: generateEmptyGraphDetailsArray(),
                bmcc: generateEmptyGraphDetailsArray(),
                bactoscan: generateEmptyGraphDetailsArray(),
                protein: generateEmptyGraphDetailsArray(),
                thermo: generateEmptyGraphDetailsArray(),
            },
        },
        district: {
            lastYear: {
                production: generateEmptyGraphDetailsArray(),
                fat: generateEmptyGraphDetailsArray(),
                lactose: generateEmptyGraphDetailsArray(),
                protein: generateEmptyGraphDetailsArray(),
                scc: generateEmptyGraphDetailsArray(),
                bmcc: generateEmptyGraphDetailsArray(),
                bactoscan: generateEmptyGraphDetailsArray(),
                protein: generateEmptyGraphDetailsArray(),
                thermo: generateEmptyGraphDetailsArray(),
            },
            thisYear: {
                production: generateEmptyGraphDetailsArray(),
                fat: generateEmptyGraphDetailsArray(),
                lactose: generateEmptyGraphDetailsArray(),
                protein: generateEmptyGraphDetailsArray(),
                scc: generateEmptyGraphDetailsArray(),
                bmcc: generateEmptyGraphDetailsArray(),
                bactoscan: generateEmptyGraphDetailsArray(),
                protein: generateEmptyGraphDetailsArray(),
                thermo: generateEmptyGraphDetailsArray(),
            },
        },
    },
    currentIndustryDetails: {
        previousMonth: {
            fat: [],
            lactose: [],
            scc: [],
            bmcc: [],
            bacto_scan: [],
            protein: [],
            thermo: [],
            fatTotal: 0,
            lactoseTotal: 0,
            sccTotal: 0,
            bmccTotal: 0,
            bactoTotal: 0,
            proteinTotal: 0,
            thermoTotal: 0,
        },
        currentMonth: {
            fat: [],
            lactose: [],
            scc: [],
            bmcc: [],
            bacto_scan: [],
            protein: [],
            thermo: [],
            fatTotal: 0,
            lactoseTotal: 0,
            sccTotal: 0,
            bmccTotal: 0,
            bactoTotal: 0,
            proteinTotal: 0,
            thermoTotal: 0,
        },
    },
    previousIndustryDetails: {
        previousMonth: {
            fat: [],
            lactose: [],
            scc: [],
            bmcc: [],
            bacto_scan: [],
            protein: [],
            thermo: [],
            fatTotal: 0,
            lactoseTotal: 0,
            sccTotal: 0,
            bmccTotal: 0,
            bactoTotal: 0,
            proteinTotal: 0,
            thermoTotal: 0,
        },
        currentMonth: {
            fat: [],
            lactose: [],
            scc: [],
            bmcc: [],
            bacto_scan: [],
            protein: [],
            thermo: [],
            fatTotal: 0,
            lactoseTotal: 0,
            sccTotal: 0,
            bmccTotal: 0,
            bactoTotal: 0,
            proteinTotal: 0,
            thermoTotal: 0,
        },
    },
    currentDistrictDetails: {
        previousMonth: {
            fat: [],
            lactose: [],
            scc: [],
            bmcc: [],
            bacto_scan: [],
            protein: [],
            thermo: [],
            fatTotal: 0,
            lactoseTotal: 0,
            sccTotal: 0,
            bmccTotal: 0,
            bactoTotal: 0,
            proteinTotal: 0,
            thermoTotal: 0,
        },
        currentMonth: {
            fat: [],
            lactose: [],
            scc: [],
            bmcc: [],
            bacto_scan: [],
            protein: [],
            thermo: [],
            fatTotal: 0,
            lactoseTotal: 0,
            sccTotal: 0,
            bmccTotal: 0,
            bactoTotal: 0,
            proteinTotal: 0,
            thermoTotal: 0,
        },
    },
    previousDistrictDetails: {
        previousMonth: {
            fat: [],
            lactose: [],
            scc: [],
            bmcc: [],
            bacto_scan: [],
            protein: [],
            thermo: [],
            fatTotal: 0,
            lactoseTotal: 0,
            sccTotal: 0,
            bmccTotal: 0,
            bactoTotal: 0,
            proteinTotal: 0,
            thermoTotal: 0,
        },
        currentMonth: {
            fat: [],
            lactose: [],
            scc: [],
            bmcc: [],
            bacto_scan: [],
            protein: [],
            thermo: [],
            fatTotal: 0,
            lactoseTotal: 0,
            sccTotal: 0,
            bmccTotal: 0,
            bactoTotal: 0,
            proteinTotal: 0,
            thermoTotal: 0,
        },
    },
    currentProducerDetails: {
        previousMonth: {
            fat: [],
            lactose: [],
            scc: [],
            bmcc: [],
            bacto_scan: [],
            protein: [],
            thermo: [],
            fatTotal: 0,
            lactoseTotal: 0,
            sccTotal: 0,
            bmccTotal: 0,
            bactoTotal: 0,
            proteinTotal: 0,
            thermoTotal: 0,
        },
        currentMonth: {
            fat: [],
            lactose: [],
            scc: [],
            bmcc: [],
            bacto_scan: [],
            protein: [],
            fluid_production: [],
            thermo: [],
            fatTotal: 0,
            lactoseTotal: 0,
            sccTotal: 0,
            bmccTotal: 0,
            bactoTotal: 0,
            proteinTotal: 0,
            thermoTotal: 0,
        },
    },
    previousProducerDetails: {
        previousMonth: {
            fat: [],
            lactose: [],
            scc: [],
            bmcc: [],
            bacto_scan: [],
            protein: [],
            thermo: [],
            fatTotal: 0,
            lactoseTotal: 0,
            sccTotal: 0,
            bmccTotal: 0,
            bactoTotal: 0,
            proteinTotal: 0,
            thermoTotal: 0,
        },
        currentMonth: {
            fat: [],
            lactose: [],
            scc: [],
            bmcc: [],
            bacto_scan: [],
            protein: [],
            fluid_production: [],
            thermo: [],
            fatTotal: 0,
            lactoseTotal: 0,
            sccTotal: 0,
            bmccTotal: 0,
            bactoTotal: 0,
            proteinTotal: 0,
            thermoTotal: 0,
        },
    },
    tableDetails: {
        pickups: [],
        labs: [],
        combined: [],
    },
});
/* eslint-enable */

const getDailyDataArray = (date, datumByDay) => {
    const daysInMonth = moment(date, 'YYYY-MM').daysInMonth();
    const arr = Array(daysInMonth).fill(null);
    const days = _.keys(datumByDay);
    _.forEach(days, (day) => {
        const index = parseInt(day) - 1;
        arr[index] = datumByDay[day];
    });
    return arr;
};

const samedayPickupReducer = (acc, val) => {
    if (acc.length === 0) return [val];
    const last = acc.slice(-1)[0];
    if (moment(val.created_at).isSame(last.created_at, 'day')) {
        last.volume += val.volume;
        last.volume_a += val.volume_a;
        last.volume_b += val.volume_b;
        last.volume_c += val.volume_c;
        return acc;
    }
    return [...acc, val];
};

const averageVolumeReducer = (acc, val) => {
    if (acc.length === 0) return [val];
    const last = acc.slice(-1)[0];
    const first = moment(val.created_at).format('YYYY-MM-DD');
    const second = moment(last.created_at).format('YYYY-MM-DD');
    const diff = moment(first).diff(second, 'days');
    return [...acc, { ...val, average_volume: val.volume / diff }];
};

const getMonthDetails = (averages, date) => {
    const fat = !!averages && !!averages.fat_by_day ? getDailyDataArray(date, averages.fat_by_day) : getDailyDataArray(date, {});
    const lactose = !!averages && !!averages.lactose_by_day ? getDailyDataArray(date, averages.lactose_by_day) : getDailyDataArray(date, {});
    const scc = !!averages && !!averages.scc_by_day ? getDailyDataArray(date, averages.scc_by_day) : getDailyDataArray(date, {});
    const bmcc = !!averages && !!averages.bmcc_by_day ? getDailyDataArray(date, averages.bmcc_by_day) : getDailyDataArray(date, {});
    const bactoscan = !!averages && !!averages.bacto_by_day ? getDailyDataArray(date, averages.bacto_by_day) : getDailyDataArray(date, {});
    const protein = !!averages && !!averages.protein_by_day ? getDailyDataArray(date, averages.protein_by_day) : getDailyDataArray(date, {});
    const production = !!averages && !!averages.production_by_day ? getDailyDataArray(date, averages.production_by_day) : getDailyDataArray(date, {});
    const thermo = !!averages && !!averages.thermo_by_day ? getDailyDataArray(date, averages.thermo_by_day) : getDailyDataArray(date, {});
    const snf = !!averages && !!averages.solids_not_fat_by_day ? getDailyDataArray(date, averages.solids_not_fat_by_day) : getDailyDataArray(date, {});
    return {
        fat,
        lactose,
        scc,
        bmcc,
        bactoscan,
        protein,
        production,
        thermo,
        snf,
    };
};

const calculateProductionTotals = (producerAverages, date) => {
    const monthlyProductionTotal = !!producerAverages ? (!!producerAverages.total_pickup_volume ? producerAverages.total_pickup_volume : producerAverages.average_pickup_volume && producerAverages.count_pickup_volume ? producerAverages.average_pickup_volume * producerAverages.count_pickup_volume : 0) : 0;
    const dailyProductionTotal = !!monthlyProductionTotal ? monthlyProductionTotal / date.daysInMonth() : 0;
    const weeklyProductionTotal = !!dailyProductionTotal ? dailyProductionTotal * 7 : 0;
    return {
        dailyProductionTotal,
        weeklyProductionTotal,
        monthlyProductionTotal,
    };
};

const combineLabReportsAndPickups = (labReports, pickups, region, numTanks) => {
    const labsByDate = _.groupBy(labReports, (value) => moment(value.date).format('YYYY-MM-DD'));
    const labsByPickupId = arrayToMap(labReports, 'pickup_id');

    return pickups.map((value, index) => {
        const createdAt = moment(value.created_at).format('YYYY-MM-DD');
        const labsFromTank = _.filter(labsByDate[createdAt], (report) => report.tank === parseInt(value.tank_number));
        const labsWithFat = _.filter(labsFromTank, (report) => !!report.fat);
        const labsWithBacto = _.filter(labsFromTank, (report) => !!report.bacto_scan);
        const labsWithScc = _.filter(labsFromTank, (report) => !!report.somatic_cell_count);
        const labsWithProtein = _.filter(labsFromTank, (report) => !!report.protein);
        const labsWithBmcc = _.filter(labsFromTank, (report) => !!report.bulk_milk_cell_count);
        const labsWithThermo = _.filter(labsFromTank, (report) => !!report.thermo_plate_count);

        return region === 'NL' || region === 'PEI'
            ? {
                  id: index + 1,
                  date_tested: value.created_at,
                  volume: value.volume,
                  fat: _.round(_.sumBy(labsWithFat, 'fat') / labsWithFat.length, 2) || null,
                  protein: _.round(_.sumBy(labsWithProtein, 'protein') / labsWithProtein.length, 2) || null,
                  somatic_cell_count: _.round(_.sumBy(labsWithScc, 'somatic_cell_count') / labsWithScc.length, 0) || null,
                  bacto_scan: _.round(_.sumBy(labsWithBacto, 'bacto_scan') / labsWithBacto.length, 0) || null,
                  ...(numTanks > 1 && { tank_number: value.tank_number }),
                  flagged: false,
              }
            : region === 'RF'
            ? {
                  id: index + 1,
                  date_tested: value.created_at,
                  volume: value.volume,
                  fat: _.round(_.sumBy(labsWithFat, 'fat') / labsWithFat.length, 2) || null,
                  fat_per_litre: value.volume * (_.round(_.sumBy(labsWithFat, 'fat') / labsWithFat.length, 2) / 100) || null,
                  protein: _.round(_.sumBy(labsWithProtein, 'protein') / labsWithProtein.length, 2) || null,
                  protein_per_litre: value.volume * (_.round(_.sumBy(labsWithProtein, 'protein') / labsWithProtein.length, 2) / 100) || null,
                  bulk_milk_cell_count: _.round(_.sumBy(labsWithBmcc, 'bulk_milk_cell_count') / labsWithBmcc.length, 0) || null,
                  bacto_scan: _.round(_.sumBy(labsWithBacto, 'bacto_scan') / labsWithBacto.length, 0) || null,
                  thermo_plate_count: _.round(_.sumBy(labsWithThermo, 'thermo_plate_count') / labsWithThermo.length, 2) || null,
                  ...(numTanks > 1 && { tank_number: value.tank_number }),
                  flagged: false,
              }
            : ['UDA', 'CDI', 'LEGACY'].includes(region)
            ? {
                  id: index + 1,
                  date_tested: value.created_at,
                  volume: value.volume,
                  temperature: value.temperature,
                  fat: labsByPickupId[value.id]?.fat || null,
                  lactose: labsByPickupId[value.id]?.lactose || null,
                  protein: labsByPickupId[value.id]?.protein || null,
                  somatic_cell_count: labsByPickupId[value.id]?.somatic_cell_count || null,
                  tank_number: value.tank_number,
                  flagged: false,
              }
            : {
                  id: index + 1,
                  date_tested: value.created_at,
                  volume: value.volume,
                  fat: _.round(_.sumBy(labsWithFat, 'fat') / labsWithFat.length, 2) || null,
                  protein: _.round(_.sumBy(labsWithProtein, 'protein') / labsWithProtein.length, 2) || null,
              };
    });
};

const getProducerDashboardDetails = (id, region, month, year) =>
    new Promise((resolve, reject) => {
        const producerId = getId();
        const data = initializeData();

        const end = moment(`${year}-${month}`, 'YYYY-MM').endOf('month');
        const twoYearStart = moment(`${year}-${month}`, 'YYYY-MM').subtract(2, 'years').add(1, 'months');
        const monthStart = moment(`${year}-${month}`, 'YYYY-MM').startOf('month');
        const currentMonthSelected = month.toString() === moment().format('MM') && year.toString() === moment().format('YYYY');

        const producerQuery = getProducersQuery({ ids: [producerId], queryBuilder: buildExpressQuery });
        const producerRequest = axios.get(producerQuery);
        const pickupsQuery = getPickupsQuery({
            producerId,
            start: monthStart.format(),
            end: end.format(),
            filterDeleted: true,
            queryBuilder: buildExpressQuery,
        });
        const pickupsRequest = axios.get(pickupsQuery);

        const labStartDate = region === 'MMPA' ? moment(`${year}-${month}`, 'YYYY-MM').subtract(2, 'month').startOf('month') : monthStart;

        const labReportsQuery = getLabReportsQuery({
            producerIds: [producerId],
            start: labStartDate.format(),
            end: end.format(),
            filterDeleted: true,
            queryBuilder: buildExpressQuery,
        });
        const labReportsRequest = axios.get(labReportsQuery);

        const lastLabQuery = getLabReportsQuery({
            producerIds: [producerId],
            order: 'created_at DESC',
            limit: 1,
            filterDeleted: true,
            queryBuilder: buildExpressQuery,
        });
        const lastLabRequest = axios.get(lastLabQuery);

        const lastPickupQuery = getPickupsQuery({
            fields: ['producer_id', 'created_at', 'volume', 'temperature'],
            producerId,
            order: 'created_at DESC',
            limit: 1,
            filterDeleted: true,
            queryBuilder: buildExpressQuery,
        });

        const lastPickupRequest = axios.get(lastPickupQuery);

        const producersMonthlyBfQuotaQuery = getMonthlyQuotaTotalsQuery({
            start: twoYearStart.format(),
            end: end.format(),
            filterDeleted: true,
        });

        const producersMonthlyBfQuotaRequest = axios.get(producersMonthlyBfQuotaQuery);

        const fileQuery = getReceivedFilesQuery({
            fields: ['id', 'name', 'generated_name', 'created_at'],
            userId: id,
            filterDeleted: true,
            limit: 5,
        });

        const fileRequest = axios.get(fileQuery);

        const producerMonthlyAveragesQuery = getMonthlyAveragesQuery({
            userIds: [producerId],
            start: twoYearStart.format('YYYY-MM'),
            end: end.format('YYYY-MM'),
        });
        const producerMonthlyAveragesRequest = axios.get(producerMonthlyAveragesQuery);

        const industryMonthlyAveragesQuery = getMonthlyAveragesQuery({ start: twoYearStart.format('YYYY-MM'), end: end.format('YYYY-MM') });
        const industryMonthlyAveragesRequest = axios.get(industryMonthlyAveragesQuery);
        const twoMonthStart = monthStart.subtract(1, 'month').startOf('month');

        const monthlyReportQuery = getMonthlyReportsQuery({
            producerId,
            start: twoMonthStart.format('YYYY-MM'),
            end: end.format('YYYY-MM'),
            order: 'created_at DSC',
            filterDeleted: true,
            filterType: 'milk',
        });
        const monthlyReportRequest = axios.get(monthlyReportQuery);

        axios
            .all([producerRequest, pickupsRequest, labReportsRequest, lastLabRequest, producersMonthlyBfQuotaRequest, fileRequest, producerMonthlyAveragesRequest, industryMonthlyAveragesRequest, lastPickupRequest, monthlyReportRequest])
            .then(
                axios.spread((...responses) => {
                    const producer = responses[0].data[0];
                    renameKey(producer, '_id', 'id');
                    const pickups = responses[1].data;
                    renameKey(pickups, '_id', 'id');
                    const labReports = responses[2].data;
                    renameKey(labReports, '_id', 'id');
                    const lastLabReport = responses[3].data[0];
                    renameKey(lastLabReport, '_id', 'id');
                    const producersMonthlyQuotas = responses[4].data[0];
                    const receivedFiles = responses[5].data;
                    const producerMonthlyAverages = responses[6].data;
                    const industryMonthlyAverages = responses[7].data;
                    const lastPickupResponse = responses[8].data;
                    renameKey(lastPickupResponse, '_id', 'id');
                    const monthlyReport = responses[9].data;

                    const combinedSamedayPickups = combinePickups(pickups).reduce(samedayPickupReducer, []).reverse().reduce(averageVolumeReducer, []).reverse();

                    const combinedPickups = combinePickups(pickups);

                    data.tableDetails.pickups = combinedSamedayPickups;
                    data.tableDetails.labs = labReports;

                    data.demeritPoints = _.sumBy(labReports, 'demerit_points');
                    data.tableDetails.combined = combineLabReportsAndPickups(data.tableDetails.labs, combinedPickups, region, parseInt(producer.tank));

                    const lastPickup = combinedPickups[0] ? combinedPickups[0] : lastPickupResponse[0];

                    if (lastPickup) {
                        data.lastPickup.time = lastPickup.created_at;
                        data.lastPickup.volume = lastPickup.volume;
                        data.lastPickup.temperature = lastPickup.temperature;
                    }

                    if (lastLabReport) {
                        data.lastLab.date = lastLabReport.date;
                        data.lastLab.fat = lastLabReport.fat || 0;
                        data.lastLab.protein = lastLabReport.protein || 0;
                        data.lastLab.scc = lastLabReport.somatic_cell_count || 0;
                        data.lastLab.bmcc = lastLabReport.bulk_milk_cell_count || 0;
                        data.lastLab.bacto = lastLabReport.bacto_scan || 0;
                        data.lastLab.thermo = lastLabReport.thermo_plate_count || 0;
                        data.lastLab.temp = lastLabReport.temperature || 0;
                        data.lastLab.lactose = lastLabReport.lactose || 0;
                    }

                    if (monthlyReport) {
                        data.monthlyReport = monthlyReport;
                    }

                    if (producersMonthlyQuotas && producersMonthlyQuotas.producer_kgs_bf_quota) {
                        const producersQuota = producersMonthlyQuotas.producer_kgs_bf_quota.find((value) => value.producer_id === producerId);
                        data.producerBFQuota = producersQuota ? producersQuota.kgs_bf_quota : 0;
                        data.quotaDate = producersMonthlyQuotas.updated_at;
                    }

                    data.receivedFiles = receivedFiles;

                    const producerDistrictMonthlyAveragesQuery = getMonthlyAveragesQuery({
                        routeRegions: [producer.route_region],
                        start: twoYearStart.format('YYYY-MM'),
                        end: end.format('YYYY-MM'),
                    });
                    const producerDistrictMonthlyAveragesRequest = producer.route_region ? axios.get(producerDistrictMonthlyAveragesQuery) : new Promise((res) => res(null));

                    producerDistrictMonthlyAveragesRequest.then((res) => {
                        const districtMonthlyAverages = !!res ? res.data : null;

                        data.hasDistrictAverages = !!districtMonthlyAverages;

                        _.forEach(_.range(24), (i) => {
                            const date = moment(`${year}-${month}`, 'YYYY-MM')
                                .subtract(2, 'years')
                                .add(1, 'seconds')
                                .add(i + 1, 'months')
                                .format('YYYY-MM');

                            const industryAverages = _.find(industryMonthlyAverages, (averages) => averages.date === date);
                            const districtAverages = _.find(districtMonthlyAverages, (averages) => averages.date === date);
                            const producerAverages = _.find(producerMonthlyAverages, (averages) => averages.date === date);

                            const industryGraphDetails = getDashboardGraphDetails(industryAverages, region);
                            const districtGraphDetails = getDashboardGraphDetails(districtAverages, region);
                            const producerGraphDetails = getDashboardGraphDetails(producerAverages, region);

                            if (i < 12) {
                                data.graphDetails.industry.lastYear.production[i] = industryGraphDetails.production;
                                data.graphDetails.industry.lastYear.fat[i] = industryGraphDetails.fat;
                                data.graphDetails.industry.lastYear.lactose[i] = industryGraphDetails.lactose;
                                data.graphDetails.industry.lastYear.scc[i] = industryGraphDetails.scc;
                                data.graphDetails.industry.lastYear.bmcc[i] = industryGraphDetails.bmcc;
                                data.graphDetails.industry.lastYear.bactoscan[i] = industryGraphDetails.bactoscan;
                                data.graphDetails.industry.lastYear.protein[i] = industryGraphDetails.protein;
                                data.graphDetails.industry.lastYear.thermo[i] = industryGraphDetails.thermo;

                                data.graphDetails.district.lastYear.production[i] = districtGraphDetails.production;
                                data.graphDetails.district.lastYear.fat[i] = districtGraphDetails.fat;
                                data.graphDetails.district.lastYear.lactose[i] = districtGraphDetails.lactose;
                                data.graphDetails.district.lastYear.scc[i] = districtGraphDetails.scc;
                                data.graphDetails.district.lastYear.bmcc[i] = districtGraphDetails.bmcc;
                                data.graphDetails.district.lastYear.bactoscan[i] = districtGraphDetails.bactoscan;
                                data.graphDetails.district.lastYear.protein[i] = districtGraphDetails.protein;
                                data.graphDetails.district.lastYear.thermo[i] = districtGraphDetails.thermo;

                                data.graphDetails.lastYear.production[i] = producerGraphDetails.production;
                                data.graphDetails.lastYear.fat[i] = producerGraphDetails.fat;
                                data.graphDetails.lastYear.lactose[i] = producerGraphDetails.lactose;
                                data.graphDetails.lastYear.scc[i] = producerGraphDetails.scc;
                                data.graphDetails.lastYear.bmcc[i] = producerGraphDetails.bmcc;
                                data.graphDetails.lastYear.bactoscan[i] = producerGraphDetails.bactoscan;
                                data.graphDetails.lastYear.protein[i] = producerGraphDetails.protein;
                                data.graphDetails.lastYear.thermo[i] = producerGraphDetails.thermo;
                            } else {
                                data.graphDetails.industry.thisYear.production[i % 12] = industryGraphDetails.production;
                                data.graphDetails.industry.thisYear.fat[i % 12] = industryGraphDetails.fat;
                                data.graphDetails.industry.thisYear.lactose[i % 12] = industryGraphDetails.lactose;
                                data.graphDetails.industry.thisYear.scc[i % 12] = industryGraphDetails.scc;
                                data.graphDetails.industry.thisYear.bmcc[i % 12] = industryGraphDetails.bmcc;
                                data.graphDetails.industry.thisYear.bactoscan[i % 12] = industryGraphDetails.bactoscan;
                                data.graphDetails.industry.thisYear.protein[i % 12] = industryGraphDetails.protein;
                                data.graphDetails.industry.thisYear.thermo[i % 12] = industryGraphDetails.thermo;
                                data.graphDetails.district.thisYear.production[i % 12] = districtGraphDetails.production;
                                data.graphDetails.district.thisYear.fat[i % 12] = districtGraphDetails.fat;
                                data.graphDetails.district.thisYear.lactose[i % 12] = districtGraphDetails.lactose;
                                data.graphDetails.district.thisYear.scc[i % 12] = districtGraphDetails.scc;
                                data.graphDetails.district.thisYear.bmcc[i % 12] = districtGraphDetails.bmcc;
                                data.graphDetails.district.thisYear.bactoscan[i % 12] = districtGraphDetails.bactoscan;
                                data.graphDetails.district.thisYear.protein[i % 12] = districtGraphDetails.protein;
                                data.graphDetails.district.thisYear.thermo[i % 12] = districtGraphDetails.thermo;
                                data.graphDetails.thisYear.production[i % 12] = producerGraphDetails.production;
                                data.graphDetails.thisYear.fat[i % 12] = producerGraphDetails.fat;
                                data.graphDetails.thisYear.lactose[i % 12] = producerGraphDetails.lactose;
                                data.graphDetails.thisYear.scc[i % 12] = producerGraphDetails.scc;
                                data.graphDetails.thisYear.bmcc[i % 12] = producerGraphDetails.bmcc;
                                data.graphDetails.thisYear.bactoscan[i % 12] = producerGraphDetails.bactoscan;
                                data.graphDetails.thisYear.protein[i % 12] = producerGraphDetails.protein;
                                data.graphDetails.thisYear.thermo[i % 12] = producerGraphDetails.thermo;
                            }
                            if (i === 10) {
                                const industryMonthDetails = getMonthDetails(industryAverages, date);
                                const districtMonthDetails = getMonthDetails(districtAverages, date);
                                const producerMonthDetails = getMonthDetails(producerAverages, date);

                                data.previousIndustryDetails.previousMonth.fatTotal = industryGraphDetails.fat;
                                data.previousIndustryDetails.previousMonth.lactoseTotal = industryGraphDetails.lactose;
                                data.previousIndustryDetails.previousMonth.sccTotal = industryGraphDetails.scc;
                                data.previousIndustryDetails.previousMonth.bmccTotal = industryGraphDetails.bmcc;
                                data.previousIndustryDetails.previousMonth.bactoTotal = industryGraphDetails.bactoscan;
                                data.previousIndustryDetails.previousMonth.proteinTotal = industryGraphDetails.protein;
                                data.previousIndustryDetails.previousMonth.thermoTotal = industryGraphDetails.thermo;

                                data.previousIndustryDetails.previousMonth.fat = industryMonthDetails.fat;
                                data.previousIndustryDetails.previousMonth.lactose = industryMonthDetails.lactose;
                                data.previousIndustryDetails.previousMonth.scc = industryMonthDetails.scc;
                                data.previousIndustryDetails.previousMonth.bmcc = industryMonthDetails.bmcc;
                                data.previousIndustryDetails.previousMonth.bacto_scan = industryMonthDetails.bactoscan;
                                data.previousIndustryDetails.previousMonth.protein = industryMonthDetails.protein;
                                data.previousIndustryDetails.previousMonth.thermo = industryMonthDetails.thermo;

                                data.previousDistrictDetails.previousMonth.fatTotal = districtGraphDetails.fat;
                                data.previousDistrictDetails.previousMonth.lactoseTotal = districtGraphDetails.lactose;
                                data.previousDistrictDetails.previousMonth.sccTotal = districtGraphDetails.scc;
                                data.previousDistrictDetails.previousMonth.bmccTotal = districtGraphDetails.bmcc;
                                data.previousDistrictDetails.previousMonth.bactoTotal = districtGraphDetails.bactoscan;
                                data.previousDistrictDetails.previousMonth.proteinTotal = districtGraphDetails.protein;
                                data.previousDistrictDetails.previousMonth.thermoTotal = districtGraphDetails.thermo;

                                data.previousDistrictDetails.previousMonth.fat = districtMonthDetails.fat;
                                data.previousDistrictDetails.previousMonth.lactose = districtMonthDetails.lactose;
                                data.previousDistrictDetails.previousMonth.scc = districtMonthDetails.scc;
                                data.previousDistrictDetails.previousMonth.bmcc = districtMonthDetails.bmcc;
                                data.previousDistrictDetails.previousMonth.bacto_scan = districtMonthDetails.bactoscan;
                                data.previousDistrictDetails.previousMonth.protein = districtMonthDetails.protein;
                                data.previousDistrictDetails.previousMonth.thermo = districtMonthDetails.thermo;

                                data.previousProducerDetails.previousMonth.fatTotal = producerGraphDetails.fat;
                                data.previousProducerDetails.previousMonth.lactoseTotal = producerGraphDetails.lactose;
                                data.previousProducerDetails.previousMonth.sccTotal = producerGraphDetails.scc;
                                data.previousProducerDetails.previousMonth.bmccTotal = producerGraphDetails.bmcc;
                                data.previousProducerDetails.previousMonth.bactoTotal = producerGraphDetails.bactoscan;
                                data.previousProducerDetails.previousMonth.proteinTotal = producerGraphDetails.protein;
                                data.previousProducerDetails.previousMonth.thermoTotal = producerGraphDetails.thermo;

                                data.previousProducerDetails.previousMonth.fat = producerMonthDetails.fat;
                                data.previousProducerDetails.previousMonth.lactose = producerMonthDetails.lactose;
                                data.previousProducerDetails.previousMonth.scc = producerMonthDetails.scc;
                                data.previousProducerDetails.previousMonth.bmcc = producerMonthDetails.bmcc;
                                data.previousProducerDetails.previousMonth.bacto_scan = producerMonthDetails.bactoscan;
                                data.previousProducerDetails.previousMonth.protein = producerMonthDetails.protein;
                                data.previousProducerDetails.previousMonth.thermo = producerMonthDetails.thermo;
                            }
                            if (i === 11) {
                                const industryMonthDetails = getMonthDetails(industryAverages, date);
                                const districtMonthDetails = getMonthDetails(districtAverages, date);
                                const producerMonthDetails = getMonthDetails(producerAverages, date);

                                data.previousIndustryDetails.currentMonth.fatTotal = industryGraphDetails.fat;
                                data.previousIndustryDetails.currentMonth.lactoseTotal = industryGraphDetails.lactose;
                                data.previousIndustryDetails.currentMonth.sccTotal = industryGraphDetails.scc;
                                data.previousIndustryDetails.currentMonth.bmccTotal = industryGraphDetails.bmcc;
                                data.previousIndustryDetails.currentMonth.bactoTotal = industryGraphDetails.bactoscan;
                                data.previousIndustryDetails.currentMonth.proteinTotal = industryGraphDetails.protein;
                                data.previousIndustryDetails.currentMonth.thermoTotal = industryGraphDetails.thermo;

                                data.previousIndustryDetails.currentMonth.fat = industryMonthDetails.fat;
                                data.previousIndustryDetails.currentMonth.lactose = industryMonthDetails.lactose;
                                data.previousIndustryDetails.currentMonth.scc = industryMonthDetails.scc;
                                data.previousIndustryDetails.currentMonth.bmcc = industryMonthDetails.bmcc;
                                data.previousIndustryDetails.currentMonth.bacto_scan = industryMonthDetails.bactoscan;
                                data.previousIndustryDetails.currentMonth.protein = industryMonthDetails.protein;
                                data.previousIndustryDetails.currentMonth.thermo = industryMonthDetails.thermo;

                                data.previousDistrictDetails.currentMonth.fatTotal = districtGraphDetails.fat;
                                data.previousDistrictDetails.currentMonth.lactoseTotal = districtGraphDetails.lactose;
                                data.previousDistrictDetails.currentMonth.sccTotal = districtGraphDetails.scc;
                                data.previousDistrictDetails.currentMonth.bmccTotal = districtGraphDetails.bmcc;
                                data.previousDistrictDetails.currentMonth.bactoTotal = districtGraphDetails.bactoscan;
                                data.previousDistrictDetails.currentMonth.proteinTotal = districtGraphDetails.protein;
                                data.previousDistrictDetails.currentMonth.thermoTotal = districtGraphDetails.thermo;

                                data.previousDistrictDetails.currentMonth.fat = districtMonthDetails.fat;
                                data.previousDistrictDetails.currentMonth.lactose = districtMonthDetails.lactose;
                                data.previousDistrictDetails.currentMonth.scc = districtMonthDetails.scc;
                                data.previousDistrictDetails.currentMonth.bmcc = districtMonthDetails.bmcc;
                                data.previousDistrictDetails.currentMonth.bacto_scan = districtMonthDetails.bactoscan;
                                data.previousDistrictDetails.currentMonth.protein = districtMonthDetails.protein;
                                data.previousDistrictDetails.currentMonth.thermo = districtMonthDetails.thermo;

                                data.previousProducerDetails.currentMonth.fatTotal = producerGraphDetails.fat;
                                data.previousProducerDetails.currentMonth.lactoseTotal = producerGraphDetails.lactose;
                                data.previousProducerDetails.currentMonth.sccTotal = producerGraphDetails.scc;
                                data.previousProducerDetails.currentMonth.bmccTotal = producerGraphDetails.bmcc;
                                data.previousProducerDetails.currentMonth.bactoTotal = producerGraphDetails.bactoscan;
                                data.previousProducerDetails.currentMonth.proteinTotal = producerGraphDetails.protein;
                                data.previousProducerDetails.currentMonth.thermoTotal = producerGraphDetails.thermo;

                                data.previousProducerDetails.currentMonth.fat = producerMonthDetails.fat;
                                data.previousProducerDetails.currentMonth.lactose = producerMonthDetails.lactose;
                                data.previousProducerDetails.currentMonth.scc = producerMonthDetails.scc;
                                data.previousProducerDetails.currentMonth.bmcc = producerMonthDetails.bmcc;
                                data.previousProducerDetails.currentMonth.bacto_scan = producerMonthDetails.bactoscan;
                                data.previousProducerDetails.currentMonth.protein = producerMonthDetails.protein;
                                data.previousProducerDetails.currentMonth.thermo = producerMonthDetails.thermo;
                                data.previousProducerDetails.currentMonth.fluid_production = producerMonthDetails.production;
                            }
                            if (i === 22) {
                                const industryMonthDetails = getMonthDetails(industryAverages, date);
                                const districtMonthDetails = getMonthDetails(districtAverages, date);
                                const producerMonthDetails = getMonthDetails(producerAverages, date);

                                data.currentIndustryDetails.previousMonth.fatTotal = industryGraphDetails.fat;
                                data.currentIndustryDetails.previousMonth.lactoseTotal = industryGraphDetails.lactose;
                                data.currentIndustryDetails.previousMonth.sccTotal = industryGraphDetails.scc;
                                data.currentIndustryDetails.previousMonth.bmccTotal = industryGraphDetails.bmcc;
                                data.currentIndustryDetails.previousMonth.bactoTotal = industryGraphDetails.bactoscan;
                                data.currentIndustryDetails.previousMonth.proteinTotal = industryGraphDetails.protein;
                                data.currentIndustryDetails.previousMonth.thermoTotal = industryGraphDetails.thermo;

                                data.currentIndustryDetails.previousMonth.fat = industryMonthDetails.fat;
                                data.currentIndustryDetails.previousMonth.lactose = industryMonthDetails.lactose;
                                data.currentIndustryDetails.previousMonth.scc = industryMonthDetails.scc;
                                data.currentIndustryDetails.previousMonth.bmcc = industryMonthDetails.bmcc;
                                data.currentIndustryDetails.previousMonth.bacto_scan = industryMonthDetails.bactoscan;
                                data.currentIndustryDetails.previousMonth.protein = industryMonthDetails.protein;
                                data.currentIndustryDetails.previousMonth.thermo = industryMonthDetails.thermo;

                                data.currentDistrictDetails.previousMonth.fatTotal = districtGraphDetails.fat;
                                data.currentDistrictDetails.previousMonth.lactoseTotal = districtGraphDetails.lactose;
                                data.currentDistrictDetails.previousMonth.sccTotal = districtGraphDetails.scc;
                                data.currentDistrictDetails.previousMonth.bmccTotal = districtGraphDetails.bmcc;
                                data.currentDistrictDetails.previousMonth.bactoTotal = districtGraphDetails.bactoscan;
                                data.currentDistrictDetails.previousMonth.proteinTotal = districtGraphDetails.protein;
                                data.currentDistrictDetails.previousMonth.thermoTotal = districtGraphDetails.thermo;

                                data.currentDistrictDetails.previousMonth.fat = districtMonthDetails.fat;
                                data.currentDistrictDetails.previousMonth.lactose = districtMonthDetails.lactose;
                                data.currentDistrictDetails.previousMonth.scc = districtMonthDetails.scc;
                                data.currentDistrictDetails.previousMonth.bmcc = districtMonthDetails.bmcc;
                                data.currentDistrictDetails.previousMonth.bacto_scan = districtMonthDetails.bactoscan;
                                data.currentDistrictDetails.previousMonth.protein = districtMonthDetails.protein;
                                data.currentDistrictDetails.previousMonth.thermo = districtMonthDetails.thermo;

                                data.currentProducerDetails.previousMonth.fatTotal = producerGraphDetails.fat;
                                data.currentProducerDetails.previousMonth.lactoseTotal = producerGraphDetails.lactose;
                                data.currentProducerDetails.previousMonth.sccTotal = producerGraphDetails.scc;
                                data.currentProducerDetails.previousMonth.bmccTotal = producerGraphDetails.bmcc;
                                data.currentProducerDetails.previousMonth.bactoTotal = producerGraphDetails.bactoscan;
                                data.currentProducerDetails.previousMonth.proteinTotal = producerGraphDetails.protein;
                                data.currentProducerDetails.previousMonth.thermoTotal = producerGraphDetails.thermo;

                                data.currentProducerDetails.previousMonth.fat = producerMonthDetails.fat;
                                data.currentProducerDetails.previousMonth.lactose = producerMonthDetails.lactose;
                                data.currentProducerDetails.previousMonth.scc = producerMonthDetails.scc;
                                data.currentProducerDetails.previousMonth.bmcc = producerMonthDetails.bmcc;
                                data.currentProducerDetails.previousMonth.bacto_scan = producerMonthDetails.bactoscan;
                                data.currentProducerDetails.previousMonth.protein = producerMonthDetails.protein;
                                data.currentProducerDetails.previousMonth.thermo = producerMonthDetails.thermo;

                                const { dailyProductionTotal, weeklyProductionTotal, monthlyProductionTotal } = calculateProductionTotals(producerAverages, moment(`${year}-${month}`, 'YYYY-MM').startOf('month').subtract(1, 'month'));
                                data.productionDetails.averageDay.previousMonth = dailyProductionTotal;
                                data.productionDetails.averageWeek.previousMonth = weeklyProductionTotal;
                                data.productionDetails.totalMonth.previousMonth = monthlyProductionTotal;
                            }
                            if (i === 23) {
                                const industryMonthDetails = getMonthDetails(industryAverages, date);
                                const districtMonthDetails = getMonthDetails(districtAverages, date);
                                const producerMonthDetails = getMonthDetails(producerAverages, date);

                                data.currentIndustryDetails.currentMonth.fatTotal = industryGraphDetails.fat;
                                data.currentIndustryDetails.currentMonth.lactoseTotal = industryGraphDetails.lactose;
                                data.currentIndustryDetails.currentMonth.sccTotal = industryGraphDetails.scc;
                                data.currentIndustryDetails.currentMonth.bmccTotal = industryGraphDetails.bmcc;
                                data.currentIndustryDetails.currentMonth.bactoTotal = industryGraphDetails.bactoscan;
                                data.currentIndustryDetails.currentMonth.proteinTotal = industryGraphDetails.protein;
                                data.currentIndustryDetails.currentMonth.thermoTotal = industryGraphDetails.thermo;

                                data.currentIndustryDetails.currentMonth.fat = industryMonthDetails.fat;
                                data.currentIndustryDetails.currentMonth.lactose = industryMonthDetails.lactose;
                                data.currentIndustryDetails.currentMonth.scc = industryMonthDetails.scc;
                                data.currentIndustryDetails.currentMonth.bmcc = industryMonthDetails.bmcc;
                                data.currentIndustryDetails.currentMonth.bacto_scan = industryMonthDetails.bactoscan;
                                data.currentIndustryDetails.currentMonth.protein = industryMonthDetails.protein;
                                data.currentIndustryDetails.currentMonth.thermo = industryMonthDetails.thermo;

                                data.currentDistrictDetails.currentMonth.fatTotal = districtGraphDetails.fat;
                                data.currentDistrictDetails.currentMonth.lactoseTotal = districtGraphDetails.lactose;
                                data.currentDistrictDetails.currentMonth.sccTotal = districtGraphDetails.scc;
                                data.currentDistrictDetails.currentMonth.bmccTotal = districtGraphDetails.bmcc;
                                data.currentDistrictDetails.currentMonth.bactoTotal = districtGraphDetails.bactoscan;
                                data.currentDistrictDetails.currentMonth.proteinTotal = districtGraphDetails.protein;
                                data.currentDistrictDetails.currentMonth.thermoTotal = districtGraphDetails.thermo;

                                data.currentDistrictDetails.currentMonth.fat = districtMonthDetails.fat;
                                data.currentDistrictDetails.currentMonth.lactose = districtMonthDetails.lactose;
                                data.currentDistrictDetails.currentMonth.scc = districtMonthDetails.scc;
                                data.currentDistrictDetails.currentMonth.bmcc = districtMonthDetails.bmcc;
                                data.currentDistrictDetails.currentMonth.bacto_scan = districtMonthDetails.bactoscan;
                                data.currentDistrictDetails.currentMonth.protein = districtMonthDetails.protein;
                                data.currentDistrictDetails.currentMonth.thermo = districtMonthDetails.thermo;

                                data.currentProducerDetails.currentMonth.fatTotal = producerGraphDetails.fat;
                                data.currentProducerDetails.currentMonth.lactoseTotal = producerGraphDetails.lactose;
                                data.currentProducerDetails.currentMonth.sccTotal = producerGraphDetails.scc;
                                data.currentProducerDetails.currentMonth.bmccTotal = producerGraphDetails.bmcc;
                                data.currentProducerDetails.currentMonth.bactoTotal = producerGraphDetails.bactoscan;
                                data.currentProducerDetails.currentMonth.proteinTotal = producerGraphDetails.protein;
                                data.currentProducerDetails.currentMonth.thermoTotal = producerGraphDetails.thermo;
                                data.currentProducerDetails.currentMonth.snfTotal = producerGraphDetails.solids_not_fat;

                                data.currentProducerDetails.currentMonth.fat = producerMonthDetails.fat;
                                data.currentProducerDetails.currentMonth.lactose = producerMonthDetails.lactose;
                                data.currentProducerDetails.currentMonth.scc = producerMonthDetails.scc;
                                data.currentProducerDetails.currentMonth.bmcc = producerMonthDetails.bmcc;
                                data.currentProducerDetails.currentMonth.bacto_scan = producerMonthDetails.bactoscan;
                                data.currentProducerDetails.currentMonth.protein = producerMonthDetails.protein;
                                data.currentProducerDetails.currentMonth.thermo = producerMonthDetails.thermo;

                                data.currentProducerDetails.currentMonth.fluid_production = producerMonthDetails.production;

                                const { dailyProductionTotal, weeklyProductionTotal, monthlyProductionTotal } = calculateProductionTotals(producerAverages, moment(`${year}-${month}`, 'YYYY-MM').startOf('month'));
                                data.productionDetails.averageDay.currentMonth = dailyProductionTotal;
                                data.productionDetails.averageWeek.currentMonth = weeklyProductionTotal;
                                data.productionDetails.totalMonth.currentMonth = monthlyProductionTotal;
                                data.graphDetails.thisYear.production[i % 12] = currentMonthSelected ? moment().daysInMonth() * dailyProductionTotal * (moment().daysInMonth() / moment().date()) : monthlyProductionTotal;

                                if (region === 'MMPA') {
                                    const currentMonth = moment(`${year}-${month}`, 'YYYY-MM').format('YYYY-MM');
                                    const previousMonth = moment(`${year}-${month}`, 'YYYY-MM').subtract(1, 'month').format('YYYY-MM');
                                    const previous2Months = moment(`${year}-${month}`, 'YYYY-MM').subtract(2, 'month').format('YYYY-MM');

                                    // Place labs in reverse chronological order as the query gets them with most recent first
                                    const sortedLabs = [...labReports].sort((a, b) => moment(a.date).valueOf() - moment(b.date).valueOf());

                                    const firstSccLabInMonth = sortedLabs.find((lab) => moment(lab.date).format('YYYY-MM') === currentMonth && lab.somatic_cell_count);
                                    const firstSccInMonth = firstSccLabInMonth?.somatic_cell_count * 1000;
                                    const firstSccLabInPrevMonth = sortedLabs.find((lab) => moment(lab.date).format('YYYY-MM') === previousMonth && lab.somatic_cell_count);
                                    const firstSccInPrevMonth = firstSccLabInPrevMonth?.somatic_cell_count * 1000;
                                    const firstSccLabInPrev2Months = sortedLabs.find((lab) => moment(lab.date).format('YYYY-MM') === previous2Months && lab.somatic_cell_count);
                                    const firstSccInPrev2Months = firstSccLabInPrev2Months?.somatic_cell_count * 1000;

                                    let euScc;
                                    if (firstSccInPrev2Months && firstSccInPrevMonth && firstSccInMonth) {
                                        euScc = Math.round((firstSccInMonth * firstSccInPrevMonth * firstSccInPrev2Months) ** (1 / 3));
                                    }
                                    data.euSccAverage = euScc;
                                }
                            }
                        });

                        resolve(data);
                    });
                })
            )
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getProducerDashboardDetails;
