import addUser from './addUser';

const addSubHauling = (model) =>
    addUser({
        ...model,
        role: 'transport',
        subuser: true,
    });

export default addSubHauling;
