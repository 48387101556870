import React, { useContext, useState } from 'react';
import { DrawingManager } from '@react-google-maps/api';
import {
    shapeOptions,
    isCircleGeofence,
    drawingModeOptions,
    GeoJSONPoint,
    calculatePolygonCenter,
    convertPathsArrayToGeoJSON,
    isValidPolygon,
    // wrap
} from './GeofenceManager/GeofenceManager.helpers';
import GeofenceControls from './GeofenceManager/GeofenceControls';
import { MapContext } from './MapContext';

const useDrawingManager = ({ addGeofence, canDraw, canEdit }) => {
    const { fences, setError } = useContext(MapContext);
    const [drawingMode, setDrawingMode] = useState(!!fences.length && isCircleGeofence(fences[0]) ? drawingModeOptions[0] : drawingModeOptions[1]);

    const handleAddGeofence = (type, instance) => {
        const newFence = {};
        if (type === 'radialGeofence') {
            const newCenter = instance.center.toJSON();
            newFence.radius_meters = instance.getRadius();
            newFence.center = GeoJSONPoint([Number(newCenter.lng), Number(newCenter.lat)]);
        }
        if (type === 'polygonalGeofence') {
            const paths = instance?.getPath()?.getArray();
            const { valid, error, create } = isValidPolygon(paths);
            if (!valid) {
                if (!create) {
                    instance.setMap(null);
                    return;
                }
                setError(error);
            } else {
                setError();
            }
            const newCenter = calculatePolygonCenter(paths.map((path) => path.toJSON()));
            newFence.center = GeoJSONPoint([Number(newCenter.lng), Number(newCenter.lat)]);
            newFence.geometry = GeoJSONPoint(convertPathsArrayToGeoJSON(paths), 'Polygon');
        }

        // Make DrawingManager shape invisible, only display shape from geofences array
        instance.setOptions({
            fillOpacity: 0.0,
            strokeOpacity: 0.0,
        });
        addGeofence(type, newFence);
    };

    const handleShapeChange = (shapeType, index) => {
        const newGeofence = {};
        const { instance } = fences[index];
        if (shapeType === 'radialGeofence') {
            if (!instance?.getRadius) return;
            const newCenter = instance?.getCenter().toJSON();
            const newRadius = instance?.getRadius();
            if (newRadius) {
                newGeofence.radius_meters = newRadius;
            }
            if (newCenter) {
                newGeofence.center = GeoJSONPoint([Number(newCenter.lng), Number(newCenter.lat)]);
            }
        } else if (shapeType === 'polygonalGeofence') {
            if (!instance?.getPath) return;
            const newPathsArray = instance?.getPath()?.getArray();
            const { valid, error } = isValidPolygon(newPathsArray);
            if (!valid) {
                setError(error);
            } else {
                setError();
            }
            const newCenter = calculatePolygonCenter(newPathsArray.map((path) => path.toJSON()));
            newGeofence.geometry = GeoJSONPoint(convertPathsArrayToGeoJSON(newPathsArray, true), 'Polygon');
            newGeofence.center = GeoJSONPoint([Number(newCenter.lng), Number(newCenter.lat)]);
        }
        return newGeofence;
    };

    const MapManager = (
        <DrawingManager
            onCircleComplete={(circle) => handleAddGeofence('radialGeofence', circle)}
            onPolygonComplete={(polygon) => handleAddGeofence('polygonalGeofence', polygon)}
            drawingMode={canDraw ? drawingMode?.value : null}
            options={{
                drawingControl: false,
                circleOptions: { ...shapeOptions },
                polygonOptions: { ...shapeOptions, strokeWeight: 4 },
            }}
        />
    );

    const DrawingControls = (
        <GeofenceControls
            drawingMode={drawingMode}
            drawingModeOptions={drawingModeOptions}
            setDrawingMode={setDrawingMode}
            canDraw={canDraw}
            canEdit={canEdit}
            // wrap
        />
    );
    return { DrawingControls, MapManager, handleShapeChange };
};

export default useDrawingManager;
