import { GridToolbarExport } from '@mui/x-data-grid';
import React from 'react';

const sx = {
    // Append '80' to hex to apply 50% opacity
    border: (theme) => `1px solid ${theme.palette.secondary.main}80`,
    color: (theme) => `${theme.palette.secondary.light}`,
    height: '48px',
    fontSize: '0',
    '& .MuiButton-startIcon': {
        marginRight: '0',
    },
    '& .MuiSvgIcon-root:nth-of-type(1)': {
        fontSize: '2.4rem',
    },
};

export default function StyledExportButton({ ...props }) {
    return (
        <GridToolbarExport sx={sx} {...props}>
            StyledExportButton
        </GridToolbarExport>
    );
}
