import axios from 'axios';
import { getHaulerRatesForHaulingContractQuery } from './queries';

const getHaulerRatesForHaulingContract = async (haulerId, startDate, endDate, haulerRateIds) => {
    if (haulerId == null || startDate == null) {
        return { haulerRates: [] };
    }
    const effectiveHaulerRateQuery = getHaulerRatesForHaulingContractQuery({
        haulerId,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate ? endDate.format('YYYY-MM-DD') : undefined,
        haulerRateIds: haulerRateIds ?? undefined,
    });
    try {
        const response = await axios.get(effectiveHaulerRateQuery);
        const haulerRates = response.data;
        return { haulerRates };
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error when getHaulerRatesForHaulingContract: ', error);
        return { haulerRates: [] };
    }
};

export default getHaulerRatesForHaulingContract;
