import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

const DriverLocations = {
    auth: authRoles.hauling,
    routes: [
        {
            path: '/processed-fluid/driver-locations',
            component: FuseLoadable({ loader: () => import('../driver-locations/DriverLocations') }),
            exact: true,
        },
    ],
};

const ListProcessedFluidRouteSessions = { auth: [...authRoles.admin, ...authRoles.processor, ...authRoles.hauling], routes: [{ path: '/processed-fluid/route-sessions', component: FuseLoadable({ loader: () => import('./ListProcessedFluidRouteSessionsApp') }) }] };

const ListProcessedFluidTransfers = { auth: [...authRoles.admin, ...authRoles.processor, ...authRoles.hauling], routes: [{ path: '/processed-fluid/:transferType', component: FuseLoadable({ loader: () => import('./ListProcessedFluidTransfersApp') }) }] };

export default [DriverLocations, ListProcessedFluidRouteSessions, ListProcessedFluidTransfers];
