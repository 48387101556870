import React from 'react';
import { Typography } from '@mui/material';
import { GridOverlay } from '@mui/x-data-grid';

const ReceivingErrorOverlay = ({ errorMessage }) => {
    return (
        <GridOverlay>
            <Typography>{errorMessage ?? 'No in-progress dropoffs retrieved'}</Typography>
        </GridOverlay>
    );
};

export default ReceivingErrorOverlay;
