import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import { getRouteSessionsQuery, getProcessorsQuery, getProducersQuery, getMonthlyQuotaTotalsQuery, getDropoffsQuery, batchQuery } from './queries';

const getAdminEstimatedFluidSalesDetails = (month, year) =>
    new Promise((resolve, reject) => {
        const start = moment([year, month - 1]);
        const end = start.clone().endOf('month');
        const prevMonthStart = start.clone().subtract(1, 'month');
        const prevMonthEnd = prevMonthStart.clone().endOf('month');
        const now = moment();

        const isCurrentMonth = moment().isSame(`${year}-${month}`, 'month');

        const prevMonthRouteSessionsQuery = getRouteSessionsQuery({
            fields: ['id', 'created_at'],
            start: prevMonthStart,
            end: prevMonthEnd,
            filterDeleted: true,
            removeOrder: true,
            filterType: 'milk',
        });
        const prevMonthRouteSessionRequests = axios.get(prevMonthRouteSessionsQuery);

        const thisMonthRouteSessionsQuery = getRouteSessionsQuery({
            fields: ['id', 'created_at'],
            start,
            end,
            filterDeleted: true,
            removeOrder: true,
            filterType: 'milk',
        });
        const thisMonthRouteSessionsRequest = axios.get(thisMonthRouteSessionsQuery);

        const processorsQuery = getProcessorsQuery({ filterDeleted: true, fields: ['id', 'name'] });
        const processorsRequest = axios.get(processorsQuery);

        const producersQuery = getProducersQuery({ filterDeleted: true, fields: ['daily_fluid_milk_quota'] });
        const producersRequest = axios.get(producersQuery);

        const monthlyQuotaTotalsQuery = getMonthlyQuotaTotalsQuery({ start: prevMonthStart, end });
        const monthlyQuotaTotalsRequest = axios.get(monthlyQuotaTotalsQuery);

        const requests = [prevMonthRouteSessionRequests, thisMonthRouteSessionsRequest, processorsRequest, producersRequest, monthlyQuotaTotalsRequest];

        axios
            .all(requests)
            .then(
                axios.spread((...responses) => {
                    const [prevMonthRouteSessionResponse, thisMonthRouteSessionsResponse, processorsResponse, producersResponse, monthlyQuotaTotalsResponse] = responses;
                    const prevMonthRouteSessions = prevMonthRouteSessionResponse.data;
                    const thisMonthRouteSessions = thisMonthRouteSessionsResponse.data;
                    const processors = processorsResponse.data;
                    const producers = producersResponse.data;
                    const monthlyQuotaTotals = monthlyQuotaTotalsResponse.data;

                    const agropur = _.find(processors, { name: 'Agropur' });
                    const saputo = _.find(processors, { name: 'Saputo' });

                    if (!agropur || !saputo) {
                        resolve({ fluidSales: { thisMonth: '-1', lastMonth: '-1' }, fluidSalesChange: '-1' });
                        return;
                        // reject(new Error('Unable to find processors'));
                    }

                    const thisMonthTotalDailyFluid = isCurrentMonth ? _.sumBy(producers, 'daily_fluid_milk_quota') : _.find(monthlyQuotaTotals, (o) => moment(o.created_at).isBetween(start, end)) ? _.find(monthlyQuotaTotals, (o) => moment(o.created_at).isBetween(start, end)).daily_fluid_quota_daily : 0;

                    const prevMonthTotalDailyFluid = _.find(monthlyQuotaTotals, (o) => moment(o.created_at).isBetween(prevMonthStart, prevMonthEnd)) ? _.find(monthlyQuotaTotals, (o) => moment(o.created_at).isBetween(prevMonthStart, prevMonthEnd)).daily_fluid_quota_daily : 0;

                    const prevMonthRouteSessionIds = _.uniq(_.map(prevMonthRouteSessions, 'id'));
                    const thisMonthRouteSessionIds = _.uniq(_.map(thisMonthRouteSessions, 'id'));

                    const daysInMonth = parseFloat(end.format('D'));
                    const daysInPrevMonth = parseFloat(prevMonthEnd.format('D'));
                    const dayOfMonth = isCurrentMonth ? parseFloat(now.format('D')) : daysInMonth;
                    const dayOfPrevMonth = isCurrentMonth ? dayOfMonth : daysInPrevMonth;

                    // limit previous month to match time in current month
                    const prevMonthDropoffQueries = batchQuery(
                        getDropoffsQuery,
                        {
                            start: prevMonthStart,
                            end: isCurrentMonth
                                ? moment({
                                      month: prevMonthStart.month(),
                                      day: dayOfMonth,
                                      hour: now.hour(),
                                      minute: now.minute(),
                                      second: now.second(),
                                  })
                                : prevMonthEnd,
                            routeSessionIds: prevMonthRouteSessionIds,
                            filterDeleted: true,
                        },
                        'routeSessionIds',
                        100
                    );

                    const prevMonthDropoffRequests = _.map(prevMonthDropoffQueries, (q) => axios.get(q));

                    const thisMonthDropoffQueries = batchQuery(getDropoffsQuery, { routeSessionIds: thisMonthRouteSessionIds, filterDeleted: true }, 'routeSessionIds', 100);
                    const thisMonthDropoffRequests = _.map(thisMonthDropoffQueries, (q) => axios.get(q));

                    axios.all([...prevMonthDropoffRequests, ...thisMonthDropoffRequests]).then(
                        axios.spread((...dropoffResponses) => {
                            const prevMonthDropoffResponses = dropoffResponses.slice(0, prevMonthDropoffRequests.length);
                            const thisMonthDropoffResponses = dropoffResponses.slice(prevMonthDropoffRequests.length, prevMonthDropoffRequests.length + thisMonthDropoffRequests.length);

                            const prevMonthDropoffs = _.reduce(prevMonthDropoffResponses, (acc, val) => [...acc, ...val.data], []);
                            const thisMonthDropoffs = _.reduce(thisMonthDropoffResponses, (acc, val) => [...acc, ...val.data], []);

                            const prevMonthDropoffsGroupedByProcessorId = _.groupBy(prevMonthDropoffs, 'processor_id');
                            const thisMonthDropoffsGroupedByProcessorId = _.groupBy(thisMonthDropoffs, 'processor_id');

                            const prevMonthAgropurTotal = prevMonthDropoffsGroupedByProcessorId[agropur.id] ? _.sumBy(prevMonthDropoffsGroupedByProcessorId[agropur.id], 'volume') : 0;
                            const prevMonthSaputoTotal = prevMonthDropoffsGroupedByProcessorId[saputo.id] ? _.sumBy(prevMonthDropoffsGroupedByProcessorId[saputo.id], 'volume') : 0;

                            const thisMonthAgropurTotal = thisMonthDropoffsGroupedByProcessorId[agropur.id] ? _.sumBy(thisMonthDropoffsGroupedByProcessorId[agropur.id], 'volume') : 0;
                            const thisMonthSaputoTotal = thisMonthDropoffsGroupedByProcessorId[saputo.id] ? _.sumBy(thisMonthDropoffsGroupedByProcessorId[saputo.id], 'volume') : 0;

                            const thisMonth = !!thisMonthTotalDailyFluid ? ((thisMonthAgropurTotal + thisMonthSaputoTotal + 333333 * (dayOfMonth / daysInMonth)) / (thisMonthTotalDailyFluid * dayOfMonth)) * 100 : 0;
                            const lastMonth = !!prevMonthTotalDailyFluid ? ((prevMonthAgropurTotal + prevMonthSaputoTotal + 333333 * (dayOfPrevMonth / daysInPrevMonth)) / (prevMonthTotalDailyFluid * daysInPrevMonth)) * 100 : 0;

                            const fluidSales = { thisMonth: thisMonth.toFixed(3), lastMonth: lastMonth.toFixed(3) };

                            const fluidSalesChange = thisMonthTotalDailyFluid * dayOfMonth * ((lastMonth - thisMonth) / -100);

                            resolve({ fluidSales, fluidSalesChange });
                        })
                    );
                })
            )
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getAdminEstimatedFluidSalesDetails;
