const logoText = {
    CDI: 'California Dairies Inc.',
    LEGACY: 'Legacy Milk',
    MMPA: 'Michigan Milk Producers Association',
    NL: 'Dairy Farmers of NL',
    PEI: 'Dairy Farmers of Prince Edward Island',
    PRAIRIE: 'Prairie Farms',
    RF: 'Riverina Fresh',
    'TCJ-ERIE': 'TCJ - Erie Cooperative Association',
    'TCJ-GPDA': "TCJ - Great Plains Dairymen's Association",
    'TCJ-LIBERTY': 'TCJ - Liberty Milk Producers',
    'TCJ-KYTN': 'TCJ - KYTN Cooperative',
    'TCJ-NEBRASKA': 'TCJ - Nebraska Milk Producers',
    'TCJ-WHITEEAGLE': 'TCJ - White Eagle Cooperative',
    UDA: 'United Dairymen of Arizona',
    UNC: 'Upstate Niagara Cooperative Inc.',
    BONGARDS: 'Bongards Creameries',
    default: 'Milk Moovement',
};

export default logoText;
