import React from 'react';
import { withStyles } from '@material-ui/core';
import { AppBar, Hidden, Typography, Grid } from '@mui/material';
/* eslint-disable-next-line */
import classNames from 'classnames';
import UserNavbarHeader from 'app/fuse-layouts/shared-components/UserNavbarHeader';
import Logo from 'app/fuse-layouts/shared-components/Logo';
import BottomLogoDark from 'app/fuse-layouts/shared-components/BottomLogoDark';
import NavbarMobileToggleButton from 'app/fuse-layouts/shared-components/NavbarMobileToggleButton';
import Navigation from 'app/fuse-layouts/shared-components/Navigation';
import { FuseScrollbars } from '@fuse';
import { mui5ThemeObject } from '../../../../styles/mm-theme-fuse';
import { KeyboardDoubleArrowLeft } from '@mui/icons-material';

const styles = (theme) => ({
    content: {
        overflowX: 'hidden',
        overflowY: 'auto',
        '-webkit-overflow-scrolling': 'touch',
        backgroundColor: mui5ThemeObject.palette.secondary.extraDark,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 40px, 100% 10px',
        backgroundAttachment: 'local, scroll',
    },
    navigationBackground: {
        backgroundColor: mui5ThemeObject.palette.secondary.extraDark,
    },
});

const BuildVersionSx = {
    textAlign: 'right',
    whiteSpace: 'nowrap',
    opacity: 0.5,
    height: 'fit-content',
    marginY: 'auto',
    position: 'absolute',
    top: '33%',
    right: 0,
};

const BottomGridSx = {
    position: 'relative',
    alignItems: 'flex-end',
};

const AppBarSx = {
    display: 'flex',
    alignItems: 'end',
    flexShrink: 0,
    backgroundColor: mui5ThemeObject.palette.secondary.extraDark,
};
// TODO: Finish the navigation bar layout

const NavbarLayout = ({ classes, navigation, className }) => (
    <div className={classNames('flex flex-col overflow-hidden h-full', classes.navigationBackground, className)}>
        <AppBar sx={AppBarSx} position="static" elevation={0} className="flex flex-row items-center flex-shrink">
            {window.innerWidth < 1280 && (
                <Hidden sx={{ backgroundColor: 'inherit' }} lgUp>
                    <NavbarMobileToggleButton>
                        <KeyboardDoubleArrowLeft />
                    </NavbarMobileToggleButton>
                </Hidden>
            )}
            <Logo />
        </AppBar>

        <FuseScrollbars className={classNames(classes.content)}>
            <UserNavbarHeader />

            <Navigation className={classNames('pt-16', classes.navigationBackground)} layout="vertical" />
        </FuseScrollbars>

        <Grid container spacing={2} sx={BottomGridSx}>
            <Grid item sx={{ marginTop: '10px' }}>
                <div>
                    <BottomLogoDark />
                </div>
            </Grid>
            {process.env.REACT_APP_VERSION && (
                <Grid item xs sx={BuildVersionSx}>
                    <Typography variant="caption" color="white">
                        v. {process.env.REACT_APP_VERSION.substring(0, 7)}
                    </Typography>
                </Grid>
            )}
        </Grid>
    </div>
);

export default withStyles(styles, { withTheme: true })(NavbarLayout);
