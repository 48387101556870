import axios from 'axios';
import _ from 'lodash';
import { getLatestProductionQuery, getProducersQuery } from './queries';
import { calculateAccuracy, getTimeSinceLastPickup } from './utils';

const MS_PER_HOUR = 1000 * 60 * 60;
const MS_PER_WEEK = MS_PER_HOUR * 24 * 7;

const getProductionStats = () =>
    new Promise((resolve, reject) => {
        const productionQuery = getLatestProductionQuery();
        const productionRequest = axios.get(productionQuery);

        const producerQuery = getProducersQuery({ fields: ['id', 'name'], filterDeleted: true });
        const producerRequest = axios.get(producerQuery);

        axios
            .all([productionRequest, producerRequest])
            .then(
                axios.spread((...responses) => {
                    const productions = responses[0].data;
                    const producers = responses[1].data;

                    const results = _.orderBy(
                        _.filter(
                            _.map(productions, (production) => {
                                const producer = _.find(producers, { id: production.producer_id });

                                const ms = getTimeSinceLastPickup(production);

                                const expectedHourlyProduction = production.rate * MS_PER_HOUR;

                                const expectedMilkOnFarm = production.rate * ms;

                                const accuracy = calculateAccuracy(production.actual_volume, production.expected_volume);

                                return {
                                    id: producer.id,
                                    producer: producer.name,
                                    tank: production.tank,
                                    time_since_last_pickup: ms,
                                    expected_hourly_production: expectedHourlyProduction,
                                    expected_milk_on_farm: expectedMilkOnFarm,
                                    accuracy,
                                };
                            }),
                            (v) => v.time_since_last_pickup < 2 * MS_PER_WEEK
                        ),
                        ['producer'],
                        ['asc']
                    );

                    resolve({ producers: results });
                })
            )
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getProductionStats;
