import React from 'react';
import { Box, Dialog, DialogContent, DialogTitle, DialogActions, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DraggablePaper from './DraggablePaper';

const useStyles = makeStyles(() => ({
    title: {
        cursor: ({ draggable }) => (draggable ? 'move' : null),
    },
}));

function DialogWrapper({ Title, open, handleClose, draggable, hideClose, maxWidth, children, actions }) {
    const { title } = useStyles({ draggable });

    return (
        <Dialog open={open} fullWidth maxWidth={maxWidth || 'md'} PaperComponent={draggable && DraggablePaper}>
            <DialogTitle className={title} id="draggable-dialog-title">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <div>{Title}</div>
                    {!hideClose && handleClose && (
                        <IconButton aria-label="close" onClick={handleClose}>
                            <CloseIcon />
                        </IconButton>
                    )}
                </Box>
            </DialogTitle>
            <p></p>
            <DialogContent>{children}</DialogContent>
            {actions && <DialogActions>{actions}</DialogActions>}
        </Dialog>
    );
}

export default DialogWrapper;
