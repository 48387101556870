import { LOCATION_ROLES } from '../../../constants';

export const selectedShapeOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    visible: true,
    zIndex: 1,
};

// TODO: Get rid of these hex values
export const producerShapeOptions = { fillColor: '#40B481', strokeColor: '#40B481', strokeWeight: 2 };
export const processorShapeOptions = { fillColor: '#00BCD4', strokeColor: '#00BCD4', strokeWeight: 2 };

export const defaultShapeOptions = {
    strokeColor: 'gray',
    strokeOpacity: 0.8,
    strokeWeight: 0,
    fillColor: 'gray',
    fillOpacity: 0.35,
    visible: true,
    zIndex: 1,
};

export const iconUrlMap = {
    [LOCATION_ROLES.PRODUCER]: require('../symbols/producer-icon.svg'),
    [LOCATION_ROLES.PROCESSOR]: require('../symbols/processor-icon.svg'),
    [LOCATION_ROLES.HAULER]: require('../symbols/producer-icon.svg'),
    [LOCATION_ROLES.DRIVER]: require('../symbols/hauler-icon.svg'),
};

export const defaultIconSize = { height: 52, width: 42 };
export const enlargedIconSize = { height: 52 * 1.2, width: 42 * 1.2 };
export const routeDetailsIconSize = { height: 40, width: 40 };
