import React from 'react';
import { ForgotPassword } from '@milkmoovement/common_cognito_package';
import { useHistory } from 'react-router-dom';
import { getRegion } from '../../../../utils';

const ForgotPasswordPage = () => {
    const region = getRegion(window.location.hostname);
    const subdomain = window.location.href.split('#')[0];
    const history = useHistory();
    const handleNavigateBack = () => history.push({ pathname: '/login' });
    return <ForgotPassword onNavigateBack={handleNavigateBack} metadata={{ coreSystem: region, clientUrl: `${subdomain}#` }} />;
};

export default ForgotPasswordPage;
