import axios from 'axios';
import { getClosePeriodQuery } from './queries';

const getClosePeriodDetails = async ({ startDate, endDate }) => {
    const closePeriodQuery = getClosePeriodQuery({ startDate, endDate });
    const { data } = await axios.get(closePeriodQuery);
    return data;
};

export default getClosePeriodDetails;
