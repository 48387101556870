import axios from 'axios';
import { requestScheduleItemLoadsQuery } from './queries';

const requestScheduleItemLoads = async (key, loads) => {
    try {
        const query = requestScheduleItemLoadsQuery({ key, loads });
        const request = axios.patch(query.url, { ...query.body });
        const { data } = await request;
        return { items: data };
    } catch {
        throw new Error('Unable to edit schedule item');
    }
};

export default requestScheduleItemLoads;
