import Region from '../../../app/constants/region';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

const local = new Environment({
    name: stages.LOCAL,
    regions: [Region.CDI, Region.DEMO, Region.UNC],
});

const dev = new Environment({
    name: stages.DEV,
    regions: [Region.CDI, Region.DEMO, Region.UNC],
});

const stg = new Environment({
    name: stages.STAGING,
    regions: [Region.CDI, Region.DEMO, Region.UNC],
});

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: [Region.CDI, Region.DEMO, Region.UNC],
});

const demo = new Environment({
    name: stages.DEMO,
    regions: [Region.CDI, Region.DEMO, Region.UNC],
});

const can_use_member_center = new MockSplit({
    name: 'can_use_member_center',
    environments: [local, dev, stg, prod, demo],
});

export default can_use_member_center;
