import { combineReducers } from 'redux';
import persisted from './persisted/persistedReducers';
import quickPanel from 'app/fuse-layouts/shared-components/quickPanel/store/reducers';
import storageSession from 'redux-persist/lib/storage/session';
import { persistReducer } from 'redux-persist';
import mainReducer from './mainReducer';
import fuse from './fuse';

const persistConfig = { key: 'state', storage: storageSession };

const createReducer = () =>
    combineReducers({
        fuse,
        quickPanel,
        mainReducer,
        persisted: persistReducer(persistConfig, persisted),
    });

const rootReducer = createReducer();

export default rootReducer;
