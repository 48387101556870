import { useState, useEffect } from 'react';
import useWindowSize from './useWindowSize';

const useToolbarOffset = () => {
    const [toolbarOffset, setToolbarOffset] = useState(document.getElementById('fuse-toolbar')?.clientHeight ?? 0);
    const windowSize = useWindowSize();

    useEffect(() => {
        const offset = document.getElementById('fuse-toolbar')?.clientHeight ?? 0;
        setToolbarOffset(offset);
    }, [windowSize]);

    return { toolbarOffset };
};

export default useToolbarOffset;
