import axios from 'axios';
import { editUserQuery } from './queries';
import { buildExpressQuery, buildLoopbackQuery } from './queries/builders';

const editUser = (model, key, isExpressRequired = false) =>
    new Promise((resolve, reject) => {
        const query = editUserQuery({
            key,
            model,
            queryBuilder: isExpressRequired ? buildExpressQuery : buildLoopbackQuery,
        });
        const request = axios.patch(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to edit user'));
            });
    });

export default editUser;
