import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Pun from '../../custom-components/Pun';
import RandomMoorice from '../../custom-components/RandomMoorice';

class LoadingDialog extends React.Component {
    render() {
        const { showDialog } = this.props;

        return (
            <div>
                <Dialog open={showDialog} style={{ color: 'red' }} onClose={this.handleClose} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
                    <div className="flex flex-col w-full h-384" style={{ background: 'linear-gradient(#4291fb, #41527f)', justifyContent: 'center', alignItems: 'center' }}>
                        <RandomMoorice />
                        <Pun />
                    </div>
                </Dialog>
            </div>
        );
    }
}

export default LoadingDialog;
