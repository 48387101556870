import axios from 'axios';
import { editAssigneeQuery } from './queries';

const editAssignee = (model, key, usePatch = false) =>
    new Promise((resolve, reject) => {
        const query = editAssigneeQuery({ model, key });
        const request = axios.put(query.url, { ...query.body }, usePatch ? null : { params: { useNewPutRoute: true } });
        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to edit Assignee'));
            });
    });

export default editAssignee;
