import axios from 'axios';
import _ from 'lodash';
import { getProcessorsQuery } from './queries';
import { getProcessorDropoffDetails } from '.';

const getDFNSDropoffDetails = (start, end) =>
    new Promise((resolve, reject) => {
        const processorsQuery = getProcessorsQuery({ fields: ['id', 'name'] });
        const processorRequest = axios.get(processorsQuery);
        processorRequest.then((processorResponse) => {
            const processors = processorResponse.data;
            const truro = _.find(processors, { name: 'Truro' });
            const sussex = _.find(processors, { name: 'Sussex' });
            if (!truro || !sussex) {
                resolve([]);
            } else {
                const truroDropoffDetails = getProcessorDropoffDetails(truro.id, start, end);
                const sussexDropoffDetails = getProcessorDropoffDetails(sussex.id, start, end);
                Promise.all([truroDropoffDetails, sussexDropoffDetails]).then((payloads) => {
                    const truroDropoffs = payloads[0].dropoffs;
                    const sussexDropoffs = payloads[1].dropoffs;
                    const dropoffs = _.orderBy([...truroDropoffs, ...sussexDropoffs], ['created_at'], ['desc']);
                    resolve(dropoffs);
                });
            }
        });
    });

export default getDFNSDropoffDetails;
