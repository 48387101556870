import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { capitalize } from '../../../../utils';

const inputBounds = {
    latitude: {
        min: -90,
        max: 90,
    },
    longitude: {
        min: -180,
        max: 180,
    },
};

const LatLngInput = ({ type, onChange, value, ...rest }) => {
    const [inputValue, setValue] = useState(value ?? '');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (value) setValue(value);
    }, [value]);

    const { max, min } = inputBounds[type];

    const isValid = (val) => {
        return !Number.isNaN(val) && val >= min && val <= max;
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        setValue(newValue);

        if (isValid(newValue)) {
            if (onChange) {
                onChange(event);
            }
            setError(false);
            setErrorMessage('');
        } else {
            setError(true);
            setErrorMessage(`Invalid ${type}. Must be between ${min} and ${max}`);
        }
    };

    return (
        <TextField
            error={error}
            helperText={error ? errorMessage : ''}
            label={capitalize(type)}
            value={inputValue}
            onChange={handleChange}
            {...rest}
            // wrap
        />
    );
};

export default LatLngInput;
