import React from 'react';
import { Grid } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';

const OrganicMilkPrice = () => (
    <Grid item xs={3}>
        <ReactHookFormInput label="Organic Milk Price" name={'milk_prices.organic_milk_price'} type="number" />
    </Grid>
);

export default OrganicMilkPrice;
