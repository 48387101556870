const settingsConfig = {
    layout: { style: 'layout', config: {} },
    customScrollbars: true,
    theme: {
        main: 'default',
        navbar: 'mainThemeDark',
        toolbar: 'mainThemeLight',
        footer: 'mainThemeDark',
    },
};

export default settingsConfig;
