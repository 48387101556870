import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getCloseDayDetails, getCloseMonthDetails, setError } from '../store/actions';
import moment from 'moment-timezone';
import { AbilityContext } from '../auth/permissions';

const RestrictedDatePicker = (props) => {
    const ignoreLockPeriod = props?.ignoreLockPeriod || false;
    const dispatch = useDispatch();
    const [lockedDays, setLockedDays] = useState([]);
    const [lockedDaysList, setLockedDaysList] = useState([]);

    const ability = useContext(AbilityContext);
    const canAccessClosedMonth = ability?.can('mutate', 'close_month');

    const fetchList = () => {
        if (canAccessClosedMonth) {
            dispatch(getCloseMonthDetails({}))
                .then(({ payload }) => {
                    setLockedDays(payload.closedMonth.filter((month) => month.is_locked !== null));
                })
                .catch((error) => {
                    dispatch(setError(error));
                });
        } else {
            dispatch(getCloseDayDetails({}))
                .then(({ payload }) => {
                    setLockedDays(payload.closeDays);
                })
                .catch((error) => {
                    dispatch(setError(error));
                });
        }
    };

    useEffect(() => {
        fetchList();
    }, []);

    useEffect(() => {
        setLockedDaysList(lockedDays.filter((day) => day.is_locked).map((day) => moment(day.date).format('YYYY-MM-DD')));
    }, [lockedDays]);

    const checkIfDayLocked = (date) => {
        return lockedDaysList.includes(date.format('YYYY-MM-DD'));
    };

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DateTimePicker
                shouldDisableDate={!ignoreLockPeriod && checkIfDayLocked}
                leftArrowButtonProps={{
                    classes: {
                        root: 'fs-ignore-rage-clicks',
                    },
                }}
                rightArrowButtonProps={{
                    classes: {
                        root: 'fs-ignore-rage-clicks',
                    },
                }}
                {...props}
            />
        </MuiPickersUtilsProvider>
    );
};

export default React.memo(RestrictedDatePicker);
