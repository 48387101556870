import { getReportServiceAPIHost, getRegion } from '../../../../utils';

const buildReportServiceQuery = (endpoint, { headers, data }) => {
    const clientName = getRegion(window.location.hostname);
    return {
        url: `${getReportServiceAPIHost()}${endpoint}?customer=${clientName}`,
        headers,
        data,
    };
};

export default buildReportServiceQuery;
