import axios from 'axios';
import _ from 'lodash';
import { getSubUsersQuery, getNonSubUsersQuery } from './queries';

const getSubUserListDetails = (includeDeleted) =>
    new Promise((resolve, reject) => {
        const fields = ['created_at', 'deleted_at', 'email', 'secondary_email', 'id', 'name', 'permissions', 'producer_id', 'hauling_id', 'processor_id', 'admin_id', 'updated_at', 'username', 'role', 'type_of_fluid', 'equity_id', 'mailing_address1', 'mailing_address2', 'mailing_city', 'mailing_state', 'mailing_zip'];
        const subUsersQuery = getSubUsersQuery({ fields, includeDeleted });
        const subUsersRequest = axios.get(subUsersQuery);

        subUsersRequest
            .then((subUsersResponse) => {
                const subUsers = subUsersResponse.data;

                const adminSubUsers = subUsers.filter((subUser) => subUser.role === 'admin');
                const producersSubUsers = subUsers.filter((subUser) => subUser.role === 'producer');
                const processorSubUsers = subUsers.filter((subUser) => subUser.role === 'processor');
                const haulingCompanySubUsers = subUsers.filter((subUser) => subUser.role === 'transport');

                const usersQuery = getNonSubUsersQuery({
                    fields: ['id', 'name'],
                    roles: ['admin', 'producer', 'processor', 'transport'],
                });
                const usersRequest = axios.get(usersQuery);

                usersRequest.then((usersResponse) => {
                    const users = usersResponse.data;

                    const adminData = _.map(adminSubUsers, (value) => ({ ...value, user_name: _.find(users, { id: value.admin_id }) ? _.find(users, { id: value.admin_id }).name : '' }));
                    const producerData = _.map(producersSubUsers, (value) => ({ ...value, user_name: _.find(users, { id: value.producer_id }) ? _.find(users, { id: value.producer_id }).name : '' }));
                    const processorData = _.map(processorSubUsers, (value) => ({ ...value, user_name: _.find(users, { id: value.processor_id }) ? _.find(users, { id: value.processor_id }).name : '' }));
                    const haulingData = _.map(haulingCompanySubUsers, (value) => ({ ...value, user_name: _.find(users, { id: value.hauling_id }) ? _.find(users, { id: value.hauling_id }).name : '' }));

                    const data = [...adminData, ...producerData, ...processorData, ...haulingData];

                    resolve({ subUsers: data });
                });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getSubUserListDetails;
