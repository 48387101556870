import axios from 'axios';
import { resolveId } from '../../utils';
import { deleteUserGroupQuery } from './queries';

const deleteUserGroup = async (key) => {
    try {
        const query = deleteUserGroupQuery({ key });
        const request = axios.delete(query);
        const { data } = await request;
        return resolveId(data);
    } catch {
        throw new Error('Unable to delete user groups');
    }
};

export default deleteUserGroup;
