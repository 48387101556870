import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

export const Index = {
    auth: authRoles.admin,
    routes: [
        {
            path: '/processor-fmmo-monthly-totals',
            component: FuseLoadable({ loader: () => import('./ProcessorFmmoMonthlyTotalsApp') }),
        },
    ],
};

export default [Index];
