import axios from 'axios';
import { getTableDocsListQuery } from './queries';

const getTableDocsList = async () => {
    const query = getTableDocsListQuery();
    const resp = await axios.get(query);
    return resp.data;
};

export default getTableDocsList;
