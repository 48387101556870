import axios from 'axios';
import _ from 'lodash';
import { batchQuery, getReceiversQuery, getProcessorsQuery } from './queries';

const getReceiverListDetails = (id, includeDeleted) =>
    new Promise((resolve, reject) => {
        const fields = ['created_at', 'deleted_at', 'email', 'processor_id', 'id', 'name', 'updated_at', 'username'];
        const params = {
            fields,
            ...(!!id && { processorId: id }),
            includeDeleted,
        };
        const query = getReceiversQuery(params);
        const request = axios.get(query);

        request
            .then((response) => {
                const receivers = response.data;
                const processorIds = _.uniq(_.map(receivers, 'processor_id'));

                const processorsQuery = batchQuery(
                    getProcessorsQuery,
                    {
                        filterDeleted: true,
                        fields: ['id', 'name'],
                        ids: processorIds,
                    },
                    'ids'
                );
                const processorRequest = _.map(processorsQuery, (q) => axios.get(q));

                axios.all(processorRequest).then(
                    axios.spread((...responses) => {
                        const processors = _.reduce(responses, (acc, val) => [...acc, ...val.data], []);
                        const receiverResponse = _.orderBy(
                            _.map(receivers, (receiver) => {
                                const processor = _.find(processors, { id: receiver.processor_id });
                                const processorName = processor?.name ?? '';
                                return { ...receiver, processor: processorName };
                            }),
                            ['name'],
                            ['asc']
                        );
                        resolve({ receivers: receiverResponse });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getReceiverListDetails;
