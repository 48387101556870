// import routesConfig from 'app/fuse-configs/routesConfig';

const initialState = {};

const routes = function (state = initialState, action) {
    switch (action.type) {
        default: {
            return state;
        }
    }
};

export default routes;
