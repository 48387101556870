import * as Actions from '../../actions';

const initialState = { data: null };

const haulerMonthlyPayReportsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_HAULER_MONTHLY_PAY_REPORTS_FOR_HAULER:
            return { ...state, data: { ...action.payload } };
        default:
            return state;
    }
};

export default haulerMonthlyPayReportsReducer;
