import axios from 'axios';
import { editGeofenceQuery } from './queries';
import generateErrorMessage from '../utils/generateErrorMessage';
import parseGeofence from './utils';

const editGeofence = async (id, model) => {
    try {
        const parsedFence = parseGeofence(model);
        const query = editGeofenceQuery({ id, model: parsedFence });

        const { data } = await axios.patch(query.url, { ...query.body });

        return data;
    } catch (error) {
        return generateErrorMessage(error);
    }
};

export default editGeofence;
