import axios from 'axios';
import _ from 'lodash';

import generateErrorMessage from './utils/generateErrorMessage';
import { getHistoryQuery } from './queries';

const getHistory = async (id, type) => {
    try {
        const query = getHistoryQuery({ id, type });
        const response = await axios.get(query);
        const history = response.data;
        return history;
    } catch (error) {
        return generateErrorMessage(error);
    }
};

export default getHistory;
