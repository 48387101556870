import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';
import { getUserRegion } from '../../../../../../utils';
import Region from '../../../../../constants/region';

const can_use_new_milk_prices_page = [Region.BONGARDS, Region.UNC, Region.PSC, Region.CACIQUE, Region.TCJ.ERIE, Region.TCJ.GPDA, Region.TCJ.KYTN, Region.TCJ.LIBERTY, Region.TCJ.WHITEEAGLE, Region.DEMO];

export const MilkPrice = {
    auth: authRoles.admin,
    routes: [
        {
            path: '/milk-prices/create-price',
            component: FuseLoadable({
                loader: () => import('./MutateMilkPrice'),
            }),
        },
        {
            path: '/milk-prices/edit-price/:id',
            component: FuseLoadable({
                loader: () => import('./MutateMilkPrice'),
            }),
        },
        {
            path: '/milk-prices/:date',
            component: FuseLoadable({
                loader: () => (can_use_new_milk_prices_page.includes(getUserRegion()) ? import('./MilkPrices') : import('./CreateMilkPriceApp')),
            }),
        },
        {
            path: '/milk-prices',
            component: FuseLoadable({
                loader: () => (can_use_new_milk_prices_page.includes(getUserRegion()) ? import('./MilkPrices') : import('./CreateMilkPriceApp')),
            }),
        },
    ],
};

export const HaulingRates = { auth: authRoles.admin, routes: [{ path: '/hauling-rates', component: FuseLoadable({ loader: () => import('./cdi/CreateCDIHaulingRateApp') }) }] };

export default [MilkPrice, HaulingRates];
