import axios from 'axios';
import { addBarcodePrintQueueQuery, editBarcodePrintQueueQuery, getBarcodePrintQueueQuery, getBarcodePrintHistoryQuery, getBarcodeManagementQuery } from './queries';
import _ from 'lodash';

export const addBarcodePrintQueue = async (model) => {
    const query = addBarcodePrintQueueQuery({ model });
    const request = axios.post(query.url, { ...query.body });
    const { data } = await request;
    return data;
};

export const editBarcodePrintQueue = async (key, model) => {
    const queueBody = _.omit(model, ['created_at', 'updated_at', 'deleted_at', '__v', '_id', 'tableData', 'index', 'producer_name', 'producer_number', 'barcode_type']);
    const query = editBarcodePrintQueueQuery(key, queueBody);
    const { data } = await axios.patch(query.url, { ...query.body });
    return data;
};

export const getBarcodePrintQueue = async ({ queueStatus, queueType, producerId, tankNumber, sortBy, limit }) => {
    const query = getBarcodePrintQueueQuery({ queueStatus, queueType, producerId, tankNumber, sortBy, limit });
    const { data } = await axios.get(query);
    return data;
};

export const getLatestBarcodePrintQueue = async (queueType, producerId, tankNumber) => {
    const query = getBarcodePrintQueueQuery({ queueType, producerId, tankNumber, sortBy: 'created_at', limit: 1 });
    const { data } = await axios.get(query);
    return data[0];
};

export const getBarcodePrintHistory = async (startDate, endDate) => {
    const query = getBarcodePrintHistoryQuery(startDate, endDate);
    const { data } = await axios.get(query);
    return data;
};

export const getBarcodeManagementDetails = async () => {
    try {
        const barcodeManagementQuery = getBarcodeManagementQuery();
        const response = await axios.get(barcodeManagementQuery);
        return response.data;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};
