import axios from 'axios';
import { bulkEditScheduleFluidItemQuery } from './queries';

const bulkEditScheduleFluidItem = async (model, keys) => {
    try {
        const query = bulkEditScheduleFluidItemQuery({ keys, model });
        const request = axios.put(query.url, { ...query.body });
        const { data } = await request;
        return { ids: data };
    } catch {
        throw new Error('Unable to edit schedule item');
    }
};

export default bulkEditScheduleFluidItem;
