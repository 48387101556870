import { isZodValidatorError, parseZodIssues } from './zodErrors';

const UNKNOWN_ERROR_TEXT = 'An unknown error occurred.';
const CONTACT_SUPPORT_TEXT = 'If issue persists, please contact support.';

const appendContactSupport = (message) => `${message}${message.slice(message.length - 1) !== '.' ? '.' : ''} ${CONTACT_SUPPORT_TEXT}`;
const DEFAULT_ERROR_MESSAGE = appendContactSupport(UNKNOWN_ERROR_TEXT);

const parseError = ({ error }) => {
    // If the error is from the server
    if (error?.response?.data) {
        const { data } = error.response;

        // express is inconsistent with the status code, sometimes it's status, sometimes it's statusCode
        const status = data.statusCode ?? data.status ?? 500;

        let message = data?.message ?? UNKNOWN_ERROR_TEXT;
        // If the error is a conflict, we don't want to append the contact support message.
        if (status !== 409) message = appendContactSupport(message);

        return {
            message,
            status,
            details: data.details ?? undefined, // additional details from the server
            validationIssues: isZodValidatorError(error) ? parseZodIssues(error) : undefined, // zod validation errors
        };
    }
    // If the error is from the client or network
    return {
        message: error?.message ? appendContactSupport(error?.message) : DEFAULT_ERROR_MESSAGE,
        status: error?.status,
    };
};

export default parseError;
