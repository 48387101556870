import axios from 'axios';
import Split from '../../Split';
import { createLabReportQuery } from './queries';
import { isLabAlreadyExistsMsg, isPickupLabAlreadyExistsMsg } from '../../utils';
import { buildExpressQuery } from './queries/builders';

const addLabReport = (model) =>
    new Promise((resolve, reject) => {
        const useExpressEndpoint = Split.getBooleanTreatment('use_express_lab_endpoint');

        const query = createLabReportQuery({ model, ...(useExpressEndpoint && { queryBuilder: buildExpressQuery }) });
        const request = axios.post(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.log(error);
                if (error.response && error.response.status === 405) {
                    const { data } = error.response;
                    if (isPickupLabAlreadyExistsMsg(data.message) || isLabAlreadyExistsMsg(data.message)) {
                        reject(new Error(data.message));
                    }
                } else {
                    reject(new Error('Unable to add lab report'));
                }
            });
    });

export default addLabReport;
