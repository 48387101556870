import { combineReducers } from 'redux';
import userListDetails from './list.user.reducer';
import adminListDetails from './list.admin.reducer';
import adminDashboardDetails from './admin.dashboard.reducer';
import adminEstimatedFluidSalesDetails from './admin.estimated_fluid_sales.reducer';
import adminInTransitDetails from './admin.in_transit.reducer';
import adminOnFarmEstimateDetails from './admin.on_farm_estimate_reducer';
import producerDashboardDetails from './producer.dashboard.reducer';
import producerDashboardLabReportDetails from './producer.dashboard.labReports.reducer';
import producerDashboardLabAverages from './producer.dashboard.labAverages.reducer';
import producerDashboardPickupAndLabDetails from './producer.dashboard.pickup.and.lab.reducer';
import processorDashboardDetails from './processor.dashboard.reducer';
import driverListDetails from './list.driver.reducer';
import deferralListDetails from './list.deferrals.reducer';
import receiverListDetails from './list.receiver.reducer';
import producerListDetails from './list.producer.reducer';
import producerBusinessListDetails from './list.producer.business.reducer';
import processorListDetails from './list.processor.reducer';
import labProviderListDetails from './list.lab_provider.reducer';
import haulingListDetails from './list.hauling.reducer';
import trailerListDetails from './list.trailer.reducer';
import truckListDetails from './list.truck.reducer';
import routesListDetails from './list.routes.reducer';
import siloListDetails from './list.silos.reducer';
import assigneeListDetails from './list.assignees.reducer';
import assignmentListDetails from './list.assignment.reducer';
import assignmentConfigurationListDetails from './list.assignmentconfiguration.reducer';
import subProducerListDetails from './list.sub_producer.reducer';
import subProcessorListDetails from './list.sub_processor.reducer';
import subHaulingListDetails from './list.sub_hauling.reducer';
import subUserListDetails from './list.sub_users.reducer';
import pickupProcessorDetails from './view.pickups.processors.reducer';
import baseTransferDetails from './view.base.transfers.reducer';
import listCloseDayDetails from './list.close.day.reducer';
import listCloseMonthDetails from './list.close.month.reducer';
import washDetails from './view.washes.reducer';
import washFacilityDetails from './view.wash.facility.reducer';
import pickupDetails from './view.pickups.reducer';
import dropoffDetails from './view.dropoffs.reducer';
import labDetails from './view.labs.reducer';
import routeSessionDetails from './view.route.sessions.reducer';
import monthlyReportsDetails from './view.monthly.reports.reducer';
import routeSessionPickups from './route.session.pickup.reducer';
import routeSessionDropoffs from './route.session.dropoff.reducer';
import receivedSharedFileDetails from './shared_files.received.reducer';
import sharedFileDetails from './shared_files.shared.reducer';
import quotaLeasingDetails from './quota_leasing.reducer';
import inspectRouteSessionDetails from './inspect.routesession.reducer';
import sessionDetails from './route.session.details.reducer';
import routeStatsDetails from './route_stats_reducer';
import driverStatsDetails from './driver_stats_reducer';
import driverRouteSessionsDetails from './driver_route_sessions_reducer';
import routeRouteSessionsDetails from './route_route_sessions_reducer';
import generalRouteSessionsDetails from './general_route_sessions_reducer';
import generalRouteStatsDetails from './general_stats_reducer';
import splashScreenDetails from './splash_screen.reducer';
import singleProducerProductionDetails from './single_producer_production.reducer';
import productionStatsDetails from './production_stats_reducer';
import userSettingsDetails from './user_settings.reducer';
import userReportScheduleDetails from './user_report_schedule.reducer';
import getMilkPrices from './list.milk_prices.reducer';
import adminMonthlyPayReportsReducer from './admin.monthly.pay.reports';
import adminVendorMonthlyPayReportsReducer from './admin.vendor.pay.reports';
import adminHaulerMonthlyPayReportsReducer from './admin.hauler.pay.reports.reducer';
import producerMonthlyPayReportsReducer from './producer.monthly.pay.reports.reducer';
import poolingListDetails from './pooling_list_reducer';
import customTableConfigurationDetails from './custom_table_configuration.reducer';
import adminCustomTableConfigurationDetails from './admin_custom_table_configuration.reducer';
import dropoffSampleListDetails from './list.dropoff_sample.reducer';
import splitProducerListDetails from './list.split_producer.reducer';
import haulingChargeListDetails from './list.hauling_charges.reducer';
import paymentStatusDetails from './list.payment_status.reducer';
import producerHaulingContractListDetails from './list.producer_hauling_contract.reducer';
import supplementalHaulingContractListDetails from './list.supplemental_hauling_contract.reducer';
import haulerAssignmentListDetails from './list.hauler_assignment.reducer';
import haulerMonthlyPayReportsReducer from './hauler.pay.reports.reducer';
import invoicingReducer from './invoicing.reducer';
import equityMemberReducer from './equityMember.reducer';
import selectedHaulingContractTabReducer from './selected_hauling_contract_tab_reducer';
import listEquityMemberReducer from './list.equityMember.reducer';

const reducer = combineReducers({
    adminDashboardDetails,
    adminEstimatedFluidSalesDetails,
    adminInTransitDetails,
    adminOnFarmEstimateDetails,
    adminMonthlyPayReportsReducer,
    adminVendorMonthlyPayReportsReducer,
    adminHaulerMonthlyPayReportsReducer,
    processorDashboardDetails,
    producerMonthlyPayReportsReducer,

    producerDashboardDetails,
    producerDashboardLabReportDetails,
    producerDashboardLabAverages,

    userListDetails,

    dropoffSampleListDetails,
    adminListDetails,

    assigneeListDetails,

    assignmentListDetails,
    assignmentConfigurationListDetails,

    producerListDetails,
    producerBusinessListDetails,

    driverListDetails,
    receiverListDetails,

    processorListDetails,

    getMilkPrices,
    labProviderListDetails,
    haulingListDetails,

    trailerListDetails,

    truckListDetails,

    routesListDetails,

    siloListDetails,

    deferralListDetails,

    subProducerListDetails,
    subProcessorListDetails,
    subHaulingListDetails,
    subUserListDetails,

    pickupProcessorDetails,

    pickupDetails,

    dropoffDetails,

    labDetails,

    baseTransferDetails,

    listCloseDayDetails,
    listCloseMonthDetails,

    washDetails,
    washFacilityDetails,

    routeSessionDetails,
    routeSessionPickups,
    routeSessionDropoffs,
    sessionDetails,

    monthlyReportsDetails,

    inspectRouteSessionDetails,

    receivedSharedFileDetails,
    sharedFileDetails,

    quotaLeasingDetails,

    routeStatsDetails,
    driverStatsDetails,
    driverRouteSessionsDetails,
    routeRouteSessionsDetails,
    generalRouteSessionsDetails,
    generalRouteStatsDetails,
    producerDashboardPickupAndLabDetails,

    splashScreenDetails,

    singleProducerProductionDetails,

    productionStatsDetails,

    userSettingsDetails,
    userReportScheduleDetails,

    poolingListDetails,
    customTableConfigurationDetails,
    adminCustomTableConfigurationDetails,

    splitProducerListDetails,

    haulingChargeListDetails,

    paymentStatusDetails,

    producerHaulingContractListDetails,
    supplementalHaulingContractListDetails,

    haulerAssignmentListDetails,
    haulerMonthlyPayReportsReducer,

    invoicingReducer,

    equityMemberReducer,
    listEquityMemberReducer,
    selectedHaulingContractTabReducer,
});

export default reducer;
