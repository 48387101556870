import React from 'react';
import { Login } from '@milkmoovement/common_cognito_package';
import { onCognitoUnlock } from '../../../auth/store/actions';
import { useDispatch } from 'react-redux';
import { getRegion } from '../../../../utils';

const LockPage = ({ onUnlock, onLogout }) => {
    const region = getRegion(window.location.hostname);
    const dispatch = useDispatch();

    const handleSucessfulLogin = async () => {
        dispatch(await onCognitoUnlock());
        await onUnlock();
    };

    return (
        <Login
            isLocked
            onSucessfulLogin={handleSucessfulLogin}
            onLogout={onLogout}
            metadata={{
                coreSystem: region,
            }}
        />
    );
};

export default LockPage;
