import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { arrayToMap, getUserRegion } from '../../../utils';
import { Button, Divider, InputAdornment } from '@material-ui/core';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Formsy from 'formsy-react';
import { showMessage } from 'app/store/actions';
import { TextFieldFormsy } from '@fuse';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import { addProducerBusiness, editProducerBusiness } from '../../repository';

const ProducerBusinessForm = ({ editData, producers }) => {
    const history = useHistory();
    const [canSubmit, setCanSubmit] = useState(false);
    const [name, setName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [address2, setAddress2] = useState('');
    const [town, setTown] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [CMTNumber, setCMTNumber] = useState('');
    const [territory, setTerritory] = useState('');
    const [btu, setBTU] = useState('');
    const region = getUserRegion();
    const [myProducers, setMyProducers] = useState([]);

    const transformProducers = (producersList) => {
        const producersMap = arrayToMap(producersList, 'value');
        const transformedProducers = editData.producers.map((producer) => producersMap[producer]);
        setMyProducers(
            // On autofill we get a the stringified value.
            typeof transformedProducers === 'string' ? transformedProducers.split(',') : transformedProducers
        );
    };

    /* eslint-disable camelcase */
    useEffect(() => {
        if (editData?.name) setName(editData.name);
        if (editData?.username) setUsername(editData.username);
        if (editData?.email) setEmail(editData.email);
        if (editData?.address1) setAddress(editData.address1);
        if (editData?.address2) setAddress2(editData.address2);
        if (editData?.town) setTown(editData.town);
        if (editData?.postal_code) setPostalCode(editData.postal_code);
        if (editData?.CMTNumber) setCMTNumber(editData.CMTNumber);
        if (editData?.producers) setMyProducers(editData.producers);
        if (editData?.territory) setTerritory(editData.territory);
        if (editData?.btu) setBTU(editData.btu);
        if (editData?.producers) transformProducers(producers);
    }, [editData]);

    const disableButton = () => {
        setCanSubmit(false);
    };

    const enableButton = () => {
        setCanSubmit(true);
    };

    const handleMyProducers = (event) => {
        const {
            target: { value },
        } = event;
        setMyProducers(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value
        );
    };

    const onSubmit = (model) => {
        const submitModel = { ...model };
        submitModel.producers = myProducers.map((producer) => (producer.value ? producer.value : typeof producer === 'string' ? producer : null));
        if (!editData) {
            submitModel.region = region;
            submitModel.type_of_fluid = 'milk';
        }

        if (editData) {
            // eslint-disable-next-line no-underscore-dangle
            editProducerBusiness(submitModel, editData._id)
                .then((response) => {
                    showMessage({ message: 'Successfully Edited Producer Business' });
                    history.replace({ pathname: '/list-producer-businesses' });
                })
                .catch((err) => {
                    showMessage({ message: err.message });
                });
        } else {
            addProducerBusiness(submitModel)
                .then((response) => {
                    showMessage({ message: 'Successfully Added Producer Business' });
                    history.replace({ pathname: '/list-producer-businesses' });
                })
                .catch((err) => {
                    showMessage({ message: err.message });
                });
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const render = () => {
        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className={window.innerWidth > 600 ? 'w-3/4' : 'w-full'}>
                        <Divider />

                        <Formsy
                            onValidSubmit={onSubmit}
                            onValid={enableButton}
                            onInvalid={disableButton}
                            /* eslint-disable-next-line no-return-assign */
                            ref={(form) => form}
                            className="flex flex-col"
                        >
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="name"
                                label={'Producer Business Name'}
                                placeholder={'Producer Business Name'}
                                onChange={({ value }) => setName(value)}
                                value={name || null}
                                validations={{ minLength: 2 }}
                                validationError="Please enter a valid name"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-21 material-icons" color="action">
                                                person
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="username"
                                label={'Producer Business Username'}
                                placeholder={'Producer Business Username'}
                                onChange={({ value }) => setUsername(value)}
                                value={username || null}
                                validations={{ ...(!editData && { minLength: 4 }) }}
                                validationError="Please enter a valid username"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-21 material-icons" color="action">
                                                bookmark
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="email"
                                label={'Producer Business Email'}
                                placeholder={'Producer Business Email'}
                                onChange={({ value }) => setEmail(value)}
                                value={email || null}
                                validations="isEmail"
                                validationError="Please enter a valid email"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-21 material-icons" color="action">
                                                alternate_email
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />
                            {!editData && (
                                <>
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="password"
                                        name="password"
                                        label="Password"
                                        validations={{ minLength: 8, equalsField: 'password_confirmation' }}
                                        validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                        InputProps={{
                                            autoComplete: 'new-password',
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        vpn_key
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        required={!!!editData}
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="password"
                                        name="password_confirmation"
                                        label="Confirm Password"
                                        validations={{ minLength: 8, equalsField: 'password' }}
                                        validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        vpn_key
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        required={!!!editData}
                                    />
                                </>
                            )}
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="address1"
                                label={'Mailing Address Line 1'}
                                placeholder={'Mailing Address Line 1'}
                                onChange={({ value }) => setAddress(value)}
                                value={address || ''}
                                validations={{ minLength: 2 }}
                                validationError="Please enter a valid address"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-21 material-icons" color="action">
                                                home
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="address2"
                                label="Mailing Address Line 2"
                                placeholder="Mailing Address Line 2"
                                onChange={({ value }) => setAddress2(value)}
                                value={address2 || ''}
                                validations={{ minLength: 2 }}
                                validationError="Please enter a valid address"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-21 material-icons" color="action">
                                                home
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="town"
                                label={'Mailing City'}
                                placeholder={'Mailing City'}
                                onChange={({ value }) => setTown(value)}
                                value={town || ''}
                                validations={{ minLength: 2 }}
                                validationError="Please enter a valid mailing city"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-21 material-icons" color="action">
                                                place
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="territory"
                                label="Territory"
                                placeholder="Territory"
                                value={territory || ''}
                                onChange={({ value }) => setTerritory(value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                place
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="postal_code"
                                label={'Mailing Postal/ZIP Code'}
                                placeholder={'Mailing Postal/ZIP Code'}
                                onChange={({ value }) => setPostalCode(value)}
                                value={postalCode || ''}
                                validations="minLength: 4"
                                validationError="Please enter a valid postal code"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-21 material-icons" color="action">
                                                mail_outline
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />
                            <FormControl sx={{ m: 0 }}>
                                <InputLabel className="my-16">Producers</InputLabel>
                                <Select
                                    className="my-16"
                                    type="text"
                                    label={'Farm Name(s)'}
                                    labelId="demo-multiple-chip-label"
                                    id="demo-multiple-chip"
                                    multiple
                                    value={myProducers}
                                    onChange={handleMyProducers}
                                    validations={{ minLength: 1 }}
                                    validationError={'Please select a farm'}
                                    input={<OutlinedInput id="select-multiple-chip" label="Producers" />}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                            {selected.map((entry) => (
                                                <Chip key={entry.value} label={entry.name} />
                                            ))}
                                        </Box>
                                    )}
                                    required
                                    MenuProps={MenuProps}
                                >
                                    {producers.map((producer) => (
                                        <MenuItem key={producer.value} value={producer} className="my-16">
                                            {producer.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="CMTNumber"
                                label={'Producer Business ID'}
                                placeholder={'Producer Business ID'}
                                value={CMTNumber || null}
                                onChange={({ value }) => setCMTNumber(value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                list_alt
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="btu"
                                label="BTU"
                                placeholder="BTU"
                                value={btu || null}
                                onChange={({ value }) => setCMTNumber(value)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                list_alt
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />

                            <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={`${editData ? 'Edit' : 'Add'} Producer Business`.toUpperCase()} disabled={!canSubmit}>
                                {`${editData ? 'Edit' : 'Add'} Producer Business`}
                            </Button>
                        </Formsy>
                    </div>
                )}
            </LocalizationConsumer>
        );
    };

    return <>{render()}</>;
};

export default withRouter(ProducerBusinessForm);
