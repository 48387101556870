import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
    symbol: {
        display: 'block',
        margin: 'auto',
    },
});

const SymbolDropoff = () => {
    const classes = useStyles();

    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.symbol}>
            <g filter="url(#filter0_ddd_53_20544)">
                <circle cx="19" cy="18" r="15.2727" fill="#00A2F5" stroke="white" strokeWidth="1.45455" />
                <path d="M11.8091 18.1655L13.1056 16.869L18.2459 22V10.8092L20.0849 10.8092V22L25.216 16.8598L26.5217 18.1655L19.1654 25.5219L11.8091 18.1655Z" fill="white" />
            </g>
            <defs>
                <filter id="filter0_ddd_53_20544" x="0" y="0" width="38" height="38" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_53_20544" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
                    <feBlend mode="normal" in2="effect1_dropShadow_53_20544" result="effect2_dropShadow_53_20544" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feMorphology radius="1" operator="erode" in="SourceAlpha" result="effect3_dropShadow_53_20544" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="0.5" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                    <feBlend mode="normal" in2="effect2_dropShadow_53_20544" result="effect3_dropShadow_53_20544" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect3_dropShadow_53_20544" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default SymbolDropoff;
