import axios from 'axios';
import { buildMembershipQuery } from './queries/builders';

const deleteEquity = async ({ memberId, certificateId, deleteReason, userId }) => {
    try {
        const membershipQuery = `/v1/equityMembers/${memberId}/equityValues`;

        const base64EncodedReason = btoa(deleteReason);

        const equityMembershipQuery = buildMembershipQuery(membershipQuery, { query: { certificateId, deleteReason: base64EncodedReason, deletedBy: userId } });

        const { data } = await axios({ ...equityMembershipQuery, method: 'delete' });

        return data;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw new Error(err instanceof Error ? err.message : 'Unable to process request.');
    }
};

export default deleteEquity;
