const localization = {
    general: {
        full_name: 'Producer Name',
        hauling_plural: 'Hauling Companies',
        hauling_singular: 'Hauling Company',
        hauling_delivery: 'Delivery Hauler',
        license_number: 'Producer ID',
        lt_number: 'Route Session ID',
        metered: 'Net Amount',
        meteredLevel: 'Net Amount',
        monthlyReports: 'Monthly Reports',
        pickup_amount: 'Total Amount',
        processor_singular: 'Processor',
        processor_plural: 'Processors',
        producer_weight: 'Producer Weight',
        routeNumber: 'Lt Number',
        routeSession: 'Route Session',
        tank: 'Tank',
        trailer: 'Trailer',
        transfer: 'Quota',
        volume: 'Producer Amount',
    },
    navigation: {
        'Base Transfers': 'Quota Transfers',
        'Penalty Status': 'Quality Report',
    },
    lab: {
        scc: 'scc',
        temperature: 'Freezing Point',
        sample_date: 'Date',
    },
    processed_fluids: {
        status: {
            active: 'Active',
            closed: 'Closed',
            inactive: 'Awaiting Offload',
            preparing_load: 'Preparing Load',
            awaiting_pickup: 'Awaiting Pickup',
            in_transit: 'In Transit',
            awaiting_dropoff: 'Awaiting Dropoff',
            manual_adjustment: 'Manual Adjustment',
            cancelled: 'Cancelled',
        },
    },
    other: {},
};

export default localization;
