import * as Actions from '../../actions';

const initialState = { data: null };

const producerMonthlyPayReportsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_PRODUCER_MONTHLY_PAY_REPORTS:
            return { ...state, data: { ...action.payload } };
        default:
            return state;
    }
};

export default producerMonthlyPayReportsReducer;
