import moment from 'moment-timezone';
import axios from 'axios';
import _ from 'lodash';
import { getLabReportsQuery, getPickupsQuery, getProducersQuery } from './queries';
import { getTwoStringOfMonth } from '../../utils';
import { getAlertData } from './utils';

const requiresPondviewAdjustments = (region) => {
    switch (region) {
        case 'NL':
            return true;
        default:
            return false;
    }
};

const requirePenaltiesByPercentage = (region) => {
    switch (region) {
        case 'NL':
            return true;
        default:
            return false;
    }
};

const getPayCalcsDetails = (month, year, region) =>
    new Promise((resolve, reject) => {
        const startDate = moment(`${year}-${getTwoStringOfMonth(month)}-01`)
            .startOf('month')
            .format();
        const endDate = moment(`${year}-${getTwoStringOfMonth(month)}-01`)
            .endOf('month')
            .format();

        const pickupsQuery = getPickupsQuery({
            start: startDate,
            end: endDate,
            order: 'created_at ASC',
            filterDeleted: true,
            filterType: 'milk',
        });
        const labsQuery = getLabReportsQuery({
            start: startDate,
            end: endDate,
            order: 'date ASC',
            bySampleDate: true,
            filterOutliers: true,
            filterDeleted: true,
        });
        const producerQuery = getProducersQuery({
            filterDeleted: true,
            includeSubUser: false,
            orderBy: 'order',
        });

        const pickups = axios.get(pickupsQuery);
        const labs = axios.get(labsQuery);
        const producers = axios.get(producerQuery);

        axios
            .all([pickups, labs, producers])
            .then(
                axios.spread((...responses) => {
                    const tableDetails = responses[2].data;
                    const producerGroupedPickups = _.groupBy(responses[0].data, (v) => v.producer_id);
                    const producerGroupedPickupsKeys = new Set(_.keys(producerGroupedPickups));
                    const labSet = responses[1].data;
                    const producerGroupedLabs = _.groupBy(labSet, (v) => v.producer_id);
                    const producerGroupedLabsKeys = new Set(_.keys(producerGroupedLabs));
                    const dateRange = {
                        start: moment(endDate).add(1, 'seconds').subtract(3, 'months'),
                        end: moment(endDate),
                    };

                    _.forEach(tableDetails, (detail) => {
                        if (producerGroupedPickupsKeys.has(detail.id)) {
                            _.assign(detail, {
                                num_pickups: producerGroupedPickups[detail.id].length,
                                volume: _.round(
                                    _.sumBy(producerGroupedPickups[detail.id], (v) => v.volume),
                                    2
                                ),
                            });
                        }

                        if (producerGroupedLabsKeys.has(detail.id)) {
                            _.assign(detail, {
                                bacto_plc: 0.0,
                                bacto_scan: _.round(
                                    _.meanBy(
                                        producerGroupedLabs[detail.id].filter((value) => !!value.bacto_scan),
                                        (v) => v.bacto_scan
                                    ),
                                    2
                                ),
                                butter_fat: _.round(
                                    _.meanBy(
                                        producerGroupedLabs[detail.id].filter((value) => !!value.fat),
                                        (v) => v.fat
                                    ),
                                    2
                                ),
                                scc: _.round(
                                    _.meanBy(
                                        producerGroupedLabs[detail.id].filter((value) => !!value.somatic_cell_count),
                                        (v) => v.somatic_cell_count
                                    ),
                                    2
                                ),
                                los: _.round(
                                    _.meanBy(
                                        producerGroupedLabs[detail.id].filter((value) => !!value.LOS),
                                        (v) => v.LOS
                                    ),
                                    2
                                ),
                                protein: _.round(
                                    _.meanBy(
                                        producerGroupedLabs[detail.id].filter((value) => !!value.protein),
                                        (v) => v.protein
                                    ),
                                    2
                                ),
                            });
                        }

                        const labReports = producerGroupedLabs[detail.id] ? producerGroupedLabs[detail.id] : [];
                        const alertDetails = getAlertData(dateRange, labReports, region);
                        const penalties = requirePenaltiesByPercentage(region)
                            ? {
                                  bacto_penalty: alertDetails.bacto_percentage,
                                  scc_penalty: alertDetails.scc_percentage,
                                  freezing_penalty: null,
                              }
                            : {
                                  bacto_penalty: alertDetails.bacto_scan_count_over_standard,
                                  scc_penalty: alertDetails.scc_count_over_standard,
                                  freezing_penalty: alertDetails.freezing_point_count_over_standard,
                              };

                        _.assign(detail, penalties);
                    });

                    // Pondview adjustment logic
                    if (requiresPondviewAdjustments(region)) {
                        const pondviewFarmsIndex = tableDetails.findIndex((producer) => producer.CMTNumber === '103900');
                        const forestPondRoadIndex = tableDetails.findIndex((producer) => producer.CMTNumber === '103200');

                        const pondViewFarms = _.clone(tableDetails[pondviewFarmsIndex]);

                        tableDetails[forestPondRoadIndex].volume = 1300 * pondViewFarms.num_pickups;
                        tableDetails[pondviewFarmsIndex].volume = pondViewFarms.volume - 1300 * pondViewFarms.num_pickups;

                        tableDetails[forestPondRoadIndex].butter_fat = pondViewFarms.butter_fat;
                        tableDetails[forestPondRoadIndex].protein = pondViewFarms.protein;
                        tableDetails[forestPondRoadIndex].los = pondViewFarms.los;
                    }

                    resolve(tableDetails);
                })
            )
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
            });
    });

export default getPayCalcsDetails;
