import axios from 'axios';
import { getMilkPriceQuery } from './queries';
import moment from 'moment-timezone';

const getMilkPrices = (date) =>
    new Promise((resolve, reject) => {
        const query = getMilkPriceQuery({ date: moment(date).format('YYYY-MM') });
        const request = axios.get(query);

        request
            .then((response) => {
                resolve({ prices: response.data[0] });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to get milk prices'));
            });
    });

export default getMilkPrices;
