import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

export const Index = {
    auth: process.env.NODE_ENV !== 'development' ? ['onlyDev'] : authRoles.admin,
    routes: [
        {
            path: '/configure-preset-buttons',
            component: FuseLoadable({
                loader: () => import('./ConfigurePresetButtonApp'),
            }),
        },
    ],
};

export default [Index];
