import './typedefs';

/**
 * @returns {Object} - dictionary of environments
 */
export function createEnvironmentsDictionary(environments) {
    return Object.assign({}, ...environments.map((env) => ({ [env.name]: env })));
}

/**
 * @returns {Treatment} - default region checker
 */
export function defaultEvaluationFn(attributes, regions) {
    if (regions.includes(attributes.region)) return 'on';
    return 'off';
}
