import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { DeleteDialog, MonthlyReportDialog, SlipDialog } from 'app/custom-widgets';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import LeaseViewerDialog from './LeaseViewerDialog';

function PaperComponent(props) {
    return (
        // <Draggable>
        <Paper {...props} />
        // </Draggable>
    );
}

class TableSelectionDialog extends React.Component {
    state = { open: true, autoOpen: '' };

    componentDidMount() {
        const { autoOpen } = this.props;

        this.setState({ autoOpen });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { autoOpen } = this.state;

        if (prevState.autoOpen !== autoOpen) {
            if (autoOpen === 'inspect') {
                document.getElementById('inspect').click();
            }
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = ({ handleSelectionClose }) => {
        this.setState({ open: false });
        handleSelectionClose();
    };

    render() {
        const { role, handleDownload, handleEdit, handleInspect, handleLogin, loginActionButton, editActionButton, inspectActionButton, inspectRouteSessionActionButton, deleteActionButton, slipActionButton, downloadActionButton, inspectLeaseActionButton, reportActionButton, selected, forceFetch } = this.props;
        const { autoOpen, open } = this.state;

        return (
            <div>
                <Dialog open={open} onClose={() => this.handleClose(this.props)} PaperComponent={PaperComponent} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
                    <DialogTitle id="draggable-dialog-title">Selection Options</DialogTitle>
                    <DialogContent>
                        <div
                            style={{
                                margin: 'auto',
                                width: 'fit-content',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {reportActionButton && <MonthlyReportDialog data={selected[0]} />}
                            {slipActionButton && <SlipDialog data={selected[0]} type={slipActionButton.type} shouldAutoOpen={autoOpen === 'slip'} />}
                            {downloadActionButton && (
                                <div>
                                    <Button variant="contained" color="primary" className="my-16" aria-label={downloadActionButton.title} onClick={() => handleDownload('download', selected[0])}>
                                        {downloadActionButton.title}
                                    </Button>
                                </div>
                            )}
                            {loginActionButton && (
                                <div>
                                    <Button variant="contained" color="primary" className="my-16" aria-label={loginActionButton.title} onClick={() => handleLogin('Log in as', selected[0])}>
                                        {loginActionButton.title}
                                    </Button>
                                </div>
                            )}
                            {inspectActionButton && (
                                <div>
                                    <Button id="inspect" variant="contained" color="primary" className="my-16" aria-label={inspectActionButton.title} onClick={() => handleInspect(inspectActionButton, selected[0])}>
                                        {inspectActionButton.title}
                                    </Button>
                                </div>
                            )}
                            {inspectRouteSessionActionButton && (
                                <div>
                                    <Button id="inspect" variant="contained" color="primary" className="my-16" aria-label={inspectRouteSessionActionButton.title} onClick={() => handleInspect(inspectRouteSessionActionButton, selected[0])}>
                                        {inspectRouteSessionActionButton.title}
                                    </Button>
                                </div>
                            )}
                            {inspectLeaseActionButton && (
                                <div>
                                    <LeaseViewerDialog selected={selected[0]} type={inspectLeaseActionButton.type} title={inspectLeaseActionButton.title} />
                                </div>
                            )}
                            {editActionButton && (
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="my-16"
                                        aria-label={editActionButton.title}
                                        onClick={() => handleEdit(editActionButton, selected[0])}
                                        disabled={role === 'transport' && (editActionButton.title.includes('Pickup') || editActionButton.title.includes('Dropoff')) && selected[0].created_at && moment().date() > 7 && moment(selected[0].created_at).isBefore(moment().date(1).hour(0).minutes(0).seconds(0))}
                                    >
                                        {editActionButton.title}
                                    </Button>
                                </div>
                            )}
                            {deleteActionButton && <DeleteDialog title={deleteActionButton.title} dataType={deleteActionButton.dataType} data={selected[0]} forceFetch={forceFetch} />}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleClose(this.props)} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps({ persisted }) {
    return { role: persisted.auth.user.role };
}

export default withRouter(connect(mapStateToProps)(TableSelectionDialog));
