import axios from 'axios';
import { editBaseTransferQuery } from './queries';

const editBaseTransfer = (model, key, localization) =>
    new Promise((resolve, reject) => {
        const query = editBaseTransferQuery({ key, model });
        const request = axios.put(query.url, { ...query.body }, { params: { useNewPutRoute: true } });
        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error(`Unable to edit ${localization.general.transfer} transfer`));
            });
    });

export default editBaseTransfer;
