import axios from 'axios';
import _ from 'lodash';
import { getPayReportsQuery, getDeferralReportsQuery, getApprovedPaymentReportsQuery } from './queries';
import moment from 'moment-timezone';
import { ReportType } from '../constants/report';

const getProducerMonthlyPayReports = async (start, end, region, producerId) => {
    const startDate = moment(start).startOf('month').format();
    const endDate = moment(end).endOf('month').format();
    const reportsRequest = axios.get(getPayReportsQuery({ startDate, endDate, region, producerId }));
    const deferralsRequest = axios.get(getDeferralReportsQuery({ startDate, endDate, producerId }));
    const paymentReportsRequest = axios.get(getApprovedPaymentReportsQuery({ startDate, endDate, producerId }));

    const [reportsResponse, deferralsResponse, paymentReportsResponse] = await axios.all([reportsRequest, deferralsRequest, paymentReportsRequest]);
    const [reports, deferrals, paymentReports] = [reportsResponse.data, deferralsResponse.data, paymentReportsResponse.data];

    const { settlement, advance } = reports;

    const deferralDates = [];
    const producerDeferrals = [];
    deferrals.forEach((entry) => {
        // eslint-disable-next-line no-underscore-dangle
        const producerWithDate = `${entry.producer._id}${moment(entry.deferred_payment_date).startOf('day')}`;
        if (!deferralDates.includes(producerWithDate) && entry?.approved_for_producer) {
            // eslint-disable-next-line no-underscore-dangle
            deferralDates.push(producerWithDate);
            producerDeferrals.push({ ...entry, type: ReportType.deferral });
        }
    });

    const publishedPaymentReports = paymentReports?.filter((report) => report?.publish_date == null || moment(report.publish_date).isSameOrBefore(moment()));

    const reportsForProducer = [...publishedPaymentReports, ...producerDeferrals];
    settlement.forEach((report) => {
        if (report.producer.toString() === producerId && report.approved_for_producer_settlement) {
            reportsForProducer.push({ ...report, type: ReportType.settlement });
        }
    });
    advance.forEach((report) => {
        if (report.producer.toString() === producerId && report.approved_for_producer_advance) {
            reportsForProducer.push({ ...report, type: ReportType.advance });
        }
    });

    return {
        data: reportsForProducer,
    };
};

export default getProducerMonthlyPayReports;
