import axios from 'axios';
import { getCloseMonthQuery } from './queries';

const getCloseMonthSummary = async (startDate, endDate) => {
    try {
        const query = getCloseMonthQuery({ startDate, endDate });
        const request = axios.get(query);
        const { data } = await request;
        if (data.length === 0) return false;
        return data.every((day) => day.is_locked !== '' && day.is_locked);
    } catch (err) {
        // eslint-disable-next-line no-console
        throw new Error(err);
    }
};

export default getCloseMonthSummary;
