import { FuseLoadable } from '@fuse';

const SiloInvoices = {
    auth: ['admin', 'sub-admin', 'processor', 'sub-processor'],
    routes: [
        {
            path: '/silo-invoices',
            component: FuseLoadable({
                loader: () => {
                    return import('./SiloInvoicesList');
                },
            }),
        },
        {
            path: '/edit-silo-invoice/:invoice_number',
            component: FuseLoadable({
                loader: () => {
                    return import('./form/MutateSiloInvoices');
                },
            }),
        },
    ],
};

export default [SiloInvoices];
