import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box } from '@mui/material';
import { NotificationContext } from '../../../NotificationContext';

const sx = {
    root: {
        background: (theme) => theme.palette.primary.default,
        color: (theme) => theme.palette.primary.contrastText,
    },
};

const TitledPage = ({ title, subtitle, children }) => {
    const { setToolbarContent } = useContext(NotificationContext);
    useEffect(() => {
        setToolbarContent({ title, subtitle });
        return () => setToolbarContent({ title: '', subtitle: null, content: null });
    }, []);

    return (
        <Box sx={sx}>
            <Helmet>
                <title>{`${title} - Milk Moovement`}</title>
            </Helmet>
            {children}
        </Box>
    );
};

export default TitledPage;
