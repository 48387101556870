import LoginConfig from './login/config';
import InternalLoginConfig from './internal-login/config';
import LogoutConfig from './logout/config';
import PasswordConfig from './password/config';
import RegisterConfig from './register/config';
import MFAConfig from './register-mfa/config';
import SupportConfig from './support/config';
import TestPageConfig from './test-page/config';
import SettingsConfig from './settings/config';
import Error404PageConfig from './errors/404/Error404PageConfig';
import Error500PageConfig from './errors/500/Error500PageConfig';

export default [...InternalLoginConfig, ...LoginConfig, ...LogoutConfig, ...PasswordConfig, ...RegisterConfig, ...MFAConfig, ...SupportConfig, ...SettingsConfig, ...TestPageConfig, Error404PageConfig, Error500PageConfig];
