import store from 'app/store';
import { FuseLoadable } from '@fuse';

export const Index = {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/vendor-pay-reports',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                            return import('./AdminVendorPayReportsApp');
                        default:
                            break;
                    }
                },
            }),
        },
    ],
};

export default [Index];
