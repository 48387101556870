import _ from 'lodash';
import moment from 'moment-timezone';
import { arrayToRealMap } from '../../../utils';

const requiresComponentAverageByMonth = (region) => {
    switch (region) {
        case 'RF':
            return false;
        case 'BORDEN':
            return false;
        default:
            return true;
    }
};

const requiresMatchingByBarcode = (region) => {
    switch (region) {
        case 'BORDEN':
            return true;
        default:
            return false;
    }
};

const atMostOneLabPerPickup = (region) => {
    switch (region) {
        case 'RF':
            return true;
        default:
            return false;
    }
};

const hasPickupIdOnLabs = (region) => {
    switch (region) {
        case 'CDI':
        case 'UDA':
        case 'RF':
        case 'PRAIRIE':
        case 'LEGACY':
        case 'MMPA':
            return true;
        default:
            return false;
    }
};

const nonZeroComponentFilters = {
    fat: (o) => o.fat && o.fat !== 0,
    fat_weight: (o) => o.fat_weight && o.fat_weight !== 0,
    fat_percentage: (o) => o.fat_percentage && o.fat_percentage !== 0,
    los: (o) => o.LOS && o.LOS !== 0,
    lactose: (o) => o.lactose && o.lactose !== 0,
    other_solids: (o) => o.solids_not_fat && o.solids_not_fat !== 0 && o.protein && o.protein !== 0,
    other_solids_weight: (o) => o.solids_not_fat && o.solids_not_fat !== 0 && o.protein && o.protein !== 0,
    solids_not_fat: (o) => o.solids_not_fat && o.solids_not_fat !== 0,
    solids_not_fat_weight: (o) => o.solids_not_fat && o.solids_not_fat !== 0,
    total_solids: (o) => o.total_solids && o.total_solids !== 0,
    protein: (o) => o.protein && o.protein !== 0,
    protein_weight: (o) => o.protein_weight && o.protein_weight !== 0,
    protein_percentage: (o) => o.protein_percentage && o.protein_percentage !== 0,
    scc: (o) => o.somatic_cell_count && o.somatic_cell_count !== 0,
    bmcc: (o) => o.bulk_milk_cell_count && o.bulk_milk_cell_count !== 0,
    standard_plate_count: (o) => o.standard_plate_count && o.standard_plate_count !== 0,
};

const calculateComponents = (pickups, groupedLabReports, region, labsByPickup = []) => {
    if (pickups.length === 0) {
        return {
            fat: 0,
            fat_weight: 0,
            fat_percentage: 0,
            LOS: 0,
            protein: 0,
            protein_weight: 0,
            protein_percentage: 0,
            lactose: 0,
            other_solids: 0,
            other_solids_weight: 0,
            solids_not_fat: 0,
            solids_not_fat_weight: 0,
            total_solids: 0,
            scc: 0,
            bmcc: 0,
            volume: '0',
            standard_plate_count: 0,
        };
    }

    const pickupsData = _.map(pickups, (pickup) => {
        let labReportsForPickup = [];

        if (hasPickupIdOnLabs(region)) {
            labReportsForPickup = labsByPickup.get(pickup.id) ? [labsByPickup.get(pickup.id)] : [];
        } else if (requiresComponentAverageByMonth(region)) {
            labReportsForPickup = _.mapValues(groupedLabReports, (v) => _.flattenDeep(_.map(v)))[pickup.producer_id];
        } else if (requiresMatchingByBarcode(region)) {
            labReportsForPickup = _.mapValues(groupedLabReports, (v) => _.flattenDeep(_.map(v)))[pickup.component_barcode] ? _.mapValues(groupedLabReports, (v) => _.flattenDeep(_.map(v)))[pickup.component_barcode] : [];
        } else {
            const pickupDate = moment(pickup.created_at).format('YYYYMMDD');
            const labReportExists = !!groupedLabReports[pickup.producer_id] && !!groupedLabReports[pickup.producer_id][pickupDate];
            const labReportsHaveValue = !!labReportExists && groupedLabReports[pickup.producer_id][pickupDate].some((lab) => !!lab.fat || !!lab.LOS || !!lab.protein || !!lab.scc);
            labReportsForPickup = labReportExists && labReportsHaveValue ? groupedLabReports[pickup.producer_id][pickupDate] : [];

            if (atMostOneLabPerPickup(region) && labReportsForPickup.length > 1) {
                labReportsForPickup = labReportsForPickup.filter((lab) => {
                    return lab.sample_barcode === pickup.component_barcode;
                });
            }
        }

        const nonZeroFatReports = _.filter(labReportsForPickup, nonZeroComponentFilters.fat);
        const nonZeroFatWeightReports = _.filter(labReportsForPickup, nonZeroComponentFilters.fat_weight);
        const nonZeroFatPercentageReports = _.filter(labReportsForPickup, nonZeroComponentFilters.fat_percentage);
        const nonZeroLosReports = _.filter(labReportsForPickup, nonZeroComponentFilters.los);
        const nonZeroProteinReports = _.filter(labReportsForPickup, nonZeroComponentFilters.protein);
        const nonZeroProteinWeightReports = _.filter(labReportsForPickup, nonZeroComponentFilters.protein_weight);
        const nonZeroProteinPercentageReports = _.filter(labReportsForPickup, nonZeroComponentFilters.protein_percentage);
        const nonZeroLactoseReports = _.filter(labReportsForPickup, nonZeroComponentFilters.lactose);
        const nonZeroSolidsNotFatReports = _.filter(labReportsForPickup, nonZeroComponentFilters.solids_not_fat);
        const nonZeroSolidsNotFatWeightReports = _.filter(labReportsForPickup, nonZeroComponentFilters.solids_not_fat_weight);
        const nonZeroOtherSolidsReports = _.filter(labReportsForPickup, nonZeroComponentFilters.other_solids);
        const nonZeroOtherSolidsWeightReports = _.filter(labReportsForPickup, nonZeroComponentFilters.other_solids_weight);
        const nonZeroTotalSolidsReports = _.filter(labReportsForPickup, nonZeroComponentFilters.total_solids);
        const nonZeroSccReports = _.filter(labReportsForPickup, nonZeroComponentFilters.scc);
        const nonZeroBmccReports = _.filter(labReportsForPickup, nonZeroComponentFilters.bmcc);
        const nonZeroStandardPlateCountReports = _.filter(labReportsForPickup, nonZeroComponentFilters.standard_plate_count);

        const components = {
            fat: nonZeroFatReports.length > 0 ? _.meanBy(nonZeroFatReports, 'fat') : 0,
            fat_weight: nonZeroFatWeightReports.length > 0 ? _.meanBy(nonZeroFatWeightReports, 'fat_weight') : 0,
            fat_percentage: nonZeroFatPercentageReports.length > 0 ? _.meanBy(nonZeroFatPercentageReports, 'fat_percentage') : 0,
            los: nonZeroLosReports.length > 0 ? _.meanBy(nonZeroLosReports, 'LOS') : 0,
            protein: nonZeroProteinReports.length > 0 ? _.meanBy(nonZeroProteinReports, 'protein') : 0,
            protein_weight: nonZeroProteinWeightReports.length > 0 ? _.meanBy(nonZeroProteinWeightReports, 'protein_weight') : 0,
            protein_percentage: nonZeroProteinPercentageReports.length > 0 ? _.meanBy(nonZeroProteinPercentageReports, 'protein_percentage') : 0,
            lactose: nonZeroLactoseReports.length > 0 ? _.meanBy(nonZeroLactoseReports, 'lactose') : 0,
            // CALCULATE BY SNF - PROTEIN
            other_solids: nonZeroOtherSolidsReports.length > 0 ? _.meanBy(nonZeroOtherSolidsReports, 'other_solids') : 0,
            // other_solids_weight: nonZeroOtherSolidsWeightReports.length > 0 ? _.meanBy(nonZeroOtherSolidsWeightReports, 'other_solids_weight') : 0,
            solids_not_fat: nonZeroSolidsNotFatReports.length > 0 ? _.meanBy(nonZeroSolidsNotFatReports, 'solids_not_fat') : 0,
            solids_not_fat_weight: nonZeroSolidsNotFatWeightReports.length > 0 ? _.meanBy(nonZeroSolidsNotFatWeightReports, 'solids_not_fat_weight') : 0,
            total_solids: nonZeroTotalSolidsReports.length > 0 ? _.meanBy(nonZeroTotalSolidsReports, 'total_solids') : 0,
            scc: nonZeroSccReports.length > 0 ? _.meanBy(nonZeroSccReports, 'somatic_cell_count') : 0,
            bmcc: nonZeroBmccReports.length > 0 ? _.meanBy(nonZeroBmccReports, 'bulk_milk_cell_count') : 0,
            standard_plate_count: nonZeroStandardPlateCountReports.length > 0 ? _.meanBy(nonZeroStandardPlateCountReports, 'standard_plate_count') : 0,
        };

        return { ...components, volume: pickup.volume };
    });
    if (pickupsData.length === 1 && !['UDA'].includes(region)) {
        return {
            fat: pickupsData[0].fat,
            fat_weight: pickupsData[0].fat_weight,
            fat_percentage: pickupsData[0].fat_percentage,
            LOS: pickupsData[0].los,
            protein: pickupsData[0].protein,
            protein_weight: pickupsData[0].protein_weight,
            protein_percentage: pickupsData[0].protein_percentage,
            lactose: pickupsData[0].lactose,
            other_solids: region === 'CDI' ? pickupsData[0].solids_not_fat - pickupsData[0].protein : pickupsData[0].other_solids,
            solids_not_fat: pickupsData[0].solids_not_fat,
            total_solids: pickupsData[0].total_solids,
            scc: pickupsData[0].scc,
            bmcc: pickupsData[0].bmcc,
            volume: pickupsData[0].volume ? pickupsData[0].volume.toString() : '',
            standard_plate_count: pickupsData[0].standard_plate_count,
        };
    }

    const weightedComponents = _.map(pickupsData, (o) => ({
        weightedFat: o.volume * o.fat,
        weightedLos: o.volume * o.los,
        weightedProtein: o.volume * o.protein,
        weightedLactose: o.volume * o.lactose,
        weightedOtherSolids: o.volume * (o.solids_not_fat - o.protein),
        weightedTotalSolids: o.volume * o.total_solids,
        weightedSolidsNotFat: o.volume * o.solids_not_fat,
        weightedScc: o.volume * o.scc,
        weightedBmcc: o.volume * o.bmcc,
        weightedStandardPlateCount: o.volume * o.standard_plate_count,
        weightedFatVolume: o.fat ? o.volume : 0,
        weightedLosVolume: o.los ? o.volume : 0,
        weightedProteinVolume: o.protein ? o.volume : 0,
        weightedLactoseVolume: o.lactose ? o.volume : 0,
        weightedOtherSolidsVolume: o.solids_not_fat && o.protein ? o.volume : 0,
        weightedSolidsNotFatVolume: o.solids_not_fat ? o.volume : 0,
        weightedTotalSolidsVolume: o.total_solids ? o.volume : 0,
        weightedSccVolume: o.scc ? o.volume : 0,
        weightedBmccVolume: o.bmcc ? o.volume : 0,
        weightedStandardPlateCountVolume: o.standard_plate_count ? o.volume : 0,
    }));
    const componentSums = _.reduce(weightedComponents, (acc, value) => ({
        weightedFat: acc.weightedFat + value.weightedFat,
        weightedLos: acc.weightedLos + value.weightedLos,
        weightedProtein: acc.weightedProtein + value.weightedProtein,
        weightedLactose: acc.weightedLactose + value.weightedLactose,
        weightedOtherSolids: acc.weightedOtherSolids + value.weightedOtherSolids,
        weightedSolidsNotFat: acc.weightedSolidsNotFat + value.weightedSolidsNotFat,
        weightedTotalSolids: acc.weightedTotalSolids + value.weightedTotalSolids,
        weightedScc: acc.weightedScc + value.weightedScc,
        weightedBmcc: acc.weightedBmcc + value.weightedBmcc,
        weightedStandardPlateCount: acc.weightedStandardPlateCount + value.weightedStandardPlateCount,
        weightedFatVolume: acc.weightedFatVolume + value.weightedFatVolume,
        weightedLosVolume: acc.weightedLosVolume + value.weightedLosVolume,
        weightedProteinVolume: acc.weightedProteinVolume + value.weightedProteinVolume,
        weightedOtherSolidsVolume: acc.weightedOtherSolidsVolume + value.weightedOtherSolidsVolume,
        weightedSolidsNotFatVolume: acc.weightedSolidsNotFatVolume + value.weightedSolidsNotFatVolume,
        weightedTotalSolidsVolume: acc.weightedTotalSolidsVolume + value.weightedTotalSolidsVolume,
        weightedLactoseVolume: acc.weightedLactoseVolume + value.weightedLactoseVolume,
        weightedSccVolume: acc.weightedSccVolume + value.weightedSccVolume,
        weightedBmccVolume: acc.weightedBmccVolume + value.weightedBmccVolume,
        weightedStandardPlateCountVolume: acc.weightedStandardPlateCountVolume + value.weightedStandardPlateCountVolume,
    }));

    const pickupsTotal = _.sumBy(pickupsData, 'volume');

    return {
        fat: componentSums.weightedFatVolume !== 0 ? componentSums.weightedFat / componentSums.weightedFatVolume : 0,
        fat_weight: componentSums.weightedFatVolume !== 0 ? (componentSums.weightedFat / componentSums.weightedFatVolume / 100) * pickupsTotal : 0,
        LOS: componentSums.weightedLosVolume !== 0 ? componentSums.weightedLos / componentSums.weightedLosVolume : 0,
        protein: componentSums.weightedProteinVolume !== 0 ? componentSums.weightedProtein / componentSums.weightedProteinVolume : 0,
        protein_weight: componentSums.weightedProteinVolume !== 0 ? (componentSums.weightedProtein / componentSums.weightedProteinVolume / 100) * pickupsTotal : 0,
        lactose: componentSums.weightedLactoseVolume !== 0 ? componentSums.weightedLactose / componentSums.weightedLactoseVolume : 0,
        other_solids: componentSums.weightedOtherSolidsVolume !== 0 ? componentSums.weightedOtherSolids / componentSums.weightedOtherSolidsVolume : 0,
        other_solids_weight: componentSums.weightedOtherSolidsVolume !== 0 ? (componentSums.weightedOtherSolids / componentSums.weightedOtherSolidsVolume / 100) * pickupsTotal : 0,
        solids_not_fat: componentSums.weightedSolidsNotFat !== 0 ? componentSums.weightedSolidsNotFat / componentSums.weightedSolidsNotFatVolume : 0,
        solids_not_fat_weight: componentSums.weightedSolidsNotFatVolume !== 0 ? (componentSums.weightedSolidsNotFat / componentSums.weightedSolidsNotFatVolume / 100) * pickupsTotal : 0,
        total_solids: componentSums.weightedTotalSolids !== 0 ? componentSums.weightedTotalSolids / componentSums.weightedTotalSolidsVolume : 0,
        scc: componentSums.weightedSccVolume !== 0 ? componentSums.weightedScc / componentSums.weightedSccVolume : 0,
        bmcc: componentSums.weightedBmccVolume !== 0 ? componentSums.weightedBmcc / componentSums.weightedBmccVolume : 0,
        volume: pickupsTotal.toString(),
        standard_plate_count: componentSums.weightedStandardPlateCount !== 0 ? componentSums.weightedStandardPlateCount / componentSums.weightedStandardPlateCountVolume : 0,
    };
};

export default calculateComponents;
