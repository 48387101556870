import React, { useCallback } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import ReactHookFormInput from '../form-components/ReactHookFormInput';
import { showMessage, addAssignee, editAssignee } from 'app/store/actions';
import { Box, Button, Grid, debounce } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactHookFormSearchableSelect from '../form-components/ReactHookFormSearchableSelect';
import { paymentMethod } from '../form-components/AssigneeSelects';
import { state } from '../form-components/ReactHookProducerFormSelects';
import SwitchWithLabel from '../form-components/SwitchWithLabel';
import MuiPhoneNumber from 'material-ui-phone-number';
import _ from 'lodash';
import { GridRow } from './layout/GridRow';
import { getUserRegion } from '../../../utils';
import Region from '../../constants/region';

const AssigneeForm = ({ editData }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const region = getUserRegion();
    const reactHookFormMethods = useForm({
        mode: 'all',
        defaultValues: {
            ...editData,
            state: _.find(state, (z) => z.value === editData?.state),
            payment_method: _.find(paymentMethod, (z) => z.value === editData?.payment_method),
            print_check_detail: editData?.print_check_detail ?? true,
        },
    });

    const { handleSubmit, control, errors } = reactHookFormMethods;

    const post = (submitModel) => {
        dispatch(addAssignee(submitModel))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Added Assignee' }));
                history.replace({ pathname: '/assignees' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not add Assignee' }));
            });
    };

    const put = (submitModel) => {
        const { _id } = editData;
        dispatch(editAssignee(submitModel, _id))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Edited Assignee' }));
                history.replace({ pathname: '/assignees' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not edit Assignee' }));
            });
    };

    const onSubmit = (model) => {
        let submitModel = { ...model };
        // This function applies to the searchable select components and passes the value of the selection to the model
        Object.keys(submitModel).forEach((key) => {
            if (submitModel[key]?.value) {
                submitModel[key] = submitModel[key].value;
            }
        });

        submitModel = _.omitBy(submitModel, (field) => _.isUndefined(field) || _.isNull(field) || field === '');

        // The query has been updated to only include fields that apply to the form data, these fields are coming from editDetails somehow
        delete submitModel?._id;
        delete submitModel?.producer_id;
        delete submitModel.tableData;
        delete submitModel.index;

        if (editData) {
            put(submitModel);
        } else {
            post(submitModel);
        }
    };

    const debounceSubmit = useCallback(debounce(onSubmit, 500), []);

    const render = () => {
        return (
            <div>
                <FormProvider {...reactHookFormMethods}>
                    <form onSubmit={handleSubmit(debounceSubmit, errors)}>
                        <Grid container spacing={3}>
                            <GridRow size={6}>
                                <ReactHookFormInput label={'Assignee ID'} name={'assignee_number'} required type={'text'} fullWidth />
                                <ReactHookFormInput label={'Assignee Name'} name={'assignee_name'} required type={'text'} fullWidth />
                            </GridRow>

                            <GridRow size={6}>
                                <ReactHookFormInput label={'Classification Code'} type={'text'} name={'classification_code'} fullWidth />
                                <ReactHookFormInput label={'AP Account'} name={'account_number'} type={'number'} fullWidth />
                            </GridRow>

                            <GridRow size={[Region.LEGACY].includes(region) ? 3 : 4}>
                                <ReactHookFormInput label={'GL Number'} name={'gl_number'} type={'text'} fullWidth />
                                <ReactHookFormSearchableSelect name={'payment_method'} label={'Payment Method'} options={paymentMethod} />
                                {![Region.LEGACY].includes(region) && <SwitchWithLabel name="print_check_detail" mainLabel="Print Check Detail?" />}
                            </GridRow>

                            {![Region.LEGACY].includes(region) && (
                                <>
                                    <GridRow size={6}>
                                        <ReactHookFormInput label={'Address 1'} type={'text'} name={'address1'} fullWidth />
                                        <ReactHookFormInput label={'Address 2'} type={'text'} name={'address2'} fullWidth />
                                    </GridRow>
                                    <GridRow size={4}>
                                        <ReactHookFormInput label={'Town'} type={'text'} name={'town'} fullWidth />
                                        <ReactHookFormInput label={'ZIP'} type={'text'} name={'postal_code'} fullWidth />
                                        <ReactHookFormSearchableSelect name={'state'} label={'State'} options={state} />
                                    </GridRow>
                                    <GridRow size={6}>
                                        <Controller
                                            name="phone_number"
                                            value="phone_number"
                                            control={control}
                                            render={({ field: { onChange, name, value } }) => {
                                                return <MuiPhoneNumber defaultCountry="us" fullWidth onChange={onChange} disableCountryCode disableDropdown autoFormat={false} name={name} value={value} label="Phone Number" />;
                                            }}
                                        />
                                        <ReactHookFormInput label={'Email Address'} type={'text'} name={'email'} fullWidth />
                                    </GridRow>
                                </>
                            )}

                            <Box>
                                <Button type="submit" variant="contained" color="primary">
                                    Save
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                </FormProvider>
            </div>
        );
    };
    return render();
};

export default AssigneeForm;
