import * as Actions from '../../actions';

const initialState = {
    data: [],
};

const listEquityMemberReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_MEMBERS_LIST:
            return { ...state, data: action.payload };
        default:
            return state;
    }
};

export default listEquityMemberReducer;
