import addUser from './addUser';

const addSubProcessor = (model) =>
    addUser({
        ...model,
        role: 'processor',
        subuser: true,
    });

export default addSubProcessor;
