import axios from 'axios';
import _ from 'lodash';
import { batchQuery, getTrucksQuery, getHaulingCompaniesQuery } from './queries';

const getTruckListDetails = ({ includeDeleted, haulingId }) =>
    new Promise((resolve, reject) => {
        const query = getTrucksQuery({ ...(!includeDeleted && { filterDeleted: true }), haulingId });
        const request = axios.get(query);

        request
            .then((response) => {
                const trucks = response.data;
                const haulingIds = _.filter(_.uniq(_.map(trucks, 'hauling_company')), (id) => !_.isNull(id));

                const haulingCompanyQuery = batchQuery(
                    getHaulingCompaniesQuery,
                    {
                        fields: ['id', 'name'],
                        ids: haulingIds,
                        filterDeleted: true,
                    },
                    'ids'
                );
                const haulingCompanyRequest = _.map(haulingCompanyQuery, (q) => axios.get(q));

                axios.all(haulingCompanyRequest).then(
                    axios.spread((...haulingCompanyResponse) => {
                        const haulingCompanies = _.reduce(haulingCompanyResponse, (acc, val) => [...acc, ...val.data], []);
                        const truckResponse = _.map(trucks, (truck) => {
                            const truckHauler = truck.hauling_company ? truck.hauling_company : '';
                            const hauler = _.find(haulingCompanies, { id: truckHauler });
                            const haulingCompany = truckHauler && hauler && hauler.name ? hauler.name : '';
                            return { ...truck, hauling_company_name: haulingCompany };
                        });
                        resolve({ trucks: truckResponse });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getTruckListDetails;
