import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getPickupsQuery, getDropoffsQuery, getRouteSessionsQuery, getDriversQuery, getProducersQuery, getLabReportsQuery, getRoutesQuery, getTrailersQuery, getProcessorsQuery, batchQuery, bulkQuery } from './queries';
import { calculateComponents, getProcessorByPickupId } from './utils';
import { buildExpressQuery } from './queries/builders';
import { renameKey, arrayToMap, formatLabsForComponentCalculation } from '../../utils';
import store from '../store';

const getPickupsProcessorDetails = (start, end) =>
    new Promise((resolve, reject) => {
        const { region } = store.getState().persisted.auth.user.data;
        const pickupsQuery = getPickupsQuery({
            start,
            end,
            filterDeleted: true,
            filterType: 'milk',
            queryBuilder: buildExpressQuery,
        });
        const pickupsRequest = axios.get(pickupsQuery);
        pickupsRequest
            .then((pickupsResponse) => {
                const pickups = pickupsResponse.data;
                if (_.isEmpty(pickups)) {
                    resolve({ success: true, pickups });
                }
                renameKey(pickups, '_id', 'id');

                const routeSessionIds = _.uniq(_.map(pickups, 'route_session_id'));
                const driverIds = _.uniq(_.map(pickups, 'driver_id'));
                const producerIds = _.uniq(_.map(pickups, 'producer_id'));

                const firstPickup = _.minBy(pickups, 'created_at');
                const lastPickup = _.maxBy(pickups, 'created_at');

                const labReportStart = moment(firstPickup.created_at).startOf('day').format();
                const labReportEnd = moment(lastPickup.created_at).endOf('day').format();

                const driverQuery = bulkQuery(getDriversQuery, { ids: driverIds }, 'ids');
                const producerQuery = bulkQuery(getProducersQuery, { ids: producerIds }, 'ids');

                const labReportQuery = bulkQuery(getLabReportsQuery, {
                    fields: ['producer_id', 'date', 'fat', 'LOS', 'somatic_cell_count', 'protein', 'bulk_milk_cell_count', 'pickup_id'],
                    start: labReportStart,
                    end: labReportEnd,
                    producerIds,
                    filterDeleted: true,
                });

                const routeSessionQuery = bulkQuery(getRouteSessionsQuery, { ids: routeSessionIds, filterDeleted: true });
                const dropoffsQuery = bulkQuery(getDropoffsQuery, { routeSessionIds, filterDeleted: true });

                const driverRequest = axios.post(driverQuery.url, driverQuery.body);
                const producerRequest = axios.post(producerQuery.url, producerQuery.body);
                const labReportRequest = axios.post(labReportQuery.url, labReportQuery.body);
                const routeSessionRequest = axios.post(routeSessionQuery.url, routeSessionQuery.body);
                const dropoffsRequest = axios.post(dropoffsQuery.url, dropoffsQuery.body);

                const requests1 = [driverRequest, producerRequest, labReportRequest, routeSessionRequest, dropoffsRequest];

                axios.all(requests1).then(
                    axios.spread((...responses1) => {
                        const driverResponse = responses1[0];
                        const producerResponse = responses1[1];
                        const labsResponse = responses1[2];
                        const sessionResponse = responses1[3];
                        const dropoffResponse = responses1[4];

                        const drivers = driverResponse.data;
                        renameKey(drivers, '_id', 'id');

                        const producers = producerResponse.data;
                        renameKey(producers, '_id', 'id');

                        const labReports = labsResponse.data;
                        renameKey(labReports, '_id', 'id');

                        const routeSessions = sessionResponse.data;
                        renameKey(routeSessions, '_id', 'id');

                        const dropoffs = dropoffResponse.data;
                        renameKey(dropoffs, '_id', 'id');

                        const routeIds = _.uniq(_.map(routeSessions, 'route_id'));
                        const trailerIds = _.uniq(_.map(routeSessions, 'trailer_id'));
                        const processorIds = _.uniq(_.map(dropoffs, 'processor_id'));

                        const routeQuery = bulkQuery(getRoutesQuery, { ids: routeIds }, 'ids');
                        const routeRequest = axios.post(routeQuery.url, routeQuery.body);

                        const trailerQuery = batchQuery(getTrailersQuery, { ids: trailerIds }, 'ids');
                        const trailerRequest = _.map(trailerQuery, (q) => axios.get(q));

                        const processorQuery = batchQuery(getProcessorsQuery, { ids: processorIds }, 'ids');
                        const processorRequest = _.map(processorQuery, (q) => axios.get(q));

                        const requests2 = [routeRequest, ...trailerRequest, ...processorRequest];

                        axios.all(requests2).then(
                            axios.spread((...responses2) => {
                                const routes = responses2[0].data;
                                renameKey(routes, '_id', 'id');

                                const trailers = _.reduce(responses2.slice(routeRequest.length, routeRequest.length + trailerRequest.length), (acc, val) => [...acc, ...val.data], []);
                                const processors = _.reduce(responses2.slice(routeRequest.length + trailerRequest.length), (acc, val) => [...acc, ...val.data], []);
                                const [groupedLabReports, labsByPickupIdMap] = formatLabsForComponentCalculation(labReports, region);

                                const pickupsByRouteSessionId = _.groupBy(pickups, 'route_session_id');
                                const dropoffsByRouteSessionId = _.groupBy(dropoffs, 'route_session_id');
                                const processorByPickupId = _.reduce(
                                    _.keys(pickupsByRouteSessionId),
                                    (acc, val) => {
                                        const rsPickups = pickupsByRouteSessionId[val];
                                        const rsDropoffs = dropoffsByRouteSessionId[val] ? dropoffsByRouteSessionId[val] : [];
                                        const rsProcessorByPickupId = getProcessorByPickupId(rsPickups, rsDropoffs, processors);
                                        return { ...acc, ...rsProcessorByPickupId };
                                    },
                                    {}
                                );

                                const driversMap = arrayToMap(drivers, 'id');
                                const producersMap = arrayToMap(producers, 'id');
                                const routeSessionsMap = arrayToMap(routeSessions, 'id');
                                const routesMap = arrayToMap(routes, 'id');
                                const trailersMap = arrayToMap(trailers, 'id');

                                const pickupsData = _.map(pickups, (pickup) => {
                                    const components = calculateComponents([pickup], groupedLabReports, region, labsByPickupIdMap);
                                    const driver = driversMap[pickup.driver_id];
                                    const producer = producersMap[pickup.producer_id];
                                    const routeSession = routeSessionsMap[pickup.route_session_id];
                                    const trailer = routeSession ? trailersMap[routeSession.trailer_id] : {};
                                    const route = routeSession ? routesMap[routeSession.route_id] : {};

                                    return {
                                        bacto_barcode: pickup.bacto_barcode || '',
                                        comment: pickup.comment,
                                        compartment: pickup.compartment,
                                        component_barcode: pickup.component_barcode || '',
                                        created_at: pickup.created_at,
                                        deleted_at: pickup.deleted_at,
                                        dip_level: pickup.dip_level ? pickup.dip_level.toString() : '',
                                        driver: [driver],
                                        // dropoff_date: '-1', // Not used?
                                        fat: components.fat,
                                        hourly_production: -1, // TODO
                                        id: pickup.id,
                                        is_rejected: pickup.is_rejected,
                                        latitude: pickup.latitude,
                                        longitude: pickup.longitude,
                                        los: components.LOS,
                                        lactose: components.lactose,
                                        other_solids: region === 'CDI' ? components.solids_not_fat - components.protein : components.other_solids,
                                        outside: pickup.outside,
                                        partial: !!pickup.partial,
                                        processor: routeSession && routeSession.status === 'closed' ? (processorByPickupId[pickup.id] ? processorByPickupId[pickup.id] : '') : '',
                                        producer: [_.pick(producer, ['CMTNumber', 'geofence', 'id', 'latitude', 'longitude', 'name', 'tank'])],
                                        protein: components.protein,
                                        rejection_reason: pickup.rejection_reason,
                                        route_session: [_.assign(_.pick(routeSession, ['BOL', 'id', 'status']), { route: route ? route.name : '' })],
                                        run_number: pickup.run_number,
                                        scc: components.scc,
                                        bmcc: components.bmcc || null,
                                        slip: pickup.slip,
                                        tank_number: pickup.tank_number,
                                        temperature: pickup.temperature,
                                        trailer: trailer ? parseInt(trailer.trailer_number) : null, // TODO number or string?
                                        updated_at: pickup.updated_at,
                                        volume: !!pickup.volume ? pickup.volume : 0,
                                        volume_a: pickup.volume_a,
                                        volume_b: pickup.volume_b,
                                        volume_c: pickup.volume_c,
                                    };
                                });
                                resolve({ success: true, pickups: pickupsData });
                            })
                        );
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getPickupsProcessorDetails;
