import axios from 'axios';
import { getMerchandiseQuery } from './queries';
import { buildExpressQuery } from './queries/builders';

const getMerchandiseListDetails = async (id) => {
    try {
        const merchandiseQuery = getMerchandiseQuery({ id, fields: ['product_number', 'product_name', 'product_type', 'historic_rates'], queryBuilder: buildExpressQuery });
        const merchandiseResponse = await axios.get(merchandiseQuery);
        const merchandise = merchandiseResponse.data;
        return { merchandise };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getMerchandiseListDetails;
