import axios from 'axios';
import { createCommentQuery } from './queries';

const addComment = (model) =>
    new Promise((resolve, reject) => {
        const query = createCommentQuery({ model });
        const request = axios.post(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to add comment'));
            });
    });

export default addComment;
