import React, { useState, useCallback } from 'react';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import { GridRow } from '@mui/x-data-grid';
import { arrayToMap } from '../../../../utils';
import { Box, Typography } from '@mui/material';

const titleSx = {
    fontSize: 13,
    fontWeight: 'bold',
};

const valueSx = {
    fontSize: 14,
    width: '100%',
    wordWrap: 'break-word',
};

const boxSx = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 180,
    minHeight: 72,
    background: '#F5F5F5',
    border: '1px solid #BDBDBD',
    paddingLeft: 15,
    paddingRight: 15,
};

const rowSx = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 10,
    alignItems: 'center',
};

const useExpandableGrid = ({ columns }) => {
    const [expandedRows, setExpandedRows] = useState({});
    const [columnMap, setColumnMap] = useState(arrayToMap(columns, 'field'));
    const [columnVisibilityModel, setColumnVisibilityModel] = useState(columns.reduce((acc, column) => ({ ...acc, [column.field]: !column.showOnExpand }), {}));

    const handleVisibilityChange = useCallback((model) => {
        setColumnVisibilityModel(model);
    }, []);

    const fieldsToExpand = Object.entries(columnVisibilityModel)
        .filter(([field, show]) => !show)
        .map(([field, show]) => field);

    const toggleRowExpansion = useCallback((id) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [id]: !prevExpandedRows[id],
        }));
    }, []);

    const isRowExpanded = useCallback(
        (id) => {
            return !!expandedRows[id];
        },
        [expandedRows]
    );

    const renderExpandCell = ({ id }) => {
        const rotateDegrees = isRowExpanded(id) ? 0 : 270;
        return <ArrowDropDownCircleIcon style={{ cursor: 'pointer', fill: '#00A2F5', transform: `rotate(${rotateDegrees}deg)` }} onClick={() => toggleRowExpansion(id)} />;
    };

    const expandControlColumn = {
        field: 'expandController',
        headerName: '',
        width: 50,
        sortable: false,
        renderCell: renderExpandCell,
        disableExport: true,
    };

    const columnsWithExpand = [...(fieldsToExpand.length ? [expandControlColumn] : []), ...columns];

    const ExpandableItem = ({ row, field }) => {
        const item = columnMap[field];
        const { headerName: title, renderCell } = item;
        const value = row?.[item?.field];
        const renderedValue = renderCell ? renderCell({ value }) : value;

        return (
            <Box style={boxSx}>
                <Typography sx={titleSx}>{title}</Typography>
                <Typography sx={valueSx}>{renderedValue}</Typography>
            </Box>
        );
    };

    const ExpandableRow = (props) => {
        const isExpanded = isRowExpanded(props.rowId);

        return (
            <>
                <GridRow {...props} />
                {isExpanded && fieldsToExpand.length && (
                    <Box style={rowSx}>
                        <Box sx={{ width: 2 }} />
                        {fieldsToExpand.map((field) => (
                            <ExpandableItem row={props.row} field={field} />
                        ))}
                    </Box>
                )}
            </>
        );
    };

    return { isRowExpanded, columnsWithExpand, ExpandableRow, handleVisibilityChange, columnVisibilityModel };
};

export default useExpandableGrid;
