import Environment from './Environment';
import { createEnvironmentsDictionary } from './SplitUtils';
import './typedefs';

/**
 * Emulates the behaviour of a Split as created from the console
 */

const regions = ['CDI', 'LEGACY', 'PRAIRIE', 'MMPA', 'UDA'];
const defaultEnvironments = [new Environment({ name: 'LOCAL', regions })];

class MockSplit {
    /**
     * Create new mock Split.
     *
     * @param {MockSplitParams} params parameters for creating new mock split
     */
    // when environments is empty provide default value
    constructor({ name, environments = defaultEnvironments }) {
        this.name = name;
        this.environments = createEnvironmentsDictionary(environments);
    }

    /**
     * Evaluate Split to retrieve treatment value.
     * If evaluation fails, return default or control statement
     *
     * @param {Attributes} attributes
     * @param {string} stage
     * @returns {Treatment} Evaluated Treatment
     */
    getTreatment(attributes, stage) {
        try {
            const env = this.environments[stage];
            if (!env) return 'control';
            return env.evaluationFn(attributes);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
            throw new Error(`Could not evaluate treatment for ${this.name}: ${err}`);
        }
    }

    /**
     * Evaluate Split to retrieve static configuration based on attributes and evaluation function
     *
     * @param {Attributes} attributes
     * @returns {Treatments} static Split mock configuration as ingested by Split Manager
     */
    getStaticEvaluation(attributes, stage) {
        return {
            [this.name]: this.getTreatment(attributes, stage),
        };
    }
}

export default MockSplit;
