import { getAPIHost } from '../../../../utils';

const buildLoopbackQuery = (endpoint, { params, body }) => {
    return body
        ? {
              url: `${getAPIHost() + endpoint}`,
              body,
          }
        : params && Object.keys(params).length !== 0
        ? `${getAPIHost() + endpoint}?filter=${encodeURIComponent(JSON.stringify(params))}`
        : `${getAPIHost() + endpoint}`;
};

export default buildLoopbackQuery;
