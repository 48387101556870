import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getBaseTransfersQuery } from './queries';

const getBaseTransferDetails = async (start, end, producerId) => {
    try {
        const baseTransfersResponse = await axios.get(getBaseTransfersQuery({ producerId }));
        const unfilteredBaseTransfers = baseTransfersResponse.data;

        const baseTransfers = unfilteredBaseTransfers
            .filter((baseTransfer) => {
                if (start == null && end == null) {
                    return true;
                }
                const startDateIsValid = baseTransfer.start_date != null && end != null && moment(end).isSameOrAfter(moment(baseTransfer.start_date), 'day');
                let endDateIsValid = baseTransfer.end_date == null;
                if (start != null && baseTransfer.end_date != null) {
                    endDateIsValid = moment(start).isSameOrBefore(moment(baseTransfer.end_date), 'day');
                }
                return startDateIsValid && endDateIsValid;
            })
            .sort((a, b) => {
                return moment(a.start_date).isSame(moment(b.start_date), 'date') ? (moment(a.updated_at).isBefore(b.updated_at) ? 1 : -1) : moment(a.start_date).isBefore(moment(b.start_date), 'date') ? 1 : -1;
            });
        return { baseTransfers };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getBaseTransferDetails;
