import React, { useState, useEffect, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText, FilledInput, OutlinedInput, InputLabel, Input, TextField, InputAdornment, Select, MenuItem } from '@material-ui/core';
import Search from '@material-ui/icons/Search';
import { withFormsy } from 'formsy-react';
import _ from '@lodash';

const ESC_KEY_CODE = 27;

const useStyles = makeStyles({
    search: {
        paddingLeft: 15,
        background: 'lightgray',
    },
});

const SelectSearchFormsy = (props) => {
    const importedProps = _.pick(props, ['autoWidth', 'children', 'classes', 'displayEmpty', 'input', 'inputProps', 'MenuProps', 'multiple', 'native', 'onChange', 'onClose', 'onOpen', 'open', 'renderValue', 'SelectDisplayProps', 'value', 'variant']);

    // An error message is returned only if the component is invalid
    const errorMessage = props.errorMessage;
    const value = props.value;
    const disableSearch = props.disableSearch ? true : false;

    const classes = useStyles();

    const [filter, setFilter] = React.useState('');

    const [open, setOpen] = useState(false);
    const openRef = useRef(open);
    openRef.current = open;

    const [defaultValue, setDefaultValue] = useState(' ');
    const defaultValueRef = useRef(defaultValue);
    defaultValueRef.current = defaultValue;

    const input = () => {
        switch (importedProps.variant) {
            case 'outlined':
                return <OutlinedInput labelWidth={props.label.length * 8} id={props.name} />;
            case 'filled':
                return <FilledInput id={props.name} />;
            default:
                return <Input id={props.name} />;
        }
    };

    const search = () => (
        <Input
            className={classes.search}
            onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
            }}
            value={filter}
            fullWidth
            onChange={handleSearchChange}
            onKeyDown={(e) => e.stopPropagation()}
            endAdornment={
                <InputAdornment position="end">
                    <Search />
                </InputAdornment>
            }
        />
    );

    useEffect(() => {
        setDefaultValue(props.value);
    }, []);

    const escFunction = useCallback((event) => {
        if (event.keyCode === ESC_KEY_CODE && openRef.current) {
            setOpen(false);
            setFilter('');
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', escFunction, false);

        return () => {
            document.removeEventListener('keydown', escFunction, false);
        };
    }, [open]);

    const handleClick = () => {
        if (!props.disabled) {
            setOpen((prev) => !prev);
            setFilter('');
        }
    };

    const handleSelectChange = (event) => {
        props.setValue(event.target.value);
        if (props.onChange) {
            props.onChange(event);
        }
    };

    const handleSearchChange = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setFilter(e.target.value);
    };

    const handleClose = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setOpen(false);
    };

    // eslint-disable-next-line global-require
    const uuidv4 = require('uuid/v4');

    return (
        <FormControl error={Boolean(errorMessage)} className={props.className} variant={importedProps.variant}>
            {props.label && <InputLabel htmlFor={props.name}>{props.label}</InputLabel>}
            <Select {...importedProps} variant="standard" value={value} onChange={handleSelectChange} input={input()} open={open} disabled={props.disabled} onClick={handleClick}>
                {!disableSearch && search()}
                {props.options &&
                    props.options
                        .filter((option) => option.name.toLowerCase().includes(filter.toLowerCase()))
                        .map((option) => (
                            <MenuItem key={uuidv4()} {..._.omit(option, ['name'])} onClick={handleClose}>
                                {option.name}
                            </MenuItem>
                        ))}
            </Select>
            {Boolean(errorMessage) && <FormHelperText>{errorMessage}</FormHelperText>}
        </FormControl>
    );
};

export default React.memo(withFormsy(SelectSearchFormsy));
