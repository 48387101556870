import { FuseLoadable } from '@fuse';
import { authRoles } from 'app/auth';
import { inspectRouteSessionSlug } from '../../../../constants/slugs';

export const InspectRouteSessionConfig = {
    routes: [
        {
            exact: true,
            path: `${inspectRouteSessionSlug}/:id?`,
            component: FuseLoadable({ loader: () => import('./route-session/InspectRouteSessionApp') }),
        },
    ],
};

export const InspectPickupConfig = {
    auth: [...authRoles.admin, ...authRoles.producer, ...authRoles.hauling, ...authRoles.processor],
    routes: [
        {
            path: '/inspect-pickup/:id?',
            component: FuseLoadable({
                loader: () => {
                    return import('../../ConfiguredApps/pickups-raw-milk/inspect/InspectPickup');
                },
            }),
        },
    ],
};

export const InspectLabReportConfig = {
    auth: ['admin', 'sub-admin', 'producer', 'sub-producer'],
    routes: [
        {
            path: '/inspect-lab-report/:id?/:mode?',
            component: FuseLoadable({
                loader: () => {
                    return import('./lab-reports/RegionRouter');
                },
            }),
        },
    ],
};

export const InspectDropoffConfig = {
    auth: ['admin', 'sub-admin', 'processor', 'sub-processor', 'transport', 'sub-transport'],
    routes: [
        {
            path: '/inspect-dropoff/:id?/:mode?',
            component: FuseLoadable({ loader: () => import('../../ConfiguredApps/dropoffs-raw-milk/inspect/InspectDropoff') }),
        },
    ],
};

export const InspectWashFacilityConfig = { auth: ['admin', 'sub-admin'], routes: [{ path: '/inspect-wash-facility/:id?', component: FuseLoadable({ loader: () => import('./InspectWashFacilityApp') }) }] };

export const InspectWashConfig = { auth: [...authRoles.admin, ...authRoles.hauling, ...authRoles.processor], routes: [{ path: '/inspect-wash/:id?', component: FuseLoadable({ loader: () => import('./InspectWashApp') }) }] };

// export const InspectLabReportConfig = { auth: ['admin', 'sub-admin', 'producer', 'sub-producer'], routes: [{ path: '/inspect-lab-report/:id?', component: FuseLoadable({ loader: () => import('./InspectLabReportApp') }) }] };

export const InspectSealsConfig = { auth: ['admin', 'sub-admin', 'producer', 'sub-producer', 'transport', 'sub-transport'], routes: [{ path: '/inspect-seal/:id?', component: FuseLoadable({ loader: () => import('./InspectSealsApp') }) }] };

export const InspectDropoffSampleConfig = { auth: ['admin', 'sub-admin', 'processor', 'sub-processor'], routes: [{ path: '/inspect-dropoff-sample/:id?', component: FuseLoadable({ loader: () => import('./InspectDropoffSampleApp') }) }] };

export const InspectProcessedFluidRouteSessionConfig = { auth: [...authRoles.admin, ...authRoles.hauling, ...authRoles.processor, ...authRoles.hauling], routes: [{ path: '/inspect-processed-fluid-route-session/:id?', component: FuseLoadable({ loader: () => import('./processed-fluids-route-session/InspectProcessedFluidRouteSessionApp') }) }] };

export const InspectProcessedFluidPickupConfig = { auth: [...authRoles.admin, ...authRoles.hauling, ...authRoles.processor, ...authRoles.hauling], routes: [{ path: '/inspect-processed-fluid-pickup/:id', component: FuseLoadable({ loader: () => import('./processed-fluids-route-session/InspectProcessedFluidTransferApp') }) }] };

export const InspectProcessedFluidDropoffConfig = { auth: [...authRoles.admin, ...authRoles.hauling, ...authRoles.processor, ...authRoles.hauling], routes: [{ path: '/inspect-processed-fluid-dropoff/:id', component: FuseLoadable({ loader: () => import('./processed-fluids-route-session/InspectProcessedFluidTransferApp') }) }] };

export const InspectGeofenceDemo = { auth: [...authRoles.admin], routes: [{ path: '/geofence-management', component: FuseLoadable({ loader: () => import('./InspectGeofenceDemo') }) }] };

export const ViewEffectiveRatesConfig = { auth: authRoles.admin, routes: [{ path: '/view-effective-rates', component: FuseLoadable({ loader: () => import('./ViewEffectiveRatesApp') }) }] };

export default [
    InspectRouteSessionConfig,
    InspectPickupConfig,
    InspectDropoffConfig,
    InspectWashFacilityConfig,
    InspectWashConfig,
    InspectLabReportConfig,
    InspectSealsConfig,
    InspectDropoffSampleConfig,
    InspectProcessedFluidRouteSessionConfig,
    InspectProcessedFluidPickupConfig,
    InspectProcessedFluidDropoffConfig,
    InspectGeofenceDemo,
    ViewEffectiveRatesConfig,
];
