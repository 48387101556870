import axios from 'axios';
import { getAdminFoldersQuery, getSharedFoldersQuery } from './queries';

const getUserFolders = (id, isAdmin) =>
    new Promise((resolve, reject) => {
        const folderQuery = isAdmin ? getAdminFoldersQuery() : getSharedFoldersQuery({ ownerId: id });
        const folderRequest = axios.get(folderQuery);
        folderRequest
            .then((folderResponse) => {
                const folders = folderResponse.data;
                resolve({ folders });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getUserFolders;
