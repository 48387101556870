import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment-timezone';
import Card from '@material-ui/core/Card';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import { secondsToTimeConverter, getLabBoxComponentTextColor } from '../../utils';

function LastPickupBox({ region, last, volume, temp, date }) {
    const [secondsTimeDiff, setTime] = useState(moment().diff(moment(last), 'seconds'));
    const intervalRef = useRef();
    const isCurrentMonth = moment(date).format('YYYY-MM') === moment().format('YYYY-MM');

    function update() {
        setTime(moment().diff(moment(last), 'seconds'));
    }

    useEffect(() => {
        intervalRef.current = setInterval(update, 1000);
        return () => {
            clearInterval(intervalRef.current);
        };
    });

    return (
        <Card className="w-1/2 h-1/2 shadow-none rounded-8 border-1" color="inherit">
            <AppBar position="static" className="rounded-8 h-full">
                <div className="flex items-center justify-between px-16 py-16">
                    <Typography className="text-20 sm:h3 font-bold whitespace-no-wrap">Last Pickup</Typography>
                </div>
                <div className="flex flex-row items-center justify-between">
                    <Typography className="h3 px-16 text-center items-center" color="inherit">
                        {moment(last).format('MMM Do, YYYY')}
                    </Typography>
                </div>
                {isCurrentMonth && (
                    <div className="text-center px-24 pt-24">
                        <Typography className="h2 sm:h3 leading-tight whitespace-no-wrap" color="inherit">
                            Since Last
                        </Typography>
                        <Typography className="h2 sm:h3 leading-tight text-bold" color="inherit">
                            {secondsToTimeConverter(secondsTimeDiff * 1000)}
                        </Typography>
                    </div>
                )}
                <div className="text-center px-24 py-24">
                    <Typography className="h2 sm:h3 leading-tight" color="inherit">
                        Amount
                    </Typography>
                    <Typography className="h2 sm:h3 leading-tight" color="inherit">
                        {volume.toLocaleString('us', { minimumFractionDigits: ['MMPA'].includes(region) ? 0 : 2, maximumFractionDigits: ['MMPA'].includes(region) ? 0 : 2 })}
                    </Typography>
                </div>
                {(region === 'RF' || region === 'UDA') && (
                    <div className="text-center px-24 pt-20 sm:py-8">
                        <Typography className="h2 sm:h3 leading-tight" color="inherit">
                            {region === 'UDA' ? 'Temperature' : 'TEMP(°C)'}
                        </Typography>
                        <Typography className={`h2 sm:h3 leading-tight" ${getLabBoxComponentTextColor('temp', temp, region)}`}>{temp ? temp.toLocaleString('us', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : '-'}</Typography>
                    </div>
                )}
            </AppBar>
        </Card>
    );
}

export default React.memo(LastPickupBox);
