import axios from 'axios';
import Split from '../../../../Split';
import { resolveId } from '../../../../utils';
import { createDropoffQuery } from '../../queries';
import { buildExpressQuery } from '../../queries/builders';
import parseError from '../../errors/utils';

const addDropoff = async (model) => {
    const useExpressEndpoint = Split.getBooleanTreatment('use_express_dropoff_endpoint');

    const query = createDropoffQuery({ model, ...(useExpressEndpoint && { queryBuilder: buildExpressQuery }) });

    try {
        const { data } = await axios.post(query.url, query.body);
        return { data: resolveId(data) };
    } catch (error) {
        return { error: parseError({ error }) };
    }
};

export default addDropoff;
