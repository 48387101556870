import axios from 'axios';
import { createScheduleSupplyItemQuery } from './queries';
import { buildExpressQuery } from './queries/builders';
import { resolveId } from '../../utils';

const addScheduleSupplyItems = async (model) => {
    try {
        const query = createScheduleSupplyItemQuery({ model, queryBuilder: buildExpressQuery });
        const request = axios.post(query.url, { ...query.body });
        const { data } = await request;
        return { items: data.map((item) => resolveId(item)) };
    } catch {
        throw new Error('Unable to add schedule item');
    }
};

export default addScheduleSupplyItems;
