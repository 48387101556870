import axios from 'axios';
import { getMilkPricesInDateRangeQuery } from './queries';

const getMilkPricesInDateRange = (startDate, endDate) =>
    new Promise((resolve, reject) => {
        const query = getMilkPricesInDateRangeQuery({
            startDate,
            endDate,
        });
        const request = axios.get(query);

        request
            .then((response) => {
                resolve(response.data);
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to get milk prices'));
            });
    });

export default getMilkPricesInDateRange;
