import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';
import Region from '../../../app/constants/region';
import HaulingContractType from '../../../app/constants/hauling';
/**
 * Define all of your environment configs here
 */

const regionConfigs = [
    {
        region: Region.MMPA,
        hauling_contract_types: [HaulingContractType.ProducerHaulerContract, HaulingContractType.HaulerProcessorRouteContract],
        evaluationFunction: (attributes) => {
            return attributes.role !== 'producer' && attributes.role !== 'sub-producer';
        },
    },
    {
        region: Region.TCJ.ERIE,
        hauling_contract_types: [HaulingContractType.HaulerProcessorRouteContract],
    },
    {
        region: Region.TCJ.LIBERTY,
        hauling_contract_types: [HaulingContractType.HaulerProcessorRouteContract],
    },
    {
        region: Region.TCJ.WHITEEAGLE,
        hauling_contract_types: [HaulingContractType.HaulerProcessorRouteContract],
    },
    {
        region: Region.TCJ.GPDA,
        hauling_contract_types: [HaulingContractType.HaulerProcessorRouteContract],
    },
    {
        region: Region.TCJ.NEBRASKA,
        hauling_contract_types: [HaulingContractType.HaulerProcessorRouteContract],
    },
    {
        region: Region.TCJ.KYTN,
        hauling_contract_types: [HaulingContractType.HaulerProcessorRouteContract],
    },
    {
        region: Region.PRAIRIE,
        hauling_contract_types: [HaulingContractType.HaulerProcessorRouteContract],
    },
    {
        region: Region.UDA,
        hauling_contract_types: [HaulingContractType.HaulerProcessorRouteContract],
    },
    {
        region: Region.UNC,
        hauling_contract_types: [HaulingContractType.HaulerProcessorRouteContract],
    },
    {
        region: Region.CACIQUE,
        hauling_contract_types: [HaulingContractType.HaulerProcessorRouteContract],
    },
];

export const getRegion = (attributes) => {
    const region = regionConfigs.find((config) => config.region === attributes.region);
    return region;
};

const evaluationFn = (attributes) => {
    const region = getRegion(attributes);
    if (region && (region.evaluationFunction == null || region.evaluationFunction(attributes))) {
        return region.hauling_contract_types;
    }

    return [HaulingContractType.HaulerProcessorRouteContract];
};

const demoEvaluationFn = () => {
    return [HaulingContractType.ProducerHaulerContract, HaulingContractType.HaulerProcessorRouteContract];
};

const local = new Environment({
    name: stages.LOCAL,
    evaluationFn,
});

const dev = new Environment({
    name: stages.DEV,
    evaluationFn,
});

const stg = new Environment({
    name: stages.STAGING,
    evaluationFn,
});

const prod = new Environment({
    name: stages.PRODUCTION,
    evaluationFn,
});

const demo = new Environment({
    name: stages.DEMO,
    evaluationFn: demoEvaluationFn,
});
/**
 * Create the new MockSplit, be sure to create the env dictionary with util
 */
const hauling_permissions = new MockSplit({
    name: 'hauling_permissions',
    environments: [local, dev, stg, demo, prod],
});

export default hauling_permissions;
