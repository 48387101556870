import React from 'react';
import { ResetPassword } from '@milkmoovement/common_cognito_package';
import { useHistory } from 'react-router-dom';

const ResetPasswordPage = () => {
    const history = useHistory();
    const handleNavigateBack = () => history.push({ pathname: '/login' });
    return <ResetPassword onNavigateBack={handleNavigateBack} isUrlHash />;
};

export default ResetPasswordPage;
