/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, FormControl, InputLabel, MenuItem, TextField, Select, FormHelperText, Grid, Box, Checkbox, FormControlLabel, debounce } from '@material-ui/core';
import { showMessage, addAssignment, editAssignment, addRecurringAssignment, editRecurringAssignment } from 'app/store/actions';
import SearchableSelectAll from '../../custom-widgets/SearchableSelectAll';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import { generateTestId, getUserRegion } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .MuiAutocomplete-root .MuiFormControl-root': {
            marginTop: '0',
            marginBottom: '0',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    map: {
        width: '100%',
        height: '400px',
        justifyContent: 'center',
        margin: '2%',
    },
    timeField: {
        marginTop: '15px',
        marginBottom: '10px',
    },
    list: {
        maxHeight: '280px',
        overflowY: 'scroll',
    },
}));

const CDIAssignmentForm = ({ producers, editData, assignees }) => {
    const dispatch = useDispatch();
    const region = getUserRegion();
    const history = useHistory();
    const classes = useStyles();
    const [selectedAssignee, setSelectedAssignee] = useState(null);
    const [selectedProducer, setSelectedProducer] = useState(null);
    const [basis, setBasis] = useState('');
    const [paymentRate, setPaymentRate] = useState('');
    const [monetaryValue, setMonetaryValue] = useState('');
    const [paymentType, setPaymentType] = useState('');
    const [date, setDate] = useState(null);
    const [description, setDescription] = useState('');
    const [recurringStartDate, setRecurringStartDate] = useState(null);
    const [recurringEndDate, setRecurringEndDate] = useState(null);
    const [recurringAssignment, setRecurringAssignment] = useState(false);
    const [updateRecurringAssignment, setUpdateRecurringAssignment] = useState(false);

    useEffect(() => {
        if (editData) {
            const editedProducer = producers.find((producer) => producer.id === editData.producer_id);
            const editedAssignee = assignees.find((assignee) => assignee._id === editData.assignee_id);
            setBasis(editData.type_of_charge);
            setPaymentRate(editData.payment_rate);
            setMonetaryValue(editData.monetary_value);
            setPaymentType(editData.payment_type);
            setDate(editData.date);
            setSelectedProducer(editedProducer);
            setSelectedAssignee(editedAssignee);
            setDescription(editData.description);
            setRecurringStartDate(editData.recurring_start_date);
            setRecurringEndDate(editData.recurring_end_date);
            if (!!editData.recurring_start_date && !!editData.recurring_end_date) {
                setRecurringAssignment(true);
            }
        }
    }, [editData]);

    const handleAssigneeChange = (event, value) => {
        const newAssignee = value;
        if (newAssignee) {
            setSelectedAssignee(newAssignee);
            if (newAssignee.type_of_charge) {
                setBasis(newAssignee.type_of_charge);
                if (['cwt', 'lbs', 'gross_price'].includes(newAssignee.type_of_charge)) {
                    setMonetaryValue('');
                    if (newAssignee.rate_amount) {
                        setPaymentRate(newAssignee.rate_amount);
                    }
                } else {
                    setPaymentRate('');
                    if (newAssignee.flat_amount) {
                        setMonetaryValue(newAssignee.flat_amount);
                    }
                }
            }
            if (newAssignee.payment_type) setPaymentType(newAssignee.payment_type);
            if (newAssignee.producer && producers && producers.length) {
                const assigneeProducer = producers.find((producer) => producer.id === newAssignee.producer._id);
                setSelectedProducer(assigneeProducer);
            }
        }
    };

    const handleProducerChange = (event, value) => {
        setSelectedProducer(value);
    };

    const handleBasisChange = (event) => {
        setBasis(event.target.value);
        const basisValue = event.target.value;
        if (['variable', 'fixed', 'per_advance_day'].includes(basisValue)) {
            setPaymentRate('');
        } else if (['cwt', 'lbs', 'gross_price'].includes(basisValue)) {
            setMonetaryValue('');
        }
    };

    const handlePaymentChange = (event) => {
        setPaymentRate(event.target.value);
    };

    const handlePaymentTypeChange = (event) => {
        setPaymentType(event.target.value);
    };

    const handleValueChange = (event) => {
        setMonetaryValue(event.target.value);
    };

    const handleDateChange = (newDate) => {
        setDate(newDate);
    };

    const handleRecurringStartDateChange = (newDate) => {
        setRecurringStartDate(newDate);
    };

    const handleRecurringEndDateChange = (newDate) => {
        setRecurringEndDate(newDate);
    };

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
    };

    const handleRecurringAssignmentChange = (event) => {
        setRecurringAssignment(event.target.checked);
        if (event.target.checked) {
            setDate(null);
        } else {
            setRecurringStartDate(null);
            setRecurringEndDate(null);
        }
    };

    const handleUpdateRecurringAssignmentChange = (event) => {
        setUpdateRecurringAssignment(event.target.checked);
    };

    const transformProducerLabel = (option) => {
        return `${option.license_number} - ${option.name}`;
    };

    const transformAssigneeLabel = (option) => {
        return `${option.assignee_number} - ${option.assignee_name}`;
    };

    const getModel = () => ({
        assignee: selectedAssignee._id || undefined,
        date: date ? moment(date).format('YYYY-MM') : undefined,
        producer: selectedProducer.id || undefined,
        type_of_charge: basis || undefined,
        payment_rate: paymentRate || (editData?.payment_rate ? null : undefined),
        monetary_value: monetaryValue || (editData?.monetary_value ? null : undefined),
        payment_type: paymentType || undefined,
        description: description || (editData?.description ? null : undefined),
        recurring_start_date: recurringStartDate ? moment(recurringStartDate).startOf('month').toDate() : undefined,
        recurring_end_date: recurringEndDate ? moment(recurringEndDate).endOf('month').toDate() : undefined,
        recurring_assignment_id: editData?.recurring_assignment_id || undefined,
    });
    const post = () => {
        const model = getModel();
        dispatch(recurringAssignment ? addRecurringAssignment(model) : addAssignment(model))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Added Assignment' }));
                history.replace({ pathname: '/assignments' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not add Assignment' }));
            });
    };

    const put = () => {
        const { _id } = editData;
        const model = getModel();

        dispatch(updateRecurringAssignment ? editRecurringAssignment(model, _id) : editAssignment(model, _id))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Edited Assignment' }));
                history.replace({ pathname: '/assignments' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not edit Assignment' }));
            });
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <SearchableSelectAll
                        fullWidth
                        disableClearable
                        required
                        label="Assignee"
                        value={selectedAssignee}
                        options={assignees.filter((assignee) => assignee.assignee_number !== '20').sort((a, b) => Number(a.assignee_number) - Number(b.assignee_number))}
                        onChange={handleAssigneeChange}
                        customRender={transformAssigneeLabel}
                    ></SearchableSelectAll>
                </Grid>
                <Grid item xs={6}>
                    <SearchableSelectAll fullWidth disableClearable required label="Producer" value={selectedProducer} options={producers.sort((a, b) => Number(a.license_number) - Number(b.license_number))} onChange={handleProducerChange} customRender={transformProducerLabel}></SearchableSelectAll>
                </Grid>
                <Grid item xs={6}>
                    <TextField fullWidth value={description} label="Description" onChange={handleDescriptionChange}></TextField>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="required">Payment Type</InputLabel>
                        <Select id="payment_type" value={paymentType} label="Payment Type *" onChange={handlePaymentTypeChange}>
                            <MenuItem value={'settlement'}>Settlement</MenuItem>
                            <MenuItem value={'advance'}>Advance</MenuItem>
                            {!editData && recurringAssignment && <MenuItem value={'advance_and_settlement'}>Both</MenuItem>}
                        </Select>
                        <FormHelperText>Required</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    {['CDI'].includes(region) && (
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="required">Basis</InputLabel>
                            <Select id="type_of_charge" value={basis} label="Basis *" onChange={handleBasisChange}>
                                <MenuItem value={'fixed'}>Fixed</MenuItem>
                                <MenuItem value={'variable'}>Variable</MenuItem>
                                <MenuItem value={'cwt'}>CWT</MenuItem>
                                <MenuItem value={'lbs'}>Lbs</MenuItem>
                                <MenuItem value={'gross_price'}>Gross Price at Test</MenuItem>
                            </Select>
                            <FormHelperText>Required</FormHelperText>
                        </FormControl>
                    )}
                    {['LEGACY'].includes(region) && (
                        <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
                            <InputLabel id="required">Basis</InputLabel>
                            <Select id="type_of_charge" value={basis} label="Basis *" onChange={handleBasisChange}>
                                <MenuItem value={'fixed'}>Fixed</MenuItem>
                                <MenuItem value={'cwt'}>CWT</MenuItem>
                                <MenuItem value={'lbs'}>Lbs</MenuItem>
                                <MenuItem value={'per_advance_day'}>Weighted Per Advance Day</MenuItem>
                            </Select>
                            <FormHelperText>Required</FormHelperText>
                        </FormControl>
                    )}
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth value={paymentRate} disabled={['fixed', 'variable', 'per_advance_day'].includes(basis)} label="Payment Rate" onChange={handlePaymentChange}></TextField>
                </Grid>
                <Grid item xs={4}>
                    <TextField fullWidth value={monetaryValue} disabled={['gross_price', 'cwt', 'lbs'].includes(basis)} label="Monetary Value" onChange={handleValueChange}></TextField>
                </Grid>
                {(!recurringAssignment || !!editData) && (
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker data-testId={generateTestId('Assignment Date', 'date-picker')} views={['year', 'month']} openTo="year" label="Assignment Date *" value={date} disableToolbar onChange={handleDateChange} renderInput={(params) => <TextField {...params} helperText={null} />} fullWidth />
                        </MuiPickersUtilsProvider>
                        <FormHelperText>Required</FormHelperText>
                    </Grid>
                )}
                {recurringAssignment && !editData && (
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker data-testid={generateTestId('Start Date', 'date-picker')} views={['year', 'month']} openTo="year" label="Start Date *" value={recurringStartDate} disableToolbar onChange={handleRecurringStartDateChange} renderInput={(params) => <TextField {...params} helperText={null} />} fullWidth />
                        </MuiPickersUtilsProvider>
                        <FormHelperText>Required</FormHelperText>
                    </Grid>
                )}
                {!editData && (
                    <Grid item xs={6}>
                        <FormControlLabel control={<Checkbox checked={recurringAssignment} onChange={handleRecurringAssignmentChange} inputProps={{ 'aria-label': 'primary checkbox' }} />} label="Recurring Assignment?" />
                    </Grid>
                )}
                {editData && recurringAssignment && (
                    <Grid item xs={6}>
                        <FormControlLabel control={<Checkbox checked={updateRecurringAssignment} onChange={handleUpdateRecurringAssignmentChange} inputProps={{ 'aria-label': 'primary checkbox' }} />} label="Edit future recurring assignments?" />
                    </Grid>
                )}
                {recurringAssignment && !editData && (
                    <Grid item xs={6}>
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                            <DatePicker
                                data-testid={generateTestId('End Date', 'date-picker')}
                                views={['year', 'month']}
                                openTo="year"
                                label="End Date *"
                                value={recurringEndDate}
                                maxDate={moment().add(5, 'years').endOf('month')}
                                disableToolbar
                                onChange={handleRecurringEndDateChange}
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                fullWidth
                            />
                        </MuiPickersUtilsProvider>
                        <FormHelperText>Required</FormHelperText>
                    </Grid>
                )}
            </Grid>
            <Box display="flex" justifyContent="center" gridGap="30px" paddingTop={'15px'}>
                <Button variant="contained" color="primary" margin="normal" className="mx-auto my-16" sx={{ paddingTop: 2 }} onClick={debounce(editData ? put : post, 500)}>
                    Save
                </Button>
            </Box>
        </div>
    );
};

export default withRouter(CDIAssignmentForm);
