import _ from 'lodash';
import { getPickupsQuery, getDropoffsQuery, getRouteSessionsQuery, getProcessorsQuery, getProducersQuery } from './queries';
import { arrayToMap, renameKey } from '../../utils';
import { getBulkQueryData } from './utils';
import axios from 'axios';

const getPoolingSessionDetails = async (start, end, filterType) => {
    try {
        const dropoffs = (
            await axios.get(
                getDropoffsQuery({
                    start,
                    end,
                    fields: ['volume', 'metered', 'route_session_id', 'processor_id', 'created_at'],
                    filterDeleted: true,
                    filterType,
                })
            )
        ).data;
        renameKey(dropoffs, '_id', 'id');

        const routeSessionIds = _.uniq(_.map(dropoffs, 'route_session_id'));
        const processorIds = _.uniq(_.map(dropoffs, 'processor_id'));

        const routeSessionsRequest = getBulkQueryData(getRouteSessionsQuery, { filterDeleted: true, fields: ['id', 'BOL', 'manifest_number', 'created_at'], ids: routeSessionIds }, 'ids');

        const pickupsRequest = getBulkQueryData(getPickupsQuery, { routeSessionIds, filterDeleted: true, fields: ['id', 'producer_id', 'tank_number', 'volume', 'route_session_id', 'pooled_load', 'latitude', 'longitude', 'created_at'] }, 'routeSessionIds');
        const processorsRequest = getBulkQueryData(getProcessorsQuery, { fields: ['id', 'name', 'latitude', 'longitude', 'geofence', 'license_number'], ids: processorIds }, 'ids');

        const [routeSessions, pickups, processors] = await Promise.all([routeSessionsRequest, pickupsRequest, processorsRequest]);
        const producerIds = _.uniq(_.map(pickups, 'producer_id'));
        const producersRequest = getBulkQueryData(getProducersQuery, { fields: ['id', 'name', 'license_number'], ids: producerIds }, 'ids');
        const [producers] = await Promise.all([producersRequest]);

        const routeSessionMap = arrayToMap(routeSessions, 'id');
        const dropoffMap = arrayToMap(dropoffs, 'route_session_id');
        const processorsMap = arrayToMap(processors, 'id');
        const producersMap = arrayToMap(producers, 'id');

        const data = pickups.map((pickup) => {
            const routeSession = routeSessionMap[pickup.route_session_id];
            const dropoff = dropoffMap[pickup.route_session_id];
            const processor = processorsMap[dropoff.processor_id];
            const producer = producersMap[pickup.producer_id];
            return {
                BOL: routeSession?.BOL || '',
                manifest_number: routeSession?.manifest_number || '',
                date: dropoff?.created_at,
                pickup_date: pickup?.created_at,
                pickup_id: pickup?.id,
                id: pickup?.route_session_id,
                producer_id: pickup?.producer_id,
                producer_number: producer?.license_number || '',
                producer_name: producer?.name || '',
                processor_number: processor?.license_number || 'No Drop',
                processor_name: processor?.name || 'No Drop',
                dairy_weight: pickup?.volume || 0,
                tank_number: pickup?.tank_number || '',
                pooled_load: !!pickup?.pooled_load ? 'P' : 'U',
                latitude: pickup?.latitude || '',
                longitude: pickup?.longitude || '',
            };
        });
        return { pooledSessions: data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request');
    }
};

export default getPoolingSessionDetails;
