import axios from 'axios';
import { deleteScheduleSupplyItemQuery } from './queries';

const deleteScheduleSupplyItem = async (key, recur) => {
    try {
        const query = deleteScheduleSupplyItemQuery({ key, recur });
        const request = axios.delete(query);
        const { data } = await request;
        return data;
    } catch {
        throw new Error('Unable to delete schedule item');
    }
};

export default deleteScheduleSupplyItem;
