const localization = {
    general: {
        component_barcode: 'Component Barcode Value',
        daily_fluid_milk_quota: 'Quota',
        hauling_plural: 'Transport Companies',
        hauling_singular: 'Transport Company',
        hauling_delivery: 'Delivery Transport Company',
        license_number: 'Factory Number',
        full_name: 'Contact Name',
        metered: 'Metered',
        meteredLevel: 'Metered Level',
        monthlyReports: 'Payment Reports',
        processor_singular: 'Processor',
        processor_plural: 'Processors',
        producer_weight: 'Producer Weight',
        routeNumber: 'Load',
        routeSession: 'Load',
        tank: 'Vat',
        trailer: 'Truck',
        volume: 'Volume',
        inhibitor: 'Inhibitory Substances',
    },
    navigation: {
        'Hauling Companies': 'Transport Companies',
        Trailers: 'Trucks',
        'Create Hauling Company': 'Create Transport Company',
        'Create Sub Hauler': 'Create Sub Transporter',
        'Monthly Reports': 'Payment Reports',
        'Route Sessions': 'Loads',
        'Cream Route Sessions': 'Cream Loads',
    },
    lab: {
        scc: 'bmcc',
        temperature: 'Temperature',
        sample_date: 'Date',
    },
    other: {},
};

export default localization;
