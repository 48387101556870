import { AbilityBuilder, PureAbility } from '@casl/ability';
import { getId } from '../../../../../utils';

function RouteSessionPermissions(user) {
    const { can, cannot, rules } = new AbilityBuilder(PureAbility);
    const {
        role,
        data: { region, hauling_id },
    } = user;
    const id = getId();

    if (role === 'processor') {
        if (['PRAIRIE'].includes(region)) {
            can('mutate', 'route_session');
        }
    }

    if (role === 'admin' || role === 'transport') {
        can('mutate', 'route_session');
    }

    if (['admin', 'transport', 'processor'].includes(role)) {
        can('view', 'route_session');
    }

    if (['admin', 'processor'].includes(role)) {
        can('view', 'route_session_button');
    }

    if (role === 'transport') {
        can('view', 'route_session_button', { hauling_id: id });
    }

    if (['admin', 'processor'].includes(role)) {
        can('generate', 'delivery_report');
    }

    return rules;
}

export default RouteSessionPermissions;
