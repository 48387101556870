import axios from 'axios';
import _ from 'lodash';
import { batchQuery, getMonthlyReportsQuery, getProducersQuery } from './queries';

const getMonthlyReportDetails = (id) =>
    new Promise((resolve, reject) => {
        const monthlyReportQuery = id ? getMonthlyReportsQuery({ producerId: id, filterDeleted: true }) : getMonthlyReportsQuery({ filterDeleted: true });
        const monthlyReportRequest = axios.get(monthlyReportQuery);

        monthlyReportRequest
            .then((monthlyReportResponse) => {
                const monthlyReports = monthlyReportResponse.data;
                const producerIds = _.uniq(_.map(monthlyReports, 'producer_id'));

                const producerQuery = batchQuery(getProducersQuery, { fields: ['id', 'name'], ids: producerIds }, 'ids');
                const producerRequest = _.map(producerQuery, (q) => axios.get(q));

                axios.all(producerRequest).then(
                    axios.spread((...producerResponse) => {
                        const producers = _.reduce(producerResponse, (acc, val) => [...acc, ...val.data], []);
                        const response = _.map(monthlyReports, (report) => ({
                            attachment: report.attachment,
                            created_at: report.created_at,
                            id: report.id,
                            producer: [{ id: report.producer_id, name: report.producer_id ? (_.find(producers, { id: report.producer_id }) ? _.find(producers, { id: report.producer_id }).name : '') : 'All Producers' }],
                            type: report.type,
                            read_status: report.read_status,
                            updated_at: report.updated_at,
                            is_adjusted: report.is_adjusted,
                            report_date: report.report_date,
                        }));
                        resolve({ monthlyReports: response });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getMonthlyReportDetails;
