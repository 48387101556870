import { useState, useEffect, useCallback } from 'react';

/**
 * @template {any[]} Args
 * @typedef { { (...Args): void; cancel():void; replace(): CancelableCallback<Args>; } } CancelableCallback<Args>
 */

/**
 * @template {any[]} Args
 * @param {(...args: Args) => void} callback
 * @returns {CancelableCallback<Args>}
 */
const cancelableCallback = (callback) => {
    let canceled = false;
    const wrappedCallback = (...args) => {
        if (!canceled) {
            callback(...args);
        }
    };

    wrappedCallback.cancel = () => {
        canceled = true;
    };

    wrappedCallback.replace = () => {
        wrappedCallback.cancel();

        return cancelableCallback(callback);
    };

    return wrappedCallback;
};

/**
 * @template T
 * @typedef { { status: 'pending' } | { status: 'resolved', data: T } | { status: 'rejected', error: any } } AsyncResult<T>
 */

/**
 * @template T
 * @param {() => Promise<T>} asyncFunc - Async function to be executed
 * @param {any[]} dependencies - Dependencies for the async function in the same format as useEffect
 * @returns {[AsyncResult<T>, () => void]}
 *
 * @example
 * const [todo, refreshTodo] = useAsync(() => fetchTodo(todo_id), [todo_id]);
 */
export default function useAsync(asyncFunc, dependencies) {
    const [result, setResult] = useState({ status: 'pending' });
    const [refreshToken, setRefreshToken] = useState(false);
    const [onResolve, setOnResolve] = useState(() => cancelableCallback((data) => setResult({ status: 'resolved', data })));
    const [onReject, setOnReject] = useState(() => cancelableCallback((error) => setResult({ status: 'rejected', error })));
    const refresh = useCallback(() => setRefreshToken((token) => !token));

    useEffect(() => {
        setResult({ status: 'pending' });
        const newOnResolve = onResolve.replace();
        const newOnReject = onReject.replace();
        asyncFunc().then(newOnResolve, newOnReject);
        setOnResolve(() => newOnResolve);
        setOnReject(() => newOnReject);

        return () => {
            newOnResolve.cancel();
            newOnReject.cancel();
        };
    }, [refreshToken, ...dependencies]);

    return [result, refresh];
}
