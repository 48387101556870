import * as Actions from '../../actions';

const initialState = { data: null };

const viewPickupsProcessorReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_PICKUPS_PROCESSOR_DETAILS:
            return { ...state, data: { ...action.payload } };
        default:
            return state;
    }
};

export default viewPickupsProcessorReducer;
