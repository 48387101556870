import axios from 'axios';
import { getLTNumberQuery } from './queries';

const getLTNumber = async (date) => {
    try {
        const query = getLTNumberQuery({ date });
        const request = axios.get(query);
        const { data } = await request;
        return { ltNumber: String(data.lt_number) };
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        throw new Error('Unable to add schedule item');
    }
};

export default getLTNumber;
