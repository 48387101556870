import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

export const Index = {
    auth: authRoles.admin,
    routes: [
        {
            path: '/assignees',
            component: FuseLoadable({
                loader: () => import('./AssigneeApp'),
            }),
        },
    ],
};

export default [Index];
