import React from 'react';
import { Grid } from '@material-ui/core';

export function GridRow({ size, fullOnMobile, children }) {
    if (size < 0 || size > 12) return <></>;
    if (!children) return <></>;

    if (!Array.isArray(children))
        return (
            <Grid xs={size} item>
                {children}
            </Grid>
        );

    return (
        <>
            {children?.map((child, i) => {
                // Giving this a key only to disable the console error.
                if (!child) return null;
                return (
                    // eslint-disable-next-line react/no-array-index-key
                    <Grid key={`grid-row-${i}`} xs={fullOnMobile ? 12 : size} sm={size} item>
                        {child}
                    </Grid>
                );
            })}
        </>
    );
}

export default GridRow;
