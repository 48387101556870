import axios from 'axios';
import { addMiscSampleQuery, editMiscSampleQuery, getMiscSampleQuery, listMiscSamplesQuery } from './queries';
import _ from 'lodash';

export const addMiscSample = async (model) => {
    const query = addMiscSampleQuery(model);
    const request = axios.post(query.url, { ...query.body });
    const { data } = await request;
    return data;
};

export const editMiscSample = async (id, model) => {
    const batchBody = _.omit(model, ['created_at', 'updated_at', 'deleted_at', '__v', '_id', '__t']);
    const query = editMiscSampleQuery(id, batchBody);
    const request = axios.patch(query.url, { ...query.body });
    const { data } = await request;
    return data;
};

export const getMiscSample = async (id) => {
    const query = getMiscSampleQuery(id);
    const request = axios.get(query);
    const { data } = await request;
    return data[0];
};

export const listMiscSamples = async ({ startDate, endDate, producerId }) => {
    const query = listMiscSamplesQuery({ startDate, endDate, producerId });
    const request = axios.get(query);
    const { data } = await request;
    return data;
};
