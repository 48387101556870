import React from 'react';
import { useSelector } from 'react-redux';
import getLocalization from './config';

const LocalizationContext = React.createContext({});

export const LocalizationProvider = ({ children }) => {
    // Can't use getUserRegion() here as this occurs before login
    const region = useSelector(({ persisted }) => persisted?.auth?.user?.data?.region) || 'NL';

    const localization = getLocalization(region);

    return <LocalizationContext.Provider value={localization}>{children}</LocalizationContext.Provider>;
};

export const LocalizationConsumer = LocalizationContext.Consumer;

export default LocalizationContext;
