import React from 'react';
import { Dialog, DialogContent, DialogTitle, Typography, Box, Button } from '@mui/material';

const sx = {
    root: {
        p: (theme) => theme.spacing(2),
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    submitButton: {
        ml: { md: 2 },
        my: { xs: 2, sm: 2, md: 0 },
    },
    buttonDiv: {
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'center',
        alignItems: 'center',
        my: (theme) => theme.spacing(1),
    },
};

const DeleteModal = ({ open, rowData, localization, handleClose, handleDelete }) => {
    return (
        <Dialog open={open}>
            <DialogContent sx={sx.root}>
                <DialogTitle sx={sx.title}>
                    <Typography variant="h6">Delete In-Progress Dropoff?</Typography>
                    <Typography>
                        {localization.general.lt_number ?? 'LT Number'}: {rowData?.lt_number}
                    </Typography>
                </DialogTitle>
                <Box sx={sx.buttonDiv}>
                    <Button variant="contained" color="secondary" onClick={handleClose}>
                        No
                    </Button>
                    <Button variant="contained" onClick={handleDelete} sx={sx.submitButton}>
                        Yes
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default DeleteModal;
