import store from 'app/store';
import { getId } from '../../../../utils';
import * as repository from '../../../repository';

export const GET_USER_LIST_DETAILS = '[ADMIN] GET_USER_LIST_DETAILS';
export const GET_ADMIN_LIST_DETAILS = '[ADMIN] GET_ADMIN_LIST_DETAILS';
export const GET_DRIVER_LIST_DETAILS = '[ADMIN] GET_DRIVER_LIST_DETAILS';
export const GET_RECEIVER_LIST_DETAILS = '[ADMIN] GET_RECEIVER_LIST_DETAILS';
export const GET_HAULING_LIST_DETAILS = '[ADMIN] GET_HAULING_LIST_DETAILS';
export const GET_PROCESSOR_LIST_DETAILS = '[ADMIN] GET_PROCESSOR_LIST_DETAILS';
export const GET_PRODUCER_LIST_DETAILS = '[ADMIN] GET_PRODUCER_LIST_DETAILS';
export const GET_PRODUCER_BUSINESS_LIST_DETAILS = '[ADMIN] GET_PRODUCER_BUSINESS_LIST_DETAILS';
export const GET_ROUTES_LIST_DETAILS = '[ADMIN] GET_ROUTES_LIST_DETAILS';
export const GET_TRAILER_LIST_DETAILS = '[ADMIN] GET_TRAILER_LIST_DETAILS';
export const GET_TRUCK_LIST_DETAILS = '[ADMIN] GET_TRUCK_LIST_DETAILS';
export const GET_SILO_LIST_DETAILS = '[ADMIN] GET_SILO_LIST_DETAILS';
export const GET_MONTHLY_PAY_REPORTS = '[ADMIN] GET_MONTHLY_PAY_REPORTS';
export const GET_HAULER_MONTHLY_PAY_REPORTS = '[ADMIN] GET_HAULER_MONTHLY_PAY_REPORTS';
export const GET_VENDOR_MONTHLY_PAY_REPORTS = '[ADMIN] GET_VENDOR_MONTHLY_PAY_REPORTS';
export const GET_HAULER_MONTHLY_PAY_REPORTS_FOR_HAULER = 'GET_HAULER_MONTHLY_PAY_REPORTS_FOR_HAULER';
export const GET_DEFERRALS_LIST_DETAILS = 'GET_DEFERRALS_LIST_DETAILS';
export const GET_ASSIGNEE_LIST_DETAILS = 'GET_ASSIGNEE_LIST_DETAILS';
export const GET_ASSIGNMENT_LIST_DETAILS = 'GET_ASSIGNMENT_LIST_DETAILS';
export const GET_ASSIGNMENT_CONFIGURATION_LIST_DETAILS = 'GET_ASSIGNMENT_CONFIGURATION_LIST_DETAILS';
export const GET_PRODUCER_MONTHLY_PAY_REPORTS = 'GET_PRODUCER_MONTHLY_PAY_REPORTS';
export const GET_SUB_USERS_LIST_DETAILS = 'GET_SUB_USERS_LIST_DETAILS';
export const GET_SUB_PRODUCERS_LIST_DETAILS = 'GET_SUB_PRODUCERS_LIST_DETAILS';
export const GET_SUB_PROCESSORS_LIST_DETAILS = 'GET_SUB_PROCESSORS_LIST_DETAILS';
export const GET_SUB_HAULING_COMPANIES_LIST_DETAILS = 'GET_SUB_HAULING_COMPANIES_LIST_DETAILS';
export const GET_PAY_CALCS_INFO = 'GET_PAY_CALCS_INFO';
export const GET_PAY_CALCS_DETAILS = 'GET_PAY_CALCS_DETAILS';
export const GET_ACCESS_LIST = 'GET_ACCESS_LIST';
export const GET_MILK_PRICES = 'GET_MILK_PRICES';
export const GET_LAB_PROVIDER_LIST_DETAILS = 'GET_LAB_PROVIDER_LIST_DETAILS';
export const GET_MULTISITEACCESS_LIST_DETAILS = 'GET_MULTISITEACCESS_LIST_DETAILS';
export const GET_DROPOFF_SAMPLE_DETAILS = 'GET_DROPOFF_SAMPLE_DETAILS';
export const GET_SPLIT_PRODUCER_LIST_DETAILS = 'GET_SPLIT_PRODUCER_LIST_DETAILS';
export const GET_HAULING_CHARGE_LIST_DETAILS = 'GET_HAULING_CHARGES_LIST_DETAILS';
export const GET_PAYMENT_STATUS_DETAILS = 'GET_PAYMENT_STATUS_DETAILS';
export const GET_PRODUCER_HAULING_CONTRACT_LIST_DETAILS = 'GET_PRODUCER_HAULING_CONTRACT_LIST_DETAILS';
export const GET_SUPPLEMENTAL_HAULING_CONTRACT_LIST_DETAILS = 'GET_SUPPLEMENTAL_HAULING_CONTRACT_LIST_DETAILS';
export const GET_HAULER_ASSIGNMENT_LIST_DETAILS = 'GET_HAULER_ASSIGNMENT_LIST_DETAILS';

export function getUserListDetails(includeDeleted) {
    return (dispatch) => repository.getUserListDetails(includeDeleted).then((payload) => dispatch({ type: GET_USER_LIST_DETAILS, payload }));
}

export function getAdminListDetails(includeDeleted, filterSubUsers = true) {
    return (dispatch) => repository.getAdminListDetails(includeDeleted, filterSubUsers).then((payload) => dispatch({ type: GET_ADMIN_LIST_DETAILS, payload }));
}

export function getSubUserListDetails(includeDeleted) {
    return (dispatch) => repository.getSubUserListDetails(includeDeleted).then((payload) => dispatch({ type: GET_SUB_USERS_LIST_DETAILS, payload }));
}

export function getSubProducerListDetails(includeDeleted) {
    const id = getId();
    return (dispatch) => repository.getSubProducerListDetails(includeDeleted, id).then((payload) => dispatch({ type: GET_SUB_PRODUCERS_LIST_DETAILS, payload }));
}

export function getSubProcessorListDetails(includeDeleted) {
    const id = getId();
    return (dispatch) => repository.getSubProcessorListDetails(includeDeleted, id).then((payload) => dispatch({ type: GET_SUB_PROCESSORS_LIST_DETAILS, payload }));
}

export function getSubHaulingListDetails(includeDeleted) {
    const id = getId();
    return (dispatch) => repository.getSubHaulingListDetails(includeDeleted, id).then((payload) => dispatch({ type: GET_SUB_HAULING_COMPANIES_LIST_DETAILS, payload }));
}

export function getLabProviderListDetails(isProcessor, includeDeleted) {
    const isSubUser = store.getState().persisted.auth.user.data.subuser;
    const id = isProcessor ? (isSubUser ? store.getState().persisted.auth.user.data.processor_id : store.getState().persisted.auth.user.data.id) : null;
    const request = repository.getLabProviderListDetails(id, includeDeleted);
    return (dispatch) => request.then((payload) => dispatch({ type: GET_LAB_PROVIDER_LIST_DETAILS, payload }));
}

export function getDriverListDetails(isTransport, includeDeleted) {
    const isSubUser = store.getState().persisted.auth.user.data.subuser;
    const id = isTransport ? (isSubUser ? store.getState().persisted.auth.user.data.hauling_id : store.getState().persisted.auth.user.data.id) : null;
    const request = repository.getDriverListDetails(id, includeDeleted);
    return (dispatch) => request.then((payload) => dispatch({ type: GET_DRIVER_LIST_DETAILS, payload }));
}

export function getReceiverListDetails(isProcessor, includeDeleted) {
    const isSubUser = store.getState().persisted.auth.user.data.subuser;
    const id = isProcessor ? (isSubUser ? store.getState().persisted.auth.user.data.processor_id : store.getState().persisted.auth.user.data.id) : null;
    const request = repository.getReceiverListDetails(id, includeDeleted);
    return (dispatch) => request.then((payload) => dispatch({ type: GET_RECEIVER_LIST_DETAILS, payload }));
}

export function getHaulingListDetails(includeDeleted, isSelf) {
    const id = store.getState().persisted.auth.user.data.subuser ? store.getState().persisted.auth.user.data.hauling_id : store.getState().persisted.auth.user.data.id;
    const request = repository.getHaulingListDetails(includeDeleted, isSelf ? id : null);
    return (dispatch) => request.then((payload) => dispatch({ type: GET_HAULING_LIST_DETAILS, payload }));
}

export function getProcessorListDetails(includeDeleted, isProcessor, isExpressRequired = false) {
    const isSubUser = store.getState().persisted.auth.user.data.subuser;
    const id = isProcessor ? (isSubUser ? store.getState().persisted.auth.user.data.processor_id : store.getState().persisted.auth.user.data.id) : null;
    return (dispatch) => repository.getProcessorListDetails(id, includeDeleted, isExpressRequired).then((payload) => dispatch({ type: GET_PROCESSOR_LIST_DETAILS, payload }));
}

export function getProducerListDetails({ filterDeleted = true, filterInactive, includePickups, filterSplitProducer }) {
    return (dispatch) => repository.getProducerListDetails({ filterDeleted, filterInactive, includePickups, filterSplitProducer }).then((payload) => dispatch({ type: GET_PRODUCER_LIST_DETAILS, payload }));
}

export function getProducerBusinessListDetails(includeDeleted) {
    return (dispatch) => repository.getProducerBusinessListDetails(includeDeleted).then((payload) => dispatch({ type: GET_PRODUCER_BUSINESS_LIST_DETAILS, payload }));
}

export function getAccessList() {
    return (dispatch) => repository.getAccessList(store.getState().persisted.auth.user.data.id, store.getState().persisted.auth.user.role).then((payload) => dispatch({ type: GET_ACCESS_LIST, payload }));
}

export function getRoutesListDetails(desiredFilters = null, filterDeleted = true) {
    return (dispatch) => repository.getRoutesListDetails(desiredFilters, filterDeleted).then((payload) => dispatch({ type: GET_ROUTES_LIST_DETAILS, payload }));
}

export function getDeferralListDetails(startDate, endDate) {
    return (dispatch) => repository.getDeferralListDetails(startDate, endDate).then((payload) => dispatch({ type: GET_DEFERRALS_LIST_DETAILS, payload }));
}

export function getTrailerListDetails(includeDeleted, isHauler) {
    const isSubUser = store.getState().persisted.auth.user.data.subuser;
    const id = isHauler ? (isSubUser ? store.getState().persisted.auth.user.data.hauling_id : store.getState().persisted.auth.user.data.id) : null;
    return (dispatch) => repository.getTrailerListDetails(includeDeleted, id).then((payload) => dispatch({ type: GET_TRAILER_LIST_DETAILS, payload }));
}

export function getTruckListDetails(includeDeleted) {
    return (dispatch) => repository.getTruckListDetails(includeDeleted).then((payload) => dispatch({ type: GET_TRUCK_LIST_DETAILS, payload }));
}

export function getMilkPrices(date) {
    return (dispatch) => repository.getMilkPrices(date).then((payload) => dispatch({ type: GET_MILK_PRICES, payload }));
}

export function getAssigneeListDetails() {
    return (dispatch) => repository.getAssigneeListDetails({}).then((payload) => dispatch({ type: GET_ASSIGNEE_LIST_DETAILS, payload }));
}

export function getAssignmentListDetails(month, year) {
    return (dispatch) => repository.getAssignmentListDetails(month, year).then((payload) => dispatch({ type: GET_ASSIGNMENT_LIST_DETAILS, payload }));
}

export function getAssignmentConfigurationListDetails(startDate, endDate, role) {
    return (dispatch) => repository.getAssignmentConfigurationListDetails(startDate, endDate, role).then((payload) => dispatch({ type: GET_ASSIGNMENT_CONFIGURATION_LIST_DETAILS, payload }));
}

export function getSiloListDetails() {
    return (dispatch) => repository.getSiloListDetails().then((payload) => dispatch({ type: GET_SILO_LIST_DETAILS, payload }));
}

export function getMonthlyPayReports(month, year, region) {
    return (dispatch) => repository.getMonthlyPayReports(month, year, region).then((payload) => dispatch({ type: GET_MONTHLY_PAY_REPORTS, payload }));
}

export function getHaulerMonthlyPayReports(startDate, endDate) {
    return (dispatch) => repository.getHaulerMonthlyPayReports(startDate, endDate).then((payload) => dispatch({ type: GET_HAULER_MONTHLY_PAY_REPORTS, payload }));
}

export function getHaulerMonthlyPayReportsForHauler(startDate, endDate, haulerId) {
    return (dispatch) => repository.getHaulerMonthlyPay(haulerId, startDate, endDate).then((payload) => dispatch({ type: GET_HAULER_MONTHLY_PAY_REPORTS_FOR_HAULER, payload }));
}

export function getVendorMonthlyPayReports(month, year) {
    return (dispatch) => repository.getVendorMonthlyPayReports(month, year).then((payload) => dispatch({ type: GET_VENDOR_MONTHLY_PAY_REPORTS, payload }));
}

export function getProducerMonthlyPayReports(startDate, endDate, region, producerId) {
    return (dispatch) => repository.getProducerMonthlyPayReports(startDate, endDate, region, producerId).then((payload) => dispatch({ type: GET_PRODUCER_MONTHLY_PAY_REPORTS, payload }));
}

export function getPayCalcsInfo(month, year) {
    return (dispatch) => repository.getPayCalcsInfo(month, year).then((payload) => dispatch({ type: GET_PAY_CALCS_INFO, payload }));
}

export function getPayCalcsDetails(month, year, region) {
    return (dispatch) => repository.getPayCalcsDetails(month, year, region).then((payload) => dispatch({ type: GET_PAY_CALCS_DETAILS, payload }));
}

export function getMultisiteAccessListDetails() {
    return (dispatch) => repository.getMultisiteAccessListDetails().then((payload) => dispatch({ type: GET_MULTISITEACCESS_LIST_DETAILS, payload }));
}

export function getDropoffSampleDetails(id, start, end) {
    return (dispatch) => repository.getDropoffSampleDetails(id, start, end).then((payload) => dispatch({ type: GET_DROPOFF_SAMPLE_DETAILS, payload }));
}

export function getSplitProducerListDetails() {
    return (dispatch) => repository.getSplitProducerListDetails().then((payload) => dispatch({ type: GET_SPLIT_PRODUCER_LIST_DETAILS, payload }));
}

export function getHaulingChargeListDetails(start, end, isHauler, isProducer) {
    const isSubUser = store.getState().persisted.auth.user.data.subuser;
    const producerId = isProducer ? (isSubUser ? store.getState().persisted.auth.user.data.producer_id : store.getState().persisted.auth.user.data.id) : null;
    const haulerId = isHauler ? (isSubUser ? store.getState().persisted.auth.user.data.hauling_id : store.getState().persisted.auth.user.data.id) : null;
    return (dispatch) => repository.getHaulingChargeListDetails(start, end, haulerId, producerId).then((payload) => dispatch({ type: GET_HAULING_CHARGE_LIST_DETAILS, payload }));
}

export function getPaymentStatusDetails(start, end) {
    return (dispatch) => repository.getPaymentStatusDetails(start, end).then((payload) => dispatch({ type: GET_PAYMENT_STATUS_DETAILS, payload }));
}

export function getProducerHaulingContractListDetails(isHauler, isProducer, startDate, endDate) {
    const isSubUser = store.getState().persisted.auth.user.data.subuser;
    const producerId = isProducer ? (isSubUser ? store.getState().persisted.auth.user.data.producer_id : store.getState().persisted.auth.user.data.id) : null;
    const haulerId = isHauler ? (isSubUser ? store.getState().persisted.auth.user.data.hauling_id : store.getState().persisted.auth.user.data.id) : null;
    return (dispatch) => repository.getProducerHaulingContractListDetails(haulerId, producerId, startDate, endDate).then((payload) => dispatch({ type: GET_PRODUCER_HAULING_CONTRACT_LIST_DETAILS, payload }));
}

export function getSupplementalHaulingContractListDetails(isHauler, isProducer, startDate, endDate) {
    const isSubUser = store.getState().persisted.auth.user.data.subuser;
    const producerId = isProducer ? (isSubUser ? store.getState().persisted.auth.user.data.producer_id : store.getState().persisted.auth.user.data.id) : null;
    const haulerId = isHauler ? (isSubUser ? store.getState().persisted.auth.user.data.hauling_id : store.getState().persisted.auth.user.data.id) : null;
    return (dispatch) => repository.getSupplementalHaulingContractListDetails(haulerId, producerId, startDate, endDate).then((payload) => dispatch({ type: GET_SUPPLEMENTAL_HAULING_CONTRACT_LIST_DETAILS, payload }));
}

export function getHaulerAssignmentListDetails(month, year) {
    return (dispatch) => repository.getHaulerAssignmentListDetails(month, year).then((payload) => dispatch({ type: GET_HAULER_ASSIGNMENT_LIST_DETAILS, payload }));
}
