import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';
import { showMessage } from 'app/store/actions';
import { addMerchandisePurchase, editMerchandisePurchase } from '../../../repository';
import { Box, Button, Grid, debounce, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ReactHookFormSearchableSelect from '../../form-components/ReactHookFormSearchableSelect';
import _ from 'lodash';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import { filterParentProducers } from '../../../../utils';

const useStyles = makeStyles(() => ({
    root: {
        width: '75%',
    },
    date: {
        marginTop: '8px',
    },
}));

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });

const PRAIRIEMerchandisePurchaseForm = ({ editData, producers, merchandise }) => {
    const history = useHistory();
    const classes = useStyles();
    const [purchaseAmount, setPurchaseAmount] = useState(0);
    const [parentProducers] = useMemo(() => filterParentProducers(producers), [producers]);
    const reactHookFormMethods = useForm({
        mode: 'all',
        defaultValues: {
            merchandise_item: _.find(merchandise, (z) => z.value === editData?.merchandise_id),
            purchased_by: _.find(producers, (z) => z.value === editData?.producer_id),
            purchased_date: editData?.purchased_date ? moment(editData?.purchased_date) : undefined,
            purchase_amount: editData?.purchase_amount || undefined,
            purchase_quantity: editData?.purchase_quantity || undefined,
        },
    });

    const { handleSubmit, control, errors, register, watch, setValue } = reactHookFormMethods;

    const merchandiseWatch = watch('merchandise_item');
    const purchaseQtyWatch = watch('purchase_quantity');
    const purchaseDateWatch = watch('purchased_date');

    useEffect(() => {
        const purchaseQuantity = parseInt(purchaseQtyWatch);
        const purchaseDate = moment(purchaseDateWatch).format('YYYY-MM-DD');

        const futureRateAndDate = merchandiseWatch?.historic_rates?.length > 0 ? merchandiseWatch?.historic_rates.sort((a, b) => moment(a.price_start_date) - moment(b.price_start_date)).find((rate) => moment(rate.price_start_date).isSameOrAfter(purchaseDate, 'month')) : null;

        const currentRateAndDate = merchandiseWatch?.historic_rates?.sort((a, b) => moment(b.price_start_date) - moment(a.price_start_date)).find((rate) => moment(rate.price_start_date).isSameOrBefore(purchaseDate, 'month')) || futureRateAndDate;

        const pricePerUnit = parseFloat(currentRateAndDate?.price_per_unit || 0);

        const amount = purchaseQuantity * pricePerUnit;

        if (purchaseQtyWatch) {
            setValue('purchase_amount', amount);
        }
        setPurchaseAmount(amount);
    }, [merchandiseWatch, purchaseQtyWatch, purchaseDateWatch, setValue]);

    const transformMerchandiseLabel = (option) => {
        if (option.product_number && option.product_name) {
            return `${option.product_number} - ${option.product_name} - ${_.capitalize(option.product_type)}`;
        }
        return option.name;
    };

    const transformProducerLabel = (option) => {
        if (option.license_number && option.name) {
            return `${option.license_number} - ${option.name}`;
        }
        return option.name;
    };

    const put = async (submitModel) => {
        const { _id } = editData;
        const model = submitModel;
        try {
            await editMerchandisePurchase(model, _id);
            showMessage({ message: 'Successfully Edited Purchase' });
            history.replace({ pathname: '/merchandise', state: { selectedTab: 1 } });
        } catch (err) {
            showMessage({ message: 'Error editing purchase' });
        }
    };

    const post = async (submitModel) => {
        try {
            await addMerchandisePurchase(submitModel);
            showMessage({ message: 'Successfully Added Purchase' });
            history.replace({ pathname: '/merchandise', state: { selectedTab: 1 } });
        } catch (err) {
            showMessage({ message: 'Could not add Purchase' });
        }
    };

    const onSubmit = (model) => {
        let submitModel = { ...model };

        Object.keys(submitModel).forEach((key) => {
            if (submitModel[key]?.value !== undefined) {
                submitModel[key] = submitModel[key].value;
            }
            if (submitModel[key] === '') {
                submitModel[key] = undefined;
            }
        });
        submitModel.purchase_quantity = submitModel?.purchase_quantity?.toString() || undefined;
        submitModel.purchase_amount = submitModel?.purchase_amount?.toString() || undefined;

        submitModel = _.omitBy(submitModel, (field) => _.isUndefined(field) || _.isNull(field) || field === '');

        delete submitModel?._id;
        delete submitModel.tableData;
        delete submitModel.index;

        if (editData) {
            put(submitModel);
        } else {
            post(submitModel);
        }
    };

    const cancel = () => {
        history.replace({ pathname: '/merchandise', state: { selectedTab: 1 } });
    };

    const debounceSubmit = useCallback(debounce(onSubmit, 500), []);

    return (
        <div className={classes.root}>
            <FormProvider {...reactHookFormMethods}>
                <form onSubmit={handleSubmit(debounceSubmit, errors)}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={6} className={classes.date}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Controller
                                    name={'purchased_date'}
                                    control={control}
                                    defaultValue={moment()}
                                    render={({ field: { onChange, value } }) => {
                                        return <DatePicker className={classes.date} openTo="year" views={['year', 'month']} fullWidth onChange={onChange} format="YYYY" value={value} label="Year" inputVariant="outlined" required />;
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={6} className={classes.date}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Controller
                                    name={'purchased_date'}
                                    control={control}
                                    defaultValue={moment()}
                                    render={({ field: { onChange, value } }) => {
                                        return <DatePicker className={classes.date} openTo="month" views={['year', 'month']} fullWidth onChange={onChange} format="MMM" value={value} label="Month" inputVariant="outlined" required />;
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={12}>
                            <ReactHookFormSearchableSelect label={'Producer'} defaultValue={[]} name={'purchased_by'} options={parentProducers.sort((a, b) => collator.compare(a.license_number, b.license_number))} type={'text'} customRender={transformProducerLabel} fullWidth required />
                        </Grid>

                        <Grid item xs={12}>
                            <ReactHookFormSearchableSelect label={'Merchandise'} name={'merchandise_item'} options={merchandise.sort((a, b) => Number(a.product_number) - Number(b.product_number))} customRender={transformMerchandiseLabel} required type={'text'} fullWidth />
                        </Grid>

                        {merchandiseWatch?.product_type === 'farm' && (
                            <>
                                <Grid item xs={12}>
                                    <ReactHookFormInput label={'Qty'} type={'number'} name={'purchase_quantity'} fullWidth variant="outlined" required />
                                </Grid>
                                {purchaseQtyWatch && (
                                    <Grid item xs={12}>
                                        <ReactHookFormInput {...register('purchase_amount')} label={'Value'} type={'number'} name={'purchase_amount'} fullWidth variant="outlined" disabled value={purchaseAmount} />
                                    </Grid>
                                )}
                            </>
                        )}

                        {merchandiseWatch?.product_type === 'dairy' && (
                            <Grid item xs={12}>
                                <ReactHookFormInput label={'Value'} type={'number'} name={'purchase_amount'} fullWidth variant="outlined" required />
                            </Grid>
                        )}

                        <Box display="flex" justifyContent="center" gridGap="30px" paddingTop={'15px'}>
                            <Button variant="contained" color="primary" margin="normal" className="mx-auto my-16" sx={{ paddingTop: 2 }} onClick={cancel}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="secondary" margin="normal" className="mx-auto my-16" sx={{ paddingTop: 2 }} type="submit">
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </form>
            </FormProvider>
        </div>
    );
};

export default PRAIRIEMerchandisePurchaseForm;
