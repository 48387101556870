import React from 'react';
import { Helmet } from 'react-helmet';
import { Box, Grid } from '@mui/material';
import useToolbarOffset from '../../../hooks/useToolbarOffset';

const sx = {
    root: {
        background: (theme) => theme.palette.primary.default,
        color: (theme) => theme.palette.primary.contrastText,
    },
};

const SideDrawer = ({ helmet, DrawerContent, children }) => {
    const helmetLabel = helmet ?? 'Milk Moovement';
    const { toolbarOffset } = useToolbarOffset();

    return (
        <Box sx={sx}>
            <Helmet>
                <title>{helmetLabel}</title>
            </Helmet>
            <Grid container>
                <Grid item sm={3} sx={{ height: `calc(100vh - ${toolbarOffset}px)`, display: { xs: 'none', sm: 'grid' } }}>
                    <Box sx={{ boxShadow: 1, backgroundColor: 'background.paper', height: '100%' }}>{DrawerContent}</Box>
                </Grid>
                <Grid item xs={12} sm={9} sx={{ height: `calc(100vh - ${toolbarOffset}px)`, overflow: 'scroll' }}>
                    {children}
                </Grid>
            </Grid>
        </Box>
    );
};

export default SideDrawer;
