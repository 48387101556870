import ability from './ability';
import {
    ProcessedFluidPermissions,
    ReceivingPermissions,
    ScheduleItems,
    DropoffPermissions,
    RouteSessionPermissions,
    PickupPermissions,
    HelpfulLinkPermissions,
    MilkAdjustmentsPermissions,
    UserManagementPermissions,
    //
} from './features';
import UDACloseMonth from './features/ClosePeriod/UDACloseMonth';

function updateUserPermissions(user) {
    const emptyRules = [];
    const rules = emptyRules.concat(
        ProcessedFluidPermissions(user),
        ScheduleItems(user),
        ReceivingPermissions(user),
        DropoffPermissions(user),
        RouteSessionPermissions(user),
        PickupPermissions(user),
        HelpfulLinkPermissions(user),
        UDACloseMonth(user),
        MilkAdjustmentsPermissions(user),
        UserManagementPermissions(user)
        // Prettier no prettying
    );

    ability.update(rules);
}

export default updateUserPermissions;
