import addUser from './addUser';

const addHauling = (model) =>
    addUser({
        ...model,
        role: 'transport',
        subuser: false,
    });

export default addHauling;
