import axios from 'axios';
import { createEffectiveHaulerRateQuery } from './queries';

const addEffectiveHaulerRate = (model) => {
    const query = createEffectiveHaulerRateQuery({ model });

    return axios
        .post(query.url, { ...query.body })
        .then((response) => {
            // eslint-disable-next-line no-console
            console.log(response.data);
            return { data: response.data };
        })
        .catch((errors) => {
            // eslint-disable-next-line no-console
            console.log(errors);
            throw new Error('Unable to add hauler rate');
        });
};

export default addEffectiveHaulerRate;
