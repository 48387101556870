import React from 'react';
import { Grid } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';

const TCJMilkPriceFormSkimButterfatPrices = () => (
    <>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Skim ($/cwt)" name={'milk_prices.skim_milk_price'} type="number" />
        </Grid>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Butterfat ($/lb)" name={'milk_prices.butter_fat_price'} type="number" />
        </Grid>
    </>
);

export default TCJMilkPriceFormSkimButterfatPrices;
