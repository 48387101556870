/* eslint-disable */
import React from 'react';
import axios from 'axios';
import { createGenerateClassName, jssPreset } from '@material-ui/core';
import Provider from 'react-redux/es/components/Provider';
import { HashRouter } from 'react-router-dom';
import { StylesProvider } from '@material-ui/core';
import { create } from 'jss';
import jssExtend from 'jss-extend';
import { FuseAuthorization, FuseLayout, FuseTheme } from '@fuse';
import moment from 'moment-timezone';
import { PersistGate } from 'redux-persist/integration/react';
import { Auth } from './auth';
import store, { persistor } from './store';
import AppContext from './AppContext';
//import {AutoLogoutTimer} from 'app/custom-components'
import routes from './fuse-configs/routesConfig';
import TimeoutDialog from './custom-widgets/dialogs/TimeoutDialog';
import ErrorBoundary from './custom-widgets/ErrorBoundary';
import { getEnvironment } from '../utils';
//import jwtService from 'app/services/jwtService';
import { LocalizationProvider } from './localization/LocalizationContext';
import LoggingBoundary from './custom-widgets/LoggingBoundary';
import { Ability, AbilityContext } from './auth/permissions';
import { BasisTheoryProvider, useBasisTheory } from '@basis-theory/basis-theory-react';
import { NotificationProvider } from './NotificationContext';
import { QueryProvider } from './QueryProvider';

const jss = create({
    ...jssPreset(),
    plugins: [...jssPreset().plugins, jssExtend()],
});

jss.options.insertionPoint = document.getElementById('jss-insertion-point');
const generateClassName = createGenerateClassName();
const environment = getEnvironment(window.location.hostname);
const basisTheoryApiKey = environment === 'production' ? process.env.REACT_APP_BASIS_THEORY_PRODUCTION_CLIENT : environment === 'staging' ? process.env.REACT_APP_BASIS_THEORY_STAGING_CLIENT : process.env.REACT_APP_BASIS_THEORY_CLIENT;

const App = () => {
    const { bt } = useBasisTheory(basisTheoryApiKey, {
        elements: true,
    });

    return (
        <AppContext.Provider value={{ routes }}>
            <StylesProvider jss={jss} generateClassName={generateClassName}>
                <NotificationProvider>
                    <Provider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <LocalizationProvider>
                                <AbilityContext.Provider value={Ability}>
                                    <QueryProvider>
                                        <Auth>
                                            <HashRouter>
                                                <LoggingBoundary>
                                                    <ErrorBoundary>
                                                        <FuseAuthorization>
                                                            <FuseTheme>
                                                                <TimeoutDialog>
                                                                    <BasisTheoryProvider bt={bt}>
                                                                        <FuseLayout />
                                                                    </BasisTheoryProvider>
                                                                </TimeoutDialog>
                                                            </FuseTheme>
                                                        </FuseAuthorization>
                                                    </ErrorBoundary>
                                                </LoggingBoundary>
                                            </HashRouter>
                                        </Auth>
                                    </QueryProvider>
                                </AbilityContext.Provider>
                            </LocalizationProvider>
                        </PersistGate>
                    </Provider>
                </NotificationProvider>
            </StylesProvider>
        </AppContext.Provider>
    );
};

export default App;
