import axios from 'axios';
import { getLoansQuery } from '../queries';
import parseError from '../errors/utils';

const getLoansInDateRange = async ({ startDate, endDate }) => {
    try {
        const loans = await axios.get(getLoansQuery({ startDate, endDate }));
        return { loans: loans?.data };
    } catch (error) {
        return { error: parseError({ error }) };
    }
};

export default getLoansInDateRange;
