import React from 'react';

const TabPanel = (props) => {
    const { children, value, index, ...rest } = props;
    return (
        <div role="tabpanel" hidden={value !== index} {...rest}>
            {value === index && <div>{children}</div>}
        </div>
    );
};

export default TabPanel;
