import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import Papa from 'papaparse';
import { downloadCSV, getMonthDateRange, numberFormat } from '../../../utils';
import * as Actions from '../../store/actions';

class AgropurDownloadButton extends React.Component {
    state = {
        loadingPickupData: false,
        loadingLabData: false,
        month: '',
        year: '',
    };

    componentDidMount() {
        this.fetch({ ...this.state, ...this.props });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedMonth, selectedYear } = this.props;

        if (prevProps.selectedMonth !== selectedMonth || prevProps.selectedYear !== selectedYear) {
            this.fetch({ ...this.state, ...this.props });
        }
    }

    fetch = ({ selectedMonth, selectedYear, getPickupsProcessorDetails, getAdminLabDetails }) => {
        const dates = getMonthDateRange(selectedYear, selectedMonth);

        this.setState({
            loadingPickupData: true,
            loadingLabData: true,
            month: selectedMonth,
            year: selectedYear,
        });

        getPickupsProcessorDetails(dates.start, dates.end).then(() => {
            const { month, year } = this.state;
            this.setState({ loadingPickupData: !(month === selectedMonth && year === selectedYear) });
        });

        getAdminLabDetails(dates.start, dates.end).then(() => {
            const { month, year } = this.state;
            this.setState({ loadingLabData: !(month === selectedMonth && year === selectedYear) });
        });
    };

    transformDFNLForCsv = (details, year, month) => {
        const data = [];

        details
            .filter((v) => v.payable > 0)
            .map((value) => {
                data.push([
                    `${moment([year, month - 1]).format('YYYYMM')};${value.CMTNumber};${value.total_production};${numberFormat(value.revenue, 2, '.', '')};${numberFormat(value.expenses, 2, '.', '')};${numberFormat(value.HST, 2, '.', '')};${value.adv};${numberFormat(value.payable, 2, '.', '')};${numberFormat(value.fat, 2, '.', '')};${numberFormat(
                        value.protein,
                        2,
                        '.',
                        ''
                    )};${numberFormat(value.los, 2, '.', '')};`,
                ]);

                return value;
            });

        return data;
    };

    transformAgropurForCsv = (details) => {
        if (details) {
            const CMTList = ['104200', '106000', '100300', '200600', '105200', '106100', '106300', '107400', '100700', '101700', '103900'];

            const reversedDetails = details.filter((value) => !value.deleted_at && !!value.producer).reverse();
            const returnValue = [];

            CMTList.forEach((number) => {
                const reducedLabs = reversedDetails.filter((value) => value.producer[0].CMTNumber === number && !!value.fat && !!value.protein && !!value.los);
                // Handle multiple tank farms
                if (number === '106000' || number === '106300' || number === '100700') {
                    const cmt1 = number;
                    const cmt2 = (parseInt(number) + 1).toString();
                    const tank1 = reducedLabs.filter((value) => parseInt(value.tank_number) === 1);
                    const tank2 = reducedLabs.filter((value) => parseInt(value.tank_number) === 2);

                    tank1.forEach((value) => {
                        returnValue.push([`${value.route_session[0].BOL};${moment(value.created_at).format('YYYY-MM-DD HH:mm')};${cmt1};${value.volume}`]);
                    });

                    tank2.forEach((value) => {
                        returnValue.push([`${value.route_session[0].BOL};${moment(value.created_at).format('YYYY-MM-DD HH:mm')};${cmt2};${value.volume}`]);
                    });
                } else if (number === '103900') {
                    reducedLabs.forEach((value) => {
                        returnValue.push([`${value.route_session[0].BOL};${moment(value.created_at).format('YYYY-MM-DD HH:mm')};${value.producer[0].CMTNumber};${(parseFloat(value.volume).toFixed(2) - 1300.0).toFixed(2).toString()}`]);
                    });

                    reducedLabs.forEach((value) => {
                        returnValue.push([`${value.route_session[0].BOL};${moment(value.created_at).format('YYYY-MM-DD HH:mm')};` + '103200;' + '1300.00']);
                    });
                } else if (number === '107400') {
                    reducedLabs.forEach((value) => {
                        returnValue.push([`${value.route_session[0].BOL};${moment(value.created_at).format('YYYY-MM-DD HH:mm')};` + '106300;' + `${value.volume}`]);
                    });
                } else {
                    reducedLabs.forEach((value) => {
                        returnValue.push([`${value.route_session[0].BOL};${moment(value.created_at).format('YYYY-MM-DD HH:mm')};${value.producer[0].CMTNumber};${value.volume}`]);
                    });
                }
            });

            return returnValue;
        }
        return [];
    };

    transformLabsForCsv = (details) => {
        if (details) {
            const CMTList = ['104200', '106000', '100300', '200600', '105200', '106100', '106300', '107400', '100700', '101700', '103900'];

            const reversedDetails = details.filter((value) => !value.deleted_at && !!value.producer).reverse();

            const returnValue = [];

            CMTList.forEach((number) => {
                const reducedLabs = reversedDetails.filter((value) => value.producer[0].CMTNumber === number && !!value.fat && !!value.protein && !!value.LOS);

                // Handle multiple tank farms
                if (number === '106000' || number === '106300' || number === '100700') {
                    const cmt1 = number;
                    const cmt2 = (parseInt(number) + 1).toString();
                    const tank1 = reducedLabs.filter((value) => parseInt(value.tank) === 1);
                    const tank2 = reducedLabs.filter((value) => parseInt(value.tank) === 2);

                    tank1.forEach((value) => {
                        returnValue.push([`${cmt1};${value.somatic_cell_count};${moment(value.date).format('MM/DD/YYYY')};${parseFloat(value.fat).toFixed(2)};${parseFloat(value.protein).toFixed(2)};${parseFloat(value.LOS).toFixed(2)}`]);
                    });

                    tank2.forEach((value) => {
                        returnValue.push([`${cmt2};${value.somatic_cell_count};${moment(value.date).format('MM/DD/YYYY')};${parseFloat(value.fat).toFixed(2)};${parseFloat(value.protein).toFixed(2)};${parseFloat(value.LOS).toFixed(2)}`]);
                    });
                } else if (number === '107400') {
                    reducedLabs.forEach((value) => {
                        returnValue.push([`106300;${value.somatic_cell_count};${moment(value.date).format('MM/DD/YYYY')};${parseFloat(value.fat).toFixed(2)};${parseFloat(value.protein).toFixed(2)};${parseFloat(value.LOS).toFixed(2)}`]);
                    });
                } else {
                    reducedLabs.forEach((value) => {
                        returnValue.push([`${value.producer[0].CMTNumber};${value.somatic_cell_count};${moment(value.date).format('MM/DD/YYYY')};${parseFloat(value.fat).toFixed(2)};${parseFloat(value.protein).toFixed(2)};${parseFloat(value.LOS).toFixed(2)}`]);
                    });

                    // Handle 103900
                    if (number === '103900') {
                        reducedLabs.forEach((value) => {
                            returnValue.push([`103200;${value.somatic_cell_count};${moment(value.date).format('MM/DD/YYYY')};${parseFloat(value.fat).toFixed(2)};${parseFloat(value.protein).toFixed(2)};${parseFloat(value.LOS).toFixed(2)}`]);
                        });
                    }
                }
            });

            return returnValue;
        }
        return [];
    };

    downloadAgropur = (month, year, { getPayCalcsInfo, pickupProcessorDetails, labDetails }) => {
        getPayCalcsInfo(month, year).then((result) => {
            if (result.payload.length) {
                this.convertDataToCSV(this.transformDFNLForCsv(result.payload, year, month), 'DFNLPAYMENT');
            }

            this.convertDataToCSV(this.transformAgropurForCsv(pickupProcessorDetails.pickups), 'Agropur_Report');
            this.convertDataToCSV(this.transformLabsForCsv(labDetails.labReports), 'Lab_Report');
        });
    };

    convertDataToCSV = (data = null, title = null) => {
        const { selectedMonth, selectedYear } = this.props;

        const CSVContent = Papa.unparse(data);
        downloadCSV(CSVContent, `${`${title}_${moment(`${selectedYear.toString()} ${selectedMonth.toString()} 01`, 'YYYY MM DD').format('MMMM')}_${selectedYear}`}.csv`);
    };

    render() {
        const { selectedMonth, selectedYear } = this.props;
        const { loadingPickupData, loadingLabData } = this.state;
        return (
            <Button type="submit" variant="contained" color="secondary" className="m-16 ml-16" aria-label="Download Agropur and Labs" onClick={() => this.downloadAgropur(selectedMonth, selectedYear, { ...this.props, ...this.state })} disabled={loadingPickupData || loadingLabData}>
                Download Agropur and Labs {`${moment(`${selectedYear.toString()} ${selectedMonth.toString()} 01`, 'YYYY MM DD').format('MMMM')}, ${selectedYear}`}
            </Button>
        );
    }
}

function mapStateToProps({ mainReducer }) {
    return { pickupProcessorDetails: mainReducer.pickupProcessorDetails.data, labDetails: mainReducer.labDetails.data };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            getPayCalcsInfo: Actions.getPayCalcsInfo,
            getPickupsProcessorDetails: Actions.getPickupsProcessorDetails,
            getAdminLabDetails: Actions.getAdminLabDetails,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(AgropurDownloadButton);
