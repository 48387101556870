import axios from 'axios';
import { resolveId } from '../../utils';
import { getRoutesQuery, getProcessorsQuery, getHaulingCompaniesQuery, getProducersQuery, getUserGroupQuery } from './queries';

import { getCommodities } from '../main/apps/Core/scheduling2/fluid/commodities';
import { getUserDisplayName, getUserLicenseNumber } from '../helpers/users/utils';

const getScheduleConfig2Details = async (region) => {
    try {
        const haulerFields = ['id', 'name', 'hauler_number'];
        const fields = ['id', 'name', 'license_number'];
        const filterDeleted = true;

        const routeQuery = getRoutesQuery({ filterDeleted });
        const processorQuery = getProcessorsQuery({ fields, filterDeleted });
        const haulingQuery = getHaulingCompaniesQuery({ fields: haulerFields, filterDeleted });
        const populate = { users: ['role', 'name', 'deleted_at'] };
        const userGroupQuery = getUserGroupQuery({ populate });
        const producerQuery = getProducersQuery({ fields, filterDeleted });

        const routeRequest = axios.get(routeQuery);
        const processorRequest = axios.get(processorQuery);
        const haulingRequest = axios.get(haulingQuery);
        const producerRequest = axios.get(producerQuery);
        const userGroupRequest = axios.get(userGroupQuery);

        const [routeResponse, processorResponse, haulingResponse, producerResponse, userGroupResponse] = await Promise.all([routeRequest, processorRequest, haulingRequest, producerRequest, userGroupRequest]);
        const userGroups = userGroupResponse.data.map((userGroup) => resolveId(userGroup)).sort((a, b) => (a.name < b.name ? -1 : 1));

        const processorGroups = userGroups.map((group) => {
            const user_ids = [];
            group.users.forEach((user) => {
                const resolvedUser = resolveId(user);
                if (user.role === 'processor' && resolvedUser.id) user_ids.push(resolvedUser.id);
            });
            const newGroup = { ...group, user_ids, role: 'processor' };
            delete newGroup.users;
            return newGroup;
        });
        const haulingGroups = userGroups.map((group) => {
            const user_ids = [];
            group.users.forEach((user) => {
                const resolvedUser = resolveId(user);
                if (user.role === 'transport' && resolvedUser.id) user_ids.push(resolvedUser.id);
            });
            const newGroup = { ...group, user_ids, role: 'transport' };
            delete newGroup.users;
            return newGroup;
        });

        const processorOptions = processorResponse.data.map((p) => {
            const license_number = getUserLicenseNumber(p);
            const name = getUserDisplayName(p.name, license_number, false);
            return { ...p, name };
        });

        const haulingOptions = haulingResponse.data.map((h) => {
            const license_number = getUserLicenseNumber(h);
            const name = getUserDisplayName(h.name, license_number, false);
            return { ...h, name };
        });

        const commodities = getCommodities(region, true);
        return {
            routes: routeResponse.data,
            processors: processorOptions,
            haulingCompanies: haulingOptions,
            producers: producerResponse.data,
            processorGroups,
            haulingGroups,
            commodities,
        };
    } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getScheduleConfig2Details;
