import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory, useParams } from 'react-router-dom';
import { Button, Divider, InputAdornment, Typography, FormControl, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers';
import Formsy from 'formsy-react';
import moment, { isDate } from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import path from 'path';
import _ from 'lodash';
import MMSplashScreen from '../MMSplashScreen';
import Dropzone from './Dropzone';
import { TextFieldFormsy } from '@fuse';
import { uploadImage, setError, editRouteSession, getSingleInstance, showMessage } from '../../store/actions';
import { uploadFile, getFileURL } from '../../repository';
import { LocalizationConsumer } from '../../localization/LocalizationContext';

const SealForm = ({ editData }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const fileInput = React.createRef();
    const [files, setFiles] = useState([]);
    const [canSubmit, setCanSubmit] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment().toDate());
    const [sealTypes, setSealTypes] = useState([
        {
            name: 'CIP Vent',
            value: 'cipVent',
        },
        {
            name: 'Top Dome',
            value: 'topDome',
        },
        {
            name: 'Rear Dust Cover',
            value: 'dustCover',
        },
        {
            name: 'Pump Box',
            value: 'pumpBox',
        },
        {
            name: 'Tube End',
            value: 'tubeEnd',
        },
        {
            name: 'Tanker Cap',
            value: 'tankerCap',
        },
        {
            name: 'Dropoff Sample',
            value: 'dropoffSample',
        },
    ]);
    const [selectedType, setSelectedType] = useState(null);
    const [barcode, setBarcode] = useState('');
    const [url, setURL] = useState('');
    const { routeSessionId } = useParams();

    useEffect(() => {
        if (editData?.selectedDate) setSelectedDate(moment(editData.created_at));
        if (editData?.seal_type) setSelectedType(editData.seal_type);
        if (editData?.barcode) setBarcode(editData.barcode);
        if (editData && editData.slip_s3_location) {
            getFileURL(editData.slip_s3_location)
                .then((file) => {
                    setURL(file);
                })
                .catch((error) => {
                    showMessage({ message: 'There was a problem displaying the image. Please contact support.' });
                });
        }
    }, [editData]);

    const disableButton = () => {
        setCanSubmit(false);
    };

    const enableButton = () => {
        setCanSubmit(true);
    };

    const finishSubmit = (model) => {
        dispatch(getSingleInstance('route-sessions', routeSessionId))
            .then((value) => {
                let routeSessionSeals = value.payload.routeSession[0].seals;
                if (editData) {
                    routeSessionSeals = routeSessionSeals.filter((seal) => seal.barcode !== editData.barcode);
                }
                const submitModel = { seals: [...routeSessionSeals, model] };
                dispatch(editRouteSession(submitModel, routeSessionId))
                    .then((response) => {
                        // TODO once functional component use localization
                        dispatch(showMessage({ message: editData ? 'Successfully Edited Seal.' : 'Successfully Added Seal.' }));
                        history.goBack();
                    })
                    .catch((err) => {
                        dispatch(showMessage({ message: err.message }));
                    });
            })
            .catch((err) => {
                setError(err);
            });
    };

    const onSubmit = (model) => {
        const submitModel = model;
        submitModel.created_at = moment(selectedDate).format();
        submitModel.seal_type = selectedType.toString();
        submitModel.barcode = barcode.toString();
        if (files.length > 0) {
            const type = _.lowerCase(path.extname(files[0].name));
            if (type === 'jpg' || type === 'jpeg' || type === 'png') {
                const reader = new FileReader();
                reader.readAsDataURL(files[0]);
                reader.onloadend = () => {
                    dispatch(uploadImage(reader.result))
                        .then((response) => {
                            const imageLocation = response.imageInfo.key;
                            submitModel.slip_s3_location = imageLocation;
                            finishSubmit(submitModel);
                        })
                        .catch((err) => {
                            // console.warn('ERROR: ', err);
                            dispatch(showMessage({ message: 'There was a problem uploading your file. Please contact support.' }));
                        });
                };
            } else if (type === 'pdf') {
                const formData = new FormData();
                formData.append('file', files[0]);
                uploadFile(formData)
                    .then((response) => {
                        submitModel.slip_s3_location = response.data.key;
                        finishSubmit(submitModel);
                    })
                    .catch((err) => {
                        dispatch(showMessage({ message: 'There was a problem uploading your file. Please contact support.' }));
                    });
            } else {
                dispatch(showMessage({ message: 'Please provide a valid image or .pdf file.' }));
            }
        } else {
            finishSubmit(submitModel);
        }
    };

    const handleDateTimeChange = (date) => {
        setSelectedDate(date);
    };

    const updateCurrentSlip = (newFiles) => {
        setFiles(newFiles);
    };

    const handleTypeChange = (event) => {
        setSelectedType(event.target.value);
    };

    const updateBarcode = (event) => {
        setBarcode(event.target.value);
    };

    const render = () => {
        if (!editData && !selectedDate) {
            return <MMSplashScreen />;
        }

        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className={window.innerWidth > 600 ? 'w-3/4' : 'w-full'}>
                        <Divider />

                        <Formsy
                            onValidSubmit={onSubmit}
                            onValid={enableButton}
                            onInvalid={disableButton}
                            /* eslint-disable-next-line no-return-assign */
                            className="flex flex-col justify-center"
                        >
                            <div className="w-full my-16">
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DateTimePicker label="Date" value={selectedDate} onChange={handleDateTimeChange} validations={isDate} inputVariant="outlined" />
                                </MuiPickersUtilsProvider>
                            </div>

                            <div>
                                <FormControl component="fieldset">
                                    <Typography color="textSecondary">Seal Type</Typography>
                                    <RadioGroup aria-label="seal_type" name="seal_type" value={selectedType} required validations={'minLength:2'} onChange={(value) => handleTypeChange(value)}>
                                        {sealTypes.map((type) => (
                                            <FormControlLabel value={type.value} control={<Radio />} label={<Typography textSize="10">{type.name}</Typography>} fontSize="100p" />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </div>

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="barcode"
                                label="Barcode"
                                placeholder="Barcode"
                                value={barcode}
                                required
                                onChange={(value) => updateBarcode(value)}
                                validations="minLength:2"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <img className="ml-16" width="22" src="assets/MMAssets/icons/barcode.png" alt="logo" />
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />

                            {/* eslint-disable-next-line no-return-assign */}
                            <Typography className="my-16">
                                <div className="draggable">
                                    Seal Slip Picture: &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                    <Dropzone handleDrop={updateCurrentSlip} multiple />
                                    <div>
                                        {editData && editData.slip_s3_location ? (
                                            <img
                                                style={{
                                                    width: '300px',
                                                    height: '300px',
                                                    padding: 5,
                                                }}
                                                src={`${url}`}
                                                alt="No slip found"
                                            />
                                        ) : (
                                            ' '
                                        )}
                                    </div>
                                </div>
                            </Typography>

                            <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={editData ? 'EDIT SEAL' : 'ADD SEAL'} disabled={!canSubmit}>
                                {editData ? 'Edit Seal' : 'Add Seal'}
                            </Button>
                        </Formsy>
                    </div>
                )}
            </LocalizationConsumer>
        );
    };
    return <>{render()}</>;
};

export default withRouter(SealForm);
