import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import Papa from 'papaparse';
import { downloadCSV, getMonthDateRange } from '../../../utils';
import * as Actions from '../../store/actions';

class OutOfProvinceDropoffsDownloadButton extends React.Component {
    state = {
        loading: false,
        month: '',
        year: '',
    };

    componentDidMount() {
        this.fetch({ ...this.state, ...this.props });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedMonth, selectedYear } = this.props;

        if (prevProps.selectedMonth !== selectedMonth || prevProps.selectedYear !== selectedYear) {
            this.fetch({ ...this.state, ...this.props });
        }
    }

    fetch = ({ selectedMonth, selectedYear, getDropoffDetails }) => {
        const dates = getMonthDateRange(selectedYear, selectedMonth);

        this.setState({
            loading: true,
            month: selectedMonth,
            year: selectedYear,
        });
        getDropoffDetails(dates.start, dates.end).then(() => {
            const { month, year } = this.state;
            this.setState({ loading: !(month === selectedMonth && year === selectedYear) });
        });
    };

    downloadOutOfProvince = ({ dropoffDetails, selectedYear, selectedMonth }) => {
        const data = dropoffDetails.dropoffs
            .filter((value) => !value.deleted_at && value.processor && value.processor[0] && (value.processor[0].name === 'Truro' || value.processor[0].name === 'Sussex'))
            .map((value) => ({
                Date: moment(value.created_at).format('MMM Do, YY'),
                Time: moment(value.created_at).format('h:mm a'),
                BOL: value.route_session?.[0]?.BOL || '',
                'Run Number': value.run_number,
                Route: value.route_session[0] ? value.route_session[0].route : '',
                Processor: value.processor[0] ? value.processor[0].name : '',
                Metered: value.metered,
                Litres: value.volume,
            }));

        if (data.length) {
            downloadCSV(Papa.unparse(data), `${`Out_of_Province_Dropoffs_${moment(`${selectedYear.toString()} ${selectedMonth.toString()} 01`, 'YYYY MM DD').format('MMMM')}_${selectedYear}`}.csv`);
        }
    };

    render() {
        const { selectedMonth, selectedYear } = this.props;
        const { loading } = this.state;
        return (
            <Button type="submit" variant="contained" color="secondary" className="m-16 ml-16" aria-label="Download Out-of-Province" onClick={() => this.downloadOutOfProvince({ ...this.props, ...this.state })} disabled={loading}>
                Download Out-of-Province {`${moment(`${selectedYear.toString()} ${selectedMonth.toString()} 01`, 'YYYY MM DD').format('MMMM')}, ${selectedYear}`}
            </Button>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getDropoffDetails: Actions.getDropoffDetails }, dispatch);
}

function mapStateToProps({ mainReducer }) {
    return { dropoffDetails: mainReducer.dropoffDetails.data };
}

export default connect(mapStateToProps, mapDispatchToProps)(OutOfProvinceDropoffsDownloadButton);
