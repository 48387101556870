import axios from 'axios';
import _ from 'lodash';
import { batchQuery, getDriversQuery, getHaulingCompaniesQuery } from './queries';

const getDriverListDetails = (id, includeDeleted) =>
    new Promise((resolve, reject) => {
        const fields = ['created_at', 'deleted_at', 'email', 'hauling_id', 'id', 'name', 'updated_at', 'username', 'license_number', 'driver_number', 'permissions', 'expiry_date', 'inspection_date', 'hire_date', 'state_of_license'];
        const params = {
            fields,
            ...(!!id && { haulingId: id }),
            ...(includeDeleted !== undefined && { filterDeleted: !includeDeleted }),
        };
        const query = getDriversQuery(params);
        const request = axios.get(query);

        request
            .then((response) => {
                const drivers = response.data;
                const haulingIds = _.uniq(_.map(drivers, 'hauling_id'));

                const haulingCompanyQuery = batchQuery(
                    getHaulingCompaniesQuery,
                    {
                        filterDeleted: true,
                        fields: ['id', 'name'],
                        ids: haulingIds,
                    },
                    'ids'
                );
                const haulingCompanyRequest = _.map(haulingCompanyQuery, (q) => axios.get(q));

                axios.all(haulingCompanyRequest).then(
                    axios.spread((...responses) => {
                        const haulingCompanies = _.reduce(responses, (acc, val) => [...acc, ...val.data], []);
                        const driverResponse = _.orderBy(
                            _.map(drivers, (driver) => {
                                const hauler = _.find(haulingCompanies, { id: driver.hauling_id });
                                const haulingCompany = hauler && hauler.name ? hauler.name : '';
                                return { ...driver, hauling_company: haulingCompany };
                            }),
                            ['name'],
                            ['asc']
                        );
                        resolve({ drivers: driverResponse });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getDriverListDetails;
