import React from 'react';
import { DialogContent, DialogContentText, Button, Grid } from '@mui/material';
import DialogWrapper from '../../../../custom-widgets/dialogs/DialogWrapper';

const DeleteGeofenceActions = ({ onDelete, onClose }) => {
    return (
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button sx={{ margin: '8px' }} onClick={onClose} color="primary">
                Cancel
            </Button>
            <Button sx={{ margin: '8px' }} onClick={onDelete} variant="contained" color="secondary">
                Delete
            </Button>
        </Grid>
    );
};

const DeleteGeofenceDialog = ({ open, onClose, onDelete }) => {
    return (
        <DialogWrapper open={open} Title="Delete Geofence" hideClose={false} maxWidth="sm" handleClose={onClose} actions={<DeleteGeofenceActions onClose={onClose} onDelete={onDelete} />}>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete this Geofence?</DialogContentText>
            </DialogContent>
        </DialogWrapper>
    );
};

export default DeleteGeofenceDialog;
