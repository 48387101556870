import Region from '../../constants/region';

const localization = {
    general: {
        barcode: 'Sample Barcode',
        component_barcode: 'Component Barcode Value',
        customer_po: 'Load Number',
        daily_fluid_milk_quota: 'Quota',
        'dip/stick': 'Dip Stick',
        driver_singular: 'Driver',
        driver_plural: 'Drivers',
        driver: 'Driver',
        dropoff_weight: 'Producer Amount (lbs)',
        end_odometer: 'End Odometer',
        full_name: 'Contact Name',
        hauling_plural: 'Hauling Companies',
        hauling_singular: 'Hauling Company',
        hauling_delivery: 'Delivery Hauler',
        route_number: 'LT Number',
        license_number: 'License Number',
        lt_number: 'LT Number',
        manifest_number: 'Manifest Number',
        metered: 'Net Amount',
        meteredLevel: 'Net Amount',
        monthlyReports: 'Monthly Reports',
        pickup_amount: 'Producer Amount (lbs)',
        processor_singular: 'Plant',
        processor_plural: 'Plants',
        producer_singular: 'Producer Name',
        producer_weight: 'Producer Amount',
        region: Region.MMPA,
        routeNumber: 'Lt Number',
        route_name: 'Route',
        routeSession: 'Route Session',
        scale_weight: 'Scale/Metered Amount (lbs)',
        scale: 'Scale',
        start_odometer: 'Start Odometer',
        tank: 'Tank',
        trailer: 'Trailer',
        transfer: 'Quota',
        truck: 'Truck',
        volume: 'Dairy Amount',
        inhibitor: 'Inhibitor',
        merchandise: 'Merchandise',
    },
    navigation: {
        'Base Transfers': 'Quota Transfers',
        'Print Queue': 'Barcode Management',
    },
    lab: {
        aflatoxin: 'Aflatoxin',
        antibiotics: 'Antibiotics',
        butterfat: 'Butterfat',
        cfu: 'CFU',
        cryoscope: 'Cryo',
        fp: 'FDP',
        ibc: 'IBC',
        mun: 'MUN',
        os: 'OS',
        pi: 'PI',
        protein: 'Protein',
        rope: 'Rope',
        scc: 'SCC',
        sediment: 'Sediment',
        snf: 'SNF',
        spc: 'SPC',
        temperature: 'Temperature',
        tru_protein: 'Tru Protein',
        ts: 'TS',
        sample_date: 'Sample Pickup Date',
    },
    other: {},
};

export default localization;
