import CSVFileValidator from 'csv-file-validator';

export const submitCSV =
    (query, csvConfig, maxLength) =>
    async ({ file }) => {
        const { data, inValidData } = await CSVFileValidator(file[0], csvConfig);

        if (inValidData.length) {
            return {
                error: inValidData.map((err) => err.message),
            };
        }
        if (maxLength && data.length > maxLength) {
            return { error: `CSV file can have a maximum of ${maxLength} lines` };
        }

        try {
            const formData = new FormData();

            formData.append('file', file[0]);
            const returnData = await query(formData);

            return returnData;
        } catch (error) {
            return { error };
        }
    };

export const uniqueError = (header, row) => `${header} in row ${row} is not unique`;
export const validateError = (header, row) => `${header} in row ${row} is not valid`;
export const isStringInList = (value, list) => list.some((item) => item.name.toLowerCase() === value.toLowerCase());
export const arePropertiesInList = (value, list, properties) => list.some((item) => properties.some((property) => item[property]?.toLowerCase() === value.toLowerCase()));
// TODO: Update regex
export const isLTValid = (lt_number) => true;

export default submitCSV;
