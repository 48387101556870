import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import LinearProgress from '@material-ui/core/LinearProgress';
import { DataTable } from 'app/custom-widgets';
import getConfig from './config';

import { Paper, IconButton, Box, Collapse, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: '10px',
        margin: '15px 15px',
        padding: '15px 0px',
    },
    content: {
        margin: '0px 30px',
    },
}));

const DropoffStatistics = ({ data, region }) => {
    const [expanded, setExpanded] = useState(false);
    const classes = useStyles();
    const handleSetExpanded = () => {
        setExpanded(!expanded);
    };
    const config = getConfig(region);

    // Don't render anything if we don't have a config
    if (config.datatable.getColumns().length === 0) {
        return <>{}</>;
    }

    const render = () => {
        return (
            <Paper className="p-12 mx-12 sm:mx-20 mt-0 mb-0">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography className={classes.content} variant="h6">
                        Summary
                    </Typography>
                    {!data && (
                        <Box sx={{ width: '50%' }}>
                            <LinearProgress />
                        </Box>
                    )}
                    <Box className={classes.content} display="flex" alignItems="center">
                        <IconButton onClick={handleSetExpanded} disabled={!data}>
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                </Box>
                <Collapse in={expanded}>
                    <div className="flex flex-col md:flex-row sm:p-8 container">
                        <div className="flex flex-1 flex-col min-w-0 p-12">
                            <DataTable title={config.datatable.getTitle()} columns={config.datatable.getColumns()} data={config.datatable.getData(data)} options={config.datatable.getOptions()} />
                        </div>
                    </div>
                </Collapse>
            </Paper>
        );
    };

    return <>{render()}</>;
};

export default DropoffStatistics;
