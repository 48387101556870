import store from 'app/store';
import { FuseLoadable } from '@fuse';

export const Index = {
    auth: ['admin', 'sub-admin', 'transport', 'sub-transport', 'producer', 'sub-producer', 'producer_business'],
    routes: [
        {
            path: '/pickups/:date?',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                            return import('./raw-milk/admin/AdminPickupsApp');
                        case 'transport':
                        case 'sub-transport':
                            return import('./raw-milk/hauling/HaulingPickupsApp');
                        case 'producer':
                        case 'sub-producer':
                            return store.getState().persisted.auth.user.data.type_of_fluid === 'cream' ? import('./cream/CreamPickupsApp') : import('./raw-milk/producer/ProducerPickupsApp');
                        case 'producer_business':
                            return import('./raw-milk/producer-business/ProducerBusinessPickupsApp');
                        default:
                            break;
                    }
                },
            }),
        },
        {
            path: '/cream-pickups/:date?',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                        case 'transport':
                        case 'sub-transport':
                        case 'producer':
                        case 'sub-producer':
                            return import('./cream/CreamPickupsApp');
                        default:
                            break;
                    }
                },
            }),
        },
    ],
};

export default [Index];
