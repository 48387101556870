import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getScheduleItemsQuery, getHaulingCompaniesQuery, getProcessorsQuery, getRoutesQuery } from './queries';
import { getMonthDateRange } from '../../utils';

/**
 * Get all related information and creates an objected used to create a csv
 *
 * @param {string} month
 * @param {string} year
 * @returns {array} (array of csv data)
 */

const downloadScheduleItems = async (month, year, region) => {
    try {
        const dates = getMonthDateRange(year, month);
        const { start, end } = dates;

        const scheduleItemsQuery = getScheduleItemsQuery({
            start: moment(start).format('YYYY-MM-DD'),
            end: moment(end).format('YYYY-MM-DD'),
        });
        const scheduleItems = _.sortBy((await axios.get(scheduleItemsQuery)).data, 'date');

        const routesQuery = getRoutesQuery({ fields: ['id', 'name'], filterDeleted: true });
        const routes = (await axios.get(routesQuery)).data;
        const groupedRoutes = _.groupBy(routes, 'id');

        const processorQuery = getProcessorsQuery({ fields: ['id', 'name'], filterDeleted: true });
        const processors = (await axios.get(processorQuery)).data;
        const groupedProcessors = _.groupBy(processors, 'id');

        const haulersQuery = getHaulingCompaniesQuery({ fields: ['id', 'name'], filterDeleted: true });
        const haulers = (await axios.get(haulersQuery)).data;
        const groupedHaulers = _.groupBy(haulers, 'id');

        const sheet = [];

        _.map(scheduleItems, (item) => {
            const processor = groupedProcessors[item.processor_id] ? groupedProcessors[item.processor_id][0] : undefined;
            const hauler = groupedHaulers[item.hauling_id] ? groupedHaulers[item.hauling_id][0] : undefined;
            const route = groupedRoutes[item.route_id] ? groupedRoutes[item.route_id][0] : undefined;
            sheet.push({
                Date: moment(item.date).format('YYYY/MM/DD'),
                ...(region === 'BORDEN' && item.date && item.duration && { 'Appointment Time': moment(item.date).add(item.duration, 'milliseconds').format('h:mm A') }),
                ...(region === 'RF' && item.po && { Po: item.po }),
                ...(region === 'RF' && item.batch && { Batch: item.batch }),
                ...(region === 'BORDEN' && item.lt_number && { 'LT Number': item.lt_number }),
                Amount: item.volume,
                'Route Name': route?.name || '',
                Processor: processor?.name || '',
                Hauler: hauler?.name || '',
            });
        });

        return sheet;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default downloadScheduleItems;
