import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import * as FuseActions from 'app/store/actions';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import Papa from 'papaparse';
import { downloadCSV, getMonthDateRange } from '../../../utils';
import * as Actions from '../../store/actions';

class PickupsForProcessorDownloadButton extends React.Component {
    state = {
        loading: false,
        month: '',
        year: '',
    };

    componentDidMount() {
        this.fetch({ ...this.state, ...this.props });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { selectedMonth, selectedYear } = this.props;

        if (prevProps.selectedMonth !== selectedMonth || prevProps.selectedYear !== selectedYear) {
            this.fetch({ ...this.state, ...this.props });
        }
    }

    fetch = ({ selectedMonth, selectedYear, getPickupsProcessorDetails }) => {
        const dates = getMonthDateRange(selectedYear, selectedMonth);

        this.setState({
            loading: true,
            month: selectedMonth,
            year: selectedYear,
        });
        getPickupsProcessorDetails(dates.start, dates.end).then(() => {
            const { month, year } = this.state;
            this.setState({ loading: !(month === selectedMonth && year === selectedYear) });
        });
    };

    parsePickups = (pickups) => {
        let dataArray = {};

        const reversedPickups = pickups
            .filter((value) => !value.deleted_at)
            .map((value) => ({
                Processor: value.processor,
                BOL: value.route_session?.[0]?.BOL || '',
                'Run Number': value.run_number,
                Date: moment(value.created_at).format('DD-MMM'),
                ' ': value.producer[0] ? value.producer[0].name : '',
                Producer: value.producer[0] ? value.producer[0].CMTNumber : '',
                Litres: parseFloat(value.volume).toFixed(2),
                'BF KG/HL': parseFloat(value.fat).toFixed(2),
                '$/Amount Billed': 0,
                'Protein KG/HL': parseFloat(value.protein).toFixed(2),
                'LOS KG/HL': parseFloat(value.los).toFixed(2),
            }))
            .reverse();

        /* eslint-disable-next-line */
        for (const key in reversedPickups) {
            dataArray = { ...dataArray, [reversedPickups[key].Processor || 'Blank']: dataArray[reversedPickups[key].Processor || 'Blank'] ? [...dataArray[reversedPickups[key].Processor || 'Blank'], reversedPickups[key]] : [reversedPickups[key]] };
        }

        return dataArray;
    };

    downloadMultipleReports = ({ pickupProcessorDetails, selectedMonth, selectedYear }) => {
        const parsedData = this.parsePickups(pickupProcessorDetails.pickups);

        // TODO: Make this config driver or move this entire report to metabase
        const priceLookupTable = [
            { endDate: '2020-01-31', price: 1.2012 },
            { endDate: '2021-01-31', price: 1.2173 },
            { endDate: '2021-12-31', price: 1.2255 },
            { endDate: '2022-06-30', price: 1.2947 },
            { endDate: '2023-01-31', price: 1.3594 },
            { endDate: '2024-05-01', price: 1.3795 },
            { endDate: '9999-12-31', price: 1.3976 }, // To add a new price, simply duplicate the last line,
            // update the second the last object with the end date of the previous price and update the final
            // object with the current price
        ];

        const selectedDate = moment(`${selectedYear.toString()}-${selectedMonth.toString()}-01`);

        const litrePrice = priceLookupTable.find((entry) => selectedDate.isBefore(entry.endDate)).price;

        const butterfatPrice = 5.2;

        let litreTotal = 0;
        let billedTotal = 0;
        let bfTotal = 0;
        let proteinTotal = 0;
        let losTotal = 0;

        /* eslint-disable-next-line */
        for (const processor in parsedData) {
            let hasRunNumber = true;

            /* eslint-disable-next-line */
            for (const key in parsedData[processor]) {
                if (parsedData[processor][key].Processor !== 'Truro' && parsedData[processor][key].Processor !== 'Sussex') {
                    hasRunNumber = false;
                    delete parsedData[processor][key]['Run Number'];
                }

                delete parsedData[processor][key].Processor;

                const billed = parseFloat((parseFloat(parsedData[processor][key].Litres) * (litrePrice + (parseFloat(parsedData[processor][key]['BF KG/HL']) - 3.6) * (butterfatPrice / 100.0))).toFixed(6));
                const bfkg = parseFloat((parseFloat(parsedData[processor][key].Litres) * (parseFloat(parsedData[processor][key]['BF KG/HL']) / 100)).toFixed(6));
                const pkg = parseFloat((parseFloat(parsedData[processor][key].Litres) * (parseFloat(parsedData[processor][key]['Protein KG/HL']) / 100)).toFixed(6));
                const loskg = parseFloat((parseFloat(parsedData[processor][key].Litres) * (parseFloat(parsedData[processor][key]['LOS KG/HL']) / 100)).toFixed(6));

                parsedData[processor][key]['$/Amount Billed'] = billed;
                parsedData[processor][key]['BF KG'] = bfkg;
                parsedData[processor][key]['Protein KG'] = pkg;
                parsedData[processor][key]['LOS KG'] = loskg;

                litreTotal += parseFloat(parsedData[processor][key].Litres);
                billedTotal += billed;
                bfTotal += bfkg;
                proteinTotal += pkg;
                losTotal += loskg;
            }

            parsedData[processor].sort((a, b) => (a.Date > b.Date ? 1 : a.BOL > b.BOL ? 1 : -1));

            const _ = {
                1: '',
                [processor]: '',
                '': '',
                ' ': '',
                '  ': '',
                '   ': litreTotal,
                Litre: '',
                [litrePrice]: billedTotal,
                Butterfat: '',
                [butterfatPrice]: '',
                '    ': bfTotal,
                '     ': proteinTotal,
                '      ': losTotal,
            };

            if (!hasRunNumber) {
                delete _[''];
            }

            const head = Papa.unparse([_]);
            const body = Papa.unparse([...parsedData[processor]]);

            const combined = `${head}\n${body}`;

            downloadCSV(combined, `${`${processor}_${moment(`${selectedYear.toString()} ${selectedMonth.toString()} 01`, 'YYYY MM DD').format('MMMM')}_${selectedYear}`}.csv`);

            litreTotal = 0;
            billedTotal = 0;
            bfTotal = 0;
            proteinTotal = 0;
            losTotal = 0;
        }
    };

    render() {
        const { selectedMonth, selectedYear } = this.props;
        const { loading } = this.state;
        return (
            <Button type="submit" variant="contained" color="secondary" className="m-16 ml-16" aria-label="Download Selected Month" onClick={() => this.downloadMultipleReports({ ...this.props, ...this.state })} disabled={loading}>
                Download Pickup For Processor {`${moment(`${selectedYear.toString()} ${selectedMonth.toString()} 01`, 'YYYY MM DD').format('MMMM')}, ${selectedYear}`}
            </Button>
        );
    }
}

function mapStateToProps({ mainReducer }) {
    return { pickupProcessorDetails: mainReducer.pickupProcessorDetails.data };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: FuseActions.showMessage, getPickupsProcessorDetails: Actions.getPickupsProcessorDetails }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(PickupsForProcessorDownloadButton);
