import axios from 'axios';
import _, { countBy } from 'lodash';
import moment from 'moment-timezone';
import { batchQuery, getDriversQuery, getHaulingCompaniesQuery, getDropoffsQuery, getPickupsQuery, getProcessorsQuery, getProducersQuery, getRouteSessionsQuery, getRoutesQuery, getTrailersQuery } from './queries';
import { arrayToMap, getMonthDateRange } from '../../utils';
import { getPickupsByDropoffId } from './utils';

const getHaulerID = (hauler) => {
    if (!!hauler.transport_id) {
        return hauler.transport_id;
    }

    if (!!hauler.description) {
        const description = hauler.description.toLowerCase();
        if (!Number.isNaN(Number(description))) {
            return description;
        }

        if (description.includes('transport') || description.includes('id')) {
            return description.replace(/\D/g, '');
        }
    }
    return '';
};

const getADLPickupsInfo = (month, year) =>
    new Promise((resolve, reject) => {
        const { start, end } = getMonthDateRange(year, month);

        const dropsQuery = getDropoffsQuery({
            fields: ['id', 'created_at', 'route_session_id'],
            start,
            end,
            filterDeleted: true,
            filterType: 'milk',
        });
        const dropsRequest = axios.get(dropsQuery);

        dropsRequest
            .then((dropsResponse) => {
                const routeSessionIds = _.uniq(
                    _.map(
                        _.sortBy(dropsResponse.data, (v) => v.created_at),
                        'route_session_id'
                    )
                );

                const sessionQuery = batchQuery(
                    getRouteSessionsQuery,
                    {
                        fields: ['id', 'created_by', 'trailer_id', 'route_id', 'deleted_at'],
                        ids: routeSessionIds,
                        removeOrder: true,
                        filterDeleted: true,
                    },
                    'ids'
                );

                let loadNumber = parseInt(year.substring(2).concat(month)) * 10000;

                const sessionRequest = _.map(sessionQuery, (q) => axios.get(q));

                axios
                    .all(sessionRequest)
                    .then(
                        axios.spread((...sessionResponse) => {
                            const response = _.reduce(sessionResponse, (acc, val) => [...acc, ...val.data], []);
                            const trailerIds = _.uniq(_.map(response, 'trailer_id'));
                            const routeIds = _.uniq(_.map(response, 'route_id'));

                            const pickupsQuery = batchQuery(getPickupsQuery, { routeSessionIds, filterDeleted: true }, 'routeSessionIds');
                            const dropoffsQuery = batchQuery(getDropoffsQuery, { routeSessionIds, filterDeleted: true }, 'routeSessionIds');
                            const trailersQuery = batchQuery(getTrailersQuery, { ids: trailerIds }, 'ids');
                            const routesQuery = batchQuery(getRoutesQuery, { ids: routeIds }, 'ids');
                            const producerQuery = getProducersQuery({ fields: ['id', 'name', 'lab_number'], filterDeleted: true });
                            const processorsQuery = getProcessorsQuery({ filterDeleted: true, fields: ['id', 'name'] });

                            const pickupsRequest = _.map(pickupsQuery, (q) => axios.get(q));
                            const dropoffsRequest = _.map(dropoffsQuery, (q) => axios.get(q));
                            const trailersRequest = _.map(trailersQuery, (q) => axios.get(q));
                            const routesRequest = _.map(routesQuery, (q) => axios.get(q));
                            const producersRequest = axios.get(producerQuery);
                            const processorsRequest = axios.get(processorsQuery);

                            axios
                                .all([producersRequest, processorsRequest, ...pickupsRequest, ...dropoffsRequest, ...trailersRequest, ...routesRequest])
                                .then(
                                    axios.spread((...responses) => {
                                        let responseStart = 2;
                                        let responseEnd = 2;

                                        const updateValues = (nextValue) => {
                                            responseStart = responseEnd;
                                            responseEnd += nextValue;
                                        };

                                        updateValues(pickupsRequest.length);
                                        const pickupResponses = responses.slice(responseStart, responseEnd);
                                        updateValues(dropoffsRequest.length);
                                        const dropoffResponses = responses.slice(responseStart, responseEnd);
                                        updateValues(trailersRequest.length);
                                        const trailersResponses = responses.slice(responseStart, responseEnd);
                                        updateValues(routesRequest.length);
                                        const routesResponses = responses.slice(responseStart);

                                        const producers = responses[0].data;
                                        const producersMap = arrayToMap(producers, 'id');
                                        const processors = responses[1].data;
                                        const processorsMap = arrayToMap(processors, 'id');
                                        const pickups = _.reduce(pickupResponses, (acc, val) => [...acc, ...val.data], []);
                                        const dropoffs = _.reduce(dropoffResponses, (acc, val) => [...acc, ...val.data], []);
                                        const dropoffMap = arrayToMap(dropoffs, 'id');
                                        const trailers = _.reduce(trailersResponses, (acc, val) => [...acc, ...val.data], []);
                                        const routes = _.reduce(routesResponses, (acc, val) => [...acc, ...val.data], []);

                                        const pickupsGroupedByRouteSession = _.groupBy(pickups, 'route_session_id');
                                        const dropoffsGroupedByRouteSession = _.groupBy(dropoffs, 'route_session_id');

                                        const driverIds = _.uniq(_.map(pickups, 'driver_id'));
                                        const driversQuery = batchQuery(getDriversQuery, { ids: driverIds }, 'ids');
                                        const driversRequest = _.map(driversQuery, (q) => axios.get(q));

                                        axios.all([...driversRequest]).then(
                                            axios.spread((...driversResponses) => {
                                                const drivers = driversResponses[0].data;
                                                const driversMap = arrayToMap(drivers, 'id');

                                                const haulerIds = _.uniq(_.map(drivers, 'hauling_id'));
                                                const haulerQuery = batchQuery(getHaulingCompaniesQuery, { ids: haulerIds }, 'ids');
                                                const haulerRequest = _.map(haulerQuery, (q) => axios.get(q));

                                                axios.all([...haulerRequest]).then(
                                                    axios.spread((...haulerResponses) => {
                                                        const haulers = haulerResponses[0].data;
                                                        const haulersMap = arrayToMap(haulers, 'id');

                                                        const purity = _.find(processors, { name: 'Purity Dairy Limited' });
                                                        const purityId = !!purity ? purity.id : null;

                                                        const info = [];
                                                        _.forEach(routeSessionIds, (value) => {
                                                            let sessionPickups = pickupsGroupedByRouteSession[value];
                                                            let sessionDropoffs = dropoffsGroupedByRouteSession[value];

                                                            if (!!!sessionPickups || !!!sessionDropoffs) {
                                                                return;
                                                            }

                                                            sessionPickups = sessionPickups.sort((a, b) => (a.created_at < b.created_at ? -1 : b.created_at < a.created_at ? 1 : 0));
                                                            sessionDropoffs = sessionDropoffs.sort((a, b) => (a.created_at < b.created_at ? -1 : b.created_at < a.created_at ? 1 : 0));

                                                            // combine split pickups if the route does not include a purity dropoff
                                                            if (!!!_.find(sessionDropoffs, { processor_id: purityId })) {
                                                                sessionPickups = _.reduce(
                                                                    sessionPickups,
                                                                    (acc, pickup) => {
                                                                        const matchingSplitPickup = _.find(acc, {
                                                                            producer_id: pickup.producer_id,
                                                                            tank_number: pickup.tank_number,
                                                                            compartment: pickup.compartment,
                                                                        });

                                                                        if (!matchingSplitPickup) {
                                                                            acc.push(pickup);
                                                                        } else {
                                                                            acc[_.findIndex(acc, matchingSplitPickup)] = { ...matchingSplitPickup, volume: matchingSplitPickup.volume + pickup.volume };
                                                                        }

                                                                        return acc;
                                                                    },
                                                                    []
                                                                );
                                                            }

                                                            const pickupsByDropoffId = getPickupsByDropoffId(sessionDropoffs, sessionPickups);
                                                            const dropoffIdsByPickupId = {};
                                                            Object.entries(pickupsByDropoffId).forEach((entry) => {
                                                                const [dropoff, pickupsForDropoff] = entry;
                                                                pickupsForDropoff.forEach((pickup) => {
                                                                    dropoffIdsByPickupId[pickup.id] = dropoff;
                                                                });
                                                            });

                                                            _.forEach(sessionPickups, (pickup) => {
                                                                const routeSession = _.find(response, { id: pickup.route_session_id });
                                                                const producer = producersMap?.[pickup?.producer_id];
                                                                const driver = driversMap?.[pickup?.driver_id];
                                                                const hauler = haulersMap?.[driver?.hauling_id];
                                                                const dropoff = dropoffMap?.[dropoffIdsByPickupId?.[pickup?.id]] || sessionDropoffs[0];
                                                                // eslint-disable-next-line camelcase
                                                                const processor = processorsMap?.[dropoff?.processor_id];

                                                                if (!routeSession) {
                                                                    return;
                                                                }

                                                                const route = _.find(routes, { id: routeSession.route_id });
                                                                const trailer = _.find(trailers, { id: routeSession.trailer_id });

                                                                info.push({
                                                                    'Load Number': 'A'.concat(loadNumber),
                                                                    'Truck/Unit No.': !!trailer ? trailer.trailer_number : '',
                                                                    'Transport ID': !!hauler ? getHaulerID(hauler) : '',
                                                                    'Transporter Name': !!driver ? driver.name : '',
                                                                    Plant: processor?.name ? processor.name : '',
                                                                    Route: !!route ? route.name : '',
                                                                    MilkPickupDate: moment(pickup.created_at).format('YYYYMMDD'),
                                                                    MilkPickupTime: moment(pickup.created_at).format('HH:mm:ss'),
                                                                    'Producer ID': !!producer ? producer.lab_number : '',
                                                                    'Producer Name': !!producer ? producer.name : '',
                                                                    Quantity: Math.round(pickup.volume),
                                                                    UOM: 'LTR',
                                                                    'Dipstick Level': pickup.dip_level,
                                                                    'Tank Temp': pickup.temperature,
                                                                    DropoffDate: moment(dropoff.created_at).format('YYYYMMDD'),
                                                                    DropoffTime: moment(dropoff.created_at).format('HH:mm:ss'),
                                                                });

                                                                loadNumber += 1;
                                                            });
                                                        });

                                                        resolve(info);
                                                    })
                                                );
                                            })
                                        );
                                    })
                                )
                                .catch(() => {
                                    // console.log(e);
                                    reject(new Error('Unable to download report. Please contact support.'));
                                });
                        })
                    )
                    .catch(() => {
                        reject(new Error('Unable to download report. Please contact support.'));
                    });
            })
            .catch(() => {
                // console.log(e);
                reject(new Error('Unable to download report. Please contact support.'));
            });
    });

export default getADLPickupsInfo;
