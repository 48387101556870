import axios from 'axios';
import { getExpressAPIHost } from '../../utils';

const uploadAssignments = async (year, month, type, formData) => {
    try {
        const response = await axios.post(`${getExpressAPIHost()}/assignments/upload-assignments/${year}/${month}/${type}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        return response;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to upload file. Please contact support.');
    }
};
export default uploadAssignments;
