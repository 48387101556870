import * as Actions from '../../actions';

const initialState = { data: null };

const sessionDetailsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_SESSION_DETAILS:
            return { ...state, data: { ...action.payload } };

        default:
            return state;
    }
};

export default sessionDetailsReducer;
