import store from 'app/store';
import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

export const MainConfig = {
    auth: authRoles.producer,
    routes: [
        {
            exact: true,
            path: '/penalty-status/:date?',
            component: FuseLoadable({ loader: () => import('./PenaltyStatusApp') }),
        },
    ],
};

export const ViewConfig = {
    auth: authRoles.producer,
    routes: [
        {
            path: '/penalty-status/view/:date?',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.data.region) {
                        case 'RF':
                            return import('./RFPenaltyStatusViewApp');
                        case 'NL':
                            return import('./NLPenaltyStatusViewApp');
                        case 'PEI':
                            return import('./PeiPenaltyStatusViewApp');
                        case 'CDI':
                            return import('./CDIPenaltyStatusViewApp');
                        default:
                            break;
                    }
                },
            }),
        },
    ],
};

export default [MainConfig, ViewConfig];
