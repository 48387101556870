import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { DialogContentText } from '@material-ui/core';
import { uploadPooledPickups } from '../../repository';
import { showMessage } from 'app/store/actions';
import { useDispatch } from 'react-redux';

function PaperComponent(props) {
    return (
        // <Draggable>
        <Paper {...props} />
        // </Draggable>
    );
}

const PooledPickupsDialog = ({ showLoadingDialog, hideLoadingDialog, selectedMonth, selectedYear }) => {
    const fileInput = React.createRef();
    const [open, setOpen] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const dispatch = useDispatch();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleEnableButton = () => {
        setEnableButton(true);
    };

    const handleUpload = async () => {
        const obj = { file: fileInput.current.files[0] };

        if (showLoadingDialog) {
            showLoadingDialog();
        }

        if (obj.file) {
            if (obj.file.name.slice(-4) !== 'xlsx') {
                dispatch(showMessage({ message: 'Please provide a valid .xlsx file.' }));
                if (hideLoadingDialog) {
                    hideLoadingDialog();
                }
                return;
            }

            const formData = new FormData();

            formData.append('file', obj.file);

            try {
                await uploadPooledPickups(formData, selectedMonth, selectedYear);
                dispatch(showMessage({ message: 'Successfully uploaded your Pooled Pickups file.' }));
                if (hideLoadingDialog) {
                    hideLoadingDialog();
                }
            } catch (err) {
                dispatch(showMessage({ message: 'There was a problem uploading your file. Please contact support.' }));
                if (hideLoadingDialog) {
                    hideLoadingDialog();
                }
            }
            setOpen(false);
        }
    };

    return (
        <div className="inline-flex">
            <Button type="submit" variant="contained" color="secondary" className="m-4 ml-4" aria-label="Pay Calcs" onClick={handleClickOpen}>
                Pooled Pickups
            </Button>
            <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
                <DialogTitle id="draggable-dialog-title">Upload File</DialogTitle>
                <DialogContent>
                    <DialogContentText className="text-black">
                        Upload File &nbsp;&nbsp;&nbsp; <input type="file" id="pdfFile" ref={fileInput} onChange={handleEnableButton} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpload} variant="contained" color="secondary" disabled={!enableButton}>
                        Upload
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withRouter(PooledPickupsDialog);
