import React from 'react';
import { Grid } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';

const MMPAMarketAdjustment = () => (
    <Grid item xs={3}>
        <ReactHookFormInput label="Market Adjustment" name={'milk_prices.market_adjustment'} type="number" />
    </Grid>
);

export default MMPAMarketAdjustment;
