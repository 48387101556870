import axios from 'axios';
import jwtDecode from 'jwt-decode';
import FuseUtils from '@fuse/FuseUtils';
import { bootInitialCustomIntercomLauncher, getAPIHost, getRegion } from '../../../utils';
import _ from 'lodash';
import { signOut, getCurrentSession } from '@milkmoovement/common_cognito_package';

class JWTService extends FuseUtils.EventEmitter {
    init() {
        this.setInterceptors();
        this.handleAuthentication();
    }

    setInterceptors = () => {
        axios.interceptors.request.use(async (config) => {
            try {
                const session = await getCurrentSession();
                const jwt = session.getIdToken().getJwtToken();
                // eslint-disable-next-line no-param-reassign
                config.headers.Authorization = `Bearer ${jwt}`;
                // eslint-disable-next-line no-param-reassign
                config.headers.customer = getRegion(window.location.hostname);
                return config;
            } catch (e) {
                if (e.message === 'No current user') {
                    // This occurs post manual logout typically and we don't want to display a message
                    this.emit('onAutoLogout', null);
                } else {
                    this.emit('onAutoLogout', 'Token expired');
                }
                return {
                    ...config,
                    cancelToken: new axios.CancelToken((cancel) => cancel('User unauthenticated')),
                };
            }
        });
        axios.interceptors.response.use(
            (response) => response,
            (err) =>
                new Promise((resolve, reject) => {
                    if (err?.toJSON?.().message === 'Network Error' && !navigator.onLine) {
                        this.emit('onAutoLogout', 'Check your internet connection');
                    }
                    /* eslint-disable-next-line */
                    if (err.response && err.response.status === 401 && err.config && !err.config.__isRetryRequest) {
                        // if you ever get an unauthorized response, logout the user
                        if (sessionStorage.getItem('areWeLocked') !== 'locked') {
                            this.emit('onAutoLogout', 'User credentials are not correct');
                        }

                        this.setSession(null);
                    }
                    throw err;
                })
        );
    };

    handleAuthentication = async () => {
        try {
            const session = await getCurrentSession();
            const accessToken = session.getIdToken().getJwtToken();

            if (!accessToken) {
                return;
            }

            if (this.isAuthTokenValid(accessToken)) {
                this.setSession(accessToken);
                this.emit('onAutoLogin', true);
            }
            // eslint-disable-next-line no-empty
        } catch {}
    };

    signInWithCognitoSession = async () => {
        const session = await getCurrentSession();
        this.setSession(session.getIdToken().getJwtToken());
        return this.fetchUserData();
    };

    setSession = (accessToken) => {
        if (accessToken) {
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            axios.defaults.headers.customer = getRegion(window.location.hostname);
            axios.defaults.crossDomain = true;
            const loginas = sessionStorage.getItem('selected_email');
            if (loginas) {
                axios.defaults.headers.common.loginas = loginas;
            } else {
                delete axios.defaults.headers.common.loginas;
            }
        } else {
            delete axios.defaults.headers.common.Authorization;
            delete axios.defaults.headers.common.loginas;
        }
    };

    logout = async () => {
        this.setSession(null);
        const isLocked = sessionStorage.getItem('areWeLocked') === 'locked';
        try {
            if (!isLocked) {
                await signOut();
            }
        } finally {
            sessionStorage.clear();
            window.Intercom('shutdown');
            bootInitialCustomIntercomLauncher();
        }
    };

    lock = async () => {
        const clearUsername = false;
        await signOut(clearUsername);
    };

    isAuthTokenValid = (accessToken) => {
        if (!accessToken) {
            return false;
        }
        const decoded = jwtDecode(accessToken);
        const currentTime = Date.now() / 1000;
        return decoded.exp >= currentTime;
    };

    fetchUserData = async () => {
        const response = await axios.get(`${getAPIHost()}/users/me`);
        const { data } = response;
        const { role } = data;
        if (role === 'admin') {
            sessionStorage.setItem('isAdmin', 'true');
        } else if (role === 'driver' || role === 'receiver') {
            this.setSession(null);
            const error = new Error(`${_.capitalize(role)}s please login through the driver handheld.`);
            error.name = 'RoleError';
            throw error;
        }
        return {
            role,
            data,
        };
    };

    signInAsImpersonatedUser = async (id) => {
        const { data } = await axios.get(`${getAPIHost()}/users/${id}`);
        const { email, role } = data;
        axios.defaults.headers.common.loginas = email;
        sessionStorage.setItem('selected_user', id);
        sessionStorage.setItem('selected_name', data.name);
        sessionStorage.setItem('selected_email', email);
        return { role, data };
    };

    revertImpersonatedLogin = async () => {
        delete axios.defaults.headers.common.loginas;
        const { data } = await axios.get(`${getAPIHost()}/users/me`);
        const { role } = data;
        if (role === 'admin') {
            sessionStorage.setItem('isAdmin', 'true');
        }
        sessionStorage.removeItem('isAdmin');
        sessionStorage.removeItem('selected_user');
        sessionStorage.removeItem('selected_name');
        sessionStorage.removeItem('selected_email');
        return { role, data };
    };
}

// eslint-disable-next-line new-cap
const instance = new JWTService();

export default instance;
