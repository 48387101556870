import React from 'react';
import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import _ from 'lodash';
import TabPanel from '../layout/TabPanel';
import MilkPriceFormDatePicker from './MilkPriceFormDatePicker';
import { regionFederalOrderConfig } from './MilkPriceFormConfig';
import lightGreyBgColour from './MilkPriceFormStyles';
import { toTitleCase } from '../../../../utils';

const MilkPriceFormFederalOrderTabs = ({ control, selectedTab, setSelectedTab, startDate, region, reactHookFormMethods }) => {
    const tabLabels = regionFederalOrderConfig[region].map(({ federalOrder }) => `Federal Order ${toTitleCase(federalOrder, '_')}`);
    return (
        <>
            <Box style={{ width: 'calc(100% + 24px)', marginLeft: '-12px', ...lightGreyBgColour }}>
                <Tabs onChange={(_event, value) => setSelectedTab(value)} style={{ paddingBottom: '10px', paddingTop: '10px', marginLeft: '12px', marginRight: '12px' }} value={selectedTab}>
                    {tabLabels.map((tabLabel) => (
                        <Tab key={tabLabel} label={tabLabel} />
                    ))}
                </Tabs>
            </Box>
            {regionFederalOrderConfig[region].map(({ SettlementMilkPrices, SettlementPayGroupAdjustment, AdditionalSettlementFields, AdvanceMilkPrices, federalOrder, payGroups, overrideFieldNames, excludePayGroupAdjustment }, tabIndex) => (
                <TabPanel key={`${federalOrder}`} value={selectedTab} index={tabIndex}>
                    <Grid container spacing={3} style={{ display: 'flex', flexDirection: 'row' }}>
                        <Grid item xs={12} style={lightGreyBgColour}>
                            <Typography variant="h5">Monthly Advance</Typography>
                        </Grid>
                        <MilkPriceFormDatePicker control={control} label="Advance Date" name={overrideFieldNames?.['advance_milk_prices.payment_date_advance'] ?? 'advance_milk_prices.payment_date_advance'} startDate={startDate} style={lightGreyBgColour} />
                        <AdvanceMilkPrices overrideFieldNames={overrideFieldNames} />
                        <Grid item xs={12}>
                            <Typography variant="h5">Monthly Settlement</Typography>
                        </Grid>
                        <MilkPriceFormDatePicker control={control} label="Settlement Date" name={overrideFieldNames?.['milk_prices.payment_date_settlement'] ?? 'milk_prices.payment_date_settlement'} startDate={startDate} />
                        <SettlementMilkPrices federalOrder={federalOrder} />
                        {AdditionalSettlementFields != null && <AdditionalSettlementFields />}
                        {!_.isEmpty(payGroups) &&
                            SettlementPayGroupAdjustment != null &&
                            payGroups
                                .filter((payGroup) => !excludePayGroupAdjustment?.includes(payGroup))
                                .map((payGroup) => {
                                    return <SettlementPayGroupAdjustment key={payGroup} federalOrder={federalOrder} payGroup={payGroup} watch={reactHookFormMethods.watch} setValue={reactHookFormMethods.setValue} />;
                                })}
                    </Grid>
                </TabPanel>
            ))}
        </>
    );
};

export default MilkPriceFormFederalOrderTabs;
