import React from 'react';
import { Marker, OverlayView } from '@react-google-maps/api';
import TransferDetails from './helper-components/TransferDetails';
import { toAmountString } from '../../schedule2/utils';

const MapPointPickup = ({ index, shape, color = '#00A2F5' }) => {
    const { center } = shape;

    const circleIcon = {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: color,
        fillOpacity: 1,
        scale: 10,
        strokeWeight: 0,
    };

    const getPixelPositionOffset = (width, height) => ({
        x: -(width / 2),
        y: -height, // Adjust the value to position the bottom center of the SVG
    });

    return (
        <>
            <Marker
                key={shape._id}
                position={center}
                icon={circleIcon}
                // wrap
            />
            <OverlayView key={index} position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} getPixelPositionOffset={getPixelPositionOffset}>
                <TransferDetails name={shape.name} volume={shape.volume} date={shape.date} />
            </OverlayView>
        </>
    );
};

export default React.memo(MapPointPickup);
