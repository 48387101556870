import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { ReportType } from '../constants/report';
import { getPayReportsQuery } from './queries';

const getProducerStatementsForProducerBusiness = async (start, end, region, producerBusinessId) => {
    const startDate = moment(start).startOf('month').format();
    const endDate = moment(end).endOf('month').format();

    const reportRequest = await axios.get(getPayReportsQuery({ startDate, endDate, producerBusinessId, region }));
    const reports = reportRequest.data ?? {};
    let { settlement, advance } = reports;
    settlement = settlement?.filter((report) => report.approved_for_producer_settlement)?.map((report) => ({ ...report, type: ReportType.settlement })) ?? [];
    advance = advance?.filter((report) => report.approved_for_producer_advance)?.map((report) => ({ ...report, type: ReportType.advance })) ?? [];
    return {
        data: settlement.concat(advance)?.sort((a, b) => a.producer_number.localeCompare(b.producer_number)),
    };
};

export default getProducerStatementsForProducerBusiness;
