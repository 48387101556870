import React from 'react';
import Typography from '@material-ui/core/Typography';
import FieldLocalizer from './utils/FieldLocalizer';

const DocumentHistoryCollapseEventHeader = ({ event, user, count }) => {
    if (event === 'update') {
        return (
            <Typography style={{ marginTop: 'auto', marginBottom: 'auto' }} variant="p">
                <b>{count} Fields Updated</b> by <b>{user}</b>
            </Typography>
        );
    }

    if (['lock', 'unlock', 'delete', 'restore'].includes(event)) {
        return (
            <Typography style={{ marginTop: 'auto', marginBottom: 'auto' }} variant="p">
                <b>{FieldLocalizer(event)}</b> by <b>{user}</b>
            </Typography>
        );
    }

    return (
        <Typography style={{ marginTop: 'auto', marginBottom: 'auto' }} variant="p">
            <b>Created</b> by <b>{user}</b>
        </Typography>
    );
};

export default DocumentHistoryCollapseEventHeader;
