const commodities = {
    cream: 'Cream',
    condensed_skim: 'Condensed Skim',
};

const config = {
    commodities,
};

export default config;
