import axios from 'axios';
import { getAPIHost } from '../../utils';

const downloadPickupSlips = (month, year) =>
    new Promise((resolve, reject) => {
        const request = axios.get(`${getAPIHost()}/download-pickup-slips/${year}/${month}`, { responseType: 'arraybuffer' });
        request
            .then((response) => {
                resolve(response.data);
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to download pickup slips'));
            });
    });

export default downloadPickupSlips;
