import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';
import { ThemeProvider } from '@mui/material/styles';
import { withRouter } from 'react-router-dom';
import oldTheme, { theme } from '../../../styles/mm-theme-fuse';

function FuseTheme({ children }) {
    return (
        <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={oldTheme}>{children}</MuiThemeProvider>
        </ThemeProvider>
    );
}

export default withRouter(FuseTheme);
