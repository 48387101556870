import axios from 'axios';
import _ from 'lodash';
import { createWashFacilityQuery } from './queries';

const addWashFacility = (model) =>
    new Promise((resolve, reject) => {
        const query = createWashFacilityQuery({ model });
        const request = axios.post(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to add wash facility'));
            });
    });

export default addWashFacility;
