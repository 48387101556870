import axios from 'axios';
import { editScheduleSupplyItemQuery } from './queries';
import { buildExpressQuery } from './queries/builders';

const editScheduleSupplyItem = async (model, key, recur) => {
    try {
        const query = editScheduleSupplyItemQuery({ key, model, recur });

        const request = axios.put(query.url, { ...query.body });
        const { data } = await request;
        return { ids: data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to edit schedule item');
    }
};

export default editScheduleSupplyItem;
