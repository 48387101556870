import React from 'react';
import { Grid } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';
import lightGreyBgColour from './MilkPriceFormStyles';

const AdvanceBasePriceWithOrganicMilkPrice = () => (
    <>
        <Grid item xs={12} sm={3} style={lightGreyBgColour}>
            <ReactHookFormInput fullWidth label="Base Price" name={'advance_milk_prices.base_price'} type="number" />
        </Grid>
        <Grid item xs={12} sm={3} style={lightGreyBgColour}>
            <ReactHookFormInput fullWidth label="Organic Milk Price" name={'advance_milk_prices.organic_milk_price'} type="number" />
        </Grid>
        <Grid item xs={12} sm={6} style={lightGreyBgColour}></Grid>
    </>
);

export default AdvanceBasePriceWithOrganicMilkPrice;
