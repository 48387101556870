import axios from 'axios';
import generateErrorMessage from './utils/generateErrorMessage';
import { createPayReportPaymentStatusQuery } from './queries';

const addPayReportPaymentStatus = async (model) => {
    try {
        const query = createPayReportPaymentStatusQuery({ model });
        const { data } = await axios.post(query.url, { ...query.body });
        return { data };
    } catch (err) {
        const formattedError = generateErrorMessage(err);
        if (formattedError?.message?.includes('PaymentStatusError')) {
            const [, messageToDisplay] = formattedError.message.split(/PaymentStatusError: /);
            formattedError.message = messageToDisplay;
        } else {
            formattedError.message = 'Unable to process request.';
        }
        throw formattedError;
    }
};

export default addPayReportPaymentStatus;
