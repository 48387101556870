import styled from 'styled-components';

export const StyledLink = styled.a`
    min-width: 120px;
    background-color: white;
    color: black !important;
    font-size: 14px;
    padding: 12px;

    &:hover {
        background-color: rgb(242, 242, 242);
        text-decoration: none !important;
    }
`;

export const StyledButton = styled.button`
    min-width: 120px;
    background: white;
    color: black !important;
    font-size: 14px;
    padding: 12px;
    display: flex;
    margin-right: ${(props) => (props.marginRight ? '10px' : '0px')};
    border-bottom-left-radius: ${(props) => (props.addBorderRadius ? '10px' : '0px')};

    &:hover {
        background-color: rgb(242, 242, 242);
    }
`;

export const StyledIconButton = styled.button`
    margin-top: 18px;
`;

export const HelpfulLinksContainer = styled.div`
    position: relative;
    display: flex;
    direction: column;
`;

export const HelpfulLinksDropDown = styled.div`
    position: absolute;
    z-index: 1;
    top: 45px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    box-shadow: -10px 15px 22px -8px;
`;

export const BasisTheoryWrapper = styled.div`
    padding: 11px 15px;
    border-radius: 2px;
    border: 1px solid rgba(0, 0, 0, 0.42);
    box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08);

    &:hover {
        border: 1px solid #000;
    }
`;
