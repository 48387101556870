import axios from 'axios';
import Split from '../../Split';
import { resolveId } from '../../utils';
import { editPickupQuery } from './queries';
import { buildExpressQuery } from './queries/builders';

const editPickup = (model, key) =>
    new Promise((resolve, reject) => {
        const useExpressEndpoint = Split.getBooleanTreatment('use_express_pickup_endpoint');

        const query = editPickupQuery({ key, model, ...(useExpressEndpoint && { queryBuilder: buildExpressQuery }) });
        const requestMethod = useExpressEndpoint ? axios.put : axios.patch;

        const request = requestMethod(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: resolveId(response.data) });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to edit pickup'));
            });
    });

export default editPickup;
