import axios from 'axios';
import { getProducerBusinessPickUpsQuery } from './queries';

const getProducerBusinessPickupDetails = (producerBusinessId, start, end) =>
    new Promise((resolve, reject) => {
        const pickupsQuery = getProducerBusinessPickUpsQuery({ producerBusinessId, start, end });
        const pickupsRequest = axios.get(pickupsQuery);

        pickupsRequest
            .then(({ data }) => {
                resolve({ pickups: data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getProducerBusinessPickupDetails;
