import * as Actions from '../../actions';

const initialState = { data: null };

const listAdminReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ADMIN_LIST_DETAILS:
            return { ...state, data: { ...action.payload } };
        default:
            return state;
    }
};

export default listAdminReducer;
