import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import SaveProgress from '../../custom-components/SaveProgress';

const ConfirmCustomTableResetDialog = ({ mode, onClose, onSubmit, saving, isAdmin }) => {
    const useStyles = makeStyles(() => ({
        deleteButton: {
            color: 'white',
            background: '#E26365',
        },
    }));
    const classes = useStyles();

    return (
        <Dialog open fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
            <DialogTitle id="draggable-dialog-title">Reset the table configuration{!isAdmin ? '' : mode === 'me' ? '' : ' for all users'}?</DialogTitle>
            <DialogActions style={{ margin: 16 }}>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => onSubmit(mode)} variant="contained" className={classes.deleteButton} disabled={saving}>
                    Confirm and Reset
                    {saving && <SaveProgress />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmCustomTableResetDialog;
