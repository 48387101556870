import addUser from './addUser';

const addProducer = (model) =>
    addUser({
        ...model,
        role: 'producer',
        subuser: false,
    });

export default addProducer;
