import store from 'app/store';
import authRoles from 'app/auth/authRoles';
import { FuseLoadable } from '@fuse';

const Index = {
    auth: authRoles.everyoneButGuest,
    routes: [
        {
            path: '/dashboard/:date?',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                            return import('./AdminDashboardApp');
                        case 'transport':
                        case 'sub-transport':
                            return import('./HaulingDashboardApp');
                        case 'processor':
                        case 'sub-processor':
                            return import('./ProcessorDashboardApp');
                        case 'producer':
                        case 'sub-producer':
                            return store.getState().persisted.auth.user.data.type_of_fluid === 'cream' ? import('../pickups/cream/CreamPickupsApp') : import('./ProducerDashboardApp');
                        case 'producer_business':
                            return import('./ProducerBusinessDashboardApp');
                        default:
                            break;
                    }
                },
            }),
        },
    ],
};

export default [Index];
