import axios from 'axios';
import { getHaulerAssignmentQuery } from './queries';

const getHaulerAssignmentListDetails = async (month, year) => {
    try {
        const haulerAssgnmentQuery = getHaulerAssignmentQuery({
            month,
            year,
        });
        const haulerAssignmentResponse = await axios.get(haulerAssgnmentQuery);
        const haulerAssignments = haulerAssignmentResponse.data;
        return { haulerAssignments };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getHaulerAssignmentListDetails;
