import axios from 'axios';
import { listGeofencesQuery } from './queries';

const listGeofences = async ({ type, ids, filterDeleted = true }) => {
    try {
        const query = listGeofencesQuery({ type, ids, filterDeleted });
        const { data } = await axios.get(query);

        return data;
    } catch (error) {
        return { error };
    }
};

export default listGeofences;
