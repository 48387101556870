import React, { useEffect, useState } from 'react';
import { Marker, OverlayView } from '@react-google-maps/api';

const defaultMarkerLabelStyle = {
    background: '#203254',
    padding: '7px 12px',
    fontSize: '11px',
    color: 'white',
    borderRadius: '4px',
};

export default function MapMarker({ shape, selectedShape, labelStyle = defaultMarkerLabelStyle }) {
    const [isSelected, setIsSelected] = useState(false);

    useEffect(() => {
        setIsSelected(shape.id === selectedShape);
    }, [selectedShape]);

    return (
        <>
            <Marker
                key={shape.id}
                position={shape.position}
                editable={isSelected}
                draggable={isSelected} //
            />
            {shape.label && (
                <OverlayView key="mwl" position={shape.position} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}>
                    <div style={labelStyle}>{shape.label}</div>
                </OverlayView>
            )}
        </>
    );
}
