import React from 'react';
import { InfoWindow } from '@react-google-maps/api';
import moment from 'moment-timezone';

/**
 * MapInfoWindow does not need to leverage instances that are managed by the Map component
 *
 * @param {*} shape
 * @param {*} onClose
 * @returns
 */
const MapInfoWindow = ({ shape, onClose }) => {
    const handleInfoWindowClose = () => {
        onClose();
    };

    return (
        <InfoWindow
            position={{
                lat: shape.location.coordinates[1],
                lng: shape.location.coordinates[0],
            }}
            onCloseClick={handleInfoWindowClose}
            // wrap
        >
            {/* TODO: Create some sort of config that outputs info based on some param */}
            <h3>{moment(shape.created_at).format('MMMM Do YYYY, h:mm:ss a')}</h3>
        </InfoWindow>
    );
};

export default React.memo(MapInfoWindow);
