import * as yup from 'yup';
import { isValidPhoneNumber } from 'libphonenumber-js';

const nullableStringValidation = yup
    .string()
    .nullable()
    .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value));

const phoneNumberValidation = (errMessage = 'Phone Number is not valid') => yup.string().test('phone-validation', errMessage, (v) => !v || v === '+' || isValidPhoneNumber(v));

const producerValidationSchema = yup.object({
    email: yup.string().email('Not a valid email format').required('Email is required'),
    password: yup.string().min(8, 'Minimum 8 characters'),
    password_confirmation: yup.string().oneOf([yup.ref('password')], 'Passwords do not match'),
    hauling_type_stop_limit: yup
        .number()
        .integer('Must be a valid number.')
        .typeError('Must be a valid number.')
        .min(0, 'Min value is 0.')
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    license_number: yup
        .string()
        .typeError('Must be a valid number.')
        .min(0, 'Min value is 0.')
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
        .required('Producer ID is required'),
    name: yup
        .string()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
        .typeError('Must be a valid name.')
        .required('Producer Name is required'),
    username: yup
        .string()
        .min(4, 'Min length is 4')
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
        .typeError('Must be a valid username.')
        .required('Username is required'),
    type_of_fluid: yup.object().optional().nullable(),
    milk_category: yup.object().optional().nullable(),
    status: yup.object({}).required('Status is a required field').typeError('Status is a required field'),
    state_number: nullableStringValidation,
    gl_number: nullableStringValidation,
    deferral_years: yup
        .number()
        .integer('Must be a valid number.')
        .typeError('Must be a valid number.')
        .min(0, 'Min value is 0.')
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    geofence: yup
        .number()
        .integer('Must be a valid number.')
        .typeError('Must be a valid number.')
        .min(0, 'Min value is 0.')
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    numTanks: yup
        .number()
        .integer('Must be a valid number.')
        .typeError('Must be a valid number.')
        .min(0, 'Min value is 0.')
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    district_id: nullableStringValidation,
    county_id: yup.lazy((value) => (typeof value === 'string' ? yup.string().optional().nullable() : yup.object().optional().nullable())),
    permit_number: yup
        .string()
        .typeError('Must be a valid number.')
        .min(0, 'Min value is 0.')
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    federal_order: yup.object().optional().nullable(),
    territory: nullableStringValidation,
    lab_region: yup.object().optional().nullable(),
    pay_group: nullableStringValidation,
    temp_address: nullableStringValidation,
    nutritionist: nullableStringValidation,
    tax_id: nullableStringValidation,
    pay_to_name: nullableStringValidation,
    award_name: nullableStringValidation,
    phone: phoneNumberValidation(),
    barn_phone: phoneNumberValidation(),
    fax_number: phoneNumberValidation('Fax Number is not valid'),
    state_county_code: yup
        .string()
        .typeError('Must be a valid number.')
        .min(0, 'Min value is 0.')
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    market_admin_id: yup
        .string()
        .typeError('Must be a valid number.')
        .min(0, 'Min value is 0.')
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
});

export default producerValidationSchema;
