import _ from 'lodash';
import axios from 'axios';
import { buildExpressBulkQuery } from '../queries/builders';
import { renameKey } from '../../../utils';

/**
 * Replacement for batch queries.
 *
 * Abstracts request > response process
 *
 * @param {*} queryFn
 * @param {*} params
 */
const getBulkQueryData = async (queryFn, params) => {
    const newParams = params;
    newParams.queryBuilder = buildExpressBulkQuery;
    const { url, body } = queryFn(newParams);
    const response = await axios.post(url, body);
    renameKey(response.data, '_id', 'id');
    return response.data;
};

export default getBulkQueryData;
