import { fuseDark } from '@fuse/fuse-colors';
import lightBlue from '@material-ui/core/colors/lightBlue';
import red from '@material-ui/core/colors/red';
import { createTheme } from '@material-ui/core';
import { createTheme as muiCreateTheme, getContrastRatio } from '@mui/material/styles';
import MM_COLORS from './colors';
import MMThemeChip from './mm-theme-extras/MMThemeChip';

const CONTRAST_RATIO = 2;

const themeObject = {
    palette: {
        type: 'light',
        primary: fuseDark,
        secondary: {
            veryLight: lightBlue[100],
            light: lightBlue[400],
            main: lightBlue[600],
            dark: lightBlue[700],
        },
        error: red,
        ...MM_COLORS,
    },
    typography: {
        htmlFontSize: 10,
        fontFamily: ['Open-Sans', 'Muli', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        suppressDeprecationWarnings: true,
    },
    status: { danger: 'orange' },
};

export const mui5ThemeObject = {
    palette: {
        type: 'light',
        background: {
            light: '#FAFAFA',
            main: '#F7F8FB',
            dark: '#EEF0F3',
        },
        primary: {
            light: '#59BFF3',
            main: '#00A2F5',
            dark: '#0071AB',
            contrastText: getContrastRatio('#00A2F5', '#fff') >= CONTRAST_RATIO ? '#fff' : '#000',
        },
        secondary: {
            light: '#636774',
            main: '#3C4252',
            dark: '#2A2E39',
            extraDark: '#1B2330',
            contrastText: getContrastRatio('#3C4252', '#fff') >= CONTRAST_RATIO ? '#fff' : '#000',
        },
        info: {
            light: '#59BFF3',
            main: '#00BCD4',
            dark: '#008394',
            contrastText: getContrastRatio('#00BCD4', '#fff') >= CONTRAST_RATIO ? '#fff' : '#000',
        },
        success: {
            light: '#81C784',
            main: '#40B481',
            dark: '#2C7D5A',
            contrastText: getContrastRatio('#40B481', '#fff') >= CONTRAST_RATIO ? '#fff' : '#000',
        },
        yellow: {
            light: '#ffeb3b',
            main: '#f2ce0c',
            dark: '#caa600',
            // TODO: Decide if this should be black
            contrastText: getContrastRatio('#f2ce0c', '#fff') >= CONTRAST_RATIO ? '#fff' : '#000',
        },
        pink: {
            light: '#ff91dc',
            main: '#FF69B4',
            dark: '#d7418c',
            contrastText: getContrastRatio('#FF69B4', '#fff') >= CONTRAST_RATIO ? '#fff' : '#000',
        },
        purple: {
            light: '#bb98ff',
            main: '#9370DB',
            dark: '#6b48b3',
            contrastText: getContrastRatio('#9370DB', '#fff') >= CONTRAST_RATIO ? '#fff' : '#000',
        },

        warning: {
            light: '#F7C77F',
            main: '#FFA351',
            dark: '#C17936',
            contrastText: getContrastRatio('#FFA351', '#fff') >= CONTRAST_RATIO ? '#fff' : '#000',
        },
        error: {
            light: '#F88078',
            main: '#E26365',
            dark: '#9E4546',
            contrastText: getContrastRatio('#E26365', '#fff') >= CONTRAST_RATIO ? '#fff' : '#000',
        },
        grey: {
            light: '#F5F5F5',
            main: '#E0E0E0',
            dark: '#757575',
            contrastText: '#000',
        },
        ...MM_COLORS,
    },
    typography: {
        htmlFontSize: 10,
        fontFamily: ['Open-Sans', 'Muli', 'Roboto', '"Helvetica"', 'Arial', 'sans-serif'].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        suppressDeprecationWarnings: true,
        h1: {
            fontWeight: 600,
            fontSize: '3.6rem',
            lineHeight: 1.36,
        },
        h2: {
            fontWeight: 600,
            fontSize: '3rem',
            lineHeight: 1.36,
        },
        h3: {
            fontSize: '2.6rem',
            fontWeight: 600,
            lineHeight: 1.34,
        },
        h4: {
            fontWeight: 600,
            lineHeight: 1.34,
            fontSize: '2.2rem',
        },
        h5: {
            lineHeight: 1.2,
            fontWeight: 600,
            fontSize: '2rem',
        },
        h6: {
            fontSize: '1.8rem',
            fontWeight: 600,
            lineHeight: 1.2,
        },
        body1: {
            fontSize: '1.6rem',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        body2: {
            fontSize: '1.4rem',
            fontWeight: 400,
            lineHeight: 1.5,
        },
        subtitle1: {
            fontSize: '1.4rem',
            fontWeight: 400,
            lineHeight: 1.2,
        },
        caption: {
            fontSize: '1.3rem',
            fontWeight: 400,
            lineHeight: 1.2,
        },
        overline: {
            fontSize: '1.3rem',
            fontWeight: 400,
            lineHeight: 1.2,
        },
    },
    status: { danger: 'orange' },
    components: {
        MuiButton: {
            variants: [
                {
                    props: { size: 'large' },
                    style: {
                        fontSize: '1.6rem',
                        lineHeight: 1.19,
                        fontWeight: 600,
                    },
                },
            ],
        },
        MuiAlert: {
            variants: [
                {
                    props: { variant: 'filled' },
                    style: {
                        color: '#fff',
                    },
                },
            ],
        },
        MuiChip: MMThemeChip,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
};

export const theme = muiCreateTheme(mui5ThemeObject);

export default createTheme(themeObject);
