import axios from 'axios';
import { copyScheduleSupplyItemsQuery } from './queries';
import { buildExpressQuery } from './queries/builders';

const copyScheduleSupplyItems = async (model) => {
    try {
        const query = copyScheduleSupplyItemsQuery({ model, queryBuilder: buildExpressQuery, endpoint: '/schedule-supply-item/copy' });
        const request = axios.post(query.url, { ...query.body });
        const { data } = await request;
        return { items: data };
    } catch {
        throw new Error('Unable to copy schedule items');
    }
};

export default copyScheduleSupplyItems;
