import axios from 'axios';
import _ from 'lodash';
import { getMultisiteAccessQuery, getUsersQuery, getProducersQuery, getHaulingCompaniesQuery, getProcessorsQuery, getProducerBusinessesQuery } from './queries';
import { buildLoopbackQuery } from './queries/builders';

const getAccessList = (id, role) =>
    new Promise((resolve, reject) => {
        if (role === 'admin') {
            const fields = ['id', 'name', 'role', 'license_number', 'hauler_number'];
            const producersQuery = getProducersQuery({ filterDeleted: true, fields });
            const processorsQuery = getProcessorsQuery({ filterDeleted: true, fields });
            const haulingCompaniesQuery = getHaulingCompaniesQuery({ filterDeleted: true, fields });
            const producerBusinessQuery = getProducerBusinessesQuery({ filterDeleted: true, fields, queryBuilder: buildLoopbackQuery });

            const producersRequest = axios.get(producersQuery);
            const processorsRequest = axios.get(processorsQuery);
            const haulingCompaniesRequest = axios.get(haulingCompaniesQuery);
            const producerBusinessRequest = axios.get(producerBusinessQuery);

            const requests = [producersRequest, processorsRequest, haulingCompaniesRequest, producerBusinessRequest];

            axios
                .all(requests)
                .then(
                    axios.spread((...responses) => {
                        const [producersResponse, processorsResponse, haulingCompaniesResponse, producerBusinessResponse] = responses;
                        const users = _.sortBy([...producersResponse.data, ...processorsResponse.data, ...haulingCompaniesResponse.data, ...producerBusinessResponse.data], 'name');
                        const data = _.map(users, (user) => ({
                            id: user.id,
                            label: user.name,
                            type: user.role,
                            license_number: user.license_number,
                            hauler_number: user.hauler_number,
                        }));
                        resolve({ accesses: data });
                    })
                )
                .catch((errors) => {
                    // eslint-disable-next-line no-console
                    console.log(errors);
                    reject(new Error('User credentials are not correct'));
                });
        } else {
            const accessQuery = getMultisiteAccessQuery({ id });
            const accessRequest = axios.get(accessQuery);

            accessRequest
                .then((accessResponse) => {
                    const accesses = accessResponse.data;
                    const accessIds = _.uniq(_.flatten(_.map(accesses, 'access_id')));

                    const userQuery = getUsersQuery({ ids: accessIds, fields: ['id', 'name', 'role', 'license_number'] });
                    const userRequest = axios.get(userQuery);

                    userRequest.then((userResponse) => {
                        const users = userResponse.data;
                        const data = _.map(users, (user) => ({
                            id: user.id,
                            label: user.name,
                            type: user.role,
                            license_number: user.license_number,
                        }));
                        resolve({ accesses: data });
                    });
                })
                .catch((errors) => {
                    // eslint-disable-next-line no-console
                    console.log(errors);
                    reject(new Error('User credentials are not correct'));
                });
        }
    });

export default getAccessList;
