/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { getId, renameKey } from '../../utils';
import { getScheduleFluidItemQuery } from './queries';
import moment from 'moment-timezone';

const getScheduleFluidDemandItemDetails = async (date, user) => {
    try {
        const start = moment(date).startOf('day').utc();
        const end = moment(date).endOf('day').add(7, 'days').utc();

        const { role } = user;

        if (role === 'transport') return [];

        const inboundParams = {
            start,
            end,
            ...(role === 'processor' && { processorId: getId() }),
            status: 'demand',
            populate: {
                hauling_id: ['name'],
            },
            filterByEndDate: true,
        };
        const inboundItemsQuery = getScheduleFluidItemQuery(inboundParams);
        const inboundItemsRequest = axios.get(inboundItemsQuery);

        const outboundParams = {
            start,
            end,
            ...(role === 'processor' && { supplyProcessorId: getId() }),
            status: 'demand',
            populate: {
                hauling_id: ['name'],
            },
            filterByEndDate: false,
        };
        const outboundItemsQuery = getScheduleFluidItemQuery(outboundParams);
        const outboundItemsRequest = axios.get(outboundItemsQuery);

        const [inboundItemsResponse, outboundItemsResponse] = await Promise.all([inboundItemsRequest, outboundItemsRequest]);
        const inboundItems = inboundItemsResponse.data.map((d) => ({ ...d, inbound: true }));
        const outboundItems = outboundItemsResponse.data.map((d) => ({ ...d, outbound: true }));
        const data = inboundItems.concat(outboundItems);

        renameKey(data, '_id', 'id');
        data.forEach((d) => {
            // eslint-disable-next-line
            d.haulingName = d?.hauling_id?.name || 'Unclaimed';
            // eslint-disable-next-line
            d.hauling_id = d?.hauling_id?._id || '';
        });

        return data;
    } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getScheduleFluidDemandItemDetails;
