import store from 'app/store';
import { FuseLoadable } from '@fuse';

export const QuotaLeasingConfig = {
    auth: ['admin', 'sub-admin', 'producer', 'sub-producer'],
    routes: [
        {
            exact: true,
            path: '/quota/lease',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                            return import('./AdminQuotaLeasingApp');
                        case 'producer':
                        case 'sub-producer':
                            return import('./ProducerQuotaLeasingApp');
                        default:
                            break;
                    }
                },
            }),
        },
    ],
};

export const NewLeaseConfig = {
    auth: ['admin', 'sub-admin', 'producer', 'sub-producer'],
    routes: [
        {
            exact: true,
            path: '/quota/lease/new',
            component: FuseLoadable({ loader: () => import('./NewLeaseApp') }),
        },
    ],
};

export default [QuotaLeasingConfig, NewLeaseConfig];
