import addUser from './addUser';

const addSubAdmin = (model) =>
    addUser({
        ...model,
        role: 'admin',
        subuser: true,
    });

export default addSubAdmin;
