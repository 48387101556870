import React from 'react';
import { connect } from 'react-redux';
import { withStyles, FormLabel, FormControl, FormGroup, FormControlLabel, FormHelperText, Checkbox } from '@material-ui/core';
import { MANIFEST_REGIONS, SCHEDULING_REGIONS, POOLING_REGIONS, TCJ_REGIONS, ACCOUNTING_REGIONS, IN_PROGRESS_RAPID_TEST_REGIONS, NEW_SYSTEM_PERMISSION_REGIONS, SILO_REGIONS } from '../constants/bulkRegion';
import Region from '../constants/region';
import { getRegion, getId } from '../../utils';
import { SCHREIBER_PROCESSOR_ID } from './PermissionsSelector/config/constants';

const styles = (theme) => ({ root: { display: 'flex' }, formControl: { margin: theme.spacing(3) } });

/**
 * @deprecated
 * Use PermissionsSelector instead
 */
class CheckboxesGroup extends React.Component {
    state = {
        allChecked: {
            checked: false,
            label: 'Select All',
            value: null,
        },
    };

    componentDidMount() {
        const { permissionType } = this.props;
        this.setState({ ...this.getPermissions(permissionType) });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { handlePermissionChange, selectedValues, permissionType } = this.props;

        if (JSON.stringify(prevState) !== JSON.stringify(this.state)) {
            handlePermissionChange(
                Object.values(this.state)
                    .filter((value) => !!value.value && !!value.checked)
                    .map((value) => value.value)
            );
        }

        if (prevProps.selectedValues !== selectedValues) {
            this.setState({ ...this.getPermissions(permissionType) });
        }
    }

    handleChange = (name) => (event) => {
        if (name === 'QARapidTest' && event.target.checked) {
            // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
            this.setState({ ManageRapidTest: { ...this.state.ManageRapidTest, checked: event.target.checked }, QARapidTest: { ...this.state.QARapidTest, checked: event.target.checked } });
            return;
        }
        if (name === 'ManageRapidTest' && this.state.QARapidTest?.checked) {
            return;
        }
        // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
        this.setState({ [name]: { ...this.state[name], checked: event.target.checked } });
    };

    handleAllChange = (bool) => (event) => {
        // eslint-disable-next-line react/destructuring-assignment, react/no-access-state-in-setstate
        Object.keys(this.state).map((value) => this.setState({ [value]: { ...this.state[value], checked: event.target.checked } }));
    };

    getPermissions = (type) => {
        const { selectedValues, region } = this.props;
        // TODO use localization context when this component is functional component
        const routeSession = region !== Region.RF ? 'Route Session' : 'Load';
        const monthlyReport = region !== Region.RF ? 'Monthly Report' : 'Payment Report';
        const rapidTestRegion = getRegion(window.location.hostname) === Region.US_DEMO ? Region.US_DEMO : region;
        const isSchreiber = getId() === SCHREIBER_PROCESSOR_ID;

        switch (type) {
            case 'Sub-Admin': {
                return {
                    GetDashboardDetails: {
                        checked: selectedValues ? selectedValues.includes('GetDashboardDetails') : false,
                        label: 'Manage Dashboard (View)',
                        value: 'GetDashboardDetails',
                    },
                    ListAdmins: {
                        checked: selectedValues ? selectedValues.includes('ListAdmins') : false,
                        label: 'Manage Admins (View, Create, Update, Delete)',
                        value: 'ListAdmins',
                    },
                    ListProducers: {
                        checked: selectedValues ? selectedValues.includes('ListProducers') : false,
                        label: 'Manage Producers (View, Create, Update, Delete)',
                        value: 'ListProducers',
                    },
                    ListProcessors: {
                        checked: selectedValues ? selectedValues.includes('ListProcessors') : false,
                        label: region === Region.UDA ? 'Manage Handlers (View, Create, Update, Delete)' : 'Manage Processors (View, Create, Update, Delete)',
                        value: 'ListProcessors',
                    },
                    ListTrailers: {
                        checked: selectedValues ? selectedValues.includes('ListTrailers') : false,
                        label: 'Manage Trailers (View, Create, Update, Delete)',
                        value: 'ListTrailers',
                    },
                    ListTrucks: {
                        checked: selectedValues ? selectedValues.includes('ListTrucks') : false,
                        label: 'Manage Trucks (View, Create, Update, Delete)',
                        value: 'ListTrucks',
                    },
                    ListHaulingCompanies: {
                        checked: selectedValues ? selectedValues.includes('ListHaulingCompanies') : false,
                        label: region === Region.UDA ? 'Manage Carriers (View, Create, Update, Delete)' : 'Manage Hauling Companies (View, Create, Update, Delete)',
                        value: 'ListHaulingCompanies',
                    },
                    ListDrivers: {
                        checked: selectedValues ? selectedValues.includes('ListDrivers') : false,
                        label: 'Manage Drivers (View, Create, Update, Delete)',
                        value: 'ListDrivers',
                    },
                    ...([Region.NL, Region.PEI, Region.RF].includes(region)
                        ? {
                              ListRoutes: {
                                  checked: selectedValues ? selectedValues.includes('ListRoutes') : false,
                                  label: 'Manage Routes (View, Create, Update, Delete)',
                                  value: 'ListRoutes',
                              },
                          }
                        : {
                              ListScheduleRoutes: {
                                  checked: selectedValues ? selectedValues.includes('ListScheduleRoutes') : false,
                                  label: 'Manage Routes (View, Create, Update, Delete)',
                                  value: 'ListScheduleRoutes',
                              },
                          }),
                    ListRouteSessions: {
                        checked: selectedValues ? selectedValues.includes('ListRouteSessions') : false,
                        label: `Manage ${routeSession}s (View, Create, Update, Delete)`,
                        value: 'ListRouteSessions',
                    },
                    ListDropoffs: {
                        checked: selectedValues ? selectedValues.includes('ListDropoffs') : false,
                        label: 'Manage Dropoffs (View, Create, Update, Delete)',
                        value: 'ListDropoffs',
                    },
                    ListPickups: {
                        checked: selectedValues ? selectedValues.includes('ListPickups') : false,
                        label: 'Manage Pickups (View, Create, Update, Delete)',
                        value: 'ListPickups',
                    },
                    ListLabReports: {
                        checked: selectedValues ? selectedValues.includes('ListLabReports') : false,
                        label: 'Manage Lab Reports (View, Create, Update, Delete)',
                        value: 'ListLabReports',
                    },
                    ListBulkCharts: {
                        checked: selectedValues ? selectedValues.includes('ListBulkCharts') : false,
                        label: 'Manage Bulk Charts (View, Create, Update, Delete)',
                        value: 'ListBulkCharts',
                    },
                    ListSubAdmins: {
                        checked: selectedValues ? selectedValues.includes('ListSubAdmins') : false,
                        label: 'Manage Sub Users (View, Create, Update, Delete)',
                        value: 'ListSubAdmins',
                    },
                    ListMonthlyReports: {
                        checked: selectedValues ? selectedValues.includes('ListMonthlyReports') : false,
                        label: `Manage ${monthlyReport}s (View, Delete)`,
                        value: 'ListMonthlyReports',
                    },
                    ManageEquity: {
                        checked: selectedValues ? selectedValues.includes('ManageEquity') : false,
                        label: 'Manage Equity Features (View, Create, Update, Delete)',
                        value: 'ManageEquity',
                    },
                    ...(ACCOUNTING_REGIONS.includes(region) && {
                        ManageAccounting: {
                            checked: selectedValues ? selectedValues.includes('ManageAccounting') : false,
                            label: 'Manage Accounting Features (View, Create, Update, Delete)',
                            value: 'ManageAccounting',
                        },
                    }),
                    ...(SILO_REGIONS.includes(region) && {
                        ListSilos: {
                            checked: selectedValues ? selectedValues.includes('ListSilos') : false,
                            label: 'Manage Silos (View, Create, Update, Delete)',
                            value: 'ListSilos',
                        },
                    }),
                    ListCIPWash: {
                        checked: selectedValues ? selectedValues.includes('ListCIPWash') : false,
                        label: 'Manage CIP Wash (View, Create, Update, Delete)',
                        value: 'ListCIPWash',
                    },
                    ...(POOLING_REGIONS.includes(region) && {
                        ListPooling: {
                            checked: selectedValues ? selectedValues.includes('ListPooling') : false,
                            label: 'Manage Pooling (View, Create, Update, Delete)',
                            value: 'ListPooling',
                        },
                    }),
                    ...(NEW_SYSTEM_PERMISSION_REGIONS.includes(region) && {
                        OverrideQualityStatus: {
                            checked: selectedValues ? selectedValues.includes('OverrideQualityStatus') : false,
                            label: 'Manage Quality Status (View, Create, Update, Delete)',
                            value: 'OverrideQualityStatus',
                        },
                    }),
                    ...(NEW_SYSTEM_PERMISSION_REGIONS.includes(region) && {
                        ListInvoicing: {
                            checked: selectedValues ? selectedValues.includes('ListInvoicing') : false,
                            label: 'Manage Invoicing (View, Create, Update, Delete)',
                            value: 'ListInvoicing',
                        },
                    }),
                    ...(![Region.MMPA, Region.UNC, ...TCJ_REGIONS].includes(region) && {
                        ListBaseTransfers: {
                            checked: selectedValues ? selectedValues.includes('ListBaseTransfers') : false,
                            label: `Manage ${region === Region.UDA ? 'Base' : 'Quota'} Transfers (View, Create, Update, Delete)`,
                            value: 'ListBaseTransfers',
                        },
                    }),
                    ...([Region.PEI, Region.NL].includes(region) && {
                        ViewPickupsForProcessor: {
                            checked: selectedValues ? selectedValues.includes('ViewPickupsForProcessor') : false,
                            label: 'Manage Pickups for Processors (View)',
                            value: 'ViewPickupsForProcessor',
                        },
                    }),
                    ...(![Region.CDI, ...NEW_SYSTEM_PERMISSION_REGIONS].includes(region) && {
                        ListBactoAlert: {
                            checked: selectedValues ? selectedValues.includes('ListBactoAlert') : false,
                            label: 'Manage Penalty Status (View)',
                            value: 'ListBactoAlert',
                        },
                    }),
                    ...(MANIFEST_REGIONS.includes(region) && {
                        ListManifests: {
                            checked: selectedValues ? selectedValues.includes('ListManifests') : false,
                            label: 'Manage Manifest View (View, Create, Update, Delete)',
                            value: 'ListManifests',
                        },
                    }),
                    ...(SCHEDULING_REGIONS.includes(region) && {
                        ListUserGroups: {
                            checked: selectedValues ? selectedValues.includes('ListUserGroups') : false,
                            label: 'Manage User Groups (View, Create, Update, Delete)',
                            value: 'ListUserGroups',
                        },
                    }),
                    ...(SCHEDULING_REGIONS.includes(region) && {
                        GetMilkScheduleDetails: {
                            checked: selectedValues ? selectedValues.includes('GetMilkScheduleDetails') : false,
                            label: 'Manage Milk Schedule (View, Create, Update, Delete)',
                            value: 'GetMilkScheduleDetails',
                        },
                    }),
                    ...(SCHEDULING_REGIONS.includes(region) && {
                        GetFluidScheduleDetails: {
                            checked: selectedValues ? selectedValues.includes('GetFluidScheduleDetails') : false,
                            label: 'Manage Processed Milk Schedule (View, Create, Update, Delete)',
                            value: 'GetFluidScheduleDetails',
                        },
                    }),
                    ListSharedFiles: {
                        checked: selectedValues ? selectedValues.includes('ListSharedFiles') : false,
                        label: 'Manage Shared Files (View, Create, Update, Delete)',
                        value: 'ListSharedFiles',
                    },
                    ...([...NEW_SYSTEM_PERMISSION_REGIONS, Region.PRAIRIE].includes(region) && {
                        ManageClosePeriod: {
                            checked: selectedValues ? selectedValues.includes('ManageClosePeriod') : false,
                            label: 'Manage Close Period (View, Open, Close)',
                            value: 'ManageClosePeriod',
                        },
                    }),
                    ...([Region.CDI, Region.LEGACY].includes(region) && {
                        ManageCloseDay: {
                            checked: selectedValues ? selectedValues.includes('ManageCloseDay') : false,
                            label: 'Manage Close Days (View, Open, Close)',
                            value: 'ManageCloseDay',
                        },
                    }),
                    ...([Region.UDA].includes(region) && {
                        ManageCloseMonth: {
                            checked: selectedValues ? selectedValues.includes('ManageCloseMonth') : false,
                            label: 'Manage Close Month (View, Open, Close)',
                            value: 'ManageCloseMonth',
                        },
                    }),
                    ViewDriverLocations: {
                        checked: selectedValues ? selectedValues.includes('ViewDriverLocations') : false,
                        label: 'View Driver Locations',
                        value: 'ViewDriverLocations',
                    },
                    UploadDownload: {
                        checked: selectedValues ? selectedValues.includes('UploadDownload') : false,
                        label: 'View Upload / Download',
                        value: 'UploadDownload',
                    },
                    ...(IN_PROGRESS_RAPID_TEST_REGIONS.includes(rapidTestRegion) && {
                        ManageRapidTest: {
                            checked: selectedValues ? selectedValues.includes('ManageRapidTest') : false,
                            label: 'Manage Rapid Test',
                            value: 'ManageRapidTest',
                        },
                        QARapidTest: {
                            checked: selectedValues ? selectedValues.includes('QARapidTest') : false,
                            label: 'QA Rapid Test',
                            value: 'QARapidTest',
                        },
                    }),
                    ...(NEW_SYSTEM_PERMISSION_REGIONS.includes(region) && {
                        ManageMerchandise: {
                            checked: selectedValues ? selectedValues.includes('ManageMerchandise') : false,
                            label: 'Manage Merchandise',
                            value: 'ManageMerchandise',
                        },
                        ManageProducerAssignments: {
                            checked: selectedValues ? selectedValues.includes('ManageProducerAssignments') : false,
                            label: 'Manage Producer Assignments',
                            value: 'ManageProducerAssignments',
                        },
                        ManageHaulerAssignments: {
                            checked: selectedValues ? selectedValues.includes('ManageHaulerAssignments') : false,
                            label: 'Manage Hauler Assignments',
                            value: 'ManageHaulerAssignments',
                        },
                        ManageAssignees: {
                            checked: selectedValues ? selectedValues.includes('ManageAssignees') : false,
                            label: 'Manage Assignees',
                            value: 'ManageAssignees',
                        },
                        ManageMilkPrices: {
                            checked: selectedValues ? selectedValues.includes('ManageMilkPrices') : false,
                            label: 'Manage Milk Prices',
                            value: 'ManageMilkPrices',
                        },
                        ManageDeferrals: {
                            checked: selectedValues ? selectedValues.includes('ManageDeferrals') : false,
                            label: 'Manage Deferrals',
                            value: 'ManageDeferrals',
                        },
                        ManageSplitProducers: {
                            checked: selectedValues ? selectedValues.includes('') : false,
                            label: 'Manage Split Producers',
                            value: 'ManageSplitProducers',
                        },
                        ManageHaulingCharges: {
                            checked: selectedValues ? selectedValues.includes('ManageHaulingCharges') : false,
                            label: 'Manage Hauling Charges',
                            value: 'ManageHaulingCharges',
                        },
                        ManageHaulingContracts: {
                            checked: selectedValues ? selectedValues.includes('ManageHaulingContracts') : false,
                            label: 'Manage Hauling Contracts',
                            value: 'ManageHaulingContracts',
                        },
                        ManageHaulerRates: {
                            checked: selectedValues ? selectedValues.includes('ManageHaulerRates') : false,
                            label: 'Manage Hauler Rates',
                            value: 'ManageHaulerRates',
                        },
                        ManageProducerPayReports: {
                            checked: selectedValues ? selectedValues.includes('ManageProducerPayReports') : false,
                            label: 'Manage Producer Pay Reports',
                            value: 'ManageProducerPayReports',
                        },
                        ManageHaulerPayReports: {
                            checked: selectedValues ? selectedValues.includes('ManageHaulerPayReports') : false,
                            label: 'Manage Hauler Pay Reports',
                            value: 'ManageHaulerPayReports',
                        },
                        ManageVendorPayReports: {
                            checked: selectedValues ? selectedValues.includes('ManageVendorPayReports') : false,
                            label: 'Manage Vendor Pay Reports',
                            value: 'ManageVendorPayReports',
                        },
                        ManagePaymentStatus: {
                            checked: selectedValues ? selectedValues.includes('ManagePaymentStatus') : false,
                            label: 'Manage Payment Status',
                            value: 'ManagePaymentStatus',
                        },
                        ...([Region.UDA].includes(region) &&
                            isSchreiber && {
                                EditSiloInvoices: {
                                    checked: selectedValues ? selectedValues.includes('EditSiloInvoices') : false,
                                    label: 'Edit Silo Invoices',
                                    value: 'EditSiloInvoices',
                                },
                            }),
                    }),
                };
            }
            case 'Sub-Producer': {
                return {
                    GetDashboardDetails: {
                        checked: selectedValues ? selectedValues.includes('GetDashboardDetails') : false,
                        label: 'View Dashboard',
                        value: 'GetDashboardDetails',
                    },
                    ListPickups: {
                        checked: selectedValues ? selectedValues.includes('ListPickups') : false,
                        label: 'View Pickups',
                        value: 'ListPickups',
                    },
                    ListLabReports: {
                        checked: selectedValues ? selectedValues.includes('ListLabReports') : false,
                        label: 'View Lab Reports',
                        value: 'ListLabReports',
                    },
                    ListMonthlyReports: {
                        checked: selectedValues ? selectedValues.includes('ListMonthlyReports') : false,
                        label: `View ${monthlyReport}s`,
                        value: 'ListMonthlyReports',
                    },
                    ListBactoAlert: {
                        checked: selectedValues ? selectedValues.includes('ListBactoAlert') : false,
                        label: 'View Penalty Status',
                        value: 'ListBactoAlert',
                    },
                    ...([Region.UDA, Region.CDI, Region.PRAIRIE].includes(region) && {
                        UploadDownload: {
                            checked: selectedValues ? selectedValues.includes('UploadDownload') : false,
                            label: 'View Upload / Download',
                            value: 'UploadDownload',
                        },
                    }),
                    ListSharedFiles: {
                        checked: selectedValues ? selectedValues.includes('ListSharedFiles') : false,
                        label: 'Manage Shared Files (View, Create, Update, Delete)',
                        value: 'ListSharedFiles',
                    },
                    ListPickupsAndLabs: {
                        checked: selectedValues ? selectedValues.includes('ListPickupsAndLabs') : false,
                        label: 'View Pickups And Labs',
                        value: 'ListPickupsAndLabs',
                    },
                    ListSubProducers: {
                        checked: selectedValues ? selectedValues.includes('ListSubProducers') : false,
                        label: 'Manage Sub Producers (View)',
                        value: 'ListSubProducers',
                    },
                    ...(region === Region.CDI && {
                        ListCIPWash: {
                            checked: selectedValues ? selectedValues.includes('ListCIPWash') : false,
                            label: 'Manage CIP Wash (View, Create, Update, Delete)',
                            value: 'ListCIPWash',
                        },
                    }),
                };
            }
            case 'Sub-Processor': {
                return {
                    GetDashboardDetails: {
                        checked: selectedValues ? selectedValues.includes('GetDashboardDetails') : false,
                        label: 'View Dashboard',
                        value: 'GetDashboardDetails',
                    },
                    ListDropoffs: {
                        checked: selectedValues ? selectedValues.includes('ListDropoffs') : false,
                        label: 'View Dropoffs',
                        value: 'ListDropoffs',
                    },
                    ...([Region.PRAIRIE].includes(region) && {
                        UploadDownload: {
                            checked: selectedValues ? selectedValues.includes('UploadDownload') : false,
                            label: 'View Upload / Download',
                            value: 'UploadDownload',
                        },
                    }),
                    ListRouteSessions: {
                        checked: selectedValues ? selectedValues.includes('ListRouteSessions') : false,
                        label: `View ${routeSession}s`,
                        value: 'ListRouteSessions',
                    },
                    ListSharedFiles: {
                        checked: selectedValues ? selectedValues.includes('ListSharedFiles') : false,
                        label: 'Manage Shared Files (View, Create, Update, Delete)',
                        value: 'ListSharedFiles',
                    },
                    ListSubProcessors: {
                        checked: selectedValues ? selectedValues.includes('ListSubProcessors') : false,
                        label: region === Region.UDA ? 'Manage Sub Handlers (View, Create, Update, Delete)' : 'Manage Sub Processors (View, Create, Update, Delete)',
                        value: 'ListSubProcessors',
                    },
                    ListReceivers: {
                        checked: selectedValues ? selectedValues.includes('ListReceivers') : false,
                        label: 'Manage Receivers (View, Create, Update, Delete)',
                        value: 'ListReceivers',
                    },
                    ...(NEW_SYSTEM_PERMISSION_REGIONS.includes(region) && {
                        ListInvoicing: {
                            checked: selectedValues ? selectedValues.includes('ListInvoicing') : false,
                            label: 'Manage Invoicing (View, Create, Update, Delete)',
                            value: 'ListInvoicing',
                        },
                    }),
                    ...(SILO_REGIONS.includes(region) && {
                        ListSilos: {
                            checked: selectedValues ? selectedValues.includes('ListSilos') : false,
                            label: 'Manage Silos (View, Create, Update, Delete)',
                            value: 'ListSilos',
                        },
                    }),
                    ...(SCHEDULING_REGIONS.includes(region) && {
                        GetMilkScheduleDetails: {
                            checked: selectedValues ? selectedValues.includes('GetMilkScheduleDetails') : false,
                            label: 'Manage Milk Schedule (View, Create Demand)',
                            value: 'GetMilkScheduleDetails',
                        },
                    }),
                    ...(SCHEDULING_REGIONS.includes(region) && {
                        GetFluidScheduleDetails: {
                            checked: selectedValues ? selectedValues.includes('GetFluidScheduleDetails') : false,
                            label: 'Manage Processed Milk Schedule (View, Create, Update, Delete)',
                            value: 'GetFluidScheduleDetails',
                        },
                    }),
                    ...([Region.UDA].includes(region) && {
                        ListTrucks: {
                            checked: selectedValues ? selectedValues.includes('ListTrucks') : false,
                            label: 'Manage Trucks (View, Create, Update, Delete)',
                            value: 'ListTrucks',
                        },
                    }),
                    ...(IN_PROGRESS_RAPID_TEST_REGIONS.includes(rapidTestRegion) && {
                        ManageRapidTest: {
                            checked: selectedValues ? selectedValues.includes('ManageRapidTest') : false,
                            label: 'Manage Rapid Test',
                            value: 'ManageRapidTest',
                        },
                    }),
                    ...([...TCJ_REGIONS, Region.UNC, Region.CA_DEMO, Region.US_DEMO].includes(region) && {
                        ListManifests: {
                            checked: selectedValues ? selectedValues.includes('ListManifests') : false,
                            label: 'Manage Manifest View (View, Create, Update, Delete)',
                            value: 'ListManifests',
                        },
                    }),
                    ...([Region.UDA].includes(region) &&
                        isSchreiber && {
                            EditSiloInvoices: {
                                checked: selectedValues ? selectedValues.includes('EditSiloInvoices') : false,
                                label: 'Edit Silo Invoices',
                                value: 'EditSiloInvoices',
                            },
                        }),
                };
            }
            case 'Sub-Hauling': {
                return {
                    GetDashboardDetails: {
                        checked: selectedValues ? selectedValues.includes('GetDashboardDetails') : false,
                        label: 'View Dashboard',
                        value: 'GetDashboardDetails',
                    },
                    ListDrivers: {
                        checked: selectedValues ? selectedValues.includes('ListDrivers') : false,
                        label: 'Manage Drivers (View, Create, Update, Delete)',
                        value: 'ListDrivers',
                    },
                    ListDropoffs: {
                        checked: selectedValues ? selectedValues.includes('ListDropoffs') : false,
                        label: 'Manage Dropoffs (View, Create, Update, Delete)',
                        value: 'ListDropoffs',
                    },
                    ...(SCHEDULING_REGIONS.includes(region) && {
                        GetMilkScheduleDetails: {
                            checked: selectedValues ? selectedValues.includes('GetMilkScheduleDetails') : false,
                            label: 'Manage Milk Schedule (View)',
                            value: 'GetMilkScheduleDetails',
                        },
                    }),
                    ...(SCHEDULING_REGIONS.includes(region) && {
                        GetFluidScheduleDetails: {
                            checked: selectedValues ? selectedValues.includes('GetFluidScheduleDetails') : false,
                            label: 'Manage Processed Milk Schedule (View, Create, Update, Delete)',
                            value: 'GetFluidScheduleDetails',
                        },
                    }),
                    ListPickups: {
                        checked: selectedValues ? selectedValues.includes('ListPickups') : false,
                        label: 'Manage Pickups (View, Create, Update, Delete)',
                        value: 'ListPickups',
                    },
                    ListRouteSessions: {
                        checked: selectedValues ? selectedValues.includes('ListRouteSessions') : false,
                        label: `Manage ${routeSession}s (View, Create, Update, Delete)`,
                        value: 'ListRouteSessions',
                    },
                    ListSharedFiles: {
                        checked: selectedValues ? selectedValues.includes('ListSharedFiles') : false,
                        label: 'Manage Shared Files (View, Create, Update, Delete)',
                        value: 'ListSharedFiles',
                    },
                    ListSubHauling: {
                        checked: selectedValues ? selectedValues.includes('ListSubHauling') : false,
                        label: region === Region.UDA ? 'Manage Sub Carrier (View, Create, Update, Delete)' : 'Manage Sub Hauling Company (View, Create, Update, Delete)',
                        value: 'ListSubHauling',
                    },
                    ListTrailers: {
                        checked: selectedValues ? selectedValues.includes('ListTrailers') : false,
                        label: 'Manage Trailers (View, Create, Update, Delete)',
                        value: 'ListTrailers',
                    },
                    ListCIPWash: {
                        checked: selectedValues ? selectedValues.includes('ListCIPWash') : false,
                        label: 'Manage CIP Wash (View, Create, Update, Delete)',
                        value: 'ListCIPWash',
                    },
                    ...([Region.UDA].includes(region) && {
                        ListTrucks: {
                            checked: selectedValues ? selectedValues.includes('ListTrucks') : false,
                            label: 'Manage Trucks (View, Create, Update, Delete)',
                            value: 'ListTrucks',
                        },
                    }),
                    ...([Region.MMPA, Region.PRAIRIE, Region.UNC].includes(region) && {
                        ViewHaulerPayReport: {
                            checked: selectedValues ? selectedValues.includes('ViewHaulerPayReport') : false,
                            label: 'View Hauler Pay Report',
                            value: 'ViewHaulerPayReport',
                        },
                        ViewProducerHaulingContracts: {
                            checked: selectedValues ? selectedValues.includes('ViewProducerHaulingCharges') : false,
                            label: 'View Hauling Contracts',
                            value: 'ViewProducerHaulingCharges',
                        },
                        ViewProducerHaulingCharges: {
                            checked: selectedValues ? selectedValues.includes('ViewProducerHaulingCharges') : false,
                            label: 'View Hauling Charges',
                            value: 'ViewProducerHaulingCharges',
                        },
                    }),
                    ...([Region.PRAIRIE].includes(region) && {
                        ManageHaulingCharges: {
                            checked: selectedValues ? selectedValues.includes('ManageHaulingCharges') : false,
                            label: 'Manage Hauling Charges',
                            value: 'ManageHaulingCharges',
                        },
                        ManageHaulingContracts: {
                            checked: selectedValues ? selectedValues.includes('ManageHaulingContracts') : false,
                            label: 'Manage Hauling Contracts',
                            value: 'ManageHaulingContracts',
                        },
                    }),
                    ViewDriverLocations: {
                        checked: selectedValues ? selectedValues.includes('ViewDriverLocations') : false,
                        label: 'View Driver Locations',
                        value: 'ViewDriverLocations',
                    },
                    ...([Region.UDA, Region.PRAIRIE].includes(region) && {
                        UploadDownload: {
                            checked: selectedValues ? selectedValues.includes('UploadDownload') : false,
                            label: 'View Upload / Download',
                            value: 'UploadDownload',
                        },
                    }),
                };
            }
            case 'Sub-Lab-Provider': {
                return {
                    ListPickups: {
                        checked: selectedValues ? selectedValues.includes('ListPickups') : false,
                        label: 'View Pickups',
                        value: 'ListPickups',
                    },
                    ListLabReports: {
                        checked: selectedValues ? selectedValues.includes('ListLabReports') : false,
                        label: 'View Lab Reports',
                        value: 'ListLabReports',
                    },
                    ListBactoAlert: {
                        checked: selectedValues ? selectedValues.includes('ListBactoAlert') : false,
                        label: 'View Penalty Status',
                        value: 'ListBactoAlert',
                    },
                    ListSharedFiles: {
                        checked: selectedValues ? selectedValues.includes('ListSharedFiles') : false,
                        label: 'Manage Shared Files (View, Create, Update, Delete)',
                        value: 'ListSharedFiles',
                    },

                    ListSubLabProvider: {
                        checked: selectedValues ? selectedValues.includes('ListSubLabProvider') : false,
                        label: 'Manage Sub Producers (View)',
                        value: 'ListSubProducers',
                    },
                };
            }
            case 'Driver': {
                return {
                    CanBypassMandatoryLocation: {
                        checked: selectedValues ? selectedValues.includes('CanBypassMandatoryLocation') : false,
                        label: 'Bypass Mandatory Location',
                        value: 'CanBypassMandatoryLocation',
                    },
                };
            }
            default: {
                return {};
            }
        }
    };

    render() {
        // eslint-disable-next-line global-require
        const uuidv4 = require('uuid/v4');
        const { classes, disabled, showError } = this.props;
        const { allChecked } = this.state;
        const error = !Object.values(this.state).filter((value) => !!value.value && !!value.checked).length;

        return (
            <div className={classes.root}>
                <FormControl error={error && showError} component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend" className="text-black bold">
                        Assign Permissions
                    </FormLabel>
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={allChecked.checked} disabled={disabled} onChange={this.handleAllChange(!allChecked)} value="allChecked" />} label="Select All" />
                        {Object.values(this.state).map((value, index) => {
                            if (!index) {
                                return null;
                            }
                            return <FormControlLabel key={uuidv4()} disabled={disabled} control={<Checkbox checked={value.checked} onChange={this.handleChange(value.value)} value={value.value} />} label={value.label} />;
                        })}
                    </FormGroup>
                    {error && showError && <FormHelperText>* Please select at least one permission.</FormHelperText>}
                </FormControl>
            </div>
        );
    }
}

function mapStateToProps({ persisted }) {
    return { region: persisted.auth.user.data.region };
}

export default withStyles(styles)(connect(mapStateToProps, null)(CheckboxesGroup));
