import { object, number, date, string, boolean } from 'yup';

const HaulerRateSchema = object({
    hauler: object({}).required('Hauler is a required field').typeError('Hauler is a required field'),
    rate_name: string().required('Rate name is a required field'),
    type: object({}).required('Type is a required field').typeError('Type is a required field'),
    basis: object({}).required('Basis is a required field').typeError('Basis is a required field'),
    basis_parameter: object({}).when('basis', {
        is: (basis) => basis?.value === 'cwt',
        then: (cwtSchema) => cwtSchema.required('Milk Type is a required field').typeError('Milk Type is a required field'),
        otherwise: (schema) =>
            schema.when('basis', {
                is: (basis) => basis?.value === 'percent',
                then: (percentSchema) => percentSchema.required('Hauling Rate Type is a required field').typeError('Hauling Rate Type is a required field'),
                otherwise: (percentSchema) => percentSchema.nullable(),
            }),
    }),
    rate: number().when('basis', {
        is: (basis) => basis?.value === 'percent',
        then: (schema) => schema.required().min(0, 'Percent rate must be between 0-100.').max(100, 'Percent rate must be between 0-100.').typeError('Rate is a required field'),
        otherwise: (schema) => schema.required().typeError('Rate is a required field'),
    }),
    effective_date: date().required(),
    exclude_pickups_before_date: object().optional().nullable(),
    min_charge: number()
        .optional()
        .nullable()
        .min(0, 'Charges cannot be negative')
        .transform((_, val) => (val.length !== 0 ? Number(val) : undefined))
        .typeError('Min Charge must be a number'),
    max_charge: number()
        .optional()
        .nullable()
        .when(['min_charge'], (min, schema) => schema.min(min ?? 0, 'Max Charge cannot be less than Min Charge'))
        .transform((_, val) => (val.length !== 0 ? Number(val) : undefined))
        .typeError('Max Charge must be a number'),
    paid_by_producer: boolean().optional().nullable(),
});

export default HaulerRateSchema;
