import store from 'app/store';
import * as repository from '../../../repository';

export const CREATE_ADMIN = 'CREATE_ADMIN';
export const CREATE_SUB_ADMIN = 'CREATE_SUB_ADMIN';
export const CREATE_PRODUCER = 'CREATE_PRODUCER';
export const CREATE_SUB_PRODUCER = 'CREATE_SUB_PRODUCER';
export const CREATE_DRIVER = 'CREATE_DRIVER';
export const CREATE_RECEIVER = 'CREATE_RECEIVER';
export const CREATE_PROCESSOR = 'CREATE_PROCESSOR';
export const CREATE_SUB_PROCESSOR = 'CREATE_SUB_PROCESSOR';
export const CREATE_HAULING = 'CREATE_HAULING';
export const CREATE_SUB_HAULING = 'CREATE_SUB_HAULING';
export const CREATE_ROUTE = 'CREATE_ROUTE';
export const CREATE_TRAILER = 'CREATE_TRAILER';
export const CREATE_TRUCK = 'CREATE_TRUCK';
export const CREATE_PICKUP = 'CREATE_PICKUP';
export const CREATE_DROPOFF = 'CREATE_DROPOFF';
export const CREATE_ROUTE_SESSION = 'CREATE_ROUTE_SESSION';
export const ADD_COMMENT = 'ADD_COMMENT';
export const CREATE_LAB_REPORT = 'CREATE_LAB_REPORT';
export const CREATE_DROPOFF_SAMPLE = 'CREATE_DROPOFF_SAMPLE';
export const CREATE_SILO = 'CREATE_SILO';
export const CREATE_BASE_TRANSFER = 'CREATE_BASE_TRANSFER';
export const CREATE_WASH = 'CREATE_WASH';
export const CREATE_WASH_FACILITY = 'CREATE_WASH_FACILITY';
export const CREATE_MILK_PRICES = 'CREATE_MILK_PRICES';
export const CREATE_ASSIGNEE = 'CREATE_ASSIGNEE';
export const CREATE_SPLIT_PRODUCER = 'CREATE_SPLIT_PRODUCER';
export const CREATE_HAULING_CONTRACT = 'CREATE_HAULING_CONTRACT';
export const CREATE_ASSIGNMENT = 'CREATE_ASSIGNMENT';
export const CREATE_ASSIGNMENT_CONFIGURATION = 'CREATE_ASSIGNMENT_CONFIGURATION';
export const CREATE_HAULER_ASSIGNMENT = 'CREATE_HAULER_ASSIGNMENT';
export const CREATE_RECURRING_ASSIGNMENT = 'CREATE_RECURRING_ASSIGNMENT';
export const CREATE_DEFERRAL = 'CREATE_DEFERRAL';
export const CREATE_LAB_PROVIDER = 'CREATE_LAB_PROVIDER';
export const CREATE_CUSTOM_TABLE_CONFIGURATION = 'CREATE_CUSTOM_TABLE_CONFIGURATION';
export const CREATE_PAY_REPORT_PAYMENT_STATUS = 'CREATE_PAY_REPORT_PAYMENT_STATUS';
export const CREATE_EFFECTIVE_HAULER_RATE = 'CREATE_EFFECTIVE_HAULER_RATE';
export const UPDATE_EFFECTIVE_HAULER_RATE = 'UPDATE_EFFECTIVE_HAULER_RATE';

export function addAdmin(model) {
    return (dispatch) => repository.addAdmin(model).then((payload) => dispatch({ type: CREATE_ADMIN, payload }));
}

export function addSubAdmin(model) {
    return (dispatch) => repository.addSubAdmin(model).then((payload) => dispatch({ type: CREATE_SUB_ADMIN, payload }));
}

export function addProducer(model) {
    return (dispatch) => repository.addProducer(model).then((payload) => dispatch({ type: CREATE_PRODUCER, payload }));
}

export function addSubProducer(model) {
    return (dispatch) => repository.addSubProducer(model).then((payload) => dispatch({ type: CREATE_SUB_PRODUCER, payload }));
}

export function addLabProvider(model) {
    return (dispatch) => repository.addLabProvider(model).then((payload) => dispatch({ type: CREATE_LAB_PROVIDER, payload }));
}

export function addDriver(model) {
    return (dispatch) => repository.addDriver(model).then((payload) => dispatch({ type: CREATE_DRIVER, payload }));
}

export function addReceiver(model) {
    return (dispatch) => repository.addReceiver(model).then((payload) => dispatch({ type: CREATE_RECEIVER, payload }));
}

export function addProcessor(model) {
    return (dispatch) => repository.addProcessor(model).then((payload) => dispatch({ type: CREATE_PROCESSOR, payload }));
}

export function addMilkPrices(model, date) {
    return (dispatch) => repository.addMilkPrices(model, date).then((payload) => dispatch({ type: CREATE_MILK_PRICES, payload }));
}

export function addSilo(model) {
    return (dispatch) => repository.addSilo(model).then((payload) => dispatch({ type: CREATE_SILO, payload }));
}

export function addAssignee(model) {
    return (dispatch) => repository.addAssignee(model).then((payload) => dispatch({ type: CREATE_ASSIGNEE, payload }));
}

export function addSplitProducer(model) {
    return (dispatch) => repository.addSplitProducer(model).then((payload) => dispatch({ type: CREATE_SPLIT_PRODUCER, payload }));
}

export function addHaulingContract(model) {
    return (dispatch) => repository.addHaulingContract(model).then((payload) => dispatch({ type: CREATE_HAULING_CONTRACT, payload }));
}

export function addAssignment(model) {
    return (dispatch) => repository.addAssignment(model).then((payload) => dispatch({ type: CREATE_ASSIGNMENT, payload }));
}

export function addAssignmentConfiguration(model) {
    return (dispatch) => repository.addAssignmentConfiguration(model).then((payload) => dispatch({ type: CREATE_ASSIGNMENT_CONFIGURATION, payload }));
}

export function addRecurringAssignment(model) {
    return (dispatch) => repository.addRecurringAssignment(model).then((payload) => dispatch({ type: CREATE_RECURRING_ASSIGNMENT, payload }));
}

export function addHaulerAssignment(model) {
    return (dispatch) => repository.addHaulerAssignment(model).then((payload) => dispatch({ type: CREATE_HAULER_ASSIGNMENT, payload }));
}

export function addDeferral(model) {
    return (dispatch) => repository.addDeferral(model).then((payload) => dispatch({ type: CREATE_DEFERRAL, payload }));
}

export function addSubProcessor(model) {
    return (dispatch) => repository.addSubProcessor(model).then((payload) => dispatch({ type: CREATE_SUB_PROCESSOR, payload }));
}

export function addHauling(model) {
    return (dispatch) => repository.addHauling(model).then((payload) => dispatch({ type: CREATE_HAULING, payload }));
}

export function addSubHauling(model) {
    return (dispatch) => repository.addSubHauling(model).then((payload) => dispatch({ type: CREATE_SUB_HAULING, payload }));
}

export function addRoute(model) {
    return (dispatch) => repository.addRoute(model).then((payload) => dispatch({ type: CREATE_ROUTE, payload }));
}

export function addTrailer(model) {
    return (dispatch) => repository.addTrailer(model).then((payload) => dispatch({ type: CREATE_TRAILER, payload }));
}

export function addTruck(model) {
    return (dispatch) => repository.addTruck(model).then((payload) => dispatch({ type: CREATE_TRUCK, payload }));
}

export function addPickup(model) {
    return (dispatch) => repository.addPickup(model).then((payload) => dispatch({ type: CREATE_PICKUP, payload }));
}

export function addLabReport(model) {
    return (dispatch) => repository.addLabReport(model).then((payload) => dispatch({ type: CREATE_LAB_REPORT, payload }));
}

export function addDropoffSample(model) {
    return (dispatch) => repository.addDropoffSample(model).then((payload) => dispatch({ type: CREATE_DROPOFF_SAMPLE, payload }));
}

export function addDropoff(model) {
    return (dispatch) => repository.addDropoff(model).then((payload) => dispatch({ type: CREATE_DROPOFF, payload }));
}

export function addRouteSession(model) {
    return (dispatch) => repository.addRouteSession(model).then((payload) => dispatch({ type: CREATE_ROUTE_SESSION, payload }));
}

export function addBaseTransfer(model) {
    return (dispatch) => repository.addBaseTransfer(model).then((payload) => dispatch({ type: CREATE_BASE_TRANSFER, payload }));
}

export function addWash(model) {
    return (dispatch) => repository.addWash(model).then((payload) => dispatch({ type: CREATE_WASH, payload }));
}

export function addWashFacility(model) {
    return (dispatch) => repository.addWashFacility(model).then((payload) => dispatch({ type: CREATE_WASH_FACILITY, payload }));
}

export function addComment(type, ItemID, comment, isEdit) {
    return (dispatch) =>
        repository
            .addComment({
                commented_type: type,
                commented_id: ItemID,
                comment,
                is_edit: isEdit,
                created_by: store.getState().persisted.auth.user.data.id,
            })
            .then((payload) => dispatch({ type: ADD_COMMENT, payload }));
}

export function uploadImage(base64) {
    return () => repository.uploadImage({ base64 }).then((payload) => payload);
}

export function saveCustomTableConfiguration(configuration) {
    return (dispatch) => repository.saveCustomTableConfiguration({ configuration }).then((payload) => dispatch({ type: CREATE_CUSTOM_TABLE_CONFIGURATION, payload }));
}

export function addPayReportPaymentStatus(model) {
    return (dispatch) => repository.addPayReportPaymentStatus(model).then((payload) => dispatch({ type: CREATE_PAY_REPORT_PAYMENT_STATUS, payload }));
}

export function addEffectiveHaulerRate(model) {
    return (dispatch) => repository.addEffectiveHaulerRate(model).then((payload) => dispatch({ type: CREATE_EFFECTIVE_HAULER_RATE, payload }));
}

export function updateEffectiveHaulerRate(model, date) {
    return (dispatch) => repository.updateEffectiveHaulerRate(model, date).then((payload) => dispatch({ type: UPDATE_EFFECTIVE_HAULER_RATE, payload }));
}
