import React from 'react';
import MomentUtils from '@date-io/moment';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Alert, Grid } from '@mui/material';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import moment from 'moment-timezone';

const PayReportPublishDate = ({ selectedPublishDate, setSelectedPublishDate, usePublishDate, setUsePublishDate }) => {
    return (
        <>
            <Grid container spacing={2} sx={{ marginTop: '10px' }}>
                <Grid item xs={12} sm={5}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DateTimePicker
                            value={selectedPublishDate}
                            label={'Publish Date'}
                            minDate={moment().add(5, 'minutes')}
                            maxDate={moment().add(3, 'days')}
                            inputVariant="outlined"
                            disabled={!usePublishDate}
                            onChange={(date) => setSelectedPublishDate(date)}
                            // wrap
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={7}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={usePublishDate}
                                onChange={(event) => setUsePublishDate(event.target.checked)}
                                // wrap
                            />
                        }
                        label={'Publish Later?'}
                        // wrap
                    />
                </Grid>
                {!usePublishDate && (
                    <Grid item xs={12}>
                        <Alert severity="info">
                            <b>Note:</b> Payroll statements will be available immediately if a publish date is not selected.
                        </Alert>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default PayReportPublishDate;
