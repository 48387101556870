import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import DehazeIcon from '@material-ui/icons/Dehaze';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const grid = 8;

const useStyles = (isDragging, draggableStyle) =>
    makeStyles(() => ({
        root: {
            display: 'flex',
            justifyContent: 'space-between',
            userSelect: 'none',
            padding: grid * 2,
            margin: `0 0 ${grid}px 0`,
            background: isDragging ? 'lightgrey' : 'white',
            ...draggableStyle,
        },
    }));

function Item({ item, index }) {
    return (
        <>
            {item && (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                        <div className={useStyles(snapshot.isDragging, provided.draggableProps.style)().root} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <Typography>{item.name}</Typography>
                            <DehazeIcon />
                        </div>
                    )}
                </Draggable>
            )}
        </>
    );
}

export default Item;
