import React, { useEffect, useState } from 'react';
import { Login } from '@milkmoovement/common_cognito_package';
import { onCognitoLogin } from '../../../auth/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRegion, getHostname } from '../../../../utils';
import { showMessage } from '../../../store/actions';

const LoginPage = () => {
    const history = useHistory();
    const region = getRegion(window.location.hostname);
    const dispatch = useDispatch();

    const hostname = getHostname(window);
    let authConfig;

    const [internalSSO, setInternalSSO] = useState(false);

    const providersMapJSON = process.env.REACT_APP_PROVIDERS_MAP;
    const providersMap = providersMapJSON ? JSON.parse(providersMapJSON) : {};
    const providers = Object.hasOwn(providersMap, region) ? providersMap[region] : [];
    const internalProviderJSON = process.env.REACT_APP_INTERNAL_PROVIDER_MAP;
    const internalProvider = internalProviderJSON ? JSON.parse(internalProviderJSON) : {};

    // Internal redirection based on cookie set in Cloudfront function
    const cookie = document.cookie.split('; ').find((row) => row.startsWith('internal-redirect='));
    const redirectValue = cookie && internalProviderJSON ? cookie.split('=')[1] : null;
    useEffect(() => {
        if (redirectValue === 'true') {
            // Delete cookie so redirection only happens once in the session
            document.cookie = 'internal-redirect=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            setInternalSSO(true);
            localStorage.setItem('mm.login.internalLogin', 'true');
        }
    }, []);

    if (localStorage.getItem('mm.login.internalLogin') === 'true' || redirectValue === 'true') {
        authConfig = {
            domain: process.env.REACT_APP_OAUTH_DOMAIN,
            redirectSignIn: hostname,
            redirectSignOut: hostname,
            clientId: internalProvider.clientId,
        };
        providers.push(internalProvider);
    } else if (providers?.length > 0) {
        authConfig = {
            domain: process.env.REACT_APP_OAUTH_DOMAIN,
            redirectSignIn: hostname,
            redirectSignOut: hostname,
            clientId: providers[0].clientId, // all providers currently have the same client id
        };
    }

    const { error } = useSelector(({ persisted }) => persisted.auth.login);

    useEffect(() => {
        if (error.message && error.name === 'RoleError') {
            dispatch(showMessage({ message: error.message }));
        }
    }, [error]);

    const handleNavigateForgotPassword = () => history.push({ pathname: '/forgot-password' });

    const handleSucessfulLogin = async () => {
        dispatch(await onCognitoLogin());
    };
    const handleNavigateLogin = () => setInternalSSO(false);

    return (
        <Login
            onSucessfulLogin={handleSucessfulLogin}
            onNavigateForgotPassword={handleNavigateForgotPassword}
            onNavigateLogin={handleNavigateLogin}
            metadata={{
                coreSystem: region,
            }}
            internalLoginRender={internalSSO}
            authConfig={authConfig}
            providers={providers}
        />
    );
};

export default LoginPage;
