import authRoles from 'app/auth/authRoles';
import { FuseLoadable } from '@fuse';

export const ReceivedFilesConfig = {
    auth: authRoles.everyoneButGuest,
    routes: [
        {
            exact: true,
            path: '/shared-files/received',
            component: FuseLoadable({ loader: () => import('./SharedFilesApp') }),
        },
    ],
};

export const SharedFilesConfig = {
    auth: authRoles.everyoneButGuest,
    routes: [
        {
            exact: true,
            path: '/shared-files/shared',
            component: FuseLoadable({ loader: () => import('./SharedFilesApp') }),
        },
    ],
};

export const SharedAFileConfig = {
    auth: authRoles.everyoneButGuest,
    routes: [
        {
            exact: true,
            path: '/shared-files/share',
            component: FuseLoadable({ loader: () => import('./ShareAFileApp') }),
        },
    ],
};

export default [SharedFilesConfig, SharedAFileConfig, ReceivedFilesConfig];
