import axios from 'axios';
import { getUnreadNotificationsCountQuery } from './queries';

const getNumOfUnreadNotifications = (id) =>
    new Promise((resolve, reject) => {
        const query = getUnreadNotificationsCountQuery({ id });
        const request = axios.get(query);

        request
            .then((response) => {
                resolve({ success: true, count: response.data.count });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getNumOfUnreadNotifications;
