import axios from 'axios';
import { getCloseMonthQuery } from './queries';

const getCloseMonthDetails = async ({ isLocked, startDate, endDate }) => {
    try {
        const closeMonthQuery = getCloseMonthQuery({ isLocked, startDate, endDate });
        const closeMonthResponse = await axios.get(closeMonthQuery);
        const closedMonth = closeMonthResponse.data;
        return { closedMonth };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getCloseMonthDetails;
