import axios from 'axios';
import { resolveId } from '../../utils';
import { createUserGroupQuery } from './queries';

const addUserGroup = async (model) => {
    try {
        const query = createUserGroupQuery({ model });
        const request = axios.post(query.url, { ...query.body });
        const { data } = await request;

        return resolveId(data);
    } catch {
        throw new Error('Unable to add schedule user group');
    }
};

export default addUserGroup;
