import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Divider, InputAdornment, TextField, Typography, IconButton, debounce } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import Formsy from 'formsy-react';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import { TextFieldFormsy } from '@fuse';
import moment, { isDate } from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import Dropzone from './Dropzone';
import path from 'path';
import _ from 'lodash';
import { getRole, getId } from '../../../utils';
import { showMessage, addTruck, editTruck, uploadImage } from 'app/store/actions';
import { uploadFile, getFileURL } from '../../repository';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import { getTrucksQuery } from '../../repository/queries';
import axios from 'axios';

const CreateTruckForm = ({ editData, haulingCompanies }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const role = getRole();
    const fileInput = React.createRef();
    const [haulerId, setHaulerId] = useState(role === 'transport' && getId());
    const [truckNumber, setTruckNumber] = useState('');
    const [issueDate, setIssueDate] = useState(null);
    const [expireDate, setExpireDate] = useState(null);
    const [url, setURL] = useState('');
    const [files, setFiles] = useState([]);
    const [canSubmit, setCanSubmit] = useState(false);
    const [truckNumberError, setTruckNumberError] = useState(editData ? null : 'This is not a valid Truck Number');
    const prevTruckNumber = editData ? editData.truck_number : null;

    const updateTruckNumber = _.debounce((value) => {
        setTruckNumber(value);
    }, 500);

    const validateTruckNumber = (haulingId) => {
        const query = getTrucksQuery({ filterDeleted: true, haulingId, truckNumber });
        const request = axios.get(query);
        request.then((response) => {
            const error = response.data.length > 0;
            setTruckNumberError(error ? 'This Truck Number is already in use' : null);
            setCanSubmit(!error);
        });
    };

    useEffect(() => {
        const shouldValidate = haulerId && truckNumber && truckNumber !== prevTruckNumber;
        const emptyTruckNumber = !(!!truckNumber || prevTruckNumber);
        if (shouldValidate) validateTruckNumber(haulerId);
        else setTruckNumberError(emptyTruckNumber ? 'This is not a valid truck number' : null);
    }, [haulerId, truckNumber]);

    useEffect(() => {
        const getDate = (date) => (date ? moment(date) : null);

        if (editData) {
            if (editData.hauling_company) setHaulerId(editData.hauling_company);
            if (editData.issue_date) setIssueDate(getDate(editData.issue_date));
            if (editData.expire_date) setExpireDate(getDate(editData.expire_date));
            if (editData.heavy_haul_permit_slip) {
                getFileURL(editData.heavy_haul_permit_slip)
                    .then((file) => {
                        setURL(file);
                    })
                    .catch((error) => {
                        showMessage({ message: 'There was a problem displaying the image. Please contact support.' });
                    });
            }
        }
    }, [editData]);

    const disableButton = () => {
        setCanSubmit(false);
    };

    const enableButton = () => {
        if (!truckNumberError) setCanSubmit(true);
    };

    const updateCurrentSlip = (newFiles) => {
        setFiles(newFiles);
    };

    const finishSubmit = (model) => {
        if (editData) {
            // eslint-disable-next-line no-underscore-dangle
            dispatch(editTruck(model, editData._id))
                .then(() => {
                    dispatch(showMessage({ message: 'Successfully Edited Truck.' }));
                    history.push({ pathname: '/list-trucks' });
                })
                .catch((err) => {
                    dispatch(showMessage({ message: err.message }));
                });
        } else {
            dispatch(addTruck(model))
                .then(() => {
                    dispatch(showMessage({ message: 'Successfully Added Truck.' }));
                    history.push({ pathname: '/list-trucks' });
                })
                .catch((err) => {
                    dispatch(showMessage({ message: err.message }));
                });
        }
    };

    const onSubmit = (model) => {
        const submitModel = { ...model };
        submitModel.hauling_company = haulerId || submitModel.hauling_id;
        delete submitModel.hauling_id;

        if (issueDate) submitModel.issue_date = moment(issueDate).format();
        if (expireDate) submitModel.expire_date = moment(expireDate).format();

        if (!model.permit_number) delete submitModel.permit_number;
        if (!model.vin_number) delete submitModel.vin_number;
        if (!model.maximum_weight) delete submitModel.maximum_weight;
        if (model.maximum_weight < 0) {
            submitModel.maximum_weight = 0;
        }

        if (files.length > 0) {
            const type = _.lowerCase(path.extname(files[0].name));
            if (type === 'jpg' || type === 'jpeg' || type === 'png') {
                const reader = new FileReader();
                reader.readAsDataURL(files[0]);

                reader.onloadend = async () => {
                    try {
                        const response = await dispatch(uploadImage(reader.result));
                        const imageLocation = response.imageInfo.key;
                        submitModel.heavy_haul_permit_slip = imageLocation;
                        finishSubmit(submitModel);
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.warn('ERROR: ', err);
                        dispatch(showMessage({ message: 'Failed to create truck.' }));
                    }
                };
            } else if (type === 'pdf') {
                const formData = new FormData();
                formData.append('file', files[0]);

                (async () => {
                    try {
                        const response = await uploadFile(formData);
                        submitModel.heavy_haul_permit_slip = response.data.key;
                        finishSubmit(submitModel);
                    } catch (err) {
                        dispatch(showMessage({ message: 'There was a problem uploading your file. Please contact support.' }));
                    }
                })();
            }
        } else {
            finishSubmit(submitModel);
        }
    };

    const debounceSubmit = debounce(onSubmit, 500);

    return (
        <LocalizationConsumer>
            {(localization) => (
                <div className="min-w-3/4 max-w-3/4">
                    <Divider />

                    <Formsy
                        onValidSubmit={debounceSubmit}
                        onValid={enableButton}
                        onInvalid={disableButton}
                        /* eslint-disable-next-line no-return-assign */
                        ref={(form) => form}
                        className="flex flex-col justify-center"
                    >
                        <TextFieldFormsy
                            className="my-16"
                            type="number"
                            name="truck_number"
                            label="Truck Number"
                            value={editData ? editData.truck_number : ''}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-20 material-icons" color="action">
                                            local_shipping
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            required
                            variant="outlined"
                            onChange={(event) => {
                                setCanSubmit(false);
                                updateTruckNumber(event.target.value);
                            }}
                            customErrorMessage={truckNumberError}
                        />

                        {role === 'transport' ? (
                            <TextFieldFormsy className="my-16" name="hauling_id" label="Hauling Company" value={haulingCompanies.find((entry) => entry.value === haulerId)?.name || ''} disabled />
                        ) : (
                            <SelectSearchFormsy
                                className="my-16"
                                name="hauling_id"
                                label={`${localization.general.hauling_singular}`}
                                variant="standard"
                                options={[{ value: ' ', name: 'None' }, ...haulingCompanies.map((entry) => ({ value: entry.value, name: entry.name }))]}
                                value={editData ? haulerId : ' '}
                                validations="minLength:2"
                                validationError={`Please select a ${localization.general.hauling_singular}`}
                                disabled={role === 'transport'}
                                onChange={(event) => setHaulerId(event.target.value)}
                            />
                        )}

                        <TextFieldFormsy
                            className="my-16"
                            type="text"
                            name="permit_number"
                            label="Permit Number"
                            value={editData ? editData.permit_number : ''}
                            validations="minLength:1"
                            validationError="This is not a valid Permit Number"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-20 material-icons" color="action">
                                            list_alt
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />

                        <div className="w-full my-16">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    label={'Issue Date'}
                                    value={issueDate}
                                    onChange={(date) => setIssueDate(date)}
                                    validations={isDate}
                                    format="DD MMMM YYYY"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton sx={{ m: 0.55 }} onClick={() => setIssueDate(null)} edge="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        cancel
                                                    </i>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                    fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <div className="w-full my-16">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    label={'Expire Date'}
                                    value={expireDate}
                                    onChange={(date) => setExpireDate(date)}
                                    validations={isDate}
                                    format="DD MMMM YYYY"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton sx={{ m: 0.55 }} onClick={() => setExpireDate(null)} edge="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        cancel
                                                    </i>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    renderInput={(params) => <TextField {...params} helperText={null} />}
                                    fullWidth
                                />
                            </MuiPickersUtilsProvider>
                        </div>

                        <TextFieldFormsy
                            className="my-16"
                            type="text"
                            name="vin_number"
                            label="VIN Number"
                            value={editData ? editData.vin_number : ''}
                            validations="minLength:1"
                            validationError="This is not a valid VIN Number"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-20 material-icons" color="action">
                                            list_alt
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />

                        <TextFieldFormsy
                            className="my-16"
                            type="number"
                            name="maximum_weight"
                            label="Maximum Weight"
                            value={editData ? editData.maximum_weight : ''}
                            validations={{
                                isNumeric: true,
                            }}
                            validationError="Please enter a valid positive number"
                            variant="outlined"
                        />

                        {/* eslint-disable-next-line no-return-assign */}
                        <Typography className="my-16">
                            <div className="draggable">
                                Permit Image: &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
                                <Dropzone handleDrop={updateCurrentSlip} multiple />
                                <div>
                                    {editData && editData.heavy_haul_permit_slip ? (
                                        <img
                                            style={{
                                                width: '300px',
                                                height: '300px',
                                                padding: 5,
                                            }}
                                            src={url}
                                            alt="No slip found"
                                        />
                                    ) : (
                                        ' '
                                    )}
                                </div>
                            </div>
                        </Typography>

                        <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={editData ? 'EDIT TRUCK' : 'ADD TRUCK'} disabled={!canSubmit}>
                            {editData ? 'Edit Truck' : 'Add Truck'}
                        </Button>
                    </Formsy>
                </div>
            )}
        </LocalizationConsumer>
    );
};

export default withRouter(CreateTruckForm);
