import React, { useState } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Divider } from '@material-ui/core';
import Formsy from 'formsy-react';
import { showMessage } from 'app/store/actions';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import { addMultisiteAccess } from '../../repository';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';

const MultisiteAccessForm = ({ users }) => {
    const history = useHistory();
    const [user, setUser] = useState('');
    const [access, setAccess] = useState('');
    const [canSubmit, setCanSubmit] = useState(false);

    const disableButton = () => {
        setCanSubmit(false);
    };

    const enableButton = () => {
        setCanSubmit(true);
    };

    const onSubmit = async (model) => {
        const mainUser = users.find((u) => u.id === model.user_id);
        const linkedUser = users.find((u) => u.id === model.access_id);
        const type = mainUser.role === 'producer' && linkedUser.role === 'producer' ? 'producer' : 'sub_user'; // may eventually be in backend
        const submitData = {
            user_id: model.user_id,
            access_id: model.access_id,
            type,
        };
        try {
            await addMultisiteAccess(submitData);
        } catch (err) {
            showMessage({ message: err.message });
            return;
        }
        showMessage({ message: 'Successfully added linked user' });
        history.replace({ pathname: '/manage/link-users' });
    };

    /* eslint-disable-next-line no-underscore-dangle */
    const options = users.map((entry) => ({ value: entry.id, name: entry.username }));

    const render = () => {
        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className={window.innerWidth > 600 ? 'w-3/4' : 'w-full'}>
                        <Divider />

                        <Formsy
                            onValidSubmit={onSubmit}
                            onValid={enableButton}
                            onInvalid={disableButton}
                            /* eslint-disable-next-line no-return-assign */
                            ref={(form) => form}
                            className="flex flex-col"
                        >
                            <SelectSearchFormsy
                                className="my-16"
                                name="user_id"
                                label={'Main Username'}
                                /* eslint-disable-next-line no-underscore-dangle */
                                options={options}
                                variant="standard"
                                value={user}
                                validations="minLength:2"
                                validationError={'Please select a user'}
                                on
                            />

                            <SelectSearchFormsy
                                className="my-16"
                                name="access_id"
                                label={'Linked Access Username'}
                                /* eslint-disable-next-line no-underscore-dangle */
                                options={options}
                                variant="standard"
                                value={access}
                                validations="minLength:2"
                                validationError={'Please select a user'}
                                on
                            />

                            <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={'Add'} disabled={!canSubmit}>
                                Add Link
                            </Button>
                        </Formsy>
                    </div>
                )}
            </LocalizationConsumer>
        );
    };

    return <>{render()}</>;
};

export default withRouter(MultisiteAccessForm);
