import axios from 'axios';
import { getExpressAPIHost } from '../../utils';

const bulkDeleteMerchandisePurchases = async (selectedDate) => {
    try {
        const response = await axios.delete(`${getExpressAPIHost()}/merchandise-purchase/delete-merchandise-purchases/${selectedDate}`);
        return response;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to delete purchases. Please contact support.');
    }
};
export default bulkDeleteMerchandisePurchases;
