import axios from 'axios';
import { deleteFluidItemQuery } from './queries';

const deleteFluidItem = async ({ key, date }) => {
    try {
        const query = deleteFluidItemQuery({ key, date });
        const request = axios.delete(query);
        const { data } = await request;
        return data;
    } catch {
        // eslint-disable-next-line no-console
        throw new Error('Unable to delete schedule item');
    }
};

export default deleteFluidItem;
