import React from 'react';
import { dataTableSort, searchTextField } from '../../../../utils';

const getColumns = () => [
    {
        title: 'Processor',
        field: 'processor',
        filter: {
            title: 'Processor',
            label: 'Processor',
            field: 'processor',
            saveStateKey: 'TCJAdminDropoffSummaryConfigProcessor',
            optionFormatter: (name) => {
                return name;
            },
        },
        customFilterAndSearch: (term, rowData) => searchTextField(term, rowData.processor),
    },
    {
        title: 'Processor#',
        field: 'processor_number',
    },
    {
        title: 'City',
        field: 'processor_city',
    },
    {
        title: 'State',
        field: 'processor_state',
    },
    {
        title: 'Category',
        field: 'category',
    },
    {
        title: 'Pickup (lbs)',
        field: 'amount',
        type: 'numeric',
        render: (rowData) => {
            return <div>{rowData.amount ? parseFloat(rowData.amount).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'}</div>;
        },
        customSort: (a, b) => dataTableSort(a, b, 'amount'),
    },
    {
        title: 'BF (lbs)',
        field: 'fat_weight',
        render: (rowData) => {
            return <div>{parseFloat(rowData.fat_weight).toLocaleString('us')}</div>;
        },
    },
    {
        title: 'PR (lbs)',
        field: 'protein_weight',
        render: (rowData) => {
            return <div>{parseFloat(rowData.protein_weight).toLocaleString('us')}</div>;
        },
    },
    {
        title: 'SKIM (lbs)',
        field: 'protein_weight',
        render: (rowData) => {
            return <div>{rowData.amount ? (parseFloat(rowData.amount) - parseFloat(rowData.fat_weight)).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'}</div>;
        },
    },
    {
        title: 'OS (lbs)',
        field: 'other_solids_weight',
        render: (rowData) => {
            return <div>{parseFloat(rowData.other_solids_weight).toLocaleString('us')}</div>;
        },
    },
    {
        title: 'BF (%)',
        field: 'fat_average',
        render: (rowData) => {
            return <div>{parseFloat(rowData.fat_average).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>;
        },
    },
    {
        title: 'PR (%)',
        field: 'protein_average',
        render: (rowData) => {
            return <div>{parseFloat(rowData.protein_average).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>;
        },
    },
    {
        title: 'OS (%)',
        field: 'other_solids_average',
        render: (rowData) => {
            return <div>{parseFloat(rowData.other_solids_average).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 })}</div>;
        },
    },
    {
        title: 'SCC',
        field: 'scc_average',
    },
];

const getData = (data) => data || [];

export default {
    getColumns,
    getData,
};
