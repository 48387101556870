import Environment from '../../Environment';
import stages from '../stages';
import MockSplit from '../../MockSplit';

const prod = new Environment({
    name: stages.PRODUCTION,
    evaluationFn: (attributes) => {
        return 'off';
    },
});

const demo = new Environment({
    ...prod,
    name: stages.DEMO,
});

const staging = new Environment({
    ...prod,
    name: stages.STAGING,
});

const local = new Environment({
    evaluationFn: (attributes) => {
        return 'on';
    },
    name: stages.LOCAL,
});

const dev = new Environment({
    ...prod,
    name: stages.DEV,
});

const use_list_view_schedule = new MockSplit({
    name: 'use_list_view_schedule',
    environments: [local, dev, staging, demo, prod],
});

export default use_list_view_schedule;
