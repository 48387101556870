import { getEquityMasterValuesAPIHost, getEquityMembershipAPIHost, getRegion } from '../../../../utils';
import { transformObjectToQueryString } from './utils';

const buildMasterValuesQuery = (endpoint, { headers, data, query }) => {
    const clientName = getRegion(window.location.hostname);
    let url = `${getEquityMasterValuesAPIHost()}${endpoint}?customer=${clientName}`;
    url += transformObjectToQueryString(query);

    return {
        url,
        headers,
        data,
    };
};

const buildMembershipQuery = (endpoint, { headers, data, query }, lastEvaluatedKey = undefined) => {
    const clientName = getRegion(window.location.hostname);
    let url = `${getEquityMembershipAPIHost()}${endpoint}?customer=${clientName}`;
    url += transformObjectToQueryString(query);

    if (lastEvaluatedKey) {
        url += `&lastEvaluatedKey=${encodeURIComponent(lastEvaluatedKey)}`;
    }

    return {
        url,
        headers,
        data,
    };
};

export { buildMasterValuesQuery, buildMembershipQuery };
