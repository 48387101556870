import axios from 'axios';
import Split from '../../Split';
import { resolveId } from '../../utils';
import { createPickupQuery } from './queries';
import { buildExpressQuery } from './queries/builders';
import generateErrorMessage from './utils/generateErrorMessage';

const addPickup = (model) =>
    new Promise((resolve, reject) => {
        const useExpressEndpoint = Split.getBooleanTreatment('use_express_pickup_endpoint');

        const query = createPickupQuery({ model, ...(useExpressEndpoint && { queryBuilder: buildExpressQuery }) });
        const request = axios.post(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: resolveId(response.data) });
            })
            .catch((error) => {
                reject(generateErrorMessage(error, 'add', 'Pickup'));
            });
    });

export default addPickup;
