import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

/**
 * @type EnvironmentParams['evaluationFn']
 */
const evaluationFn = (attributes) => {
    if (['PRAIRIE', 'MMPA'].includes(attributes.region) && ['admin', 'sub-admin'].includes(attributes.role)) {
        return 'on';
    }
    return 'off';
};

const environments = Object.values(stages).map(
    (stage) =>
        new Environment({
            name: stage,
            evaluationFn,
        })
);

const can_access_pooling = new MockSplit({
    name: 'can_access_pooling',
    environments,
});

export default can_access_pooling;
