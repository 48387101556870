import * as Actions from '../../actions';

const initialState = { data: null };

const processorDashboardReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_PROCESSOR_DASHBOARD_DETAILS:
            return { ...state, data: { ...action.payload } };

        default:
            return state;
    }
};

export default processorDashboardReducer;
