import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Grid } from '@material-ui/core';
import ReactHookFormInput from './ReactHookFormInput';
import ReactHookFormSearchableSelect from './ReactHookFormSearchableSelect';
import { basis, splitPortion } from './SplitProducerSelects';

const basisSymbols = {
    fixed: '$',
    percent: '%',
    total_production: '(lbs)',
};

const SplitProducerAgreementDetails = ({ field, idx }) => {
    const { watch } = useFormContext();
    const basisWatch = watch(`split_producer_agreement_details.${idx}.basis`);

    return (
        <Grid container spacing={2}>
            <Grid item xs={4}>
                <ReactHookFormSearchableSelect key={field.id} label={'Split Portion'} name={`split_producer_agreement_details.${idx}.split_portion`} options={splitPortion} required />
            </Grid>

            <Grid item xs={4}>
                <ReactHookFormSearchableSelect key={field.id} label={'Basis'} name={`split_producer_agreement_details.${idx}.basis`} options={basis} required />
            </Grid>

            <Grid item xs={4} style={{ marginTop: '8px' }}>
                <ReactHookFormInput key={field.id} label={basisWatch?.value ? `Split Rate ${basisSymbols[basisWatch.value]}` : 'Split Rate'} name={`split_producer_agreement_details.${idx}.split_rate`} type="number" required />
            </Grid>
        </Grid>
    );
};

export default SplitProducerAgreementDetails;
