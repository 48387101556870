import axios from 'axios';
import parseError from '../../errors/utils';
import { getDeleteInProgressDropoffQuery } from './inprogressDropoff.queries';

const deleteInProgressDropoff = async (id) => {
    const query = getDeleteInProgressDropoffQuery(id);

    try {
        const { data } = await axios.delete(query);

        return data;
    } catch (error) {
        return { error: parseError({ error }) };
    }
};

export default deleteInProgressDropoff;
