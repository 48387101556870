import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Formsy from 'formsy-react';
import { Button, Card, CardContent, Collapse, Grid, IconButton, Paper, Typography } from '@mui/material';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { grey } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'moment-timezone';
import { TextFieldFormsy } from '../../@fuse/components/formsy';
import * as Actions from '../store/actions';
import { DeleteDialog } from './dialogs';
import _ from 'lodash';

function getTitle(value) {
    const title = value.replace(/_/g, ' ');
    const splitTitle = title.split(' ');
    for (let i = 0; i < splitTitle.length; i += 1) {
        splitTitle[i] = splitTitle[i].charAt(0).toUpperCase() + splitTitle[i].substring(1);
    }
    return splitTitle.join(' ');
}

function isIntOrFloatStr(value) {
    return value.match(/^(?!0\d)\d*(\.\d+)?$/);
}

class CommentSection extends Component {
    state = { showComments: false, comment: '' };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { itemID, addComment, type } = this.props;
        const { comment } = this.state;
        if (comment && prevState.comment !== comment) {
            this.setState({ comment: '' });
            addComment(type, itemID, comment).then(() => this.forceFetch());
        }
    }

    onSubmit = (moment) => {
        this.setState({ comment: moment.submitComment });
    };

    editComment = (moment) => {
        const { editComment, forceFetch } = this.props;
        const { editCommentId } = this.state;
        editComment(moment, editCommentId).then(() => forceFetch());
        this.setState({ editCommentId: null });
    };

    commentLog = (edit_details) => {
        return JSON.parse(edit_details).map((current) => {
            const uuidv4 = require('uuid/v4');
            const value = current[Object.keys(current)[0]];

            if (_.isString(value)) {
                const valBefore = value.split(',')[0];
                const valAfter = value.split(',')[1];
                if (valBefore === 'null' || valBefore === 'undefined') return;

                // Check if the change in time is just a timezone difference
                if (new Date(valBefore).toUTCString() !== 'Invalid Date' && new Date(valAfter).toUTCString() !== 'Invalid Date' && new Date(valBefore).toUTCString() === new Date(valAfter).toUTCString()) return;

                if (isIntOrFloatStr(valBefore) && isIntOrFloatStr(valAfter) && parseFloat(valBefore) === parseFloat(valAfter)) return;
            }

            if (Object.keys(current)[0] === 'lab_report_ids') return; // No need to display lab report id changes on frontend

            if (Object.keys(current)[0] === 'sample_barcodes') {
                if (Object.keys(value).length <= 0) return;

                return Object.keys(value).map((key) => {
                    const barcodeBefore = value[key].split(',')[0];
                    const barcodeAfter = value[key].split(',')[1];

                    if ((barcodeBefore === 'null' || barcodeBefore === 'undefined') && (barcodeAfter === 'null' || barcodeAfter === 'undefined')) return;

                    if (barcodeBefore === 'null' || barcodeBefore === 'undefined') {
                        return (
                            <Typography
                                key={uuidv4()}
                                className="w-full"
                                style={{
                                    textAlign: 'center',
                                    fontSize: 20,
                                    color: grey['600'],
                                }}
                            >
                                {`Sample Barcode ${Number(key) + 1}: Added ${barcodeAfter}`}
                            </Typography>
                        );
                    }

                    if (barcodeAfter === 'null' || barcodeAfter === 'undefined') {
                        return (
                            <Typography
                                key={uuidv4()}
                                className="w-full"
                                style={{
                                    textAlign: 'center',
                                    fontSize: 20,
                                    color: grey['600'],
                                }}
                            >
                                {`Sample Barcode ${Number(key) + 1}: ${barcodeBefore} removed`}
                            </Typography>
                        );
                    }

                    return (
                        <Typography
                            key={uuidv4()}
                            className="w-full"
                            style={{
                                textAlign: 'center',
                                fontSize: 20,
                                color: grey['600'],
                            }}
                        >
                            {`Sample Barcode ${Number(key) + 1}: ${value[key]?.toString().replace(',', ' to ')}`}
                        </Typography>
                    );
                });
            }

            if (typeof value === 'object') return;

            if (!value.split(',')[1] || value.split(',')[1] === 'null' || value.split(',')[1] === 'undefined') {
                return (
                    <Typography
                        key={uuidv4()}
                        className="w-full"
                        style={{
                            textAlign: 'center',
                            fontSize: 20,
                            color: grey['600'],
                        }}
                    >
                        {getTitle(Object.keys(current)[0]) === 'Split Details' ? '' : `${getTitle(Object.keys(current)[0])}: ${value.split(',')[0]} removed`}
                    </Typography>
                );
            }

            if (!value.split(',')[0]) {
                return (
                    <Typography
                        key={uuidv4()}
                        className="w-full"
                        style={{
                            textAlign: 'center',
                            fontSize: 20,
                            color: grey['600'],
                        }}
                    >
                        {getTitle(Object.keys(current)[0]) === 'Split Details' ? '' : `${getTitle(Object.keys(current)[0])}: Added ${value.split(',')[1]}`}
                    </Typography>
                );
            }

            return (
                <Typography
                    key={uuidv4()}
                    className="w-full"
                    style={{
                        textAlign: 'center',
                        fontSize: 20,
                        color: grey['600'],
                    }}
                >
                    {getTitle(Object.keys(current)[0]) === 'Split Details' ? '' : `${getTitle(Object.keys(current)[0])}: ${value?.toString().replace(',', ' to ')}`}
                </Typography>
            );
        });
    };

    toggleShowComments() {
        const { showComments } = this.state;
        this.setState({ showComments: !showComments });
    }

    forceFetch() {
        const { forceFetch } = this.props;
        this.setState({ showComments: false });
        forceFetch();
    }

    render() {
        const { showComments, comment, editCommentId } = this.state;
        const { commentData, numComments, userName } = this.props;
        // eslint-disable-next-line global-require
        const uuidv4 = require('uuid/v4');

        return (
            <Paper
                className="w-full flex-col"
                style={{
                    paddingTop: 20,
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginBottom: '16px',
                }}
            >
                <div className="flex flex-row w-full">
                    <Typography style={{ fontSize: 20, alignSelf: 'center' }}>Comments</Typography>
                </div>
                {(!commentData || commentData.length === 0) && (
                    <Card className="w-full items-center border-1" style={{ backgroundColor: grey['50'], borderColor: grey['300'] }}>
                        <CardContent>
                            <Typography style={{ paddingTop: 18, fontSize: 18 }}>No comments available. Be the first!</Typography>
                        </CardContent>
                    </Card>
                )}
                {commentData && commentData.length > numComments && (
                    <div className="flex flex-row w-full justify-center">
                        <Typography className="justify-center" style={{ fontSize: 16, alignSelf: 'center' }}>
                            {showComments ? 'Show Fewer' : 'Show Older'}
                        </Typography>
                        <IconButton type="submit" variant="contained" className="m-4 ml-4" style={{ alignSelf: 'end' }} aria-label="Download Selected Month" onClick={() => this.toggleShowComments()}>
                            {!showComments && <ExpandMoreIcon />}
                            {showComments && <ExpandLessIcon />}
                        </IconButton>
                    </div>
                )}
                <Collapse className="w-full" in={showComments} timeout="auto" unmountOnExit>
                    {commentData
                        ? commentData.slice(0, commentData.length - numComments).map((value, index) => (
                              <div key={uuidv4()}>
                                  {!value.is_edit && (
                                      <Card
                                          key={uuidv4()}
                                          className="w-full items-center border-1 mb-8"
                                          style={{
                                              backgroundColor: grey['50'],
                                              borderColor: grey['300'],
                                          }}
                                      >
                                          <CardContent>
                                              <div className="flex w-full">
                                                  <Typography className="w-1/3" style={{ fontSize: 16, fontWeight: 700 }}>
                                                      {value.user}
                                                  </Typography>
                                                  <Typography
                                                      className="w-2/3"
                                                      style={{
                                                          fontSize: 14,
                                                          fontWeight: 100,
                                                          textAlign: 'right',
                                                      }}
                                                  >
                                                      {Moment(value.created_at).format('DD MMM,YYYY h:mmA')}
                                                  </Typography>
                                              </div>
                                              <div className="w-full flex flex-row items-baseline">
                                                  <Formsy
                                                      onValidSubmit={this.editComment}
                                                      /* eslint-disable-next-line no-return-assign */
                                                      ref={(form) => (this.form = form)}
                                                      className="flex flex-col justify-center w-5/6"
                                                  >
                                                      <TextFieldFormsy
                                                          className="my-16 flex-wrap"
                                                          value={value.comment}
                                                          InputProps={{
                                                              disableUnderline: !(value.id === editCommentId),
                                                              style: { color: 'black' },
                                                              multiline: true,
                                                          }}
                                                          type="text"
                                                          name="comment"
                                                          required
                                                          disabled={!(value.id === editCommentId)}
                                                      />
                                                  </Formsy>
                                                  {value.user === userName && (
                                                      <div className="w-1/6 justify-end" style={{ textAlign: 'right' }}>
                                                          <Button
                                                              /* eslint-disable-next-line no-param-reassign */
                                                              onClick={() => this.setState({ editCommentId: value.id !== editCommentId ? value.id : null })}
                                                          >
                                                              <img width="20" height="20" src="assets/MMAssets/icons/edit.png" alt="logo" />
                                                          </Button>
                                                          {value.id === editCommentId && <DeleteDialog title={<img width="20" height="20" src="assets/MMAssets/icons/bin.png" alt="logo" />} dataType="Comment" data={value.id} forceFetch={() => this.forceFetch()} />}
                                                      </div>
                                                  )}
                                              </div>
                                          </CardContent>
                                      </Card>
                                  )}
                                  {!!value.is_edit && (
                                      <div
                                          data-testid={'uneditable-comment'}
                                          className="w-full"
                                          style={{
                                              paddingTop: 20,
                                              paddingBottom: 20,
                                              backgroundColor: grey['300'],
                                          }}
                                      >
                                          <Typography
                                              className="w-full"
                                              style={{
                                                  textAlign: 'center',
                                                  fontSize: 20,
                                                  color: grey['600'],
                                              }}
                                          >
                                              {`${value.user} ${value.comment} on ${Moment(value.created_at).format('MMM DD YYYY hh:mm A')}`}
                                          </Typography>
                                          {!!value.edit_details && this.commentLog(value.edit_details)}
                                      </div>
                                  )}
                              </div>
                          ))
                        : null}
                </Collapse>
                {/* eslint-disable-next-line no-return-assign */}
                {commentData
                    ? commentData.slice(commentData.length > numComments ? commentData.length - numComments : 0).map((value, index) => (
                          <div key={uuidv4()}>
                              {!value.is_edit && (
                                  <Card className="w-full items-center border-1 mb-8" style={{ backgroundColor: grey['50'], borderColor: grey['300'] }}>
                                      <CardContent>
                                          <div className="flex w-full">
                                              <Typography className="w-1/3" style={{ fontSize: 16, fontWeight: 700 }}>
                                                  {value.user}
                                              </Typography>
                                              <Typography
                                                  className="w-2/3"
                                                  style={{
                                                      fontSize: 14,
                                                      fontWeight: 100,
                                                      textAlign: 'right',
                                                  }}
                                              >
                                                  {Moment(value.created_at).format('DD MMM, YYYY h:mmA')}
                                              </Typography>
                                          </div>
                                          <div className="w-full flex flex-row items-baseline">
                                              <Formsy
                                                  onValidSubmit={this.editComment}
                                                  /* eslint-disable-next-line no-return-assign */
                                                  ref={(form) => (this.form = form)}
                                                  className="flex flex-col justify-center w-5/6"
                                              >
                                                  <TextFieldFormsy
                                                      className="my-16 flex-wrap"
                                                      value={value.comment}
                                                      InputProps={{
                                                          disableUnderline: !(value.id === editCommentId),
                                                          style: { color: 'black' },
                                                          multiline: true,
                                                      }}
                                                      type="text"
                                                      name="comment"
                                                      required
                                                      disabled={!(value.id === editCommentId)}
                                                  />
                                                  {value.id === editCommentId && (
                                                      <Button type="submit" variant="contained" color="secondary" className="m-4 h-48" style={{ justifySelf: 'center' }}>
                                                          Submit
                                                      </Button>
                                                  )}
                                              </Formsy>
                                              {value.user === userName && (
                                                  <div className="w-1/6 justify-end" style={{ textAlign: 'right' }}>
                                                      <Button onClick={() => this.setState({ editCommentId: value.id !== editCommentId ? value.id : null })}>
                                                          <img width="20" height="20" src="assets/MMAssets/icons/edit.png" alt="logo" />
                                                      </Button>
                                                      {value.id === editCommentId && <DeleteDialog title={<img width="20" height="20" src="assets/MMAssets/icons/bin.png" alt="logo" />} dataType="Comment" data={value.id} forceFetch={() => this.forceFetch()} />}
                                                  </div>
                                              )}
                                          </div>
                                      </CardContent>
                                  </Card>
                              )}
                              {!!value.is_edit && (
                                  <div
                                      className="w-full"
                                      style={{
                                          paddingTop: 20,
                                          paddingBottom: 20,
                                          backgroundColor: grey['300'],
                                      }}
                                  >
                                      <Typography
                                          className="w-full"
                                          style={{
                                              textAlign: 'center',
                                              fontSize: 20,
                                              color: grey['600'],
                                          }}
                                      >
                                          {`${value.user} ${value.comment} on ${Moment(value.created_at).format('MMM DD YYYY hh:mm A')}`}
                                      </Typography>
                                      {value.edit_details && this.commentLog(value.edit_details)}
                                  </div>
                              )}
                          </div>
                      ))
                    : null}
                <Formsy
                    onValidSubmit={this.onSubmit}
                    /* eslint-disable-next-line no-return-assign */
                    ref={(form) => (this.form = form)}
                    className="flex flex-row justify-center w-full"
                    style={{ paddingTop: 20 }}
                >
                    <Grid container spacing={2} mb={4}>
                        <Grid item xs={9}>
                            <TextFieldFormsy className="w-full" value={comment} label="Add a New Comment" type="text" name="submitComment" InputProps={{ height: '100%', style: { color: 'black' }, multiline: true }} variant="outlined" disableAutoComplete style={{ justifySelf: 'center' }} />
                        </Grid>
                        <Grid item xs={3}>
                            <Button fullWidth data-testid={'comment-section-submit'} type="submit" variant="contained" color="primary" style={{ justifySelf: 'center', height: '100%' }}>
                                Submit
                            </Button>
                        </Grid>
                    </Grid>
                </Formsy>
            </Paper>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ addComment: Actions.addComment, editComment: Actions.editComment }, dispatch);
}

function mapStateToProps({ persisted }) {
    return { userName: persisted.auth.user.data.name };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommentSection));
