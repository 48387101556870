import axios from 'axios';
import _ from 'lodash';
import * as moment from 'moment-timezone';
import { getProcessorsQuery } from './queries';
import { getProcessorDropoffDetails } from '.';
import { getMonthDateRange, numberFormat } from '../../utils';

const getDFNSReportInfo = (month, year) =>
    new Promise((resolve, reject) => {
        const processorsQuery = getProcessorsQuery({ fields: ['id', 'name'] });
        const processorRequest = axios.get(processorsQuery);

        processorRequest.then((processorResponse) => {
            const processors = processorResponse.data;
            const truro = _.find(processors, { name: 'Truro' });
            const sussex = _.find(processors, { name: 'Sussex' });
            if (!truro || !sussex) {
                resolve([]);
            } else {
                const dates = getMonthDateRange(year, month);
                const truroDropoffDetails = getProcessorDropoffDetails(truro.id, dates.start, dates.end);
                const sussexDropoffDetails = getProcessorDropoffDetails(sussex.id, dates.start, dates.end);
                Promise.all([truroDropoffDetails, sussexDropoffDetails]).then((payloads) => {
                    const truroDropoffs = payloads[0].dropoffs;
                    const sussexDropoffs = payloads[1].dropoffs;
                    const dropoffs = _.orderBy([...truroDropoffs, ...sussexDropoffs], ['created_at'], ['asc']);

                    let reportData = _.map(dropoffs, (d) => {
                        const labValues = {
                            fat: _.round(d.components.fat, 2),
                            protein: _.round(d.components.protein, 2),
                            LOS: _.round(d.components.LOS, 2),
                        };

                        return {
                            'Load No': d.run_number ? d.run_number : '-',
                            'Delivery Date': moment(d.created_at).format('M/DD/YYYY'),
                            'Source Province': 'NL',
                            'Load Status': 'SURPLUS',
                            'Meter Volume': numberFormat(d.metered, 2),
                            'Sample Date': moment(d.created_at).format('M/DD/YYYY'),
                            'BF Test': !!labValues.fat ? labValues.fat : '-',
                            'Protein Test': !!labValues.protein ? labValues.protein : '-',
                            'LOS Test': !!labValues.LOS ? labValues.LOS : '-',
                            'BF KGs': !!labValues.fat ? _.round(d.metered * (labValues.fat / 100), 2) : '-',
                            'Protein KGs': !!labValues.protein ? _.round(d.metered * (labValues.protein / 100), 2) : '-',
                            'LOS KGs': !!labValues.LOS ? _.round(d.metered * (labValues.LOS / 100), 2) : '-',
                            Period: moment(d.created_at).format('YYYYMM'),
                            'Plant ID': '242',
                        };
                    });

                    const FatKgTotal = _.sumBy(reportData, (d) => d['BF KGs']);
                    const ProKgTotal = _.sumBy(reportData, (d) => d['Protein KGs']);
                    const LOSKgTotal = _.sumBy(reportData, (d) => d['LOS KGs']);

                    const fat4t = 9.5255;
                    const pro4t = 5.9625;
                    const los4t = 5.9625;

                    const totalsRow = {
                        'Load No': 'Total Volume, Components',
                        'Delivery Date': '',
                        'Source Province': '',
                        'Load Status': '',
                        'Meter Volume': numberFormat(
                            _.sumBy(dropoffs, (d) => d.metered),
                            2
                        ),
                        'Sample Date': '',
                        'BF Test': '',
                        'Protein Test': '',
                        'LOS Test': '',
                        'BF KGs': numberFormat(FatKgTotal, 2),
                        'Protein KGs': numberFormat(ProKgTotal, 2),
                        'LOS KGs': numberFormat(LOSKgTotal, 2),
                        Period: '',
                        'Plant ID': '',
                    };

                    const tRow = {
                        'Load No': '4(t) Prices',
                        'Delivery Date': '',
                        'Source Province': '',
                        'Load Status': '',
                        'Meter Volume': '',
                        'Sample Date': '',
                        'BF Test': '',
                        'Protein Test': '',
                        'LOS Test': '',
                        'BF KGs': fat4t,
                        'Protein KGs': pro4t,
                        'LOS KGs': los4t,
                        Period: '',
                        'Plant ID': '',
                    };

                    const FatValueTotal = FatKgTotal * fat4t;
                    const ProValueTotal = ProKgTotal * pro4t;
                    const LOSValueTotal = LOSKgTotal * los4t;

                    const calculatedTotalsRow = {
                        'Load No': 'Total Value',
                        'Delivery Date': '',
                        'Source Province': 'Payable to DFNL',
                        'Load Status': '',
                        'Meter Volume': `$ ${numberFormat(FatValueTotal + ProValueTotal + LOSValueTotal, 2)}`,
                        'Sample Date': '',
                        'BF Test': '',
                        'Protein Test': '',
                        'LOS Test': '',
                        'BF KGs': numberFormat(FatValueTotal, 2),
                        'Protein KGs': numberFormat(ProValueTotal, 2),
                        'LOS KGs': numberFormat(LOSValueTotal, 2),
                        Period: '',
                        'Plant ID': '',
                    };

                    reportData = [...reportData, {}, totalsRow, {}, {}, tRow, calculatedTotalsRow];
                    resolve(reportData);
                });
            }
        });
    });

export default getDFNSReportInfo;
