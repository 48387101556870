import axios from 'axios';
import { getSchedulingStatsQuery } from './queries';

const getSchedulingStats = async (processorIds, start, end) => {
    try {
        const schedulingPerformanceQuery = getSchedulingStatsQuery({ processorIds, start, end });
        const schedulingPerformanceRequest = axios.get(schedulingPerformanceQuery);

        const { data } = await schedulingPerformanceRequest;

        return {
            summaryData: data.summaryData,
            processorData: data.processorData,
            routeData: data.routeData,
        };
    } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getSchedulingStats;
