import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { TextField } from '@material-ui/core';

const SearchableSelect = ({ options, label, onChange, value, multiple, disableClearable, variant, margin, error, name, required, disabled, hideCheckbox, closeOnSelect, getOptionDisabled = false, dataTestId = '' }) => {
    const [inputValue, setInputValue] = useState('');
    const [open, setOpen] = useState(false);

    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const getOptionLabel = (option) => {
        if (option?.name && typeof option.name === 'string') return option.name;
        if (option && typeof option === 'string') return option;
        return '';
    };

    return (
        <div>
            {options && (
                <Autocomplete
                    value={value}
                    multiple={multiple}
                    fullWidth
                    disableCloseOnSelect
                    disableClearable={disableClearable}
                    disabled={disabled}
                    limitTags="1"
                    id="combo-box"
                    options={options}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    onFocus={() => setOpen(true)}
                    open={open}
                    getOptionDisabled={getOptionDisabled}
                    // TODO this should only be option.name - this handles edge cases where usage pattern in deprecated
                    getOptionLabel={getOptionLabel}
                    renderOption={(option, { selected }) => (
                        <>
                            {!hideCheckbox && <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />}
                            {getOptionLabel(option)}
                        </>
                    )}
                    onChange={(event, val) => {
                        if (val && closeOnSelect) {
                            setOpen(false);
                        }
                        onChange(event, val);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => <TextField margin={margin || 'normal'} {...params} label={label} variant={variant || 'standard'} error={error} required={required} name={name} />}
                    data-testid={dataTestId === '' ? null : dataTestId}
                />
            )}
        </div>
    );
};

export default SearchableSelect;
