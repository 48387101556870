import store from 'app/store';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { FuseUtils } from '@fuse/index';
import AppsConfig from 'app/main/apps/config';
import PagesConfig from 'app/main/pages/config';

const routeConfigs = [...AppsConfig, ...PagesConfig];

const routes = [
    ...FuseUtils.generateRoutesFromConfigs(routeConfigs),
    {
        path: '/',
        exact: true,
        component: () => <Redirect to="/login" />,
    },

    // TODO: Insert this 404 error handler back in once each page has been setup, let it default on error for now

    { component: () => (store.getState().persisted.auth.user.role !== 'guest' ? <Redirect to="/error-404" /> : <Redirect to="/login" />) },
];

export default routes;
