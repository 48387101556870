/**
 * Handles displaying errors returned from the backend
 *
 * If statement handles inconsistencies from validation errors and CreateError
 *
 * @param {error} error object returned
 * @param {string} action action the user is doing against type (add/edit/etc...)
 * @param {string} type type of item action is being performed on
 */

const generateErrorMessage = (error, action, type) => {
    let errorMessage = `Unable to ${action} ${type}`;
    if (!error.response && error.message) {
        errorMessage = error.message;
    } else if (error?.response?.data?.errors?.message) {
        errorMessage = error.response.data.errors.message;
    } else if (error?.response?.data?.message) {
        errorMessage = error.response.data.message;
    }
    return new Error(errorMessage);
};

export default generateErrorMessage;
