import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import { isEmpty } from 'lodash';

import SearchableSelectAll from '../../custom-widgets/SearchableSelectAll';
import getHistory from '../../repository/getHistory';

import FieldLocalizer from './utils/FieldLocalizer';
import DocumentHistoryCollapse from './DocumentHistoryCollapse';
import { toSentenceCase } from '../../../utils';

const DocumentHistoryList = ({ id, type, showFilterControls, height }) => {
    const [documentHistory, setDocumentHistory] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);
    const [filterFields, setFilterFields] = useState();
    const [filter, setFilter] = useState([]);

    useEffect(() => {
        const loadData = async () => {
            try {
                setIsLoading(true);
                const histories = await getHistory(id, type);
                const fields = [];
                const mapped = histories.map((history) => {
                    if (history.event !== 'create') {
                        const properties = Object.keys(history.content);
                        properties.forEach((prop) => {
                            const localizedProp = toSentenceCase(FieldLocalizer(prop));
                            if (
                                fields.findIndex((field) => {
                                    return field.name === localizedProp;
                                }) === -1
                            ) {
                                fields.push({ name: localizedProp, value: prop });
                            }
                        });
                    }
                    const modified = { ...history, show: true, collapse: false };
                    return modified;
                });
                setDocumentHistory(mapped);
                const sorted = fields.sort((a, b) => {
                    return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
                });
                setFilterFields(sorted);
                setIsLoading(false);
            } catch (e) {
                setIsLoading(false);
                setError(true);
            }
        };
        loadData();
    }, []);

    useEffect(() => {
        const filterHistories = () => {
            const filtered = documentHistory.map((history) => {
                const fields = Object.keys(history.content);
                if (isEmpty(filter)) {
                    return { ...history, show: true, collapse: false };
                }
                let foundField = false;
                fields.forEach((field) => {
                    if (filter.includes(field)) {
                        foundField = true;
                    }
                });
                if (foundField) {
                    return { ...history, show: true, collapse: true };
                }
                return { ...history, show: false, collapse: false };
            });
            setDocumentHistory(filtered);
        };
        if (!isLoading) {
            filterHistories();
        }
    }, [filter]);

    const handleFilterChange = (event, value) => {
        setFilter(value.map((v) => v.value));
    };

    if (isLoading) {
        return <Typography style={{ textAlign: 'center' }}>Loading...</Typography>;
    }

    if (!isLoading && error) {
        return <Typography style={{ textAlign: 'center' }}>Error getting activity log, please contact support.</Typography>;
    }

    if (!isLoading && isEmpty(documentHistory)) {
        return <Typography style={{ textAlign: 'center' }}>There is no activity log to display.</Typography>;
    }

    if (!isLoading && documentHistory) {
        return (
            <>
                {showFilterControls && (
                    <Box width="60%" marginX={3}>
                        <SearchableSelectAll multiple label="Filter by Fields" options={filterFields} onChange={handleFilterChange} />
                    </Box>
                )}
                {/* Scroll requires a fixed height that should be retrieved from the parent component */}
                <Box sx={{ height: height - 100, overflowY: 'scroll' }}>
                    {documentHistory.map((history, index) => {
                        return <DocumentHistoryCollapse key={`DocumentHistoryCollapse${index + 1}`} index={index} item={history} />;
                    })}
                </Box>
            </>
        );
    }

    return <>No Activity Log to Display</>;
};

export default DocumentHistoryList;
