import Card from '@material-ui/core/Card';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { getPenaltyStatusImage } from '../../utils';
import _ from 'lodash';

function ProducerPenaltyStatusBox({ data, region, clickEvent }) {
    const uuidv4 = require('uuid/v4');

    const [locations, setLocations] = useState(data?.locations || []);

    function getImage(reg, status, type, failsQuality) {
        let source = null;
        let numStatus = null;
        switch (status) {
            case 'INFRACTION':
                numStatus = 1;
                break;
            case 'WARNING':
                numStatus = 2;
                break;
            case 'PENALTY':
                numStatus = 3;
                break;
            case 'SAFE':
                numStatus = 0;
                // for CDI only
                // if safe but failsQuality is true, then warning
                if (failsQuality) {
                    numStatus = 2;
                    break;
                }
                break;
            default:
                numStatus = status;
                break;
        }

        // eslint-disable-next-line default-case
        switch (type) {
            case 'scc':
                source = getPenaltyStatusImage(region, numStatus, 'scc');
                break;
            case 'spc':
                source = getPenaltyStatusImage(region, numStatus, 'spc');
                break;
            case 'lpc':
                source = getPenaltyStatusImage(region, numStatus, 'lpc');
                break;
            case 'coliform':
                source = getPenaltyStatusImage(region, numStatus, 'coliform');
                break;
            case 'bacto':
                source = getPenaltyStatusImage(region, status, 'bacto_scan');
                break;
            case 'temperature':
                source = getPenaltyStatusImage(region, status, 'temperature');
                break;
        }

        return <img className="max-w-96 max-h-36" src={source} alt="logo" />;
    }

    return (
        <Card className="w-full rounded-8 shadow-none border-1 h-full">
            <div className="flex items-center justify-between px-16 pt-16">
                <Typography className="text-20 font-bold">{`${['UDA'].includes(region) ? 'Quality' : 'Penalty'} Status`}</Typography>
            </div>
            <div className="flex flex-col container justify-center items-center" style={{ paddingLeft: 10 }}>
                {Object.keys(data).map((entry) => (
                    <div key={uuidv4()}>
                        {['PEI'].includes(region) && ['scc', 'bacto', 'temperature'].includes(entry) && (
                            <div className="w-full items-center justify-center text-center py-24">
                                <Typography className="h2 sm:h6 font-bold text-center whitespace-no-wrap" color="inherit">
                                    {data[entry].label}
                                </Typography>
                                <div className="flex flex-col items-center justify-center" style={{ paddingTop: 10 }}>
                                    <IconButton onClick={clickEvent} className="w-88 h-32 float-left px-20">
                                        {getImage(region, data[entry].status, entry)}
                                    </IconButton>
                                    <Typography className="h1 font-500 float-left text-center" color="inherit">
                                        {data[entry].value ? data[entry].value.toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}
                                    </Typography>
                                    <div className="flex flex-col items-center justify-center" style={{ paddingTop: 10 }}>
                                        <IconButton onClick={clickEvent} className="w-88 h-32 float-left px-20">
                                            {getImage(region, data[entry].status, entry)}
                                        </IconButton>
                                        <Typography className="h1 font-500 float-left text-center" color="inherit">
                                            {data[entry].value ? data[entry].value.toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        )}
                        {['NL'].includes(region) && ['scc', 'bacto'].includes(entry) && (
                            <div className="w-full items-center justify-center text-center py-24 px-32">
                                <Typography className="h1 font-500 text-center px-12 leading-tight" color="inherit">
                                    {data[entry].label}
                                </Typography>
                                <div className="flex flex-col items-center justify-center" style={{ paddingTop: 10 }}>
                                    <IconButton onClick={clickEvent} className="w-88 h-32 float-left px-20">
                                        {getImage(region, data[entry].status, entry)}
                                    </IconButton>
                                    <Typography className="h3 font-500 float-left text-center" color="inherit">
                                        {data[entry].value ? data[entry].value.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%
                                    </Typography>
                                </div>
                            </div>
                        )}
                        {['CDI'].includes(region) && ['scc', 'spc', 'lpc', 'coliform'].includes(entry) && (
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <div className="w-full items-center justify-center text-center py-12 px-12">
                                        <Typography className="h2 font-500 text-center px-12 leading-tight" color="textSecondary">
                                            {data[entry].label}
                                        </Typography>
                                        <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                                            <IconButton onClick={clickEvent} className="h2 font-500 text-center px-12 leading-tight">
                                                {getImage(region, data[entry].status, entry, data[entry].failsQuality)}
                                            </IconButton>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                    </div>
                ))}
                <div>
                    {['UDA'].includes(region) && (
                        <div>
                            {_.map(locations, (location) => {
                                return (
                                    <div className="w-full items-center justify-center text-center py-24 px-32">
                                        <Typography className="h1 font-500 text-center px-12 leading-tight" color="inherit">
                                            Location {location.location}
                                        </Typography>
                                        <div className="flex flex-col items-center justify-center">
                                            <Typography className="h3 font-500 float-left text-center" style={{ color: location.status === 'PASS' ? 'green' : 'red' }}>
                                                {location.status}
                                            </Typography>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </Card>
    );
}

export default React.memo(ProducerPenaltyStatusBox);
