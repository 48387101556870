import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Tabs as MUITabs, Tab } from '@mui/material';

const useTabs = (tabs) => {
    const history = useHistory();
    const [currentTab, setCurrentTab] = useState(0);

    useEffect(() => {
        const initialTab = tabs.findIndex(({ url }) => history.location.pathname.includes(url));
        if (initialTab >= 0) setCurrentTab(initialTab);
    }, []);

    const handleTabChange = (_e, value) => {
        if (tabs[value].url) history.replace(tabs[value].url);
        setCurrentTab(value);
    };

    const Tabs = useMemo(
        () => (
            <MUITabs value={currentTab} onChange={handleTabChange} aria-label="basic tabs example">
                {tabs.map(({ label }, index) => (
                    <Tab label={label} value={index} key={label} />
                ))}
            </MUITabs>
        ),
        [tabs, currentTab]
    );

    return {
        currentTab,
        setCurrentTab,
        Tabs,
    };
};

export default useTabs;
