import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import axios from 'axios';
import * as FuseActions from 'app/store/actions';
import { bindActionCreators } from 'redux';
import { getAPIHost, resolveId } from '../../../utils';

function PaperComponent(props) {
    return (
        // <Draggable>
        <Paper {...props} />
        // </Draggable>
    );
}

class RestoreDialog extends React.Component {
    state = { open: false };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleRestore = (variables, { showMessage, forceFetch }) => {
        axios
            .patch(`${getAPIHost() + variables[0]}/${variables[1]}`)
            .then(() => {
                showMessage({ message: 'Successful. This may take a few seconds to reflect on your page.' });
                forceFetch();
            })
            .catch((err) => {
                showMessage({ message: 'There was a problem during restoration. Please try again later.' });
            });
        this.setState({ open: false });
    };

    getVariables = (data, dataType) => {
        switch (dataType) {
            case 'Admin':
            case 'Driver':
            case 'Hauling Company':
            case 'Processor':
            case 'Producer':
            case 'Sub Producer':
            case 'Sub User':
            case 'Receiver':
                return ['/restore-user', data.id];
            case 'Trailer':
                return ['/restore-trailer', data.id];
            default:
                return [];
        }
    };

    render() {
        const { title, data, color, dataType } = this.props;
        const { open } = this.state;

        return (
            <div>
                <Button variant="contained" color={color || 'primary'} className="my-16" aria-label={title} onClick={this.handleClickOpen}>
                    {title}
                </Button>
                <Dialog open={open} onClose={this.handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
                    <DialogTitle id="draggable-dialog-title">Restore</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-black">Restore {dataType}?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleRestore(this.getVariables(resolveId(data), dataType), { ...this.props, ...this.state })} variant="contained" color="secondary">
                            Restore
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps({ persisted }) {
    return { region: persisted.auth.user.data.region };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: FuseActions.showMessage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(RestoreDialog);
