import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, DialogContentText } from '@material-ui/core';
import LockPage from '../../main/pages/auth/LockPage';
import { isUserGuest, logoutUser, lockUser } from '../../auth/store/actions';
import store from '../../store';

const LOCKOUT_TIME_IN_MINUTES = 60;
const COUNTDOWN_TIME_IN_SECONDS = 30;
const STORE_KEY = 'lastAction';

class TimeoutDialog extends Component {
    state = {
        open: false,
        timeLeft: COUNTDOWN_TIME_IN_SECONDS,
        timerIsGoing: false,
        isLocked: false,
    };

    componentDidMount() {
        this.setLastAction(Date.now());
        const listener = () => {
            if (this.reset()) {
                this.autoLockout();
            }
        };
        document.body.addEventListener('click', listener);
        document.body.addEventListener('mousemove', listener);
        window.addEventListener('resize', this.resize);
        this.initInterval();
        if (sessionStorage.getItem('areWeLocked') === 'locked') {
            this.autoLockout();
        }
    }

    componentDidUpdate() {
        const { isLocked } = this.state;
        if (Date.now() - this.getLastAction() > 60 * 1000 * LOCKOUT_TIME_IN_MINUTES + COUNTDOWN_TIME_IN_SECONDS * 1000 && store.dispatch(isUserGuest()) && !isLocked) {
            this.autoLockout();
        }
    }

    componentWillUnmount() {
        const { isLocked } = this.state;
        clearInterval(this.intervalID2);
        clearInterval(this.intervalID);
        window.removeEventListener('resize', this.resize);
        const listener = () => {
            if (this.reset()) {
                this.autoLockout();
            }
        };
        document.body.removeEventListener('click', listener);
        document.body.removeEventListener('mousemove', () => listener);
        if (isLocked) {
            sessionStorage.setItem('areWeLocked', 'locked');
        }
    }

    logOutUser = () => {
        store.dispatch(logoutUser());
        this.unlockUser();
    };

    getLastAction = () => sessionStorage.getItem(STORE_KEY);

    setLastAction = (value) => {
        sessionStorage.setItem(STORE_KEY, parseInt(value));
    };

    resize = () => this.setState({});

    unlockUser = () =>
        new Promise((resolve) => {
            this.setLastAction();
            sessionStorage.setItem('areWeLocked', 'unlocked');
            this.setState({ isLocked: false });
            this.initInterval();
            resolve();
        });

    handleClose = () => {
        this.resetTimeLeft();
    };

    handleClickOpen = ({ timerIsGoing }) => {
        if (!timerIsGoing) {
            this.startCountdown();
            this.setState({ open: true, timerIsGoing: true });
        }
    };

    reset() {
        const { isLocked } = this.state;
        if (Date.now() - this.getLastAction() > 60 * 1000 * LOCKOUT_TIME_IN_MINUTES + COUNTDOWN_TIME_IN_SECONDS * 1000 && store.dispatch(isUserGuest()) && !isLocked) {
            return true;
        }
        this.setLastAction(Date.now());
    }

    startCountdown() {
        this.intervalID2 = setInterval(() => {
            const { timeLeft } = this.state;
            if (timeLeft <= 0) {
                this.resetTimeLeft();
                this.autoLockout();
            } else {
                this.setState({ timeLeft: timeLeft - 1 });
            }
        }, 1000);
    }

    autoLockout() {
        const { isLocked } = this.state;
        if (!isLocked) {
            clearInterval(this.intervalID);
            clearInterval(this.intervalID2);
            this.lockUser();
        }
    }

    lockUser() {
        const username = localStorage.getItem('mm.login.currentUsername');
        // Avoids lock screen when username is not set (includes SSO scenarios)
        if (username) {
            lockUser();
            this.setState({ isLocked: true, open: false });
            sessionStorage.setItem('areWeLocked', 'locked');
        } else {
            this.logOutUser();
        }
        clearInterval(this.intervalID);
        clearInterval(this.intervalID2);
    }

    initInterval() {
        this.intervalID = setInterval(() => {
            if (Date.now() - this.getLastAction() > 60 * 1000 * LOCKOUT_TIME_IN_MINUTES && store.dispatch(isUserGuest())) {
                this.render();
                this.handleClickOpen(this.state);
            }
        }, 1000);
    }

    resetTimeLeft() {
        this.setState({
            open: false,
            timerIsGoing: false,
            timeLeft: COUNTDOWN_TIME_IN_SECONDS,
        });
        clearInterval(this.intervalID2);
    }

    render() {
        const { children } = this.props;
        const { isLocked, open, timeLeft } = this.state;

        if (!isLocked) {
            return (
                /* eslint react/jsx-fragments:0 */
                <React.Fragment>
                    {children}
                    <div className="inline-flex">
                        <Dialog open={open} onClose={this.handleClose} fullWidth maxWidth="sm">
                            <DialogTitle id="draggable-dialog-title" aria-setsize={16}>
                                Are you still there?
                            </DialogTitle>
                            <DialogContent>
                                <DialogContentText className="text-black text-16">Your session will be logged out in: {timeLeft} seconds</DialogContentText>
                            </DialogContent>
                            <div className="flex items-center justify-center">
                                <Button style={{ fontSize: window.innerWidth > 506 ? '24px' : window.innerWidth > 398 ? '20px' : '14px' }} type="submit" variant="contained" color="secondary" className="m-4 ml-4 flex w-2/3 h-60 " aria-label="Stay Logged In" onClick={this.handleClose}>
                                    Stay Logged In
                                </Button>
                            </div>
                        </Dialog>
                    </div>
                </React.Fragment>
            );
        }
        return <LockPage onUnlock={() => this.unlockUser()} onLogout={() => this.logOutUser()} />;
    }
}

export default TimeoutDialog;
