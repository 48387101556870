import _ from 'lodash';

const batchQuery = (queryFn, params, batchField, batchSize = 75) => {
    if (!params[batchField] || !_.isArray(params[batchField])) {
        return [queryFn(params)];
    }
    return _.map(_.chunk(params[batchField], batchSize), (chunk) => queryFn({ ...params, [batchField]: chunk }));
};

export default batchQuery;
