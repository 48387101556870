import axios from 'axios';
import { getAdminsQuery } from './queries';

const getAdminListDetails = (includeDeleted, filterSubUsers = true) =>
    new Promise((resolve, reject) => {
        const fields = ['id', 'name', 'created_at', 'updated_at', 'username', 'email', 'secondary_email', 'deleted_at', 'permissions', 'equity_approval'];
        const params = { fields, includeDeleted, filterSubUsers };
        const query = getAdminsQuery(params);
        const request = axios.get(query);

        request
            .then((response) => {
                resolve({ admins: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getAdminListDetails;
