import axios from 'axios';
import { getSplitProducerQuery } from './queries';

const getSplitProducerListDetails = async () => {
    try {
        const splitProducerQuery = getSplitProducerQuery({
            fields: ['parent_producer', 'child_producer', 'split_producer_agreement_details', 'start_date', 'end_date'],
        });
        const splitProducerResponse = await axios.get(splitProducerQuery);
        const splitProducers = splitProducerResponse.data;
        return { splitProducers };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getSplitProducerListDetails;
