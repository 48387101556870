import axios from 'axios';
import { buildMembershipQuery } from './queries/builders';
import { getParentChildProducerIds } from './index';

const getEquityValues = async (id, type) => {
    try {
        const { isSplitProducer, parentProducerId, childProducerIds } = await getParentChildProducerIds(id);

        const promises = [];

        [parentProducerId, ...childProducerIds].forEach((producerId) => {
            const membershipQuery = `/v1/equityMembers/${producerId}/equityValues`;

            const equityMembershipQuery = buildMembershipQuery(membershipQuery, {
                headers: {},
                data: {},
                query: { type },
            });

            promises.push(axios.get(equityMembershipQuery.url));
        });

        const responses = await Promise.all(promises);

        const equityValues = responses.reduce((acc, { data }) => {
            return acc.concat(data);
        }, []);

        return {
            isSplitProducer,
            equityValues,
        };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw new Error('Unable to process request.');
    }
};

export default getEquityValues;
