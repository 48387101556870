import { FuseLoadable } from '@fuse';

export default {
    auth: ['admin', 'sub-admin'],
    routes: [
        {
            path: '/loan-management/loans/create-loan',
            component: FuseLoadable({
                loader: () => {
                    return import('./loans/MutateLoan');
                },
            }),
        },
        {
            path: '/loan-management/loans/edit-loan/:id',
            component: FuseLoadable({
                loader: () => {
                    return import('./loans/MutateLoan');
                },
            }),
        },
        {
            path: '/loan-management/loans/view-loan/:id',
            component: FuseLoadable({
                loader: () => {
                    return import('./loans/ViewLoan');
                },
            }),
        },
        {
            path: '/loan-management',
            component: FuseLoadable({
                loader: () => {
                    return import('./LoanManagement');
                },
            }),
        },
    ],
};
