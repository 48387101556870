import axios from 'axios';
import parseError from '../../errors/utils';
import { getActiveInProgressDropoffQuery } from './inprogressDropoff.queries';

const getActiveInprogressDropoffs = async (props) => {
    try {
        const query = getActiveInProgressDropoffQuery(props);
        const response = await axios.get(query);

        return response;
    } catch (error) {
        return { error: parseError({ error }) };
    }
};

export default getActiveInprogressDropoffs;
