import React, { useEffect, useRef, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Button, Grid, Box, Typography, Divider, IconButton, Dialog, DialogActions, Accordion, AccordionDetails, AccordionSummary, DialogTitle } from '@material-ui/core';
import { AddBox, Delete, ExpandMore } from '@material-ui/icons';
import _, { parseInt } from 'lodash';
import SwitchWithLabel from './SwitchWithLabel';
import ReactHookFormInput from './ReactHookFormInput';
import ReactHookFormSearchableSelect from './ReactHookFormSearchableSelect';
import { getRateTypesForContract, basis, getHaulingTypesBasisParameter, basisStopCharge, pickupRelatedHaulingBasis, fullMonthSelectOptions, displayMonthlyMaxCharges, displayMaxMinCharges, getMilkTypeOptions } from './HaulingContractSelectOptions';
import { Stack } from '@mui/material';
import Region from '../../constants/region';

const HaulingContractDetails = ({ editData, supplemental, region }) => {
    const { control, watch, getValues, reset, formState } = useFormContext();
    const { fields, remove, append } = useFieldArray({
        control,
        name: 'hauling_rate_details',
    });

    const [deleteModal, setDeleteModal] = useState(false);
    const [expanded, setExpanded] = useState([]);
    const deleteIdx = useRef(0);
    const rateTypeHistory = useRef([]);
    const rateBasisHistory = useRef([]);
    const rateType = watch(fields.map((_field, idx) => `hauling_rate_details.${idx}.type`));
    const rateBasis = watch(fields.map((_field, idx) => `hauling_rate_details.${idx}.basis`));

    const type = getRateTypesForContract(region, supplemental);
    const haulingTypesBasisParameter = getHaulingTypesBasisParameter(region, supplemental);

    useEffect(() => {
        if (editData) setExpanded([editData.rate_id]);
    }, []);

    useEffect(() => {
        if (!formState.errors?.hauling_rate_details) return;
        const errorKeys = Object.keys(formState.errors.hauling_rate_details).map((str) => parseInt(str));
        setExpanded(_.sortedUniq([...expanded, ...errorKeys].sort()));
    }, [formState.errors]);

    useEffect(() => {
        // Need to get the latest values, `fields` seems to be one update behind the form
        const data = getValues('hauling_rate_details');
        let changed = false;
        rateBasis.forEach((item, idx) => {
            if (item?.value !== 'cwt' && rateBasisHistory.current?.[idx]?.value === 'cwt') {
                data[idx].basis_parameter = undefined;
                changed = true;
            }

            // Reset the fields every time they are hidden to ensure the value doesn't persist
            if (!displayMaxMinCharges(rateBasis[idx])) {
                data[idx].min_charge = '';
                data[idx].max_charge = '';
            }

            if (!displayMonthlyMaxCharges(rateBasis[idx])) {
                data[idx].rate_max_charge_per_period = '';
            }

            if (!pickupRelatedHaulingBasis.has(item?.value)) {
                data[idx].exclude_pickups_before_date = undefined;
            }
        });

        // setValue does not propagate updates correctly so we need to reset the form with the updated changes
        if (changed) reset({ ...getValues(), hauling_rate_details: data });
        rateBasisHistory.current = rateBasis;
    }, [rateBasis]);

    useEffect(() => {
        const data = getValues('hauling_rate_details');
        let changed = false;
        rateType.forEach((item, idx) => {
            if (!rateTypeHistory.current[idx]) return;

            if (item?.value !== rateTypeHistory.current[idx]?.value) {
                data[idx].basis = undefined;
                changed = true;
            }
        });
        if (changed) reset({ ...getValues(), hauling_rate_details: data });
        rateTypeHistory.current = rateType;
    }, [rateType]);

    const onAddClick = () => {
        setExpanded([...expanded, fields.length]);
        append({
            type: null,
            basis: null,
            basis_parameter: null,
            paid_by_producer: ![Region.UDA].includes(region),
            rate: '',
            min_charge: '',
            max_charge: '',
            exclude_pickups_before_date: null,
        });
    };

    const onDeleteClick = (idx) => {
        deleteIdx.current = idx;
        setDeleteModal(true);
    };

    const onDeleteComfirmation = (confirmation) => {
        if (confirmation) {
            remove(deleteIdx.current);
            const removeIndex = expanded.indexOf(deleteIdx.current);
            const updated = expanded.filter((idx) => idx > deleteIdx.current).map((idx) => idx - 1);
            if (removeIndex === -1) expanded.splice(-updated.length, updated.length, ...updated);
            else expanded.splice(removeIndex, updated.length + 1, ...updated);
            setExpanded(expanded);
        }
        setDeleteModal(false);
    };

    const onAccordionClick = (idx) => {
        if (expanded.includes(idx)) setExpanded(expanded.filter((accordion) => accordion !== idx));
        else setExpanded([...expanded, idx].sort());
    };

    const shouldDisplayMilkPrices = (idx) => {
        if (['per_pickup', 'per_load'].includes(rateBasis[idx]?.value)) {
            return false;
        }
        if (rateBasis[idx]?.value !== 'fixed' && rateType[idx]?.value !== 'stop_charge') {
            return true;
        }
        return false;
    };

    const getRateTypeOptions = (rateTypeArg) => {
        let localRateOptions = basis;
        if (rateTypeArg === 'stop_charge') {
            localRateOptions = basisStopCharge;
        }
        return localRateOptions;
    };

    const render = () => {
        return (
            <>
                <Stack direction="row" alignItems="center" spacing={2} sx={{ paddingTop: '25px' }}>
                    <Typography variant="body1">Custom Rate</Typography>
                    <IconButton aria-label="Add" component="span" onClick={onAddClick}>
                        <AddBox />
                    </IconButton>
                </Stack>
                <Divider />

                {fields.map((field, idx) => {
                    return (
                        <Accordion key={field.id} expanded={expanded.includes(idx)}>
                            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" onClick={() => onAccordionClick(idx)}>
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Typography>{idx + 1}</Typography>
                                    <IconButton
                                        color="primary"
                                        aria-label="Add"
                                        value={idx}
                                        component="span"
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            onDeleteClick(idx);
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Stack>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={4}>
                                        <ReactHookFormSearchableSelect key={field.id} label={'Type'} name={`hauling_rate_details.${idx}.type`} options={type} required />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ReactHookFormSearchableSelect key={field.id} label={'Basis'} name={`hauling_rate_details.${idx}.basis`} options={getRateTypeOptions(rateType[idx]?.value)} required />
                                    </Grid>
                                    {shouldDisplayMilkPrices(idx) && (
                                        <Grid item xs={4}>
                                            <ReactHookFormSearchableSelect
                                                key={field.id}
                                                label={rateBasis[idx]?.value === 'percent' ? 'Hauling Rate Type' : 'Milk Type'}
                                                name={`hauling_rate_details.${idx}.basis_parameter`}
                                                options={rateBasis[idx]?.value === 'percent' ? haulingTypesBasisParameter : getMilkTypeOptions(region)}
                                                disabled={rateBasis[idx]?.value === 'fixed'}
                                                required
                                            />
                                        </Grid>
                                    )}
                                    <Grid item xs={4}>
                                        <ReactHookFormInput key={field.id} label={'Rate'} name={`hauling_rate_details.${idx}.rate`} type={'number'} required />
                                    </Grid>
                                    {displayMaxMinCharges(rateBasis[idx]) && (
                                        <>
                                            <Grid item xs={4}>
                                                <ReactHookFormInput key={field.id} label={'Min Charge'} name={`hauling_rate_details.${idx}.min_charge`} type={'number'} />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <ReactHookFormInput key={field.id} label={'Max Charge'} name={`hauling_rate_details.${idx}.max_charge`} type={'number'} />
                                            </Grid>
                                        </>
                                    )}
                                    {displayMonthlyMaxCharges(rateBasis[idx]) && (
                                        <Grid item xs={4}>
                                            <ReactHookFormInput key={field.id} label={'Monthly Max Charge'} name={`hauling_rate_details.${idx}.rate_max_charge_per_period`} type={'number'} />
                                        </Grid>
                                    )}
                                    {!(supplemental && [Region.MMPA].includes(region)) && (
                                        <Grid item xs={4}>
                                            <SwitchWithLabel key={field.id} name={`hauling_rate_details.${idx}.paid_by_producer`} mainLabel="Paid By Producer" />
                                        </Grid>
                                    )}
                                    {!(supplemental && [Region.MMPA].includes(region)) && pickupRelatedHaulingBasis.has(rateBasis[idx]?.value) && (
                                        <Grid item xs={4}>
                                            <ReactHookFormSearchableSelect key={field.id} label={'Exclude Pickups Before Date'} name={`hauling_rate_details.${idx}.exclude_pickups_before_date`} options={fullMonthSelectOptions} />
                                        </Grid>
                                    )}
                                </Grid>
                            </AccordionDetails>
                        </Accordion>
                    );
                })}

                {fields.length === 0 && (
                    <Box display="flex" justifyContent="center" flexDirection="column" gridGap="30px" paddingTop="40px" textAlign="center">
                        <Typography variant="subtitle1">No charges have been added</Typography>
                        {formState.errors?.hauling_rate_details?.message && (
                            <Typography variant="subtitle2" color="error">
                                {formState.errors.hauling_rate_details.message}
                            </Typography>
                        )}
                    </Box>
                )}

                <Dialog open={deleteModal}>
                    <DialogTitle>
                        <Typography>Are you sure you want to delete this item?</Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Stack direction="row" spacing={2}>
                            <Button variant="contained" onClick={() => onDeleteComfirmation(false)}>
                                No
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => onDeleteComfirmation(true)}>
                                Yes
                            </Button>
                        </Stack>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    return render();
};

export default HaulingContractDetails;
