import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';
import { getRegion } from '../../../../../../utils';

const isPrairie = getRegion(window.location.hostname) === 'PRAIRIE';

export const Index = {
    auth: [...authRoles.admin, ...authRoles.hauling, ...((isPrairie && authRoles.processor) || [])],
    routes: [
        { path: '/wash-facilities', component: FuseLoadable({ loader: () => import('./WashFacilitiesApp') }) },
        { path: '/washes', component: FuseLoadable({ loader: () => import('./WashesApp') }) },
    ],
};

export default [Index];
