import axios from 'axios';
import { getQualityStatusAndOverrideQuery, updateProducerOverrideQuery } from './queries';

export const getQualityStatusAndOverride = async (date) => {
    const query = getQualityStatusAndOverrideQuery({ date });
    const request = axios.get(query);
    const { data } = await request;
    return data;
};

export const updateProducerOverride = async (model) => {
    const query = updateProducerOverrideQuery(model);
    const request = axios.post(query.url, { ...query.body });
    const { data } = await request;
    return data;
};
