import axios from 'axios';
import _ from 'lodash';
import { getProcessorSubUsersQuery, getProcessorsQuery } from './queries';

const getSubProcessorListDetails = (includeDeleted, id) =>
    new Promise((resolve, reject) => {
        const fields = ['created_at', 'deleted_at', 'email', 'secondary_email', 'id', 'name', 'permissions', 'processor_id', 'updated_at', 'username'];
        const subUsersQuery = getProcessorSubUsersQuery({
            ...(!!id && { processorId: id }),
            fields,
            includeDeleted,
        });
        const subUsersRequest = axios.get(subUsersQuery);

        subUsersRequest
            .then((subUsersResponse) => {
                const subUsers = subUsersResponse.data;
                const ids = _.uniq(_.map(subUsers, 'processor_id'));

                const processorQuery = getProcessorsQuery({ fields: ['id', 'name'], ids });
                const processorsRequest = axios.get(processorQuery);

                processorsRequest.then((processorsResponse) => {
                    const processors = processorsResponse.data;

                    const data = _.map(subUsers, (value) => ({ ...value, processor_name: _.find(processors, { id: value.processor_id }) ? _.find(processors, { id: value.processor_id }).name : '' }));

                    resolve({ subProducers: data });
                });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getSubProcessorListDetails;
