import React from 'react';
import MomentUtils from '@date-io/moment';
import { Grid } from '@material-ui/core';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Controller } from 'react-hook-form';
import { generateTestId } from '../../../../utils';

/** Date format ex: August 24th */
const MilkPriceFormDatePicker = ({ control, label, name, startDate, style }) => (
    <>
        <Grid item xs={12} sm={3} style={style}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => {
                        return <DatePicker data-testid={generateTestId('Milk Price Date', 'date-picker')} fullWidth variant="inline" label={label} value={value} minDate={startDate} onChange={onChange} />;
                    }}
                />
            </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={12} sm={9} style={style}></Grid>
    </>
);

export default MilkPriceFormDatePicker;
