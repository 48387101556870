import React from 'react';
import moment from 'moment-timezone';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Card, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { toTitleCase } from '../../../../../utils';

const BoxSx = { margin: (theme) => theme.spacing(2) };
const CardSx = { maxWidth: '250px' };
const CloseIconSx = {
    position: 'absolute',
    top: 8,
    right: 8,
    height: '8px',
    width: '8px',
};

const DriverDetails = ({ shape, deselect }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const isRawMilk = !pathname.includes('processed-fluid');
    const inspectRouteSessionUrl = isRawMilk ? '/inspect-route-session' : '/inspect-processed-fluid-route-session';
    return (
        <Card sx={CardSx}>
            <IconButton sx={CloseIconSx} onClick={deselect}>
                <CloseIcon />
            </IconButton>
            <Box sx={BoxSx}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{shape.BOL}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption">Driver</Typography>
                        <Typography variant="body1">{toTitleCase(shape.driver_name?.toLowerCase())}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption">Hauling Company</Typography>
                        <Typography variant="body1">{toTitleCase(shape.hauling_name?.toLowerCase())}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption">Scheduled Destination</Typography>
                        <Typography variant="body1">{toTitleCase(shape.processor_name?.toLowerCase())}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="caption">Updated</Typography>
                        <Typography variant="body1">{moment(shape.updated_at).format('MMM DD - hh:mm')}</Typography>
                    </Grid>
                    {isRawMilk && (
                        <Grid item xs={12}>
                            <Typography variant="caption">Route</Typography>
                            <Typography variant="body1">{shape.route_name}</Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <Typography variant="caption">Status</Typography>
                        <Typography variant="body1" color={shape.status === 'inactive' ? 'error' : 'success.main'}>
                            {toTitleCase(shape.status, '_')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={() => history.push(`${inspectRouteSessionUrl}/${shape.id}`)}>
                            View Route Session
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Card>
    );
};

export default DriverDetails;
