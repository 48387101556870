/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { arrayToMap, renameKey } from '../../utils';
import { getScheduleFluidItemQuery, getTrailersQuery } from './queries';
import moment from 'moment-timezone';

const getScheduleFluidSupplyItemDetails = async (date, user, getMonth = false, withoutRouteSession = false) => {
    try {
        let start = moment(date).startOf('day').utc();
        let end = moment(date).endOf('day').add(7, 'days').utc();
        if (getMonth) {
            start = moment(date).startOf('month').subtract(2, 'week').utc();
            if (user.role === 'processor') {
                start = moment(date).subtract(2, 'week').utc();
            } else if (user.role === 'transport') {
                start = moment(date).subtract(2, 'days').utc();
            }
            end = moment(date).endOf('month').utc();
        }

        const trailers = await axios.get(getTrailersQuery({}));
        const trailerMap = arrayToMap(trailers.data, 'id');

        const params = {
            start,
            end,
            ...(user.role === 'processor' && { processorId: user.subuser ? user.processor_id : user.id }),
            ...(user.role === 'transport' && { haulingId: user.subuser ? user.hauling_id : user.id }),
            status: 'supply',
            populate: {
                hauling_id: ['name'],
                route_session_id: ['manifest_number', 'status', 'trailer_id'],
            },
            ...(user.role === 'processor' && { filterByEndDate: true }),
        };

        const scheduleItemsQuery = getScheduleFluidItemQuery(params);
        const scheduleItemsRequest = axios.get(scheduleItemsQuery);

        let { data } = await scheduleItemsRequest;

        if (user.role === 'processor') {
            const suppliedParams = {
                start,
                end,
                supplyProcessorId: user.subuser ? user.processor_id : user.id,
                status: 'supply',
                populate: {
                    hauling_id: ['name'],
                    route_session_id: ['manifest_number', 'status', 'trailer_id'],
                },
            };

            const suppliedScheduleItemsQuery = getScheduleFluidItemQuery(suppliedParams);

            const suppliedScheduleItemsRequest = await axios.get(suppliedScheduleItemsQuery);
            let suppliedData = suppliedScheduleItemsRequest.data;
            data = data.map((d) => ({ ...d, inbound: true }));
            suppliedData = suppliedData.map((d) => ({ ...d, outbound: true }));
            data = data.concat(suppliedData);
        }

        if (withoutRouteSession) {
            data = data.filter((item) => item.route_session_id === null);
        }

        renameKey(data, '_id', 'id');
        data.forEach((d) => {
            // eslint-disable-next-line
            d.haulingName = d?.hauling_id?.name || 'Unclaimed';
            // eslint-disable-next-line
            d.hauling_id = d?.hauling_id?._id || '';
            // eslint-disable-next-line
            d.trailer_number = trailerMap?.[d?.route_session_id?.trailer_id]?.trailer_number || '';
        });

        return data;
    } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getScheduleFluidSupplyItemDetails;
