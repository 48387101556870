import { defaultEvaluationFn } from './SplitUtils';
import './typedefs';

class Environment {
    /**
     * Environment definitions for a given split
     * @param {EnvironmentParams} params
     */

    constructor({ name, evaluationFn = defaultEvaluationFn, regions }) {
        this.name = name;
        this.evaluationFn = (attributes) => evaluationFn(attributes, regions);
    }
}

export default Environment;
