import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import { Box, Paper } from '@material-ui/core';
import SearchableSelect from '../custom-widgets/SearchableSelect';
import _ from 'lodash';

function DataTableFilterExtension({ title, columns, data, filterTableData }) {
    const [desiredFilters, setDesiredFilters] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState({});

    const saveValueToSessionStorage = (key, value) => {
        if (_.find(desiredFilters, { field: key })) {
            const filt = _.find(desiredFilters, { field: key });
            if (value) {
                sessionStorage.setItem(filt.saveStateKey, value);
            } else {
                sessionStorage.removeItem(filt.saveStateKey);
            }
        }
    };

    const handleSelection = (key, formatter, value) => {
        const newFilters = { ...selectedFilters, [key]: { value, formatter } };
        Object.keys(newFilters).forEach((k) => {
            if (!newFilters[k].value) {
                delete newFilters[key];
            }
        });
        saveValueToSessionStorage(key, value);
        setSelectedFilters(newFilters);
    };

    const checkForExistingValuesInStorage = (dFilters) => {
        let filters = {};
        Object.values(dFilters).forEach((f) => {
            const value = sessionStorage.getItem(f.saveStateKey);
            if (value) {
                filters = { ...filters, [f.field]: { formatter: f.optionFormatter, value } };
            }
        });
        setSelectedFilters(filters);
    };

    useEffect(() => {
        setDesiredFilters(columns.filter((col) => col.filter).map((entry) => entry.filter));
        filterTableData(selectedFilters);
    }, [columns, selectedFilters]);

    useEffect(() => {
        checkForExistingValuesInStorage(columns.filter((col) => col.filter).map((entry) => entry.filter));
    }, []);

    const getValueFromSessionStorage = (saveKey) => {
        return sessionStorage.getItem(saveKey);
    };

    // TODO Usage of Searchable Select here is deprecated
    const renderFilterOption = (filter) => {
        const testId = filter.label?.toLowerCase().replace(/ /g, '-') || '';
        return (
            <Box display="flex" flexDirection="row" sx={{ width: '50%' }} justifyContent="space-between" alignItems="center">
                <Box sx={{ width: '50%' }}>
                    <Typography data-testid={`${testId}-filter-label`} className="h3 font-bold text-center items-center">
                        {filter.label}:
                    </Typography>
                </Box>
                <Box sx={{ width: '50%' }}>
                    <SearchableSelect dataTestId={`${testId}-filter-select`} value={getValueFromSessionStorage(filter.saveStateKey)} options={_.uniq(data.map((d) => filter.optionFormatter(d[filter.field])))} onChange={(event, value) => handleSelection(filter.field, filter.optionFormatter, value)} fullWidth />
                </Box>
            </Box>
        );
    };

    const testId = title?.toLowerCase().replace(/ /g, '-').replace(':', '') || '';
    return (
        <Paper className="mb-20 pt-16">
            <Typography variant="h6" align="center" data-testid={'datatable-filter-header'}>
                {title}
            </Typography>

            {desiredFilters && (
                <Box display="flex" flexDirection="column" alignItems="center" gridGap="30px" paddingTop={'30px'} paddingBottom={'30px'}>
                    {desiredFilters.map((f) => renderFilterOption(f))}
                </Box>
            )}
        </Paper>
    );
}

export default DataTableFilterExtension;
