import React, { useEffect, useState, useRef, useMemo } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { useBasisTheory } from '@basis-theory/basis-theory-react';
import { FormProvider, useForm } from 'react-hook-form';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { showMessage } from '../../store/actions';
import { yupResolver } from '@hookform/resolvers/yup';
import { addRevealToken, editAssignee, editUser } from '../../repository';
import SensitiveInfoSchema from '../../custom-components/form-schemas/SensitiveInfoSchema';
import { transactionCodes } from '../../constants/selects/SensitiveInfoDialogSelects';
import ReactHookFormSearchableSelect from '../../custom-components/form-components/ReactHookFormSearchableSelect';
import _ from 'lodash';
import { setSubmitModelSelectValues, resolveValidStringId, getUserRegion } from '../../../utils';
import { createBasisTheoryToken } from '../../repository/utils';
import BasisTheoryTextElement from '../../custom-components/form-components/BasisTheoryTextElement';
import Split from '../../../Split';
import sendNotificationEvent from '../../repository/sendNotificationEvent';
import NotificationEventType from '../../constants/notificationEvents';

function PaperComponent(props) {
    return <Paper {...props} />;
}

const routingNumberMask = [/\d/u, /\d/u, /\d/u, /\d/u, ' ', /\d/u, /\d/u, /\d/u, /\d/u, ' ', /\d/u];

const ErrorLabel = ({ error }) =>
    error ? (
        <Grid item xs={12} style={{ paddingTop: 0, marginTop: -4 }}>
            <Typography color="error">{error}</Typography>
        </Grid>
    ) : (
        <></>
    );

export default function SensitiveInfoDialog({ title, dataType, data, forceFetch }) {
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [revealData, setRevealData] = useState({});
    const [revealLoading, setRevealLoading] = useState(false);
    const [routingChange, setRoutingChange] = useState(false);
    const [accountChange, setAccountChange] = useState(false);
    const dispatch = useDispatch();
    const { bt } = useBasisTheory();
    const region = getUserRegion();
    const routingNumberRef = useRef(null);
    const accountNumberRef = useRef(null);
    const [isRoutingNumberEmpty, setIsRoutingNumberEmpty] = useState(true);
    const [isAccountNumberEmpty, setIsAccountNumberEmpty] = useState(true);
    const [error, setError] = useState({});

    const use_two_level_banking_approval = Split.getBooleanTreatment('use_two_level_banking_approval');

    useEffect(() => {
        if (revealData.routing_number) routingNumberRef.current.setValue(revealData.routing_number);
        if (revealData.account_number) accountNumberRef.current.setValue(revealData.account_number);
        setRevealLoading(false);
    }, [revealData]);

    const defaultValues = useMemo(
        () => ({
            bank_transaction_code: _.find(transactionCodes, (z) => z.value === data?.bank_transaction_code),
            bank_account_number: data?.bank_account_number?.masked_data,
            bank_routing_number: data?.bank_routing_number?.masked_data,
        }),
        [data]
    );

    const reactHookFormMethods = useForm({
        mode: 'onTouched',
        defaultValues: {
            bank_transaction_code: defaultValues.bank_transaction_code,
        },
        resolver: yupResolver(SensitiveInfoSchema),
    });

    const { handleSubmit, errors } = reactHookFormMethods;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setLoading(false);
        setError({});
    };

    const handleChange = (event, setChange) => {
        if (event.complete) {
            setChange(true);
        } else {
            setChange(false);
        }
    };

    const setErroMessage = (label, message) => {
        setError((prev) => ({ ...prev, [label]: message }));
    };

    const onSubmit = async (model) => {
        setError({});

        if (!bt) {
            setErroMessage('form', 'Please wait a moment and try again.  If the problem persists please contact support.');
            return;
        }

        // Since the Basis Theory components are uncontrolled components, we can't retrieve the values because of their security reason. Therefore add the validation checking manually here(just check if they are empty or not)
        // FYI, Basis Theory team is doing the validation on their end one more time before the token creation.
        if (isRoutingNumberEmpty) setErroMessage('bankRoutingNumber', 'Bank Routing Number is a required field');

        if (isAccountNumberEmpty) setErroMessage('bankAccountNumber', 'Bank Account Number is a required field');

        if (isRoutingNumberEmpty || isAccountNumberEmpty) return;

        setLoading(true);

        // create a basis theory token with the form data
        const tokenData = await createBasisTheoryToken(
            bt,
            {
                routing_number: routingNumberRef.current,
                account_number: accountNumberRef.current,
            },
            region
        ).catch((err) => {
            const btErrors = err.data?.errors;

            if (btErrors) {
                // ex: { dataAccountNumber: ["'account_number' is invalid"], dataRoutingNumber: ["'routing_number' is invalid"] }
                if (btErrors.dataAccountNumber) setErroMessage('bankAccountNumber', 'Bank Account Number is invalid');

                if (btErrors.dataRoutingNumber) setErroMessage('bankRoutingNumber', 'Bank Routing Number is invalid');
            } else {
                // we can assume that it is the routing number's mask-validation failure. (before submitting)
                setErroMessage('bankRoutingNumber', 'Bank Routing Number should be 9 digits');
            }

            setLoading(false);
        });

        if (!tokenData) return;

        // submit data to the backend
        let submitModel = setSubmitModelSelectValues(model);

        // If two-level banking approval is enabled, we revert the user's payment method to check after a change.
        if (use_two_level_banking_approval) {
            submitModel.payment_method = 'check';
        }

        try {
            submitModel = {
                ...tokenData,
                ...submitModel,
            };

            const id = resolveValidStringId(data);

            if (dataType === 'Assignee') {
                await editAssignee(submitModel, id, true);
            } else {
                await editUser(submitModel, id);
            }

            dispatch(showMessage({ message: 'Successfully updated sensitive information' }));
            // If two-level banking approval is enabled, send notification to inform admins.
            if (use_two_level_banking_approval) {
                await sendNotificationEvent(NotificationEventType.UpdateSensitiveInformation, data, [data.id]);
            }
            forceFetch();
        } catch (err) {
            dispatch(showMessage({ message: 'There was a problem in storing the sensitive information. Please contact support.' }));
        }

        setLoading(false);
    };

    const revealToken = async () => {
        setRevealLoading(true);

        const keys = Object.keys(defaultValues);
        const tokenIds = keys.map((key) => data[key]?.token_id).filter((value, index, array) => value !== undefined && array.indexOf(value) === index);

        if (tokenIds.length === 0) {
            dispatch(showMessage({ message: 'No sensitive information to reveal' }));
            setRevealLoading(false);
            return;
        }

        const session = await bt.sessions.create();
        try {
            await addRevealToken(session.nonce, tokenIds);

            const tokens = await Promise.all(
                tokenIds.map((tokenId) => {
                    return bt.tokens.retrieve(tokenId, {
                        apiKey: session.sessionKey,
                    });
                })
            );

            const tokenData = {};
            tokens.forEach((token) => Object.assign(tokenData, token.data));

            setRevealData(tokenData);
        } catch (err) {
            setRevealLoading(false);
            dispatch(showMessage({ message: 'Failed to reveal sensitive information. Please contact support.' }));
        }
    };

    return (
        <div>
            <Button variant="contained" color="primary" className="my-16" aria-label={title} onClick={handleClickOpen}>
                {title}
            </Button>

            <FormProvider {...reactHookFormMethods}>
                <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
                    <DialogTitle id="draggable-dialog-title">{dataType} Sensitive Information</DialogTitle>

                    <form onSubmit={handleSubmit(onSubmit, errors)}>
                        <DialogContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <ReactHookFormSearchableSelect name="bank_transaction_code" label="Bank Account Type" options={transactionCodes} required />
                                </Grid>
                                <Grid item xs={12}>
                                    <BasisTheoryTextElement label="Bank Routing Number" id="bankRoutingNumber" elementRef={routingNumberRef} placeholder={defaultValues.bank_routing_number} mask={routingNumberMask} transform={/\s/u} setIsEmpty={setIsRoutingNumberEmpty} onChange={(e) => handleChange(e, setRoutingChange)} />
                                </Grid>
                                <ErrorLabel error={error?.bankRoutingNumber} />
                                <Grid item xs={12}>
                                    <BasisTheoryTextElement label="Bank Account Number" id="bankAccountNumber" elementRef={accountNumberRef} placeholder={defaultValues.bank_account_number} setIsEmpty={setIsAccountNumberEmpty} onChange={(e) => handleChange(e, setAccountChange)} />
                                </Grid>
                                <ErrorLabel error={error?.bankAccountNumber} />
                                <ErrorLabel error={error?.form} />
                            </Grid>
                        </DialogContent>

                        <DialogActions style={{ display: 'flex', justifyContent: 'space-between', padding: '10px 24px' }}>
                            <Button onClick={revealToken} variant="outlined" color="secondary" disabled={revealLoading}>
                                Reveal
                                {revealLoading && (
                                    <div className="flex items-center justify-center" style={{ marginLeft: 10 }}>
                                        <CircularProgress size={20} />
                                    </div>
                                )}
                            </Button>
                            <div>
                                <Button onClick={handleClose} color="secondary" style={{ marginRight: 5 }}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="secondary" type="submit" disabled={loading || !accountChange || !routingChange}>
                                    Update
                                    {loading && (
                                        <div className="flex items-center justify-center" style={{ marginLeft: 10 }}>
                                            <CircularProgress size={20} />
                                        </div>
                                    )}
                                </Button>
                            </div>
                        </DialogActions>
                    </form>
                </Dialog>
            </FormProvider>
        </div>
    );
}
