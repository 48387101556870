import React, { createContext, useState } from 'react';
import { Snackbar, Alert, Typography } from '@mui/material';

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
    const [toolbarContent, setToolbarContent] = useState(null);
    const [snackBar, setSnackBar] = useState({ open: false, message: '', severity: 'success', content: null });

    const showSnackbar = ({ message, severity, content }) => {
        setSnackBar((prev) => ({
            open: true,
            message: message || prev.message,
            severity: severity || prev.severity,
            content: content || null,
        }));
    };

    const handleClose = () => setSnackBar({ ...snackBar, open: false });

    // Add persistent components here
    return (
        <NotificationContext.Provider value={{ showSnackbar, toolbarContent, setToolbarContent }}>
            {children}
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackBar.open}
                autoHideDuration={6000}
                onClose={handleClose}
                // wrap
            >
                <Alert
                    onClose={handleClose}
                    severity={snackBar.severity}
                    sx={{ width: '100%' }}
                    elevation={6}
                    variant="filled"
                    // wrap
                >
                    {snackBar.content ? snackBar.content : <Typography variant="h6">{snackBar.message}</Typography>}
                </Alert>
            </Snackbar>
        </NotificationContext.Provider>
    );
};
