import * as Actions from '../../actions';

const initialState = { data: null };

const viewAdminCustomTableConfiguration = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ADMIN_CUSTOM_TABLE_CONFIGURATION:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default viewAdminCustomTableConfiguration;
