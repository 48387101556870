import * as repository from '../../../repository';

export const GET_MEMBERSHIP_DATA = 'GET_MEMBERSHIP_DATA';
export const GET_MEMBERS_LIST = 'GET_MEMBERS_LIST';

export function getEquityMembershipData(id) {
    return (dispatch) => repository.getEquityUser(id).then((payload) => dispatch({ type: GET_MEMBERSHIP_DATA, payload }));
}

export function getMembersList() {
    return (dispatch) => repository.getEquityUsersList().then((payload) => dispatch({ type: GET_MEMBERS_LIST, payload }));
}
