import axios from 'axios';
import _ from 'lodash';
import { getPickupsQuery, getDriversQuery, getProducersQuery, getRouteSessionsQuery, getRoutesQuery, getTrailersQuery, batchQuery, bulkQuery } from './queries';
import { renameKey, arrayToMap, getNumSlips } from '../../utils';
import { buildExpressQuery } from './queries/builders';

const getHaulingPickupDetails = (id, start, end, filterType = 'milk') =>
    new Promise((resolve, reject) => {
        const driverQuery = getDriversQuery({ fields: ['id', 'name'], haulingId: id });
        const driverRequest = axios.get(driverQuery);

        driverRequest
            .then((driverResponse) => {
                const drivers = driverResponse.data;
                const driverIds = _.uniq(_.map(drivers, 'id'));

                const pickupsQuery = batchQuery(
                    getPickupsQuery,
                    {
                        start,
                        end,
                        driverIds,
                        filterDeleted: true,
                        filterType,
                        queryBuilder: buildExpressQuery,
                    },
                    'driverIds'
                );
                const pickupsRequest = _.map(pickupsQuery, (q) => axios.get(q));

                return axios.all([...pickupsRequest]).then(
                    axios.spread((...pickupsResponse) => {
                        const pickups = _.reduce(pickupsResponse.slice(0), (acc, val) => [...acc, ...val.data], []);
                        const producerIds = _.uniq(_.map(pickups, 'producer_id'));
                        const routeSessionIds = _.uniq(_.map(pickups, 'route_session_id'));

                        const producerQuery = getProducersQuery({ fields: ['id', 'name', 'geofence', 'latitude', 'longitude', 'license_number', 'number_of_tanks'], ids: producerIds });
                        const routeSessionQuery = batchQuery(
                            getRouteSessionsQuery,
                            {
                                fields: ['id', 'po_number', 'BOL', 'route_number', 'manifest_number', 'status', 'route_id', 'trailer_id', 'truck_id', 'created_at'],
                                ids: routeSessionIds,
                                filterDeleted: true,
                            },
                            'ids'
                        );

                        const producerRequest = axios.get(producerQuery);
                        const routeSessionRequest = _.map(routeSessionQuery, (q) => axios.get(q));

                        return axios.all([producerRequest, ...routeSessionRequest]).then(
                            axios.spread((...responses) => {
                                const [producerResponse] = responses;

                                const routeSessions = _.reduce(responses.slice(1), (acc, val) => [...acc, ...val.data], []);

                                const routeIds = _.uniq(_.map(routeSessions, 'route_id'));
                                const routeQuery = bulkQuery(getRoutesQuery, { fields: ['id', 'name'], ids: routeIds });

                                const routeRequest = axios.post(routeQuery.url, routeQuery.body);

                                const trailerIds = _.uniq(_.map(routeSessions, 'trailer_id'));
                                const trailerQuery = bulkQuery(getTrailersQuery, { ids: trailerIds });
                                const trailerRequest = axios.get(trailerQuery);

                                return axios.all([routeRequest, trailerRequest]).then(
                                    axios.spread((...subResponses) => {
                                        const producers = producerResponse.data;
                                        const routes = subResponses?.[0]?.data || [];
                                        const trailers = subResponses?.[1]?.data || [];
                                        renameKey(routes, '_id', 'id');

                                        const data = _.map(pickups, (value) => {
                                            const producer = [_.find(producers, { id: value.producer_id })];
                                            const driver = [_.find(drivers, { id: value.driver_id })];
                                            const routeSession = _.find(routeSessions, { id: value.route_session_id });
                                            const trailerMap = arrayToMap(trailers, 'id');

                                            return {
                                                ...value,
                                                producer,
                                                driver,
                                                route_session: [{ ...routeSession, route: routeSession && _.find(routes, { id: routeSession.route_id }) ? _.find(routes, { id: routeSession.route_id }).name : '' }],
                                                trailer_number: trailerMap?.[routeSession?.trailer_id]?.trailer_number || '',
                                                num_slips: getNumSlips(value),
                                            };
                                        });

                                        resolve({ pickups: data });
                                    })
                                );
                            })
                        );
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getHaulingPickupDetails;
