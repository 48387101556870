import axios from 'axios';
import _ from 'lodash';
import { getHaulingSubUsersQuery, getHaulingCompaniesQuery } from './queries';

const getSubHaulingListDetails = (includeDeleted, id) =>
    new Promise((resolve, reject) => {
        const fields = ['created_at', 'deleted_at', 'email', 'secondary_email', 'id', 'name', 'permissions', 'hauling_id', 'updated_at', 'username'];
        const subUsersQuery = getHaulingSubUsersQuery({
            ...(!!id && { haulingId: id }),
            fields,
            includeDeleted,
        });
        const subUsersRequest = axios.get(subUsersQuery);

        subUsersRequest
            .then((subUsersResponse) => {
                const subUsers = subUsersResponse.data;
                const ids = _.uniq(_.map(subUsers, 'hauling_id'));

                const haulingCompaniesQuery = getHaulingCompaniesQuery({ fields: ['id', 'name'], ids });
                const haulingCompaniesRequest = axios.get(haulingCompaniesQuery);

                haulingCompaniesRequest.then((haulingCompaniesResponse) => {
                    const haulingCompanies = haulingCompaniesResponse.data;

                    const data = _.map(subUsers, (value) => ({ ...value, hauling_company_name: _.find(haulingCompanies, { id: value.hauling_id }) ? _.find(haulingCompanies, { id: value.hauling_id }).name : '' }));

                    resolve({ subProducers: data });
                });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getSubHaulingListDetails;
