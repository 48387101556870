import axios from 'axios';
import { buildExpressQuery } from './queries/builders';

const addRevealToken = async (nonce, tokens) => {
    try {
        await axios.post(buildExpressQuery('/users/reveal-token', {}), { nonce, tokens });
    } catch {
        throw new Error('Failed to reveal sensitive information');
    }
};

export default addRevealToken;
