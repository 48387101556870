import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';
import { getPayGroupDisplayName } from './utils';

const TCJLocationtAdjustment = ({ payGroup }) => {
    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">Pay Group {getPayGroupDisplayName(payGroup)}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <ReactHookFormInput label="Location Adj. ($/cwt)" name={`milk_prices.pay_group_${payGroup}.location_adjustment`} type="number" />
            </Grid>
        </>
    );
};

export default TCJLocationtAdjustment;
