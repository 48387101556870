import axios from 'axios';
import _ from 'lodash';
import { arrayToMap } from '../../utils';
import { calculateShrinkAmount, calculateShrinkPercentage } from '../helpers/dropoffs/utils';
import { getRouteSessionsQuery, getPickupsQuery, getProcessorsQuery, getDropoffsQuery, getRoutesQuery, bulkQuery, getDriversQuery, getProducersQuery, getTrailersQuery, getTrucksQuery } from './queries';
import { getBulkQueryData } from './utils';

const getHaulingRouteSessionDetailsByHauler = async (id, start, end, filterType) => {
    try {
        const routeSessionRequest = getRouteSessionsQuery({ start, end, haulingId: id, filterDeleted: true, filterType });
        const routeSessions = (await axios.get(routeSessionRequest)).data;

        const routeSessionIds = _.uniq(_.map(routeSessions, 'id'));

        const driverIds = _.uniq(_.map(routeSessions, 'created_by'));
        const drivers = await getBulkQueryData(getDriversQuery, { fields: ['id', 'name'], ids: driverIds }, 'ids');

        const routeIds = _.uniq(_.map(routeSessions, 'route_id'));
        const routes = await getBulkQueryData(getRoutesQuery, { fields: ['id', 'name'], ids: routeIds }, 'ids');

        const pickups = await getBulkQueryData(
            getPickupsQuery,
            {
                routeSessionIds,
                filterDeleted: true,
                hint: 'route_session_id_1_deleted_at_1_created_at_1',
            },
            'routeSessionIds'
        );

        const producerIds = _.uniq(_.map(pickups, 'producer_id'));
        const producers = await getBulkQueryData(getProducersQuery, { fields: ['id', 'name', 'latitude', 'longitude', 'geofence', 'license_number', 'number_of_tanks'], ids: producerIds }, 'ids');

        const dropoffs = await getBulkQueryData(
            getDropoffsQuery,
            {
                routeSessionIds,
                filterDeleted: true,
            },
            'routeSessionIds'
        );
        const processorIds = _.uniq(_.map(dropoffs, 'processor_id'));
        const processors = await getBulkQueryData(getProcessorsQuery, { fields: ['id', 'name', 'latitude', 'longitude', 'geofence', 'license_number'], ids: processorIds }, 'ids');

        const pickupTotals = _.mapValues(_.groupBy(pickups, 'route_session_id'), (p) => _.sumBy(p, 'volume'));
        const dropoffTotals = _.mapValues(_.groupBy(dropoffs, 'route_session_id'), (d) => _.sumBy(d, 'volume'));

        const driversMap = arrayToMap(drivers, 'id');
        const routesMap = arrayToMap(routes, 'id');
        const routeSessionsMap = arrayToMap(routeSessions, 'id');
        const processorsMap = arrayToMap(processors, 'id');
        const producersMap = arrayToMap(producers, 'id');

        const trailerIds = _.uniq(_.map(routeSessions, 'trailer_id'));
        const trailerQuery = bulkQuery(getTrailersQuery, { ids: trailerIds });
        const trailers = (await axios.get(trailerQuery)).data;
        const trailersMap = arrayToMap(trailers, 'id');

        const truckIds = _.uniq(_.map(routeSessions, 'truck_id'));
        const truckQuery = bulkQuery(getTrucksQuery, { ids: truckIds });
        const trucks = (await axios.get(truckQuery)).data;
        const trucksMap = arrayToMap(trucks, '_id');

        const getSessionProcessor = (sessionId) => {
            const sessionDropoff = dropoffs.find((dropoff) => dropoff.route_session_id === sessionId);
            const sessionProcessor = processorsMap[sessionDropoff?.processor_id];

            return sessionProcessor?.name;
        };

        const sessionData = _.map(routeSessions, (session) => {
            const driver = driversMap[session.created_by];
            const route = routesMap[session.route_id];
            const trailer = trailersMap[session.trailer_id];
            const truck = trucksMap[session.truck_id];
            const processor = getSessionProcessor(session.id);

            return {
                ...session,
                BOL: session.BOL,
                date: session.created_at,
                route_number: session.route_number,
                dropoffVolume: dropoffTotals[session.id] ? dropoffTotals[session.id].toString() : '0',
                flagged: !dropoffTotals[session.id] || !pickupTotals[session.id] || dropoffTotals[session.id] !== pickupTotals[session.id],
                id: session.id,
                name: driver?.name ?? '',
                route: route?.name ?? '',
                route_session_status: _.capitalize(session.status),
                volume: pickupTotals[session.id] ? pickupTotals[session.id] : 0,
                type_of_fluid: session.type_of_fluid,
                route_id: session && session.route_id ? session.route_id : '',
                driver_id: session && session.created_by ? session.created_by : '',
                trailer_number: trailer?.trailer_number || '',
                truck_number: truck?.truck_number || '',
                truck_id: session.truck_id ? session.truck_id : '',
                po_number: session.po_number ? session.po_number : '',
                processor_name: processor || '',
                odometer_start_miles: session.odometer_start_miles || 0,
                odometer_end_miles: session.odometer_end_miles || 0,
            };
        });

        const dropoffData = _.map(dropoffs, (dropoff) => {
            const processor = [processorsMap[dropoff.processor_id]];
            const driver = [driversMap[dropoff.driver_id]];
            const routeSession = routeSessionsMap[dropoff.route_session_id];
            const trailer = trailersMap[routeSession.trailer_id];
            const truck_number = trucksMap[routeSession?.truck_id]?.truck_number || '-';

            return {
                ...dropoff,
                shrink_amount: calculateShrinkAmount(dropoff),
                shrink_percent: calculateShrinkPercentage(dropoff),
                processor,
                driver,
                trailer_number: trailer?.trailer_number || '',
                route_session: [{ ...routeSession, route: routeSession && routes && routesMap[routeSession.route_id] ? routesMap[routeSession.route_id].name : '' }],
                truck_number,
            };
        });

        const pickupData = _.map(pickups, (pickup) => {
            const producer = [producersMap[pickup.producer_id]];
            const driver = [driversMap[pickup.driver_id]];
            const routeSession = routeSessionsMap[pickup.route_session_id];
            const trailer = trailersMap[routeSession.trailer_id];
            const truck_number = trucksMap[routeSession?.truck_id]?.truck_number || '-';

            return {
                ...pickup,
                producer,
                driver,
                trailer_number: trailer?.trailer_number || '',
                route_session: [{ ...routeSession, route: routeSession && routes && routesMap[routeSession.route_id] ? routesMap[routeSession.route_id].name : '' }],
                truck_number,
            };
        });
        return { sessions: sessionData, pickups: pickupData, dropoffs: dropoffData };
    } catch (errors) {
        // eslint-disable-next-line no-console
        console.log(errors);
        throw new Error('Unable to process request.');
    }
};

export default getHaulingRouteSessionDetailsByHauler;
