import React, { useEffect, useState } from 'react';
import { Marker, OverlayView } from '@react-google-maps/api';
import { LOCATION_ROLES } from '../../../constants';
import DriverDetails from './helper-components/DriverDetails';
import BusinessDetails from './helper-components/BusinessDetails';
import { iconUrlMap, enlargedIconSize, defaultIconSize } from './MapShapes.helpers';

const detailsMap = {
    [LOCATION_ROLES.PRODUCER]: BusinessDetails,
    [LOCATION_ROLES.PROCESSOR]: BusinessDetails,
    [LOCATION_ROLES.HAULER]: BusinessDetails,
    [LOCATION_ROLES.DRIVER]: DriverDetails,
};

const MapLocationPin = ({ shape, clusterer, selectedShape, handleMarkerClick }) => {
    const [selected, setSelected] = useState(selectedShape === shape.id);

    useEffect(() => {
        setSelected(selectedShape === shape.id);
    }, [selectedShape]);

    const handleSelect = () => {
        setSelected(true);
        handleMarkerClick(shape.id);
    };

    const center = { lat: shape.location.coordinates[1], lng: shape.location.coordinates[0] };
    const pixelPositionOffset = () => ({
        x: 0,
        y: -24,
    });
    const DetailsComponent = detailsMap[shape.role];
    const isActive = shape.role !== LOCATION_ROLES.DRIVER || shape.status !== 'inactive';

    return (
        <>
            <Marker
                position={center}
                onClick={handleSelect}
                zIndex={selected ? 1 : 0}
                icon={{
                    url: iconUrlMap[shape.role],
                    scaledSize: selected ? enlargedIconSize : defaultIconSize,
                }}
                opacity={isActive ? 1 : 0.7}
                clusterer={clusterer}
            />
            {selected && (
                <OverlayView position={center} mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} getPixelPositionOffset={pixelPositionOffset}>
                    <DetailsComponent shape={shape} deselect={() => setSelected(false)} />
                </OverlayView>
            )}
        </>
    );
};

export default React.memo(MapLocationPin);
