import _ from 'lodash';
import axios from 'axios';
import moment from 'moment-timezone';
import { arrayToMap, formatLabsForComponentCalculation, renameKey } from '../../utils';
import { calculateShrinkAmount, calculateShrinkPercentage } from '../helpers/dropoffs/utils';
import store from '../store';
import { bulkQuery, getTrucksQuery, getDropoffsQuery, getHaulingCompaniesQuery, getLabReportsQuery, getPickupsQuery, getProcessorsQuery, getProducersQuery, getRouteSessionsQuery, getRoutesQuery, getTrailersQuery, getUsersQuery, getSilosQuery } from './queries';
import { getBatchedQueryData, getBulkQueryData, calculateComponents, getPickupsByDropoffId } from './utils';

/**
 * For clients who have receivers adding dropoffs we need to get the
 * driver and hauling company info from the route session instead of the
 * dropoff
 */
const hasActiveReceivers = (region) => {
    switch (region) {
        case 'PRAIRIE':
            return true;
        default:
            return false;
    }
};

const getProcessorDropoffDetails = async (pId, start, end) => {
    try {
        const { region } = store.getState().persisted.auth.user.data;

        const dropoffs = await getBulkQueryData(getDropoffsQuery, {
            start,
            end,
            processorId: pId,
            filterType: 'milk',
            filterDeleted: true,
        });
        const routeSessionIds = _.uniq(_.map(dropoffs, 'route_session_id'));

        const routeSessions = await getBulkQueryData(getRouteSessionsQuery, {
            fields: ['id', 'BOL', 'status', 'route_id', 'route_number', 'manifest_number', 'fairlife_number', 'hauling_id', 'trailer_id', 'created_by', 'truck_id', 'po_number'],
            ids: routeSessionIds,
            filterDeleted: true,
            hint: '_id_1_created_at_-1_deleted_at_1',
        });

        const pickups = await getBulkQueryData(
            getPickupsQuery,
            {
                routeSessionIds,
                filterDeleted: true,
                hint: 'route_session_id_1_deleted_at_1_created_at_1',
            },
            'routeSessionIds'
        );

        const driverIds = hasActiveReceivers(region) ? _.uniq(_.map(routeSessions, 'created_by')) : _.uniq(_.map(dropoffs, 'driver_id'));
        const processorIds = _.uniq(_.map(dropoffs, 'processor_id'));
        const routeIds = _.uniq(_.map(routeSessions, 'route_id'));
        const trailerIds = _.uniq(_.map(routeSessions, 'trailer_id'));
        const producerIds = _.uniq(_.map(pickups, 'producer_id'));
        const truckIds = _.uniq(_.map(routeSessions, 'truck_id'));

        const firstPickup = _.minBy(pickups, 'created_at');
        const lastPickup = _.maxBy(pickups, 'created_at');

        const labReportStart = firstPickup ? moment(firstPickup.created_at).startOf('day').toString() : null;
        const labReportEnd = lastPickup ? moment(lastPickup.created_at).endOf('day').toString() : null;

        const labReportsRequest = getBatchedQueryData(
            getLabReportsQuery,
            {
                fields: ['producer_id', 'date', 'fat', 'LOS', 'somatic_cell_count', 'protein', 'solids_not_fat', 'sample_barcode', 'pickup_id', 'fat_weight', 'los_weight', 'protein_weight', 'other_solids', 'lactose', 'sample_barcodes'],
                start: labReportStart,
                end: labReportEnd,
                producerIds,
                filterDeleted: true,
            },
            'producerIds'
        );

        const driversRequest = getBulkQueryData(getUsersQuery, { fields: ['id', 'name', 'hauling_id'], ids: driverIds }, 'ids');
        const processorsRequest = getBulkQueryData(getProcessorsQuery, { fields: ['id', 'name', 'geofence', 'latitude', 'longitude'], ids: processorIds }, 'ids');
        const routesRequest = getBulkQueryData(getRoutesQuery, { fields: ['id', 'name'], ids: routeIds }, 'ids');
        const producersRequest = getBulkQueryData(getProducersQuery, { fields: ['id', 'license_number', 'milk_category'], ids: producerIds }, 'ids');
        const silosRequest = axios.get(getSilosQuery({ filterDeleted: true }));

        const [drivers, processors, routes, producers, labReports, silos] = await Promise.all([driversRequest, processorsRequest, routesRequest, producersRequest, labReportsRequest, silosRequest]);

        const haulingIds = _.filter(_.uniq(_.map(drivers, 'hauling_id')), (id) => !_.isNull(id));

        const haulingCompanyRequest = getBulkQueryData(getHaulingCompaniesQuery, { fields: ['id', 'name'], ids: haulingIds });
        const trailerRequest = getBatchedQueryData(getTrailersQuery, { ids: trailerIds }, 'ids');
        const truckRequest = axios.get(getTrucksQuery({ ids: truckIds }));

        const [haulers, trailers, trucks] = await Promise.all([haulingCompanyRequest, trailerRequest, truckRequest]);

        const driversMap = arrayToMap(drivers, 'id');
        const processorsMap = arrayToMap(processors, 'id');
        const routeSessionsMap = arrayToMap(routeSessions, 'id');
        const routesMap = arrayToMap(routes, 'id');
        const haulingMap = arrayToMap(haulers, 'id');
        const siloMap = arrayToMap(silos?.data || [], 'id');
        const trailerMap = arrayToMap(trailers, 'id');
        const trucksMap = arrayToMap(trucks?.data || [], '_id');
        const producersMap = arrayToMap(producers, 'id');
        const pickupsByRouteSessionId = _.groupBy(pickups, 'route_session_id');
        const dropoffsByRouteSessionId = _.groupBy(dropoffs, 'route_session_id');

        const pickupsDropoffsByRouteSessionId = _.mapValues(
            _.groupBy(
                _.map(routeSessionIds, (id) => {
                    const rsDropoffs = dropoffsByRouteSessionId[id];
                    const rsPickups = pickupsByRouteSessionId[id] ? pickupsByRouteSessionId[id] : [];
                    return {
                        route_session_id: id,
                        dropoffs: rsDropoffs,
                        pickups: rsPickups,
                    };
                }),
                'route_session_id'
            ),
            (o) => _.pick(o[0], ['pickups', 'dropoffs'])
        );

        const pickupsByDropoffIdByRouteSessionId = _.mapValues(
            _.groupBy(
                _.map(routeSessionIds, (id) => {
                    const { pickups: p, dropoffs: d } = pickupsDropoffsByRouteSessionId[id];
                    return { route_session_id: id, pickupsByDropoffId: d ? getPickupsByDropoffId(d, p) : [] };
                }),
                'route_session_id'
            ),
            (o) => o[0].pickupsByDropoffId
        );

        const [groupedLabReports, labsByPickupIdMap] = formatLabsForComponentCalculation(labReports, region);

        const data = _.map(dropoffs, (dropoff) => {
            const routeSession = routeSessionsMap[dropoff.route_session_id];
            const pickupsForDropoff = pickupsByDropoffIdByRouteSessionId?.[routeSession?.id]?.[dropoff?.id] || [];
            const components = calculateComponents(pickupsForDropoff, groupedLabReports, region, labsByPickupIdMap);
            const driver = hasActiveReceivers(region) ? [driversMap[routeSession?.created_by]] : [driversMap[dropoff.driver_id]];
            const hauling_company = hasActiveReceivers(region) ? haulingMap?.[routeSession?.hauling_id]?.name : haulingMap?.[driversMap[dropoff?.driver_id]?.hauling_id]?.name || haulingMap?.[routeSession?.hauling_id]?.name || '';
            const truck_number = trucksMap[routeSession?.truck_id]?.truck_number || '-';

            // Combine the data from the Dropoff/Pickup's split details with the split details info from the Silo Query
            const split_details = dropoff?.split_details?.map((silo) => ({
                ...silo,
                ...siloMap[silo.silo],
            }));

            return {
                ...dropoff,
                shrink_amount: calculateShrinkAmount(dropoff),
                shrink_percent: calculateShrinkPercentage(dropoff),
                components,
                split_details,
                processor: [processorsMap[dropoff.processor_id]],
                producerNumber: producersMap?.[pickupsForDropoff?.[0]?.producer_id]?.license_number,
                driver,
                trailer_number: trailerMap?.[routeSessionsMap?.[dropoff?.route_session_id]?.trailer_id]?.trailer_number || '',
                hauling_company,
                route_session: [{ ...routeSession, route: routeSession && routesMap[routeSession.route_id] ? routesMap[routeSession.route_id].name : '' }],
                truck_number,
            };
        });

        return { dropoffs: data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getProcessorDropoffDetails;
