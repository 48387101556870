import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    search: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderWidth: '1px',
        borderColor: alpha(theme.palette.common.black, 0.4),
        color: alpha(theme.palette.common.black, 0.4),
        borderRadius: theme.shape.borderRadius,
        backgroundColor: alpha(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: alpha(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: 'auto',
        },
        padding: theme.spacing(1, 1, 1, 1),
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        color: theme.palette.common.black,
        padding: theme.spacing(1, 1, 1, 1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

const SearchBaseComponent = ({ onChange = () => {}, ...props }) => {
    const classes = useStyles();
    return (
        <Box {...props} className={classNames(classes.search, props.className)}>
            <SearchIcon />
            <StyledInputBase placeholder="Search" inputProps={{ 'aria-label': 'search' }} onChange={onChange} />
        </Box>
    );
};

export default SearchBaseComponent;
