import React, { useState } from 'react';
import { Button } from '@mui/material';
import ViewDeliveryReportModal from '../../custom-components/delivery-reports/ViewDeliveryReportModal';

const ViewDeliveryReportDialog = ({ data, title }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Button disabled={!data?.delivery_report} variant="contained" color="primary" className="my-16" aria-label={title} onClick={() => setOpen(true)}>
                {title}
            </Button>

            <ViewDeliveryReportModal open={open} handleClose={() => setOpen(false)} fileName={data?.delivery_report} />
        </>
    );
};

export default ViewDeliveryReportDialog;
