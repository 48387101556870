import { FuseLoadable } from '@fuse';
import HaulingContractCenterpointApp from './centerpoints';

export const Index = {
    auth: ['admin', 'sub-admin', 'transport', 'sub-transport', 'producer', 'sub-producer'],
    routes: [
        {
            path: '/hauling-contracts',
            component: FuseLoadable({
                loader: () => import('./HaulingContractApp'),
            }),
        },
    ],
};

export default [Index, ...HaulingContractCenterpointApp];
