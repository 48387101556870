import React, { useEffect } from 'react';
import { Login } from '@milkmoovement/common_cognito_package';
import { onCognitoLogin } from '../../../auth/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getRegion, getHostname } from '../../../../utils';
import { showMessage } from '../../../store/actions';

const InternalLoginPage = () => {
    const history = useHistory();
    const region = getRegion(window.location.hostname);
    const dispatch = useDispatch();

    const hostname = getHostname(window);

    const providers = [];
    const internalProviderJSON = process.env.REACT_APP_INTERNAL_PROVIDER_MAP;
    const internalProvider = internalProviderJSON ? JSON.parse(internalProviderJSON) : {};
    providers.push(internalProvider);

    const authConfig = {
        domain: process.env.REACT_APP_OAUTH_DOMAIN,
        redirectSignIn: hostname,
        redirectSignOut: hostname,
        clientId: internalProvider.clientId,
    };

    const { error } = useSelector(({ persisted }) => persisted.auth.login);

    useEffect(() => {
        if (error.message && error.name === 'RoleError') {
            dispatch(showMessage({ message: error.message }));
        }
    }, [error]);

    const handleNavigateForgotPassword = () => history.push({ pathname: '/forgot-password' });

    const handleNavigateLogin = () => history.push({ pathname: '/login' });

    const handleSucessfulLogin = async () => {
        dispatch(await onCognitoLogin());
    };

    return (
        <Login
            onSucessfulLogin={handleSucessfulLogin}
            onNavigateForgotPassword={handleNavigateForgotPassword}
            onNavigateLogin={handleNavigateLogin}
            metadata={{
                coreSystem: region,
            }}
            authConfig={authConfig}
            internalLoginRender
            providers={providers}
        />
    );
};

export default InternalLoginPage;
