/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { getId, renameKey } from '../../utils';
import { getScheduleMilkSupplyItemQuery } from './queries';
import Region from '../constants/region';

const getScheduleMilkSupplyItemDetails = async (date, user) => {
    try {
        const start = date.format();
        const end = date.clone().add(7, 'days').subtract(1, 'second').format();
        const populate = {
            route_session_id: ['status'],
        };

        const { region } = user;

        const transportFilter = region === Region.MMPA ? { $or: [{ hauling_id: getId() }, { delivery_hauler_id: getId() }] } : { hauling_id: getId() };

        const params = {
            start,
            end,
            ...(user.role === 'processor' && { processorId: user.subuser ? user.processor_id : user.id }),
            populate,
            // This allows us to use $or syntax
            filter: {
                ...(user.role === 'transport' && transportFilter),
            },
            filterDeleted: true,
        };

        const scheduleItemsQuery = getScheduleMilkSupplyItemQuery(params);
        const scheduleItemsRequest = axios.get(scheduleItemsQuery);

        const { data } = await scheduleItemsRequest;
        renameKey(data, '_id', 'id');
        return data;
    } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getScheduleMilkSupplyItemDetails;
