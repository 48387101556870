import axios from 'axios';
import parseError from '../../errors/utils';
import { completeIPDOQuery } from './inprogressDropoff.queries';

const completeIPDO = async (model, key) => {
    try {
        const query = completeIPDOQuery({ key, model });
        const response = await axios.patch(query.url, { ...query.body });

        return { data: response.data };
    } catch (error) {
        // eslint-disable-next-line no-console
        return { error: parseError({ error }) };
    }
};

export default completeIPDO;
