import React from 'react';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
    center: {
        display: 'block',
        margin: 'auto',
    },
});

// Strictly used for spacing in Document History
const EmptyIcon = () => {
    const classes = useStyles();
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.center}></svg>;
};

export default EmptyIcon;
