import axios from 'axios';
import { createDropoffSampleQuery } from './queries';

const addDropoffSample = async (model) => {
    try {
        const query = createDropoffSampleQuery({ model });
        const request = axios.post(query.url, { ...query.body });
        const { data } = await request;
        return { data };
    } catch {
        throw new Error('Unable to add schedule item');
    }
};

export default addDropoffSample;
