import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getUsersQuery, getLabReportsQuery, getProducersQuery } from './queries';
import { getAlertData, getAlertDataPEI, getAlertDataRF } from './utils/getAlertData';

const requiresTwoMonthsData = (region) => {
    switch (region) {
        case 'RF':
            return false;
        default:
            return true;
    }
};

const requiresYearLabData = (region) => {
    switch (region) {
        case 'PEI':
            return true;
        default:
            return false;
    }
};

const getMonthlyBactoDetails = (id, region, date) =>
    new Promise((resolve, reject) => {
        const userQuery = getUsersQuery({ fields: ['id', 'name', 'subuser'], ids: [id] });
        const userRequest = axios.get(userQuery);

        userRequest
            .then((userResponse) => {
                const user = _.find(userResponse.data);
                const producerId = user.subuser ? user.producer_id : id;

                const startOfEndMonth = moment(date, 'YYYY-MM-DD').startOf('month');
                const start = requiresTwoMonthsData(region) ? startOfEndMonth.clone().subtract(2, 'months') : startOfEndMonth.clone();
                const end = startOfEndMonth.clone().endOf('month');
                const dateRange = { start, end };

                const labReportQuery = getLabReportsQuery({
                    start: requiresYearLabData(region) ? end.clone().add(1, 'seconds').subtract(1, 'years') : start,
                    end,
                    producerIds: [producerId],
                    filterDeleted: true,
                });

                const labReportRequest = axios.get(labReportQuery);

                labReportRequest.then((labReportResponse) => {
                    const labReports = labReportResponse.data;
                    if (region === 'NL') {
                        const data = getAlertData(dateRange, labReports, region);
                        const response = {
                            label: `${dateRange.start.format('MMM')} - ${dateRange.end.format('MMM')} , ${dateRange.end.format('YYYY')}`,
                            scc: {
                                status: data.scc_status,
                                total_results: data.scc_count,
                                results_above_standard: data.scc_count_over_standard,
                                percentage: data.scc_percentage,
                                one_in_third_month: data.one_in_third_month_scc,
                            },
                            bacto_scan: {
                                status: data.bacto_status,
                                bacteria_bacto_scan: data.bacto_scan_count,
                                bacteria_plc_count: data.bacteria_plc_count,
                                total_results: data.total_results,
                                bacteria_bacto_scan_above_standard: data.bacto_scan_count_over_standard,
                                bacteria_plc_above_standard: data.bacteria_plc_count_over_standard,
                                results_above_standard: data.total_results_above_standard,
                                percentage: data.bacto_percentage,
                                one_in_third_month_bacto_scan: data.one_in_third_month_bacto_scan,
                                one_in_third_month_plc: data.one_in_third_month_plc,
                                total_tests_in_third_month: data.total_tests_in_third_month,
                            },
                        };
                        resolve({ response });
                    } else if (region === 'PEI') {
                        const producerQuery = getProducersQuery({ ids: [producerId] });
                        const producerRequest = axios.get(producerQuery);
                        producerRequest.then((producerResponse) => {
                            const producer = _.find(producerResponse.data);
                            const response = getAlertDataPEI(dateRange, labReports, producer);
                            resolve({ response });
                        });
                    } else if (region === 'RF') {
                        const producerQuery = getProducersQuery({ ids: [producerId] });
                        const producerRequest = axios.get(producerQuery);
                        producerRequest.then((producerResponse) => {
                            const producer = _.find(producerResponse.data);
                            const response = getAlertDataRF(dateRange, labReports, producer);
                            resolve({ response });
                        });
                    }
                });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getMonthlyBactoDetails;
