import axios from 'axios';
import { getAPIHost } from '../../utils';

const uploadImage = ({ base64 }) =>
    new Promise((resolve, reject) => {
        const uploadRequest = axios.post(`${getAPIHost()}/image-upload`, { base64String: base64 });
        uploadRequest
            .then((uploadResponse) => {
                resolve({ imageInfo: uploadResponse.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error(errors.toString()));
            });
    });

export default uploadImage;
