export const SET_INSPECT_DETAILS = 'SET_INSPECT_DETAILS';
export const SET_INSPECT_ROUTE_SESSION_DETAILS = 'SET_INSPECT_ROUTE_SESSION_DETAILS';

export function setInspectReducer(model) {
    return (dispatch) => {
        dispatch({ type: SET_INSPECT_DETAILS, payload: model });
    };
}

export function setInspectRouteSessionReducer(model) {
    return (dispatch) => {
        dispatch({ type: SET_INSPECT_ROUTE_SESSION_DETAILS, payload: model });
    };
}
