/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { renameKey } from '../../utils';
import { getScheduleMilkDemandItemQuery } from './queries';

const getScheduleMilkDemandItemDetails = async (date, user) => {
    try {
        if (user.role === 'transport') return [];
        const start = date.format();
        const end = date.clone().add(7, 'days').subtract(1, 'second').format();

        const params = {
            start,
            end,
            ...(user.role === 'processor' && { processorId: user.subuser ? user.processor_id : user.id }),
        };

        const scheduleItemsQuery = getScheduleMilkDemandItemQuery(params);
        const scheduleItemsRequest = axios.get(scheduleItemsQuery);

        const { data } = await scheduleItemsRequest;
        renameKey(data, '_id', 'id');
        return data;
    } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getScheduleMilkDemandItemDetails;
