import React from 'react';
import { dataTableSort, capitalize } from '../../../../utils';

const getColumns = () => [
    {
        title: 'Processor',
        field: 'processor',
    },
    {
        title: 'Loads',
        field: 'count',
        type: 'numeric',
        render: (rowData) => {
            return <div>{rowData.count ? parseInt(rowData.count) : '-'}</div>;
        },
        customSort: (a, b) => dataTableSort(a, b, 'count'),
    },
    {
        title: 'Amount',
        field: 'amount',
        type: 'numeric',
        render: (rowData) => {
            return <div>{rowData.amount ? parseFloat(rowData.amount).toLocaleString('us', { maximumFractionDigits: 2, minimumFractionDigits: 2 }) : '-'}</div>;
        },
        customSort: (a, b) => dataTableSort(a, b, 'amount'),
    },
];

const getData = (data) =>
    Object.entries(data).map((entry) => {
        const [processor, totals] = entry;
        return {
            processor: capitalize(processor),
            ...totals,
        };
    });

export default {
    getColumns,
    getData,
};
