import React from 'react';
import { Typography, withStyles } from '@material-ui/core';
import Card from '@material-ui/core/es/Card/Card';
import { getPenaltyStatusImage } from '../../utils';
import moment from 'moment-timezone';
import _ from 'lodash';

const MonthPenaltyBox = ({ title, colour, scc, bacto, clickEvent, freezingPoint, demeritPoints, adjustedPoints, region, monthlyReports, date }) => {
    const isCurrentMonth = moment().isSame(moment(date), 'month');
    const currentDateReportExists = !!monthlyReports && monthlyReports.length > 0 ? moment(date).isSame(moment(monthlyReports[0].report_date), 'month') : false;
    const reportToUse = !!monthlyReports && monthlyReports.length > 0 ? monthlyReports.filter((report) => moment(report.report_date).format('YYYY-MM') === moment(!isCurrentMonth ? date : moment(date).subtract(1, 'month')).format('YYYY-MM')) : [];

    return (
        <Card className="w-full rounded-8 shadow-none border-8 py-8" style={{ cursor: 'pointer', borderColor: colour }} onClick={clickEvent}>
            <div className="p-8 flex flex-col flex-wrap">
                <Typography className="h2" color="textSecondary">
                    {title}
                </Typography>
                {(region === 'NL' || region === 'PEI') && (
                    <div
                        className="items-end w-full inline-block"
                        style={{
                            fontSize: '20px',
                            fontWeight: 'normal',
                            paddingTop: '12px',
                        }}
                    >
                        {scc && (
                            <div className="inline-flex w-full flex-wrap">
                                <div className="w-2/5" style={{ textAlign: 'left' }}>
                                    SCC: &nbsp;
                                </div>
                                <div className="w-3/5">
                                    {
                                        <div className="w-full" style={{ display: 'inline-flex' }}>
                                            <img className="max-w-96 max-h-36 w-2/3" src={getPenaltyStatusImage(region, scc.status, 'scc')} alt="logo" />
                                            {region === 'NL' && (
                                                <i className="w-1/3">
                                                    &nbsp;
                                                    {!!!scc.percentage ? '0.00' : scc.percentage}%
                                                </i>
                                            )}
                                            {region === 'PEI' && (
                                                <i className="w-1/3">
                                                    &nbsp;
                                                    {scc.count}
                                                </i>
                                            )}
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                        <br />
                        {bacto && (
                            <div className="w-full inline-flex flex-wrap">
                                <div className="w-2/5" style={{ textAlign: 'left' }}>
                                    BACTOSCAN:&nbsp;
                                </div>
                                <div className="w-3/5">
                                    <div className="w-full" style={{ display: 'inline-flex' }}>
                                        <img className="max-w-96 max-h-36 w-2/3" src={getPenaltyStatusImage(region, bacto.status, 'bacto_scan')} alt="logo" />
                                        {region === 'NL' && (
                                            <i className="w-1/3">
                                                &nbsp;
                                                {!!!bacto.percentage ? '0.00' : bacto.percentage}%
                                            </i>
                                        )}
                                        {region === 'PEI' && (
                                            <i className="w-1/3">
                                                &nbsp;
                                                {bacto.count}
                                            </i>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        <br />
                        {freezingPoint && region === 'PEI' && (
                            <div className="w-full inline-flex flex-wrap">
                                <div className="w-2/5" style={{ textAlign: 'left' }}>
                                    Freezing Point:&nbsp;
                                </div>
                                <div className="w-3/5">
                                    <div className="w-full" style={{ display: 'inline-flex' }}>
                                        <img className="max-w-96 max-h-36 w-2/3" src={getPenaltyStatusImage(region, freezingPoint.status, 'temperature')} alt="logo" />
                                        <i className="w-1/3">
                                            &nbsp;
                                            {freezingPoint.count}
                                        </i>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {region === 'RF' && (
                    <div
                        className="items-end w-full inline-block"
                        style={{
                            fontSize: '20px',
                            fontWeight: 'normal',
                            paddingTop: '12px',
                        }}
                    >
                        <div className="inline-flex w-full flex-wrap">
                            <div className="w-3/5" style={{ textAlign: 'left' }}>
                                Merit Points:&nbsp;
                            </div>
                            <div className="w-2/5">
                                <div className="w-full pl-12" style={{ display: 'inline-flex' }}>
                                    <i className="w-1/3">{isCurrentMonth && !currentDateReportExists && reportToUse[0] ? reportToUse[0].merits_available : reportToUse[0] ? reportToUse[0].merit_points_carried_forward : 0}</i>
                                </div>
                            </div>
                        </div>
                        <div className="inline-flex w-full flex-wrap">
                            <div className="w-3/5 pr-6" style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
                                Demerit Points:
                            </div>
                            <div className="w-2/5">
                                <div className="w-full pl-12" style={{ display: 'inline-flex' }}>
                                    <i className="w-1/3">{isCurrentMonth && !currentDateReportExists ? demeritPoints : reportToUse[0] ? reportToUse[0].total_demerit_points : demeritPoints || 0}</i>
                                </div>
                            </div>
                        </div>
                        <div className="inline-flex w-full flex-wrap">
                            <div className="w-3/5 pr-6" style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
                                Scaled Demerits:
                            </div>
                            <div className="w-2/5">
                                <div className="w-full pl-12" style={{ display: 'inline-flex' }}>
                                    <i className="w-1/3">{isCurrentMonth && !currentDateReportExists ? adjustedPoints : reportToUse[0] ? reportToUse[0].scaled_demerit_points?.toFixed(0) : adjustedPoints || 0}</i>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Card>
    );
};

export default withStyles(null, { withTheme: true })(MonthPenaltyBox);
