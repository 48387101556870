import React, { useRef, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import MuiPhoneNumber from 'material-ui-phone-number';
import { getCountryCode } from '../../../utils';

const ReactHookFormPhoneInput = ({ name, label, rules, fieldOpts, required, region, ...rest }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const inputRef = useRef(null);
    const error = errors[name];

    useEffect(() => {
        if (error) {
            inputRef.current.inputRef.scrollIntoView({ block: 'nearest' });
        }
    }, [error]);

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { ref, ...field } }) => (
                <MuiPhoneNumber
                    defaultCountry={getCountryCode(region)}
                    disableAreaCodes
                    InputLabelProps={{ shrink: true }}
                    style={{ width: '100%', margin: '8px 0' }}
                    {...field}
                    {...fieldOpts}
                    error={!!error}
                    required={required}
                    label={label}
                    helperText={error?.message || ''}
                    {...rest}
                    ref={inputRef}
                    // wrap formatting
                />
            )}
        />
    );
};

export default ReactHookFormPhoneInput;
