import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getDropoffsQuery, getLabReportsQuery, getProcessorsQuery, getPickupsQuery, getRouteSessionsQuery, getRoutesQuery, getDriversQuery, batchQuery, bulkQuery } from './queries';
import getPickupsByDropoffId from './utils/getPickupsByDropoffId';
import calculateComponents from './utils/calculateComponents';
import { renameKey, arrayToMap, formatLabsForComponentCalculation } from '../../utils';
import store from '../store';

const getProcessorLabDetails = (pId, start, end) =>
    new Promise((resolve, reject) => {
        const { region } = store.getState().persisted.auth.user.data;

        const dropoffsQuery = bulkQuery(
            getDropoffsQuery,
            {
                start,
                end,
                processorId: pId,
                filterType: 'milk',
                filterDeleted: true,
            },
            'processorId'
        );
        const dropoffsRequest = axios.post(dropoffsQuery.url, dropoffsQuery.body);

        dropoffsRequest
            .then((dropoffsResponse) => {
                const dropoffs = dropoffsResponse.data;
                renameKey(dropoffs, '_id', 'id');
                const processorIds = _.uniq(_.map(dropoffs, 'processor_id'));
                const routeSessionIds = _.uniq(_.map(dropoffs, 'route_session_id'));
                const driverIds = _.uniq(_.map(dropoffs, 'driver_id'));
                const processorQuery = bulkQuery(getProcessorsQuery, { fields: ['id', 'name', 'geofence', 'latitude', 'longitude'], ids: processorIds });
                const driverQuery = bulkQuery(getDriversQuery, { fields: ['id', 'name'], ids: driverIds });
                const routeSessionQuery = bulkQuery(getRouteSessionsQuery, {
                    fields: ['id', 'BOL', 'status', 'route_id', 'route_number', 'manifest_number'],
                    ids: routeSessionIds,
                    filterDeleted: true,
                });

                const processorRequest = axios.post(processorQuery.url, processorQuery.body);
                const driverRequest = axios.post(driverQuery.url, driverQuery.body);
                const routeSessionRequest = axios.post(routeSessionQuery.url, routeSessionQuery.body);

                const pickupQuery = bulkQuery(getPickupsQuery, { routeSessionIds, filterDeleted: true }, 'routeSessionIds');
                const pickupRequest = axios.post(pickupQuery.url, pickupQuery.body);

                const requests = [processorRequest, driverRequest, pickupRequest, routeSessionRequest];

                return axios.all(requests).then(
                    axios.spread((...responses) => {
                        const processors = responses[0].data;
                        renameKey(processors, '_id', 'id');
                        const pickups = responses[2].data;
                        renameKey(pickups, '_id', 'id');
                        const routeSessions = responses[3].data;
                        renameKey(routeSessions, '_id', 'id');

                        const firstPickup = _.minBy(pickups, 'created_at');
                        const lastPickup = _.maxBy(pickups, 'created_at');

                        const labReportStart = firstPickup ? moment(firstPickup.created_at).startOf('day').format() : null;
                        const labReportEnd = lastPickup ? moment(lastPickup.created_at).endOf('day').format() : null;

                        const producerIds = _.uniq(_.map(pickups, 'producer_id'));

                        const labReportQuery = bulkQuery(
                            getLabReportsQuery,
                            {
                                fields: ['producer_id', 'date', 'fat', 'somatic_cell_count', 'protein', 'pickup_id', 'lactose', 'other_solids', 'solids_not_fat'],
                                start: labReportStart,
                                end: labReportEnd,
                                producerIds,
                                filterDeleted: true,
                            },
                            'producerIds'
                        );
                        const labReportRequest = axios.post(labReportQuery.url, labReportQuery.body);

                        const routeIds = _.uniq(_.map(routeSessions, 'route_id'));

                        const routeQuery = batchQuery(getRoutesQuery, { fields: ['id', 'name'], ids: routeIds }, 'ids');
                        const routeRequest = _.map(routeQuery, (q) => axios.get(q));

                        const subRequests = [labReportRequest, ...routeRequest];

                        return axios.all(subRequests).then(
                            axios.spread((...subResponses) => {
                                const labReports = subResponses[0].data;
                                const routes = _.reduce(subResponses.slice(labReportRequest.length), (acc, val) => [...acc, ...val.data], []);

                                const [groupedLabReports, labsByPickupIdMap] = formatLabsForComponentCalculation(labReports, region);

                                const pickupsByRouteSessionId = _.groupBy(pickups, 'route_session_id');
                                const dropoffsByRouteSessionId = _.groupBy(dropoffs, 'route_session_id');

                                const pickupsDropoffsByRouteSessionId = _.mapValues(
                                    _.groupBy(
                                        _.map(routeSessionIds, (id) => {
                                            const rsDropoffs = dropoffsByRouteSessionId[id];
                                            const rsPickups = pickupsByRouteSessionId[id] ? pickupsByRouteSessionId[id] : [];
                                            return {
                                                route_session_id: id,
                                                dropoffs: rsDropoffs,
                                                pickups: rsPickups,
                                            };
                                        }),
                                        'route_session_id'
                                    ),
                                    (o) => _.pick(o[0], ['pickups', 'dropoffs'])
                                );

                                const pickupsByDropoffIdByRouteSessionId = _.mapValues(
                                    _.groupBy(
                                        _.map(routeSessionIds, (id) => {
                                            const { pickups: p, dropoffs: d } = pickupsDropoffsByRouteSessionId[id];
                                            return { route_session_id: id, pickupsByDropoffId: d ? getPickupsByDropoffId(d, p) : [] };
                                        }),
                                        'route_session_id'
                                    ),
                                    (o) => o[0].pickupsByDropoffId
                                );

                                const processorMap = arrayToMap(processors, 'id');
                                const routeSessionMap = arrayToMap(routeSessions, 'id');
                                const routesMap = arrayToMap(routes, 'id');

                                const data = _.map(dropoffs, (dropoff) => {
                                    const processor = [processorMap[dropoff.processor_id]];
                                    const routeSession = routeSessionMap[dropoff.route_session_id]; // not an array
                                    const pickupsForDropoff = routeSession ? pickupsByDropoffIdByRouteSessionId[routeSession.id][dropoff.id] : [];
                                    const components = calculateComponents(pickupsForDropoff, groupedLabReports, region, labsByPickupIdMap);

                                    return {
                                        ...dropoff,
                                        components,
                                        processor,
                                        route_session: [{ ...routeSession, route: routeSession && routesMap[routeSession.route_id] ? routesMap[routeSession.route_id].name : '' }],
                                    };
                                });

                                resolve({ dropoffs: data });
                            })
                        );
                    })
                );
            })

            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getProcessorLabDetails;
