import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { batchQuery, getLabReportsQuery, getProducersQuery } from './queries';
import { getTwoStringOfMonth, numberFormat } from '../../utils';

const downloadADL = (month, year) =>
    new Promise((resolve) => {
        let ADLData = '';
        const start = moment(`${year}-${getTwoStringOfMonth(month)}-01`)
            .startOf('month')
            .format();
        const end = moment(`${year}-${getTwoStringOfMonth(month)}-01`)
            .endOf('month')
            .format();

        const producersQuery = getProducersQuery({ filterDeleted: true });
        const producersRequest = axios.get(producersQuery);

        producersRequest.then((producersResponse) => {
            const producers = producersResponse.data;
            const producerIds = _.uniq(_.map(producers, 'id'));

            const labReportQuery = batchQuery(
                getLabReportsQuery,
                {
                    fields: ['producer_id', 'fat', 'LOS', 'temperature', 'somatic_cell_count', 'protein', 'bacto_scan'],
                    start,
                    end,
                    producerIds,
                    filterOutliers: true,
                    filterDeleted: true,
                },
                'producerIds'
            );
            const labRequest = _.map(labReportQuery, (q) => axios.get(q));

            axios.all([...labRequest]).then(
                axios.spread((...responses) => {
                    const labs = _.reduce(responses, (acc, val) => [...acc, ...val.data], []);

                    const producerGroupedLabs = _.groupBy(labs, (v) => v.producer_id);

                    _.forEach(producerIds, (producerId) => {
                        const row = {};
                        const producerLabs = producerGroupedLabs[producerId];

                        if (!!!producerLabs) {
                            return true;
                        }

                        const producerAvgLOS = _.meanBy(
                            producerLabs.filter((v) => !!v.LOS),
                            (l) => l.LOS
                        );
                        const producerAvgProtein = _.meanBy(
                            producerLabs.filter((v) => !!v.protein),
                            (l) => l.protein
                        );

                        _.forEach(producerLabs, (singleLab) => {
                            if (!!singleLab.bacto_scan || !!singleLab.fat || !!singleLab.somatic_cell_count || !!singleLab.temperature || !!singleLab.LOS || !!singleLab.protein) {
                                row.lab_number = _.find(producers, { id: producerId }).lab_number;
                                row.date = moment(singleLab.date).format('YMMDD');
                                row.period = '1';
                                row.ibc = !!singleLab.bacto_scan ? numberFormat(singleLab.bacto_scan, 0).padStart(4) : '    ';
                                row.fat = !!singleLab.fat ? numberFormat(singleLab.fat * 10, 2).padStart(5) : '     ';
                                row.scc = !!singleLab.somatic_cell_count ? numberFormat(singleLab.somatic_cell_count, 0).padStart(5) : '     ';
                                row.freezing_point = !!singleLab.temperature ? numberFormat(singleLab.temperature * -1, 3).padStart(5) : '     ';
                                row.LOS = numberFormat(producerAvgLOS * 10, 1).padStart(5);
                                row.protein = numberFormat(producerAvgProtein * 10, 1).padStart(5);

                                ADLData = ADLData.concat(`${row.lab_number}         ${row.date}        ${row.period}  ${row.ibc}                    ${row.fat}${row.scc}${row.freezing_point}                                     ${row.LOS}${row.protein}\n`);
                            }
                        });
                    });

                    resolve(ADLData);
                })
            );
        });
    });

export default downloadADL;
