import store from '../store';
import React from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { post } from 'axios';
import { getUser } from '../../utils';

const LoggingBoundary = (props) => {
    React.useEffect(() => {
        window.addEventListener('fullstory/rageclick', (e) => {
            (async () => {
                const url = process.env.REACT_APP_SLACK_RAGEHOOK_URL;
                const { role, region, email } = getUser();
                const page = window.location.hash;
                const message = {
                    blocks: [
                        {
                            type: 'header',
                            text: {
                                type: 'plain_text',
                                text: ':rage: Someone Rage Clicked :rage:',
                                emoji: true,
                            },
                        },
                        {
                            type: 'divider',
                        },
                        {
                            type: 'section',
                            text: {
                                type: 'plain_text',
                                text: `Region: ${region}`,
                                emoji: true,
                            },
                        },
                        {
                            type: 'section',
                            text: {
                                type: 'plain_text',
                                text: `Account Type: ${role}`,
                                emoji: true,
                            },
                        },
                        {
                            type: 'section',
                            text: {
                                type: 'plain_text',
                                text: `User: ${email}`,
                                emoji: true,
                            },
                        },
                        {
                            type: 'section',
                            text: {
                                type: 'plain_text',
                                text: `Page: ${page}`,
                                emoji: true,
                            },
                        },
                        {
                            type: 'section',
                            text: {
                                type: 'mrkdwn',
                                text: 'Click here to view the session at the beginning.',
                            },
                            accessory: {
                                type: 'button',
                                text: {
                                    type: 'plain_text',
                                    text: 'Click Me',
                                    emoji: true,
                                },
                                value: 'click_me_123',
                                url: e.detail.eventReplayUrlAtStart,
                                action_id: 'button-action',
                            },
                        },
                        {
                            type: 'section',
                            text: {
                                type: 'mrkdwn',
                                text: 'Click here to view the session at the rage point.',
                            },
                            accessory: {
                                type: 'button',
                                text: {
                                    type: 'plain_text',
                                    text: 'Click Me',
                                    emoji: true,
                                },
                                value: 'click_me_123',
                                url: e.detail.eventReplayUrlAtCurrentTime,
                                action_id: 'button-action',
                            },
                        },
                    ],
                };
                if (!email.includes('developer@milkmoovement.com')) {
                    post(url, JSON.stringify(message), {
                        withCredentials: false,
                        transformRequest: [
                            (data, headers) => {
                                // eslint-disable-next-line no-param-reassign
                                delete headers.common.Authorization;
                                return data;
                            },
                        ],
                    });
                }
            })();
        });
    }, []);
    return props.children;
};

export default withRouter(LoggingBoundary);
