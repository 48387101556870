import cdiConfig from './CDI';
import prairieConfig from './PRAIRIE';
import mmpaConfig from './MMPA';
import defaultConfig from './default';
import udaConfig from './UDA';
import Region from '../../../../../../constants/region';

const commodityConfig = {
    [Region.CDI]: cdiConfig,
    [Region.PRAIRIE]: prairieConfig,
    [Region.MMPA]: mmpaConfig,
    [Region.UDA]: udaConfig,
};

const getConfig = (region) => commodityConfig[region] ?? defaultConfig;

const getCommoditiesMapping = (items) => {
    return Object.entries(items).map(([key, value]) => ({ id: key, name: value }));
};

const getCommodities = (region, mapped) => {
    const { commodities } = getConfig(region);
    return mapped ? getCommoditiesMapping(commodities) : commodities;
};

const getEnabledCommodities = (region, mapped) => {
    const { commodities, disabled = [] } = getConfig(region);

    let enabledCommodities = { ...commodities };
    if (disabled.length) {
        enabledCommodities = Object.entries(commodities).reduce((acc, [key, value]) => {
            if (!disabled.includes(value)) {
                acc[key] = value;
            }
            return acc;
        }, {});
    }

    return mapped ? getCommoditiesMapping(enabledCommodities) : enabledCommodities;
};

export { getCommodities, getEnabledCommodities };
