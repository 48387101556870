import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

const ProducerTouchBaseConfig = {
    auth: authRoles.admin,
    routes: [{ exact: true, path: '/producer-touch-base', component: FuseLoadable({ loader: () => import('./ProducerTouchBaseApp/ProducerTouchBaseApp') }) }],
};

export const PoolingDecisionsAppConfig = {
    auth: authRoles.admin,
    routes: [{ exact: true, path: '/pooling-decisions', component: FuseLoadable({ loader: () => import('./PoolingDecisionsApp/PoolingDecisionsApp') }) }],
};

export default [ProducerTouchBaseConfig, PoolingDecisionsAppConfig];
