import axios from 'axios';
import { getEquityMembersQuery } from './queries';
import { buildMembershipQuery } from './queries/builders';

const getEquityUsers = async (id, shouldFetchValues = true) => {
    let equityUser = {};

    // data from mongo User collection
    try {
        const queryOptions = {
            filterDeleted: true,
            filterSubusers: false,
            ids: [id],
            populate: {
                producers: ['license_number', 'name'],
            },
        };

        const equityUsersQuery = getEquityMembersQuery(queryOptions);
        const { data } = await axios.get(equityUsersQuery);
        [equityUser] = data;

        // assign parent license number to subuser
        if (equityUser?.subuser === true && equityUser?.producer_id != null) {
            const parentProducerId = equityUser?.producer_id;
            const parentUser = await getEquityUsers(parentProducerId, false);
            equityUser = { ...equityUser, license_number: parentUser?.license_number ?? '' };
        }
    } catch (error) {
        throw new Error('Failed to fetch equity user data.');
    }

    if (shouldFetchValues === false) {
        return equityUser;
    }

    // data from dynamo equity-member
    const emptyEquityValues = { equity_values: [], transactions: [], contracts: [] };

    try {
        const membershipQuery = `/v1/equityMembers/${id}`;
        const equityMembershipQuery = buildMembershipQuery(membershipQuery, {
            headers: {},
            data: {},
        });

        const { data } = await axios.get(equityMembershipQuery.url);
        const equityMembershipData = data.results;
        equityUser = { ...equityUser, ...equityMembershipData };
    } catch (error) {
        equityUser = { ...equityUser, ...emptyEquityValues };
    }

    return equityUser;
};

export default getEquityUsers;
