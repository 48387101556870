import { capitalize, toSentenceCase } from '../../../utils';

export const parseZodIssues = (error) => {
    const zodIssues = error?.response?.data?.[0]?.errors?.issues ?? [];
    return zodIssues.map((issue) => {
        // Construct the path string with array indices
        const pathWithIndices = issue.path
            .reduce((acc, part) => {
                // zod issues for array elememt are returned in the format:
                // [{ path: ['foo', 0, 'bar'], message: 'error message' }]
                if (typeof part === 'number') {
                    // Append array index to the previous path element
                    const lastIndex = acc.length - 1;
                    acc[lastIndex] = `${acc[lastIndex]} #${part + 1}`;
                } else {
                    // Append non-numeric path elements
                    acc.push(capitalize(toSentenceCase(part)));
                }
                return acc;
            }, [])
            .join(' ');

        // Return the formatted string
        const formattedIssue = `${capitalize(pathWithIndices)}: ${issue.message}`;
        return formattedIssue;
    });
};

// Check if the error is a zod validation error which has a specific format
export const isZodValidatorError = (error) => error?.response?.data?.[0]?.errors?.name === 'ZodError';
