import { FuseLoadable } from '@fuse';

export const Index = {
    auth: ['admin', 'sub-admin', 'transport', 'sub-transport', 'producer', 'sub-producer'],
    routes: [
        {
            path: '/hauling-charges/:date?',
            component: FuseLoadable({
                loader: () => import('./HaulingChargeApp'),
            }),
        },
    ],
};

export default [Index];
