import React from 'react';
import { withRouter } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
/* eslint-disable-next-line */
import classNames from 'classnames';
import AppContext from 'app/AppContext';
import { FuseScrollbars, FuseMessage, FuseDialog } from '@fuse';
import ToolbarLayout from './components/Toolbar';
import FooterLayout from './components/FooterLayout';
import LeftSideLayout from './components/LeftSideLayout';
import RightSideLayout from './components/RightSideLayout';
import NavbarWrapperLayout from './components/NavbarWrapperLayout';

const styles = (theme) => ({
    root: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        backgroundColor: '#F1F3F8',
        color: theme.palette.text.primary,
        '&.boxed': {
            maxWidth: 1280,
            margin: '0 auto',
            boxShadow: theme.shadows[3],
        },
        '&.scroll-body': {
            '& $wrapper': {
                height: 'auto',
                flex: '0 0 auto',
                overflow: 'auto',
            },
            '& $contentWrapper': {},
            '& $content': {},
        },
        '&.scroll-content': {
            '& $wrapper': {},
            '& $contentWrapper': {},
            '& $content': {},
        },
    },
    wrapper: {
        display: 'flex',
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        zIndex: 3,
        overflow: 'hidden',
        flex: '1 1 auto',
    },
    content: {
        position: 'relative',
        display: 'flex',
        overflow: 'auto',
        flex: '1 1 auto',
        flexDirection: 'column',
        width: '100%',
        '-webkit-overflow-scrolling': 'touch',
        zIndex: 2,
    },
});

const Layout = ({ classes, settings, children, numOfUnreadNotifications, getNumOfUnreadNotifications }) => {
    const layoutConfig = settings.layout.config;

    const toolbarFixedAbove = layoutConfig.toolbar.display && layoutConfig.toolbar.style === 'fixed' && layoutConfig.toolbar.position === 'above';
    const toolbarNotFixedAbove = layoutConfig.toolbar.display && layoutConfig.toolbar.style !== 'fixed' && layoutConfig.toolbar.position === 'above';

    const navbarDisplayLeft = layoutConfig.navbar.display && layoutConfig.navbar.position === 'left';
    const toolbarDisplayBelow = layoutConfig.toolbar.display && layoutConfig.toolbar.position === 'below';
    const footerDisplayBelow = layoutConfig.footer.display && layoutConfig.footer.position === 'below';
    const navbarDisplayRight = layoutConfig.navbar.display && layoutConfig.navbar.position === 'right';

    const footerNotFixedAbove = layoutConfig.footer.display && layoutConfig.footer.style !== 'fixed' && layoutConfig.footer.position === 'above';
    const footerFixedAbove = layoutConfig.footer.display && layoutConfig.footer.style === 'fixed' && layoutConfig.footer.position === 'above';

    const toolbarDisplayAbove = layoutConfig.toolbar.display && layoutConfig.toolbar.position === 'above';
    const footerDisplayAbove = layoutConfig.footer.display && layoutConfig.footer.position === 'above';

    switch (layoutConfig.scroll) {
        case 'body': {
            return (
                <AppContext.Consumer>
                    {({ routes }) => (
                        <div id="fuse-layout" className={classNames(classes.root, layoutConfig.mode, `scroll-${layoutConfig.scroll}`)}>
                            {layoutConfig.leftSidePanel.display && <LeftSideLayout />}

                            <div className="flex flex-1 flex-col overflow-hidden relative">
                                {toolbarFixedAbove && <ToolbarLayout layoutConfig={layoutConfig} />}

                                <FuseScrollbars className="overflow-auto">
                                    {toolbarNotFixedAbove && <ToolbarLayout layoutConfig={layoutConfig} />}

                                    <div className={classes.wrapper}>
                                        {navbarDisplayLeft && <NavbarWrapperLayout />}

                                        <div className={classes.contentWrapper}>
                                            {toolbarDisplayBelow && <ToolbarLayout layoutConfig={layoutConfig} />}

                                            <div className={classNames(classes.content, `scrollable-${layoutConfig.scroll}`)}>
                                                <FuseDialog />
                                                {renderRoutes(routes)}
                                                {children}
                                            </div>

                                            {footerDisplayBelow && <FooterLayout />}
                                        </div>

                                        {navbarDisplayRight && <NavbarWrapperLayout />}
                                    </div>

                                    {footerNotFixedAbove && <FooterLayout />}
                                </FuseScrollbars>

                                {footerFixedAbove && <FooterLayout />}
                            </div>

                            {layoutConfig.rightSidePanel.display && <RightSideLayout />}

                            <FuseMessage />
                        </div>
                    )}
                </AppContext.Consumer>
            );
        }
        case 'content':
        default: {
            return (
                <AppContext.Consumer>
                    {({ routes }) => (
                        <div id="fuse-layout" className={classNames(classes.root, layoutConfig.mode, `scroll-${layoutConfig.scroll}`)}>
                            {layoutConfig.leftSidePanel.display && <LeftSideLayout />}

                            <div className="flex flex-1 flex-col overflow-hidden relative">
                                {toolbarDisplayAbove && <ToolbarLayout layoutConfig={layoutConfig} />}

                                <div className={classes.wrapper}>
                                    {navbarDisplayLeft && <NavbarWrapperLayout />}

                                    <div className={classes.contentWrapper}>
                                        {toolbarDisplayBelow && layoutConfig.toolbar.style === 'fixed' && <ToolbarLayout layoutConfig={layoutConfig} numOfUnreadNotifications={numOfUnreadNotifications} />}

                                        <FuseScrollbars className={classNames(classes.content, `scrollable-${layoutConfig.scroll}`)}>
                                            {toolbarDisplayBelow && layoutConfig.toolbar.style !== 'fixed' && <ToolbarLayout layoutConfig={layoutConfig} />}

                                            <FuseDialog />

                                            {renderRoutes(routes)}
                                            {children}

                                            {footerDisplayBelow && layoutConfig.footer.style !== 'fixed' && <FooterLayout />}
                                        </FuseScrollbars>

                                        {footerDisplayBelow && layoutConfig.footer.style === 'fixed' && <FooterLayout />}
                                    </div>

                                    {navbarDisplayRight && <NavbarWrapperLayout />}
                                </div>

                                {footerDisplayAbove && <FooterLayout />}
                            </div>

                            {layoutConfig.rightSidePanel.display && <RightSideLayout getNumOfUnreadNotifications={getNumOfUnreadNotifications} />}

                            <FuseMessage />
                        </div>
                    )}
                </AppContext.Consumer>
            );
        }
    }
};

function mapStateToProps({ fuse }) {
    return { settings: fuse.settings.current };
}

export default withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps)(Layout)));
