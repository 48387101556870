import Environment from '../../Environment';
import MockSplit from '../../MockSplit';

const local = new Environment({
    name: 'LOCAL',
    evaluationFn: (attributes) => {
        if (attributes.email === 'developer@milkmoovement.com') return 'on';
        return 'off';
    },
});

const staging = new Environment({
    ...local,
    name: 'STAGING',
});

const can_demo_geofence_tool = new MockSplit({
    name: 'can_demo_geofence_tool',
    environments: [local, staging],
});

export default can_demo_geofence_tool;
