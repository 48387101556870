import React from 'react';
import { Grid } from '@mui/material';
import { GridToolbarQuickFilter, GridToolbarContainer } from '@mui/x-data-grid';
import LocalizedDatePicker from '../../../../custom-widgets/LocalizedDatePicker';
import { StyledExportButton } from '../../../../custom-components';

const toolbarSx = {
    display: 'flex',
    justifyContent: 'space-between',
    mt: (theme) => theme.spacing(1),
    p: (theme) => theme.spacing(2),
    leftMargin: { ml: (theme) => theme.spacing(2) },
};

const ReceivingToolbar = ({ startDate, setStartDate, endDate, setEndDate }) => {
    const handleStartDate = (date) => {
        setStartDate(date.clone().startOf('day'));
    };

    const handleCloseDate = (date) => {
        setEndDate(date.clone().endOf('day'));
    };

    return (
        <GridToolbarContainer sx={toolbarSx}>
            <Grid item>
                <LocalizedDatePicker maxDate={endDate} label="Start Date" views={['day', 'month']} value={startDate} onChange={handleStartDate} />
                <LocalizedDatePicker minDate={startDate.add(1, 'minute')} label="End Date" views={['day', 'month']} value={endDate} onChange={handleCloseDate} sx={toolbarSx.leftMargin} />
            </Grid>
            <Grid item>
                <GridToolbarQuickFilter />
                <StyledExportButton sx={toolbarSx.leftMargin} />
            </Grid>
        </GridToolbarContainer>
    );
};

export default ReceivingToolbar;
