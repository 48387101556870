import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { Checkbox, DialogContentText, Button, FormControlLabel, Box, Grid } from '@material-ui/core';
import { showMessage } from 'app/store/actions';
import { downloadAdminReports, getHaulingListDetails, getProcessorListDetails, getProducerListDetails } from '../../repository';
import moment from 'moment-timezone';
import SearchableSelectAll from '../SearchableSelectAll';
import { arrayToMap, getId, getUserRegion, getRole } from '../../../utils';
import { LoadingDialog } from '.';
import { getSampleBarcodeBatches } from '../../repository/batchRepository';
import _ from '@lodash';
import Region from '../../constants/region';

const DATA_SOURCE_OPTIONS = [
    { name: 'Pickup Date', value: 'pickup' },
    { name: 'Delivery Date', value: 'dropoff' },
];

export const MILK_CATEGORY_OPTIONS = [
    {
        name: 'Member Producer',
        value: 'member_producer',
    },
    {
        name: 'Outside Milk',
        value: 'outside_milk',
    },
];

const PRODUCERS_ADDED_LOST_ORDER_OPTIONS = [
    {
        name: 'Added Date',
        value: 'added',
    },
    {
        name: 'Lost Date',
        value: 'lost',
    },
];

function PaperComponent(props) {
    return <Paper {...props} />;
}

const AdminReportDialog = ({ hideLoadingDialog, selectedYear, selectedMonth, selectedDate, type, singleDate, startDate, endDate, filter, defaultMilkCategory }) => {
    const fileInput = React.createRef();
    const [open, setOpen] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const [selectedHauler, setSelectedHauler] = useState('');
    const [selectedProcessor, setSelectedProcessor] = useState(null);
    const [haulingCompanies, setHaulingCompanies] = useState([]);
    const [selectedTerritory, setSelectedTeritory] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedDataSource, setSelectedDataSource] = useState(DATA_SOURCE_OPTIONS[0]);
    const [processors, setProcessors] = useState([]);
    const [producers, setProducers] = useState([]);
    const [selectedProducer, setSelectedProducer] = useState(null);
    const [selectedProducers, setSelectedProducers] = useState([]);
    const [batches, setBatches] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState(null);
    const [loading, setLoading] = useState(false);
    const [checked, setChecked] = useState(false);
    const [CDIweek, setCDIweek] = useState(null);
    const [selectedPayWeeks, setSelectedPayWeeks] = useState([]);
    const role = getRole();
    const region = getUserRegion();
    const dispatch = useDispatch();
    const haulingMap = arrayToMap(haulingCompanies, 'id');
    const [selectedMilkCategory, setSelectedMilkCategory] = useState(defaultMilkCategory);
    const [isLoading, setIsLoading] = useState(true);
    const [producerOptions, setProducerOptions] = useState([]);
    const [selectedPayroll, setSelectedPayroll] = useState();
    const [selectedPatronage, setSelectedPatronage] = useState();
    const [selectedProducersAddedLostOrder, setSelectedProducersAddedLostOrder] = useState(PRODUCERS_ADDED_LOST_ORDER_OPTIONS[0]);
    const [labRegions, setLabRegions] = useState([]);
    const [selectedLabRegion, setSelectedLabRegion] = useState(null);
    const [selectedGrade, setSelectedGrade] = useState(null);

    useEffect(() => {
        (async () => {
            setIsLoading(true);
            const [haulingResults, processorResults, producerResults] = await Promise.all([getHaulingListDetails(), getProcessorListDetails(), getProducerListDetails({})]);
            setHaulingCompanies(haulingResults.haulingCompanies);
            setProcessors(arrayToMap(processorResults.processors, 'name'));
            const producersMap = arrayToMap(producerResults.producers, 'name');
            setProducers(producersMap);
            if (region === 'PRAIRIE') {
                const producerGroupedByCategory = _.groupBy(producersMap, 'milk_category');
                // eslint-disable-next-line dot-notation
                const outsideProducers = producerGroupedByCategory['outside_milk'];
                setProducerOptions([{ value: '', name: 'All Producers' }, { value: 'member_producer', name: 'Member Producers' }, ...outsideProducers?.map((outsideProducer) => ({ name: outsideProducer.name, value: outsideProducer.id }))]);
            }
            if (['labQualityResultsByStateExcelReport'].includes(type)) {
                const filteredLabRegions = producerResults.producers.filter((producer) => {
                    return !!producer.lab_region;
                });
                const labRegionsAndStates = filteredLabRegions.map(({ lab_region, state }) => ({ lab_region, state }));
                setLabRegions(_.uniqBy(labRegionsAndStates, 'lab_region'));
            }
            setIsLoading(false);
        })();
    }, []);

    useEffect(() => {
        const getBatches = async () => {
            const batchResults = await getSampleBarcodeBatches(startDate, endDate);
            setBatches(batchResults);
        };
        getBatches();
    }, [startDate, endDate]);

    const handleClose = () => {
        setOpen(false);
    };

    const handleEnableButton = () => {
        setEnableButton(true);
    };

    const handleHaulerChange = (event, value) => {
        setSelectedHauler(haulingMap[value?.id]);
    };

    const handleProcessorChange = (event, value) => {
        const { name } = value;
        setSelectedProcessor(name === 'All Processors' ? null : name);
    };

    const fiscalYearReportCriteriaMet = () => ['riverinaFreshFiscalYearSummaryReport'].includes(type) && !['producer'].includes(role);

    const isMasterPickupsOrDropoffsForPrairie = () => {
        if (['masterPickupExcelReport', 'masterDropoffExcelReport'].includes(type) && ['PRAIRIE'].includes(region)) {
            return true;
        }
        return false;
    };

    const requiresProducer = () => {
        if (['productionHistoryReport', 'cdiAllLabTestsReportCSV', 'riverinaFreshFiscalYearSummaryReport'].includes(type) && !['producer'].includes(role)) {
            return true;
        }
        return false;
    };

    const requiresProducers = () => {
        if (['missedListReport', 'producerDailyDeliveriesByDestinationReport', 'BSTPoundsByHaulerReport'].includes(type) && !['producer'].includes(role)) {
            return true;
        }
        return false;
    };

    const requiresProcessor = () => {
        if (['prairieFarmsShrinkReport', 'deliveredLoadsReportCSV', 'farmProductionReport'].includes(type) && !['processor'].includes(role)) {
            return true;
        }
        return false;
    };

    const overrideDisableButton = () => {
        if (['productionHistoryReport'].includes(type) || fiscalYearReportCriteriaMet()) {
            return true;
        }
        return false;
    };

    const handleProducerChange = (event, value) => {
        const { name } = value;
        setSelectedProducer(name);
        if (fiscalYearReportCriteriaMet()) {
            setEnableButton(true);
        } else {
            setEnableButton(false);
        }
    };

    const handleProducersChange = (event, value) => {
        const producerNames = value.map((producer) => {
            return producer.producerName;
        });
        setSelectedProducers(producerNames);
    };

    const shrinkReportCriteriaMet = () => {
        if (['prairieFarmsShrinkReport'].includes(type) && !['producer'].includes(role)) {
            return true;
        }
        return false;
    };

    const handleShrinkReportProducerChange = (event, value) => {
        const { name } = value;
        setSelectedMilkCategory(value);
        setSelectedProducer(name === 'All Producers' ? null : name);
    };

    const handleBatchChange = (event, value) => {
        setSelectedBatch(value);
    };

    const handleTerritoryChange = (event, value) => {
        setSelectedTeritory(value);
    };

    const handleStateChange = (event, value) => {
        setSelectedState(value);
    };

    const handleDataSourceChange = (event, value) => {
        setSelectedDataSource(value);
    };

    const handleSort = () => {
        setChecked(true);
    };

    const handleWeekChange = (event, value) => {
        setCDIweek(value.value);
        setEnableButton(true);
    };

    const handlePayWeekChange = (event, value) => {
        setSelectedPayWeeks(value);
    };

    const handleLabRegionChange = (event, value) => {
        setSelectedLabRegion(value);
    };

    const handleMilkGradeChange = (event, value) => {
        setSelectedGrade(value);
    };

    const handleDownload = async () => {
        const date = `${selectedYear}-${selectedMonth}`;
        const start = startDate ? moment(startDate).format() : singleDate ? moment(selectedDate).format() : CDIweek ? moment(CDIweek).format() : moment(date).startOf('month').format();
        const end = endDate ? moment(endDate).format() : singleDate ? null : selectedMonth === moment().format('MM') ? moment().format() : moment(date).endOf('month').format();

        try {
            let response = '';
            setLoading(true);
            switch (type) {
                case 'transportationHaulerReport':
                    {
                        const haulingNumber = ['admin'].includes(role) ? haulingMap[selectedHauler?.id]?.hauler_number : haulingMap[getId()]?.hauler_number;
                        response = await downloadAdminReports({ start, end, type, identifier: haulingNumber });
                    }
                    break;
                case 'travisReport':
                    {
                        const sort = checked.toString();
                        const identifier = undefined;
                        response = await downloadAdminReports({ start, end, type, identifier, sort });
                    }
                    break;

                case 'cdiLabVariancesExcelReport':
                    {
                        const territory = selectedTerritory?.value || null;
                        response = await downloadAdminReports({ start, type, identifier: territory, identifierName: 'territory' });
                    }
                    break;
                case 'deliveredLoadsReportCSV':
                    {
                        const processorId = processors?.[selectedProcessor]?.id || null;
                        response = await downloadAdminReports({ start, end, type, identifier: processorId, identifierName: 'processorId' });
                    }
                    break;
                case 'riverinaFreshFiscalYearSummaryReport':
                    {
                        const producerId = ['admin'].includes(role) ? producers?.[selectedProducer]?.id : getId();
                        response = await downloadAdminReports({ start: selectedYear, type, identifier: producerId, identifierName: 'producer' });
                    }
                    break;
                case 'prairieFarmsShrinkReport':
                    {
                        const producerId = producers?.[selectedProducer]?.id || null;
                        const processorId = ['admin'].includes(role) ? processors?.[selectedProcessor]?.id : getId();
                        const milkCategory = selectedMilkCategory?.value === 'member_producer' ? 'member_producer' : selectedMilkCategory?.value === 'outside_milk' ? 'outside_milk' : null;
                        response = await downloadAdminReports({
                            start,
                            end,
                            type,
                            identifier: producerId,
                            identifierName: 'producer',
                            secondIdentifier: processorId,
                            secondIdentifierName: 'processorId',
                            thirdIdentifier: milkCategory,
                            thirdIdentifierName: 'milkCategory',
                            fourthIdentifier: selectedDataSource?.value,
                            fourthIdentifierName: 'dataSource',
                        });
                    }
                    break;
                case 'producerDistributionSummaryByProducerReport':
                case 'producerDistributionSummaryReport':
                case 'producerDistributionSummaryByRouteReport':
                case 'loadsQualifiedMissingReport':
                case 'poundsByDistrictStateCountyReport':
                case 'poundsByStateCountyReport':
                case 'producerDistributionReport':
                case 'poundsByDistrictReport':
                case 'poundsByHaulerDestinationDay':
                case 'poundsByHaulerByDestination':
                case 'poundsByStateReport':
                case 'producerPoundsExcelReport':
                case 'producerDeliveriesByPlant':
                case 'masterPickupExcelReport':
                case 'masterDropoffExcelReport':
                    response = await downloadAdminReports({
                        start,
                        end,
                        type,
                        identifier: selectedMilkCategory?.value,
                        identifierName: 'milkCategory',
                        secondIdentifier: selectedPatronage,
                        secondIdentifierName: 'patronage',
                        thirdIdentifier: selectedPayroll,
                        thirdIdentifierName: 'payroll',
                        fourthIdentifier: selectedDataSource?.value,
                        fourthIdentifierName: 'dataSource',
                    });
                    break;
                case 'producersAddedLostReport':
                    response = await downloadAdminReports({
                        start,
                        end,
                        type,
                        identifier: selectedProducersAddedLostOrder.value,
                        identifierName: 'order',
                    });
                    break;
                case 'BSTPoundsByHaulerReport':
                case 'producerDailyDeliveriesByDestinationReport': {
                    const producerIds = new Set(Array.isArray(selectedProducers) ? selectedProducers.map((producer) => producers?.[producer]?.id).filter((id) => !!id) : []);
                    response = await downloadAdminReports({
                        start,
                        end,
                        type,
                        identifier: selectedMilkCategory?.value,
                        identifierName: 'milkCategory',
                        secondIdentifier: selectedPatronage,
                        secondIdentifierName: 'patronage',
                        thirdIdentifier: selectedPayroll,
                        thirdIdentifierName: 'payroll',
                        fourthIdentifier: selectedDataSource?.value,
                        fourthIdentifierName: 'dataSource',
                        fifthIdentifier: Array.from(producerIds),
                        fifthIdentifierName: 'producer',
                    });
                    break;
                }
                case 'MAProductionReport': {
                    response = await downloadAdminReports({
                        start,
                        end,
                        type,
                        identifier: selectedMilkCategory?.value,
                        identifierName: 'milkCategory',
                        secondIdentifier: selectedPatronage,
                        secondIdentifierName: 'patronage',
                        thirdIdentifier: selectedPayroll,
                        thirdIdentifierName: 'payroll',
                        fourthIdentifier: selectedDataSource?.value,
                        fourthIdentifierName: 'dataSource',
                    });
                    break;
                }
                case 'producerPoolingReport': {
                    response = await downloadAdminReports({
                        start,
                        end,
                        type,
                        identifier: selectedMilkCategory?.value,
                        identifierName: 'milkCategory',
                        secondIdentifier: selectedPatronage,
                        secondIdentifierName: 'patronage',
                        thirdIdentifier: selectedPayroll,
                        thirdIdentifierName: 'payroll',
                    });
                    break;
                }
                case 'poolingQualificationReport': {
                    response = await downloadAdminReports({
                        start,
                        end,
                        type,
                        identifier: selectedMilkCategory?.value,
                        identifierName: 'milkCategory',
                        secondIdentifier: selectedPatronage,
                        secondIdentifierName: 'patronage',
                        thirdIdentifier: selectedPayroll,
                        thirdIdentifierName: 'payroll',
                    });
                    break;
                }
                case 'poolingDiversionLimits': {
                    response = await downloadAdminReports({
                        start,
                        end,
                        type,
                        identifier: selectedMilkCategory?.value,
                        identifierName: 'milkCategory',
                        secondIdentifier: selectedPayroll,
                        secondIdentifierName: 'payroll',
                    });
                    break;
                }
                case 'inTransitReport':
                    response = await downloadAdminReports({
                        start,
                        type,
                        identifier: selectedMilkCategory,
                        identifierName: 'milkCategory',
                        secondIdentifier: selectedPatronage,
                        secondIdentifierName: 'patronage',
                        thirdIdentifier: selectedPayroll,
                        thirdIdentifierName: 'payroll',
                    });
                    break;
                case 'productionHistoryReport':
                    {
                        const producerId = ['admin'].includes(role) ? producers?.[selectedProducer]?.id : getId();
                        response = await downloadAdminReports({ start, type, identifier: producerId, identifierName: 'id' });
                    }
                    break;
                case 'benchSheetReport':
                    {
                        const batchId = selectedBatch?._id;
                        response = await downloadAdminReports({ type, identifier: batchId, identifierName: 'batchId' });
                    }
                    break;
                case 'cdiAllLabTestsReportCSV': {
                    const producerId = ['admin'].includes(role) ? producers?.[selectedProducer]?.id : getId();
                    response = await downloadAdminReports({ start, end, type, identifier: producerId });
                    break;
                }
                case 'labQualityResultsByStateExcelReport':
                case 'labQualityResultsByStateTxtReport': {
                    const state = selectedState?.value || null;
                    const labRegion = selectedLabRegion?.value || null;
                    const milkGrade = selectedGrade?.value || null;
                    response = await downloadAdminReports({ start, end, type, identifier: state, identifierName: 'state', secondIdentifier: labRegion, secondIdentifierName: 'labRegion', thirdIdentifier: milkGrade, thirdIdentifierName: 'milkGrade' });
                    break;
                }
                case 'farmProductionReport': {
                    const processorId = ['admin'].includes(role) ? processors?.[selectedProcessor]?.id : getId();
                    response = await downloadAdminReports({
                        start,
                        end,
                        type,
                        identifier: selectedDataSource?.value,
                        identifierName: 'dataSource',
                        secondIdentifier: selectedMilkCategory?.value,
                        secondIdentifierName: 'milkCategory',
                        thirdIdentifier: selectedPatronage,
                        thirdIdentifierName: 'patronage',
                        fourthIdentifier: selectedPayroll,
                        fourthIdentifierName: 'payroll',
                        fifthIdentifier: processorId,
                        fifthIdentifierName: 'processorId',
                    });
                    break;
                }
                case 'totalPoundsByRequestReport': {
                    const dataSource = selectedDataSource?.value || null;
                    response = await downloadAdminReports({ start, end, type, identifier: dataSource, identifierName: 'dataSource' });
                    break;
                }
                case 'missedListReport':
                    {
                        const producerIds = selectedProducers.map((producer) => {
                            return ['admin'].includes(role) ? producers?.[producer]?.id : getId();
                        });
                        const payWeeks = selectedPayWeeks.map((payWeek) => {
                            return payWeek.value;
                        });
                        response = await downloadAdminReports({ start, type, identifier: producerIds, identifierName: 'producer', secondIdentifier: payWeeks, secondIdentifierName: 'payWeek' });
                    }
                    break;
                default:
                    response = await downloadAdminReports({ start, end, type });
            }
            setLoading(false);

            const pdfToLoad = response.data.s3Url;
            window.open(pdfToLoad, '_blank');

            dispatch(showMessage({ message: 'Successfully downloaded file.' }));
            if (hideLoadingDialog) {
                hideLoadingDialog();
            }
        } catch (err) {
            dispatch(showMessage({ message: err?.message ?? 'There was a problem downloading your file. Please contact support.' }));
            setLoading(false);
            if (hideLoadingDialog) {
                hideLoadingDialog();
            }
        }
        setOpen(false);
    };

    const transformBatchLabel = (option) => {
        return `Batch: ${option.batch_number} - ${option.batch_name}`;
    };

    const filteredLabRegions = selectedState ? labRegions.filter((item) => item.state === selectedState.value) : [];

    return (
        <>
            <div className="flex flex-col items-center w-full">
                {type === 'travisReport' && (
                    <Box display="flex" alignItems="center">
                        <FormControlLabel control={<Checkbox onChange={handleSort} value="checked" inputProps={{ 'aria-label': 'primary checkbox' }} />} label="Sort by Protein" />
                    </Box>
                )}
            </div>

            <div className="flex flex-col items-center w-full">
                {type === 'transportationHaulerReport' && !['transport'].includes(role) && (
                    <Box sx={{ width: '50%' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll variant="outlined" label="Select Hauler" options={haulingCompanies} onChange={handleHaulerChange} />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>

            <div className="flex flex-col items-center w-full">
                {shrinkReportCriteriaMet() && (
                    <Box sx={{ width: '50%' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll isLoading={isLoading} variant="outlined" label="Select Producer" options={producerOptions} onChange={handleShrinkReportProducerChange} />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>

            <div className="flex flex-col items-center w-full">
                {requiresProducer() && (
                    <Box sx={{ width: '50%' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll variant="outlined" label="Select Producer" options={Object.keys(producers).map((name) => ({ name }))} disableClearable onChange={handleProducerChange} />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>

            <div className="flex flex-col items-center w-full">
                {requiresProducers() && (
                    <Box sx={{ width: '50%' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    multiple
                                    variant="outlined"
                                    label="Select Producers"
                                    options={Object.keys(producers).map((name) => {
                                        const option = [producers[name]?.license_number, name, producers[name]?.status].filter((part) => part != null && part !== '').join(' - ');
                                        return { name: option, producerName: name };
                                    })}
                                    disableClearable
                                    onChange={handleProducersChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>

            <div className="flex flex-col items-center w-full">
                {type === 'benchSheetReport' && (
                    <Box sx={{ width: '50%' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll value={selectedBatch} label="Select Batch" options={batches.sort((a, b) => a.batch_number.localeCompare(b.batch_number))} customRender={transformBatchLabel} onChange={handleBatchChange} />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>

            <div className="flex flex-col items-center w-full">
                {requiresProcessor() && (
                    <Box sx={{ width: '50%', paddingTop: '30px' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll variant="outlined" label="Select Processor" options={['All Processors', ...Object.keys(processors)].map((name) => ({ name }))} onChange={handleProcessorChange} />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>

            <div className="flex flex-col items-center w-full">
                {type === 'productionHistoryReport' && (
                    <Box sx={{ width: '50%' }}>
                        <Grid container spacing={5} paddingBottom={'10px'} justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    variant="outlined"
                                    label={['CDI'].includes(region) ? 'CDI Pay Week' : 'Pay Week'}
                                    options={[
                                        {
                                            name: '1 (1-7)',
                                            value: moment(`${selectedYear}-${selectedMonth}`, 'YYYY-MM').startOf('month').format(),
                                        },
                                        {
                                            name: '2 (8-15)',
                                            value: moment(`${selectedYear}-${selectedMonth}`, 'YYYY-MM').date(8).format(),
                                        },
                                        {
                                            name: '3 (16-23)',
                                            value: moment(`${selectedYear}-${selectedMonth}`, 'YYYY-MM').date(16).format(),
                                        },
                                        {
                                            name: '4 (24-EOM)',
                                            value: moment(`${selectedYear}-${selectedMonth}`, 'YYYY-MM').endOf('month').format(),
                                        },
                                    ]}
                                    onChange={handleWeekChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>
            <div className="flex flex-col items-center w-full">
                {type === 'missedListReport' && (
                    <Box sx={{ width: '50%', paddingTop: '30px' }}>
                        <Grid container spacing={5} paddingBottom={'10px'} justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    multiple
                                    variant="outlined"
                                    label="Pay Week"
                                    options={[
                                        {
                                            name: '1 (1-7)',
                                            value: '1',
                                        },
                                        {
                                            name: '2 (8-15)',
                                            value: '2',
                                        },
                                        {
                                            name: '3 (16-23)',
                                            value: '3',
                                        },
                                        {
                                            name: '4 (24-EOM)',
                                            value: '4',
                                        },
                                    ]}
                                    onChange={handlePayWeekChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>
            <div className="flex flex-col items-center w-full">
                {type === 'cdiLabVariancesExcelReport' && (
                    <Box sx={{ width: '50%' }}>
                        <Grid container spacing={5} paddingBottom={'10px'} justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    variant="outlined"
                                    label="Select Territory"
                                    options={[
                                        { name: 'Field Area 1', value: 'field_area_1' },
                                        { name: 'Field Area 2', value: 'field_area_2' },
                                        { name: 'Field Area 3', value: 'field_area_3' },
                                        { name: 'Field Area 4', value: 'field_area_4' },
                                        { name: 'Field Area 5', value: 'field_area_5' },
                                    ]}
                                    onChange={handleTerritoryChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>
            <div className="flex flex-col items-center w-full">
                {type === 'inTransitReport' && (
                    <Box sx={{ width: '50%', paddingBottom: '20px' }}>
                        <Grid container justifyContent="center" spacing={5}>
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    variant="outlined"
                                    label="Category"
                                    options={[
                                        {
                                            name: 'Member Producer',
                                            value: 'member_producer',
                                        },
                                        {
                                            name: 'Outside Milk',
                                            value: 'outside_milk',
                                        },
                                    ]}
                                    value={selectedMilkCategory}
                                    onChange={(event, value) => setSelectedMilkCategory(value?.value)}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    variant="outlined"
                                    label="Patronage"
                                    options={[
                                        {
                                            name: 'Paid',
                                            value: true,
                                        },
                                        {
                                            name: 'Not Paid',
                                            value: false,
                                        },
                                    ]}
                                    value={selectedPatronage}
                                    onChange={(event, value) => setSelectedPatronage(value?.value)}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    variant="outlined"
                                    label="Payroll"
                                    options={[
                                        {
                                            name: 'Paid',
                                            value: true,
                                        },
                                        {
                                            name: 'Not Paid',
                                            value: false,
                                        },
                                    ]}
                                    value={selectedPayroll}
                                    onChange={(event, value) => setSelectedPayroll(value?.value)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>

            {type === 'producersAddedLostReport' ? (
                <div className="flex flex-col items-center w-full">
                    <Box sx={{ width: '50%' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    variant="outlined"
                                    label="Sort By"
                                    options={PRODUCERS_ADDED_LOST_ORDER_OPTIONS}
                                    value={selectedProducersAddedLostOrder}
                                    onChange={(event, value) => {
                                        setSelectedProducersAddedLostOrder(value || PRODUCERS_ADDED_LOST_ORDER_OPTIONS[0]);
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </div>
            ) : null}

            <div className="flex flex-col items-center w-full">
                {['labQualityResultsByStateExcelReport', 'labQualityResultsByStateTxtReport'].includes(type) && (
                    <Box sx={{ width: '50%' }}>
                        <Grid container spacing={5} paddingBottom={'10px'} justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    variant="outlined"
                                    label="Select State"
                                    options={
                                        [Region.BONGARDS].includes(region)
                                            ? [{ name: 'MN (Minnesota)', value: 'MN' }]
                                            : ['labQualityResultsByStateExcelReport'].includes(type)
                                            ? [
                                                  { name: 'FL (Florida)', value: 'FL' },
                                                  { name: 'IA (Iowa)', value: 'IA' },
                                                  { name: 'IL (Illinois)', value: 'IL' },
                                                  { name: 'IN (Indiana)', value: 'IN' },
                                                  { name: 'KS (Kansas)', value: 'KS' },
                                                  { name: 'KY (Kentucky)', value: 'KY' },
                                                  { name: 'MI (Michigan)', value: 'MI' },
                                                  { name: 'MN (Minnesota)', value: 'MN' },
                                                  { name: 'MO (Missouri)', value: 'MO' },
                                                  { name: 'NM (New Mexico)', value: 'NM' },
                                                  { name: 'OH (Ohio)', value: 'OH' },
                                                  { name: 'TN (Tennessee)', value: 'TN' },
                                                  { name: 'TX (Texas)', value: 'TX' },
                                                  { name: 'VA (Virginia)', value: 'VA' },
                                                  { name: 'WI (Wisconsin)', value: 'WI' },
                                              ]
                                            : [
                                                  { name: 'MN (Minnesota)', value: 'MN' },
                                                  { name: 'WI (Wisconsin)', value: 'WI' },
                                              ]
                                    }
                                    onChange={handleStateChange}
                                />
                            </Grid>
                            {[Region.BONGARDS].includes(region) && (
                                <Grid item xs={8}>
                                    <SearchableSelectAll
                                        variant="outlined"
                                        label="Select Grade"
                                        options={[
                                            { name: 'Grade A', value: 'A' },
                                            { name: 'Degrade', value: 'D' },
                                        ]}
                                        onChange={handleMilkGradeChange}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                )}
            </div>
            <div className="flex flex-col items-center w-full">
                {['labQualityResultsByStateExcelReport'].includes(type) && selectedState && (
                    <Box sx={{ width: '50%', paddingTop: '30px' }}>
                        <Grid container justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    variant="outlined"
                                    label="Select Lab Region"
                                    options={filteredLabRegions.map(({ lab_region }) => {
                                        return { name: lab_region, value: lab_region };
                                    })}
                                    onChange={handleLabRegionChange}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>
            <div className="flex flex-col items-center w-full">
                {([
                    'producerDeliveriesByPlant',
                    'producerPoundsExcelReport',
                    'totalPoundsByRequestReport',
                    'poundsByStateReport',
                    'poundsByHaulerByDestination',
                    'poundsByDistrictReport',
                    'poundsByStateCountyReport',
                    'prairieFarmsShrinkReport',
                    'producerDistributionSummaryReport',
                    'producerDistributionSummaryByRouteReport',
                    'producerDistributionSummaryByProducerReport',
                    'farmProductionReport',
                    'loadsQualifiedMissingReport',
                    'producerDailyDeliveriesByDestinationReport',
                    'producerDistributionReport',
                    'BSTPoundsByHaulerReport',
                    'poundsByDistrictStateCountyReport',
                    'poundsByHaulerDestinationDay',
                ].includes(type) ||
                    isMasterPickupsOrDropoffsForPrairie()) && (
                    <Box sx={{ width: '50%', paddingTop: '30px' }}>
                        <Grid container spacing={5} paddingBottom={'10px'} justifyContent="center">
                            <Grid item xs={8}>
                                <SearchableSelectAll variant="outlined" label="Sort By" options={DATA_SOURCE_OPTIONS} value={selectedDataSource || DATA_SOURCE_OPTIONS[0]} onChange={handleDataSourceChange} disableClearable hideCheckbox />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>
            <div className="flex flex-col items-center w-full">
                {([
                    'poundsByStateReport',
                    'poundsByStateCountyReport',
                    'producerDistributionSummaryReport',
                    'producerDistributionSummaryByProducerReport',
                    'loadsQualifiedMissingReport',
                    'producerDailyDeliveriesByDestinationReport',
                    'MAProductionReport',
                    'producerPoolingReport',
                    'poolingQualificationReport',
                    'poundsByDistrictStateCountyReport',
                    'poundsByDistrictReport',
                    'poundsByHaulerDestinationDay',
                    'poundsByHaulerByDestination',
                    'BSTPoundsByHaulerReport',
                    'producerDistributionReport',
                    'producerDistributionSummaryByRouteReport',
                    'farmProductionReport',
                    'producerPoundsExcelReport',
                    'producerDeliveriesByPlant',
                ].includes(type) ||
                    isMasterPickupsOrDropoffsForPrairie()) && (
                    <Box sx={{ width: '50%', paddingTop: '30px' }}>
                        <Grid container justifyContent="center" spacing={5}>
                            <Grid item xs={8}>
                                <SearchableSelectAll variant="outlined" label="Producer Category" options={MILK_CATEGORY_OPTIONS} value={selectedMilkCategory || undefined} onChange={(event, value) => setSelectedMilkCategory(value)} />
                            </Grid>
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    variant="outlined"
                                    label="Producer Patronage"
                                    options={[
                                        {
                                            name: 'Paid',
                                            value: true,
                                        },
                                        {
                                            name: 'Not Paid',
                                            value: false,
                                        },
                                    ]}
                                    value={selectedPatronage || undefined}
                                    onChange={(event, value) => setSelectedPatronage(value?.value)}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    variant="outlined"
                                    label="Producer Payroll"
                                    options={[
                                        {
                                            name: 'Paid',
                                            value: true,
                                        },
                                        {
                                            name: 'Not Paid',
                                            value: false,
                                        },
                                    ]}
                                    value={selectedPayroll || undefined}
                                    onChange={(event, value) => setSelectedPayroll(value?.value)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
                {['poolingDiversionLimits'].includes(type) && ['PRAIRIE'].includes(region) && (
                    <Box sx={{ width: '50%', paddingTop: '30px' }}>
                        <Grid container justifyContent="center" spacing={5}>
                            <Grid item xs={8}>
                                <SearchableSelectAll variant="outlined" label="Producer Category" options={MILK_CATEGORY_OPTIONS} value={selectedMilkCategory || undefined} onChange={(event, value) => setSelectedMilkCategory(value)} />
                            </Grid>
                            <Grid item xs={8}>
                                <SearchableSelectAll
                                    variant="outlined"
                                    label="Producer Payroll"
                                    options={[
                                        {
                                            name: 'Paid',
                                            value: true,
                                        },
                                        {
                                            name: 'Not Paid',
                                            value: false,
                                        },
                                    ]}
                                    value={selectedPayroll || undefined}
                                    onChange={(event, value) => setSelectedPayroll(value?.value)}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </div>
            <div className="flex w-full h-full items-center justify-center padding-top:50px">
                <LoadingDialog showDialog={loading} />
                <Box m={2} p={3}>
                    <Button disabled={!enableButton && overrideDisableButton()} type="submit" variant="contained" color="secondary" onClick={handleDownload}>
                        Download
                    </Button>
                    <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="sm">
                        <DialogTitle id="draggable-dialog-title">Download report</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Download report <input type="file" id="csvfile" ref={fileInput} onChange={() => handleEnableButton} />
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleDownload} variant="contained" color="primary" disabled={!enableButton}>
                                Download
                            </Button>
                            <Button onClick={handleClose} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Box>
            </div>
        </>
    );
};

export default withRouter(AdminReportDialog);
