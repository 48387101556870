/* eslint-disable no-restricted-globals */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, withRouter } from 'react-router-dom';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import { TextField, Grid, Box, Button, Typography, Checkbox, FormControl, FormControlLabel, debounce } from '@mui/material';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import Region from '../../constants/region';
import { showMessage, addRoute, editRoute } from '../../store/actions';
import { DraggableList, CustomGoogleMap } from '../../custom-widgets';
import SearchableSelectAll from '../../custom-widgets/SearchableSelectAll';
import { arrayToMap, getGoogleMapsURL, getDefaultLatitude, getDefaultLongitude, getDefaultZoom, getRole } from '../../../utils';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import { getRoute } from '../../repository';

const disableProcessorChange = (role) => {
    switch (role) {
        case 'admin':
            return false;
        default:
            return true;
    }
};

const formValidWithoutProcessorSelect = (role, region) => {
    if ([Region.PRAIRIE].includes(region) && ['transport'].includes(role)) {
        return true;
    }
    if ([Region.MMPA].includes(region) && ['admin'].includes(role)) {
        return true;
    }
    return false;
};

const formSx = {
    root: {
        flexGrow: 1,
    },
    paper: {
        display: 'flex',
        padding: (theme) => theme.spacing(2),
        textAlign: 'center',
        color: (theme) => theme.palette.text.secondary,
    },
    map: {
        height: '400px',
        justifyContent: 'center',
        margin: '2%',
    },
    list: {
        maxHeight: '280px',
        overflowY: 'scroll',
    },
    submit: {
        display: 'flex',
        justifyContent: 'center',
        gridGap: '30px',
        mt: (theme) => theme.spacing(4),
    },
};

function RouteForm({ producers, processors, haulingCompanies, region, editDetails }) {
    const dispatch = useDispatch();
    const history = useHistory();
    const hasComponents = [Region.UDA].includes(region);
    const has24HourClock = [Region.MMPA].includes(region);
    const hasKosher = [Region.UNC, Region.BONGARDS].includes(region);
    const hasTypeSelector = [Region.PEI, Region.NL, Region.RF].includes(region);
    const hasMultipleHaulers = ![Region.MMPA].includes(region);
    const producerMap = arrayToMap(producers, 'id');
    const processorMap = arrayToMap(processors, 'id');
    const haulingMap = arrayToMap(haulingCompanies, 'id');
    const [name, setName] = useState('');
    const [selectedProducers, setSelectedProducers] = useState([]);
    const [producerIds, setProducerIds] = useState([]);
    const [producerOptions, setProducerOptions] = useState(producers);
    const [processorIds, setProcessorIds] = useState([]);
    const [haulingIds, setHaulingIds] = useState([]);
    const [amount, setAmount] = useState('');
    const [startTime, setStartTime] = useState(moment('09:00', 'HH:mm'));
    const [endTime, setEndTime] = useState(moment('17:00', 'HH:mm'));
    const [dairyType, setDairyType] = useState({ name: 'Milk', value: 'milk' });
    const [category, setCategory] = useState({});
    const [kosher, setKosher] = useState(false);
    const [protein, setProtein] = useState(null);
    const [isValid, setIsValid] = useState(false);
    const [routeNameError, setRouteNameError] = useState();
    const [latitude] = useState(getDefaultLatitude(region));
    const [longitude] = useState(getDefaultLongitude(region));
    const [overrideZoom] = useState(getDefaultZoom(region));
    const [selectedType] = useState('milk');
    const role = getRole();

    useEffect(() => {
        const getCategoryName = (value) => {
            if (value === 'member_producer') return 'Member Producer';
            if (value === 'outside_milk') return 'Outside Milk';
            if (value === 'comingled') return 'Co-mingled';
        };

        if (editDetails) {
            setStartTime(moment(editDetails.start_time, 'HH:mm'));
            setEndTime(moment(editDetails.end_time, 'HH:mm'));
            setAmount(editDetails.amount);
            setDairyType({ name: editDetails.type_of_fluid, value: editDetails.type_of_fluid.toLowerCase() });
            setName(editDetails.name);
            setHaulingIds(editDetails.hauling_companies);
            setProducerIds(editDetails.users.map((id) => producerMap[id]?.id));
            setProcessorIds(editDetails.processors?.map((id) => processorMap[id]?.id).filter((id) => id !== undefined) || []);
            setProtein(editDetails.protein ? editDetails.protein.toString() : '');
            setCategory(editDetails.category && { name: getCategoryName(editDetails.category), value: editDetails.category });
            setKosher(editDetails?.kosher ?? false);
        }
    }, [editDetails]);

    useEffect(() => {
        const validateForm = () => {
            if (routeNameError) return false;
            if (!name.length) return false;
            if (!producerIds.length) return false;
            if (!processorIds.length && !formValidWithoutProcessorSelect(role, region)) return false;
            if (!haulingIds.length) return false;
            if (!amount) return false;
            if (!category?.value) return false;

            return true;
        };

        setIsValid(validateForm());
    }, [routeNameError, name, producerIds, processorIds, haulingIds, amount, category]);

    useEffect(() => {
        const categoryFilters = {
            member_producer: (p) => p.milk_category === 'member_producer',
            outside_milk: (p) => p.milk_category === 'outside_milk',
            comingled: (p) => ['outside_milk', 'member_producer'].includes(p.milk_category),
        };

        const filterByCategory = categoryFilters[category?.value] || (() => false);
        const filteredProducers = producers.filter(filterByCategory);

        setProducerOptions(filteredProducers);
    }, [producers, category]);

    const checkUniqueness = useCallback(
        debounce(async (routeName) => {
            const existingRoutes = await getRoute({ name: routeName, filterDeleted: true });
            if (existingRoutes.length > 0) {
                setRouteNameError('Route name already exists');
                return;
            }
            if (existingRoutes.err) {
                setRouteNameError('Could not validate route name');
                return;
            }
            setRouteNameError();
        }, 750),
        []
    );

    const handleRouteChange = async (event) => {
        const routeName = event.target.value;
        setName(routeName);
        if (routeName) checkUniqueness(routeName);
    };

    const handleCategoryChange = (event, value) => {
        setCategory(value);
    };

    const handleKosherChange = (event) => {
        setKosher(event.target.checked);
    };

    const handleProducersChange = (event, value) => {
        setProducerIds(value.map((v) => v.id));
    };

    const handleProcessorsChange = (event, value) => {
        setProcessorIds(value.map((v) => v.id));
    };

    const handleHaulingChange = (event, value) => {
        if (value.length === 0) {
            setHaulingIds([]);
            return;
        }

        if (hasMultipleHaulers) {
            const mappedHaulingIds = value.map((v) => v.id);
            setHaulingIds(mappedHaulingIds);

            return;
        }

        const haulingId = value[value.length - 1].id;
        setHaulingIds([haulingId]);
    };

    const handleAmountChange = (event) => {
        let value = parseInt(event.target.value);
        if (isNaN(value)) {
            setAmount(0);
        } else {
            value = value >= 0 ? value : 0;
            setAmount(value);
        }
    };

    const handleStartTimeChange = (date) => {
        setStartTime(date);
    };

    const handleEndTimeChange = (date) => {
        setEndTime(date);
    };

    const handleDairyTypeChange = (event, value) => {
        setDairyType(value);
    };

    const handleDraggableListChange = (selected) => {
        setProducerIds(selected.map((v) => v.id));
    };

    const handleProteinChange = (event) => {
        const { value } = event.target;
        setProtein(!value || (!isNaN(value) && !isNaN(parseFloat(value))) ? value : protein);
    };

    const transformProducers = (details) => {
        return details
            .filter((value) => value.type_of_fluid === selectedType)
            .map((value) => ({
                ...value,
                label: value.label,
                latitude: value.latitude,
                longtitude: value.longitude,
                isSelected: producerIds ? producerIds.includes(value.id) : false,
            }));
    };

    const addProducerFromMap = (value) => {
        if (value.isSelected) {
            setSelectedProducers((producerList) => {
                return producerList.filter((producer) => {
                    return producer.id !== value.id;
                });
            });
        } else {
            setSelectedProducers((producerList) => {
                return producerList.concat(value);
            });
        }
    };

    const getModel = () => ({
        name,
        users: producerIds,
        processors: processorIds,
        hauling_companies: haulingIds,
        amount,
        start_time: startTime.format('HH:mm'),
        end_time: endTime.format('HH:mm'),
        type_of_fluid: dairyType.value,
        category: category?.value,
        kosher,
        ...(hasComponents && protein && { protein: parseFloat(protein) }),
    });

    const post = () => {
        const model = getModel();

        dispatch(addRoute(model))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Added Route' }));
                history.replace({ pathname: '/list-schedule-routes' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not add Route' }));
            });
    };

    const patch = () => {
        const { id } = editDetails;
        const model = getModel();

        dispatch(editRoute(model, id))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Edited Route' }));
                history.replace({ pathname: '/list-schedule-routes' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not edit Route' }));
            });
    };

    const cancel = () => {
        history.replace({ pathname: '/list-schedule-routes' });
    };

    return (
        <LocalizationConsumer>
            {(localization) => (
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Box sx={formSx.root}>
                        <Grid container alignItems="center" spacing={3}>
                            <Grid item xs={hasKosher ? 10 : hasComponents ? 6 : 12}>
                                <TextField
                                    required
                                    value={name}
                                    onChange={handleRouteChange}
                                    label="Route Name"
                                    fullWidth
                                    margin="normal"
                                    variant="standard"
                                    error={!!routeNameError}
                                    helperText={routeNameError}
                                    // wrap
                                />
                            </Grid>
                            {hasKosher && (
                                <Grid item xs={2}>
                                    <FormControl>
                                        <FormControlLabel sx={{ mt: (theme) => theme.spacing(4) }} label="Kosher" labelPlacement="start" control={<Checkbox checked={kosher} onChange={handleKosherChange} />} />
                                    </FormControl>
                                </Grid>
                            )}
                            {hasComponents && (
                                <Grid item xs={6}>
                                    <TextField value={protein} onChange={handleProteinChange} label="Expected Protein" variant="standard" fullWidth />
                                </Grid>
                            )}
                            <Grid item xs={6} sm={3}>
                                <TextField required type="number" value={amount} onChange={handleAmountChange} label="Expected Amount" variant="standard" fullWidth inputProps={{ min: 0 }} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TimePicker label="Start Time" value={startTime} fullWidth onChange={handleStartTimeChange} ampm={!has24HourClock} />
                            </Grid>
                            <Grid item xs={6} sm={3}>
                                <TimePicker label="End Time" value={endTime} fullWidth onChange={handleEndTimeChange} ampm={!has24HourClock} />
                            </Grid>
                            {hasTypeSelector && (
                                <Grid item xs={6} sm={3}>
                                    <SearchableSelectAll
                                        label={'Type'}
                                        onChange={handleDairyTypeChange}
                                        value={dairyType}
                                        fullWidth
                                        options={[
                                            { name: 'Milk', value: 'milk' },
                                            { name: 'Cream', value: 'cream' },
                                        ]}
                                    ></SearchableSelectAll>
                                </Grid>
                            )}
                            {!hasTypeSelector && (
                                <Grid item xs={6} sm={3}>
                                    <SearchableSelectAll
                                        required
                                        label={'Category'}
                                        onChange={handleCategoryChange}
                                        value={category}
                                        fullWidth
                                        options={[
                                            { name: 'Member Producer', value: 'member_producer' },
                                            { name: 'Outside Milk', value: 'outside_milk' },
                                            { name: 'Co-mingled', value: 'comingled' },
                                        ]}
                                    ></SearchableSelectAll>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <SearchableSelectAll required value={processorIds.map((id) => processorMap[id])} options={processors} onChange={handleProcessorsChange} label={`${localization.general.processor_plural}`} multiple chipLimit={5} disabled={disableProcessorChange(role)} />
                            </Grid>
                            <Grid item xs={12}>
                                <SearchableSelectAll required value={producerIds.map((id) => producerMap[id])} options={producerOptions} onChange={handleProducersChange} label={'Producers'} multiple chipLimit={5} />
                            </Grid>
                            <Grid item xs={12}>
                                <SearchableSelectAll
                                    required
                                    value={haulingIds.map((id) => haulingMap[id])}
                                    options={haulingCompanies}
                                    onChange={handleHaulingChange}
                                    label={hasMultipleHaulers ? localization.general.hauling_plural : localization.general.hauling_singular}
                                    multiple
                                    chipLimit={5}
                                    disabled={editDetails && haulingCompanies?.length === 1}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6">Producer Order</Typography>
                                <Box sx={formSx.list}>
                                    <DraggableList items={producerIds.map((id) => producerMap[id])} onChange={handleDraggableListChange}></DraggableList>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Box sx={formSx.map} fullWidth>
                                    <CustomGoogleMap
                                        googleMapURL={getGoogleMapsURL()}
                                        loadingElement={<div style={{ height: '100%' }} />}
                                        containerElement={<div style={{ height: '400px' }} />}
                                        mapElement={<div style={{ height: '100%' }} />}
                                        lat={latitude}
                                        lng={longitude}
                                        draggable={false}
                                        overrideZoom={overrideZoom}
                                        producerLocations={transformProducers(producerOptions)}
                                        pinFunction={(value) => addProducerFromMap(value)}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={formSx.submit}>
                            <Button variant="contained" margin="normal" color="secondary" onClick={cancel}>
                                Cancel
                            </Button>
                            <Button disabled={!isValid} variant="contained" color="primary" margin="normal" onClick={editDetails ? patch : post}>
                                Save
                            </Button>
                        </Box>
                    </Box>
                </MuiPickersUtilsProvider>
            )}
        </LocalizationConsumer>
    );
}

export default withRouter(RouteForm);
