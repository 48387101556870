import React from 'react';
import { Polygon, Marker } from '@react-google-maps/api';
import { getShapeCenter } from '../maps/GeofenceManager/GeofenceManager.helpers';
import { selectedShapeOptions, processorShapeOptions, producerShapeOptions, iconUrlMap } from './MapShapes.helpers';

const shapeOptionsMap = {
    producer: producerShapeOptions,
    processor: processorShapeOptions,
};

const MapPolygon = ({ shape }) => {
    const role = shape?.user_id?.role;
    const shapeOptions = shapeOptionsMap[role] || selectedShapeOptions;
    const center = getShapeCenter(shape);

    return (
        <>
            <Polygon
                key={shape._id}
                paths={shape.geometry?.coordinates?.[0]?.map((point) => ({
                    lat: point[1],
                    lng: point[0],
                }))}
                options={{
                    ...shapeOptions,
                }}
                // wrap
            />
            <Marker
                position={center}
                icon={{
                    url: iconUrlMap[role],
                }}
            />
        </>
    );
};

export default MapPolygon;
