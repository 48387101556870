import axios from 'axios';
import { orderBy } from 'lodash';
import { getProcessorsQuery } from './queries';

const getSchedulingProcessors = async () => {
    try {
        const fields = ['id', 'name'];
        const schedulingProcessorsQuery = getProcessorsQuery({ fields, filterDeleted: true });
        const schedulingProcessorsRequest = axios.get(schedulingProcessorsQuery);

        const { data } = await schedulingProcessorsRequest;
        return orderBy(data, ['name'], ['asc']);
    } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getSchedulingProcessors;
