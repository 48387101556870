import { buildExpressQuery } from './queries/builders';
import axios from 'axios';
import { getRouteSessionsQuery } from './queries';

const getRouteSessions = async (filterType) => {
    try {
        const fields = ['id', 'route_id', 'created_by', 'BOL', 'created_at', 'status', 'pooled_load', 'type_of_fluid', 'odometer_start_miles', 'odometer_end_miles', 'total_distance', 'route_number', 'trailer_id', 'manifest_number', 'is_locked', 'hauling_id', 'pre_delivery_report_processor_recipients', 'truck_id'];
        const routeSessionQuery = getRouteSessionsQuery({
            fields,
            filterDeleted: true,
            ...filterType,
            queryBuilder: buildExpressQuery,
        });

        const routeSessionResp = await axios.get(routeSessionQuery);
        return routeSessionResp.data;
    } catch (err) {
        // eslint-disable-next-line
        console.error(err);
        throw new Error('Unable to process request.');
    }
};

export default getRouteSessions;
