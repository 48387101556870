import { useDispatch } from 'react-redux';
import React, { useState, useEffect, useRef } from 'react';
import { MTableBodyRow, MTableCell } from '@material-table/core';
import PureDataTable from '../design/components/DataTable';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import SettingsIcon from '@material-ui/icons/Settings';
import { TableCell, TableRow } from '@material-ui/core';
import { saveCustomTableConfiguration, resetCustomTableConfiguration, setError, showMessage } from 'app/store/actions';
import { getCustomTableConfiguration } from '../repository';
import { CustomTableColumnVisibilityDialog, ConfirmCustomTableResetDialog } from './dialogs';
import MobileDetect from 'mobile-detect';
import { getUser } from '../../utils';

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile();

const isColumnHidden = (column, customTableConfigurationDetails) => {
    if (!customTableConfigurationDetails.columns) {
        return column.hidden;
    }
    const configColumn = customTableConfigurationDetails.columns.find((c) => c.field === column.field);
    if (!configColumn) {
        return column.hidden;
    }
    return configColumn.visible === false;
};

// eslint-disable-next-line
const buildTableComponents = (components, rowExpanded, setRowExpanded, customTableConfigurationDetails) => ({
    ...components,
    Row: (props) => (
        <>
            <MTableBodyRow {...props} />
            {rowExpanded === props.data.index && (
                <TableRow>
                    <TableCell colSpan={props.columns.length + 1}>
                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 10, alignItems: 'center' }}>
                            {props.columns
                                .filter((column) => isColumnHidden(column, customTableConfigurationDetails))
                                .map((column) => (
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 180, minHeight: 72, background: '#F5F5F5', border: '1px solid #BDBDBD', paddingLeft: 15, paddingRight: 15 }}>
                                        <h3 style={{ fontSize: 13 }}>{column.title}</h3>
                                        <div style={{ fontSize: 14, width: '100%', wordWrap: 'break-word' }}>{column.render ? column.render(props.data) : props.data[column.field]}</div>
                                    </div>
                                ))}
                        </div>
                    </TableCell>
                </TableRow>
            )}
        </>
    ),
    Cell: (props) => {
        if (props.columnDef.field === 'expand') {
            const rotateDegrees = rowExpanded === props.rowData.index ? 180 : 0;
            const onExpand = () => setRowExpanded(rowExpanded === props.rowData.index ? null : props.rowData.index);
            return (
                <TableCell align="center" style={{ position: 'sticky', right: 0, borderBottom: '1px solid #e0e0e0', backgroundColor: 'inherit' }}>
                    <ArrowDropDownCircleIcon style={{ cursor: 'pointer', fill: '#00A2F5', transform: `rotate(${rotateDegrees}deg)` }} onClick={onExpand} />
                </TableCell>
            );
        }
        return <MTableCell {...props} />;
    },
});

const CustomizableDataTable = ({ isLoading, name, columns, data, title, components, actions, options, detailPanel, localization, onSelectionChange }) => {
    const dispatch = useDispatch();
    const [rowExpanded, setRowExpanded] = useState();
    const [showColumnVisibilityDialog, setShowColumnVisibilityDialog] = useState(false);
    const [showConfirmResetDialog, setShowConfirmResetDialog] = useState(false);
    const [configuredColumns, setConfiguredColumns] = useState();
    const [tableConfiguration, setTableConfiguration] = useState();
    const [saving, setSaving] = useState(false);
    const [customTableConfigurationDetails, setCustomTableConfigurationDetails] = useState();
    const user = getUser();
    const containerRef = useRef();
    const lastVisibleCount = useRef(); // Used to save count of visible columns for performance reasons

    const configureColumns = (initialColumns, configuration) => {
        const hiddenColumns = initialColumns.filter((column) => isColumnHidden(column, configuration));
        const columnsWithVisibility = initialColumns.map((col) => ({ ...col, hidden: isColumnHidden(col, configuration) }));

        if (configuration.columns) {
            columnsWithVisibility.sort((first, second) => {
                let firstIndex = configuration.columns.findIndex((config) => first.field === config.field);
                let secondIndex = configuration.columns.findIndex((config) => second.field === config.field);
                if (firstIndex === -1) firstIndex = columnsWithVisibility.length;
                if (secondIndex === -1) secondIndex = columnsWithVisibility.length;
                return firstIndex - secondIndex;
            });
        }
        const result = [
            ...columnsWithVisibility,
            {
                title: <SettingsIcon style={{ cursor: 'pointer' }} onClick={() => setShowColumnVisibilityDialog(true)} data-testid={'customizable-table-settings'} />,
                sorting: false,
                field: hiddenColumns.length === 0 ? '' : 'expand',
                width: '0px',
                filtering: false,
                headerStyle: { position: 'sticky', right: 0, backgroundColor: 'white', borderBottom: '1px solid #e0e0e0' },
                customFilterAndSearch: (term, rowData) =>
                    hiddenColumns.some((col) => {
                        if (col.customFilterAndSearch) {
                            return col.customFilterAndSearch(term, rowData);
                        }
                        return `${rowData[col.field]}`.toLowerCase().includes(term.toLowerCase());
                    }),
            },
        ];
        setConfiguredColumns(result);
    };

    const fetchConfiguration = () => {
        getCustomTableConfiguration(name)
            .then((res) => {
                setCustomTableConfigurationDetails(res.data);
                setTableConfiguration(res.data);
                configureColumns(columns, res.data);
            })
            .catch((error) => {
                dispatch(setError(error));
            });
    };

    useEffect(() => {
        fetchConfiguration();
    }, [title]);

    useEffect(() => {
        if (isMobile && rowExpanded !== undefined && rowExpanded !== null) {
            const table = containerRef.current.getElementsByTagName('table');
            if (table && table.length >= 1 && table[0].parentElement && table[0].parentElement.parentElement) {
                table[0].parentElement.parentElement.style.scrollBehavior = 'smooth';
                table[0].parentElement.parentElement.scroll(0, 0);
            }
        }
    }, [rowExpanded]);

    useEffect(() => {
        if (configuredColumns) {
            const onSetupVisibleColumns = () => {
                if (!containerRef.current) {
                    return;
                }
                const table = containerRef.current.getElementsByTagName('table');
                if (!table || table.length === 0) {
                    return;
                }
                const countVisible = configuredColumns.filter((col) => col.field !== 'expand' && col.field && !col.hidden).length;
                if (countVisible <= 1 || isMobile) {
                    return;
                }
                const tableWidth = table[0].offsetWidth;
                const containerWidth = containerRef.current.offsetWidth;
                if (tableWidth > containerWidth) {
                    let lastVisibleIndex = 0;
                    configuredColumns.forEach((col, index) => {
                        if (!col.hidden && col.field !== 'expand' && col.field) {
                            lastVisibleIndex = index;
                        }
                    });
                    setTableConfiguration({
                        ...tableConfiguration,
                        columns: (tableConfiguration.columns || configuredColumns).map((col) => {
                            const isTarget = col.field === configuredColumns[lastVisibleIndex].field;
                            return isTarget ? { ...col, visible: false } : col;
                        }),
                    });
                    const newConfiguredColumns = configuredColumns.map((col, index) => {
                        const wasHiddenBefore = index > lastVisibleCount.current && col.field && col.field !== 'expand';
                        if (index === lastVisibleIndex || wasHiddenBefore) {
                            return { ...col, hidden: true };
                        }
                        if (index === configuredColumns.length - 1) {
                            return { ...col, field: 'expand' };
                        }
                        return col;
                    });
                    lastVisibleCount.current = newConfiguredColumns.filter((col) => !col.hidden).length;
                    setConfiguredColumns(newConfiguredColumns);
                }
            };
            setTimeout(() => onSetupVisibleColumns(), 0);
        }
    }, [configuredColumns]);

    const addIndex = (initialData = []) => {
        return initialData.map((record, index) => ({ ...record, index }));
    };

    const onColumnVisibilitySave = (newColumns, newAdminColumns, meChanged) => {
        setSaving(true);
        const { table_name } = customTableConfigurationDetails;

        const mappedColumns = newColumns.map(({ field, visible, locked }) => ({ field, visible, locked }));
        const newConfiguration = { table_name, columns: mappedColumns, mode: 'me' };
        const requests = [];

        if (user.role === 'admin') {
            if (meChanged) {
                requests.push(dispatch(saveCustomTableConfiguration(newConfiguration)));
            }

            const mappedAdminColumns = newAdminColumns.map(({ field, visible, locked }) => ({ field, visible, locked }));
            const newAdminConfiguration = { table_name, columns: mappedAdminColumns, mode: 'default' };
            const adminRequest = dispatch(saveCustomTableConfiguration(newAdminConfiguration));
            requests.push(adminRequest);
        } else {
            requests.push(dispatch(saveCustomTableConfiguration(newConfiguration)));
        }
        Promise.all(requests)
            .then(() => {
                setShowColumnVisibilityDialog(false);
                dispatch(showMessage({ message: 'Successfully saved configuration.' }));
                setSaving(false);
                fetchConfiguration();
            })
            .catch((err) => {
                // eslint-disable-next-line
                console.error(err);
                dispatch(showMessage({ message: 'Failed saving user configuration' }));
                setSaving(false);
            });
    };

    const onResetColumnsConfiguration = (mode) => {
        setShowColumnVisibilityDialog(false);
        setShowConfirmResetDialog(mode || true);
    };

    const onResetColumnsConfigurationConfirm = (mode) => {
        setSaving(true);
        dispatch(resetCustomTableConfiguration(name, mode))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully reset configuration.' }));
                setShowConfirmResetDialog(false);
                setSaving(false);
                fetchConfiguration();
            })
            .catch((err) => {
                dispatch(showMessage({ message: err.message }));
                setSaving(false);
            });
    };

    if (!configuredColumns) {
        return <div>Loading table...</div>;
    }

    return (
        <div ref={containerRef}>
            <PureDataTable
                isLoading={isLoading}
                columns={configuredColumns}
                data={addIndex(data)}
                title={title}
                components={buildTableComponents(components, rowExpanded, setRowExpanded, tableConfiguration)}
                actions={actions}
                options={{ ...options, draggable: false }}
                detailPanel={detailPanel}
                localization={localization}
                onSelectionChange={onSelectionChange}
            />
            {showColumnVisibilityDialog && <CustomTableColumnVisibilityDialog onClose={() => setShowColumnVisibilityDialog(false)} onSave={onColumnVisibilitySave} saving={saving} onReset={onResetColumnsConfiguration} configuration={customTableConfigurationDetails} columns={columns} isAdmin={user.role === 'admin'} />}
            {showConfirmResetDialog && <ConfirmCustomTableResetDialog mode={showConfirmResetDialog} onClose={() => setShowConfirmResetDialog(false)} onSubmit={onResetColumnsConfigurationConfirm} saving={saving} isAdmin={user.role === 'admin'} />}
        </div>
    );
};

export default CustomizableDataTable;
