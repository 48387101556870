import axios from 'axios';
import { updateEffectiveHaulerRateQuery } from './queries';

const updateEffectiveHaulerRate = (model, date) => {
    const query = updateEffectiveHaulerRateQuery({ model, date });
    return axios
        .put(query.url, { ...query.body })
        .then((response) => {
            // eslint-disable-next-line no-console
            console.log(response.data);
            return { data: response.data };
        })
        .catch((errors) => {
            // eslint-disable-next-line no-console
            console.log(errors);
            throw new Error('Unable to edit hauler rate');
        });
};

export default updateEffectiveHaulerRate;
