import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Formsy from 'formsy-react';
import moment from 'moment-timezone';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from 'app/store/actions';
import Papa from 'papaparse';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import { downloadCSV } from '../../../utils';

function PaperComponent(props) {
    return (
        // <Draggable>
        <Paper {...props} />
        // </Draggable>
    );
}

class ExportLeaseDialog extends React.Component {
    state = {
        open: false,
        selectedMonth: moment().format('MMMM'),
        selectedYear: moment().format('YYYY'),
        selectedType: 'daily',
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    populateYearSelector = (startYear, numYears) => {
        const returnValue = [];

        for (let i = 0; i < numYears; i++) {
            returnValue.push({ value: (startYear + i).toString(), name: (startYear + i).toString() });
        }

        return returnValue;
    };

    setSelectedMonth = (event) => {
        this.setState({ selectedMonth: event.target.value });
    };

    setSelectedYear = (event) => {
        this.setState({ selectedYear: event.target.value });
    };

    setSelectedType = (event) => {
        this.setState({ selectedType: event.target.value });
    };

    handleExport = (type, month, year, { getLeaseData, showMessage }) => {
        getLeaseData(type, moment().month(month).format('M'), year)
            .then((result) => {
                this.downloadCurrentMonth(result.payload.leases);
                this.handleClose();
            })
            .catch((err) => {
                showMessage({ message: 'No data is available for the type, month and year you have selected.' });
            });
    };

    downloadCurrentMonth = (data) => {
        this.convertDataToCSV(this.transformForCsv(data));
    };

    transformForCsv = (details) => {
        if (details) {
            return details.map((value) => ({
                Leaser: value.leased_by,
                Leasee: value.leased_to,
                'Total Litres': value.amount,
                'Start Date': moment(value.start_date.date).format('MMMM D, YYYY'),
                'End Date': moment(value.end_date.date).format('MMMM D, YYYY'),
                Term: value.duration,
                Fees: '',
                'Succession Date': '',
            }));
        }
        return [];
    };

    convertDataToCSV = (data = null, { selectedType, selectedYear, selectedMonth }) => {
        const CSVContent = Papa.unparse(data);
        if (selectedType === 'daily') {
            downloadCSV(CSVContent, `Daily_Fluid_Transfer_Agreement_${`${moment(`${selectedYear.toString()} ${moment().month(selectedMonth).format('M')} 01`, 'YYYY MM DD').format('MMMM')}_${selectedYear}`}.csv`);
        } else {
            downloadCSV(CSVContent, `MSQ_Transfer_Agreement_${`${moment(`${selectedYear.toString()} ${moment().month(selectedMonth).format('M')} 01`, 'YYYY MM DD').format('MMMM')}_${selectedYear}`}.csv`);
        }
    };

    render() {
        const { selectedMonth, selectedYear, selectedType, open } = this.state;
        const { region } = this.props;

        return (
            <div>
                <Button type="submit" variant="contained" color="secondary" className="m-4 ml-4" aria-label="Export Lease Data" onClick={this.handleClickOpen}>
                    Export Lease Data
                </Button>

                <Dialog open={open} onClose={this.handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
                    <DialogTitle id="draggable-dialog-title">Export Lease Data</DialogTitle>
                    <DialogContent>
                        <Formsy
                            /* eslint-disable-next-line no-return-assign */
                            ref={(form) => (this.form = form)}
                            className="flex flex-col justify-center"
                        >
                            <SelectSearchFormsy
                                className="my-16"
                                name="type"
                                label="Type"
                                variant="standard"
                                options={[
                                    { value: 'daily', name: region === 'NL' ? 'Daily Fluid Milk Quota' : 'Quota' },
                                    { value: 'market', name: 'Market Share Quota' },
                                ]}
                                value={selectedType}
                                onChange={this.setSelectedType}
                            />

                            <div className="flex flex-row justify-center">
                                <SelectSearchFormsy
                                    className="my-16"
                                    name="month"
                                    label="Month"
                                    variant="standard"
                                    options={[
                                        { value: 'January', name: 'January' },
                                        { value: 'February', name: 'February' },
                                        { value: 'March', name: 'March' },
                                        { value: 'April', name: 'April' },
                                        { value: 'May', name: 'May' },
                                        { value: 'June', name: 'June' },
                                        { value: 'July', name: 'July' },
                                        { value: 'August', name: 'August' },
                                        { value: 'September', name: 'September' },
                                        { value: 'October', name: 'October' },
                                        { value: 'November', name: 'November' },
                                        { value: 'December', name: 'December' },
                                    ]}
                                    value={selectedMonth}
                                    onChange={this.setSelectedMonth}
                                />

                                <SelectSearchFormsy className="my-16" name="year" label="Year" variant="standard" options={this.populateYearSelector(2018, 5)} value={selectedYear} onChange={this.setSelectedYear} />
                            </div>
                        </Formsy>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={() => this.handleExport(selectedType, selectedMonth, selectedYear, { ...this.props, ...this.state })} color="secondary" variant="contained">
                            Export
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps({ persisted }) {
    return { region: persisted.auth.user.data.region };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getLeaseData: Actions.getLeaseData, showMessage: Actions.showMessage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportLeaseDialog);
