import React, { useState, useEffect } from 'react';
import { Grid, Box, TextField, Typography, Button, Icon } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useForm } from './useForm';
import Formsy from 'formsy-react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage, addMilkPrices, getMilkPrices } from 'app/store/actions';
import { isDemoServer, getUserRegion } from '../../../utils';

const CDIMilkPriceForm = ({ priceData }) => {
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(useSelector((state) => moment(`${state.persisted.dateDetails.selectedDate}`, 'YYYY-MM')));
    const [paymentDateAdvance, setPaymentDateAdvance] = useState(selectedDate.clone().endOf('month'));
    const [paymentDateSettlement, setPaymentDateSettlement] = useState(selectedDate.clone().add(1, 'month').set('date', 19));
    const startDate = useSelector((state) => moment(`${state.persisted.dateDetails.startDate}`, 'YYYY-MM'));
    const changeDisplayForDemo = isDemoServer();
    const region = getUserRegion();
    const formatInitial = (val) => {
        return val === 0 ? 0 : val || '';
    };

    const populateInitialValues = (data) => {
        const vals = {
            /* eslint-disable camelcase */
            class1: formatInitial(data?.report_data?.milk_prices?.class1),
            class2: formatInitial(data?.report_data?.milk_prices?.class2),
            class3: formatInitial(data?.report_data?.milk_prices?.class3),
            class4: formatInitial(data?.report_data?.milk_prices?.class4),

            butterfatPrice: formatInitial(data?.report_data?.milk_prices?.butter_fat_price),
            proteinPrice: formatInitial(data?.report_data?.milk_prices?.protein_price),
            otherSolidsPrice: formatInitial(data?.report_data?.milk_prices?.other_solids_price),
            nonFatPrice: formatInitial(data?.report_data?.milk_prices?.non_fat_price),

            cdiplus: formatInitial(data?.report_data?.milk_prices?.cdi_plus),
            quotaZoneAa: formatInitial(data?.report_data?.milk_prices?.quota_snf_zoneAa),
            quotaZoneB: formatInitial(data?.report_data?.milk_prices?.quota_snf_zoneB),
            quotaZoneCa: formatInitial(data?.report_data?.milk_prices?.quota_snf_zoneCa),
            quotaZoneCb: formatInitial(data?.report_data?.milk_prices?.quota_snf_zoneCb),
            quotaZoneD: formatInitial(data?.report_data?.milk_prices?.quota_snf_zoneD),

            asAnnouncedZone: formatInitial(data?.report_data?.milk_prices?.zone_diff_announced),
            zoneDiff1: formatInitial(data?.report_data?.milk_prices?.zone_diff_zoneA),
            zoneDiff2: formatInitial(data?.report_data?.milk_prices?.zone_diff_zoneB),
            zoneDiff3: formatInitial(data?.report_data?.milk_prices?.zone_diff_zoneD),
            zoneDiff4: formatInitial(data?.report_data?.milk_prices?.zone_diff_zoneC),

            asAnnouncedAdjustment: formatInitial(data?.report_data?.milk_prices?.adjustment_announced),
            Adjustment1: formatInitial(data?.report_data?.milk_prices?.adjustment_zoneA),
            Adjustment2: formatInitial(data?.report_data?.milk_prices?.adjustment_zoneB),
            Adjustment3: formatInitial(data?.report_data?.milk_prices?.adjustment_zoneD),
            Adjustment4: formatInitial(data?.report_data?.milk_prices?.adjustment_zoneC),

            asAnnouncedPPD: formatInitial(data?.report_data?.milk_prices?.ppd_announced),
            PPD1: formatInitial(data?.report_data?.milk_prices?.ppd_zoneA),
            PPD2: formatInitial(data?.report_data?.milk_prices?.ppd_zoneB),
            PPD3: formatInitial(data?.report_data?.milk_prices?.ppd_zoneD),
            PPD4: formatInitial(data?.report_data?.milk_prices?.ppd_zoneC),

            asAnnouncedStatsUni: formatInitial(data?.report_data?.milk_prices?.stats_uni_announced),
            StatsUni1: formatInitial(data?.report_data?.milk_prices?.stats_uni_zoneA),
            StatsUni2: formatInitial(data?.report_data?.milk_prices?.stats_uni_zoneB),
            StatsUni3: formatInitial(data?.report_data?.milk_prices?.stats_uni_zoneD),
            StatsUni4: formatInitial(data?.report_data?.milk_prices?.stats_uni_zoneC),

            proteinPremium: formatInitial(data?.report_data?.milk_prices?.protein_premium),
            quotaRate: formatInitial(data?.report_data?.milk_prices?.quota_rate),
            regionalQuotaAdjustment: formatInitial(data?.report_data?.milk_prices?.regional_quota_adjustment),
            ppdRate: formatInitial(data?.report_data?.milk_prices?.ppd_rate),

            skimMilkQuota: formatInitial(data?.report_data?.advance_milk_prices?.skim_milk_quota),
            skimMilkOverQuota: formatInitial(data?.report_data?.advance_milk_prices?.skim_milk_over_quota),
            butterfatQuota: formatInitial(data?.report_data?.advance_milk_prices?.butterfat_quota),
            butterfatOverQuota: formatInitial(data?.report_data?.advance_milk_prices?.butterfat_over_quota),
        };
        return vals;
    };

    let initialValues = populateInitialValues(priceData);

    const { values, setValues, handleInputChange } = useForm(initialValues);

    const formatSubmit = (val) => {
        return val === '' ? undefined : +val === 0 ? +val : +val || undefined;
    };

    const transformModel = (model) => {
        const data = {
            milk_prices: {
                class1: formatSubmit(model.class1),
                class2: formatSubmit(model.class2),
                class3: formatSubmit(model.class3),
                class4: formatSubmit(model.class4),

                butter_fat_price: formatSubmit(model.butterfatPrice),
                other_solids_price: formatSubmit(model.otherSolidsPrice),
                protein_price: formatSubmit(model.proteinPrice),
                non_fat_price: formatSubmit(model.nonFatPrice),

                cdi_plus: formatSubmit(model.cdiplus),
                quota_snf_zoneAa: formatSubmit(model.quotaZoneAa),
                quota_snf_zoneB: formatSubmit(model.quotaZoneB),
                quota_snf_zoneCa: formatSubmit(model.quotaZoneCa),
                quota_snf_zoneCb: formatSubmit(model.quotaZoneCb),
                quota_snf_zoneD: formatSubmit(model.quotaZoneD),

                zone_diff_announced: formatSubmit(model.asAnnouncedZone),
                zone_diff_zoneA: formatSubmit(model.zoneDiff1),
                zone_diff_zoneB: formatSubmit(model.zoneDiff2),
                zone_diff_zoneD: formatSubmit(model.zoneDiff3),
                zone_diff_zoneC: formatSubmit(model.zoneDiff4),

                adjustment_announced: formatSubmit(model.asAnnouncedAdjustment),
                adjustment_zoneA: formatSubmit(model.Adjustment1),
                adjustment_zoneB: formatSubmit(model.Adjustment2),
                adjustment_zoneD: formatSubmit(model.Adjustment3),
                adjustment_zoneC: formatSubmit(model.Adjustment4),

                ppd_announced: formatSubmit(model.asAnnouncedPPD),
                ppd_zoneA: formatSubmit(model.PPD1),
                ppd_zoneB: formatSubmit(model.PPD2),
                ppd_zoneD: formatSubmit(model.PPD3),
                ppd_zoneC: formatSubmit(model.PPD4),

                stats_uni_announced: formatSubmit(model.asAnnouncedStatsUni),
                stats_uni_zoneA: formatSubmit(model.StatsUni1),
                stats_uni_zoneB: formatSubmit(model.StatsUni2),
                stats_uni_zoneD: formatSubmit(model.StatsUni3),
                stats_uni_zoneC: formatSubmit(model.StatsUni4),

                payment_date_advance: paymentDateAdvance.format(),
                payment_date_settlement: paymentDateSettlement.format(),
            },
            advance_milk_prices: {
                skim_milk_quota: formatSubmit(model.skimMilkQuota),
                skim_milk_over_quota: formatSubmit(model.skimMilkOverQuota),
                butterfat_quota: formatSubmit(model.butterfatQuota),
                butterfat_over_quota: formatSubmit(model.butterfatOverQuota),
            },
        };
        return data;
    };

    useEffect(() => {
        dispatch(getMilkPrices(selectedDate))
            .then((value) => {
                initialValues = populateInitialValues(value.payload.prices);
                setValues(initialValues);
                if (value.payload.prices?.report_data?.milk_prices?.payment_date_advance) setPaymentDateAdvance(moment(value.payload.prices.report_data.milk_prices.payment_date_advance));
                else setPaymentDateAdvance(selectedDate.clone().endOf('month'));
                if (value.payload.prices?.report_data?.milk_prices?.payment_date_settlement) setPaymentDateSettlement(moment(value.payload.prices.report_data.milk_prices.payment_date_settlement));
                else setPaymentDateSettlement(selectedDate.clone().add(1, 'month').set('date', 19));
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                dispatch(showMessage({ message: err.message }));
            });
    }, [selectedDate]);

    const roundNum = (num) => {
        return Number(num.toFixed(5)) === 0 ? '0' : Number(num.toFixed(5));
    };

    const handleAutocalcChange = (event) => {
        const { name, value } = event.target;
        values[name] = value;
        const zoneAdjustments = ['Adjustment1', 'Adjustment2', 'Adjustment3', 'Adjustment4'];
        if (['asAnnouncedPPD', ...zoneAdjustments].includes(name)) {
            values.PPD1 = roundNum(+values.asAnnouncedPPD + +values.Adjustment1) || '';
            values.PPD2 = roundNum(+values.asAnnouncedPPD + +values.Adjustment2) || '';
            values.PPD3 = roundNum(+values.asAnnouncedPPD + +values.Adjustment3) || '';
            values.PPD4 = roundNum(+values.asAnnouncedPPD + +values.Adjustment4) || '';
        }
        if (['asAnnouncedStatsUni', ...zoneAdjustments].includes(name)) {
            values.StatsUni1 = roundNum(+values.asAnnouncedStatsUni + +values.Adjustment1) || '';
            values.StatsUni2 = roundNum(+values.asAnnouncedStatsUni + +values.Adjustment2) || '';
            values.StatsUni3 = roundNum(+values.asAnnouncedStatsUni + +values.Adjustment3) || '';
            values.StatsUni4 = roundNum(+values.asAnnouncedStatsUni + +values.Adjustment4) || '';
        }
        setValues({ ...values });
    };

    const handleSubmit = () => {
        const submitModel = transformModel({ ...values });
        dispatch(addMilkPrices(submitModel, selectedDate))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Updated Milk Prices.' }));
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                dispatch(showMessage({ message: err.message }));
            });
    };

    return (
        <Formsy onValidSubmit={handleSubmit}>
            <Helmet>
                <title>Announced Milk Prices - Milk Moovement</title>
            </Helmet>
            <Box sx={{ mt: 0.5 }}>
                <Typography variant="h6">Announced Milk Price</Typography>
            </Box>
            <Grid container spacing={12} justifyContent="flex-end" alignItems="baseline">
                <Grid item>
                    <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={'Save'}>
                        {'Save'}
                    </Button>
                </Grid>
                <div className="p-12">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Button onClick={() => setSelectedDate(selectedDate.clone().subtract(1, 'month'))} disabled={moment(startDate).format('YYYY MM') === moment(selectedDate).format('YYYY MM')}>
                            <Icon>navigate_before</Icon>
                        </Button>

                        <MuiDatePicker disableFuture variant="inline" openTo="month" label="Select Date" views={['year', 'month']} value={selectedDate} minDate={startDate} onChange={(date) => setSelectedDate(date)} />

                        <Button onClick={() => setSelectedDate(selectedDate.clone().add(1, 'month'))} disabled={moment(selectedDate).format('YYYY MM') === moment().format('YYYY MM')}>
                            <Icon>navigate_next</Icon>
                        </Button>
                    </MuiPickersUtilsProvider>
                </div>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Typography>Class Price</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Class I"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.class1}
                        name="class1"
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Class II"
                        type="number"
                        name="class2"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.class2}
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Class III"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="class3"
                        value={values.class3}
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Class IV"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="class4"
                        value={values.class4}
                        onChange={handleInputChange}
                    ></TextField>
                </Grid>
                <Grid item xs={3}>
                    <Typography>{['CDI'].includes(region) ? 'Monthly Component Prices' : 'Settlement Component Prices'}</Typography>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Butterfat Price (per lb.)"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.butterfatPrice}
                        name="butterfatPrice"
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Protein Price (per lb.)"
                        type="number"
                        name="proteinPrice"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.proteinPrice}
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Other Solids Price (per lb.)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="otherSolidsPrice"
                        value={values.otherSolidsPrice}
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Nonfat Price (per lb.)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.nonFatPrice}
                        name="nonFatPrice"
                        onChange={handleInputChange}
                    ></TextField>

                    {['LEGACY'].includes(region) && (
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="PPD"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.ppdRate}
                            name="ppd_rate"
                            onChange={handleInputChange}
                        ></TextField>
                    )}
                </Grid>

                {['LEGACY'].includes(region) && (
                    <Grid item xs={3}>
                        <Typography>Advance Component Prices</Typography>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            label="Skim Milk Quota"
                            type="number"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.skimMilkQuota}
                            name="skimMilkQuota"
                            onChange={handleInputChange}
                        ></TextField>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            label="Skim Milk Over Quota"
                            type="number"
                            name="skimMilkOverQuota"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.skimMilkOverQuota}
                            onChange={handleInputChange}
                        ></TextField>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Butterfat Quota"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            name="butterfatQuota"
                            value={values.butterfatQuota}
                            onChange={handleInputChange}
                        ></TextField>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Butterfat Over Quota"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.butterfatOverQuota}
                            name="butterfatOverQuota"
                            onChange={handleInputChange}
                        ></TextField>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="PPD"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.ppdRate}
                            name="ppd_rate"
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>
                )}

                <Grid item xs={3}>
                    <Typography>Premium Rates</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label={changeDisplayForDemo ? 'Base Rate' : ['CDI'].includes(region) ? 'CDI Plus (cwt)' : 'Protein Premium'}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={['CDI'].includes(region) ? values.cdiplus : values.proteinPremium}
                        name={['CDI'].includes(region) ? 'cdiplus' : ''}
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label={changeDisplayForDemo ? 'Zone A' : ['CDI'].includes(region) ? 'Zone A SO CAL - SNF Quota RQA -0' : 'Quota Payment'}
                        type="number"
                        name="quotaZoneAa"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={['CDI'].includes(region) ? values.quotaZoneAa : values.quotaRate}
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label={changeDisplayForDemo ? 'Zone C' : ['CDI'].includes(region) ? 'Zone B Kern - SNF Quota RQA 0.20' : 'Regional Quota Adjustment'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="quotaZoneB"
                        value={['CDI'].includes(region) ? values.quotaZoneB : values.regionalQuotaAdjustment}
                        onChange={handleInputChange}
                    ></TextField>
                </Grid>

                {!['LEGACY'].includes(region) && (
                    <>
                        <Grid item xs={3}>
                            <Typography>&nbsp;</Typography>
                            <TextField
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                type="number"
                                label={changeDisplayForDemo ? 'Zone D' : 'Zone C Tulare - SNF Quota RQA -0.2 - Pay Agreement'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={values.quotaZoneCa}
                                name="quotaZoneCa"
                                onChange={handleInputChange}
                            ></TextField>

                            <TextField
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                type="number"
                                label={changeDisplayForDemo ? 'Zone E' : 'Zone C Tulare - SNF Quota RQA -0.11'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={values.quotaZoneCb}
                                name="quotaZoneCb"
                                onChange={handleInputChange}
                            ></TextField>

                            <TextField
                                fullWidth
                                margin="normal"
                                variant="outlined"
                                type="number"
                                label={changeDisplayForDemo ? 'Zone F' : 'Zone D NO CAL - SNF Quota RQA -0.11'}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={values.quotaZoneD}
                                name="quotaZoneD"
                                onChange={handleInputChange}
                            ></TextField>
                        </Grid>
                    </>
                )}
            </Grid>
            <Typography>Payment Dates</Typography>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <MuiDatePicker label="Advance" value={paymentDateAdvance} inputVariant="outlined" margin="normal" fullWidth onChange={(date) => setPaymentDateAdvance(date)} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <MuiDatePicker label="Settlement" value={paymentDateSettlement} inputVariant="outlined" margin="normal" fullWidth onChange={(date) => setPaymentDateSettlement(date)} />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>

            {!['LEGACY'].includes(region) && (
                <Grid container spacing={3}>
                    <Grid item xs={3}>
                        <Typography>Zone Differential</Typography>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="As Announced"
                            name="asAnnouncedZone"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.asAnnouncedZone}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone A"
                            name="zoneDiff1"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.zoneDiff1}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone B"
                            name="zoneDiff2"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.zoneDiff2}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone D"
                            name="zoneDiff3"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.zoneDiff3}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone C"
                            name="zoneDiff4"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.zoneDiff4}
                            onChange={handleInputChange}
                        ></TextField>
                    </Grid>

                    <Grid item xs={3}>
                        <Typography>Adjustments</Typography>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="As Announced"
                            name="asAnnouncedAdjustment"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.asAnnouncedAdjustment}
                            onChange={handleInputChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone A"
                            name="Adjustment1"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.Adjustment1}
                            onChange={handleAutocalcChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone B"
                            name="Adjustment2"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.Adjustment2}
                            onChange={handleAutocalcChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone D"
                            name="Adjustment3"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.Adjustment3}
                            onChange={handleAutocalcChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone C"
                            name="Adjustment4"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.Adjustment4}
                            onChange={handleAutocalcChange}
                        ></TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>PPD</Typography>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="As Announced"
                            name="asAnnouncedPPD"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.asAnnouncedPPD}
                            onChange={handleAutocalcChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone A"
                            name="PPD1"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.PPD1}
                            disabled
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone B"
                            name="PPD2"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.PPD2}
                            disabled
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone D"
                            name="PPD3"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.PPD3}
                            disabled
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone C"
                            name="PPD4"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.PPD4}
                            disabled
                        ></TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>Statistical Uniform Price</Typography>

                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="As Announced"
                            name="asAnnouncedStatsUni"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.asAnnouncedStatsUni}
                            onChange={handleAutocalcChange}
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone A"
                            name="StatsUni1"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.StatsUni1}
                            disabled
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone B"
                            name="StatsUni2"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.StatsUni2}
                            disabled
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone D"
                            name="StatsUni3"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.StatsUni3}
                            disabled
                        ></TextField>
                        <TextField
                            fullWidth
                            margin="normal"
                            variant="outlined"
                            type="number"
                            label="Zone C"
                            name="StatsUni4"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={values.StatsUni4}
                            disabled
                        ></TextField>
                    </Grid>
                </Grid>
            )}
        </Formsy>
    );
};

export default withRouter(CDIMilkPriceForm);
