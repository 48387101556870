import React, { Component } from 'react';
import Formsy from 'formsy-react';
import { Button, InputAdornment } from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import connect from 'react-redux/es/connect/connect';
import * as authActions from 'app/auth/store/actions';
import { TextFieldFormsy } from '@fuse';

class JWTRegisterTab extends Component {
    state = { canSubmit: false };

    form = React.createRef();

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { register } = this.props;

        if (register.error && (register.error.username || register.error.password || register.error.email)) {
            this.form.updateInputsWithError({ ...register.error });

            register.error = null;
            this.disableButton();
        }

        return null;
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    onSubmit = (model) => {
        // console.warn(model);
        // this.props.submitRegister(model);
    };

    render() {
        const { canSubmit } = this.state;

        return (
            <div className="w-full">
                <Formsy
                    onValidSubmit={this.onSubmit}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    /* eslint-disable-next-line no-return-assign */
                    ref={(form) => (this.form = form)}
                    className="flex flex-col justify-center w-full"
                >
                    <TextFieldFormsy
                        className="mb-16"
                        type="text"
                        name="name"
                        label="Display name"
                        validations={{ minLength: 4 }}
                        validationErrors={{ minLength: 'Min character length is 4' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        person
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        required
                    />

                    <TextFieldFormsy
                        className="mb-16"
                        type="text"
                        name="email"
                        label="Email"
                        validations="isEmail"
                        validationErrors={{ isEmail: 'Please enter a valid email' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        email
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        required
                    />

                    <TextFieldFormsy
                        className="mb-16"
                        type="password"
                        name="password"
                        label="Password"
                        validations="equalsField:password-confirm"
                        validationErrors={{ equalsField: 'Passwords do not match' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        vpn_key
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        required
                    />

                    <TextFieldFormsy
                        className="mb-16"
                        type="password"
                        name="password-confirm"
                        label="Confirm Password"
                        validations="equalsField:password"
                        validationErrors={{ equalsField: 'Passwords do not match' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        vpn_key
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                        required
                    />

                    <Button type="submit" variant="contained" color="primary" className="w-full mx-auto mt-16 normal-case" aria-label="REGISTER" disabled={!canSubmit} value="legacy">
                        Register
                    </Button>
                </Formsy>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ submitRegister: authActions.submitRegister }, dispatch);
}

function mapStateToProps({ persisted }) {
    return { register: persisted.auth.register, user: persisted.auth.user };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(JWTRegisterTab));
