import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MaterialTable from '@material-table/core';
import RandomMoorice from '../../custom-components/RandomMoorice';
import { Checkbox } from '@material-ui/core';
import { ContactsOutlined, FilterList } from '@material-ui/icons';

const DataTable = ({ title, columns, data, actions, editable, components, options, detailPanel, onRowClick, localization, isLoading, onSelectionChange, isInlineFilters }) => {
    const [columnsData, setColumnsData] = useState(columns);

    const defaultOptions = {
        rowStyle: (_, index) => {
            return { backgroundColor: !(index % 2) ? '#f2f2f2' : 'white' };
        },
        pageSizeOptions: [5, 10, 25, 50, 100, 250],
        emptyRowsWhenPaging: false,
        exportAllData: true,
        ...(isInlineFilters && { filtering: true }),
    };
    const defaultLocalization = {
        header: { actions: '' },
        body: {
            emptyDataSourceMessage: (
                <div>
                    <div className="mt-44 h1 text-bold text-blue">{isLoading ? 'Loading...' : 'No data awheylable'}</div>
                    <div className="m-auto">{isLoading ? <RandomMoorice /> : <img height="300" width="250" src="assets/New Logo/moorice_sad.png" alt="logo" />}</div>
                </div>
            ),
        },
    };

    return (
        <MaterialTable title={title} columns={columnsData} data={data} actions={actions} editable={editable} components={components} options={{ ...defaultOptions, ...options }} detailPanel={detailPanel} onRowClick={onRowClick} localization={{ ...defaultLocalization, ...localization }} isLoading={isLoading} onSelectionChange={onSelectionChange} />
    );
};

DataTable.propTypes = {
    /**
     * actions that appear in leftmost column
     */
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            disabled: PropTypes.boolean,
            hidden: PropTypes.boolean,
            icon: PropTypes.string.isRequired,
            onClick: PropTypes.func.isRequired,
            tooltip: PropTypes.string.isRequired,
        })
    ),
    /**
     * header columns
     */
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            cellStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
            currencySetting: PropTypes.object,
            customFilterAndSearch: PropTypes.func,
            customSort: PropTypes.func,
            defaultFilter: PropTypes.any,
            defaultGroupOrder: PropTypes.number,
            defaultGroupSort: PropTypes.oneOf(['asc', 'desc']),
            defaultSort: PropTypes.oneOf(['asc', 'desc']),
            disableClick: PropTypes.bool,
            editable: PropTypes.oneOf(['always', 'never', 'onUpdate', 'onAdd']),
            editComponent: PropTypes.node,
            emptyValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
            export: PropTypes.bool,
            field: PropTypes.string,
            filtering: PropTypes.bool,
            filterCellStyle: PropTypes.object,
            filterPlaceholder: PropTypes.string,
            grouping: PropTypes.bool,
            headerStyle: PropTypes.object,
            hidden: PropTypes.bool,
            initialEditValue: PropTypes.any,
            lookup: PropTypes.object,
            readonly: PropTypes.bool,
            removable: PropTypes.bool,
            render: PropTypes.func,
            searchable: PropTypes.bool,
            sorting: PropTypes.bool,
            title: PropTypes.string,
            type: PropTypes.string,
        })
    ).isRequired,
    /**
     * component that can be overridden
     */
    components: PropTypes.shape({
        Action: PropTypes.node,
        Actions: PropTypes.node,
        Body: PropTypes.node,
        Cell: PropTypes.node,
        Container: PropTypes.node,
        EditField: PropTypes.node,
        EditRow: PropTypes.node,
        FilterRow: PropTypes.node,
        Groupbar: PropTypes.node,
        Header: PropTypes.node,
        OverlayLoading: PropTypes.node,
        Pagination: PropTypes.node,
        Row: PropTypes.node,
        Toolbar: PropTypes.node,
    }),
    /**
     * array of objects which have fields defined in columns
     */
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    /**
     * detail panel
     */
    detailPanel: PropTypes.func,
    /**
     * editable config
     */
    editable: PropTypes.shape({
        onRowAdd: PropTypes.func,
        onRowUpdate: PropTypes.func,
        onRowDelete: PropTypes.func,
    }),
    /**
     * isLoading
     */
    isLoading: PropTypes.bool,
    /**
     * localization
     */
    localization: PropTypes.shape({
        body: PropTypes.shape({
            emptyDataSourceMessage: PropTypes.string,
            addTooltip: PropTypes.string,
            deleteTooltip: PropTypes.string,
            editTooltip: PropTypes.string,
            filterRow: PropTypes.shape({ filterTooltip: PropTypes.string }),
            editRow: PropTypes.shape({
                deleteText: PropTypes.string,
                cancelTooltip: PropTypes.string,
                saveTooltip: PropTypes.string,
            }),
        }),
        grouping: PropTypes.shape({ placeholder: PropTypes.string }),
        header: PropTypes.shape({ actions: PropTypes.string }),
        pagination: PropTypes.shape({
            labelDisplayedRows: PropTypes.string,
            labelRowsSelect: PropTypes.string,
            labelRowsPerPage: PropTypes.string,
            firstAriaLabel: PropTypes.string,
            firstTooltip: PropTypes.string,
            previousAriaLabel: PropTypes.string,
            previousTooltip: PropTypes.string,
            nextAriaLabel: PropTypes.string,
            nextTooltip: PropTypes.string,
            lastAriaLabel: PropTypes.string,
            lastTooltip: PropTypes.string,
        }),
        toolbar: PropTypes.shape({
            addRemoveColumns: PropTypes.string,
            nRowsSelected: PropTypes.string,
            showColumnsTitle: PropTypes.string,
            showColumnsAriaLabel: PropTypes.string,
            exportTitle: PropTypes.string,
            exportAriaLabel: PropTypes.string,
            exportName: PropTypes.string,
            searchTooltip: PropTypes.string,
            searchPlaceholder: PropTypes.string,
        }),
    }),
    /**
     * handles a row click
     */
    onRowClick: PropTypes.func,
    /**
     * options
     */
    options: PropTypes.shape({
        actionsCellStyle: PropTypes.object,
        actionsColumnIndex: PropTypes.number,
        addRowPosition: PropTypes.oneOf(['first', 'last']),
        columnsButton: PropTypes.bool,
        debounceInterval: PropTypes.number,
        detailPanelColumnAlignment: PropTypes.oneOf(['left', 'right']),
        detailPanelType: PropTypes.oneOf(['single', 'multiple']),
        doubleHorizontalScroll: PropTypes.bool,
        emptyRowsWhenPaging: PropTypes.bool,
        exportAllData: PropTypes.bool,
        exportButton: PropTypes.bool,
        exportDelimiter: PropTypes.string,
        exportFileName: PropTypes.string,
        exportCsv: PropTypes.func,
        filtering: PropTypes.bool,
        filterCellStyle: PropTypes.object,
        fixedColumns: PropTypes.object,
        grouping: PropTypes.bool,
        header: PropTypes.bool,
        headerStyle: PropTypes.object,
        loadingType: PropTypes.oneOf(['overlay', 'linear']),
        maxBodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        minBodyHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        initialPage: PropTypes.number,
        padding: PropTypes.oneOf(['default', 'dense']),
        paging: PropTypes.bool,
        pageSize: PropTypes.number,
        pageSizeOptions: PropTypes.array,
        paginationType: PropTypes.oneOf(['normal', 'stepped']),
        rowStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        showEmptyDataSourceMessage: PropTypes.bool,
        showFirstLastPageButtons: PropTypes.bool,
        showSelectAllCheckbox: PropTypes.bool,
        showTextRowsSelected: PropTypes.bool,
        search: PropTypes.bool,
        searchText: PropTypes.string,
        searchFieldAlignment: PropTypes.string,
        searchFieldStyle: PropTypes.object,
        selection: PropTypes.bool,
        selectionProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
        sorting: PropTypes.bool,
        tableLayout: PropTypes.oneOf(['auto', 'fixed']),
        toolbar: PropTypes.bool,
        showTitle: PropTypes.bool,
        toolbarButtonAlignment: PropTypes.oneOf(['left', 'right']),
        draggable: PropTypes.bool,
    }),
    /**
     * title of table
     */
    title: PropTypes.string.isRequired,
};

DataTable.defaultProps = {
    editable: null,
    actions: null,
    components: null,
    detailPanel: null,
    isLoading: false,
    localization: {},
    onRowClick: null,
    options: {},
};

export default DataTable;
