import React from 'react';
import { Grid } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';

const NebraskaSettlementMilkPrices = () => (
    <>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Butterfat ($/lb)" name={'milk_prices.butter_fat_price'} type="number" />
        </Grid>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Protein ($/lb)" name={'milk_prices.protein_price'} type="number" />
        </Grid>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Protein Caseinate ($/lb)" name={'milk_prices.protein_caseinate_price'} type="number" />
        </Grid>
    </>
);

export default NebraskaSettlementMilkPrices;
