import axios from 'axios';
import _ from 'lodash';
import { getLabReportsQuery } from './queries';

const getThirtyDayAverages = async (producerId, tank, selectedDate) => {
    try {
        const labReportResponse = await axios.get(
            getLabReportsQuery({
                limit: 30,
                tank,
                start: selectedDate,
                producerIds: [producerId],
                fields: ['fat', 'lactose', 'somatic_cell_count', 'protein', 'solids_not_fat', 'producer_id', 'date', 'tank'],
            })
        );

        const labData = labReportResponse.data;
        const averageFat = _.meanBy(labData, 'fat');
        const averageLactose = _.meanBy(labData, 'lactose');
        const averageProtein = _.meanBy(labData, 'protein');
        const averageScc = _.meanBy(labData, 'somatic_cell_count');
        const averageSNF = _.meanBy(labData, 'solids_not_fat');

        return { averageFat, averageLactose, averageProtein, averageScc, averageSNF };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getThirtyDayAverages;
