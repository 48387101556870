import axios from 'axios';
import { buildMembershipQuery } from './queries/builders';
import { getUser } from '../../utils';

const transferEquity = async ({ equityType, certificateIds, dateOfTransfer, fromMemberId, fromSubuserId, toMemberId, toSubuserId, value, dividendReceived, sharePrice, remainingCertificateId, transferredCertificateId }) => {
    try {
        const user = getUser();
        const signature = user.name;

        const membershipQuery = `/v1/equityMembers/${fromMemberId}/transferEquity`;
        const payload = {
            type: equityType,
            certificate_ids: certificateIds,
            date_of_transfer: dateOfTransfer,
            from_subuser_id: fromSubuserId ?? fromMemberId,
            to_member_id: toMemberId,
            to_subuser_id: toSubuserId ?? toMemberId,
            value,
            dividend_received: dividendReceived,
            price: sharePrice,
            certificate_id_from: remainingCertificateId,
            certificate_id_to: transferredCertificateId,
            signature,
        };

        const equityMembershipQuery = buildMembershipQuery(membershipQuery, {
            headers: {},
            data: payload,
        });

        const { data } = await axios({ ...equityMembershipQuery, method: 'post' });

        return data;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw new Error(err instanceof Error ? err.message : 'Unable to process request.');
    }
};

export default transferEquity;
