import React from 'react';
import { Box, Card, Grid, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const BoxSx = { margin: (theme) => theme.spacing(2) };
const CardSx = { maxWidth: '250px' };
const CloseIconSx = {
    position: 'absolute',
    top: 8,
    right: 8,
    height: '8px',
    width: '8px',
};

const BusinessDetails = ({ shape, deselect }) => (
    <Card sx={CardSx}>
        <IconButton sx={CloseIconSx} onClick={deselect}>
            <CloseIcon />
        </IconButton>
        <Box sx={BoxSx}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6">{shape.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="caption">License Number</Typography>
                    <Typography variant="body1">{shape.license_number}</Typography>
                </Grid>
                {shape.address1 && (
                    <Grid item xs={12}>
                        <Typography variant="caption">Address</Typography>
                        <Typography variant="body1">{shape.address1}</Typography>
                        <Typography variant="body1">
                            {shape.town}, {shape.postal_code}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Box>
    </Card>
);

export default BusinessDetails;
