import axios from 'axios';
import { buildMembershipQuery } from './queries/builders';

const sellEquity = async ({ equityType, certificateIds, dateOfSale, memberId, subuserId, value, dividendReceived, sharePrice, remainingCertificateId }) => {
    try {
        const membershipQuery = `/v1/equityMembers/${memberId}/sellEquity`;
        const payload = {
            type: equityType,
            certificate_ids: certificateIds,
            from_subuser_id: subuserId ?? memberId,
            date_of_sale: dateOfSale,
            value,
            dividend_received: dividendReceived,
            price: sharePrice,
            certificate_id_from: remainingCertificateId,
        };

        const equityMembershipQuery = buildMembershipQuery(membershipQuery, {
            headers: {},
            data: payload,
        });

        const { data } = await axios({ ...equityMembershipQuery, method: 'post' });

        return data;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw new Error(err instanceof Error ? err.message : 'Unable to process request.');
    }
};

export default sellEquity;
