import axios from 'axios';
import _ from 'lodash';
import { getAssignmentConfigurationQuery, getUsersQuery, batchQuery } from './queries';
import moment from 'moment-timezone';

const getUsersForAssignments = async (assignments) => {
    const payeeIds = assignments.reduce((userIds, assignment) => {
        if (assignment.filter?.payee != null) {
            userIds.push(...assignment.filter.payee);
        }
        return userIds;
    }, []);
    const userQuery = batchQuery(getUsersQuery, { fields: ['id', 'name', 'full_name', 'license_number', 'hauler_number'], ids: payeeIds }, 'ids');
    const userRequest = _.map(userQuery, (q) => axios.get(q));

    return axios.all(userRequest).then(
        axios.spread((...responses) => {
            return _.reduce(responses, (acc, val) => [...acc, ...val.data], []);
        })
    );
};

const populateUserDetailsOnAssignmentFilter = (filter, users) => {
    if (!_.isEmpty(filter?.payee) && !_.isEmpty(users)) {
        // eslint-disable-next-line no-param-reassign
        filter.payee = filter.payee.map((payeeId) => {
            return users.find(({ id }) => id.toString() === payeeId.toString());
        });
    }
    return filter;
};

const getAssignmentsInDateRangeForUserRole = (assignments, startDate, endDate, role) => {
    return assignments.filter(({ start_date, end_date, filter }) => {
        return filter.role === role && moment(start_date).isSameOrBefore(moment(endDate), 'day') && (end_date == null || moment(end_date).isSameOrAfter(moment(startDate), 'day'));
    });
};

const getAssignmentConfigurationListDetails = async (startDate, endDate, role) => {
    try {
        const assignmentResponse = await axios.get(getAssignmentConfigurationQuery({}));
        const assignments = getAssignmentsInDateRangeForUserRole(assignmentResponse.data, startDate, endDate, role);
        const users = await getUsersForAssignments(assignments);
        assignments.forEach(({ filter }) => {
            // eslint-disable-next-line no-param-reassign
            filter = populateUserDetailsOnAssignmentFilter(filter, users);
        });
        return { assignments };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getAssignmentConfigurationListDetails;
