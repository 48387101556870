import axios from 'axios';
import { getPaymentStatusQuery } from './queries';

const getPaymentStatusDetails = async (start, end, filters) => {
    try {
        const paymentStatusQuery = getPaymentStatusQuery({
            start,
            end,
            filters,
            filterDeleted: true,
        });
        const paymentStatusResponse = await axios.get(paymentStatusQuery);
        const paymentStatus = paymentStatusResponse.data;
        return { paymentStatus };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getPaymentStatusDetails;
