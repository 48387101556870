import axios from 'axios';
import { getExpressAPIHost } from '../../utils';

const getProducerPickupsLabsDetails = async (producerId, startDate, endDate, selectedTank) => {
    try {
        const params = {
            producerId,
            startDate,
            endDate,
            selectedTank,
        };

        const response = await axios.get(`${getExpressAPIHost()}/pickups/producer-production`, { params });
        return response.data;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Error fetching producer pickup and lab data.');
    }
};

export default getProducerPickupsLabsDetails;
