import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { batchQuery, getDropoffsQuery, getDriversQuery, getProcessorsQuery, getRouteSessionsQuery, getRoutesQuery, getTrailersQuery, getPickupsQuery, getLabReportsQuery, getHaulingCompaniesQuery } from './queries';
import getPickupsByDropoffId from './utils/getPickupsByDropoffId';
import calculateComponents from './utils/calculateComponents';
import { formatLabsForComponentCalculation } from '../../utils';
import { format } from 'date-fns';

const getRouteSessionDropoffs = (key, region) =>
    new Promise((resolve, reject) => {
        const dropoffsQuery = getDropoffsQuery({ routeSessionIds: [key], filterDeleted: true });
        const dropoffsRequest = axios.get(dropoffsQuery);

        const routeSessionQuery = getRouteSessionsQuery({
            fields: ['id', 'BOL', 'status', 'route_id', 'trailer_id', 'hauling_id'],
            ids: [key],
            filterDeleted: true,
        });
        const routeSessionRequest = axios.get(routeSessionQuery);

        const requests1 = [dropoffsRequest, routeSessionRequest];

        axios
            .all(requests1)
            .then(
                axios.spread((...responses1) => {
                    const [dropoffsResponse, routeSessionResponse] = responses1;

                    const routeSession = _.find(routeSessionResponse.data);

                    const dropoffs = dropoffsResponse.data;

                    const driverIds = _.uniq(_.map(dropoffs, 'driver_id'));
                    const processorIds = _.uniq(_.map(dropoffs, 'processor_id'));
                    const routeId = routeSession.route_id;
                    const trailerId = routeSession.trailer_id;
                    const haulerId = routeSession.hauling_id;

                    const driverQuery = getDriversQuery({ fields: ['id', 'name'], ids: driverIds });
                    const processorQuery = getProcessorsQuery({ fields: ['id', 'name', 'latitude', 'longitude', 'geofence'], ids: processorIds });
                    const routeQuery = getRoutesQuery({ fields: ['id', 'name'], ids: [routeId] });
                    const trailerQuery = getTrailersQuery({ fields: ['trailer_number'], ids: [trailerId] });
                    const pickupQuery = getPickupsQuery({ routeSessionIds: [routeSession.id], filterDeleted: true });
                    const haulerQuery = getHaulingCompaniesQuery({ fields: ['id', 'name'], ids: [haulerId] });

                    const driverRequest = axios.get(driverQuery);
                    const processorRequest = axios.get(processorQuery);
                    const routeRequest = axios.get(routeQuery);
                    const trailerRequest = axios.get(trailerQuery);
                    const pickupRequest = axios.get(pickupQuery);
                    const haulerRequest = axios.get(haulerQuery);

                    const requests = [driverRequest, processorRequest, routeRequest, trailerRequest, pickupRequest, haulerRequest];
                    return axios.all(requests).then(
                        axios.spread((...responses2) => {
                            const [driverResponse, processorResponse, routeResponse, trailerResponse, pickupResponse, haulerResponse] = responses2;

                            const drivers = driverResponse.data;
                            const processors = processorResponse.data;
                            const routes = routeResponse.data;
                            const trailers = trailerResponse.data;
                            const pickups = pickupResponse.data;

                            const firstPickup = _.minBy(pickups, 'created_at');
                            const lastPickup = _.maxBy(pickups, 'created_at');

                            const labReportStart = firstPickup ? moment(firstPickup.created_at).startOf('day').toString() : moment().toString();
                            const labReportEnd = lastPickup ? moment(lastPickup.created_at).endOf('day').toString() : moment().toString();

                            const producerIds = _.uniq(_.map(pickups, 'producer_id'));

                            const labReportQuery =
                                region === 'BORDEN'
                                    ? getLabReportsQuery({
                                          start: labReportStart,
                                          end: labReportEnd,
                                          filterDeleted: true,
                                      })
                                    : getLabReportsQuery({
                                          start: labReportStart,
                                          end: labReportEnd,
                                          producerIds,
                                          filterDeleted: true,
                                      });

                            const labReportRequest = axios.get(labReportQuery);
                            labReportRequest.then((response) => {
                                const labReports = response.data;
                                const [groupedLabReports, labsByPickupIdMap] = formatLabsForComponentCalculation(labReports, region);

                                const pickupsByDropoffId = getPickupsByDropoffId(dropoffs, pickups);

                                const data = _.map(dropoffs, (dropoff) => {
                                    const driver = _.find(drivers, { id: dropoff.driver_id });
                                    const processor = _.find(processors, { id: dropoff.processor_id });
                                    const route = _.find(routes);
                                    const trailer = _.find(trailers);
                                    const hauler = _.find(haulerResponse.data);

                                    const pickupsForDropoff = pickupsByDropoffId[dropoff.id];

                                    const components = calculateComponents(pickupsForDropoff, groupedLabReports, region, labsByPickupIdMap);

                                    return {
                                        ...dropoff,
                                        comment: dropoff.comment,
                                        compartment: dropoff.compartment,
                                        components,
                                        created_at: dropoff.created_at,
                                        deleted_at: dropoff.deleted_at,
                                        driver: [_.pick(driver, ['id', 'name'])],
                                        id: dropoff.id,
                                        is_rejected: dropoff.is_rejected,
                                        hauling_company: hauler?.name || '',
                                        latitude: dropoff.latitude,
                                        longitude: dropoff.longitude,
                                        metered: dropoff.metered,
                                        scale_weight: dropoff?.scale_weight || null,
                                        tare_weight: dropoff?.tare_weight || null,
                                        metered_slip: dropoff.metered_slip,
                                        outside: dropoff.outside,
                                        processor: [_.pick(processor, ['geofence', 'id', 'latitude', 'longitude', 'name'])],
                                        rejection_reason: dropoff.rejection_reason,
                                        route: [_.pick(route, ['id'])],
                                        routeSession: route && routeSession ? [_.assign(_.pick(routeSession, ['BOL', 'id']), { route: route.name }, { route_session_status: _.capitalize(routeSession.status) })] : '',
                                        run_number: dropoff.run_number,
                                        shrink_amount: dropoff.volume > 0 && dropoff.metered > 0 ? dropoff.volume - dropoff.metered : undefined, // TODO correct?
                                        shrink_percent: dropoff.volume > 0 && dropoff.metered > 0 ? ((dropoff.volume - dropoff.metered) / dropoff.volume) * 100 : undefined, // TODO correct?
                                        slip: dropoff.slip,
                                        temperature: dropoff.temperature,
                                        trailer: trailer ? parseInt(trailer.trailer_number) : '', // TODO number or string?
                                        updated_at: dropoff.updated_at,
                                        volume: dropoff.volume ? dropoff.volume.toString() : '0',
                                        volume_a: dropoff.volume_a,
                                        volume_b: dropoff.volume_b,
                                        volume_c: dropoff.volume_c,
                                        type_of_fluid: dropoff.type_of_fluid,
                                        fat_percentage: dropoff.fat_percentage,
                                        protein_percentage: dropoff.protein_percentage,
                                        solids_not_fat_percentage: dropoff.solids_not_fat_percentage,
                                    };
                                });

                                resolve({ dropoffs: data });
                            });
                        })
                    );
                })
            )
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getRouteSessionDropoffs;
