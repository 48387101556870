import axios from 'axios';
import { deleteGeofenceQuery } from './queries';

const deleteGeofence = async (id) => {
    try {
        const query = deleteGeofenceQuery(id);
        const { data } = await axios.delete(query);

        return data;
    } catch (error) {
        return { error };
    }
};

export default deleteGeofence;
