import React from 'react';
import DataTable from './DataTable';
import moment from 'moment-timezone';
import { numberFormat, secondsToTimeConverter } from '../../utils';

const OdometerTable = (sessionDetails) => {
    const columns = [
        {
            title: 'Total Distance (mi)',
            field: 'totalDistance',
            type: 'number',
        },
        {
            title: 'Odometer Start (mi)',
            field: 'odometerStart',
            type: 'number',
        },
        {
            title: 'Odometer End (mi)',
            field: 'odometerEnd',
            type: 'number',
        },
        {
            title: 'Start Time',
            field: 'startTime',
            type: 'number',
        },
        {
            title: 'End Time',
            field: 'endTime',
            type: 'number',
        },
        {
            title: 'Total Time',
            field: 'totalTime',
            type: 'number',
        },
    ];

    const rows = [
        {
            totalDistance: sessionDetails.sessionDetails.odometer_end && sessionDetails.sessionDetails.odometer_start ? sessionDetails.sessionDetails.odometer_end - sessionDetails.sessionDetails.odometer_start : '-',
            odometerStart: sessionDetails.sessionDetails.odometer_start ? numberFormat(sessionDetails.sessionDetails.odometer_start) : '-',
            odometerEnd: sessionDetails.sessionDetails.odometer_end ? numberFormat(sessionDetails.sessionDetails.odometer_end) : '-',
            startTime: sessionDetails.sessionDetails.start_time ? moment(sessionDetails.sessionDetails.start_time).format('HH:mm:ss') : '-',
            endTime: sessionDetails.sessionDetails.end_time ? moment(sessionDetails.sessionDetails.end_time).format('HH:mm:ss') : '-',
            totalTime: sessionDetails.sessionDetails.total_time ? secondsToTimeConverter(sessionDetails.sessionDetails.total_time) : '-',
        },
    ];

    return (
        <div>
            <DataTable columns={columns} data={rows} title="Route Info" />
        </div>
    );
};

export default OdometerTable;
