import { getLabsAPIHost, getUserRegion } from '../../../../utils';

const buildServerlessLabQuery = (endpoint, { body }) => {
    const clientName = getUserRegion().toLowerCase();

    return {
        url: `${getLabsAPIHost()}${endpoint}?customer=${clientName}`,
        body,
    };
};

export default buildServerlessLabQuery;
