import axios from 'axios';
import { editWashFacilityQuery } from './queries';

const editWashFacility = (model, key) =>
    new Promise((resolve, reject) => {
        const query = editWashFacilityQuery({ key, model });
        const request = axios.put(query.url, { ...query.body });
        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to edit wash facility'));
            });
    });

export default editWashFacility;
