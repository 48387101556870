import axios from 'axios';
import { getDeferralsQuery } from './queries';

const getDeferralListDetails = async (startDate, endDate) => {
    try {
        const deferralResponse = await axios.get(getDeferralsQuery(startDate, endDate));
        const deferrals = deferralResponse.data;
        return { deferrals };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getDeferralListDetails;
