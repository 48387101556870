import React, { useCallback } from 'react';
import { Alert, AlertTitle, Button, Typography, Grid } from '@mui/material';
import { useHistory } from 'react-router-dom';

const AlertSx = {
    display: 'flex',
    width: '100%',
    '& .MuiAlert-message': { width: '100%' },
};

const ErrorMessage = ({ title = 'error', text = 'Something went wrong', buttonText = 'Return to Previous Page', severity = 'error', onClick }) => {
    const history = useHistory();

    return (
        <Alert severity={severity} sx={AlertSx}>
            <Grid container spacing={2} justifyContent={'space-between'}>
                <Grid item>
                    <AlertTitle>{title}</AlertTitle>
                    <Typography>{text}</Typography>
                </Grid>
                <Grid item>
                    <Button variant="outlined" color={severity} onClick={onClick ?? (() => history.goBack())}>
                        {buttonText}
                    </Button>
                </Grid>
            </Grid>
        </Alert>
    );
};

export default ErrorMessage;
