import MilkPriceFormComponentPrices from './MilkPriceFormComponentPrices';
import MilkPriceFormComponentPricesNoSCC from './MilkPriceFormComponentPricesNoSCC';
import MilkPriceFormSkimButterfatPrices from './MilkPriceFormSkimButterfatPrices';
import MilkPriceFormSkimButterfatAdjustment from './MilkPriceFormSkimButterfatAdjustment';
import MilkPriceFormAdvanceBasePrice from './MilkPriceFormAdvanceBasePrice';
import MilkPriceFormPPDAdjustment from './MilkPriceFormPPDAdjustment';
import PrairieFarmsRbstPremium from './PrairieFarmsRbstPremium';
import MMPAMarketAdjustment from './MMPAMarketAdjustement';
import AdvanceBasePriceWithOrganicMilkPrice from './AdvanceBasePriceWithOrganicMilkPrice';
import OrganicMilkPrice from './OrganicMilkPrice';
import PrairieFarmsFederalOrder7 from './PrairieFarmsFederalOrder7';
import NebraskaSettlementMilkPrices from './NebraskaSettlementMilkPrices';
import TCJMilkPriceFormSkimButterfatPrices from './TCJMilkPriceFormSkimButterfatPrices';
import TCJLocationtAdjustment from './TCJLocationAdjustment';
import Region from '../../../constants/region';

const globalComponentFields = ['milk_prices.payment_date_settlement', 'milk_prices.butter_fat_price', 'milk_prices.protein_price', 'milk_prices.other_solids_price', 'milk_prices.scc_price'];
const globalSkimMilkButterfatBaseFields = ['milk_prices.payment_date_settlement', 'milk_prices.skim_milk_base_price', 'milk_prices.butter_fat_base_price'];
const globalSkimMilkButterfatFields = ['milk_prices.payment_date_settlement', 'milk_prices.skim_milk_price', 'milk_prices.butter_fat_price'];
const advanceFields = ['advance_milk_prices.payment_date_advance', 'advance_milk_prices.base_price'];

export const globalFederalOrderFields = [
    {
        federalOrder: '1',
        globalFields: [...advanceFields, 'advance_milk_prices.organic_milk_price', ...globalComponentFields],
        regions: [Region.UNC],
    },
    {
        federalOrder: '1',
        globalFields: [...advanceFields, ...globalComponentFields],
        regions: [Region.TCJ.LIBERTY],
    },
    {
        federalOrder: '5',
        globalFields: [...advanceFields, ...globalSkimMilkButterfatBaseFields],
        regions: [Region.PRAIRIE, Region.DEMO],
    },
    {
        federalOrder: '6',
        globalFields: [...advanceFields, ...globalSkimMilkButterfatFields],
        regions: [Region.TCJ.WHITEEAGLE],
    },
    {
        federalOrder: '7',
        globalFields: [...advanceFields, ...globalSkimMilkButterfatFields],
        regions: [Region.TCJ.KYTN],
    },
    {
        federalOrder: '7',
        globalFields: [...advanceFields, ...globalSkimMilkButterfatBaseFields],
        regions: [Region.PRAIRIE, Region.DEMO],
    },
    {
        federalOrder: '30',
        globalFields: [...advanceFields, ...globalComponentFields],
        regions: [Region.PRAIRIE, Region.TCJ.GPDA, Region.DEMO],
    },
    {
        federalOrder: '32',
        globalFields: [...advanceFields, ...globalComponentFields],
        regions: [Region.PRAIRIE, Region.TCJ.GPDA, Region.DEMO],
    },
    {
        federalOrder: '33',
        globalFields: [...advanceFields, ...globalComponentFields],
        regions: [Region.PRAIRIE, Region.MMPA, Region.TCJ.WHITEEAGLE, Region.TCJ.ERIE, Region.UNC, Region.DEMO],
    },
    {
        federalOrder: 'nebraska',
        globalFields: [...advanceFields, 'milk_prices.payment_date_settlement', 'milk_prices.butter_fat_price', 'milk_prices.protein_price', 'milk_prices.protein_caseinate_price'],
        regions: [Region.TCJ.NEBRASKA],
    },
    {
        federalOrder: '126',
        globalFields: [...advanceFields, ...globalComponentFields],
        regions: [Region.CACIQUE],
    },
    {
        federalOrder: '127',
        globalFields: [...advanceFields, ...globalComponentFields],
        regions: [Region.UNC],
    },
];

const regionFederalOrderConfigObject = {
    [Region.PRAIRIE]: [
        {
            federalOrder: '5',
            payGroups: ['10', '15'],
            SettlementMilkPrices: MilkPriceFormSkimButterfatPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormSkimButterfatAdjustment,
            AdditionalSettlementFields: PrairieFarmsRbstPremium,
            regionalMilkPriceFields: ['milk_prices.rbst_free_premium'],
        },
        {
            federalOrder: '7',
            payGroups: ['71'],
            SettlementMilkPrices: PrairieFarmsFederalOrder7,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            regionalMilkPriceFields: ['milk_prices.rbst_free_premium'],
            overrideFieldNames: {
                'advance_milk_prices.payment_date_advance': 'advance_milk_prices.pay_group_71.payment_date_advance',
                'advance_milk_prices.base_price': 'advance_milk_prices.pay_group_71.base_price',
                'milk_prices.payment_date_settlement': 'milk_prices.pay_group_71.payment_date_settlement',
            },
        },
        {
            federalOrder: '30',
            payGroups: ['25', '27'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
            AdditionalSettlementFields: PrairieFarmsRbstPremium,
            regionalMilkPriceFields: ['milk_prices.rbst_free_premium'],
        },
        {
            federalOrder: '32',
            payGroups: ['20', '30', '32', '35', '40'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
            AdditionalSettlementFields: PrairieFarmsRbstPremium,
            regionalMilkPriceFields: ['milk_prices.rbst_free_premium'],
        },
        {
            federalOrder: '33',
            payGroups: ['33'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
            AdditionalSettlementFields: PrairieFarmsRbstPremium,
            regionalMilkPriceFields: ['milk_prices.rbst_free_premium'],
        },
    ],
    [Region.CACIQUE]: [
        {
            federalOrder: '126',
            payGroups: ['cacique'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
        },
    ],
    [Region.DEMO]: [
        {
            federalOrder: '5',
            payGroups: ['10', '15'],
            SettlementMilkPrices: MilkPriceFormSkimButterfatPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormSkimButterfatAdjustment,
        },
        {
            federalOrder: '7',
            payGroups: ['71'],
            SettlementMilkPrices: PrairieFarmsFederalOrder7,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
        },
        {
            federalOrder: '30',
            payGroups: ['25', '27'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
        },
        {
            federalOrder: '32',
            payGroups: ['20', '30', '32', '35', '40'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
        },
        {
            federalOrder: '33',
            payGroups: ['33'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
        },
    ],
    [Region.MMPA]: [
        {
            federalOrder: '33',
            payGroups: ['Z1', 'Z2', 'Z3', 'Z4', 'Z5', 'Z6', 'Z7', 'Z8', 'Z9', 'ZA', 'ZB', 'ZN', 'ZS'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
            AdditionalSettlementFields: MMPAMarketAdjustment,
            regionalMilkPriceFields: ['milk_prices.market_adjustment'],
        },
    ],
    [Region.CA_DEMO]: [
        {
            federalOrder: '1',
            payGroups: ['1', '2', '3'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
        },
    ],
    [Region.TCJ.NEBRASKA]: [
        {
            federalOrder: 'nebraska',
            payGroups: ['nebraska'],
            SettlementMilkPrices: NebraskaSettlementMilkPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
        },
    ],
    [Region.TCJ.LIBERTY]: [
        {
            federalOrder: '1',
            payGroups: ['2_5'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
        },
    ],
    [Region.TCJ.WHITEEAGLE]: [
        {
            federalOrder: '6',
            payGroups: ['5_0'],
            SettlementMilkPrices: TCJMilkPriceFormSkimButterfatPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: TCJLocationtAdjustment,
        },
        {
            federalOrder: '33',
            payGroups: ['1_8', '2_0'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
        },
    ],
    [Region.TCJ.GPDA]: [
        {
            federalOrder: '30',
            payGroups: ['1_7'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
        },
        {
            federalOrder: '32',
            payGroups: ['1_8'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
        },
    ],
    [Region.TCJ.ERIE]: [
        {
            federalOrder: '33',
            payGroups: ['1_8'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
        },
    ],
    [Region.TCJ.KYTN]: [
        {
            federalOrder: '7',
            payGroups: ['2_7'],
            SettlementMilkPrices: TCJMilkPriceFormSkimButterfatPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: TCJLocationtAdjustment,
        },
    ],
    [Region.UNC]: [
        {
            federalOrder: '1',
            payGroups: ['1', '2', '3', '4', '5', 'organic'],
            SettlementMilkPrices: MilkPriceFormComponentPricesNoSCC,
            AdvanceMilkPrices: AdvanceBasePriceWithOrganicMilkPrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
            AdditionalSettlementFields: OrganicMilkPrice,
            regionalMilkPriceFields: ['milk_prices.organic_milk_price'],
            excludePayGroupAdjustment: ['organic'],
        },
        {
            federalOrder: '33',
            payGroups: ['2'],
            SettlementMilkPrices: MilkPriceFormComponentPrices,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
        },
        {
            federalOrder: '127',
            payGroups: ['1', '2'],
            SettlementMilkPrices: MilkPriceFormComponentPricesNoSCC,
            AdvanceMilkPrices: MilkPriceFormAdvanceBasePrice,
            SettlementPayGroupAdjustment: MilkPriceFormPPDAdjustment,
        },
    ],
};

const createRegionFederalOrderConfigProxy = {
    get(target, prop, receiver) {
        if (!Object.keys(regionFederalOrderConfigObject).includes(prop)) {
            return regionFederalOrderConfigObject[Region.CA_DEMO];
        }
        return regionFederalOrderConfigObject[prop];
    },
};

export const regionFederalOrderConfig = new Proxy(regionFederalOrderConfigObject, createRegionFederalOrderConfigProxy);

const componentFields = {
    butter_fat_price: '',
    protein_price: '',
    other_solids_price: '',
    scc_price: '',
};
const skimMilkButterfatBaseFields = {
    skim_milk_base_price: '',
    butter_fat_base_price: '',
};
const skimMilkButterfatFields = {
    skim_milk_price: '',
    butter_fat_price: '',
};
const settlementPaymentDateField = {
    payment_date_settlement: null,
};
const advancePaymentDateField = {
    payment_date_advance: null,
};
const advanceBasePriceField = {
    base_price: '',
};
const basePPDRateField = {
    base_ppd_rate: '',
};
const nebraskaComponentFields = {
    butter_fat_price: '',
    protein_price: '',
    protein_caseinate_price: '',
};
const organicMilkPrice = {
    organic_milk_price: '',
};

const defaultMilkPricesObject = {
    [Region.PRAIRIE]: {
        advance_milk_prices: {
            ...advancePaymentDateField,
            ...advanceBasePriceField,
            pay_group_71: {
                ...advanceBasePriceField,
                ...advancePaymentDateField,
            },
        },
        milk_prices: {
            ...componentFields,
            ...skimMilkButterfatBaseFields,
            ...settlementPaymentDateField,
            rbst_free_premium: '',
            federal_order_30: {
                ...basePPDRateField,
            },
            federal_order_32: {
                ...basePPDRateField,
            },
            federal_order_33: {
                ...basePPDRateField,
            },
            pay_group_10: {
                butter_fat_location_adjustment: -0.005,
                skim_milk_location_adjustment: -0.5,
            },
            pay_group_15: {
                butter_fat_location_adjustment: -0.011,
                skim_milk_location_adjustment: -1.1,
            },
            pay_group_25: {
                location_adjustment: -0.1,
            },
            pay_group_27: {
                location_adjustment: -0.05,
            },
            pay_group_20: {
                location_adjustment: 0,
            },
            pay_group_30: {
                location_adjustment: -0.25,
            },
            pay_group_32: {
                location_adjustment: -0.2,
            },
            pay_group_35: {
                location_adjustment: -0.25,
            },
            pay_group_40: {
                location_adjustment: -0.25,
            },
            pay_group_33: {
                location_adjustment: -0.2,
            },
            pay_group_71: {
                skim_milk_price: '',
                butter_fat_price: '',
                milk_at_3percent: '',
                ...settlementPaymentDateField,
            },
        },
    },
    [Region.DEMO]: {
        advance_milk_prices: {
            ...advancePaymentDateField,
            ...advanceBasePriceField,
            pay_group_71: {
                ...advanceBasePriceField,
                ...advancePaymentDateField,
            },
        },
        milk_prices: {
            ...componentFields,
            ...skimMilkButterfatBaseFields,
            ...settlementPaymentDateField,
            rbst_free_premium: '',
            federal_order_30: {
                ...basePPDRateField,
            },
            federal_order_32: {
                ...basePPDRateField,
            },
            federal_order_33: {
                ...basePPDRateField,
            },
            pay_group_10: {
                butter_fat_location_adjustment: -0.005,
                skim_milk_location_adjustment: -0.5,
            },
            pay_group_15: {
                butter_fat_location_adjustment: -0.011,
                skim_milk_location_adjustment: -1.1,
            },
            pay_group_25: {
                location_adjustment: -0.1,
            },
            pay_group_27: {
                location_adjustment: -0.05,
            },
            pay_group_20: {
                location_adjustment: 0,
            },
            pay_group_30: {
                location_adjustment: -0.25,
            },
            pay_group_32: {
                location_adjustment: -0.2,
            },
            pay_group_35: {
                location_adjustment: -0.25,
            },
            pay_group_40: {
                location_adjustment: -0.25,
            },
            pay_group_33: {
                location_adjustment: -0.2,
            },
            pay_group_71: {
                skim_milk_price: '',
                butter_fat_price: '',
                milk_at_3percent: '',
                ...settlementPaymentDateField,
            },
        },
    },
    [Region.CACIQUE]: {
        advance_milk_prices: {
            ...advancePaymentDateField,
            ...advanceBasePriceField,
            pay_group_cacique: {
                ...advanceBasePriceField,
                ...advancePaymentDateField,
            },
        },
        milk_prices: {
            ...componentFields,
            ...settlementPaymentDateField,
            federal_order_126: {
                ...basePPDRateField,
            },
            pay_group_cacique: {
                location_adjustment: 0,
            },
        },
    },
    [Region.MMPA]: {
        advance_milk_prices: {
            ...advancePaymentDateField,
            ...advanceBasePriceField,
        },
        milk_prices: {
            ...componentFields,
            ...settlementPaymentDateField,
            market_adjustment: '',
            federal_order_33: {
                ...basePPDRateField,
            },
            pay_group_Z1: {
                location_adjustment: -0.3,
            },
            pay_group_Z2: {
                location_adjustment: -0.27,
            },
            pay_group_Z3: {
                location_adjustment: -0.25,
            },
            pay_group_Z4: {
                location_adjustment: -0.2,
            },
            pay_group_Z5: {
                location_adjustment: -0.15,
            },
            pay_group_Z6: {
                location_adjustment: -0.1,
            },
            pay_group_Z7: {
                location_adjustment: -0.05,
            },
            pay_group_Z8: {
                location_adjustment: 0,
            },
            pay_group_Z9: {
                location_adjustment: -0.2,
            },
            pay_group_ZA: {
                location_adjustment: 0,
            },
            pay_group_ZB: {
                location_adjustment: 0,
            },
            pay_group_ZN: {
                location_adjustment: -0.2,
            },
            pay_group_ZS: {
                location_adjustment: 0,
            },
        },
    },
    [Region.CA_DEMO]: {
        advance_milk_prices: {
            ...advancePaymentDateField,
            ...advanceBasePriceField,
        },
        milk_prices: {
            ...settlementPaymentDateField,
            ...componentFields,
            pay_group_1: {
                location_adjustment: -0.1,
            },
            pay_group_2: {
                location_adjustment: -0.2,
            },
            pay_group_3: {
                location_adjustment: -0.3,
            },
        },
    },
    [Region.TCJ.NEBRASKA]: {
        advance_milk_prices: {
            ...advancePaymentDateField,
            ...advanceBasePriceField,
        },
        milk_prices: {
            ...settlementPaymentDateField,
            ...nebraskaComponentFields,
        },
    },
    [Region.TCJ.LIBERTY]: {
        advance_milk_prices: {
            ...advancePaymentDateField,
            ...advanceBasePriceField,
        },
        milk_prices: {
            ...componentFields,
            ...settlementPaymentDateField,
            federal_order_1: {
                ...basePPDRateField,
            },
            pay_group_2_5: {
                location_adjustment: -0.75,
            },
        },
    },
    [Region.TCJ.WHITEEAGLE]: {
        advance_milk_prices: {
            ...advancePaymentDateField,
            ...advanceBasePriceField,
        },
        milk_prices: {
            ...componentFields,
            ...skimMilkButterfatFields,
            ...settlementPaymentDateField,
            federal_order_33: {
                ...basePPDRateField,
            },
            pay_group_5_0: {
                location_adjustment: -0.4,
            },
            pay_group_1_8: {
                location_adjustment: -0.2,
            },
            pay_group_2_0: {
                location_adjustment: 0,
            },
        },
    },
    [Region.TCJ.GPDA]: {
        advance_milk_prices: {
            ...advancePaymentDateField,
            ...advanceBasePriceField,
        },
        milk_prices: {
            ...componentFields,
            ...settlementPaymentDateField,
            federal_order_30: {
                ...basePPDRateField,
            },
            federal_order_32: {
                ...basePPDRateField,
            },
            pay_group_1_7: {
                location_adjustment: -0.1,
            },
            pay_group_1_8: {
                location_adjustment: 0,
            },
        },
    },
    [Region.TCJ.ERIE]: {
        advance_milk_prices: {
            ...advancePaymentDateField,
            ...advanceBasePriceField,
        },
        milk_prices: {
            ...componentFields,
            ...settlementPaymentDateField,
            federal_order_33: {
                ...basePPDRateField,
            },
            pay_group_1_8: {
                location_adjustment: -0.2,
            },
        },
    },
    [Region.TCJ.KYTN]: {
        advance_milk_prices: {
            ...advancePaymentDateField,
            ...advanceBasePriceField,
        },
        milk_prices: {
            ...skimMilkButterfatFields,
            ...settlementPaymentDateField,
            pay_group_2_7: {
                location_adjustment: -1.1,
            },
        },
    },
    [Region.UNC]: {
        advance_milk_prices: {
            ...advancePaymentDateField,
            ...advanceBasePriceField,
            ...organicMilkPrice,
        },
        milk_prices: {
            ...componentFields,
            ...settlementPaymentDateField,
            ...organicMilkPrice,
            federal_order_1: {
                ...basePPDRateField,
            },
            federal_order_33: {
                ...basePPDRateField,
            },
            federal_order_127: {
                ...basePPDRateField,
            },
            pay_group_1: {
                location_adjustment: 0,
            },
            pay_group_2: {
                location_adjustment: 0.07,
            },
            pay_group_3: {
                location_adjustment: 0.2,
            },
            pay_group_4: {
                location_adjustment: 0.3,
            },
            pay_group_5: {
                location_adjustment: 0.1,
            },
            pay_group_organic: {
                location_adjustment: 0,
            },
        },
    },
};

const defaultMilkPricesProxy = {
    get(target, prop, receiver) {
        if (!Object.keys(defaultMilkPricesObject).includes(prop)) {
            return defaultMilkPricesObject.CA_DEMO;
        }
        return defaultMilkPricesObject[prop];
    },
};

export const defaultMilkPrices = new Proxy(defaultMilkPricesObject, defaultMilkPricesProxy);
