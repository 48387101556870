import axios from 'axios';
import _ from 'lodash';
import { arrayToMap, getId, renameKey } from '../../utils';
import { getRouteSessionsQuery, getDropoffsQuery, getPickupsQuery, getRoutesQuery, getDriversQuery, getHaulingCompaniesQuery, bulkQuery, getTrailersQuery, batchQuery } from './queries';
import { buildExpressQuery } from './queries/builders';
import moment from 'moment-timezone';

const getProcessorRouteSessionDetails = (id, start, end) =>
    new Promise((resolve, reject) => {
        const routeSessionIdQuery = getRouteSessionsQuery({
            start,
            end,
            fields: ['id'],
            filterDeleted: true,
            filterType: 'milk',
            queryBuilder: buildExpressQuery,
        });
        const routeSessionIdRequest = axios.get(routeSessionIdQuery);
        routeSessionIdRequest
            .then((routeSessionIdResponse) => {
                renameKey(routeSessionIdResponse.data, '_id', 'id');
                const allRouteSessionIds = _.uniq(_.map(routeSessionIdResponse.data, 'id'));

                const dropoffQuery = bulkQuery(getDropoffsQuery, {
                    fields: ['route_session_id', 'route_number', 'volume', 'metered'],
                    processorId: id,
                    routeSessionIds: allRouteSessionIds,
                    filterDeleted: true,
                    filterType: 'milk',
                });

                const dropoffRequest = axios.post(dropoffQuery.url, dropoffQuery.body);

                dropoffRequest.then((dropoffResponse) => {
                    const dropoffs = dropoffResponse.data;
                    renameKey(dropoffs, '_id', 'id');

                    const routeSessionIds = _.uniq(_.map(dropoffs, 'route_session_id'));

                    const pickupsQuery = bulkQuery(getPickupsQuery, {
                        fields: ['route_session_id', 'volume', 'producer_id'],
                        routeSessionIds,
                        filterDeleted: true,
                        hint: 'route_session_id_1_deleted_at_1_created_at_1',
                    });

                    const routeSessionQuery = bulkQuery(getRouteSessionsQuery, {
                        fields: [
                            'id',
                            'route_id',
                            'created_by',
                            'BOL',
                            'created_at',
                            'status',
                            'pooled_load',
                            'type_of_fluid',
                            'odometer_start_miles',
                            'odometer_end_miles',
                            'total_distance',
                            'route_number',
                            'trailer_id',
                            'manifest_number',
                            'is_locked',
                            'hauling_id',
                            'pre_delivery_report_processor_recipients',
                            'po_number',
                            'kosher',
                            'delivery_report',
                        ],
                        ids: routeSessionIds,
                        filterDeleted: true,
                        hint: '_id_1_created_at_-1_deleted_at_1',
                    });

                    const pickupsRequest = axios.post(pickupsQuery.url, pickupsQuery.body);
                    const routeSessionRequest = axios.post(routeSessionQuery.url, routeSessionQuery.body);
                    const userId = getId();

                    return axios.all([pickupsRequest, routeSessionRequest]).then(
                        axios.spread((...responses) => {
                            const pickups = responses[0].data;
                            const routeSessions = responses[1].data;
                            renameKey(pickups, '_id', 'id');
                            renameKey(routeSessions, '_id', 'id');

                            const routeIds = _.uniq(_.map(routeSessions, 'route_id'));
                            const driverIds = _.uniq(_.map(routeSessions, 'created_by'));
                            const haulingIds = _.uniq(_.map(routeSessions, 'hauling_id'));
                            const trailerIds = _.uniq(_.map(routeSessions, 'trailer_id'));

                            const routeQuery = bulkQuery(getRoutesQuery, { fields: ['id', 'name'], ids: routeIds });
                            const driverQuery = bulkQuery(getDriversQuery, { fields: ['id', 'name'], ids: driverIds });
                            const haulingQuery = bulkQuery(getHaulingCompaniesQuery, { fields: ['id', 'name'], ids: haulingIds });
                            const trailerQuery = batchQuery(getTrailersQuery, { fields: ['id', 'trailer_number'], ids: trailerIds }, 'ids');

                            const routeRequest = axios.post(routeQuery.url, routeQuery.body);
                            const driverRequest = axios.post(driverQuery.url, driverQuery.body);
                            const haulingRequest = axios.post(haulingQuery.url, haulingQuery.body);
                            const trailersRequest = _.map(trailerQuery, (q) => axios.get(q));

                            const subRequests = [routeRequest, driverRequest, haulingRequest, ...trailersRequest];
                            return axios.all(subRequests).then(
                                axios.spread((...subResponses) => {
                                    const routes = subResponses[0].data;
                                    const drivers = subResponses[1].data;
                                    const hauling = subResponses[2].data;
                                    const trailers = subResponses?.[3]?.data || [];

                                    renameKey(routes, '_id', 'id');
                                    renameKey(drivers, '_id', 'id');
                                    renameKey(hauling, '_id', 'id');

                                    const pickupTotals = _.mapValues(_.groupBy(pickups, 'route_session_id'), (p) => _.sumBy(p, 'volume'));
                                    const dropoffTotals = _.mapValues(_.groupBy(dropoffs, 'route_session_id'), (d) => _.sumBy(d, 'volume'));
                                    const meteredTotals = _.mapValues(_.groupBy(dropoffs, 'route_session_id'), (d) => _.sumBy(d, 'metered'));

                                    const dropoffMap = _.groupBy(dropoffs, 'route_session_id');
                                    const routesMap = arrayToMap(routes, 'id');
                                    const driversMap = arrayToMap(drivers, 'id');
                                    const haulingsMap = arrayToMap(hauling, 'id');
                                    const trailersMap = arrayToMap(trailers, 'id');

                                    const routeSessionsResponse = _.map(routeSessions, (rs) => {
                                        return {
                                            BOL: rs.BOL,
                                            route_number: rs.route_number,
                                            date: rs.created_at,
                                            dropoffVolume: dropoffTotals[rs.id] ? dropoffTotals[rs.id].toString() : '0',
                                            metered: meteredTotals[rs.id] ? meteredTotals[rs.id] : 0,
                                            firstDropoffDate: dropoffMap[rs.id]?.[0] ? moment(dropoffMap[rs.id][0].created_at) : 'No Drop',
                                            flagged: !dropoffTotals[rs.id] || !pickupTotals[rs.id] || dropoffTotals[rs.id] !== pickupTotals[rs.id],
                                            id: rs.id,
                                            po_number: rs.po_number,
                                            kosher: rs.kosher || false,
                                            name: rs.created_by in driversMap ? driversMap[rs.created_by].name : '',
                                            trailer_number: trailersMap?.[rs.trailer_id]?.trailer_number,
                                            driver_id: rs.created_by || '',
                                            trailer_id: rs.trailer_id || '',
                                            hauling_id: rs.hauling_id || rs.hauling_id in haulingsMap ? haulingsMap[rs.hauling_id].id : '',
                                            hauling_company: rs.hauling_id in haulingsMap ? haulingsMap[rs.hauling_id].name : '',
                                            route: rs.route_id in routesMap ? routesMap[rs.route_id].name : '',
                                            route_id: rs.route_id || '',
                                            route_session_status: _.capitalize(rs.status),
                                            manifest_number: rs.manifest_number,
                                            volume: pickupTotals[rs.id] ? pickupTotals[rs.id] : 0,
                                            type_of_fluid: rs.type_of_fluid ?? 'milk',
                                            odometer_start_miles: rs.odometer_start_miles,
                                            odometer_end_miles: rs.odometer_end_miles,
                                            total_distance: rs.total_distance,
                                            pre_delivery_report_received: rs?.pre_delivery_report_processor_recipients?.indexOf(userId),
                                            delivery_report: rs?.delivery_report,
                                        };
                                    });

                                    // dropoffs - artifact of UI and old API?
                                    resolve({ dropoffs: routeSessionsResponse });
                                })
                            );
                        })
                    );
                });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getProcessorRouteSessionDetails;
