import { AbilityBuilder, PureAbility } from '@casl/ability';
import Region from '../../../../constants/region';
import { getId } from '../../../../../utils';

function PickupPermissions(user) {
    const { can, rules } = new AbilityBuilder(PureAbility);
    const {
        role,
        data: { region },
    } = user;
    const id = getId();

    // Only PRAIRIE processors can mutate raw milk pickup
    if (role === 'processor' && [Region.PRAIRIE].includes(region)) {
        can('mutate', 'raw_milk_pickup');
        can('view', 'raw_milk_pickup');
    }

    // Admin can View and Mutate
    if (role === 'admin') {
        can('mutate', 'raw_milk_pickup');
        can('view', 'raw_milk_pickup');
    }

    // Producer Business can View
    if (role === 'producer_business') {
        // can view if the pickup belongs to any of the assigned producers
        can('view', 'raw_milk_pickup', { producer_id: { $in: user.data.producers } });
    }

    if (role === 'transport') {
        can('view', 'raw_milk_pickup');

        // Haulers in PRAIRIE cannot edit pickups
        if (![Region.PRAIRIE].includes(region)) {
            // Haulers can edit pickups they are assigned to
            can('mutate', 'raw_milk_pickup', { hauling_id: id });
        }
    }

    // Producers can see their own pickups
    if (role === 'producer') {
        can('view', 'raw_milk_pickup', { producer_id: id });
    }

    return rules;
}

export default PickupPermissions;
