import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Divider, InputAdornment } from '@material-ui/core';
import Formsy from 'formsy-react';
import { showMessage, addSubProcessor, editUser } from 'app/store/actions';
import PermissionSelector from '../../custom-widgets/PermissionsSelector/PermissionSelector';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import { TextFieldFormsy } from '@fuse';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import { formatUserErrorMessage } from '../../../utils';
import { Alert } from '@mui/material';
import UserAlreadyExistsWarningDialog from '../../custom-widgets/dialogs/UserAlreadyExistsWarningDialog';

const SubProcessorForm = ({ editData, processors, preselectedPermissions, disabled }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [canSubmit, setCanSubmit] = useState(false);
    const [alert, setAlert] = useState(null);
    const [warning, setWarning] = useState(null);
    const basePermissions = editData?.permissions || preselectedPermissions || [];
    const [permissions, setPermissions] = useState(new Set(basePermissions));
    const showError = !permissions.size;

    const disableButton = () => {
        setCanSubmit(false);
    };

    const enableButton = () => {
        setCanSubmit(true);
    };

    const handlePermissionChange = (newPermissions) => {
        setPermissions(newPermissions);
    };

    const navigateToManageUsers = () => {
        history.replace({ pathname: '/manage/sub-users' });
    };

    const onSubmit = (model) => {
        const submitModel = { ...model };

        submitModel.permissions = Array.from(permissions);

        if (editData) {
            delete submitModel.username;
            delete submitModel.email;
            dispatch(editUser(submitModel, editData.id))
                .then(() => {
                    dispatch(showMessage({ message: 'Successfully Edited Sub Processor.' }));
                    setAlert(null);
                    navigateToManageUsers();
                })
                .catch((err) => {
                    dispatch(showMessage({ message: formatUserErrorMessage(err, 'Sub Processor', 'edit') }));
                });
        } else {
            submitModel.type_of_fluid = processors ? processors[0].type_of_fluid : 'milk';
            dispatch(addSubProcessor(submitModel))
                .then((response) => {
                    dispatch(showMessage({ message: 'Successfully Added Sub Processor.' }));
                    setAlert(null);
                    if (response?.payload?.data?.warning) {
                        setWarning(response.payload.data.warning);
                    } else {
                        navigateToManageUsers();
                    }
                })
                .catch((err) => {
                    dispatch(showMessage({ message: 'Unable to create a new Sub Processor' }));
                    if (err.message) {
                        setAlert(err.message);
                    }
                });
        }
    };

    return (
        <LocalizationConsumer>
            {(localization) => (
                <div className="min-w-3/4 max-w-3/4">
                    <UserAlreadyExistsWarningDialog open={!!warning} onClose={() => navigateToManageUsers()} message={warning} />

                    <Divider />

                    <Formsy
                        onValidSubmit={onSubmit}
                        onValid={enableButton}
                        onInvalid={disableButton}
                        /* eslint-disable-next-line no-return-assign */
                        ref={(form) => form}
                        className="flex flex-col justify-center"
                    >
                        {alert && (
                            <Alert className="mt-32 mb-16" color="error">
                                {alert}
                            </Alert>
                        )}
                        <TextFieldFormsy
                            className="my-16"
                            type="text"
                            name="name"
                            label="Name"
                            value={editData ? editData.name : ''}
                            validations={{ minLength: 2 }}
                            validationErrors={{ minLength: 'Min character length is 2' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-20 material-icons" color="action">
                                            person
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            required
                            variant="outlined"
                        />

                        <TextFieldFormsy
                            className="my-16"
                            type="text"
                            name="username"
                            label="Username"
                            value={editData ? editData.username : ''}
                            validations={{ ...(!editData && { minLength: 4 }) }}
                            validationErrors={{ minLength: 'Min character length is 4' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-20 material-icons" color="action">
                                            bookmark
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            required
                            variant="outlined"
                            disabled={!!editData}
                        />

                        {!editData && (
                            <TextFieldFormsy
                                className="my-16"
                                type="password"
                                name="password"
                                label="Password"
                                validations={{ minLength: 8, equalsField: 'password_confirmation' }}
                                validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                InputProps={{
                                    autoComplete: 'new-password',
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                vpn_key
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                required
                            />
                        )}

                        {!editData && (
                            <TextFieldFormsy
                                className="my-16"
                                type="password"
                                name="password_confirmation"
                                label="Confirm Password"
                                validations={{ minLength: 8, equalsField: 'password' }}
                                validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                vpn_key
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                required
                            />
                        )}

                        <TextFieldFormsy
                            className="my-16"
                            type="text"
                            name="email"
                            label="Email"
                            value={editData ? editData.email : ''}
                            validations="isEmail"
                            validationError="This is not a valid email"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-20 material-icons" color="action">
                                            alternate_email
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            required
                            variant="outlined"
                            disabled={!!editData}
                        />

                        <TextFieldFormsy
                            className="my-16"
                            type="text"
                            name="secondary_email"
                            label="Secondary Email"
                            validations="isEmail"
                            value={editData ? editData.secondary_email : ''}
                            validationError="This is not a valid email"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-20 material-icons" color="action">
                                            alternate_email
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                        />

                        <SelectSearchFormsy
                            className="my-16"
                            name="processor_id"
                            label={`${localization.general.processor_singular}`}
                            variant="standard"
                            options={[{ value: ' ', name: 'None' }, ...processors.map((entry) => ({ value: entry.value, name: entry.name }))]}
                            value={editData ? editData.processor_id : processors.length === 1 ? processors[0].value : ' '}
                            validations="minLength:2"
                            validationError={`Please select a ${localization.general.processor_singular}`}
                            disabled={!!editData || processors.length === 1}
                        />

                        <PermissionSelector
                            permissionType="Sub-Processor"
                            handlePermissionChange={handlePermissionChange}
                            /* eslint no-unneeded-ternary:0 */
                            selectedValues={permissions}
                            disabled={disabled}
                            showError={showError}
                        />

                        <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={editData ? 'EDIT SUB USER' : 'ADD SUB USER'} disabled={disabled || !canSubmit}>
                            {editData ? 'Edit Sub User' : 'Add Sub User'}
                        </Button>
                    </Formsy>
                </div>
            )}
        </LocalizationConsumer>
    );
};

export default withRouter(SubProcessorForm);
