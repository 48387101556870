import React from 'react';
import { Circle, Polygon } from 'react-google-maps';

const defaultShapeOptions = {
    strokeColor: '#FF0000',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: '#FF0000',
    fillOpacity: 0.35,
    visible: true,
    zIndex: 1,
};

const shapeOptionsMap = {
    producer: { fillColor: '#40B481', strokeColor: '#40B481', strokeWeight: 2 },
    processor: { fillColor: '#00BCD4', strokeColor: '#00BCD4', strokeWeight: 2 },
};

const isCircleGeofence = (geofence) => geofence?.type === 'radialGeofence';
const isPolygonGeofence = (geofence) => geofence?.type === 'polygonalGeofence';

const GeofenceArtist_Deprecited = ({ geofences }) => {
    return geofences.map((geofence) => {
        const shapeOptions = shapeOptionsMap[geofence.user_id?.role] || defaultShapeOptions;
        if (isCircleGeofence(geofence)) {
            const circleCenter = {
                lat: geofence.center.coordinates[1],
                lng: geofence.center.coordinates[0],
            };
            return (
                <Circle
                    key={geofence._id}
                    center={circleCenter}
                    radius={geofence.radius_meters}
                    options={{ ...shapeOptions }}
                    // wrap
                />
            );
        }
        if (isPolygonGeofence(geofence)) {
            return (
                <Polygon
                    key={geofence._id}
                    paths={geofence.geometry.coordinates[0].map((point) => ({
                        lat: point[1],
                        lng: point[0],
                    }))}
                    options={{ ...shapeOptions }}
                    // wrap
                />
            );
        }
        return null;
    });
};

export default GeofenceArtist_Deprecited;
