import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { useDispatch } from 'react-redux';
import { editPickup, showMessage } from 'app/store/actions';

function PaperComponent(props) {
    return <Paper {...props} />;
}

const PoolingDialog = ({ data, forceFetch, toggleLoading }) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);

    const currentStatusBool = data.pooled_load === 'P';
    const currentPooledStatus = currentStatusBool ? 'Pooled' : 'Unpooled';
    const potentialPooledStatus = currentStatusBool ? 'Unpooled' : 'Pooled';

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTogglePooled = async (model) => {
        const submitModel = {
            pooled_load: !model.currentPooled,
            latitude: model.latitude,
            longitude: model.longitude,
        };

        toggleLoading(true);

        dispatch(editPickup(submitModel, model.pickup_id))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully toggled pooling. This may take a moment to reflect in your table.' }));
                forceFetch();
            })
            .catch((err) => {
                dispatch(showMessage({ message: err.message }));
            })
            .finally(() => {
                toggleLoading(false);
            });

        setOpen(false);
    };

    return (
        <div>
            <Button variant="contained" color={'primary'} className="my-16" aria-label={'Pooled Dialog'} onClick={handleClickOpen}>
                {'Toggle Pooled'}
            </Button>
            <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
                <DialogTitle id="draggable-dialog-title">
                    {currentPooledStatus} - LT: {data.BOL} - Manifest: {data.manifest_number}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText className="text-black">
                        Toggle pickup from {currentPooledStatus} to {potentialPooledStatus}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleTogglePooled({ ...data, currentPooled: currentStatusBool })} variant="contained" color="secondary">
                        Toggle to {potentialPooledStatus}
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default PoolingDialog;
