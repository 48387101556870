import axios from 'axios';
import { renameKey } from '../../utils';
import { getHaulingCompaniesQuery } from './queries';
import { buildExpressQuery } from './queries/builders';

const getHaulingListDetails = (includeDeleted, id) =>
    new Promise((resolve, reject) => {
        const fields = [
            'created_at',
            'deleted_at',
            'address1',
            'address2',
            'town',
            'state',
            'postal_code',
            'email',
            'secondary_email',
            'description',
            'id',
            'name',
            'updated_at',
            'username',
            'hauler_number',
            'license_number',
            'expiry_date',
            'full_name',
            'payment_method',
            'advance_amount',
            'bank_transaction_code',
            'bank_account_number',
            'bank_routing_number',
            'owner_name',
            'owner_phone',
            'owner_email',
            'dispatcher_name',
            'dispatcher_phone',
            'dispatcher_email',
            'on_call_name',
            'on_call_phone',
            'on_call_email',
            'phone',
            'mailing_address1',
            'mailing_city',
            'mailing_state',
            'mailing_zip',
        ];
        const query = getHaulingCompaniesQuery({
            fields,
            queryBuilder: buildExpressQuery,
            ...(!includeDeleted && { filterDeleted: true }),
            ...(!!id && { ids: [id] }),
        });
        const request = axios.get(query);

        request
            .then((response) => {
                renameKey(response.data, '_id', 'id');
                resolve({ haulingCompanies: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getHaulingListDetails;
