import authRoles from '../../../../../auth/authRoles';

const { everyoneButGuest } = authRoles;

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'item',
    icon: 'desktop_windows',
    url: '/dashboard',
    auth: everyoneButGuest,
    permission: ['GetDashboardDetails'],
};

const pages = {
    id: 'pages',
    title: 'Pages',
    type: 'group',
    icon: 'apps',
    children: [
        {
            id: 'admin-users',
            title: 'Users',
            type: 'collapse',
            auth: ['admin', 'sub-admin'],
            icon: 'person',
            permission: ['ListAdmins', 'AccessProducerManagement', 'ListProducers', 'ListDrivers', 'ListProcessors', 'AccessPlantManagement', 'ListHaulingCompanies', 'AccessHaulerManagement', 'ListTrailers', 'ListTrucks'],
            requireAllPermissions: false,
            children: [
                {
                    id: 'admin-users-admin',
                    title: 'Admins',
                    type: 'item',
                    icon: 'supervisor_account',
                    url: '/list-admins',
                    permission: ['ListAdmins'],
                },
                {
                    id: 'admin-users-producers',
                    title: 'Producers',
                    type: 'item',
                    icon: 'landscape',
                    url: '/list-producers',
                    permission: ['AccessProducerManagement', 'ListProducers'],
                    requireAllPermissions: false,
                },
                {
                    id: 'admin-users-producer-businesses',
                    title: 'Producer Businesses',
                    type: 'item',
                    icon: 'business_center',
                    url: '/list-producer-businesses',
                },
                {
                    id: 'admin-users-lab-providers',
                    title: 'Lab Providers',
                    type: 'item',
                    icon: 'assistant',
                    url: '/list-lab-providers',
                },
                {
                    id: 'admin-users-drivers',
                    title: 'Drivers',
                    type: 'item',
                    icon: 'directions_bus',
                    url: '/list-drivers',
                    permission: ['ListDrivers'],
                },
                {
                    id: 'admin-users-receivers',
                    title: 'Receivers',
                    type: 'item',
                    icon: 'flag',
                    url: '/list-receivers',
                },
                {
                    id: 'admin-users-processors',
                    title: 'Processors',
                    type: 'item',
                    icon: 'local_convenience_store',
                    url: '/list-processors',
                    permission: ['AccessPlantManagement', 'ListProcessors'],
                    requireAllPermissions: false,
                },
                {
                    id: 'admin-users-hauling',
                    title: 'Hauling Companies',
                    type: 'item',
                    icon: 'airport_shuttle',
                    url: '/list-hauling-companies',
                    permission: ['ListHaulingCompanies', 'AccessHaulerManagement'],
                    requireAllPermissions: false,
                },
                {
                    id: 'admin-users-trailer',
                    title: 'Trailers',
                    type: 'item',
                    icon: 'local_shipping',
                    url: '/list-trailers',
                    permission: ['ListTrailers'],
                },
            ],
        },
        {
            id: 'processor-plant-management',
            title: 'Plant Management',
            type: 'item',
            auth: ['processor', 'sub-processor'],
            icon: 'local_convenience_store',
            url: '/plant-management',
            split: 'can_access_plant_management',
            permission: ['AccessPlantManagement'],
            subPermissionOnly: true,
        },
        {
            id: 'transport-hauler-management',
            title: 'Hauler Management',
            type: 'item',
            auth: ['transport', 'sub-transport'],
            icon: 'airport_shuttle',
            url: '/hauler-management',
            split: 'can_access_plant_management',
            permission: ['AccessHaulerManagement'],
            subPermissionOnly: true,
        },
        {
            id: 'producer-producer-management',
            title: 'Producer Management',
            type: 'item',
            auth: ['producer', 'sub-producer'],
            icon: 'landscape',
            url: '/producer-management',
            split: 'can_access_plant_management',
            permission: ['AccessProducerManagement'],
            subPermissionOnly: true,
        },
        {
            id: 'transport-drivers',
            title: 'Drivers',
            type: 'item',
            auth: ['transport', 'sub-transport'],
            icon: 'directions_bus',
            url: '/list-drivers',
            permission: ['ListDrivers'],
        },
        {
            id: 'transport-trailers',
            title: 'Trailers',
            type: 'item',
            auth: ['transport', 'sub-transport'],
            icon: 'local_shipping',
            url: '/list-trailers',
            permission: ['ListTrailers'],
            subPermissionOnly: true,
        },
        {
            id: 'processor-receivers',
            title: 'Receivers',
            type: 'item',
            auth: ['processor', 'sub-processor'],
            icon: 'flag',
            url: '/list-receivers',
            permission: ['ListReceivers'],
            subPermissionOnly: true,
        },
        {
            id: 'pooling',
            title: 'Pooling',
            type: 'collapse',
            auth: ['admin', 'sub-admin'],
            icon: 'pool',
            permission: ['ManagePoolingDecisions', 'ManageProcessorTotals', 'ManageProducerTouchBases'],
            requireAllPermissions: false,
            children: [
                {
                    id: 'pooling-decisions',
                    title: 'Pooling Decisions',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManagePoolingDecisions'],
                    icon: 'filter_1',
                    url: '/pooling-decisions',
                },
                {
                    id: 'processor-fmmo-monthly-totals',
                    title: 'Processor Totals',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageProcessorTotals'],
                    icon: 'business',
                    url: '/processor-fmmo-monthly-totals',
                },
                {
                    id: 'producer-touch-base',
                    title: 'Producer Touch Bases',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageProducerTouchBases'],
                    icon: 'landscape',
                    url: '/producer-touch-base',
                },
            ],
        },
        {
            id: 'silos',
            title: 'Silos',
            type: 'item',
            auth: ['admin', 'sub-admin', 'processor', 'sub-processor'],
            icon: 'directions_bus',
            url: '/list-silos',
            permission: ['ListSilos'],
            subPermissionOnly: true,
        },
        {
            id: 'usergroup',
            title: 'User Groups',
            type: 'item',
            icon: 'group',
            auth: ['admin', 'sub-admin'],
            url: '/user-groups',
            permission: ['ListUserGroups'],
            subPermissionOnly: true,
        },
        {
            id: 'driver-performance',
            title: 'Driver Performance',
            type: 'item',
            icon: 'bar_chart',
            url: '/driver-performance',
            auth: ['admin', 'sub-admin', 'transport', 'sub-transport'],
        },
        {
            id: 'driver-locations',
            title: 'Driver Locations',
            type: 'item',
            auth: ['admin', 'sub-admin', 'transport', 'sub-transport'],
            url: '/driver-locations',
            icon: 'add_location',
            permission: ['ViewDriverLocations'],
        },
        {
            id: 'routes',
            title: 'Routes',
            type: 'item',
            auth: ['admin', 'sub-admin', 'transport', 'sub-transport'],
            url: '/list-schedule-routes',
            icon: 'near_me',
            permission: ['ListScheduleRoutes'],
        },
        {
            id: 'close-period',
            title: 'Close Period',
            type: 'item',
            auth: ['admin', 'sub-admin'],
            url: '/close-period',
            icon: 'lock',
            permission: ['ManageClosePeriod'],
            subPermissionOnly: true,
        },
        {
            id: 'pickups',
            title: 'Pickups',
            type: 'item',
            auth: ['admin', 'sub-admin', 'transport', 'sub-transport', 'producer', 'sub-producer', 'producer_business'],
            icon: 'vertical_align_top',
            permission: ['ListPickups'],
            url: '/pickups',
        },
        {
            id: 'pickups-labs',
            title: 'Pickups and Labs',
            type: 'item',
            auth: ['producer', 'sub-producer'],
            icon: 'local_drink',
            permission: ['ListPickupsAndLabs'],
            url: '/pickups-and-labs',
        },
        {
            id: 'dropoffs',
            title: 'Dropoffs',
            type: 'item',
            auth: ['admin', 'sub-admin', 'transport', 'sub-transport', 'processor', 'sub-processor'],
            icon: 'vertical_align_bottom',
            permission: ['ListDropoffs'],
            url: '/dropoffs',
        },
        {
            id: 'receiving',
            title: 'Receiving',
            type: 'item',
            auth: ['admin', 'sub-admin', 'processor', 'sub-processor', 'transport', 'sub-transport'],
            permission: ['ViewReceivingDropoffs'],
            subPermissionOnly: true,
            icon: 'local_shipping',
            url: '/receiving',
        },
        {
            id: 'washes',
            title: 'CIP Wash',
            type: 'item',
            auth: ['transport', 'sub-transport'],
            icon: 'local_car_wash',
            url: '/washes',
            permission: ['ListCIPWash'],
            subPermissionOnly: true,
        },
        {
            id: 'processed-fluids-collapse',
            title: 'Processed Fluids',
            type: 'collapse',
            auth: ['admin', 'sub-admin', 'processor', 'sub-processor', 'transport', 'sub-transport'],
            icon: 'navigation',
            permission: ['ViewDriverLocations', 'ListPickups', 'ListDropoffs', 'ListRouteSessions'],
            requireAllPermissions: false,
            children: [
                {
                    id: 'driver-locations',
                    title: 'Driver Locations',
                    type: 'item',
                    auth: ['admin', 'sub-admin', 'transport', 'sub-transport'],
                    url: '/processed-fluid/driver-locations',
                    icon: 'add_location',
                    permission: ['ViewDriverLocations'],
                },
                {
                    id: 'processed-fluid-pickups',
                    title: 'Pickups',
                    type: 'item',
                    auth: ['admin', 'sub-admin', 'processor', 'sub-processor', 'transport', 'sub-transport'],
                    icon: 'vertical_align_top',
                    url: '/processed-fluid/pickups',
                    permission: ['ListPickups'],
                },
                {
                    id: 'processed-fluid-dropoffs',
                    title: 'Dropoffs',
                    type: 'item',
                    auth: ['admin', 'sub-admin', 'processor', 'sub-processor', 'transport', 'sub-transport'],
                    icon: 'vertical_align_bottom',
                    url: '/processed-fluid/dropoffs',
                    permission: ['ListDropoffs'],
                },
                {
                    id: 'processed-fluid-route-session',
                    title: 'Route Sessions',
                    type: 'item',
                    auth: ['admin', 'sub-admin', 'processor', 'sub-processor', 'transport', 'sub-transport'],
                    icon: 'navigation',
                    url: '/processed-fluid/route-sessions',
                    permission: ['ListRouteSessions'],
                },
            ],
        },
        {
            id: 'invoicing-collapse',
            title: 'Invoicing',
            type: 'collapse',
            auth: ['admin', 'sub-admin', 'processor', 'sub-processor'],
            icon: 'receipt',
            subPermissionOnly: true,
            children: [
                {
                    id: 'invoicing-price-configuration',
                    title: 'Price Configuration',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    url: '/invoicing/price-configuration',
                },
                {
                    id: 'invoicing-check-out-report',
                    title: 'Checkout Report',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    url: '/invoicing/checkout-report',
                },
                {
                    id: 'invoicing-check-out-report',
                    title: 'Checkout Report',
                    type: 'item',
                    auth: ['processor', 'sub-processor'],
                    url: '/invoicing/processor-checkout-report',
                },
                {
                    id: 'invoicing-utilization',
                    title: 'Utilization',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    url: '/invoicing/utilization',
                },
                {
                    id: 'invoicing-summary',
                    title: 'Statement Summary',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    url: '/invoicing/summary',
                },
            ],
        },
        {
            id: 'wash-collapse',
            title: 'CIP Wash',
            type: 'collapse',
            auth: ['admin', 'sub-admin'],
            icon: 'local_car_wash',
            permission: ['ListCIPWash'],
            children: [
                {
                    id: 'wash-facilities',
                    title: 'Wash Facilities',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    icon: 'bathroom',
                    url: '/wash-facilities',
                },
                {
                    id: 'washes',
                    title: 'Washes',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    icon: 'local_laundry_service',
                    url: '/washes',
                },
            ],
        },
        {
            id: 'cream-collapse',
            title: 'Cream',
            type: 'collapse',
            auth: ['admin', 'sub-admin', 'producer', 'sub-producer', 'transport', 'sub-transport'],
            icon: 'local_drink',
            permission: ['ListCreamPickups', 'ListCreamDropoffs', 'ListCreamRouteSessions'],
            requireAllPermissions: false,
            children: [
                {
                    id: 'cream-pickups',
                    title: 'Cream Pickups',
                    type: 'item',
                    auth: ['admin', 'sub-admin', 'transport', 'sub-transport', 'producer', 'sub-producer'],
                    icon: 'vertical_align_top',
                    permission: ['ListCreamPickups'],
                    url: '/cream-pickups',
                },
                {
                    id: 'cream-dropoffs',
                    title: 'Cream Dropoffs',
                    type: 'item',
                    auth: ['admin', 'sub-admin', 'transport', 'sub-transport'],
                    icon: 'vertical_align_bottom',
                    permission: ['ListCreamDropoffs'],
                    url: '/cream-dropoffs',
                },
                {
                    id: 'cream-route-session',
                    title: 'Cream Route Sessions',
                    type: 'item',
                    auth: ['admin', 'sub-admin', 'transpopnrt', 'sub-transport'],
                    icon: 'navigation',
                    url: '/cream-route-sessions',
                    permission: ['ListCreamRouteSessions'],
                },
            ],
        },
        {
            id: 'lab-reports-collapse',
            title: 'Lab',
            type: 'collapse',
            auth: ['admin', 'sub-admin'],
            icon: 'insert_chart',
            permission: ['ListLabReports'],
            requireAllPermissions: false,
            children: [
                {
                    id: 'lab-reports',
                    title: 'Lab Reports',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    url: '/lab-reports',
                    icon: 'show_chart',
                },
                {
                    id: 'barcode-input',
                    title: 'Barcode Input',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    url: '/barcode-input',
                    icon: 'input',
                },
                {
                    id: 'sample-barcode-batches',
                    title: 'Batches',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    url: '/sample-barcode-batches',
                    icon: 'assignments',
                },
                {
                    id: 'print-queue',
                    title: 'Print Queue',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    url: '/print-queue',
                    icon: 'input',
                },
            ],
        },
        {
            id: 'lab-reports',
            title: 'Lab Reports',
            type: 'item',
            auth: ['lab_provider'],
            url: '/lab-reports',
            icon: 'show_chart',
        },
        {
            id: 'lab-reports',
            title: 'Lab',
            type: 'item',
            auth: ['producer', 'sub-producer'],
            url: '/lab-reports',
            icon: 'insert_chart',
            permission: ['ListLabReports'],
        },
        {
            id: 'barcode-input',
            title: 'Barcode Input',
            type: 'item',
            auth: ['lab_provider'],
            url: '/barcode-input',
            icon: 'input',
        },
        {
            id: 'accounting-collapse',
            title: 'Accounting',
            type: 'collapse',
            auth: ['admin', 'sub-admin'],
            icon: 'show_chart',
            permission: [
                'ManageMerchandise',
                'ManageProducerAssignments',
                'ManageHaulerAssignments',
                'ManageAssignments',
                'ManageAssignees',
                'ManageMilkPrices',
                'ManageHaulingRates',
                'ManageDeferrals',
                'ManageSplitProducers',
                'ManagePooling',
                'ManageHaulingCharges',
                'ManageHaulingContracts',
                'ManageHaulerRates',
                'ManageProducerPayReports',
                'ManageHaulerPayReports',
                'ManageVendorPayReports',
                'ManagePaymentStatus',
            ],
            requireAllPermissions: false,
            children: [
                {
                    id: 'merchandise',
                    title: 'Merchandise',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageMerchandise'],
                    icon: 'store',
                    url: '/merchandise',
                },
                {
                    id: 'producer-assignments',
                    title: 'Producer Assignments',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageProducerAssignments'],
                    icon: 'assignments',
                    url: '/producer-assignments',
                },
                {
                    id: 'hauler-assignments',
                    title: 'Hauler Assignments',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageHaulerAssignments'],
                    icon: 'assignments',
                    url: '/hauler-assignments',
                },
                {
                    id: 'assignees',
                    title: 'Assignees',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    url: '/assignees',
                    icon: 'insert_chart',
                    permission: ['ManageAssignees'],
                },
                {
                    id: 'milk-prices',
                    title: 'Milk Prices',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageMilkPrices'],
                    url: '/milk-prices',
                    icon: 'insert_chart',
                },
                {
                    id: 'deferrals',
                    title: 'Deferrals',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageDeferrals'],
                    url: '/deferrals',
                    icon: 'near_me',
                },
                {
                    id: 'split-producers',
                    title: 'Split Producers',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageSplitProducers'],
                    url: '/split-producers',
                    icon: 'table_chart',
                },
                {
                    id: 'hauling-charges',
                    title: 'Hauling Charges',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageHaulingCharges'],
                    url: '/hauling-charges',
                    icon: 'local_shipping',
                },
                {
                    id: 'hauling-contracts',
                    title: 'Hauling Contracts',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageHaulingContracts'],
                    url: '/hauling-contracts',
                    icon: 'description',
                },
                {
                    id: 'hauler-rate',
                    title: 'Hauler Rates',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageHaulerRates'],
                    url: '/hauler-rate',
                    icon: 'local_shipping',
                },
                {
                    id: 'loan-management',
                    title: 'Loan Management',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageProducerPayReports'],
                    url: '/loan-management',
                    icon: 'account_balance',
                },
                {
                    id: 'producer-business-pay-reports',
                    title: 'Business Pay Reports',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    icon: 'assignment',
                    url: '/producer-business-pay-reports',
                    permission: ['ManageProducerPayReports'],
                    badge: {
                        title: 0,
                        bg: 'rgb(204,121,167)',
                        fg: '#000000',
                    },
                },
                {
                    id: 'monthly-pay-reports',
                    title: 'Producer Pay Reports',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    icon: 'assignment',
                    url: '/monthly-pay-reports',
                    permission: ['ManageProducerPayReports'],
                    badge: {
                        title: 0,
                        bg: 'rgb(204,121,167)',
                        fg: '#000000',
                    },
                },
                {
                    id: 'hauler-pay-reports',
                    title: 'Hauler Pay Reports',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageHaulerPayReports'],
                    url: '/hauler-pay-reports',
                    icon: 'assignment',
                },
                {
                    id: 'vendor-pay-reports',
                    title: 'Vendor Pay Reports',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManageVendorPayReports'],
                    url: '/vendor-pay-reports',
                    icon: 'assignment',
                },
                {
                    id: 'payment-status',
                    title: 'Payment Status',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    permission: ['ManagePaymentStatus'],
                    url: '/payment-status',
                    icon: 'payment',
                },
            ],
        },
        {
            id: 'equity',
            title: 'Equity',
            type: 'collapse',
            auth: ['admin', 'sub-admin'],
            icon: 'account_balance',
            permission: ['ManageEquity'],
            children: [
                {
                    id: 'equity-membership',
                    title: 'Membership',
                    type: 'item',
                    url: '/equity/membership',
                },
                {
                    id: 'equity-statements',
                    title: 'Statements',
                    type: 'item',
                    url: '/equity/statements',
                    badge: {
                        title: 0,
                        bg: 'rgb(238,119,51)',
                        fg: '#000000',
                    },
                },
            ],
        },
        {
            id: 'hauling-charges',
            title: 'Hauling Charges',
            type: 'item',
            auth: ['transport', 'sub-transport', 'producer', 'sub-producer'],
            url: '/hauling-charges',
            icon: 'local_shipping',
            permission: ['ViewProducerHaulingCharges'],
        },
        {
            id: 'equity-statements',
            title: 'Equity',
            auth: ['producer_business'],
            type: 'item',
            icon: 'account_balance',
            url: '/equity/statements',
            permission: ['ManageEquity'],
        },
        {
            id: 'hauling-contracts',
            title: 'Hauling Contracts',
            type: 'item',
            auth: ['transport', 'sub-transport', 'producer', 'sub-producer'],
            url: '/hauling-contracts',
            icon: 'description',
            permission: ['ViewProducerHaulingCharges'],
        },
        {
            id: 'hauler-pay-reports',
            title: 'Monthly Pay Reports',
            type: 'item',
            auth: ['transport', 'sub-transport'],
            url: '/hauler-pay-reports',
            icon: 'assignment',
            permission: ['ViewHaulerPayReport'],
            subPermissionOnly: true,
        },
        {
            id: 'upload-download',
            title: 'Upload / Download',
            type: 'item',
            auth: ['admin', 'sub-admin', 'producer', 'sub-producer', 'transport', 'sub-transport', 'processor', 'sub-processor'],
            url: '/upload-download',
            icon: 'near_me',
            permission: ['UploadDownload'],
        },
        {
            id: 'route-sessions',
            title: 'Route Sessions',
            type: 'item',
            auth: ['admin', 'sub-admin', 'processor', 'sub-processor'],
            icon: 'navigation',
            url: '/route-sessions',
            permission: ['ListRouteSessions'],
        },
        {
            id: 'manifest-search',
            title: 'Manifest',
            type: 'item',
            auth: ['admin', 'sub-admin'],
            icon: 'search',
            url: '/manifest-search',
            permission: ['ListManifests'],
            subPermissionOnly: true,
        },
        {
            id: 'scheduling-collapse',
            title: 'Scheduling',
            type: 'collapse',
            icon: 'date_range',
            auth: ['admin', 'sub-admin', 'processor', 'sub-processor', 'transport', 'sub-transport'],
            permissions: ['GetMilkScheduleDetails', 'GetFluidScheduleDetails'],
            subPermissionOnly: true,
            requireAllPermissions: false,
            children: [
                {
                    id: 'milk-schedule',
                    title: 'Milk',
                    type: 'item',
                    icon: 'date_range',
                    url: '/milk-schedule',
                    permission: ['GetMilkScheduleDetails'],
                    subPermissionOnly: true,
                },
                {
                    id: 'milk-schedule-list-view',
                    title: 'Schedule List View',
                    type: 'item',
                    icon: 'list',
                    url: '/milk-schedule-list-view',
                    split: 'use_list_view_schedule',
                },
                {
                    id: 'fluid-schedule',
                    title: 'Processed Milk',
                    type: 'item',
                    icon: 'date_range',
                    url: '/fluid-schedule',
                    permission: ['GetFluidScheduleDetails'],
                    subPermissionOnly: true,
                },
                {
                    id: 'scheduling-performance',
                    title: 'Performance',
                    type: 'item',
                    icon: 'bar_chart',
                    url: '/scheduling-performance',
                    auth: ['admin'],
                },
            ],
        },
        {
            id: 'monthly-pay-reports',
            title: 'Monthly Pay Reports',
            type: 'item',
            auth: ['producer', 'sub-producer', 'producer_business'],
            icon: 'assignment',
            url: '/monthly-pay-reports',
            permission: ['ListMonthlyReports'],
            badge: {
                title: 0,
                bg: 'rgb(204,121,167)',
                fg: '#000000',
            },
        },
        {
            id: 'embedded-reports',
            title: 'Reporting',
            type: 'item',
            auth: ['admin', 'sub-admin', 'producer', 'sub-producer', 'transport', 'sub-transport', 'processor', 'sub-processor', 'lab-provider'],
            icon: 'assignment',
            url: '/reporting',
        },
        {
            id: 'producer-penalty',
            title: 'Penalty Status',
            type: 'item',
            auth: ['producer', 'sub-producer'],
            icon: 'priority_high',
            url: '/penalty-status',
            permission: ['ListBactoAlert'],
        },
        {
            id: 'table-documentation',
            title: 'Documentation',
            type: 'item',
            auth: ['admin', 'sub-admin'],
            icon: 'description',
            url: '/documentation/tables',
            permission: ['ViewDocumentation'],
        },
        {
            id: 'file-share',
            title: 'Shared Files',
            type: 'collapse',
            auth: everyoneButGuest,
            icon: 'folder_shared',
            badge: {
                title: 0,
                bg: 'rgb(238,119,51)',
                fg: '#000000',
            },
            permission: ['ListSharedFiles'],
            children: [
                {
                    id: 'file-share-received',
                    title: 'Received',
                    type: 'item',
                    icon: 'folder_shared',
                    url: '/shared-files/received',
                    badge: {
                        title: 0,
                        bg: 'rgb(238,119,51)',
                        fg: '#000000',
                    },
                },
                {
                    id: 'file-share-shared',
                    title: 'Shared',
                    type: 'item',
                    icon: 'folder_shared',
                    url: '/shared-files/shared',
                    auth: ['admin', 'sub-admin', 'producer_business'],
                },
            ],
        },
    ],
};

const status = {
    id: 'status',
    title: 'Status',
    type: 'group',
    icon: 'apps',
    children: [
        {
            id: 'create',
            title: 'Creation Zone',
            type: 'collapse',
            auth: ['admin', 'sub-admin', 'producer', 'processor', 'transport', 'sub-transport'],
            icon: 'person_add',
            permission: [
                'ListAdmins',
                'ListSubAdmins',
                'ListProducers',
                'ListSubProducers',
                'ListProcessors',
                'ListSubProcessors',
                'ListHaulingCompanies',
                'ListSubHauling',
                'CreateAdmins',
                'CreateDrivers',
                'CreateProducers',
                'CreateProcessors',
                'CreateTrailers',
                'CreateHaulingCompanies',
                'CreateRoutes',
                'CreateSubAdmin',
                'CreateSubProducers',
                'CreateSchedulingRoutes',
                'CreateSubProcessors',
                'CreateSubHauling',
                'CreateMilkPrices',
                'CreateTrucks',
            ],
            requireAllPermissions: false,
            children: [
                {
                    id: 'create-admin',
                    title: 'Create Admin',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    icon: '',
                    permission: ['CreateAdmins', 'ListAdmins'],
                    requireAllPermissions: false,
                    url: '/create-admin',
                },
                {
                    id: 'create-sub-admin',
                    title: 'Create Sub Admin',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    icon: '',
                    url: '/create-sub-admin',
                    permission: ['CreateSubAdmins', 'ListSubAdmins'],
                    requireAllPermissions: false,
                },
                {
                    id: 'create-producer',
                    title: 'Create Producer',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    icon: '',
                    permission: ['ListProducers'],
                    url: '/create-producer',
                },
                {
                    id: 'create-sub-producer',
                    title: 'Create Sub Producer',
                    type: 'item',
                    auth: ['admin', 'sub-admin', 'producer', 'sub-producer'],
                    icon: '',
                    url: '/create-sub-producer/general',
                    permission: ['CreateSubProducers', 'ListSubProducers'],
                    requireAllPermissions: false,
                },
                {
                    id: 'create-driver',
                    title: 'Create Driver',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    icon: '',
                    url: '/create-driver',
                },
                {
                    id: 'create-processor',
                    title: 'Create Processor',
                    type: 'item',
                    auth: ['admin', 'sub-admin'],
                    icon: '',
                    permission: ['ListProcessors'],
                    url: '/create-processor',
                },
                {
                    id: 'create-sub-processor',
                    title: 'Create Sub Processor',
                    type: 'item',
                    auth: ['admin', 'sub-admin', 'processor', 'sub-processor'],
                    icon: '',
                    url: '/create-sub-processor',
                    permission: ['CreateSubProcessors', 'ListSubProcessors'],
                    requireAllPermissions: false,
                },
                {
                    id: 'create-hauling',
                    title: 'Create Hauling Company',
                    type: 'item',
                    auth: ['admin'],
                    icon: '',
                    permission: ['ListHaulingCompanies'],
                    url: '/create-hauling-company',
                },
                {
                    id: 'create-sub-hauling',
                    title: 'Create Sub Hauler',
                    type: 'item',
                    auth: ['admin', 'sub-admin', 'transport', 'sub-transport'],
                    icon: '',
                    url: '/create-sub-hauling',
                    permission: ['CreateSubHauling', 'ListSubHauling'],
                    requireAllPermissions: false,
                },
                {
                    id: 'create-trailer',
                    title: 'Create Trailer',
                    type: 'item',
                    auth: ['admin', 'transport', 'sub-transport'],
                    icon: '',
                    url: '/create-trailer',
                    permission: ['ListTrailers'],
                    subPermissionOnly: true,
                },
                {
                    id: 'create-route',
                    title: 'Create Route',
                    type: 'item',
                    auth: ['admin'],
                    icon: '',
                    url: '/create-route',
                    permission: ['CreateRoutes'],
                },
                {
                    id: 'create-schedule-route',
                    title: 'Create Route',
                    type: 'item',
                    auth: ['admin'],
                    icon: '',
                    url: '/create-schedule-route',
                    permission: ['CreateScheduleRoutes'],
                },
                {
                    id: 'create-producer-business',
                    title: 'Create Producer Business',
                    type: 'item',
                    auth: ['admin'],
                    icon: '',
                    url: '/create-producer-business',
                },
                {
                    id: 'add-sub-producer-accountant',
                    title: 'Add Accountant',
                    type: 'item',
                    icon: '',
                    url: '/create-sub-producer/accountant',
                    auth: ['producer'],
                    permission: ['CreateSubProducers'],
                },
                {
                    id: 'add-sub-producer-feed',
                    title: 'Add Feed Provider',
                    type: 'item',
                    icon: '',
                    url: '/create-sub-producer/feed',
                    auth: ['producer'],
                    permission: ['CreateSubProducers'],
                },
                {
                    id: 'add-sub-producer-nutritionist',
                    title: 'Add Nutritionist',
                    type: 'item',
                    icon: '',
                    url: '/create-sub-producer/nutritionist',
                    auth: ['producer'],
                    permission: ['CreateSubProducers'],
                },
                {
                    id: 'add-sub-producer-co-owner',
                    title: 'Add Co-owner',
                    type: 'item',
                    icon: '',
                    url: '/create-sub-producer/co-owner',
                    auth: ['producer'],
                    permission: ['CreateSubProducers'],
                },
            ],
        },
        {
            id: 'manage-sub-users-collapse',
            title: 'Manage Sub Users',
            type: 'collapse',
            auth: ['admin', 'sub-admin', 'producer', 'sub-producer', 'processor', 'sub-processor', 'transport', 'sub-transport'],
            icon: 'device_hub',
            permission: ['ListSubAdmins', 'ListSubProducers', 'ListSubProcessors', 'ListSubHauling'],
            requireAllPermissions: false,
            children: [
                {
                    id: 'manage-sub-users',
                    title: 'Sub Users',
                    type: 'item',
                    icon: '',
                    url: '/manage/sub-users',
                    auth: ['admin', 'sub-admin', 'producer', 'sub-producer', 'processor', 'sub-processor', 'transport', 'sub-transport'],
                },
            ],
        },
        {
            id: 'manage-linked-users',
            title: 'Manage Linked Users',
            type: 'item',
            auth: ['admin'],
            icon: 'device_hub',
            url: '/manage/link-users',
            permission: ['UpdateMultisiteAccess'],
        },
        {
            id: 'settings',
            title: 'Settings',
            type: 'collapse',
            auth: ['admin', 'producer', 'processor', 'transport', 'sub-transport', 'producer_business'],
            icon: 'settings',
            children: [
                {
                    id: 'manage-user-settings',
                    title: 'User Settings',
                    type: 'item',
                    auth: ['admin', 'producer', 'processor', 'producer_business'],
                    icon: 'account_circle',
                    url: '/user-settings',
                },
                {
                    id: 'manage-notifications-settings',
                    title: 'Notifications Settings',
                    type: 'item',
                    auth: ['admin', 'producer', 'processor', 'transport', 'sub-transport', 'producer_business'],
                    icon: 'notifications',
                    url: '/notifications-settings',
                },
            ],
        },
        {
            id: 'logout',
            title: 'Logout',
            type: 'item',
            icon: 'desktop_access_disabled',
            url: '/logout',
        },
    ],
};

export default { dashboard, pages, status };
