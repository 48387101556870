import React, { useState } from 'react';
import { Marker } from '@react-google-maps/api';
import { theme } from '../../../../styles/mm-theme-fuse';
import MapInfoWindow from './MapInfoWindow';

const MapPointMarker = ({ index, shape, handleMapMarkerLoad, color = '#00A2F5' }) => {
    const [selected, setSelected] = useState(false);

    const isRouteBookendPoint = shape.event_type === 'StartedRoute' || shape.event_type === 'EndedRoute';
    const calculateColor = () => {
        if (shape.event_type === 'StartedRoute') return theme.palette.success.main;
        if (shape.event_type === 'EndedRoute') return theme.palette.error.main;
        return color;
    };
    const calculateScale = () => {
        if (selected) return 10;
        if (isRouteBookendPoint) return 6;
        return 4;
    };

    const circleIcon = {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: calculateColor(),
        fillOpacity: 1,
        scale: calculateScale(),
        strokeWeight: 0,
    };

    const handleSelect = () => {
        setSelected(true);
    };

    const handleDeselect = () => {
        setSelected(false);
    };

    return (
        <>
            <Marker
                key={shape._id}
                position={{ lat: shape.location.coordinates[1], lng: shape.location.coordinates[0] }}
                onMouseOver={handleSelect}
                onMouseOut={handleDeselect}
                onDblClick={handleSelect}
                icon={circleIcon}
                onLoad={(marker) => handleMapMarkerLoad(marker, shape)}
                zIndex={isRouteBookendPoint ? 1 : 0}
                // wrap
            />
            {selected && <MapInfoWindow shape={shape} onClose={handleDeselect} />}
        </>
    );
};

export default React.memo(MapPointMarker);
