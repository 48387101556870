import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showMessage, addMilkPrices, getMilkPrices } from 'app/store/actions';
import { LoadingDialog } from 'app/custom-widgets';
import _ from 'lodash';
import MilkPriceFormFederalOrderTabs from './MilkPriceFormFederalOrderTabs';
import { regionFederalOrderConfig, globalFederalOrderFields, defaultMilkPrices } from './MilkPriceFormConfig';
import { getUserRegion } from '../../../../utils';

export const federalOrderMilkPriceFormId = 'FederalOrderMilkPriceForm';

const FederalOrderMilkPriceForm = ({ startDate, selectedDate }) => {
    const dispatch = useDispatch();
    const [savingData, setSavingData] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);
    const region = getUserRegion();

    const reactHookFormMethods = useForm({
        defaultValues: defaultMilkPrices[region],
    });

    const { handleSubmit, errors, control, setValue, reset, getValues } = reactHookFormMethods;

    useEffect(() => {
        dispatch(getMilkPrices(selectedDate))
            .then((value) => {
                const reportData = value?.payload?.prices?.report_data;
                if (!_.isEmpty(reportData?.milk_prices) && !_.isEmpty(reportData?.advance_milk_prices)) {
                    reset({ ...reportData });
                } else {
                    reset(defaultMilkPrices[region]);
                }
            })
            .catch((err) => {
                dispatch(showMessage({ message: err.message }));
            });
    }, [selectedDate]);

    const setFieldValue = (fieldName, payGroups) => {
        const splitFieldName = fieldName.split('.');
        payGroups.forEach((payGroup) => {
            setValue(`${splitFieldName[0]}.pay_group_${payGroup}.${splitFieldName[1]}`, getValues(fieldName));
        });
    };

    const setGlobalMilkPriceToPayGroups = () => {
        globalFederalOrderFields.forEach(({ federalOrder, globalFields, regions }) => {
            if (regions.includes(region)) {
                const regionHasFederalOrder = regionFederalOrderConfig[region].find((regionConfig) => regionConfig.federalOrder === federalOrder);
                globalFields.forEach((globalFieldName) => {
                    const noFieldNameOverwrite = regionHasFederalOrder?.overrideFieldNames?.[globalFieldName] == null;
                    if (regionHasFederalOrder != null && noFieldNameOverwrite) {
                        setFieldValue(globalFieldName, regionHasFederalOrder?.payGroups);
                    }
                });
            }
        });
    };

    const setRegionalMilkPriceToPayGroups = () => {
        regionFederalOrderConfig[region].forEach(({ regionalMilkPriceFields, payGroups }) => {
            if (!regionalMilkPriceFields) return;
            regionalMilkPriceFields.forEach((fieldName) => {
                setFieldValue(fieldName, payGroups);
            });
        });
    };

    const post = (submitModel) => {
        setSavingData(true);
        dispatch(addMilkPrices(submitModel, selectedDate))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Updated Milk Prices.' }));
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                dispatch(showMessage({ message: err.message }));
            })
            .finally(() => {
                setSavingData(false);
            });
    };

    const onSubmit = () => {
        setGlobalMilkPriceToPayGroups();
        setRegionalMilkPriceToPayGroups();
        post(getValues());
    };

    const render = () => {
        return (
            <FormProvider {...reactHookFormMethods}>
                <LoadingDialog showDialog={savingData} />
                <form id={federalOrderMilkPriceFormId} onSubmit={handleSubmit(onSubmit, errors)}>
                    <MilkPriceFormFederalOrderTabs control={control} selectedTab={selectedTab} setSelectedTab={setSelectedTab} startDate={startDate} region={region} reactHookFormMethods={reactHookFormMethods} />
                </form>
            </FormProvider>
        );
    };
    return <>{render()}</>;
};

export default withRouter(FederalOrderMilkPriceForm);
