import axios from 'axios';
import { getSendScheduleQuery } from './queries';

const sendScheduleItems = async (model) => {
    try {
        const query = getSendScheduleQuery(model);
        const request = axios.put(query);
        const { data } = await request;
        return { items: data };
    } catch (error) {
        throw new Error('Unable to send schedule items');
    }
};

export default sendScheduleItems;
