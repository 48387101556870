import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { batchQuery, getDropoffsQuery, getLabReportsQuery, getPickupsQuery, getProducersQuery } from './queries';
import { getTwoStringOfMonth, numberFormat } from '../../utils';

const downloadCDC = (month, year) =>
    new Promise((resolve) => {
        let CDCData = '';
        const start = moment(`${year}-${getTwoStringOfMonth(month)}-01`)
            .startOf('month')
            .format();
        const end = moment(`${year}-${getTwoStringOfMonth(month)}-01`)
            .endOf('month')
            .format();

        const producersQuery = getProducersQuery({ filterDeleted: true });
        const producersRequest = axios.get(producersQuery);

        producersRequest.then((producersResponse) => {
            const producers = producersResponse.data;
            const producerIds = _.uniq(_.map(producers, 'id'));

            const pickupsQuery = getPickupsQuery({
                fields: ['producer_id', 'created_at', 'temperature', 'tank_number', 'compartment', 'route_session_id', 'volume', 'volume_a', 'volume_b', 'volume_c'],
                start,
                end,
                filterDeleted: true,
                filterType: 'milk',
            });
            const labReportQuery = batchQuery(
                getLabReportsQuery,
                {
                    fields: ['producer_id', 'fat', 'LOS', 'somatic_cell_count', 'protein', 'bacto_scan'],
                    start,
                    end,
                    producerIds,
                    filterDeleted: true,
                    filterOutliers: true,
                },
                'producerIds'
            );

            const pickupRequest = axios.get(pickupsQuery);
            const labRequest = _.map(labReportQuery, (q) => axios.get(q));

            axios.all([pickupRequest, ...labRequest]).then(
                axios.spread((...responses) => {
                    const pickups = responses[0].data;
                    const labs = _.reduce(responses.slice(1), (acc, val) => [...acc, ...val.data], []);

                    const routeSessionIds = _.uniq(_.map(pickups, 'route_session_id'));

                    const dropoffQuery = batchQuery(
                        getDropoffsQuery,
                        {
                            routeSessionIds,
                            fields: ['producer_id', 'created_at'],
                            filterDeleted: true,
                            filterType: 'milk',
                        },
                        'routeSessionIds'
                    );

                    const dropoffRequest = _.map(dropoffQuery, (q) => axios.get(q));

                    axios.all([...dropoffRequest]).then(
                        axios.spread((...dropoffResponse) => {
                            const dropoffs = _.reduce(dropoffResponse, (acc, val) => [...acc, ...val.data], []);

                            const routeSessionGroupedDropoffs = _.groupBy(dropoffs, (v) => v.route_session_id);
                            const producerGroupedLabs = _.groupBy(labs, (v) => v.producer_id);
                            const producerGroupedPickups = _.groupBy(
                                _.sortBy(pickups, (v) => v.created_at),
                                (v) => v.producer_id
                            );

                            // console.log(producerGroupedPickups);
                            _.forEach(producerIds, (producerId) => {
                                const producer = _.find(producers, { id: producerId });
                                const row = {};

                                const volumeProduced = _.sumBy(producerGroupedPickups[producerId], 'volume');

                                row.board_registration_number = producer.CMTNumber.padStart(6, '0');
                                row.name = producer.name.length > 30 ? producer.name.substring(0, 30) : producer.name.padEnd(30);
                                row.butterfat_production = numberFormat(volumeProduced * (_.meanBy(producerGroupedLabs[producerId] ? producerGroupedLabs[producerId].filter((v) => !!v.fat) : 0, 'fat') / 100), 2, '.', '').padStart(8); // Average * Lites
                                row.litres = numberFormat(volumeProduced, 0, '', '').padStart(6); // Litres not right
                                row['.00'] = '.00';
                                row.levy = '.00';
                                row.protein_production = numberFormat(volumeProduced * (_.meanBy(producerGroupedLabs[producerId] ? producerGroupedLabs[producerId].filter((v) => !!v.protein) : 0, 'protein') / 100), 2, '.', '').padStart(8); // Average * Lites
                                row.other_solids_production = numberFormat(volumeProduced * (_.meanBy(producerGroupedLabs[producerId] ? producerGroupedLabs[producerId].filter((v) => !!v.LOS) : 0, 'LOS') / 100), 2, '.', '').padStart(8); // Average * Lites
                                row.contract_litres = '0';
                                row.contract_butterfat = '.00';
                                row.contract_protein = '.00';
                                row.contract_other_solids = '.00';
                                row.date_of_last_dropoff = !!routeSessionGroupedDropoffs[producerId] ? moment(routeSessionGroupedDropoffs[producerId][0].created_at).format('YMMDD') : moment().format('YMMDD');
                                row.microsoft_date = moment().diff(moment('1899-12-31'), 'days'); // microsoft access stores dates as days since December 31 1899

                                // the following spaces are intentional and should not be changed
                                CDCData = CDCData.concat(`${row.board_registration_number}    ${row.name}${row.butterfat_production}${row.litres}     ${row['.00']}     ${row.levy}1011-3${row.protein_production}${row.other_solids_production}     0     .00     .00     .00  ${row.date_of_last_dropoff} ${row.microsoft_date}\n`);
                            });

                            resolve(CDCData);
                        })
                    );
                })
            );
        });
    });

export default downloadCDC;
