import axios from 'axios';
import _ from 'lodash';
import { createWashQuery } from './queries';

const addWash = (model) =>
    new Promise((resolve, reject) => {
        const query = createWashQuery({ model });
        const request = axios.post(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to add wash'));
            });
    });

export default addWash;
