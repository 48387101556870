import * as Actions from '../../actions';

const initialState = { data: null };

const singleProducerPickupsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_PRODUCER_PRODUCTION_DETAILS:
            return { ...state, data: { ...action.payload } };
        case Actions.CLEAR_PRODUCER_PRODUCTION_DETAILS:
            return initialState;
        default:
            return state;
    }
};

export default singleProducerPickupsReducer;
