import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import MomentUtils from '@date-io/moment';
import { Button, Grid, Box, makeStyles, debounce, Typography } from '@material-ui/core';
import { showMessage, addDropoffSample, editDropoffSample } from 'app/store/actions';
import _ from 'lodash';
import ReactHookFormInput from '../form-components/ReactHookFormInput';
import ReactHookFormSearchableSelect from '../form-components/ReactHookFormSearchableSelect';
import { getDropoffsQuery } from '../../repository/queries';
import axios from 'axios';
import { buildExpressQuery } from '../../repository/queries/builders';
import generateErrorMessage from '../../repository/utils/generateErrorMessage';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import { generateTestId, getUserRegion } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        margin: 'auto',
        width: '100%',
        height: '100%',
        '& .MuiAutocomplete-root .MuiFormControl-root': {
            marginTop: '8px',
        },
    },
    dropdown: {
        marginTop: '-16px',
    },
}));

const DropoffSampleForm = ({ editData, processors, dropoff }) => {
    const region = getUserRegion();
    const [dropoffs, setDropoffs] = useState([]);
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const reactHookFormMethods = useForm({
        mode: 'all',
        defaultValues: {
            ...editData,
            date: editData?.date || moment().toDate(),
            processor: _.find(processors, (z) => z.value === editData?.processor_id),
            dropoff_id: dropoff?.[0],
        },
    });

    const { handleSubmit, register, watch, errors, control, setValue } = reactHookFormMethods;

    const processorWatch = watch('processor');
    const dateWatch = watch('date');
    const dropoffWatch = watch('dropoff_id');

    const getDropoffs = async () => {
        try {
            const { data: dropoffData } = await axios.get(
                getDropoffsQuery({
                    fields: ['id', 'processor_id', 'created_at', 'route_session_id', 'sample_barcodes', 'volume'],
                    populate: { route_session_id: ['BOL'] },
                    queryBuilder: buildExpressQuery,
                    start: moment(dateWatch)?.subtract(7, 'days').startOf('day').format(),
                    end: moment(dateWatch).endOf('day').format(),
                    processorId: processorWatch.value,
                })
            );
            return dropoffData;
        } catch (err) {
            return generateErrorMessage(err);
        }
    };

    const transformDropoffs = (details) =>
        details?.map((value) => ({
            ...value,
            value: value.id,
            name: `LT#: ${value?.route_session_id?.BOL} - ${value?.sample_barcodes?.[0] || ''}  - ${value?.volume}lbs - ${moment(value.created_at)?.format('MMM DD YYYY')}`,
            sample_barcodes: value?.sample_barcodes?.[0] || '',
        }));

    useEffect(() => {
        if (dateWatch && processorWatch) {
            getDropoffs(dateWatch).then((results) => {
                setDropoffs(transformDropoffs(results));
            });
        }
    }, [dateWatch, processorWatch]);

    useEffect(() => {
        if (processorWatch) {
            setValue('processor_id', processorWatch?.value);
            setValue('processor.id', processorWatch?.value);
        }
    }, [processorWatch]);

    useEffect(() => {
        if (dropoffWatch) {
            if (dropoffWatch.sample_barcodes) setValue('sample_barcodes', dropoffWatch.sample_barcodes);
            setValue('BOL', dropoffWatch.route_session_id.BOL);
            setValue('date', moment(dropoffWatch.created_at).startOf('day').format());
            setValue('processor_id', dropoffWatch.processor_id);
            setValue('processor.id', dropoffWatch.processor_id);
        }
    }, [dropoffWatch]);

    const post = (submitModel) => {
        dispatch(addDropoffSample(submitModel))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Added Dropoff Sample' }));
                history.replace({ pathname: '/dropoff-samples' });
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                dispatch(showMessage({ message: 'Could not add Dropoff Sample' }));
            });
    };

    const put = (submitModel) => {
        const { _id } = editData;
        dispatch(editDropoffSample(_id, submitModel))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Edited Dropoff Sample' }));
                history.replace({ pathname: '/dropoff-samples' });
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                dispatch(showMessage({ message: 'Could not edit Dropoff Sample' }));
            });
    };

    const onSubmit = (model) => {
        const submitModel = { ...model };

        submitModel.dropoff_id = submitModel.dropoff_id?._id;
        submitModel.sample_barcodes = submitModel.sample_barcodes ? [submitModel.sample_barcodes] : [];
        submitModel.date = submitModel.created_at || submitModel.date;
        submitModel.freeze_point = submitModel.freeze_point ? parseFloat(submitModel.freeze_point) : undefined;

        delete submitModel._id;
        delete submitModel.__t;
        delete submitModel.tableData;
        delete submitModel.BOL;
        delete submitModel.matched;
        delete submitModel.index;
        delete submitModel.hauler;
        delete submitModel.seq_number;

        if (editData) {
            put(submitModel);
        } else {
            post(submitModel);
        }
    };

    const debounceSubmit = useCallback(debounce(onSubmit, 500), []);

    const render = () => {
        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className={classes.root}>
                        <FormProvider {...reactHookFormMethods}>
                            <form onSubmit={handleSubmit(debounceSubmit, errors)}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography className={classes.header} variant="h5">
                                            {['PRAIRIE'].includes(region) ? 'Load Sample Info' : 'Dropoff Sample Info'}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6} md={6}>
                                        <MuiPickersUtilsProvider utils={MomentUtils}>
                                            <Controller
                                                name="date"
                                                control={control}
                                                render={({ field: { onChange, value } }) => {
                                                    return <DatePicker data-testid={generateTestId('Sample Date', 'date-picker')} fullWidth onChange={onChange} format="MMMM Do, YYYY" value={value} label="Date" required />;
                                                }}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>

                                    <Grid item xs={6} className={classes.dropdown}>
                                        <ReactHookFormSearchableSelect label={`${localization.general.processor_singular}`} name={'processor'} options={processors} fullWidth disabled={dropoffWatch} />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <ReactHookFormSearchableSelect options={dropoffs} name={'dropoff_id'} label="Dropoffs" disabled={!dateWatch || !processorWatch} />
                                    </Grid>

                                    {dropoffWatch && !dropoffWatch.sample_barcodes && (
                                        <Grid item xs={12}>
                                            <Typography className={classes.header} variant="h10">
                                                {'Note: Load sample barcode must be updated on selected Dropoff'}
                                            </Typography>
                                        </Grid>
                                    )}

                                    <Grid item xs={6}>
                                        <ReactHookFormInput {...register('sample_barcodes')} label={'Sample Barcode'} name={'sample_barcodes'} disabled fullWidth />
                                    </Grid>

                                    <Grid item xs={6}>
                                        <ReactHookFormInput label={'LT Number'} name={'BOL'} fullWidth disabled />
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography className={classes.header} variant="h5">
                                            {'Sample Data'}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <ReactHookFormInput label={'Fat'} name={'fat'} type="number" fullWidth />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <ReactHookFormInput label={'Protein'} name={'protein'} type="number" fullWidth />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ReactHookFormInput label={'Lactose'} name={'lactose'} type="number" fullWidth />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <ReactHookFormInput label={'Total Solids'} name={'total_solids'} type="number" fullWidth />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <ReactHookFormInput label={'Other Solids'} name={'other_solids'} type="number" fullWidth />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ReactHookFormInput label={'SNF'} name={'solids_not_fat'} type="number" fullWidth />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <ReactHookFormInput label={'SCC'} name={'somatic_cell_count'} type="number" fullWidth />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <ReactHookFormInput label={'Freeze Point'} name={'freeze_point'} type="number" fullWidth />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <ReactHookFormInput label={'MUN'} name={'milk_urea_nitrogen'} type="number" fullWidth />
                                    </Grid>
                                    {!['PRAIRIE'].includes(region) && (
                                        <Grid item xs={4}>
                                            <ReactHookFormInput label={'IBC'} name={'bacto_scan'} type="number" fullWidth />
                                        </Grid>
                                    )}
                                    <Grid item xs={4}>
                                        <ReactHookFormInput label={'SPC'} name={'standard_plate_count'} type="number" fullWidth />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <ReactHookFormInput label={'PI'} name={'preliminary_incubation'} type="number" fullWidth />
                                    </Grid>
                                </Grid>
                                <Box display="flex" justifyContent="center" gridGap="30px" paddingTop={'15px'}>
                                    <Button variant="contained" color="primary" margin="normal" className="mx-auto my-16" sx={{ paddingTop: 2 }} type="submit">
                                        Save
                                    </Button>
                                </Box>
                            </form>
                        </FormProvider>
                    </div>
                )}
            </LocalizationConsumer>
        );
    };
    return <>{render()}</>;
};

export default withRouter(DropoffSampleForm);
