import _ from '@lodash';
import axios from 'axios';
import { batchQuery } from '../queries';

/**
 *
 * Handles the processing steps involved between defining a batch query and
 * recieving an array of data from said batch query.
 *
 * Abstracts the query > request > response > aggregation process
 *
 *
 * @param {function} queryFn query function to be batched
 * @param {object} params query params
 * @param {string} batchField field to batch on
 * @param {number} batchSize size of batch
 * @returns {array} array of aggregated data from batched queries
 */
const getBatchedQueryData = async (queryFn, params, batchField, batchSize = 75) => {
    const query = batchQuery(queryFn, params, batchField, batchSize);
    const request = _.map(query, (q) => axios.get(q));
    const response = await axios.all(request);
    const data = _.reduce(response, (acc, val) => [...acc, ...val.data], []);
    return data;
};

export default getBatchedQueryData;
