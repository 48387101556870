import axios from 'axios';
import { bulkEditScheduleSupplyItemQuery } from './queries';

const bulkEditScheduleSupplyItem = async (model, keys, recur) => {
    try {
        const query = bulkEditScheduleSupplyItemQuery({ keys, model });
        const request = axios.put(query.url, { ...query.body });
        const { data } = await request;
        return { ids: data };
    } catch {
        throw new Error('Unable to edit schedule item');
    }
};

export default bulkEditScheduleSupplyItem;
