import React from 'react';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

/**
 *  Select a date
 */
const PureDatePicker = ({ value, minDate, onChange, onPrev, onNext, open, onOpen, onClose }) => (
    <MuiPickersUtilsProvider utils={MomentUtils}>
        {onPrev && (
            <Button onClick={onPrev}>
                <Icon>navigate_before</Icon>
            </Button>
        )}
        <MuiDatePicker
            variant="inline"
            openTo="month"
            label="Select Date"
            views={['year', 'month']}
            value={value}
            minDate={minDate}
            maxDate={moment()}
            onChange={onChange}
            open={open}
            onOpen={onOpen}
            onClose={onClose}
            leftArrowButtonProps={{
                classes: {
                    root: 'fs-ignore-rage-clicks',
                },
            }}
            rightArrowButtonProps={{
                classes: {
                    root: 'fs-ignore-rage-clicks',
                },
            }}
        />
        {onNext && (
            <Button onClick={onNext}>
                <Icon>navigate_next</Icon>
            </Button>
        )}
    </MuiPickersUtilsProvider>
);

// eslint-disable-next-line object-curly-newline
PureDatePicker.propTypes = {
    /**
    date as a moment object
   */
    value: MomentPropTypes.momentObj.isRequired,
    /**
     starting date of the datepicker
     */
    minDate: MomentPropTypes.momentObj.isRequired,
    /**
    updates the date to the value selected
   */
    onChange: PropTypes.func.isRequired,
    /**
    sets the date picker to closed
   */
    onClose: PropTypes.func.isRequired,
    /**
   updates the date to the next month
  */
    onNext: PropTypes.func.isRequired,
    /**
   sets the date picker to open
  */
    onOpen: PropTypes.func.isRequired,
    /**
   updates the date to the previous month
  */
    onPrev: PropTypes.func.isRequired,
    /**
   boolean that determines whether the datepicker is opened or closed
  */
    open: PropTypes.bool.isRequired,
};

PureDatePicker.defaultProps = {};

export default PureDatePicker;
