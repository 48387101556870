/* eslint-disable react/prop-types, react/jsx-handler-names */
import React, { Component } from 'react';
import Select from 'react-select';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from 'app/auth/store/actions';
import * as Actions from 'app/store/actions';
import jwtService from 'app/services/jwtService';
import { getInitialRoute } from '../../@fuse/components/FuseAuthorization/FuseAuthorization';
import { Button } from '@mui/material';

const ButtonSx = {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 16,
    width: 255,
};

const BackButton = ({ show, onClick }) => {
    if (!show) return null;
    return (
        <div style={ButtonSx}>
            <Button size="small" sx={{ width: '100%', backgroundColor: (theme) => theme.palette?.secondary?.light }} onClick={onClick} variant="contained">
                Return To Main
            </Button>
        </div>
    );
};
class ProducerSelector extends Component {
    state = { selectedValue: null };

    componentDidMount() {
        const { initialValue } = this.props;

        this.setState({ selectedValue: initialValue });
    }

    handleChange =
        ({ setUserData, history }) =>
        async (value, event) => {
            if (event.action === 'clear' || !value.value) {
                const user = await jwtService.revertImpersonatedLogin();
                setUserData(user);
                history.push({ pathname: getInitialRoute(user.data) });
                window.location.reload();
            }
            if (event.action === 'select-option') {
                const user = await jwtService.signInAsImpersonatedUser(value.value);
                setUserData(user);
                history.push({ pathname: user.data.name === 'DFNS' ? '/dropoffs' : getInitialRoute(user.data) });
                window.location.reload();
            }
        };

    render() {
        const { classes, listOfValues, show } = this.props;
        const { selectedValue } = this.state;

        if (!show) return null;

        const optionGroups = [
            {
                label: 'Main',
                options: [...(selectedValue ? [{ label: 'Return to Main', value: null }] : [])],
            },
            {
                label: 'Producers',
                options: [],
            },
            {
                label: 'Processors',
                options: [],
            },
            {
                label: 'Haulers',
                options: [],
            },
            {
                label: 'Admins',
                options: [],
            },
            {
                label: 'Other',
                options: [],
            },
            {
                label: 'Producer Business',
                options: [],
            },
        ];

        const sortedProducerList = listOfValues.sort((a, b) => a.label.localeCompare(b.label));

        sortedProducerList.forEach((value) => {
            if (value.role.includes('producer_business')) {
                optionGroups[6].options.push(value);
            } else if (value.role.includes('producer')) {
                optionGroups[1].options.push(value);
            } else if (value.role.includes('processor')) {
                optionGroups[2].options.push(value);
            } else if (value.role.includes('transport')) {
                optionGroups[3].options.push(value);
            } else if (value.role.includes('admin')) {
                optionGroups[4].options.push(value);
            } else {
                optionGroups[5].options.push(value);
            }
        });

        const customStyles = {
            container: (provided) => ({
                ...provided,
                paddingLeft: 20,
                paddingRight: 20,
                paddingTop: '1.2rem',
                width: 255,
                fontSize: '1.2rem',
            }),
            option: (provided, state) => ({
                ...provided,
                color: 'black',
                overflow: 'hidden',
            }),
        };

        return (
            <div className={classes.root}>
                <NoSsr>
                    <Select
                        styles={customStyles}
                        options={optionGroups}
                        variant="standard"
                        value={selectedValue}
                        onChange={this.handleChange(this.props)}
                        placeholder="Change Account..."
                        isClearable // Wrap
                    />
                    <BackButton show={selectedValue != null} onClick={() => this.handleChange(this.props)(null, { action: 'clear' })} />
                </NoSsr>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            setUserData: userActions.setUserData,
            showMessage: Actions.showMessage,
            hideMessage: Actions.hideMessage,
        },
        dispatch
    );
}

export default withRouter(withStyles(null, { withTheme: true })(connect(null, mapDispatchToProps)(ProducerSelector)));
