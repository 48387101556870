import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import * as FuseActions from 'app/store/actions';
import { bindActionCreators } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import MMSplashScreen from '../../custom-components/MMSplashScreen';
import { getAPIHost } from '../../../utils';

const styles = {
    dialogPaper: {
        minWidth: '70vw',
        maxWidth: '70vw',
        minHeight: '90vh',
        maxHeight: '90vh',
    },
};

class MonthlyReportDialog extends React.Component {
    state = {
        fileAddress: '',
        model: {},
        selectedData: null,
        open: false,
    };

    componentDidMount() {
        const { selected } = this.props;

        this.setState({ model: JSON.parse(selected.model_info), selectedData: selected });
    }

    handleClickOpen = () => {
        const { model, selectedData } = this.state;
        const { type } = this.props;

        if (type === 'Decision Producer') {
            axios
                .put(`${getAPIHost()}/upload-lease/initial`, {
                    ...model,
                    type: 'Replacing',
                    oldAddress: selectedData.file_address,
                    leaseId: selectedData.id,
                })
                .then((response) => {
                    this.setState({
                        open: true,
                        fileAddress: response.selected.generatedName,
                        model,
                    });
                })
                .catch((err) => {});
        } else if (type === 'Decision Admin') {
            model.leasee_signature = model.leasee_farm_name;
            model.lease_approval_day = moment().format('Do');
            model.lease_approval_month = moment().format('MMMM');
            model.lease_approval_year = moment().format('YY');
            model.lease_approval_signature = 'John Moores';
            axios
                .put(`${getAPIHost()}/upload-lease/initial`, {
                    ...model,
                    type: 'Finalize',
                    oldAddress: selectedData.file_address,
                    leaseId: selectedData.id,
                })
                .then((response) => {
                    this.setState({
                        open: true,
                        fileAddress: response.selected.generatedName,
                        model,
                    });
                })
                .catch((err) => {
                    // console.warn('ERROR: ', err);
                });
        } else {
            this.setState({ open: true, fileAddress: selectedData.file_address });
        }
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleDecision = (address, decision, id, { showMessage }) => {
        axios
            .put(`${getAPIHost()}/upload-lease/decision`, {
                leaseId: id,
                file_address: address,
                decision,
            })
            .then((response) => {
                showMessage({ message: 'Status Successfully Updated.' });
                this.handleClose();
                window.location.reload();
            })
            .catch((err) => {
                // console.warn('ERROR: ', err);
            });
    };

    render() {
        const { classes, title, type } = this.props;
        const { open, selectedData, model, fileAddress } = this.state;

        if (!selectedData || !model) {
            return <MMSplashScreen />;
        }

        return (
            <div>
                <Button variant="contained" color="primary" className="my-16" aria-label={selectedData.status.includes('Rejected') ? 'Not Available' : title} disabled={selectedData.status.includes('Rejected')} onClick={this.handleClickOpen}>
                    {selectedData.status.includes('Rejected') ? 'Not Available' : title}
                </Button>
                <Dialog classes={{ paper: classes.dialogPaper }} open={open} onClose={this.handleClose} fullWidth maxWidth="sm" aria-labelledby="dialog-title">
                    <DialogTitle id="dialog-title">Agreement</DialogTitle>
                    <DialogContent id="dialog-content">
                        {!!fileAddress && (
                            <iframe
                                className="iframe"
                                name="pdf"
                                id="reportLoader"
                                title="pdf"
                                src={`https://drive.google.com/viewerng/viewer?url=${process.env.REACT_APP_BUCKET_LOCATION}/Leases/${fileAddress}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                                style={{
                                    minWidth: '100%',
                                    maxWidth: '100%',
                                    minHeight: '70vh',
                                    maxHeight: '70vh',
                                    border: 'none',
                                }}
                            />
                        )}
                    </DialogContent>
                    {(type === 'Decision Producer' || type === 'Decision Admin') && (
                        <DialogActions>
                            <Button onClick={() => this.handleDecision(fileAddress, 'Reject', selectedData.id, { ...this.props, ...this.state })} color="primary">
                                Reject
                            </Button>
                            <Button onClick={() => this.handleDecision(fileAddress, 'Accept', selectedData.id, { ...this.props, ...this.state })} variant="contained" color="secondary">
                                Accept
                            </Button>
                        </DialogActions>
                    )}
                    {!(type === 'Decision Producer' || type === 'Decision Admin') && (
                        <DialogActions>
                            <Button onClick={this.handleClose} color="primary">
                                Cancel
                            </Button>
                        </DialogActions>
                    )}
                </Dialog>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: FuseActions.showMessage }, dispatch);
}

export default withRouter(withStyles(styles)(connect(null, mapDispatchToProps)(MonthlyReportDialog)));
