import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Divider, Typography } from '@material-ui/core';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles/index';
import _ from 'lodash';
import * as FuseActions from 'app/store/actions';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl/index';
import FormLabel from '@material-ui/core/FormLabel/index';
import FormGroup from '@material-ui/core/FormGroup/index';
import FormHelperText from '@material-ui/core/FormHelperText/index';
import FormControlLabel from '@material-ui/core/FormControlLabel/index';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import Checkbox from '@material-ui/core/Checkbox/index';
import MMSplashScreen from '../MMSplashScreen';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import LoadingDialog from '../../custom-widgets/dialogs/LoadingDialog';
import { shareFile } from '../../repository';
import { LocalizationConsumer } from '../../localization/LocalizationContext';

const styles = (theme) => ({
    root: { display: 'flex' },
    formControl: { margin: theme.spacing(3) },
    nested: { paddingLeft: theme.spacing(4) },
    list: { width: '100%', backgroundColor: theme.palette.background.paper },
});

const Dropdown = ({ classes, disabled, users, listItemText, openList, allChecked, handleOpenList, handleAllChange, handleChange, hidden }) => {
    const [text, setText] = useState('');

    const handleTextChange = (event) => {
        setText(event.target.value);
    };

    const handleTextClear = (event) => {
        setText('');
    };

    // eslint-disable-next-line global-require
    const uuidv4 = require('uuid/v4');
    if (hidden) {
        return (
            /* eslint-disable-next-line react/jsx-fragments */
            <React.Fragment />
        );
    }
    return (
        /* eslint-disable-next-line react/jsx-fragments */
        <React.Fragment>
            <ListItem button onClick={handleOpenList}>
                <ListItemText primary={listItemText} />
                {openList ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openList} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItem className={classes.nested}>
                        <FormControlLabel
                            control={
                                <TextField
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ fill: 'gray' }} />
                                                &nbsp;
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={handleTextClear}>
                                                    <ClearIcon style={{ fill: 'darkred' }} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={text}
                                    onChange={handleTextChange}
                                />
                            }
                        />
                    </ListItem>
                    <ListItem className={classes.nested}>
                        <FormControlLabel control={<Checkbox checked={allChecked.checked} disabled={disabled} onChange={handleAllChange(!allChecked)} value="allChecked" />} label={allChecked.label} />
                    </ListItem>
                    {users
                        .filter((value) => value.label.toLowerCase().includes(text.toLowerCase()))
                        .map((value) => (
                            <ListItem className={classes.nested}>
                                <FormControlLabel key={uuidv4()} control={<Checkbox checked={value.checked} onChange={handleChange(value.value)} value={value.value} />} label={value.label} />
                            </ListItem>
                        ))}
                </List>
            </Collapse>
        </React.Fragment>
    );
};

class ShareAFileForm extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
    }

    state = {
        canSubmit: false,
        users: {},
        allChecked: {
            checked: false,
            label: 'Select All',
            value: null,
        },
        allAdminChecked: {
            checked: false,
            label: 'Select All Admins',
            value: null,
        },
        allProducerChecked: {
            checked: false,
            label: 'Select All Producers',
            value: null,
        },
        allProcessorChecked: {
            checked: false,
            label: 'Select All Processors',
            value: null,
        },
        allHaulingChecked: {
            checked: false,
            label: 'Select All Hauling Companies',
            value: null,
        },
        allDriverChecked: {
            checked: false,
            label: 'Select All Drivers',
            value: null,
        },
        allSubAdminChecked: {
            checked: false,
            label: 'Select All Sub Admins',
            value: null,
        },
        allSubProducerChecked: {
            checked: false,
            label: 'Select All Sub Producers',
            value: null,
        },
        allSubProcessorChecked: {
            checked: false,
            label: 'Select All Sub Processors',
            value: null,
        },
        allSubHaulingChecked: {
            checked: false,
            label: 'Select All Sub Hauling Companies',
            value: null,
        },
        loading: false,
        openAdminList: false,
        openProducerList: false,
        openProcessorList: false,
        openHaulingList: false,
        openDriverList: false,
        openSubAdminList: false,
        openSubProducerList: false,
        openSubProcessorList: false,
        openSubHaulingList: false,
    };

    componentDidMount() {
        const { allHaulingChecked, allSubHaulingChecked } = this.state;
        const { users, region } = this.props;

        this.setState({ users: { ...users } });
        if (region === 'RF') {
            this.setState({
                allHaulingChecked: { ...allHaulingChecked, label: 'Select All Transport Companies' },
                allSubHaulingChecked: { ...allSubHaulingChecked, label: 'Select All Sub Transport Companies' },
            });
        }
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    handleChange = (name) => (event) => {
        const { allChecked, allProducerChecked, users } = this.state;

        this.setState({ allChecked: { ...allChecked, checked: false }, allProducerChecked: { ...allProducerChecked, checked: false } });

        const key = Object.keys(users).find((k) => users[k].value === name);

        this.setState({ users: { ...users, [key]: { ...users[key], checked: event.target.checked } } });
    };

    handleAllChange = () => (event) => {
        const { allChecked, allAdminChecked, allProducerChecked, allProcessorChecked, allHaulingChecked, allDriverChecked, allSubAdminChecked, allSubProducerChecked, allSubProcessorChecked, allSubHaulingChecked, users } = this.state;

        this.setState({
            allChecked: { ...allChecked, checked: event.target.checked },
            allAdminChecked: { ...allAdminChecked, checked: event.target.checked },
            allProducerChecked: { ...allProducerChecked, checked: event.target.checked },
            allProcessorChecked: { ...allProcessorChecked, checked: event.target.checked },
            allHaulingChecked: { ...allHaulingChecked, checked: event.target.checked },
            allDriverChecked: { ...allDriverChecked, checked: event.target.checked },
            allSubAdminChecked: { ...allSubAdminChecked, checked: event.target.checked },
            allSubProducerChecked: { ...allSubProducerChecked, checked: event.target.checked },
            allSubProcessorChecked: { ...allSubProcessorChecked, checked: event.target.checked },
            allSubHaulingChecked: { ...allSubHaulingChecked, checked: event.target.checked },
        });

        // eslint-disable-next-line no-return-assign
        Object.keys(users).map((value) => (users[value] = { ...users[value], checked: event.target.checked }));

        this.setState({ users });
    };

    handleAllAdminChange = () => (event) => {
        const { allChecked, allAdminChecked, users } = this.state;

        this.setState({ allChecked: { ...allChecked, checked: false }, allAdminChecked: { ...allAdminChecked, checked: event.target.checked } });

        // eslint-disable-next-line no-return-assign
        Object.keys(users).map((value) => (users[value] = { ...users[value], checked: users[value].userRole === 'admin' && !users[value].subuser ? event.target.checked : users[value].checked }));

        this.setState({ users });
    };

    handleAllProducerChange = () => (event) => {
        const { allChecked, allProducerChecked, users } = this.state;

        this.setState({ allChecked: { ...allChecked, checked: false }, allProducerChecked: { ...allProducerChecked, checked: event.target.checked } });

        // eslint-disable-next-line no-return-assign
        Object.keys(users).map((value) => (users[value] = { ...users[value], checked: users[value].userRole === 'producer' && !users[value].subuser ? event.target.checked : users[value].checked }));

        this.setState({ users });
    };

    handleAllProcessorChange = () => (event) => {
        const { allChecked, allProcessorChecked, users } = this.state;

        this.setState({ allChecked: { ...allChecked, checked: false }, allProcessorChecked: { ...allProcessorChecked, checked: event.target.checked } });

        // eslint-disable-next-line no-return-assign
        Object.keys(users).map((value) => (users[value] = { ...users[value], checked: users[value].userRole === 'processor' && !users[value].subuser ? event.target.checked : users[value].checked }));

        this.setState({ users });
    };

    handleAllHaulingChange = () => (event) => {
        const { allChecked, allHaulingChecked, users } = this.state;

        this.setState({ allChecked: { ...allChecked, checked: false }, allHaulingChecked: { ...allHaulingChecked, checked: event.target.checked } });
        // eslint-disable-next-line no-return-assign
        Object.keys(users).map((value) => (users[value] = { ...users[value], checked: users[value].userRole === 'transport' && !users[value].subuser ? event.target.checked : users[value].checked }));

        this.setState({ users });
    };

    handleAllDriverChange = () => (event) => {
        const { allChecked, allDriverChecked, users } = this.state;

        this.setState({ allChecked: { ...allChecked, checked: false }, allDriverChecked: { ...allDriverChecked, checked: event.target.checked } });

        // eslint-disable-next-line no-return-assign
        Object.keys(users).map((value) => (users[value] = { ...users[value], checked: users[value].userRole === 'driver' ? event.target.checked : users[value].checked }));

        this.setState({ users });
    };

    handleAllSubAdminChange = () => (event) => {
        const { allChecked, allSubAdminChecked, users } = this.state;

        this.setState({ allChecked: { ...allChecked, checked: false }, allSubAdminChecked: { ...allSubAdminChecked, checked: event.target.checked } });

        // eslint-disable-next-line no-return-assign
        Object.keys(users).map((value) => (users[value] = { ...users[value], checked: users[value].userRole === 'admin' && users[value].subuser ? event.target.checked : users[value].checked }));

        this.setState({ users });
    };

    handleAllSubProducerChange = () => (event) => {
        const { allChecked, allSubProducerChecked, users } = this.state;

        this.setState({ allChecked: { ...allChecked, checked: false }, allSubProducerChecked: { ...allSubProducerChecked, checked: event.target.checked } });

        // eslint-disable-next-line no-return-assign
        Object.keys(users).map((value) => (users[value] = { ...users[value], checked: users[value].userRole === 'producer' && users[value].subuser ? event.target.checked : users[value].checked }));

        this.setState({ users });
    };

    handleAllSubProcessorChange = () => (event) => {
        const { allChecked, allSubProcessorChecked, users } = this.state;

        this.setState({ allChecked: { ...allChecked, checked: false }, allSubProcessorChecked: { ...allSubProcessorChecked, checked: event.target.checked } });

        // eslint-disable-next-line no-return-assign
        Object.keys(users).map((value) => (users[value] = { ...users[value], checked: users[value].userRole === 'processor' && users[value].subuser ? event.target.checked : users[value].checked }));

        this.setState({ users });
    };

    handleAllSubHaulingChange = () => (event) => {
        const { allChecked, allSubHaulingChecked, users } = this.state;

        this.setState({ allChecked: { ...allChecked, checked: false }, allSubHaulingChecked: { ...allSubHaulingChecked, checked: event.target.checked } });

        // eslint-disable-next-line no-return-assign
        Object.keys(users).map((value) => (users[value] = { ...users[value], checked: users[value].userRole === 'transport' && users[value].subuser ? event.target.checked : users[value].checked }));

        this.setState({ users });
    };

    handleOpenAdminList = () => {
        const { openAdminList } = this.state;
        this.setState({ openAdminList: !openAdminList });
    };

    handleOpenProducerList = () => {
        const { openProducerList } = this.state;
        this.setState({ openProducerList: !openProducerList });
    };

    handleOpenProcessorList = () => {
        const { openProcessorList } = this.state;
        this.setState({ openProcessorList: !openProcessorList });
    };

    handleOpenHaulingList = () => {
        const { openHaulingList } = this.state;
        this.setState({ openHaulingList: !openHaulingList });
    };

    handleOpenDriverList = () => {
        const { openDriverList } = this.state;
        this.setState({ openDriverList: !openDriverList });
    };

    handleOpenSubAdminList = () => {
        const { openSubAdminList } = this.state;
        this.setState({ openSubAdminList: !openSubAdminList });
    };

    handleOpenSubProducerList = () => {
        const { openSubProducerList } = this.state;
        this.setState({ openSubProducerList: !openSubProducerList });
    };

    handleOpenSubProcessorList = () => {
        const { openSubProcessorList } = this.state;
        this.setState({ openSubProcessorList: !openSubProcessorList });
    };

    handleOpenSubHaulingList = () => {
        const { openSubHaulingList } = this.state;
        this.setState({ openSubHaulingList: !openSubHaulingList });
    };

    getFolderName = (value) => {
        const { folders } = this.props;
        let folder = _.find(folders, { value });
        let name = folder.label;
        while (folder.folder_id) {
            folder = _.find(folders, { value: folder.folder_id });
            name = `${folder.label}/${name}`;
        }
        return name;
        // return value;
    };

    onSubmit = (model) => {
        const { showMessage, history, ownerId } = this.props;
        const { users } = this.state;

        const submitModel = { ...model };

        submitModel.folder_id = submitModel.folder_id === 'none' ? null : submitModel.folder_id;

        if (!this.fileInput.current.files.length) {
            showMessage({ message: 'Please select a file, or files, to share.' });
        } else {
            this.setState({ loading: true });
        }
        const numberOfFiles = this.fileInput.current.files.length;

        for (let i = 0; i < numberOfFiles; i++) {
            const obj = {
                file: this.fileInput.current.files[i],
                file_title: this.fileInput.current.files[i].name,
                folder_id: submitModel.folder_id,
                selected: Object.values(users)
                    .filter((value) => !!value.checked)
                    .map((value) => value.value),
            };

            if (obj.file) {
                const formData = new FormData();
                formData.append('file', obj.file, obj.file.name);
                formData.append('file_title', obj.file_title);
                formData.append('folder_id', obj.folder_id);
                formData.append('selected', obj.selected);
                formData.append('owner_id', ownerId);
                formData.append('index', i.toString());

                shareFile(formData)
                    .then((payload) => {
                        if (i + 1 >= numberOfFiles) {
                            showMessage({ message: 'Successfully shared your file(s).' });
                            history.push({ pathname: '/shared-files/shared' });
                            this.setState({ loading: false });
                        }
                    })
                    .catch((err) => {
                        // eslint-disable-next-line no-console
                        console.log(err);
                        showMessage({ message: `There was a problem sharing your file: ${obj.file_title}. Please contact support.` });
                        this.setState({ loading: false });
                    });
            }
        }
    };

    render() {
        // eslint-disable-next-line global-require

        // eslint-disable-next-line max-len
        const {
            canSubmit,
            users,
            allChecked,
            loading,
            openAdminList,
            openProducerList,
            openProcessorList,
            openHaulingList,
            openSubAdminList,
            openSubProducerList,
            openSubProcessorList,
            openSubHaulingList,
            openDriverList,
            allProducerChecked,
            allAdminChecked,
            allProcessorChecked,
            allHaulingChecked,
            allDriverChecked,
            allSubAdminChecked,
            allSubProducerChecked,
            allSubProcessorChecked,
            allSubHaulingChecked,
        } = this.state;
        const { classes, disabled, folders, region } = this.props;
        const error = !Object.values(users).filter((value) => !!value.value && !!value.checked).length;

        if (!users) {
            return <MMSplashScreen />;
        }

        const sortedUsers = _.orderBy(users, ['label'], ['asc']);

        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className={window.innerWidth > 600 ? 'w-3/4' : 'w-full'}>
                        <Divider />

                        <Formsy
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            /* eslint-disable-next-line no-return-assign */
                            ref={(form) => (this.form = form)}
                            className="flex flex-col justify-center"
                        >
                            <SelectSearchFormsy className="my-16" name="folder_id" label="Share To Folder" options={[{ value: 'none', name: 'None' }, ...folders.map((entry) => ({ value: entry.value, name: this.getFolderName(entry.value) }))]} variant="standard" value="none" disabled={folders.length === 0} />

                            <Typography className="my-16">
                                Upload File &nbsp;&nbsp;&nbsp; <input type="file" id="pdfFile" ref={this.fileInput} multiple />
                            </Typography>

                            <div className={classes.root}>
                                <FormControl error={error} component="fieldset" className={classes.formControl}>
                                    <FormLabel component="legend" className="text-black bold">
                                        Select Who Should Receive File
                                    </FormLabel>
                                    <FormGroup>
                                        <List className={classes.list}>
                                            <ListItem>
                                                <FormControlLabel control={<Checkbox checked={allChecked.checked} disabled={disabled} onChange={this.handleAllChange(!allChecked)} value="allChecked" />} label={allChecked.label} />
                                            </ListItem>
                                            <Dropdown
                                                classes={classes}
                                                disabled={disabled}
                                                users={Object.values(sortedUsers).filter((user) => user.userRole === 'admin' && !user.subuser)}
                                                listItemText="Admins"
                                                openList={openAdminList}
                                                allChecked={allAdminChecked}
                                                handleOpenList={this.handleOpenAdminList}
                                                handleAllChange={this.handleAllAdminChange}
                                                handleChange={this.handleChange}
                                            />
                                            <Dropdown
                                                classes={classes}
                                                disabled={disabled}
                                                users={Object.values(sortedUsers).filter((user) => user.userRole === 'producer' && !user.subuser)}
                                                listItemText="Producers"
                                                openList={openProducerList}
                                                allChecked={allProducerChecked}
                                                handleOpenList={this.handleOpenProducerList}
                                                handleAllChange={this.handleAllProducerChange}
                                                handleChange={this.handleChange}
                                            />
                                            <Dropdown
                                                classes={classes}
                                                disabled={disabled}
                                                users={Object.values(sortedUsers).filter((user) => user.userRole === 'processor' && !user.subuser)}
                                                listItemText={localization.general.processor_plural}
                                                openList={openProcessorList}
                                                allChecked={allProcessorChecked}
                                                handleOpenList={this.handleOpenProcessorList}
                                                handleAllChange={this.handleAllProcessorChange}
                                                handleChange={this.handleChange}
                                            />
                                            <Dropdown
                                                classes={classes}
                                                disabled={disabled}
                                                users={Object.values(sortedUsers).filter((user) => user.userRole === 'transport' && !user.subuser)}
                                                listItemText={localization.general.hauling_plural}
                                                openList={openHaulingList}
                                                allChecked={allHaulingChecked}
                                                handleOpenList={this.handleOpenHaulingList}
                                                handleAllChange={this.handleAllHaulingChange}
                                                handleChange={this.handleChange}
                                            />
                                            <Dropdown
                                                classes={classes}
                                                disabled={disabled}
                                                users={Object.values(sortedUsers).filter((user) => user.userRole === 'driver')}
                                                listItemText="Driver"
                                                openList={openDriverList}
                                                allChecked={allDriverChecked}
                                                handleOpenList={this.handleOpenDriverList}
                                                handleAllChange={this.handleAllDriverChange}
                                                handleChange={this.handleChange}
                                            />
                                            <Dropdown
                                                classes={classes}
                                                disabled={disabled}
                                                users={Object.values(sortedUsers).filter((user) => user.userRole === 'admin' && user.subuser)}
                                                listItemText="Sub Admins"
                                                openList={openSubAdminList}
                                                allChecked={allSubAdminChecked}
                                                handleOpenList={this.handleOpenSubAdminList}
                                                handleAllChange={this.handleAllSubAdminChange}
                                                handleChange={this.handleChange}
                                                hidden
                                            />
                                            <Dropdown
                                                classes={classes}
                                                disabled={disabled}
                                                users={Object.values(sortedUsers).filter((user) => user.userRole === 'producer' && user.subuser)}
                                                listItemText="Sub Producers"
                                                openList={openSubProducerList}
                                                allChecked={allSubProducerChecked}
                                                handleOpenList={this.handleOpenSubProducerList}
                                                handleAllChange={this.handleAllSubProducerChange}
                                                handleChange={this.handleChange}
                                            />
                                            <Dropdown
                                                classes={classes}
                                                disabled={disabled}
                                                users={Object.values(sortedUsers).filter((user) => user.userRole === 'processor' && user.subuser)}
                                                listItemText={`Sub ${localization.general.processor_plural}`}
                                                openList={openSubProcessorList}
                                                allChecked={allSubProcessorChecked}
                                                handleOpenList={this.handleOpenSubProcessorList}
                                                handleAllChange={this.handleAllSubProcessorChange}
                                                handleChange={this.handleChange}
                                                hidden
                                            />
                                            <Dropdown
                                                classes={classes}
                                                disabled={disabled}
                                                users={Object.values(sortedUsers).filter((user) => user.userRole === 'transport' && user.subuser)}
                                                listItemText={`Sub ${localization.general.hauling_plural}`}
                                                openList={openSubHaulingList}
                                                allChecked={allSubHaulingChecked}
                                                handleOpenList={this.handleOpenSubHaulingList}
                                                handleAllChange={this.handleAllSubHaulingChange}
                                                handleChange={this.handleChange}
                                                hidden
                                            />
                                        </List>
                                    </FormGroup>
                                    {error && <FormHelperText>* Please select at least one receiver.</FormHelperText>}
                                </FormControl>
                            </div>

                            <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label="SHARE FILE" disabled={!canSubmit}>
                                Share File
                            </Button>
                        </Formsy>
                        <LoadingDialog showDialog={loading} />
                    </div>
                )}
            </LocalizationConsumer>
        );
    }
}

function mapStateToProps({ persisted }) {
    return { ownerId: persisted.auth.user.data.id, region: persisted.auth.user.data.region };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: FuseActions.showMessage }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShareAFileForm)));
