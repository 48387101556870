import React, { useState, useCallback, useEffect } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { showMessage, editPaymentStatus } from 'app/store/actions';
import { Box, Button, Grid, debounce } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ReactHookFormInput from '../form-components/ReactHookFormInput';
import ReactHookFormSearchableSelect from '../form-components/ReactHookFormSearchableSelect';
import { paymentStatus, PaymentStatusValues } from '../form-components/PaymentStatusSelect';
import _ from 'lodash';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import { generateTestId, setSubmitModelSelectValues } from 'app/../utils';

const PaymentStatusForm = ({ editData }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [showDuplicatePaymentNumberWarning, setShowDuplicatePaymentNumberWarning] = useState(false);
    const reactHookFormMethods = useForm({
        mode: 'all', // Sets validation onBlur + onChange + onSubmit
        defaultValues: {
            payment_number: editData.payment_number,
            issue_date: editData.issue_date,
            payment_status: _.find(paymentStatus, (z) => z.value === editData?.payment_status),
            cleared_date: editData?.cleared_date ? moment(editData?.cleared_date) : null,
            payment_approved: editData.payment_approved,
        },
    });

    const { handleSubmit, control, errors, watch, setValue } = reactHookFormMethods;
    const paymentStatusWatch = watch('payment_status');
    const paymentNumberWatch = watch('payment_number');

    useEffect(() => {
        if (editData?.payment_number && Number(editData?.payment_number) !== Number(paymentNumberWatch)) {
            setShowDuplicatePaymentNumberWarning(true);
        } else {
            setShowDuplicatePaymentNumberWarning(false);
        }
    }, [paymentNumberWatch]);

    useEffect(() => {
        if (paymentStatusWatch?.value !== PaymentStatusValues.Cleared) {
            setValue('cleared_date', null);
        }
    }, [paymentStatusWatch]);

    const put = (submitModel) => {
        const { _id } = editData;
        dispatch(editPaymentStatus(submitModel, _id))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Edited Payment Status' }));
                history.replace({ pathname: '/payment-status' });
            })
            .catch((error) => {
                dispatch(showMessage({ message: error.message }));
            });
    };

    const onSubmit = (model) => {
        const submitModel = setSubmitModelSelectValues(model);
        submitModel.cleared_date = submitModel?.cleared_date ? moment(submitModel.cleared_date).startOf('day').format() : null;
        put(submitModel);
    };

    const debounceSubmit = useCallback(debounce(onSubmit, 500), []);

    const render = () => {
        return (
            <div>
                <FormProvider {...reactHookFormMethods}>
                    <form onSubmit={handleSubmit(debounceSubmit, errors)}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={4} style={{ marginTop: '8px' }}>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <Controller
                                        name="cleared_date"
                                        control={control}
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <DatePicker
                                                    data-testid={generateTestId('Cleared Date', 'date-picker')}
                                                    fullWidth
                                                    onChange={onChange}
                                                    format="MM/DD/YYYY"
                                                    value={value}
                                                    label="Cleared Date"
                                                    disabled={![PaymentStatusValues.Cleared].includes(paymentStatusWatch?.value)}
                                                    required={[PaymentStatusValues.Cleared].includes(paymentStatusWatch?.value)}
                                                />
                                            );
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <ReactHookFormSearchableSelect required name={'payment_status'} label={'Payment Status'} options={paymentStatus} />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <ReactHookFormInput label={'Payment Number'} name={'payment_number'} type={'text'} disabled={[PaymentStatusValues.Cleared, PaymentStatusValues.Void].includes(paymentStatusWatch?.value) || editData?.payment_approved === true} />
                            </Grid>

                            {showDuplicatePaymentNumberWarning && (
                                <Grid item xs={12}>
                                    <Alert severity="warning">Updating the payment number may overwrite an existing outstanding payment.</Alert>
                                </Grid>
                            )}

                            <Box display="flex" justifyContent="center" gridGap="30px" paddingTop={'15px'}>
                                <Button variant="contained" color="primary" margin="normal" className="mx-auto my-16" sx={{ paddingTop: 2 }} type="submit">
                                    Save
                                </Button>
                            </Box>
                        </Grid>
                    </form>
                </FormProvider>
            </div>
        );
    };
    return render();
};

export default PaymentStatusForm;
