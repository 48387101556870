import Environment from '../../Environment';
import MockSplit from '../../MockSplit';

const prod = new Environment({
    name: 'PRODUCTION',
    evaluationFn: (attributes) => {
        if (attributes.region === 'PRAIRIE' && ['stosh@prairiefarms.com', 'choeger@prairiefarms.com', 'sescalet@prairiefarms.com', 'developer@milkmoovement.com'].includes(attributes.email)) return 'on';
        if (attributes.region === 'MMPA' && ['emily.keranen@mimilk.com', 'jeannie.strain@mimilk.com', 'therese.tierney@mimilk.com', 'developer@milkmoovement.com'].includes(attributes.email)) return 'on';
        return 'off';
    },
});

const local = new Environment({
    ...prod,
    name: 'LOCAL',
});

const dev = new Environment({
    ...prod,
    name: 'DEV',
});

const staging = new Environment({
    ...prod,
    name: 'STAGING',
});

const demo = new Environment({
    ...prod,
    name: 'DEMO',
});

const can_access_sensitive_info_dialog = new MockSplit({
    name: 'can_access_sensitive_info_dialog',
    environments: [local, dev, staging, demo, prod],
});

export default can_access_sensitive_info_dialog;
