import axios from 'axios';
import { getSplitProducerWithParentOrChildProducerQuery } from './queries';

const getParentChildProducerIds = async (id) => {
    let isSplitProducer = false;
    let parentProducerId = id;
    const childProducerIds = new Set();

    try {
        const splitProducersQuery = getSplitProducerWithParentOrChildProducerQuery(id);
        const { data: splitAgreements } = await axios.get(splitProducersQuery);

        splitAgreements.forEach((splitAgreement) => {
            const { parent_producer, child_producer } = splitAgreement;
            if (id === child_producer.id) {
                isSplitProducer = true;
            }

            if (parentProducerId !== parent_producer.id) {
                parentProducerId = parent_producer.id;
            }

            childProducerIds.add(child_producer.id);
        });

        return {
            isSplitProducer,
            parentProducerId,
            childProducerIds: Array.from(childProducerIds),
        };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw new Error('Unable to process request.');
    }
};

export default getParentChildProducerIds;
