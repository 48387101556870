import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

const prod = new Environment({
    name: stages.PRODUCTION,
    evaluationFn: (attributes) => {
        if (attributes.email === 'developer@milkmoovement.com') return 'on';
        return 'off';
    },
});

const local = new Environment({
    ...prod,
    name: stages.LOCAL,
});

const dev = new Environment({
    ...prod,
    name: stages.DEV,
});

const staging = new Environment({
    ...prod,
    name: stages.STAGING,
});

const demo = new Environment({
    ...prod,
    name: stages.DEMO,
});

/**
 * Not currently used, but here in case we need it again as we continue to roll out this feature to new clients.
 */
const can_view_new_and_old_geofence_data = new MockSplit({
    name: 'can_view_new_and_old_geofence_data',
    environments: [local, dev, staging, demo, prod],
});

export default can_view_new_and_old_geofence_data;
