import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import store from 'app/store';
import { getTrucksQuery, getPickupsQuery, getDriversQuery, getProducersQuery, getRouteSessionsQuery, getRoutesQuery, batchQuery, getLabReportsQuery, bulkQuery, getHaulingCompaniesQuery } from './queries';
import { arrayToMap, renameKey, formatLabsForComponentCalculation } from '../../utils';
import { buildExpressQuery } from './queries/builders';
import { getBulkQueryData } from './utils';
import calculateComponents from './utils/calculateComponents';

const requiresLabData = (region) => {
    switch (region) {
        case 'RF':
            return true;
        default:
            return false;
    }
};

const getProducerPickupDetails = (id, start, end, filterType) =>
    new Promise((resolve, reject) => {
        const { region } = store.getState().persisted.auth.user.data;
        const pickupsQuery = getPickupsQuery({
            start,
            end,
            producerId: id,
            filterDeleted: true,
            filterType,
            queryBuilder: buildExpressQuery,
        });
        const pickupsRequest = axios.get(pickupsQuery);

        pickupsRequest
            .then((pickupsResponse) => {
                const pickups = pickupsResponse.data;
                renameKey(pickups, '_id', 'id');

                const driverIds = _.uniq(_.map(pickups, 'driver_id'));
                const producerIds = _.uniq(_.map(pickups, 'producer_id'));
                const routeSessionIds = _.uniq(_.map(pickups, 'route_session_id'));

                const driverQuery = bulkQuery(getDriversQuery, { ids: driverIds, queryBuilder: buildExpressQuery });
                const producerQuery = getProducersQuery({ ids: producerIds, queryBuilder: buildExpressQuery });
                const routeSessionQuery = bulkQuery(getRouteSessionsQuery, {
                    fields: ['id', 'BOL', 'status', 'route_id', 'route_number', 'manifest_number', 'truck_id', 'created_at', 'po_number'],
                    ids: routeSessionIds,
                    filterDeleted: true,
                    hint: '_id_1_created_at_-1_deleted_at_1',
                });

                const driverRequest = axios.post(driverQuery.url, driverQuery.body);
                const producerRequest = axios.get(producerQuery);
                const routeSessionRequest = axios.post(routeSessionQuery.url, routeSessionQuery.body);

                const requests = [producerRequest, driverRequest, routeSessionRequest];
                return axios.all(requests).then(
                    axios.spread(async (...responses) => {
                        const producerResponse = responses[0];
                        const driverResponse = responses[responses.length - 2];
                        const sessionResponse = responses[responses.length - 1];

                        const producers = producerResponse.data;
                        renameKey(producers, '_id', 'id');
                        const producersMap = arrayToMap(producers, 'id');

                        const drivers = driverResponse.data;
                        renameKey(drivers, '_id', 'id');
                        const driversMap = arrayToMap(drivers, 'id');

                        const routeSessions = sessionResponse.data;
                        renameKey(routeSessions, '_id', 'id');
                        const routeSessionsMap = arrayToMap(routeSessions, 'id');
                        const routeIds = _.uniq(_.map(routeSessions, 'route_id'));
                        const routeQuery = bulkQuery(getRoutesQuery, { ids: routeIds, queryBuilder: buildExpressQuery });
                        const routeRequest = axios.post(routeQuery.url, routeQuery.body);

                        const truckIds = _.uniq(_.map(routeSessions, 'truck_id'));
                        const truckQuery = bulkQuery(getTrucksQuery, { ids: truckIds });
                        const trucks = (await axios.get(truckQuery)).data;
                        const truckMap = arrayToMap(trucks, '_id');

                        const haulingIds = _.filter(_.uniq(_.map(drivers, 'hauling_id')), (haulingId) => !_.isNull(haulingId));
                        const haulers = await getBulkQueryData(getHaulingCompaniesQuery, { fields: ['id', 'name'], ids: haulingIds });
                        const haulingMap = arrayToMap(haulers, 'id');

                        if (requiresLabData(region)) {
                            const labReports = await getBulkQueryData(getLabReportsQuery, {
                                fields: ['producer_id', 'sample_barcode', 'date', 'fat', 'fat_weight', 'fat_percentage', 'LOS', 'somatic_cell_count', 'protein', 'protein_weight', 'protein_percentage', 'bulk_milk_cell_count', 'pickup_id'],
                                start,
                                end,
                                filterDeleted: true,
                            });
                            const [groupedLabReports, labsByPickupIdMap] = formatLabsForComponentCalculation(labReports, region);
                            return routeRequest.then((routeResponse) => {
                                const routes = routeResponse.data;
                                renameKey(routes, '_id', 'id');
                                const routesMap = arrayToMap(routes, 'id');
                                const data = _.map(pickups, (pickup) => {
                                    const producer = [producersMap[pickup.producer_id]];
                                    const driver = [driversMap[pickup.driver_id]];
                                    const routeSession = routeSessionsMap[pickup.route_session_id];
                                    const route = routeSession ? routesMap[routeSession.route_id] : {};
                                    const components = calculateComponents([pickup], groupedLabReports, region, labsByPickupIdMap);
                                    const truck = routeSession && routeSession.truck_id ? truckMap[routeSession.truck_id] : {};
                                    return {
                                        ...pickup,
                                        ...components,
                                        partial: !!pickup.partial,
                                        producer,
                                        driver,
                                        route_session: [{ ...routeSession, route: route && routeSession ? route.name : '' }],
                                        truck_number: truck?.truck_number || '',
                                    };
                                });
                                const pickupTotal = data.reduce((acc, val) => acc + Number(val.volume), 0);
                                resolve({ pickups: data, pickupTotal });
                            });
                        }
                        return routeRequest.then((routeResponse) => {
                            const routes = routeResponse.data;
                            renameKey(routes, '_id', 'id');
                            const routesMap = arrayToMap(routes, 'id');
                            const data = _.map(pickups, (pickup) => {
                                const producer = [producersMap[pickup.producer_id]];
                                const driver = [driversMap[pickup.driver_id]];
                                const hauling_company = haulingMap?.[driversMap?.[pickup.driver_id]?.hauling_id]?.name || '';
                                const routeSession = routeSessionsMap[pickup.route_session_id];
                                const route = routeSession ? routesMap[routeSession.route_id] : {};
                                const truck = routeSession && routeSession.truck_id ? truckMap[routeSession.truck_id] : {};
                                return {
                                    ...pickup,
                                    partial: !!pickup.partial,
                                    producer,
                                    driver,
                                    hauling_company,
                                    route_session: [{ ...routeSession, route: route && routeSession ? route.name : '' }],
                                    truck_number: truck?.truck_number || '',
                                };
                            });
                            const pickupTotal = data.reduce((acc, val) => acc + Number(val.volume), 0);
                            resolve({ pickups: data, pickupTotal });
                        });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getProducerPickupDetails;
