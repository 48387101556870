import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

const EmbeddedReportsConfig = {
    auth: authRoles.everyoneButGuest,
    routes: [
        { exact: true, path: '/reporting', component: FuseLoadable({ loader: () => import('./ListEmbeddedReportsApp') }) },
        { exact: true, path: '/embedded/:reportIdentifier', component: FuseLoadable({ loader: () => import('./ViewEmbeddedReportApp') }) },
    ],
};

export default EmbeddedReportsConfig;
