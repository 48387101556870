import * as Actions from '../../actions';

const initialState = { data: null };

const listAssigneeReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ASSIGNEE_LIST_DETAILS:
            return { ...state, data: { ...action.payload } };
        default:
            return state;
    }
};

export default listAssigneeReducer;
