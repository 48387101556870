import { getProductionStats } from '.';

const getAdminOnFarmEstimateDetails = () =>
    new Promise((resolve, reject) => {
        const excludeCurrentDayPickupsFromEstimate = false;
        getProductionStats(excludeCurrentDayPickupsFromEstimate)
            .then((productionStats) => {
                const volume = productionStats.producers.reduce((acc, val) => acc + val.expected_milk_on_farm, 0);
                resolve({ volume });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getAdminOnFarmEstimateDetails;
