import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers';

/**
 *  MUI DatePicker with moment-timezone adapter
 * @param {object} props - props spread directly into the DatePicker component
 * @see https://mui.com/x/api/date-pickers/date-picker/
 */
const LocalizedDatePicker = ({ Picker = DateTimePicker, ...props }) => (
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <Picker {...props} />
    </LocalizationProvider>
);

export default LocalizedDatePicker;
