import * as Actions from 'app/store/actions';

const initialState = { data: null };

const adminInTransitReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ADMIN_IN_TRANSIT_DETAILS:
            return { ...state, data: { ...action.payload } };
        default:
            return state;
    }
};

export default adminInTransitReducer;
