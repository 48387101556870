import axios from 'axios';
import _ from 'lodash';
import store from 'app/store';
import moment from 'moment-timezone';
import { bulkQuery, getTrucksQuery, getPickupsQuery, getDriversQuery, getProducersQuery, getRouteSessionsQuery, getRoutesQuery, getLabReportsQuery, getHaulingCompaniesQuery, getTrailersQuery } from './queries';
import { arrayToMap, formatLabsForComponentCalculation, getNumSlips } from '../../utils';
import calculateComponents from './utils/calculateComponents';
import { getBulkQueryData, getBatchedQueryData } from './utils';

const requiresRouteSessionsBeforePickups = (region) => {
    switch (region) {
        // case 'NL':
        //     return true;
        default:
            return false;
    }
};

const requiresLabData = (region) => {
    switch (region) {
        case 'CDI':
        case 'PRAIRIE':
            return false;
        default:
            return true;
    }
};

/**
 *
 * Creates a query to retrieve Pickups, bounded by start and end if provided
 *
 * @param {string} start start boundary (datestring)
 * @param {string} end end boundary (datestring)
 * @param {string} filterType ("milk" || "cream")
 * @returns {object} PickupsQuery params bounded by start/end if provided, else to 100 records
 */
const generateBoundedPickupsQueryParams = (start, end, filterType) => {
    return start && end
        ? {
              start,
              end,
              filterDeleted: true,
              filterType,
              hint: 'type_of_fluid_1_deleted_at_1_created_at_-1',
          }
        : {
              limit: '250',
              filterDeleted: true,
              filterType,
              hint: 'type_of_fluid_1_deleted_at_1_created_at_-1',
          };
};

/**
 * Get routeSessions first, and then all pickups corresponding to those routeSessions
 *
 * @param {string} start start boundary (datestring)
 * @param {string} end end boundary (datestring)
 * @param {string} filterType ("milk" || "cream")
 * @returns {object} { dropoffs, routeSessions }
 */
const getRouteSessionsThenPickups = async (start, end, filterType) => {
    const routeSessionsQuery = getRouteSessionsQuery({
        fields: ['id', 'BOL', 'status', 'route_id', 'deleted_at', 'manifest_number', 'trailer_id', 'truck_id', 'created_at', 'po_number'],
        start,
        end,
        filterDeleted: true,
        filterType,
    });

    const routeSessionsResponse = await axios.get(routeSessionsQuery);
    const routeSessions = routeSessionsResponse.data;

    const routeSessionsIds = _.map(routeSessions, (session) => session.id);
    const pickups = await getBulkQueryData(
        getPickupsQuery,
        {
            routeSessionIds: routeSessionsIds,
            filterDeleted: true,
            hint: 'route_session_id_1_deleted_at_1_created_at_1',
        },
        'routeSessionIds'
    );

    return { pickups, routeSessions };
};

/**
 * Get pickups first, and then all routeSessions corresponding to those pickups
 *
 * @param {string} start start boundary (datestring)
 * @param {string} end end boundary (datestring)
 * @param {string} filterType ("milk" || "cream")
 * @returns {object} { pickups, routeSessions }
 */
const getPickupsThenRouteSessions = async (start, end, filterType) => {
    const pickups = await getBulkQueryData(getPickupsQuery, generateBoundedPickupsQueryParams(start, end, filterType));
    const boundedStart = moment(start).subtract(1, 'week');
    const boundedEnd = moment(end).add(1, 'week');
    const linkingIds = _.uniq(_.map(pickups, 'route_session_id'));

    const routeSessions = await getBulkQueryData(getRouteSessionsQuery, {
        start: start && boundedStart,
        end: end && boundedEnd,
        fields: ['id', 'BOL', 'status', 'route_id', 'manifest_number', 'trailer_id', 'truck_id', 'created_at', 'po_number'],
        ids: linkingIds,
        hint: '_id_1_created_at_-1_deleted_at_1',
    });
    return { pickups, routeSessions };
};

/**
 * Get all related pickups and routeSessions
 *
 * @param {string} region
 * @param {string} start start boundary (datestring)
 * @param {string} end end boundary (datestring)
 * @param {string} filterType ("milk" || "cream")
 * @returns {object} { pickups, routeSessions }
 */
const getPickupsAndRouteSessions = async (region, start, end, filterType) => {
    let data = [];
    if (requiresRouteSessionsBeforePickups(region)) {
        data = await getRouteSessionsThenPickups(start, end, filterType);
    } else {
        data = await getPickupsThenRouteSessions(start, end, filterType);
    }
    return data;
};

const getAdminPickupDetails = async (start, end, filterType = 'milk') => {
    try {
        const { region } = store.getState().persisted.auth.user.data;
        const { pickups, routeSessions } = await getPickupsAndRouteSessions(region, start, end, filterType);

        const trailerIds = _.uniq(_.map(routeSessions, 'trailer_id'));
        const driverIds = _.uniq(_.map(pickups, 'driver_id'));
        const producerIds = _.uniq(_.map(pickups, 'producer_id'));
        const truckIds = _.uniq(_.map(routeSessions, 'truck_id'));

        const driversRequest = getBulkQueryData(getDriversQuery, { fields: ['id', 'name', 'hauling_id'], ids: driverIds });
        const producersRequest = getBulkQueryData(getProducersQuery, { fields: ['id', 'name', 'latitude', 'longitude', 'geofence', 'CMTNumber', 'number_of_tanks', 'license_number', 'permit_number', 'is_organic'], ids: producerIds });
        const routesRequest = getBulkQueryData(getRoutesQuery, { fields: ['id', 'name'] });

        const [drivers, producers, routes] = await Promise.all([driversRequest, producersRequest, routesRequest]);
        const haulingIds = _.filter(_.uniq(_.map(drivers, 'hauling_id')), (id) => !_.isNull(id));

        const haulingCompanyRequest = getBulkQueryData(getHaulingCompaniesQuery, { fields: ['id', 'name'], ids: haulingIds });
        const trailerRequest = getBatchedQueryData(getTrailersQuery, { ids: trailerIds }, 'ids');
        const truckRequest = axios.get(getTrucksQuery({ ids: truckIds }));

        const [haulers, trailers, trucks] = await Promise.all([haulingCompanyRequest, trailerRequest, truckRequest]);

        const routesMap = arrayToMap(routes, 'id');
        const producerMap = arrayToMap(producers, 'id');
        const driversMap = arrayToMap(drivers, 'id');
        const routeSessionsMap = arrayToMap(routeSessions, 'id');
        const haulingMap = arrayToMap(haulers, 'id');
        const trailerMap = arrayToMap(trailers, 'id');
        const trucksMap = arrayToMap(trucks?.data || [], '_id');

        let data = [];

        if (requiresLabData(region)) {
            const labReports = await getBulkQueryData(getLabReportsQuery, {
                fields: ['producer_id', 'sample_barcode', 'date', 'fat', 'fat_weight', 'fat_percentage', 'LOS', 'somatic_cell_count', 'protein', 'protein_percentage', 'protein_weight', 'bulk_milk_cell_count', 'pickup_id', 'solids_not_fat', 'other_solids'],
                start,
                end,
                producerIds,
                filterDeleted: true,
            });

            const [groupedLabReports, labsByPickupIdMap] = formatLabsForComponentCalculation(labReports, region);

            data = _.map(pickups, (pickup) => {
                const components = calculateComponents([pickup], groupedLabReports, region, labsByPickupIdMap);
                const producer = [producerMap[pickup.producer_id]];
                const driver = [driversMap[pickup.driver_id]];

                const hauling_company = haulingMap?.[driversMap?.[pickup.driver_id]?.hauling_id]?.name || '';

                const trailer = trailerMap?.[routeSessionsMap?.[pickup.route_session_id]?.trailer_id]?.trailer_number || '';

                const routeSession = routeSessionsMap[pickup.route_session_id];
                const truck_number = trucksMap[routeSession?.truck_id]?.truck_number || '-';

                return {
                    ...pickup,
                    fat: components.fat,
                    fat_percentage: components.fat_percentage,
                    fat_weight: components.fat_weight,
                    los: components.LOS,
                    protein: components.protein,
                    protein_percentage: components.protein_percentage,
                    protein_weight: components.protein_weight,
                    scc: components.scc,
                    solids_not_fat: components.solids_not_fat,
                    bmcc: components.bmcc,
                    producer,
                    driver,
                    hauling_company,
                    trailer,
                    truck_number,
                    route_session: [{ ...routeSession, route: routeSession && routesMap[routeSession.route_id] ? _.find(routes, { id: routeSession.route_id }).name : '' }],
                    num_slips: getNumSlips(pickup),
                };
            });
        } else {
            data = _.map(pickups, (pickup) => {
                const producer = [producerMap[pickup.producer_id]];
                const driver = [driversMap[pickup.driver_id]];
                const routeSession = routeSessionsMap[pickup.route_session_id];
                const hauling_company = haulingMap?.[driversMap?.[pickup.driver_id]?.hauling_id]?.name || '';
                const trailer = trailerMap?.[routeSessionsMap?.[pickup.route_session_id]?.trailer_id]?.trailer_number || '';
                const truck_number = trucksMap[routeSession?.truck_id]?.truck_number || '-';

                return {
                    ...pickup,
                    producer,
                    driver,
                    hauling_company,
                    trailer,
                    route_session: [{ ...routeSession, route: routeSession && routesMap[routeSession.route_id] ? routesMap[routeSession.route_id].name : '' }],
                    num_slips: getNumSlips(pickup),
                    truck_number,
                };
            });
        }
        const pickupTotal = region === 'RF' ? _.sumBy(pickups, 'volume') : data.reduce((acc, val) => acc + val.volume, 0);
        return { pickups: data, pickupTotal };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getAdminPickupDetails;
