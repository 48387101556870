import * as Actions from '../../actions';

const initialState = { data: null };

const viewPickupsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_PICKUP_DETAILS:
            return { ...state, data: { ...action.payload } };

        case Actions.GET_PICKUPS_LABS_DETAILS:
            return { ...state, data: { ...action.payload } };

        case Actions.GET_HAULING_PICKUP_DETAILS:
            return { ...state, data: { ...action.payload } };

        default:
            return state;
    }
};

export default viewPickupsReducer;
