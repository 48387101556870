import React, { useState, useEffect } from 'react';
import { Grid, Box, TextField, Typography, Button, Icon } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { useForm } from './useForm';
import Formsy from 'formsy-react';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, DatePicker as MuiDatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import { withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage, addMilkPrices, getMilkPrices } from 'app/store/actions';
import { isDemoServer, getUserRegion } from '../../../utils';

const LegacyMilkPriceForm = ({ priceData }) => {
    const dispatch = useDispatch();
    const [selectedDate, setSelectedDate] = useState(useSelector((state) => moment(`${state.persisted.dateDetails.selectedDate}`, 'YYYY-MM')));
    const [paymentDateAdvance, setPaymentDateAdvance] = useState(selectedDate.clone().endOf('month'));
    const [paymentDateSettlement, setPaymentDateSettlement] = useState(selectedDate.clone().add(1, 'month').set('date', 19));
    const startDate = useSelector((state) => moment(`${state.persisted.dateDetails.startDate}`, 'YYYY-MM'));
    const changeDisplayForDemo = isDemoServer();
    const region = getUserRegion();
    const formatInitial = (val) => {
        return val === 0 ? 0 : val || '';
    };

    const populateInitialValues = (data) => {
        const vals = {
            /* eslint-disable camelcase */
            class1: formatInitial(data?.report_data?.milk_prices?.class1),
            class2: formatInitial(data?.report_data?.milk_prices?.class2),
            class3: formatInitial(data?.report_data?.milk_prices?.class3),
            class4: formatInitial(data?.report_data?.milk_prices?.class4),

            butterfatPrice: formatInitial(data?.report_data?.milk_prices?.butter_fat_price),
            proteinPrice: formatInitial(data?.report_data?.milk_prices?.protein_price),
            otherSolidsPrice: formatInitial(data?.report_data?.milk_prices?.other_solids_price),
            nonFatPrice: formatInitial(data?.report_data?.milk_prices?.non_fat_price),
            ppdRate: formatInitial(data?.report_data?.milk_prices?.ppd_rate),

            butterfatPriceAdvance: formatInitial(data?.report_data?.advance_milk_prices?.butter_fat_price),
            proteinPriceAdvance: formatInitial(data?.report_data?.advance_milk_prices?.protein_price),
            otherSolidsPriceAdvance: formatInitial(data?.report_data?.advance_milk_prices?.other_solids_price),
            nonFatPriceAdvance: formatInitial(data?.report_data?.advance_milk_prices?.non_fat_price),
            ppdRateAdvance: formatInitial(data?.report_data?.advance_milk_prices?.ppd_rate),

            proteinPremium: formatInitial(data?.report_data?.milk_prices?.protein_premium),
            quotaRate: formatInitial(data?.report_data?.milk_prices?.quota_rate),
            regionalQuotaAdjustment: formatInitial(data?.report_data?.milk_prices?.regional_quota_adjustment),
        };
        return vals;
    };

    let initialValues = populateInitialValues(priceData);

    const { values, setValues, handleInputChange } = useForm(initialValues);

    const formatSubmit = (val) => {
        return val === '' ? undefined : +val === 0 ? +val : +val || undefined;
    };

    const transformModel = (model) => {
        const data = {
            milk_prices: {
                class1: formatSubmit(model.class1),
                class2: formatSubmit(model.class2),
                class3: formatSubmit(model.class3),
                class4: formatSubmit(model.class4),

                butter_fat_price: formatSubmit(model.butterfatPrice),
                other_solids_price: formatSubmit(model.otherSolidsPrice),
                protein_price: formatSubmit(model.proteinPrice),
                non_fat_price: formatSubmit(model.nonFatPrice),
                protein_premium: formatSubmit(model.proteinPremium),
                quota_rate: formatSubmit(model.quotaRate),
                regional_quota_adjustment: formatSubmit(model.regionalQuotaAdjustment),
                ppd_rate: formatSubmit(model.ppdRate),

                payment_date_settlement: paymentDateSettlement.format(),
            },
            advance_milk_prices: {
                payment_date_advance: paymentDateAdvance.format(),

                butter_fat_price: formatSubmit(model.butterfatPriceAdvance),
                other_solids_price: formatSubmit(model.otherSolidsPriceAdvance),
                protein_price: formatSubmit(model.proteinPriceAdvance),
                non_fat_price: formatSubmit(model.nonFatPriceAdvance),
                ppd_rate: formatSubmit(model.ppdRateAdvance),
            },
        };
        return data;
    };

    useEffect(() => {
        dispatch(getMilkPrices(selectedDate))
            .then((value) => {
                initialValues = populateInitialValues(value.payload.prices);
                setValues(initialValues);
                if (value.payload.prices?.report_data?.advance_milk_prices?.payment_date_advance) setPaymentDateAdvance(moment(value.payload.prices.report_data.advance_milk_prices.payment_date_advance));
                else setPaymentDateAdvance(selectedDate.clone().endOf('month'));
                if (value.payload.prices?.report_data?.milk_prices?.payment_date_settlement) setPaymentDateSettlement(moment(value.payload.prices.report_data.milk_prices.payment_date_settlement));
                else setPaymentDateSettlement(selectedDate.clone().add(1, 'month').set('date', 19));
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                dispatch(showMessage({ message: err.message }));
            });
    }, [selectedDate]);

    const handleSubmit = () => {
        const submitModel = transformModel({ ...values });
        dispatch(addMilkPrices(submitModel, selectedDate))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Updated Milk Prices.' }));
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
                dispatch(showMessage({ message: err.message }));
            });
    };

    return (
        <Formsy onValidSubmit={handleSubmit}>
            <Helmet>
                <title>Announced Milk Prices - Milk Moovement</title>
            </Helmet>
            <Box sx={{ mt: 0.5 }}>
                <Typography variant="h6">Announced Milk Price</Typography>
            </Box>
            <Grid container spacing={12} justifyContent="flex-end" alignItems="baseline">
                <Grid item>
                    <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={'Save'}>
                        {'Save'}
                    </Button>
                </Grid>
                <div className="p-12">
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <Button onClick={() => setSelectedDate(selectedDate.clone().subtract(1, 'month'))} disabled={moment(startDate).format('YYYY MM') === moment(selectedDate).format('YYYY MM')}>
                            <Icon>navigate_before</Icon>
                        </Button>

                        <MuiDatePicker disableFuture variant="inline" openTo="month" label="Select Date" views={['year', 'month']} value={selectedDate} minDate={startDate} onChange={(date) => setSelectedDate(date)} />

                        <Button onClick={() => setSelectedDate(selectedDate.clone().add(1, 'month'))} disabled={moment(selectedDate).format('YYYY MM') === moment().format('YYYY MM')}>
                            <Icon>navigate_next</Icon>
                        </Button>
                    </MuiPickersUtilsProvider>
                </div>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <Typography>Class Price</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Class I"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.class1}
                        name="class1"
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Class II"
                        type="number"
                        name="class2"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.class2}
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Class III"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="class3"
                        value={values.class3}
                        onChange={handleInputChange}
                    ></TextField>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Class IV"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="class4"
                        value={values.class4}
                        onChange={handleInputChange}
                    ></TextField>
                </Grid>
                <Grid item xs={3}>
                    <Typography>Settlement Component Prices</Typography>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Butterfat Price (per lb.)"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.butterfatPrice}
                        name="butterfatPrice"
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Protein Price (per lb.)"
                        type="number"
                        name="proteinPrice"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.proteinPrice}
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Other Solids Price (per lb.)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="otherSolidsPrice"
                        value={values.otherSolidsPrice}
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Nonfat Price (per lb.)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.nonFatPrice}
                        name="nonFatPrice"
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="PPD"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.ppdRate}
                        name="ppdRate"
                        onChange={handleInputChange}
                    ></TextField>
                </Grid>

                <Grid item xs={3}>
                    <Typography>Advance Component Prices</Typography>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Butterfat Price (per lb.)"
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.butterfatPriceAdvance}
                        name="butterfatPriceAdvance"
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label="Protein Price (per lb.)"
                        type="number"
                        name="proteinPriceAdvance"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.proteinPriceAdvance}
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Other Solids Price (per lb.)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="otherSolidsPriceAdvance"
                        value={values.otherSolidsPriceAdvance}
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="Nonfat Price (per lb.)"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.nonFatPriceAdvance}
                        name="nonFatPriceAdvance"
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label="PPD"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.ppdRateAdvance}
                        name="ppdRateAdvance"
                        onChange={handleInputChange}
                    ></TextField>
                </Grid>

                <Grid item xs={3}>
                    <Typography>Premium Rates</Typography>
                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label={changeDisplayForDemo ? 'Base Rate' : 'Protein Premium'}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.proteinPremium}
                        name="proteinPremium"
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        label={changeDisplayForDemo ? 'Zone A' : 'Quota Payment'}
                        type="number"
                        name="quotaRate"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={values.quotaRate}
                        onChange={handleInputChange}
                    ></TextField>

                    <TextField
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        type="number"
                        label={changeDisplayForDemo ? 'Zone C' : 'Regional Quota Adjustment'}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        name="regionalQuotaAdjustment"
                        value={values.regionalQuotaAdjustment}
                        onChange={handleInputChange}
                    ></TextField>
                </Grid>
            </Grid>
            <Typography>Payment Dates</Typography>
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <MuiDatePicker label="Advance" value={paymentDateAdvance} inputVariant="outlined" margin="normal" fullWidth onChange={(date) => setPaymentDateAdvance(date)} />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={3}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <MuiDatePicker label="Settlement" value={paymentDateSettlement} inputVariant="outlined" margin="normal" fullWidth onChange={(date) => setPaymentDateSettlement(date)} />
                    </MuiPickersUtilsProvider>
                </Grid>
            </Grid>
        </Formsy>
    );
};

export default withRouter(LegacyMilkPriceForm);
