import CDI from './CDI';
import LEGACY from './LEGACY';
import MMPA from './MMPA';
import NL from './NL';
import PEI from './PEI';
import PRAIRIE from './PRAIRIE';
import RF from './RF';
import UDA from './UDA';
import UNC from './UNC';
import defaultConfig from './default';

const config = {
    CDI,
    LEGACY,
    MMPA,
    NL,
    PEI,
    PRAIRIE,
    RF,
    UDA,
    UNC,
};

const getLocalization = (region) => config[region] ?? defaultConfig;

export default getLocalization;
