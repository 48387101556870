import axios from 'axios';
import { getAPIHost } from '../../utils';

const uploadBulkChart = (formData) =>
    new Promise((resolve, reject) => {
        axios
            .post(`${getAPIHost()}/upload-bulk-chart`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                reject(new Error('Unable to share file'));
            });
    });

export default uploadBulkChart;
