import axios from 'axios';
import _ from 'lodash';
import { getProducerBusinessesQuery } from './queries';

const getProducerBusinessListDetails = (includeDeleted) =>
    new Promise((resolve, reject) => {
        const params = { ...(!includeDeleted && { filterDeleted: true }) };
        const query = getProducerBusinessesQuery(params);
        const request = axios.get(query);

        request
            .then((response) => {
                const producerBusinesses = _.orderBy(response.data, ['name'], ['asc']);
                resolve({ producerBusinesses });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getProducerBusinessListDetails;
