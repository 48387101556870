/* eslint-disable react/prop-types, react/jsx-handler-names */
import React, { Component } from 'react';
/* eslint-disable-next-line */
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import NoSsr from '@material-ui/core/NoSsr';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
    root: { flexGrow: 1, height: 250 },
    input: { display: 'flex', padding: 0 },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: { margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px` },
    chipFocused: { backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08) },
    noOptionsMessage: { padding: `${theme.spacing(1)}px ${theme.spacing(2)}px` },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: { height: theme.spacing(2) },
});

class IntegrationReactSelect extends Component {
    state = { selectedValues: null, injectedPreValues: false };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { preselectedValues, onChange, isEdit, toAdd, toRemove, selectedType } = this.props;
        const { injectedPreValues, selectedValues } = this.state;

        if (!injectedPreValues && preselectedValues && preselectedValues.length > 0 && isEdit) {
            this.setState({ selectedValues: preselectedValues, injectedPreValues: true });
        }

        if (!!toAdd && prevProps.toAdd !== toAdd) {
            this.setState({ selectedValues: selectedValues ? selectedValues.concat(toAdd) : toAdd });
        }

        if (!!toRemove && prevProps.toRemove !== toRemove) {
            this.setState({ selectedValues: selectedValues.filter((value) => value.value !== toRemove[0].value) });
        }

        if (prevState.selectedValues !== selectedValues) {
            onChange(selectedValues);
        }
        if (prevProps.selectedType !== selectedType) {
            this.setState({ selectedValues: null, injectedPreValues: true });
        }
    }

    handleChange = (name) => (value) => {
        this.setState({ [name]: value });
    };

    render() {
        const { classes, theme, listOfValues, label } = this.props;
        const { selectedValues } = this.state;

        const selectStyles = {
            input: (base) => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': { font: 'inherit' },
            }),
        };

        return (
            <div className={`${classes.root} h-full`}>
                <NoSsr>
                    <Select classes={classes} variant="standard" styles={selectStyles} textFieldProps={{ InputLabelProps: { shrink: true }, label }} options={listOfValues} value={selectedValues} onChange={this.handleChange('selectedValues')} placeholder={'Select '.concat(label)} isMulti isClearable={false} />
                </NoSsr>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(IntegrationReactSelect);
