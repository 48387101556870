import React from 'react';
import { TextElement } from '@basis-theory/basis-theory-react';
import { BasisTheoryWrapper } from '../../main/apps/Core/dashboard/helpful-links/helpers/StyledComponents';

const style = {
    fonts: ['https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap'],
    base: {
        color: '#000',
        fontWeight: 500,
        fontFamily: '"Inter"',
        fontSize: '16px',
        fontSmooth: 'antialiased',
        '::placeholder': {
            color: '#6b7294',
        },
        ':disabled': {
            backgroundColor: 'transparent',
        },
    },
    invalid: {
        color: '#ffc7ee',
    },
};

const BasisTheoryTextElement = ({ label, id, elementRef, placeholder, setIsEmpty, onChange, ...options }) => {
    return (
        <BasisTheoryWrapper>
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={id}>{label}</label>
            <TextElement
                id={id}
                aria-label={label}
                ref={elementRef}
                placeholder={placeholder}
                style={style}
                onChange={(e) => {
                    setIsEmpty(e.empty);
                    onChange(e);
                }}
                {...options}
            />
        </BasisTheoryWrapper>
    );
};

export default BasisTheoryTextElement;
