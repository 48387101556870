import { TCJ_REGIONS } from '../../../app/constants/bulkRegion';
import Region from '../../../app/constants/region';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

/**
 * Define all of your environment configs here
 */

const local = new Environment({
    name: stages.LOCAL,
    regions: [Region.CA_DEMO, Region.UDA, Region.US_DEMO, Region.DEMO, Region.MMPA, Region.PRAIRIE, Region.LEGACY, ...TCJ_REGIONS, Region.UNC, Region.CACIQUE],
});

const dev = new Environment({
    name: stages.DEV,
    regions: [Region.CA_DEMO, Region.UDA, Region.US_DEMO, Region.DEMO, Region.MMPA, Region.PRAIRIE, Region.LEGACY, ...TCJ_REGIONS, Region.UNC, Region.CACIQUE],
});

const stg = new Environment({
    name: stages.STAGING,
    regions: [Region.CA_DEMO, Region.UDA, Region.US_DEMO, Region.DEMO, Region.MMPA, Region.PRAIRIE, ...TCJ_REGIONS, Region.UNC, Region.CACIQUE],
});

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: [Region.CA_DEMO, Region.UDA, Region.US_DEMO, Region.DEMO, Region.MMPA, Region.PRAIRIE, ...TCJ_REGIONS, Region.UNC, Region.CACIQUE],
});

/**
 * Create the new MockSplit, be sure to create the env dictionary with util
 */
const can_access_assignment_configuration = new MockSplit({
    name: 'can_access_assignment_configuration',
    environments: [local, dev, stg, prod],
});

export default can_access_assignment_configuration;
