import React, { useState, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box, Button, IconButton, Typography, Divider, Dialog, DialogActions, Accordion, AccordionDetails, AccordionSummary, DialogTitle } from '@material-ui/core';
import { AddBox, Delete, ExpandMore } from '@material-ui/icons';
import { Stack } from '@mui/material';

const CardHeader = ({ title, onAddClick }) => {
    return (
        <>
            <Stack direction="row" alignItems="center" spacing={2} sx={{ paddingTop: '25px' }}>
                <Typography variant="body1">{title}</Typography>
                <IconButton aria-label="Add" component="span" onClick={onAddClick}>
                    <AddBox />
                </IconButton>
            </Stack>
            <Divider />
        </>
    );
};

const CardSummary = ({ idx, onDeleteClick }) => {
    return (
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content">
            <Stack direction="row" alignItems="center" spacing={2}>
                <Typography>{idx + 1}</Typography>
                <IconButton
                    color="primary"
                    aria-label="Add"
                    value={idx}
                    component="span"
                    onClick={(event) => {
                        event.stopPropagation();
                        onDeleteClick(idx);
                    }}
                >
                    <Delete />
                </IconButton>
            </Stack>
        </AccordionSummary>
    );
};

const DeleteModal = ({ showDeleteModal, onConfirmDelete }) => {
    return (
        <Dialog open={showDeleteModal}>
            <DialogTitle>
                <Typography>Are you sure you want to delete this item?</Typography>
            </DialogTitle>
            <DialogActions>
                <Stack direction="row" spacing={2}>
                    <Button variant="contained" onClick={() => onConfirmDelete(false)}>
                        No
                    </Button>
                    <Button variant="contained" color="primary" onClick={() => onConfirmDelete(true)}>
                        Yes
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

const ReactHookFormArrayOfObjects = ({ name, objectToAppend, title, CustomRender, objectToExpand }) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const idxToDelete = useRef();
    const idxToExpand = useRef();
    const { control, formState } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name,
    });

    const onAddClick = () => {
        append(objectToAppend);
        idxToExpand.current = fields.length;
    };

    const onDeleteClick = (idx) => {
        idxToDelete.current = idx;
        setShowDeleteModal(true);
    };

    const onConfirmDelete = (shouldDelete) => {
        if (shouldDelete) remove(idxToDelete.current);
        setShowDeleteModal(false);
    };

    const expandSelectedCardToEdit = () => {
        if (!objectToExpand) return;
        fields.forEach((field, idx) => {
            const keys = Object.keys(objectToExpand);
            const isEqual = keys.every((key) => {
                const value = field[key]?.value ?? field[key];
                return objectToExpand[key] === value;
            });
            if (isEqual) idxToExpand.current = idx;
        });
    };

    const shouldExpandByDefault = (idx) => {
        // Expand the selected card when editing (only run on initial mounting of component)
        if (!idxToExpand.current) expandSelectedCardToEdit();
        // Newly created cards are expanded by default
        if (idx === idxToExpand.current) return true;
    };

    return (
        <>
            <CardHeader title={title} onAddClick={onAddClick} />

            {fields.map((field, idx) => (
                <Accordion key={field.id} defaultExpanded={shouldExpandByDefault(idx)}>
                    <CardSummary idx={idx} onDeleteClick={onDeleteClick} />
                    <AccordionDetails>
                        <CustomRender field={field} idx={idx} />
                    </AccordionDetails>
                </Accordion>
            ))}

            {fields.length === 0 && formState.errors?.split_producer_agreement_details?.message && (
                <Typography variant="subtitle2" color="error">
                    {formState.errors.split_producer_agreement_details.message}
                </Typography>
            )}

            <DeleteModal showDeleteModal={showDeleteModal} onConfirmDelete={onConfirmDelete} />
        </>
    );
};

export default ReactHookFormArrayOfObjects;
