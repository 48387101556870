import React, { memo, useEffect } from 'react';
import NotificationAPI from 'notificationapi-js-client-sdk';
import { PopupPosition } from 'notificationapi-js-client-sdk/lib/interfaces';
import { getEnvironment, getServerType } from '../../utils';

const NotificationAPIComponent = memo(({ userId }) => {
    useEffect(() => {
        if (userId) {
            const notificationApiUserId = `${userId}${getEnvironment(window.location.hostname)}${getServerType(window.location.hostname)}`;

            const notificationApi = new NotificationAPI({
                clientId: process.env.REACT_APP_NOTIFICATION_API_CLIENT_ID,
                userId: notificationApiUserId,
            });
            notificationApi.showInApp({
                root: 'notification_api_id',
                popupPosition: PopupPosition.BottomLeft,
            });
        }
    });

    return (
        <div className="flex w-64 h-64">
            <div className="m-auto">
                <div id="notification_api_id"></div>
            </div>
        </div>
    );
});
export default NotificationAPIComponent;
