import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { Button, Grid, Box, makeStyles, debounce } from '@material-ui/core';
import { showMessage, addEffectiveHaulerRate, updateEffectiveHaulerRate } from 'app/store/actions';
import MomentUtils from '@date-io/moment';
import _ from 'lodash';
import ReactHookFormInput from '../form-components/ReactHookFormInput';
import ReactHookFormSearchableSelect from '../form-components/ReactHookFormSearchableSelect';
import SwitchWithLabel from '../form-components/SwitchWithLabel';
import { getRateTypesForContract, basis, basisStopCharge, getHaulingTypesBasisParameter, pickupRelatedHaulingBasis, fullMonthSelectOptions, displayMaxMinCharges, displayMonthlyMaxCharges, getMilkTypeOptions } from '../form-components/HaulingContractSelectOptions';
import { yupResolver } from '@hookform/resolvers/yup';
import EffectiveHaulerRateSchema from '../form-schemas/EffectiveHaulerRateSchema';
import moment from 'moment-timezone';
import { generateTestId, getUserRegion, haulingStopCategory } from '../../../utils';
import Region from '../../constants/region';

const DATE_FORMAT = 'MM/DD/yyyy';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
}));

const EffectiveRateForm = ({ haulerRateType, haulerRateId, editData }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const region = getUserRegion();
    const type = getRateTypesForContract(region);
    const haulingTypesBasisParameter = getHaulingTypesBasisParameter(region);
    const milkTypesBasisParameter = getMilkTypeOptions(region);

    const reactHookFormMethods = useForm({
        mode: 'all',
        defaultValues: {
            type: _.find(type, { value: haulerRateType }),
            basis: _.find([...basis, ...basisStopCharge], { value: editData?.basis }),
            basis_parameter: _.find([...milkTypesBasisParameter, ...haulingTypesBasisParameter], { value: editData?.basis_parameter }),
            effective_date: moment(editData?.effective_date).toDate(),
            rate: editData?.rate,
            min_charge: editData?.min_charge,
            max_charge: editData?.max_charge,
            rate_max_charge_per_period: editData?.rate_max_charge_per_period,
            paid_by_producer: editData?.paid_by_producer ?? ![Region.UDA].includes(region),
            exclude_pickups_before_date: fullMonthSelectOptions.find(({ value }) => value === editData?.exclude_pickups_before_date),
            hauling_stop_category: _.find(haulingStopCategory, { value: editData?.hauling_stop_category }),
        },
        resolver: yupResolver(EffectiveHaulerRateSchema),
    });

    const { handleSubmit, control, watch, errors, getValues, setValue } = reactHookFormMethods;

    const rateBasisHistory = useRef([]);
    const effectiveHaulerRateType = watch('type');
    const effectiveHaulerRateBasis = watch('basis');

    useEffect(() => {
        const formData = getValues();
        let changed = false;
        if (effectiveHaulerRateBasis?.value !== rateBasisHistory?.current?.value) {
            formData.basisParameter = undefined;
            changed = true;
        }
        if (changed && rateBasisHistory?.current?.value != null) setValue('basis_parameter', null);
        rateBasisHistory.current = effectiveHaulerRateBasis;

        if (!pickupRelatedHaulingBasis.has(effectiveHaulerRateBasis?.value)) {
            setValue('exclude_pickups_before_date', null);
        }
    }, [effectiveHaulerRateBasis]);

    const post = (submitModel) => {
        dispatch(
            addEffectiveHaulerRate({
                hauler_rate_id: haulerRateId,
                effective_date: moment(submitModel.effective_date).toDate(),
                type: haulerRateType,
                basis: submitModel.basis.value,
                basis_parameter: submitModel.basis_parameter?.value,
                hauling_stop_category: submitModel.hauling_stop_category?.value,
                rate: parseFloat(submitModel.rate),
                min_charge: submitModel?.min_charge ? parseFloat(submitModel?.min_charge) : undefined,
                max_charge: submitModel?.max_charge ? parseFloat(submitModel?.max_charge) : undefined,
                rate_max_charge_per_period: submitModel?.rate_max_charge_per_period ? parseFloat(submitModel?.rate_max_charge_per_period) : undefined,
                exclude_pickups_before_date: submitModel?.exclude_pickups_before_date?.value,
                paid_by_producer: submitModel.paid_by_producer,
            })
        )
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Added Effective Rate' }));
                history.replace({ pathname: '/view-effective-rates' });
            })
            .catch(() => {
                dispatch(showMessage({ message: 'Could not add Effective Rate' }));
            });
    };

    const put = (submitModel) => {
        dispatch(
            updateEffectiveHaulerRate(
                {
                    hauler_rate_id: haulerRateId,
                    effective_date: moment(submitModel.effective_date).toDate(),
                    type: submitModel.type.value,
                    basis: submitModel.basis.value,
                    basis_parameter: submitModel.basis_parameter?.value,
                    hauling_stop_category: submitModel.hauling_stop_category?.value,
                    rate: parseFloat(submitModel.rate),
                    min_charge: submitModel?.min_charge ? parseFloat(submitModel?.min_charge) : undefined,
                    max_charge: submitModel?.max_charge ? parseFloat(submitModel?.max_charge) : undefined,
                    rate_max_charge_per_period: submitModel?.rate_max_charge_per_period ? parseFloat(submitModel?.rate_max_charge_per_period) : undefined,
                    exclude_pickups_before_date: submitModel?.exclude_pickups_before_date?.value,
                    paid_by_producer: submitModel.paid_by_producer,
                },
                editData.effective_date
            )
        )
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Edited Effective Rate' }));
                history.replace({ pathname: '/view-effective-rates' });
            })
            .catch(() => {
                dispatch(showMessage({ message: 'Could not edit Effective Rate' }));
            });
    };

    const onSubmit = async (model) => {
        const submitModel = {
            ...model,
        };
        if (editData) {
            put(submitModel);
        } else {
            post(submitModel);
        }
    };

    const debounceSubmit = debounce(onSubmit, 500);
    return (
        <div className={classes.root}>
            <FormProvider {...reactHookFormMethods}>
                <form noValidate onSubmit={handleSubmit(debounceSubmit, errors)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <ReactHookFormSearchableSelect label={'Rate Type'} name={'type'} options={type} disabled required />
                        </Grid>
                        <Grid item xs={6}>
                            <ReactHookFormInput label={'Rate'} name={'rate'} type={'number'} required />
                        </Grid>
                        <Grid item xs={6}>
                            <ReactHookFormSearchableSelect label={'Basis'} name={'basis'} options={effectiveHaulerRateType?.value === 'stop_charge' ? basisStopCharge : basis} required />
                        </Grid>
                        {effectiveHaulerRateBasis?.value !== 'fixed' && effectiveHaulerRateBasis?.value !== 'per_pickup' && effectiveHaulerRateBasis?.value !== 'per_load' && effectiveHaulerRateType?.value !== 'stop_charge' && (
                            <Grid item xs={6}>
                                <ReactHookFormSearchableSelect
                                    label={effectiveHaulerRateBasis?.value === 'percent' ? 'Hauling Rate Type' : 'Milk Type'}
                                    name={'basis_parameter'}
                                    options={effectiveHaulerRateBasis?.value === 'percent' ? haulingTypesBasisParameter : milkTypesBasisParameter}
                                    disabled={effectiveHaulerRateBasis == null || effectiveHaulerRateBasis?.value === 'fixed'}
                                />
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <SwitchWithLabel name="paid_by_producer" mainLabel="Paid By Producer" />
                        </Grid>
                        {displayMaxMinCharges(effectiveHaulerRateBasis) && (
                            <>
                                <Grid item sm={6} xs={12}>
                                    <ReactHookFormInput label={'Min Charge'} name={'min_charge'} type={'number'} />
                                </Grid>
                                <Grid item sm={6} xs={12}>
                                    <ReactHookFormInput label={'Max Charge'} name={'max_charge'} type={'number'} />
                                </Grid>
                            </>
                        )}
                        {displayMonthlyMaxCharges(effectiveHaulerRateBasis) && (
                            <Grid item sm={6} xs={12}>
                                <ReactHookFormInput label={'Monthly Max Charge'} name={'rate_max_charge_per_period'} type={'number'} />
                            </Grid>
                        )}
                        {pickupRelatedHaulingBasis.has(effectiveHaulerRateBasis?.value) && (
                            <Grid item xs={6}>
                                <ReactHookFormSearchableSelect label={'Exclude Pickups Before Date'} name={'exclude_pickups_before_date'} options={fullMonthSelectOptions} />
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Controller
                                    name={'effective_date'}
                                    control={control}
                                    value={editData?.effective_date}
                                    defaultValue={moment()}
                                    render={({ field: { onChange, value } }) => {
                                        return <DatePicker data-testid={generateTestId('Effective Rate Date', 'date-picker')} fullWidth onChange={onChange} format={DATE_FORMAT} value={value} label="Start Date" disabled={editData != null} required />;
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                    </Grid>

                    <Box display="flex" justifyContent="center" gridGap="30px" paddingTop={'30px'}>
                        <Button variant="contained" color="primary" margin="normal" className="mx-auto my-16" sx={{ paddingTop: 2 }} type="submit">
                            Save
                        </Button>
                    </Box>
                </form>
            </FormProvider>
        </div>
    );
};

export default withRouter(EffectiveRateForm);
