import axios from 'axios';
import { getEquityMembersQuery } from './queries';
import { buildMembershipQuery } from './queries/builders';

const getAllEquityMembershipData = async () => {
    try {
        const membershipQuery = '/v1/equityMembers';
        const equityMembershipQuery = buildMembershipQuery(membershipQuery, {
            headers: {},
            data: {},
        });

        const response = await axios.get(equityMembershipQuery.url);
        let equityMembershipResults = response.data;
        let equityMembershipData = response.data?.results;

        while (equityMembershipResults?.lastEvaluatedKey) {
            const { lastEvaluatedKey } = equityMembershipResults;
            const nextQuery = buildMembershipQuery(
                membershipQuery,
                {
                    headers: {},
                    data: {},
                },
                lastEvaluatedKey
            );
            // eslint-disable-next-line no-await-in-loop
            const nextResponse = await axios.get(nextQuery.url);
            equityMembershipResults = nextResponse.data;
            equityMembershipData = [...equityMembershipData, ...nextResponse.data.results];
        }

        return equityMembershipData;
    } catch (error) {
        throw new Error('Failed to fetch equity membership data.');
    }
};

const getEquityUsersList = async () => {
    let equityUsers = [];

    // data from mongo User collection
    try {
        const queryOptions = { filterDeleted: true, filterSubusers: false, populate: { producers: ['license_number', 'name'] } };
        const equityUsersQuery = getEquityMembersQuery(queryOptions);

        const { data } = await axios.get(equityUsersQuery);
        equityUsers = data;
    } catch (error) {
        throw new Error('Failed to fetch equity users data.');
    }

    const emptyEquityValues = { equity_values: [], transactions: [], contracts: [] };

    // data from dynamo equity-member
    const equityMembershipData = await getAllEquityMembershipData();

    // join mongo user `_id` by dynamo equity-member `id`
    equityUsers = equityUsers.map((equityUser) => {
        const membershipData = equityMembershipData.find((data) => data?.id === equityUser?._id);

        if (membershipData != null) {
            const { equity_values, transactions, contracts } = membershipData;
            return { ...equityUser, equity_values, transactions, contracts };
        }

        return { ...equityUser, ...emptyEquityValues };
    });

    return equityUsers;
};
export default getEquityUsersList;
