const commodities = {
    cream: 'Cream',
    buttermilk: 'Buttermilk',
    condensed: 'Condensed',
    skim: 'Skim',
    permeate: 'Permeate',
    waste_water: 'Waste Water',
};

const config = {
    commodities,
};

export default config;
