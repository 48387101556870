/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, FormControl, InputLabel, MenuItem, TextField, Select, Grid, Box, debounce } from '@material-ui/core';
import { showMessage, addDeferral, editDeferral } from 'app/store/actions';
import SearchableSelectAll from '../../custom-widgets/SearchableSelectAll';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import { generateTestId } from '../../../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        '& .MuiAutocomplete-root .MuiFormControl-root': {
            marginTop: '0',
            marginBottom: '0',
        },
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    map: {
        width: '100%',
        height: '400px',
        justifyContent: 'center',
        margin: '2%',
    },
    timeField: {
        marginTop: '15px',
        marginBottom: '10px',
    },
    list: {
        maxHeight: '280px',
        overflowY: 'scroll',
    },
}));

const DeferralForm = ({ producers, editData, deferrals }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [selectedProducer, setSelectedProducer] = useState(null);
    const [paymentType, setPaymentType] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [selectedDate, setSelectedDate] = useState(moment());
    const [selectedEndDate, setSelectedEndDate] = useState(null);

    useEffect(() => {
        if (editData) {
            const editedProducer = producers.find((producer) => producer.id === editData.producer_id);
            setSelectedDate(editData.original_payment_date_moment);
            setSelectedEndDate(editData.deferred_payment_date_moment);
            setPaymentType(editData.payment_type.toLowerCase());
            setSelectedProducer(editedProducer);
        }
    }, [editData]);

    const handleProducerChange = (event, value) => {
        setSelectedProducer(value);
    };

    useEffect(() => {
        setIsValid(selectedEndDate >= selectedDate && paymentType && selectedProducer);
    }, [selectedDate, selectedEndDate, selectedProducer, paymentType]);

    const handleDateTimeChange = (date) => {
        setSelectedDate(date);
    };
    const handleEndDateTimeChange = (date) => {
        if (date > selectedDate) setSelectedEndDate(date);
    };
    const handlePaymentTypeChange = (event) => {
        setPaymentType(event.target.value);
    };

    const getModel = () => ({
        producer: selectedProducer?.id || undefined,
        payment_type: paymentType || undefined,
        original_payment_date: selectedDate ? selectedDate.startOf('month').format() : undefined,
        deferred_payment_date: selectedEndDate ? selectedEndDate.startOf('day').format() : undefined,
    });

    const post = () => {
        const model = getModel();
        dispatch(addDeferral(model))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Added Deferral' }));
                history.replace({ pathname: '/deferrals' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not add Deferral' }));
            });
    };

    const put = () => {
        const { _id } = editData;
        const model = getModel();
        dispatch(editDeferral(model, _id))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Edited Deferral' }));
                history.replace({ pathname: '/deferrals' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not edit Deferral' }));
            });
    };

    const postAdvanceAndSettlementDeferrals = async () => {
        const advanceModel = getModel();
        advanceModel.payment_type = 'advance';

        const settlementModel = getModel();
        settlementModel.payment_type = 'settlement';

        dispatch(addDeferral(advanceModel))
            .then(() => {
                dispatch(addDeferral(settlementModel));
            })
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Added Deferral' }));
                history.replace({ pathname: '/deferrals' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not add Deferral' }));
            });
    };

    const onSave = () => {
        if (editData) {
            return put();
        }

        if (paymentType === 'advance_and_settlement') {
            return postAdvanceAndSettlementDeferrals();
        }

        return post();
    };

    const transformProducerLabel = (option) => {
        return `${option.license_number} - ${option.name}`;
    };

    return (
        <div className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            data-testid={generateTestId('Original Payment Date', 'date-picker')}
                            views={['year', 'month']}
                            openTo="year"
                            fullWidth
                            disableToolbar
                            required
                            label="Delivery Period"
                            name="original_payment_date"
                            value={selectedDate}
                            onChange={handleDateTimeChange}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker data-testid={generateTestId('Deferred Payment Date', 'date-picker')} fullWidth required label="Deferred Payment Date" name="deferred_payment_date" value={selectedEndDate} minDate={selectedDate} onChange={handleEndDateTimeChange} format="MMMM DD, YYYY" />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <SearchableSelectAll fullWidth disableClearable required label="Producer" value={selectedProducer} options={producers.sort((a, b) => Number(a.license_number) - Number(b.license_number))} onChange={handleProducerChange} customRender={transformProducerLabel}></SearchableSelectAll>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel>Payment Type</InputLabel>
                        <Select id="payment_type" value={paymentType} label="Payment Type *" onChange={handlePaymentTypeChange}>
                            <MenuItem value={'settlement'}>Settlement</MenuItem>
                            <MenuItem value={'advance'}>Advance</MenuItem>
                            {!editData && <MenuItem value={'advance_and_settlement'}>Advance and Settlement</MenuItem>}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Box display="flex" justifyContent="center" gridGap="30px" paddingTop={'15px'}>
                <Button disabled={!isValid} variant="contained" color="primary" margin="normal" className="mx-auto my-16" sx={{ paddingTop: 2 }} onClick={debounce(onSave, 500)}>
                    Save
                </Button>
            </Box>
        </div>
    );
};

export default withRouter(DeferralForm);
