import axios from 'axios';
import { createCloseMonthQuery } from './queries';

const putCloseMonthDetails = async (is_locked, startDate, endDate) => {
    try {
        const query = createCloseMonthQuery({ is_locked, startDate, endDate });
        const request = axios.put(query.url, { ...query.body });
        const { data } = await request;
        return { data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default putCloseMonthDetails;
