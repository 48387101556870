import * as Actions from '../../actions';

const initialState = { selectedHaulingContractTab: 0 };

const selectedHaulingContractTabReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_SELECTED_HAULING_CONTRACT_TAB:
            return { ...state, selectedHaulingContractTab: action.payload };
        default:
            return state;
    }
};

export default selectedHaulingContractTabReducer;
