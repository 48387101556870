import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import AttachmentUploaderWidget from '../../custom-widgets/attachment-uploader/AttachmentUploaderWidget';

const ViewDeliveryReportModal = ({ open, handleClose, fileName }) => {
    return (
        <Dialog open={open} sx={{ width: '100%' }} onClose={handleClose} fullWidth maxWidth="sm">
            <DialogTitle>View Delivery Report</DialogTitle>
            <DialogContent>
                <AttachmentUploaderWidget disabled file={fileName} useExpress />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ViewDeliveryReportModal;
