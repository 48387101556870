import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';

import DocumentHistoryList from './DoucmentHistoryList';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
    },
    box: {
        paddingLeft: '10px',
    },
}));

const DocumentHistoryModal = ({ open, id, type, onClose }) => {
    const classes = useStyles();

    return (
        <Dialog className={classes.root} open={open} fullWidth maxWidth="sm">
            <DialogTitle>Activity Logs</DialogTitle>
            <Box className={classes.box}>
                <DocumentHistoryList id={id} type={type} showFilterControls />
            </Box>
            <DialogActions>
                <Button variant="contained" onClick={onClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DocumentHistoryModal;
