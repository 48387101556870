import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getProducersQuery, getLabReportsQuery, getPickupsQuery, batchQuery } from './queries';

const getWeeklyDateRanges = (year, month) => {
    const startOfMonth = moment(`${year}-${month}`, 'YYYY-MM');
    const firstSaturdayInMonth = startOfMonth.day() === 6 ? startOfMonth : startOfMonth.day(6);
    const saturdaysInMonth = [];
    let currentSaturday = firstSaturdayInMonth.clone();
    while (currentSaturday.month() === startOfMonth.month()) {
        saturdaysInMonth.push(currentSaturday.clone());
        currentSaturday = currentSaturday.clone().day(13);
    }
    const dateRanges = _.map(saturdaysInMonth, (saturday) => ({
        start: saturday.clone(),
        end: saturday.clone().add(7, 'days').subtract(1, 'seconds'),
    }));
    return dateRanges;
};

const getAdminBactoChartDetails = (month, year) =>
    new Promise((resolve, reject) => {
        const producerQuery = getProducersQuery({ fields: ['name', 'id'], filterDeleted: true });

        const producerRequest = axios.get(producerQuery);

        producerRequest
            .then((producerResponse) => {
                const producers = producerResponse.data;
                const producerIds = _.uniq(_.map(producers, 'id'));

                const dateRanges = getWeeklyDateRanges(year, month);
                const { start } = dateRanges[0];
                const { end } = dateRanges.slice(-1)[0];

                const labReportQueries = batchQuery(
                    getLabReportsQuery,
                    {
                        fields: ['producer_id', 'date', 'bacto_scan'],
                        start,
                        end,
                        producerIds,
                        hasBactoScan: true,
                        filterDeleted: true,
                    },
                    'producerIds'
                );

                const labReportRequests = _.map(labReportQueries, (q) => axios.get(q));

                const batchedPickupRequests = dateRanges.map((range) =>
                    _.map(
                        batchQuery(
                            getPickupsQuery,
                            {
                                fields: ['created_at', 'volume', 'producer_id'],
                                start: range.start,
                                end: range.end,
                                producerIds,
                                filterDeleted: true,
                            },
                            'producerIds'
                        ),
                        (q) => axios.get(q)
                    )
                );

                const pickupRequests = _.flatten(batchedPickupRequests);

                axios.all([...labReportRequests, ...pickupRequests]).then(
                    axios.spread((...responses) => {
                        const labReports = _.flatten(responses.slice(0, labReportRequests.length).map((response) => response.data));
                        const pickups = responses.slice(labReportRequests.length, labReportRequests.length + pickupRequests.length).map((response) => response.data);

                        const pickupsByDateRange = [];
                        let l = 0;
                        for (let i = 0; i < batchedPickupRequests.length; ++i) {
                            const bLen = batchedPickupRequests[i].length;
                            pickupsByDateRange.push(_.flatten(pickups.slice(l, l + bLen)));
                            l += bLen;
                        }

                        // eslint-disable-next-line no-shadow
                        const producerIdsWPickups = pickupsByDateRange.map((pickups) =>
                            _.uniq(
                                pickups.map((pickup) => pickup.producer_id),
                                'producer_id'
                            )
                        );

                        const dataSets = _.map(dateRanges, (dateRange, index) => {
                            const pIds = producerIdsWPickups[index];
                            const labReportsInDateRange = _.filter(labReports, (labReport) => moment(labReport.date).isBetween(dateRange.start, dateRange.end) && pIds.includes(labReport.producer_id));
                            const testedIds = _.uniq(_.map(labReportsInDateRange, 'producer_id'));
                            const producersWPickup = producers.filter((producer) => pIds.includes(producer.id));
                            const producerInfo = _.map(producersWPickup, (producer) => ({
                                producerID: producer.id,
                                producer: producer.name,
                                isChecked: _.includes(testedIds, producer.id) ? 'Yes' : 'No',
                            }));
                            const producerInfoOrdered = _.sortBy(producerInfo, 'producer');
                            const date = `${dateRange.start.format('MMM DD')} - ${dateRange.end.format('MMM DD')}`;
                            return {
                                checked: testedIds.length,
                                date,
                                producers: producerInfoOrdered,
                                unchecked: producersWPickup.length - testedIds.length,
                            };
                        });
                        resolve({ dataSets });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getAdminBactoChartDetails;
