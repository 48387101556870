import axios from 'axios';
import { getLinksQuery, updateLinksQuery, updateProducerConfigQuery } from './queries';

export const getLinks = async () => {
    const query = getLinksQuery();
    const request = axios.get(query);
    const { data } = await request;
    return data;
};

export const updateLinks = async (links) => {
    const query = updateLinksQuery({ links });
    const request = axios.patch(query.url, { ...query.body });
    const { data } = await request;
    return data;
};

export const updateProducerConfig = async (producerConfig) => {
    const query = updateProducerConfigQuery(producerConfig);
    const request = axios.patch(query.url, { ...query.body });
    const { data } = await request;
    return data;
};
