import * as Actions from '../../actions';

const initialState = { data: null };

const viewCustomTableConfiguration = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_CUSTOM_TABLE_CONFIGURATION:
        case Actions.CREATE_CUSTOM_TABLE_CONFIGURATION:
        case Actions.RESET_CUSTOM_TABLE_CONFIGURATION:
            // Admin create responses do not contain data
            if (action.payload.data) {
                return { ...state, ...action.payload };
            }
            return state;
        default:
            return state;
    }
};

export default viewCustomTableConfiguration;
