import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContentText } from '@material-ui/core';
import { setError } from 'app/store/actions';
import axios from 'axios';
import _ from 'lodash';
import { getAPIHost, getId } from '../../../utils';
import { getFileURL } from '../../repository';

const useStyles = makeStyles(() => ({
    dialogPaper: {
        minWidth: '70vw',
        maxWidth: '70vw',
        minHeight: '90vh',
        maxHeight: '90vh',
    },
}));

const MonthlyReportDialog = ({ data }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [url, setUrl] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadFrame, setLoadFrame] = useState(false);
    const loadingRef = useRef(loading);
    loadingRef.current = loading;
    const id = getId();

    useEffect(() => {
        if (!_.includes(data.read_status, id)) {
            // eslint-disable-next-line no-console
            axios.patch(`${getAPIHost()}/producer-monthly-reports/${data.id}`, { read_status: [...data.read_status, id] }).catch((err) => {
                dispatch(setError(err));
            });
        }
        if (data.attachment) {
            getFileURL(`MonthlyReport/${data.attachment}`)
                .then((result) => {
                    setUrl(result);
                })
                .catch((error) => {
                    dispatch(setError(error));
                });
        } else {
            axios
                .get(`${getAPIHost()}/download-monthly-report/${data.id}`)
                .then((response) => {
                    getFileURL(response.data.Location)
                        .then((result) => {
                            setUrl(result);
                            setLoadFrame(true);
                        })
                        .catch((error) => {
                            dispatch(setError(error));
                        });
                })
                .catch((error) => {
                    dispatch(setError(error));
                });
        }
    }, [data.attachment, data.id, data.read_status, dispatch, id]);

    const handleClickOpen = () => {
        setOpen(true);
        setLoading(true);
        const interval = setInterval(() => {
            if (loadingRef.current) {
                setOpen(false);
                setLoadFrame(false);
                setTimeout(() => {
                    setOpen(true);
                    setLoadFrame(true);
                }, 25);
            } else {
                clearInterval(interval);
            }
        }, 5000);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const hideSpinner = () => {
        setLoading(false);
    };

    return (
        <div>
            <Button variant="contained" color="primary" className="my-16" aria-label="View Report" onClick={handleClickOpen}>
                {!!data.is_adjusted ? 'View Adjusted Report' : 'View Report'}
            </Button>

            <Dialog classes={{ paper: classes.dialogPaper }} open={open} onClose={handleClose} aria-labelledby="dialog-title">
                <DialogTitle id="dialog-title">
                    <div className="flex items-center justify-between">
                        Report
                        <IconButton onClick={() => handleClose()}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>
                <DialogContent id="dialog-content">
                    <div>
                        {loading && (
                            <div className="flex items-center justify-center pb-20">
                                <CircularProgress />
                            </div>
                        )}
                        {!!loadFrame && (
                            <DialogContentText>
                                <iframe
                                    className="iframe"
                                    name="pdf"
                                    id="reportLoader"
                                    title="pdf"
                                    src={`https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`}
                                    style={{
                                        minWidth: '100%',
                                        maxWidth: '100%',
                                        minHeight: '70vh',
                                        maxHeight: '70vh',
                                        border: 'none',
                                    }}
                                    onLoad={() => hideSpinner()}
                                    loading="lazy"
                                />
                            </DialogContentText>
                        )}
                    </div>
                </DialogContent>
                <DialogActions>
                    <div
                        style={{
                            margin: 'auto',
                            width: 'fit-content',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Button variant="contained" onClick={() => handleClose()} color="secondary">
                            Close
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withRouter(MonthlyReportDialog);
