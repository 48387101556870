import React from 'react';
import { MenuItem, Menu, IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ActionColumn = ({ disabled, options }) => {
    return {
        field: 'actions',
        headerName: '',
        sortable: false,
        filterable: false,
        flex: 0.1,
        disableColumnMenu: true,
        disableExport: true,
        maxWidth: 50,
        renderCell: (params) => {
            const [anchorEl, setAnchorEl] = React.useState(null);

            const handleClick = (event) => {
                setAnchorEl(event.currentTarget);
            };

            const handleClose = () => {
                setAnchorEl(null);
            };

            const handleAction = (action) => {
                action(params.row);
                handleClose();
            };

            const isDisabled = disabled && disabled(params.row);

            return (
                <>
                    <IconButton
                        aria-label="Actions"
                        aria-controls={`actions-menu-${params.row.id}`}
                        aria-haspopup="true"
                        onClick={handleClick}
                        disabled={isDisabled}
                        // wrap
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu id={`actions-menu-${params.row.id}`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                        {options.map((option) => {
                            if (option.shouldRender && !option.shouldRender(params.row)) return null;
                            return (
                                <MenuItem key={option.label} onClick={() => handleAction(option.action)}>
                                    {option.label}
                                </MenuItem>
                            );
                        })}
                    </Menu>
                </>
            );
        },
    };
};

export default ActionColumn;
