const FadedChips = [
    {
        props: {
            variant: 'faded',
            color: 'success',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${theme.palette.success.main}`,
            color: 'black',
        }),
    },
    {
        props: {
            variant: 'faded',
            color: 'error',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${theme.palette.error.main}`,
            color: 'black',
        }),
    },
    {
        props: {
            variant: 'faded',
            color: 'warning',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${theme.palette.warning.main}`,
            color: `black`,
        }),
    },
    {
        props: {
            variant: 'faded',
            color: 'primary',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${theme.palette.primary.main}`,
            color: `black`,
        }),
    },
    {
        props: {
            variant: 'faded',
            color: 'secondary',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${theme.palette.secondary.main}`,
            color: `black`,
        }),
    },
    {
        props: {
            variant: 'faded',
            color: 'info',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${theme.palette.info.main}`,
            color: `black`,
        }),
    },
    {
        props: {
            variant: 'faded',
            color: 'pink',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${theme.palette.pink.main}`,
            color: `black`,
        }),
    },
    {
        props: {
            variant: 'faded',
            color: 'purple',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${theme.palette.purple.main}`,
            color: `black`,
        }),
    },
    {
        props: {
            variant: 'faded',
            color: 'yellow',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), ${theme.palette.yellow.main}`,
            color: `black`,
        }),
    },
];

const DarkChips = [
    {
        props: {
            variant: 'filled',
            shade: 'dark',
            color: 'success',
        },
        style: ({ theme }) => ({
            background: `${theme.palette.success.dark}`,
            color: theme.palette.success.contrastText,
        }),
    },
];

const OpacityTwentyChips = [
    {
        props: {
            variant: 'opacity-twenty',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), ${theme.palette.success.main}`,
            border: `1px solid ${theme.palette.success.main}`,
            color: theme.palette.success.main,
        }),
    },
    {
        props: {
            variant: 'opacity-twenty',
            color: 'error',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), ${theme.palette.error.main}`,
            color: 'black',
        }),
    },
    {
        props: {
            variant: 'opacity-twenty',
            color: 'warning',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), ${theme.palette.warning.main}`,
            color: `black`,
        }),
    },
    {
        props: {
            variant: 'opacity-twenty',
            color: 'primary',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), ${theme.palette.primary.main}`,
            color: `black`,
        }),
    },
    {
        props: {
            variant: 'opacity-twenty',
            color: 'secondary',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), ${theme.palette.secondary.main}`,
            color: `black`,
        }),
    },
    {
        props: {
            variant: 'opacity-twenty',
            color: 'info',
        },
        style: ({ theme }) => ({
            background: `linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), ${theme.palette.info.main}`,
            color: `black`,
        }),
    },
];

const MMThemeChip = {
    variants: [...FadedChips, ...OpacityTwentyChips, ...DarkChips],
};

export default MMThemeChip;
