import axios from 'axios';
import { getProcessorFmmoMonthlyTotalsQuery, getProcessorsQuery } from './queries';
import generateErrorMessage from './utils/generateErrorMessage';
import _ from 'lodash';
import { arrayToMap } from '../../utils';
import { getBulkQueryData } from './utils';

const getProcessorFmmoMonthlyTotals = async ({ startDate, endDate, selectedFmmo }) => {
    const processorFmmoMonthlyTotalsQuery = getProcessorFmmoMonthlyTotalsQuery({ startDate, endDate, selectedFmmo });
    try {
        const processorTotalsResponse = await axios.get(processorFmmoMonthlyTotalsQuery);

        const processorTotalsData = processorTotalsResponse.data;
        const processorIds = _.uniq(_.map(processorTotalsData, 'processor.id'));
        const processors = await getBulkQueryData(getProcessorsQuery, { fields: ['id', 'processor_pooling_fmmo'], ids: processorIds }, 'ids');
        const processorMap = arrayToMap(processors, 'id');

        const newProcessorTotalsData = processorTotalsData.map((processor) => {
            const matchedProcessor = processorMap[processor.processor.id];
            const newProcessor = { ...processor };
            if (matchedProcessor?.processor_pooling_fmmo != null) {
                newProcessor.processor_pooling_fmmo = matchedProcessor.processor_pooling_fmmo;
            }
            return newProcessor;
        });

        return newProcessorTotalsData;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        const descriptiveError = generateErrorMessage(err, 'get', 'processor fmmo monthly totals');
        throw descriptiveError;
    }
};

export default getProcessorFmmoMonthlyTotals;
