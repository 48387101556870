import React, { useContext, useState } from 'react';
import { Alert, Grid } from '@mui/material';
import { MapContext } from '../MapContext';
import { getShapeCenter, GeoJSONPoint } from './GeofenceManager.helpers';
import BareMap from '../BareMap';
import LatLngDisplay from '../../controls/LatLngDisplay';
import AddressSearchBar from '../../controls/AddressSearchBar';
import GeofenceArtist from './GeofenceArtist';
import useDrawingManager from '../useDrawingManager';

const GeofenceMap = ({ center, searchable, displayOnly = false, maxFences = 2, user_id }) => {
    const { fences: geofences, selectedIndex, setFences, error, setError } = useContext(MapContext);

    const canEdit = geofences.length <= maxFences && !displayOnly;
    const canDraw = geofences.length < maxFences && !displayOnly;

    const addGeofence = (type, addedFence) => {
        const newGeofences = [...geofences];
        const newFence = {
            ...addedFence,
            type,
            site_type: 'primary',
            enabled: true,
        };
        newGeofences.push(newFence);
        setFences(newGeofences);
    };

    const {
        DrawingControls,
        MapManager,
        initializeInstance,
        handleShapeChange,
        // wrap
    } = useDrawingManager({ addGeofence, canDraw, canEdit });

    const handleChange = (shapeType, index) => {
        const newGeofences = [...geofences];
        const newGeofenceAttributres = handleShapeChange(shapeType, index);
        if (!newGeofenceAttributres) return;
        newGeofences[index] = { ...newGeofences[index], ...newGeofenceAttributres };

        setFences(newGeofences);
    };

    const handleOnClick = (coordinates) => {
        if (!canDraw) return;
        // add a new circle with a default radius of 200 meters
        const newCenter = coordinates.latLng.toJSON();
        const geoJsonCenter = GeoJSONPoint([Number(newCenter.lng), Number(newCenter.lat)]);
        const newRadius = 200;
        addGeofence('radialGeofence', { center: geoJsonCenter, radius_meters: newRadius });
    };

    if (!geofences) return null;

    return (
        <Grid container spacing={2}>
            {searchable && (
                <Grid item xs={12}>
                    <AddressSearchBar />
                </Grid>
            )}
            {canDraw && (
                <Grid item xs={12}>
                    <Alert severity="info">Click the map to start creating a geofence around your desired location.</Alert>
                </Grid>
            )}
            {!!error && (
                <Grid item xs={12}>
                    <Alert severity="error">{error}</Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                <BareMap
                    center={center}
                    mapTypeId="satellite"
                    mapContainerStyle={{ height: '500px', width: '100%' }}
                    zoom={16}
                    onClick={handleOnClick}
                    // wrap
                >
                    {MapManager}
                    <GeofenceArtist
                        handleChange={handleChange}
                        canEdit={canEdit}
                        onLoad={initializeInstance}
                        // wrap
                    />
                </BareMap>
            </Grid>
            {!displayOnly && (
                <>
                    {DrawingControls}
                    <LatLngDisplay center={getShapeCenter(geofences[selectedIndex])} canEdit={canEdit} handleChange={handleChange} />
                </>
            )}
        </Grid>
    );
};

export default GeofenceMap;
