/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { renameKey } from '../../utils';
import { getCustomNotificationDefinitionsQuery } from './queries';

const getCustomNotificationDefinitions = async (user) => {
    try {
        const params = { user };

        const customNotificationDefinitionsQuery = getCustomNotificationDefinitionsQuery(params);
        const customNotificationDefinitionsRequest = axios.get(customNotificationDefinitionsQuery);

        const { data } = await customNotificationDefinitionsRequest;
        renameKey(data, '_id', 'id');

        // fields param in get not working...
        for (let i = 0; i < data.length; ++i) {
            delete data[i].created_at;
            delete data[i].updated_at;
            delete data[i].deleted_at;
            delete data[i].__v;
        }
        return data;
    } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getCustomNotificationDefinitions;
