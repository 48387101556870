import React, { useContext } from 'react';
import { Button, Grid } from '@mui/material';
import DialogWrapper from '../../../../custom-widgets/dialogs/DialogWrapper';
import { MapContext } from '../MapContext';
import GeofenceMap from './GeofenceMap';

export const GeofenceDialogActions = ({ handleCancel, onSave, onClear, disabled }) => (
    <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Grid item xs={8} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
            <Button sx={{ margin: '8px' }} variant="outlined" color="error" onClick={onClear}>
                Clear
            </Button>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button sx={{ margin: '8px' }} variant="outlined" onClick={handleCancel}>
                Cancel
            </Button>
            <Button sx={{ margin: '8px' }} variant="contained" color="primary" onClick={onSave} disabled={disabled}>
                Save
            </Button>
        </Grid>
    </Grid>
);

const GeofenceDialog = ({ open, onClose, onSave, maxFences, center, user_id }) => {
    const { error, setError, fences, setFences, focusMap, resetFences } = useContext(MapContext);

    const handleClose = () => {
        resetFences();
        onClose();
    };

    const handleClear = () => {
        setFences([]);
        setError();
    };

    const handleSave = () => {
        const fencesToSave = fences.map((fence) => {
            const newFence = { ...fence };
            delete newFence.instance;
            return newFence;
        });
        focusMap(fences[0]);
        onSave(fencesToSave);
    };

    return (
        <DialogWrapper
            open={open}
            Title="Geofence"
            hideClose={false}
            handleClose={handleClose}
            actions={
                <GeofenceDialogActions
                    onClose={onClose}
                    handleCancel={handleClose}
                    onSave={handleSave}
                    onClear={handleClear}
                    disabled={fences < 1 || !!error}
                    // wrap
                />
            }
            // wrap
        >
            <GeofenceMap geofences={fences} maxFences={maxFences} center={center} user_id={user_id} searchable />
        </DialogWrapper>
    );
};

export default GeofenceDialog;
