import axios from 'axios';
import { deleteAdhocPaymentReportQuery } from './queries';

const deleteAdhocPaymentReport = async (key) => {
    try {
        const query = deleteAdhocPaymentReportQuery(key);
        const request = await axios.delete(query);
        return request.data;
    } catch (err) {
        return { error: 'Cannot delete payment report' };
    }
};

export default deleteAdhocPaymentReport;
