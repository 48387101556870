/* eslint-disable no-underscore-dangle */

import { parseBool } from '../utils';
import mockedSplits from './MockedSplits';
import MockSplit from './MockSplit';
import './typedefs';

/**
 * The Split Manager houses all the internal machinations of the Split mechanism, and provides a point of access to the public methods required to put Split into action.
 *
 * The Split Manager is a singleton - meaning that only one instance can ever exist at a time.
 *
 */
class SplitManager {
    /**
     * Create new Split Manager instance.
     */
    constructor() {
        /**
         * @private
         * @type {MockSplit[]}
         */
        this._mockedSplits = mockedSplits;

        /**
         * @private
         * @type {*}
         */
        this._attributes = {};

        /**
         * @private
         * @type {Stage | null}
         */
        this._environment = this.getStage(window);
    }

    /**
     * Updates user attributes.
     *
     * This completely overwrites existing stored attributes.
     *
     * If user attributes contain an id, updates user_client to reflect new id.
     *
     * If called with no attributes parameter, resets stored attributes to an empty object.
     *
     * @param {Attributes} attributes user attributes
     */
    setAttributes = (attributes = {}) => {
        this._attributes = attributes;
    };

    /**
     * Set a single attribute.
     *
     * Either adds new attribute or overwrites existing stored attribute of the same name.
     *
     * If attribute is user id,  updates user client to reflect new id.
     *
     * @param {string} key
     * @param {Attribute} value
     */
    setAttribute = (key, value) => {
        this._attributes[key] = value;
    };

    /**
     * Get stored user attributes.
     *
     * @returns {Attributes} stored user attributes, keyed by name
     */
    getAttributes = () => this._attributes;

    /**
     * Get a single attribute by name.
     *
     * If no attribute exists with specified name, will return null.
     *
     * @param {string} key
     * @returns {Attribute} attribue if exist else null
     */
    getAttribute = (key) => this._attributes?.[key] || null;

    /**
     * @private
     *
     * Find a single mock split by name
     *
     * @param {string} splitName
     * @returns {MockSplit}
     */
    getMockSplit = (splitName) => this._mockedSplits.find((mockSplit) => mockSplit.name === splitName);

    /**
     * @private
     * Get Treatment for a specified Mock Split (mock only).
     *
     * Evaluates Split as specified by splitName based on current stored attributes and returns treatment..
     *
     * @param {string} splitName name of split
     * @returns {Treatment} treatment for splitName
     */
    getMockTreatment = (splitName) => this.getMockSplit(splitName)?.getTreatment(this._attributes, this._environment) || 'control';

    /**
     * @private
     * Get a series of Treatments for an array of Mock Splits (mock only).
     *
     * Evaluates array of Splits as specified by splitNames based on current stored attributes and returns object containing Splits and their respective Treatments as key/value pairs
     *
     * @param {string[]} splitNames array of split names
     * @returns {Treatments} treatments by name for array of split names
     */
    getMockTreatments = (splitNames) => Object.assign({}, ...splitNames.map((splitName) => this.getMockSplit(splitName)?.getStaticEvaluation(this._attributes) || { [splitName]: 'control' }));

    /**
     * Get Treatment for a specified Split.
     *
     * Evaluates Split as specified by splitName based on current stored attributes and returns treatment.
     *
     * If the Split service is not correctly initialized, the Split in question has been killed, or the Split in question fails to evaulate to a treatment based on its targeting rules, the treament returned will be 'control'.
     *
     * @param {string} splitName name of split
     * @returns {Treatment} treatment for splitName
     */
    getTreatment = (splitName) => this.getMockTreatment(splitName);

    /**
     * Get a series of Treatments for an array of Splits.
     *
     * Evaluates array of Splits as specified by splitNames based on current stored attributes and returns object containing Splits and their respective Treatments as key/value pairs
     *
     * @param {string[]} splitNames array of split names
     * @returns {Treatments} treatments by name for array of split names
     */
    getTreatments = (splitNames) => this.getMockTreatments(splitNames);

    /**
     * Get Treatment and associated Config for a specified Split.
     *
     * Evaluates Split as specified by splitName to obtain both Treatment and associated Config, based on current stored attributes.
     *
     * Returns object containing with properties treatment and config, containing the evaluated Treatment and Config respectively.
     *
     * @param {string} splitName name of split
     * @returns {TreatmentWithConfig} string treatment and config for splitName
     */
    getTreatmentWithConfig = (splitName) => this.getMockTreatment(splitName);

    /**
     * Get Treatment and associated Config for an array of Splits.
     *
     * Evaluates array of Splits as specified by splitNames based on current stored attributes, to obtain both Treatment and associated Config for each Split.
     *
     * Returns object containing with properties treatment and config, containing the evaluated treatment and config respectively.
     *
     * @param {string[]} splitNames array of split names
     * @returns {TreatmentsWithConfig} treatments and config by name for array of split names
     */
    getTreatmentsWithConfig = (splitNames) => this.getMockTreatments(splitNames);

    /**
     * Get Treatment for a specified Split and cast it to a boolean.
     *
     * Treatments that will evaluate to true are 'on', 'yes', 'true', '1', 'active -  capitalization notwithstanding.
     *
     * All other treatments will evaluate to false, including the control statement.
     *
     * @param {string} splitName
     * @returns {BooleanTreatment} treatment, cast to boolean
     */
    getBooleanTreatment = (splitName) => parseBool(this.getTreatment(splitName));

    /**
     * Get a series of Treatments for an array of Splits, all cast to boolean.
     *
     * Evaluates array of Splits as specified by splitNames based on current stored attributes and returns object containing Splits and their respective boolean Treatments as key/value pairs
     *
     * @param {string[]} splitNames array of split names
     * @returns {BooleanTreatments} treatments by name for array of split names
     */
    getBooleanTreatments = (splitNames) =>
        Object.assign(
            ...[splitNames].flat().map((splitName) => {
                return { [splitName]: this.getBooleanTreatment(splitName) };
            })
        );

    /**
     * Determine environment based on windows.location
     * @returns {Stage} - formatted environment as a string
     */
    getStage = (window) => {
        const location = window.location.hostname;
        if (location.includes('localhost')) {
            return 'LOCAL';
        }
        if (location.includes('demo')) {
            return 'DEMO';
        }
        if (location.includes('stg')) {
            return 'STAGING';
        }
        if (location.includes('dev')) {
            return 'DEV';
        }
        return 'PRODUCTION';
    };
}

export default SplitManager;
