import axios from 'axios';
import _ from 'lodash';
import { renameKey, toTitleCase } from '../../utils';
import { getProducersQuery, getPayReportsQuery, getDeferralReportsQuery } from './queries';
import { buildExpressQuery } from './queries/builders';
import moment from 'moment-timezone';
import { ReportType } from '../constants/report';

const getMonthlyPayReports = async (month, year, region) => {
    const startDate = moment(`${year}-${month}`).startOf('month').format();
    const endDate = moment(`${year}-${month}`).endOf('month').format();
    const producersRequest = axios.get(getProducersQuery({ queryBuilder: buildExpressQuery }));
    const reportsRequest = axios.get(getPayReportsQuery({ startDate, endDate, region }));
    const deferralsRequest = axios.get(getDeferralReportsQuery({ startDate, endDate }));

    const [producersResponse, reportsResponse, deferralsResponse] = await axios.all([producersRequest, reportsRequest, deferralsRequest]);
    const [producers, reports, deferrals] = [producersResponse.data, reportsResponse.data, deferralsResponse.data];
    renameKey(producers, '_id', 'id');
    renameKey(reports, '_id', 'id');
    renameKey(deferrals, '_id', 'id');

    const fmmos = new Set();

    let { settlement, advance } = reports;
    settlement = _.map(settlement, (entry) => {
        if (entry.federal_order == null) {
            // eslint-disable-next-line no-param-reassign
            entry.federal_order = 'MISSING';
        }
        fmmos.add(entry.federal_order);
        return { ...entry, type: ReportType.settlement, license_number: entry.producer_number };
    });
    advance = _.map(advance, (entry) => {
        if (entry.federal_order == null) {
            // eslint-disable-next-line no-param-reassign
            entry.federal_order = 'MISSING';
        }
        fmmos.add(entry.federal_order);
        return { ...entry, type: ReportType.advance, license_number: entry.producer_number };
    });

    const fmmoOptions = Array.from(fmmos, (federal_order) => ({
        name: `FMMO ${toTitleCase(federal_order, '_')}`,
        value: `FMMO${federal_order}`,
        federal_order,
    })).sort((a, b) => a.federal_order.localeCompare(b.federal_order, undefined, { numeric: true }));

    const usersWithDeferrals = [];
    const deferral = [];
    deferrals.forEach((entry) => {
        // eslint-disable-next-line no-underscore-dangle
        const producerWithDate = `${entry.producer._id}${moment(entry.deferred_payment_date).startOf('day')}`;
        if (!usersWithDeferrals.includes(producerWithDate)) {
            // eslint-disable-next-line no-underscore-dangle
            usersWithDeferrals.push(producerWithDate);
            deferral.push({ ...entry, type: ReportType.deferral });
        }
    });

    return {
        data: {
            fmmoOptions,
            deferral,
            settlement,
            advance,
        },
    };
};

export default getMonthlyPayReports;
