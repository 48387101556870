import axios from 'axios';
import _ from 'lodash';
import { getPenaltyStatusQuery } from './queries';

const getPenaltyStatusDetails = async (date) => {
    try {
        const penaltyStatusRequest = await axios.get(getPenaltyStatusQuery({ date }));
        const penaltyStatusDetails = penaltyStatusRequest.data;

        return { penaltyStatusDetails };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getPenaltyStatusDetails;
