import React, { useState, useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { DialogContentText } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { getFileURL } from '../../repository';
import path from 'path';
import { result } from 'lodash';
import { setSchedulePublishedProcessor } from '../../store/actions';
import { showMessage } from '../../store/actions/fuse/message.actions';

const SharedFilesDialog = ({ file, closeDialog, open, selectedId, forceFetch, fileName }) => {
    const [openDialog, setOpen] = useState(true);
    const [openPreview, setOpenPreview] = useState(false);
    const [loading, setLoading] = useState(false);
    const loadingRef = useRef(loading);
    loadingRef.current = loading;
    const [url, setUrl] = useState('');

    const isImage = file ? path.extname(file) === '.jpg' || path.extname(file) === '.jpeg' || path.extname(file) === '.png' : false;

    useEffect(() => {
        (async () => {
            try {
                const urlResults = await getFileURL(`SharedFiles/${file}`);
                setUrl(urlResults);
            } catch (e) {
                showMessage({ message: 'There was a problem accessing your file. Please contact support.' });
            }
        })();
    }, [file]);

    const handleClose = () => {
        setOpen(false);
        closeDialog();
    };

    const handleClosePreview = () => {
        setOpenPreview(false);
        setLoading(false);
        handleClose();
    };

    const handlePreview = () => {
        setOpen(false);
        setOpenPreview(true);
        if (!isImage) {
            setLoading(true);
            const interval = setInterval(() => {
                if (loadingRef.current) {
                    setOpenPreview(false);
                    setTimeout(() => {
                        setOpenPreview(true);
                    }, 50);
                } else {
                    clearInterval(interval);
                }
            }, 5000);
        }
    };

    const hideSpinner = () => {
        setLoading(false);
    };

    return (
        <div>
            <Dialog open={openDialog} onClose={() => handleClose()} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
                <DialogTitle id="draggable-dialog-title">Selection Options</DialogTitle>
                <DialogContent>
                    <div
                        style={{
                            margin: 'auto',
                            width: 'fit-content',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    />
                    <div className="flex flex-row items-center justify-center p-12">
                        <Button type="submit" variant="contained" color="primary" className="m-4 ml-4 justify-center" aria-label="Open File" onClick={() => handlePreview()}>
                            View File
                        </Button>
                    </div>
                    <div className="flex flex-row items-center justify-center p-12">
                        <Button variant="contained" color="primary" className="m-4 ml-4 justify-center" component="a" href={url} target="_blank" role="button">
                            Download File
                        </Button>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleClose()} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            {openPreview && (
                <Dialog open={openPreview} onClose={() => handleClosePreview()} aria-labelledby="dialog-title" fullWidth maxWidth="md">
                    <DialogTitle id="dialog-title">
                        <div className="flex items-center justify-between">
                            Shared File
                            <IconButton onClick={() => handleClosePreview()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </DialogTitle>
                    <DialogContent id="dialog-content">
                        {!isImage && (
                            <div>
                                {loading && (
                                    <div className="flex items-center justify-center pb-20">
                                        <CircularProgress />
                                    </div>
                                )}
                                <DialogContentText>
                                    <div className="">
                                        <iframe
                                            title="pdf"
                                            src={`https://docs.google.com/gview?url=${encodeURIComponent(url)}&embedded=true`}
                                            style={{
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                minHeight: '70vh',
                                                maxHeight: '70vh',
                                                border: 'none',
                                            }}
                                            onLoad={() => hideSpinner()}
                                            loading="lazy"
                                        />
                                    </div>
                                </DialogContentText>
                            </div>
                        )}
                        {isImage && (
                            <DialogContentText>
                                <img style={{ width: '100%', height: '30%' }} src={`${url}`} alt="No slip found" />
                            </DialogContentText>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <div
                            style={{
                                margin: 'auto',
                                width: 'fit-content',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Button variant="contained" onClick={() => handleClosePreview()} color="secondary">
                                Close
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default withRouter(SharedFilesDialog);
