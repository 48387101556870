import { dataTableCSVExport } from '../../../../utils';
import cdiConfig from './CDI';
import prairieConfig from './PRAIRIE';
import tcjConfig from './TCJ';
import Region from '../../../constants/region';

const getConfig = (region) => {
    const config = {
        datatable: {
            getTitle: () => 'Dropoff Summary Statistics',
            getColumns: () => {
                switch (region) {
                    case Region.CDI:
                        return cdiConfig.getColumns();
                    case Region.PRAIRIE:
                        return prairieConfig.getColumns();
                    case Region.TCJ.ERIE:
                    case Region.TCJ.KYTN:
                    case Region.TCJ.LIBERTY:
                    case Region.TCJ.NEBRASKA:
                    case Region.TCJ.GPDA:
                    case Region.TCJ.WHITEEAGLE:
                        return tcjConfig.getColumns();
                    default:
                        /** Note: We are explicitly not setting a default for this view as we
                         * don't want it in the demo environment at this time
                         */
                        return [];
                }
            },
            getData: (data) => {
                switch (region) {
                    case Region.CDI:
                        return cdiConfig.getData(data);
                    case Region.PRAIRIE:
                        return prairieConfig.getData(data);
                    case Region.TCJ.ERIE:
                    case Region.TCJ.KYTN:
                    case Region.TCJ.LIBERTY:
                    case Region.TCJ.NEBRASKA:
                    case Region.TCJ.GPDA:
                    case Region.TCJ.WHITEEAGLE:
                        return tcjConfig.getData(data);
                    default:
                        /** Note: We are explicitly not setting a default for this view as we
                         * don't want it in the demo environment at this time
                         */
                        return {};
                }
            },
            getOptions: () => ({
                pageSize: 10,
                exportMenu: [
                    {
                        label: 'Export CSV',
                        exportFunc: dataTableCSVExport('Dropoff Statistics'),
                    },
                ],
            }),
        },
    };
    return config;
};

export default getConfig;
