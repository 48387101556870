/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { changeFluidItemDateQuery } from './queries';

const changeFluidItemDate = async ({ id, date }) => {
    try {
        const scheduleItemsQuery = changeFluidItemDateQuery({ id, date });
        const scheduleItemsRequest = axios.put(scheduleItemsQuery);
        const { data } = await scheduleItemsRequest;
        return data;
    } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default changeFluidItemDate;
