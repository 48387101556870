import axios from 'axios';
import { getUsersQuery } from './queries';

const getUserReportSchedule = (id) =>
    new Promise((resolve, reject) => {
        const userQuery = getUsersQuery({ ids: [id] });
        const userRequest = axios.get(userQuery);
        userRequest
            .then((userResponse) => {
                // eslint-disable-next-line camelcase
                const schedule = userResponse.data[0].scheduled_reports;
                resolve({ report: schedule });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getUserReportSchedule;
