import axios from 'axios';
import { getUsersQuery } from './queries';

const getUserListDetails = ({ role, filterDeleted, fields } = {}) =>
    new Promise((resolve, reject) => {
        const params = { filterSubUsers: true };
        if (fields) {
            params.fields = fields;
        }
        if (filterDeleted) {
            params.filterDeleted = filterDeleted;
        }
        if (role) {
            params.role = role;
        }
        const query = getUsersQuery(params);
        const request = axios.get(query);

        request
            .then((response) => {
                resolve({ users: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getUserListDetails;
