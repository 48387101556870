import use_express_dropoff_endpoint from './use_express_dropoff_endpoint/use_express_dropoff_endpoint';
import use_express_route_session_endpoint from './use_express_route_session_endpoint/use_express_route_session_endpoint';
import use_express_pickup_endpoint from './use_express_pickup_endpoint/use_express_pickup_endpoint';
import use_express_lab_endpoint from './use_express_lab_endpoint/use_express_lab_endpoint';
import date_range_preset_configs from './date_range_preset_configs/date_range_preset_configs';
import can_access_sensitive_info_dialog from './can_access_sensitive_info_dialog/can_access_sensitive_info_dialog';
import can_access_hauler_rates from './can_access_hauler_rates/can_access_hauler_rates';
import can_access_assignment_configuration from './can_access_assignment_configuration/can_access_assignment_configuration';
import hauling_permissions from './hauling_permissions/hauling_permissions';
import can_use_new_splits from './can_use_new_splits/can_use_new_splits';
import can_access_manifest_view from './can_access_manifest_view/can_access_manifest_view';
import can_demo_geofence_tool from './can_demo_geofence_tool/can_demo_geofence_tool';
import can_view_new_and_old_geofence_data from './can_view_new_and_old_geofence_data/can_view_new_and_old_geofence_data';
import can_access_merchandise_app from './can_access_merchandise_app/can_access_merchandise_app';
import can_access_pooling from './can_access_pooling/can_access_pooling';
import can_access_stocks from './can_access_stocks/can_access_stocks';
import use_two_level_banking_approval from './use_two_level_banking_approval/use_two_level_banking_approval';
import use_new_lab_form from './use_new_lab_form/use_new_lab_form';
import can_access_equity_pages from './can_access_equity_pages/can_access_equity_pages';
import can_manage_mobile_app_processor_workflow from './can_manage_mobile_app_processor_workflow/can_manage_mobile_app_processor_workflow';
import use_new_hauler_company_form from './use_new_hauler_company_form/use_new_hauler_company_form';
import use_legacy_processor_form from './use_legacy_processor_form/use_legacy_processor_form';
import can_access_hauling_charges from './can_access_hauling_charges/can_access_hauling_charges';
import can_access_hauler_assignments from './can_access_hauler_assignments/can_access_hauler_assignments';
import can_access_hauler_pay_reports from './can_access_hauler_pay_reports/can_access_hauler_pay_reports';
import can_use_weekly_pay_frequency from './can_use_weekly_pay_frequency/can_use_weekly_pay_frequency';
import can_access_hauling_contract from './can_access_hauling_contract/can_access_hauling_contract';
import can_access_equity_membership_pages from './can_access_equity_membership_pages/can_access_equity_membership_pages';
import splits_for_test_runs from './test_splits';
import can_access_cdi_quality_report_producer from './can_access_cdi_quality_report_producer/can_access_cdi_quality_report_producer';
import can_access_adhoc_reports from './can_access_adhoc_reports/can_access_adhoc_reports';
import can_use_member_center from './can_use_member_center/can_use_member_center';
import can_access_plant_management from './can_access_plant_management/can_access_plant_management';
import can_access_processor_management from './can_access_processor_management/can_access_processor_management_configuration';
import can_create_stock_holder from './can_create_stock_holder/can_create_stock_holder';
import can_access_detailed_pay_statements from './can_access_detailed_pay_statements/can_access_detailed_pay_statements';
import can_opt_out_mailed_pay_statements from './can_opt_out_mailed_pay_statements/can_opt_out_mailed_pay_statements';
import dark_launch_processed_fluids from './dark_launch_processed_fluids/dark_launch_processed_fluids';
import dark_launch_pf_rs_seals from './dark_launch_pf_rs_seals/dark_launch_pf_rs_seals';
import use_list_view_schedule from './use_list_view_schedule/use_list_view_schedule';
import can_use_payment_line_item_configs from './can_use_payment_line_item_configs.js/can_use_payment_line_item_configs';

const mockedSplits = [
    ...splits_for_test_runs,
    use_express_pickup_endpoint,
    use_express_dropoff_endpoint,
    use_express_route_session_endpoint,
    use_express_lab_endpoint,
    date_range_preset_configs,
    can_access_sensitive_info_dialog,
    can_access_hauler_rates,
    can_access_assignment_configuration,
    hauling_permissions,
    can_use_new_splits,
    can_access_manifest_view,
    can_demo_geofence_tool,
    can_opt_out_mailed_pay_statements,
    can_view_new_and_old_geofence_data,
    can_access_merchandise_app,
    can_access_pooling,
    can_access_stocks,
    use_two_level_banking_approval,
    use_new_lab_form,
    can_access_equity_pages,
    can_access_equity_membership_pages,
    can_manage_mobile_app_processor_workflow,
    use_new_hauler_company_form,
    use_legacy_processor_form,
    can_access_hauling_charges,
    can_access_hauler_assignments,
    can_access_hauler_pay_reports,
    can_use_weekly_pay_frequency,
    can_access_cdi_quality_report_producer,
    can_access_hauling_contract,
    can_access_adhoc_reports,
    can_use_member_center,
    can_access_processor_management,
    can_access_plant_management,
    can_create_stock_holder,
    can_access_detailed_pay_statements,
    dark_launch_processed_fluids,
    dark_launch_pf_rs_seals,
    use_list_view_schedule,
    can_use_payment_line_item_configs,
    // This comment forces multi line array to help mitigate git conflicts
];
export default mockedSplits;
