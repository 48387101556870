import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { renameKey, formatLabsForComponentCalculation } from '../../utils';
import { calculateShrinkAmount, calculateShrinkPercentage } from '../helpers/dropoffs/utils';
import { getRouteSessionsQuery, getPickupsQuery, getDropoffsQuery, getProducersQuery, getProcessorsQuery, getTrailersQuery, getLabReportsQuery, getRoutesQuery, getDriversQuery, getHaulingCompaniesQuery, getAdjustmentsQuery } from './queries';
import { getActiveInprogressDropoffs } from './dropoffs/inprogress-dropoff';
import { buildExpressQuery } from './queries/builders';
import { calculateComponents, getBulkQueryData, getProcessorByPickupId, getPickupsByDropoffId } from './utils';

const calculatedRouteSessionDropoffsMeteredTotal = (routeSessionDropoffs) => {
    // remove (continuous) duplicate metered values
    const routeSessionDropoffsMeteredDuplicatedRemoved = _.reduce(
        routeSessionDropoffs,
        (acc, val) => {
            if (acc.length === 0) return [_.pick(val, ['driver_id', 'processor_id', 'metered'])];
            if (acc.slice(-1)[0].metered === val.metered && acc.slice(-1)[0].processor_id === val.processor_id) return acc;
            return [...acc, _.pick(val, ['driver_id', 'processor_id', 'metered'])];
        },
        []
    );
    const routeSessionDropoffMetered = _.map(routeSessionDropoffsMeteredDuplicatedRemoved, (dropoff) => parseFloat(dropoff.metered));
    const routeSessionDropoffsTotalMetered = _.sum(routeSessionDropoffMetered);
    return routeSessionDropoffsTotalMetered;
};

const calculateShrinkageForRouteSession = (routeSessionDropoffsTotalVolume, routeSessionDropoffsTotalMetered) => {
    const canCalculateShrinkage = routeSessionDropoffsTotalVolume > 0 && routeSessionDropoffsTotalMetered > 0;
    const shrinkageAmount = canCalculateShrinkage ? routeSessionDropoffsTotalVolume - routeSessionDropoffsTotalMetered : '--';
    const shrinkagePercentage = canCalculateShrinkage ? ((routeSessionDropoffsTotalVolume - routeSessionDropoffsTotalMetered) / routeSessionDropoffsTotalVolume) * 100 : '--';
    return [shrinkageAmount, shrinkagePercentage];
};

const usingScaledWeight = (dropoffs) => {
    const retObject = {
        isMetered: false,
        batchedNet: 0,
    };

    dropoffs.forEach((dropoff) => {
        if (dropoff.metered != null) {
            retObject.isMetered = true;
        }
        retObject.batchedNet += dropoff.net_weight;
    });
    return retObject;
};

const getSessionDetails = async (key, region) => {
    try {
        const routeSessionQuery = getRouteSessionsQuery({ id: key, filterDeleted: true, queryBuilder: buildExpressQuery, populate: { adjustments_id: [] } });
        const routeSessionResponse = await axios.get(routeSessionQuery);
        const [routeSession] = routeSessionResponse.data;
        // eslint-disable-next-line no-underscore-dangle
        routeSession.id = routeSession._id;

        const trailerRequest = axios.get(getTrailersQuery({ id: routeSession.trailer_id, filterDeleted: true, queryBuilder: buildExpressQuery }));
        const routeSessionPickupRequest = axios.get(getPickupsQuery({ routeSessionIds: [routeSession.id], filterDeleted: true, queryBuilder: buildExpressQuery }));
        const routeSessionDropoffRequest = axios.get(getDropoffsQuery({ routeSessionIds: [routeSession.id], filterDeleted: true, queryBuilder: buildExpressQuery }));
        const routeRequest = axios.get(
            getRoutesQuery({ fields: ['hauling_companies', 'average_route_total_volume', 'average_route_unused_capacity', 'average_route_total_time', 'average_route_total_distance', 'odometer_start_miles', 'odometer_end_miles', 'total_distance'], id: routeSession.route_id, filterDeleted: true, queryBuilder: buildExpressQuery })
        );
        const adjustmentRequest = axios.get(getAdjustmentsQuery({ route_session_id: routeSession.id, filterDeleted: true }));

        const [trailerResponse, routeSessionPickupsResponse, routeSessionDropoffsResponse, routeResponse, adjustmentResponse] = await Promise.all([trailerRequest, routeSessionPickupRequest, routeSessionDropoffRequest, routeRequest, adjustmentRequest]);

        const trailer = trailerResponse.data;
        const routeSessionPickups = routeSessionPickupsResponse.data;
        const routeSessionDropoffs = routeSessionDropoffsResponse.data;
        const route = routeResponse.data;
        const adjustments = adjustmentResponse.data;
        if (!routeSession.adjustments_id) {
            [routeSession.adjustments_id] = adjustments;
        }

        renameKey(routeSessionPickups, '_id', 'id');
        renameKey(routeSessionDropoffs, '_id', 'id');

        const driverIds = _.uniq(_.map([...routeSessionPickups, ...routeSessionDropoffs], 'driver_id'));
        const producerIds = _.uniq(_.map([...routeSessionPickups], 'producer_id'));
        const processorIds = _.uniq(_.map([...routeSessionDropoffs], 'processor_id'));

        const driversRequest = axios.get(getDriversQuery({ ids: driverIds, filterDeleted: true, queryBuilder: buildExpressQuery }));
        const producersRequest = axios.get(getProducersQuery({ ids: producerIds, filterDeleted: true, queryBuilder: buildExpressQuery }));
        const processorsRequest = axios.get(getProcessorsQuery({ ids: processorIds, filterDeleted: true, queryBuilder: buildExpressQuery }));

        const [driversResponse, producersResponse, processorsResponse] = await Promise.all([driversRequest, producersRequest, processorsRequest]);

        const drivers = driversResponse.data;
        renameKey(drivers, '_id', 'id');

        const producers = producersResponse.data;
        renameKey(producers, '_id', 'id');

        const processors = processorsResponse.data;
        renameKey(processors, '_id', 'id');

        const processorsByPickupId = getProcessorByPickupId(routeSessionPickups, routeSessionDropoffs, processors);

        const routeSessionDropoffsTotalVolume = !_.isEmpty(routeSessionDropoffs) ? _.sumBy(routeSessionDropoffs, 'volume') : '0.00';
        const routeSessionDropoffsTotalMetered = calculatedRouteSessionDropoffsMeteredTotal(routeSessionDropoffs);
        const routeSessionPickupsTotalVolume = !_.isEmpty(routeSessionPickups) ? _.sumBy(routeSessionPickups, 'volume') : '0.00';

        const firstPickup = _.minBy(routeSessionPickups, 'created_at');
        const lastPickup = _.maxBy(routeSessionPickups, 'created_at');

        const labReportStart = firstPickup ? moment(firstPickup.created_at).startOf('day').toString() : moment().toString();
        const labReportEnd = lastPickup ? moment(lastPickup.created_at).endOf('day').toString() : moment().toString();

        const { isMetered, batchedNet } = usingScaledWeight(routeSessionDropoffs);
        const [shrinkage_amount, shrinkage_percentage] = isMetered
            ? calculateShrinkageForRouteSession(routeSessionDropoffsTotalVolume, routeSessionDropoffsTotalMetered) // Force Wrapping
            : calculateShrinkageForRouteSession(routeSessionDropoffsTotalVolume, batchedNet);

        const haulingIds = _.uniq(_.map(drivers, 'hauling_id'));
        const haulersResponse = await axios.get(getHaulingCompaniesQuery({ ids: haulingIds, filterDeleted: true, queryBuilder: buildExpressQuery }));
        const haulers = haulersResponse.data;
        renameKey(haulers, '_id', 'id');

        const { is_locked } = routeSession;

        let labs = [];
        labs = await getBulkQueryData(
            getLabReportsQuery,
            {
                start: labReportStart,
                end: labReportEnd,
                producerIds,
                filterDeleted: true,
            },
            'producerIds'
        );

        const [groupedLabReports, labsByPickupIdMap] = formatLabsForComponentCalculation(labs, region);
        const unusedCapacity = trailer || routeSessionDropoffsTotalVolume > trailer.capacity ? 0 : trailer.capacity - routeSessionDropoffsTotalVolume;

        const pickupsData = _.map(routeSessionPickups, (pickup) => {
            const components = calculateComponents([pickup], groupedLabReports, region, labsByPickupIdMap);

            const driver = _.find(drivers, { id: pickup.driver_id });
            const producer = _.find(producers, { id: pickup.producer_id });
            const hauler = _.find(haulers, { id: driver?.hauling_id });

            return {
                ...pickup,
                bacto_barcode: pickup.bacto_barcode || '',
                bmcc: components.bmcc,
                comment: pickup.comment,
                compartment: pickup.compartment,
                component_barcode: pickup.component_barcode || '',
                created_at: pickup.created_at,
                deleted_at: pickup.deleted_at,
                dip_level: pickup?.dip_level?.toString?.() || '',
                driver: [driver],
                fat: components.fat,
                hourly_production: -1,
                id: pickup.id,
                hauling_company: hauler?.name || '',
                scale_weight: pickup?.scale_weight || null,
                tare_weight: pickup?.tare_weight || null,
                is_rejected: pickup.is_rejected,
                latitude: pickup.latitude,
                longitude: pickup.longitude,
                los: components.LOS,
                outside: pickup.outside,
                partial: pickup.partial,
                processor: routeSession.status === 'closed' ? (processorsByPickupId[pickup.id] ? processorsByPickupId[pickup.id] : '') : '',
                producer: [_.pick(producer, ['lab_number', 'geofence', 'id', 'latitude', 'longitude', 'name', 'tank', 'CMTNumber', 'number_of_tanks', 'license_number'])],
                protein: components.protein,
                rejection_reason: pickup.rejection_reason,
                route_session: route ? [_.assign(_.pick(routeSession, ['BOL', 'id', 'status', 'route_number']), { route: route.name })] : '',
                run_number: pickup.run_number,
                scc: components.scc,
                slip: pickup.slip,
                tank_number: pickup.tank_number,
                temperature: pickup.temperature,
                trailer: trailer.trailer_number ? parseInt(trailer.trailer_number) : 0,
                updated_at: pickup.updated_at,
                volume: pickup.volume ? pickup.volume.toString() : '',
                volume_a: pickup.volume_a,
                volume_b: pickup.volume_b,
                volume_c: pickup.volume_c,
                type_of_fluid: pickup.type_of_fluid,
                lactose: components.lactose,
                other_solids: components.other_solids,
                solids_not_fat: components.solids_not_fat,
                total_solids: components.total_solids,
                sample_barcodes: pickup?.sample_barcodes || undefined,
            };
        });

        const pickupsByDropoffId = getPickupsByDropoffId(routeSessionDropoffs, routeSessionPickups);

        const dropoffsData = _.map(routeSessionDropoffs, (dropoff) => {
            const driver = _.find(drivers, { id: dropoff.driver_id });
            const processor = _.find(processors, { id: dropoff.processor_id });
            const hauler = _.find(haulers, { id: driver?.hauling_id });

            const pickupsForDropoff = pickupsByDropoffId[dropoff.id];

            const components = calculateComponents(pickupsForDropoff, groupedLabReports, region, labsByPickupIdMap);

            return {
                ...dropoff,
                comment: dropoff.comment,
                compartment: dropoff.compartment,
                components,
                created_at: dropoff.created_at,
                deleted_at: dropoff.deleted_at,
                driver: [_.pick(driver, ['id', 'name'])],
                id: dropoff.id,
                is_rejected: dropoff.is_rejected,
                hauling_company: hauler?.name || '',
                latitude: dropoff.latitude,
                longitude: dropoff.longitude,
                metered: dropoff.metered,
                // UDA handler weight can be either net_weight or metered
                // depending on the type of dropoff.
                // frontend does an awful job of supporting that right now
                handler_weight: dropoff.net_weight ?? dropoff.metered,
                scale_weight: dropoff?.scale_weight || null,
                tare_weight: dropoff?.tare_weight || null,
                metered_slip: dropoff.metered_slip,
                outside: dropoff.outside,
                processor: [_.pick(processor, ['geofence', 'id', 'latitude', 'longitude', 'name'])],
                rejection_reason: dropoff.rejection_reason,
                route: [_.pick(route, ['id'])],
                routeSession: route && routeSession ? [_.assign(_.pick(routeSession, ['BOL', 'id']), { route: route.name }, { route_session_status: routeSession.status })] : '',
                run_number: dropoff.run_number,
                shrink_amount: calculateShrinkAmount(dropoff),
                shrink_percent: calculateShrinkPercentage(dropoff),
                slip: dropoff.slip,
                temperature: dropoff.temperature,
                trailer: trailer.trailer_number ? parseInt(trailer.trailer_number) : 0,
                updated_at: dropoff.updated_at,
                volume: dropoff.volume ? dropoff.volume.toString() : '0',
                volume_a: dropoff.volume_a,
                volume_b: dropoff.volume_b,
                volume_c: dropoff.volume_c,
                type_of_fluid: dropoff.type_of_fluid,
                fat_percentage: dropoff.fat_percentage,
                protein_percentage: dropoff.protein_percentage,
                solids_not_fat_percentage: dropoff.solids_not_fat_percentage,
            };
        });

        const components = calculateComponents(routeSessionPickups, groupedLabReports, region, labsByPickupIdMap);

        const total_volume = routeSessionPickupsTotalVolume;

        const inProgressDropoffs = await getActiveInprogressDropoffs({ filterDeleted: true, routeSessionId: key });

        return {
            success: true,
            details: {
                average_route_total_volume: route ? route.average_route_total_volume : 0,
                average_route_unused_capacity: route ? route.average_route_unused_capacity : 0,
                average_route_total_time: route ? route.average_route_total_time : 0,
                average_route_total_distance: route ? route.average_route_total_distance : 0,
                total_distance: routeSession?.total_distance || 0,
                odometer_start: routeSession?.odometer_start_miles || 0,
                odometer_end: routeSession?.odometer_end_miles || 0,
                total_time: routeSession?.total_time || 0,
                start_time: routeSession?.created_at || 0,
                end_time: routeSession?.closed_at || 0,
                components,
                shrinkage_amount,
                shrinkage_percentage,
                total_volume,
                unused_capacity: unusedCapacity || '0.00',
                is_locked,
                pickupsData,
                dropoffsData,
                inProgressDropoffs,
                delivery_report: routeSession?.delivery_report,
                adjustments_id: routeSession?.adjustments_id,
            },
        };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getSessionDetails;
