import axios from 'axios';
import { getWeeklyAdvanceProducerPayReportsQuery } from './queries';
import moment from 'moment-timezone';

const getWeeklyAdvanceProducerPayReports = (startDate, endDate) =>
    new Promise((resolve, reject) => {
        const query = getWeeklyAdvanceProducerPayReportsQuery({
            startDate: moment(startDate).startOf('day').toISOString(),
            endDate: moment(endDate).endOf('day').toISOString(),
        });
        const request = axios.get(query);

        request
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject(new Error('Unable to get weekly advance pay reports'));
            });
    });

export default getWeeklyAdvanceProducerPayReports;
