import * as yup from 'yup';

const merchandiseProductValidationSchema = yup.object({
    price_per_unit: yup
        .number()
        .typeError('Must be a valid number.')
        .min(0, 'Min value is 0.')
        .nullable()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value)),
    product_number: yup
        .string()
        .typeError('Must be a valid number.')
        .min(0, 'Min value is 0.')
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
        .required('Product Number is required'),
    product_name: yup
        .string()
        .transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
        .typeError('Must be a valid name.')
        .required('Product Name is required'),
});

export default merchandiseProductValidationSchema;
