import { useSelector, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Checkbox, FormControlLabel, IconButton, Switch, TextField } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { reorder } from '../DraggableList';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { makeStyles } from '@material-ui/styles';
import { getAdminCustomTableConfigurationDetails, setError } from 'app/store/actions';
import SaveProgress from '../../custom-components/SaveProgress';
import MobileDetect from 'mobile-detect';

const md = new MobileDetect(window.navigator.userAgent);
const isMobile = md.mobile();

const CustomTableColumnVisibilityDialog = ({ onClose, onSave, saving, onReset, configuration, columns, isAdmin }) => {
    const dispatch = useDispatch();
    const [mode, setMode] = useState(false);
    const [search, setSearch] = useState('');
    const [editColumns, setEditColumns] = useState();
    const [adminEditColumns, setAdminEditColumns] = useState();
    const [meChanged, setMeChanged] = useState(false);
    const adminCustomTableConfigurationDetails = useSelector((state) => state.mainReducer.adminCustomTableConfigurationDetails.data);

    useEffect(() => {
        const rails = document.querySelectorAll('.ps__rail-y');
        const target = rails[rails.length - 1] && rails[rails.length - 1].nextSibling;
        if (target) target.classList.add('noscroll');
        return () => {
            if (target) target.classList.remove('noscroll');
        };
    }, []);

    const getMappedCollumns = (cols, config) => {
        const result = cols
            .filter((col) => col.field)
            .map((col) => {
                const currentConfig = (config.columns || []).find((c) => col.field === c.field);
                return {
                    ...col,
                    visible: currentConfig ? currentConfig.visible : !col.hidden,
                    locked: currentConfig ? currentConfig.locked : false,
                };
            });
        if (config.columns) {
            result.sort((first, second) => {
                let firstIndex = config.columns.findIndex((cfg) => first.field === cfg.field);
                let secondIndex = config.columns.findIndex((cfg) => second.field === cfg.field);
                if (firstIndex === -1) firstIndex = result.length;
                if (secondIndex === -1) secondIndex = result.length;
                return firstIndex - secondIndex;
            });
        }
        return result.map(({ title, field, visible, locked }) => ({ title, field, visible, locked }));
    };

    useEffect(() => {
        setEditColumns(getMappedCollumns(columns, configuration));
        dispatch(getAdminCustomTableConfigurationDetails(configuration.table_name)).catch((error) => {
            dispatch(setError(error));
        });
    }, []);

    useEffect(() => {
        if (adminCustomTableConfigurationDetails) {
            setAdminEditColumns(getMappedCollumns(columns, adminCustomTableConfigurationDetails));
        }
    }, [adminCustomTableConfigurationDetails]);

    const onModeChange = (event) => {
        setMode(event.target.checked);
    };

    const onColumnVisiblityChange = (event, field) => {
        const visible = event.target.checked;
        const modeColumns = mode ? adminEditColumns : editColumns;
        const newColumns = modeColumns.map((col) => (col.field === field ? { ...col, visible } : col));
        if (newColumns.some((col) => col.visible)) {
            if (mode) {
                setAdminEditColumns(newColumns);
            } else {
                setEditColumns(newColumns);
                setMeChanged(true);
            }
        }
    };

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }
        if (mode) {
            setAdminEditColumns(reorder(adminEditColumns, result.source.index, result.destination.index));
        } else {
            setEditColumns(reorder(editColumns, result.source.index, result.destination.index));
            setMeChanged(true);
        }
    };

    const onColumnLockChange = (column) => {
        setAdminEditColumns(adminEditColumns.map((col) => (col.field === column.field ? { ...col, locked: !column.locked } : col)));
    };

    const onSearchChange = (event) => {
        setSearch(event.target.value);
    };

    const useStyles = makeStyles(() => ({
        deleteButton: {
            color: '#E26365',
            background: '#E2636514',
        },
    }));
    const classes = useStyles();

    const testId = configuration.table_name?.toLowerCase().replace(/ /g, '-') || '';

    return (
        <Dialog open onClose={onClose} fullWidth maxWidth="sm" sx={{ margin: isMobile ? 0 : 32 }} data-testid={`${testId}-table-configuration`}>
            <DialogTitle style={{ marginTop: 15, fontSize: 24 }}>Table Configuration</DialogTitle>
            <DialogContent style={{ padding: isMobile ? '8px 16px' : '8px 24px' }}>
                <DialogContentText style={{ marginBottom: 10, minHeight: 50 }} className="text-black">
                    {!mode ? 'Toggle visibility and column order for this table.' : 'Set the default visibility and column order for all users of this table. Users can then fine tune their own table preferences.'}
                </DialogContentText>
                {isAdmin && (
                    <DialogContentText className="text-black">
                        <Switch checked={mode} onChange={onModeChange} data-testid={`${testId}-user-toggle`} /> All Users
                    </DialogContentText>
                )}
                <TextField style={{ marginBottom: 10, marginTop: 10 }} value={search} onChange={onSearchChange} placeholder="Search columns" />
                <DraggableColumnsList items={mode ? adminEditColumns : editColumns} onDragEnd={onDragEnd} onColumnVisiblityChange={onColumnVisiblityChange} onColumnLockChange={onColumnLockChange} isAdmin={isAdmin} search={search} mode={mode} />
            </DialogContent>
            <DialogActions style={{ margin: isMobile ? 8 : 16 }}>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => onReset(mode ? 'default' : 'me')} variant="contained" className={classes.deleteButton}>
                    Reset
                </Button>
                <Button onClick={() => onSave(editColumns, adminEditColumns, meChanged)} variant="contained" color="secondary" disabled={saving}>
                    Save
                    {saving && <SaveProgress />}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const DraggableColumnsList = ({ items, onDragEnd, onColumnVisiblityChange, onColumnLockChange, isAdmin, search, mode }) => {
    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" direction="vertical">
                {(provided) => (
                    <div style={{ height: items && items.length * 55, flex: 1 }} ref={provided.innerRef} {...provided.droppableProps}>
                        {items &&
                            items
                                .filter((col) => col.title.toLowerCase().includes(search.toLowerCase()))
                                .map((col, index) => (
                                    <Draggable key={col.field} draggableId={col.field} index={index} isDragDisabled={search !== ''}>
                                        {(innerProvided) => (
                                            <div ref={innerProvided.innerRef} {...innerProvided.draggableProps}>
                                                <div style={{ display: 'flex', backgroundColor: !isAdmin && col.locked && !col.visible ? '#EBEBE4' : '#F5F5F5', borderRadius: 5, marginBottom: 5, fontSize: 16, padding: isMobile ? '2px 5px' : '2px 20px' }}>
                                                    {col.locked && !isAdmin && (
                                                        <div style={{ display: 'inline-block', flex: 1, padding: '11px 10px' }}>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <LockIcon style={{ opacity: 0.4 }} />
                                                                <span style={{ marginLeft: 6 }}>{col.title}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {isAdmin && mode && (
                                                        <IconButton onClick={() => onColumnLockChange(col)} data-testid={`${col.field?.toLowerCase().replace(/ /g, '-')}-lock-toggle` || ''}>
                                                            {col.locked ? <LockIcon /> : <LockOpenIcon />}
                                                        </IconButton>
                                                    )}
                                                    {(!col.locked || isAdmin) && <FormControlLabel style={{ margin: '3px 0px', flex: 1 }} key={col.field} control={<Checkbox checked={col.visible} onChange={(event) => onColumnVisiblityChange(event, col.field)} value={col.field} name={col.field} />} label={col.title} />}
                                                    <div {...innerProvided.dragHandleProps} style={{ padding: 10, marginRight: -10 }}>
                                                        <DragHandleIcon />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

export default CustomTableColumnVisibilityDialog;
