import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import { getProductionQuery } from './queries';
import { calculateAccuracy } from './utils';

const getSingleProducerProductionDetails = (id, tank) =>
    new Promise((resolve, reject) => {
        const productionQuery = getProductionQuery({
            producerId: id,
            limit: '10',
            tank,
        });
        const productionRequest = axios.get(productionQuery);

        productionRequest
            .then((productionResponse) => {
                const production = productionResponse.data;
                const results = _.map(production, (p) => ({
                    date: moment(p.created_at).format('MMM D'),
                    hour: moment(p.created_at).format('h:mm A'),
                    actual_pickup: p.actual_volume.toFixed(0),
                    expected_pickup: p.expected_volume ? p.expected_volume.toFixed(0) : 0,
                    accuracy: calculateAccuracy(p.actual_volume, p.expected_volume),
                }));
                resolve({ pickups: results });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getSingleProducerProductionDetails;
