/* eslint-disable import/prefer-default-export */

export const productType = [
    { value: 'farm', name: 'Farm' },
    { value: 'dairy', name: 'Dairy' },
];

export const purchaseType = [
    { value: 'producer_sales', name: 'Producer Sales' },
    { value: 'hauler_sales', name: 'Hauler Sales' },
    { value: 'hauler_commissions', name: 'Hauler Commissions' },
    { value: 'hauler_over_short', name: 'Hauler Over Short' },
];
