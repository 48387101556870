import * as Actions from '../../actions';

const initialState = { data: null };

const inspectReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_INSPECT_DETAILS: {
            const los = action.payload.los || action.payload.LOS;
            const scc = action.payload.scc || action.payload.somatic_cell_count;
            return { ...state, data: { ...action.payload, los, scc } };
        }
        default:
            return state;
    }
};

export default inspectReducer;
