import { FuseLoadable } from '@fuse';

const Index = {
    auth: ['admin', 'sub-admin', 'transport', 'sub-transport', 'processor', 'sub-processor'],
    routes: [
        {
            path: '/milk-schedule-list-view',
            component: FuseLoadable({
                loader: () => import('./milk/SchedulingRouter'),
            }),
        },
        {
            path: '/milk-schedule/:mode?',
            component: FuseLoadable({
                loader: () => import('./milk/SchedulingApp'),
            }),
        },
        {
            path: '/fluid-schedule',
            component: FuseLoadable({
                loader: () => import('./fluid/SchedulingApp'),
            }),
        },
        {
            path: '/scheduling-performance',
            component: FuseLoadable({
                loader: () => import('./performance'),
            }),
        },
    ],
};

export default [Index];
