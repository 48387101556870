import axios from 'axios';
import _ from 'lodash';
import { getBatchedQueryData, getBulkQueryData } from './utils';
import { getDriversQuery, getTrailersQuery, getWashesQuery, getRouteSessionsQuery, getWashFacilitiesQuery, getHaulingCompaniesQuery, getProcessorsQuery, getDropoffsQuery, bulkQuery, getRoutesQuery } from './queries';
import { getProcessedFluidRouteSessionQuery } from './route-sessions/queries';
import { arrayToMap, renameKey } from '../../utils';

const getWashDetails = async (start, end, id) => {
    try {
        const filterDeleted = true;
        const query = getWashesQuery({ start, end, filterDeleted, ...(!!id && { haulingId: id }) });
        const washResponse = await axios.get(query);
        const washes = [...washResponse.data];
        const washRouteSessionIds = [];
        const washPFRouteSessionIds = [];
        washes.forEach((wash) => {
            if (wash.route_session_id) {
                washRouteSessionIds.push(wash.route_session_id);
            }
            if (wash.pf_route_session_id) {
                washPFRouteSessionIds.push(wash.pf_route_session_id);
            }
        });
        const routeSessionsResponse = await getBulkQueryData(getRouteSessionsQuery, {
            fields: ['id', 'BOL', 'status', 'route_id', 'deleted_at', 'manifest_number', 'trailer_id', 'created_by', 'hauling_id'],
            ids: washRouteSessionIds,
            hint: '_id_1_created_at_-1_deleted_at_1',
        });
        const pfRouteSessionsResponse = await getBulkQueryData(getProcessedFluidRouteSessionQuery, {
            fields: ['_id', 'lt_number', 'status', 'route_id', 'route_number', 'hauling_id', 'driver_id', 'destination_processor_id'],
            populate: { driver_id: ['name'], route_id: ['name'], hauling_id: ['name'], destination_processor_id: ['name'] },
            ids: washPFRouteSessionIds,
        });

        const routeSessionIds = _.uniq(_.map(routeSessionsResponse, 'id'));
        const routeIds = _.uniq(_.map(routeSessionsResponse, 'route_id'));

        const driversRequest = axios.get(getDriversQuery({ fields: ['id', 'name', 'hauling_id'] }));
        const haulersRequest = axios.get(getHaulingCompaniesQuery({ fields: ['id', 'name'] }));

        const dropoffsQuery = bulkQuery(getDropoffsQuery, {
            fields: ['route_session_id', 'volume', 'created_at', 'processor_id', 'net_weight', 'volume'],
            routeSessionIds,
            filterDeleted: true,
        });
        const dropoffsRequest = axios.post(dropoffsQuery.url, dropoffsQuery.body);

        const processorQuery = bulkQuery(getProcessorsQuery, { fields: ['id', 'name', 'dropoff_location'] });
        const processorRequest = axios.post(processorQuery.url, processorQuery.body);

        const trailerIds = _.uniq(_.map(washes, 'trailer'));
        const washFacilityIds = _.uniq(_.map(washes, 'wash_facility'));

        const trailerRequest = getBatchedQueryData(getTrailersQuery, { ids: trailerIds }, 'ids');
        const washFacilityRequest = getBatchedQueryData(getWashFacilitiesQuery, { ids: washFacilityIds }, 'ids');

        const routesRequest = getBulkQueryData(getRoutesQuery, { fields: ['id', 'name'], ids: routeIds }, 'ids');

        const [trailers, washFacilities, routes] = await Promise.all([trailerRequest, washFacilityRequest, routesRequest]);

        const subRequests = [dropoffsRequest, processorRequest, driversRequest, haulersRequest];

        return axios.all(subRequests).then(
            axios.spread((...responses) => {
                const dropoffs = responses[0].data;
                renameKey(dropoffs, '_id', 'id');

                const processors = responses[1].data;
                renameKey(processors, '_id', 'id');

                const drivers = responses[2].data;
                const haulers = responses[3].data;
                const routeSessionMap = arrayToMap([...routeSessionsResponse, ...pfRouteSessionsResponse], 'id');
                const trailerMap = arrayToMap(trailers, 'id');
                const washFacilityMap = arrayToMap(washFacilities, '_id');
                const driversMap = arrayToMap(drivers, 'id');
                const haulersMap = arrayToMap(haulers, 'id');
                const processorsMap = arrayToMap(processors, 'id');
                const dropoffMap = _.groupBy(dropoffs, 'route_session_id');
                const routesMap = arrayToMap(routes, 'id');

                const washData = _.map(washes, (wash) => {
                    const trailer = trailerMap[wash.trailer];
                    const washFacility = washFacilityMap[wash.wash_facility];
                    const routeSession = routeSessionMap[wash.route_session_id ?? wash.pf_route_session_id];
                    const driver = routeSession?.driver_id ?? (wash.driver ? driversMap?.[wash.driver] : wash.route_session_id ? driversMap?.[routeSessionMap?.[wash.route_session_id]?.created_by] : {});
                    const hauler = routeSession?.hauling_id?.name ?? (wash.hauling_id ? haulersMap?.[wash.hauling_id].name : wash.route_session_id ? haulersMap?.[routeSessionMap?.[wash.route_session_id]?.hauling_id]?.name : wash.driver && driversMap?.[wash.driver].hauling_id ? haulersMap?.[driversMap?.[wash.driver].hauling_id]?.name : '');
                    const dropoffLocation = routeSession?.destination_processor_id?.name ?? processorsMap?.[dropoffMap?.[routeSessionMap?.[wash?.route_session_id]?.id]?.[0]?.processor_id]?.name ?? '';
                    const route = routesMap?.[routeSession?.route_id] || '';

                    return {
                        ...wash,
                        trailer,
                        driver,
                        hauler,
                        washFacility,
                        route_session: [{ ...routeSession }],
                        dropoffLocation,
                        route,
                    };
                });

                return { washes: washData };
            })
        );
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to get wash details');
    }
};

export default getWashDetails;
