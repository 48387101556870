import _ from 'lodash';
import { getLabReportsQuery, getUsersQuery, getRouteSessionsQuery, getPickupsQuery } from './queries';
import { arrayToMap, getPrairieTestType } from '../../utils';
import { getBulkQueryData, getQueryData } from './utils';
import moment from 'moment-timezone';

const getAdminLabDetails = async (start, end) => {
    try {
        const labReports = await getQueryData(getLabReportsQuery, start ? { start, end } : {});

        const pickups = await getBulkQueryData(getPickupsQuery, { fields: ['route_session_id'], start, end });

        const startOffset = moment(start).subtract(7, 'days').toISOString();
        const routeSessions = await getQueryData(getRouteSessionsQuery, { fields: ['BOL', 'id', 'manifest_number'], start: startOffset, end, filterDeleted: true });

        const producerIds = _.uniq(_.map(labReports, 'producer_id'));
        const producers = await getBulkQueryData(getUsersQuery, { fields: ['id', 'name', 'CMTNumber', 'milk_category', 'is_organic'], ids: producerIds });

        const producerNamesById = {};
        const producerCMTById = {};

        producers.forEach((value) => {
            producerNamesById[value.id] = value.name;
            producerCMTById[value.id] = value.CMTNumber;
        });

        const routeSessionMap = arrayToMap(routeSessions, 'id');
        const pickupsMap = arrayToMap(pickups, 'id');
        const producersMap = arrayToMap(producers, 'id');

        const highestSCCIndexes = {};
        const highestSPCIndexes = {};

        labReports.forEach((report, index) => {
            const producerId = report.producer_id;
            if (report.somatic_cell_count != null && report.somatic_cell_count > 0) {
                if (!highestSCCIndexes[producerId] || report.somatic_cell_count > labReports[highestSCCIndexes[producerId]].somatic_cell_count) {
                    highestSCCIndexes[producerId] = index;
                }
            }
            if (report.standard_plate_count != null && report.standard_plate_count > 0) {
                if (!highestSPCIndexes[producerId] || report.standard_plate_count > labReports[highestSPCIndexes[producerId]].standard_plate_count) {
                    highestSPCIndexes[producerId] = index;
                }
            }
        });

        const dataWithHighlights = labReports.map((report, index) => {
            const producerId = report.producer_id;
            const highlightedSPC = highestSPCIndexes[producerId] === index;
            const highlightedSCC = highestSCCIndexes[producerId] === index;

            return { ...report, highlightedSPC, highlightedSCC };
        });

        // Modify the lab reports data to include additional attributes
        const data = dataWithHighlights.map((value) => {
            const testType = getPrairieTestType(value);
            return {
                ...value,
                producer: [
                    {
                        name: producerNamesById[value.producer_id],
                        id: value.producer_id,
                        CMTNumber: producerCMTById[value.producer_id],
                        is_organic: producersMap?.[value.producer_id]?.is_organic,
                    },
                ],
                BOL: routeSessionMap[pickupsMap[value?.pickup_id]?.route_session_id]?.BOL,
                manifest_number: routeSessionMap[pickupsMap[value?.pickup_id]?.route_session_id]?.manifest_number,
                milk_category: producersMap[value?.producer_id]?.milk_category,
                test_type: testType,
            };
        });

        return { labReports: data };
    } catch (errors) {
        // eslint-disable-next-line no-console
        console.log(errors);
        throw new Error('Unable to process request.');
    }
};

export default getAdminLabDetails;
