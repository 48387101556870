import { extractValues, omitValues } from '../../utils';
import Region from './region';

export const ALL_REGIONS = extractValues(Region);

export const TCJ_REGIONS = extractValues(Region.TCJ);

export const HAULING_CHARGE_REGIONS = [Region.MMPA, Region.PRAIRIE, ...TCJ_REGIONS, Region.UNC, Region.UDA, Region.CACIQUE];

export const SCHEDULING_REGIONS = omitValues(ALL_REGIONS, [Region.PEI, Region.NL, Region.RF]);

export const PROCESSED_FLUID_REGIONS = [Region.CDI, Region.PRAIRIE, Region.MMPA, Region.DEMO, Region.UDA];

export const POOLING_REGIONS = omitValues(ALL_REGIONS, [Region.PEI, Region.NL, Region.RF, Region.CDI, Region.UDA, Region.LEGACY]);

export const MANIFEST_REGIONS = omitValues(ALL_REGIONS, [Region.UDA, Region.NL, Region.PEI, Region.RF]);

export const ACCOUNTING_REGIONS = omitValues(ALL_REGIONS, [Region.NL, Region.PEI, Region.RF, Region.CACIQUE]);

export const MISC_SAMPLE_REGIONS = [Region.PRAIRIE, Region.MMPA, Region.UNC];

export const EMBEDDED_REPORT_REGIONS = omitValues(ALL_REGIONS, [Region.PEI, Region.NL, Region.RF]);

export const SILO_REGIONS = omitValues(ALL_REGIONS, [Region.PEI, Region.NL, Region.RF]);

export const MONTHLY_REPORT_REGIONS = [Region.NL, Region.PEI, Region.RF];

export const DRIVER_LOCATION_REGIONS = omitValues(ALL_REGIONS, [Region.RF]);

export const IN_PROGRESS_RAPID_TEST_REGIONS = [Region.UDA, Region.US_DEMO, Region.CA_DEMO];

export const NEW_SYSTEM_PERMISSION_REGIONS = omitValues(ALL_REGIONS, [Region.CDI, Region.LEGACY, Region.PRAIRIE, Region.UDA, Region.NL, Region.PEI, Region.RF]);

export const CONFIGURED_PROCESSOR_FORM_REGIONS = [Region.MMPA, Region.UNC, ...TCJ_REGIONS, Region.US_DEMO, Region.CA_DEMO, Region.DEMO];

export const DEFERRAL_PAY_STATEMENT_REGIONS = [Region.CDI, Region.UNC];

export const STATUS_REGIONS = [Region.CDI, Region.UDA, Region.LEGACY, Region.PEI, Region.NL, Region.RF];

export const MOBILE_RECEIVER_UI_REGIONS = [Region.UDA, Region.LEGACY, Region.PRAIRIE];

export const MANIFEST_SELECT_DRIVER_REGIONS = omitValues(ALL_REGIONS, [Region.CDI, Region.PRAIRIE, Region.BONGARDS, ...TCJ_REGIONS]);

export const DASHBOARD_DEFAULT_WIDGET_REGIONS = omitValues(ALL_REGIONS, [Region.RF, Region.UDA, Region.CACIQUE]);

export const HELPFUL_LINKS_REGIONS = omitValues(ALL_REGIONS, [Region.PEI, Region.NL, Region.RF, Region.CDI, Region.LEGACY, Region.PRAIRIE, Region.CACIQUE, ...TCJ_REGIONS]);

export const BARCODE_PRINTING_REGIONS = [Region.PRAIRIE, Region.BONGARDS];

export const EQUITY_REGIONS = [Region.CDI, Region.PRAIRIE, Region.MMPA, Region.UNC, Region.BONGARDS];
