import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles({
    symbol: {
        display: 'block',
        margin: 'auto',
    },
});

const SymbolProducer = () => {
    const classes = useStyles();

    return (
        <svg width="28" height="35" viewBox="0 0 28 35" fill="none" xmlns="http://www.w3.org/2000/svg" className={classes.symbol}>
            <g filter="url(#filter0_d_53_20501)">
                <path
                    d="M13.8881 31.4074L13.8878 31.4071C13.6616 31.1795 10.8082 28.2824 8.01525 24.4511C6.61877 22.5354 5.24582 20.3976 4.22384 18.2521C3.19873 16.1001 2.54602 13.98 2.54602 12.0892H2.54605L2.546 12.0839C2.53123 10.5641 2.81749 9.05657 3.38795 7.6497C3.95839 6.24284 4.80151 4.96498 5.86777 3.8908C6.93402 2.81662 8.20198 1.96768 9.59736 1.39344C10.9927 0.819202 12.4876 0.531139 13.9946 0.546001L13.9946 0.546108L14.0054 0.546001C15.5124 0.531139 17.0073 0.819202 18.4026 1.39344C19.798 1.96768 21.066 2.81662 22.1322 3.8908C23.1985 4.96498 24.0416 6.24284 24.6121 7.6497C25.1825 9.05657 25.4688 10.5641 25.454 12.0839H25.454V12.0892C25.454 13.98 24.8013 16.1001 23.7762 18.2521C22.7542 20.3976 21.3812 22.5354 19.9847 24.4511C17.1918 28.2824 14.3384 31.1795 14.1122 31.4071L14.1119 31.4074C14.0815 31.4381 14.0411 31.4545 13.9999 31.4545L14 32L14.0001 31.4545C13.9589 31.4545 13.9185 31.4381 13.8881 31.4074Z"
                    fill="#40B481"
                    stroke="white"
                    strokeWidth="1.09091"
                />
                <rect x="8" y="6" width="12" height="12" fill="white" fillOpacity="0.01" />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M11.2364 10.7501L11.5885 9.87012C11.7022 9.58513 11.9164 9.3518 12.1906 9.21513L13.1235 8.74889C12.9481 7.84307 12.2268 7.09933 11.2873 7.00975C10.0448 6.89142 8.99939 7.86557 8.99939 9.08347V10.7501H11.2364ZM18.1363 10.1789L18.9696 12.2622L18.9992 12.4164V16.583C18.9992 16.813 18.8125 16.9996 18.5825 16.9996H17.3325C17.1025 16.9996 16.9159 16.813 16.9159 16.583V14.4997C16.9159 14.2697 16.7292 14.083 16.4992 14.083H13.9993C13.7693 14.083 13.5826 14.2697 13.5826 14.4997V16.583C13.5826 16.813 13.396 16.9996 13.166 16.9996H11.916C11.686 16.9996 11.4993 16.813 11.4993 16.583V12.4159L11.5289 12.2618L12.3622 10.1785C12.4001 10.0839 12.4714 10.0064 12.5626 9.96057L15.0626 8.71059C15.1801 8.65184 15.318 8.65184 15.4355 8.71059L17.9354 9.96057C18.0271 10.0064 18.0984 10.0839 18.1363 10.1789ZM15.8742 12.4168C15.9892 12.4168 16.0826 12.3234 16.0826 12.2084V11.5839C16.0826 11.0776 15.6313 10.6768 15.1093 10.7618C14.6997 10.8281 14.4159 11.213 14.4159 11.628V12.2084C14.4159 12.3234 14.5093 12.4168 14.6243 12.4168H15.8742ZM10.6719 12.3055L10.666 12.4163V14.0834H8.99939V11.5834H10.9031L10.7256 12.0268L10.6719 12.3055ZM10.666 16.5833V14.9167H8.99939V16.5833C8.99939 16.8133 9.18605 17 9.41605 17H10.7427C10.696 16.8692 10.666 16.73 10.666 16.5833Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter id="filter0_d_53_20501" x="0.5" y="0" width="27" height="35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="1.5" />
                    <feGaussianBlur stdDeviation="0.75" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_53_20501" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_53_20501" result="shape" />
                </filter>
            </defs>
        </svg>
    );
};

export default SymbolProducer;
