import { buildExpressQuery } from '../queries/builders';

export const listGeofencesQuery = ({ type, ids, filterDeleted }) => {
    const endpoint = '/geofence';
    const params = {};

    if (type) {
        params.where = { ...params.where, type };
    }

    if (ids) {
        params.where = { ...params.where, user_id: { inq: ids } };
    }

    if (filterDeleted) {
        params.where = { ...params.where, deleted_at: { eq: null } };
    }

    params.populate = {
        user_id: ['full_name', 'name', 'role'],
    };

    return buildExpressQuery(endpoint, { params });
};

export const addGeofenceQuery = ({ model }) => {
    const endpoint = '/geofence';
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const editGeofenceQuery = ({ id, model }) => {
    const endpoint = `/geofence/${id}`;
    const body = model;
    return buildExpressQuery(endpoint, { body });
};

export const deleteGeofenceQuery = (id) => {
    const endpoint = `/geofence/${id}`;
    return buildExpressQuery(endpoint, {});
};
