export const basis = [
    { value: 'fixed', name: 'Fixed' },
    { value: 'percent', name: 'Percent' },
    { value: 'total_production', name: 'Total Production' },
];

export const splitPortion = [
    { value: 'gross_pay', name: 'Gross Pay' },
    { value: 'total_hauling', name: 'Total Hauling' },
    { value: 'total_advertising', name: 'Total Advertising' },
    { value: 'total_products', name: 'Total Products' },
];
