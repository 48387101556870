import axios from 'axios';
import { getAdhocAdvanceReportsQuery } from './queries';

const getAdhocAdvanceReports = (startDate, endDate) =>
    new Promise((resolve, reject) => {
        const query = getAdhocAdvanceReportsQuery({ startDate, endDate });
        const request = axios.get(query);

        request
            .then((response) => {
                resolve(response.data);
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to get adhoc advance reports'));
            });
    });

export default getAdhocAdvanceReports;
