// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
/* eslint-disable-next-line */
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import moment from 'moment-timezone';
import { numberFormat } from '../../utils';

// eslint-disable-next-line react/prefer-stateless-function
class EnhancedTableHead extends Component {
    render() {
        // eslint-disable-next-line global-require
        const uuidv4 = require('uuid/v4');
        const { columnSettings } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {columnSettings.map(
                        (row) => (
                            <TableCell key={uuidv4()} align="justify" padding="checkbox">
                                {row.label}
                            </TableCell>
                        ),
                        this
                    )}
                </TableRow>
            </TableHead>
        );
    }
}

const toolbarStyles = (theme) => ({ root: { paddingRight: theme.spacing(1) }, title: { flex: '1 1 25%' } });

let EnhancedTableToolbar = (props) => {
    const { classes, tableTitle, headerComponent } = props;

    return (
        <Toolbar className={classNames(classes.root)}>
            <div className={`flex flex-row w-full ${classes.title}`}>
                <Typography className="text-16 font-bold" id="tableTitle">
                    {tableTitle}
                </Typography>
                {headerComponent && headerComponent}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
    root: {},
    table: { minWidth: '50%', maxWidth: '100%' },
    tableWrapper: { overflowX: 'auto', overflowY: 'scroll' },
});

class MiniTable extends Component {
    state = {
        page: 0,
        rowsPerPage: 10,
        storedData: [],
    };

    componentDidMount() {
        const { tableData, defaultRowAmount } = this.props;
        this.setState({ storedData: tableData, rowsPerPage: defaultRowAmount || 25 });
    }

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    getFormatted = (value, formatter) => {
        let returnVal = value;

        switch (formatter[0]) {
            case 'Number':
                returnVal = value !== null ? numberFormat(parseFloat(value), formatter[1], '.', ',') + (formatter[2] || '') : '';
                break;

            case 'Date':
                returnVal = value ? moment(value).format(formatter[1]) : '';
                break;

            case 'LabComponent':
                returnVal = value ? parseFloat(value).toFixed(formatter[1]) + (formatter[2] || '') : '-';
                break;

            default:
                break;
        }

        return returnVal;
    };

    insertEmptyRows = (rowCount, columnCount) => {
        // eslint-disable-next-line global-require
        const uuidv4 = require('uuid/v4');
        const returnValue = [];

        for (let i = 0; i < rowCount; i++) {
            returnValue.push(
                <TableRow tabIndex={-1} style={{ backgroundColor: (rowCount + i) % 2 === 0 ? 'white' : '#eeeeee' }} key={uuidv4()}>
                    <TableCell key={uuidv4()} component="th" scope="row" padding="checkbox" colSpan={columnCount} className="text-white">
                        &nbsp;
                    </TableCell>
                </TableRow>
            );
        }

        return returnValue;
    };

    render() {
        // eslint-disable-next-line global-require
        const uuidv4 = require('uuid/v4');
        const { classes, columnSettings, tableData, tableTitle, headerComponent, disableRowsPerPage, rowClick, hover, colWidth } = this.props;
        const { rowsPerPage, page, storedData } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, tableData.length - page * rowsPerPage);
        const { getFormatted } = this;

        return (
            <Paper className={`${classes.root} table-responsive rounded-8 shadow-none border-1`}>
                <EnhancedTableToolbar storedData={storedData} headerComponent={headerComponent} tableTitle={tableTitle} />
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <EnhancedTableHead rowCount={tableData.length} columnSettings={columnSettings} />
                        {colWidth}
                        <TableBody>
                            {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                                <TableRow hover={hover} tabIndex={-1} key={uuidv4()} style={row.flagged ? { backgroundColor: 'rgba(192,10,0,0.51)', cursor: hover ? 'pointer' : null } : { cursor: hover ? 'pointer' : null, backgroundColor: rowIndex % 2 === 0 ? 'white' : '#eeeeee' }} onClick={rowClick ? () => rowClick(row) : null}>
                                    {Object.values(row)
                                        .slice(1)
                                        .map((value, index) => {
                                            if (Object.keys(row).find((key) => row[key] === value) === 'flagged') {
                                                return null;
                                            }

                                            return !index ? (
                                                <TableCell key={uuidv4()} component="th" scope="row" padding="checkbox" className={columnSettings[index] && columnSettings[index].cellClassNameSettings ? columnSettings[index].cellClassNameSettings : null}>
                                                    {columnSettings[index] && columnSettings[index].formatter ? getFormatted(value, columnSettings[index].formatter) : value}
                                                </TableCell>
                                            ) : (
                                                <TableCell key={uuidv4()} align="left" padding="checkbox" className={columnSettings[index] && columnSettings[index].cellClassNameSettings ? columnSettings[index].cellClassNameSettings : null}>
                                                    {columnSettings[index] && columnSettings[index].formatter ? getFormatted(value, columnSettings[index].formatter) : value}
                                                </TableCell>
                                            );
                                        })}
                                </TableRow>
                            ))}
                            {emptyRows > 0 && this.insertEmptyRows(emptyRows, Object.keys(columnSettings).length)}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={disableRowsPerPage ? [] : [5, 10, 25]}
                    component="div"
                    count={tableData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        'aria-label': 'Previous Page',
                        classes: {
                            root: 'fs-ignore-rage-clicks',
                        },
                    }}
                    nextIconButtonProps={{
                        'aria-label': 'Next Page',
                        classes: {
                            root: 'fs-ignore-rage-clicks',
                        },
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    // className={'fs-ignore-rage-clicks'}
                />
            </Paper>
        );
    }
}

export default withRouter(withStyles(styles)(MiniTable));
