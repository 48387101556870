import axios from 'axios';
import _ from 'lodash';
import { editScheduleQuery } from './queries';

const saveScheduleDetails = (date, { items, processor }) =>
    new Promise((resolve, reject) => {
        if (!items && !processor) {
            resolve({});
        }

        const schedule = {};

        if (items) {
            schedule.items = _.map(items, (item) => ({
                date: item.date,
                volume: item.volume,
                route_id: item.route.id,
                end_date: item.end_date.format('YYYY-MM-DD HH:mm:ss'),
                ...(item.processor && { processor_id: item.processor.id }),
                ...(item.hauling && { hauling_id: item.hauling.id }),
                ...(item.note && { note: item.note }),
            }));
        }

        if (processor) {
            schedule.published_processor = processor;
        }

        const query = editScheduleQuery({ date, schedule });
        const request = axios.patch(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to edit schedule'));
            });
    });

export default saveScheduleDetails;
