import axios from 'axios';
import { editProcessedFluidAdjustmentsQuery } from './queries';

const editProcessedFluidAdjustments = async (model, id) => {
    const query = editProcessedFluidAdjustmentsQuery({ model, id });

    try {
        const adjustmentsDetails = await axios.patch(query.url, { ...query.body });
        const adjustments = adjustmentsDetails?.data;

        return { data: adjustments };
    } catch (e) {
        return { error: e.message };
    }
};

export default editProcessedFluidAdjustments;
