import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import { getMonthlyReportsQuery, getProducersQuery } from './queries';

const calculateRevenue = (report) => parseFloat(report.base_fluid_milk_revenue) + parseFloat(report.fluid_milk_butterfat_premium_penalty) + parseFloat(report.P5_fluid) + parseFloat(report.industrial_milk_revenue);

const calculateExpenses = (report) =>
    parseFloat(report.DFNL_board_levy_fluid) +
    parseFloat(report.transportation_fluid_milk) +
    parseFloat(report.fluid_skimoff_charges) +
    parseFloat(report.DFNL_board_levy_industrial_milk) +
    parseFloat(report.DFC_levy_industrial_milk) +
    parseFloat(report.transportation_industrial_milk) +
    parseFloat(report.CDC_special_class_pooling) +
    parseFloat(report.new_entrant_levy) +
    parseFloat(report.DFNL_admin) +
    parseFloat(report.P10_commitment_and_P6_dumped_milk) +
    parseFloat(report.chargeback) +
    parseFloat(report.adjustments);

const calculateHST = (report) => parseFloat(report.HST_fluid) + parseFloat(report.HST_MSQ);

const getPayCalcsInfo = (month, year) =>
    new Promise((resolve, reject) => {
        const start = moment(`${year}-${month}`).startOf('month').format();
        const end = moment(`${year}-${month}`).endOf('month').format();
        const monthlyReportQuery = getMonthlyReportsQuery({
            start,
            end,
            filterForPayment: true,
            filterDeleted: true,
        });
        const monthlyReportRequest = axios.get(monthlyReportQuery);

        const producerQuery = getProducersQuery({
            filterDeleted: true,
            includeSubUser: false,
            orderBy: 'order',
            fields: ['id', 'order', 'name'],
        });
        const producerRequest = axios.get(producerQuery);

        const requests = [monthlyReportRequest, producerRequest];

        axios
            .all(requests)
            .then(
                axios.spread((...responses) => {
                    const [monthlyReportResponse, producerResponse] = responses;

                    const orderedIds = _.map(producerResponse.data, 'id');

                    const reports = _.reduce(
                        orderedIds,
                        (acc, val) => {
                            const report = _.find(monthlyReportResponse.data, { producer_id: val });
                            return report ? [...acc, report] : acc;
                        },
                        []
                    );

                    const data = _.map(reports, (report) => ({
                        CMTNumber: report.licence_number,
                        total_production: report.total_milk_production,
                        revenue: calculateRevenue(report),
                        expenses: calculateExpenses(report),
                        HST: calculateHST(report),
                        adv: parseFloat(report.first_advance),
                        payable: report.net_payable,
                        fat: report.BF_Kgs_per_L,
                        protein: report.Kg_Protein,
                        los: report.Kg_Other_Solids,
                    }));
                    resolve(data);
                })
            )
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getPayCalcsInfo;
