import moment from 'moment-timezone';
import * as Actions from '../../actions';

const initialState = { startDate: moment().format('YYYY-MM'), selectedDate: moment().format('YYYY-MM') };

const viewDateReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_DATE:
            if (action.payload.isBefore(state.startDate)) {
                return { ...state, selectedDate: state.startDate };
            }
            if (action.payload.isAfter(moment().format('YYYY-MM'))) {
                return { ...state, selectedDate: moment().format('YYYY-MM') };
            }
            return { ...state, selectedDate: action.payload.format('YYYY-MM') };
        case Actions.SET_START_DATE:
            return { ...state, startDate: action.payload };
        case Actions.SET_USING_CUSTOM_DATE_RANGE:
            return { ...state, usingCustomDateRange: action.payload };
        case Actions.SET_USING_CUSTOM_DATE_PRESETS:
            return { ...state, usingCustomDatePresets: action.payload };
        case Actions.SET_CUSTOM_DATE_PRESET_OPTION:
            return { ...state, customDatePresetOption: action.payload };
        case Actions.SET_CUSTOM_DATE_RANGE_START:
            return { ...state, customDateRangeStart: action.payload };
        case Actions.SET_CUSTOM_DATE_RANGE_END:
            return { ...state, customDateRangeEnd: action.payload };
        case Actions.SET_TAX_YEAR:
            return { ...state, taxYear: action.payload };
        default:
            return state;
    }
};

export default viewDateReducer;
