import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { Button, Divider, InputAdornment } from '@material-ui/core';
import Formsy from 'formsy-react';
import { showMessage, addWashFacility, editWashFacility } from 'app/store/actions';
import { TextFieldFormsy } from '@fuse';

const WashFacilityForm = ({ editData }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [canSubmit, setCanSubmit] = useState(false);
    const [name, setName] = useState('');

    /* eslint-disable camelcase */
    useEffect(() => {
        if (editData?.name) setName(editData.name);
    }, [editData]);

    const disableButton = () => {
        setCanSubmit(false);
    };

    const enableButton = () => {
        setCanSubmit(true);
    };

    const onNameChange = (event) => {
        setName(event.target.value);
    };

    const onSubmit = (model) => {
        const submitModel = { ...model };
        submitModel.name = name;

        if (editData) {
            // eslint-disable-next-line
            dispatch(editWashFacility(submitModel, editData._id))
                .then((response) => {
                    dispatch(showMessage({ message: 'Successfully Edited Wash Facility.' }));
                    history.replace({ pathname: '/wash-facilities' });
                })
                .catch((err) => {
                    showMessage({ message: err.message });
                });
        } else {
            dispatch(addWashFacility(submitModel))
                .then((response) => {
                    dispatch(showMessage({ message: 'Successfully Added Wash Facility.' }));
                    history.replace({ pathname: '/wash-facilities' });
                })
                .catch((err) => {
                    showMessage({ message: err.message });
                });
        }
    };

    const render = () => {
        return (
            <div className={window.innerWidth > 600 ? 'w-3/4' : 'w-full'}>
                <Divider />

                <Formsy
                    onValidSubmit={onSubmit}
                    onValid={enableButton}
                    onInvalid={disableButton}
                    /* eslint-disable-next-line no-return-assign */
                    ref={(form) => form}
                    className="flex flex-col justify-center"
                >
                    <TextFieldFormsy
                        className="my-16"
                        type="text"
                        name="name"
                        label="Name"
                        placeholder="Name"
                        // eslint-disable-next-line camelcase
                        value={name}
                        onChange={onNameChange}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <img className="mr-4" width="21" src="assets/MMAssets/icons/edit.png" alt="logo" />
                                </InputAdornment>
                            ),
                        }}
                        required
                        validations="minLength:2"
                        validationError="This is not a valid name"
                        variant="outlined"
                    />

                    <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={editData ? 'EDIT WASH FACILITY' : 'ADD WASH FACILITY'} disabled={!canSubmit}>
                        {editData ? 'Edit Wash Facility' : 'Add Wash Facility'}
                    </Button>
                </Formsy>
            </div>
        );
    };

    return <>{render()}</>;
};

export default withRouter(WashFacilityForm);
