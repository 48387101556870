import _ from 'lodash';
import moment from 'moment-timezone';
import { getHaulersWithCompletePayReportsQuery } from './queries';
import axios from 'axios';

const getHaulerMonthlyPayReports = async (startDate, endDate) => {
    try {
        const haulerMonthlyPayQuery = getHaulersWithCompletePayReportsQuery({
            report_month: moment(startDate).format('YYYY-MM'),
        });
        const haulerMonthlyPayResponse = await axios.get(haulerMonthlyPayQuery);
        return { data: haulerMonthlyPayResponse.data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getHaulerMonthlyPayReports;
