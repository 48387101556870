import axios from 'axios';
import buildExpressQuery from './queries/builders/buildExpressQuery';

const updateHaulerRate = async (haulerRateId, newRateName) => {
    const endpoint = '/hauler-rate';
    const body = { hauler_rate_id: haulerRateId, rate_name: newRateName };
    const query = buildExpressQuery(endpoint, {});
    await axios.patch(query, body);
};

export default updateHaulerRate;
