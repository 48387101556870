import * as Actions from '../../actions';

const initialState = {
    data: null,
};

const equityMemberReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_MEMBERSHIP_DATA:
            return { ...state, data: { ...action.payload } };
        default:
            return state;
    }
};

export default equityMemberReducer;
