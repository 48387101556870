import { buildExpressQuery } from '../queries/builders';
import axios from 'axios';
import { renameKey } from '../../../utils';

/**
 * Replacement for batch queries.
 *
 * Abstracts request > response process
 *
 * @param {*} queryFn
 * @param {*} params
 */
const getQueryData = async (queryFn, params) => {
    const newParams = params;
    newParams.queryBuilder = buildExpressQuery;
    const url = queryFn(newParams);
    const response = await axios.get(url);
    renameKey(response.data, '_id', 'id');
    return response.data;
};

export default getQueryData;
