import axios from 'axios';
import { removeScheduleItemsQuery } from './queries';

const removeScheduleItems = async (ids) => {
    try {
        const query = removeScheduleItemsQuery({ ids });
        const request = axios.patch(query.url, { ...query.body });
        const { data } = await request;
        return { ids: data };
    } catch {
        throw new Error('Unable to remove schedule items');
    }
};

export default removeScheduleItems;
