import axios from 'axios';
import { getTableDocByIdQuery } from './queries';

const getTableDocById = async (tableId) => {
    const query = getTableDocByIdQuery({ tableId });
    const resp = await axios.get(query);
    return resp.data;
};

export default getTableDocById;
