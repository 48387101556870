import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

const TableDocumentationConfig = {
    auth: authRoles.everyoneButGuest,
    routes: [
        { exact: true, path: '/documentation/tables', component: FuseLoadable({ loader: () => import('./ListTableDocumentationApp') }) },
        { exact: true, path: '/documentation/tables/:tableIdentifier', component: FuseLoadable({ loader: () => import('./ViewTableDocumentationApp') }) },
    ],
};

export default TableDocumentationConfig;
