import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

export const Index = {
    auth: authRoles.admin,
    routes: [
        {
            path: '/merchandise',
            component: FuseLoadable({
                loader: () => import('./MerchandiseApp'),
            }),
        },
    ],
};

export default [Index];
