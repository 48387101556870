import axios from 'axios';
import { editCommentQuery } from './queries';

const editComment = (model, key) =>
    new Promise((resolve, reject) => {
        const query = editCommentQuery({ key, model });
        const request = axios.patch(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to edit comment'));
            });
    });

export default editComment;
