import * as Actions from '../../actions';

const initialState = { data: null };

const generalRouteSessionsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_GENERAL_ROUTE_SESSIONS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default generalRouteSessionsReducer;
