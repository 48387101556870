import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';
import { ALL_REGIONS } from '../../../app/constants/bulkRegion';

const ALLOWED_REGIONS_PROD = ALL_REGIONS.filter((region) => ![].includes(region));
const ALLOWED_REGIONS_TEST = ALL_REGIONS.filter((region) => ![].includes(region));

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: ALLOWED_REGIONS_PROD,
});

const demo = new Environment({
    ...prod,
    name: stages.DEMO,
});

const staging = new Environment({
    name: stages.STAGING,
    regions: ALLOWED_REGIONS_TEST,
});

const local = new Environment({
    ...staging,
    name: stages.LOCAL,
});

const dev = new Environment({
    ...staging,
    name: stages.DEV,
});

/**
 * Create the new MockSplit, be sure to create the env dictionary with util
 */
const can_access_hauler_pay_reports = new MockSplit({
    name: 'can_access_hauler_pay_reports',
    environments: [local, dev, staging, demo, prod],
});

export default can_access_hauler_pay_reports;
