import axios from 'axios';
import { getAllHaulerRatesQuery } from './queries';

const getAllHaulerRates = async () => {
    const haulerRateQuery = getAllHaulerRatesQuery();
    const haulerRateResponse = await axios.get(haulerRateQuery);
    const allHaulerRates = haulerRateResponse.data;
    return { allHaulerRates };
};

export default getAllHaulerRates;
