import {
    Dashboard,
    ListAdmins,
    ListProducers,
    ListProcessors,
    ListTrailers,
    ListHaulingCompanies,
    ListDrivers,
    ListRouteSessions,
    ListDropoffs,
    ListPickups,
    ListLabReports,
    ListSubAdmins,
    ListCIPWash,
    ListBactoAlert,
    ListSharedFiles,
    ViewDriverLocations,
    UploadDownload,
    ListMonthlyReports,
    ListSubProducers,
    ListSubProcessors,
    ListReceivers,
    ListSubHauling,
    ViewAccountingReports,
    ListRoutes,
    ViewPickupsForProcessor,
    ManageCreamLoads,
    ManageDeliveryReports,
    ViewDocumentation,
    AccessPlantManagement,
    AccessHaulerManagement,
    AccessProducerManagement,
} from './permissions';
import LABELS from './constants';

const region = 'PEI';

const SubAdminManagement = {
    label: LABELS.Management,
    permissions: [
        ListAdmins,
        ListProducers,
        ListDrivers,
        ListProcessors(region),
        ListHaulingCompanies(region),
        ListTrailers,
        ListSubAdmins,
        // force wrap
    ],
};

const SubAdminGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ViewDriverLocations,
        ListRoutes,
        ListPickups(),
        ListDropoffs(),
        ManageCreamLoads,
        ListLabReports(),
        UploadDownload,
        ListRouteSessions(region),
        ManageDeliveryReports,
        ViewPickupsForProcessor,
        ListCIPWash,
        ListSharedFiles,
        ViewDocumentation,
        AccessPlantManagement,
        AccessHaulerManagement,
        AccessProducerManagement,
        // force wrap
    ],
};

const SubAdminAccounting = {
    label: LABELS.Accounting,
    permissions: [ListMonthlyReports(region, 'Manage', '(View, Delete)'), ViewAccountingReports],
};

const SubProducerManagement = {
    label: LABELS.Management,
    permissions: [ListSubProducers],
};

const SubProducerGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListPickups('View', ''),
        ListLabReports('View', ''),
        ListMonthlyReports(region),
        ListBactoAlert(region),
        ListSharedFiles,
        AccessProducerManagement,
        // force wrap
    ],
};

const SubProcessorManagement = {
    label: LABELS.Management,
    permissions: [ListSubProcessors(region)],
};

const SubProcessorGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ListReceivers,
        ListDropoffs('View', ''),
        ListRouteSessions(region, 'View', ''),
        ListSharedFiles,
        AccessPlantManagement,
        // force wrap
    ],
};

const SubHaulingManagement = {
    label: LABELS.Management,
    permissions: [ListDrivers, ListTrailers, ListSubHauling(region)],
};

const SubHaulingGeneral = {
    label: LABELS.General,
    permissions: [
        Dashboard,
        ViewDriverLocations,
        ListPickups(),
        ListDropoffs(),
        ListCIPWash,
        ListSharedFiles,
        AccessHaulerManagement,
        // force wrap
    ],
};

const SubAdmin = [SubAdminManagement, SubAdminGeneral, SubAdminAccounting];
const SubProducer = [SubProducerManagement, SubProducerGeneral];
const SubProcessor = [SubProcessorManagement, SubProcessorGeneral];
const SubHauling = [SubHaulingManagement, SubHaulingGeneral];

const REQUIRED_PERMISSIONS = { Admin: [ListTrailers] };

const PEI = {
    Admin: { permissions: SubAdmin, required: REQUIRED_PERMISSIONS.Admin },
    'Sub-Admin': { permissions: SubAdmin },
    'Sub-Producer': { permissions: SubProducer },
    Accountant: { permissions: SubProducer },
    'Feed-Provider': { permissions: SubProducer },
    Nutritionist: { permissions: SubProducer },
    'Co-Owner': { permissions: SubProducer },
    'Sub-Processor': { permissions: SubProcessor },
    'Sub-Hauling': { permissions: SubHauling },
};

export default PEI;
