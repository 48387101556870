import React from 'react';
import { Grid } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';

const MilkPriceFormComponentPrices = ({ federalOrder }) => (
    <>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Butterfat ($/lb)" name={'milk_prices.butter_fat_price'} type="number" />
        </Grid>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Protein ($/lb)" name={'milk_prices.protein_price'} type="number" />
        </Grid>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Other Solids ($/lb)" name={'milk_prices.other_solids_price'} type="number" />
        </Grid>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="SCC Adjustment Rate" name={'milk_prices.scc_price'} type="number" />
        </Grid>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Base PPD ($/cwt)" name={`milk_prices.federal_order_${federalOrder}.base_ppd_rate`} type="number" />
        </Grid>
    </>
);

export default MilkPriceFormComponentPrices;
