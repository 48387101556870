import * as Actions from '../../actions';

const initialState = { data: null };

const viewLabsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ADMIN_LAB_REPORTS:
            return { ...state, data: { ...action.payload } };

        case Actions.GET_PRODUCER_LAB_REPORTS:
            return { ...state, data: { ...action.payload } };

        case Actions.GET_PROCESSOR_LAB_REPORTS:
            return { ...state, data: { ...action.payload } };

        default:
            return state;
    }
};

export default viewLabsReducer;
