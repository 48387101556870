import axios from 'axios';
import { editMerchandiseQuery } from './queries';

const editMerchandise = async (model, key) => {
    try {
        const query = editMerchandiseQuery({ model, key });
        const request = axios.put(query.url, { ...query.body });
        const { data } = await request;
        return { data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to edit product');
    }
};
export default editMerchandise;
