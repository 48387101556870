import React, { Component } from 'react';
import { withStyles, Drawer } from '@material-ui/core/';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as Actions from './store/actions/index';
import NotificationCenter from '../../../custom-widgets/NotificationCenter';

const styles = () => (window.innerWidth > 479 ? { root: { width: 480, padding: 24 } } : { root: { width: 320, padding: 24 } });

// eslint-disable-next-line react/prefer-stateless-function
class QuickPanel extends Component {
    forceClose = () => {
        const { toggleQuickPanel, panelProps } = this.props;
        panelProps.getNumOfUnreadNotifications();
        toggleQuickPanel(false);
    };

    render() {
        const { classes, state } = this.props;
        return (
            <Drawer classes={{ paper: classes.root }} open={state} anchor="right" onClose={() => this.forceClose()}>
                <NotificationCenter close={this.forceClose} />
            </Drawer>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ toggleQuickPanel: Actions.toggleQuickPanel }, dispatch);
}

function mapStateToProps({ quickPanel }) {
    return { state: quickPanel.state };
}

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(QuickPanel));
