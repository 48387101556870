import addUser from './addUser';

const addReceiver = (model) =>
    addUser({
        ...model,
        role: 'receiver',
        subuser: false,
    });

export default addReceiver;
