import axios from 'axios';
import { getRoutesQuery, getProcessorsQuery, getProducersQuery, getHaulingCompaniesQuery } from './queries';
import { arrayToMap } from '../../utils';

const getRoutes = async (haulingId, routeId = null, filterDeletedProducers = false) => {
    if (!routeId) {
        try {
            const requests = [];

            const routeQuery = getRoutesQuery({ ...(!!haulingId && { haulingId }), filterDeleted: true });
            requests.push(axios.get(routeQuery));

            const haulersQuery = getHaulingCompaniesQuery({ fields: ['id', 'name'] });
            requests.push(axios.get(haulersQuery));

            const processorsQuery = getProcessorsQuery({ fields: ['id', 'name'] });
            requests.push(axios.get(processorsQuery));

            const producersQuery = getProducersQuery({ fields: ['id', 'name'], filterDeleted: filterDeletedProducers });
            requests.push(axios.get(producersQuery));

            const [routeResponse, haulersResponse, processorsResponse, producersResponse] = (await Promise.all(requests)).map((res) => res.data);

            const haulersMap = arrayToMap(haulersResponse, 'id');
            const processorsMap = arrayToMap(processorsResponse, 'id');
            const producersMap = arrayToMap(producersResponse, 'id');

            return routeResponse.map((result) => {
                return {
                    ...result,
                    hauling_companies_names: result.hauling_companies?.map((hId) => haulersMap[hId]?.name || null),
                    processor_names: result.processors?.map((id) => processorsMap[id]?.name || null),
                    producer_names: result.users?.map((id) => producersMap[id]?.name).filter((name) => name),
                };
            });
        } catch (err) {
            // eslint-disable-next-line
            console.log(err);
            throw new Error('Unable to process request.');
        }
    } else {
        try {
            const routeQuery = getRoutesQuery({ id: routeId });
            const routeResp = await axios.get(routeQuery);
            return routeResp.data;
        } catch (err) {
            // eslint-disable-next-line
            console.log(err);
            throw new Error('Unable to process request.');
        }
    }
};

export default getRoutes;
