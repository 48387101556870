import axios from 'axios/index';
import { getAPIHost } from '../../../../utils';

export const GET_QUOTA_LEASING_DETAILS = 'GET_QUOTA_LEASING_DETAILS';
export const GET_LEASE_DATA = 'GET_LEASE_DATA';

export function getQuotaLeasingDetails() {
    const request = axios.get(`${getAPIHost()}/quota-leases`);

    return (dispatch) => request.then((response) => dispatch({ type: GET_QUOTA_LEASING_DETAILS, payload: response.data }));
}

export function getLeaseData(type, month, year) {
    const request = axios.post(`${getAPIHost()}/quota-leases/export-report`, {
        type,
        month,
        year,
    });

    return (dispatch) => request.then((response) => dispatch({ type: GET_LEASE_DATA, payload: response.data }));
}
