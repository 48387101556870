import axios from 'axios';
import _ from 'lodash';
import { getHaulerMonthlyPayQuery } from './queries';

const getHaulerMonthlyPay = async (hauler_id, startDate, endDate) => {
    try {
        const haulerMonthlyPayQuery = getHaulerMonthlyPayQuery({
            hauler_id,
            startDate,
            endDate,
        });
        const haulerMonthlyPayResponse = await axios.get(haulerMonthlyPayQuery);
        const approvedHaulerPayReports = { settlementReports: [], advanceReports: [] };
        if (_.isEmpty(haulerMonthlyPayResponse?.data)) {
            return approvedHaulerPayReports;
        }
        haulerMonthlyPayResponse.data.forEach((report) => {
            if (report?.report_data?.approved_advance_report) {
                approvedHaulerPayReports.advanceReports.push(report);
            }
            if (report?.report_data?.approved_settlement_report) {
                approvedHaulerPayReports.settlementReports.push(report);
            }
        });
        return approvedHaulerPayReports;
    } catch (err) {
        throw new Error('Unable to process request.');
    }
};

export default getHaulerMonthlyPay;
