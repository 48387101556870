import axios from 'axios';
import _ from 'lodash';
import { getSharedFilesQuery, getAdminSharedFilesQuery, getUsersQuery, getAdminFoldersQuery, getSharedFoldersQuery, batchQuery } from './queries';

const mapFileData = (files, users) => {
    const fileData = _.map(files, (file) => ({
        created_at: file.created_at,
        deleted_at: file.deleted_at,
        name: file.name,
        folder_id: file.folder_id,
        generated_name: file.generated_name,
        id: file.id,
        read_status: file.read_status,
        sent_by: _.find(users, { id: file.owner_id }) ? _.find(users, { id: file.owner_id }).name : 'Unknown',
        sentTo_id: file.sentTo_id,
        updated_at: file.updated_at,
    }));
    const fileDataExpanded = _.reduce(
        fileData,
        (acc, val) => [
            ...acc,
            ..._.map(val.sentTo_id, (sentToId) => ({
                ...val,
                sent_to: _.find(users, { id: sentToId }) ? _.find(users, { id: sentToId }).name : '',
                sentTo_id: sentToId,
                read_status: _.includes(val.read_status, sentToId),
            })),
        ],
        []
    );
    return fileDataExpanded;
};

const getSharedFiles = (id, isAdmin) =>
    new Promise((resolve, reject) => {
        const fileQuery = isAdmin ? getAdminSharedFilesQuery({ filterDeleted: true }) : getSharedFilesQuery({ userId: id, filterDeleted: true });
        const fileRequest = axios.get(fileQuery);

        fileRequest
            .then((fileResponse) => {
                const files = fileResponse.data;

                const userIds = _.uniq(_.flatten(_.map(files, 'sentTo_id')));

                const foldersQuery = isAdmin ? getAdminFoldersQuery({}) : getSharedFoldersQuery({ ownerId: id, filterDeleted: true });
                const folderRequest = axios.get(foldersQuery);

                const usersQuery = batchQuery(getUsersQuery, { fields: ['id', 'name'], ids: userIds }, 'ids');
                const userRequest = _.map(usersQuery, (q) => axios.get(q));
                axios.all([folderRequest, ...userRequest]).then(
                    axios.spread((...responses) => {
                        const folders = responses[0].data;
                        const users = _.reduce(responses.slice(1, 1 + userRequest.length), (acc, val) => [...acc, ...val.data], []);
                        if (folders.length === 0) {
                            const fileData = mapFileData(files, users);
                            const folderData = [];
                            resolve({ data: { files: fileData, folders: folderData } });
                        } else {
                            // folderIds.length > 0
                            const fileData = mapFileData(files, users);
                            const folderData = _.map(folders, (folder) => ({
                                deleted_at: folder.deleted_at,
                                id: folder.id,
                                name: folder.name,
                                owner_id: folder.owner_id,
                                owner_name: _.find(users, { id: folder.owner_id }) ? _.find(users, { id: folder.owner_id }).name : 'Unknown',
                                ...(folder.folder_id && { folder_id: folder.folder_id }),
                            }));
                            resolve({ data: { files: fileData, folders: folderData } });
                        }
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getSharedFiles;
