/* eslint-disable no-underscore-dangle */
import React, { memo } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';

import FieldLocalizer from './utils/FieldLocalizer';
import { Grid } from '@material-ui/core';
import DocumentHistoryItemData from './DocumentHistoryItemData';
import { toSentenceCase } from '../../../utils';
import FormatWithDeltas from './utils/DeltaFormatter';
import ActionChangeIcon from '../icons/ActionChangeIcon';
import ActionAddIcon from '../icons/ActionAddIcon';

const useStyles = makeStyles({
    chip: {
        paddingTop: '4px',
        paddingBottom: '4px',
        justifyContent: 'flex-start',
        borderStyle: 'none',
    },
    item: {
        paddingLeft: '20px',
        paddingRight: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
    },
    name: {
        textAlign: 'end',
        width: '150px',
        paddingRight: '10px',
        marginBottom: 'auto',
        marginTop: '6px',
    },
    iconBox: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
});

const DocumentHistoryItem = ({ name, data, delta, event }) => {
    const classes = useStyles();
    const readableName = toSentenceCase(name);

    if (Array.isArray(data)) {
        const dataCopy = data;
        let dataModified = false;
        // Accomodates instances where a value is being defined for the first time, showing blank -> curr
        if (event === 'update' && data.length === 1) {
            dataCopy.unshift(null);
            dataModified = true;
        }
        return (
            <Box className={classes.item} display="flex" alignItems="center">
                <Box className={classes.name}>
                    <Typography variant="p">
                        <b>{FieldLocalizer(readableName)}</b>
                    </Typography>
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <DocumentHistoryItemData name={name} data={dataCopy[0]} />
                    </Grid>
                    {dataCopy[1] && (
                        <>
                            <Grid item className={classes.iconBox}>
                                {dataModified ? <ActionAddIcon /> : <ActionChangeIcon />}
                            </Grid>
                            <Grid item xs={5}>
                                <DocumentHistoryItemData name={name} data={dataCopy[1]} asNew />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        );
    }

    if (typeof data === 'object' && !Array.isArray(data?.[0])) {
        return (
            <Box className={classes.item} display="flex" alignItems="center">
                <Box className={classes.name}>
                    <Typography variant="p">
                        <b>{FieldLocalizer(readableName)}</b>
                    </Typography>
                </Box>
                <Grid container spacing={1}>
                    <Grid item xs={5}>
                        <DocumentHistoryItemData name={name} data={data[0]} />
                    </Grid>
                    {data._0 && (
                        <>
                            <Grid item className={classes.iconBox}>
                                <ActionChangeIcon />
                            </Grid>
                            <Grid item xs={5}>
                                <DocumentHistoryItemData name={name} data={data._0} asNew />
                            </Grid>
                        </>
                    )}
                </Grid>
            </Box>
        );
    }

    if (typeof data === 'object') {
        const { formatted, length } = FormatWithDeltas(data, delta);
        return [...Array(length)].map((e, i) => {
            return (
                <Box className={classes.item} display="flex" alignItems="center">
                    <Box className={classes.name}>
                        {i === 0 && (
                            <Typography variant="p">
                                <b>{FieldLocalizer(readableName)}</b>
                            </Typography>
                        )}
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs={5}>
                            <DocumentHistoryItemData name={name} data={formatted[0][i]} />
                        </Grid>
                        <Grid item className={classes.iconBox}>
                            {formatted.changes[i]}
                        </Grid>
                        <Grid item xs={5}>
                            <DocumentHistoryItemData name={name} data={formatted._0[i]} asNew />
                        </Grid>
                    </Grid>
                </Box>
            );
        });
    }

    return <></>;
};

const MemoizedDocumentHistoryItem = memo(DocumentHistoryItem);

// export default DocumentHistoryItem;

export default MemoizedDocumentHistoryItem;
