import axios from 'axios';
import { bulkDeleteScheduleSupplyItemQuery } from './queries';

const bulkDeleteScheduleSupplyItem = async (keys) => {
    try {
        const query = bulkDeleteScheduleSupplyItemQuery({ keys });
        const request = axios.delete(query.url, { data: { ...query.body } });
        const { data } = await request;
        return { ids: data };
    } catch {
        throw new Error('Unable to delete schedule item');
    }
};

export default bulkDeleteScheduleSupplyItem;
