import React, { useContext } from 'react';
import { Grid, Autocomplete, TextField } from '@mui/material';
import { MapContext } from '../MapContext';

const GeofenceControls = ({ drawingMode, drawingModeOptions, setDrawingMode, canDraw, canEdit }) => {
    const { fences, selectedIndex } = useContext(MapContext);
    const { instance } = fences[selectedIndex] || {};

    const handleChangeRadius = (event) => {
        if (!instance?.setRadius) return;
        instance.setRadius(parseFloat(event.target.value));
    };
    const showRadius = drawingMode?.value === 'circle' || !!instance?.getRadius;

    return (
        <>
            <Grid item xs={6}>
                <Autocomplete
                    value={drawingMode}
                    onChange={(_event, newValue) => setDrawingMode(newValue)}
                    options={drawingModeOptions}
                    getOptionLabel={(option) => option.label}
                    disabled={!canDraw}
                    renderInput={(params) => <TextField {...params} label="Select a drawing mode" />}
                    // wrap
                />
            </Grid>
            <Grid item xs={6}>
                {showRadius && !!fences?.length && (
                    <TextField
                        type="number"
                        label="Radius"
                        value={instance?.getRadius ? instance?.getRadius() : ''}
                        fullWidth
                        onChange={handleChangeRadius}
                        disabled={!canEdit}
                        // wrap
                    />
                )}
            </Grid>
        </>
    );
};

export default GeofenceControls;
