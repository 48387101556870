import axios from 'axios';
import { getNextAvailableCheckNumberQuery } from './queries';

const getNextAvailableCheckNumber = async () => {
    try {
        const checkNumberQuery = getNextAvailableCheckNumberQuery();
        const { data: paymentStatus } = await axios.get(checkNumberQuery);
        return paymentStatus?.[0]?.payment_number + 1 || 1;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getNextAvailableCheckNumber;
