import { combineReducers } from 'redux';
import auth from 'app/auth/store/reducers';
import accessListDetails from './access_list_reducer';
import dateDetails from './view.date.reducer';
import editDetails from './edit.reducer';
import inspectDetails from './inspect.reducer';

const persistedReducers = combineReducers({
    auth,
    accessListDetails,
    dateDetails,
    editDetails,
    inspectDetails,
});

export default persistedReducers;
