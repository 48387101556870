import store from 'app/store';
import { FuseLoadable } from '@fuse';

const Index = {
    auth: ['admin', 'sub-admin', 'transport', 'sub-transport', 'processor', 'sub-processor'],
    routes: [
        {
            path: '/route-sessions/:date?',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                            return import('./raw-milk/admin/RouteSessionsApp');
                        case 'processor':
                        case 'sub-processor':
                            return import('./raw-milk/processor/ProcessorRouteSessionsApp');
                        default:
                            break;
                    }
                },
            }),
        },
        {
            path: '/cream-route-sessions/:date?',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                            return import('./cream/CreamRouteSessionApp');
                        case 'transport':
                        case 'sub-transport':
                            return import('./cream/CreamRouteSessionApp');
                        default:
                            break;
                    }
                },
            }),
        },
    ],
};

export default [Index];
