import JWTService from 'app/services/jwtService';
import * as FullStory from '@fullstory/browser';
import { setUserData } from './user.actions';
import { datadogRum } from '@datadog/browser-rum';
import { getAccessList } from '../../../store/actions';
import { addPageTracking } from '../../../../utils';

export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export async function onCognitoLogin() {
    return async (dispatch) => {
        try {
            const user = await JWTService.signInWithCognitoSession();
            window.Intercom('shutdown');

            window.Intercom('boot', {
                app_id: 'mf8emzsc',
                custom_launcher_selector: '.intercom-launcher',
                name: user.data.name,
                email: user.data.email,
                Region: user.data.region,
                'Account Type': user.role.charAt(0).toUpperCase() + user.role.slice(1),
                hide_default_launcher: true,
                vertical_padding: 100,
            });

            datadogRum.setUser({
                id: user.data.id,
                email: user.data.email,
                name: user.data.name,
                region: user.data.region,
                role: user.role,
            });

            addPageTracking('login');

            // Set Fullstory info
            FullStory.identify(user.data.id, {
                displayName: user.data.name,
                email: user.data.email,
                Region: user.data.region,
                'Account Type': user.role.charAt(0).toUpperCase() + user.role.slice(1),
            });

            dispatch(setUserData(user));

            dispatch(getAccessList());

            return dispatch({ type: LOGIN_SUCCESS, payload: user });
        } catch (error) {
            return dispatch({ type: LOGIN_ERROR, payload: error });
        }
    };
}

export async function onCognitoUnlock() {
    return async (dispatch) => {
        try {
            const user = await JWTService.signInWithCognitoSession();
            datadogRum.setUser({
                id: user.data.id,
                email: user.data.email,
                name: user.data.name,
                region: user.data.region,
                role: user.role,
            });

            addPageTracking('unlock');

            dispatch({ type: LOGIN_SUCCESS, payload: user });
        } catch (error) {
            dispatch({ type: LOGIN_ERROR, payload: error });
        }
    };
}
