import axios from 'axios';
import { editTruckQuery } from './queries';

const editTruck = (model, key) =>
    new Promise((resolve, reject) => {
        const query = editTruckQuery({ key, model });
        const request = axios.put(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to edit truck'));
            });
    });

export default editTruck;
