import { getId, getUserRegion, isSuperUser } from '../../../../../utils';
import Region from '../../../../constants/region';
import { SCHREIBER_PROCESSOR_ID } from '../constants';

const editSiloInvoicesPermission = (permissionType) => {
    // TODO: Should this invoke the ghost of CASL?
    const isSchreiber = getId() === SCHREIBER_PROCESSOR_ID;
    const region = getUserRegion();
    const isPermissionType = ['Admin', 'Sub-Admin', 'Sub-Processor'].includes(permissionType);
    if ((isSchreiber && isPermissionType) || (isSuperUser() && isPermissionType && [Region.UDA].includes(region))) {
        return [{ label: 'Edit Silo Invoices', value: 'EditSiloInvoices' }];
    }
    return [];
};

export default editSiloInvoicesPermission;
