const localization = {
    general: {
        tank: 'Tank',
        hauling_plural: 'Carriers',
        hauling_singular: 'Carrier',
        hauling_delivery: 'Delivery Carrier',
        full_name: 'Contact Name',
        trailer: 'Trailer',
        license_number: 'Handler Number',
        route_number: 'Invoice Number',
        routeSession: 'Route Session',
        monthlyReports: 'Monthly Reports',
        processor_singular: 'Handler',
        processor_plural: 'Handlers',
        producer_weight: 'Dairy Weight',
        volume: 'Producer Amount',
        metered: 'Dropoff Weight',
        meteredLevel: 'Scaled Amount',
        transfer: 'Base',
        lt_number: 'Invoice Number',
        customer_po: 'Customer PO #',
    },
    navigation: {
        'Base Transfers': 'Quota Transfers',
        Processors: 'Handlers',
        'Hauling Companies': 'Carriers',
        'Create Hauling Company': 'Create Carrier',
        'Create Sub Hauler': 'Create Sub Carrier',
        'Create Processor': 'Create Handler',
        'Create Sub Processor': 'Create Sub Handler',
    },
    lab: {
        scc: 'scc',
        temperature: 'Freezing Point',
        sample_date: 'Date',
    },
    other: {},
};

export default localization;
