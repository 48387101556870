import { makeStyles } from '@material-ui/core';

const useReactHookFormStyles = makeStyles((theme) => ({
    formController: {
        width: '100%',
        margin: '8px 0',
    },
    hideForm: {
        display: 'none',
    },
    textField: {
        width: '100%',
    },
    collapseCard: {
        width: '100%',
        margin: '8px 0',
        borderColor: '#4aa1f3',
    },
    collapseCardHeaderText: {
        // color: '#4aa1f3',
    },
    fieldArray: {
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    root: {
        flexGrow: 1,
    },
}));

export default useReactHookFormStyles;
