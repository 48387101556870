import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';
import store from '../../../../store';

const ListAdminAppConfig = { auth: authRoles.admin, routes: [{ path: '/list-admins', component: FuseLoadable({ loader: () => import('./ListAdminApp') }) }] };

const ListDriverAppConfig = { auth: authRoles.hauling, routes: [{ path: '/list-drivers', component: FuseLoadable({ loader: () => import('./ListDriverApp') }) }] };

const ListReceiverAppConfig = { auth: [...authRoles.hauling, ...authRoles.processor], routes: [{ path: '/list-receivers', component: FuseLoadable({ loader: () => import('./ListReceiverApp') }) }] };

const ListHaulingAppConfig = { auth: authRoles.admin, routes: [{ path: '/list-hauling-companies', component: FuseLoadable({ loader: () => import('./ListHaulingApp') }) }] };

const ListProcessorAppConfig = { auth: authRoles.admin, routes: [{ path: '/list-processors', component: FuseLoadable({ loader: () => import('./ListProcessorApp') }) }] };

const ListProducerAppConfig = { auth: authRoles.admin, routes: [{ path: '/list-producers', component: FuseLoadable({ loader: () => import('./ListProducerApp') }) }] };

const ListProducerBusinessAppConfig = { auth: authRoles.admin, routes: [{ path: '/list-producer-businesses', component: FuseLoadable({ loader: () => import('./ListProducerBusinessApp') }) }] };

const ListTrailerAppConfig = { auth: [...authRoles.admin, ...authRoles.hauling], routes: [{ path: '/list-trailers', component: FuseLoadable({ loader: () => import('./ListTrailerApp') }) }] };

const ListTruckAppConfig = { auth: [...authRoles.admin, ...authRoles.hauling], routes: [{ path: '/list-trucks', component: FuseLoadable({ loader: () => import('./ListTruckApp') }) }] };

const ListLabProviderAppConfig = { auth: authRoles.admin, routes: [{ path: '/list-lab-providers', component: FuseLoadable({ loader: () => import('./ListLabProviderApp') }) }] };

export default [ListAdminAppConfig, ListDriverAppConfig, ListReceiverAppConfig, ListHaulingAppConfig, ListProcessorAppConfig, ListProducerAppConfig, ListProducerBusinessAppConfig, ListTrailerAppConfig, ListTruckAppConfig, ListLabProviderAppConfig];
