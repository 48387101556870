import React, { useEffect, Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Pun from './Pun';
import * as Actions from '../store/actions';
import RandomMoorice from './RandomMoorice';
import CircularProgress from '@mui/material/CircularProgress';

const MMSplashScreen = ({ splashScreenDetails, setLoading, title, subtitle }) => {
    const { status } = splashScreenDetails;

    useEffect(() => {
        setLoading();
    }, [setLoading]);

    return (
        <div id="fuse-splash-screen">
            <div className="center" style={{ position: 'absolute', top: (5 / 11) * window.innerHeight }}>
                <div className="w-full flex flex-col justify-center align-middle">
                    {status === 'loading' && (
                        // eslint-disable-next-line react/jsx-fragments
                        <Fragment>
                            <div className="m-auto" style={{ display: 'grid', justifyItems: 'start', alignItems: 'start' }}>
                                <CircularProgress sx={{ gridColumnStart: 1, gridRowStart: 1 }} color="primary" size={'300px'} thickness={2} />
                                <RandomMoorice style={{ gridColumnStart: 1, gridRowStart: 1 }} />
                            </div>
                            <div className="mt-44">
                                <Pun title={title} subtitle={subtitle} />
                            </div>
                        </Fragment>
                    )}
                    {status === 'error' && (
                        <div className="mt-44">
                            <Redirect to="/error-404" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setLoading: Actions.setLoading }, dispatch);
}

function mapStateToProps({ mainReducer }) {
    return { splashScreenDetails: mainReducer.splashScreenDetails };
}

// export default MMSplashScreen;
export default connect(mapStateToProps, mapDispatchToProps)(MMSplashScreen);
