import React, { useState } from 'react';
import { makeStyles, Grid, TextField } from '@material-ui/core';

export function useForm(initialValues) {
    const [values, setValues] = useState(initialValues);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    return {
        values,
        setValues,
        handleInputChange,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '80%',
        margin: theme.spacing(1),
    },
    paper: {
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

export function Form(props) {
    const classes = useStyles();

    return (
        <div>
            <form className={classes.root} onSubmit>
                {props.children}
            </form>
        </div>
    );
}

export function FormRow() {
    const classes = useStyles();

    return (
        <>
            <Grid item xs={4}>
                <TextField fullWidth margin="normal" variant="outlined" className={classes.paper}></TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField fullWidth margin="normal" variant="outlined" className={classes.paper}></TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField fullWidth margin="normal" variant="outlined" className={classes.paper}></TextField>
            </Grid>
            <Grid item xs={4}>
                <TextField fullWidth margin="normal" variant="outlined" className={classes.paper}></TextField>
            </Grid>
        </>
    );
}
