import axios from 'axios';
import { getUsersQuery } from './queries';

const getUserSettings = (id) =>
    new Promise((resolve, reject) => {
        const userQuery = getUsersQuery({ ids: [id] });
        const userRequest = axios.get(userQuery);
        userRequest
            .then((userResponse) => {
                // eslint-disable-next-line camelcase
                const { notifications, custom_alerts } = userResponse.data[0].settings;
                resolve({ notifications, custom_alerts });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getUserSettings;
