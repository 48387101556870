import axios from 'axios';
import { editMerchandisePurchaseQuery } from './queries';

const editMerchandisePurchase = async (model, key, usePatch = false) => {
    try {
        const query = editMerchandisePurchaseQuery({ model, key });
        const request = axios.put(query.url, { ...query.body }, usePatch ? null : { params: { useNewPutRoute: true } });
        const { data } = await request;
        return { data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to edit purchase');
    }
};
export default editMerchandisePurchase;
