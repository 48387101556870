import { datadogLogs } from '@datadog/browser-logs';

const logger = datadogLogs.createLogger('memory-usage');
function measurementInterval() {
    const MEAN_INTERVAL_IN_MS = 5 * 60 * 1000;
    return -Math.log(Math.random()) * MEAN_INTERVAL_IN_MS;
}

const performMeasurement = async () => {
    // 1. Invoke performance.measureUserAgentSpecificMemory().

    try {
        const result = await performance.measureUserAgentSpecificMemory();
        // 2. Record the result.
        logger.info('Memory usage result', { result });
    } catch (error) {
        if (error instanceof DOMException && error.name === 'SecurityError') {
            logger.info('The context is not secure.');
            return;
        }
        // Rethrow other errors.
        throw error;
    }

    // 3. Schedule the next measurement.
    // eslint-disable-next-line no-use-before-define
    scheduleMeasurement();
};

const scheduleMeasurement = () => {
    try {
        // Check measurement API is available.
        if (!window.crossOriginIsolated) {
            logger.info('performance.measureUserAgentSpecificMemory() is only available in cross-origin-isolated pages');
            logger.info('See https://web.dev/coop-coep/ to learn more');
            return;
        }
        if (!performance.measureUserAgentSpecificMemory) {
            logger.info('performance.measureUserAgentSpecificMemory() is not available in this browser');
            return;
        }
        const interval = measurementInterval();
        logger.info(`Running next memory measurement in ${Math.round(interval / 1000)} seconds`);
        setTimeout(performMeasurement, interval);
    } catch (error) {
        logger.error('Error scheduling memory measurement', { error });
    }
};

export default scheduleMeasurement;
