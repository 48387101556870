import axios from 'axios';
import moment from 'moment-timezone';
import _, { drop } from 'lodash';
import { getProcessorMonthlyAveragesQuery, getDropoffsQuery, getRouteSessionsQuery, getDriversQuery, getProcessorsQuery, getPickupsQuery, getRoutesQuery, getLabReportsQuery } from './queries';
// import { getProcessorDropoffDetails } from './getProcessorDropoffDetails';
import { getMonthDateRange, arrayToMap } from '../../utils';
import { getBulkQueryData } from './utils';
import getPickupsByDropoffId from './utils/getPickupsByDropoffId';
import calculateComponents from './utils/calculateComponents';

const initializeData = () => ({
    totalVolume: 0,
    lastDropoff: {
        time: 'N/A',
        volume: 0,
    },
    shrinkDetails: {
        thisMonth: {
            totalVolume: 0,
            amount: 0,
            percent: 0,
        },
        prevMonth: {
            totalVolume: 0,
            amount: 0,
            percent: 0,
        },
    },
    dropoffDetails: {
        averageDay: {
            currentMonth: 0, // group dropoffs by date (created_at), sum each group, find avg of sums
            previousMonth: 0,
        },
        averageWeek: {
            currentMonth: 0, // group dropoffs by week (created_at), sum each group, find avg of sums
            previousMonth: 0,
        },
        totalMonth: {
            currentMonth: 0, // sum of all for month
            previousMonth: 0,
        },
    },
    prevMonthsData: {
        totalVolume: 0,
        dropoffVolume: 0,
    },
    labDetails: {
        currentMonth: {
            avgFat: 0,
            avgSCC: 0,
            // bmccTotal: 0,
            // avgBacto: 0,
            avgProtein: 0,
            // thermoTotal: 0,
        },
    },
    graphDetails: {
        thisYear: {
            // prop: [jan, feb, mar, aprl, ... ]
            dropoffs: [], // sum of all for month by volume
            dropoffQuantity: [],
            fat: [], // avg fat for all per month
            protein: [], // '' protein '' '' '' ''
            los: [], // '' los '' '' '' ''
            scc: [], // '' scc '' '' '' ''
            bactoscan: [], // '' bactoscan '' '' '' ''
        },
        lastYear: {
            dropoffs: [],
            dropoffQuantity: [],
            fat: [],
            protein: [],
            los: [],
            scc: [],
            bactoscan: [],
        },
        thisMonth: {
            // prop: [jan, feb, mar, aprl, ... ]
            dropoffs: [], // sum of all for month by volume
            dropoffQuantity: [],
            fat: [], // avg fat for all per month
            protein: [], // '' protein '' '' '' ''
            los: [], // '' los '' '' '' ''
            scc: [], // '' scc '' '' '' ''
            bactoscan: [], // '' bactoscan '' '' '' ''
        },
        prevMonth: {
            dropoffs: [],
            dropoffQuantity: [],
            fat: [],
            protein: [],
            los: [],
            scc: [],
            bactoscan: [],
        },
    },
});

const getProcessorById = async (processorId) => {
    const processorRequest = await axios.get(getProcessorsQuery({ ids: [processorId] }));
    const processor = processorRequest.data[0];
    return processor;
};

const getLabReports = async (pickups, region) => {
    const producerIds = _.uniq(_.map(pickups, 'producer_id'));

    const firstPickup = _.minBy(pickups, 'created_at');
    const lastPickup = _.maxBy(pickups, 'created_at');

    const labReportStart = firstPickup ? moment(firstPickup.created_at).startOf('day').toString() : null;
    const labReportEnd = lastPickup ? moment(lastPickup.created_at).endOf('day').toString() : null;
    if (region === 'BORDEN') {
        const labReportsQuery = getLabReportsQuery({
            start: labReportStart,
            end: labReportEnd,
            filterDeleted: true,
        });
        const labReports = (await axios.get(labReportsQuery)).data;
        const groupedLabReports = _.groupBy(labReports, 'sample_barcode');
        return groupedLabReports;
    }
    const labReportRequest = getBulkQueryData(
        getLabReportsQuery,
        {
            start: labReportStart,
            end: labReportEnd,
            producerIds,
            filterDeleted: true,
            hint: 'producer_id_1_deleted_at_1_created_at_-1',
        },
        'producerIds'
    );

    const [labReports] = await Promise.all([labReportRequest]);
    return labReports;
};

const getMonthlyData = async (processor, start, end, region) => {
    const dropoffsRequest = getBulkQueryData(
        getDropoffsQuery,
        {
            start,
            end,
            processorId: processor.id,
            filterType: 'milk',
            filterDeleted: true,
            hint: 'processor_id_1_deleted_at_1_created_at_1',
        },
        'processorId'
    );

    const [dropoffs] = await Promise.all([dropoffsRequest]);

    const routeSessionIds = _.uniq(_.map(dropoffs, 'route_session_id'));

    const pickupRequest = getBulkQueryData(
        getPickupsQuery,
        {
            routeSessionIds,
            filterDeleted: true,
            hint: 'route_session_id_1_deleted_at_1_created_at_1',
        },
        'routeSessionIds'
    );

    const [pickups] = await Promise.all([pickupRequest]);

    const labReports = await getLabReports(pickups, region);

    const dropoffData = _.map(dropoffs, (dropoff) => {
        // eslint-disable-next-line
        dropoff.created_at = moment(dropoff.created_at).format();

        return {
            ...dropoff,
            processor,
            shrink: dropoff.volume - dropoff.metered,
            shrink_percent: (100 * (dropoff.volume - dropoff.metered)) / dropoff.volume,
        };
    });
    return { dropoffData, labReports };
};

const getShrinkDetails = (dropoffData) => {
    const totalVolume = _.sumBy(dropoffData, 'volume');
    const amount = _.sumBy(dropoffData, 'shrink');
    const percent = _.meanBy(dropoffData, 'shrink_percent');
    return { totalVolume, amount, percent };
};

const getAggregatedMonthData = (dropoffData) => {
    let monthTotal = 0;
    let daysInMonth = 1;
    let averageDay = 0;
    let averageWeek = 0;
    if (dropoffData.length > 0) {
        daysInMonth = moment(dropoffData[0].created_at).daysInMonth();
        const weeks = _.divide(daysInMonth / 7);

        monthTotal = _.sumBy(dropoffData, 'volume');
        averageDay = _.divide(monthTotal, daysInMonth);
        averageWeek = _.divide(monthTotal, weeks);
    }

    return {
        monthTotal,
        daysInMonth,
        averageDay,
        averageWeek,
    };
};

const getLabAverageData = (labData) => {
    return {
        fat: _.meanBy(labData, 'fat'),
        scc: _.meanBy(labData, 'somatic_cell_count'),
        protein: _.meanBy(labData, 'protein'),
        lactose: _.meanBy(labData, 'lactose'),
        snf: _.meanBy(labData, 'solids_not_fat'),
        other_solids: _.meanBy(labData, 'other_solids'),
    };
};

const totalDay = (dropoffData) => {
    const day = (dropoff) => moment(dropoff.created_at, 'YYYY-MM-DD').format('YYYY-MM-DD');

    /**
     * TODO
     * When we decide to add in graph data for components, we will need to revist a lot of this
     * for now none of the component stuff matters
     */

    // const dailyLabData = _(dropoffData)
    //     .groupBy(day)
    //     .mapValues((index) => _.map(index, 'components'))
    //     .value();

    const dropoffQuantity = {};
    dropoffData.forEach((dropoff) => {
        const dropoffDate = day(dropoff);
        if (!dropoffQuantity[dropoffDate]) {
            dropoffQuantity[dropoffDate] = 0;
        }
        dropoffQuantity[dropoffDate] += 1;
    });

    // const totalFat = _.chain(dailyLabData)
    //     .map((dropoffs, index) => [index, _.sumBy(dropoffs, (component) => component.fat)])
    //     .fromPairs()
    //     .value();

    // const totalProtein = _.chain(dailyLabData)
    //     .map((dropoffs, index) => [index, _.sumBy(dropoffs, (component) => component.protein)])
    //     .fromPairs()
    //     .value();

    // const totalSCC = _.chain(dailyLabData)
    //     .map((dropoffs, index) => [index, _.sumBy(dropoffs, (component) => component.scc)])
    //     .fromPairs()
    //     .value();

    // const totalBacto = _.chain(dailyLabData)
    //     .map((dropoffs, index) => [index, _.sumBy(dropoffs, (component) => component.bmcc)])
    //     .fromPairs()
    //     .value();

    const dropoffVolume = _(dropoffData)
        .groupBy(day)
        .mapValues((index) => _.sumBy(index, 'volume'))
        .value();

    return {
        // totalFat,
        // totalBacto,
        // totalProtein,
        // totalSCC,
        dropoffVolume,
        dropoffQuantity,
    };
};

const transformMonthlyData = (monthlyData) => {
    const thisYearData = {
        dropoffs: [null, null, null, null, null, null, null, null, null, null, null, null],
        numDrops: [null, null, null, null, null, null, null, null, null, null, null, null],
    };

    const lastYearData = {
        dropoffs: [null, null, null, null, null, null, null, null, null, null, null, null],
        numDrops: [null, null, null, null, null, null, null, null, null, null, null, null],
    };

    const thisYear = moment(monthlyData[0].date).format('YYYY');

    monthlyData.forEach((month) => {
        if (moment(month.date).format('YYYY') === thisYear) {
            thisYearData.dropoffs[Number(moment(month.date).format('MM')) - 1] = month.total_monthly_dropoff_production;
            thisYearData.numDrops[Number(moment(month.date).format('MM')) - 1] = month.count_dropoff_volume;
        } else {
            lastYearData.dropoffs[Number(moment(month.date).format('MM')) - 1] = month.total_monthly_dropoff_production;
            lastYearData.numDrops[Number(moment(month.date).format('MM')) - 1] = month.count_dropoff_volume;
        }
    });

    return { thisYearData, lastYearData };
};

const getProcessorDashboardDetails = async (processorId, region, month, year) => {
    try {
        const data = initializeData();
        const processor = await getProcessorById(processorId);

        const start = moment(`${year}-${month}`, 'YYYY-MM').startOf('month').format();
        const end = moment(`${year}-${month}`, 'YYYY-MM').endOf('month').format();

        const prevMonthsDates = { start: moment(start).subtract(1, 'month'), end: moment(end).subtract(1, 'month') };

        const thisMonthsDataRequest = getMonthlyData(processor, start, end, region);
        const prevMonthsDataRequest = getMonthlyData(processor, prevMonthsDates.start, prevMonthsDates.end, region);

        const [thisMonthsData, prevMonthsData] = await Promise.all([thisMonthsDataRequest, prevMonthsDataRequest]);

        const thisMonthsDropoffData = thisMonthsData.dropoffData;
        const thisMonthsLabs = thisMonthsData.labReports;

        const prevMonthsDropoffData = prevMonthsData.dropoffData;

        const thisMonthsShrinkData = getShrinkDetails(thisMonthsDropoffData);
        const prevMonthsShrinkData = getShrinkDetails(prevMonthsDropoffData);

        const thisMonthsAggregateData = getAggregatedMonthData(thisMonthsDropoffData);
        if (thisMonthsShrinkData) {
            data.dropoffDetails.averageDay.currentMonth = thisMonthsAggregateData.averageDay;
            data.dropoffDetails.averageWeek.currentMonth = thisMonthsAggregateData.averageWeek;
            data.dropoffDetails.totalMonth.currentMonth = thisMonthsShrinkData.totalVolume;
        }

        const prevMonthsAggregateData = getAggregatedMonthData(prevMonthsDropoffData);
        if (prevMonthsShrinkData) {
            data.dropoffDetails.averageDay.previousMonth = prevMonthsAggregateData.averageDay;
            data.dropoffDetails.averageWeek.previousMonth = prevMonthsAggregateData.averageWeek;
            data.dropoffDetails.totalMonth.previousMonth = prevMonthsShrinkData.totalVolume;
        }

        const thisMonthsLabAverages = getLabAverageData(thisMonthsLabs);

        data.labDetails.currentMonth.avgFat = thisMonthsLabAverages?.fat || 0;
        data.labDetails.currentMonth.avgSCC = thisMonthsLabAverages?.scc || 0;
        data.labDetails.currentMonth.avgProtein = thisMonthsLabAverages?.protein || 0;
        data.labDetails.currentMonth.lactose = thisMonthsLabAverages?.lactose || 0;
        data.labDetails.currentMonth.avgSolidsNotFat = thisMonthsLabAverages?.snf || 0;
        data.labDetails.currentMonth.avgOtherSolids = region === 'CDI' ? thisMonthsLabAverages?.snf - thisMonthsLabAverages.protein : thisMonthsLabAverages?.other_solids || 0;

        /**
         * TODO
         * These components are never displayed anywhere. Should they be?
         */
        // data.labDetails.currentMonth.avgBMCC = thisMonthsLabAverages?.bmcc || 0;
        // data.labDetails.currentMonth.avgLOS = thisMonthsLabAverages?.los || 0;
        // data.labDetails.currentMonth.avgStandardPlateCount = thisMonthsLabAverages?.standard_plate_count || 0;

        if (thisMonthsDropoffData > start) {
            const lastDropoff = thisMonthsDropoffData[0];
            data.lastDropoff.time = lastDropoff.created_at;
            data.lastDropoff.volume = lastDropoff.volume;
        } else if (thisMonthsDropoffData < prevMonthsDates.end) {
            const prevDropoff = prevMonthsDropoffData[0];

            data.lastDropoff.time = prevDropoff ? prevDropoff.created_at : '';
            data.lastDropoff.volume = prevDropoff ? prevDropoff.volume : 0;
        }

        data.shrinkDetails.thisMonth = thisMonthsShrinkData;
        data.shrinkDetails.prevMonth = prevMonthsShrinkData;

        // TODO: Filter this by data. processorMonthlyAverages collection currently does not allow this.
        const processorMonthlyAveragesQuery = getProcessorMonthlyAveragesQuery({
            start: moment(year).startOf('year').subtract(1, 'year').format('YYYY'),
            end: moment(year).startOf('year').add(1, 'year').format('YYYY'),
            userIds: [processor.id],
        });
        const processorMonthlyAveragesAllMonths = await axios.get(processorMonthlyAveragesQuery);

        const monthlyData = processorMonthlyAveragesAllMonths.data;
        if (monthlyData.length > 0) {
            const transformedMonthlyData = transformMonthlyData(monthlyData);
            data.graphDetails.thisYear.dropoffs = transformedMonthlyData.thisYearData.dropoffs;
            data.graphDetails.thisYear.dropoffQuantity = transformedMonthlyData.thisYearData.numDrops;
            data.graphDetails.lastYear.dropoffs = transformedMonthlyData.lastYearData.dropoffs;
            data.graphDetails.lastYear.dropoffQuantity = transformedMonthlyData.lastYearData.numDrops;
        }

        /**
         * TODO
         * We will need to revist adding components in  here at some point, but for now no components are shown anyway
         */
        const thisMonthsDailyTotals = totalDay(thisMonthsDropoffData);
        if (thisMonthsDailyTotals) {
            // data.graphDetails.thisMonth.scc = thisMonthsDailyTotals.avgSCC;
            // data.graphDetails.thisMonth.fat = thisMonthsDailyTotals.avgFat;
            // data.graphDetails.thisMonth.protein = thisMonthsDailyTotals.avgProtein;
            data.graphDetails.thisMonth.dropoffs = thisMonthsDailyTotals.dropoffVolume;
            // data.graphDetails.thisMonth.bactoscan = thisMonthsDailyTotals.avgBacto;
            data.graphDetails.thisMonth.dropoffQuantity = thisMonthsDailyTotals.dropoffQuantity;
        }

        const prevMonthsDailyTotals = totalDay(prevMonthsDropoffData);
        if (prevMonthsDailyTotals) {
            // data.graphDetails.prevMonth.scc = prevMonthsDailyTotals.avgSCC;
            // data.graphDetails.prevMonth.fat = prevMonthsDailyTotals.avgFat;
            // data.graphDetails.prevMonth.protein = prevMonthsDailyTotals.avgProtein;
            data.graphDetails.prevMonth.dropoffs = prevMonthsDailyTotals.dropoffVolume;
            data.graphDetails.prevMonth.bactoscan = prevMonthsDailyTotals.avgBacto;
        }
        return data;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getProcessorDashboardDetails;
