import { authRoles } from 'app/auth';
import InternalLogin from './InternalLogin';

const LoginConfig = {
    settings: {
        layout: {
            config: {
                navbar: { display: false },
                toolbar: { display: false },
                footer: { display: false },
                leftSidePanel: { display: false },
                rightSidePanel: { display: false },
            },
        },
    },
    auth: authRoles.onlyGuest,
    routes: [{ path: '/internal-login', component: InternalLogin }],
};

export default [LoginConfig];
