import { object, number, date, array, boolean } from 'yup';

const HaulingRateDetails = object({
    type: object({}).required('Type is a required field').typeError('Type is a required field'),
    basis: object({}).required('Basis is a required field').typeError('Basis is a required field'),
    basis_parameter: object({}).when('basis', {
        is: (basis) => basis?.value === 'cwt',
        then: (schema) => schema.required('Milk Type is a required field').typeError('Milk Type is a required field'),
        otherwise: (schema) => schema.nullable(),
    }),
    rate: number().when('basis', {
        is: (basis) => basis?.value === 'percent',
        then: (schema) => schema.required().min(0, 'Percent rate must be between 0-100.').max(100, 'Percent rate must be between 0-100.').typeError('Rate is a required field'),
        otherwise: (schema) => schema.required().typeError('Rate is a required field'),
    }),
    min_charge: number()
        .optional()
        .nullable()
        .min(0, 'Charges cannot be negative')
        .transform((_, val) => (val.length !== 0 ? Number(val) : undefined))
        .typeError('Min Charge must be a number'),
    max_charge: number()
        .optional()
        .nullable()
        .when(['min_charge'], (min, schema) => schema.min(min || 0, 'Max Charge cannot be less than Min Charge'))
        .transform((_, val) => (val.length !== 0 ? Number(val) : undefined))
        .typeError('Max Charge must be a number'),
    rate_max_charge_per_period: number()
        .optional()
        .nullable()
        .min(0, 'Charges cannot be negative')
        .transform((_, val) => (val.length !== 0 ? Number(val) : undefined))
        .typeError('Monthly Max Charge must be a number'),
    paid_by_producer: boolean().optional().nullable(),
    exclude_pickups_before_date: object().optional().nullable(),
});

const HaulingContractSchema = object({
    hauler: object().required('Hauler is a required field').typeError('Hauler is a required field'),
    producer: object().required('Producer is a required field').typeError('Producer is a required field'),
    start_date: date().required(),
    end_date: date().optional().nullable(),
    hauling_rate_details: array()
        .of(HaulingRateDetails)
        .optional()
        .test('non-empty-array-test', 'Please select from existing rates or create a custom rate', function (value) {
            const { haulerRates } = this.parent;
            if ((!haulerRates || haulerRates.length === 0) && (!value || value.length === 0)) {
                return false;
            }
            return true;
        }),
    haulerRates: array().optional().nullable(),
    contract_max_charge_per_period: number()
        .optional()
        .nullable()
        .min(0, 'Charges cannot be negative')
        .transform((_, val) => (val.length !== 0 ? Number(val) : undefined))
        .typeError('Monthly Max Charge must be a number'),
});

const HaulingContractEditSchema = HaulingContractSchema.shape({
    effective_date: date().required('Effective Date is a required field').typeError('Effective Date is a required field'),
});

const SupplementalHaulingContractSchema = object({
    hauler: object().required('Hauler is a required field').typeError('Hauler is a required field'),
    route_id: object().nullable(),
    processor: object().nullable(),
    producers_on_route: array().nullable(),
    start_date: date().required(),
    end_date: date().optional().nullable(),
    hauling_rate_details: array()
        .of(HaulingRateDetails)
        .optional()
        .test('non-empty-array-test', 'Please select from existing rates or create a custom rate', function (value) {
            const { haulerRates } = this.parent;
            if ((!haulerRates || haulerRates.length === 0) && (!value || value.length === 0)) {
                return false;
            }
            return true;
        }),
    haulerRates: array().optional().nullable(),
});

const SupplementalHaulingContractEditSchema = SupplementalHaulingContractSchema.shape({
    effective_date: date().required('Effective Date is a required field').typeError('Effective Date is a required field'),
});

export { HaulingContractSchema, SupplementalHaulingContractSchema, SupplementalHaulingContractEditSchema, HaulingContractEditSchema };
