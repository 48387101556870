import axios from 'axios';
import { saveCustomTableConfigurationQuery } from './queries';

const saveCustomTableConfiguration = ({ configuration }) =>
    new Promise((resolve, reject) => {
        const query = saveCustomTableConfigurationQuery({ configuration });
        const request = axios.post(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default saveCustomTableConfiguration;
