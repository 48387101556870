import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Divider, InputAdornment } from '@material-ui/core';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import * as FuseActions from 'app/store/actions';
import * as Actions from '../../store/actions';
import { TextFieldFormsy } from '@fuse';
import { formatUserErrorMessage, isSuperUser } from '../../../utils';
import { Alert } from '@mui/material';
import UserAlreadyExistsWarningDialog from '../../custom-widgets/dialogs/UserAlreadyExistsWarningDialog';
import PermissionSelector from '../../custom-widgets/PermissionsSelector/PermissionSelector';

class AdminForm extends Component {
    state = {
        canSubmit: false,
        alert: null,
        warning: null,
        permissions: new Set([]),
        permissionsDirty: false,
    };

    handlePermissionChange = (newPermissions) => {
        this.setState({ permissions: newPermissions });
        this.setState({ permissionsDirty: true });
    };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    navigateToListUsers = () => {
        const { history } = this.props;
        history.replace({ pathname: '/list-admins' });
    };

    onSubmit = (model) => {
        const { editData, editUser, showMessage, history, addAdmin } = { ...this.state, ...this.props };
        const submitModel = { ...model };
        if (this.state.permissionsDirty) submitModel.permissions = Array.from(this.state.permissions);

        if (editData) {
            delete submitModel.username;
            delete submitModel.email;
            editUser(submitModel, editData.id)
                .then(() => {
                    showMessage({ message: 'Admin edited successfully.' });
                    this.setState({ alert: null });
                    this.navigateToListUsers();
                })
                .catch((err) => {
                    showMessage({ message: formatUserErrorMessage(err, 'Admin', 'edit') });
                });
        } else {
            addAdmin(submitModel)
                .then((response) => {
                    showMessage({ message: 'Admin added successfully.' });
                    this.setState({ alert: null });
                    if (response?.payload?.data?.warning) {
                        this.setState({ warning: response.payload.data.warning });
                    } else {
                        this.navigateToListUsers();
                    }
                })
                .catch((err) => {
                    showMessage({ message: 'Unable to create a new Admin' });
                    if (err.message) {
                        this.setState({ alert: err.message });
                    }
                });
        }
    };

    render() {
        const { canSubmit } = this.state;
        const { editData } = this.props;

        return (
            <div className="min-w-3/4 max-w-3/4">
                <UserAlreadyExistsWarningDialog open={!!this.state.warning} onClose={() => this.navigateToListUsers()} message={this.state.warning} />

                <Divider />

                <Formsy
                    onValidSubmit={this.onSubmit}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    /* eslint-disable-next-line no-return-assign */
                    ref={(form) => (this.form = form)}
                    className="flex flex-col justify-center"
                >
                    {this.state.alert && (
                        <Alert className="mt-32 mb-16" color="error">
                            {this.state.alert}
                        </Alert>
                    )}

                    <TextFieldFormsy
                        className="my-16"
                        type="text"
                        name="name"
                        label="Name"
                        value={editData ? editData.name : ''}
                        validations={{ minLength: 2 }}
                        validationErrors={{ minLength: 'Min character length is 2' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        person
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        required
                        variant="outlined"
                    />

                    <TextFieldFormsy
                        className="my-16"
                        type="text"
                        name="username"
                        label="Username"
                        value={editData ? editData.username : ''}
                        validations={{ ...(!editData && { minLength: 4 }) }}
                        validationErrors={{ minLength: 'Min character length is 4' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        bookmark
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        required
                        variant="outlined"
                        disabled={!!editData}
                    />

                    {!editData && (
                        <TextFieldFormsy
                            className="my-16"
                            type="password"
                            name="password"
                            label="Password"
                            validations={{ minLength: 8, equalsField: 'password_confirmation' }}
                            validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                            InputProps={{
                                autoComplete: 'new-password',
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-20 material-icons" color="action">
                                            vpn_key
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            required
                        />
                    )}

                    {!editData && (
                        <TextFieldFormsy
                            className="my-16"
                            type="password"
                            name="password_confirmation"
                            label="Confirm Password"
                            validations={{ minLength: 8, equalsField: 'password' }}
                            validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <i className="text-20 material-icons" color="action">
                                            vpn_key
                                        </i>
                                    </InputAdornment>
                                ),
                            }}
                            variant="outlined"
                            required
                        />
                    )}

                    <TextFieldFormsy
                        className="my-16"
                        type="text"
                        name="email"
                        label="Email"
                        validations="isEmail"
                        value={editData ? editData.email : ''}
                        validationError="This is not a valid email"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        alternate_email
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        required
                        variant="outlined"
                        disabled={!!editData}
                    />

                    <TextFieldFormsy
                        className="my-16"
                        type="text"
                        name="secondary_email"
                        label="Secondary Email"
                        validations="isEmail"
                        value={editData ? editData.secondary_email : ''}
                        validationError="This is not a valid email"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        alternate_email
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        variant="outlined"
                    />

                    {isSuperUser() && <PermissionSelector permissionType={'Admin'} handlePermissionChange={this.handlePermissionChange} selectedValues={!!editData && !this.state.permissionsDirty ? new Set(editData.permissions) : this.state.permissions} />}

                    <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={editData ? 'EDIT ADMIN' : 'ADD ADMIN'} disabled={!canSubmit}>
                        {editData ? 'Edit Admin' : 'Add Admin'}
                    </Button>
                </Formsy>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addAdmin: Actions.addAdmin,
            editUser: Actions.editUser,
            showMessage: FuseActions.showMessage,
        },
        dispatch
    );
}

export default withRouter(connect(null, mapDispatchToProps)(AdminForm));
