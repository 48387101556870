import addUser from './addUser';

const addLabProvider = (model) =>
    addUser({
        ...model,
        role: 'lab_provider',
        subuser: false,
    });

export default addLabProvider;
