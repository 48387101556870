const getZoomFromRadius = (radius) => {
    if (radius < 200) {
        return 16;
    }
    if (radius <= 1000) {
        return 15;
    }
    if (radius <= 3500) {
        return 13;
    }
    if (radius <= 8500) {
        return 12;
    }
    if (radius <= 17000) {
        return 11;
    }
    if (radius <= 25000) {
        return 10;
    }

    // this comfortably fits 120,000m radius
    return 8;
};

export default getZoomFromRadius;
