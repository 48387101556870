import React, { useState, useEffect } from 'react';
import { getUserRegion } from '../../../utils';
import logoText from '../../constants/logoText';
import { Box, Typography } from '@mui/material';

const BoxSx = {
    width: '100%',
    display: 'flex',
    justifyContent: 'left',
    height: 'fit-content',
    padding: '12px 20px 8px 16px',
    cursor: 'pointer',
    backgroundColor: (theme) => theme.palette.secondary.extraDark,
};

/* eslint-disable */
function Logo() {
    const [logoLocation, setLogoLocation] = useState('');

    const ecsHostName = new URL(window.location.href).host;
    const ecsRegionName = ecsHostName.split('.')[0]; // value before the first period but after https
    let ecsDeployLevel = ecsHostName.split('.')[1]; // value after the first period, but before the secon

    let region = getUserRegion();

    if (['us-demo', 'ca-demo'].includes(ecsRegionName)) region = 'default';
    useEffect(() => {
        const possibleLogoLocation = `assets/MMAssets/images/header-logos/${region}_header_logo.png`;
        const savedLogoLocation = sessionStorage.getItem(`logoLocation_${region}`);
        if (savedLogoLocation) {
            setLogoLocation(savedLogoLocation);
        } else {
            fetch(possibleLogoLocation, { method: 'HEAD' })
                .then((response) => {
                    if (response.ok) {
                        setLogoLocation(possibleLogoLocation);
                        sessionStorage.setItem(`logoLocation_${region}`, possibleLogoLocation);
                    } else {
                        setLogoLocation('assets/MMAssets/images/header-logos/DEFAULT_header_logo.png');
                        sessionStorage.setItem(`logoLocation_${region}`, 'assets/MMAssets/images/header-logos/DEFAULT_header_logo.png');
                    }
                })
                .catch(() => {
                    setLogoLocation('assets/MMAssets/images/header-logos/DEFAULT_header_logo.png');
                    sessionStorage.setItem(`logoLocation_${region}`, 'assets/MMAssets/images/header-logos/DEFAULT_header_logo.png');
                });
        }
    }, []);

    function reload() {
        window.location.reload();
    }

    if (ecsDeployLevel === 'milkmoovement') {
        ecsDeployLevel = ''; // replace with empty string if not set
    }

    const deploySubdomain = ecsDeployLevel ? `.${ecsDeployLevel}` : '';
    const windowLocation =
        process.env.NODE_ENV === 'development'
            ? `http://${ecsRegionName}.localhost:3002/#/dashboard` //
            : `https://${ecsRegionName}${deploySubdomain}.milkmoovement.io/#/dashboard`;

    return (
        <Box sx={BoxSx} onClick={() => (window.location.href = windowLocation)}>
            <img
                className="logo-icon margin-auto justify-center"
                width="44px"
                height="44px"
                style={{ backgroundColor: 'white', borderRadius: 4 }}
                src={logoLocation}
                alt={`${region} logo`}
                onClick={() => reload}
                // Wrap
            />
            <Typography variant="body2" sx={{ paddingX: '12px', marginY: 'auto' }}>
                {logoText[region] ?? logoText.default}
            </Typography>
        </Box>
    );
}

export default Logo;
