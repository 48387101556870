import axios from 'axios';
import _ from 'lodash';
import { getPickupsQuery, getDropoffsQuery, getRouteSessionsQuery, getRoutesQuery, getDriversQuery, getHaulingCompaniesQuery, getProcessorsQuery, getProducersQuery, getTrailersQuery, batchQuery, bulkQuery } from './queries';
import { buildExpressQuery } from './queries/builders';
import { renameKey, arrayToMap } from '../../utils';
import moment from 'moment-timezone';

const getRouteSessionDetails = (start, end, filterType, filterLocked) =>
    new Promise((resolve, reject) => {
        const fields = [
            'id',
            'route_id',
            'created_by',
            'BOL',
            'created_at',
            'status',
            'pooled_load',
            'type_of_fluid',
            'odometer_start_miles',
            'odometer_end_miles',
            'total_distance',
            'route_number',
            'trailer_id',
            'manifest_number',
            'is_locked',
            'hauling_id',
            'pre_delivery_report_processor_recipients',
            'truck_id',
            'outside_milk',
            'milk_category',
            'po_number',
            'kosher',
            'delivery_report',
        ];
        const routeSessionQuery = start
            ? getRouteSessionsQuery({
                  fields,
                  start,
                  end,
                  filterDeleted: true,
                  filterType,
                  filterLocked,
                  queryBuilder: buildExpressQuery,
              })
            : getRouteSessionsQuery({
                  fields,
                  limit: '100',
                  filterDeleted: true,
                  filterType,
                  filterLocked,
                  queryBuilder: buildExpressQuery,
              });

        const routeSessionRequest = axios.get(routeSessionQuery);

        const boundedStart = moment(start).subtract(1, 'week');
        const boundedEnd = moment(end).add(1, 'week');

        routeSessionRequest
            .then((routeSessionResponse) => {
                const routeSessions = routeSessionResponse.data;
                renameKey(routeSessions, '_id', 'id');
                const routeSessionIds = _.uniq(_.map(routeSessions, 'id'));
                const routeIds = _.uniq(_.map(routeSessions, 'route_id'));
                const driverIds = _.uniq(_.map(routeSessions, 'created_by'));
                const trailerIds = _.uniq(_.map(routeSessions, 'trailer_id'));
                const routeQuery = bulkQuery(getRoutesQuery, { fields: ['id', 'name'], ids: routeIds });
                const driverQuery = bulkQuery(getDriversQuery, { fields: ['id', 'name', 'hauling_id'], ids: driverIds });
                const trailerQuery = batchQuery(getTrailersQuery, { fields: ['id', 'trailer_number'], ids: trailerIds }, 'ids');
                const producerQuery = bulkQuery(getProducersQuery, { fields: ['id', 'license_number', 'milk_category'] });
                const processorQuery = bulkQuery(getProcessorsQuery, { fields: ['id', 'name'] });
                const haulingCompaniesQuery = bulkQuery(getHaulingCompaniesQuery, { fields: ['id', 'name'] });
                const pickupsQuery = bulkQuery(getPickupsQuery, {
                    fields: ['route_session_id', 'volume', 'producer_id', 'sample_barcodes', 'created_at', 'outside_milk'],
                    routeSessionIds,
                    filterDeleted: true,
                    start: start && boundedStart,
                    end: end && boundedEnd,
                    hint: 'route_session_id_1_deleted_at_1_created_at_1',
                });
                const dropoffsQuery = bulkQuery(getDropoffsQuery, {
                    fields: ['route_session_id', 'volume', 'created_at', 'processor_id', 'net_weight', 'metered'],
                    routeSessionIds,
                    filterDeleted: true,
                    start: start && boundedStart,
                    end: end && boundedEnd,
                    hint: 'route_session_id_1_deleted_at_1_created_at_1',
                });

                const routeRequest = axios.post(routeQuery.url, routeQuery.body);
                const driverRequest = axios.post(driverQuery.url, driverQuery.body);
                const trailersRequest = _.map(trailerQuery, (q) => axios.get(q));
                const processorRequest = axios.post(processorQuery.url, processorQuery.body);
                const producerRequest = axios.post(producerQuery.url, producerQuery.body);
                const haulingCompaniesRequest = axios.post(haulingCompaniesQuery.url, haulingCompaniesQuery.body);
                const pickupsRequest = axios.post(pickupsQuery.url, pickupsQuery.body);
                const dropoffsRequest = axios.post(dropoffsQuery.url, dropoffsQuery.body);
                const requests = [routeRequest, driverRequest, pickupsRequest, dropoffsRequest, haulingCompaniesRequest, processorRequest, producerRequest, ...trailersRequest];

                return axios.all(requests).then(
                    axios.spread((...responses) => {
                        const routes = responses[0].data;
                        renameKey(routes, '_id', 'id');

                        const drivers = responses[1].data;
                        renameKey(drivers, '_id', 'id');

                        const pickups = responses[2].data;
                        renameKey(pickups, '_id', 'id');
                        const pickupsByRouteSession = _.groupBy(pickups, 'route_session_id');

                        const dropoffs = responses[3].data;
                        renameKey(dropoffs, '_id', 'id');

                        const haulingCompanies = responses[4].data;
                        renameKey(haulingCompanies, '_id', 'id');

                        const processors = responses[5].data;
                        renameKey(processors, '_id', 'id');

                        const producers = responses[6].data;
                        renameKey(producers, '_id', 'id');

                        const trailerResponses = responses?.[7] ? responses.splice(7) : [];

                        const flattenedTrailers = [];
                        trailerResponses.forEach((response) => {
                            flattenedTrailers.push(...response.data);
                        });

                        const driverMap = arrayToMap(drivers, 'id');
                        const trailerMap = arrayToMap(flattenedTrailers, 'id');
                        const routesMap = arrayToMap(routes, 'id');
                        const haulingCompanyMap = arrayToMap(haulingCompanies, 'id');
                        const processorsMap = arrayToMap(processors, 'id');
                        const producersMap = arrayToMap(producers, 'id');
                        const dropoffMap = _.groupBy(dropoffs, 'route_session_id');
                        const dropoffTotals = _.mapValues(dropoffMap, (o) => _.sumBy(o, 'volume'));
                        const pickupTotals = _.mapValues(_.groupBy(pickups, 'route_session_id'), (o) => _.sumBy(o, 'volume'));
                        const routeSessionsResponse = _.map(routeSessions, (rs) => {
                            return {
                                BOL: rs.BOL,
                                route_number: rs.route_number,
                                manifest_number: rs.manifest_number || '',
                                po_number: rs.po_number || '',
                                kosher: rs.kosher || false,
                                route_id: rs.route_id || '',
                                metered: dropoffMap?.[rs?.id]?.[0]?.metered || '',
                                driver_id: rs.created_by || '',
                                trailer_id: rs.trailer_id || '',
                                truck_id: rs.truck_id || '',
                                trailer_number: trailerMap?.[rs.trailer_id]?.trailer_number || 'Unassigned',
                                date: rs.created_at,
                                dropoffVolume: dropoffMap?.[rs?.id]?.[0]?.net_weight || dropoffMap?.[rs?.id]?.[0]?.volume || 0,
                                multiDropoffVolume: dropoffMap?.[rs?.id] ? _.sumBy(dropoffMap?.[rs?.id], 'metered') : 0,
                                dropoffLocation: processorsMap?.[dropoffMap?.[rs?.id]?.[0]?.processor_id]?.name || '',
                                firstDropoffDate: dropoffMap[rs.id]?.[0] ? moment(dropoffMap[rs.id][0].created_at) : 'No Drop',
                                flagged: !dropoffTotals[rs.id] || !pickupTotals[rs.id] || dropoffTotals[rs.id] !== pickupTotals[rs.id],
                                id: rs.id,
                                hauling_company_name: haulingCompanyMap?.[driverMap?.[rs.created_by]?.hauling_id]?.name || '',
                                name: driverMap[rs.created_by]?.name || '',
                                route: routesMap[rs.route_id]?.name || '',
                                route_session_status: rs.status,
                                odometer_start_miles: rs.odometer_start_miles || 0,
                                odometer_end_miles: rs.odometer_end_miles || 0,
                                total_distance: rs.total_distance,
                                is_locked: _.capitalize(rs.is_locked),
                                volume: pickupTotals[rs.id] ? pickupTotals[rs.id] : 0,
                                firstPickupDate: pickupsByRouteSession?.[rs?.id]?.[0] ? moment(pickupsByRouteSession?.[rs?.id]?.[0].created_at) : 'No Pickup',
                                type_of_fluid: rs.type_of_fluid,
                                pickupsMatchProducers: !!pickupsByRouteSession?.[rs?.id]?.every?.((pickup) => {
                                    return pickup?.sample_barcodes?.[0]?.toString()?.substr?.(0, 4) === producersMap?.[pickup?.producer_id]?.license_number?.toString();
                                }),
                                pre_delivery_report_processor_recipients: processorsMap?.[rs?.pre_delivery_report_processor_recipients?.[rs?.pre_delivery_report_processor_recipients?.length - 1]]?.name,
                                outside_milk: rs.outside_milk,
                                milk_category: rs.milk_category,
                                hauling_id: haulingCompanyMap?.[driverMap?.[rs.created_by]?.hauling_id]?.id || '',
                                delivery_report: rs?.delivery_report,
                            };
                        });

                        resolve({ pickups: routeSessionsResponse });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getRouteSessionDetails;
