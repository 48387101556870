import { getExpressAPIHost } from '../../../../utils';
import { convertParamsToAQPQueryString } from './utils';
import store from '../../../store';

const buildExpressBulkQuery = (endpoint, { params }) => {
    const { region } = store.getState().persisted.auth.user.data;
    return {
        url: `${getExpressAPIHost() + endpoint}/body-query/`,
        body: { query: convertParamsToAQPQueryString(params) },
    };
};

export default buildExpressBulkQuery;
