import axios from 'axios';
import { getBarnScoresQuery, editBarnScoreQuery, addBarnScoreQuery } from './queries';
import _ from 'lodash';

export const getBarnScores = async ({ startDate, endDate }) => {
    const query = getBarnScoresQuery({ startDate, endDate });
    const request = axios.get(query);
    const { data } = await request;
    return data;
};

export const addBarnScore = async (model) => {
    const query = addBarnScoreQuery(model);
    const request = axios.post(query.url, { ...query.body });
    const { data } = await request;
    return data;
};

export const editBarnScore = async (id, model) => {
    const query = editBarnScoreQuery(id, model);
    const request = axios.patch(query.url, { ...query.body });
    const { data } = await request;
    return data;
};
