import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

const MemberCenterConfig = {
    settings: {
        layout: {
            config: {
                navbar: { display: true },
                toolbar: { mobileOnly: true },
                footer: { display: false },
                leftSidePanel: { display: false },
                rightSidePanel: { display: false },
            },
        },
    },
    auth: [...authRoles.admin, ...authRoles.producer],
    routes: [{ exact: true, path: '/member-center', component: FuseLoadable({ loader: () => import('./ViewMemberCenterApp') }) }],
};

export default MemberCenterConfig;
