import React from 'react';
import moment from 'moment-timezone';
import AppBar from '@material-ui/core/AppBar';
import Card from '@material-ui/core/Card';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';
import { numberFormat } from '../../utils';
import _ from '@lodash';

const usesYesterdayTotals = (region) => ['UDA', 'PRAIRIE', 'CDI', 'LEGACY', 'MMPA'].includes(region);

function ProductionWidget({ graphDetails, isCurrentMonthYear, month, year, avgFat, avgProtein, region }) {
    const data = _.merge({}, graphDetails);
    const daysInMonth = !isCurrentMonthYear ? moment(`${year.toString()} ${month.toString()} 01`, 'YYYY MM DD').daysInMonth() : moment().format('DD');
    const daysInLastMonth = month !== '01' ? moment(`${year.toString()} ${(month - 1).toString()} 01`, 'YYYY MM DD').daysInMonth() : 31;
    const numWeeks = daysInMonth / 7;
    const numLastWeeks = daysInLastMonth / 7;

    const getTrendIcon = (value) => {
        if (value === 0) return <Icon className="text-grey">trending_flat</Icon>;
        return value > 0 ? <Icon className="text-green">trending_up</Icon> : <Icon className="text-red">trending_down</Icon>;
    };

    const getDifferenceTotal = (dataValueLabel) => {
        if (dataValueLabel.includes('Daily')) {
            const totalDifference = usesYesterdayTotals(region) ? data[0].total : data[2].total / daysInMonth;
            const dailyTotalDifference = ['MMPA'].includes(region) ? numberFormat(totalDifference, 0) : ['UDA'].includes(region) ? numberFormat(Math.floor(totalDifference), 0) : numberFormat(totalDifference, 2);
            return dailyTotalDifference;
        }
        if (dataValueLabel.includes('Weekly')) {
            const totalDifference = usesYesterdayTotals(region) ? data[1].total : data[2].total / numWeeks;
            const weeklyTotalDifference = ['MMPA'].includes(region) ? numberFormat(totalDifference, 0) : ['UDA'].includes(region) ? numberFormat(Math.floor(totalDifference), 0) : numberFormat(totalDifference, 2);
            return weeklyTotalDifference;
        }
        const monthlyTotal = ['MMPA'].includes(region) ? numberFormat(data[2].total, 0) : ['UDA'].includes(region) ? numberFormat(Math.floor(data[2].total), 0) : numberFormat(data[2].total, 2);
        return monthlyTotal;
    };

    const getDifferenceChange = (dataValueLabel) => {
        if (dataValueLabel.includes('Daily')) {
            const differenceChange = usesYesterdayTotals(region) ? data[0].previousTotal : data[2].total / daysInMonth - data[2].previousTotal / daysInLastMonth;
            const dailyDifferenceChange = ['MMPA'].includes(region) ? numberFormat(Math.abs(differenceChange), 0) : numberFormat(Math.abs(differenceChange), 2);
            return dailyDifferenceChange;
        }
        if (dataValueLabel.includes('Weekly')) {
            const differenceChange = usesYesterdayTotals(region) ? data[1].previousTotal : data[2].total / numWeeks - data[2].previousTotal / numLastWeeks;
            const weeklyDifferenceChange = ['MMPA'].includes(region) ? numberFormat(Math.abs(differenceChange), 0) : numberFormat(Math.abs(differenceChange), 2);
            return weeklyDifferenceChange;
        }
        if (['MMPA'].includes(region)) {
            return usesYesterdayTotals(region) ? numberFormat(data?.[2]?.previousTotal) : numberFormat(Math.abs(data[2].total - data[2].previousTotal * (daysInMonth / daysInLastMonth)), 0);
        }
        return usesYesterdayTotals(region) ? numberFormat(data?.[2]?.previousTotal) : numberFormat(Math.abs(data[2].total - data[2].previousTotal * (daysInMonth / daysInLastMonth)), 2);
    };

    const getPercentChange = (dataValueLabel) => {
        if (dataValueLabel.includes('Daily')) {
            let percentChange = 0;
            if (data?.[0]?.percentChange != null) {
                percentChange = data[0].percentChange;
            } else if (usesYesterdayTotals(region)) {
                percentChange = ((data[0].total - data[0].previousTotal) / data[0].previousTotal) * 100;
            } else {
                percentChange = ((data[2].total / daysInMonth - data[2].previousTotal / daysInLastMonth) / (data[2].previousTotal / daysInLastMonth)) * 100;
            }
            return numberFormat(Math.abs(percentChange), 2);
        }
        if (dataValueLabel.includes('Weekly')) {
            let percentChange = 0;
            if (data?.[1]?.percentChange != null) {
                percentChange = data[1].percentChange;
            } else if (usesYesterdayTotals(region)) {
                percentChange = ((data[1].total - data[2].previousTotal / numLastWeeks) / data[2].previousTotal / numLastWeeks) * 100;
            } else {
                percentChange = ((data[2].total / numWeeks - data[2].previousTotal / numLastWeeks) / (data[2].previousTotal / numLastWeeks)) * 100;
            }
            return numberFormat(Math.abs(percentChange), 2);
        }
        let percentChange = 0;
        if (data?.[2]?.percentChange != null) {
            percentChange = data[2].percentChange;
        } else if (usesYesterdayTotals(region)) {
            percentChange = ((data[2].total - data[2].previousTotal) / data[2].previousTotal) * 100;
        } else {
            percentChange = ((data[2].total - data[2].previousTotal * (daysInMonth / daysInLastMonth)) / (data[2].previousTotal * (daysInMonth / daysInLastMonth))) * 100;
        }
        return numberFormat(Math.abs(percentChange), 2);
    };

    return (
        <Card className="w-full rounded-8 shadow-none border-1 h-full">
            <div className="flex flex-col flex-grow justify-center">
                {Object.keys(data).map((value, index) => (
                    <AppBar key={value} position="relative" color={index % 2 === 0 ? 'primary' : 'inherit'}>
                        {index === 0 && (
                            <div className="flex items-center justify-between px-16 pt-16 h-auto" style={{ backgroundColor: '#3B4352' }}>
                                <Typography className="text-20 font-bold">Production</Typography>
                            </div>
                        )}
                        <div className="px-4 py-16 flex flex-row items-center justify-between">
                            <Typography className="h2 px-12 whitespace-no-wrap" color="inherit">
                                {data[value].label}
                            </Typography>
                        </div>
                        <div className="w-full px-16 py-16 flex flex-col justify-between items-start">
                            <Typography className="text-32 font-300 leading-none" color="inherit">
                                {getDifferenceTotal(data[value].label)}
                            </Typography>
                            {region === 'RF' && (
                                <div className="flex items-left justify-between py-4">
                                    <Typography className="text-12 font-150 leading-none" color="inherit pt-6">
                                        {`${(parseFloat(data[value].total) * ((parseFloat(avgFat) + parseFloat(avgProtein)) / 100)).toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} KG of solids per ${data[value].label.includes('Weekly') ? 'week' : data[value].label.includes('Monthly') ? 'month' : 'day'}`}
                                    </Typography>
                                </div>
                            )}
                            <div className="flex items-left justify-between py-8">
                                <Typography className="text-16 font-300 leading-none" style={{ paddingTop: 11 }} color="inherit">
                                    Last Month
                                </Typography>
                            </div>
                            <div className="flex flex-row items-center">
                                {getTrendIcon(data[value].total - data[value].previousTotal)}
                                <Typography className="mx-8 text-10">
                                    {`${getDifferenceChange(data[value].label)} `} {`(${getPercentChange(data[value].label)} %)`}
                                </Typography>
                            </div>
                        </div>
                    </AppBar>
                ))}
            </div>
        </Card>
    );
}

export default React.memo(ProductionWidget);
