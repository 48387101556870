import axios from 'axios';
import { editTrailerQuery } from './queries';

const editTrailer = (model, key) =>
    new Promise((resolve, reject) => {
        const query = editTrailerQuery({ key, model });
        const request = axios.patch(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to edit trailer'));
            });
    });

export default editTrailer;
