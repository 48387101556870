import axios from 'axios';
import { getAPIHost } from '../../utils';

const downloadBulkChart = (id, tank) =>
    new Promise((resolve, reject) => {
        axios
            .get(`${getAPIHost()}/download-producers-bulk-chart/${id}/${tank}`)
            .then((response) => {
                resolve(response);
            })
            .catch(() => {
                reject(new Error('Unable to download file'));
            });
    });

export default downloadBulkChart;
