import axios from 'axios';
import moment from 'moment-timezone';
import { renameKey } from '../../utils';
import { getRouteSessionsQuery, getPickupsQuery, getDropoffsQuery, batchQuery, bulkQuery } from './queries';

const getAdminInTransitDetails = () =>
    new Promise((resolve, reject) => {
        const start = moment().subtract(30, 'days').startOf('day');
        const end = moment().endOf('day');
        const routeSessionQuery = getRouteSessionsQuery({
            fields: ['id', 'status'],
            start,
            end,
            filterDeleted: true,
            filterType: 'milk',
        });
        const routeSessionRequest = axios.get(routeSessionQuery);

        routeSessionRequest
            .then((routeSessionResponse) => {
                const routeSessions = routeSessionResponse.data.filter((rs) => rs.status !== 'closed');
                const routeSessionIds = routeSessions.map((rs) => rs.id);

                const pickupQuery = bulkQuery(
                    getPickupsQuery,
                    {
                        fields: ['volume'],
                        routeSessionIds,
                        filterDeleted: true,
                    },
                    'routeSessionIds'
                );
                const dropoffQuery = bulkQuery(
                    getDropoffsQuery,
                    {
                        fields: ['volume'],
                        routeSessionIds,
                        filterDeleted: true,
                    },
                    'routeSessionIds'
                );

                const pickupRequests = axios.post(pickupQuery.url, pickupQuery.body);
                const dropoffRequests = axios.post(dropoffQuery.url, dropoffQuery.body);

                axios.all([pickupRequests, dropoffRequests]).then(
                    axios.spread((...responses) => {
                        const pickups = responses[0].data;
                        const dropoffs = responses[1].data;

                        const pickupTotal = pickups.reduce((acc, val) => acc + val.volume, 0);
                        const dropoffTotal = dropoffs.reduce((acc, val) => acc + val.volume, 0);

                        resolve({ volume: Math.max(pickupTotal - dropoffTotal, 0) });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getAdminInTransitDetails;
