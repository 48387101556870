import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';

export { default as DeleteModal } from './DeleteModal';
export { default as ReceivingErrorOverlay } from './ReceivingErrorOverlay';
export { default as ReceivingToolbar } from './ReceivingToolbar';

const Receiving = {
    auth: [...authRoles.admin, ...authRoles.hauling, ...authRoles.processor],
    routes: [{ path: '/receiving', component: FuseLoadable({ loader: () => import('./ReceivingApp') }) }],
};

export default Receiving;
