import React, { useContext, useState } from 'react';
import { StandaloneSearchBox } from '@react-google-maps/api';
import { TextField, useTheme } from '@mui/material';
import { MapContext } from '../maps/MapContext';

const AddressSearchBar = () => {
    const [searchbar, setSearchbar] = useState(null);

    const { map } = useContext(MapContext);
    const { zIndex } = useTheme();

    const handleChange = () => {
        // StandaloneSearchBox renders a dropdown list of suggested locations
        // That list is a direct child of the body element
        // To be visible over a dialog, the z-index of the list must be higher than the dialog
        const suggestionsElements = document.querySelectorAll('.pac-container');
        if (suggestionsElements?.length > 0) {
            for (let i = 0; i < suggestionsElements.length; i += 1) {
                suggestionsElements[i].style.zIndex = zIndex.modal + 1000;
            }
        }
    };

    const onPlacesChanged = () => {
        const places = searchbar.getPlaces();
        if (places && places.length > 0) {
            const location = places[0].geometry.location.toJSON();
            map.panTo(location);
        }
    };

    if (!map) return null;

    return (
        <StandaloneSearchBox onLoad={setSearchbar} onPlacesChanged={onPlacesChanged}>
            <TextField fullWidth variant="outlined" placeholder="Search for an address" onChange={handleChange} />
        </StandaloneSearchBox>
    );
};

export default AddressSearchBar;
