import axios from 'axios';
import { createFluidItemQuery } from './queries';

const addFluidItem = async (model) => {
    try {
        const query = createFluidItemQuery({ model });
        const request = axios.post(query.url, { ...query.body });
        const { data } = await request;
        return { items: data };
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        throw new Error('Unable to add schedule item');
    }
};

export default addFluidItem;
