import React from 'react';
import { Dialog, Button, DialogActions, DialogContent, DialogTitle, Box } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';

const ScrollableConfirmationDialog = ({ showDialog, handleDialogCancel, handleDialogConfirm, dialogColumns, dialogRows, dialogTitle, emptyDialogMessage, cancelButtonText, confirmButtonText }) => {
    return (
        <Dialog sx={{ fontSize: 14 }} open={showDialog} fullWidth maxWidth="lg" display="flex" scroll={'paper'} aria-labelledby="scroll-dialog-title" aria-describedby="scroll-dialog-description">
            <DialogTitle id="scroll-dialog-title"> {dialogTitle}</DialogTitle>
            <DialogContent>
                {dialogRows.length !== 0 && (
                    <Box sx={{ width: '100%', height: '60vh', display: 'flex', flexGrow: 1 }}>
                        <DataGrid
                            getRowHeight={() => 'auto'}
                            getEstimatedRowHeight={() => 200}
                            sx={{ fontSize: 12, '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' }, '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' }, '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' } }}
                            isRowSelectable={() => false}
                            fullWidth
                            rows={dialogRows}
                            columns={dialogColumns}
                            getRowId={(row) => row._id}
                        />
                    </Box>
                )}
                {dialogRows.length === 0 && <Box className="root"> {emptyDialogMessage}</Box>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogCancel}>{cancelButtonText || 'Cancel'}</Button>
                <Button style={{ color: '#fff', backgroundColor: '#039be5' }} onClick={handleDialogConfirm}>
                    {confirmButtonText || 'Confirm'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ScrollableConfirmationDialog;
