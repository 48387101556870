import React, { useState, useEffect } from 'react';
import { NewDatePicker } from 'app/custom-widgets';
import { Box, FormControl, FormGroup, Checkbox, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import _ from 'lodash';
import { compareDates, directionalMapCompare } from '../../../util-files/compare-utils';

export const milkClassDefaults = {
    class_1: false,
    class_2: false,
    class_3: false,
    class_4: false,
};

const getDateFromHistoryObject = (obj) => {
    return moment(obj.date).toDate();
};

const directionalDateSort = directionalMapCompare(getDateFromHistoryObject, compareDates);

const ProcessorMonthlyPoolingInformationSelects = ({ poolingStatusesByMonth, setPoolingStatusForMonth, milkClassesByMonth, setMilkClassesForMonth }) => {
    const [selectedDate, setSelectedDate] = useState(useSelector((state) => moment(`${state.persisted.dateDetails.selectedDate}`, 'YYYY-MM')));
    const [isPooled, setIsPooled] = useState(null);
    const [milkClasses, setMilkClasses] = useState({ ...milkClassDefaults });
    const startDate = useSelector((state) => moment(`${state.persisted.dateDetails.startDate}`, 'YYYY-MM'));

    /**
     * @param {Date} month
     * @returns {Object} an empty object or the matched pooling status
     * Processors are not required to make a pooling status decision every month.
     * This function will return:
     * a) The Pooling Status decision matching the month
     * b) if no match, returns the last pooling status decision made before that month
     */
    const getPoolingStatusForMonth = (month) => {
        if (_.isEmpty(poolingStatusesByMonth)) {
            return {};
        }

        const monthMoment = moment(month);
        const monthString = monthMoment.format('YYYY-MM');

        if (Object.prototype.hasOwnProperty.call(poolingStatusesByMonth, monthString)) {
            return poolingStatusesByMonth[monthString];
        }

        const poolingStatusesByDateDescending = Object.values(poolingStatusesByMonth).sort(directionalDateSort.desc);
        const activePoolingStatusForMonth = poolingStatusesByDateDescending.find((poolingStatus) => {
            const poolingStatusMoment = moment(poolingStatus.date);

            return poolingStatusMoment.isSameOrBefore(monthMoment);
        });

        return activePoolingStatusForMonth ?? {};
    };

    /**
     * @param {Date} month
     * @returns {Object} The selected milk classes for month
     * Processors are not required to make a milk class decision every month.
     * This function will return:
     * a) The Milk Classes  matching the month
     * b) if no match, returns the last Milk Classes decision made before that month
     */
    const getMilkClassesForMonth = (month) => {
        const monthMoment = moment(month);
        const monthString = monthMoment.format('YYYY-MM');

        const newMilkClassSelectionsForMonth = {
            ...milkClassDefaults,
            date: monthString,
        };

        if (_.isEmpty(milkClassesByMonth)) {
            return newMilkClassSelectionsForMonth;
        }

        if (Object.prototype.hasOwnProperty.call(milkClassesByMonth, monthString)) {
            return milkClassesByMonth[monthString];
        }

        const milkClassesByDateDescending = Object.values(milkClassesByMonth).sort(directionalDateSort.desc);

        const activeMilkClassesForMonth = milkClassesByDateDescending.find((milkClassesChoice) => {
            const milkClassesMoment = moment(milkClassesChoice.date);

            return milkClassesMoment.isSameOrBefore(monthMoment);
        });

        if (activeMilkClassesForMonth == null) {
            return newMilkClassSelectionsForMonth;
        }
        return activeMilkClassesForMonth;
    };

    useEffect(() => {
        const activePoolingStatus = getPoolingStatusForMonth(selectedDate);
        const activeMilkClasses = getMilkClassesForMonth(selectedDate);

        if (_.isEmpty(activePoolingStatus)) {
            setIsPooled(null);
        } else {
            setIsPooled(activePoolingStatus.isPooled);
        }

        setMilkClasses(activeMilkClasses);
    }, [selectedDate, poolingStatusesByMonth, milkClassesByMonth]);

    const updatePoolingStatusForMonth = (newPoolingStatus, month) => {
        const monthString = month.format('YYYY-MM');

        const updatedPoolingStatus = { ...newPoolingStatus, date: month.toDate() };
        setIsPooled(updatedPoolingStatus.isPooled);
        setPoolingStatusForMonth(updatedPoolingStatus, monthString);
    };

    const updateSelectedMilkClassesForMonth = (updatedMilkClassStatus, month) => {
        const monthString = month.format('YYYY-MM');
        let updatedMilkClassSelectionsForMonth;

        if (Object.prototype.hasOwnProperty.call(milkClassesByMonth, monthString)) {
            updatedMilkClassSelectionsForMonth = milkClassesByMonth[monthString];
        } else {
            const activeMilkClassesForMonth = getMilkClassesForMonth(month.toDate());

            updatedMilkClassSelectionsForMonth = {
                ...milkClassDefaults,
                ...activeMilkClassesForMonth,
                date: monthString,
            };
        }

        const [updatedMilkClass, updatedStatus] = Object.entries(updatedMilkClassStatus)[0];
        updatedMilkClassSelectionsForMonth[updatedMilkClass] = updatedStatus;

        setMilkClasses(updatedMilkClassSelectionsForMonth);
        setMilkClassesForMonth(updatedMilkClassSelectionsForMonth, monthString);
    };

    return (
        <fieldset
            style={{
                border: '1px solid rgba(0,0,0,0.23)',
                borderRadius: '4px',
                marginTop: '7px',
                marginBottom: '16px',
                padding: '8px',
            }}
        >
            <legend
                style={{
                    marginLeft: '8px',
                    paddingLeft: '4px',
                    paddingRight: '4px',
                    color: 'rgba(0,0,0,0.54)',
                    fontSize: '12px',
                }}
            >
                Monthly Pooling Information
            </legend>
            <Box display={'flex'} paddingBottom={'32px'} justifyContent={'center'}>
                <NewDatePicker setSelectedDate={setSelectedDate} selectedDate={selectedDate} disableFutureDate startDate={startDate} />
            </Box>
            <PoolingStatusRadioGroup isPooled={isPooled} updatePoolingStatusForMonth={(updatedFields) => updatePoolingStatusForMonth(updatedFields, selectedDate)} />
            <MilkClassCheckboxGroup milkClasses={milkClasses} updateMilkClassesForMonth={(updatedFields) => updateSelectedMilkClassesForMonth(updatedFields, selectedDate)} />
        </fieldset>
    );
};

export default ProcessorMonthlyPoolingInformationSelects;

export const PoolingStatusRadioGroup = ({ isPooled, updatePoolingStatusForMonth }) => {
    const handleChange = (event) => {
        const newPooledStatus = event.target.value === 'true';

        updatePoolingStatusForMonth({
            isPooled: newPooledStatus,
            is_locked: false,
        });
    };

    return (
        <Box pb={'23px'}>
            <Box color={'rgba(0,0,0,0.54)'} fontSize={'12px'} textAlign={'center'}>
                Select Pooling Status
            </Box>
            <Box display={'flex'} justifyContent={'space-evenly'}>
                <FormControl style={{ width: '100%' }}>
                    <RadioGroup row name={'pooling-status-radio-group'} value={isPooled} onChange={handleChange} style={{ width: '100%', display: 'flex', justifyContent: 'space-evenly' }}>
                        <FormControlLabel value label={'Pool'} control={<Radio />} />
                        <FormControlLabel value={false} label={'Non-Pool'} control={<Radio />} />
                    </RadioGroup>
                </FormControl>
            </Box>
        </Box>
    );
};

export const MilkClassCheckboxGroup = ({ milkClasses, updateMilkClassesForMonth }) => {
    const handleChange = (event) => {
        const milkClassName = event.target.name;
        const isChecked = event.target.checked;

        updateMilkClassesForMonth({ [milkClassName]: isChecked });
    };

    return (
        <>
            <Box color={'rgba(0,0,0,0.54)'} fontSize={'12px'} textAlign={'center'}>
                Select Milk Processing Classes
            </Box>
            <FormControl style={{ width: '100%' }}>
                <FormGroup>
                    <Box display={'flex'} justifyContent={'space-evenly'} width={'100%'}>
                        <FormControlLabel control={<Checkbox checked={milkClasses.class_1 ?? false} onChange={handleChange} name={'class_1'} />} label={'Class 1'} />
                        <FormControlLabel control={<Checkbox checked={milkClasses.class_2 ?? false} onChange={handleChange} name={'class_2'} />} label={'Class 2'} />
                        <FormControlLabel control={<Checkbox checked={milkClasses.class_3 ?? false} onChange={handleChange} name={'class_3'} />} label={'Class 3'} />
                        <FormControlLabel control={<Checkbox checked={milkClasses.class_4 ?? false} onChange={handleChange} name={'class_4'} />} label={'Class 4'} />
                    </Box>
                </FormGroup>
            </FormControl>
        </>
    );
};
