import React, { Component } from 'react';
import { withStyles, Collapse, IconButton, ListItem, ListItemText } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
/* eslint-disable-next-line */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FuseNavVerticalGroup from './FuseNavVerticalGroup';
import FuseNavVerticalItem from './FuseNavVerticalItem';
import FuseNavBadge from './../FuseNavBadge';
import _ from 'lodash';
import { verifyVerticalNav } from '../../../../app/main/apps/ConfiguredApps/navigation/navigation-utils';

const propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        icon: PropTypes.string,
        children: PropTypes.array,
        subPermissionOnly: PropTypes.bool,
        requireAllPermissions: PropTypes.bool,
    }),
};

const defaultProps = {};

const styles = (theme) => ({
    root: {
        padding: 0,
        '&.open': {
            backgroundColor: 'rgba(0,0,0,.08)',
        },
    },
    item: {
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingRight: 12,
        '&.square': {
            width: '100%',
            borderRadius: '0',
        },
    },
});

function needsToBeOpened(props) {
    return props.location && isUrlInChildren(props.item, props.location.pathname);
}

function isUrlInChildren(parent, url) {
    if (!parent.children) {
        return false;
    }

    for (let i = 0; i < parent.children.length; i++) {
        if (parent.children[i].children) {
            if (isUrlInChildren(parent.children[i], url)) {
                return true;
            }
        }

        if (parent.children[i].url === url || url.includes(parent.children[i].url)) {
            return true;
        }
    }

    return false;
}

class FuseNavVerticalCollapse extends Component {
    constructor(props) {
        super(props);

        this.state = { open: needsToBeOpened(this.props) };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (needsToBeOpened(this.props)) {
            !prevState.open && this.setState({ open: true });
        } else {
            prevState.open && this.setState({ open: false });
        }
    }

    handleClick = () => {
        this.setState({ open: !this.state.open });
    };

    render() {
        const { item, nestedLevel, classes, user, active } = this.props;

        if (!verifyVerticalNav(item, user)) {
            return null;
        }

        let paddingValue = 40 + nestedLevel * 16;
        const listItemPadding = nestedLevel > 0 ? 'pl-' + (paddingValue > 80 ? 80 : paddingValue) : 'pl-24';
        return (
            <ul className={classNames(classes.root, this.state.open && 'open')}>
                <ListItem button className={classNames(classes.item, listItemPadding, 'list-item', active)} onClick={this.handleClick} id={item.id}>
                    {item.icon && (
                        <i color="action" className="text-16 flex-no-shrink material-icons md-light">
                            {item.icon}
                        </i>
                    )}
                    &nbsp; &nbsp; &nbsp;
                    <ListItemText style={{ color: 'white' }} className="list-item-text" primary={item.title} classes={{ primary: 'text-14' }} />
                    {item.badge && <FuseNavBadge className="mr-4" badge={item.badge} />}
                    <IconButton disableRipple className="w-16 h-16 p-0">
                        <i className="text-16 material-icons md-light" color="inherit">
                            {this.state.open ? 'expand_less' : 'expand_more'}
                        </i>
                    </IconButton>
                </ListItem>

                {item.children && (
                    <Collapse in={this.state.open} className="collapse-children">
                        {item.children.map((item) => (
                            <React.Fragment key={`${item.id} ${item.auth} ${item.permission}`}>
                                {item.type === 'group' && <FuseNavVerticalGroup item={item} nestedLevel={nestedLevel + 1} active={active} />}

                                {item.type === 'collapse' && <NavVerticalCollapse item={item} nestedLevel={nestedLevel + 1} active={active} />}

                                {item.type === 'item' && <FuseNavVerticalItem item={item} nestedLevel={nestedLevel + 1} active={active} />}
                            </React.Fragment>
                        ))}
                    </Collapse>
                )}
            </ul>
        );
    }
}

function mapStateToProps({ persisted }) {
    return {
        user: persisted.auth.user,
    };
}

FuseNavVerticalCollapse.propTypes = propTypes;
FuseNavVerticalCollapse.defaultProps = defaultProps;

const NavVerticalCollapse = withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps)(FuseNavVerticalCollapse)));

export default NavVerticalCollapse;
