import Environment from '../../Environment';
import MockSplit from '../../MockSplit';

const prod = new Environment({
    name: 'PRODUCTION',
    evaluationFn: (attributes) => {
        const regions = ['CDI', 'LEGACY', 'PRAIRIE', 'MMPA'];
        if (attributes.role === 'admin' && regions.includes(attributes.region)) return 'on';
        if (attributes.role === 'processor' && attributes.region === 'PRAIRIE') return 'on';
        return 'off';
    },
});

const local = new Environment({
    ...prod,
    name: 'LOCAL',
});

const dev = new Environment({
    ...prod,
    name: 'DEV',
});

const staging = new Environment({
    ...prod,
    name: 'STAGING',
});

const demo = new Environment({
    ...prod,
    name: 'DEMO',
});

const can_access_manifest_view = new MockSplit({
    name: 'can_access_manifest_view',
    environments: [local, dev, staging, demo, prod],
});

export default can_access_manifest_view;
