import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import * as FuseActions from 'app/store/actions';
import { bindActionCreators } from 'redux';
import { DialogContentText } from '@material-ui/core';
import { uploadPaymentSheet } from '../../repository';

function PaperComponent(props) {
    return (
        // <Draggable>
        <Paper {...props} />
        // </Draggable>
    );
}

class MilkPriceDialog extends React.Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
    }

    state = { open: false, enableButton: false };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleEnableButton = () => {
        this.setState({ enableButton: true });
    };

    handleUpload = ({ showMessage }) => {
        const { showLoadingDialog, hideLoadingDialog, selectedMonth, selectedYear } = this.props;
        const obj = { file: this.fileInput.current.files[0] };

        if (showLoadingDialog) {
            showLoadingDialog();
        }

        if (obj.file) {
            if (obj.file.name.slice(-4) !== '.pdf' && obj.file.name.slice(-4) !== '.csv') {
                showMessage({ message: 'Please provide a valid .pdf or .csv file.' });
                if (hideLoadingDialog) {
                    hideLoadingDialog();
                }
                return;
            }

            const formData = new FormData();

            formData.append('file', obj.file);

            uploadPaymentSheet(formData, 'Milk Price', selectedYear, selectedMonth)
                .then(() => {
                    showMessage({ message: 'Successfully uploaded your Milk Price file.' });
                    if (hideLoadingDialog) {
                        hideLoadingDialog();
                    }
                })
                .catch(() => {
                    showMessage({ message: 'There was a problem uploading your file. Please contact support.' });
                    if (hideLoadingDialog) {
                        hideLoadingDialog();
                    }
                });

            this.setState({ open: false });
        }
    };

    render() {
        const { open, enableButton } = this.state;

        return (
            <div className="inline-flex">
                <Button type="submit" variant="contained" color="secondary" className="m-4 ml-4" aria-label="Pay Calcs" onClick={this.handleClickOpen}>
                    Milk Price
                </Button>
                <Dialog open={open} onClose={this.handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="sm" aria-labelledby="draggable-dialog-title">
                    <DialogTitle id="draggable-dialog-title">Upload File</DialogTitle>
                    <DialogContent>
                        <DialogContentText className="text-black">
                            Upload File &nbsp;&nbsp;&nbsp; <input type="file" id="pdfFile" ref={this.fileInput} onChange={this.handleEnableButton} />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleUpload(this.props)} variant="contained" color="secondary" disabled={!enableButton}>
                            Upload
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps({ mainReducer }) {
    return { pickupDetails: mainReducer.pickupDetails.data, dropoffDetails: mainReducer.dropoffDetails.data };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: FuseActions.showMessage }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(MilkPriceDialog);
