import axios from 'axios';
import _ from 'lodash';
import { getLabProvidersQuery } from './queries';

const getLabProviderListDetails = (includeDeleted) =>
    new Promise((resolve, reject) => {
        const params = { ...(!includeDeleted && { filterDeleted: true }) };
        const query = getLabProvidersQuery(params);
        const request = axios.get(query);

        request
            .then((response) => {
                const labProviders = _.orderBy(response.data, ['name'], ['asc']);
                resolve({ labProviders });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getLabProviderListDetails;
