import { getUserRegion, toTitleCase } from '../../../../utils';

// eslint-disable-next-line import/prefer-default-export
export const getPayGroupDisplayName = (payGroup) => {
    const region = getUserRegion();

    if (region.includes('TCJ')) {
        return `$${payGroup.replace('_', '.')}`;
    }

    return toTitleCase(payGroup, '_');
};
