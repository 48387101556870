import React, { Component } from 'react';
import { withStyles, Button, Card, Typography } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from '@lodash';
import MiniTable from './MiniTable';
import * as Actions from '../store/actions';

class StdGraph extends Component {
    state = { dataset: null };

    componentDidMount() {
        const { defaultSet } = this.props;
        this.setState({ dataset: defaultSet });
    }

    setDataSet = (dataset) => {
        this.setState({ dataset });
    };

    setInspect = (selected) =>
        new Promise(() => {
            const { setInspectReducer } = this.props;
            setInspectReducer(selected);
        });

    rowClicked = (row) => {
        const { getSingleInstance, history } = this.props;
        getSingleInstance('lab-reports', row.id).then((resolve) => this.setInspect(resolve.payload.labReport[0]).then(history.push({ pathname: '/inspect-lab-report' })));
    };

    transformTableDetails = (details, dataset) => {
        const array = [];
        for (let i = 0; i < Object.keys(details[dataset]).length; i += 1) {
            for (let j = 0; j < details[dataset][Object.keys(details[dataset])[i]].length; j += 1) {
                array.push({
                    id: details[dataset][Object.keys(details[dataset])[i]][j].id,
                    producer: Object.keys(details[dataset])[i],
                    offences: details[dataset][Object.keys(details[dataset])[i]][j].value,
                });
            }
        }
        return array.sort((a, b) => (a.offences > b.offences ? -1 : b.offences > a.offences ? 1 : 0));
    };

    transformTableDetailsRf = (details, dataset) => {
        const array = [];
        for (let i = 0; i < Object.keys(details[dataset]).length; i += 1) {
            for (let j = 0; j < details[dataset][Object.keys(details[dataset])[i]].length; j += 1) {
                const { value } = details[dataset][Object.keys(details[dataset])[i]][j];
                array.push({
                    id: details[dataset][Object.keys(details[dataset])[i]][j].id,
                    date: details[dataset][Object.keys(details[dataset])[i]][j].date,
                    producer: Object.keys(details[dataset])[i],
                    // eslint-disable-next-line no-restricted-globals
                    offences: isNaN(value) ? value : value.toFixed(),
                });
            }
        }
        return array.sort((a, b) => (a.date > b.date ? -1 : b.date > a.date ? 1 : 0));
    };

    render() {
        const { graphData, tableData, region } = this.props;
        const { dataset } = this.state;

        if (!dataset || !graphData[dataset][0]) {
            return null;
        }
        const colorSettings = graphData[dataset][0].isOutlier.map((value) => (value ? 'rgb(211,4,0)' : 'rgb(7,157,229)'));
        const data = _.merge({}, graphData);
        const dataWithColors = data[dataset].map((obj) => ({
            ...obj,
            backgroundColor: colorSettings,
            borderColor: colorSettings,
            borderWidth: 1,
            hoverBackgroundColor: colorSettings,
            hoverBorderColor: colorSettings,
            fill: false,
        }));
        // console.log([tableData.bacto, tableData.bmcc, tableData.thermo, tableData.temperature, tableData.colostrum]);
        return (
            // test for IE before applying flex
            <div className={!!document.documentMode || /* @cc_on!@ */ false ? 'block' : window.innerWidth >= 1024 ? 'flex flex-row flex-1' : 'flex flex-col flex-1'}>
                {region === 'NL' && (
                    <div className={window.innerWidth >= 1024 ? 'pb-20 w-3/4 p-12' : 'pb-20 w-full p-12'}>
                        <Card className="w-full h-full rounded-8 shadow-none border-1">
                            <div className="relative p-24 flex flex-row items-center justify-between">
                                <div className="flex flex-col">
                                    <Typography className="h1 sm:h3 font-bold">Outlier Data</Typography>
                                </div>
                                <div className="flex-no-wrap whitespace-no-wrap flex-row items-center">
                                    {Object.keys(data).map((key) => (
                                        <Button
                                            style={{ fontSize: window.innerWidth < 375 ? '8px' : window.innerWidth < 465 ? '10px' : '14px' }}
                                            key={key}
                                            className={`py-8 px-12${window.innerWidth < 375 ? 'min-w-0 max-w-60' : window.innerWidth < 465 ? 'min-w-0 max-w-62' : ''}`}
                                            size="small"
                                            onClick={() => this.setDataSet(key)}
                                            disabled={key === dataset}
                                            variant={key === dataset ? 'contained' : 'text'}
                                        >
                                            {key}
                                        </Button>
                                    ))}
                                </div>
                            </div>

                            <Typography className="relative h-250 sm:h-320 sm:pb-20">
                                <Bar
                                    data={{ labels: dataWithColors[0].labels, datasets: dataWithColors }}
                                    options={{
                                        legend: { display: false },
                                        maintainAspectRatio: false,
                                        beginAtZero: true,
                                        scales: { xAxes: [{ id: 'x-axis-0' }], yAxes: [{ ticks: { min: 0 } }] },
                                    }}
                                />
                            </Typography>
                        </Card>
                    </div>
                )}
                {region === 'NL' && (
                    <div className={window.innerWidth >= 1024 ? 'pb-20 p-12 w-1/4' : 'pb-20 p-12 w-full'}>
                        <MiniTable
                            tableTitle="Outlier Offences"
                            columnSettings={[
                                {
                                    id: 'producer',
                                    label: 'Name',
                                    cellClassNameSettings: 'w-1/2',
                                    formatter: null,
                                },
                                {
                                    id: 'offences',
                                    label: 'Total',
                                    cellClassNameSettings: 'w-1/2',
                                    formatter: ['Number', 0],
                                },
                            ]}
                            tableData={this.transformTableDetails(tableData, dataset)}
                            rowClick={this.rowClicked}
                            defaultRowAmount={13}
                            disableRowsPerPage
                            hover
                        />
                    </div>
                )}
                {region === 'RF' && (
                    <div className="w-full">
                        <Card className="w-full h-full rounded-8 shadow-none border-1">
                            <div className="relative p-12 flex flex-row items-center justify-between">
                                <div className="flex flex-col">
                                    <Typography className="h1 sm:h3 font-bold">Above Standard Results</Typography>
                                </div>
                                <div className="flex-no-wrap whitespace-no-wrap flex-row items-center">
                                    {Object.keys(data).map((key) => (
                                        <Button
                                            style={{ fontSize: window.innerWidth < 375 ? '8px' : window.innerWidth < 465 ? '10px' : '14px' }}
                                            key={key}
                                            className={`py-8 px-12${window.innerWidth < 375 ? 'min-w-0 max-w-60' : window.innerWidth < 465 ? 'min-w-0 max-w-62' : ''}`}
                                            size="small"
                                            onClick={() => this.setDataSet(key)}
                                            disabled={key === dataset}
                                            variant={key === dataset ? 'contained' : 'text'}
                                        >
                                            {key}
                                        </Button>
                                    ))}
                                </div>
                            </div>

                            <Typography className="relative h-250 p-12">
                                <MiniTable
                                    columnSettings={[
                                        {
                                            id: 'date',
                                            label: 'Date Sampled',
                                            cellClassNameSettings: 'w-1/4',
                                            formatter: ['Date', 'MMM Do, YYYY'],
                                        },
                                        {
                                            id: 'producer',
                                            label: 'Name',
                                            cellClassNameSettings: 'w-2/4',
                                            formatter: null,
                                        },
                                        {
                                            id: 'offences',
                                            label: 'Result',
                                            cellClassNameSettings: 'w-1/4',
                                            formatter: null,
                                        },
                                    ]}
                                    tableData={this.transformTableDetailsRf(tableData, dataset)}
                                    rowClick={this.rowClicked}
                                    defaultRowAmount={10}
                                    hover
                                />
                            </Typography>
                        </Card>
                    </div>
                )}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getSingleInstance: Actions.getSingleInstance, setInspectReducer: Actions.setInspectReducer }, dispatch);
}

function mapStateToProps({ persisted }) {
    return { role: persisted.auth.user.role, region: persisted.auth.user.data.region };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(null, { withTheme: true })(StdGraph));
