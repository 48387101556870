import React, { useState } from 'react';
import { Button, CircularProgress, Grid } from '@mui/material';
import { MapProvider } from '../MapContext';
import GeofenceMap from './GeofenceMap';
import GeofenceDialog from './GeofenceDialog';
import DeleteGeofenceDialog from './DeleteGeofenceDialog';
import useGeofence from '../../useGeofence';
import { addGeofence, editGeofence, deleteGeofence } from '../../../../repository/geofences';
import { getShapeCenter } from './GeofenceManager.helpers';

const GeofenceManager = ({ center, maxFences = 2, onChange = (geofences) => {}, user_id, user_name }) => {
    const [refetch, setRefetch] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [deleting, setDeleting] = useState(false);

    const { loadingGeofences, geofences, setGeofences } = useGeofence({ user_id, refetch });
    const hasGeofences = !!geofences.length;
    const calculatedCenter = hasGeofences ? getShapeCenter(geofences[0]) : center;

    const handleSave = async (newGeofences) => {
        if (!user_id) {
            // When creating a geofence for a new user, use onChange prop
            onChange([
                {
                    ...newGeofences[0],
                    name: `${user_name} - ${newGeofences[0].type}`,
                },
            ]);
        } else if (hasGeofences) {
            // Edit existing geofence
            const mergedGeofences = newGeofences.map((geofence, index) => ({
                ...geofence,
                _id: geofences[index]._id,
                name: `${user_name} - ${geofence.type}`,
                user_id,
            }));
            onChange(mergedGeofences);
            await editGeofence(mergedGeofences[0]._id, { ...mergedGeofences[0] });
        } else {
            // When editing a user with no geofence, add a new geofence
            const name = `${user_name} - ${newGeofences[0].type}`;
            await addGeofence({
                ...newGeofences[0],
                name,
                user_id,
            });
            onChange([
                {
                    ...newGeofences[0],
                    name: `${user_name} - ${newGeofences[0].type}`,
                    user_id,
                },
            ]);
        }
        setGeofences(newGeofences);
        setRefetch((prev) => !prev);
        setOpenEditDialog(false);
    };

    const handleDelete = async () => {
        await deleteGeofence(geofences[0]._id);
        setGeofences([]);
        setRefetch((prev) => !prev);
        setDeleting(false);
    };

    if (loadingGeofences) return <CircularProgress />;

    return (
        <MapProvider geofences={geofences}>
            <Grid container>
                {hasGeofences && (
                    <Grid item xs={12}>
                        <GeofenceMap
                            geofences={geofences}
                            center={calculatedCenter}
                            displayOnly
                            // searchable
                            // wrap
                        />
                    </Grid>
                )}
                <Grid item xs={12} sx={{ marginY: (theme) => theme.spacing(2) }}>
                    <Button variant="outlined" color="secondary" onClick={() => setOpenEditDialog(true)}>
                        {hasGeofences ? 'Edit' : 'Add'} Geofence
                    </Button>
                    {hasGeofences && (
                        <>
                            <Button sx={{ marginLeft: (theme) => theme.spacing(2) }} variant="outlined" color="error" onClick={() => setDeleting(true)}>
                                Delete Geofence
                            </Button>
                            <DeleteGeofenceDialog open={deleting} onClose={() => setDeleting(false)} onDelete={handleDelete} />
                        </>
                    )}
                </Grid>
                <GeofenceDialog
                    open={openEditDialog}
                    onClose={() => setOpenEditDialog(false)}
                    onSave={handleSave}
                    maxFences={maxFences}
                    center={calculatedCenter}
                    user_id={user_id}
                    // wrap
                />
            </Grid>
        </MapProvider>
    );
};

export default GeofenceManager;
