// Direct copy of CDI localization file
const localization = {
    general: {
        full_name: 'Producer Name',
        hauling_plural: 'Hauling Companies',
        hauling_singular: 'Hauling Company',
        hauling_delivery: 'Delivery Hauler',
        license_number: 'License Number',
        lt_number: 'Route Session ID',
        metered: 'Net Amount',
        meteredLevel: 'Net Amount',
        monthlyReports: 'Monthly Reports',
        pickup_amount: 'Total Amount',
        processor_singular: 'Processor',
        processor_plural: 'Processors',
        producer_weight: 'Producer Weight',
        routeNumber: 'Lt Number',
        routeSession: 'Route Session',
        tank: 'Tank',
        trailer: 'Trailer',
        transfer: 'Quota',
        volume: 'Dairy Amount',
        customer_po: 'Customer PO #',
        merchandise: 'Merchandise',
    },
    navigation: {
        'Base Transfers': 'Quota Transfers',
    },
    lab: {
        scc: 'scc',
        temperature: 'Freezing Point',
        sample_date: 'Date',
    },
    other: {},
};

export default localization;
