import * as JSONS from './export_button_jsons';

const getDateRangePresetConfig = (region) => {
    switch (region) {
        case 'UDA':
            return JSON.stringify(JSONS.UDA);
        case 'CDI':
            return JSON.stringify(JSONS.DefaultButtons);
        case 'LEGACY':
            return JSON.stringify(JSONS.DefaultButtons);
        case 'PRAIRIE':
            return JSON.stringify(JSONS.DefaultButtons);
        case 'MMPA':
            return JSON.stringify(JSONS.DefaultButtons);
        case 'NL':
            return JSON.stringify(JSONS.DefaultButtonsMonth);
        case 'RF':
            return JSON.stringify(JSONS.DefaultButtonsMonth);
        case 'PEI':
            return JSON.stringify(JSONS.DefaultButtonsMonth);
        default:
            return JSON.stringify(JSONS.DefaultButtons);
    }
};

export default getDateRangePresetConfig;
