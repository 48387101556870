import moment from 'moment-timezone';
import _ from 'lodash';
import transformLabReport from './transformLabReport';
import { LAB_STANDARDS } from '../../../utils';

// replace with standards in util.js --^
const standards = {
    NL: {
        SCC_WARN: 300,
        SCC: 400,
        BACTO_SCAN_WARN: 75,
        BACTO_SCAN: 121,
        BACTERIA_PLC_WARN: 30000,
        BACTERIA_PLC: 50000,
    },
    RF: {
        SCC_WARN: 300,
        SCC: 400,
        BACTO_SCAN_WARN: 75,
        BACTO_SCAN: 121,
        BACTERIA_PLC_WARN: 30000,
        BACTERIA_PLC: 50000,
    },
    PEI: {
        SCC_WARN: 350,
        SCC: 400,
        BACTO_SCAN_WARN: 80,
        BACTO_SCAN: 121,
        FREEZING_POINT_WARN: -0.53,
        FREEZING_POINT: -0.525,
    },
    SM: {
        SCC_WARN: 300,
        SCC: 400,
        BACTO_SCAN_WARN: 75,
        BACTO_SCAN: 121,
        BACTERIA_PLC_WARN: 30000,
        BACTERIA_PLC: 50000,
    },
    CDI: {
        SCC: 400,
        SPC: 50000,
        LPC: 750,
        COLI: 750,
    },
    UDA: {
        SCC_WARN: 300,
        SCC: 400,
        BACTO_SCAN_WARN: 75,
        BACTO_SCAN: 121,
        BACTERIA_PLC_WARN: 30000,
        BACTERIA_PLC: 50000,
    },
};

const getSccStatus = (percentage, aboveStandardInThirdMonth, countOverStandard, region) => {
    switch (region) {
        case 'RF':
        case 'NL':
            return percentage >= 30 ? (percentage >= 40 && aboveStandardInThirdMonth > 0 ? 2 : 1) : 0;
        case 'PEI':
            return countOverStandard === 0 ? 0 : countOverStandard > 6 ? (countOverStandard >= 10 && aboveStandardInThirdMonth > 0 ? 3 : 2) : 1;
        default:
    }
};

const getBactoStatus = (percentage, bactoAboveStandardInThirdMonth, plcAboveStandardInThirdMonth, countOverStandard, region, bactoScanCountWarnings) => {
    switch (region) {
        case 'RF':
        case 'NL':
            return percentage >= 30 ? (percentage >= 40 && bactoAboveStandardInThirdMonth + plcAboveStandardInThirdMonth > 0 ? 2 : 1) : 0;
        case 'PEI':
            // Check for Penalties
            // PEI Penalty key values can be found here: src/utils/getPenaltyStatusImage
            // The logic for PEI penalties is as follows comes from loopback: src/utils/lab-reports/pei/bacto/checkIBC.ts
            if ((countOverStandard >= 3 && bactoAboveStandardInThirdMonth === 1) || (countOverStandard === 3 && bactoAboveStandardInThirdMonth > 0)) {
                // PEI Penalties are created if there are 3 or more bactos over standard in the current month and 1 bacto over standard in the third month
                // Or if there are exactly 3 issues over standard and at least 1 bacto over standard in the last three months
                return 3;
            }
            if (countOverStandard > 0) {
                // If there are no penalties, but there are labs over standard, return an infraction
                return 1;
            }
            if (bactoScanCountWarnings > 0) {
                // If there are no penalties or infractions, but there are labs over the warning threshold, return a warning
                return 2;
            }
            // Otherwise, return safe
            return 0;
        default:
    }
};

const getFreezingStatus = (countOverStandard, freezingPointAboveStandardInThirdMonth) => (countOverStandard > 0 ? (countOverStandard >= 2 && freezingPointAboveStandardInThirdMonth > 0 ? 2 : 1) : 0);

export const getAlertData = (dateRange, _labReports, region) => {
    const labReports = _labReports.map((r) => {
        return { ...r, date: new Date(r.date) };
    });
    const nativeDateRange = {
        start: dateRange.start.toDate(),
        end: dateRange.end.toDate(),
        secondMonth: dateRange.start.clone().add(1, 'months').toDate(),
        thirdMonth: dateRange.start.clone().add(2, 'months').toDate(),
        yearStart: dateRange.end.clone().add(1, 'seconds').subtract(1, 'years').toDate(),
    };
    const labReportsInDateRange = _.filter(labReports, (labReport) => labReport.date > nativeDateRange.start && labReport.date < nativeDateRange.end && !labReport.deleted_at);
    const labReportsInThirdMonth = _.filter(labReports, (labReport) => labReport.date > nativeDateRange.thirdMonth && labReport.date < nativeDateRange.end && !labReport.deleted_at);
    const sccCount = _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.somatic_cell_count) && labReport.somatic_cell_count > 0 && labReport.is_outlier === false).length;
    const sccCountOverStandard = standards?.[region]?.SCC ? _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.somatic_cell_count) && labReport.somatic_cell_count > standards[region].SCC && labReport.is_outlier === false).length : 0;
    const sccAboveStandardInThirdMonth = standards?.[region]?.SCC ? _.filter(labReportsInThirdMonth, (labReport) => !_.isNull(labReport.somatic_cell_count) && labReport.somatic_cell_count > standards[region].SCC && labReport.is_outlier === false).length : 0;
    const percentage = sccCount === 0 || sccCountOverStandard === 0 ? 0 : ((sccCountOverStandard / sccCount) * 100).toFixed(2);
    const sccStatus = getSccStatus(percentage, sccAboveStandardInThirdMonth, sccCountOverStandard, region);

    const bacteriaPlcCount = region === 'NL' || region === 'RF' ? _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.bacteria_PLC) && labReport.bacteria_PLC > 0 && labReport.is_outlier === false).length : 0;
    const bacteriaPlcCountOverStandard = region === 'NL' || region === 'RF' ? _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.bacteria_PLC) && labReport.bacteria_PLC > standards[region].BACTERIA_PLC && labReport.is_outlier === false).length : 0;
    const plcAboveStandardInThirdMonth = region === 'NL' || region === 'RF' ? _.filter(labReportsInThirdMonth, (labReport) => !_.isNull(labReport.bacteria_PLC) && labReport.bacteria_PLC > standards[region].BACTERIA_PLC && labReport.is_outlier === false).length : 0;

    // CDI only
    const spcCount = _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.standard_plate_count) && labReport.standard_plate_count > 0 && labReport.is_outlier === false).length;
    const spcCountOverStandard = standards?.[region]?.SPC ? _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.standard_plate_count) && labReport.standard_plate_count > standards[region].SPC && labReport.is_outlier === false).length : 0;
    const spcPercentage = spcCount === 0 || spcCountOverStandard === 0 ? 0 : ((spcCountOverStandard / spcCount) * 100).toFixed(2);
    const lpcCount = _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.lab_pasteurization_count) && labReport.lab_pasteurization_count > 0 && labReport.is_outlier === false).length;
    const lpcCountOverStandard = standards?.[region]?.LPC ? _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.lab_pasteurization_count) && labReport.lab_pasteurization_count > standards[region].LPC && labReport.is_outlier === false).length : 0;
    const lpcPercentage = lpcCount === 0 || lpcCountOverStandard === 0 ? 0 : ((lpcCountOverStandard / lpcCount) * 100).toFixed(2);
    const coliformCount = _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.coliform_count) && labReport.coliform_count > 0 && labReport.is_outlier === false).length;
    const coliformCountOverStandard = standards?.[region]?.COLI ? _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.coliform_count) && labReport.coliform_count > standards[region].COLI && labReport.is_outlier === false).length : 0;
    const coliformPercentage = coliformCount === 0 || coliformCountOverStandard === 0 ? 0 : ((coliformCountOverStandard / coliformCount) * 100).toFixed(2);

    // PEI only
    const labReportsInFreezingDateRange = _.filter(labReports, (labReport) => labReport.date > nativeDateRange.yearStart && labReport.date < nativeDateRange.end);

    // RF only
    const demeritPoints = _.sumBy(labReportsInDateRange, 'demerit_points');
    const freezingPointCount = region === 'PEI' ? _.filter(labReportsInFreezingDateRange, (labReport) => !_.isNull(labReport.temperature) && !!labReport.temperature && labReport.is_outlier === false).length : [];
    const freezingPointCountOverStandard = region === 'PEI' ? _.filter(labReportsInFreezingDateRange, (labReport) => !_.isNull(labReport.temperature) && !!labReport.temperature && labReport.temperature > standards[region].FREEZING_POINT && labReport.is_outlier === false).length : [];
    const freezingPointAboveStandardInThirdMonth = region === 'PEI' ? _.filter(labReportsInThirdMonth, (labReport) => !_.isNull(labReport.temperature) && !!labReport.temperature && labReport.temperature > standards[region].FREEZING_POINT && labReport.is_outlier === false).length : [];
    const freezingPointStatus = region === 'PEI' ? getFreezingStatus(freezingPointCountOverStandard, freezingPointAboveStandardInThirdMonth) : 0;

    // add month for PEI
    const labReportsInBactoDateRange = region === 'NL' || region === 'RF' ? labReportsInDateRange : _.filter(labReports, (labReport) => labReport.date > nativeDateRange.secondMonth && labReport.date < nativeDateRange.end); // PEI

    const bactoScanCount = _.filter(labReportsInBactoDateRange, (labReport) => !_.isNull(labReport.bacto_scan) && labReport.bacto_scan > 0 && labReport.is_outlier === false).length;
    const bactoScanCountOverStandard = standards?.[region]?.BACTO_SCAN ? _.filter(labReportsInBactoDateRange, (labReport) => !_.isNull(labReport.bacto_scan) && labReport.bacto_scan > standards[region].BACTO_SCAN && labReport.is_outlier === false).length : 0;
    const bactoScanCountWarnings = standards?.[region]?.BACTO_SCAN_WARN ? _.filter(labReportsInBactoDateRange, (labReport) => !_.isNull(labReport.bacto_scan) && labReport.bacto_scan >= standards[region].BACTO_SCAN_WARN && labReport.bacto_scan <= standards[region]?.BACTO_SCAN && labReport.is_outlier === false).length : 0;
    const bactoScanAboveStandardInThirdMonth = standards?.[region]?.BACTO_SCAN ? _.filter(labReportsInThirdMonth, (labReport) => !_.isNull(labReport.bacto_scan) && labReport.bacto_scan > standards[region].BACTO_SCAN && labReport.is_outlier === false).length : 0;

    const oneInThirdMonthBactoScan = bactoScanAboveStandardInThirdMonth > 0 ? 1 : 0;
    const oneInThirdMonthPlc = plcAboveStandardInThirdMonth > 0 ? 1 : 0;

    const totalResults = bacteriaPlcCount + bactoScanCount;
    const totalResultsAboveStandard = bacteriaPlcCountOverStandard + bactoScanCountOverStandard;

    const bactoPercentage = totalResults === 0 || totalResultsAboveStandard === 0 ? 0 : ((totalResultsAboveStandard / totalResults) * 100).toFixed(2);
    const bactoStatus = getBactoStatus(bactoPercentage, bactoScanAboveStandardInThirdMonth, plcAboveStandardInThirdMonth, bactoScanCountOverStandard, region, bactoScanCountWarnings);

    return {
        scc_status: sccStatus,
        scc_count: sccCount,
        scc_count_over_standard: sccCountOverStandard,
        scc_percentage: percentage,
        one_in_third_month_scc: sccAboveStandardInThirdMonth,
        bacto_status: bactoStatus,
        bacto_scan_count: bactoScanCount,
        bacteria_plc_count: bacteriaPlcCount,
        total_results: totalResults,
        bacto_scan_count_over_standard: bactoScanCountOverStandard,
        bacteria_plc_count_over_standard: bacteriaPlcCountOverStandard,
        total_results_above_standard: totalResultsAboveStandard,
        bacto_percentage: bactoPercentage,
        one_in_third_month_bacto_scan: oneInThirdMonthBactoScan,
        one_in_third_month_plc: oneInThirdMonthPlc,
        total_tests_in_third_month: (oneInThirdMonthBactoScan || oneInThirdMonthPlc) > 0,
        freezing_point_count: freezingPointCount,
        freezing_point_count_over_standard: freezingPointCountOverStandard,
        freezing_point_status: freezingPointStatus,
        demerit_points: demeritPoints,
    };
};

export const getAlertDataCDI = (dateRange, labReports, producer) => {
    const labReportsInDateRange = _.filter(labReports, (labReport) => moment(labReport.date).isBetween(dateRange.start, dateRange.end));
    const label = `${dateRange.start.format('MMM')} - ${dateRange.end.format('MMM')} , ${dateRange.end.format('YYYY')}`;

    const sccOffences = _.map(
        _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.somatic_cell_count) && labReport.somatic_cell_count > standards.CDI.SCC && labReport.is_outlier === false),
        (labReport) => transformLabReport(labReport, producer)
    );
    const sccCountOverStandard = sccOffences.length;

    const spcOffences = _.map(
        _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.standard_plate_count) && labReport.standard_plate_count > standards.CDI.SPC && labReport.is_outlier === false),
        (labReport) => transformLabReport(labReport, producer)
    );
    const spcCountOverStandard = spcOffences.length;

    const lpcOffences = _.map(
        _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.lab_pasteurization_count) && labReport.lab_pasteurization_count > standards.CDI.LPC && labReport.is_outlier === false),
        (labReport) => transformLabReport(labReport, producer)
    );
    const lpcCountOverStandard = lpcOffences.length;

    const coliformOffences = _.map(
        _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.coliform_count) && labReport.coliform_count > standards.CDI.COLI && labReport.is_outlier === false),
        (labReport) => transformLabReport(labReport, producer)
    );
    const coliformCountOverStandard = coliformOffences.length;

    return {
        label,
        scc_count_over_standard: sccCountOverStandard,
        scc_offences: sccOffences,
        spc_count_over_standard: spcCountOverStandard,
        spc_offences: spcOffences,
        lpc_count_over_standard: lpcCountOverStandard,
        lpc_offences: lpcOffences,
        coliform_count_over_standard: coliformCountOverStandard,
        coliform_offences: coliformOffences,
    };
};

export const getAlertDataPEI = (dateRange, labReports, producer) => {
    const region = 'PEI';

    const labReportsInDateRangeScc = _.filter(labReports, (labReport) => moment(labReport.date).isBetween(dateRange.start, dateRange.end));
    const labReportsInDateRangeFreezing = _.filter(labReports, (labReport) => moment(labReport.date).isBetween(dateRange.end.clone().add(1, 'seconds').subtract(1, 'years'), dateRange.end));
    const labReportsInDateRangeBacto = _.filter(labReports, (labReport) => moment(labReport.date).isBetween(dateRange.start.clone().add(1, 'months'), dateRange.end));
    const labReportsInThirdMonth = _.filter(labReports, (labReport) => moment(labReport.date).isBetween(dateRange.start.clone().add(2, 'months'), dateRange.end));

    const threeMonthLabel = `${dateRange.start.format('MMM')} - ${dateRange.end.format('MMM')} , ${dateRange.end.format('YYYY')}`;
    const sccOffences = _.map(
        _.filter(labReportsInDateRangeScc, (labReport) => !_.isNull(labReport.somatic_cell_count) && labReport.somatic_cell_count > standards.PEI.SCC && labReport.is_outlier === false),
        (labReport) => transformLabReport(labReport, producer)
    );
    const sccCountOverStandard = sccOffences.length;
    const sccAboveStandardInThirdMonth = _.filter(labReportsInThirdMonth, (labReport) => !_.isNull(labReport.somatic_cell_count) && labReport.somatic_cell_count > standards.PEI.SCC && labReport.is_outlier === false).length;
    const sccStatus = getSccStatus(null, sccAboveStandardInThirdMonth, sccCountOverStandard, region);

    const oneYearLabel = `${dateRange.end.clone().add(1, 'seconds').subtract('1', 'years').format('MMM')} ${dateRange.end.clone().add(1, 'seconds').subtract('1', 'years').format('YYYY')} - ${dateRange.end.format('MMM')} ${dateRange.end.format('YYYY')}`;
    const freezingPointOffences = _.map(
        _.filter(labReportsInDateRangeFreezing, (labReport) => !_.isNull(labReport.temperature) && !!labReport.temperature && labReport.temperature > standards.PEI.FREEZING_POINT && labReport.is_outlier === false),
        (labReport) => transformLabReport(labReport, producer)
    );
    const freezingPointCountOverStandard = freezingPointOffences.length;
    const freezingPointAboveStandardInThirdMonth = _.filter(labReportsInThirdMonth, (labReport) => !_.isNull(labReport.temperature) && !!labReport.temperature && labReport.temperature > standards.PEI.FREEZING_POINT && labReport.is_outlier === false).length;
    const freezingPointStatus = getFreezingStatus(freezingPointCountOverStandard, freezingPointAboveStandardInThirdMonth);

    const twoMonthLabel = `${dateRange.start.clone().add(1, 'months').format('MMM')} - ${dateRange.end.format('MMM')} , ${dateRange.end.format('YYYY')}`;
    const bactoOffences = _.map(
        _.filter(labReportsInDateRangeBacto, (labReport) => !_.isNull(labReport.bacto_scan) && labReport.bacto_scan > standards.PEI.BACTO_SCAN && labReport.is_outlier === false),
        (labReport) => transformLabReport(labReport, producer)
    );
    const bactoScanCountOverStandard = bactoOffences.length;
    const bactoScanAboveStandardInThirdMonth = _.filter(labReportsInThirdMonth, (labReport) => !_.isNull(labReport.bacto_scan) && labReport.bacto_scan > standards.PEI.BACTO_SCAN && labReport.is_outlier === false).length;
    const bactoScanCountWarnings = standards?.[region]?.BACTO_SCAN_WARN ? _.filter(labReportsInThirdMonth, (labReport) => !_.isNull(labReport.bacto_scan) && labReport.bacto_scan >= standards[region].BACTO_SCAN_WARN && labReport.bacto_scan <= standards[region]?.BACTO_SCAN && labReport.is_outlier === false).length : 0;
    const bactoStatus = getBactoStatus(null, bactoScanAboveStandardInThirdMonth, null, bactoScanCountOverStandard, region, bactoScanCountWarnings);

    return {
        scc_label: threeMonthLabel,
        scc_status: sccStatus,
        scc_count_over_standard: sccCountOverStandard,
        scc_offences: sccOffences,
        bacto_label: twoMonthLabel,
        bacto_status: bactoStatus,
        bacto_scan_count_over_standard: bactoScanCountOverStandard,
        bacto_offences: bactoOffences,
        freezing_point_label: oneYearLabel,
        freezing_point_status: freezingPointStatus,
        freezing_point_count_over_standard: freezingPointCountOverStandard,
        freezing_point_offences: freezingPointOffences,
    };
};

export const getAlertDataRF = (dateRange, labReports, producer) => {
    const monthLabel = `${dateRange.start.format('MMM')}, ${dateRange.start.format('YYYY')}`;

    const labReportsInDateRange = _.filter(labReports, (labReport) => moment(labReport.date).format('YYYY-MM') === moment(dateRange.start).format('YYYY-MM'));
    const bmccOffences = _.map(
        _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.bulk_milk_cell_count) && labReport.bulk_milk_cell_count > LAB_STANDARDS.RF.BMCC_WARNING && labReport.is_outlier === false),
        (labReport) => transformLabReport(labReport, producer)
    );
    const bactoOffences = _.map(
        _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.bacto_scan) && labReport.bacto_scan > LAB_STANDARDS.RF.BACTO_WARNING && labReport.is_outlier === false),
        (labReport) => transformLabReport(labReport, producer)
    );
    const thermoOffences = _.map(
        _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.thermo_plate_count) && labReport.thermo_plate_count > LAB_STANDARDS.RF.THERMO_PC_WARNING && labReport.is_outlier === false),
        (labReport) => transformLabReport(labReport, producer)
    );
    const colostrumOffences = _.map(
        _.filter(labReportsInDateRange, (labReport) => !_.isNull(labReport.colostrum_percentage) && labReport.colostrum_percentage > LAB_STANDARDS.RF.COLOSTRUM_ERROR && labReport.is_outlier === false),
        (labReport) => transformLabReport(labReport, producer)
    );

    return {
        bmcc: {
            label: monthLabel,
            count_over_standard: bmccOffences.length,
            offences: bmccOffences,
            free_kicks: Math.min(bmccOffences.length, 2),
        },
        bactoscan: {
            label: monthLabel,
            count_over_standard: bactoOffences.length,
            offences: bactoOffences,
            free_kicks: Math.min(bactoOffences.length, 8),
        },
        thermo: {
            label: monthLabel,
            count_over_standard: thermoOffences.length,
            offences: thermoOffences,
            free_kicks: Math.min(thermoOffences.length, 8),
        },
        colostrum: {
            label: monthLabel,
            count_over_standard: colostrumOffences.length,
            offences: colostrumOffences,
            free_kicks: 0,
        },
    };
};
