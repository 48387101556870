import axios from 'axios';
import { getExpressAPIHost } from '../../utils';

const downloadAllDeferralPayReports = async ({ type, reportName, date, payReportDownloadStatus }) => {
    try {
        const params = {
            type,
            date,
            reportName,
            payReportDownloadStatus,
        };
        const response = await axios.get(`${getExpressAPIHost()}/reports/all-deferral-reports/`, { params });
        return response;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to download file. Please contact support.');
    }
};
export default downloadAllDeferralPayReports;
