import { AbilityBuilder, PureAbility } from '@casl/ability';

const prefix = 'receiving';
const ipdPrefix = `${prefix}-in-progress-dropoff`;

export const RECEIVING_PERMISSION_NAMES = {
    VIEW_ALL_BUTTONS: `${ipdPrefix}-view-all-buttons`,
};
function ReceivingPermissions(user) {
    const { can, cannot, rules } = new AbilityBuilder(PureAbility);
    const {
        role,
        data: { region, id, processor_id },
    } = user;

    // TODO: When rolled out to other users, we need to make sure that the user can only see the dropoffs for the plants they manage
    // TODO: This is a temporary solution to make sure that the user can see all dropoffs for the 3 plants they manage

    // if (role === 'admin') {
    //     can('view', RECEIVING_PERMISSION_NAMES.VIEW_ALL_BUTTONS);
    // }

    return rules;
}

export default ReceivingPermissions;
