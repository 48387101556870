import React from 'react';
import { Box, Typography, Tooltip, Link } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';

const MessageWithLink = ({ message, link, tooltip }) => {
    return (
        <Box display="inline-flex">
            <Typography variant="h6">{message}: </Typography>
            <Link href={link}>
                <Tooltip title={<Typography variant="h6">{tooltip}</Typography>} arrow>
                    <LaunchIcon fontSize="large" sx={{ color: (theme) => theme.palette.primary.contrastText, ml: (theme) => theme.spacing(1), '&:hover': { opacity: 0.8 } }} />
                </Tooltip>
            </Link>
        </Box>
    );
};

export default MessageWithLink;
