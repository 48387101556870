import axios from 'axios';
import _ from 'lodash';
import { getReceivedFilesQuery, getAdminReceivedFilesQuery, getAdminFoldersQuery, getUsersQuery, getSharedFoldersQuery, batchQuery } from './queries';

const mapFileData = (files, id, users) =>
    _.map(files, (file) => ({
        created_at: file.created_at,
        deleted_at: file.deleted_at,
        name: file.name,
        folder_id: file.folder_id,
        generated_name: file.generated_name,
        id: file.id,
        read_status: _.includes(file.read_status, id),
        sent_by: _.find(users, { id: file.owner_id }) ? _.find(users, { id: file.owner_id }).name : '',
        sent_to: file.sentTo_id.length === 1 ? _.find(users, { id: file.sentTo_id[0] }).name : 'Multiple',
        sentTo_id: id,
        updated_at: file.updated_at,
    }));

const getReceivedFiles = (id, isAdmin) =>
    new Promise((resolve, reject) => {
        const fileQuery = isAdmin ? getAdminReceivedFilesQuery() : getReceivedFilesQuery({ userId: id, filterDeleted: true });
        const fileRequest = axios.get(fileQuery);

        fileRequest
            .then((fileResponse) => {
                const files = fileResponse.data;
                const folderIds = _.filter(_.uniq(_.map(files, 'folder_id')), (folderId) => !_.isNull(folderId));

                if (folderIds.length === 0) {
                    const ownerIds = _.uniq(_.map(files, 'owner_id'));
                    const sentToIds = _.uniq(_.flatten(_.map(files, (file) => file.sentTo_id)));
                    const userIds = _.uniq(_.concat(ownerIds, sentToIds));

                    const usersQuery = batchQuery(getUsersQuery, { fields: ['id', 'name'], ids: userIds }, 'ids');
                    const userRequest = _.map(usersQuery, (q) => axios.get(q));
                    axios.all([...userRequest]).then(
                        axios.spread((...responses) => {
                            const users = _.reduce(responses, (acc, val) => [...acc, ...val.data], []);
                            const fileData = mapFileData(files, id, users);
                            const folderData = [];
                            resolve({ data: { files: fileData, folders: folderData } });
                        })
                    );
                } else {
                    const foldersQuery = isAdmin ? getAdminFoldersQuery() : getSharedFoldersQuery({ ids: folderIds });
                    const folderRequest = axios.get(foldersQuery);
                    folderRequest.then((folderResponse) => {
                        const folders = folderResponse.data;
                        const ownerIds = _.uniq(_.concat(_.map(files, 'owner_id'), _.map(folders, 'owner_id')));
                        const sentToIds = _.uniq(_.flatten(_.map(files, (file) => file.sentTo_id)));
                        const userIds = _.uniq(_.concat(ownerIds, sentToIds));
                        const usersQuery = batchQuery(getUsersQuery, { fields: ['id', 'name'], ids: userIds }, 'ids');
                        const userRequest = _.map(usersQuery, (q) => axios.get(q));
                        axios.all([...userRequest]).then(
                            axios.spread((...responses) => {
                                const users = _.reduce(responses, (acc, val) => [...acc, ...val.data], []);
                                const fileData = mapFileData(files, id, users);
                                const folderData = _.map(folders, (folder) => ({
                                    deleted_at: folder.deleted_at,
                                    id: folder.id,
                                    name: folder.name,
                                    owner_id: folder.owner_id,
                                    owner_name: _.find(users, { id: folder.owner_id }) ? _.find(users, { id: folder.owner_id }).name : '',
                                    ...(folder.folder_id && { folder_id: folder.folder_id }),
                                }));
                                resolve({ data: { files: fileData, folders: folderData } });
                            })
                        );
                    });
                }
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getReceivedFiles;
