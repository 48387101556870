import * as Actions from '../../actions';

const initialState = { data: null };

const producerDashboardPickupAndLabReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_PRODUCER_DASHBOARD_PICKUP_AND_LAB_DETAILS:
            return { ...state, data: { ...action.payload } };

        default:
            return state;
    }
};

export default producerDashboardPickupAndLabReducer;
