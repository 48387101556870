import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Grid, Box, makeStyles, debounce } from '@material-ui/core';
import { showMessage, addAssignee, editAssignee } from 'app/store/actions';
import _ from 'lodash';
import ReactHookFormInput from '../form-components/ReactHookFormInput';
import ReactHookFormSearchableSelect from '../form-components/ReactHookFormSearchableSelect';
import { cdiBasis, paymentMethod, deductionType, status, CDIMilkTypes, paymentType, cdiJurisdictions } from '../form-components/AssigneeSelects';
import { state } from '../form-components/ReactHookProducerFormSelects';
import { getUserRegion } from '../../../utils';

import { GridRow } from './layout/GridRow';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    status: {
        marginTop: '-8px',
    },
    phone: {
        marginTop: '8px',
    },
}));

const getColumnSize = (region) => {
    switch (region) {
        case 'CDI':
            return 6;
        default:
            return 4;
    }
};

const CDIAssigneeForm = ({ editData, producers }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const region = getUserRegion();
    const reactHookFormMethods = useForm({
        mode: 'all',
        defaultValues: {
            assignee_number: editData?.assignee_number,
            assignee_name: editData?.assignee_name,
            account_number: editData?.account_number,
            gl_number: editData?.gl_number,
            type_of_charge: _.find(cdiBasis, (z) => z.value === editData?.type_of_charge),
            payment_method: _.find(paymentMethod, (z) => z.value === editData?.payment_method),
            deduction_type: _.find(deductionType, (z) => z.value === editData?.deduction_type),
            status: _.find(status, (z) => z.value === editData?.status) || '',
            jurisdiction: _.find(cdiJurisdictions, (z) => z.value === editData?.jurisdiction),
            milk_type: _.find(CDIMilkTypes, (z) => z.value === editData?.milk_type),
            payment_type: _.find(paymentType, (z) => z.value === editData?.payment_type),
            state: _.find(state, (z) => z.value === editData?.state),
            producer: _.find(producers, (z) => z.value === editData?.producer_id) || '',
            flat_amount: editData?.flat_amount,
            rate_amount: editData?.rate_amount,
        },
    });

    const { handleSubmit, register, watch, setValue, errors, control } = reactHookFormMethods;

    const basisWatch = watch('type_of_charge');
    const deductionTypeWatch = watch('deduction_type');
    const producerWatch = watch('producer');
    const jurisdictionWatch = watch('jurisdiction');

    useEffect(() => {
        if (['cwt', 'lbs', 'gross_price'].includes(basisWatch?.value)) {
            setValue('flat_amount', '');
        } else if (['variable', 'fixed', 'per_advance_day'].includes(basisWatch?.value)) {
            setValue('rate_amount', '');
        }
    }, [basisWatch]);

    useEffect(() => {
        if (['discretionary'].includes(deductionTypeWatch?.value)) {
            setValue('producer', '');
            setValue('jurisdiction', '');
            setValue('status', '');
        } else if (['mandatory'].includes(deductionTypeWatch?.value)) {
            setValue('status', status[0]);
        }
    }, [deductionTypeWatch]);

    const transformProducerLabel = (option) => {
        if (option.license_number && option.name) {
            return `${option.license_number} - ${option.name}`;
        }
        return '';
    };

    const post = (submitModel) => {
        dispatch(addAssignee(submitModel))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Added Assignee' }));
                history.replace({ pathname: '/assignees' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not add Assignee' }));
            });
    };

    const patch = (submitModel) => {
        const { _id } = editData;
        dispatch(editAssignee(submitModel, _id))
            .then(() => {
                dispatch(showMessage({ message: 'Successfully Edited Assignee' }));
                history.replace({ pathname: '/assignees' });
            })
            .catch((ex) => {
                dispatch(showMessage({ message: 'Could not edit Assignee' }));
            });
    };

    const onSubmit = (model) => {
        let submitModel = { ...model };
        // This function applies to the searchable select components and passes the value of the selection to the model
        Object.keys(submitModel).forEach((key) => {
            if (submitModel[key]?.value) {
                submitModel[key] = submitModel[key].value;
            }
        });
        submitModel.status = submitModel?.status ? submitModel.status : editData?.status ? null : undefined;
        submitModel.flat_amount = submitModel?.flat_amount ? parseFloat(submitModel?.flat_amount) : undefined;
        submitModel.rate_amount = submitModel?.rate_amount ? parseFloat(submitModel?.rate_amount) : undefined;
        submitModel = _.omitBy(submitModel, (field) => _.isUndefined(field) || _.isNull(field) || field === '');

        if (editData) {
            patch(submitModel);
        } else {
            post(submitModel);
        }
    };

    const debounceSubmit = useCallback(debounce(onSubmit, 500), []);

    const render = () => {
        return (
            <div className={classes.root}>
                <FormProvider {...reactHookFormMethods}>
                    <form onSubmit={handleSubmit(debounceSubmit, errors)}>
                        <Grid container spacing={2}>
                            <GridRow size={getColumnSize(region)}>
                                <ReactHookFormInput label={'Assignee ID'} name={'assignee_number'} required type={'text'} fullWidth />
                                <ReactHookFormInput label={'Assignee Name'} name={'assignee_name'} required type={'text'} fullWidth />
                            </GridRow>

                            <GridRow size={6}>
                                <ReactHookFormInput label={'AP Account'} name={'account_number'} type={'text'} fullWidth />
                                <ReactHookFormInput label={'GL Number'} name={'gl_number'} type={'text'} fullWidth />
                            </GridRow>

                            <GridRow size={4}>
                                <ReactHookFormSearchableSelect name={'payment_method'} label={'Payment Method'} options={paymentMethod} required />
                                <ReactHookFormSearchableSelect label={'Basis'} name={'type_of_charge'} options={cdiBasis} required />
                                <ReactHookFormSearchableSelect label={'Deduction Type'} name={'deduction_type'} options={deductionType} required />
                                <ReactHookFormInput label={'Amount ($)'} name="flat_amount" type={'number'} fieldOpts={{ disabled: ['lbs', 'cwt', 'gross_price'].includes(basisWatch?.value) }} fullWidth />
                                <ReactHookFormInput label={'CWT Rate ($/cwt)'} name="rate_amount" type={'number'} fieldOpts={{ disabled: ['fixed', 'variable', 'per_advance_day'].includes(basisWatch?.value) }} fullWidth />
                                <ReactHookFormSearchableSelect className={classes.status} label={'Status'} required={!['discretionary'].includes(deductionTypeWatch?.value)} {...register('status')} options={status} disabled={['discretionary'].includes(deductionTypeWatch?.value)} />
                            </GridRow>

                            <GridRow size={6}>
                                <ReactHookFormSearchableSelect label={'Milk Type'} name={'milk_type'} options={CDIMilkTypes} />
                                <ReactHookFormSearchableSelect label={'Payment Type'} name={'payment_type'} options={paymentType} />
                                <ReactHookFormSearchableSelect label={'Producer'} {...register('producer')} options={producers.sort((a, b) => Number(a.license_number) - Number(b.license_number))} disabled={['discretionary'].includes(deductionTypeWatch?.value) || !!jurisdictionWatch} customRender={transformProducerLabel} />
                                <ReactHookFormSearchableSelect label={'Jurisdiction'} {...register('jurisdiction')} options={cdiJurisdictions} disabled={['discretionary'].includes(deductionTypeWatch?.value) || !!producerWatch} />
                            </GridRow>
                        </Grid>
                        <Box display="flex" justifyContent="center" gridGap="30px" paddingTop={'15px'}>
                            <Button variant="contained" color="primary" margin="normal" className="mx-auto my-16" sx={{ paddingTop: 2 }} type="submit">
                                Save
                            </Button>
                        </Box>
                    </form>
                </FormProvider>
            </div>
        );
    };
    return <>{render()}</>;
};

export default withRouter(CDIAssigneeForm);
