import React from 'react';
import { TextField } from '@material-ui/core';
import { withFormsy } from 'formsy-react';
import _ from '@lodash';

function TextFieldFormsy(props) {
    const importedProps = _.pick(props, [
        'autoComplete',
        'autoFocus',
        'children',
        'className',
        'defaultValue',
        'disabled',
        'customErrorMessage',
        'FormHelperTextProps',
        'fullWidth',
        'id',
        'InputLabelProps',
        'inputProps',
        'InputProps',
        'inputRef',
        'label',
        'multiline',
        'name',
        'onBlur',
        'onChange',
        'onFocus',
        'placeholder',
        'required',
        'rows',
        'rowsMax',
        'select',
        'SelectProps',
        'type',
        'variant',
        'onBlur',
    ]);
    const customErrorMessage = props.customErrorMessage;
    const errorMessage = props.errorMessage;
    const value = props.value || '';

    function changeValue(event) {
        props.setValue(event.currentTarget.value);
        if (props.onChange) {
            props.onChange(event);
        }
    }

    function onBlur(event) {
        props.setValue(event.currentTarget.value);
        if (props.onBlur) {
            props.onBlur(event);
        }
    }

    function onFocus(event) {
        event.currentTarget.select();
    }

    return <TextField {...importedProps} onFocus={onFocus} onBlur={onBlur} onChange={changeValue} value={value} error={Boolean(customErrorMessage) || Boolean(errorMessage)} helperText={customErrorMessage ? customErrorMessage : errorMessage} />;
}

export default React.memo(withFormsy(TextFieldFormsy));
