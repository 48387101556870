import React from 'react';

const moorices = ['assets/New Logo/Moorice_Pop&Lock.gif', 'assets/New Logo/Moorice_Pop_Lock_2.gif', 'assets/New Logo/Moorice_Moonwalk_output.gif', 'assets/New Logo/Moorice_Bean_output.gif', 'assets/New Logo/Moorice_Dab_output.gif', 'assets/New Logo/Moorice_Dicaprio_output.gif', 'assets/New Logo/Moorice_Floss_output.gif'];

const RandomMoorice = (props) => {
    const moorice = 'android-chrome-512x512.png';
    // moorices[Math.floor(Math.random() * moorices.length)];

    return <img style={props.style} height="300" width="300" src={moorice} alt="logo" />;
};

export default RandomMoorice;
