import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Grid, CircularProgress, FormHelperText } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import GetAppIcon from '@material-ui/icons/GetApp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import imageCompression from 'browser-image-compression';
import { makeStyles } from '@material-ui/styles';
import { getFileURL } from '../../../../repository';
import { handleFileUpload } from '../../../../../utils';

const useStyles = makeStyles((theme) => ({
    message: {
        width: '80%',
        textAlign: 'center',
    },
    DropZoneContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '1.5rem',
        margin: 0,
        width: '300x',
        height: '130px',
        border: '3px dashed #4aa1f3',
        borderRadius: '4px',
    },
    thumbsContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 16,
    },
    thumb: {
        display: 'inline-flex',
        borderRadius: 2,
        border: '1px solid #eaeaea',
        marginBottom: 8,
        marginRight: 8,
        minWidth: 100,
        minHeight: 100,
        padding: 4,
        boxSizing: 'border-box',
    },
    thumbInner: {
        display: 'flex',
        minWidth: 0,
        overflow: 'hidden',
        alignItems: 'center',
        padding: 4,
    },
    img: {
        display: 'block',
        width: 'auto',
        height: '100%',
    },
    bundle: {
        display: 'flex',
        flexDirection: 'column',
    },
    deleteButton: {
        color: theme.palette.error.main,
    },
    pdfButton: {
        display: 'flex',
        flexDirection: 'column',
    },
}));

const compressImage = async (file, options) => {
    const isPdf = file?.type?.includes('pdf') || file?.preview?.includes('.pdf');
    if (!isPdf) {
        // Revoke the data uris to avoid memory leaks
        URL.revokeObjectURL(file.preview);
        const compressedFile = await imageCompression(file, options);
        return compressedFile;
    }
    return file;
};

function Dropzone({ handleDrop, value, message, amount, ...props }) {
    const [file, setFile] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const classes = useStyles();

    const getUrlAndSetFile = async () => {
        let preview;
        try {
            preview = props.preview || (await getFileURL(value, true));
        } catch (err) {
            setError('Error fetching the file. Please try again or contact support if the problem persists');
        }
        const defaultFile = {
            name: props.name || 'default',
            preview,
        };
        setFile(defaultFile);
    };

    const uploadAndSetFile = async (droppedFile) => {
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };
        try {
            setLoading(true);
            const newFile = await compressImage(droppedFile, options);
            const { data } = await handleFileUpload(newFile);
            const preview = await getFileURL(data, true);
            handleDrop(data);
            setFile({ ...droppedFile, preview });
        } catch (err) {
            setError('Error uploading file. Please try again or contact support if the problem persists');
        }
        setLoading(false);
    };

    useEffect(() => {
        if (value) {
            // fetch preview url and set file
            getUrlAndSetFile();
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*, application/pdf',
        addRemoveLinks: true,
        multiple: false,
        onDrop: async (acceptedFiles) => {
            if (!acceptedFiles.length) {
                setError('Please make sure you are uploading an image or pdf file');
                return;
            }
            const newFile = acceptedFiles[0];
            uploadAndSetFile(newFile);
        },
    });

    const removeFile = () => {
        handleDrop(null);
        setFile();
    };

    const { type, preview, name } = file || {};
    const isPdf = type?.includes('pdf') || preview?.includes('.pdf');

    if (loading) {
        return (
            <Grid container alignItems="center">
                <CircularProgress size={50} color="secondary" disableShrink />
            </Grid>
        );
    }

    if (!file) {
        return (
            <>
                <div {...getRootProps({ className: classes.DropZoneContainer })}>
                    <input {...getInputProps()} />
                    <p className={classes.message}>{message || 'Drag and drop your slip here, or click to select files'}</p>
                </div>
                <FormHelperText error={!!error}>{error}</FormHelperText>
            </>
        );
    }

    return (
        <Grid container alignItems="center" className={classes.thumbsContainer}>
            <Grid item xs={6}>
                {isPdf ? (
                    <Button classes={{ label: classes.pdfButton }} onClick={() => window.open(preview, '_blank').focus()}>
                        <PictureAsPdfIcon color="secondary" fontSize="large" />
                        {file.path || `${name}.pdf`}
                    </Button>
                ) : (
                    <div className={classes.thumb}>
                        <div className={classes.thumbInner}>
                            <img src={preview} className={classes.img} alt="add file" />
                        </div>
                    </div>
                )}
            </Grid>
            <Grid item>
                <Grid container>
                    <Grid item xs={12}>
                        <Button onClick={removeFile}>
                            <HighlightOffIcon className={classes.deleteButton} />
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button onClick={() => window.open(preview, '_blank').focus()}>
                            <GetAppIcon />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Dropzone;
