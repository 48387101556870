import Region from '../../../app/constants/region';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

const ALL_ALLOWED_REGIONS = [Region.MMPA];

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: ALL_ALLOWED_REGIONS,
});

const demo = new Environment({
    name: stages.DEMO,
    regions: ALL_ALLOWED_REGIONS,
});

const staging = new Environment({
    name: stages.STAGING,
    regions: ALL_ALLOWED_REGIONS,
});

const local = new Environment({
    name: stages.LOCAL,
    regions: ALL_ALLOWED_REGIONS,
});

const dev = new Environment({
    name: stages.DEV,
    regions: ALL_ALLOWED_REGIONS,
});

const can_opt_out_mailed_pay_statements = new MockSplit({
    name: 'can_opt_out_mailed_pay_statements',
    environments: [local, dev, staging, demo, prod],
});

export default can_opt_out_mailed_pay_statements;
