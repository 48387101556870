import axios from 'axios';
import { getCurrentSession } from '@milkmoovement/common_cognito_package';
import { buildMasterValuesQuery } from './queries/builders';

const getMasterValues = async (name = undefined) => {
    let endpoint = '/v1/masterValues';

    if (name == null) {
        endpoint = '/v1/masterValues/active';
    }
    const session = await getCurrentSession();
    const jwt = session.getIdToken().getJwtToken();
    const headers = {
        Authorization: `Bearer ${jwt}`,
    };

    const query = buildMasterValuesQuery(endpoint, {
        headers,
        data: {},
        query: {
            name,
        },
    });

    const { data: masterValues } = await axios({
        method: 'get',
        ...query,
    });

    return masterValues ?? [];
};

export default getMasterValues;
