/* eslint-disable import/prefer-default-export */
export const paymentStatus = [
    { name: 'Cleared', value: 'cleared' },
    { name: 'Void', value: 'void' },
    { name: 'Outstanding', value: 'outstanding' },
];

export const PaymentStatusValues = {
    Cleared: 'cleared',
    Void: 'void',
    Outstanding: 'outstanding',
};
