import React from 'react';
import { Grid } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';

const MilkPriceFormSkimButterfatPrices = () => (
    <>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Base Skim ($/cwt)" name={'milk_prices.skim_milk_base_price'} type="number" />
        </Grid>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Base Butterfat ($/lb)" name={'milk_prices.butter_fat_base_price'} type="number" />
        </Grid>
    </>
);

export default MilkPriceFormSkimButterfatPrices;
