export const PayReportPageType = {
    producer: 'producer',
    hauler: 'hauler',
    vendor: 'vendor',
    producerBusiness: 'producer_business',
};

export const PaymentFrequency = {
    weekly: 'WK',
    monthly: 'MO',
    semiMonthly: 'SM',
    adhoc: 'adhoc',
};

export const ReportType = {
    advance: 'Advance',
    deferral: 'Deferral',
    settlement: 'Settlement',
    weekly: 'Weekly',
    adhoc: 'Adhoc',
};

export const PaymentType = {
    advance: 'advance',
    deferral: 'deferral',
    settlement: 'settlement',
    weeklyAdvance: 'weeklyAdvance',
    adhoc: 'adhoc',
};

export const PayReportDownloadStatus = {
    calculate: 'calculate',
    download: 'download',
    approve: 'approve',
};

export const ReportDownloadSource = {
    producer: 'producer',
    hauler: 'hauler',
    admin: 'admin',
};

export const PayReportStatus = {
    pending: 'pending',
    generated: 'generated',
    approved: 'approved',
};

const ReportNamesByRegionObject = {
    CDI: {
        advance: {
            reportSetName: 'CDIPayReports',
            reportName: 'cdiProducerPayAdvanceReport',
        },
        deferral: {
            reportSetName: 'ProducerDeferralPayReports',
            reportName: 'producerDeferralPayReport',
        },
        settlement: {
            reportSetName: 'CDIPayReports',
            reportName: 'cdiProducerPayReport',
        },
        adhoc: {
            reportName: 'producerPayStatement',
        },
        weekly: {
            reportName: 'producerPayStatement',
        },
    },
    LEGACY: {
        advance: {
            reportSetName: 'LegacyPayReports',
            reportName: 'legacyProducerPayAdvanceReport',
        },
        settlement: {
            reportSetName: 'LegacyPayReports',
            reportName: 'legacyProducerPaySettlementReport',
        },
        adhoc: {
            reportName: 'producerPayStatement',
        },
        weekly: {
            reportName: 'producerPayStatement',
        },
    },
    UDA: {
        advance: {
            reportSetName: 'UDAPayReports',
            reportName: 'udaProducerPayAdvanceReport',
        },
        settlement: {
            reportSetName: 'UDAPayReports',
            reportName: 'udaProducerPayReport',
        },
        adhoc: {
            reportName: 'producerPayStatement',
        },
        weekly: {
            reportName: 'producerPayStatement',
        },
    },
    PRAIRIE: {
        advance: {
            reportSetName: 'PrairiePayReports',
            reportName: 'prairieFarmsProducerPayAdvanceReport',
        },
        settlement: {
            reportSetName: 'PrairiePayReports',
            reportName: 'prairieFarmsProducerPaySettlementReport',
        },
        adhoc: {
            reportName: 'producerPayStatement',
        },
        weekly: {
            reportName: 'producerPayStatement',
        },
    },
    MMPA: {
        advance: {
            reportSetName: 'MMPAPayReports',
            reportName: 'mmpaProducerPayAdvanceReport',
        },
        settlement: {
            reportSetName: 'MMPAPayReports',
            reportName: 'mmpaProducerPaySettlementReport',
        },
        adhoc: {
            reportName: 'producerPayStatement',
        },
        weekly: {
            reportName: 'producerPayStatement',
        },
    },
    GENERIC: {
        advance: {
            reportSetName: 'GenericProducerPayReport',
            reportName: 'genericProducerPayAdvanceReport',
        },
        settlement: {
            reportSetName: 'GenericProducerPayReport',
            reportName: 'genericProducerPaySettlementReport',
        },
        deferral: {
            reportSetName: 'ProducerDeferralPayReports',
            reportName: 'producerDeferralPayReport',
        },
        adhoc: {
            reportName: 'producerPayStatement',
        },
        weekly: {
            reportName: 'producerPayStatement',
        },
        producerBusiness: {
            reportName: 'producerBusinessPayStatement',
        },
    },
};

const ReportNamesByRegionProxy = {
    get(target, prop, receiver) {
        if (!Object.keys(ReportNamesByRegionObject).includes(prop)) {
            return ReportNamesByRegionObject.GENERIC;
        }
        return ReportNamesByRegionObject[prop];
    },
};

export const ReportNamesByRegion = new Proxy(ReportNamesByRegionObject, ReportNamesByRegionProxy);

export const VendorPayReportName = {
    advance: 'vendorPayAdvanceReport',
    settlement: 'vendorPaySettlementReport',
    allReports: {
        advance: 'vendorPayAllAdvanceReport',
        settlement: 'vendorPayAllSettlementReport',
    },
};

export const HaulerPayReportName = {
    advance: 'haulerPayAdvanceReport',
    settlement: 'haulerPaySettlementReport',
    allReports: {
        advance: 'haulerPayAllAdvanceReport',
        settlement: 'haulerPayAllSettlementReport',
    },
};
