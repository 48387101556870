import axios from 'axios';
import { getCustomTableConfigurationQuery } from './queries';

const getCustomTableConfiguration = (tableName) =>
    new Promise((resolve, reject) => {
        const query = getCustomTableConfigurationQuery({ tableName });
        const request = axios.get(query);

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getCustomTableConfiguration;
