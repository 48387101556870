import axios from 'axios';
import { editScheduleDemandItemQuery } from './queries';

const editScheduleDemandItem = async (model, key) => {
    try {
        const query = editScheduleDemandItemQuery({ key, model });

        const request = axios.put(query.url, { ...query.body });
        const { data } = await request;
        return data;
    } catch (err) {
        // TODO we /desperately need/ better error handling than this at this point
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to edit schedule item');
    }
};

export default editScheduleDemandItem;
