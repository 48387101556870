import axios from 'axios';
import _ from 'lodash';
import { batchQuery, getCommentsQuery, getUsersQuery } from './queries';

const getComments = (type, itemId) =>
    new Promise((resolve, reject) => {
        const commentsQuery = getCommentsQuery({
            type,
            itemId,
            filterDeleted: true,
        });
        const commentsRequest = axios.get(commentsQuery);

        commentsRequest
            .then((commentsResponse) => {
                const userIds = _.uniq(_.map(commentsResponse.data, 'created_by'));

                const userQuery = batchQuery(getUsersQuery, { fields: ['id', 'name'], ids: userIds }, 'ids');
                const userRequest = _.map(userQuery, (q) => axios.get(q));

                axios.all(userRequest).then(
                    axios.spread((...responses) => {
                        const comments = commentsResponse.data;
                        const users = _.reduce(responses, (acc, val) => [...acc, ...val.data], []);

                        const data = comments
                            .map((value) => {
                                const user = _.find(users, { id: value.created_by }) ? _.find(users, { id: value.created_by }).name : undefined;
                                return { ...value, user };
                            })
                            .filter((value) => value.user);

                        resolve({ comments: data });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getComments;
