import axios from 'axios';
import _ from 'lodash';
import { bulkQuery, getRoutesQuery, getUsersQuery } from './queries';
import { renameKey } from '../../utils';

const getRoutesListDetails = (fieldFilterList, filterDeleted = true) =>
    new Promise((resolve, reject) => {
        let querySettings = { filterDeleted };

        if (fieldFilterList) {
            querySettings = { ...querySettings, fields: fieldFilterList };
        }
        const routeQuery = getRoutesQuery(querySettings);
        const routeRequest = axios.get(routeQuery);

        routeRequest
            .then((routeResponse) => {
                const routes = routeResponse.data;

                const producerIds = _.uniq(_.flatten(_.map(routes, 'users')));
                const haulingIds = _.uniq(_.flatten(_.map(routes, 'hauling_companies')));
                const userIds = [...producerIds, ...haulingIds];
                const userQuery = bulkQuery(getUsersQuery, { fields: ['id', 'name'], ids: userIds }, 'ids');
                const userRequest = axios.post(userQuery.url, userQuery.body);

                axios.all([userRequest]).then(
                    axios.spread((...response) => {
                        const users = response[0].data;
                        renameKey(users, '_id', 'id');
                        const routePayload = _.map(routes, (route) => ({
                            ...route,
                            name: route.deleted_at ? `${route.name} (Deleted Route)` : route.name,
                            users: _.map(route.users, (userId) => {
                                const producer = _.find(users, { id: userId });
                                if (producer) {
                                    return { id: producer.id, name: producer.name };
                                }
                                return { id: '', name: '' };
                            }),
                            haulingCompanies: route.hauling_companies
                                ? _.map(route.hauling_companies, (userId) => {
                                      const hauling = _.find(users, { id: userId });
                                      if (hauling) {
                                          return { id: hauling.id, name: hauling.name };
                                      }
                                      return { id: '', name: '' };
                                  })
                                : [],
                        }));
                        resolve({ routes: routePayload });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getRoutesListDetails;
