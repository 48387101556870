import React from 'react';
import { withStyles } from '@material-ui/core';
/* eslint-disable-next-line */
import classNames from 'classnames';

const styles = (theme) => ({
    root: {
        '& .logo-icon': {
            width: 12,
            height: 12,
            position: 'absolute',
            top: 0,
            marginTop: 70,
            marginBottom: 42,
            transition: theme.transitions.create(['width', 'height'], { duration: theme.transitions.duration.shortest, easing: theme.transitions.easing.easeInOut }),
        },
    },
});

/* eslint-disable */
function BottomLogoDark({ classes }) {
    function reload() {
        window.location.reload();
    }

    return (
        <div>
            <img className="min-w-96 max-w-128 min-h-23 max-h-48 justify-end pl-12" src="/assets/New Logo/Digital (RGB)/Reverse/Horizontal (Reverse)/MM Logo - Horizontal (White, Digital, Png).png" alt="logo" />
        </div>
    );
}

export default withStyles(styles, { withTheme: true })(BottomLogoDark);
