import axios from 'axios';
import _ from 'lodash';
import { getDropoffsQuery, getDriversQuery, getProcessorsQuery, getRouteSessionsQuery, getRoutesQuery, getTrailersQuery, batchQuery, bulkQuery } from './queries';
import { renameKey, arrayToMap, getNumSlips } from '../../utils';
import { buildExpressQuery } from './queries/builders';
import { calculateShrinkAmount, calculateShrinkPercentage } from '../helpers/dropoffs/utils';

const getHaulingDropoffDetails = (id, start, end, filterType = 'milk') =>
    new Promise((resolve, reject) => {
        const driverQuery = getDriversQuery({ fields: ['id', 'name'], haulingId: id });
        const driverRequest = axios.get(driverQuery);

        driverRequest.then((response) => {
            const drivers = response.data;
            const driverIds = _.uniq(_.map(drivers, 'id'));

            const dropoffsQuery = bulkQuery(
                getDropoffsQuery,
                {
                    start,
                    end,
                    driverIds,
                    filterDeleted: true,
                    filterType,
                },
                'driverIds'
            );
            const dropoffsRequest = axios.post(dropoffsQuery.url, dropoffsQuery.body);

            return axios
                .all([dropoffsRequest])
                .then(
                    axios.spread((dropoffsResponse) => {
                        const dropoffs = dropoffsResponse?.data || [];
                        renameKey(dropoffs, '_id', 'id');

                        const processorIds = _.uniq(_.map(dropoffs, 'processor_id'));
                        const routeSessionIds = _.uniq(_.map(dropoffs, 'route_session_id'));

                        const processorQuery = getProcessorsQuery({ fields: ['id', 'name', 'geofence', 'latitude', 'longitude', 'license_number'], ids: processorIds, queryBuilder: buildExpressQuery });
                        const routeSessionQuery = bulkQuery(
                            getRouteSessionsQuery,
                            {
                                fields: ['id', 'BOL', 'status', 'route_id', 'route_number', 'trailer_id', 'truck_id', 'po_number'],
                                ids: routeSessionIds,
                                filterDeleted: true,
                                hint: '_id_1_created_at_-1_deleted_at_1',
                            },
                            'ids'
                        );

                        const processorRequest = axios.get(processorQuery);
                        const routeSessionRequest = axios.post(routeSessionQuery.url, routeSessionQuery.body);

                        return axios.all([processorRequest, routeSessionRequest]).then(
                            axios.spread((...responses) => {
                                const processors = responses?.[0]?.data || [];
                                const routeSessions = responses?.[1]?.data || [];
                                renameKey(processors, '_id', 'id');
                                renameKey(routeSessions, '_id', 'id');

                                const routeIds = _.uniq(_.map(routeSessions, 'route_id'));
                                const routeQuery = bulkQuery(getRoutesQuery, { fields: ['id', 'name'], ids: routeIds });

                                const routeRequest = axios.post(routeQuery.url, routeQuery.body);

                                const trailerIds = _.uniq(_.map(routeSessions, 'trailer_id'));
                                const trailerQuery = bulkQuery(getTrailersQuery, { ids: trailerIds });
                                const trailerRequest = axios.get(trailerQuery);

                                return axios.all([routeRequest, trailerRequest]).then(
                                    axios.spread((...subResponses) => {
                                        const routes = subResponses?.[0]?.data || [];
                                        renameKey(routes, '_id', 'id');
                                        const trailers = subResponses?.[1]?.data || [];
                                        const trailerMap = arrayToMap(trailers, 'id');

                                        const data = _.map(dropoffs, (value) => {
                                            const processor = [_.find(processors, { id: value.processor_id })];
                                            const driver = [_.find(drivers, { id: value.driver_id })];
                                            const routeSession = _.find(routeSessions, { id: value.route_session_id });
                                            return {
                                                ...value,
                                                shrink_amount: calculateShrinkAmount(value),
                                                shrink_percent: calculateShrinkPercentage(value),
                                                processor,
                                                driver,
                                                route_session: [{ ...routeSession, route: routeSession && _.find(routes, { id: routeSession.route_id }) ? _.find(routes, { id: routeSession.route_id }).name : '' }],
                                                trailer_number: trailerMap?.[routeSession?.trailer_id]?.trailer_number || '',
                                                num_slips: getNumSlips(value),
                                            };
                                        });

                                        resolve({ dropoffs: data });
                                    })
                                );
                            })
                        );
                    })
                )
                .catch((errors) => {
                    // eslint-disable-next-line no-console
                    console.log(errors);
                    reject(new Error('Unable to process request'));
                });
        });
    });

export default getHaulingDropoffDetails;
