import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Box, IconButton, Slider } from '@mui/material';
import PlayArrow from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';

const menuSx = (theme) => ({
    wrap: { py: theme.spacing(2) },
    icon: { height: theme.typography.h1 },
    box: {
        boxShadow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    sliderBox: { boxShadow: 1, py: theme.spacing(2), px: theme.spacing(4) },
    slider: {
        '& .MuiSlider-thumb': {
            color: theme.palette.warning.main,
        },
    },
});

const formatSliderTimes = (route) => {
    const startTime = moment(route[0].date).format('hh:mm A');
    const endTime = moment(route[route.length - 1].date).format('hh:mm A');

    return { startTime, endTime };
};

const calculateElapsedTime = (route, sliderValue) => {
    const startTime = moment(route[0].date);
    const endTime = moment(route[sliderValue].date);
    const duration = moment.duration(endTime.diff(startTime));

    const days = Math.floor(duration.asDays());
    const hours = duration.hours();
    const minutes = duration.minutes();

    let result = '';

    if (days > 0) {
        result += `${days}d `;
    }
    if (hours > 0 || days > 0) {
        result += `${hours}h `;
    }
    result += `${minutes}m`;

    return result.trim();
};

const PlaybackMenu = ({ route, setPlaybackCoordinates }) => {
    const [sliderValue, setSliderValue] = useState(0);
    const [playbackValue, setPlaybackValue] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [selectedTime, setSelectedTime] = useState(route[0].date);
    const formattedSelectedTime = selectedTime ? moment(selectedTime).format('MM/DD, hh:mm A') : 'N/A';
    const elapsedTime = calculateElapsedTime(route, sliderValue);
    const { startTime, endTime } = formatSliderTimes(route);

    const handleSliderChange = (event, newValue) => {
        setSliderValue(newValue);
        setSelectedTime(route[newValue].date);
    };

    const handlePlayClick = () => {
        if (isPlaying) {
            clearInterval(playbackValue);
            setPlaybackValue(null);
            setIsPlaying(false);
            return;
        }

        setIsPlaying(true);

        const id = setInterval(() => {
            setSliderValue((prevValue) => {
                const nextValue = prevValue + 1;
                if (nextValue >= route.length) {
                    clearInterval(id);
                    setPlaybackValue(null);
                    setIsPlaying(false);
                    return route.length - 1;
                }
                setSelectedTime(route[nextValue].date);
                return nextValue;
            });
        }, 100);

        setPlaybackValue(id);
    };

    useEffect(() => {
        const { coordinates } = route[sliderValue]?.location;

        setPlaybackCoordinates({
            lat: coordinates[1],
            lng: coordinates[0],
        });
    }, [sliderValue]);

    return (
        <Box fullWidth display="flex" justifyContent="center" alignItems="stretch" sx={(theme) => menuSx(theme).wrap}>
            <Box sx={(theme) => menuSx(theme).box} flexGrow={1}>
                <IconButton onClick={handlePlayClick}>{isPlaying ? <PauseIcon sx={(theme) => menuSx(theme).icon} color="primary" /> : <PlayArrow sx={(theme) => menuSx(theme).icon} color="primary" />}</IconButton>
            </Box>
            <Box sx={(theme) => menuSx(theme).box} flexGrow={2}>
                <Box>Date/Time</Box>
                <Box>{formattedSelectedTime}</Box>
            </Box>
            <Box sx={(theme) => menuSx(theme).box} flexGrow={2}>
                <Box>Total Time</Box>
                <Box>{elapsedTime}</Box>
            </Box>
            <Box sx={(theme) => menuSx(theme).sliderBox} flexGrow={10}>
                <Slider sx={(theme) => menuSx(theme).slider} value={sliderValue} min={0} max={route.length - 1} onChange={handleSliderChange} />
                <Box display="flex" justifyContent="space-between" fullWidth>
                    <Box>{startTime}</Box>
                    <Box>{endTime}</Box>
                </Box>
            </Box>
        </Box>
    );
};

export default PlaybackMenu;
