import Split from '../../../../../Split';

export const isUserRoleAuthorized = (item, role) => {
    if (!item.auth || item.auth.length === 0) return true;

    if (!Array.isArray(item.auth)) return false;

    return item.auth.includes(role);
};

export const isPermissionGranted = (item, userPermissions, subuser) => {
    const { permission, subPermissionOnly, requireAllPermissions = true } = item;

    if (!permission || permission.length === 0) return true;

    if (subPermissionOnly && !subuser) return true;

    if (!userPermissions || userPermissions.length === 0) return false;

    if (!Array.isArray(permission)) return false;

    const permissionGranted = requireAllPermissions ? permission.every((perm) => userPermissions.includes(perm)) : permission.some((perm) => userPermissions.includes(perm));

    return permissionGranted;
};

export const hasSplitTreatment = (item) => {
    if (!item.split) return true;

    return Object.values(Split.getBooleanTreatments(item.split)).includes(true);
};

export const verifyVerticalNav = (item, user) => {
    const { role } = user;
    const { permissions: userPermissions, subuser } = user.data;

    return isUserRoleAuthorized(item, role) && isPermissionGranted(item, userPermissions, subuser) && hasSplitTreatment(item);
};
