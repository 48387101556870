import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';
import Big from 'big.js';

const MilkPriceFormSkimButterfatAdjustment = ({ payGroup, watch, setValue }) => {
    const skimMilkLocationAdjustmentWatch = watch(`milk_prices.pay_group_${payGroup}.skim_milk_location_adjustment`);
    const butterfatLocationAdjustmentWatch = watch(`milk_prices.pay_group_${payGroup}.butter_fat_location_adjustment`);
    const baseSkimMilkWatch = watch('milk_prices.skim_milk_base_price');
    const baseButterfatWatch = watch('milk_prices.butter_fat_base_price');
    useEffect(() => {
        setValue(`milk_prices.pay_group_${payGroup}.skim_milk_price`, new Big(baseSkimMilkWatch || 0).plus(new Big(skimMilkLocationAdjustmentWatch || 0)));
    }, [skimMilkLocationAdjustmentWatch, baseSkimMilkWatch]);
    useEffect(() => {
        setValue(`milk_prices.pay_group_${payGroup}.butter_fat_price`, new Big(baseButterfatWatch || 0).plus(new Big(butterfatLocationAdjustmentWatch || 0)));
    }, [butterfatLocationAdjustmentWatch, baseButterfatWatch]);

    return (
        <>
            <Grid item xs={12}>
                <Typography variant="h6">Pay Group {payGroup}</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
                <ReactHookFormInput label="Skim Loc. Adj. ($/cwt)" name={`milk_prices.pay_group_${payGroup}.skim_milk_location_adjustment`} type="number" />
            </Grid>
            <Grid item xs={12} sm={3}>
                <ReactHookFormInput label="Butterfat Loc. Adj. ($/lb)" name={`milk_prices.pay_group_${payGroup}.butter_fat_location_adjustment`} type="number" />
            </Grid>
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={3}>
                <ReactHookFormInput label="Skim ($/cwt)" name={`milk_prices.pay_group_${payGroup}.skim_milk_price`} fieldOpts={{ type: 'number', disabled: true }} />
            </Grid>
            <Grid item xs={12} sm={3}>
                <ReactHookFormInput label="Butterfat ($/lb)" name={`milk_prices.pay_group_${payGroup}.butter_fat_price`} fieldOpts={{ type: 'number', disabled: true }} />
            </Grid>
        </>
    );
};

export default MilkPriceFormSkimButterfatAdjustment;
