import React from 'react';
import { ListSubheader, withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
/* eslint-disable-next-line */
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import FuseNavVerticalCollapse from './FuseNavVerticalCollapse';
import FuseNavVerticalItem from './FuseNavVerticalItem';
import { verifyVerticalNav } from '../../../../app/main/apps/ConfiguredApps/navigation/navigation-utils';

const propTypes = {
    item: PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string,
        children: PropTypes.array,
        subPermissionOnly: PropTypes.bool,
        requireAllPermissions: PropTypes.bool,
    }),
};

const defaultProps = {};

const styles = (theme) => ({
    item: {
        height: 40,
        width: 'calc(100% - 16px)',
        borderRadius: '0 20px 20px 0',
        paddingRight: 12,
    },
});

function FuseNavVerticalGroup({ classes, item, nestedLevel, user, active }) {
    if (!verifyVerticalNav(item, user)) {
        return null;
    }

    let paddingValue = 40 + nestedLevel * 16;
    const listItemPadding = nestedLevel > 0 ? 'pl-' + (paddingValue > 80 ? 80 : paddingValue) : 'pl-24';

    return (
        <React.Fragment>
            <ListSubheader disableSticky={true} className={classNames(classes.item, listItemPadding, 'list-subheader flex items-center')} id={item.id}>
                <span className="list-subheader-text uppercase text-12">{item.title}</span>
            </ListSubheader>

            {item.children && (
                <React.Fragment>
                    {item.children.map((item) => (
                        <React.Fragment key={`${item.id} ${item.auth} ${item.permission}`}>
                            {item.type === 'group' && <NavVerticalGroup item={item} nestedLevel={nestedLevel} active={active} />}

                            {item.type === 'collapse' && <FuseNavVerticalCollapse item={item} nestedLevel={nestedLevel} active={active} />}

                            {item.type === 'item' && <FuseNavVerticalItem item={item} nestedLevel={nestedLevel} active={active} />}
                        </React.Fragment>
                    ))}
                </React.Fragment>
            )}
        </React.Fragment>
    );
}

function mapStateToProps({ persisted }) {
    return {
        user: persisted.auth.user,
    };
}

FuseNavVerticalGroup.propTypes = propTypes;
FuseNavVerticalGroup.defaultProps = defaultProps;

const NavVerticalGroup = withStyles(styles, { withTheme: true })(withRouter(connect(mapStateToProps)(FuseNavVerticalGroup)));

export default NavVerticalGroup;
