import * as Actions from '../../actions';

const initialState = { data: null };

const viewRouteSessionsReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ROUTE_SESSIONS_DETAILS:
            return { ...state, data: { ...action.payload } };
        case Actions.GET_HAULING_ROUTE_SESSIONS_DETAILS:
            return { ...state, data: { ...action.payload } };
        case Actions.GET_PROCESSOR_ROUTE_SESSIONS_DETAILS:
            return { ...state, data: { pickups: [...action.payload.dropoffs] } };
        default:
            return state;
    }
};

export default viewRouteSessionsReducer;
