import store from 'app/store';
import * as repository from '../../../repository';

export const GET_RECEIVED_FILES_DETAILS = 'GET_RECEIVED_FILES_DETAILS';
export const GET_SHARED_FILES_DETAILS = 'GET_SHARED_FILES_DETAILS';
export const ADD_FOLDER = 'ADD_FOLDER';
export const GET_USER_FOLDERS = 'GET_USER_FOLDERS';

export function getReceivedFiles() {
    const { id, role } = store.getState().persisted.auth.user.data;
    const isAdmin = role === 'admin';
    return (dispatch) =>
        repository
            .getReceivedFiles(id, isAdmin)
            .then((payload) => dispatch({ type: GET_RECEIVED_FILES_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getSharedFiles() {
    const { id, role } = store.getState().persisted.auth.user.data;
    const isAdmin = role === 'admin';
    return (dispatch) =>
        repository
            .getSharedFiles(id, isAdmin)
            .then((payload) => dispatch({ type: GET_SHARED_FILES_DETAILS, payload }))
            .catch((error) => {
                throw error;
            });
}

export function addFolder(model) {
    const { id } = store.getState().persisted.auth.user.data;
    return (dispatch) =>
        repository
            .addFolder({ ...model, owner_id: id })
            .then((payload) => dispatch({ type: ADD_FOLDER, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getUserFolders() {
    const { id, role } = store.getState().persisted.auth.user.data;
    const isAdmin = role === 'admin';
    return (dispatch) =>
        repository
            .getUserFolders(id, isAdmin)
            .then((payload) => dispatch({ type: GET_USER_FOLDERS, payload }))
            .catch((error) => {
                throw error;
            });
}
