import CreateConfig from './create';
import DriverLocationsConfig from './driver-locations';
import EditConfig from './edit';
import InspectConfig from './inspect';
import ListUsersConfig from './list-users';
import ManageConfig from './manage';
import MonthlyReportsConfig from './monthly-reports';
import MonthlyPayReportsConfig from './monthly-pay-reports';
import QuotaConfig from './quota';
import SharedConfig from './shared-files';
import SiloConfig from './silos';
import HaulerPayReportsConfig from './hauler-pay-reports';
import VendorPayReportsConfig from './vendor-pay-reports';
import PoolingConfig from './pooling';
import ListConfig from './list';
import EquityConfig from './equity';
import EmbeddedReportConfig from './embedded-reports';
import ManifestConfig from './manifest';
import TableDocumentationConfig from './table-documentation';
import MemberCenterConfig from './member-center';
import UserManagement from './user-management';
import EmbeddedAdminCheckoutReportConfig from './admin-checkout-report-embedded';
import EmbeddedProcessorCheckoutReportConfig from './processor-checkout-report-embedded';
import FuelIntakeConfig from './fuel-intake';

export default [
    ...CreateConfig,
    ...DriverLocationsConfig,
    ...EditConfig,
    ...InspectConfig,
    ...ListUsersConfig,
    ...ManageConfig,
    ...MonthlyReportsConfig,
    ...MonthlyPayReportsConfig,
    ...QuotaConfig,
    ...SharedConfig,
    ...SiloConfig,
    ...HaulerPayReportsConfig,
    ...PoolingConfig,
    ...ListConfig,
    ...VendorPayReportsConfig,
    ...EquityConfig,
    ...ManifestConfig,
    EmbeddedReportConfig,
    TableDocumentationConfig,
    MemberCenterConfig,
    ...UserManagement,
    EmbeddedAdminCheckoutReportConfig,
    EmbeddedProcessorCheckoutReportConfig,
    FuelIntakeConfig,
    // wrap
];
