import React, { Component } from 'react';
import { withStyles, Card, Typography } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import { connect } from 'react-redux';
import Select from '@material-ui/core/es/Select';
import MenuItem from '@material-ui/core/es/MenuItem';
import FormControl from '@material-ui/core/es/FormControl';
import { numberFormat } from '../../utils';
import _ from '@lodash';

class LineGraph extends Component {
    state = {
        dataset: null,
        options: {
            spanGaps: false,
            legend: { display: true },
            maintainAspectRatio: false,
            tooltips: {
                position: 'nearest',
                mode: 'index',
                intersect: false,
                titleFontSize: 14,
                bodyFontSize: 15,
                callbacks: {
                    label(tooltipItem) {
                        return numberFormat(tooltipItem.yLabel, 2, '.', ',');
                    },
                },
            },
            layout: { padding: { left: 24, right: 32 } },
            elements: {
                point: {
                    radius: 5,
                    borderWidth: 2,
                    hoverRadius: 8,
                    hoverBorderWidth: 2,
                },
            },
            plugins: { filler: { propagate: false } },
        },
    };

    componentDidMount() {
        const { defaultSet } = this.props;

        this.setState({ dataset: defaultSet, selectedGraph: defaultSet });
    }

    setDataSet = (event) => {
        this.setState({ dataset: event.target.value });
    };

    getTickSettings = (data, dataset, { role, type, decimalPlaces }) => {
        let min = Number.MAX_VALUE;
        let max = 0;

        for (let x = 0; x < data.length; x++) {
            let setMin = min;
            let setMax = max;

            if (data[x] && data[x].data) {
                data[x].data.forEach((value) => {
                    if (!value) {
                        return;
                    }
                    if (parseFloat(value) < parseFloat(setMin)) {
                        setMin = parseFloat(value);
                    }
                    if (parseFloat(value) > parseFloat(setMax)) {
                        setMax = parseFloat(value);
                    }
                });
            }

            min = parseFloat(setMin) < parseFloat(min) ? setMin : min;
            max = parseFloat(setMax) > parseFloat(max) ? setMax : max;
        }

        let scale;

        switch (dataset) {
            case 'production':
                scale = role === 'admin' ? 200000 : 5000;
                scale = type === 'daily' ? scale / 10 : scale;
                break;
            case 'region':
            case 'district':
                scale = 200000;
                break;
            case 'fat':
                scale = 0.5;
                break;
            case 'lactose':
                scale = 0.5;
                break;
            case 'scc':
                scale = 20;
                break;
            case 'bactoscan':
                scale = 20;
                break;
            case 'bmcc':
                scale = 20;
                break;
            case 'thermo':
                scale = 20;
                break;
            default:
                scale = 1;
                break;
        }

        return {
            beginAtZero: false,
            min: Math.round(min / scale) * scale <= min ? Math.round(min / scale) * scale : Math.round((min - scale) / scale) * scale,
            max: Math.round(max / scale) * scale >= max ? Math.round(max / scale) * scale : Math.round((max + scale) / scale) * scale,
            callback(label) {
                return numberFormat(label, decimalPlaces !== undefined && dataset === 'production' ? decimalPlaces : 2, '.', ',');
            },
        };
    };

    render() {
        const { xAxisLabels, graphData, title, region } = this.props;
        const { dataset, options } = this.state;
        const colors = ['rgb(7,157,229)', 'rgb(0,0,0)', 'rgb(229,165,29)', 'rgb(211,4,0)', 'rgb(0,158,115)'];

        if (!dataset) {
            return null;
        }

        const getMenuDisplayKeys = (key) => {
            switch (key) {
                case 'dropoffs':
                    return 'dropoff amount';
                case 'dropoffQuantity':
                    return '# dropoffs';
                default:
                    return key;
            }
        };

        const data = _.merge({}, graphData);
        Object.keys(data).map((value) => {
            if (data[value].length === 0) {
                delete data[value];
                return value;
            }

            data[value].map((entry, index) => {
                if ('data' in entry && entry.data && entry.data.length === 0) {
                    data[value].pop(index);
                }
                return entry;
            });

            return value;
        });

        const dataWithColors = data[dataset].map((obj, index) => ({
            ...obj,
            borderColor: obj.label.includes('Projected') || obj.label.includes('Year') ? colors[1] : colors[index],
            backgroundColor: obj.label.includes('Projected') || obj.label.includes('Year') ? colors[3] : colors[index],
            pointBackgroundColor: obj.label.includes('Projected') || obj.label.includes('Year') ? colors[3] : colors[index],
            pointHoverBackgroundColor: obj.label.includes('Projected') || obj.label.includes('Year') ? colors[3] : colors[index],
            pointBorderColor: obj.label.includes('Projected') || obj.label.includes('Year') ? colors[3] : colors[index],
            pointHoverBorderColor: obj.label.includes('Projected') || obj.label.includes('Year') ? colors[3] : colors[index],
            borderDash: obj.label.includes('Projected') || obj.label.includes('Year') ? [5] : [],
            fill: false,
            lineTension: 0.3,
            spanGaps: true,
        }));
        const tickSettings = this.getTickSettings(dataWithColors, dataset, { ...this.props, ...this.state, ...this.props });

        return (
            <Card className="w-full rounded-8 shadow-none border-1 py-8 h-full">
                <div className="relative p-24 flex flex-row items-center justify-between">
                    <Typography className="h1 sm:h3 font-bold">{title}</Typography>
                    <FormControl variant="outlined" className="w-full min-w-160 max-w-160">
                        <Typography className="h3 sm:h2 text-14 sm:text-10">Select Graph</Typography>
                        <Select placeholder="Select a Route" className="w-full align-middle" style={{ fontSize: 20, textTransform: 'uppercase' }} variant="standard" value={dataset} onChange={this.setDataSet}>
                            {Object.keys(data).map((key) => (
                                <MenuItem key={key} style={{ textTransform: 'uppercase' }} value={key}>
                                    {getMenuDisplayKeys(key) === 'scc' && region === 'RF' ? 'BMCC' : getMenuDisplayKeys(key)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>

                <Typography className="relative h-200 sm:h-320 sm:pb-16">
                    <Line data={{ labels: xAxisLabels, datasets: dataWithColors }} options={{ ...options, scales: { xAxes: [{ gridLines: { display: false }, ticks: { fontColor: 'rgba(0,0,0,0.54)' } }], yAxes: [{ gridLines: { tickMarkLength: 20 }, ticks: tickSettings }] } }} />
                </Typography>
            </Card>
        );
    }
}

function mapStateToProps({ persisted }) {
    return { role: persisted.auth.user.role, region: persisted.auth.user.data.region };
}

export default connect(mapStateToProps, null)(withStyles(null, { withTheme: true })(LineGraph));
