import addUser from './addUser';

const addSubProducer = (model) =>
    addUser({
        ...model,
        role: 'producer',
        subuser: true,
    });

export default addSubProducer;
