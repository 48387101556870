import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Card, CardContent, MenuItem, Typography, Select } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Moment from 'moment-timezone';
import axios from 'axios';
import _ from 'lodash';
import * as Actions from '../store/actions';
import { getAPIHost } from '../../utils';
import LoadingDialog from './dialogs/LoadingDialog';
import * as repository from '../repository';

class NotificationCenter extends Component {
    state = {
        loading: false,
        readStatusSelect: 'all',
        typeSelect: ['all'],
        roleTypes: {
            admin: ['dropoff', 'comment', 'shared_file', 'scc_150', 'bacto_150', 'scc_warning', 'bacto_warning', 'scc_error', 'bacto_error'],
            producer: ['pickup', 'lab', 'comment', 'monthly_report', 'shared_file'],
            processor: ['dropoff', 'comment'],
            transport: ['pickup', 'dropoff', 'comment'],
            'sub-producer': [],
            guest: [],
        },
        warningTypes: ['scc_150', 'bacto_150', 'scc_warning', 'bacto_warning', 'scc_error', 'bacto_error'],
    };

    componentDidMount() {
        const { id } = this.props;
        repository
            .getNotifications(id)
            .then((payload) => {
                this.setState({ notificationData: payload.notifications.filter((value) => !!value.message) });
            })
            .catch((err) => {});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { id, itemID, addComment, forceFetch, type } = this.props;
        const { comment, notificationData } = this.state;
        if (comment && prevState.comment !== comment) {
            this.setState({ comment: '' });
            addComment(type, itemID, comment).then(() => forceFetch());
        }

        if (notificationData !== prevState.notificationData) {
            const patches = _.map(
                _.filter(notificationData, (data) => !data.status),
                (notification) => {
                    const query = `${getAPIHost()}/notifications/${notification.id}`;
                    const body = { read_status: _.uniq([...notification.read_status, id]) };
                    return axios.patch(query, body);
                }
            );
            Promise.all(patches).then(() => {});
        }
    }

    updateReadStatusSelect = (event) => {
        this.setState({ readStatusSelect: event.target.value });
    };

    updateTypeSelect = (event) => {
        this.setState({ typeSelect: event.target.value });
    };

    filterNotifications = (notifications) => {
        const { readStatusSelect, typeSelect, roleTypes, warningTypes } = this.state;
        const { role } = this.props;
        let newNotifications = notifications;
        newNotifications = newNotifications.filter((value) => roleTypes[role].includes(value.type));
        if (readStatusSelect === 'unread') {
            newNotifications = newNotifications.filter((value) => !value.status);
        }
        if (readStatusSelect === 'read') {
            newNotifications = newNotifications.filter((value) => value.status);
        }
        if (typeSelect.includes('warning')) {
            newNotifications = newNotifications.filter((value) => warningTypes.includes(value.type));
        } else if (!typeSelect.includes('all')) {
            newNotifications = newNotifications.filter((value) => typeSelect.includes(value.type));
        }
        // TODO comments are currently broken
        newNotifications = newNotifications.filter((value) => value.type !== 'comment');
        //

        return newNotifications;
    };

    closeNotifications = () => {
        const { close } = this.props;
        this.setState({ loading: false });
        close();
    };

    goToInstance = (data) => {
        const { getSingleInstance, history } = this.props;
        this.setState({ loading: true });
        switch (data.type) {
            case 'pickup':
                getSingleInstance('pickups', data.object_id).then((resolve) => this.setInspect(resolve.payload.pickup[0]).then(history.location.pathname !== '/inspect-pickup' ? history.push({ pathname: '/inspect-pickup' }) : null, this.closeNotifications()));
                break;
            case 'dropoff':
                getSingleInstance('dropoffs', data.object_id).then((resolve) => this.setInspect(resolve.payload.dropoff[0]).then(history.location.pathname !== '/inspect-dropoff' ? history.push({ pathname: '/inspect-dropoff' }) : null, this.closeNotifications()));
                break;
            case 'comment':
                if (data.commented_object_type === 'pickup') {
                    getSingleInstance('pickups', data.commented_object_id).then((resolve) => this.setInspect(resolve.payload.pickup[0]).then(history.location.pathname !== '/inspect-pickup' ? history.push({ pathname: '/inspect-pickup' }) : null, this.closeNotifications()));
                } else if (data.commented_object_type === 'dropoff') {
                    getSingleInstance('dropoffs', data.commented_object_id).then((resolve) => this.setInspect(resolve.payload.dropoff[0]).then(history.location.pathname !== '/inspect-dropoff' ? history.push({ pathname: '/inspect-dropoff' }) : null, this.closeNotifications()));
                } else if (data.commented_object_type === 'lab_report') {
                    getSingleInstance('lab-reports', data.commented_object_id).then((resolve) => this.setInspect(resolve.payload.labReport[0]).then(history.location.pathname !== '/inspect-lab-report' ? history.push({ pathname: '/inspect-lab-report' }) : null, this.closeNotifications()));
                }
                break;
            case 'shared_file':
                history.push({ pathname: '/shared-files/received' });
                this.closeNotifications();
                break;
            case 'monthly_report':
                history.push({ pathname: '/monthly-reports' });
                this.closeNotifications();
                break;
            case 'scc_warning':
            case 'bacto_warning':
            case 'scc_150':
            case 'bacto_150':
            case 'scc_error':
            case 'bacto_error':
                getSingleInstance('lab-reports', data.object_id).then((resolve) => this.setInspect(resolve.payload.labReport[0]).then(history.location.pathname !== '/inspect-lab-report' ? history.push({ pathname: '/inspect-lab-report' }) : null, this.closeNotifications()));
                break;
            case 'lab_report':
                getSingleInstance('lab-reports', data.commented_object_id).then((resolve) => this.setInspect(resolve.payload.labReport[0]).then(history.location.pathname !== '/inspect-lab-report' ? history.push({ pathname: '/inspect-lab-report' }) : null, this.closeNotifications()));
                break;
            // return 'A new lab report was created!';
            // /inspect-lab-report
            default:
                return data;
        }
    };

    setInspect = (selected) =>
        new Promise(() => {
            const { setInspectReducer } = this.props;
            setInspectReducer(selected);
        });

    formatData = (selected) => ({
        created_at: selected.created_at.date,
        selected_id: selected.id,
        producer: selected.producer ? selected.producer : null,
        date_tested: selected.created_at.date,
    });

    render() {
        const { notificationData, readStatusSelect, typeSelect, loading } = this.state;
        const { close, role } = this.props;
        // eslint-disable-next-line global-require
        const uuidv4 = require('uuid/v4');

        return (
            <div
                className="w-full flex-col h-full"
                style={{
                    paddingTop: 20,
                    paddingLeft: 20,
                    paddingRight: 20,
                }}
            >
                <LoadingDialog showDialog={loading} />
                <div className="flex flex-row w-full justify-between">
                    <Typography className="flex" style={{ fontSize: 20 }}>
                        Notification Center
                    </Typography>
                    <Button className="flex" style={{ color: 'black' }} onClick={close}>
                        Cancel
                    </Button>
                </div>
                <div className="w-full">
                    <Select className="w-1/2" name="first" label="Read Status" value={readStatusSelect} onChange={this.updateReadStatusSelect}>
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="unread">Unread</MenuItem>
                        <MenuItem value="read">Read</MenuItem>
                    </Select>
                    <Select className="w-1/2" name="2" label="Type" value={typeSelect} onChange={this.updateTypeSelect}>
                        <MenuItem value="all">All</MenuItem>
                        {role !== 'admin' && role !== 'processor' && <MenuItem value="pickup">Pickup</MenuItem>}
                        {role !== 'producer' && <MenuItem value="dropoff">Dropoff</MenuItem>}
                        {role !== 'admin' && role !== 'processor' && <MenuItem value="lab">Lab</MenuItem>}
                        <MenuItem value="comment">Comment</MenuItem>
                        {role !== 'admin' && role !== 'processor' && <MenuItem value="monthly_report">Monthly Report</MenuItem>}
                        {role !== 'processor' && <MenuItem value="shared_file">Shared File</MenuItem>}
                        {role === 'admin' && <MenuItem value="warning">Quality Warning</MenuItem>}
                    </Select>
                </div>
                {(!notificationData || notificationData.length === 0) && (
                    <Card className="w-full items-center border-1" style={{ backgroundColor: grey['50'], borderColor: grey['300'] }}>
                        <CardContent>
                            <Typography style={{ paddingTop: 18, fontSize: 18 }}>{!notificationData ? 'Fetching Notifications...' : 'You have no Notifications'}</Typography>
                        </CardContent>
                    </Card>
                )}
                {/* eslint-disable-next-line no-return-assign */}
                {notificationData
                    ? this.filterNotifications(notificationData).map((value, index) => (
                          <div key={uuidv4()}>
                              {!value.is_edit && (
                                  <Card
                                      className={value.status ? 'w-full items-center border-1' : 'w-full items-center border-4'}
                                      style={{
                                          backgroundColor: grey['50'],
                                          borderColor: value.status ? grey['300'] : '#3E8FFB',
                                          cursor: 'pointer',
                                      }}
                                      onClick={() => this.goToInstance(value)}
                                  >
                                      <CardContent>
                                          <div className="flex w-full">
                                              <Typography
                                                  align="left"
                                                  className="flex w-2/3"
                                                  style={{
                                                      fontSize: 14,
                                                      fontWeight: 900,
                                                      color: !value.status ? '#3E8FFB' : grey['500'],
                                                  }}
                                              >
                                                  {!value.status ? 'Unread' : 'Read'}
                                              </Typography>
                                              <Typography align="right" className="flex w-2/3 justify-end" style={{ fontSize: 14, fontWeight: 100 }}>
                                                  {Moment(value.created_at).format('DD MMM, YYYY h:mmA')}
                                              </Typography>
                                          </div>
                                          <div className="w-full flex flex-row items-baseline">
                                              <Typography>{value.message}</Typography>
                                          </div>
                                      </CardContent>
                                  </Card>
                              )}
                              {!!value.is_edit && (
                                  <div
                                      className="w-full"
                                      style={{
                                          paddingTop: 20,
                                          paddingBottom: 20,
                                          backgroundColor: grey['300'],
                                      }}
                                  >
                                      <Typography
                                          className="w-full"
                                          style={{
                                              textAlign: 'center',
                                              fontSize: 20,
                                              color: grey['600'],
                                          }}
                                      >
                                          {`${value.user} edited this ${value.comment} on ${value.created_at}`}
                                      </Typography>
                                  </div>
                              )}
                          </div>
                      ))
                    : null}
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addComment: Actions.addComment,
            editComment: Actions.editComment,
            getSingleInstance: Actions.getSingleInstance,
            setInspectReducer: Actions.setInspectReducer,
        },
        dispatch
    );
}

function mapStateToProps({ mainReducer, persisted }) {
    return {
        userName: persisted.auth.user.data.name,
        role: persisted.auth.user.role,
        routeSessionPickups: mainReducer.routeSessionPickups.data,
        id: persisted.auth.user.data.id,
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NotificationCenter));
