import React, { useState } from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'app/store/actions';
import PureDatePicker from './PureDatePicker';

const DatePicker = ({ selectedDate, startDate, setDate }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleDateChange = (value) => {
        setDate(value);
        if (value.format('MM') !== moment(selectedDate, 'YYYY-MM').format('MM')) {
            setIsOpen(false);
        }
    };

    const handlePrevMonth = () => {
        setDate(selectedDate.clone().subtract(1, 'month'));
    };

    const handleNextMonth = () => {
        setDate(selectedDate.clone().add(1, 'month'));
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    return <PureDatePicker value={selectedDate} minDate={startDate} onChange={handleDateChange} onPrev={handlePrevMonth} onNext={handleNextMonth} open={isOpen} onOpen={handleOpen} onClose={handleClose} />;
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ setDate: Actions.setDate }, dispatch);

const mapStateToProps = ({ persisted }) => ({ selectedDate: moment(`${persisted.dateDetails.selectedDate}`, 'YYYY-MM'), startDate: moment(`${persisted.dateDetails.startDate}`, 'YYYY-MM') });

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);
