import React, { createContext, useState } from 'react';
import { getShapeCenter } from './GeofenceManager/GeofenceManager.helpers';

export const MapContext = createContext({});

export const MapProvider = ({ children, geofences }) => {
    const [error, setError] = useState();
    const [map, setMap] = useState();
    const [fences, setFences] = useState(JSON.parse(JSON.stringify(geofences)));
    const [selectedIndex, setSelectedIndex] = useState(0);

    const resetFences = () => setFences(JSON.parse(JSON.stringify(geofences)));

    const focusMap = (fence) => {
        const center = getShapeCenter(fence);
        if (!center) return;
        map.panTo(center);
        map.setZoom(12);
    };

    return (
        <MapContext.Provider
            value={{
                error,
                setError,
                fences,
                setFences,
                resetFences,
                focusMap,
                map,
                setMap,
                selectedIndex,
                setSelectedIndex,
            }}
        >
            {children}
        </MapContext.Provider>
    );
};
