import { AbilityBuilder, PureAbility } from '@casl/ability';
import { getId } from '../../../../../utils';

const RAW_MILK_DROPOFF = 'raw_milk_dropoff';
function DropoffPermissions(user) {
    const { can, cannot, rules } = new AbilityBuilder(PureAbility);
    const {
        role,
        data: { region },
    } = user;

    const id = getId();

    /*
       Currently only processors in the Prairie Region can mutate raw milk dropoffs.
       TODO: Add rules for other roles/regions if we need to give other users this ability
    */
    if (role === 'processor') {
        if (['PRAIRIE'].includes(region)) {
            can('mutate', RAW_MILK_DROPOFF);
            can('view', RAW_MILK_DROPOFF);
        }
    }

    if (role === 'admin') {
        can('view', RAW_MILK_DROPOFF);
        can('mutate', RAW_MILK_DROPOFF, { read_only_enabled: false });
        can('view_route_session_button', RAW_MILK_DROPOFF, { read_only_enabled: false });
    }

    if (role === 'transport') {
        can('view', RAW_MILK_DROPOFF);

        // Haulers can edit dropoffs they are assigned to
        can('mutate', RAW_MILK_DROPOFF, { hauling_id: id, read_only_enabled: false });
        can('view_route_session_button', RAW_MILK_DROPOFF, { hauling_id: id });
    }

    if (role === 'processor') {
        can('view', RAW_MILK_DROPOFF, { processor_id: id });
        can('view_route_session_button', RAW_MILK_DROPOFF, { read_only_enabled: false });
    }

    can(
        'view_rapid_test',
        RAW_MILK_DROPOFF,
        { dropoff_rapid_test_id: { $exists: true }, read_only_enabled: false } //
    );

    return rules;
}

export default DropoffPermissions;
