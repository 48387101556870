import axios from 'axios';
import { editDropoffSampleQuery } from './queries';

const editDropoffSample = async (key, model) => {
    try {
        const query = editDropoffSampleQuery({ key, model });
        const request = axios.put(query.url, { ...query.body });
        const { data } = await request;
        return data;
    } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default editDropoffSample;
