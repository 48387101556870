import React from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import Container from './Container';

export const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

function DragableList({ items, onChange }) {
    const onDragEnd = (result) => {
        if (!result.destination) return;
        const reordered = reorder(items, result.source.index, result.destination.index);
        onChange(reordered);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Container items={items} />
        </DragDropContext>
    );
}
export default DragableList;
