import { FormControl, FormHelperText } from '@material-ui/core';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import SearchableSelectAll from '../../custom-widgets/SearchableSelectAll';
import _ from 'lodash';

const ReactHookFormSearchableSelect = ({ name, label, rules, selectProps, options, disabled, required, disableClearable, selectAll, multiple, customRender, chipLimit, variant, hideError, hideCheckbox, inputProps, defaultValue, loading, onChange: selectOnChange }) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();
    const error = _.get(errors, name);

    return (
        <FormControl error={!!error} fullWidth style={{ margin: '8px 0' }}>
            <Controller
                control={control}
                rules={rules}
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                    <SearchableSelectAll
                        chipLimit={chipLimit}
                        label={label}
                        multiple={multiple}
                        selectAll={selectAll}
                        disableClearable={disableClearable}
                        required={required}
                        disabled={disabled}
                        customRender={customRender}
                        options={options}
                        onChange={(event, item) => {
                            if (selectOnChange) selectOnChange(event, item);
                            onChange(item);
                        }}
                        inputProps={inputProps}
                        value={!value && defaultValue ? defaultValue : value} // allow to set defaultValue for selectAll to work
                        {...selectProps}
                        variant={variant}
                        hideError={hideError}
                        hideCheckbox={hideCheckbox}
                        loading={loading}
                    />
                )}
                name={name}
            />
            {error && <FormHelperText>{error.message}</FormHelperText>}
        </FormControl>
    );
};

export default ReactHookFormSearchableSelect;
