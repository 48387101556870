import axios from 'axios';
import { getLocationServicesUrl } from './utils';

const getRoutePoints = async (id) => {
    try {
        if (!id) throw new Error('Invalid Id');

        const baseUrl = getLocationServicesUrl();
        const path = 'location-updates';
        const url = `${baseUrl}/${path}/${id}`;

        const response = await axios.get(url);
        return response.data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return [];
    }
};

export default getRoutePoints;
