import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    content: {
        maxWidth: 400,
    },
    bold: {
        fontWeight: 600,
    },
    message: {
        textAlign: 'center',
    },
    spacer: {
        padding: 15,
    },
});

export default function UserAlreadyExistsWarningDialog({ open, onClose, message }) {
    const classes = useStyles();
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogContent className={classes.content}>
                <Box display="flex" alignItems="center" flexDirection="column">
                    <Typography variant="h5" className={classes.bold}>
                        User already exists
                    </Typography>
                    <div className={classes.spacer} />
                    <div className={classes.message}>{message}</div>
                    <div className={classes.spacer} />
                    <Button variant="contained" color="secondary" onClick={onClose}>
                        Close
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
