import axios from 'axios';
import { getDriverStatsQuery } from './queries';

const getDriverStats = async (haulingIds, start, end) => {
    try {
        const driverPerformanceQuery = getDriverStatsQuery({ haulingIds, start, end });
        const driverPerformanceRequest = axios.get(driverPerformanceQuery);

        const { data } = await driverPerformanceRequest;

        return data;
    } catch (err) {
        // eslint-disable-next-line
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getDriverStats;
