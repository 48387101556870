import axios from 'axios';
import _ from 'lodash';
import { getLabReportsQuery, getPickupsQuery, bulkQuery } from './queries';
import { buildExpressQuery } from './queries/builders';

const getLabReportFromBarcode = (barcode) =>
    new Promise((resolve, reject) => {
        const labReportQuery = getLabReportsQuery({ sampleBarcode: barcode });
        const pickupQuery = getPickupsQuery({
            fields: ['id'],
            componentBarcode: barcode,
            filterDeleted: true,
            filterType: 'milk',
            queryBuilder: buildExpressQuery,
        });
        const requests = [axios.get(labReportQuery), axios.get(pickupQuery)];

        axios
            .all(requests)
            .then(
                axios.spread((...responses) => {
                    const [labReport, pickup] = _.map(responses, (response) => _.find(response.data));
                    if (_.isUndefined(labReport) || _.isUndefined(pickup)) {
                        resolve({ success: true });
                    } else {
                        resolve({ success: true, lab_reports: { ...labReport, pickup_id: pickup.id } });
                    }
                })
            )
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getLabReportFromBarcode;
