import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { getAvailableProducerBusinessPayReportsQuery } from './queries';

const getAvailableProducerBusinessPayReports = async (start, end) => {
    const startDate = moment(start).startOf('month').format();
    const endDate = moment(end).endOf('month').format();

    const reportRequest = await axios.get(getAvailableProducerBusinessPayReportsQuery({ startDate, endDate }));
    const reports = reportRequest.data ?? [];
    return {
        data: reports,
    };
};

export default getAvailableProducerBusinessPayReports;
