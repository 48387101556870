import React, { useCallback, useContext, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { makeStyles, debounce } from '@material-ui/core';
import { Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { NotificationContext } from '../../../NotificationContext';
import FormControllerActions from './FormController.actions';
import ControllerErrorAlert from './ControllerErrorAlert';
import { deleteObjectField } from '../../../../utils';

const dirtyValues = (dirtyFields, allValues) => {
    // NOTE: Recursive function.

    // If *any* item in an array was modified, the entire array must be submitted, because there's no
    // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
    if (dirtyFields === true || Array.isArray(dirtyFields)) {
        return allValues;
    }

    // Here, we have an object.
    return Object.fromEntries(Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])]));
};

const parseErrorMessage = (error) => {
    if (Array.isArray(error)) return error;
    if (typeof error === 'string') return [error];

    let errorMessage = [];
    const { message, validatationErrors } = error;

    if (message) errorMessage.push(message);
    if (validatationErrors?.length) errorMessage = errorMessage.concat(validatationErrors);

    if (!errorMessage.length) errorMessage.push('An unknown error occurred. If issue persists, please contact support.');
    return errorMessage;
};

/**
 * @typedef {Object} FormControllerOptions
 * @property {boolean} hideFormActions - hide the default buttons for the form
 * @property {boolean} otherIsSubmitting - if there is another submit button, this will disable the default buttons while submitting
 * @property {string} otherSubmittingText - text to display while otherSubmitting is true
 */

/**
 * @typedef FormControllerSubmitReturn
 * @property {*} data - Return data from the server
 * @property {string | string[]} [error] - Error messages, likely from the server.
 * @property {string[]} [stageError] - If part of the submit worked, the errors for what didn't.
 * @property {string[]} [stageSuccess] - If part of the submit worked, the parts that succeeded.
 */

/**
 * @function FormControllerSubmitFunction
 * @description  Should return data object on success, an error message on failure, or a stage error message
 *  if it failed part way through.
 *  The stageSuccess variable can be used to indicate which parts of the submit worked.
 * @param {Object} model - Data to be submitted.
 * @param {string} [id] - Id of the object if editing.
 * @return {FormControllerSubmitReturn}
 */

const useStyles = makeStyles(() => ({
    grid: { padding: '15px' },
}));

/**
 * Controller component for react hook forms.
 *
 * @param {Object} props
 * @param {HandleCancelFunction} props.handleCancel - Function to cancel/close the form.
 * @param {string} props.submitText - Text for the submit button. Defaults to 'Submit'
 * @param {string} props.id - Id to be used in submit function if required. Generally used for edit functions.
 * @param {FormControllerSubmitFunction} props.submit - Submit function to be run by the form.
 * @param {object} props.defaultValues - Default values to be handed to the form controller.
 * @param {object} props.schema - Yup schema to be used in the form resolver
 * @param {boolean} props.shouldUnregister - RHF property; if true, only submit form field values, not entire defaultValues object
 * @param {string[]} props.ignore - List of field names to remove from submit model
 * @param {React.ReactElement} props.children - The form fields to be rendered.
 * @param {object} props.useFormMethods - Allow form methods to be defined in a higher level component to enable extending form controller
 * @param {FormControllerOptions}  props.options - Options for the form controller
 * @param {string[]} props.ignoreOnSubmitFields - List of fields to be removed from model on submit
 * @param {boolean} props.shouldSubmitOnlyDirtyFields- Only submit dirty/changed fields
 * @param {boolean} props.displaySuccessSnackbar - If false, success snackbar will not override snackbar in higher level component
 * @returns {React.ReactElement}
 */

function FormController({
    handleCancel,
    submitText,
    cancelText,
    id,
    submit,
    defaultValues,
    schema,
    children,
    disabled,
    useFormMethods,
    outsideContext,
    options = {},
    shouldUnregister = false,
    ignore,
    context,
    ignoreOnSubmitFields,
    shouldSubmitOnlyDirtyFields,
    shouldDisableSubmitUnlessDirty,
    displaySuccessSnackbar = true,
    hideCancel = false,
    hideSubmit = false,
    ...props //
}) {
    const classes = useStyles();
    const methods =
        useFormMethods ||
        useForm({
            defaultValues,
            resolver: schema ? yupResolver(schema) : null,
            context,
            shouldUnregister,
        });
    const { showSnackbar, snack } = useContext(NotificationContext);

    const [errorMessage, setErrorMessage] = useState(null);
    const [stageErrorMessages, setStageErrorMessages] = useState(null);
    const [stageSuccessMessages, setStageSuccessMessages] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedValues, setSelectedValues] = useState({});

    const handleSubmit = useCallback(
        async (v, e) => {
            let model = { ...v };
            if (shouldSubmitOnlyDirtyFields) {
                model = dirtyValues(methods.formState.dirtyFields, model);
            }
            if (Array.isArray(ignoreOnSubmitFields)) {
                for (let i = 0; i < ignoreOnSubmitFields.length; ++i) {
                    deleteObjectField(model, ignoreOnSubmitFields[i]);
                }
            }
            setIsSubmitting(true);
            const retVal = (await submit(model, id, e)) ?? {};
            if (retVal.sigInterrupt) {
                setIsSubmitting(false);
                return;
            }
            if (retVal.error) {
                setErrorMessage(parseErrorMessage(retVal.error));
                setIsSubmitting(false);
                return;
            }
            if (retVal.stageError) {
                setStageErrorMessages([...(Array.isArray(retVal.stageError) ? retVal.stageError : [retVal.stageError])]);
                setStageSuccessMessages([...(Array.isArray(retVal.stageSuccess) ? retVal.stageSuccess : [retVal.stageSuccess])]);
                setIsSubmitting(false);
                return;
            }

            setIsSubmitting(false);

            if (displaySuccessSnackbar) {
                showSnackbar({ message: 'Success!', variant: 'success' });
            }

            handleCancel({ success: retVal.data });
        },
        [submit, id]
    );

    const debounceSubmit = useCallback(debounce(handleSubmit, 500), [handleSubmit]);

    const {
        formState: { errors },
    } = methods;

    const hasErrors = errorMessage || stageErrorMessages || Object.keys(errors).length > 0;

    const formHandleCancel = () => {
        handleCancel(hasErrors ? { refreshAnyway: true } : { cancel: true });
    };

    const updateSelectedValues = (name, value) => setSelectedValues((prev) => ({ ...prev, [name]: value }));
    const submittingText = () => {
        if (options.otherIsSubmitting) return options.otherSubmittingText;
        if (isSubmitting) return 'Submitting...';
        return submitText;
    };

    return (
        <FormProvider {...methods} selectedValues={selectedValues} updateSelectedValues={updateSelectedValues} {...outsideContext}>
            <form onSubmit={methods.handleSubmit(debounceSubmit)}>
                <Grid container className={classes.grid} rowSpacing={0} columnSpacing={{ xs: 1, md: 2 }}>
                    {!stageErrorMessages && children}
                    {hasErrors && (
                        <>
                            <ControllerErrorAlert errors={stageSuccessMessages} title="The following actions completed successfully:" severity="success" />
                            <ControllerErrorAlert errors={stageErrorMessages} title="The following actions failed:" />
                            <ControllerErrorAlert errors={errorMessage} title="Form submission has returned the following errors:" />
                            <ControllerErrorAlert errors={errors} title="Form validation returned the following errors:" footer="Please fix them and resubmit." />
                        </>
                    )}
                    {!options.hideFormActions && (
                        <FormControllerActions
                            AdditionalFormActions={props.AdditionalFormActions}
                            handleCancel={formHandleCancel}
                            submitText={submittingText()}
                            cancelText={cancelText}
                            disabled={
                                disabled || // If parent component wants to disable the form
                                isSubmitting || // If the form is submitting
                                options.otherIsSubmitting || // If other submit logic is running
                                stageErrorMessages || // If part of the submit worked
                                // If the form is not dirty and the parent component wants to disable the form unless change
                                (shouldDisableSubmitUnlessDirty && !methods.formState.isDirty)
                            }
                            hideCancel={hideCancel}
                            hideSubmit={hideSubmit}
                        />
                    )}
                </Grid>
            </form>
        </FormProvider>
    );
}

export default FormController;
