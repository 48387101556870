const LABELS = {
    Management: 'User / Asset Management',
    General: 'General',
    Accounting: 'Accounting',
    Pooling: 'Pooling',
};

export const SCHREIBER_PROCESSOR_ID = '60cce1348ada14e90f0783e8';

export default LABELS;
