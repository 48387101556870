import axios from 'axios';
import _ from 'lodash';
import { getNotificationsQuery } from './queries';

const getNotifications = (id) =>
    new Promise((resolve, reject) => {
        const query = getNotificationsQuery({ userId: id, limit: '250' });
        const request = axios.get(query);

        request
            .then((response) => {
                const data = _.map(response.data, (notification) => ({ status: _.includes(notification.read_status, id), ...notification }));
                resolve({ notifications: data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getNotifications;
