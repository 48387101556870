import * as Actions from '../../actions';

const initialState = { data: null };

const routeSessionDropoffReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_ROUTE_SESSION_DROPOFFS:
            return { ...state, data: { ...action.payload } };

        default:
            return state;
    }
};

export default routeSessionDropoffReducer;
