import { authRoles } from 'app/auth';
import { FuseLoadable } from '@fuse';
import store from '../../../../store';

export const CreateAdminConfig = { auth: authRoles.admin, routes: [{ path: '/create-admin', component: FuseLoadable({ loader: () => import('./CreateAdminApp') }) }] };

export const CreateSubAdminConfig = { auth: authRoles.admin, routes: [{ path: '/create-sub-admin', component: FuseLoadable({ loader: () => import('./CreateSubAdminApp') }) }] };

export const CreateDriverConfig = { auth: authRoles.hauling, routes: [{ path: '/create-driver', component: FuseLoadable({ loader: () => import('./CreateDriverApp') }) }] };

export const CreateReceiverConfig = { auth: [...authRoles.hauling, ...authRoles.processor], routes: [{ path: '/create-receiver', component: FuseLoadable({ loader: () => import('./CreateReceiverApp') }) }] };

export const CreateProducerConfig = { auth: authRoles.admin, routes: [{ path: '/create-producer', component: FuseLoadable({ loader: () => import('./CreateProducerApp') }) }] };

export const CreateProducerBusinessConfig = { auth: authRoles.admin, routes: [{ path: '/create-producer-business', component: FuseLoadable({ loader: () => import('../../../../custom-components/forms/producer-business/form/RegionRouter') }) }] };

export const CreateSubProducerConfig = { auth: authRoles.producer, routes: [{ path: '/create-sub-producer', component: FuseLoadable({ loader: () => import('./CreateSubProducerApp') }) }] };

export const CreateProcessorConfig = { auth: authRoles.admin, routes: [{ path: '/create-processor', component: FuseLoadable({ loader: () => import('../../ConfiguredApps/processors/form/RegionRouter') }) }] };

export const CreateSubProcessorConfig = { auth: authRoles.processor, routes: [{ path: '/create-sub-processor', component: FuseLoadable({ loader: () => import('./CreateSubProcessorApp') }) }] };

export const CreateLabReportConfig = { auth: authRoles.admin, routes: [{ path: '/create-lab-report', component: FuseLoadable({ loader: () => import('../../../../custom-components/forms/lab-report/form/RegionRouter') }) }] };

export const CreateHaulingConfig = { auth: authRoles.admin, routes: [{ path: '/create-hauling-company', component: FuseLoadable({ loader: () => import('../../../../custom-components/forms/hauling-companies/form/RegionRouter') }) }] };

export const CreateSubHaulingConfig = { auth: authRoles.hauling, routes: [{ path: '/create-sub-hauling', component: FuseLoadable({ loader: () => import('./CreateSubHaulingApp') }) }] };

export const CreateTrailerConfig = { auth: [...authRoles.admin, ...authRoles.hauling], routes: [{ path: '/create-trailer', component: FuseLoadable({ loader: () => import('./CreateTrailerApp') }) }] };

export const CreateTruckConfig = { auth: [...authRoles.admin, ...authRoles.hauling], routes: [{ path: '/create-truck', component: FuseLoadable({ loader: () => import('./CreateTruckApp') }) }] };

export const CreateRouteConfig = { auth: authRoles.admin, routes: [{ path: '/create-route', component: FuseLoadable({ loader: () => import('./CreateRouteApp') }) }] };

export const CreateScheduleRouteConfig = { auth: authRoles.hauling, routes: [{ path: '/create-schedule-route', component: FuseLoadable({ loader: () => import('./CreateScheduleRouteApp') }) }] };

export const CreateAssignmentApp = { auth: authRoles.admin, routes: [{ path: '/create-assignment', component: FuseLoadable({ loader: () => import('./CreateAssignmentApp') }) }] };

export const CreateSiloConfig = { auth: authRoles.processor, routes: [{ path: '/create-silo', component: FuseLoadable({ loader: () => import('./CreateSiloApp') }) }] };

export const CreateMerchandiseApp = { auth: authRoles.admin, routes: [{ path: '/create-merchandise', component: FuseLoadable({ loader: () => import('./CreateMerchandiseApp') }) }] };

export const CreateMerchandisePurchaseApp = { auth: authRoles.admin, routes: [{ path: '/create-merchandise-purchase', component: FuseLoadable({ loader: () => import('./CreateMerchandisePurchaseApp') }) }] };

export const CreateAssigneeApp = { auth: authRoles.admin, routes: [{ path: '/create-assignee', component: FuseLoadable({ loader: () => import('./CreateAssigneeApp') }) }] };

export const CreateSplitProducerConfig = { auth: authRoles.admin, routes: [{ path: '/create-split-producer', component: FuseLoadable({ loader: () => import('./CreateSplitProducerApp') }) }] };

export const CreateHaulingContractConfig = { auth: authRoles.admin, routes: [{ path: '/create-hauling-contract', component: FuseLoadable({ loader: () => import('./CreateHaulingContractApp') }) }] };

export const CreateSupplementalHaulingContractConfig = { auth: authRoles.admin, routes: [{ path: '/create-supplemental-hauling-contract', component: FuseLoadable({ loader: () => import('./CreateSupplementalHaulingContractApp') }) }] };

export const CreateHaulerRateConfig = { auth: authRoles.admin, routes: [{ path: '/create-hauler-rate', component: FuseLoadable({ loader: () => import('./CreateHaulerRateApp') }) }] };

export const CreateEffectiveHaulerRateConfig = { auth: authRoles.admin, routes: [{ path: '/create-effective-rate', component: FuseLoadable({ loader: () => import('./CreateEffectiveHaulerRateApp') }) }] };

export const CreateDeferralApp = { auth: authRoles.admin, routes: [{ path: '/create-deferral', component: FuseLoadable({ loader: () => import('./CreateDeferralApp') }) }] };

export const CreatePickupConfig = { auth: [...authRoles.hauling, ...authRoles.processor], routes: [{ path: '/create-pickup', component: FuseLoadable({ loader: () => import('../../ConfiguredApps/pickups-raw-milk/form/MutatePickup') }) }] };

export const CreateDropoffConfig = { auth: authRoles.everyoneButGuest, routes: [{ path: '/create-dropoff', component: FuseLoadable({ loader: () => import('../../ConfiguredApps/dropoffs-raw-milk/form/MutateDropoff') }) }] };

export const CreateRouteSessionConfig = { auth: [...authRoles.hauling, ...authRoles.processor], routes: [{ path: '/create-route-session', component: FuseLoadable({ loader: () => import('../../ConfiguredApps/route-sessions-raw-milk/form/MutateRouteSession') }) }] };

export const CreateSealConfig = { auth: authRoles.everyoneButGuest, routes: [{ path: '/create-seal/:routeSessionId', component: FuseLoadable({ loader: () => import('./CreateSealApp') }) }] };

export const CreateFolderConfig = { auth: authRoles.admin, routes: [{ path: '/shared-files/add-folder', component: FuseLoadable({ loader: () => import('./CreateFolderApp') }) }] };

export const CreateBaseTransferApp = { auth: authRoles.admin, routes: [{ path: '/create-base-transfer', component: FuseLoadable({ loader: () => import('./CreateBaseTransferApp') }) }] };

export const CreateWashFacilityApp = { auth: authRoles.admin, routes: [{ path: '/create-wash-facility', component: FuseLoadable({ loader: () => import('./CreateWashFacilityApp') }) }] };

export const CreateLabProviderConfig = { auth: authRoles.hauling, routes: [{ path: '/create-lab-provider', component: FuseLoadable({ loader: () => import('./CreateLabProviderApp') }) }] };

export const CreateDropoffSampleConfig = { auth: [...authRoles.admin, ...authRoles.processor], routes: [{ path: '/create-dropoff-sample', component: FuseLoadable({ loader: () => import('./CreateDropoffSampleApp') }) }] };

export const CreateAdhocPayConfig = { auth: authRoles.admin, routes: [{ path: '/add-adhoc-pay', component: FuseLoadable({ loader: () => import('../../../../custom-components/pay-reports/adhoc-pay/MutateAdhocPayApp') }) }] };

export default [
    CreateAdminConfig,
    CreateMerchandiseApp,
    CreateMerchandisePurchaseApp,
    CreateAssigneeApp,
    CreateAssignmentApp,
    CreateDeferralApp,
    CreateSplitProducerConfig,
    CreateHaulingContractConfig,
    CreateSupplementalHaulingContractConfig,
    CreateHaulerRateConfig,
    CreateSubAdminConfig,
    CreateDriverConfig,
    CreateReceiverConfig,
    CreateProducerConfig,
    CreateProducerBusinessConfig,
    CreateSubProducerConfig,
    CreateProcessorConfig,
    CreateSubProcessorConfig,
    CreateHaulingConfig,
    CreateSubHaulingConfig,
    CreateTrailerConfig,
    CreateTruckConfig,
    CreateRouteConfig,
    CreateScheduleRouteConfig,
    CreateSiloConfig,
    CreatePickupConfig,
    CreateDropoffConfig,
    CreateRouteSessionConfig,
    CreateFolderConfig,
    CreateLabReportConfig,
    CreateSealConfig,
    CreateBaseTransferApp,
    CreateWashFacilityApp,
    CreateLabProviderConfig,
    CreateDropoffSampleConfig,
    CreateEffectiveHaulerRateConfig,
    CreateAdhocPayConfig,
];
