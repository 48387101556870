import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import { round } from 'lodash';
import moment from 'moment-timezone';

function DoughnutChart({ producerBFQuota, totalMonthlyVolume, avgFat, lastUpdated }) {
    const complete = round(totalMonthlyVolume * (avgFat / 100), 2);
    const remaining = round(producerBFQuota - complete > 0 ? producerBFQuota - complete : 0, 2);

    return (
        <Card className="w-full rounded-8 shadow-none border-1 h-full">
            <div className="flex items-center justify-between px-16 py-16">
                <Typography className="text-20 font-bold">Quota</Typography>
            </div>
            <div className="text-center px-24 py-32">
                <Typography className="text-24 leading-tight font-bold">
                    {producerBFQuota ? producerBFQuota.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0.00'}
                    KG
                </Typography>
                <Typography className="text-24 leading-tight text-center">MONTHLY</Typography>
                <Typography className="text-24 leading-tight text-center">ESTIMATED QUOTA</Typography>
                <Typography className="text-12 leading-tight text-center">{`Updated as of ${moment(lastUpdated).format('MMMM Do, YYYY')}`}</Typography>
            </div>
            <div className="w-full p-32">
                <Doughnut
                    data={{
                        labels: ['Complete', 'Remaining'],
                        datasets: [
                            {
                                data: [complete, remaining],
                                backgroundColor: ['rgb(7,157,229)', '#3c4d5b'],
                                hoverBackgroundColor: ['rgb(7,157,229)', '#3c4d5b'],
                            },
                        ],
                    }}
                    options={{ legend: { display: false }, maintainAspectRatio: false }}
                />
            </div>
            <div className="flex items-center p-8 py-32">
                <div className="flex flex-1 flex-col items-center justify-center py-16 px-16">
                    <Typography className="text-24 leading-none font-bold" style={{ color: 'rgb(7,157,229)', paddingBottom: 13 }}>
                        {complete.toLocaleString('us')}
                        KG
                    </Typography>
                    <Typography className="text-15" color="textSecondary">
                        Complete
                    </Typography>
                </div>
                <div className="flex flex-1 flex-col items-center justify-center py-16 px-16">
                    <Typography className="text-24 leading-none font-bold" style={{ color: '#3c4d5b', paddingBottom: 13 }}>
                        {remaining.toLocaleString('us')}
                        KG
                    </Typography>
                    <Typography className="text-15" color="textSecondary">
                        Remaining
                    </Typography>
                </div>
            </div>
        </Card>
    );
}

export default React.memo(DoughnutChart);
