import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { DialogContentText, Button } from '@material-ui/core';
import { uploadAssignmentConfigurations } from '../../repository';
import { showMessage } from 'app/store/actions';
import { useDispatch } from 'react-redux';

function PaperComponent(props) {
    return <Paper {...props} />;
}

const AssignmentConfigurationDialog = ({ showLoadingDialog, hideLoadingDialog, selectedYear, selectedMonth, type = 'ProducerAssignments' }) => {
    const fileInput = React.createRef();
    const [open, setOpen] = useState(false);
    const [openResults, setOpenResults] = useState(false);
    const [enableButton, setEnableButton] = useState(false);
    const [results, setResults] = useState([]);

    const dispatch = useDispatch();

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseResults = () => {
        setOpenResults(false);
    };

    const handleEnableButton = () => {
        setEnableButton(true);
    };

    const handleUpload = async () => {
        const obj = { file: fileInput.current.files[0] };

        if (showLoadingDialog) {
            showLoadingDialog();
        }

        if (obj.file) {
            if (obj.file.name.slice(-4) !== 'xlsx') {
                dispatch(showMessage({ message: 'Please provide a valid .xlsx file.' }));
                if (hideLoadingDialog) {
                    hideLoadingDialog();
                }
                return;
            }
            const formData = new FormData();
            formData.append('file', obj.file);

            try {
                const response = await uploadAssignmentConfigurations(selectedYear, selectedMonth, formData, type);
                if (response?.data?.includes('ImportAssignmentConfiguration')) {
                    const responseMessage = response?.data.split('ImportAssignmentConfiguration: ')[1];
                    setResults(responseMessage.split('\n'));
                    setOpenResults(true);
                }
                if (response?.data?.includes('ImportHaulerAssignments')) {
                    const responseMessage = response?.data.split('ImportHaulerAssignments: ')[1];
                    setResults(responseMessage.split('\n'));
                    setOpenResults(true);
                }
                if (hideLoadingDialog) {
                    hideLoadingDialog();
                }
            } catch (err) {
                dispatch(showMessage({ message: 'There was a problem uploading your file. Please contact support.' }));
                if (hideLoadingDialog) {
                    hideLoadingDialog();
                }
            }
            setOpen(false);
        }
    };

    return (
        <div>
            <Button type="submit" variant="contained" color="secondary" onClick={handleClick}>
                Select File
            </Button>
            <Dialog open={open} onClose={handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="sm">
                <DialogTitle id="draggable-dialog-title">Upload File</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Upload file <input type="file" id="csvfile" ref={fileInput} onChange={handleEnableButton} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleUpload} variant="contained" color="primary" disabled={!enableButton}>
                        Upload
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openResults} onClose={handleCloseResults} PaperComponent={PaperComponent} fullWidth maxWidth="sm">
                <DialogTitle id="draggable-dialog-title">Assignment Upload Results</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {results.map((result) => (
                            <div key={result}>{result}</div>
                        ))}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseResults} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withRouter(AssignmentConfigurationDialog);
