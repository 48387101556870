import axios from 'axios';
import { editFluidItemQuery } from './queries';

const editFluidItem = async ({ model, key }) => {
    try {
        const query = editFluidItemQuery({ key, model });
        const request = axios.patch(query.url, { ...query.body });
        const { data } = await request;
        return { ids: data };
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        throw new Error('Unable to edit schedule item');
    }
};

export default editFluidItem;
