const Region = Object.freeze({
    CDI: 'CDI',
    LEGACY: 'LEGACY',
    PRAIRIE: 'PRAIRIE',
    UDA: 'UDA',
    MMPA: 'MMPA',
    TCJ: {
        LIBERTY: 'TCJ-LIBERTY',
        WHITEEAGLE: 'TCJ-WHITEEAGLE',
        GPDA: 'TCJ-GPDA',
        ERIE: 'TCJ-ERIE',
        NEBRASKA: 'TCJ-NEBRASKA',
        KYTN: 'TCJ-KYTN',
        // wrap
    },
    NL: 'NL',
    PEI: 'PEI',
    RF: 'RF',
    UNC: 'UNC',
    CACIQUE: 'CACIQUE',
    DFO: 'DFO',
    US_DEMO: 'US-DEMO',
    CA_DEMO: 'CA-DEMO',
    DEMO: 'DEMO',
    BONGARDS: 'BONGARDS',
    PSC: 'PSC',
});

export default Region;
