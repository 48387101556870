import axios from 'axios';
import moment from 'moment-timezone';
import _ from '@lodash';
import { getTwoStringOfMonth, numberFormat, omittedProducersRF } from '../../utils';
import { batchQuery, getMonthlyReportsQuery, getProducersQuery, getLabReportsQuery } from './queries';

const downloadMonthlyMilkPrices = (month, year) =>
    new Promise((resolve) => {
        const start = moment(`${year}-${getTwoStringOfMonth(month)}-01`)
            .startOf('month')
            .format();
        const end = moment(`${year}-${getTwoStringOfMonth(month)}-01`)
            .endOf('month')
            .format();
        const producersQuery = getProducersQuery({ fields: ['id', 'name', 'CMTNumber'], filterDeleted: true });
        const producersRequest = axios.get(producersQuery);

        // initialize first column
        const sheet = [
            ['Farm Number'],
            ['Farmer'],
            [''],
            ['Base Rate'],
            ['Fat ($/kg)'],
            ['Protein ($/kg)'],
            [''],
            ['Production incentive'],
            ['Fat (c/kg)'],
            ['Protein (c/kg)'],
            [''],
            ['Support'],
            ['Fat (c/kg)'],
            ['Protein (c/kg)'],
            [''],
            ['Herd Growth Incentive (- = No, 1 = Yes)'],
            [''],
            ['Herd Testing Incentive'],
            [''],
            ['Cooling Incentive'],
            [''],
            ['Other Adjustments'],
            [''],
            ['Advance repayment (non-negative value - we do the subtraction)'],
            [''],
            ['Back Pay'],
            [''],
            ['Equalisation Payment/Repayment'],
            [''],
            ['Other Adjustments Notes'],
        ];

        producersRequest.then((producersResponse) => {
            const orderedByCMTProducers = _.sortBy(producersResponse.data, 'CMTNumber');
            const groupedByCMTProducers = _.groupBy(producersResponse.data, 'CMTNumber');
            const producerIds = _.map(orderedByCMTProducers, 'id');

            const monthlyReportQuery = getMonthlyReportsQuery({
                start,
                end,
                producerIds,
                order: 'created_at DSC',
                filterDeleted: true,
                filterForPayment: true,
            });
            const monthlyReportRequest = axios.get(monthlyReportQuery);
            const reportStart = moment(start).clone().subtract(1, 'month');
            const monthlyPriceQuery = getMonthlyReportsQuery({
                reportStart,
                end,
                filterForMilkPrice: true,
                filterDeleted: true,
            });
            const monthlyPriceRequest = axios.get(monthlyPriceQuery);

            const labReportQuery = batchQuery(
                getLabReportsQuery,
                {
                    start,
                    end,
                    producerIds,
                    order: 'date ASC',
                    bySampleDate: true,
                    filterOutliers: true,
                    filterDeleted: true,
                },
                'producerIds'
            );
            const labs = _.map(labReportQuery, (q) => axios.get(q));

            axios.all([monthlyReportRequest, monthlyPriceRequest, ...labs]).then(
                axios.spread((...responses) => {
                    const monthlyReportResponse = responses[0].data;
                    const monthlyPriceResponse = responses[1].data;
                    const labsResponse = _.reduce(responses.slice(2), (acc, val) => [...acc, ...val.data], []);
                    const producerGroupedLabs = _.groupBy(labsResponse, 'producer_id');

                    const reports = monthlyPriceResponse && monthlyPriceResponse.length > 0 ? monthlyPriceResponse[0].prices : monthlyReportResponse;
                    const producerGroupedReports = _.groupBy(reports, 'producer_id');
                    // append values
                    _.forEach(orderedByCMTProducers, (producer, index) => {
                        const { CMTNumber = '0' } = producer;
                        if (omittedProducersRF.includes(CMTNumber)) {
                            return;
                        }

                        const producerId = CMTNumber === '687-1' ? groupedByCMTProducers['687'][0].id : CMTNumber === '965-1' ? groupedByCMTProducers['965'][0].id : producer.id;

                        if (producerId in producerGroupedLabs && producerId in producerGroupedReports) {
                            const fatWeight = _.sumBy(
                                _.filter(producerGroupedLabs[producerId], (v) => !!v.fat_weight),
                                'fat_weight'
                            );
                            const proteinWeight = _.sumBy(
                                _.filter(producerGroupedLabs[producerId], (v) => !!v.protein_weight),
                                'protein_weight'
                            );
                            // eslint-disable-next-line prefer-const
                            let totalSolidWeight = fatWeight + proteinWeight;
                            let fatIncentive = 0;
                            let proIncentive = 0;
                            if (CMTNumber !== ' 967') {
                                if (totalSolidWeight > 2000 && totalSolidWeight <= 5000) {
                                    fatIncentive = 5;
                                    proIncentive = 11;
                                } else if (totalSolidWeight > 5000 && totalSolidWeight <= 9000) {
                                    fatIncentive = 10;
                                    proIncentive = 22;
                                } else if (totalSolidWeight > 9000 && totalSolidWeight <= 14000) {
                                    fatIncentive = 12;
                                    proIncentive = 26;
                                } else if (totalSolidWeight > 14000 && totalSolidWeight <= 19000) {
                                    fatIncentive = 17;
                                    proIncentive = 37;
                                } else if (totalSolidWeight > 19000 && totalSolidWeight <= 24000) {
                                    fatIncentive = 21;
                                    proIncentive = 46;
                                } else if (totalSolidWeight > 24000 && totalSolidWeight <= 35000) {
                                    fatIncentive = 25;
                                    proIncentive = 55;
                                } else if (totalSolidWeight > 35000) {
                                    fatIncentive = 30;
                                    proIncentive = 66;
                                }
                            }
                            for (let x = 0; x < sheet.length; x++) {
                                switch (x) {
                                    case 0:
                                        sheet[x].push(producer.CMTNumber);
                                        break;
                                    case 1:
                                        sheet[x].push(producer.name);
                                        break;
                                    case 4:
                                        sheet[x].push(producerGroupedReports[producer.id][0].fat_base_price_per_kg ? numberFormat(producerGroupedReports[producer.id][0].fat_base_price_per_kg, 2) : '-');
                                        break;
                                    case 5:
                                        sheet[x].push(producerGroupedReports[producer.id][0].protein_base_price_per_kg ? numberFormat(producerGroupedReports[producer.id][0].protein_base_price_per_kg, 2) : '-');
                                        break;
                                    case 8:
                                        sheet[x].push(fatIncentive);
                                        break;
                                    case 9:
                                        sheet[x].push(proIncentive);
                                        break;
                                    case 12:
                                        sheet[x].push(producerGroupedReports[producer.id][0].fat_support_rate ? numberFormat(producerGroupedReports[producer.id][0].fat_support_rate, 2) : '-');
                                        break;
                                    case 13:
                                        sheet[x].push(producerGroupedReports[producer.id][0].protein_support_rate ? numberFormat(producerGroupedReports[producer.id][0].protein_support_rate, 2) : '-');
                                        break;
                                    case 15:
                                        sheet[x].push(producerGroupedReports[producer.id][0].herd_incentive ? 1 : '-');
                                        break;
                                    case 17:
                                        sheet[x].push(producerGroupedReports[producer.id][0].cooling_incentive ? producerGroupedReports[producer.id][0].cooling_incentive : '-');
                                        break;
                                    case 19:
                                        sheet[x].push('-');
                                        break;
                                    case 21:
                                        sheet[x].push('-');
                                        break;
                                    case 23:
                                        sheet[x].push(producerGroupedReports[producer.id][0].advance_repayment_amount ? numberFormat(producerGroupedReports[producer.id][0].advance_repayment_amount, 2) : '-');
                                        break;
                                    case 25:
                                        sheet[x].push('-');
                                        break;
                                    case 27:
                                        sheet[x].push('-');
                                        break;
                                    default:
                                        sheet[x].push('');
                                        break;
                                }
                            }
                        } else {
                            for (let x = 0; x < sheet.length; x++) {
                                switch (x) {
                                    case 0:
                                        sheet[x].push(producer.CMTNumber);
                                        break;
                                    case 1:
                                        sheet[x].push(producer.name);
                                        break;
                                    case 4:
                                        sheet[x].push('-');
                                        break;
                                    case 5:
                                        sheet[x].push('-');
                                        break;
                                    case 8:
                                        sheet[x].push('-');
                                        break;
                                    case 9:
                                        sheet[x].push('-');
                                        break;
                                    case 12:
                                        sheet[x].push('-');
                                        break;
                                    case 13:
                                        sheet[x].push('-');
                                        break;
                                    case 15:
                                        sheet[x].push('-');
                                        break;
                                    case 17:
                                        sheet[x].push('-');
                                        break;
                                    case 19:
                                        sheet[x].push('-');
                                        break;
                                    case 21:
                                        sheet[x].push('-');
                                        break;
                                    case 23:
                                        sheet[x].push('-');
                                        break;
                                    case 25:
                                        sheet[x].push('-');
                                        break;
                                    case 27:
                                        sheet[x].push('-');
                                        break;
                                    default:
                                        sheet[x].push('');
                                        break;
                                }
                            }
                        }
                    });

                    resolve(sheet);
                })
            );
        });
    });

export default downloadMonthlyMilkPrices;
