import axios from 'axios';
import { getDriversQuery, getDropoffsQuery, getRouteSessionsQuery, getRoutesQuery, getTrailersQuery, getPickupsQuery, batchQuery } from './queries';
import _ from '@lodash';

const getStats = ({ page, startDate, endDate }) =>
    new Promise((resolve, reject) => {
        const returnData = [];
        const sessionQuery =
            startDate && endDate
                ? getRouteSessionsQuery({
                      start: startDate,
                      end: endDate,
                      omitPoints: true,
                      filterDeleted: true,
                      filterType: 'milk',
                  })
                : getRouteSessionsQuery({
                      removeOrder: true,
                      omitPoints: true,
                      filterDeleted: true,
                      filterType: 'milk',
                  });
        const sessionRequest = axios.get(sessionQuery);

        sessionRequest.then((sessionResponse) => {
            const sessionIds = sessionResponse.data.map((sessionValue) => sessionValue.id);
            const driverQuery = getDriversQuery({});
            const driverRequest = axios.get(driverQuery);
            const routeQuery = getRoutesQuery({ filterType: 'milk' });
            const routeRequest = axios.get(routeQuery);
            const dropoffQueries = startDate && endDate ? batchQuery(getDropoffsQuery, { routeSessionIds: sessionIds, filterDeleted: true }, 'routeSessionIds') : [getDropoffsQuery({ filterDeleted: true, filterType: 'milk' })];
            const dropoffRequests = _.map(dropoffQueries, (q) => axios.get(q));
            const pickupQueries = startDate && endDate ? batchQuery(getPickupsQuery, { routeSessionIds: sessionIds, filterDeleted: true }, 'routeSessionIds') : [getPickupsQuery({ filterDeleted: true, filterType: 'milk' })];
            const pickupRequests = _.map(pickupQueries, (q) => axios.get(q));
            const trailerQuery = getTrailersQuery({});
            const trailerRequest = axios.get(trailerQuery);
            const industryAvgs = {
                volumes: [],
                times: [],
                distances: [],
                unused: [],
                shrinkLitre: [],
                shrinkPercent: [],
                shrinkVolume: [],
            };
            if (page !== 'general') {
                const subRequests = [driverRequest, trailerRequest, routeRequest, ...pickupRequests, ...dropoffRequests];
                axios.all(subRequests).then(
                    axios.spread((...responses) => {
                        const driverResponse = responses[0];
                        const trailerResponse = responses[1];
                        const routeResponse = responses[2];
                        const pickupResponses = responses.slice(3, 3 + pickupRequests.length);
                        const dropoffResponses = responses.slice(3 + pickupRequests.length);

                        const pickupData = _.reduce(pickupResponses, (acc, val) => [...acc, ...val.data], []);
                        const dropoffData = _.reduce(dropoffResponses, (acc, val) => [...acc, ...val.data], []);

                        const filterBy = page === 'driver' ? driverResponse : routeResponse;
                        filterBy.data
                            .filter((driver) => !driver.deleted_at)
                            .forEach((value) => {
                                const filteredSessions = sessionResponse.data.filter((session) => (page === 'driver' ? session.created_by === value.id && !session.deleted_at : session.route_id === value.id && !session.deleted_at));
                                const filteredVolumes = [];
                                const filteredUnusedCapacities = [];
                                const filteredTimes = [];
                                const filteredDistances = [];
                                const filteredShrinks = [];
                                const filteredMetereds = [];
                                const filteredShrinkVolumes = [];
                                let numForShrink = 0;
                                filteredSessions.forEach((session) => {
                                    let sessionVolume = 0;
                                    let sessionMetered = 0;
                                    const countedMeteredsTimes = [];
                                    dropoffData
                                        .filter((dropoff) => !dropoff.deleted_at && dropoff.route_session_id === session.id)
                                        .forEach((dropoff) => {
                                            if (!countedMeteredsTimes.includes(dropoff.metered) || (Math.abs(dropoff.metered / dropoff.volume) > 0.9 && Math.abs(dropoff.metered / dropoff.volume) < 1.1)) {
                                                sessionMetered += dropoff.metered;
                                                countedMeteredsTimes.push(dropoff.metered);
                                            }
                                        });
                                    pickupData
                                        .filter((pickup) => !pickup.deleted_at && pickup.route_session_id === session.id)
                                        .forEach((pickup) => {
                                            sessionVolume += pickup.volume;
                                        });
                                    if (sessionVolume - sessionMetered !== 0 && sessionMetered !== 0) {
                                        numForShrink += 1;
                                    }
                                    if (sessionVolume) {
                                        filteredVolumes.push(sessionVolume);
                                        if (sessionMetered) {
                                            filteredMetereds.push(sessionMetered);
                                            filteredShrinks.push(sessionVolume - sessionMetered);
                                            filteredShrinkVolumes.push(sessionVolume);
                                        }
                                    }
                                    if (trailerResponse && trailerResponse.data && trailerResponse.data.filter((trailer) => trailer.id === session.trailer_id).length > 0 && trailerResponse.data.filter((trailer) => trailer.id === session.trailer_id)[0].capacity >= sessionVolume) {
                                        filteredUnusedCapacities.push(trailerResponse.data.filter((trailer) => trailer.id === session.trailer_id)[0].capacity - sessionVolume);
                                    }
                                    if (session.total_time) {
                                        filteredTimes.push(session.total_time);
                                    }
                                    if (session.total_distance) {
                                        filteredDistances.push(session.total_distance);
                                    }
                                });
                                industryAvgs.volumes.push(...filteredVolumes);
                                industryAvgs.times.push(...filteredTimes);
                                industryAvgs.distances.push(...filteredDistances);
                                industryAvgs.shrinkLitre.push(...filteredShrinks);
                                industryAvgs.unused.push(...filteredUnusedCapacities);
                                industryAvgs.shrinkVolume.push(...filteredShrinkVolumes);

                                returnData.push({
                                    id: returnData.length,
                                    selected_id: value.id,
                                    name: value.name,
                                    volume: _.mean(filteredVolumes) || 0,
                                    unused_capacity: _.sum(filteredUnusedCapacities) / filteredUnusedCapacities.length || 0,
                                    time: filteredTimes.length > 0 ? _.sum(filteredTimes) / filteredTimes.length : '00:00:00',
                                    distance: _.sum(filteredDistances) / filteredDistances.length || 0,
                                    shrinkpercent: 100 * (_.sum(filteredShrinks) / _.sum(filteredMetereds)) || 0,
                                    shrink: _.sum(filteredShrinks) / numForShrink || 0,
                                });
                            });
                        const formattedSessions = [...sessionResponse.data];
                        formattedSessions.forEach((session) => {
                            const sessionPickups = pickupData.filter((pickup) => pickup.route_session_id === session.id);
                            const sessionDropoffs = dropoffData.filter((dropoff) => dropoff.route_session_id === session.id);
                            // eslint-disable-next-line no-param-reassign
                            session.volume = _.sumBy(sessionPickups, 'volume');
                            // eslint-disable-next-line no-param-reassign
                            session.metered = _.sumBy(_.uniqBy(sessionDropoffs, 'metered'), 'metered');
                            // eslint-disable-next-line no-param-reassign,prefer-destructuring
                            session.trailer = _.filter(trailerResponse.data, { id: session.trailer_id })[0];
                            // eslint-disable-next-line no-param-reassign,prefer-destructuring
                            session.route = _.filter(routeResponse.data, { id: session.route_id })[0];
                            // eslint-disable-next-line no-param-reassign,prefer-destructuring
                            session.driver = _.filter(driverResponse.data, { id: session.created_by })[0];
                        });

                        resolve({
                            tableData: returnData,
                            sessions: formattedSessions,
                            industryData: {
                                avgVolume: _.sum(industryAvgs.volumes) / industryAvgs.volumes.length,
                                avgShrinkLitres: _.sum(industryAvgs.shrinkLitre) / industryAvgs.shrinkLitre.length,
                                avgTime: _.sum(industryAvgs.times) / industryAvgs.times.length,
                                avgDistance: _.sum(industryAvgs.distances) / industryAvgs.distances.length,
                                avgUnused: _.sum(industryAvgs.unused) / industryAvgs.unused.length,
                                avgShrinkPercent: (_.sum(industryAvgs.shrinkLitre) / industryAvgs.shrinkLitre.length / (_.sum(industryAvgs.shrinkVolume) / industryAvgs.shrinkVolume.length)) * 100,
                            },
                        });
                    })
                );
            } else {
                const subRequests = [driverRequest, trailerRequest, routeRequest, ...pickupRequests, ...dropoffRequests];
                axios.all(subRequests).then(
                    axios.spread((...responses) => {
                        const orderedSessions = _.orderBy(
                            _.filter(sessionResponse.data, (session) => !session.deleted_at),
                            'created_at',
                            'desc'
                        );

                        const driverResponse = responses[0];
                        const trailerResponse = responses[1];
                        const routeResponse = responses[2];
                        const pickupResponses = responses.slice(3, 3 + pickupRequests.length);
                        const dropoffResponses = responses.slice(3 + pickupRequests.length, 3 + pickupRequests.length + dropoffRequests.length);

                        const pickupData = _.reduce(pickupResponses, (acc, val) => [...acc, ...val.data], []);
                        const dropoffData = _.reduce(dropoffResponses, (acc, val) => [...acc, ...val.data], []);

                        const pickupsBySession = _.groupBy(
                            _.filter(pickupData, (pickup) => !pickup.deleted_at),
                            'route_session_id'
                        );
                        const dropoffsBySession = _.groupBy(
                            _.filter(dropoffData, (dropoff) => !dropoff.deleted_at),
                            'route_session_id'
                        );
                        orderedSessions.forEach((session) => {
                            const sessionPickups = pickupsBySession[session.id];
                            const sessionDropoffs = dropoffsBySession[session.id];
                            // eslint-disable-next-line no-param-reassign
                            session.producers = sessionPickups ? sessionPickups.map((value) => value.producer_id) : [];
                            // eslint-disable-next-line no-param-reassign
                            session.processors = sessionDropoffs ? sessionDropoffs.map((value) => value.processor_id) : [];
                            // eslint-disable-next-line no-param-reassign
                            session.volume = _.sumBy(sessionPickups, 'volume');
                            // eslint-disable-next-line no-param-reassign
                            session.metered = _.sumBy(_.uniqBy(sessionDropoffs, 'metered'), 'metered');
                            // eslint-disable-next-line no-param-reassign,prefer-destructuring
                            session.trailer = _.filter(trailerResponse.data, { id: session.trailer_id })[0];
                            // eslint-disable-next-line no-param-reassign,prefer-destructuring
                            session.driver = _.filter(driverResponse.data, { id: session.created_by })[0];
                            // eslint-disable-next-line no-param-reassign,prefer-destructuring
                            session.route = _.filter(routeResponse.data, { id: session.route_id })[0];
                        });
                        resolve({ tableData: orderedSessions });
                    })
                );
            }
        });
    });

export default getStats;
