import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import moment from 'moment-timezone';
import { getLabBoxComponentTextColor } from '../../utils';

function LabBox({ region, date, fat, protein, bacto, scc, bmcc, thermo, temp, lactose, solidsNotFat, otherSolids }) {
    return (
        <Card className="w-1/2 h-1/2 shadow-none rounded-8 border-1">
            <div className="flex items-center justify-between px-16 py-16">
                <Typography className="text-20 sm:h3 xs:h3 font-bold whitespace-no-wrap">Last Lab Report</Typography>
            </div>
            <div className="flex flex-row items-center justify-between">
                <Typography className="h3 px-16 text-center items-center" color="inherit">
                    {moment(date).format('MMM Do, YYYY')}
                </Typography>
            </div>
            <div className="flex flex-row justify-center items-center py-12" style={{ paddingLeft: 10 }}>
                <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                    <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                        FAT
                    </Typography>
                    <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{fat ? fat.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%</Typography>
                </div>
                <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto py-12">
                    <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                        PROTEIN
                    </Typography>
                    <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{protein ? protein.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%</Typography>
                </div>
            </div>
            <div className="flex flex-row justify-center items-center py-6" style={{ paddingLeft: 10 }}>
                {(region === 'NL' || region === 'PEI') && (
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                            SCC
                        </Typography>
                        <Typography className={`h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold ${getLabBoxComponentTextColor('scc', scc, region)}`}>{scc ? scc.toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}</Typography>
                    </div>
                )}
                {region === 'RF' && (
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                            BMCC
                        </Typography>
                        <Typography className={`h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold ${getLabBoxComponentTextColor('bmcc', bmcc, region)}`}>{bmcc ? bmcc.toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}</Typography>
                    </div>
                )}
                {((region === 'NL' && region === 'PEI') || region === 'RF') && (
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                            BACTO
                        </Typography>
                        <Typography className={`h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold ${getLabBoxComponentTextColor('bacto', bacto, region)}`}>{bacto ? bacto.toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}</Typography>
                    </div>
                )}
            </div>
            {region === 'RF' && (
                <div className="flex flex-row justify-center items-center py-6" style={{ paddingLeft: 10 }}>
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                            THERMO
                        </Typography>
                        <Typography className={`h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold ${getLabBoxComponentTextColor('thermo', thermo, region)}`}>{thermo ? thermo.toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}</Typography>
                    </div>
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary" style={{ whitespace: 'nowrap' }}>
                            TEMP(°C)
                        </Typography>
                        <Typography className={`h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold ${getLabBoxComponentTextColor('temp', temp, region)}`}>{temp ? temp.toLocaleString('us', { minimumFractionDigits: 1, maximumFractionDigits: 1 }) : '-'}</Typography>
                    </div>
                </div>
            )}
            {['CDI', 'PRAIRIE', 'UDA', 'LEGACY', 'MMPA'].includes(region) && (
                <div className="flex flex-row justify-center items-center py-6" style={{ paddingLeft: 10 }}>
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary" style={{ whitespace: 'nowrap' }}>
                            LACTOSE
                        </Typography>
                        <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{lactose ? lactose.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%</Typography>
                    </div>
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                            SCC
                        </Typography>
                        <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{scc ? scc.toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}</Typography>
                    </div>
                </div>
            )}
            {['PRAIRIE', 'MMPA'].includes(region) && (
                <div className="flex flex-row justify-center items-center py-6" style={{ paddingLeft: 10 }}>
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary" style={{ whitespace: 'nowrap' }}>
                            SNF
                        </Typography>
                        <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{solidsNotFat ? solidsNotFat.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%</Typography>
                    </div>
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                            OTHER SOLIDS
                        </Typography>
                        <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{otherSolids ? otherSolids.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%</Typography>
                    </div>
                </div>
            )}
        </Card>
    );
}

export default React.memo(LabBox);
