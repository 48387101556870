import * as Actions from '../../actions';

const initialState = { data: null };

const editReducer = function (state = initialState, action) {
    switch (action.type) {
        case Actions.SET_EDIT_DETAILS:
            return { ...state, data: { ...action.payload } };
        default:
            return state;
    }
};

export default editReducer;
