import moment from 'moment-timezone';
import axios from 'axios';
import _ from '@lodash';
import { getTwoStringOfMonth, numberFormat, omittedProducersRF } from '../../utils';
import { batchQuery, getLabReportsQuery, getPickupsQuery, getProducersQuery, getMonthlyReportsQuery } from './queries';

const downloadMonthlyMilk = (month, year) =>
    new Promise((resolve) => {
        const start = moment(`${year}-${getTwoStringOfMonth(month)}-01`)
            .startOf('month')
            .format();
        const end = moment(`${year}-${getTwoStringOfMonth(month)}-01`)
            .endOf('month')
            .format();

        const producersQuery = getProducersQuery({ fields: ['id', 'name', 'CMTNumber'], filterDeleted: true });
        const producersRequest = axios.get(producersQuery);
        // initialize first column
        const sheet = [
            ['Farm Number'],
            ['Farmer'],
            [''],
            [''],
            ['Litres'],
            ['Fat (kg)'],
            [''],
            ['Protein (kg)'],
            [''],
            ['Total milk solids (kg)'],
            [''],
            ['Base Rate'],
            ['Fat ($/kg)'],
            ['Protein ($/kg)'],
            [''],
            ['Production incentive'],
            ['Fat (c/kg)'],
            ['Protein (c/kg)'],
            [''],
            ['Support'],
            ['Fat (c/kg)'],
            ['Protein (c/kg)'],
            [''],
            ['Income'],
            ['Fat (base)'],
            ['Fat (production)'],
            ['Fat (support)'],
            ['Protein (base)'],
            ['Protein (production)'],
            ['Protein (support)'],
            [''],
            ['Gross income'],
            [''],
            ['Stops'],
            // ['Volume charge (c/L)'],
            ['Volume charge (c/Amount)'],
            ['Stop charge ($/stop)'],
            ['Volume charge ($)'],
            ['Stop charge ($)'],
            [''],
            ['Monthly quality'],
            ['Quality adjustment'],
            ['Fat (3c)'],
            ['Protein (7.5c)'],
            ['Total quality bonus'],
            ['Annual bonus'],
            ['Herd Incentive'],
            ['Cooling Incentive'],
            ['Testing Incentive'],
            ['Other Adjustments'],
            ['Equalisation Payment/Repayment'],
            [''],
            ['Income'],
            [''],
            ['Levy UDV'],
            ['Levy DA'],
            ['Levy DFSV'],
            ['GST'],
            [''],
            ['Net pay'],
            [''],
            ['Advance repayment'],
            [''],
            ['Net & Advance Total'],
            [''],
            ['Annual quality'],
            [''],
            [''],
            [''],
            ['Other Adjustments Notes'],
        ];

        producersRequest.then((producersResponse) => {
            const orderedByCMTProducers = _.sortBy(producersResponse.data, 'CMTNumber');
            const groupedByCMTProducers = _.groupBy(producersResponse.data, 'CMTNumber');
            const producerIds = _.map(orderedByCMTProducers, 'id');

            const pickupsQuery = getPickupsQuery({
                start,
                end,
                producerIds,
                order: 'created_at ASC',
                filterDeleted: true,
                filterType: 'milk',
            });

            const labReportQuery = batchQuery(
                getLabReportsQuery,
                {
                    start,
                    end,
                    producerIds,
                    order: 'date ASC',
                    bySampleDate: true,
                    filterOutliers: true,
                    filterDeleted: true,
                },
                'producerIds'
            );
            const reportStart = moment(start).clone().subtract(1, 'month');
            const monthlyReportQuery = getMonthlyReportsQuery({
                reportStart,
                end,
                order: 'created_at DSC',
                filterForMilkPrice: true,
            });
            const monthlyReportRequest = axios.get(monthlyReportQuery);

            const producersLastReport = getMonthlyReportsQuery({
                producerIds,
                reportStart,
                end,
                order: 'created_at DSC',
                filterForPayment: true,
            });
            const producersLastReportRequest = axios.get(producersLastReport);
            const lastYearThisMonthStart = moment(start).subtract(1, 'year').format();
            const lastYearThisMonthEnd = moment(end).subtract(1, 'year').format();

            const producersLastYearThisMonthReport = getMonthlyReportsQuery({
                producerIds,
                start: lastYearThisMonthStart,
                end: lastYearThisMonthEnd,
                order: 'created_at DSC',
                filterForPayment: true,
            });
            const producersLastYearThisMonthReportRequest = axios.get(producersLastYearThisMonthReport);

            const producersLastYearThisMonthLabReportQuery = batchQuery(
                getLabReportsQuery,
                {
                    start: lastYearThisMonthStart,
                    end: lastYearThisMonthEnd,
                    producerIds,
                    order: 'date ASC',
                    bySampleDate: true,
                    filterOutliers: true,
                    filterDeleted: true,
                },
                'producerIds'
            );

            const yearToDateStart = moment(start).set('month', 6).subtract(1, 'year');

            const yearToDateReports = getMonthlyReportsQuery({
                yearToDateStart,
                end,
                order: 'created_at DSC',
                filterForPayment: true,
            });
            const yearToDateReportsRequest = axios.get(yearToDateReports);

            const pickups = axios.get(pickupsQuery);
            const labs = _.map(labReportQuery, (q) => axios.get(q));
            const lastYearThisMonthLabs = _.map(producersLastYearThisMonthLabReportQuery, (q) => axios.get(q));

            axios.all([pickups, monthlyReportRequest, producersLastReportRequest, producersLastYearThisMonthReportRequest, yearToDateReportsRequest, ...labs, ...lastYearThisMonthLabs]).then(
                axios.spread((...responses) => {
                    const pickupsResponse = responses[0].data;
                    const monthlyReportResponse = responses[1].data;
                    const producersReportResponse = responses[2].data;
                    const producersLastYearThisMonthReportResponse = responses[3].data;
                    const producersYearToDateReportsResponse = responses[4].data.filter((r) => moment(r.report_date).isAfter(yearToDateStart));
                    const labsResponse = _.reduce(responses.slice(5, 5 + labs.length), (acc, val) => [...acc, ...val.data], []);
                    const lastYearThisMonthLabsResponse = _.reduce(responses.slice(5 + labs.length), (acc, val) => [...acc, ...val.data], []);
                    const producerGroupedReports = _.groupBy(monthlyReportResponse[0].prices, 'producer_id');
                    const producerGroupedLastReports = _.groupBy(producersReportResponse, 'producer_id');
                    const producerGroupedLastYearThisMonthReports = _.groupBy(producersLastYearThisMonthReportResponse, 'producer_id');
                    const producerGroupedPickups = _.groupBy(pickupsResponse, 'producer_id');
                    const producerGroupedLabs = _.groupBy(labsResponse, 'producer_id');
                    const producerGroupedLastYearThisMonthLabs = _.groupBy(lastYearThisMonthLabsResponse, 'producer_id');
                    const producerGroupedYearToDateReports = _.groupBy(producersYearToDateReportsResponse, 'producer_id');

                    const LEVY_DA_FAT = 0.029263;
                    const LEVY_DA_PROTEIN = 0.071299;
                    const LEVY_DFSV = moment(start).isBefore(moment('2023-07-01')) ? 0.0001667 : moment(start).isBefore(moment('2024-08-01')) ? 0.00017 : 0.0001747;
                    const LEVY_UDV = 0.0008;

                    // initialize totalling variables
                    let tTotalVolume = 0;
                    let tTotalFat = 0;
                    let tTotalPro = 0;
                    let tTotalSolids = 0;
                    let tBaseFatIncome = 0;
                    let tProdFatIncome = 0;
                    let tBaseProIncome = 0;
                    let tProdProIncome = 0;
                    let tSupportFatIncome = 0;
                    let tSupportProIncome = 0;
                    let tGrossIncome = 0;
                    let tVolumeCharge = 0;
                    let tStopCharge = 0;
                    let tFatBonus = 0;
                    let tProBonus = 0;
                    let tQualityBonus = 0;
                    let tIncome = 0;
                    let tIncentive = 0;
                    let tCoolIncentive = 0;
                    let tTestIncentive = 0;
                    let tLevyUDV = 0;
                    let tLevyDA = 0;
                    let tLevyDFSV = 0;
                    let tGST = 0;
                    let tNetPay = 0;
                    // let tNetPayMinusRepayment = 0;
                    let tAnnualQuality = 0;
                    let tTotalAdjustments = 0;
                    let tEqualizationPayment = 0;
                    let tRepayment = 0;
                    // Adding this calculation so that we don't have to hard code isFinalProducer below
                    const producersToDisplay = orderedByCMTProducers.filter((item) => !omittedProducersRF.includes(item.CMTNumber));
                    const finalProducerCMTNumber = producersToDisplay[producersToDisplay.length - 1].CMTNumber;

                    // append values
                    _.forEach(orderedByCMTProducers, (producer, index) => {
                        const { CMTNumber = '0' } = producer;
                        if (omittedProducersRF.includes(CMTNumber)) {
                            return;
                        }
                        const isFinalProducer = CMTNumber === finalProducerCMTNumber;
                        const levyDFSVProducers = ['936', '939', '942', '957', '961', '962', '963', '964'];
                        let producerNumber = 0;
                        let totalVolume = 0;
                        let fatWeight = 0;
                        let proteinWeight = 0;
                        let baseFatRate = 0;
                        let baseProRate = 0;
                        let fatIncentive = 0;
                        let proIncentive = 0;
                        let fatSupport = 0;
                        let proSupport = 0;
                        let baseFatIncome = 0;
                        let prodFatIncome = 0;
                        let supportFatIncome = 0;
                        let baseProIncome = 0;
                        let prodProIncome = 0;
                        let supportProIncome = 0;
                        let solidsIncome = 0;
                        let adjustment = 0;
                        let grossIncome = 0;
                        let totalStops = 0;
                        let volumeChargeRate = 0;
                        let stopChargeRate = 0;
                        let volumeCharge = 0;
                        let stopCharge = 0;
                        let fatBonus = 0;
                        let proBonus = 0;
                        let qualityBonus = 0;
                        let annualBonus = 0;
                        let herdIncentive = 0;
                        let coolingIncentive = 0;
                        let testingIncentive = 0;
                        let otherAdjustments = 0;
                        let equalizationPayment = 0;
                        let income = 0;
                        let levyUDV = 0;
                        let levyDA = 0;
                        let levyDFSV = 0;
                        let GST = 0;
                        let netPay = 0;
                        let repayment = 0;
                        let netPayMinusRepayment = 0;
                        let annualQuality = 0;

                        if ((producer.id in producerGroupedReports && producer.id in producerGroupedLabs) || CMTNumber === '687-1' || CMTNumber === '965-1') {
                            const producerId = CMTNumber === '687-1' ? groupedByCMTProducers['687'][0].id : CMTNumber === '965-1' ? groupedByCMTProducers['965'][0].id : producer.id;

                            fatWeight = _.sumBy(
                                _.filter(producerGroupedLabs[producerId], (v) => !!v.fat_weight),
                                'fat_weight'
                            );
                            fatWeight = parseFloat(numberFormat(fatWeight, 4, '.', ''));
                            proteinWeight = _.sumBy(
                                _.filter(producerGroupedLabs[producerId], (v) => !!v.protein_weight),
                                'protein_weight'
                            );
                            proteinWeight = parseFloat(numberFormat(proteinWeight, 4, '.', ''));
                            if (CMTNumber === '687' || CMTNumber === '965') {
                                fatWeight *= 0.9;
                                proteinWeight *= 0.9;
                            }
                            if (CMTNumber === '687-1' || CMTNumber === '965-1') {
                                fatWeight *= 0.1;
                                proteinWeight *= 0.1;
                            }
                            const totalSolidWeight = fatWeight + proteinWeight;

                            if (producerGroupedReports[producer.id][0].herd_incentive) {
                                let fatWeightThisMonthLastYear = 0;
                                if (producerGroupedLastYearThisMonthReports[producer.id]) {
                                    fatWeightThisMonthLastYear = _.sumBy(
                                        _.filter(producerGroupedLastYearThisMonthReports[producer.id], (v) => !!v.fat_weight),
                                        'fat_weight'
                                    );
                                } else if (producerGroupedLastYearThisMonthLabs[producer.id]) {
                                    fatWeightThisMonthLastYear = _.sumBy(
                                        _.filter(producerGroupedLastYearThisMonthLabs[producer.id], (v) => !!v.fat_weight),
                                        'fat_weight'
                                    );
                                }
                                if (CMTNumber === '687' || CMTNumber === '965') {
                                    fatWeightThisMonthLastYear *= 0.9;
                                }
                                if (CMTNumber === '687-1' || CMTNumber === '965-1') {
                                    fatWeightThisMonthLastYear *= 0.1;
                                }

                                let proteinWeightThisMonthLastYear = 0;
                                if (producerGroupedLastYearThisMonthReports[producer.id]) {
                                    proteinWeightThisMonthLastYear = _.sumBy(
                                        _.filter(producerGroupedLastYearThisMonthReports[producer.id], (v) => !!v.protein_weight),
                                        'protein_weight'
                                    );
                                } else if (producerGroupedLastYearThisMonthLabs[producer.id]) {
                                    proteinWeightThisMonthLastYear = _.sumBy(
                                        _.filter(producerGroupedLastYearThisMonthLabs[producer.id], (v) => !!v.protein_weight),
                                        'protein_weight'
                                    );
                                }
                                if (CMTNumber === '687' || CMTNumber === '965') {
                                    proteinWeightThisMonthLastYear *= 0.9;
                                }
                                if (CMTNumber === '687-1' || CMTNumber === '965-1') {
                                    proteinWeightThisMonthLastYear *= 0.1;
                                }
                                const fatDifference = fatWeight - fatWeightThisMonthLastYear < 0 ? 0 : fatWeight - fatWeightThisMonthLastYear;
                                const proteinDifference = proteinWeight - proteinWeightThisMonthLastYear < 0 ? 0 : proteinWeight - proteinWeightThisMonthLastYear;

                                let fatRate = 0.5;
                                let proRate = 0.23;
                                const AUGUST_MONTH_NUMBER = 8;
                                const NOV_MONTH_NUMBER = 11;
                                if (Number(moment(start).format('MM')) >= AUGUST_MONTH_NUMBER && Number(moment(start).format('MM')) <= NOV_MONTH_NUMBER) {
                                    fatRate = 0.22;
                                    proRate = 0.1;
                                }
                                herdIncentive = fatDifference * fatRate + proteinDifference * proRate;
                            }
                            const currentDateReportExists = producerGroupedLastReports?.[producer.id]?.[0]?.report_date ? moment(start).isSame(moment(producerGroupedLastReports[producer.id][0].report_date), 'month') : false;
                            const producerDemerits = _.sumBy(producerGroupedLabs[producerId], 'demerit_points');
                            const producersNumOfPickups = producerGroupedPickups[producerId] && producerGroupedPickups[producerId].length;
                            const daysInMonth = moment([year, month - 1]).daysInMonth();
                            const scaledDemeritPoints = Math.round((producerDemerits * daysInMonth) / producersNumOfPickups);
                            const meritsAvailable = producerGroupedLastReports?.[producer.id]?.[0] ? (currentDateReportExists ? producerGroupedLastReports[producer.id][0].merit_points_carried_forward : producerGroupedLastReports[producer.id][0].merits_available) : 0;
                            let adjustedDemerits = scaledDemeritPoints;
                            const thresholds = [10, 20, 30, 40, 50, 60, 70, 80, 100, 120, 140, 160, 180];

                            // eslint-disable-next-line no-restricted-syntax
                            for (const threshold of thresholds) {
                                const pointToReachThreshold = adjustedDemerits - threshold;
                                if (pointToReachThreshold <= meritsAvailable) {
                                    adjustedDemerits -= pointToReachThreshold;
                                    break;
                                }
                            }

                            annualQuality = CMTNumber === '952' ? fatWeight * 0.067 + proteinWeight * 0.067 : fatWeight * 0.04 + proteinWeight * 0.1;

                            if (month === '06') {
                                if (CMTNumber !== '967') {
                                    if (producerGroupedYearToDateReports[producer.id].reduce((a, b) => a + Math.abs(b.monthly_quality_adjustment_amount), 0) === 0) {
                                        annualBonus = _.sumBy(producerGroupedYearToDateReports[producer.id], 'annual_quality_amount') + annualQuality;
                                    }
                                }
                            }
                            // lines 20 - 22 are ignored here in initial work as all values are empty in example provided, same with 40-41 as demerit system not yet established
                            for (let x = 0; x < sheet.length; x++) {
                                switch (x) {
                                    case 0:
                                        producerNumber = CMTNumber === '687' || CMTNumber === '965' ? `${CMTNumber}-0` : CMTNumber;
                                        sheet[x].push(producerNumber);
                                        break;
                                    case 1:
                                        sheet[x].push(producer.name);
                                        if (isFinalProducer) {
                                            sheet[x].push('Total');
                                        }
                                        break;
                                    case 4:
                                        totalVolume = _.sumBy(producerGroupedPickups[producerId], 'volume');
                                        if (CMTNumber === '687' || CMTNumber === '965') {
                                            totalVolume *= 0.9;
                                        }
                                        if (CMTNumber === '687-1' || CMTNumber === '965-1') {
                                            totalVolume *= 0.1;
                                        }
                                        tTotalVolume += parseFloat(numberFormat(totalVolume, 0, '.', ''));
                                        sheet[x].push(numberFormat(totalVolume));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tTotalVolume));
                                        }
                                        break;
                                    case 5:
                                        tTotalFat += parseFloat(numberFormat(fatWeight, 2, '.', ''));
                                        sheet[x].push(numberFormat(fatWeight, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tTotalFat, 2));
                                        }
                                        break;
                                    case 6:
                                        sheet[x].push(`${numberFormat((fatWeight / totalVolume) * 100, 2)}%`);
                                        break;
                                    case 7:
                                        tTotalPro += parseFloat(numberFormat(proteinWeight, 2, '.', ''));
                                        sheet[x].push(numberFormat(proteinWeight, 2));
                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tTotalPro, 2));
                                        }
                                        break;
                                    case 8:
                                        sheet[x].push(`${numberFormat((proteinWeight / totalVolume) * 100, 2)}%`);
                                        break;
                                    case 9:
                                        tTotalSolids += parseFloat(numberFormat(totalSolidWeight, 2, '.', ''));
                                        sheet[x].push(numberFormat(totalSolidWeight, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tTotalSolids, 2));
                                        }
                                        break;
                                    case 12:
                                        baseFatRate = producerGroupedReports[producer.id][0].fat_base_price_per_kg;
                                        sheet[x].push(baseFatRate);
                                        break;
                                    case 13:
                                        baseProRate = producerGroupedReports[producer.id][0].protein_base_price_per_kg;
                                        sheet[x].push(baseProRate);
                                        break;
                                    case 16:
                                        fatIncentive = producerGroupedReports[producer.id][0].fat_production_incentive_rate;
                                        sheet[x].push(numberFormat(fatIncentive, 2));
                                        break;
                                    case 17:
                                        proIncentive = producerGroupedReports[producer.id][0].protein_production_incentive_rate;
                                        sheet[x].push(numberFormat(proIncentive, 2));
                                        break;
                                    case 20:
                                        fatSupport = producerGroupedReports[producer.id][0].fat_support_rate;
                                        sheet[x].push(fatSupport ? numberFormat(fatSupport, 2) : '');
                                        break;
                                    case 21:
                                        proSupport = producerGroupedReports[producer.id][0].protein_support_rate;
                                        sheet[x].push(proSupport ? numberFormat(proSupport, 2) : '');
                                        break;
                                    case 24:
                                        baseFatIncome = fatWeight * baseFatRate;
                                        tBaseFatIncome += parseFloat(numberFormat(baseFatIncome, 2, '.', ''));
                                        sheet[x].push(numberFormat(baseFatIncome, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tBaseFatIncome, 2));
                                        }
                                        break;
                                    case 25:
                                        prodFatIncome = fatWeight * (fatIncentive / 100);
                                        tProdFatIncome += parseFloat(numberFormat(prodFatIncome, 2, '.', ''));
                                        sheet[x].push(numberFormat(prodFatIncome, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tProdFatIncome, 2));
                                        }
                                        break;
                                    case 26:
                                        supportFatIncome = fatWeight * (fatSupport / 100);
                                        tSupportFatIncome += parseFloat(numberFormat(supportFatIncome, 2, '.', ''));
                                        sheet[x].push(numberFormat(supportFatIncome, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tSupportFatIncome, 2));
                                        }
                                        break;
                                    case 27:
                                        baseProIncome = proteinWeight * baseProRate;
                                        tBaseProIncome += parseFloat(numberFormat(baseProIncome, 2, '.', ''));
                                        sheet[x].push(numberFormat(baseProIncome, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tBaseProIncome, 2));
                                        }
                                        break;
                                    case 28:
                                        prodProIncome = proteinWeight * (proIncentive / 100);
                                        tProdProIncome += parseFloat(numberFormat(prodProIncome, 2, '.', ''));
                                        sheet[x].push(numberFormat(prodProIncome, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tProdProIncome, 2));
                                        }
                                        break;
                                    case 29:
                                        supportProIncome = proteinWeight * (proSupport / 100);
                                        tSupportProIncome += parseFloat(numberFormat(supportProIncome, 2, '.', ''));
                                        sheet[x].push(numberFormat(supportProIncome, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tSupportProIncome, 2));
                                        }
                                        break;
                                    case 31:
                                        grossIncome = baseFatIncome + baseProIncome + prodFatIncome + prodProIncome + supportFatIncome + supportProIncome;
                                        tGrossIncome += parseFloat(numberFormat(grossIncome, 2, '.', ''));
                                        sheet[x].push(numberFormat(grossIncome, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tGrossIncome, 2));
                                        }
                                        break;
                                    case 33:
                                        totalStops = !!producerGroupedPickups[producerId] ? (producerGroupedPickups[producerId].length > daysInMonth ? daysInMonth : producerGroupedPickups[producerId].length) : 0;
                                        sheet[x].push(totalStops);
                                        break;
                                    case 34:
                                        if (CMTNumber === '612' || CMTNumber === '628' || CMTNumber === '967') {
                                            volumeChargeRate = 0;
                                        } else if (CMTNumber === '936' || CMTNumber === '939' || CMTNumber === '942' || CMTNumber === '957' || CMTNumber === '961' || CMTNumber === '962' || CMTNumber === '963' || CMTNumber === '964') {
                                            volumeChargeRate = 3;
                                        } else {
                                            volumeChargeRate = 2;
                                        }

                                        sheet[x].push(!!volumeChargeRate ? numberFormat(volumeChargeRate, 2) : '-');
                                        break;
                                    case 35:
                                        if (CMTNumber === '967') {
                                            stopChargeRate = 0;
                                        } else if (CMTNumber === '687' || CMTNumber === '687-1' || CMTNumber === '965' || CMTNumber === '965-1') {
                                            stopChargeRate = CMTNumber.includes('-1') ? 1.14 : 10.28;
                                        } else {
                                            stopChargeRate = 11.42;
                                        }

                                        sheet[x].push(numberFormat(stopChargeRate, 2));
                                        break;
                                    case 36:
                                        volumeCharge = (totalVolume * volumeChargeRate) / 100;
                                        tVolumeCharge += parseFloat(numberFormat(volumeCharge, 2, '.', ''));
                                        sheet[x].push(volumeCharge !== 0 ? numberFormat(volumeCharge, 2) : '-');

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tVolumeCharge, 2));
                                        }
                                        break;
                                    case 37:
                                        stopCharge = totalStops * stopChargeRate;
                                        tStopCharge += parseFloat(numberFormat(stopCharge, 2, '.', ''));
                                        sheet[x].push(numberFormat(stopCharge, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tStopCharge, 2));
                                        }
                                        break;
                                    case 39:
                                        baseFatIncome = fatWeight * baseFatRate;
                                        baseProIncome = proteinWeight * baseProRate;
                                        solidsIncome = baseFatIncome + baseProIncome;
                                        if (adjustedDemerits <= 10 || CMTNumber === '967') {
                                            sheet[x].push('-');
                                        } else if (adjustedDemerits > 10 && adjustedDemerits <= 20) {
                                            adjustment = solidsIncome * 0.01;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        } else if (adjustedDemerits > 20 && adjustedDemerits <= 30) {
                                            adjustment = solidsIncome * 0.02;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        } else if (adjustedDemerits > 30 && adjustedDemerits <= 40) {
                                            adjustment = solidsIncome * 0.03;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        } else if (adjustedDemerits > 40 && adjustedDemerits <= 50) {
                                            adjustment = solidsIncome * 0.04;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        } else if (adjustedDemerits > 50 && adjustedDemerits <= 60) {
                                            adjustment = solidsIncome * 0.05;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        } else if (adjustedDemerits > 60 && adjustedDemerits <= 70) {
                                            adjustment = solidsIncome * 0.1;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        } else if (adjustedDemerits > 70 && adjustedDemerits <= 80) {
                                            adjustment = solidsIncome * 0.15;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        } else if (adjustedDemerits > 80 && adjustedDemerits <= 100) {
                                            adjustment = solidsIncome * 0.2;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        } else if (adjustedDemerits > 100 && adjustedDemerits <= 120) {
                                            adjustment = solidsIncome * 0.25;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        } else if (adjustedDemerits > 120 && adjustedDemerits <= 140) {
                                            adjustment = solidsIncome * 0.3;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        } else if (adjustedDemerits > 140 && adjustedDemerits <= 160) {
                                            adjustment = solidsIncome * 0.35;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        } else if (adjustedDemerits > 160 && adjustedDemerits <= 180) {
                                            adjustment = solidsIncome * 0.4;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        } else if (adjustedDemerits > 180) {
                                            adjustment = solidsIncome * 0.5;
                                            sheet[x].push(`(${numberFormat(adjustment, 2)})`);
                                        }
                                        tTotalAdjustments += parseFloat(numberFormat(adjustment, 2, '.', ''));
                                        if (isFinalProducer) {
                                            sheet[x].push(`(${numberFormat(tTotalAdjustments, 2)})`);
                                        }
                                        break;
                                    case 40:
                                        if (adjustedDemerits <= 10 || CMTNumber === '967') {
                                            sheet[x].push('-');
                                        } else if (adjustedDemerits > 10 && adjustedDemerits <= 20) {
                                            sheet[x].push('1%');
                                        } else if (adjustedDemerits > 20 && adjustedDemerits <= 30) {
                                            sheet[x].push('2%');
                                        } else if (adjustedDemerits > 30 && adjustedDemerits <= 40) {
                                            sheet[x].push('3%');
                                        } else if (adjustedDemerits > 40 && adjustedDemerits <= 50) {
                                            sheet[x].push('4%');
                                        } else if (adjustedDemerits > 50 && adjustedDemerits <= 60) {
                                            sheet[x].push('5%');
                                        } else if (adjustedDemerits > 60 && adjustedDemerits <= 70) {
                                            sheet[x].push('10%');
                                        } else if (adjustedDemerits > 70 && adjustedDemerits <= 80) {
                                            sheet[x].push('15%');
                                        } else if (adjustedDemerits > 80 && adjustedDemerits <= 100) {
                                            sheet[x].push('20%');
                                        } else if (adjustedDemerits > 100 && adjustedDemerits <= 120) {
                                            sheet[x].push('25%');
                                        } else if (adjustedDemerits > 120 && adjustedDemerits <= 140) {
                                            sheet[x].push('30%');
                                        } else if (adjustedDemerits > 140 && adjustedDemerits <= 160) {
                                            sheet[x].push('35%');
                                        } else if (adjustedDemerits > 160 && adjustedDemerits <= 180) {
                                            sheet[x].push('40%');
                                        } else if (adjustedDemerits > 180) {
                                            sheet[x].push('50%');
                                        }
                                        break;
                                    case 41:
                                        if (producerDemerits === 0) {
                                            fatBonus = parseFloat((fatWeight * 0.03).toFixed(2));
                                            tFatBonus += parseFloat(numberFormat(fatBonus, 2, '.', ''));
                                            sheet[x].push(numberFormat(fatBonus, 2));
                                        } else {
                                            sheet[x].push('');
                                        }

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tFatBonus, 2));
                                        }
                                        break;
                                    case 42:
                                        if (producerDemerits === 0) {
                                            proBonus = parseFloat((proteinWeight * 0.075).toFixed(2));
                                            tProBonus += parseFloat(numberFormat(proBonus, 2, '.', ''));
                                            sheet[x].push(numberFormat(proBonus, 2));
                                        } else {
                                            sheet[x].push('');
                                        }

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tProBonus, 2));
                                        }
                                        break;
                                    case 43:
                                        qualityBonus = fatBonus + proBonus;
                                        tQualityBonus += parseFloat(numberFormat(qualityBonus, 2, '.', ''));
                                        if (producerDemerits === 0) {
                                            sheet[x].push(numberFormat(qualityBonus, 2));
                                        } else {
                                            sheet[x].push('-');
                                        }

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tQualityBonus, 2));
                                        }
                                        break;
                                    case 44:
                                        sheet[x].push(month === '06' && annualBonus !== 0 ? numberFormat(annualBonus, 2) : '-');
                                        break;
                                    case 45:
                                        tIncentive += parseFloat(numberFormat(herdIncentive, 2, '.', ''));
                                        sheet[x].push(producerGroupedReports[producer.id][0].herd_incentive ? numberFormat(herdIncentive, 2) : '');
                                        if (isFinalProducer) {
                                            sheet[x].push(tIncentive !== 0 ? numberFormat(tIncentive, 2) : '');
                                        }
                                        break;
                                    case 46:
                                        coolingIncentive = producerGroupedReports[producer.id][0].cooling_incentive ? producerGroupedReports[producer.id][0].cooling_incentive : 0;
                                        tCoolIncentive += parseFloat(numberFormat(coolingIncentive, 2, '.', ''));
                                        sheet[x].push(producerGroupedReports[producer.id][0].cooling_incentive ? numberFormat(coolingIncentive, 2) : '');
                                        if (isFinalProducer) {
                                            sheet[x].push(tCoolIncentive !== 0 ? numberFormat(tCoolIncentive, 2) : '');
                                        }
                                        break;
                                    case 47:
                                        testingIncentive = producerGroupedReports[producer.id][0].testing_incentive ? producerGroupedReports[producer.id][0].testing_incentive : 0;
                                        tTestIncentive += parseFloat(numberFormat(testingIncentive, 2, '.', ''));
                                        sheet[x].push(producerGroupedReports[producer.id][0].testing_incentive ? numberFormat(testingIncentive, 2) : '');
                                        if (isFinalProducer) {
                                            sheet[x].push(tTestIncentive !== 0 ? numberFormat(tTestIncentive, 2) : '');
                                        }
                                        break;
                                    case 48:
                                        otherAdjustments = producerGroupedReports[producer.id][0].other_adjustments ? producerGroupedReports[producer.id][0].other_adjustments : 0;
                                        sheet[x].push(otherAdjustments !== 0 ? numberFormat(otherAdjustments, 2) : '');
                                        break;
                                    case 49:
                                        equalizationPayment = producerGroupedReports[producer.id][0].equalization_payment_amount ? producerGroupedReports[producer.id][0].equalization_payment_amount : 0;
                                        tEqualizationPayment += parseFloat(numberFormat(equalizationPayment, 2, '.', ''));
                                        sheet[x].push(equalizationPayment !== 0 ? numberFormat(equalizationPayment, 2) : '');

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tEqualizationPayment, 2));
                                        }
                                        break;
                                    case 51:
                                        income = grossIncome + qualityBonus + annualBonus - stopCharge - volumeCharge - adjustment + herdIncentive + coolingIncentive + testingIncentive + otherAdjustments;
                                        tIncome += parseFloat(numberFormat(income, 2, '.', ''));
                                        sheet[x].push(numberFormat(income, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tIncome, 2));
                                        }
                                        break;
                                    case 53:
                                        if (CMTNumber) {
                                            levyUDV = 0;
                                        } else {
                                            levyUDV = totalVolume * LEVY_UDV;
                                        }

                                        tLevyUDV += parseFloat(numberFormat(levyUDV, 2, '.', ''));

                                        sheet[x].push(!!levyUDV ? numberFormat(levyUDV, 2) : '');

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tLevyUDV, 2));
                                        }
                                        break;
                                    case 54:
                                        levyDA = fatWeight * LEVY_DA_FAT + proteinWeight * LEVY_DA_PROTEIN;
                                        tLevyDA += parseFloat(numberFormat(levyDA, 2, '.', ''));
                                        sheet[x].push(numberFormat(levyDA, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tLevyDA, 2));
                                        }
                                        break;
                                    case 55:
                                        if (levyDFSVProducers.includes(CMTNumber)) {
                                            levyDFSV = totalVolume * LEVY_DFSV;
                                        } else {
                                            levyDFSV = 0;
                                        }

                                        tLevyDFSV += parseFloat(numberFormat(levyDFSV, 2, '.', ''));

                                        sheet[x].push(!!levyDFSV ? numberFormat(levyDFSV, 2) : '');

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tLevyDFSV, 2));
                                        }
                                        break;
                                    case 56:
                                        GST =
                                            Number((baseFatIncome * 0.1).toFixed(2)) +
                                            Number((baseProIncome * 0.1).toFixed(2)) +
                                            Number((prodFatIncome * 0.1).toFixed(2)) +
                                            Number((prodProIncome * 0.1).toFixed(2)) +
                                            Number((qualityBonus * 0.1).toFixed(2)) -
                                            Number((adjustment * 0.1).toFixed(2)) +
                                            Number((annualBonus * 0.1).toFixed(2)) +
                                            Number((supportFatIncome * 0.1).toFixed(2)) +
                                            Number((supportProIncome * 0.1).toFixed(2)) +
                                            Number((herdIncentive * 0.1).toFixed(2)) +
                                            Number((coolingIncentive * 0.1).toFixed(2)) +
                                            Number((testingIncentive * 0.1).toFixed(2)) +
                                            Number((otherAdjustments * 0.1).toFixed(2)) -
                                            Number((volumeCharge * 0.1).toFixed(2)) -
                                            Number((stopCharge * 0.1).toFixed(2)) -
                                            Number((levyUDV * 0.1).toFixed(2));
                                        GST = Number(GST.toFixed(2));
                                        tGST += parseFloat(numberFormat(GST, 2, '.', ''));
                                        sheet[x].push(numberFormat(GST, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tGST, 2));
                                        }
                                        break;
                                    case 58:
                                        // eslint-disable-next-line
                                        const advanceRepayment = producerGroupedReports[producer.id][0].advance_repayment_amount || 0;
                                        netPay =
                                            Number(GST?.toFixed(2)) +
                                            Number(baseFatIncome?.toFixed(2)) +
                                            Number(baseProIncome?.toFixed(2)) +
                                            Number(prodFatIncome?.toFixed(2)) +
                                            Number(prodProIncome?.toFixed(2)) +
                                            Number(qualityBonus?.toFixed(2)) -
                                            Number(adjustment?.toFixed(2)) +
                                            Number(annualBonus?.toFixed(2)) +
                                            Number(supportFatIncome?.toFixed(2)) +
                                            Number(supportProIncome?.toFixed(2)) +
                                            Number(herdIncentive?.toFixed(2)) +
                                            Number(coolingIncentive?.toFixed(2)) +
                                            Number(testingIncentive?.toFixed(2)) +
                                            Number(otherAdjustments?.toFixed(2)) -
                                            Number(volumeCharge?.toFixed(2)) -
                                            Number(stopCharge?.toFixed(2)) -
                                            Number(levyDA?.toFixed(2)) -
                                            Number(levyDFSV?.toFixed(2)) -
                                            Number(levyUDV?.toFixed(2)) -
                                            Number(advanceRepayment?.toFixed(2)) +
                                            Number(equalizationPayment?.toFixed(2));
                                        netPay = Number(netPay.toFixed(2));
                                        tNetPay += parseFloat(numberFormat(netPay, 2, '.', ''));
                                        sheet[x].push(numberFormat(netPay, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tNetPay, 2));
                                        }
                                        break;
                                    case 60:
                                        repayment = producerGroupedReports[producer.id][0].advance_repayment_amount;
                                        tRepayment += parseFloat(numberFormat(repayment, 2, '.', ''));
                                        sheet[x].push(repayment ? `(${numberFormat(repayment, 2)})` : '');
                                        if (isFinalProducer) {
                                            sheet[x].push(tRepayment !== 0 ? `(${numberFormat(tRepayment, 2)})` : '');
                                        }
                                        break;
                                    case 62:
                                        netPayMinusRepayment = netPay - parseFloat(producerGroupedReports[producer.id][0].advance_repayment_amount);
                                        sheet[x].push(repayment ? numberFormat(netPayMinusRepayment, 2) : '');
                                        if (isFinalProducer) {
                                            sheet[x].push(tRepayment !== 0 ? numberFormat(tNetPay - tRepayment, 2) : '');
                                        }
                                        break;
                                    case 64:
                                        tAnnualQuality += parseFloat(numberFormat(annualQuality, 2, '.', ''));
                                        sheet[x].push(numberFormat(annualQuality, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tAnnualQuality, 2));
                                        }
                                        break;
                                    case 68:
                                        sheet[x].push(producerGroupedReports[producer.id][0] && producerGroupedReports[producer.id][0].other_adjustments_notes ? producerGroupedReports[producer.id][0].other_adjustments_notes : '');
                                        break;
                                    default:
                                        sheet[x].push('');
                                        break;
                                }
                            }
                        } else {
                            for (let x = 0; x < sheet.length; x++) {
                                switch (x) {
                                    case 0:
                                        producerNumber = CMTNumber === '687' ? `${CMTNumber}-0` : CMTNumber;
                                        sheet[x].push(producerNumber);
                                        break;
                                    case 1:
                                        sheet[x].push(producer.name);
                                        if (isFinalProducer) {
                                            sheet[x].push('Total');
                                        }
                                        break;
                                    case 4:
                                        if (CMTNumber === '687' || CMTNumber === '965') {
                                            totalVolume *= 0.9;
                                        }
                                        if (CMTNumber === '687-1' || CMTNumber === '965-1') {
                                            totalVolume *= 0.1;
                                        }
                                        tTotalVolume += parseFloat(numberFormat(totalVolume, 0, '.', ''));
                                        sheet[x].push(numberFormat(totalVolume));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tTotalVolume));
                                        }
                                        break;
                                    case 5:
                                        tTotalFat += parseFloat(numberFormat(fatWeight, 2, '.', ''));
                                        sheet[x].push(numberFormat(fatWeight, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tTotalFat, 2));
                                        }
                                        break;
                                    case 6:
                                        sheet[x].push(`${numberFormat((fatWeight / totalVolume) * 100, 2)}%`);
                                        break;
                                    case 7:
                                        tTotalPro += parseFloat(numberFormat(proteinWeight, 2, '.', ''));
                                        sheet[x].push(numberFormat(proteinWeight, 2));
                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tTotalPro, 2));
                                        }
                                        break;
                                    case 8:
                                        sheet[x].push(`${numberFormat((proteinWeight / totalVolume) * 100, 2)}%`);
                                        break;
                                    case 9:
                                        sheet[x].push(numberFormat(0, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tTotalSolids, 2));
                                        }
                                        break;
                                    case 12:
                                        sheet[x].push(baseFatRate);
                                        break;
                                    case 13:
                                        sheet[x].push(baseProRate);
                                        break;
                                    case 16:
                                        sheet[x].push(numberFormat(fatIncentive, 2));
                                        break;
                                    case 17:
                                        sheet[x].push(numberFormat(proIncentive, 2));
                                        break;
                                    case 20:
                                        sheet[x].push(fatSupport ? numberFormat(fatIncentive, 2) : '');
                                        break;
                                    case 21:
                                        sheet[x].push(proSupport ? numberFormat(proIncentive, 2) : '');
                                        break;
                                    case 24:
                                        baseFatIncome = fatWeight * baseFatRate;
                                        tBaseFatIncome += parseFloat(numberFormat(baseFatIncome, 2, '.', ''));
                                        sheet[x].push(numberFormat(baseFatIncome, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tBaseFatIncome, 2));
                                        }
                                        break;
                                    case 25:
                                        prodFatIncome = fatWeight * (fatIncentive / 100);
                                        tProdFatIncome += parseFloat(numberFormat(prodFatIncome, 2, '.', ''));
                                        sheet[x].push(numberFormat(prodFatIncome, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tProdFatIncome, 2));
                                        }
                                        break;
                                    case 26:
                                        sheet[x].push('-');

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tSupportFatIncome, 2));
                                        }
                                        break;
                                    case 27:
                                        baseProIncome = proteinWeight * baseProRate;
                                        tBaseProIncome += parseFloat(numberFormat(baseProIncome, 2, '.', ''));
                                        sheet[x].push(numberFormat(baseProIncome, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tBaseProIncome, 2));
                                        }
                                        break;
                                    case 28:
                                        prodProIncome = proteinWeight * (proIncentive / 100);
                                        tProdProIncome += parseFloat(numberFormat(prodProIncome, 2, '.', ''));
                                        sheet[x].push(numberFormat(prodProIncome, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tProdProIncome, 2));
                                        }
                                        break;
                                    case 29:
                                        sheet[x].push('-');

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tSupportProIncome, 2));
                                        }
                                        break;
                                    case 31:
                                        grossIncome = baseFatIncome + baseProIncome + prodFatIncome + prodProIncome + supportFatIncome + supportProIncome;
                                        tGrossIncome += parseFloat(numberFormat(grossIncome, 2, '.', ''));
                                        sheet[x].push(numberFormat(grossIncome, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tGrossIncome, 2));
                                        }
                                        break;
                                    case 33:
                                        sheet[x].push(totalStops);
                                        break;
                                    case 34:
                                        if (CMTNumber === '612' || CMTNumber === '628') {
                                            volumeChargeRate = 0;
                                        } else if (CMTNumber === '936' || CMTNumber === '939' || CMTNumber === '942' || CMTNumber === '957') {
                                            volumeChargeRate = 3;
                                        } else {
                                            volumeChargeRate = 2;
                                        }

                                        sheet[x].push(!!volumeChargeRate ? numberFormat(volumeChargeRate, 2) : '-');
                                        break;
                                    case 35:
                                        if (CMTNumber === '687' || CMTNumber === '687-1') {
                                            stopChargeRate = CMTNumber.includes('-1') ? 1.14 : 10.28;
                                        } else {
                                            stopChargeRate = 11.42;
                                        }

                                        sheet[x].push(numberFormat(stopChargeRate, 2));
                                        break;
                                    case 36:
                                        volumeCharge = (totalVolume * volumeChargeRate) / 100;
                                        tVolumeCharge += parseFloat(numberFormat(volumeCharge, 2, '.', ''));
                                        sheet[x].push(volumeCharge !== 0 ? numberFormat(volumeCharge, 2) : '-');

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tVolumeCharge, 2));
                                        }
                                        break;
                                    case 37:
                                        stopCharge = totalStops * stopChargeRate;
                                        tStopCharge += parseFloat(numberFormat(stopCharge, 2, '.', ''));
                                        sheet[x].push(numberFormat(stopCharge, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tStopCharge, 2));
                                        }
                                        break;
                                    case 39:
                                        baseFatIncome = fatWeight * baseFatRate;
                                        baseProIncome = proteinWeight * baseProRate;
                                        solidsIncome = baseFatIncome + baseProIncome;
                                        sheet[x].push('-');
                                        tTotalAdjustments += parseFloat(numberFormat(adjustment, 2, '.', ''));
                                        if (isFinalProducer) {
                                            sheet[x].push(`(${numberFormat(tTotalAdjustments, 2)})`);
                                        }
                                        break;
                                    case 40:
                                        sheet[x].push('-');
                                        break;
                                    case 41:
                                        sheet[x].push('');

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tFatBonus, 2));
                                        }
                                        break;
                                    case 42:
                                        sheet[x].push('');
                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tProBonus, 2));
                                        }
                                        break;
                                    case 43:
                                        sheet[x].push('-');
                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tQualityBonus, 2));
                                        }
                                        break;
                                    case 44:
                                        sheet[x].push('-');
                                        break;
                                    case 45:
                                        sheet[x].push('');
                                        if (isFinalProducer) {
                                            sheet[x].push(tIncentive !== 0 ? numberFormat(tIncentive, 2) : '');
                                        }
                                        break;
                                    case 46:
                                        sheet[x].push('');
                                        if (isFinalProducer) {
                                            sheet[x].push(tCoolIncentive !== 0 ? numberFormat(tCoolIncentive, 2) : '');
                                        }
                                        break;
                                    case 47:
                                        sheet[x].push('');
                                        if (isFinalProducer) {
                                            sheet[x].push(tTestIncentive ? numberFormat(tTestIncentive, 2) : '');
                                        }
                                        break;
                                    case 48:
                                        sheet[x].push('');
                                        break;
                                    case 49:
                                        sheet[x].push('');

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tEqualizationPayment, 2));
                                        }
                                        break;
                                    case 51:
                                        income = 0;
                                        tIncome += parseFloat(numberFormat(income, 2, '.', ''));
                                        sheet[x].push(numberFormat(income, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tIncome, 2));
                                        }
                                        break;
                                    case 53:
                                        if (CMTNumber !== '939') {
                                            levyUDV = 0;
                                        } else {
                                            levyUDV = totalVolume * LEVY_UDV;
                                        }

                                        tLevyUDV += parseFloat(numberFormat(levyUDV, 2, '.', ''));

                                        sheet[x].push(!!levyUDV ? numberFormat(levyUDV, 2) : '');

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tLevyUDV, 2));
                                        }
                                        break;
                                    case 54:
                                        levyDA = fatWeight * LEVY_DA_FAT + proteinWeight * LEVY_DA_PROTEIN;
                                        tLevyDA += parseFloat(numberFormat(levyDA, 2, '.', ''));
                                        sheet[x].push(numberFormat(levyDA, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tLevyDA, 2));
                                        }
                                        break;
                                    case 55:
                                        if (levyDFSVProducers.includes(CMTNumber)) {
                                            levyDFSV = totalVolume * LEVY_DFSV;
                                        } else {
                                            levyDFSV = 0;
                                        }

                                        tLevyDFSV += parseFloat(numberFormat(levyDFSV, 2, '.', ''));

                                        sheet[x].push(!!levyDFSV ? numberFormat(levyDFSV, 2) : '');

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tLevyDFSV, 2));
                                        }
                                        break;
                                    case 56:
                                        GST = (income - levyUDV) * 0.1;

                                        tGST += parseFloat(numberFormat(GST, 2, '.', ''));
                                        sheet[x].push(numberFormat(GST, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tGST, 2));
                                        }
                                        break;
                                    case 58:
                                        netPay = income + GST - levyDA - levyDFSV - levyUDV + equalizationPayment; // Seems they calculated net pay after net income, thus addition here
                                        tNetPay += parseFloat(numberFormat(netPay, 2, '.', ''));
                                        sheet[x].push(numberFormat(netPay, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tNetPay, 2));
                                        }
                                        break;
                                    case 60:
                                        repayment = 0;
                                        tRepayment += parseFloat(numberFormat(repayment, 2, '.', ''));
                                        sheet[x].push(repayment ? `(${numberFormat(repayment, 2)})` : '');
                                        if (isFinalProducer) {
                                            sheet[x].push(tRepayment !== 0 ? `(${numberFormat(tRepayment, 2)})` : '');
                                        }
                                        break;
                                    case 62:
                                        netPayMinusRepayment = netPay - 0;
                                        sheet[x].push(repayment ? numberFormat(netPayMinusRepayment, 2) : '');
                                        if (isFinalProducer) {
                                            sheet[x].push(tRepayment !== 0 ? numberFormat(tNetPay - tRepayment, 2) : '');
                                        }
                                        break;
                                    case 64:
                                        tAnnualQuality += parseFloat(numberFormat(annualQuality, 2, '.', ''));
                                        sheet[x].push(numberFormat(annualQuality, 2));

                                        if (isFinalProducer) {
                                            sheet[x].push(numberFormat(tAnnualQuality, 2));
                                        }
                                        break;
                                    case 68:
                                        sheet[x].push('');
                                        break;
                                    default:
                                        sheet[x].push('');
                                        break;
                                }
                            }
                        }

                        if (isFinalProducer) {
                            // add final total line
                            sheet.push(['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', 'Milk incl bonus', numberFormat(tNetPay - tGST + tAnnualQuality, 2)]);
                        }
                    });

                    resolve(sheet);
                })
            );
        });
    });

export default downloadMonthlyMilk;
