import axios from 'axios';
import { requestScheduleItemsAmountQuery } from './queries';

const requestScheduleItemsAmount = async (key) => {
    try {
        const query = requestScheduleItemsAmountQuery({ key });
        const request = axios.patch(query.url, { ...query.body });
        const { data } = await request;
        return { items: data };
    } catch {
        throw new Error('Unable to edit schedule item');
    }
};

export default requestScheduleItemsAmount;
