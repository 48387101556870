import _, { forEach } from 'lodash';
import moment from 'moment-timezone';
import store from '../../store';

const onlyHasFrontCompartment = (region) => {
    switch (region) {
        case 'RF':
            return true;
        default:
            return false;
    }
};

const matchPickupVolumesToDropff = (volumes, total) => {
    if (!volumes) return;
    let volume;
    for (let i = 0; i < volumes.length; i++) {
        if (volumes[i] === total) return [volumes[i]];
        const next = matchPickupVolumesToDropff(volumes.slice(i + 1), total - volumes[i]);
        if (next) {
            if (volume === undefined || next.length < volume.length) {
                volume = [volumes[i], ...next];
            }
        }
    }
    return volume && volume;
};

const getPickupsForDropoffByVolume = (pickups, dropoffTotal) => {
    const pickupVolumes = _.map(pickups, (pickup) => {
        return pickup.volume;
    });
    const matchingVolumes = matchPickupVolumesToDropff(pickupVolumes, dropoffTotal);
    const volumes = !!matchingVolumes ? matchingVolumes : [];
    const pickupsForDropoff = [];
    pickups.forEach((pickup) => {
        if (volumes.includes(pickup.volume)) {
            pickupsForDropoff.push(pickup);
        }
    });
    return pickupsForDropoff;
};

const getCompartmentPickupVolume = (pickup, compartment) => {
    if (pickup.compartment !== 'Split' && pickup.compartment === compartment) {
        return pickup.volume;
    }
    switch (compartment) {
        case 'Front':
            return pickup.volume_a;
        case 'Middle':
            return pickup.volume_b;
        case 'Back':
            return pickup.volume_c;
        default:
            return 0;
    }
};

const getCompartmentDropoffVolume = (dropoff, compartment) => {
    if (dropoff.compartment !== 'Split' && dropoff.compartment === compartment) {
        return dropoff.volume;
    }
    switch (compartment) {
        case 'Front':
            return dropoff.volume_a;
        case 'Middle':
            return dropoff.volume_b;
        case 'Back':
            return dropoff.volume_c;
        default:
            return 0;
    }
};

const getPickupTotalVolumeForCompartment = (pickups, compartment) => {
    if (pickups.length === 0) return 0;
    return _.sum(_.map(pickups, (p) => getCompartmentPickupVolume(p, compartment)));
};

const calculateSplitVolume = (p, compartmentVolumes) => {
    let volume = 0;
    if (p.compartment === 'Split') {
        volume += compartmentVolumes.Front.pickupsVolume ? (p.volume_a / compartmentVolumes.Front.pickupsVolume) * compartmentVolumes.Front.dropoffVolume : 0;
        volume += compartmentVolumes.Middle.pickupsVolume ? (p.volume_b / compartmentVolumes.Middle.pickupsVolume) * compartmentVolumes.Middle.dropoffVolume : 0;
        volume += compartmentVolumes.Back.pickupsVolume ? (p.volume_c / compartmentVolumes.Back.pickupsVolume) * compartmentVolumes.Back.dropoffVolume : 0;
    } else {
        volume += (p.volume / compartmentVolumes[p.compartment].pickupsVolume) * compartmentVolumes[p.compartment].dropoffVolume;
    }
    return volume;
};

const pickupsByCompartmentFilter = (compartment) => (pickup) => getCompartmentPickupVolume(pickup, compartment) > 0;

const getPickupsForDropoff = (dropoff, pickups) => {
    const { region } = store.getState().persisted.auth.user.data;

    const pickupsBeforeDropoff = _.filter(pickups, (o) => moment(o.created_at) < moment(dropoff.updated_at));
    const pickupsAfterDropoff = _.filter(pickups, (o) => !(moment(o.created_at) < moment(dropoff.updated_at)));

    if (dropoff.compartment === 'Split') {
        const compartments = _.reduce(
            ['Front', 'Middle', 'Back'],
            (acc, val) => {
                const pickupsForCompartment = _.filter(pickupsBeforeDropoff, pickupsByCompartmentFilter(val));
                acc[val] = { pickupsVolume: getPickupTotalVolumeForCompartment(pickupsForCompartment, val), dropoffVolume: getCompartmentDropoffVolume(dropoff, val) };
                return acc;
            },
            {}
        );

        const pickupsDropoff = _.map(pickupsBeforeDropoff, (p) => {
            const volume = calculateSplitVolume(p, compartments);
            return {
                ...p,
                volume_a: p.compartment === 'Split' && compartments.Front.pickupsVolume !== 0 ? (p.volume_a / compartments.Front.pickupsVolume) * compartments.Front.dropoffVolume : 0,
                volume_b: p.compartment === 'Split' && compartments.Middle.pickupsVolume !== 0 ? (p.volume_b / compartments.Middle.pickupsVolume) * compartments.Middle.dropoffVolume : 0,
                volume_c: p.compartment === 'Split' && compartments.Back.pickupsVolume !== 0 ? (p.volume_c / compartments.Back.pickupsVolume) * compartments.Back.dropoffVolume : 0,
                volume,
            };
        });

        const pickupsRemaining = _.map(pickupsBeforeDropoff, (p) => {
            const volume = calculateSplitVolume(p, compartments);
            return {
                ...p,
                volume_a: p.compartment === 'Split' && compartments.Front.pickupsVolume !== 0 ? p.volume_a - (p.volume_a / compartments.Front.pickupsVolume) * compartments.Front.dropoffVolume : p.volume_a,
                volume_b: p.compartment === 'Split' && compartments.Middle.pickupsVolume !== 0 ? p.volume_b - (p.volume_b / compartments.Middle.pickupsVolume) * compartments.Middle.dropoffVolume : p.volume_b,
                volume_c: p.compartment === 'Split' && compartments.Back.pickupsVolume !== 0 ? p.volume_c - (p.volume_c / compartments.Back.pickupsVolume) * compartments.Back.dropoffVolume : p.volume_c,
                volume: p.volume - volume,
            };
        });

        const pickupsRemainingMerged = [..._.filter(pickupsRemaining, (p) => p.volume !== 0), ...pickupsAfterDropoff];

        return [pickupsDropoff, pickupsRemainingMerged];
    }

    const pickupsCompartment = _.filter(pickupsBeforeDropoff, (p) => (p.compartment === 'Split' && ((p.volume_a > 0 && dropoff.compartment === 'Front') || (p.volume_b > 0 && dropoff.compartment === 'Middle') || (p.volume_c > 0 && dropoff.compartment === 'Back'))) || (p.compartment === dropoff.compartment && p.volume > 0));
    const pickupsNotCompartment = _.filter(pickupsBeforeDropoff, (p) => !((p.compartment === 'Split' && ((p.volume_a > 0 && dropoff.compartment === 'Front') || (p.volume_b > 0 && dropoff.compartment === 'Middle') || (p.volume_c > 0 && dropoff.compartment === 'Back'))) || (p.compartment === dropoff.compartment && p.volume > 0)));
    const pickupsCompartmentTotalVolume = getPickupTotalVolumeForCompartment(pickupsCompartment, dropoff.compartment);
    const dropoffTotalVolume = dropoff.volume;

    let pickupsForDropoff = [];
    if (onlyHasFrontCompartment(region)) {
        pickupsForDropoff = getPickupsForDropoffByVolume(pickupsCompartment, dropoffTotalVolume);
    } else {
        pickupsForDropoff = _.map(pickupsCompartment, (p) => {
            const volumeCompartment = dropoff.compartment === 'Front' ? p.volume_a : dropoff.compartment === 'Middle' ? p.volume_b : p.volume_c;
            return {
                ...p,
                volume_a: p.compartment === 'Split' && dropoff.compartment === 'Front' ? (p.volume_a / pickupsCompartmentTotalVolume) * dropoffTotalVolume : 0,
                volume_b: p.compartment === 'Split' && dropoff.compartment === 'Middle' ? (p.volume_b / pickupsCompartmentTotalVolume) * dropoffTotalVolume : 0,
                volume_c: p.compartment === 'Split' && dropoff.compartment === 'Back' ? (p.volume_c / pickupsCompartmentTotalVolume) * dropoffTotalVolume : 0,
                volume: p.compartment === 'Split' ? (volumeCompartment / pickupsCompartmentTotalVolume) * dropoffTotalVolume : (p.volume / pickupsCompartmentTotalVolume) * dropoffTotalVolume,
            };
        });
    }

    const pickupsRemaining = _.map(pickupsCompartment, (p) => {
        const volumeCompartment = dropoff.compartment === 'Front' ? p.volume_a : dropoff.compartment === 'Middle' ? p.volume_b : p.volume_c;
        return {
            ...p,
            volume_a: p.compartment === 'Split' && dropoff.compartment === 'Front' ? p.volume_a - (p.volume_a / pickupsCompartmentTotalVolume) * dropoffTotalVolume : p.volume_a,
            volume_b: p.compartment === 'Split' && dropoff.compartment === 'Middle' ? p.volume_b - (p.volume_b / pickupsCompartmentTotalVolume) * dropoffTotalVolume : p.volume_b,
            volume_c: p.compartment === 'Split' && dropoff.compartment === 'Back' ? p.volume_c - (p.volume_c / pickupsCompartmentTotalVolume) * dropoffTotalVolume : p.volume_c,
            volume: p.compartment === 'Split' ? p.volume - (volumeCompartment / pickupsCompartmentTotalVolume) * dropoffTotalVolume : p.volume - (p.volume / pickupsCompartmentTotalVolume) * dropoffTotalVolume,
        };
    });
    const pickupsRemainingMerged = [..._.filter(pickupsRemaining, (o) => o.volume !== 0), ...pickupsNotCompartment, ...pickupsAfterDropoff];
    return [pickupsForDropoff, pickupsRemainingMerged];
};

const getPickupsByDropoffId = (dropoffs, pickups) => {
    const pickupsByDropoffId = {};
    let remainingPickups = pickups;
    let pickupsForDropoff;
    const sortedDropoffs = _.sortBy(dropoffs, 'created_at');
    for (let i = 0; i < sortedDropoffs.length; i++) {
        [pickupsForDropoff, remainingPickups] = getPickupsForDropoff(sortedDropoffs[i], remainingPickups);
        pickupsByDropoffId[sortedDropoffs[i].id] = [...pickupsForDropoff];
    }
    return pickupsByDropoffId;
};

export default getPickupsByDropoffId;
