import store from 'app/store';
import { FuseLoadable } from '@fuse';

const Index = {
    auth: ['admin', 'sub-admin', 'transport', 'sub-transport', 'processor', 'sub-processor'],
    routes: [
        {
            path: '/dropoffs/:date?',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                            return import('./raw-milk/admin/AdminDropoffsApp');
                        case 'transport':
                        case 'sub-transport':
                            return import('./raw-milk/hauling/HaulingDropoffsApp');
                        case 'processor':
                        case 'sub-processor':
                            return store.getState().persisted.auth.user.data.name === 'DFNS' ? import('./raw-milk/processor/DFNSDropoffsApp') : import('./raw-milk/processor/ProcessorDropoffsApp');
                        default:
                            break;
                    }
                },
            }),
        },
        {
            path: '/cream-dropoffs/:date?',
            component: FuseLoadable({
                loader: () => {
                    switch (store.getState().persisted.auth.user.role) {
                        case 'admin':
                        case 'sub-admin':
                            return import('./cream/CreamDropoffsApp');
                        case 'transport':
                        case 'sub-transport':
                            return import('./cream/CreamDropoffsApp');
                        default:
                            break;
                    }
                },
            }),
        },
    ],
};

export default [Index];
