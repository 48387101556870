import Region from '../../../app/constants/region';
import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import stages from '../stages';

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: [Region.MMPA],
});

const demo = new Environment({
    ...prod,
    name: stages.DEMO,
});

const staging = new Environment({
    name: stages.STAGING,
    regions: [Region.MMPA],
});

const local = new Environment({
    regions: [Region.MMPA, Region.PRAIRIE, Region.CDI, Region.UNC],
    name: stages.LOCAL,
});

const dev = new Environment({
    regions: [Region.MMPA, Region.PRAIRIE, Region.CDI, Region.UNC],
    name: stages.DEV,
});

/**
 * Create the new MockSplit, be sure to create the env dictionary with util
 */
const can_access_equity_membership_pages = new MockSplit({
    name: 'can_access_equity_membership_pages',
    environments: [local, dev, staging, demo, prod],
});

export default can_access_equity_membership_pages;
