import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { toSentenceCase } from '../../../../utils';

const useStyles = makeStyles({
    box: {
        display: 'flex',
        flexDirection: 'column',
        padding: '6px',
        justifyContent: 'flex-start',
        borderStyle: 'none',
        borderRadius: '5px',
        backgroundColor: '#EEEEEE',
        color: 'black',
    },
    blue: {
        backgroundColor: '#00A2F514',
    },
});

const ObjectDataItem = ({ data, asNew }) => {
    const classes = useStyles();

    if (!data) return null;
    return (
        <Box className={`${classes.box} ${asNew && classes.blue}`}>
            {Object.keys(data).map((key) => {
                return (
                    <Box sx={{ display: 'flex', marginBottom: '5px', justifyContent: 'space-between', marginRight: '10px' }}>
                        <Box fontWeight="fontWeightMedium">
                            <Typography align="left" variant="p">
                                {toSentenceCase(key)}:
                            </Typography>
                        </Box>
                        <Box>
                            {data[key] && typeof data[key] === 'object' ? (
                                Object.keys(data[key]).map((subKey) => (
                                    <Typography align="right" variant="p">
                                        {JSON.stringify(data[key][subKey])}
                                    </Typography>
                                ))
                            ) : (
                                <Typography align="right" variant="p">
                                    {data[key]}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                );
            })}
        </Box>
    );
};

export default ObjectDataItem;
