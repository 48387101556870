import axios from 'axios';
import { getTrailersQuery } from './queries';

const getTrailers = async (includeDeleted, hId) => {
    try {
        const query = getTrailersQuery({ ...(!includeDeleted && { filterDeleted: true }), ...(hId && { haulingId: hId }) });
        const response = await axios.get(query);

        return { trailers: response.data };
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error getting trailers:', error);
        return { trailers: [] };
    }
};

export default getTrailers;
