import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Divider, InputAdornment } from '@material-ui/core';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import * as FuseActions from 'app/store/actions';
import * as Actions from '../../store/actions';
import { TextFieldFormsy } from '@fuse';
import { LocalizationConsumer } from '../../localization/LocalizationContext';
import { formatUserErrorMessage } from '../../../utils';
import { Alert } from '@mui/material';
import UserAlreadyExistsWarningDialog from '../../custom-widgets/dialogs/UserAlreadyExistsWarningDialog';

const canEditHaulerNumber = (region) => ['CDI', 'UDA', 'PRAIRIE', 'MMPA'].includes(region);

class CreateHaulingForm extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        // eslint-disable-next-line react/state-in-constructor
        this.state = {
            canSubmit: false,
            hauler_number: '',
            alert: null,
            warning: null,
        };
    }

    componentDidMount() {
        const { editData, region } = this.props;

        if (editData) {
            this.setState({
                hauler_number: canEditHaulerNumber(region) ? editData.hauler_number : null,
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { editData, region } = this.props;
        if (prevProps.editData !== editData) {
            if (editData) {
                this.setState({
                    hauler_number: canEditHaulerNumber(region) ? editData.hauler_number : null,
                });
            }
        }
    }

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    navigateToListUsers = () => {
        const { history } = this.props;
        history.replace({ pathname: '/list-hauling-companies' });
    };

    onSubmit = (model) => {
        const { editData, editUser, showMessage, history, addHauling, region } = { ...this.state, ...this.props };
        const submitModel = { ...model };
        submitModel.description = submitModel.description ? submitModel.description : null;
        submitModel.advance_amount = Number(submitModel?.advance_amount) ?? null;

        if (editData) {
            delete submitModel.username;
            delete submitModel.email;
            editUser(submitModel, editData.id)
                .then(() => {
                    showMessage({ message: 'Successfully Edited Hauling Company.' });
                    this.setState({ alert: null });
                    this.navigateToListUsers();
                })
                .catch((err) => {
                    showMessage({ message: formatUserErrorMessage(err, 'hauling company', 'add') });
                });
        } else {
            addHauling(model)
                .then((response) => {
                    showMessage({ message: 'Successfully Added Hauling Company.' });
                    this.setState({ alert: null });
                    if (response?.payload?.data?.warning) {
                        this.setState({ warning: response.payload.data.warning });
                    } else {
                        this.navigateToListUsers();
                    }
                })
                .catch((err) => {
                    showMessage({ message: 'Unable to create a new Hauling Company' });
                    if (err.message) {
                        this.setState({ alert: err.message });
                    }
                });
        }
    };

    render() {
        const { canSubmit } = this.state;
        const { editData, region } = this.props;

        return (
            <LocalizationConsumer>
                {(localization) => (
                    <div className="min-w-3/4 max-w-3/4">
                        <UserAlreadyExistsWarningDialog open={!!this.state.warning} onClose={() => this.navigateToListUsers()} message={this.state.warning} />

                        <Divider />

                        <Formsy
                            onValidSubmit={this.onSubmit}
                            onValid={this.enableButton}
                            onInvalid={this.disableButton}
                            /* eslint-disable-next-line no-return-assign */
                            ref={(form) => (this.form = form)}
                            className="flex flex-col justify-center"
                        >
                            {this.state.alert && (
                                <Alert className="mt-32 mb-16" color="error">
                                    {this.state.alert}
                                </Alert>
                            )}

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="name"
                                label="Name"
                                placeholder={`${localization.general.hauling_singular} Name`}
                                value={editData ? editData.name : ''}
                                validations={{ minLength: 2 }}
                                validationErrors={{ minLength: 'Min character length is 2' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                person
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                            />
                            {canEditHaulerNumber(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="number"
                                    name="hauler_number"
                                    label={`${localization.general.hauling_singular} Number`}
                                    value={editData ? editData.hauler_number : ''}
                                    validations="isNumeric"
                                    validationError={`This is not a valid ${localization.general.hauling_singular} number`}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    local_shipping
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    required={['UDA'].includes(region)}
                                    variant="outlined"
                                />
                            )}
                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="username"
                                label="Username"
                                value={editData ? editData.username : ''}
                                validations={{ ...(!editData && { minLength: 4 }) }}
                                validationErrors={{ minLength: 'Min character length is 4' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                bookmark
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                                disabled={!!editData}
                            />

                            {!editData && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="password"
                                    name="password"
                                    label="Password"
                                    validations={{ minLength: 8, equalsField: 'password_confirmation' }}
                                    validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                    InputProps={{
                                        autoComplete: 'new-password',
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    vpn_key
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    required
                                />
                            )}

                            {!editData && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="password"
                                    name="password_confirmation"
                                    label="Confirm Password"
                                    validations={{ minLength: 8, equalsField: 'password' }}
                                    validationErrors={{ equalsField: 'Passwords do not match', minLength: 'Password must be at least 8 characters' }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    vpn_key
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    required
                                />
                            )}

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="email"
                                label="Email"
                                validations="isEmail"
                                validationError="This is not a valid email"
                                value={editData ? editData.email : ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                alternate_email
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                required
                                variant="outlined"
                                disabled={!!editData}
                            />

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="secondary_email"
                                label={['MMPA'].includes(region) ? 'Office Email' : 'Secondary Email'}
                                validations="isEmail"
                                value={editData ? editData.secondary_email : ''}
                                validationError="This is not a valid email"
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                alternate_email
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />

                            <TextFieldFormsy
                                className="my-16"
                                type="text"
                                name="description"
                                label="Description"
                                placeholder="Description"
                                value={editData ? editData.description : null}
                                validations={{ minLength: 2 }}
                                validationErrors={{ minLength: 'Min character length is 2' }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <i className="text-20 material-icons" color="action">
                                                comment
                                            </i>
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                            />

                            {['PRAIRIE'].includes(region) && (
                                <TextFieldFormsy
                                    className="my-16"
                                    type="number"
                                    name="advance_amount"
                                    label={'Advance Amount'}
                                    value={editData?.advance_amount}
                                    validations="isNumeric"
                                    validationError={'This is not a valid advance amount'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <i className="text-20 material-icons" color="action">
                                                    attach_money
                                                </i>
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                />
                            )}
                            {['MMPA'].includes(region) && (
                                <>
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="address1"
                                        label="Office Address"
                                        placeholder={'Office Address'}
                                        value={editData ? editData.address1 : ''}
                                        validations={{ minLength: 2 }}
                                        validationErrors={{ minLength: 'Min character length is 2' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        location_city
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="phone"
                                        label="Office Phone Number"
                                        placeholder={'Office Phone Number'}
                                        value={editData ? editData.phone : ''}
                                        validations={{ minLength: 10 }}
                                        validationErrors={{ minLength: 'Min character length is 10' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        contact_phone
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="owner_name"
                                        label="Owner Name"
                                        placeholder={'Owner Name'}
                                        value={editData ? editData.owner_name : ''}
                                        validations={{ minLength: 2 }}
                                        validationErrors={{ minLength: 'Min character length is 2' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        person
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="owner_phone"
                                        label="Owner Phone"
                                        placeholder={'Owner Phone'}
                                        value={editData ? editData.owner_phone : ''}
                                        validations={{ minLength: 10 }}
                                        validationErrors={{ minLength: 'Min character length is 10' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        contact_phone
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="owner_email"
                                        label="Owner Email"
                                        validations="isEmail"
                                        value={editData ? editData.owner_email : ''}
                                        validationError="This is not a valid email"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        alternate_email
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="dispatcher_name"
                                        label="Dispatcher Name"
                                        placeholder={'Dispatcher Name'}
                                        value={editData ? editData.dispatcher_name : ''}
                                        validations={{ minLength: 2 }}
                                        validationErrors={{ minLength: 'Min character length is 2' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        person
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="dispatcher_phone"
                                        label="Dispatcher Phone"
                                        placeholder={'Dispatcher Phone'}
                                        value={editData ? editData.dispatcher_phone : ''}
                                        validations={{ minLength: 10 }}
                                        validationErrors={{ minLength: 'Min character length is 10' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        contact_phone
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="dispatcher_email"
                                        label="Dispatcher Email"
                                        validations="isEmail"
                                        value={editData ? editData.dispatcher_email : ''}
                                        validationError="This is not a valid email"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        alternate_email
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="on_call_name"
                                        label="On Call Name"
                                        placeholder={'On Call Name'}
                                        value={editData ? editData.on_call_name : ''}
                                        validations={{ minLength: 2 }}
                                        validationErrors={{ minLength: 'Min character length is 2' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        person
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="on_call_phone"
                                        label="On Call Phone"
                                        placeholder={'On Call Phone'}
                                        value={editData ? editData.on_call_phone : ''}
                                        validations={{ minLength: 10 }}
                                        validationErrors={{ minLength: 'Min character length is 10' }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        contact_phone
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                    <TextFieldFormsy
                                        className="my-16"
                                        type="text"
                                        name="on_call_email"
                                        label="On Call Email"
                                        validations="isEmail"
                                        value={editData ? editData.on_call_email : ''}
                                        validationError="This is not a valid email"
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <i className="text-20 material-icons" color="action">
                                                        alternate_email
                                                    </i>
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                    />
                                </>
                            )}

                            <Button type="submit" variant="contained" color="primary" className="mx-auto my-16" aria-label={`${editData ? 'Edit' : 'Add'} ${localization.general.hauling_singular}`.toUpperCase()} disabled={!canSubmit}>
                                {`${editData ? 'Edit' : 'Add'} ${localization.general.hauling_singular}`}
                            </Button>
                        </Formsy>
                    </div>
                )}
            </LocalizationConsumer>
        );
    }
}

function mapStateToProps({ persisted }) {
    return { region: persisted.auth.user.data.region };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            addHauling: Actions.addHauling,
            editUser: Actions.editUser,
            showMessage: FuseActions.showMessage,
        },
        dispatch
    );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateHaulingForm));
