import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from '@material-ui/core';
import path from 'path';
import imageCompression from 'browser-image-compression';
import _ from 'lodash';
import { makeStyles } from '@material-ui/styles';

// Styling
const DropZoneContainer = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.5rem',
    margin: 0,
    width: '300x',
    height: '130px',
    border: '3px dashed #4aa1f3',
    borderRadius: '4px',
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: 'border-box',
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
};

const img = {
    display: 'block',
    width: 'auto',
    height: '100%',
};

const bundle = {
    display: 'flex',
    flexDirection: 'column',
};

const useStyles = makeStyles({
    message: {
        width: '80%',
        textAlign: 'center',
    },
});

function Dropzone(props) {
    const [files, setFiles] = useState([]);
    const classes = useStyles();

    useEffect(() => {
        if (props.value) {
            const defaultFile = {
                name: 'default',
                preview: props.value,
            };
            setFiles([defaultFile]);
        }
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*, application/pdf, .csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values, text/.xlsx, .xlsx',
        addRemoveLinks: true,
        multiple: props.multiple === undefined ? true : props.multiple,
        onDrop: (acceptedFiles) => {
            setFiles([
                ...files,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                ),
            ]);
        },
    });

    const removeFile = (file) => () => {
        const newFiles = [...files];
        newFiles.splice(file, 1);
        setFiles(newFiles);
    };

    const thumbs = files.map((file, index) => (
        <div style={bundle}>
            <div style={thumb} key={file.name}>
                <div style={thumbInner}>
                    <img src={file.preview} style={img} alt="add file" />
                </div>
            </div>
            <Button type="button" onClick={removeFile(index)}>
                Delete
            </Button>
        </div>
    ));

    useEffect(() => {
        // Make sure to revoke the data uris to avoid memory leaks
        if (files) {
            // files.forEach((file) => URL.revokeObjectURL(file.preview));

            const options = {
                maxSizeMB: 0.5,
                maxWidthOrHeight: 1920,
                useWebWorker: true,
            };

            // add logic to only compress and image type here
            const type = _.lowerCase(path.extname(files[0]?.name));
            files.forEach((file, index) => {
                if (!['csv', 'xlsx'].includes(type)) {
                    URL.revokeObjectURL(file.preview);
                    imageCompression(file, options)
                        .then(function (compressedFile) {
                            Object.assign(compressedFile, {
                                preview: URL.createObjectURL(compressedFile),
                            });
                            files[index] = compressedFile;
                        })
                        .catch(function (error) {
                            // eslint-disable-next-line
                            console.log(error.message);
                        });
                } else {
                    props.handleDrop(files);
                }
            });
        }
        props.handleDrop(files);
    }, [files]);

    return (
        <div>
            {files.length < (props.amount ?? 1) ? (
                <div style={DropZoneContainer} {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p className={classes.message}>{props.message || 'Drag and drop your slip here, or click to select files'}</p>
                </div>
            ) : (
                ' '
            )}
            <aside style={thumbsContainer}>{thumbs}</aside>
            {/* <aside>{deleteFiles}</aside> */}
        </div>
    );
}

export default Dropzone;
