import React from 'react';
import connect from 'react-redux/es/connect/connect';
import { withRouter } from 'react-router-dom';
/* eslint-disable-next-line */
import classNames from 'classnames';
import { FuseNavigation } from '@fuse';
import { mui5ThemeObject } from '../../../styles/mm-theme-fuse';

const Navigation = ({ layout, dense, className }) => (
    <FuseNavigation
        style={{ backgroundColor: mui5ThemeObject.palette.secondary.extraDark }}
        className={classNames('navigation', className)}
        layout={layout}
        dense={dense}
        //
    />
);

Navigation.defaultProps = {
    layout: 'vertical',
};

export default withRouter(Navigation);
