import * as repository from '../../../repository';

export const EDIT_USER = 'EDIT_USER';
export const EDIT_ROUTE = 'EDIT_ROUTE';
export const EDIT_ASSIGNEE = 'EDIT_ASSIGNEE';
export const EDIT_PAYMENT_STATUS = 'EDIT_PAYMENT_STATUS';
export const EDIT_ASSIGNMENT = 'EDIT_ASSIGNMENT';
export const EDIT_ASSIGNMENT_CONFIGURATION = 'EDIT_ASSIGNMENT_CONFIGURATION';
export const EDIT_RECURRING_ASSIGNMENT = 'EDIT_RECURRING_ASSIGNMENT';
export const EDIT_TRAILER = 'EDIT_TRAILER';
export const EDIT_TRUCK = 'EDIT_TRUCK';
export const EDIT_PICKUP = 'EDIT_PICKUP';
export const EDIT_DROPOFF = 'EDIT_DROPOFF';
export const EDIT_LAB_REPORT = 'EDIT_LAB_REPORT';
export const EDIT_SILO = 'EDIT_SILO';
export const EDIT_ROUTE_SESSION = 'EDIT_ROUTE_SESSION';
export const SET_EDIT_DETAILS = 'SET_EDIT_DETAILS';
export const EDIT_COMMENT = 'EDIT_COMMENT';
export const EDIT_BASE_TRANSFER = 'EDIT_BASE_TRANSFER';
export const EDIT_WASH = 'EDIT_WASH';
export const EDIT_WASH_FACILITY = 'EDIT_WASH_FACILITY';
export const EDIT_DEFERRAL = 'EDIT_DEFERRAL';
export const RESET_CUSTOM_TABLE_CONFIGURATION = 'RESET_CUSTOM_TABLE_CONFIGURATION';
export const EDIT_DROPOFF_SAMPLE = 'EDIT_DROPOFF_SAMPLE';
export const EDIT_SPLIT_PRODUCER = 'EDIT_SPLIT_PRODUCER';
export const EDIT_HAULING_CONTRACT = 'EDIT_HAULING_CONTRACT';

export function setEditReducer(model) {
    return (dispatch) => {
        dispatch({ type: SET_EDIT_DETAILS, payload: model });
    };
}

export function editUser(model, key, isExpressRequired = false) {
    return (dispatch) => repository.editUser(model, key, isExpressRequired).then((payload) => dispatch({ type: EDIT_USER, payload }));
}

export function editBaseTransfer(model, key) {
    return (dispatch) => repository.editBaseTransfer(model, key).then((payload) => dispatch({ type: EDIT_BASE_TRANSFER, payload }));
}

export function editWash(model, key) {
    return (dispatch) => repository.editWash(model, key).then((payload) => dispatch({ type: EDIT_WASH, payload }));
}

export function editWashFacility(model, key) {
    return (dispatch) => repository.editWashFacility(model, key).then((payload) => dispatch({ type: EDIT_WASH_FACILITY, payload }));
}

export function editRoute(model, key) {
    return (dispatch) => repository.editRoute(model, key).then((payload) => dispatch({ type: EDIT_ROUTE, payload }));
}

export function editPaymentStatus(model, key) {
    return (dispatch) => repository.editPaymentStatus(model, key).then((payload) => dispatch({ type: EDIT_PAYMENT_STATUS, payload }));
}

export function editAssignee(model, key) {
    return (dispatch) => repository.editAssignee(model, key).then((payload) => dispatch({ type: EDIT_ASSIGNEE, payload }));
}

export function editAssignment(model, key) {
    return (dispatch) => repository.editAssignment(model, key).then((payload) => dispatch({ type: EDIT_ASSIGNMENT, payload }));
}

export function editAssignmentConfiguration(model, key) {
    return (dispatch) => repository.editAssignmentConfiguration(model, key).then((payload) => dispatch({ type: EDIT_ASSIGNMENT_CONFIGURATION, payload }));
}

export function editHaulerAssignment(model, key) {
    return (dispatch) => repository.editHaulerAssignment(model, key).then((payload) => dispatch({ type: EDIT_ASSIGNMENT, payload }));
}

export function editRecurringAssignment(model, key) {
    return (dispatch) => repository.editRecurringAssignment(model, key).then((payload) => dispatch({ type: EDIT_RECURRING_ASSIGNMENT, payload }));
}

export function editDeferral(model, key) {
    return (dispatch) => repository.editDeferral(model, key).then((payload) => dispatch({ type: EDIT_DEFERRAL, payload }));
}

export function editDropoffSample(model, key) {
    return (dispatch) => repository.editDropoffSample(model, key).then((payload) => dispatch({ type: EDIT_DROPOFF_SAMPLE, payload }));
}

export function editTrailer(model, key) {
    return (dispatch) => repository.editTrailer(model, key).then((payload) => dispatch({ type: EDIT_TRAILER, payload }));
}

export function editTruck(model, key) {
    return (dispatch) => repository.editTruck(model, key).then((payload) => dispatch({ type: EDIT_TRUCK, payload }));
}

export function editSilo(model, key) {
    return (dispatch) => repository.editSilo(model, key).then((payload) => dispatch({ type: EDIT_SILO, payload }));
}

export function editPickup(model, key) {
    return (dispatch) => repository.editPickup(model, key).then((payload) => dispatch({ type: EDIT_PICKUP, payload }));
}

export function editDropoff(model, key) {
    return (dispatch) => repository.editDropoff(model, key).then((payload) => dispatch({ type: EDIT_DROPOFF, payload }));
}

export function editRouteSession(model, key) {
    return (dispatch) => repository.editRouteSession(model, key).then((payload) => dispatch({ type: EDIT_ROUTE_SESSION, payload }));
}

export function editLabReport(model, key) {
    return (dispatch) => repository.editLabReport(model, key).then((payload) => dispatch({ type: EDIT_LAB_REPORT, payload }));
}

export function editComment(model, key) {
    return (dispatch) => repository.editComment(model, key).then((payload) => dispatch({ type: EDIT_COMMENT, payload }));
}

export function resetCustomTableConfiguration(tableName, mode) {
    return (dispatch) => repository.resetCustomTableConfiguration({ tableName, mode }).then((payload) => dispatch({ type: RESET_CUSTOM_TABLE_CONFIGURATION, payload }));
}

export function editSplitProducer(model, key) {
    return (dispatch) => repository.editSplitProducer(model, key).then((payload) => dispatch({ type: EDIT_SPLIT_PRODUCER, payload }));
}

export function editHaulingContract(model, key) {
    return (dispatch) => repository.editHaulingContract(model, key).then((payload) => dispatch({ type: EDIT_HAULING_CONTRACT, payload }));
}
