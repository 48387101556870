import axios from 'axios';
import { addUsersToSharedFileQuery } from './queries';

const addUsersToSharedFile = async (fileName, usersToAdd) => {
    const body = {
        generated_name: fileName,
        sentTo_ids: usersToAdd.map((user) => user.id),
    };

    const query = addUsersToSharedFileQuery();
    const resp = await axios.patch(query, body);

    return resp.data;
};

export default addUsersToSharedFile;
