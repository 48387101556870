export const IN_PROGRESS_DROPOFF_STATUS = {
    AWAITING_SCALE_IN: 'awaiting_scale_in',
    AWAITING_TEST_RESULT: 'awaiting_test_result',
    TEST_IN_PROGRESS: 'test_in_progress',
    TEST_IN_QA: 'test_in_qa',
    TEST_FAILED: 'test_failed',
    AWAITING_PUMP_OFF: 'awaiting_pump_off',
    PUMP_OFF_IN_PROGRESS: 'pump_off_in_progress',
    AWAITING_SCALE_OUT: 'awaiting_scale_out',
    COMPLETE: 'complete',
    // Prettier No Prettying
};

export const getIPDOStatusString = (status) => {
    if (status === IN_PROGRESS_DROPOFF_STATUS.AWAITING_SCALE_IN) {
        return 'Arrival';
    }

    if (status === IN_PROGRESS_DROPOFF_STATUS.AWAITING_SCALE_OUT) {
        return 'Departure';
    }

    const formatString = (str) => {
        return str
            .toLowerCase()
            .split('_')
            .map((word) => {
                if (word === 'qa') {
                    return 'QA';
                }
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(' ');
    };

    const statusKey = Object.keys(IN_PROGRESS_DROPOFF_STATUS).find((key) => IN_PROGRESS_DROPOFF_STATUS[key] === status);
    return statusKey ? formatString(statusKey) : 'Unknown';
};
