import editSiloInvoicesPermission from './editSiloInvoicesPermission';

export const getUserPermissions = (permissionType) => {
    const permissions = [];

    permissions.push(...editSiloInvoicesPermission(permissionType));

    const userPermissions = [{ label: 'Special', permissions }];
    return permissions.length ? userPermissions : [];
};

export default getUserPermissions;
