import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import Item from './Item';

import { makeStyles } from '@material-ui/core/styles';

const grid = 8;

const useStyles = makeStyles(() => ({
    root: {
        background: 'white',
        padding: grid,
        width: 300,
        boxSizing: 'border-box',
        overflowY: 'scroll',
        position: 'relative',
        height: '100%',
    },
}));

function Container({ items }) {
    const classes = useStyles();
    return (
        <Droppable droppableId="droppable">
            {(provided) => (
                <div className={classes.root} {...provided.droppableProps} ref={provided.innerRef}>
                    {items.map((item, index) => (
                        <Item item={item} index={index} />
                    ))}
                </div>
            )}
        </Droppable>
    );
}

export default Container;
