import axios from 'axios';
import { resolveId } from '../../utils';
import { editUserGroupQuery } from './queries';

const editUserGroup = async (model, key) => {
    try {
        const query = editUserGroupQuery({ key, model });
        const request = axios.put(query.url, { ...query.body });
        const { data } = await request;
        return resolveId(data);
    } catch {
        throw new Error('Unable to edit schedule user group');
    }
};

export default editUserGroup;
