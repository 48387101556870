import axios from 'axios';
import { getAPIHost, getExpressAPIHost } from '../../utils';

const deleteRecord = async (endpoint, key, useExpress) => {
    try {
        const getHost = useExpress ? getExpressAPIHost : getAPIHost;
        const request = axios.delete(`${getHost() + endpoint}/${key}`);
        const { data } = await request;
        return data;
    } catch (err) {
        throw new Error('Unable to delete record');
    }
};

export default deleteRecord;
