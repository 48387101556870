import axios from 'axios';
import { getHaulingContractByDateRangeQuery } from './queries';
import moment from 'moment-timezone';

export const getProducerHaulingContractListDetails = async (haulingId, producerId, startDate, endDate) => {
    if (!startDate || !endDate) {
        return { haulingContracts: [] };
    }
    try {
        const start = moment.isMoment(startDate) ? startDate.toDate() : startDate;
        const end = moment.isMoment(endDate) ? endDate.toDate() : endDate;

        const producerHaulingContractQuery = getHaulingContractByDateRangeQuery(
            {
                fields: ['hauler', 'producer', 'hauling_rate_details', 'start_date', 'end_date', 'pay_report', 'hauler_rates', 'contract_type', 'contract_max_charge_per_period', 'contract_name', 'paid_by_producer', 'exclude_pickups_before_date', 'hauling_contract_categoryF'],
            },
            start,
            end,
            haulingId,
            producerId
        );
        const producerHaulingContractResponse = await axios.get(producerHaulingContractQuery);

        return { haulingContracts: producerHaulingContractResponse.data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export const getSupplementalHaulingContractListDetails = async (haulingId, producerId, startDate, endDate) => {
    if (!startDate || !endDate) {
        return { haulingContracts: [] };
    }
    try {
        const start = moment.isMoment(startDate) ? startDate.toDate() : startDate;
        const end = moment.isMoment(endDate) ? endDate.toDate() : endDate;

        const supplementalHaulingContractQuery = getHaulingContractByDateRangeQuery(
            {
                fields: ['hauler', 'processor', 'route_id', 'producers_on_route', 'hauling_rate_details', 'start_date', 'end_date', 'pay_report', 'hauler_rates', 'contract_type', 'contract_name', 'paid_by_producer', 'exclude_pickups_before_date', 'hauling_contract_category'],
            },
            start,
            end,
            haulingId,
            producerId,
            true
        );
        const supplementalHaulingContractResponse = await axios.get(supplementalHaulingContractQuery);

        return { haulingContracts: supplementalHaulingContractResponse.data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};
