import axios from 'axios';
import { getProducersQuery, getRouteSessionsQuery, getDriversQuery, getTrailersQuery, getProcessorsQuery } from './queries';
import { getAPIHost, numberFormat } from '../../utils';

const getSingleInstance = (type, id) =>
    new Promise((resolve, reject) => {
        const request = axios.get(`${getAPIHost()}/${type}/${id}`);

        request
            .then((response) => {
                if (type === 'lab-reports') {
                    const subQuery = getProducersQuery({ ids: [response.data.producer_id] });
                    const subRequest = axios.get(subQuery);
                    return subRequest.then((subResponse) => {
                        const data = { ...response.data, producer: subResponse.data[0].name };
                        resolve({ labReport: [data] });
                    });
                }
                if (type === 'route-sessions') {
                    resolve({ routeSession: [response.data] });
                }
                if (type === 'pickups') {
                    const producerSubQuery = getProducersQuery({ ids: [response.data.producer_id] });
                    const producerSubRequest = axios.get(producerSubQuery);
                    const driverSubQuery = getDriversQuery({ ids: [response.data.driver_id] });
                    const driverSubRequest = axios.get(driverSubQuery);
                    const sessionQuery = getRouteSessionsQuery({ ids: [response.data.route_session_id], filterDeleted: true });
                    const sessionSubRequest = axios.get(sessionQuery);
                    return sessionSubRequest.then((sessionSubResponse) => {
                        const trailerQuery = getTrailersQuery({ trailerId: sessionSubResponse.data[0].trailer_id });
                        const trailerSubRequest = axios.get(`${trailerQuery}`);
                        return trailerSubRequest.then((trailerSubResponse) => {
                            const subRequests = [producerSubRequest, driverSubRequest];
                            return axios.all(subRequests).then(
                                axios.spread((...responses) => {
                                    const [producerResponse, driverResponse] = responses;
                                    const data = {
                                        ...response.data,
                                        producer: producerResponse.data,
                                        driver: driverResponse.data,
                                        trailer: numberFormat(parseFloat(trailerSubResponse.data[0].trailer_number), 0),
                                    };
                                    resolve({ pickup: [data] });
                                })
                            );
                        });
                    });
                }
                if (type === 'dropoffs') {
                    const processorSubQuery = getProcessorsQuery({ ids: [response.data.processor_id] });
                    const processorSubRequest = axios.get(processorSubQuery);
                    const driverSubQuery = getDriversQuery({ ids: [response.data.driver_id] });
                    const driverSubRequest = axios.get(`${driverSubQuery}`);
                    const sessionRequest = getRouteSessionsQuery({ ids: [response.data.route_session_id], filterDeleted: true });
                    const sessionSubRequest = axios.get(`${sessionRequest}`);
                    return sessionSubRequest.then((sessionSubResponse) => {
                        const trailerQuery = getTrailersQuery({ id: sessionSubResponse.data[0].trailer_id });
                        const trailerSubRequest = axios.get(`${trailerQuery}`);
                        return trailerSubRequest.then((trailerSubResponse) => {
                            const subRequests = [processorSubRequest, driverSubRequest];
                            return axios.all(subRequests).then(
                                axios.spread((...responses) => {
                                    const [processorResponse, driverResponse] = responses;
                                    const data = {
                                        ...response.data,
                                        processor: processorResponse.data,
                                        driver: driverResponse.data,
                                        trailer: numberFormat(parseFloat(trailerSubResponse.data[0].trailer_number), 0),
                                        bol: sessionSubResponse.data[0].BOL,
                                    };
                                    resolve({ dropoff: [data] });
                                })
                            );
                        });
                    });
                }
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.warn(errors);
                reject(new Error(errors.toString()));
            });
    });

export default getSingleInstance;
