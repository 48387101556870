import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import * as FuseActions from 'app/store/actions';
import { bindActionCreators } from 'redux';
import { DialogContentText, Radio } from '@material-ui/core';
import { Link, Stack } from '@mui/material';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import moment from 'moment-timezone';
import { fetchOptions } from '../../configured-components/form/configured-inputs/queries';
import editPickup from '../../repository/editPickup';

function PaperComponent(props) {
    return <Paper {...props} />;
}

class MatchLab extends React.Component {
    state = {
        dialogOpened: false,
        probableLabs: [],
        selectedLab: null,
        loading: false,
    };

    fetchlabs = async () => {
        this.setState({ loading: true });
        const { data } = this.props;
        const { created_at, producer_id, tank } = data;

        const where = {
            date: { between: [moment(created_at).subtract(5, 'days'), moment(created_at).add(5, 'days')] },
            pickup_id: 'null',
            producer_id,
            tank,
        };

        const populate = {
            producer_id: ['full_name', 'license_number'],
        };

        const optionsResponse = await fetchOptions({ endpoint: '/lab-reports/unmatched', where, populate });

        this.setState({ probableLabs: optionsResponse, loading: false });
    };

    handleClickOpen = (type) => {
        this.setState({ dialogOpened: true });
        this.fetchlabs();
    };

    handleClose = () => {
        this.setState({ dialogOpened: false });
    };

    selectLab = (lab) => {
        this.setState({ selectedLab: lab });
    };

    matchLab = async () => {
        this.setState({ loading: true });
        const { id } = this.props.data;
        const { forceFetch } = this.props;

        try {
            await editPickup({ lab_report_ids: [this.state.selectedLab._id] }, id);
            this.props.showMessage({ message: 'Lab matched successfully', variant: 'success' });
        } catch (error) {
            // console.log(error);
            this.props.showMessage({ message: 'Failed to match lab', variant: 'error' });
        } finally {
            forceFetch();
            this.handleClose();
        }
    };

    loadTable = () => {
        if (!this.state.probableLabs.length) return <div>No probable labs found</div>;

        return (
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell>Producer Name</TableCell>
                        <TableCell>Producer #</TableCell>
                        <TableCell>Tank #</TableCell>
                        <TableCell>Barcodes</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.probableLabs?.map((lab) => (
                        <TableRow>
                            <TableCell>
                                <Radio checked={this.state.selectedLab === lab} onChange={() => this.selectLab(lab)} />
                            </TableCell>
                            <TableCell>{moment(lab?.date).format('MMM D, YYYY')}</TableCell>
                            <TableCell>{lab?.producer_id?.full_name}</TableCell>
                            <TableCell>{lab?.producer_id?.license_number}</TableCell>
                            <TableCell>{lab?.tank}</TableCell>
                            <TableCell>{lab?.sample_barcodes?.join(', ')}</TableCell>
                            <TableCell>
                                <Link href={`#/inspect-lab-report/${lab?._id}`} target="_blank" rel="noopener">
                                    View Lab Details
                                </Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    };

    getEditLabUrl = () => {
        const { producer_id, id: pickup_id, tank, volume } = this.props?.data;
        return `#/create-lab-report?producer_id=${producer_id}&pickup_id=${pickup_id}&tank=${tank}&volume=${volume}`;
    };

    render() {
        return (
            <div
                style={{
                    margin: 'auto',
                    width: 'fit-content',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <div>
                    {!this.props?.data?.lab_report_ids?.length && (
                        <Button variant="contained" color="primary" onClick={this.handleClickOpen} className="my-16" aria-label="Match Lab Results" disabled={this.props.disabled}>
                            Match Lab Results
                        </Button>
                    )}
                    <Dialog open={this.state.dialogOpened} onClose={this.handleClose} PaperComponent={PaperComponent} fullWidth maxWidth="md" aria-labelledby="draggable-dialog-title" scroll="body">
                        <DialogTitle id="draggable-dialog-title">
                            Probable Labs - Invoice {this.props?.data?.lt_number} - Barcode: {this.props.data.sample_barcodes.join(', ')}
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>{this.loadTable()}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Stack direction="row" spacing={2} justifyContent="space-between" sx={{ width: '100%' }}>
                                {/* eslint-disable-next-line no-return-assign */}
                                <Button onClick={() => (window.location.href = this.getEditLabUrl())} color="secondary" variant="outlined">
                                    Add New Lab
                                </Button>
                                <Stack direction="row" spacing={2} justifyContent="flex-end">
                                    <Button onClick={this.handleClose} color="primary" variant="outlined">
                                        Cancel
                                    </Button>
                                    <Button onClick={this.matchLab} color="secondary" variant="contained" disabled={!this.state.selectedLab || this.state.loading}>
                                        {this.state.loading ? 'Loading...' : 'Match Lab'}
                                    </Button>
                                </Stack>
                            </Stack>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: FuseActions.showMessage }, dispatch);
}

export default connect(null, mapDispatchToProps)(MatchLab);
