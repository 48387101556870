import axios from 'axios';
import { getDropoffSampleQuery, getRouteSessionsQuery, getProcessorsQuery, getHaulingCompaniesQuery } from './queries';
import _ from 'lodash';
import { getBulkQueryData } from './utils';
import { arrayToMap, renameKey } from '../../utils';

const getDropoffSampleDetails = async (start, end, id) => {
    try {
        const { data: dropoffSamples } = await axios.get(
            getDropoffSampleQuery({
                fields: ['bacto_scan', 'date', 'dropoff_id', 'fat', 'freeze_point', 'is_locked', 'lactose', 'milk_urea_nitrogen', 'other_solids', 'preliminary_incubation', 'processor_id', 'processor', 'protein', 'sample_barcodes', 'solids_not_fat', 'somatic_cell_count', 'standard_plate_count', 'total_solids'],
                start,
                end,
                processorId: id,
                filterDeleted: true,
                populate: { dropoff_id: ['route_session_id'] },
            })
        );

        const routeSessionIds = _.uniq(_.map(dropoffSamples, 'dropoff_id.route_session_id'));
        const routeSessions = await getBulkQueryData(getRouteSessionsQuery, { fields: ['BOL', 'id', 'hauling_id'], ids: routeSessionIds, filterDeleted: true }, 'ids');
        const routeSessionMap = arrayToMap(routeSessions, 'id');

        const processorIds = _.uniq(_.map(dropoffSamples, 'processor_id'));
        const processors = await getBulkQueryData(getProcessorsQuery, { fields: ['name', 'id'], ids: processorIds, filterDeleted: true }, 'ids');
        const processorMap = arrayToMap(processors, 'id');

        const haulerIds = _.uniq(_.map(routeSessions, 'hauling_id'));
        const haulers = await getBulkQueryData(getHaulingCompaniesQuery, { fields: ['name', 'id'], ids: haulerIds, filterDeleted: true }, 'ids');
        const haulerMap = arrayToMap(haulers, 'id');

        const dropoffSampleData = dropoffSamples.map((value) => {
            return {
                ...value,
                BOL: routeSessionMap?.[value?.dropoff_id?.route_session_id]?.BOL,
                processor: processorMap?.[value?.processor_id],
                hauler: haulerMap?.[routeSessionMap?.[value?.dropoff_id?.route_session_id]?.hauling_id]?.name,
            };
        });

        return { dropoffSamples: dropoffSampleData };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getDropoffSampleDetails;
