import * as Actions from '../../actions/fuse/index';
import configuredNavigation from '../../../main/apps/ConfiguredApps/navigation';

const initialState = configuredNavigation;

const navigation = function (state = initialState, action) {
    switch (action.type) {
        case Actions.GET_NAVIGATION: {
            return [...state];
        }
        case Actions.SET_NAVIGATION: {
            return [...action.navigation];
        }
        case Actions.RESET_NAVIGATION: {
            return [...initialState];
        }
        default: {
            return state;
        }
    }
};

export default navigation;
