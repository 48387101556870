import axios from 'axios';
import store from 'app/store';
import { getAPIHost } from '../../../../utils';
import * as repository from '../../../repository';

export const GET_SHARED_UNREAD_COUNT = 'GET_SHARED_UNREAD_COUNT';
export const GET_LEASE_PENDING_COUNT = 'GET_LEASE_PENDING_COUNT';
export const GET_MONTHLY_REPORTS_UNREAD_COUNT = 'GET_MONTHLY_REPORTS_UNREAD_COUNT';
export const GET_NUM_OF_UNREAD_NOTIFICATIONS = 'GET_NUM_OF_UNREAD_NOTIFICATIONS';

export function getPendingLeaseCount() {
    const request = axios.post(`${getAPIHost()}/quota-leases/pendingCount`);

    return (dispatch) => request.then((response) => dispatch({ type: GET_LEASE_PENDING_COUNT, payload: response.data }));
}

export function getSharedUnreadCount() {
    const { id } = store.getState().persisted.auth.user.data;
    return (dispatch) =>
        repository
            .getSharedUnreadCount(id)
            .then((payload) => dispatch({ type: GET_SHARED_UNREAD_COUNT, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getMonthlyReportsUnreadCount() {
    const { id } = store.getState().persisted.auth.user.data;
    return (dispatch) =>
        repository
            .getMonthlyReportsUnreadCount(id)
            .then((payload) => dispatch({ type: GET_MONTHLY_REPORTS_UNREAD_COUNT, payload }))
            .catch((error) => {
                throw error;
            });
}

export function getNumOfUnreadNotifications() {
    const { id } = store.getState().persisted.auth.user.data;
    return (dispatch) =>
        repository
            .getNumOfUnreadNotifications(id)
            .then((payload) => dispatch({ type: GET_NUM_OF_UNREAD_NOTIFICATIONS, payload }))
            .catch((error) => {
                throw error;
            });
}
