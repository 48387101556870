import React from 'react';
import QuickPanel from 'app/fuse-layouts/shared-components/quickPanel/QuickPanel';

const RightSideLayout = (props) => (
    /* eslint-disable-next-line react/jsx-fragments */
    <React.Fragment>
        <QuickPanel panelProps={props} />
    </React.Fragment>
);

export default RightSideLayout;
