import { AbilityBuilder, PureAbility } from '@casl/ability';
import Region from '../../../../constants/region';

function MilkAdjustmentsPermissions(user) {
    const { can, rules } = new AbilityBuilder(PureAbility);
    const {
        role,
        data: { region },
    } = user;

    if (role === 'admin') {
        can('mutate', 'milk_adjustment');
    }

    if ([Region.MMPA, Region.TCJ.LIBERTY, Region.DEMO].includes(region)) {
        can('view', 'milk_adjustment');
    }

    return rules;
}

export default MilkAdjustmentsPermissions;
