const localization = {
    general: {
        component_barcode: 'Component Barcode Value',
        daily_fluid_milk_quota: 'Daily Fluid Milk Quota',
        hauling_plural: 'Hauling Companies',
        hauling_singular: 'Hauling Company',
        hauling_delivery: 'Delivery Hauler',
        full_name: 'Contact Name',
        license_number: 'License Number',
        metered: 'Metered',
        meteredLevel: 'Metered Level',
        monthlyReports: 'Monthly Reports',
        processor_singular: 'Processor',
        processor_plural: 'Processors',
        producer_weight: 'Producer Weight',
        routeNumber: 'Route Number',
        routeSession: 'Route Session',
        tank: 'Tank',
        trailer: 'Trailer',
        volume: 'Volume',
    },
    navigation: {},
    lab: {
        scc: 'scc',
        sample_date: 'Date',
        temperature: 'Temperature',
    },
    other: {},
};

export default localization;
