import React from 'react';
import moment from 'moment-timezone';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { getLabBoxComponentTextColor } from '../../utils';
import { TCJ_REGIONS } from '../constants/bulkRegion';

function LabAveragesBox({ month, year, region, fat, protein, bacto, scc, bmcc, thermo, temp, lactose, solidsNotFat, otherSolids, standardPlateCount, euSCCAverage }) {
    const date = moment([parseInt(year), parseInt(month) - 1]).endOf('month');

    return (
        <Card className="w-full rounded-8 shadow-none border-1 h-full">
            <div className="flex items-center justify-between px-16 py-16">
                <Typography className="text-20 font-bold">Lab Averages</Typography>
            </div>
            <div className="flex flex-row items-center justify-between">
                <Typography className="h3 px-16 text-center items-center" color="inherit">
                    {moment(date).format('MMMM YYYY')}
                </Typography>
            </div>
            <div className="flex flex-row justify-center items-center py-16" style={{ paddingLeft: 10 }}>
                <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                    <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                        FAT
                    </Typography>
                    <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{fat ? fat.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%</Typography>
                </div>
                <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                    <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                        PROTEIN
                    </Typography>
                    <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{protein ? protein.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%</Typography>
                </div>
            </div>
            {['NL', 'PEI', 'RF'].includes(region) && (
                <div className="flex flex-row justify-center items-center py-16" style={{ paddingLeft: 10 }}>
                    {['NL', 'PEI'].includes(region) && (
                        <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                            <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                                SCC
                            </Typography>
                            <Typography className={`h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold ${getLabBoxComponentTextColor('scc', scc, region)}`}>{scc ? scc.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}</Typography>
                        </div>
                    )}
                    {region === 'RF' && (
                        <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                            <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                                BMCC
                            </Typography>
                            <Typography className={`h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold ${getLabBoxComponentTextColor('bmcc', bmcc, region)}`}>{bmcc ? bmcc.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}</Typography>
                        </div>
                    )}
                    {['NL', 'PEI', 'RF'].includes(region) && (
                        <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center">
                            <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                                BACTO
                            </Typography>
                            <Typography className={`h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold ${getLabBoxComponentTextColor('bacto', bacto, region)}`}>{bacto ? bacto.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}</Typography>
                        </div>
                    )}
                </div>
            )}
            {region === 'RF' && (
                <div className="flex flex-row justify-center items-center py-16" style={{ paddingLeft: 10 }}>
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                            THERMO
                        </Typography>
                        <Typography className={`h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold ${getLabBoxComponentTextColor('thermo', thermo, region)}`}>{thermo ? thermo.toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}</Typography>
                    </div>
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                            TEMP(°C)
                        </Typography>
                        <Typography className={`h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold ${getLabBoxComponentTextColor('temp', temp, region)}`}>{temp ? temp.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}</Typography>
                    </div>
                </div>
            )}
            {!['NL', 'PEI', 'RF'].includes(region) && (
                <div className="flex flex-row justify-center items-center py-16" style={{ paddingLeft: 10 }}>
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                            LACTOSE
                        </Typography>
                        <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{lactose ? lactose.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%</Typography>
                    </div>
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                            SCC
                        </Typography>
                        {['CDI', 'PRAIRIE', 'LEGACY'].includes(region) && <Typography className={`h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold ${getLabBoxComponentTextColor('scc', scc, region)}`}>{scc ? scc.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}</Typography>}
                        {['MMPA'].includes(region) && <Typography className={'h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold'}>{scc ? scc.toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}</Typography>}
                        {[...TCJ_REGIONS].includes(region) && <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{scc ? scc.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%</Typography>}
                        {['UDA'].includes(region) && <Typography className={`h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-bold ${getLabBoxComponentTextColor('scc', scc, region)}`}>{scc ? Math.floor(scc).toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}</Typography>}
                        {/* default for new customers */}
                        {!['CDI', 'PRAIRIE', 'LEGACY', 'MMPA', ...TCJ_REGIONS, 'UDA'].includes(region) && <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{scc ? scc.toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}</Typography>}
                    </div>
                </div>
            )}
            {['CDI', 'UDA', 'BORDEN', 'PRAIRIE', 'LEGACY', 'MMPA'].includes(region) && (
                <div className="flex flex-row justify-center items-center py-16" style={{ paddingLeft: 10 }}>
                    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary">
                            SNF
                        </Typography>
                        <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{solidsNotFat ? solidsNotFat.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%</Typography>
                    </div>
                    {['CDI', 'BORDEN', 'LEGACY'].includes(region) && (
                        <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                            <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary" style={{ whitespace: 'nowrap' }}>
                                OTHER SOLIDS
                            </Typography>
                            <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{solidsNotFat && protein ? (solidsNotFat - protein).toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%</Typography>
                        </div>
                    )}
                    {['PRAIRIE', 'MMPA'].includes(region) && (
                        <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                            <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary" style={{ whitespace: 'nowrap' }}>
                                OTHER SOLIDS
                            </Typography>
                            <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{otherSolids ? otherSolids.toLocaleString('us', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '-'}%</Typography>
                        </div>
                    )}
                    {/* {['MMPA'].includes(region) && ( */}
                    {/*    <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto"> */}
                    {/*        <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary" style={{ whitespace: 'nowrap' }}> */}
                    {/*            EU SCC Average */}
                    {/*        </Typography> */}
                    {/*        <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{euSCCAverage ? euSCCAverage.toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}</Typography> */}
                    {/*    </div> */}
                    {/* )} */}
                    {region === 'UDA' && (
                        <div className="w-full px-4 flex flex-col flex-shrink flex-grow justify-center my-auto">
                            <Typography className="h2 sm:h3 xs:h3 text-left w-1/2" color="textSecondary" style={{ whitespace: 'nowrap' }}>
                                SPC
                            </Typography>
                            <Typography className="h2 sm:h3 xs:h3 font-500 text-left w-1/2 text-blue-light text-bold">{standardPlateCount ? parseFloat(standardPlateCount).toLocaleString('us', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) : '-'}</Typography>
                        </div>
                    )}
                </div>
            )}
        </Card>
    );
}

export default React.memo(LabAveragesBox);
