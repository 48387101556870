import axios from 'axios';
import { getRoutesQuery } from './queries';

const getRoute = async (params) => {
    try {
        const routeQuery = getRoutesQuery(params);

        const routeResp = await axios.get(routeQuery);
        return routeResp.data;
    } catch (err) {
        return { err };
    }
};

export default getRoute;
