import axios from 'axios';
import { getHaulingChargeQuery } from './queries';
import HaulingContractType from 'app/constants/hauling';

const getHaulingChargeListDetails = async (start, end, haulingId, producerId) => {
    try {
        const haulingChargeQuery = getHaulingChargeQuery({
            fields: ['producer', 'hauler', 'processor', 'producers_on_route', 'route_id', 'date', 'hauling_rate_details', 'monetary_value', 'approved_for_hauler', 'approved_for_producer', 'amount_paid', 'outstanding_balance', 'partner_total', 'contract_type', 'milk_pounds', 'contract_name', 'pay_report', 'hauling_contract_category'],
            start,
            end,
            filterDeleted: true,
            haulingId,
            producerId,
        });
        const haulingChargeResponse = await axios.get(haulingChargeQuery);

        return {
            producerHaulingCharges: haulingChargeResponse?.data?.filter(({ contract_type }) => contract_type == null || contract_type === HaulingContractType.ProducerHaulerContract),
            supplementalHaulingCharges: haulingChargeResponse?.data?.filter(({ contract_type }) => contract_type === HaulingContractType.HaulerProcessorRouteContract),
        };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getHaulingChargeListDetails;
