import { AbilityBuilder, PureAbility } from '@casl/ability';

function ScheduleItems(user) {
    const { can, rules } = new AbilityBuilder(PureAbility);
    const {
        role,
        data: { region },
    } = user;

    if (role === 'admin') {
        can('mutate', 'schedule_item');
        can('upload', 'raw_milk_csv');
        can('upload', 'processed_fluid_csv');
        if (region === 'UDA') {
            // Only UDA admins have the ability to refresh fairlife numbers
            can('refresh', 'fairlife_number');
        }
    }
    if (role === 'transport') {
        if (region === 'CDI') can('upload', 'raw_milk_csv');
    }

    return rules;
}

export default ScheduleItems;
