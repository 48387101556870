const countyData = {
    AL: [
        { name: 'AUTAUGA', fips: '01001', id: '01001 - AUTAUGA' },
        { name: 'BALDWIN', fips: '01003', id: '01003 - BALDWIN' },
        { name: 'BARBOUR', fips: '01005', id: '01005 - BARBOUR' },
        { name: 'BIBB', fips: '01007', id: '01007 - BIBB' },
        { name: 'BLOUNT', fips: '01009', id: '01009 - BLOUNT' },
        { name: 'BULLOCK', fips: '01011', id: '01011 - BULLOCK' },
        { name: 'BUTLER', fips: '01013', id: '01013 - BUTLER' },
        { name: 'CALHOUN', fips: '01015', id: '01015 - CALHOUN' },
        { name: 'CHAMBERS', fips: '01017', id: '01017 - CHAMBERS' },
        { name: 'CHEROKEE', fips: '01019', id: '01019 - CHEROKEE' },
        { name: 'CHILTON', fips: '01021', id: '01021 - CHILTON' },
        { name: 'CHOCTAW', fips: '01023', id: '01023 - CHOCTAW' },
        { name: 'CLARKE', fips: '01025', id: '01025 - CLARKE' },
        { name: 'CLAY', fips: '01027', id: '01027 - CLAY' },
        { name: 'CLEBURNE', fips: '01029', id: '01029 - CLEBURNE' },
        { name: 'COFFEE', fips: '01031', id: '01031 - COFFEE' },
        { name: 'COLBERT', fips: '01033', id: '01033 - COLBERT' },
        { name: 'CONECUH', fips: '01035', id: '01035 - CONECUH' },
        { name: 'COOSA', fips: '01037', id: '01037 - COOSA' },
        { name: 'COVINGTON', fips: '01039', id: '01039 - COVINGTON' },
        { name: 'CRENSHAW', fips: '01041', id: '01041 - CRENSHAW' },
        { name: 'CULLMAN', fips: '01043', id: '01043 - CULLMAN' },
        { name: 'DALE', fips: '01045', id: '01045 - DALE' },
        { name: 'DALLAS', fips: '01047', id: '01047 - DALLAS' },
        { name: 'DE KALB', fips: '01049', id: '01049 - DE KALB' },
        { name: 'ELMORE', fips: '01051', id: '01051 - ELMORE' },
        { name: 'ESCAMBIA', fips: '01053', id: '01053 - ESCAMBIA' },
        { name: 'ETOWAH', fips: '01055', id: '01055 - ETOWAH' },
        { name: 'FAYETTE', fips: '01057', id: '01057 - FAYETTE' },
        { name: 'FRANKLIN', fips: '01059', id: '01059 - FRANKLIN' },
        { name: 'GENEVA', fips: '01061', id: '01061 - GENEVA' },
        { name: 'GREENE', fips: '01063', id: '01063 - GREENE' },
        { name: 'HALE', fips: '01065', id: '01065 - HALE' },
        { name: 'HENRY', fips: '01067', id: '01067 - HENRY' },
        { name: 'HOUSTON', fips: '01069', id: '01069 - HOUSTON' },
        { name: 'JACKSON', fips: '01071', id: '01071 - JACKSON' },
        { name: 'JEFFERSON', fips: '01073', id: '01073 - JEFFERSON' },
        { name: 'LAMAR', fips: '01075', id: '01075 - LAMAR' },
        { name: 'LAUDERDALE', fips: '01077', id: '01077 - LAUDERDALE' },
        { name: 'LAWRENCE', fips: '01079', id: '01079 - LAWRENCE' },
        { name: 'LEE', fips: '01081', id: '01081 - LEE' },
        { name: 'LIMESTONE', fips: '01083', id: '01083 - LIMESTONE' },
        { name: 'LOWNDES', fips: '01085', id: '01085 - LOWNDES' },
        { name: 'MACON', fips: '01087', id: '01087 - MACON' },
        { name: 'MADISON', fips: '01089', id: '01089 - MADISON' },
        { name: 'MARENGO', fips: '01091', id: '01091 - MARENGO' },
        { name: 'MARION', fips: '01093', id: '01093 - MARION' },
        { name: 'MARSHALL', fips: '01095', id: '01095 - MARSHALL' },
        { name: 'MOBILE', fips: '01097', id: '01097 - MOBILE' },
        { name: 'MONROE', fips: '01099', id: '01099 - MONROE' },
        { name: 'MONTGOMERY', fips: '01101', id: '01101 - MONTGOMERY' },
        { name: 'MORGAN', fips: '01103', id: '01103 - MORGAN' },
        { name: 'PERRY', fips: '01105', id: '01105 - PERRY' },
        { name: 'PICKENS', fips: '01107', id: '01107 - PICKENS' },
        { name: 'PIKE', fips: '01109', id: '01109 - PIKE' },
        { name: 'RANDOLPH', fips: '01111', id: '01111 - RANDOLPH' },
        { name: 'RUSSELL', fips: '01113', id: '01113 - RUSSELL' },
        { name: 'ST. CLAIR', fips: '01115', id: '01115 - ST. CLAIR' },
        { name: 'SHELBY', fips: '01117', id: '01117 - SHELBY' },
        { name: 'SUMTER', fips: '01119', id: '01119 - SUMTER' },
        { name: 'TALLADEGA', fips: '01121', id: '01121 - TALLADEGA' },
        { name: 'TALLAPOOSA', fips: '01123', id: '01123 - TALLAPOOSA' },
        { name: 'TUSCALOOSA', fips: '01125', id: '01125 - TUSCALOOSA' },
        { name: 'WALKER', fips: '01127', id: '01127 - WALKER' },
        { name: 'WASHINGTON', fips: '01129', id: '01129 - WASHINGTON' },
        { name: 'WILCOX', fips: '01131', id: '01131 - WILCOX' },
        { name: 'WINSTON', fips: '01133', id: '01133 - WINSTON' },
    ],
    AZ: [
        { name: 'APACHE', fips: '04001', id: '04001 - APACHE' },
        { name: 'COCHISE', fips: '04003', id: '04003 - COCHISE' },
        { name: 'COCONINO', fips: '04005', id: '04005 - COCONINO' },
        { name: 'GILA', fips: '04007', id: '04007 - GILA' },
        { name: 'GRAHAM', fips: '04009', id: '04009 - GRAHAM' },
        { name: 'GREENLEE', fips: '04011', id: '04011 - GREENLEE' },
        { name: 'LA PAZ', fips: '04012', id: '04012 - LA PAZ' },
        { name: 'MARICOPA', fips: '04013', id: '04013 - MARICOPA' },
        { name: 'MOHAVE', fips: '04015', id: '04015 - MOHAVE' },
        { name: 'NAVAJO', fips: '04017', id: '04017 - NAVAJO' },
        { name: 'PIMA', fips: '04019', id: '04019 - PIMA' },
        { name: 'PINAL', fips: '04021', id: '04021 - PINAL' },
        { name: 'SANTA CRUZ', fips: '04023', id: '04023 - SANTA CRUZ' },
        { name: 'YAVAPAI', fips: '04025', id: '04025 - YAVAPAI' },
        { name: 'YUMA', fips: '04027', id: '04027 - YUMA' },
    ],
    AR: [
        { name: 'ARKANSAS', fips: '05001', id: '05001 - ARKANSAS' },
        { name: 'ASHLEY', fips: '05003', id: '05003 - ASHLEY' },
        { name: 'BAXTER', fips: '05005', id: '05005 - BAXTER' },
        { name: 'BENTON', fips: '05007', id: '05007 - BENTON' },
        { name: 'BOONE', fips: '05009', id: '05009 - BOONE' },
        { name: 'BRADLEY', fips: '05011', id: '05011 - BRADLEY' },
        { name: 'CALHOUN', fips: '05013', id: '05013 - CALHOUN' },
        { name: 'CARROLL', fips: '05015', id: '05015 - CARROLL' },
        { name: 'CHICOT', fips: '05017', id: '05017 - CHICOT' },
        { name: 'CLARK', fips: '05019', id: '05019 - CLARK' },
        { name: 'CLAY', fips: '05021', id: '05021 - CLAY' },
        { name: 'CLEBURNE', fips: '05023', id: '05023 - CLEBURNE' },
        { name: 'CLEVELAND', fips: '05025', id: '05025 - CLEVELAND' },
        { name: 'COLUMBIA', fips: '05027', id: '05027 - COLUMBIA' },
        { name: 'CONWAY', fips: '05029', id: '05029 - CONWAY' },
        { name: 'CRAIGHEAD', fips: '05031', id: '05031 - CRAIGHEAD' },
        { name: 'CRAWFORD', fips: '05033', id: '05033 - CRAWFORD' },
        { name: 'CRITTENDEN', fips: '05035', id: '05035 - CRITTENDEN' },
        { name: 'CROSS', fips: '05037', id: '05037 - CROSS' },
        { name: 'DALLAS', fips: '05039', id: '05039 - DALLAS' },
        { name: 'DESHA', fips: '05041', id: '05041 - DESHA' },
        { name: 'DREW', fips: '05043', id: '05043 - DREW' },
        { name: 'FAULKNER', fips: '05045', id: '05045 - FAULKNER' },
        { name: 'FRANKLIN', fips: '05047', id: '05047 - FRANKLIN' },
        { name: 'FULTON', fips: '05049', id: '05049 - FULTON' },
        { name: 'GARLAND', fips: '05051', id: '05051 - GARLAND' },
        { name: 'GRANT', fips: '05053', id: '05053 - GRANT' },
        { name: 'GREENE', fips: '05055', id: '05055 - GREENE' },
        { name: 'HEMPSTEAD', fips: '05057', id: '05057 - HEMPSTEAD' },
        { name: 'HOT SPRING', fips: '05059', id: '05059 - HOT SPRING' },
        { name: 'HOWARD', fips: '05061', id: '05061 - HOWARD' },
        { name: 'INDEPENDENCE', fips: '05063', id: '05063 - INDEPENDENCE' },
        { name: 'IZARD', fips: '05065', id: '05065 - IZARD' },
        { name: 'JACKSON', fips: '05067', id: '05067 - JACKSON' },
        { name: 'JEFFERSON', fips: '05069', id: '05069 - JEFFERSON' },
        { name: 'JOHNSON', fips: '05071', id: '05071 - JOHNSON' },
        { name: 'LAFAYETTE', fips: '05073', id: '05073 - LAFAYETTE' },
        { name: 'LAWRENCE', fips: '05075', id: '05075 - LAWRENCE' },
        { name: 'LEE', fips: '05077', id: '05077 - LEE' },
        { name: 'LINCOLN', fips: '05079', id: '05079 - LINCOLN' },
        { name: 'LITTLE RIVER', fips: '05081', id: '05081 - LITTLE RIVER' },
        { name: 'LOGAN', fips: '05083', id: '05083 - LOGAN' },
        { name: 'LONOKE', fips: '05085', id: '05085 - LONOKE' },
        { name: 'MADISON', fips: '05087', id: '05087 - MADISON' },
        { name: 'MARION', fips: '05089', id: '05089 - MARION' },
        { name: 'MILLER', fips: '05091', id: '05091 - MILLER' },
        { name: 'MISSISSIPPI', fips: '05093', id: '05093 - MISSISSIPPI' },
        { name: 'MONROE', fips: '05095', id: '05095 - MONROE' },
        { name: 'MONTGOMERY', fips: '05097', id: '05097 - MONTGOMERY' },
        { name: 'NEVADA', fips: '05099', id: '05099 - NEVADA' },
        { name: 'NEWTON', fips: '05101', id: '05101 - NEWTON' },
        { name: 'OUACHITA', fips: '05103', id: '05103 - OUACHITA' },
        { name: 'PERRY', fips: '05105', id: '05105 - PERRY' },
        { name: 'PHILLIPS', fips: '05107', id: '05107 - PHILLIPS' },
        { name: 'PIKE', fips: '05109', id: '05109 - PIKE' },
        { name: 'POINSETT', fips: '05111', id: '05111 - POINSETT' },
        { name: 'POLK', fips: '05113', id: '05113 - POLK' },
        { name: 'POPE', fips: '05115', id: '05115 - POPE' },
        { name: 'PRAIRIE', fips: '05117', id: '05117 - PRAIRIE' },
        { name: 'PULASKI', fips: '05119', id: '05119 - PULASKI' },
        { name: 'RANDOLPH', fips: '05121', id: '05121 - RANDOLPH' },
        { name: 'ST. FRANCIS', fips: '05123', id: '05123 - ST. FRANCIS' },
        { name: 'SALINE', fips: '05125', id: '05125 - SALINE' },
        { name: 'SCOTT', fips: '05127', id: '05127 - SCOTT' },
        { name: 'SEARCY', fips: '05129', id: '05129 - SEARCY' },
        { name: 'SEBASTIAN', fips: '05131', id: '05131 - SEBASTIAN' },
        { name: 'SEVIER', fips: '05133', id: '05133 - SEVIER' },
        { name: 'SHARP', fips: '05135', id: '05135 - SHARP' },
        { name: 'STONE', fips: '05137', id: '05137 - STONE' },
        { name: 'UNION', fips: '05139', id: '05139 - UNION' },
        { name: 'VAN BUREN', fips: '05141', id: '05141 - VAN BUREN' },
        { name: 'WASHINGTON', fips: '05143', id: '05143 - WASHINGTON' },
        { name: 'WHITE', fips: '05145', id: '05145 - WHITE' },
        { name: 'WOODRUFF', fips: '05147', id: '05147 - WOODRUFF' },
        { name: 'YELL', fips: '05149', id: '05149 - YELL' },
    ],
    CA: [
        { name: 'ALAMEDA', fips: '06001', id: '06001 - ALAMEDA' },
        { name: 'ALPINE', fips: '06003', id: '06003 - ALPINE' },
        { name: 'AMADOR', fips: '06005', id: '06005 - AMADOR' },
        { name: 'BUTTE', fips: '06007', id: '06007 - BUTTE' },
        { name: 'CALAVERAS', fips: '06009', id: '06009 - CALAVERAS' },
        { name: 'COLUSA', fips: '06011', id: '06011 - COLUSA' },
        { name: 'CONTRA COSTA', fips: '06013', id: '06013 - CONTRA COSTA' },
        { name: 'DEL NORTE', fips: '06015', id: '06015 - DEL NORTE' },
        { name: 'EL DORADO', fips: '06017', id: '06017 - EL DORADO' },
        { name: 'FRESNO', fips: '06019', id: '06019 - FRESNO' },
        { name: 'GLENN', fips: '06021', id: '06021 - GLENN' },
        { name: 'HUMBOLDT', fips: '06023', id: '06023 - HUMBOLDT' },
        { name: 'IMPERIAL', fips: '06025', id: '06025 - IMPERIAL' },
        { name: 'INYO', fips: '06027', id: '06027 - INYO' },
        { name: 'KERN', fips: '06029', id: '06029 - KERN' },
        { name: 'KINGS', fips: '06031', id: '06031 - KINGS' },
        { name: 'LAKE', fips: '06033', id: '06033 - LAKE' },
        { name: 'LASSEN', fips: '06035', id: '06035 - LASSEN' },
        { name: 'LOS ANGELES', fips: '06037', id: '06037 - LOS ANGELES' },
        { name: 'MADERA', fips: '06039', id: '06039 - MADERA' },
        { name: 'MARIN', fips: '06041', id: '06041 - MARIN' },
        { name: 'MARIPOSA', fips: '06043', id: '06043 - MARIPOSA' },
        { name: 'MENDOCINO', fips: '06045', id: '06045 - MENDOCINO' },
        { name: 'MERCED', fips: '06047', id: '06047 - MERCED' },
        { name: 'MODOC', fips: '06049', id: '06049 - MODOC' },
        { name: 'MONO', fips: '06051', id: '06051 - MONO' },
        { name: 'MONTEREY', fips: '06053', id: '06053 - MONTEREY' },
        { name: 'NAPA', fips: '06055', id: '06055 - NAPA' },
        { name: 'NEVADA', fips: '06057', id: '06057 - NEVADA' },
        { name: 'ORANGE', fips: '06059', id: '06059 - ORANGE' },
        { name: 'PLACER', fips: '06061', id: '06061 - PLACER' },
        { name: 'PLUMAS', fips: '06063', id: '06063 - PLUMAS' },
        { name: 'RIVERSIDE', fips: '06065', id: '06065 - RIVERSIDE' },
        { name: 'SACRAMENTO', fips: '06067', id: '06067 - SACRAMENTO' },
        { name: 'SAN BENITO', fips: '06069', id: '06069 - SAN BENITO' },
        { name: 'SAN BERNARDINO', fips: '06071', id: '06071 - SAN BERNARDINO' },
        { name: 'SAN DIEGO', fips: '06073', id: '06073 - SAN DIEGO' },
        { name: 'SAN FRANCISCO', fips: '06075', id: '06075 - SAN FRANCISCO' },
        { name: 'SAN JOAQUIN', fips: '06077', id: '06077 - SAN JOAQUIN' },
        { name: 'SAN LUIS OBISPO', fips: '06079', id: '06079 - SAN LUIS OBISPO' },
        { name: 'SAN MATEO', fips: '06081', id: '06081 - SAN MATEO' },
        { name: 'SANTA', fips: '06083', id: '06083 - SANTA' },
        { name: 'SANTA', fips: '06085', id: '06085 - SANTA' },
        { name: 'SANTA', fips: '06087', id: '06087 - SANTA' },
        { name: 'SHASTA', fips: '06089', id: '06089 - SHASTA' },
        { name: 'SIERRA', fips: '06091', id: '06091 - SIERRA' },
        { name: 'SISKIYOU', fips: '06093', id: '06093 - SISKIYOU' },
        { name: 'SOLANO', fips: '06095', id: '06095 - SOLANO' },
        { name: 'SONOMA', fips: '06097', id: '06097 - SONOMA' },
        { name: 'STANISLAUS', fips: '06099', id: '06099 - STANISLAUS' },
        { name: 'SUTTER', fips: '06101', id: '06101 - SUTTER' },
        { name: 'TEHAMA', fips: '06103', id: '06103 - TEHAMA' },
        { name: 'TRINITY', fips: '06105', id: '06105 - TRINITY' },
        { name: 'TULARE', fips: '06107', id: '06107 - TULARE' },
        { name: 'TUOLUMNE', fips: '06109', id: '06109 - TUOLUMNE' },
        { name: 'VENTURA', fips: '06111', id: '06111 - VENTURA' },
        { name: 'YOLO', fips: '06113', id: '06113 - YOLO' },
        { name: 'YUBA', fips: '06115', id: '06115 - YUBA' },
    ],
    CO: [
        { name: 'ADAMS', fips: '08001', id: '08001 - ADAMS' },
        { name: 'ALAMOSA', fips: '08003', id: '08003 - ALAMOSA' },
        { name: 'ARAPAHOE', fips: '08005', id: '08005 - ARAPAHOE' },
        { name: 'ARCHULETA', fips: '08007', id: '08007 - ARCHULETA' },
        { name: 'BACA', fips: '08009', id: '08009 - BACA' },
        { name: 'BENT', fips: '08011', id: '08011 - BENT' },
        { name: 'BOULDER', fips: '08013', id: '08013 - BOULDER' },
        { name: 'CHAFFEE', fips: '08015', id: '08015 - CHAFFEE' },
        { name: 'CHEYENNE', fips: '08017', id: '08017 - CHEYENNE' },
        { name: 'CLEAR CREEK', fips: '08019', id: '08019 - CLEAR CREEK' },
        { name: 'CONEJOS', fips: '08021', id: '08021 - CONEJOS' },
        { name: 'COSTILLA', fips: '08023', id: '08023 - COSTILLA' },
        { name: 'CROWLE', fips: '8025', id: '8025 - CROWLE' },
        { name: 'CUSTER', fips: '08027', id: '08027 - CUSTER' },
        { name: 'DELTA', fips: '08029', id: '08029 - DELTA' },
        { name: 'DENVER', fips: '08031', id: '08031 - DENVER' },
        { name: 'DOLORES', fips: '08033', id: '08033 - DOLORES' },
        { name: 'DOUGLAS', fips: '08035', id: '08035 - DOUGLAS' },
        { name: 'EAGLE', fips: '08037', id: '08037 - EAGLE' },
        { name: 'ELBERT', fips: '08039', id: '08039 - ELBERT' },
        { name: 'EL PASO', fips: '08041', id: '08041 - EL PASO' },
        { name: 'FREMONT', fips: '08043', id: '08043 - FREMONT' },
        { name: 'GARFIELD', fips: '08045', id: '08045 - GARFIELD' },
        { name: 'GILPIN', fips: '08047', id: '08047 - GILPIN' },
        { name: 'GRAND', fips: '08049', id: '08049 - GRAND' },
        { name: 'GUNNISON', fips: '08051', id: '08051 - GUNNISON' },
        { name: 'HINSDALE', fips: '08053', id: '08053 - HINSDALE' },
        { name: 'HUERFANO', fips: '08055', id: '08055 - HUERFANO' },
        { name: 'JACKSON', fips: '08057', id: '08057 - JACKSON' },
        { name: 'JEFFERSON', fips: '08059', id: '08059 - JEFFERSON' },
        { name: 'KIOWA', fips: '08061', id: '08061 - KIOWA' },
        { name: 'KIT CARSON', fips: '08063', id: '08063 - KIT CARSON' },
        { name: 'LAKE', fips: '08065', id: '08065 - LAKE' },
        { name: 'LA PLATA', fips: '08067', id: '08067 - LA PLATA' },
        { name: 'LARIMER', fips: '08069', id: '08069 - LARIMER' },
        { name: 'LAS ANIMAS', fips: '08071', id: '08071 - LAS ANIMAS' },
        { name: 'LINCOLN', fips: '08073', id: '08073 - LINCOLN' },
        { name: 'LOGAN', fips: '08075', id: '08075 - LOGAN' },
        { name: 'MESA', fips: '08077', id: '08077 - MESA' },
        { name: 'MINERAL', fips: '08079', id: '08079 - MINERAL' },
        { name: 'MOFFAT', fips: '08081', id: '08081 - MOFFAT' },
        { name: 'MONTEZUMA', fips: '08083', id: '08083 - MONTEZUMA' },
        { name: 'MONTROSE', fips: '08085', id: '08085 - MONTROSE' },
        { name: 'MORGAN', fips: '08087', id: '08087 - MORGAN' },
        { name: 'OTERO', fips: '08089', id: '08089 - OTERO' },
        { name: 'OURAY', fips: '08091', id: '08091 - OURAY' },
        { name: 'PARK', fips: '08093', id: '08093 - PARK' },
        { name: 'PHILLIPS', fips: '08095', id: '08095 - PHILLIPS' },
        { name: 'PITKIN', fips: '08097', id: '08097 - PITKIN' },
        { name: 'PROWERS', fips: '08099', id: '08099 - PROWERS' },
        { name: 'PUEBLO', fips: '08101', id: '08101 - PUEBLO' },
        { name: 'RIO BLANCO', fips: '08103', id: '08103 - RIO BLANCO' },
        { name: 'RIO GRANDE', fips: '08105', id: '08105 - RIO GRANDE' },
        { name: 'ROUTT', fips: '08107', id: '08107 - ROUTT' },
        { name: 'SAGUACHE', fips: '08109', id: '08109 - SAGUACHE' },
        { name: 'SAN JUAN', fips: '08111', id: '08111 - SAN JUAN' },
        { name: 'SAN MIGUEL', fips: '08113', id: '08113 - SAN MIGUEL' },
        { name: 'SEDGWICK', fips: '08115', id: '08115 - SEDGWICK' },
        { name: 'SUMMIT', fips: '08117', id: '08117 - SUMMIT' },
        { name: 'TELLER', fips: '08119', id: '08119 - TELLER' },
        { name: 'WASHINGTON', fips: '08121', id: '08121 - WASHINGTON' },
        { name: 'WELD', fips: '08123', id: '08123 - WELD' },
        { name: 'YUMA', fips: '08125', id: '08125 - YUMA' },
    ],
    CT: [
        { name: 'FAIRFIELD', fips: '09001', id: '09001 - FAIRFIELD' },
        { name: 'HARTFORD', fips: '09003', id: '09003 - HARTFORD' },
        { name: 'LITCHFIELD', fips: '09005', id: '09005 - LITCHFIELD' },
        { name: 'MIDDLESEX', fips: '09007', id: '09007 - MIDDLESEX' },
        { name: 'NEW HAVEN', fips: '09009', id: '09009 - NEW HAVEN' },
        { name: 'NEW LONDON', fips: '09011', id: '09011 - NEW LONDON' },
        { name: 'TOLLAND', fips: '09013', id: '09013 - TOLLAND' },
        { name: 'WINDHAM', fips: '09015', id: '09015 - WINDHAM' },
    ],
    DE: [
        { name: 'KENT', fips: '10001', id: '10001 - KENT' },
        { name: 'NEW CASTLE', fips: '10003', id: '10003 - NEW CASTLE' },
        { name: 'SUSSEX', fips: '10005', id: '10005 - SUSSEX' },
    ],
    DC: [{ name: 'DISTRICT OF COLUMBIA', fips: '11001', id: '11001 - DISTRICT OF COLUMBIA' }],
    FL: [
        { name: 'ALACHUA', fips: '12001', id: '12001 - ALACHUA' },
        { name: 'BAKER', fips: '12003', id: '12003 - BAKER' },
        { name: 'BAY', fips: '12005', id: '12005 - BAY' },
        { name: 'BRADFORD', fips: '12007', id: '12007 - BRADFORD' },
        { name: 'BREVARD', fips: '12009', id: '12009 - BREVARD' },
        { name: 'BROWARD', fips: '12011', id: '12011 - BROWARD' },
        { name: 'CALHOUN', fips: '12013', id: '12013 - CALHOUN' },
        { name: 'CHARLOTTE', fips: '12015', id: '12015 - CHARLOTTE' },
        { name: 'CITRUS', fips: '12017', id: '12017 - CITRUS' },
        { name: 'CLAY', fips: '12019', id: '12019 - CLAY' },
        { name: 'COLLIER', fips: '12021', id: '12021 - COLLIER' },
        { name: 'COLUMBIA', fips: '12023', id: '12023 - COLUMBIA' },
        { name: 'DADE', fips: '12025', id: '12025 - DADE' },
        { name: 'DE SOTO', fips: '12027', id: '12027 - DE SOTO' },
        { name: 'DIXIE', fips: '12029', id: '12029 - DIXIE' },
        { name: 'DUVAL', fips: '12031', id: '12031 - DUVAL' },
        { name: 'ESCAMBIA', fips: '12033', id: '12033 - ESCAMBIA' },
        { name: 'FLAGLER', fips: '12035', id: '12035 - FLAGLER' },
        { name: 'FRANKLIN', fips: '12037', id: '12037 - FRANKLIN' },
        { name: 'GADSDEN', fips: '12039', id: '12039 - GADSDEN' },
        { name: 'GILCHRIST', fips: '12041', id: '12041 - GILCHRIST' },
        { name: 'GLADES', fips: '12043', id: '12043 - GLADES' },
        { name: 'GULF', fips: '12045', id: '12045 - GULF' },
        { name: 'HAMILTON', fips: '12047', id: '12047 - HAMILTON' },
        { name: 'HARDEE', fips: '12049', id: '12049 - HARDEE' },
        { name: 'HENDRY', fips: '12051', id: '12051 - HENDRY' },
        { name: 'HERNANDO', fips: '12053', id: '12053 - HERNANDO' },
        { name: 'HIGHLANDS', fips: '12055', id: '12055 - HIGHLANDS' },
        { name: 'HILLSBOROUGH', fips: '12057', id: '12057 - HILLSBOROUGH' },
        { name: 'HOLMES', fips: '12059', id: '12059 - HOLMES' },
        { name: 'INDIAN RIVER', fips: '12061', id: '12061 - INDIAN RIVER' },
        { name: 'JACKSON', fips: '12063', id: '12063 - JACKSON' },
        { name: 'JEFFERSON', fips: '12065', id: '12065 - JEFFERSON' },
        { name: 'LAFAYETTE', fips: '12067', id: '12067 - LAFAYETTE' },
        { name: 'LAKE', fips: '12069', id: '12069 - LAKE' },
        { name: 'LEE', fips: '12071', id: '12071 - LEE' },
        { name: 'LEON', fips: '12073', id: '12073 - LEON' },
        { name: 'LEVY', fips: '12075', id: '12075 - LEVY' },
        { name: 'LIBERTY', fips: '12077', id: '12077 - LIBERTY' },
        { name: 'MADISON', fips: '12079', id: '12079 - MADISON' },
        { name: 'MANATEE', fips: '12081', id: '12081 - MANATEE' },
        { name: 'MARION', fips: '12083', id: '12083 - MARION' },
        { name: 'MARTIN', fips: '12085', id: '12085 - MARTIN' },
        { name: 'MONROE', fips: '12087', id: '12087 - MONROE' },
        { name: 'NASSAU', fips: '12089', id: '12089 - NASSAU' },
        { name: 'OKALOOSA', fips: '12091', id: '12091 - OKALOOSA' },
        { name: 'OKEECHOBEE', fips: '12093', id: '12093 - OKEECHOBEE' },
        { name: 'ORANGE', fips: '12095', id: '12095 - ORANGE' },
        { name: 'OSCEOLA', fips: '12097', id: '12097 - OSCEOLA' },
        { name: 'PALM BEACH', fips: '12099', id: '12099 - PALM BEACH' },
        { name: 'PASCO', fips: '12101', id: '12101 - PASCO' },
        { name: 'PINELLAS', fips: '12103', id: '12103 - PINELLAS' },
        { name: 'POLK', fips: '12105', id: '12105 - POLK' },
        { name: 'PUTNAM', fips: '12107', id: '12107 - PUTNAM' },
        { name: 'ST. JOHNS', fips: '12109', id: '12109 - ST. JOHNS' },
        { name: 'ST. LUCIE', fips: '12111', id: '12111 - ST. LUCIE' },
        { name: 'SANTA ROSA', fips: '12113', id: '12113 - SANTA ROSA' },
        { name: 'SARASOTA', fips: '12115', id: '12115 - SARASOTA' },
        { name: 'SEMINOLE', fips: '12117', id: '12117 - SEMINOLE' },
        { name: 'SUMTER', fips: '12119', id: '12119 - SUMTER' },
        { name: 'SUWANNEE', fips: '12121', id: '12121 - SUWANNEE' },
        { name: 'TAYLOR', fips: '12123', id: '12123 - TAYLOR' },
        { name: 'UNION', fips: '12125', id: '12125 - UNION' },
        { name: 'VOLUSIA', fips: '12127', id: '12127 - VOLUSIA' },
        { name: 'WAKULLA', fips: '12129', id: '12129 - WAKULLA' },
        { name: 'WALTON', fips: '12131', id: '12131 - WALTON' },
        { name: 'WASHINGTON', fips: '12133', id: '12133 - WASHINGTON' },
    ],
    GA: [
        { name: 'APPLING', fips: '13001', id: '13001 - APPLING' },
        { name: 'ATKINSON', fips: '13003', id: '13003 - ATKINSON' },
        { name: 'BACON', fips: '13005', id: '13005 - BACON' },
        { name: 'BAKER', fips: '13007', id: '13007 - BAKER' },
        { name: 'BALDWIN', fips: '13009', id: '13009 - BALDWIN' },
        { name: 'BANKS', fips: '13011', id: '13011 - BANKS' },
        { name: 'BARROW', fips: '13013', id: '13013 - BARROW' },
        { name: 'BARTOW', fips: '13015', id: '13015 - BARTOW' },
        { name: 'BEN HILL', fips: '13017', id: '13017 - BEN HILL' },
        { name: 'BERRIEN', fips: '13019', id: '13019 - BERRIEN' },
        { name: 'BIBB', fips: '13021', id: '13021 - BIBB' },
        { name: 'BLECKLEY', fips: '13023', id: '13023 - BLECKLEY' },
        { name: 'BRANTLEY', fips: '13025', id: '13025 - BRANTLEY' },
        { name: 'BROOKS', fips: '13027', id: '13027 - BROOKS' },
        { name: 'BRYAN', fips: '13029', id: '13029 - BRYAN' },
        { name: 'BULLOCH', fips: '13031', id: '13031 - BULLOCH' },
        { name: 'BURKE', fips: '13033', id: '13033 - BURKE' },
        { name: 'BUTTS', fips: '13035', id: '13035 - BUTTS' },
        { name: 'CALHOUN', fips: '13037', id: '13037 - CALHOUN' },
        { name: 'CAMDEN', fips: '13039', id: '13039 - CAMDEN' },
        { name: 'CANDLER', fips: '13043', id: '13043 - CANDLER' },
        { name: 'CARROLL', fips: '13045', id: '13045 - CARROLL' },
        { name: 'CATOOSA', fips: '13047', id: '13047 - CATOOSA' },
        { name: 'CHARLTON', fips: '13049', id: '13049 - CHARLTON' },
        { name: 'CHATHAM', fips: '13051', id: '13051 - CHATHAM' },
        { name: 'CHATTAHOOCHEE', fips: '13053', id: '13053 - CHATTAHOOCHEE' },
        { name: 'CHATTOOGA', fips: '13055', id: '13055 - CHATTOOGA' },
        { name: 'CHEROKEE', fips: '13057', id: '13057 - CHEROKEE' },
        { name: 'CLARKE', fips: '13059', id: '13059 - CLARKE' },
        { name: 'CLAY', fips: '13061', id: '13061 - CLAY' },
        { name: 'CLAYTON', fips: '13063', id: '13063 - CLAYTON' },
        { name: 'CLINCH', fips: '13065', id: '13065 - CLINCH' },
        { name: 'COBB', fips: '13067', id: '13067 - COBB' },
        { name: 'COFFEE', fips: '13069', id: '13069 - COFFEE' },
        { name: 'COLQUITT', fips: '13071', id: '13071 - COLQUITT' },
        { name: 'COLUMBIA', fips: '13073', id: '13073 - COLUMBIA' },
        { name: 'COOK', fips: '13075', id: '13075 - COOK' },
        { name: 'COWETA', fips: '13077', id: '13077 - COWETA' },
        { name: 'CRAWFORD', fips: '13079', id: '13079 - CRAWFORD' },
        { name: 'CRISP', fips: '13081', id: '13081 - CRISP' },
        { name: 'DADE', fips: '13083', id: '13083 - DADE' },
        { name: 'DAWSON', fips: '13085', id: '13085 - DAWSON' },
        { name: 'DECATUR', fips: '13087', id: '13087 - DECATUR' },
        { name: 'DE KALB', fips: '13089', id: '13089 - DE KALB' },
        { name: 'DODGE', fips: '13091', id: '13091 - DODGE' },
        { name: 'DOOLY', fips: '13093', id: '13093 - DOOLY' },
        { name: 'DOUGHERTY', fips: '13095', id: '13095 - DOUGHERTY' },
        { name: 'DOUGLAS', fips: '13097', id: '13097 - DOUGLAS' },
        { name: 'EARLY', fips: '13099', id: '13099 - EARLY' },
        { name: 'ECHOLS', fips: '13101', id: '13101 - ECHOLS' },
        { name: 'EFFINGHAM', fips: '13103', id: '13103 - EFFINGHAM' },
        { name: 'ELBERT', fips: '13105', id: '13105 - ELBERT' },
        { name: 'EMANUEL', fips: '13107', id: '13107 - EMANUEL' },
        { name: 'EVANS', fips: '13109', id: '13109 - EVANS' },
        { name: 'FANNIN', fips: '13111', id: '13111 - FANNIN' },
        { name: 'FAYETTE', fips: '13113', id: '13113 - FAYETTE' },
        { name: 'FLOYD', fips: '13115', id: '13115 - FLOYD' },
        { name: 'FORSYTH', fips: '13117', id: '13117 - FORSYTH' },
        { name: 'FRANKLIN', fips: '13119', id: '13119 - FRANKLIN' },
        { name: 'FULTON', fips: '13121', id: '13121 - FULTON' },
        { name: 'GILMER', fips: '13123', id: '13123 - GILMER' },
        { name: 'GLASCOCK', fips: '13125', id: '13125 - GLASCOCK' },
        { name: 'GLYNN', fips: '13127', id: '13127 - GLYNN' },
        { name: 'GORDON', fips: '13129', id: '13129 - GORDON' },
        { name: 'GRADY', fips: '13131', id: '13131 - GRADY' },
        { name: 'GREENE', fips: '13133', id: '13133 - GREENE' },
        { name: 'GWINNETT', fips: '13135', id: '13135 - GWINNETT' },
        { name: 'HABERSHAM', fips: '13137', id: '13137 - HABERSHAM' },
        { name: 'HALL', fips: '13139', id: '13139 - HALL' },
        { name: 'HANCOCK', fips: '13141', id: '13141 - HANCOCK' },
        { name: 'HARALSON', fips: '13143', id: '13143 - HARALSON' },
        { name: 'HARRIS', fips: '13145', id: '13145 - HARRIS' },
        { name: 'HART', fips: '13147', id: '13147 - HART' },
        { name: 'HEARD', fips: '13149', id: '13149 - HEARD' },
        { name: 'HENRY', fips: '13151', id: '13151 - HENRY' },
        { name: 'HOUSTON', fips: '13153', id: '13153 - HOUSTON' },
        { name: 'IRWIN', fips: '13155', id: '13155 - IRWIN' },
        { name: 'JACKSON', fips: '13157', id: '13157 - JACKSON' },
        { name: 'JASPER', fips: '13159', id: '13159 - JASPER' },
        { name: 'JEFF DAVIS', fips: '13161', id: '13161 - JEFF DAVIS' },
        { name: 'JEFFERSON', fips: '13163', id: '13163 - JEFFERSON' },
        { name: 'JENKINS', fips: '13165', id: '13165 - JENKINS' },
        { name: 'JOHNSON', fips: '13167', id: '13167 - JOHNSON' },
        { name: 'JONES', fips: '13169', id: '13169 - JONES' },
        { name: 'LAMAR', fips: '13171', id: '13171 - LAMAR' },
        { name: 'LANIER', fips: '13173', id: '13173 - LANIER' },
        { name: 'LAURENS', fips: '13175', id: '13175 - LAURENS' },
        { name: 'LEE', fips: '13177', id: '13177 - LEE' },
        { name: 'LIBERTY', fips: '13179', id: '13179 - LIBERTY' },
        { name: 'LINCOLN', fips: '13181', id: '13181 - LINCOLN' },
        { name: 'LONG', fips: '13183', id: '13183 - LONG' },
        { name: 'LOWNDES', fips: '13185', id: '13185 - LOWNDES' },
        { name: 'LUMPKIN', fips: '13187', id: '13187 - LUMPKIN' },
        { name: 'MCDUFFIE', fips: '13189', id: '13189 - MCDUFFIE' },
        { name: 'MCINTOSH', fips: '13191', id: '13191 - MCINTOSH' },
        { name: 'MACON', fips: '13193', id: '13193 - MACON' },
        { name: 'MADISON', fips: '13195', id: '13195 - MADISON' },
        { name: 'MARION', fips: '13197', id: '13197 - MARION' },
        { name: 'MERIWETHER', fips: '13199', id: '13199 - MERIWETHER' },
        { name: 'MILLER', fips: '13201', id: '13201 - MILLER' },
        { name: 'MITCHELL', fips: '13205', id: '13205 - MITCHELL' },
        { name: 'MONROE', fips: '13207', id: '13207 - MONROE' },
        { name: 'MONTGOMERY', fips: '13209', id: '13209 - MONTGOMERY' },
        { name: 'MORGAN', fips: '13211', id: '13211 - MORGAN' },
        { name: 'MURRAY', fips: '13213', id: '13213 - MURRAY' },
        { name: 'MUSCOGEE', fips: '13215', id: '13215 - MUSCOGEE' },
        { name: 'NEWTON', fips: '13217', id: '13217 - NEWTON' },
        { name: 'OCONEE', fips: '13219', id: '13219 - OCONEE' },
        { name: 'OGLETHORPE', fips: '13221', id: '13221 - OGLETHORPE' },
        { name: 'PAULDING', fips: '13223', id: '13223 - PAULDING' },
        { name: 'PEACH', fips: '13225', id: '13225 - PEACH' },
        { name: 'PICKENS', fips: '13227', id: '13227 - PICKENS' },
        { name: 'PIERCE', fips: '13229', id: '13229 - PIERCE' },
        { name: 'PIKE', fips: '13231', id: '13231 - PIKE' },
        { name: 'POLK', fips: '13233', id: '13233 - POLK' },
        { name: 'PULASKI', fips: '13235', id: '13235 - PULASKI' },
        { name: 'PUTNAM', fips: '13237', id: '13237 - PUTNAM' },
        { name: 'QUITMAN', fips: '13239', id: '13239 - QUITMAN' },
        { name: 'RABUN', fips: '13241', id: '13241 - RABUN' },
        { name: 'RANDOLPH', fips: '13243', id: '13243 - RANDOLPH' },
        { name: 'RICHMOND', fips: '13245', id: '13245 - RICHMOND' },
        { name: 'ROCKDALE', fips: '13247', id: '13247 - ROCKDALE' },
        { name: 'SCHLEY', fips: '13249', id: '13249 - SCHLEY' },
        { name: 'SCREVEN', fips: '13251', id: '13251 - SCREVEN' },
        { name: 'SEMINOLE', fips: '13253', id: '13253 - SEMINOLE' },
        { name: 'SPALDING', fips: '13255', id: '13255 - SPALDING' },
        { name: 'STEPHENS', fips: '13257', id: '13257 - STEPHENS' },
        { name: 'STEWART', fips: '13259', id: '13259 - STEWART' },
        { name: 'SUMTER', fips: '13261', id: '13261 - SUMTER' },
        { name: 'TALBOT', fips: '13263', id: '13263 - TALBOT' },
        { name: 'TALIAFERRO', fips: '13265', id: '13265 - TALIAFERRO' },
        { name: 'TATTNALL', fips: '13267', id: '13267 - TATTNALL' },
        { name: 'TAYLOR', fips: '13269', id: '13269 - TAYLOR' },
        { name: 'TELFAIR', fips: '13271', id: '13271 - TELFAIR' },
        { name: 'TERRELL', fips: '13273', id: '13273 - TERRELL' },
        { name: 'THOMAS', fips: '13275', id: '13275 - THOMAS' },
        { name: 'TIFT', fips: '13277', id: '13277 - TIFT' },
        { name: 'TOOMBS', fips: '13279', id: '13279 - TOOMBS' },
        { name: 'TOWNS', fips: '13281', id: '13281 - TOWNS' },
        { name: 'TREUTLEN', fips: '13283', id: '13283 - TREUTLEN' },
        { name: 'TROUP', fips: '13285', id: '13285 - TROUP' },
        { name: 'TURNER', fips: '13287', id: '13287 - TURNER' },
        { name: 'TWIGGS', fips: '13289', id: '13289 - TWIGGS' },
        { name: 'UNION', fips: '13291', id: '13291 - UNION' },
        { name: 'UPSON', fips: '13293', id: '13293 - UPSON' },
        { name: 'WALKER', fips: '13295', id: '13295 - WALKER' },
        { name: 'WALTON', fips: '13297', id: '13297 - WALTON' },
        { name: 'WARE', fips: '13299', id: '13299 - WARE' },
        { name: 'WARREN', fips: '13301', id: '13301 - WARREN' },
        { name: 'WASHINGTON', fips: '13303', id: '13303 - WASHINGTON' },
        { name: 'WAYNE', fips: '13305', id: '13305 - WAYNE' },
        { name: 'WEBSTER', fips: '13307', id: '13307 - WEBSTER' },
        { name: 'WHEELER', fips: '13309', id: '13309 - WHEELER' },
        { name: 'WHITE', fips: '13311', id: '13311 - WHITE' },
        { name: 'WHITFIELD', fips: '13313', id: '13313 - WHITFIELD' },
        { name: 'WILCOX', fips: '13315', id: '13315 - WILCOX' },
        { name: 'WILKES', fips: '13317', id: '13317 - WILKES' },
        { name: 'WILKINSON', fips: '13319', id: '13319 - WILKINSON' },
        { name: 'WORTH', fips: '13321', id: '13321 - WORTH' },
    ],
    ID: [
        { name: 'ADA', fips: '16001', id: '16001 - ADA' },
        { name: 'ADAMS', fips: '16003', id: '16003 - ADAMS' },
        { name: 'BANNOCK', fips: '16005', id: '16005 - BANNOCK' },
        { name: 'BEAR LAKE', fips: '16007', id: '16007 - BEAR LAKE' },
        { name: 'BENEWAH', fips: '16009', id: '16009 - BENEWAH' },
        { name: 'BINGHAM', fips: '16011', id: '16011 - BINGHAM' },
        { name: 'BLAINE', fips: '16013', id: '16013 - BLAINE' },
        { name: 'BOISE', fips: '16015', id: '16015 - BOISE' },
        { name: 'BONNER', fips: '16017', id: '16017 - BONNER' },
        { name: 'BONNEVILLE', fips: '16019', id: '16019 - BONNEVILLE' },
        { name: 'BOUNDARY', fips: '16021', id: '16021 - BOUNDARY' },
        { name: 'BUTTE', fips: '16023', id: '16023 - BUTTE' },
        { name: 'CAMAS', fips: '16025', id: '16025 - CAMAS' },
        { name: 'CANYON', fips: '16027', id: '16027 - CANYON' },
        { name: 'CARIBOU', fips: '16029', id: '16029 - CARIBOU' },
        { name: 'CASSIA', fips: '16031', id: '16031 - CASSIA' },
        { name: 'CLARK', fips: '16033', id: '16033 - CLARK' },
        { name: 'CLEARWATER', fips: '16035', id: '16035 - CLEARWATER' },
        { name: 'CUSTER', fips: '16037', id: '16037 - CUSTER' },
        { name: 'ELMORE', fips: '16039', id: '16039 - ELMORE' },
        { name: 'FRANKLIN', fips: '16041', id: '16041 - FRANKLIN' },
        { name: 'FREMONT', fips: '16043', id: '16043 - FREMONT' },
        { name: 'GEM', fips: '16045', id: '16045 - GEM' },
        { name: 'GOODING', fips: '16047', id: '16047 - GOODING' },
        { name: 'IDAHO', fips: '16049', id: '16049 - IDAHO' },
        { name: 'JEFFERSON', fips: '16051', id: '16051 - JEFFERSON' },
        { name: 'JEROME', fips: '16053', id: '16053 - JEROME' },
        { name: 'KOOTENAI', fips: '16055', id: '16055 - KOOTENAI' },
        { name: 'LATAH', fips: '16057', id: '16057 - LATAH' },
        { name: 'LEMHI', fips: '16059', id: '16059 - LEMHI' },
        { name: 'LEWIS', fips: '16061', id: '16061 - LEWIS' },
        { name: 'LINCOLN', fips: '16063', id: '16063 - LINCOLN' },
        { name: 'MADISON', fips: '16065', id: '16065 - MADISON' },
        { name: 'MINIDOKA', fips: '16067', id: '16067 - MINIDOKA' },
        { name: 'NEZ PERCE', fips: '16069', id: '16069 - NEZ PERCE' },
        { name: 'ONEIDA', fips: '16071', id: '16071 - ONEIDA' },
        { name: 'OWYHEE', fips: '16073', id: '16073 - OWYHEE' },
        { name: 'PAYETTE', fips: '16075', id: '16075 - PAYETTE' },
        { name: 'POWER', fips: '16077', id: '16077 - POWER' },
        { name: 'SHOSHONE', fips: '16079', id: '16079 - SHOSHONE' },
        { name: 'TETON', fips: '16081', id: '16081 - TETON' },
        { name: 'TWIN FALLS', fips: '16083', id: '16083 - TWIN FALLS' },
        { name: 'VALLEY', fips: '16085', id: '16085 - VALLEY' },
        { name: 'WASHINGTON', fips: '16087', id: '16087 - WASHINGTON' },
    ],
    IL: [
        { name: 'ADAMS', fips: '17001', id: '17001 - ADAMS' },
        { name: 'ALEXANDER', fips: '17003', id: '17003 - ALEXANDER' },
        { name: 'BOND', fips: '17005', id: '17005 - BOND' },
        { name: 'BOONE', fips: '17007', id: '17007 - BOONE' },
        { name: 'BROWN', fips: '17009', id: '17009 - BROWN' },
        { name: 'BUREAU', fips: '17011', id: '17011 - BUREAU' },
        { name: 'CALHOUN', fips: '17013', id: '17013 - CALHOUN' },
        { name: 'CARROLL', fips: '17015', id: '17015 - CARROLL' },
        { name: 'CASS', fips: '17017', id: '17017 - CASS' },
        { name: 'CHAMPAIGN', fips: '17019', id: '17019 - CHAMPAIGN' },
        { name: 'CHRISTIAN', fips: '17021', id: '17021 - CHRISTIAN' },
        { name: 'CLARK', fips: '17023', id: '17023 - CLARK' },
        { name: 'CLAY', fips: '17025', id: '17025 - CLAY' },
        { name: 'CLINTON', fips: '17027', id: '17027 - CLINTON' },
        { name: 'COLES', fips: '17029', id: '17029 - COLES' },
        { name: 'COOK', fips: '17031', id: '17031 - COOK' },
        { name: 'CRAWFORD', fips: '17033', id: '17033 - CRAWFORD' },
        { name: 'CUMBERLAND', fips: '17035', id: '17035 - CUMBERLAND' },
        { name: 'DE KALB', fips: '17037', id: '17037 - DE KALB' },
        { name: 'DE WITT', fips: '17039', id: '17039 - DE WITT' },
        { name: 'DOUGLAS', fips: '17041', id: '17041 - DOUGLAS' },
        { name: 'DU PAGE', fips: '17043', id: '17043 - DU PAGE' },
        { name: 'EDGAR', fips: '17045', id: '17045 - EDGAR' },
        { name: 'EDWARDS', fips: '17047', id: '17047 - EDWARDS' },
        { name: 'EFFINGHAM', fips: '17049', id: '17049 - EFFINGHAM' },
        { name: 'FAYETTE', fips: '17051', id: '17051 - FAYETTE' },
        { name: 'FORD', fips: '17053', id: '17053 - FORD' },
        { name: 'FRANKLIN', fips: '17055', id: '17055 - FRANKLIN' },
        { name: 'FULTON', fips: '17057', id: '17057 - FULTON' },
        { name: 'GALLATIN', fips: '17059', id: '17059 - GALLATIN' },
        { name: 'GREENE', fips: '17061', id: '17061 - GREENE' },
        { name: 'GRUNDY', fips: '17063', id: '17063 - GRUNDY' },
        { name: 'HAMILTON', fips: '17065', id: '17065 - HAMILTON' },
        { name: 'HANCOCK', fips: '17067', id: '17067 - HANCOCK' },
        { name: 'HARDIN', fips: '17069', id: '17069 - HARDIN' },
        { name: 'HENDERSON', fips: '17071', id: '17071 - HENDERSON' },
        { name: 'HENRY', fips: '17073', id: '17073 - HENRY' },
        { name: 'IROQUOIS', fips: '17075', id: '17075 - IROQUOIS' },
        { name: 'JACKSON', fips: '17077', id: '17077 - JACKSON' },
        { name: 'JASPER', fips: '17079', id: '17079 - JASPER' },
        { name: 'JEFFERSON', fips: '17081', id: '17081 - JEFFERSON' },
        { name: 'JERSEY', fips: '17083', id: '17083 - JERSEY' },
        { name: 'JO DAVIESS', fips: '17085', id: '17085 - JO DAVIESS' },
        { name: 'JOHNSON', fips: '17087', id: '17087 - JOHNSON' },
        { name: 'KANE', fips: '17089', id: '17089 - KANE' },
        { name: 'KANKAKEE', fips: '17091', id: '17091 - KANKAKEE' },
        { name: 'KENDALL', fips: '17093', id: '17093 - KENDALL' },
        { name: 'KNOX', fips: '17095', id: '17095 - KNOX' },
        { name: 'LAKE', fips: '17097', id: '17097 - LAKE' },
        { name: 'LA SALLE', fips: '17099', id: '17099 - LA SALLE' },
        { name: 'LAWRENCE', fips: '17101', id: '17101 - LAWRENCE' },
        { name: 'LEE', fips: '17103', id: '17103 - LEE' },
        { name: 'LIVINGSTON', fips: '17105', id: '17105 - LIVINGSTON' },
        { name: 'LOGAN', fips: '17107', id: '17107 - LOGAN' },
        { name: 'MCDONOUGH', fips: '17109', id: '17109 - MCDONOUGH' },
        { name: 'MCHENRY', fips: '17111', id: '17111 - MCHENRY' },
        { name: 'MCLEAN', fips: '17113', id: '17113 - MCLEAN' },
        { name: 'MACON', fips: '17115', id: '17115 - MACON' },
        { name: 'MACOUPIN', fips: '17117', id: '17117 - MACOUPIN' },
        { name: 'MADISON', fips: '17119', id: '17119 - MADISON' },
        { name: 'MARION', fips: '17121', id: '17121 - MARION' },
        { name: 'MARSHALL', fips: '17123', id: '17123 - MARSHALL' },
        { name: 'MASON', fips: '17125', id: '17125 - MASON' },
        { name: 'MASSAC', fips: '17127', id: '17127 - MASSAC' },
        { name: 'MENARD', fips: '17129', id: '17129 - MENARD' },
        { name: 'MERCER', fips: '17131', id: '17131 - MERCER' },
        { name: 'MONROE', fips: '17133', id: '17133 - MONROE' },
        { name: 'MONTGOMERY', fips: '17135', id: '17135 - MONTGOMERY' },
        { name: 'MORGAN', fips: '17137', id: '17137 - MORGAN' },
        { name: 'MOULTRIE', fips: '17139', id: '17139 - MOULTRIE' },
        { name: 'OGLE', fips: '17141', id: '17141 - OGLE' },
        { name: 'PEORIA', fips: '17143', id: '17143 - PEORIA' },
        { name: 'PERRY', fips: '17145', id: '17145 - PERRY' },
        { name: 'PIATT', fips: '17147', id: '17147 - PIATT' },
        { name: 'PIKE', fips: '17149', id: '17149 - PIKE' },
        { name: 'POPE', fips: '17151', id: '17151 - POPE' },
        { name: 'PULASKI', fips: '17153', id: '17153 - PULASKI' },
        { name: 'PUTNAM', fips: '17155', id: '17155 - PUTNAM' },
        { name: 'RANDOLPH', fips: '17157', id: '17157 - RANDOLPH' },
        { name: 'RICHLAND', fips: '17159', id: '17159 - RICHLAND' },
        { name: 'ROCK ISLAND', fips: '17161', id: '17161 - ROCK ISLAND' },
        { name: 'ST. CLAIR', fips: '17163', id: '17163 - ST. CLAIR' },
        { name: 'SALINE', fips: '17165', id: '17165 - SALINE' },
        { name: 'SANGAMON', fips: '17167', id: '17167 - SANGAMON' },
        { name: 'SCHUYLER', fips: '17169', id: '17169 - SCHUYLER' },
        { name: 'SCOTT', fips: '17171', id: '17171 - SCOTT' },
        { name: 'SHELBY', fips: '17173', id: '17173 - SHELBY' },
        { name: 'STARK', fips: '17175', id: '17175 - STARK' },
        { name: 'STEPHENSON', fips: '17177', id: '17177 - STEPHENSON' },
        { name: 'TAZEWELL', fips: '17179', id: '17179 - TAZEWELL' },
        { name: 'UNION', fips: '17181', id: '17181 - UNION' },
        { name: 'VERMILION', fips: '17183', id: '17183 - VERMILION' },
        { name: 'WABASH', fips: '17185', id: '17185 - WABASH' },
        { name: 'WARREN', fips: '17187', id: '17187 - WARREN' },
        { name: 'WASHINGTON', fips: '17189', id: '17189 - WASHINGTON' },
        { name: 'WAYNE', fips: '17191', id: '17191 - WAYNE' },
        { name: 'WHITE', fips: '17193', id: '17193 - WHITE' },
        { name: 'WHITESIDE', fips: '17195', id: '17195 - WHITESIDE' },
        { name: 'WILL', fips: '17197', id: '17197 - WILL' },
        { name: 'WILLIAMSON', fips: '17199', id: '17199 - WILLIAMSON' },
        { name: 'WINNEBAGO', fips: '17201', id: '17201 - WINNEBAGO' },
        { name: 'WOODFORD', fips: '17203', id: '17203 - WOODFORD' },
    ],
    IN: [
        { name: 'ADAMS', fips: '18001', id: '18001 - ADAMS' },
        { name: 'ALLEN', fips: '18003', id: '18003 - ALLEN' },
        { name: 'BARTHOLOMEW', fips: '18005', id: '18005 - BARTHOLOMEW' },
        { name: 'BENTON', fips: '18007', id: '18007 - BENTON' },
        { name: 'BLACKFORD', fips: '18009', id: '18009 - BLACKFORD' },
        { name: 'BOONE', fips: '18011', id: '18011 - BOONE' },
        { name: 'BROWN', fips: '18013', id: '18013 - BROWN' },
        { name: 'CARROLL', fips: '18015', id: '18015 - CARROLL' },
        { name: 'CASS', fips: '18017', id: '18017 - CASS' },
        { name: 'CLARK', fips: '18019', id: '18019 - CLARK' },
        { name: 'CLAY', fips: '18021', id: '18021 - CLAY' },
        { name: 'CLINTON', fips: '18023', id: '18023 - CLINTON' },
        { name: 'CRAWFORD', fips: '18025', id: '18025 - CRAWFORD' },
        { name: 'DAVIESS', fips: '18027', id: '18027 - DAVIESS' },
        { name: 'DEARBORN', fips: '18029', id: '18029 - DEARBORN' },
        { name: 'DECATUR', fips: '18031', id: '18031 - DECATUR' },
        { name: 'DE KALB', fips: '18033', id: '18033 - DE KALB' },
        { name: 'DELAWARE', fips: '18035', id: '18035 - DELAWARE' },
        { name: 'DUBOIS', fips: '18037', id: '18037 - DUBOIS' },
        { name: 'ELKHART', fips: '18039', id: '18039 - ELKHART' },
        { name: 'FAYETTE', fips: '18041', id: '18041 - FAYETTE' },
        { name: 'FLOYD', fips: '18043', id: '18043 - FLOYD' },
        { name: 'FOUNTAIN', fips: '18045', id: '18045 - FOUNTAIN' },
        { name: 'FRANKLIN', fips: '18047', id: '18047 - FRANKLIN' },
        { name: 'FULTON', fips: '18049', id: '18049 - FULTON' },
        { name: 'GIBSON', fips: '18051', id: '18051 - GIBSON' },
        { name: 'GRANT', fips: '18053', id: '18053 - GRANT' },
        { name: 'GREENE', fips: '18055', id: '18055 - GREENE' },
        { name: 'HAMILTON', fips: '18057', id: '18057 - HAMILTON' },
        { name: 'HANCOCK', fips: '18059', id: '18059 - HANCOCK' },
        { name: 'HARRISON', fips: '18061', id: '18061 - HARRISON' },
        { name: 'HENDRICKS', fips: '18063', id: '18063 - HENDRICKS' },
        { name: 'HENRY', fips: '18065', id: '18065 - HENRY' },
        { name: 'HOWARD', fips: '18067', id: '18067 - HOWARD' },
        { name: 'HUNTINGTON', fips: '18069', id: '18069 - HUNTINGTON' },
        { name: 'JACKSON', fips: '18071', id: '18071 - JACKSON' },
        { name: 'JASPER', fips: '18073', id: '18073 - JASPER' },
        { name: 'JAY', fips: '18075', id: '18075 - JAY' },
        { name: 'JEFFERSON', fips: '18077', id: '18077 - JEFFERSON' },
        { name: 'JENNINGS', fips: '18079', id: '18079 - JENNINGS' },
        { name: 'JOHNSON', fips: '18081', id: '18081 - JOHNSON' },
        { name: 'KNOX', fips: '18083', id: '18083 - KNOX' },
        { name: 'KOSCIUSKO', fips: '18085', id: '18085 - KOSCIUSKO' },
        { name: 'LAGRANGE', fips: '18087', id: '18087 - LAGRANGE' },
        { name: 'LAKE', fips: '18089', id: '18089 - LAKE' },
        { name: 'LA PORTE', fips: '18091', id: '18091 - LA PORTE' },
        { name: 'LAWRENCE', fips: '18093', id: '18093 - LAWRENCE' },
        { name: 'MADISON', fips: '18095', id: '18095 - MADISON' },
        { name: 'MARION', fips: '18097', id: '18097 - MARION' },
        { name: 'MARSHALL', fips: '18099', id: '18099 - MARSHALL' },
        { name: 'MARTIN', fips: '18101', id: '18101 - MARTIN' },
        { name: 'MIAMI', fips: '18103', id: '18103 - MIAMI' },
        { name: 'MONROE', fips: '18105', id: '18105 - MONROE' },
        { name: 'MONTGOMERY', fips: '18107', id: '18107 - MONTGOMERY' },
        { name: 'MORGAN', fips: '18109', id: '18109 - MORGAN' },
        { name: 'NEWTON', fips: '18111', id: '18111 - NEWTON' },
        { name: 'NOBLE', fips: '18113', id: '18113 - NOBLE' },
        { name: 'OHIO', fips: '18115', id: '18115 - OHIO' },
        { name: 'ORANGE', fips: '18117', id: '18117 - ORANGE' },
        { name: 'OWEN', fips: '18119', id: '18119 - OWEN' },
        { name: 'PARKE', fips: '18121', id: '18121 - PARKE' },
        { name: 'PERRY', fips: '18123', id: '18123 - PERRY' },
        { name: 'PIKE', fips: '18125', id: '18125 - PIKE' },
        { name: 'PORTER', fips: '18127', id: '18127 - PORTER' },
        { name: 'POSEY', fips: '18129', id: '18129 - POSEY' },
        { name: 'PULASKI', fips: '18131', id: '18131 - PULASKI' },
        { name: 'PUTNAM', fips: '18133', id: '18133 - PUTNAM' },
        { name: 'RANDOLPH', fips: '18135', id: '18135 - RANDOLPH' },
        { name: 'RIPLEY', fips: '18137', id: '18137 - RIPLEY' },
        { name: 'RUSH', fips: '18139', id: '18139 - RUSH' },
        { name: 'ST. JOSEPH', fips: '18141', id: '18141 - ST. JOSEPH' },
        { name: 'SCOTT', fips: '18143', id: '18143 - SCOTT' },
        { name: 'SHELBY', fips: '18145', id: '18145 - SHELBY' },
        { name: 'SPENCER', fips: '18147', id: '18147 - SPENCER' },
        { name: 'STARKE', fips: '18149', id: '18149 - STARKE' },
        { name: 'STEUBEN', fips: '18151', id: '18151 - STEUBEN' },
        { name: 'SULLIVAN', fips: '18153', id: '18153 - SULLIVAN' },
        { name: 'SWITZERLAND', fips: '18155', id: '18155 - SWITZERLAND' },
        { name: 'TIPPECANOE', fips: '18157', id: '18157 - TIPPECANOE' },
        { name: 'TIPTON', fips: '18159', id: '18159 - TIPTON' },
        { name: 'UNION', fips: '18161', id: '18161 - UNION' },
        { name: 'VANDERBURGH', fips: '18163', id: '18163 - VANDERBURGH' },
        { name: 'VERMILLION', fips: '18165', id: '18165 - VERMILLION' },
        { name: 'VIGO', fips: '18167', id: '18167 - VIGO' },
        { name: 'WABASH', fips: '18169', id: '18169 - WABASH' },
        { name: 'WARREN', fips: '18171', id: '18171 - WARREN' },
        { name: 'WARRICK', fips: '18173', id: '18173 - WARRICK' },
        { name: 'WASHINGTON', fips: '18175', id: '18175 - WASHINGTON' },
        { name: 'WAYNE', fips: '18177', id: '18177 - WAYNE' },
        { name: 'WELLS', fips: '18179', id: '18179 - WELLS' },
        { name: 'WHITE', fips: '18181', id: '18181 - WHITE' },
        { name: 'WHITLEY', fips: '18183', id: '18183 - WHITLEY' },
    ],
    IA: [
        { name: 'ADAIR', fips: '19001', id: '19001 - ADAIR' },
        { name: 'ADAMS', fips: '19003', id: '19003 - ADAMS' },
        { name: 'ALLAMAKEE', fips: '19005', id: '19005 - ALLAMAKEE' },
        { name: 'APPANOOSE', fips: '19007', id: '19007 - APPANOOSE' },
        { name: 'AUDUBON', fips: '19009', id: '19009 - AUDUBON' },
        { name: 'BENTON', fips: '19011', id: '19011 - BENTON' },
        { name: 'BLACK HAWK', fips: '19013', id: '19013 - BLACK HAWK' },
        { name: 'BOONE', fips: '19015', id: '19015 - BOONE' },
        { name: 'BREMER', fips: '19017', id: '19017 - BREMER' },
        { name: 'BUCHANAN', fips: '19019', id: '19019 - BUCHANAN' },
        { name: 'BUENA VISTA', fips: '19021', id: '19021 - BUENA VISTA' },
        { name: 'BUTLER', fips: '19023', id: '19023 - BUTLER' },
        { name: 'CALHOUN', fips: '19025', id: '19025 - CALHOUN' },
        { name: 'CARROLL', fips: '19027', id: '19027 - CARROLL' },
        { name: 'CASS', fips: '19029', id: '19029 - CASS' },
        { name: 'CEDAR', fips: '19031', id: '19031 - CEDAR' },
        { name: 'CERRO GORDO', fips: '19033', id: '19033 - CERRO GORDO' },
        { name: 'CHEROKEE', fips: '19035', id: '19035 - CHEROKEE' },
        { name: 'CHICKASAW', fips: '19037', id: '19037 - CHICKASAW' },
        { name: 'CLARKE', fips: '19039', id: '19039 - CLARKE' },
        { name: 'CLAY', fips: '19041', id: '19041 - CLAY' },
        { name: 'CLAYTON', fips: '19043', id: '19043 - CLAYTON' },
        { name: 'CLINTON', fips: '19045', id: '19045 - CLINTON' },
        { name: 'CRAWFORD', fips: '19047', id: '19047 - CRAWFORD' },
        { name: 'DALLAS', fips: '19049', id: '19049 - DALLAS' },
        { name: 'DAVIS', fips: '19051', id: '19051 - DAVIS' },
        { name: 'DECATUR', fips: '19053', id: '19053 - DECATUR' },
        { name: 'DELAWARE', fips: '19055', id: '19055 - DELAWARE' },
        { name: 'DES MOINES', fips: '19057', id: '19057 - DES MOINES' },
        { name: 'DICKINSON', fips: '19059', id: '19059 - DICKINSON' },
        { name: 'DUBUQUE', fips: '19061', id: '19061 - DUBUQUE' },
        { name: 'EMMET', fips: '19063', id: '19063 - EMMET' },
        { name: 'FAYETTE', fips: '19065', id: '19065 - FAYETTE' },
        { name: 'FLOYD', fips: '19067', id: '19067 - FLOYD' },
        { name: 'FRANKLIN', fips: '19069', id: '19069 - FRANKLIN' },
        { name: 'FREMONT', fips: '19071', id: '19071 - FREMONT' },
        { name: 'GREENE', fips: '19073', id: '19073 - GREENE' },
        { name: 'GRUNDY', fips: '19075', id: '19075 - GRUNDY' },
        { name: 'GUTHRIE', fips: '19077', id: '19077 - GUTHRIE' },
        { name: 'HAMILTON', fips: '19079', id: '19079 - HAMILTON' },
        { name: 'HANCOCK', fips: '19081', id: '19081 - HANCOCK' },
        { name: 'HARDIN', fips: '19083', id: '19083 - HARDIN' },
        { name: 'HARRISON', fips: '19085', id: '19085 - HARRISON' },
        { name: 'HENRY', fips: '19087', id: '19087 - HENRY' },
        { name: 'HOWARD', fips: '19089', id: '19089 - HOWARD' },
        { name: 'HUMBOLDT', fips: '19091', id: '19091 - HUMBOLDT' },
        { name: 'IDA', fips: '19093', id: '19093 - IDA' },
        { name: 'IOWA', fips: '19095', id: '19095 - IOWA' },
        { name: 'JACKSON', fips: '19097', id: '19097 - JACKSON' },
        { name: 'JASPER', fips: '19099', id: '19099 - JASPER' },
        { name: 'JEFFERSON', fips: '19101', id: '19101 - JEFFERSON' },
        { name: 'JOHNSON', fips: '19103', id: '19103 - JOHNSON' },
        { name: 'JONES', fips: '19105', id: '19105 - JONES' },
        { name: 'KEOKUK', fips: '19107', id: '19107 - KEOKUK' },
        { name: 'KOSSUTH', fips: '19109', id: '19109 - KOSSUTH' },
        { name: 'LEE', fips: '19111', id: '19111 - LEE' },
        { name: 'LINN', fips: '19113', id: '19113 - LINN' },
        { name: 'LOUISA', fips: '19115', id: '19115 - LOUISA' },
        { name: 'LUCAS', fips: '19117', id: '19117 - LUCAS' },
        { name: 'LYON', fips: '19119', id: '19119 - LYON' },
        { name: 'MADISON', fips: '19121', id: '19121 - MADISON' },
        { name: 'MAHASKA', fips: '19123', id: '19123 - MAHASKA' },
        { name: 'MARION', fips: '19125', id: '19125 - MARION' },
        { name: 'MARSHALL', fips: '19127', id: '19127 - MARSHALL' },
        { name: 'MILLS', fips: '19129', id: '19129 - MILLS' },
        { name: 'MITCHELL', fips: '19131', id: '19131 - MITCHELL' },
        { name: 'MONONA', fips: '19133', id: '19133 - MONONA' },
        { name: 'MONROE', fips: '19135', id: '19135 - MONROE' },
        { name: 'MONTGOMERY', fips: '19137', id: '19137 - MONTGOMERY' },
        { name: 'MUSCATINE', fips: '19139', id: '19139 - MUSCATINE' },
        { name: 'O BRIEN', fips: '19141', id: '19141 - O BRIEN' },
        { name: 'OSCEOLA', fips: '19143', id: '19143 - OSCEOLA' },
        { name: 'PAGE', fips: '19145', id: '19145 - PAGE' },
        { name: 'PALO ALTO', fips: '19147', id: '19147 - PALO ALTO' },
        { name: 'PLYMOUTH', fips: '19149', id: '19149 - PLYMOUTH' },
        { name: 'POCAHONTAS', fips: '19151', id: '19151 - POCAHONTAS' },
        { name: 'POLK', fips: '19153', id: '19153 - POLK' },
        { name: 'POTTAWATTAMIE', fips: '19155', id: '19155 - POTTAWATTAMIE' },
        { name: 'POWESHIEK', fips: '19157', id: '19157 - POWESHIEK' },
        { name: 'RINGGOLD', fips: '19159', id: '19159 - RINGGOLD' },
        { name: 'SAC', fips: '19161', id: '19161 - SAC' },
        { name: 'SCOTT', fips: '19163', id: '19163 - SCOTT' },
        { name: 'SHELBY', fips: '19165', id: '19165 - SHELBY' },
        { name: 'SIOUX', fips: '19167', id: '19167 - SIOUX' },
        { name: 'STORY', fips: '19169', id: '19169 - STORY' },
        { name: 'TAMA', fips: '19171', id: '19171 - TAMA' },
        { name: 'TAYLOR', fips: '19173', id: '19173 - TAYLOR' },
        { name: 'UNION', fips: '19175', id: '19175 - UNION' },
        { name: 'VAN BUREN', fips: '19177', id: '19177 - VAN BUREN' },
        { name: 'WAPELLO', fips: '19179', id: '19179 - WAPELLO' },
        { name: 'WARREN', fips: '19181', id: '19181 - WARREN' },
        { name: 'WASHINGTON', fips: '19183', id: '19183 - WASHINGTON' },
        { name: 'WAYNE', fips: '19185', id: '19185 - WAYNE' },
        { name: 'WEBSTER', fips: '19187', id: '19187 - WEBSTER' },
        { name: 'WINNEBAGO', fips: '19189', id: '19189 - WINNEBAGO' },
        { name: 'WINNESHIEK', fips: '19191', id: '19191 - WINNESHIEK' },
        { name: 'WOODBURY', fips: '19193', id: '19193 - WOODBURY' },
        { name: 'WORTH', fips: '19195', id: '19195 - WORTH' },
        { name: 'WRIGHT', fips: '19197', id: '19197 - WRIGHT' },
    ],
    KS: [
        { name: 'ALLEN', fips: '20001', id: '20001 - ALLEN' },
        { name: 'ANDERSON', fips: '20003', id: '20003 - ANDERSON' },
        { name: 'ATCHISON', fips: '20005', id: '20005 - ATCHISON' },
        { name: 'BARBER', fips: '20007', id: '20007 - BARBER' },
        { name: 'BARTON', fips: '20009', id: '20009 - BARTON' },
        { name: 'BOURBON', fips: '20011', id: '20011 - BOURBON' },
        { name: 'BROWN', fips: '20013', id: '20013 - BROWN' },
        { name: 'BUTLER', fips: '20015', id: '20015 - BUTLER' },
        { name: 'CHASE', fips: '20017', id: '20017 - CHASE' },
        { name: 'CHAUTAUQUA', fips: '20019', id: '20019 - CHAUTAUQUA' },
        { name: 'CHEROKEE', fips: '20021', id: '20021 - CHEROKEE' },
        { name: 'CHEYENNE', fips: '20023', id: '20023 - CHEYENNE' },
        { name: 'CLARK', fips: '20025', id: '20025 - CLARK' },
        { name: 'CLAY', fips: '20027', id: '20027 - CLAY' },
        { name: 'CLOUD', fips: '20029', id: '20029 - CLOUD' },
        { name: 'COFFEY', fips: '20031', id: '20031 - COFFEY' },
        { name: 'COMANCHE', fips: '20033', id: '20033 - COMANCHE' },
        { name: 'COWLEY', fips: '20035', id: '20035 - COWLEY' },
        { name: 'CRAWFORD', fips: '20037', id: '20037 - CRAWFORD' },
        { name: 'DECATUR', fips: '20039', id: '20039 - DECATUR' },
        { name: 'DICKINSON', fips: '20041', id: '20041 - DICKINSON' },
        { name: 'DONIPHAN', fips: '20043', id: '20043 - DONIPHAN' },
        { name: 'DOUGLAS', fips: '20045', id: '20045 - DOUGLAS' },
        { name: 'EDWARDS', fips: '20047', id: '20047 - EDWARDS' },
        { name: 'ELK', fips: '20049', id: '20049 - ELK' },
        { name: 'ELLIS', fips: '20051', id: '20051 - ELLIS' },
        { name: 'ELLSWORTH', fips: '20053', id: '20053 - ELLSWORTH' },
        { name: 'FINNEY', fips: '20055', id: '20055 - FINNEY' },
        { name: 'FORD', fips: '20057', id: '20057 - FORD' },
        { name: 'FRANKLIN', fips: '20059', id: '20059 - FRANKLIN' },
        { name: 'GEARY', fips: '20061', id: '20061 - GEARY' },
        { name: 'GOVE', fips: '20063', id: '20063 - GOVE' },
        { name: 'GRAHAM', fips: '20065', id: '20065 - GRAHAM' },
        { name: 'GRANT', fips: '20067', id: '20067 - GRANT' },
        { name: 'GRAY', fips: '20069', id: '20069 - GRAY' },
        { name: 'GREELEY', fips: '20071', id: '20071 - GREELEY' },
        { name: 'GREENWOOD', fips: '20073', id: '20073 - GREENWOOD' },
        { name: 'HAMILTON', fips: '20075', id: '20075 - HAMILTON' },
        { name: 'HARPER', fips: '20077', id: '20077 - HARPER' },
        { name: 'HARVEY', fips: '20079', id: '20079 - HARVEY' },
        { name: 'HASKELL', fips: '20081', id: '20081 - HASKELL' },
        { name: 'HODGEMAN', fips: '20083', id: '20083 - HODGEMAN' },
        { name: 'JACKSON', fips: '20085', id: '20085 - JACKSON' },
        { name: 'JEFFERSON', fips: '20087', id: '20087 - JEFFERSON' },
        { name: 'JEWELL', fips: '20089', id: '20089 - JEWELL' },
        { name: 'JOHNSON', fips: '20091', id: '20091 - JOHNSON' },
        { name: 'KEARNY', fips: '20093', id: '20093 - KEARNY' },
        { name: 'KINGMAN', fips: '20095', id: '20095 - KINGMAN' },
        { name: 'KIOWA', fips: '20097', id: '20097 - KIOWA' },
        { name: 'LABETTE', fips: '20099', id: '20099 - LABETTE' },
        { name: 'LANE', fips: '20101', id: '20101 - LANE' },
        { name: 'LEAVENWORTH', fips: '20103', id: '20103 - LEAVENWORTH' },
        { name: 'LINCOLN', fips: '20105', id: '20105 - LINCOLN' },
        { name: 'LINN', fips: '20107', id: '20107 - LINN' },
        { name: 'LOGAN', fips: '20109', id: '20109 - LOGAN' },
        { name: 'LYON', fips: '20111', id: '20111 - LYON' },
        { name: 'MCPHERSON', fips: '20113', id: '20113 - MCPHERSON' },
        { name: 'MARION', fips: '20115', id: '20115 - MARION' },
        { name: 'MARSHALL', fips: '20117', id: '20117 - MARSHALL' },
        { name: 'MEADE', fips: '20119', id: '20119 - MEADE' },
        { name: 'MIAMI', fips: '20121', id: '20121 - MIAMI' },
        { name: 'MITCHELL', fips: '20123', id: '20123 - MITCHELL' },
        { name: 'MONTGOMERY', fips: '20125', id: '20125 - MONTGOMERY' },
        { name: 'MORRIS', fips: '20127', id: '20127 - MORRIS' },
        { name: 'MORTON', fips: '20129', id: '20129 - MORTON' },
        { name: 'NEMAHA', fips: '20131', id: '20131 - NEMAHA' },
        { name: 'NEOSHO', fips: '20133', id: '20133 - NEOSHO' },
        { name: 'NESS', fips: '20135', id: '20135 - NESS' },
        { name: 'NORTON', fips: '20137', id: '20137 - NORTON' },
        { name: 'OSAGE', fips: '20139', id: '20139 - OSAGE' },
        { name: 'OSBORNE', fips: '20141', id: '20141 - OSBORNE' },
        { name: 'OTTAWA', fips: '20143', id: '20143 - OTTAWA' },
        { name: 'PAWNEE', fips: '20145', id: '20145 - PAWNEE' },
        { name: 'PHILLIPS', fips: '20147', id: '20147 - PHILLIPS' },
        { name: 'POTTAWATOMIE', fips: '20149', id: '20149 - POTTAWATOMIE' },
        { name: 'PRATT', fips: '20151', id: '20151 - PRATT' },
        { name: 'RAWLINS', fips: '20153', id: '20153 - RAWLINS' },
        { name: 'RENO', fips: '20155', id: '20155 - RENO' },
        { name: 'REPUBLIC', fips: '20157', id: '20157 - REPUBLIC' },
        { name: 'RICE', fips: '20159', id: '20159 - RICE' },
        { name: 'RILEY', fips: '20161', id: '20161 - RILEY' },
        { name: 'ROOKS', fips: '20163', id: '20163 - ROOKS' },
        { name: 'RUSH', fips: '20165', id: '20165 - RUSH' },
        { name: 'RUSSELL', fips: '20167', id: '20167 - RUSSELL' },
        { name: 'SALINE', fips: '20169', id: '20169 - SALINE' },
        { name: 'SCOTT', fips: '20171', id: '20171 - SCOTT' },
        { name: 'SEDGWICK', fips: '20173', id: '20173 - SEDGWICK' },
        { name: 'SEWARD', fips: '20175', id: '20175 - SEWARD' },
        { name: 'SHAWNEE', fips: '20177', id: '20177 - SHAWNEE' },
        { name: 'SHERIDAN', fips: '20179', id: '20179 - SHERIDAN' },
        { name: 'SHERMAN', fips: '20181', id: '20181 - SHERMAN' },
        { name: 'SMITH', fips: '20183', id: '20183 - SMITH' },
        { name: 'STAFFORD', fips: '20185', id: '20185 - STAFFORD' },
        { name: 'STANTON', fips: '20187', id: '20187 - STANTON' },
        { name: 'STEVENS', fips: '20189', id: '20189 - STEVENS' },
        { name: 'SUMNER', fips: '20191', id: '20191 - SUMNER' },
        { name: 'THOMAS', fips: '20193', id: '20193 - THOMAS' },
        { name: 'TREGO', fips: '20195', id: '20195 - TREGO' },
        { name: 'WABAUNSEE', fips: '20197', id: '20197 - WABAUNSEE' },
        { name: 'WALLACE', fips: '20199', id: '20199 - WALLACE' },
        { name: 'WASHINGTON', fips: '20201', id: '20201 - WASHINGTON' },
        { name: 'WICHITA', fips: '20203', id: '20203 - WICHITA' },
        { name: 'WILSON', fips: '20205', id: '20205 - WILSON' },
        { name: 'WOODSON', fips: '20207', id: '20207 - WOODSON' },
        { name: 'WYANDOTTE', fips: '20209', id: '20209 - WYANDOTTE' },
    ],
    KY: [
        { name: 'ADAIR', fips: '21001', id: '21001 - ADAIR' },
        { name: 'ALLEN', fips: '21003', id: '21003 - ALLEN' },
        { name: 'ANDERSON', fips: '21005', id: '21005 - ANDERSON' },
        { name: 'BALLARD', fips: '21007', id: '21007 - BALLARD' },
        { name: 'BARREN', fips: '21009', id: '21009 - BARREN' },
        { name: 'BATH', fips: '21011', id: '21011 - BATH' },
        { name: 'BELL', fips: '21013', id: '21013 - BELL' },
        { name: 'BOONE', fips: '21015', id: '21015 - BOONE' },
        { name: 'BOURBON', fips: '21017', id: '21017 - BOURBON' },
        { name: 'BOYD', fips: '21019', id: '21019 - BOYD' },
        { name: 'BOYLE', fips: '21021', id: '21021 - BOYLE' },
        { name: 'BRACKEN', fips: '21023', id: '21023 - BRACKEN' },
        { name: 'BREATHITT', fips: '21025', id: '21025 - BREATHITT' },
        { name: 'BRECKINRIDGE', fips: '21027', id: '21027 - BRECKINRIDGE' },
        { name: 'BULLITT', fips: '21029', id: '21029 - BULLITT' },
        { name: 'BUTLER', fips: '21031', id: '21031 - BUTLER' },
        { name: 'CALDWELL', fips: '21033', id: '21033 - CALDWELL' },
        { name: 'CALLOWAY', fips: '21035', id: '21035 - CALLOWAY' },
        { name: 'CAMPBELL', fips: '21037', id: '21037 - CAMPBELL' },
        { name: 'CARLISLE', fips: '21039', id: '21039 - CARLISLE' },
        { name: 'CARROLL', fips: '21041', id: '21041 - CARROLL' },
        { name: 'CARTER', fips: '21043', id: '21043 - CARTER' },
        { name: 'CASEY', fips: '21045', id: '21045 - CASEY' },
        { name: 'CHRISTIAN', fips: '21047', id: '21047 - CHRISTIAN' },
        { name: 'CLARK', fips: '21049', id: '21049 - CLARK' },
        { name: 'CLAY', fips: '21051', id: '21051 - CLAY' },
        { name: 'CLINTON', fips: '21053', id: '21053 - CLINTON' },
        { name: 'CRITTENDEN', fips: '21055', id: '21055 - CRITTENDEN' },
        { name: 'CUMBERLAND', fips: '21057', id: '21057 - CUMBERLAND' },
        { name: 'DAVIESS', fips: '21059', id: '21059 - DAVIESS' },
        { name: 'EDMONSON', fips: '21061', id: '21061 - EDMONSON' },
        { name: 'ELLIOTT', fips: '21063', id: '21063 - ELLIOTT' },
        { name: 'ESTILL', fips: '21065', id: '21065 - ESTILL' },
        { name: 'FAYETTE', fips: '21067', id: '21067 - FAYETTE' },
        { name: 'FLEMING', fips: '21069', id: '21069 - FLEMING' },
        { name: 'FLOYD', fips: '21071', id: '21071 - FLOYD' },
        { name: 'FRANKLIN', fips: '21073', id: '21073 - FRANKLIN' },
        { name: 'FULTON', fips: '21075', id: '21075 - FULTON' },
        { name: 'GALLATIN', fips: '21077', id: '21077 - GALLATIN' },
        { name: 'GARRARD', fips: '21079', id: '21079 - GARRARD' },
        { name: 'GRANT', fips: '21081', id: '21081 - GRANT' },
        { name: 'GRAVES', fips: '21083', id: '21083 - GRAVES' },
        { name: 'GRAYSON', fips: '21085', id: '21085 - GRAYSON' },
        { name: 'GREEN', fips: '21087', id: '21087 - GREEN' },
        { name: 'GREENUP', fips: '21089', id: '21089 - GREENUP' },
        { name: 'HANCOCK', fips: '21091', id: '21091 - HANCOCK' },
        { name: 'HARDIN', fips: '21093', id: '21093 - HARDIN' },
        { name: 'HARLAN', fips: '21095', id: '21095 - HARLAN' },
        { name: 'HARRISON', fips: '21097', id: '21097 - HARRISON' },
        { name: 'HART', fips: '21099', id: '21099 - HART' },
        { name: 'HENDERSON', fips: '21101', id: '21101 - HENDERSON' },
        { name: 'HENRY', fips: '21103', id: '21103 - HENRY' },
        { name: 'HICKMAN', fips: '21105', id: '21105 - HICKMAN' },
        { name: 'HOPKINS', fips: '21107', id: '21107 - HOPKINS' },
        { name: 'JACKSON', fips: '21109', id: '21109 - JACKSON' },
        { name: 'JEFFERSON', fips: '21111', id: '21111 - JEFFERSON' },
        { name: 'JESSAMINE', fips: '21113', id: '21113 - JESSAMINE' },
        { name: 'JOHNSON', fips: '21115', id: '21115 - JOHNSON' },
        { name: 'KENTON', fips: '21117', id: '21117 - KENTON' },
        { name: 'KNOTT', fips: '21119', id: '21119 - KNOTT' },
        { name: 'KNOX', fips: '21121', id: '21121 - KNOX' },
        { name: 'LARUE', fips: '21123', id: '21123 - LARUE' },
        { name: 'LAUREL', fips: '21125', id: '21125 - LAUREL' },
        { name: 'LAWRENCE', fips: '21127', id: '21127 - LAWRENCE' },
        { name: 'LEE', fips: '21129', id: '21129 - LEE' },
        { name: 'LESLIE', fips: '21131', id: '21131 - LESLIE' },
        { name: 'LETCHER', fips: '21133', id: '21133 - LETCHER' },
        { name: 'LEWIS', fips: '21135', id: '21135 - LEWIS' },
        { name: 'LINCOLN', fips: '21137', id: '21137 - LINCOLN' },
        { name: 'LIVINGSTON', fips: '21139', id: '21139 - LIVINGSTON' },
        { name: 'LOGAN', fips: '21141', id: '21141 - LOGAN' },
        { name: 'LYON', fips: '21143', id: '21143 - LYON' },
        { name: 'MCCRACKEN', fips: '21145', id: '21145 - MCCRACKEN' },
        { name: 'MCCREARY', fips: '21147', id: '21147 - MCCREARY' },
        { name: 'MCLEAN', fips: '21149', id: '21149 - MCLEAN' },
        { name: 'MADISON', fips: '21151', id: '21151 - MADISON' },
        { name: 'MAGOFFIN', fips: '21153', id: '21153 - MAGOFFIN' },
        { name: 'MARION', fips: '21155', id: '21155 - MARION' },
        { name: 'MARSHALL', fips: '21157', id: '21157 - MARSHALL' },
        { name: 'MARTIN', fips: '21159', id: '21159 - MARTIN' },
        { name: 'MASON', fips: '21161', id: '21161 - MASON' },
        { name: 'MEADE', fips: '21163', id: '21163 - MEADE' },
        { name: 'MENIFEE', fips: '21165', id: '21165 - MENIFEE' },
        { name: 'MERCER', fips: '21167', id: '21167 - MERCER' },
        { name: 'METCALFE', fips: '21169', id: '21169 - METCALFE' },
        { name: 'MONROE', fips: '21171', id: '21171 - MONROE' },
        { name: 'MONTGOMERY', fips: '21173', id: '21173 - MONTGOMERY' },
        { name: 'MORGAN', fips: '21175', id: '21175 - MORGAN' },
        { name: 'MUHLENBERG', fips: '21177', id: '21177 - MUHLENBERG' },
        { name: 'NELSON', fips: '21179', id: '21179 - NELSON' },
        { name: 'NICHOLAS', fips: '21181', id: '21181 - NICHOLAS' },
        { name: 'OHIO', fips: '21183', id: '21183 - OHIO' },
        { name: 'OLDHAM', fips: '21185', id: '21185 - OLDHAM' },
        { name: 'OWEN', fips: '21187', id: '21187 - OWEN' },
        { name: 'OWSLEY', fips: '21189', id: '21189 - OWSLEY' },
        { name: 'PENDLETON', fips: '21191', id: '21191 - PENDLETON' },
        { name: 'PERRY', fips: '21193', id: '21193 - PERRY' },
        { name: 'PIKE', fips: '21195', id: '21195 - PIKE' },
        { name: 'POWELL', fips: '21197', id: '21197 - POWELL' },
        { name: 'PULASKI', fips: '21199', id: '21199 - PULASKI' },
        { name: 'ROBERTSON', fips: '21201', id: '21201 - ROBERTSON' },
        { name: 'ROCKCASTLE', fips: '21203', id: '21203 - ROCKCASTLE' },
        { name: 'ROWAN', fips: '21205', id: '21205 - ROWAN' },
        { name: 'RUSSELL', fips: '21207', id: '21207 - RUSSELL' },
        { name: 'SCOTT', fips: '21209', id: '21209 - SCOTT' },
        { name: 'SHELBY', fips: '21211', id: '21211 - SHELBY' },
        { name: 'SIMPSON', fips: '21213', id: '21213 - SIMPSON' },
        { name: 'SPENCER', fips: '21215', id: '21215 - SPENCER' },
        { name: 'TAYLOR', fips: '21217', id: '21217 - TAYLOR' },
        { name: 'TODD', fips: '21219', id: '21219 - TODD' },
        { name: 'TRIGG', fips: '21221', id: '21221 - TRIGG' },
        { name: 'TRIMBLE', fips: '21223', id: '21223 - TRIMBLE' },
        { name: 'UNION', fips: '21225', id: '21225 - UNION' },
        { name: 'WARREN', fips: '21227', id: '21227 - WARREN' },
        { name: 'WASHINGTON', fips: '21229', id: '21229 - WASHINGTON' },
        { name: 'WAYNE', fips: '21231', id: '21231 - WAYNE' },
        { name: 'WEBSTER', fips: '21233', id: '21233 - WEBSTER' },
        { name: 'WHITLEY', fips: '21235', id: '21235 - WHITLEY' },
        { name: 'WOLFE', fips: '21237', id: '21237 - WOLFE' },
        { name: 'WOODFORD', fips: '21239', id: '21239 - WOODFORD' },
    ],
    LA: [
        { name: 'ACADIA', fips: '22001', id: '22001 - ACADIA' },
        { name: 'ALLEN', fips: '22003', id: '22003 - ALLEN' },
        { name: 'ASCENSION', fips: '22005', id: '22005 - ASCENSION' },
        { name: 'ASSUMPTION', fips: '22007', id: '22007 - ASSUMPTION' },
        { name: 'AVOYELLES', fips: '22009', id: '22009 - AVOYELLES' },
        { name: 'BEAUREGARD', fips: '22011', id: '22011 - BEAUREGARD' },
        { name: 'BIENVILLE', fips: '22013', id: '22013 - BIENVILLE' },
        { name: 'BOSSIER', fips: '22015', id: '22015 - BOSSIER' },
        { name: 'CADDO', fips: '22017', id: '22017 - CADDO' },
        { name: 'CALCASIEU', fips: '22019', id: '22019 - CALCASIEU' },
        { name: 'CALDWELL', fips: '22021', id: '22021 - CALDWELL' },
        { name: 'CAMERON', fips: '22023', id: '22023 - CAMERON' },
        { name: 'CATAHOULA', fips: '22025', id: '22025 - CATAHOULA' },
        { name: 'CLAIBORNE', fips: '22027', id: '22027 - CLAIBORNE' },
        { name: 'CONCORDIA', fips: '22029', id: '22029 - CONCORDIA' },
        { name: 'DE SOTO', fips: '22031', id: '22031 - DE SOTO' },
        { name: 'EAST BATON ROUGE', fips: '22033', id: '22033 - EAST BATON ROUGE' },
        { name: 'EAST CARROLL', fips: '22035', id: '22035 - EAST CARROLL' },
        { name: 'EAST FELICIANA', fips: '22037', id: '22037 - EAST FELICIANA' },
        { name: 'EVANGELINE', fips: '22039', id: '22039 - EVANGELINE' },
        { name: 'FRANKLIN', fips: '22041', id: '22041 - FRANKLIN' },
        { name: 'GRANT', fips: '22043', id: '22043 - GRANT' },
        { name: 'IBERIA', fips: '22045', id: '22045 - IBERIA' },
        { name: 'IBERVILLE', fips: '22047', id: '22047 - IBERVILLE' },
        { name: 'JACKSON', fips: '22049', id: '22049 - JACKSON' },
        { name: 'JEFFERSON', fips: '22051', id: '22051 - JEFFERSON' },
        { name: 'JEFFERSON DAVIS', fips: '22053', id: '22053 - JEFFERSON DAVIS' },
        { name: 'LAFAYETTE', fips: '22055', id: '22055 - LAFAYETTE' },
        { name: 'LAFOURCHE', fips: '22057', id: '22057 - LAFOURCHE' },
        { name: 'LA SALLE', fips: '22059', id: '22059 - LA SALLE' },
        { name: 'LINCOLN', fips: '22061', id: '22061 - LINCOLN' },
        { name: 'LIVINGSTON', fips: '22063', id: '22063 - LIVINGSTON' },
        { name: 'MADISON', fips: '22065', id: '22065 - MADISON' },
        { name: 'MOREHOUSE', fips: '22067', id: '22067 - MOREHOUSE' },
        { name: 'NATCHITOCHES', fips: '22069', id: '22069 - NATCHITOCHES' },
        { name: 'ORLEANS', fips: '22071', id: '22071 - ORLEANS' },
        { name: 'OUACHITA', fips: '22073', id: '22073 - OUACHITA' },
        { name: 'PLAQUEMINES', fips: '22075', id: '22075 - PLAQUEMINES' },
        { name: 'POINTE COUPEE', fips: '22077', id: '22077 - POINTE COUPEE' },
        { name: 'RAPIDES', fips: '22079', id: '22079 - RAPIDES' },
        { name: 'RED RIVER', fips: '22081', id: '22081 - RED RIVER' },
        { name: 'RICHLAND', fips: '22083', id: '22083 - RICHLAND' },
        { name: 'SABINE', fips: '22085', id: '22085 - SABINE' },
        { name: 'ST. BERNARD', fips: '22087', id: '22087 - ST. BERNARD' },
        { name: 'ST. CHARLES', fips: '22089', id: '22089 - ST. CHARLES' },
        { name: 'ST. HELENA', fips: '22091', id: '22091 - ST. HELENA' },
        { name: 'ST. JAMES', fips: '22093', id: '22093 - ST. JAMES' },
        { name: 'ST. JOHN THE BAPTIST', fips: '22095', id: '22095 - ST. JOHN THE BAPTIST' },
        { name: 'ST. LANDRY', fips: '22097', id: '22097 - ST. LANDRY' },
        { name: 'ST. MARTIN', fips: '22099', id: '22099 - ST. MARTIN' },
        { name: 'ST. MARY', fips: '22101', id: '22101 - ST. MARY' },
        { name: 'ST. TAMMANY', fips: '22103', id: '22103 - ST. TAMMANY' },
        { name: 'TANGIPAHOA', fips: '22105', id: '22105 - TANGIPAHOA' },
        { name: 'TENSAS', fips: '22107', id: '22107 - TENSAS' },
        { name: 'TERREBONNE', fips: '22109', id: '22109 - TERREBONNE' },
        { name: 'UNION', fips: '22111', id: '22111 - UNION' },
        { name: 'VERMILION', fips: '22113', id: '22113 - VERMILION' },
        { name: 'VERNON', fips: '22115', id: '22115 - VERNON' },
        { name: 'WASHINGTON', fips: '22117', id: '22117 - WASHINGTON' },
        { name: 'WEBSTER', fips: '22119', id: '22119 - WEBSTER' },
        { name: 'WEST BATON ROUGE', fips: '22121', id: '22121 - WEST BATON ROUGE' },
        { name: 'WEST CARROLL', fips: '22123', id: '22123 - WEST CARROLL' },
        { name: 'WEST FELICIANA', fips: '22125', id: '22125 - WEST FELICIANA' },
        { name: 'WINN', fips: '22127', id: '22127 - WINN' },
    ],
    ME: [
        { name: 'ANDROSCOGGIN', fips: '23001', id: '23001 - ANDROSCOGGIN' },
        { name: 'AROOSTOOK', fips: '23003', id: '23003 - AROOSTOOK' },
        { name: 'CUMBERLAND', fips: '23005', id: '23005 - CUMBERLAND' },
        { name: 'FRANKLIN', fips: '23007', id: '23007 - FRANKLIN' },
        { name: 'HANCOCK', fips: '23009', id: '23009 - HANCOCK' },
        { name: 'KENNEBEC', fips: '23011', id: '23011 - KENNEBEC' },
        { name: 'KNOX', fips: '23013', id: '23013 - KNOX' },
        { name: 'LINCOLN', fips: '23015', id: '23015 - LINCOLN' },
        { name: 'OXFORD', fips: '23017', id: '23017 - OXFORD' },
        { name: 'PENOBSCOT', fips: '23019', id: '23019 - PENOBSCOT' },
        { name: 'PISCATAQUIS', fips: '23021', id: '23021 - PISCATAQUIS' },
        { name: 'SAGADAHOC', fips: '23023', id: '23023 - SAGADAHOC' },
        { name: 'SOMERSET', fips: '23025', id: '23025 - SOMERSET' },
        { name: 'WALDO', fips: '23027', id: '23027 - WALDO' },
        { name: 'WASHINGTON', fips: '23029', id: '23029 - WASHINGTON' },
        { name: 'YORK', fips: '23031', id: '23031 - YORK' },
    ],
    MD: [
        { name: 'ALLEGANY', fips: '24001', id: '24001 - ALLEGANY' },
        { name: 'ANNE ARUNDEL', fips: '24003', id: '24003 - ANNE ARUNDEL' },
        { name: 'BALTIMORE', fips: '24005', id: '24005 - BALTIMORE' },
        { name: 'CALVERT', fips: '24009', id: '24009 - CALVERT' },
        { name: 'CAROLINE', fips: '24011', id: '24011 - CAROLINE' },
        { name: 'CARROLL', fips: '24013', id: '24013 - CARROLL' },
        { name: 'CECIL', fips: '24015', id: '24015 - CECIL' },
        { name: 'CHARLES', fips: '24017', id: '24017 - CHARLES' },
        { name: 'DORCHESTER', fips: '24019', id: '24019 - DORCHESTER' },
        { name: 'FREDERICK', fips: '24021', id: '24021 - FREDERICK' },
        { name: 'GARRETT', fips: '24023', id: '24023 - GARRETT' },
        { name: 'HARFORD', fips: '24025', id: '24025 - HARFORD' },
        { name: 'HOWARD', fips: '24027', id: '24027 - HOWARD' },
        { name: 'KENT', fips: '24029', id: '24029 - KENT' },
        { name: 'MONTGOMERY', fips: '24031', id: '24031 - MONTGOMERY' },
        { name: 'PRINCE GEORGES', fips: '24033', id: '24033 - PRINCE GEORGES' },
        { name: 'QUEEN ANNES', fips: '24035', id: '24035 - QUEEN ANNES' },
        { name: 'ST. MARYS', fips: '24037', id: '24037 - ST. MARYS' },
        { name: 'SOMERSET', fips: '24039', id: '24039 - SOMERSET' },
        { name: 'TALBOT', fips: '24041', id: '24041 - TALBOT' },
        { name: 'WASHINGTON', fips: '24043', id: '24043 - WASHINGTON' },
        { name: 'WICOMICO', fips: '24045', id: '24045 - WICOMICO' },
        { name: 'WORCESTER', fips: '24047', id: '24047 - WORCESTER' },
        { name: 'BALTIMORE CITY', fips: '24510', id: '24510 - BALTIMORE CITY' },
    ],
    MA: [
        { name: 'BARNSTABLE', fips: '25001', id: '25001 - BARNSTABLE' },
        { name: 'BERKSHIRE', fips: '25003', id: '25003 - BERKSHIRE' },
        { name: 'BRISTOL', fips: '25005', id: '25005 - BRISTOL' },
        { name: 'DUKES', fips: '25007', id: '25007 - DUKES' },
        { name: 'ESSEX', fips: '25009', id: '25009 - ESSEX' },
        { name: 'FRANKLIN', fips: '25011', id: '25011 - FRANKLIN' },
        { name: 'HAMPDEN', fips: '25013', id: '25013 - HAMPDEN' },
        { name: 'HAMPSHIRE', fips: '25015', id: '25015 - HAMPSHIRE' },
        { name: 'MIDDLESEX', fips: '25017', id: '25017 - MIDDLESEX' },
        { name: 'NANTUCKET', fips: '25019', id: '25019 - NANTUCKET' },
        { name: 'NORFOLK', fips: '25021', id: '25021 - NORFOLK' },
        { name: 'PLYMOUTH', fips: '25023', id: '25023 - PLYMOUTH' },
        { name: 'SUFFOLK', fips: '25025', id: '25025 - SUFFOLK' },
        { name: 'WORCESTER', fips: '25027', id: '25027 - WORCESTER' },
    ],
    MI: [
        { name: 'ALCONA', fips: '26001', id: '26001 - ALCONA' },
        { name: 'ALGER', fips: '26003', id: '26003 - ALGER' },
        { name: 'ALLEGAN', fips: '26005', id: '26005 - ALLEGAN' },
        { name: 'ALPENA', fips: '26007', id: '26007 - ALPENA' },
        { name: 'ANTRIM', fips: '26009', id: '26009 - ANTRIM' },
        { name: 'ARENAC', fips: '26011', id: '26011 - ARENAC' },
        { name: 'BARAGA', fips: '26013', id: '26013 - BARAGA' },
        { name: 'BARRY', fips: '26015', id: '26015 - BARRY' },
        { name: 'BAY', fips: '26017', id: '26017 - BAY' },
        { name: 'BENZIE', fips: '26019', id: '26019 - BENZIE' },
        { name: 'BERRIEN', fips: '26021', id: '26021 - BERRIEN' },
        { name: 'BRANCH', fips: '26023', id: '26023 - BRANCH' },
        { name: 'CALHOUN', fips: '26025', id: '26025 - CALHOUN' },
        { name: 'CASS', fips: '26027', id: '26027 - CASS' },
        { name: 'CHARLEVOIX', fips: '26029', id: '26029 - CHARLEVOIX' },
        { name: 'CHEBOYGAN', fips: '26031', id: '26031 - CHEBOYGAN' },
        { name: 'CHIPPEWA', fips: '26033', id: '26033 - CHIPPEWA' },
        { name: 'CLARE', fips: '26035', id: '26035 - CLARE' },
        { name: 'CLINTON', fips: '26037', id: '26037 - CLINTON' },
        { name: 'CRAWFORD', fips: '26039', id: '26039 - CRAWFORD' },
        { name: 'DELTA', fips: '26041', id: '26041 - DELTA' },
        { name: 'DICKINSON', fips: '26043', id: '26043 - DICKINSON' },
        { name: 'EATON', fips: '26045', id: '26045 - EATON' },
        { name: 'EMMET', fips: '26047', id: '26047 - EMMET' },
        { name: 'GENESEE', fips: '26049', id: '26049 - GENESEE' },
        { name: 'GLADWIN', fips: '26051', id: '26051 - GLADWIN' },
        { name: 'GOGEBIC', fips: '26053', id: '26053 - GOGEBIC' },
        { name: 'GRAND TRAVERSE', fips: '26055', id: '26055 - GRAND TRAVERSE' },
        { name: 'GRATIOT', fips: '26057', id: '26057 - GRATIOT' },
        { name: 'HILLSDALE', fips: '26059', id: '26059 - HILLSDALE' },
        { name: 'HOUGHTON', fips: '26061', id: '26061 - HOUGHTON' },
        { name: 'HURON', fips: '26063', id: '26063 - HURON' },
        { name: 'INGHAM', fips: '26065', id: '26065 - INGHAM' },
        { name: 'IONIA', fips: '26067', id: '26067 - IONIA' },
        { name: 'IOSCO', fips: '26069', id: '26069 - IOSCO' },
        { name: 'IRON', fips: '26071', id: '26071 - IRON' },
        { name: 'ISABELLA', fips: '26073', id: '26073 - ISABELLA' },
        { name: 'JACKSON', fips: '26075', id: '26075 - JACKSON' },
        { name: 'KALAMAZOO', fips: '26077', id: '26077 - KALAMAZOO' },
        { name: 'KALKASKA', fips: '26079', id: '26079 - KALKASKA' },
        { name: 'KENT', fips: '26081', id: '26081 - KENT' },
        { name: 'KEWEENAW', fips: '26083', id: '26083 - KEWEENAW' },
        { name: 'LAKE', fips: '26085', id: '26085 - LAKE' },
        { name: 'LAPEER', fips: '26087', id: '26087 - LAPEER' },
        { name: 'LEELANAU', fips: '26089', id: '26089 - LEELANAU' },
        { name: 'LENAWEE', fips: '26091', id: '26091 - LENAWEE' },
        { name: 'LIVINGSTON', fips: '26093', id: '26093 - LIVINGSTON' },
        { name: 'LUCE', fips: '26095', id: '26095 - LUCE' },
        { name: 'MACKINAC', fips: '26097', id: '26097 - MACKINAC' },
        { name: 'MACOMB', fips: '26099', id: '26099 - MACOMB' },
        { name: 'MANISTEE', fips: '26101', id: '26101 - MANISTEE' },
        { name: 'MARQUETTE', fips: '26103', id: '26103 - MARQUETTE' },
        { name: 'MASON', fips: '26105', id: '26105 - MASON' },
        { name: 'MECOSTA', fips: '26107', id: '26107 - MECOSTA' },
        { name: 'MENOMINEE', fips: '26109', id: '26109 - MENOMINEE' },
        { name: 'MIDLAND', fips: '26111', id: '26111 - MIDLAND' },
        { name: 'MISSAUKEE', fips: '26113', id: '26113 - MISSAUKEE' },
        { name: 'MONROE', fips: '26115', id: '26115 - MONROE' },
        { name: 'MONTCALM', fips: '26117', id: '26117 - MONTCALM' },
        { name: 'MONTMORENCY', fips: '26119', id: '26119 - MONTMORENCY' },
        { name: 'MUSKEGON', fips: '26121', id: '26121 - MUSKEGON' },
        { name: 'NEWAYGO', fips: '26123', id: '26123 - NEWAYGO' },
        { name: 'OAKLAND', fips: '26125', id: '26125 - OAKLAND' },
        { name: 'OCEANA', fips: '26127', id: '26127 - OCEANA' },
        { name: 'OGEMAW', fips: '26129', id: '26129 - OGEMAW' },
        { name: 'ONTONAGON', fips: '26131', id: '26131 - ONTONAGON' },
        { name: 'OSCEOLA', fips: '26133', id: '26133 - OSCEOLA' },
        { name: 'OSCODA', fips: '26135', id: '26135 - OSCODA' },
        { name: 'OTSEGO', fips: '26137', id: '26137 - OTSEGO' },
        { name: 'OTTAWA', fips: '26139', id: '26139 - OTTAWA' },
        { name: 'PRESQUE ISLE', fips: '26141', id: '26141 - PRESQUE ISLE' },
        { name: 'ROSCOMMON', fips: '26143', id: '26143 - ROSCOMMON' },
        { name: 'SAGINAW', fips: '26145', id: '26145 - SAGINAW' },
        { name: 'ST. CLAIR', fips: '26147', id: '26147 - ST. CLAIR' },
        { name: 'ST. JOSEPH', fips: '26149', id: '26149 - ST. JOSEPH' },
        { name: 'SANILAC', fips: '26151', id: '26151 - SANILAC' },
        { name: 'SCHOOLCRAFT', fips: '26153', id: '26153 - SCHOOLCRAFT' },
        { name: 'SHIAWASSEE', fips: '26155', id: '26155 - SHIAWASSEE' },
        { name: 'TUSCOLA', fips: '26157', id: '26157 - TUSCOLA' },
        { name: 'VAN BUREN', fips: '26159', id: '26159 - VAN BUREN' },
        { name: 'WASHTENAW', fips: '26161', id: '26161 - WASHTENAW' },
        { name: 'WAYNE', fips: '26163', id: '26163 - WAYNE' },
        { name: 'WEXFORD', fips: '26165', id: '26165 - WEXFORD' },
    ],
    MN: [
        { name: 'AITKIN', fips: '27001', id: '27001 - AITKIN' },
        { name: 'ANOKA', fips: '27003', id: '27003 - ANOKA' },
        { name: 'BECKER', fips: '27005', id: '27005 - BECKER' },
        { name: 'BELTRAMI', fips: '27007', id: '27007 - BELTRAMI' },
        { name: 'BENTON', fips: '27009', id: '27009 - BENTON' },
        { name: 'BIG STONE', fips: '27011', id: '27011 - BIG STONE' },
        { name: 'BLUE EARTH', fips: '27013', id: '27013 - BLUE EARTH' },
        { name: 'BROWN', fips: '27015', id: '27015 - BROWN' },
        { name: 'CARLTON', fips: '27017', id: '27017 - CARLTON' },
        { name: 'CARVER', fips: '27019', id: '27019 - CARVER' },
        { name: 'CASS', fips: '27021', id: '27021 - CASS' },
        { name: 'CHIPPEWA', fips: '27023', id: '27023 - CHIPPEWA' },
        { name: 'CHISAGO', fips: '27025', id: '27025 - CHISAGO' },
        { name: 'CLAY', fips: '27027', id: '27027 - CLAY' },
        { name: 'CLEARWATER', fips: '27029', id: '27029 - CLEARWATER' },
        { name: 'COOK', fips: '27031', id: '27031 - COOK' },
        { name: 'COTTONWOOD', fips: '27033', id: '27033 - COTTONWOOD' },
        { name: 'CROW WING', fips: '27035', id: '27035 - CROW WING' },
        { name: 'DAKOTA', fips: '27037', id: '27037 - DAKOTA' },
        { name: 'DODGE', fips: '27039', id: '27039 - DODGE' },
        { name: 'DOUGLAS', fips: '27041', id: '27041 - DOUGLAS' },
        { name: 'FARIBAULT', fips: '27043', id: '27043 - FARIBAULT' },
        { name: 'FILLMORE', fips: '27045', id: '27045 - FILLMORE' },
        { name: 'FREEBORN', fips: '27047', id: '27047 - FREEBORN' },
        { name: 'GOODHUE', fips: '27049', id: '27049 - GOODHUE' },
        { name: 'GRANT', fips: '27051', id: '27051 - GRANT' },
        { name: 'HENNEPIN', fips: '27053', id: '27053 - HENNEPIN' },
        { name: 'HOUSTON', fips: '27055', id: '27055 - HOUSTON' },
        { name: 'HUBBARD', fips: '27057', id: '27057 - HUBBARD' },
        { name: 'ISANTI', fips: '27059', id: '27059 - ISANTI' },
        { name: 'ITASCA', fips: '27061', id: '27061 - ITASCA' },
        { name: 'JACKSON', fips: '27063', id: '27063 - JACKSON' },
        { name: 'KANABEC', fips: '27065', id: '27065 - KANABEC' },
        { name: 'KANDIYOHI', fips: '27067', id: '27067 - KANDIYOHI' },
        { name: 'KITTSON', fips: '27069', id: '27069 - KITTSON' },
        { name: 'KOOCHICHING', fips: '27071', id: '27071 - KOOCHICHING' },
        { name: 'LAC QUI PARLE', fips: '27073', id: '27073 - LAC QUI PARLE' },
        { name: 'LAKE', fips: '27075', id: '27075 - LAKE' },
        { name: 'LAKE OF THE WOODS', fips: '27077', id: '27077 - LAKE OF THE WOODS' },
        { name: 'LE SUEUR', fips: '27079', id: '27079 - LE SUEUR' },
        { name: 'LINCOLN', fips: '27081', id: '27081 - LINCOLN' },
        { name: 'LYON', fips: '27083', id: '27083 - LYON' },
        { name: 'MCLEOD', fips: '27085', id: '27085 - MCLEOD' },
        { name: 'MAHNOMEN', fips: '27087', id: '27087 - MAHNOMEN' },
        { name: 'MARSHALL', fips: '27089', id: '27089 - MARSHALL' },
        { name: 'MARTIN', fips: '27091', id: '27091 - MARTIN' },
        { name: 'MEEKER', fips: '27093', id: '27093 - MEEKER' },
        { name: 'MILLE LACS', fips: '27095', id: '27095 - MILLE LACS' },
        { name: 'MORRISON', fips: '27097', id: '27097 - MORRISON' },
        { name: 'MOWER', fips: '27099', id: '27099 - MOWER' },
        { name: 'MURRAY', fips: '27101', id: '27101 - MURRAY' },
        { name: 'NICOLLET', fips: '27103', id: '27103 - NICOLLET' },
        { name: 'NOBLES', fips: '27105', id: '27105 - NOBLES' },
        { name: 'NORMAN', fips: '27107', id: '27107 - NORMAN' },
        { name: 'OLMSTED', fips: '27109', id: '27109 - OLMSTED' },
        { name: 'OTTER TAIL', fips: '27111', id: '27111 - OTTER TAIL' },
        { name: 'PENNINGTON', fips: '27113', id: '27113 - PENNINGTON' },
        { name: 'PINE', fips: '27115', id: '27115 - PINE' },
        { name: 'PIPESTONE', fips: '27117', id: '27117 - PIPESTONE' },
        { name: 'POLK', fips: '27119', id: '27119 - POLK' },
        { name: 'POPE', fips: '27121', id: '27121 - POPE' },
        { name: 'RAMSEY', fips: '27123', id: '27123 - RAMSEY' },
        { name: 'RED LAKE', fips: '27125', id: '27125 - RED LAKE' },
        { name: 'REDWOOD', fips: '27127', id: '27127 - REDWOOD' },
        { name: 'RENVILLE', fips: '27129', id: '27129 - RENVILLE' },
        { name: 'RICE', fips: '27131', id: '27131 - RICE' },
        { name: 'ROCK', fips: '27133', id: '27133 - ROCK' },
        { name: 'ROSEAU', fips: '27135', id: '27135 - ROSEAU' },
        { name: 'ST. LOUIS', fips: '27137', id: '27137 - ST. LOUIS' },
        { name: 'SCOTT', fips: '27139', id: '27139 - SCOTT' },
        { name: 'SHERBURNE', fips: '27141', id: '27141 - SHERBURNE' },
        { name: 'SIBLEY', fips: '27143', id: '27143 - SIBLEY' },
        { name: 'STEARNS', fips: '27145', id: '27145 - STEARNS' },
        { name: 'STEELE', fips: '27147', id: '27147 - STEELE' },
        { name: 'STEVENS', fips: '27149', id: '27149 - STEVENS' },
        { name: 'SWIFT', fips: '27151', id: '27151 - SWIFT' },
        { name: 'TODD', fips: '27153', id: '27153 - TODD' },
        { name: 'TRAVERSE', fips: '27155', id: '27155 - TRAVERSE' },
        { name: 'WABASHA', fips: '27157', id: '27157 - WABASHA' },
        { name: 'WADENA', fips: '27159', id: '27159 - WADENA' },
        { name: 'WASECA', fips: '27161', id: '27161 - WASECA' },
        { name: 'WASHINGTON', fips: '27163', id: '27163 - WASHINGTON' },
        { name: 'WATONWAN', fips: '27165', id: '27165 - WATONWAN' },
        { name: 'WILKIN', fips: '27167', id: '27167 - WILKIN' },
        { name: 'WINONA', fips: '27169', id: '27169 - WINONA' },
        { name: 'WRIGHT', fips: '27171', id: '27171 - WRIGHT' },
        { name: 'YELLOW MEDICINE', fips: '27173', id: '27173 - YELLOW MEDICINE' },
    ],
    MS: [
        { name: 'ADAMS', fips: '28001', id: '28001 - ADAMS' },
        { name: 'ALCORN', fips: '28003', id: '28003 - ALCORN' },
        { name: 'AMITE', fips: '28005', id: '28005 - AMITE' },
        { name: 'ATTALA', fips: '28007', id: '28007 - ATTALA' },
        { name: 'BENTON', fips: '28009', id: '28009 - BENTON' },
        { name: 'BOLIVAR', fips: '28011', id: '28011 - BOLIVAR' },
        { name: 'CALHOUN', fips: '28013', id: '28013 - CALHOUN' },
        { name: 'CARROLL', fips: '28015', id: '28015 - CARROLL' },
        { name: 'CHICKASAW', fips: '28017', id: '28017 - CHICKASAW' },
        { name: 'CHOCTAW', fips: '28019', id: '28019 - CHOCTAW' },
        { name: 'CLAIBORNE', fips: '28021', id: '28021 - CLAIBORNE' },
        { name: 'CLARKE', fips: '28023', id: '28023 - CLARKE' },
        { name: 'CLAY', fips: '28025', id: '28025 - CLAY' },
        { name: 'COAHOMA', fips: '28027', id: '28027 - COAHOMA' },
        { name: 'COPIAH', fips: '28029', id: '28029 - COPIAH' },
        { name: 'COVINGTON', fips: '28031', id: '28031 - COVINGTON' },
        { name: 'DE SOTO', fips: '28033', id: '28033 - DE SOTO' },
        { name: 'FORREST', fips: '28035', id: '28035 - FORREST' },
        { name: 'FRANKLIN', fips: '28037', id: '28037 - FRANKLIN' },
        { name: 'GEORGE', fips: '28039', id: '28039 - GEORGE' },
        { name: 'GREENE', fips: '28041', id: '28041 - GREENE' },
        { name: 'GRENADA', fips: '28043', id: '28043 - GRENADA' },
        { name: 'HANCOCK', fips: '28045', id: '28045 - HANCOCK' },
        { name: 'HARRISON', fips: '28047', id: '28047 - HARRISON' },
        { name: 'HINDS', fips: '28049', id: '28049 - HINDS' },
        { name: 'HOLMES', fips: '28051', id: '28051 - HOLMES' },
        { name: 'HUMPHREYS', fips: '28053', id: '28053 - HUMPHREYS' },
        { name: 'ISSAQUENA', fips: '28055', id: '28055 - ISSAQUENA' },
        { name: 'ITAWAMBA', fips: '28057', id: '28057 - ITAWAMBA' },
        { name: 'JACKSON', fips: '28059', id: '28059 - JACKSON' },
        { name: 'JASPER', fips: '28061', id: '28061 - JASPER' },
        { name: 'JEFFERSON', fips: '28063', id: '28063 - JEFFERSON' },
        { name: 'JEFFERSON DAVIS', fips: '28065', id: '28065 - JEFFERSON DAVIS' },
        { name: 'JONES', fips: '28067', id: '28067 - JONES' },
        { name: 'KEMPER', fips: '28069', id: '28069 - KEMPER' },
        { name: 'LAFAYETTE', fips: '28071', id: '28071 - LAFAYETTE' },
        { name: 'LAMAR', fips: '28073', id: '28073 - LAMAR' },
        { name: 'LAUDERDALE', fips: '28075', id: '28075 - LAUDERDALE' },
        { name: 'LAWRENCE', fips: '28077', id: '28077 - LAWRENCE' },
        { name: 'LEAKE', fips: '28079', id: '28079 - LEAKE' },
        { name: 'LEE', fips: '28081', id: '28081 - LEE' },
        { name: 'LEFLORE', fips: '28083', id: '28083 - LEFLORE' },
        { name: 'LINCOLN', fips: '28085', id: '28085 - LINCOLN' },
        { name: 'LOWNDES', fips: '28087', id: '28087 - LOWNDES' },
        { name: 'MADISON', fips: '28089', id: '28089 - MADISON' },
        { name: 'MARION', fips: '28091', id: '28091 - MARION' },
        { name: 'MARSHALL', fips: '28093', id: '28093 - MARSHALL' },
        { name: 'MONROE', fips: '28095', id: '28095 - MONROE' },
        { name: 'MONTGOMERY', fips: '28097', id: '28097 - MONTGOMERY' },
        { name: 'NESHOBA', fips: '28099', id: '28099 - NESHOBA' },
        { name: 'NEWTON', fips: '28101', id: '28101 - NEWTON' },
        { name: 'NOXUBEE', fips: '28103', id: '28103 - NOXUBEE' },
        { name: 'OKTIBBEHA', fips: '28105', id: '28105 - OKTIBBEHA' },
        { name: 'PANOLA', fips: '28107', id: '28107 - PANOLA' },
        { name: 'PEARL RIVER', fips: '28109', id: '28109 - PEARL RIVER' },
        { name: 'PERRY', fips: '28111', id: '28111 - PERRY' },
        { name: 'PIKE', fips: '28113', id: '28113 - PIKE' },
        { name: 'PONTOTOC', fips: '28115', id: '28115 - PONTOTOC' },
        { name: 'PRENTISS', fips: '28117', id: '28117 - PRENTISS' },
        { name: 'QUITMAN', fips: '28119', id: '28119 - QUITMAN' },
        { name: 'RANKIN', fips: '28121', id: '28121 - RANKIN' },
        { name: 'SCOTT', fips: '28123', id: '28123 - SCOTT' },
        { name: 'SHARKEY', fips: '28125', id: '28125 - SHARKEY' },
        { name: 'SIMPSON', fips: '28127', id: '28127 - SIMPSON' },
        { name: 'SMITH', fips: '28129', id: '28129 - SMITH' },
        { name: 'STONE', fips: '28131', id: '28131 - STONE' },
        { name: 'SUNFLOWER', fips: '28133', id: '28133 - SUNFLOWER' },
        { name: 'TALLAHATCHIE', fips: '28135', id: '28135 - TALLAHATCHIE' },
        { name: 'TATE', fips: '28137', id: '28137 - TATE' },
        { name: 'TIPPAH', fips: '28139', id: '28139 - TIPPAH' },
        { name: 'TISHOMINGO', fips: '28141', id: '28141 - TISHOMINGO' },
        { name: 'TUNICA', fips: '28143', id: '28143 - TUNICA' },
        { name: 'UNION', fips: '28145', id: '28145 - UNION' },
        { name: 'WALTHALL', fips: '28147', id: '28147 - WALTHALL' },
        { name: 'WARREN', fips: '28149', id: '28149 - WARREN' },
        { name: 'WASHINGTON', fips: '28151', id: '28151 - WASHINGTON' },
        { name: 'WAYNE', fips: '28153', id: '28153 - WAYNE' },
        { name: 'WEBSTER', fips: '28155', id: '28155 - WEBSTER' },
        { name: 'WILKINSON', fips: '28157', id: '28157 - WILKINSON' },
        { name: 'WINSTON', fips: '28159', id: '28159 - WINSTON' },
        { name: 'YALOBUSHA', fips: '28161', id: '28161 - YALOBUSHA' },
        { name: 'YAZOO', fips: '28163', id: '28163 - YAZOO' },
    ],
    MO: [
        { name: 'ADAIR', fips: '29001', id: '29001 - ADAIR' },
        { name: 'ANDREW', fips: '29003', id: '29003 - ANDREW' },
        { name: 'ATCHISON', fips: '29005', id: '29005 - ATCHISON' },
        { name: 'AUDRAIN', fips: '29007', id: '29007 - AUDRAIN' },
        { name: 'BARRY', fips: '29009', id: '29009 - BARRY' },
        { name: 'BARTON', fips: '29011', id: '29011 - BARTON' },
        { name: 'BATES', fips: '29013', id: '29013 - BATES' },
        { name: 'BENTON', fips: '29015', id: '29015 - BENTON' },
        { name: 'BOLLINGER', fips: '29017', id: '29017 - BOLLINGER' },
        { name: 'BOONE', fips: '29019', id: '29019 - BOONE' },
        { name: 'BUCHANAN', fips: '29021', id: '29021 - BUCHANAN' },
        { name: 'BUTLER', fips: '29023', id: '29023 - BUTLER' },
        { name: 'CALDWELL', fips: '29025', id: '29025 - CALDWELL' },
        { name: 'CALLAWAY', fips: '29027', id: '29027 - CALLAWAY' },
        { name: 'CAMDEN', fips: '29029', id: '29029 - CAMDEN' },
        { name: 'CAPE GIRARDEAU', fips: '29031', id: '29031 - CAPE GIRARDEAU' },
        { name: 'CARROLL', fips: '29033', id: '29033 - CARROLL' },
        { name: 'CARTER', fips: '29035', id: '29035 - CARTER' },
        { name: 'CASS', fips: '29037', id: '29037 - CASS' },
        { name: 'CEDAR', fips: '29039', id: '29039 - CEDAR' },
        { name: 'CHARITON', fips: '29041', id: '29041 - CHARITON' },
        { name: 'CHRISTIAN', fips: '29043', id: '29043 - CHRISTIAN' },
        { name: 'CLARK', fips: '29045', id: '29045 - CLARK' },
        { name: 'CLAY', fips: '29047', id: '29047 - CLAY' },
        { name: 'CLINTON', fips: '29049', id: '29049 - CLINTON' },
        { name: 'COLE', fips: '29051', id: '29051 - COLE' },
        { name: 'COOPER', fips: '29053', id: '29053 - COOPER' },
        { name: 'CRAWFORD', fips: '29055', id: '29055 - CRAWFORD' },
        { name: 'DADE', fips: '29057', id: '29057 - DADE' },
        { name: 'DALLAS', fips: '29059', id: '29059 - DALLAS' },
        { name: 'DAVIESS', fips: '29061', id: '29061 - DAVIESS' },
        { name: 'DE KALB', fips: '29063', id: '29063 - DE KALB' },
        { name: 'DENT', fips: '29065', id: '29065 - DENT' },
        { name: 'DOUGLAS', fips: '29067', id: '29067 - DOUGLAS' },
        { name: 'DUNKLIN', fips: '29069', id: '29069 - DUNKLIN' },
        { name: 'FRANKLIN', fips: '29071', id: '29071 - FRANKLIN' },
        { name: 'GASCONADE', fips: '29073', id: '29073 - GASCONADE' },
        { name: 'GENTRY', fips: '29075', id: '29075 - GENTRY' },
        { name: 'GREENE', fips: '29077', id: '29077 - GREENE' },
        { name: 'GRUNDY', fips: '29079', id: '29079 - GRUNDY' },
        { name: 'HARRISON', fips: '29081', id: '29081 - HARRISON' },
        { name: 'HENRY', fips: '29083', id: '29083 - HENRY' },
        { name: 'HICKORY', fips: '29085', id: '29085 - HICKORY' },
        { name: 'HOLT', fips: '29087', id: '29087 - HOLT' },
        { name: 'HOWARD', fips: '29089', id: '29089 - HOWARD' },
        { name: 'HOWELL', fips: '29091', id: '29091 - HOWELL' },
        { name: 'IRON', fips: '29093', id: '29093 - IRON' },
        { name: 'JACKSON', fips: '29095', id: '29095 - JACKSON' },
        { name: 'JASPER', fips: '29097', id: '29097 - JASPER' },
        { name: 'JEFFERSON', fips: '29099', id: '29099 - JEFFERSON' },
        { name: 'JOHNSON', fips: '29101', id: '29101 - JOHNSON' },
        { name: 'KNOX', fips: '29103', id: '29103 - KNOX' },
        { name: 'LACLEDE', fips: '29105', id: '29105 - LACLEDE' },
        { name: 'LAFAYETTE', fips: '29107', id: '29107 - LAFAYETTE' },
        { name: 'LAWRENCE', fips: '29109', id: '29109 - LAWRENCE' },
        { name: 'LEWIS', fips: '29111', id: '29111 - LEWIS' },
        { name: 'LINCOLN', fips: '29113', id: '29113 - LINCOLN' },
        { name: 'LINN', fips: '29115', id: '29115 - LINN' },
        { name: 'LIVINGSTON', fips: '29117', id: '29117 - LIVINGSTON' },
        { name: 'MCDONALD', fips: '29119', id: '29119 - MCDONALD' },
        { name: 'MACON', fips: '29121', id: '29121 - MACON' },
        { name: 'MADISON', fips: '29123', id: '29123 - MADISON' },
        { name: 'MARIES', fips: '29125', id: '29125 - MARIES' },
        { name: 'MARION', fips: '29127', id: '29127 - MARION' },
        { name: 'MERCER', fips: '29129', id: '29129 - MERCER' },
        { name: 'MILLER', fips: '29131', id: '29131 - MILLER' },
        { name: 'MISSISSIPPI', fips: '29133', id: '29133 - MISSISSIPPI' },
        { name: 'MONITEAU', fips: '29135', id: '29135 - MONITEAU' },
        { name: 'MONROE', fips: '29137', id: '29137 - MONROE' },
        { name: 'MONTGOMERY', fips: '29139', id: '29139 - MONTGOMERY' },
        { name: 'MORGAN', fips: '29141', id: '29141 - MORGAN' },
        { name: 'NEW MADRID', fips: '29143', id: '29143 - NEW MADRID' },
        { name: 'NEWTON', fips: '29145', id: '29145 - NEWTON' },
        { name: 'NODAWAY', fips: '29147', id: '29147 - NODAWAY' },
        { name: 'OREGON', fips: '29149', id: '29149 - OREGON' },
        { name: 'OSAGE', fips: '29151', id: '29151 - OSAGE' },
        { name: 'OZARK', fips: '29153', id: '29153 - OZARK' },
        { name: 'PEMISCOT', fips: '29155', id: '29155 - PEMISCOT' },
        { name: 'PERRY', fips: '29157', id: '29157 - PERRY' },
        { name: 'PETTIS', fips: '29159', id: '29159 - PETTIS' },
        { name: 'PHELPS', fips: '29161', id: '29161 - PHELPS' },
        { name: 'PIKE', fips: '29163', id: '29163 - PIKE' },
        { name: 'PLATTE', fips: '29165', id: '29165 - PLATTE' },
        { name: 'POLK', fips: '29167', id: '29167 - POLK' },
        { name: 'PULASKI', fips: '29169', id: '29169 - PULASKI' },
        { name: 'PUTNAM', fips: '29171', id: '29171 - PUTNAM' },
        { name: 'RALLS', fips: '29173', id: '29173 - RALLS' },
        { name: 'RANDOLPH', fips: '29175', id: '29175 - RANDOLPH' },
        { name: 'RAY', fips: '29177', id: '29177 - RAY' },
        { name: 'REYNOLDS', fips: '29179', id: '29179 - REYNOLDS' },
        { name: 'RIPLEY', fips: '29181', id: '29181 - RIPLEY' },
        { name: 'ST. CHARLES', fips: '29183', id: '29183 - ST. CHARLES' },
        { name: 'ST. CLAIR', fips: '29185', id: '29185 - ST. CLAIR' },
        { name: 'STE. GENEVIEVE', fips: '29186', id: '29186 - STE. GENEVIEVE' },
        { name: 'ST. FRANCOIS', fips: '29187', id: '29187 - ST. FRANCOIS' },
        { name: 'ST. LOUIS', fips: '29189', id: '29189 - ST. LOUIS' },
        { name: 'SALINE', fips: '29195', id: '29195 - SALINE' },
        { name: 'SCHUYLER', fips: '29197', id: '29197 - SCHUYLER' },
        { name: 'SCOTLAND', fips: '29199', id: '29199 - SCOTLAND' },
        { name: 'SCOTT', fips: '29201', id: '29201 - SCOTT' },
        { name: 'SHANNON', fips: '29203', id: '29203 - SHANNON' },
        { name: 'SHELBY', fips: '29205', id: '29205 - SHELBY' },
        { name: 'STODDARD', fips: '29207', id: '29207 - STODDARD' },
        { name: 'STONE', fips: '29209', id: '29209 - STONE' },
        { name: 'SULLIVAN', fips: '29211', id: '29211 - SULLIVAN' },
        { name: 'TANEY', fips: '29213', id: '29213 - TANEY' },
        { name: 'TEXAS', fips: '29215', id: '29215 - TEXAS' },
        { name: 'VERNON', fips: '29217', id: '29217 - VERNON' },
        { name: 'WARREN', fips: '29219', id: '29219 - WARREN' },
        { name: 'WASHINGTON', fips: '29221', id: '29221 - WASHINGTON' },
        { name: 'WAYNE', fips: '29223', id: '29223 - WAYNE' },
        { name: 'WEBSTER', fips: '29225', id: '29225 - WEBSTER' },
        { name: 'WORTH', fips: '29227', id: '29227 - WORTH' },
        { name: 'WRIGHT', fips: '29229', id: '29229 - WRIGHT' },
        { name: 'ST. LOUIS CITY', fips: '29510', id: '29510 - ST. LOUIS CITY' },
    ],
    MT: [
        { name: 'BEAVERHEAD', fips: '30001', id: '30001 - BEAVERHEAD' },
        { name: 'BIG HORN', fips: '30003', id: '30003 - BIG HORN' },
        { name: 'BLAINE', fips: '30005', id: '30005 - BLAINE' },
        { name: 'BROADWATER', fips: '30007', id: '30007 - BROADWATER' },
        { name: 'CARBON', fips: '30009', id: '30009 - CARBON' },
        { name: 'CARTER', fips: '30011', id: '30011 - CARTER' },
        { name: 'CASCADE', fips: '30013', id: '30013 - CASCADE' },
        { name: 'CHOUTEAU', fips: '30015', id: '30015 - CHOUTEAU' },
        { name: 'CUSTER', fips: '30017', id: '30017 - CUSTER' },
        { name: 'DANIELS', fips: '30019', id: '30019 - DANIELS' },
        { name: 'DAWSON', fips: '30021', id: '30021 - DAWSON' },
        { name: 'DEER LODGE', fips: '30023', id: '30023 - DEER LODGE' },
        { name: 'FALLON', fips: '30025', id: '30025 - FALLON' },
        { name: 'FERGUS', fips: '30027', id: '30027 - FERGUS' },
        { name: 'FLATHEAD', fips: '30029', id: '30029 - FLATHEAD' },
        { name: 'GALLATIN', fips: '30031', id: '30031 - GALLATIN' },
        { name: 'GARFIELD', fips: '30033', id: '30033 - GARFIELD' },
        { name: 'GLACIER', fips: '30035', id: '30035 - GLACIER' },
        { name: 'GOLDEN VALLEY', fips: '30037', id: '30037 - GOLDEN VALLEY' },
        { name: 'GRANITE', fips: '30039', id: '30039 - GRANITE' },
        { name: 'HILL', fips: '30041', id: '30041 - HILL' },
        { name: 'JEFFERSON', fips: '30043', id: '30043 - JEFFERSON' },
        { name: 'JUDITH BASIN', fips: '30045', id: '30045 - JUDITH BASIN' },
        { name: 'LAKE', fips: '30047', id: '30047 - LAKE' },
        { name: 'LEWIS AND CLARK', fips: '30049', id: '30049 - LEWIS AND CLARK' },
        { name: 'LIBERTY', fips: '30051', id: '30051 - LIBERTY' },
        { name: 'LINCOLN', fips: '30053', id: '30053 - LINCOLN' },
        { name: 'MCCONE', fips: '30055', id: '30055 - MCCONE' },
        { name: 'MADISON', fips: '30057', id: '30057 - MADISON' },
        { name: 'MEAGHER', fips: '30059', id: '30059 - MEAGHER' },
        { name: 'MINERAL', fips: '30061', id: '30061 - MINERAL' },
        { name: 'MISSOULA', fips: '30063', id: '30063 - MISSOULA' },
        { name: 'MUSSELSHELL', fips: '30065', id: '30065 - MUSSELSHELL' },
        { name: 'PARK', fips: '30067', id: '30067 - PARK' },
        { name: 'PETROLEUM', fips: '30069', id: '30069 - PETROLEUM' },
        { name: 'PHILLIPS', fips: '30071', id: '30071 - PHILLIPS' },
        { name: 'PONDERA', fips: '30073', id: '30073 - PONDERA' },
        { name: 'POWDER RIVER', fips: '30075', id: '30075 - POWDER RIVER' },
        { name: 'POWELL', fips: '30077', id: '30077 - POWELL' },
        { name: 'PRAIRIE', fips: '30079', id: '30079 - PRAIRIE' },
        { name: 'RAVALLI', fips: '30081', id: '30081 - RAVALLI' },
        { name: 'RICHLAND', fips: '30083', id: '30083 - RICHLAND' },
        { name: 'ROOSEVELT', fips: '30085', id: '30085 - ROOSEVELT' },
        { name: 'ROSEBUD', fips: '30087', id: '30087 - ROSEBUD' },
        { name: 'SANDERS', fips: '30089', id: '30089 - SANDERS' },
        { name: 'SHERIDAN', fips: '30091', id: '30091 - SHERIDAN' },
        { name: 'SILVER BOW', fips: '30093', id: '30093 - SILVER BOW' },
        { name: 'STILLWATER', fips: '30095', id: '30095 - STILLWATER' },
        { name: 'SWEET GRASS', fips: '30097', id: '30097 - SWEET GRASS' },
        { name: 'TETON', fips: '30099', id: '30099 - TETON' },
        { name: 'TOOLE', fips: '30101', id: '30101 - TOOLE' },
        { name: 'TREASURE', fips: '30103', id: '30103 - TREASURE' },
        { name: 'VALLEY', fips: '30105', id: '30105 - VALLEY' },
        { name: 'WHEATLAND', fips: '30107', id: '30107 - WHEATLAND' },
        { name: 'WIBAUX', fips: '30109', id: '30109 - WIBAUX' },
        { name: 'YELLOWSTONE', fips: '30111', id: '30111 - YELLOWSTONE' },
        { name: 'YELLOWSTONE NATIONAL PARK', fips: '30113', id: '30113 - YELLOWSTONE NATIONAL PARK' },
    ],
    NE: [
        { name: 'ADAMS', fips: '31001', id: '31001 - ADAMS' },
        { name: 'ANTELOPE', fips: '31003', id: '31003 - ANTELOPE' },
        { name: 'ARTHUR', fips: '31005', id: '31005 - ARTHUR' },
        { name: 'BANNER', fips: '31007', id: '31007 - BANNER' },
        { name: 'BLAINE', fips: '31009', id: '31009 - BLAINE' },
        { name: 'BOONE', fips: '31011', id: '31011 - BOONE' },
        { name: 'BOX BUTTE', fips: '31013', id: '31013 - BOX BUTTE' },
        { name: 'BOYD', fips: '31015', id: '31015 - BOYD' },
        { name: 'BROWN', fips: '31017', id: '31017 - BROWN' },
        { name: 'BUFFALO', fips: '31019', id: '31019 - BUFFALO' },
        { name: 'BURT', fips: '31021', id: '31021 - BURT' },
        { name: 'BUTLER', fips: '31023', id: '31023 - BUTLER' },
        { name: 'CASS', fips: '31025', id: '31025 - CASS' },
        { name: 'CEDAR', fips: '31027', id: '31027 - CEDAR' },
        { name: 'CHASE', fips: '31029', id: '31029 - CHASE' },
        { name: 'CHERRY', fips: '31031', id: '31031 - CHERRY' },
        { name: 'CHEYENNE', fips: '31033', id: '31033 - CHEYENNE' },
        { name: 'CLAY', fips: '31035', id: '31035 - CLAY' },
        { name: 'COLFAX', fips: '31037', id: '31037 - COLFAX' },
        { name: 'CUMING', fips: '31039', id: '31039 - CUMING' },
        { name: 'CUSTER', fips: '31041', id: '31041 - CUSTER' },
        { name: 'DAKOTA', fips: '31043', id: '31043 - DAKOTA' },
        { name: 'DAWES', fips: '31045', id: '31045 - DAWES' },
        { name: 'DAWSON', fips: '31047', id: '31047 - DAWSON' },
        { name: 'DEUEL', fips: '31049', id: '31049 - DEUEL' },
        { name: 'DIXON', fips: '31051', id: '31051 - DIXON' },
        { name: 'DODGE', fips: '31053', id: '31053 - DODGE' },
        { name: 'DOUGLAS', fips: '31055', id: '31055 - DOUGLAS' },
        { name: 'DUNDY', fips: '31057', id: '31057 - DUNDY' },
        { name: 'FILLMORE', fips: '31059', id: '31059 - FILLMORE' },
        { name: 'FRANKLIN', fips: '31061', id: '31061 - FRANKLIN' },
        { name: 'FRONTIER', fips: '31063', id: '31063 - FRONTIER' },
        { name: 'FURNAS', fips: '31065', id: '31065 - FURNAS' },
        { name: 'GAGE', fips: '31067', id: '31067 - GAGE' },
        { name: 'GARDEN', fips: '31069', id: '31069 - GARDEN' },
        { name: 'GARFIELD', fips: '31071', id: '31071 - GARFIELD' },
        { name: 'GOSPER', fips: '31073', id: '31073 - GOSPER' },
        { name: 'GRANT', fips: '31075', id: '31075 - GRANT' },
        { name: 'GREELEY', fips: '31077', id: '31077 - GREELEY' },
        { name: 'HALL', fips: '31079', id: '31079 - HALL' },
        { name: 'HAMILTON', fips: '31081', id: '31081 - HAMILTON' },
        { name: 'HARLAN', fips: '31083', id: '31083 - HARLAN' },
        { name: 'HAYES', fips: '31085', id: '31085 - HAYES' },
        { name: 'HITCHCOCK', fips: '31087', id: '31087 - HITCHCOCK' },
        { name: 'HOLT', fips: '31089', id: '31089 - HOLT' },
        { name: 'HOOKER', fips: '31091', id: '31091 - HOOKER' },
        { name: 'HOWARD', fips: '31093', id: '31093 - HOWARD' },
        { name: 'JEFFERSON', fips: '31095', id: '31095 - JEFFERSON' },
        { name: 'JOHNSON', fips: '31097', id: '31097 - JOHNSON' },
        { name: 'KEARNEY', fips: '31099', id: '31099 - KEARNEY' },
        { name: 'KEITH', fips: '31101', id: '31101 - KEITH' },
        { name: 'KEYA PAHA', fips: '31103', id: '31103 - KEYA PAHA' },
        { name: 'KIMBALL', fips: '31105', id: '31105 - KIMBALL' },
        { name: 'KNOX', fips: '31107', id: '31107 - KNOX' },
        { name: 'LANCASTER', fips: '31109', id: '31109 - LANCASTER' },
        { name: 'LINCOLN', fips: '31111', id: '31111 - LINCOLN' },
        { name: 'LOGAN', fips: '31113', id: '31113 - LOGAN' },
        { name: 'LOUP', fips: '31115', id: '31115 - LOUP' },
        { name: 'MCPHERSON', fips: '31117', id: '31117 - MCPHERSON' },
        { name: 'MADISON', fips: '31119', id: '31119 - MADISON' },
        { name: 'MERRICK', fips: '31121', id: '31121 - MERRICK' },
        { name: 'MORRILL', fips: '31123', id: '31123 - MORRILL' },
        { name: 'NANCE', fips: '31125', id: '31125 - NANCE' },
        { name: 'NEMAHA', fips: '31127', id: '31127 - NEMAHA' },
        { name: 'NUCKOLLS', fips: '31129', id: '31129 - NUCKOLLS' },
        { name: 'OTOE', fips: '31131', id: '31131 - OTOE' },
        { name: 'PAWNEE', fips: '31133', id: '31133 - PAWNEE' },
        { name: 'PERKINS', fips: '31135', id: '31135 - PERKINS' },
        { name: 'PHELPS', fips: '31137', id: '31137 - PHELPS' },
        { name: 'PIERCE', fips: '31139', id: '31139 - PIERCE' },
        { name: 'PLATTE', fips: '31141', id: '31141 - PLATTE' },
        { name: 'POLK', fips: '31143', id: '31143 - POLK' },
        { name: 'RED WILLOW', fips: '31145', id: '31145 - RED WILLOW' },
        { name: 'RICHARDSON', fips: '31147', id: '31147 - RICHARDSON' },
        { name: 'ROCK', fips: '31149', id: '31149 - ROCK' },
        { name: 'SALINE', fips: '31151', id: '31151 - SALINE' },
        { name: 'SARPY', fips: '31153', id: '31153 - SARPY' },
        { name: 'SAUNDERS', fips: '31155', id: '31155 - SAUNDERS' },
        { name: 'SCOTTS BLUFF', fips: '31157', id: '31157 - SCOTTS BLUFF' },
        { name: 'SEWARD', fips: '31159', id: '31159 - SEWARD' },
        { name: 'SHERIDAN', fips: '31161', id: '31161 - SHERIDAN' },
        { name: 'SHERMAN', fips: '31163', id: '31163 - SHERMAN' },
        { name: 'SIOUX', fips: '31165', id: '31165 - SIOUX' },
        { name: 'STANTON', fips: '31167', id: '31167 - STANTON' },
        { name: 'THAYER', fips: '31169', id: '31169 - THAYER' },
        { name: 'THOMAS', fips: '31171', id: '31171 - THOMAS' },
        { name: 'THURSTON', fips: '31173', id: '31173 - THURSTON' },
        { name: 'VALLEY', fips: '31175', id: '31175 - VALLEY' },
        { name: 'WASHINGTON', fips: '31177', id: '31177 - WASHINGTON' },
        { name: 'WAYNE', fips: '31179', id: '31179 - WAYNE' },
        { name: 'WEBSTER', fips: '31181', id: '31181 - WEBSTER' },
        { name: 'WHEELER', fips: '31183', id: '31183 - WHEELER' },
        { name: 'YORK', fips: '31185', id: '31185 - YORK' },
    ],
    NV: [
        { name: 'CHURCHILL', fips: '32001', id: '32001 - CHURCHILL' },
        { name: 'CLARK', fips: '32003', id: '32003 - CLARK' },
        { name: 'DOUGLAS', fips: '32005', id: '32005 - DOUGLAS' },
        { name: 'ELKO', fips: '32007', id: '32007 - ELKO' },
        { name: 'ESMERALDA', fips: '32009', id: '32009 - ESMERALDA' },
        { name: 'EUREKA', fips: '32011', id: '32011 - EUREKA' },
        { name: 'HUMBOLDT', fips: '32013', id: '32013 - HUMBOLDT' },
        { name: 'LANDER', fips: '32015', id: '32015 - LANDER' },
        { name: 'LINCOLN', fips: '32017', id: '32017 - LINCOLN' },
        { name: 'LYON', fips: '32019', id: '32019 - LYON' },
        { name: 'MINERAL', fips: '32021', id: '32021 - MINERAL' },
        { name: 'NYE', fips: '32023', id: '32023 - NYE' },
        { name: 'PERSHING', fips: '32027', id: '32027 - PERSHING' },
        { name: 'STOREY', fips: '32029', id: '32029 - STOREY' },
        { name: 'WASHOE', fips: '32031', id: '32031 - WASHOE' },
        { name: 'WHITE PINE', fips: '32033', id: '32033 - WHITE PINE' },
        { name: 'CARSON CITY', fips: '32510', id: '32510 - CARSON CITY' },
    ],
    NH: [
        { name: 'BELKNAP', fips: '33001', id: '33001 - BELKNAP' },
        { name: 'CARROLL', fips: '33003', id: '33003 - CARROLL' },
        { name: 'CHESHIRE', fips: '33005', id: '33005 - CHESHIRE' },
        { name: 'COOS', fips: '33007', id: '33007 - COOS' },
        { name: 'GRAFTON', fips: '33009', id: '33009 - GRAFTON' },
        { name: 'HILLSBOROUGH', fips: '33011', id: '33011 - HILLSBOROUGH' },
        { name: 'MERRIMACK', fips: '33013', id: '33013 - MERRIMACK' },
        { name: 'ROCKINGHAM', fips: '33015', id: '33015 - ROCKINGHAM' },
        { name: 'STRAFFORD', fips: '33017', id: '33017 - STRAFFORD' },
        { name: 'SULLIVAN', fips: '33019', id: '33019 - SULLIVAN' },
    ],
    NJ: [
        { name: 'ATLANTIC', fips: '34001', id: '34001 - ATLANTIC' },
        { name: 'BERGEN', fips: '34003', id: '34003 - BERGEN' },
        { name: 'BURLINGTON', fips: '34005', id: '34005 - BURLINGTON' },
        { name: 'CAMDEN', fips: '34007', id: '34007 - CAMDEN' },
        { name: 'CAPE MAY', fips: '34009', id: '34009 - CAPE MAY' },
        { name: 'CUMBERLAND', fips: '34011', id: '34011 - CUMBERLAND' },
        { name: 'ESSEX', fips: '34013', id: '34013 - ESSEX' },
        { name: 'GLOUCESTER', fips: '34015', id: '34015 - GLOUCESTER' },
        { name: 'HUDSON', fips: '34017', id: '34017 - HUDSON' },
        { name: 'HUNTERDON', fips: '34019', id: '34019 - HUNTERDON' },
        { name: 'MERCER', fips: '34021', id: '34021 - MERCER' },
        { name: 'MIDDLESEX', fips: '34023', id: '34023 - MIDDLESEX' },
        { name: 'MONMOUTH', fips: '34025', id: '34025 - MONMOUTH' },
        { name: 'MORRIS', fips: '34027', id: '34027 - MORRIS' },
        { name: 'OCEAN', fips: '34029', id: '34029 - OCEAN' },
        { name: 'PASSAIC', fips: '34031', id: '34031 - PASSAIC' },
        { name: 'SALEM', fips: '34033', id: '34033 - SALEM' },
        { name: 'SOMERSET', fips: '34035', id: '34035 - SOMERSET' },
        { name: 'SUSSEX', fips: '34037', id: '34037 - SUSSEX' },
        { name: 'UNION', fips: '34039', id: '34039 - UNION' },
        { name: 'WARREN', fips: '34041', id: '34041 - WARREN' },
    ],
    NM: [
        { name: 'BERNALILLO', fips: '35001', id: '35001 - BERNALILLO' },
        { name: 'CATRON', fips: '35003', id: '35003 - CATRON' },
        { name: 'CHAVES', fips: '35005', id: '35005 - CHAVES' },
        { name: 'CIBOLA', fips: '35006', id: '35006 - CIBOLA' },
        { name: 'COLFAX', fips: '35007', id: '35007 - COLFAX' },
        { name: 'CURRY', fips: '35009', id: '35009 - CURRY' },
        { name: 'DE BACA', fips: '35011', id: '35011 - DE BACA' },
        { name: 'DONA ANA', fips: '35013', id: '35013 - DONA ANA' },
        { name: 'EDDY', fips: '35015', id: '35015 - EDDY' },
        { name: 'GRANT', fips: '35017', id: '35017 - GRANT' },
        { name: 'GUADALUPE', fips: '35019', id: '35019 - GUADALUPE' },
        { name: 'HARDING', fips: '35021', id: '35021 - HARDING' },
        { name: 'HIDALGO', fips: '35023', id: '35023 - HIDALGO' },
        { name: 'LEA', fips: '35025', id: '35025 - LEA' },
        { name: 'LINCOLN', fips: '35027', id: '35027 - LINCOLN' },
        { name: 'LOS ALAMOS', fips: '35028', id: '35028 - LOS ALAMOS' },
        { name: 'LUNA', fips: '35029', id: '35029 - LUNA' },
        { name: 'MCKINLEY', fips: '35031', id: '35031 - MCKINLEY' },
        { name: 'MORA', fips: '35033', id: '35033 - MORA' },
        { name: 'OTERO', fips: '35035', id: '35035 - OTERO' },
        { name: 'QUAY', fips: '35037', id: '35037 - QUAY' },
        { name: 'RIO ARRIBA', fips: '35039', id: '35039 - RIO ARRIBA' },
        { name: 'ROOSEVELT', fips: '35041', id: '35041 - ROOSEVELT' },
        { name: 'SANDOVAL', fips: '35043', id: '35043 - SANDOVAL' },
        { name: 'SAN JUAN', fips: '35045', id: '35045 - SAN JUAN' },
        { name: 'SAN MIGUEL', fips: '35047', id: '35047 - SAN MIGUEL' },
        { name: 'SANTA FE', fips: '35049', id: '35049 - SANTA FE' },
        { name: 'SIERRA', fips: '35051', id: '35051 - SIERRA' },
        { name: 'SOCORRO', fips: '35053', id: '35053 - SOCORRO' },
        { name: 'TAOS', fips: '35055', id: '35055 - TAOS' },
        { name: 'TORRANCE', fips: '35057', id: '35057 - TORRANCE' },
        { name: 'UNION', fips: '35059', id: '35059 - UNION' },
        { name: 'VALENCIA', fips: '35061', id: '35061 - VALENCIA' },
    ],
    NY: [
        { name: 'ALBANY', fips: '36001', id: '36001 - ALBANY' },
        { name: 'ALLEGANY', fips: '36003', id: '36003 - ALLEGANY' },
        { name: 'BRONX', fips: '36005', id: '36005 - BRONX' },
        { name: 'BROOME', fips: '36007', id: '36007 - BROOME' },
        { name: 'CATTARAUGUS', fips: '36009', id: '36009 - CATTARAUGUS' },
        { name: 'CAYUGA', fips: '36011', id: '36011 - CAYUGA' },
        { name: 'CHAUTAUQUA', fips: '36013', id: '36013 - CHAUTAUQUA' },
        { name: 'CHEMUNG', fips: '36015', id: '36015 - CHEMUNG' },
        { name: 'CHENANGO', fips: '36017', id: '36017 - CHENANGO' },
        { name: 'CLINTON', fips: '36019', id: '36019 - CLINTON' },
        { name: 'COLUMBIA', fips: '36021', id: '36021 - COLUMBIA' },
        { name: 'CORTLAND', fips: '36023', id: '36023 - CORTLAND' },
        { name: 'DELAWARE', fips: '36025', id: '36025 - DELAWARE' },
        { name: 'DUTCHESS', fips: '36027', id: '36027 - DUTCHESS' },
        { name: 'ERIE', fips: '36029', id: '36029 - ERIE' },
        { name: 'ESSEX', fips: '36031', id: '36031 - ESSEX' },
        { name: 'FRANKLIN', fips: '36033', id: '36033 - FRANKLIN' },
        { name: 'FULTON', fips: '36035', id: '36035 - FULTON' },
        { name: 'GENESEE', fips: '36037', id: '36037 - GENESEE' },
        { name: 'GREENE', fips: '36039', id: '36039 - GREENE' },
        { name: 'HAMILTON', fips: '36041', id: '36041 - HAMILTON' },
        { name: 'HERKIMER', fips: '36043', id: '36043 - HERKIMER' },
        { name: 'JEFFERSON', fips: '36045', id: '36045 - JEFFERSON' },
        { name: 'KINGS', fips: '36047', id: '36047 - KINGS' },
        { name: 'LEWIS', fips: '36049', id: '36049 - LEWIS' },
        { name: 'LIVINGSTON', fips: '36051', id: '36051 - LIVINGSTON' },
        { name: 'MADISON', fips: '36053', id: '36053 - MADISON' },
        { name: 'MONROE', fips: '36055', id: '36055 - MONROE' },
        { name: 'MONTGOMERY', fips: '36057', id: '36057 - MONTGOMERY' },
        { name: 'NASSAU', fips: '36059', id: '36059 - NASSAU' },
        { name: 'NEW YORK', fips: '36061', id: '36061 - NEW YORK' },
        { name: 'NIAGARA', fips: '36063', id: '36063 - NIAGARA' },
        { name: 'ONEIDA', fips: '36065', id: '36065 - ONEIDA' },
        { name: 'ONONDAGA', fips: '36067', id: '36067 - ONONDAGA' },
        { name: 'ONTARIO', fips: '36069', id: '36069 - ONTARIO' },
        { name: 'ORANGE', fips: '36071', id: '36071 - ORANGE' },
        { name: 'ORLEANS', fips: '36073', id: '36073 - ORLEANS' },
        { name: 'OSWEGO', fips: '36075', id: '36075 - OSWEGO' },
        { name: 'OTSEGO', fips: '36077', id: '36077 - OTSEGO' },
        { name: 'PUTNAM', fips: '36079', id: '36079 - PUTNAM' },
        { name: 'QUEENS', fips: '36081', id: '36081 - QUEENS' },
        { name: 'RENSSELAER', fips: '36083', id: '36083 - RENSSELAER' },
        { name: 'RICHMOND', fips: '36085', id: '36085 - RICHMOND' },
        { name: 'ROCKLAND', fips: '36087', id: '36087 - ROCKLAND' },
        { name: 'ST. LAWRENCE', fips: '36089', id: '36089 - ST. LAWRENCE' },
        { name: 'SARATOGA', fips: '36091', id: '36091 - SARATOGA' },
        { name: 'SCHENECTADY', fips: '36093', id: '36093 - SCHENECTADY' },
        { name: 'SCHOHARIE', fips: '36095', id: '36095 - SCHOHARIE' },
        { name: 'SCHUYLER', fips: '36097', id: '36097 - SCHUYLER' },
        { name: 'SENECA', fips: '36099', id: '36099 - SENECA' },
        { name: 'STEUBEN', fips: '36101', id: '36101 - STEUBEN' },
        { name: 'SUFFOLK', fips: '36103', id: '36103 - SUFFOLK' },
        { name: 'SULLIVAN', fips: '36105', id: '36105 - SULLIVAN' },
        { name: 'TIOGA', fips: '36107', id: '36107 - TIOGA' },
        { name: 'TOMPKINS', fips: '36109', id: '36109 - TOMPKINS' },
        { name: 'ULSTER', fips: '36111', id: '36111 - ULSTER' },
        { name: 'WARREN', fips: '36113', id: '36113 - WARREN' },
        { name: 'WASHINGTON', fips: '36115', id: '36115 - WASHINGTON' },
        { name: 'WAYNE', fips: '36117', id: '36117 - WAYNE' },
        { name: 'WESTCHESTER', fips: '36119', id: '36119 - WESTCHESTER' },
        { name: 'WYOMING', fips: '36121', id: '36121 - WYOMING' },
        { name: 'YATES', fips: '36123', id: '36123 - YATES' },
    ],
    NC: [
        { name: 'ALAMANCE', fips: '37001', id: '37001 - ALAMANCE' },
        { name: 'ALEXANDER', fips: '37003', id: '37003 - ALEXANDER' },
        { name: 'ALLEGHANY', fips: '37005', id: '37005 - ALLEGHANY' },
        { name: 'ANSON', fips: '37007', id: '37007 - ANSON' },
        { name: 'ASHE', fips: '37009', id: '37009 - ASHE' },
        { name: 'AVERY', fips: '37011', id: '37011 - AVERY' },
        { name: 'BEAUFORT', fips: '37013', id: '37013 - BEAUFORT' },
        { name: 'BERTIE', fips: '37015', id: '37015 - BERTIE' },
        { name: 'BLADEN', fips: '37017', id: '37017 - BLADEN' },
        { name: 'BRUNSWICK', fips: '37019', id: '37019 - BRUNSWICK' },
        { name: 'BUNCOMBE', fips: '37021', id: '37021 - BUNCOMBE' },
        { name: 'BURKE', fips: '37023', id: '37023 - BURKE' },
        { name: 'CABARRUS', fips: '37025', id: '37025 - CABARRUS' },
        { name: 'CALDWELL', fips: '37027', id: '37027 - CALDWELL' },
        { name: 'CAMDEN', fips: '37029', id: '37029 - CAMDEN' },
        { name: 'CARTERET', fips: '37031', id: '37031 - CARTERET' },
        { name: 'CASWELL', fips: '37033', id: '37033 - CASWELL' },
        { name: 'CATAWBA', fips: '37035', id: '37035 - CATAWBA' },
        { name: 'CHATHAM', fips: '37037', id: '37037 - CHATHAM' },
        { name: 'CHEROKEE', fips: '37039', id: '37039 - CHEROKEE' },
        { name: 'CHOWAN', fips: '37041', id: '37041 - CHOWAN' },
        { name: 'CLAY', fips: '37043', id: '37043 - CLAY' },
        { name: 'CLEVELAND', fips: '37045', id: '37045 - CLEVELAND' },
        { name: 'COLUMBUS', fips: '37047', id: '37047 - COLUMBUS' },
        { name: 'CRAVEN', fips: '37049', id: '37049 - CRAVEN' },
        { name: 'CUMBERLAND', fips: '37051', id: '37051 - CUMBERLAND' },
        { name: 'CURRITUCK', fips: '37053', id: '37053 - CURRITUCK' },
        { name: 'DARE', fips: '37055', id: '37055 - DARE' },
        { name: 'DAVIDSON', fips: '37057', id: '37057 - DAVIDSON' },
        { name: 'DAVIE', fips: '37059', id: '37059 - DAVIE' },
        { name: 'DUPLIN', fips: '37061', id: '37061 - DUPLIN' },
        { name: 'DURHAM', fips: '37063', id: '37063 - DURHAM' },
        { name: 'EDGECOMBE', fips: '37065', id: '37065 - EDGECOMBE' },
        { name: 'FORSYTH', fips: '37067', id: '37067 - FORSYTH' },
        { name: 'FRANKLIN', fips: '37069', id: '37069 - FRANKLIN' },
        { name: 'GASTON', fips: '37071', id: '37071 - GASTON' },
        { name: 'GATES', fips: '37073', id: '37073 - GATES' },
        { name: 'GRAHAM', fips: '37075', id: '37075 - GRAHAM' },
        { name: 'GRANVILLE', fips: '37077', id: '37077 - GRANVILLE' },
        { name: 'GREENE', fips: '37079', id: '37079 - GREENE' },
        { name: 'GUILFORD', fips: '37081', id: '37081 - GUILFORD' },
        { name: 'HALIFAX', fips: '37083', id: '37083 - HALIFAX' },
        { name: 'HARNETT', fips: '37085', id: '37085 - HARNETT' },
        { name: 'HAYWOOD', fips: '37087', id: '37087 - HAYWOOD' },
        { name: 'HENDERSON', fips: '37089', id: '37089 - HENDERSON' },
        { name: 'HERTFORD', fips: '37091', id: '37091 - HERTFORD' },
        { name: 'HOKE', fips: '37093', id: '37093 - HOKE' },
        { name: 'HYDE', fips: '37095', id: '37095 - HYDE' },
        { name: 'IREDELL', fips: '37097', id: '37097 - IREDELL' },
        { name: 'JACKSON', fips: '37099', id: '37099 - JACKSON' },
        { name: 'JOHNSTON', fips: '37101', id: '37101 - JOHNSTON' },
        { name: 'JONES', fips: '37103', id: '37103 - JONES' },
        { name: 'LEE', fips: '37105', id: '37105 - LEE' },
        { name: 'LENOIR', fips: '37107', id: '37107 - LENOIR' },
        { name: 'LINCOLN', fips: '37109', id: '37109 - LINCOLN' },
        { name: 'MCDOWELL', fips: '37111', id: '37111 - MCDOWELL' },
        { name: 'MACON', fips: '37113', id: '37113 - MACON' },
        { name: 'MADISON', fips: '37115', id: '37115 - MADISON' },
        { name: 'MARTIN', fips: '37117', id: '37117 - MARTIN' },
        { name: 'MECKLENBURG', fips: '37119', id: '37119 - MECKLENBURG' },
        { name: 'MITCHELL', fips: '37121', id: '37121 - MITCHELL' },
        { name: 'MONTGOMERY', fips: '37123', id: '37123 - MONTGOMERY' },
        { name: 'MOORE', fips: '37125', id: '37125 - MOORE' },
        { name: 'NASH', fips: '37127', id: '37127 - NASH' },
        { name: 'NEW HANOVER', fips: '37129', id: '37129 - NEW HANOVER' },
        { name: 'NORTHAMPTON', fips: '37131', id: '37131 - NORTHAMPTON' },
        { name: 'ONSLOW', fips: '37133', id: '37133 - ONSLOW' },
        { name: 'ORANGE', fips: '37135', id: '37135 - ORANGE' },
        { name: 'PAMLICO', fips: '37137', id: '37137 - PAMLICO' },
        { name: 'PASQUOTANK', fips: '37139', id: '37139 - PASQUOTANK' },
        { name: 'PENDER', fips: '37141', id: '37141 - PENDER' },
        { name: 'PERQUIMANS', fips: '37143', id: '37143 - PERQUIMANS' },
        { name: 'PERSON', fips: '37145', id: '37145 - PERSON' },
        { name: 'PITT', fips: '37147', id: '37147 - PITT' },
        { name: 'POLK', fips: '37149', id: '37149 - POLK' },
        { name: 'RANDOLPH', fips: '37151', id: '37151 - RANDOLPH' },
        { name: 'RICHMOND', fips: '37153', id: '37153 - RICHMOND' },
        { name: 'ROBESON', fips: '37155', id: '37155 - ROBESON' },
        { name: 'ROCKINGHAM', fips: '37157', id: '37157 - ROCKINGHAM' },
        { name: 'ROWAN', fips: '37159', id: '37159 - ROWAN' },
        { name: 'RUTHERFORD', fips: '37161', id: '37161 - RUTHERFORD' },
        { name: 'SAMPSON', fips: '37163', id: '37163 - SAMPSON' },
        { name: 'SCOTLAND', fips: '37165', id: '37165 - SCOTLAND' },
        { name: 'STANLY', fips: '37167', id: '37167 - STANLY' },
        { name: 'STOKES', fips: '37169', id: '37169 - STOKES' },
        { name: 'SURRY', fips: '37171', id: '37171 - SURRY' },
        { name: 'SWAIN', fips: '37173', id: '37173 - SWAIN' },
        { name: 'TRANSYLVANIA', fips: '37175', id: '37175 - TRANSYLVANIA' },
        { name: 'TYRRELL', fips: '37177', id: '37177 - TYRRELL' },
        { name: 'UNION', fips: '37179', id: '37179 - UNION' },
        { name: 'VANCE', fips: '37181', id: '37181 - VANCE' },
        { name: 'WAKE', fips: '37183', id: '37183 - WAKE' },
        { name: 'WARREN', fips: '37185', id: '37185 - WARREN' },
        { name: 'WASHINGTON', fips: '37187', id: '37187 - WASHINGTON' },
        { name: 'WATAUGA', fips: '37189', id: '37189 - WATAUGA' },
        { name: 'WAYNE', fips: '37191', id: '37191 - WAYNE' },
        { name: 'WILKES', fips: '37193', id: '37193 - WILKES' },
        { name: 'WILSON', fips: '37195', id: '37195 - WILSON' },
        { name: 'YADKIN', fips: '37197', id: '37197 - YADKIN' },
        { name: 'YANCEY', fips: '37199', id: '37199 - YANCEY' },
    ],
    ND: [
        { name: 'ADAMS', fips: '38001', id: '38001 - ADAMS' },
        { name: 'BARNES', fips: '38003', id: '38003 - BARNES' },
        { name: 'BENSON', fips: '38005', id: '38005 - BENSON' },
        { name: 'BILLINGS', fips: '38007', id: '38007 - BILLINGS' },
        { name: 'BOTTINEAU', fips: '38009', id: '38009 - BOTTINEAU' },
        { name: 'BOWMAN', fips: '38011', id: '38011 - BOWMAN' },
        { name: 'BURKE', fips: '38013', id: '38013 - BURKE' },
        { name: 'BURLEIGH', fips: '38015', id: '38015 - BURLEIGH' },
        { name: 'CASS', fips: '38017', id: '38017 - CASS' },
        { name: 'CAVALIER', fips: '38019', id: '38019 - CAVALIER' },
        { name: 'DICKEY', fips: '38021', id: '38021 - DICKEY' },
        { name: 'DIVIDE', fips: '38023', id: '38023 - DIVIDE' },
        { name: 'DUNN', fips: '38025', id: '38025 - DUNN' },
        { name: 'EDDY', fips: '38027', id: '38027 - EDDY' },
        { name: 'EMMONS', fips: '38029', id: '38029 - EMMONS' },
        { name: 'FOSTER', fips: '38031', id: '38031 - FOSTER' },
        { name: 'GOLDEN VALLEY', fips: '38033', id: '38033 - GOLDEN VALLEY' },
        { name: 'GRAND FORKS', fips: '38035', id: '38035 - GRAND FORKS' },
        { name: 'GRANT', fips: '38037', id: '38037 - GRANT' },
        { name: 'GRIGGS', fips: '38039', id: '38039 - GRIGGS' },
        { name: 'HETTINGER', fips: '38041', id: '38041 - HETTINGER' },
        { name: 'KIDDER', fips: '38043', id: '38043 - KIDDER' },
        { name: 'LA MOURE', fips: '38045', id: '38045 - LA MOURE' },
        { name: 'LOGAN', fips: '38047', id: '38047 - LOGAN' },
        { name: 'MCHENRY', fips: '38049', id: '38049 - MCHENRY' },
        { name: 'MCINTOSH', fips: '38051', id: '38051 - MCINTOSH' },
        { name: 'MCKENZIE', fips: '38053', id: '38053 - MCKENZIE' },
        { name: 'MCLEAN', fips: '38055', id: '38055 - MCLEAN' },
        { name: 'MERCER', fips: '38057', id: '38057 - MERCER' },
        { name: 'MORTON', fips: '38059', id: '38059 - MORTON' },
        { name: 'MOUNTRAIL', fips: '38061', id: '38061 - MOUNTRAIL' },
        { name: 'NELSON', fips: '38063', id: '38063 - NELSON' },
        { name: 'OLIVER', fips: '38065', id: '38065 - OLIVER' },
        { name: 'PEMBINA', fips: '38067', id: '38067 - PEMBINA' },
        { name: 'PIERCE', fips: '38069', id: '38069 - PIERCE' },
        { name: 'RAMSEY', fips: '38071', id: '38071 - RAMSEY' },
        { name: 'RANSOM', fips: '38073', id: '38073 - RANSOM' },
        { name: 'RENVILLE', fips: '38075', id: '38075 - RENVILLE' },
        { name: 'RICHLAND', fips: '38077', id: '38077 - RICHLAND' },
        { name: 'ROLETTE', fips: '38079', id: '38079 - ROLETTE' },
        { name: 'SARGENT', fips: '38081', id: '38081 - SARGENT' },
        { name: 'SHERIDAN', fips: '38083', id: '38083 - SHERIDAN' },
        { name: 'SIOUX', fips: '38085', id: '38085 - SIOUX' },
        { name: 'SLOPE', fips: '38087', id: '38087 - SLOPE' },
        { name: 'STARK', fips: '38089', id: '38089 - STARK' },
        { name: 'STEELE', fips: '38091', id: '38091 - STEELE' },
        { name: 'STUTSMAN', fips: '38093', id: '38093 - STUTSMAN' },
        { name: 'TOWNER', fips: '38095', id: '38095 - TOWNER' },
        { name: 'TRAILL', fips: '38097', id: '38097 - TRAILL' },
        { name: 'WALSH', fips: '38099', id: '38099 - WALSH' },
        { name: 'WARD', fips: '38101', id: '38101 - WARD' },
        { name: 'WELLS', fips: '38103', id: '38103 - WELLS' },
        { name: 'WILLIAMS', fips: '38105', id: '38105 - WILLIAMS' },
    ],
    OH: [
        { name: 'ADAMS', fips: '39001', id: '39001 - ADAMS' },
        { name: 'ALLEN', fips: '39003', id: '39003 - ALLEN' },
        { name: 'ASHLAND', fips: '39005', id: '39005 - ASHLAND' },
        { name: 'ASHTABULA', fips: '39007', id: '39007 - ASHTABULA' },
        { name: 'ATHENS', fips: '39009', id: '39009 - ATHENS' },
        { name: 'AUGLAIZE', fips: '39011', id: '39011 - AUGLAIZE' },
        { name: 'BELMONT', fips: '39013', id: '39013 - BELMONT' },
        { name: 'BROWN', fips: '39015', id: '39015 - BROWN' },
        { name: 'BUTLER', fips: '39017', id: '39017 - BUTLER' },
        { name: 'CARROLL', fips: '39019', id: '39019 - CARROLL' },
        { name: 'CHAMPAIGN', fips: '39021', id: '39021 - CHAMPAIGN' },
        { name: 'CLARK', fips: '39023', id: '39023 - CLARK' },
        { name: 'CLERMONT', fips: '39025', id: '39025 - CLERMONT' },
        { name: 'CLINTON', fips: '39027', id: '39027 - CLINTON' },
        { name: 'COLUMBIANA', fips: '39029', id: '39029 - COLUMBIANA' },
        { name: 'COSHOCTON', fips: '39031', id: '39031 - COSHOCTON' },
        { name: 'CRAWFORD', fips: '39033', id: '39033 - CRAWFORD' },
        { name: 'CUYAHOGA', fips: '39035', id: '39035 - CUYAHOGA' },
        { name: 'DARKE', fips: '39037', id: '39037 - DARKE' },
        { name: 'DEFIANCE', fips: '39039', id: '39039 - DEFIANCE' },
        { name: 'DELAWARE', fips: '39041', id: '39041 - DELAWARE' },
        { name: 'ERIE', fips: '39043', id: '39043 - ERIE' },
        { name: 'FAIRFIELD', fips: '39045', id: '39045 - FAIRFIELD' },
        { name: 'FAYETTE', fips: '39047', id: '39047 - FAYETTE' },
        { name: 'FRANKLIN', fips: '39049', id: '39049 - FRANKLIN' },
        { name: 'FULTON', fips: '39051', id: '39051 - FULTON' },
        { name: 'GALLIA', fips: '39053', id: '39053 - GALLIA' },
        { name: 'GEAUGA', fips: '39055', id: '39055 - GEAUGA' },
        { name: 'GREENE', fips: '39057', id: '39057 - GREENE' },
        { name: 'GUERNSEY', fips: '39059', id: '39059 - GUERNSEY' },
        { name: 'HAMILTON', fips: '39061', id: '39061 - HAMILTON' },
        { name: 'HANCOCK', fips: '39063', id: '39063 - HANCOCK' },
        { name: 'HARDIN', fips: '39065', id: '39065 - HARDIN' },
        { name: 'HARRISON', fips: '39067', id: '39067 - HARRISON' },
        { name: 'HENRY', fips: '39069', id: '39069 - HENRY' },
        { name: 'HIGHLAND', fips: '39071', id: '39071 - HIGHLAND' },
        { name: 'HOCKING', fips: '39073', id: '39073 - HOCKING' },
        { name: 'HOLMES', fips: '39075', id: '39075 - HOLMES' },
        { name: 'HURON', fips: '39077', id: '39077 - HURON' },
        { name: 'JACKSON', fips: '39079', id: '39079 - JACKSON' },
        { name: 'JEFFERSON', fips: '39081', id: '39081 - JEFFERSON' },
        { name: 'KNOX', fips: '39083', id: '39083 - KNOX' },
        { name: 'LAKE', fips: '39085', id: '39085 - LAKE' },
        { name: 'LAWRENCE', fips: '39087', id: '39087 - LAWRENCE' },
        { name: 'LICKING', fips: '39089', id: '39089 - LICKING' },
        { name: 'LOGAN', fips: '39091', id: '39091 - LOGAN' },
        { name: 'LORAIN', fips: '39093', id: '39093 - LORAIN' },
        { name: 'LUCAS', fips: '39095', id: '39095 - LUCAS' },
        { name: 'MADISON', fips: '39097', id: '39097 - MADISON' },
        { name: 'MAHONING', fips: '39099', id: '39099 - MAHONING' },
        { name: 'MARION', fips: '39101', id: '39101 - MARION' },
        { name: 'MEDINA', fips: '39103', id: '39103 - MEDINA' },
        { name: 'MEIGS', fips: '39105', id: '39105 - MEIGS' },
        { name: 'MERCER', fips: '39107', id: '39107 - MERCER' },
        { name: 'MIAMI', fips: '39109', id: '39109 - MIAMI' },
        { name: 'MONROE', fips: '39111', id: '39111 - MONROE' },
        { name: 'MONTGOMERY', fips: '39113', id: '39113 - MONTGOMERY' },
        { name: 'MORGAN', fips: '39115', id: '39115 - MORGAN' },
        { name: 'MORROW', fips: '39117', id: '39117 - MORROW' },
        { name: 'MUSKINGUM', fips: '39119', id: '39119 - MUSKINGUM' },
        { name: 'NOBLE', fips: '39121', id: '39121 - NOBLE' },
        { name: 'OTTAWA', fips: '39123', id: '39123 - OTTAWA' },
        { name: 'PAULDING', fips: '39125', id: '39125 - PAULDING' },
        { name: 'PERRY', fips: '39127', id: '39127 - PERRY' },
        { name: 'PICKAWAY', fips: '39129', id: '39129 - PICKAWAY' },
        { name: 'PIKE', fips: '39131', id: '39131 - PIKE' },
        { name: 'PORTAGE', fips: '39133', id: '39133 - PORTAGE' },
        { name: 'PREBLE', fips: '39135', id: '39135 - PREBLE' },
        { name: 'PUTNAM', fips: '39137', id: '39137 - PUTNAM' },
        { name: 'RICHLAND', fips: '39139', id: '39139 - RICHLAND' },
        { name: 'ROSS', fips: '39141', id: '39141 - ROSS' },
        { name: 'SANDUSKY', fips: '39143', id: '39143 - SANDUSKY' },
        { name: 'SCIOTO', fips: '39145', id: '39145 - SCIOTO' },
        { name: 'SENECA', fips: '39147', id: '39147 - SENECA' },
        { name: 'SHELBY', fips: '39149', id: '39149 - SHELBY' },
        { name: 'STARK', fips: '39151', id: '39151 - STARK' },
        { name: 'SUMMIT', fips: '39153', id: '39153 - SUMMIT' },
        { name: 'TRUMBULL', fips: '39155', id: '39155 - TRUMBULL' },
        { name: 'TUSCARAWAS', fips: '39157', id: '39157 - TUSCARAWAS' },
        { name: 'UNION', fips: '39159', id: '39159 - UNION' },
        { name: 'VAN WERT', fips: '39161', id: '39161 - VAN WERT' },
        { name: 'VINTON', fips: '39163', id: '39163 - VINTON' },
        { name: 'WARREN', fips: '39165', id: '39165 - WARREN' },
        { name: 'WASHINGTON', fips: '39167', id: '39167 - WASHINGTON' },
        { name: 'WAYNE', fips: '39169', id: '39169 - WAYNE' },
        { name: 'WILLIAMS', fips: '39171', id: '39171 - WILLIAMS' },
        { name: 'WOOD', fips: '39173', id: '39173 - WOOD' },
        { name: 'WYANDOT', fips: '39175', id: '39175 - WYANDOT' },
    ],
    OK: [
        { name: 'ADAIR', fips: '40001', id: '40001 - ADAIR' },
        { name: 'ALFALFA', fips: '40003', id: '40003 - ALFALFA' },
        { name: 'ATOKA', fips: '40005', id: '40005 - ATOKA' },
        { name: 'BEAVER', fips: '40007', id: '40007 - BEAVER' },
        { name: 'BECKHAM', fips: '40009', id: '40009 - BECKHAM' },
        { name: 'BLAINE', fips: '40011', id: '40011 - BLAINE' },
        { name: 'BRYAN', fips: '40013', id: '40013 - BRYAN' },
        { name: 'CADDO', fips: '40015', id: '40015 - CADDO' },
        { name: 'CANADIAN', fips: '40017', id: '40017 - CANADIAN' },
        { name: 'CARTER', fips: '40019', id: '40019 - CARTER' },
        { name: 'CHEROKEE', fips: '40021', id: '40021 - CHEROKEE' },
        { name: 'CHOCTAW', fips: '40023', id: '40023 - CHOCTAW' },
        { name: 'CIMARRON', fips: '40025', id: '40025 - CIMARRON' },
        { name: 'CLEVELAND', fips: '40027', id: '40027 - CLEVELAND' },
        { name: 'COAL', fips: '40029', id: '40029 - COAL' },
        { name: 'COMANCHE', fips: '40031', id: '40031 - COMANCHE' },
        { name: 'COTTON', fips: '40033', id: '40033 - COTTON' },
        { name: 'CRAIG', fips: '40035', id: '40035 - CRAIG' },
        { name: 'CREEK', fips: '40037', id: '40037 - CREEK' },
        { name: 'CUSTER', fips: '40039', id: '40039 - CUSTER' },
        { name: 'DELAWARE', fips: '40041', id: '40041 - DELAWARE' },
        { name: 'DEWEY', fips: '40043', id: '40043 - DEWEY' },
        { name: 'ELLIS', fips: '40045', id: '40045 - ELLIS' },
        { name: 'GARFIELD', fips: '40047', id: '40047 - GARFIELD' },
        { name: 'GARVIN', fips: '40049', id: '40049 - GARVIN' },
        { name: 'GRADY', fips: '40051', id: '40051 - GRADY' },
        { name: 'GRANT', fips: '40053', id: '40053 - GRANT' },
        { name: 'GREER', fips: '40055', id: '40055 - GREER' },
        { name: 'HARMON', fips: '40057', id: '40057 - HARMON' },
        { name: 'HARPER', fips: '40059', id: '40059 - HARPER' },
        { name: 'HASKELL', fips: '40061', id: '40061 - HASKELL' },
        { name: 'HUGHES', fips: '40063', id: '40063 - HUGHES' },
        { name: 'JACKSON', fips: '40065', id: '40065 - JACKSON' },
        { name: 'JEFFERSON', fips: '40067', id: '40067 - JEFFERSON' },
        { name: 'JOHNSTON', fips: '40069', id: '40069 - JOHNSTON' },
        { name: 'KAY', fips: '40071', id: '40071 - KAY' },
        { name: 'KINGFISHER', fips: '40073', id: '40073 - KINGFISHER' },
        { name: 'KIOWA', fips: '40075', id: '40075 - KIOWA' },
        { name: 'LATIMER', fips: '40077', id: '40077 - LATIMER' },
        { name: 'LE FLORE', fips: '40079', id: '40079 - LE FLORE' },
        { name: 'LINCOLN', fips: '40081', id: '40081 - LINCOLN' },
        { name: 'LOGAN', fips: '40083', id: '40083 - LOGAN' },
        { name: 'LOVE', fips: '40085', id: '40085 - LOVE' },
        { name: 'MCCLAIN', fips: '40087', id: '40087 - MCCLAIN' },
        { name: 'MCCURTAIN', fips: '40089', id: '40089 - MCCURTAIN' },
        { name: 'MCINTOSH', fips: '40091', id: '40091 - MCINTOSH' },
        { name: 'MAJOR', fips: '40093', id: '40093 - MAJOR' },
        { name: 'MARSHALL', fips: '40095', id: '40095 - MARSHALL' },
        { name: 'MAYES', fips: '40097', id: '40097 - MAYES' },
        { name: 'MURRAY', fips: '40099', id: '40099 - MURRAY' },
        { name: 'MUSKOGEE', fips: '40101', id: '40101 - MUSKOGEE' },
        { name: 'NOBLE', fips: '40103', id: '40103 - NOBLE' },
        { name: 'NOWATA', fips: '40105', id: '40105 - NOWATA' },
        { name: 'OKFUSKEE', fips: '40107', id: '40107 - OKFUSKEE' },
        { name: 'OKLAHOMA', fips: '40109', id: '40109 - OKLAHOMA' },
        { name: 'OKMULGEE', fips: '40111', id: '40111 - OKMULGEE' },
        { name: 'OSAGE', fips: '40113', id: '40113 - OSAGE' },
        { name: 'OTTAWA', fips: '40115', id: '40115 - OTTAWA' },
        { name: 'PAWNEE', fips: '40117', id: '40117 - PAWNEE' },
        { name: 'PAYNE', fips: '40119', id: '40119 - PAYNE' },
        { name: 'PITTSBURG', fips: '40121', id: '40121 - PITTSBURG' },
        { name: 'PONTOTOC', fips: '40123', id: '40123 - PONTOTOC' },
        { name: 'POTTAWATOMIE', fips: '40125', id: '40125 - POTTAWATOMIE' },
        { name: 'PUSHMATAHA', fips: '40127', id: '40127 - PUSHMATAHA' },
        { name: 'ROGER MILLS', fips: '40129', id: '40129 - ROGER MILLS' },
        { name: 'ROGERS', fips: '40131', id: '40131 - ROGERS' },
        { name: 'SEMINOLE', fips: '40133', id: '40133 - SEMINOLE' },
        { name: 'SEQUOYAH', fips: '40135', id: '40135 - SEQUOYAH' },
        { name: 'STEPHENS', fips: '40137', id: '40137 - STEPHENS' },
        { name: 'TEXAS', fips: '40139', id: '40139 - TEXAS' },
        { name: 'TILLMAN', fips: '40141', id: '40141 - TILLMAN' },
        { name: 'TULSA', fips: '40143', id: '40143 - TULSA' },
        { name: 'WAGONER', fips: '40145', id: '40145 - WAGONER' },
        { name: 'WASHINGTON', fips: '40147', id: '40147 - WASHINGTON' },
        { name: 'WASHITA', fips: '40149', id: '40149 - WASHITA' },
        { name: 'WOODS', fips: '40151', id: '40151 - WOODS' },
        { name: 'WOODWARD', fips: '40153', id: '40153 - WOODWARD' },
    ],
    OR: [
        { name: 'BAKER', fips: '41001', id: '41001 - BAKER' },
        { name: 'BENTON', fips: '41003', id: '41003 - BENTON' },
        { name: 'CLACKAMAS', fips: '41005', id: '41005 - CLACKAMAS' },
        { name: 'CLATSOP', fips: '41007', id: '41007 - CLATSOP' },
        { name: 'COLUMBIA', fips: '41009', id: '41009 - COLUMBIA' },
        { name: 'COOS', fips: '41011', id: '41011 - COOS' },
        { name: 'CROOK', fips: '41013', id: '41013 - CROOK' },
        { name: 'CURRY', fips: '41015', id: '41015 - CURRY' },
        { name: 'DESCHUTES', fips: '41017', id: '41017 - DESCHUTES' },
        { name: 'DOUGLAS', fips: '41019', id: '41019 - DOUGLAS' },
        { name: 'GILLIAM', fips: '41021', id: '41021 - GILLIAM' },
        { name: 'GRANT', fips: '41023', id: '41023 - GRANT' },
        { name: 'HARNEY', fips: '41025', id: '41025 - HARNEY' },
        { name: 'HOOD RIVER', fips: '41027', id: '41027 - HOOD RIVER' },
        { name: 'JACKSON', fips: '41029', id: '41029 - JACKSON' },
        { name: 'JEFFERSON', fips: '41031', id: '41031 - JEFFERSON' },
        { name: 'JOSEPHINE', fips: '41033', id: '41033 - JOSEPHINE' },
        { name: 'KLAMATH', fips: '41035', id: '41035 - KLAMATH' },
        { name: 'LAKE', fips: '41037', id: '41037 - LAKE' },
        { name: 'LANE', fips: '41039', id: '41039 - LANE' },
        { name: 'LINCOLN', fips: '41041', id: '41041 - LINCOLN' },
        { name: 'LINN', fips: '41043', id: '41043 - LINN' },
        { name: 'MALHEUR', fips: '41045', id: '41045 - MALHEUR' },
        { name: 'MARION', fips: '41047', id: '41047 - MARION' },
        { name: 'MORROW', fips: '41049', id: '41049 - MORROW' },
        { name: 'MULTNOMAH', fips: '41051', id: '41051 - MULTNOMAH' },
        { name: 'POLK', fips: '41053', id: '41053 - POLK' },
        { name: 'SHERMAN', fips: '41055', id: '41055 - SHERMAN' },
        { name: 'TILLAMOOK', fips: '41057', id: '41057 - TILLAMOOK' },
        { name: 'UMATILLA', fips: '41059', id: '41059 - UMATILLA' },
        { name: 'UNION', fips: '41061', id: '41061 - UNION' },
        { name: 'WALLOWA', fips: '41063', id: '41063 - WALLOWA' },
        { name: 'WASCO', fips: '41065', id: '41065 - WASCO' },
        { name: 'WASHINGTON', fips: '41067', id: '41067 - WASHINGTON' },
        { name: 'WHEELER', fips: '41069', id: '41069 - WHEELER' },
        { name: 'YAMHILL', fips: '41071', id: '41071 - YAMHILL' },
    ],
    PA: [
        { name: 'ADAMS', fips: '42001', id: '42001 - ADAMS' },
        { name: 'ALLEGHENY', fips: '42003', id: '42003 - ALLEGHENY' },
        { name: 'ARMSTRONG', fips: '42005', id: '42005 - ARMSTRONG' },
        { name: 'BEAVER', fips: '42007', id: '42007 - BEAVER' },
        { name: 'BEDFORD', fips: '42009', id: '42009 - BEDFORD' },
        { name: 'BERKS', fips: '42011', id: '42011 - BERKS' },
        { name: 'BLAIR', fips: '42013', id: '42013 - BLAIR' },
        { name: 'BRADFORD', fips: '42015', id: '42015 - BRADFORD' },
        { name: 'BUCKS', fips: '42017', id: '42017 - BUCKS' },
        { name: 'BUTLER', fips: '42019', id: '42019 - BUTLER' },
        { name: 'CAMBRIA', fips: '42021', id: '42021 - CAMBRIA' },
        { name: 'CAMERON', fips: '42023', id: '42023 - CAMERON' },
        { name: 'CARBON', fips: '42025', id: '42025 - CARBON' },
        { name: 'CENTRE', fips: '42027', id: '42027 - CENTRE' },
        { name: 'CHESTER', fips: '42029', id: '42029 - CHESTER' },
        { name: 'CLARION', fips: '42031', id: '42031 - CLARION' },
        { name: 'CLEARFIELD', fips: '42033', id: '42033 - CLEARFIELD' },
        { name: 'CLINTON', fips: '42035', id: '42035 - CLINTON' },
        { name: 'COLUMBIA', fips: '42037', id: '42037 - COLUMBIA' },
        { name: 'CRAWFORD', fips: '42039', id: '42039 - CRAWFORD' },
        { name: 'CUMBERLAND', fips: '42041', id: '42041 - CUMBERLAND' },
        { name: 'DAUPHIN', fips: '42043', id: '42043 - DAUPHIN' },
        { name: 'DELAWARE', fips: '42045', id: '42045 - DELAWARE' },
        { name: 'ELK', fips: '42047', id: '42047 - ELK' },
        { name: 'ERIE', fips: '42049', id: '42049 - ERIE' },
        { name: 'FAYETTE', fips: '42051', id: '42051 - FAYETTE' },
        { name: 'FOREST', fips: '42053', id: '42053 - FOREST' },
        { name: 'FRANKLIN', fips: '42055', id: '42055 - FRANKLIN' },
        { name: 'FULTON', fips: '42057', id: '42057 - FULTON' },
        { name: 'GREENE', fips: '42059', id: '42059 - GREENE' },
        { name: 'HUNTINGDON', fips: '42061', id: '42061 - HUNTINGDON' },
        { name: 'INDIANA', fips: '42063', id: '42063 - INDIANA' },
        { name: 'JEFFERSON', fips: '42065', id: '42065 - JEFFERSON' },
        { name: 'JUNIATA', fips: '42067', id: '42067 - JUNIATA' },
        { name: 'LACKAWANNA', fips: '42069', id: '42069 - LACKAWANNA' },
        { name: 'LANCASTER', fips: '42071', id: '42071 - LANCASTER' },
        { name: 'LAWRENCE', fips: '42073', id: '42073 - LAWRENCE' },
        { name: 'LEBANON', fips: '42075', id: '42075 - LEBANON' },
        { name: 'LEHIGH', fips: '42077', id: '42077 - LEHIGH' },
        { name: 'LUZERNE', fips: '42079', id: '42079 - LUZERNE' },
        { name: 'LYCOMING', fips: '42081', id: '42081 - LYCOMING' },
        { name: 'MCKEAN', fips: '42083', id: '42083 - MCKEAN' },
        { name: 'MERCER', fips: '42085', id: '42085 - MERCER' },
        { name: 'MIFFLIN', fips: '42087', id: '42087 - MIFFLIN' },
        { name: 'MONROE', fips: '42089', id: '42089 - MONROE' },
        { name: 'MONTGOMERY', fips: '42091', id: '42091 - MONTGOMERY' },
        { name: 'MONTOUR', fips: '42093', id: '42093 - MONTOUR' },
        { name: 'NORTHAMPTON', fips: '42095', id: '42095 - NORTHAMPTON' },
        { name: 'NORTHUMBERLAND', fips: '42097', id: '42097 - NORTHUMBERLAND' },
        { name: 'PERRY', fips: '42099', id: '42099 - PERRY' },
        { name: 'PHILADELPHIA', fips: '42101', id: '42101 - PHILADELPHIA' },
        { name: 'PIKE', fips: '42103', id: '42103 - PIKE' },
        { name: 'POTTER', fips: '42105', id: '42105 - POTTER' },
        { name: 'SCHUYLKILL', fips: '42107', id: '42107 - SCHUYLKILL' },
        { name: 'SNYDER', fips: '42109', id: '42109 - SNYDER' },
        { name: 'SOMERSET', fips: '42111', id: '42111 - SOMERSET' },
        { name: 'SULLIVAN', fips: '42113', id: '42113 - SULLIVAN' },
        { name: 'SUSQUEHANNA', fips: '42115', id: '42115 - SUSQUEHANNA' },
        { name: 'TIOGA', fips: '42117', id: '42117 - TIOGA' },
        { name: 'UNION', fips: '42119', id: '42119 - UNION' },
        { name: 'VENANGO', fips: '42121', id: '42121 - VENANGO' },
        { name: 'WARREN', fips: '42123', id: '42123 - WARREN' },
        { name: 'WASHINGTON', fips: '42125', id: '42125 - WASHINGTON' },
        { name: 'WAYNE', fips: '42127', id: '42127 - WAYNE' },
        { name: 'WESTMORELAND', fips: '42129', id: '42129 - WESTMORELAND' },
        { name: 'WYOMING', fips: '42131', id: '42131 - WYOMING' },
        { name: 'YORK', fips: '42133', id: '42133 - YORK' },
    ],
    RI: [
        { name: 'BRISTOL', fips: '44001', id: '44001 - BRISTOL' },
        { name: 'KENT', fips: '44003', id: '44003 - KENT' },
        { name: 'NEWPORT', fips: '44005', id: '44005 - NEWPORT' },
        { name: 'PROVIDENCE', fips: '44007', id: '44007 - PROVIDENCE' },
        { name: 'WASHINGTON', fips: '44009', id: '44009 - WASHINGTON' },
    ],
    SC: [
        { name: 'ABBEVILLE', fips: '45001', id: '45001 - ABBEVILLE' },
        { name: 'AIKEN', fips: '45003', id: '45003 - AIKEN' },
        { name: 'ALLENDALE', fips: '45005', id: '45005 - ALLENDALE' },
        { name: 'ANDERSON', fips: '45007', id: '45007 - ANDERSON' },
        { name: 'BAMBERG', fips: '45009', id: '45009 - BAMBERG' },
        { name: 'BARNWELL', fips: '45011', id: '45011 - BARNWELL' },
        { name: 'BEAUFORT', fips: '45013', id: '45013 - BEAUFORT' },
        { name: 'BERKELEY', fips: '45015', id: '45015 - BERKELEY' },
        { name: 'CALHOUN', fips: '45017', id: '45017 - CALHOUN' },
        { name: 'CHARLESTON', fips: '45019', id: '45019 - CHARLESTON' },
        { name: 'CHEROKEE', fips: '45021', id: '45021 - CHEROKEE' },
        { name: 'CHESTER', fips: '45023', id: '45023 - CHESTER' },
        { name: 'CHESTERFIELD', fips: '45025', id: '45025 - CHESTERFIELD' },
        { name: 'CLARENDON', fips: '45027', id: '45027 - CLARENDON' },
        { name: 'COLLETON', fips: '45029', id: '45029 - COLLETON' },
        { name: 'DARLINGTON', fips: '45031', id: '45031 - DARLINGTON' },
        { name: 'DILLON', fips: '45033', id: '45033 - DILLON' },
        { name: 'DORCHESTER', fips: '45035', id: '45035 - DORCHESTER' },
        { name: 'EDGEFIELD', fips: '45037', id: '45037 - EDGEFIELD' },
        { name: 'FAIRFIELD', fips: '45039', id: '45039 - FAIRFIELD' },
        { name: 'FLORENCE', fips: '45041', id: '45041 - FLORENCE' },
        { name: 'GEORGETOWN', fips: '45043', id: '45043 - GEORGETOWN' },
        { name: 'GREENVILLE', fips: '45045', id: '45045 - GREENVILLE' },
        { name: 'GREENWOOD', fips: '45047', id: '45047 - GREENWOOD' },
        { name: 'HAMPTON', fips: '45049', id: '45049 - HAMPTON' },
        { name: 'HORRY', fips: '45051', id: '45051 - HORRY' },
        { name: 'JASPER', fips: '45053', id: '45053 - JASPER' },
        { name: 'KERSHAW', fips: '45055', id: '45055 - KERSHAW' },
        { name: 'LANCASTER', fips: '45057', id: '45057 - LANCASTER' },
        { name: 'LAURENS', fips: '45059', id: '45059 - LAURENS' },
        { name: 'LEE', fips: '45061', id: '45061 - LEE' },
        { name: 'LEXINGTON', fips: '45063', id: '45063 - LEXINGTON' },
        { name: 'MCCORMICK', fips: '45065', id: '45065 - MCCORMICK' },
        { name: 'MARION', fips: '45067', id: '45067 - MARION' },
        { name: 'MARLBORO', fips: '45069', id: '45069 - MARLBORO' },
        { name: 'NEWBERRY', fips: '45071', id: '45071 - NEWBERRY' },
        { name: 'OCONEE', fips: '45073', id: '45073 - OCONEE' },
        { name: 'ORANGEBURG', fips: '45075', id: '45075 - ORANGEBURG' },
        { name: 'PICKENS', fips: '45077', id: '45077 - PICKENS' },
        { name: 'RICHLAND', fips: '45079', id: '45079 - RICHLAND' },
        { name: 'SALUDA', fips: '45081', id: '45081 - SALUDA' },
        { name: 'SPARTANBURG', fips: '45083', id: '45083 - SPARTANBURG' },
        { name: 'SUMTER', fips: '45085', id: '45085 - SUMTER' },
        { name: 'UNION', fips: '45087', id: '45087 - UNION' },
        { name: 'WILLIAMSBURG', fips: '45089', id: '45089 - WILLIAMSBURG' },
        { name: 'YORK', fips: '45091', id: '45091 - YORK' },
    ],
    SD: [
        { name: 'AURORA', fips: '46003', id: '46003 - AURORA' },
        { name: 'BEADLE', fips: '46005', id: '46005 - BEADLE' },
        { name: 'BENNETT', fips: '46007', id: '46007 - BENNETT' },
        { name: 'BON HOMME', fips: '46009', id: '46009 - BON HOMME' },
        { name: 'BROOKINGS', fips: '46011', id: '46011 - BROOKINGS' },
        { name: 'BROWN', fips: '46013', id: '46013 - BROWN' },
        { name: 'BRULE', fips: '46015', id: '46015 - BRULE' },
        { name: 'BUFFALO', fips: '46017', id: '46017 - BUFFALO' },
        { name: 'BUTTE', fips: '46019', id: '46019 - BUTTE' },
        { name: 'CAMPBELL', fips: '46021', id: '46021 - CAMPBELL' },
        { name: 'CHARLES MIX', fips: '46023', id: '46023 - CHARLES MIX' },
        { name: 'CLARK', fips: '46025', id: '46025 - CLARK' },
        { name: 'CLAY', fips: '46027', id: '46027 - CLAY' },
        { name: 'CODINGTON', fips: '46029', id: '46029 - CODINGTON' },
        { name: 'CORSON', fips: '46031', id: '46031 - CORSON' },
        { name: 'CUSTER', fips: '46033', id: '46033 - CUSTER' },
        { name: 'DAVISON', fips: '46035', id: '46035 - DAVISON' },
        { name: 'DAY', fips: '46037', id: '46037 - DAY' },
        { name: 'DEUEL', fips: '46039', id: '46039 - DEUEL' },
        { name: 'DEWEY', fips: '46041', id: '46041 - DEWEY' },
        { name: 'DOUGLAS', fips: '46043', id: '46043 - DOUGLAS' },
        { name: 'EDMUNDS', fips: '46045', id: '46045 - EDMUNDS' },
        { name: 'FALL RIVER', fips: '46047', id: '46047 - FALL RIVER' },
        { name: 'FAULK', fips: '46049', id: '46049 - FAULK' },
        { name: 'GRANT', fips: '46051', id: '46051 - GRANT' },
        { name: 'GREGORY', fips: '46053', id: '46053 - GREGORY' },
        { name: 'HAAKON', fips: '46055', id: '46055 - HAAKON' },
        { name: 'HAMLIN', fips: '46057', id: '46057 - HAMLIN' },
        { name: 'HAND', fips: '46059', id: '46059 - HAND' },
        { name: 'HANSON', fips: '46061', id: '46061 - HANSON' },
        { name: 'HARDING', fips: '46063', id: '46063 - HARDING' },
        { name: 'HUGHES', fips: '46065', id: '46065 - HUGHES' },
        { name: 'HUTCHINSON', fips: '46067', id: '46067 - HUTCHINSON' },
        { name: 'HYDE', fips: '46069', id: '46069 - HYDE' },
        { name: 'JACKSON', fips: '46071', id: '46071 - JACKSON' },
        { name: 'JERAULD', fips: '46073', id: '46073 - JERAULD' },
        { name: 'JONES', fips: '46075', id: '46075 - JONES' },
        { name: 'KINGSBURY', fips: '46077', id: '46077 - KINGSBURY' },
        { name: 'LAKE', fips: '46079', id: '46079 - LAKE' },
        { name: 'LAWRENCE', fips: '46081', id: '46081 - LAWRENCE' },
        { name: 'LINCOLN', fips: '46083', id: '46083 - LINCOLN' },
        { name: 'LYMAN', fips: '46085', id: '46085 - LYMAN' },
        { name: 'MCCOOK', fips: '46087', id: '46087 - MCCOOK' },
        { name: 'MCPHERSON', fips: '46089', id: '46089 - MCPHERSON' },
        { name: 'MARSHALL', fips: '46091', id: '46091 - MARSHALL' },
        { name: 'MEADE', fips: '46093', id: '46093 - MEADE' },
        { name: 'MELLETTE', fips: '46095', id: '46095 - MELLETTE' },
        { name: 'MINER', fips: '46097', id: '46097 - MINER' },
        { name: 'MINNEHAHA', fips: '46099', id: '46099 - MINNEHAHA' },
        { name: 'MOODY', fips: '46101', id: '46101 - MOODY' },
        { name: 'PENNINGTON', fips: '46103', id: '46103 - PENNINGTON' },
        { name: 'PERKINS', fips: '46105', id: '46105 - PERKINS' },
        { name: 'POTTER', fips: '46107', id: '46107 - POTTER' },
        { name: 'ROBERTS', fips: '46109', id: '46109 - ROBERTS' },
        { name: 'SANBORN', fips: '46111', id: '46111 - SANBORN' },
        { name: 'SHANNON', fips: '46113', id: '46113 - SHANNON' },
        { name: 'SPINK', fips: '46115', id: '46115 - SPINK' },
        { name: 'STANLEY', fips: '46117', id: '46117 - STANLEY' },
        { name: 'SULLY', fips: '46119', id: '46119 - SULLY' },
        { name: 'TODD', fips: '46121', id: '46121 - TODD' },
        { name: 'TRIPP', fips: '46123', id: '46123 - TRIPP' },
        { name: 'TURNER', fips: '46125', id: '46125 - TURNER' },
        { name: 'UNION', fips: '46127', id: '46127 - UNION' },
        { name: 'WALWORTH', fips: '46129', id: '46129 - WALWORTH' },
        { name: 'YANKTON', fips: '46135', id: '46135 - YANKTON' },
        { name: 'ZIEBACH', fips: '46137', id: '46137 - ZIEBACH' },
    ],
    TN: [
        { name: 'ANDERSON', fips: '47001', id: '47001 - ANDERSON' },
        { name: 'BEDFORD', fips: '47003', id: '47003 - BEDFORD' },
        { name: 'BENTON', fips: '47005', id: '47005 - BENTON' },
        { name: 'BLEDSOE', fips: '47007', id: '47007 - BLEDSOE' },
        { name: 'BLOUNT', fips: '47009', id: '47009 - BLOUNT' },
        { name: 'BRADLEY', fips: '47011', id: '47011 - BRADLEY' },
        { name: 'CAMPBELL', fips: '47013', id: '47013 - CAMPBELL' },
        { name: 'CANNON', fips: '47015', id: '47015 - CANNON' },
        { name: 'CARROLL', fips: '47017', id: '47017 - CARROLL' },
        { name: 'CARTER', fips: '47019', id: '47019 - CARTER' },
        { name: 'CHEATHAM', fips: '47021', id: '47021 - CHEATHAM' },
        { name: 'CHESTER', fips: '47023', id: '47023 - CHESTER' },
        { name: 'CLAIBORNE', fips: '47025', id: '47025 - CLAIBORNE' },
        { name: 'CLAY', fips: '47027', id: '47027 - CLAY' },
        { name: 'COCKE', fips: '47029', id: '47029 - COCKE' },
        { name: 'COFFEE', fips: '47031', id: '47031 - COFFEE' },
        { name: 'CROCKETT', fips: '47033', id: '47033 - CROCKETT' },
        { name: 'CUMBERLAND', fips: '47035', id: '47035 - CUMBERLAND' },
        { name: 'DAVIDSON', fips: '47037', id: '47037 - DAVIDSON' },
        { name: 'DECATUR', fips: '47039', id: '47039 - DECATUR' },
        { name: 'DE KALB', fips: '47041', id: '47041 - DE KALB' },
        { name: 'DICKSON', fips: '47043', id: '47043 - DICKSON' },
        { name: 'DYER', fips: '47045', id: '47045 - DYER' },
        { name: 'FAYETTE', fips: '47047', id: '47047 - FAYETTE' },
        { name: 'FENTRESS', fips: '47049', id: '47049 - FENTRESS' },
        { name: 'FRANKLIN', fips: '47051', id: '47051 - FRANKLIN' },
        { name: 'GIBSON', fips: '47053', id: '47053 - GIBSON' },
        { name: 'GILES', fips: '47055', id: '47055 - GILES' },
        { name: 'GRAINGER', fips: '47057', id: '47057 - GRAINGER' },
        { name: 'GREENE', fips: '47059', id: '47059 - GREENE' },
        { name: 'GRUNDY', fips: '47061', id: '47061 - GRUNDY' },
        { name: 'HAMBLEN', fips: '47063', id: '47063 - HAMBLEN' },
        { name: 'HAMILTON', fips: '47065', id: '47065 - HAMILTON' },
        { name: 'HANCOCK', fips: '47067', id: '47067 - HANCOCK' },
        { name: 'HARDEMAN', fips: '47069', id: '47069 - HARDEMAN' },
        { name: 'HARDIN', fips: '47071', id: '47071 - HARDIN' },
        { name: 'HAWKINS', fips: '47073', id: '47073 - HAWKINS' },
        { name: 'HAYWOOD', fips: '47075', id: '47075 - HAYWOOD' },
        { name: 'HENDERSON', fips: '47077', id: '47077 - HENDERSON' },
        { name: 'HENRY', fips: '47079', id: '47079 - HENRY' },
        { name: 'HICKMAN', fips: '47081', id: '47081 - HICKMAN' },
        { name: 'HOUSTON', fips: '47083', id: '47083 - HOUSTON' },
        { name: 'HUMPHREYS', fips: '47085', id: '47085 - HUMPHREYS' },
        { name: 'JACKSON', fips: '47087', id: '47087 - JACKSON' },
        { name: 'JEFFERSON', fips: '47089', id: '47089 - JEFFERSON' },
        { name: 'JOHNSON', fips: '47091', id: '47091 - JOHNSON' },
        { name: 'KNOX', fips: '47093', id: '47093 - KNOX' },
        { name: 'LAKE', fips: '47095', id: '47095 - LAKE' },
        { name: 'LAUDERDALE', fips: '47097', id: '47097 - LAUDERDALE' },
        { name: 'LAWRENCE', fips: '47099', id: '47099 - LAWRENCE' },
        { name: 'LEWIS', fips: '47101', id: '47101 - LEWIS' },
        { name: 'LINCOLN', fips: '47103', id: '47103 - LINCOLN' },
        { name: 'LOUDON', fips: '47105', id: '47105 - LOUDON' },
        { name: 'MCMINN', fips: '47107', id: '47107 - MCMINN' },
        { name: 'MCNAIRY', fips: '47109', id: '47109 - MCNAIRY' },
        { name: 'MACON', fips: '47111', id: '47111 - MACON' },
        { name: 'MADISON', fips: '47113', id: '47113 - MADISON' },
        { name: 'MARION', fips: '47115', id: '47115 - MARION' },
        { name: 'MARSHALL', fips: '47117', id: '47117 - MARSHALL' },
        { name: 'MAURY', fips: '47119', id: '47119 - MAURY' },
        { name: 'MEIGS', fips: '47121', id: '47121 - MEIGS' },
        { name: 'MONROE', fips: '47123', id: '47123 - MONROE' },
        { name: 'MONTGOMERY', fips: '47125', id: '47125 - MONTGOMERY' },
        { name: 'MOORE', fips: '47127', id: '47127 - MOORE' },
        { name: 'MORGAN', fips: '47129', id: '47129 - MORGAN' },
        { name: 'OBION', fips: '47131', id: '47131 - OBION' },
        { name: 'OVERTON', fips: '47133', id: '47133 - OVERTON' },
        { name: 'PERRY', fips: '47135', id: '47135 - PERRY' },
        { name: 'PICKETT', fips: '47137', id: '47137 - PICKETT' },
        { name: 'POLK', fips: '47139', id: '47139 - POLK' },
        { name: 'PUTNAM', fips: '47141', id: '47141 - PUTNAM' },
        { name: 'RHEA', fips: '47143', id: '47143 - RHEA' },
        { name: 'ROANE', fips: '47145', id: '47145 - ROANE' },
        { name: 'ROBERTSON', fips: '47147', id: '47147 - ROBERTSON' },
        { name: 'RUTHERFORD', fips: '47149', id: '47149 - RUTHERFORD' },
        { name: 'SCOTT', fips: '47151', id: '47151 - SCOTT' },
        { name: 'SEQUATCHIE', fips: '47153', id: '47153 - SEQUATCHIE' },
        { name: 'SEVIER', fips: '47155', id: '47155 - SEVIER' },
        { name: 'SHELBY', fips: '47157', id: '47157 - SHELBY' },
        { name: 'SMITH', fips: '47159', id: '47159 - SMITH' },
        { name: 'STEWART', fips: '47161', id: '47161 - STEWART' },
        { name: 'SULLIVAN', fips: '47163', id: '47163 - SULLIVAN' },
        { name: 'SUMNER', fips: '47165', id: '47165 - SUMNER' },
        { name: 'TIPTON', fips: '47167', id: '47167 - TIPTON' },
        { name: 'TROUSDALE', fips: '47169', id: '47169 - TROUSDALE' },
        { name: 'UNICOI', fips: '47171', id: '47171 - UNICOI' },
        { name: 'UNION', fips: '47173', id: '47173 - UNION' },
        { name: 'VAN BUREN', fips: '47175', id: '47175 - VAN BUREN' },
        { name: 'WARREN', fips: '47177', id: '47177 - WARREN' },
        { name: 'WASHINGTON', fips: '47179', id: '47179 - WASHINGTON' },
        { name: 'WAYNE', fips: '47181', id: '47181 - WAYNE' },
        { name: 'WEAKLEY', fips: '47183', id: '47183 - WEAKLEY' },
        { name: 'WHITE', fips: '47185', id: '47185 - WHITE' },
        { name: 'WILLIAMSON', fips: '47187', id: '47187 - WILLIAMSON' },
        { name: 'WILSON', fips: '47189', id: '47189 - WILSON' },
    ],
    TX: [
        { name: 'ANDERSON', fips: '48001', id: '48001 - ANDERSON' },
        { name: 'ANDREWS', fips: '48003', id: '48003 - ANDREWS' },
        { name: 'ANGELINA', fips: '48005', id: '48005 - ANGELINA' },
        { name: 'ARANSAS', fips: '48007', id: '48007 - ARANSAS' },
        { name: 'ARCHER', fips: '48009', id: '48009 - ARCHER' },
        { name: 'ARMSTRONG', fips: '48011', id: '48011 - ARMSTRONG' },
        { name: 'ATASCOSA', fips: '48013', id: '48013 - ATASCOSA' },
        { name: 'AUSTIN', fips: '48015', id: '48015 - AUSTIN' },
        { name: 'BAILEY', fips: '48017', id: '48017 - BAILEY' },
        { name: 'BANDERA', fips: '48019', id: '48019 - BANDERA' },
        { name: 'BASTROP', fips: '48021', id: '48021 - BASTROP' },
        { name: 'BAYLOR', fips: '48023', id: '48023 - BAYLOR' },
        { name: 'BEE', fips: '48025', id: '48025 - BEE' },
        { name: 'BELL', fips: '48027', id: '48027 - BELL' },
        { name: 'BEXAR', fips: '48029', id: '48029 - BEXAR' },
        { name: 'BLANCO', fips: '48031', id: '48031 - BLANCO' },
        { name: 'BORDEN', fips: '48033', id: '48033 - BORDEN' },
        { name: 'BOSQUE', fips: '48035', id: '48035 - BOSQUE' },
        { name: 'BOWIE', fips: '48037', id: '48037 - BOWIE' },
        { name: 'BRAZORIA', fips: '48039', id: '48039 - BRAZORIA' },
        { name: 'BRAZOS', fips: '48041', id: '48041 - BRAZOS' },
        { name: 'BREWSTER', fips: '48043', id: '48043 - BREWSTER' },
        { name: 'BRISCOE', fips: '48045', id: '48045 - BRISCOE' },
        { name: 'BROOKS', fips: '48047', id: '48047 - BROOKS' },
        { name: 'BROWN', fips: '48049', id: '48049 - BROWN' },
        { name: 'BURLESON', fips: '48051', id: '48051 - BURLESON' },
        { name: 'BURNET', fips: '48053', id: '48053 - BURNET' },
        { name: 'CALDWELL', fips: '48055', id: '48055 - CALDWELL' },
        { name: 'CALHOUN', fips: '48057', id: '48057 - CALHOUN' },
        { name: 'CALLAHAN', fips: '48059', id: '48059 - CALLAHAN' },
        { name: 'CAMERON', fips: '48061', id: '48061 - CAMERON' },
        { name: 'CAMP', fips: '48063', id: '48063 - CAMP' },
        { name: 'CARSON', fips: '48065', id: '48065 - CARSON' },
        { name: 'CASS', fips: '48067', id: '48067 - CASS' },
        { name: 'CASTRO', fips: '48069', id: '48069 - CASTRO' },
        { name: 'CHAMBERS', fips: '48071', id: '48071 - CHAMBERS' },
        { name: 'CHEROKEE', fips: '48073', id: '48073 - CHEROKEE' },
        { name: 'CHILDRESS', fips: '48075', id: '48075 - CHILDRESS' },
        { name: 'CLAY', fips: '48077', id: '48077 - CLAY' },
        { name: 'COCHRAN', fips: '48079', id: '48079 - COCHRAN' },
        { name: 'COKE', fips: '48081', id: '48081 - COKE' },
        { name: 'COLEMAN', fips: '48083', id: '48083 - COLEMAN' },
        { name: 'COLLIN', fips: '48085', id: '48085 - COLLIN' },
        { name: 'COLLINGSWORTH', fips: '48087', id: '48087 - COLLINGSWORTH' },
        { name: 'COLORADO', fips: '48089', id: '48089 - COLORADO' },
        { name: 'COMAL', fips: '48091', id: '48091 - COMAL' },
        { name: 'COMANCHE', fips: '48093', id: '48093 - COMANCHE' },
        { name: 'CONCHO', fips: '48095', id: '48095 - CONCHO' },
        { name: 'COOKE', fips: '48097', id: '48097 - COOKE' },
        { name: 'CORYELL', fips: '48099', id: '48099 - CORYELL' },
        { name: 'COTTLE', fips: '48101', id: '48101 - COTTLE' },
        { name: 'CRANE', fips: '48103', id: '48103 - CRANE' },
        { name: 'CROCKETT', fips: '48105', id: '48105 - CROCKETT' },
        { name: 'CROSBY', fips: '48107', id: '48107 - CROSBY' },
        { name: 'CULBERSON', fips: '48109', id: '48109 - CULBERSON' },
        { name: 'DALLAM', fips: '48111', id: '48111 - DALLAM' },
        { name: 'DALLAS', fips: '48113', id: '48113 - DALLAS' },
        { name: 'DAWSON', fips: '48115', id: '48115 - DAWSON' },
        { name: 'DEAF SMITH', fips: '48117', id: '48117 - DEAF SMITH' },
        { name: 'DELTA', fips: '48119', id: '48119 - DELTA' },
        { name: 'DENTON', fips: '48121', id: '48121 - DENTON' },
        { name: 'DE WITT', fips: '48123', id: '48123 - DE WITT' },
        { name: 'DICKENS', fips: '48125', id: '48125 - DICKENS' },
        { name: 'DIMMIT', fips: '48127', id: '48127 - DIMMIT' },
        { name: 'DONLEY', fips: '48129', id: '48129 - DONLEY' },
        { name: 'DUVAL', fips: '48131', id: '48131 - DUVAL' },
        { name: 'EASTLAND', fips: '48133', id: '48133 - EASTLAND' },
        { name: 'ECTOR', fips: '48135', id: '48135 - ECTOR' },
        { name: 'EDWARDS', fips: '48137', id: '48137 - EDWARDS' },
        { name: 'ELLIS', fips: '48139', id: '48139 - ELLIS' },
        { name: 'EL PASO', fips: '48141', id: '48141 - EL PASO' },
        { name: 'ERATH', fips: '48143', id: '48143 - ERATH' },
        { name: 'FALLS', fips: '48145', id: '48145 - FALLS' },
        { name: 'FANNIN', fips: '48147', id: '48147 - FANNIN' },
        { name: 'FAYETTE', fips: '48149', id: '48149 - FAYETTE' },
        { name: 'FISHER', fips: '48151', id: '48151 - FISHER' },
        { name: 'FLOYD', fips: '48153', id: '48153 - FLOYD' },
        { name: 'FOARD', fips: '48155', id: '48155 - FOARD' },
        { name: 'FORT BEND', fips: '48157', id: '48157 - FORT BEND' },
        { name: 'FRANKLIN', fips: '48159', id: '48159 - FRANKLIN' },
        { name: 'FREESTONE', fips: '48161', id: '48161 - FREESTONE' },
        { name: 'FRIO', fips: '48163', id: '48163 - FRIO' },
        { name: 'GAINES', fips: '48165', id: '48165 - GAINES' },
        { name: 'GALVESTON', fips: '48167', id: '48167 - GALVESTON' },
        { name: 'GARZA', fips: '48169', id: '48169 - GARZA' },
        { name: 'GILLESPIE', fips: '48171', id: '48171 - GILLESPIE' },
        { name: 'GLASSCOCK', fips: '48173', id: '48173 - GLASSCOCK' },
        { name: 'GOLIAD', fips: '48175', id: '48175 - GOLIAD' },
        { name: 'GONZALES', fips: '48177', id: '48177 - GONZALES' },
        { name: 'GRAY', fips: '48179', id: '48179 - GRAY' },
        { name: 'GRAYSON', fips: '48181', id: '48181 - GRAYSON' },
        { name: 'GREGG', fips: '48183', id: '48183 - GREGG' },
        { name: 'GRIMES', fips: '48185', id: '48185 - GRIMES' },
        { name: 'GUADALUPE', fips: '48187', id: '48187 - GUADALUPE' },
        { name: 'HALE', fips: '48189', id: '48189 - HALE' },
        { name: 'HALL', fips: '48191', id: '48191 - HALL' },
        { name: 'HAMILTON', fips: '48193', id: '48193 - HAMILTON' },
        { name: 'HANSFORD', fips: '48195', id: '48195 - HANSFORD' },
        { name: 'HARDEMAN', fips: '48197', id: '48197 - HARDEMAN' },
        { name: 'HARDIN', fips: '48199', id: '48199 - HARDIN' },
        { name: 'HARRIS', fips: '48201', id: '48201 - HARRIS' },
        { name: 'HARRISON', fips: '48203', id: '48203 - HARRISON' },
        { name: 'HARTLEY', fips: '48205', id: '48205 - HARTLEY' },
        { name: 'HASKELL', fips: '48207', id: '48207 - HASKELL' },
        { name: 'HAYS', fips: '48209', id: '48209 - HAYS' },
        { name: 'HEMPHILL', fips: '48211', id: '48211 - HEMPHILL' },
        { name: 'HENDERSON', fips: '48213', id: '48213 - HENDERSON' },
        { name: 'HIDALGO', fips: '48215', id: '48215 - HIDALGO' },
        { name: 'HILL', fips: '48217', id: '48217 - HILL' },
        { name: 'HOCKLEY', fips: '48219', id: '48219 - HOCKLEY' },
        { name: 'HOOD', fips: '48221', id: '48221 - HOOD' },
        { name: 'HOPKINS', fips: '48223', id: '48223 - HOPKINS' },
        { name: 'HOUSTON', fips: '48225', id: '48225 - HOUSTON' },
        { name: 'HOWARD', fips: '48227', id: '48227 - HOWARD' },
        { name: 'HUDSPETH', fips: '48229', id: '48229 - HUDSPETH' },
        { name: 'HUNT', fips: '48231', id: '48231 - HUNT' },
        { name: 'HUTCHINSON', fips: '48233', id: '48233 - HUTCHINSON' },
        { name: 'IRION', fips: '48235', id: '48235 - IRION' },
        { name: 'JACK', fips: '48237', id: '48237 - JACK' },
        { name: 'JACKSON', fips: '48239', id: '48239 - JACKSON' },
        { name: 'JASPER', fips: '48241', id: '48241 - JASPER' },
        { name: 'JEFF DAVIS', fips: '48243', id: '48243 - JEFF DAVIS' },
        { name: 'JEFFERSON', fips: '48245', id: '48245 - JEFFERSON' },
        { name: 'JIM HOGG', fips: '48247', id: '48247 - JIM HOGG' },
        { name: 'JIM WELLS', fips: '48249', id: '48249 - JIM WELLS' },
        { name: 'JOHNSON', fips: '48251', id: '48251 - JOHNSON' },
        { name: 'JONES', fips: '48253', id: '48253 - JONES' },
        { name: 'KARNES', fips: '48255', id: '48255 - KARNES' },
        { name: 'KAUFMAN', fips: '48257', id: '48257 - KAUFMAN' },
        { name: 'KENDALL', fips: '48259', id: '48259 - KENDALL' },
        { name: 'KENEDY', fips: '48261', id: '48261 - KENEDY' },
        { name: 'KENT', fips: '48263', id: '48263 - KENT' },
        { name: 'KERR', fips: '48265', id: '48265 - KERR' },
        { name: 'KIMBLE', fips: '48267', id: '48267 - KIMBLE' },
        { name: 'KING', fips: '48269', id: '48269 - KING' },
        { name: 'KINNEY', fips: '48271', id: '48271 - KINNEY' },
        { name: 'KLEBERG', fips: '48273', id: '48273 - KLEBERG' },
        { name: 'KNOX', fips: '48275', id: '48275 - KNOX' },
        { name: 'LAMAR', fips: '48277', id: '48277 - LAMAR' },
        { name: 'LAMB', fips: '48279', id: '48279 - LAMB' },
        { name: 'LAMPASAS', fips: '48281', id: '48281 - LAMPASAS' },
        { name: 'LA SALLE', fips: '48283', id: '48283 - LA SALLE' },
        { name: 'LAVACA', fips: '48285', id: '48285 - LAVACA' },
        { name: 'LEE', fips: '48287', id: '48287 - LEE' },
        { name: 'LEON', fips: '48289', id: '48289 - LEON' },
        { name: 'LIBERTY', fips: '48291', id: '48291 - LIBERTY' },
        { name: 'LIMESTONE', fips: '48293', id: '48293 - LIMESTONE' },
        { name: 'LIPSCOMB', fips: '48295', id: '48295 - LIPSCOMB' },
        { name: 'LIVE OAK', fips: '48297', id: '48297 - LIVE OAK' },
        { name: 'LLANO', fips: '48299', id: '48299 - LLANO' },
        { name: 'LOVING', fips: '48301', id: '48301 - LOVING' },
        { name: 'LUBBOCK', fips: '48303', id: '48303 - LUBBOCK' },
        { name: 'LYNN', fips: '48305', id: '48305 - LYNN' },
        { name: 'MCCULLOCH', fips: '48307', id: '48307 - MCCULLOCH' },
        { name: 'MCLENNAN', fips: '48309', id: '48309 - MCLENNAN' },
        { name: 'MCMULLEN', fips: '48311', id: '48311 - MCMULLEN' },
        { name: 'MADISON', fips: '48313', id: '48313 - MADISON' },
        { name: 'MARION', fips: '48315', id: '48315 - MARION' },
        { name: 'MARTIN', fips: '48317', id: '48317 - MARTIN' },
        { name: 'MASON', fips: '48319', id: '48319 - MASON' },
        { name: 'MATAGORDA', fips: '48321', id: '48321 - MATAGORDA' },
        { name: 'MAVERICK', fips: '48323', id: '48323 - MAVERICK' },
        { name: 'MEDINA', fips: '48325', id: '48325 - MEDINA' },
        { name: 'MENARD', fips: '48327', id: '48327 - MENARD' },
        { name: 'MIDLAND', fips: '48329', id: '48329 - MIDLAND' },
        { name: 'MILAM', fips: '48331', id: '48331 - MILAM' },
        { name: 'MILLS', fips: '48333', id: '48333 - MILLS' },
        { name: 'MITCHELL', fips: '48335', id: '48335 - MITCHELL' },
        { name: 'MONTAGUE', fips: '48337', id: '48337 - MONTAGUE' },
        { name: 'MONTGOMERY', fips: '48339', id: '48339 - MONTGOMERY' },
        { name: 'MOORE', fips: '48341', id: '48341 - MOORE' },
        { name: 'MORRIS', fips: '48343', id: '48343 - MORRIS' },
        { name: 'MOTLEY', fips: '48345', id: '48345 - MOTLEY' },
        { name: 'NACOGDOCHES', fips: '48347', id: '48347 - NACOGDOCHES' },
        { name: 'NAVARRO', fips: '48349', id: '48349 - NAVARRO' },
        { name: 'NEWTON', fips: '48351', id: '48351 - NEWTON' },
        { name: 'NOLAN', fips: '48353', id: '48353 - NOLAN' },
        { name: 'NUECES', fips: '48355', id: '48355 - NUECES' },
        { name: 'OCHILTREE', fips: '48357', id: '48357 - OCHILTREE' },
        { name: 'OLDHAM', fips: '48359', id: '48359 - OLDHAM' },
        { name: 'ORANGE', fips: '48361', id: '48361 - ORANGE' },
        { name: 'PALO PINTO', fips: '48363', id: '48363 - PALO PINTO' },
        { name: 'PANOLA', fips: '48365', id: '48365 - PANOLA' },
        { name: 'PARKER', fips: '48367', id: '48367 - PARKER' },
        { name: 'PARMER', fips: '48369', id: '48369 - PARMER' },
        { name: 'PECOS', fips: '48371', id: '48371 - PECOS' },
        { name: 'POLK', fips: '48373', id: '48373 - POLK' },
        { name: 'POTTER', fips: '48375', id: '48375 - POTTER' },
        { name: 'PRESIDIO', fips: '48377', id: '48377 - PRESIDIO' },
        { name: 'RAINS', fips: '48379', id: '48379 - RAINS' },
        { name: 'RANDALL', fips: '48381', id: '48381 - RANDALL' },
        { name: 'REAGAN', fips: '48383', id: '48383 - REAGAN' },
        { name: 'REAL', fips: '48385', id: '48385 - REAL' },
        { name: 'RED RIVER', fips: '48387', id: '48387 - RED RIVER' },
        { name: 'REEVES', fips: '48389', id: '48389 - REEVES' },
        { name: 'REFUGIO', fips: '48391', id: '48391 - REFUGIO' },
        { name: 'ROBERTS', fips: '48393', id: '48393 - ROBERTS' },
        { name: 'ROBERTSON', fips: '48395', id: '48395 - ROBERTSON' },
        { name: 'ROCKWALL', fips: '48397', id: '48397 - ROCKWALL' },
        { name: 'RUNNELS', fips: '48399', id: '48399 - RUNNELS' },
        { name: 'RUSK', fips: '48401', id: '48401 - RUSK' },
        { name: 'SABINE', fips: '48403', id: '48403 - SABINE' },
        { name: 'SAN AUGUSTINE', fips: '48405', id: '48405 - SAN AUGUSTINE' },
        { name: 'SAN JACINTO', fips: '48407', id: '48407 - SAN JACINTO' },
        { name: 'SAN PATRICIO', fips: '48409', id: '48409 - SAN PATRICIO' },
        { name: 'SAN SABA', fips: '48411', id: '48411 - SAN SABA' },
        { name: 'SCHLEICHER', fips: '48413', id: '48413 - SCHLEICHER' },
        { name: 'SCURRY', fips: '48415', id: '48415 - SCURRY' },
        { name: 'SHACKELFORD', fips: '48417', id: '48417 - SHACKELFORD' },
        { name: 'SHELBY', fips: '48419', id: '48419 - SHELBY' },
        { name: 'SHERMAN', fips: '48421', id: '48421 - SHERMAN' },
        { name: 'SMITH', fips: '48423', id: '48423 - SMITH' },
        { name: 'SOMERVELL', fips: '48425', id: '48425 - SOMERVELL' },
        { name: 'STARR', fips: '48427', id: '48427 - STARR' },
        { name: 'STEPHENS', fips: '48429', id: '48429 - STEPHENS' },
        { name: 'STERLING', fips: '48431', id: '48431 - STERLING' },
        { name: 'STONEWALL', fips: '48433', id: '48433 - STONEWALL' },
        { name: 'SUTTON', fips: '48435', id: '48435 - SUTTON' },
        { name: 'SWISHER', fips: '48437', id: '48437 - SWISHER' },
        { name: 'TARRANT', fips: '48439', id: '48439 - TARRANT' },
        { name: 'TAYLOR', fips: '48441', id: '48441 - TAYLOR' },
        { name: 'TERRELL', fips: '48443', id: '48443 - TERRELL' },
        { name: 'TERRY', fips: '48445', id: '48445 - TERRY' },
        { name: 'THROCKMORTON', fips: '48447', id: '48447 - THROCKMORTON' },
        { name: 'TITUS', fips: '48449', id: '48449 - TITUS' },
        { name: 'TOM GREEN', fips: '48451', id: '48451 - TOM GREEN' },
        { name: 'TRAVIS', fips: '48453', id: '48453 - TRAVIS' },
        { name: 'TRINITY', fips: '48455', id: '48455 - TRINITY' },
        { name: 'TYLER', fips: '48457', id: '48457 - TYLER' },
        { name: 'UPSHUR', fips: '48459', id: '48459 - UPSHUR' },
        { name: 'UPTON', fips: '48461', id: '48461 - UPTON' },
        { name: 'UVALDE', fips: '48463', id: '48463 - UVALDE' },
        { name: 'VAL VERDE', fips: '48465', id: '48465 - VAL VERDE' },
        { name: 'VAN ZANDT', fips: '48467', id: '48467 - VAN ZANDT' },
        { name: 'VICTORIA', fips: '48469', id: '48469 - VICTORIA' },
        { name: 'WALKER', fips: '48471', id: '48471 - WALKER' },
        { name: 'WALLER', fips: '48473', id: '48473 - WALLER' },
        { name: 'WARD', fips: '48475', id: '48475 - WARD' },
        { name: 'WASHINGTON', fips: '48477', id: '48477 - WASHINGTON' },
        { name: 'WEBB', fips: '48479', id: '48479 - WEBB' },
        { name: 'WHARTON', fips: '48481', id: '48481 - WHARTON' },
        { name: 'WHEELER', fips: '48483', id: '48483 - WHEELER' },
        { name: 'WICHITA', fips: '48485', id: '48485 - WICHITA' },
        { name: 'WILBARGER', fips: '48487', id: '48487 - WILBARGER' },
        { name: 'WILLACY', fips: '48489', id: '48489 - WILLACY' },
        { name: 'WILLIAMSON', fips: '48491', id: '48491 - WILLIAMSON' },
        { name: 'WILSON', fips: '48493', id: '48493 - WILSON' },
        { name: 'WINKLER', fips: '48495', id: '48495 - WINKLER' },
        { name: 'WISE', fips: '48497', id: '48497 - WISE' },
        { name: 'WOOD', fips: '48499', id: '48499 - WOOD' },
        { name: 'YOAKUM', fips: '48501', id: '48501 - YOAKUM' },
        { name: 'YOUNG', fips: '48503', id: '48503 - YOUNG' },
        { name: 'ZAPATA', fips: '48505', id: '48505 - ZAPATA' },
        { name: 'ZAVALA', fips: '48507', id: '48507 - ZAVALA' },
    ],
    UT: [
        { name: 'BEAVER', fips: '49001', id: '49001 - BEAVER' },
        { name: 'BOX ELDER', fips: '49003', id: '49003 - BOX ELDER' },
        { name: 'CACHE', fips: '49005', id: '49005 - CACHE' },
        { name: 'CARBON', fips: '49007', id: '49007 - CARBON' },
        { name: 'DAGGETT', fips: '49009', id: '49009 - DAGGETT' },
        { name: 'DAVIS', fips: '49011', id: '49011 - DAVIS' },
        { name: 'DUCHESNE', fips: '49013', id: '49013 - DUCHESNE' },
        { name: 'EMERY', fips: '49015', id: '49015 - EMERY' },
        { name: 'GARFIELD', fips: '49017', id: '49017 - GARFIELD' },
        { name: 'GRAND', fips: '49019', id: '49019 - GRAND' },
        { name: 'IRON', fips: '49021', id: '49021 - IRON' },
        { name: 'JUAB', fips: '49023', id: '49023 - JUAB' },
        { name: 'KANE', fips: '49025', id: '49025 - KANE' },
        { name: 'MILLARD', fips: '49027', id: '49027 - MILLARD' },
        { name: 'MORGAN', fips: '49029', id: '49029 - MORGAN' },
        { name: 'PIUTE', fips: '49031', id: '49031 - PIUTE' },
        { name: 'RICH', fips: '49033', id: '49033 - RICH' },
        { name: 'SALT LAKE', fips: '49035', id: '49035 - SALT LAKE' },
        { name: 'SAN JUAN', fips: '49037', id: '49037 - SAN JUAN' },
        { name: 'SANPETE', fips: '49039', id: '49039 - SANPETE' },
        { name: 'SEVIER', fips: '49041', id: '49041 - SEVIER' },
        { name: 'SUMMIT', fips: '49043', id: '49043 - SUMMIT' },
        { name: 'TOOELE', fips: '49045', id: '49045 - TOOELE' },
        { name: 'UINTAH', fips: '49047', id: '49047 - UINTAH' },
        { name: 'UTAH', fips: '49049', id: '49049 - UTAH' },
        { name: 'WASATCH', fips: '49051', id: '49051 - WASATCH' },
        { name: 'WASHINGTON', fips: '49053', id: '49053 - WASHINGTON' },
        { name: 'WAYNE', fips: '49055', id: '49055 - WAYNE' },
        { name: 'WEBER', fips: '49057', id: '49057 - WEBER' },
    ],
    VT: [
        { name: 'ADDISON', fips: '50001', id: '50001 - ADDISON' },
        { name: 'BENNINGTON', fips: '50003', id: '50003 - BENNINGTON' },
        { name: 'CALEDONIA', fips: '50005', id: '50005 - CALEDONIA' },
        { name: 'CHITTENDEN', fips: '50007', id: '50007 - CHITTENDEN' },
        { name: 'ESSEX', fips: '50009', id: '50009 - ESSEX' },
        { name: 'FRANKLIN', fips: '50011', id: '50011 - FRANKLIN' },
        { name: 'GRAND ISLE', fips: '50013', id: '50013 - GRAND ISLE' },
        { name: 'LAMOILLE', fips: '50015', id: '50015 - LAMOILLE' },
        { name: 'ORANGE', fips: '50017', id: '50017 - ORANGE' },
        { name: 'ORLEANS', fips: '50019', id: '50019 - ORLEANS' },
        { name: 'RUTLAND', fips: '50021', id: '50021 - RUTLAND' },
        { name: 'WASHINGTON', fips: '50023', id: '50023 - WASHINGTON' },
        { name: 'WINDHAM', fips: '50025', id: '50025 - WINDHAM' },
        { name: 'WINDSOR', fips: '50027', id: '50027 - WINDSOR' },
    ],
    VA: [
        { name: 'ACCOMACK', fips: '51001', id: '51001 - ACCOMACK' },
        { name: 'ALBEMARLE', fips: '51003', id: '51003 - ALBEMARLE' },
        { name: 'ALLEGHANY', fips: '51005', id: '51005 - ALLEGHANY' },
        { name: 'AMELIA', fips: '51007', id: '51007 - AMELIA' },
        { name: 'AMHERST', fips: '51009', id: '51009 - AMHERST' },
        { name: 'APPOMATTOX', fips: '51011', id: '51011 - APPOMATTOX' },
        { name: 'ARLINGTON', fips: '51013', id: '51013 - ARLINGTON' },
        { name: 'AUGUSTA', fips: '51015', id: '51015 - AUGUSTA' },
        { name: 'BATH', fips: '51017', id: '51017 - BATH' },
        { name: 'BEDFORD', fips: '51019', id: '51019 - BEDFORD' },
        { name: 'BLAND', fips: '51021', id: '51021 - BLAND' },
        { name: 'BOTETOURT', fips: '51023', id: '51023 - BOTETOURT' },
        { name: 'BRUNSWICK', fips: '51025', id: '51025 - BRUNSWICK' },
        { name: 'BUCHANAN', fips: '51027', id: '51027 - BUCHANAN' },
        { name: 'BUCKINGHAM', fips: '51029', id: '51029 - BUCKINGHAM' },
        { name: 'CAMPBELL', fips: '51031', id: '51031 - CAMPBELL' },
        { name: 'CAROLINE', fips: '51033', id: '51033 - CAROLINE' },
        { name: 'CARROLL', fips: '51035', id: '51035 - CARROLL' },
        { name: 'CHARLES CITY', fips: '51036', id: '51036 - CHARLES CITY' },
        { name: 'CHARLOTTE', fips: '51037', id: '51037 - CHARLOTTE' },
        { name: 'CHESTERFIELD', fips: '51041', id: '51041 - CHESTERFIELD' },
        { name: 'CLARKE', fips: '51043', id: '51043 - CLARKE' },
        { name: 'CRAIG', fips: '51045', id: '51045 - CRAIG' },
        { name: 'CULPEPER', fips: '51047', id: '51047 - CULPEPER' },
        { name: 'CUMBERLAND', fips: '51049', id: '51049 - CUMBERLAND' },
        { name: 'DICKENSON', fips: '51051', id: '51051 - DICKENSON' },
        { name: 'DINWIDDIE', fips: '51053', id: '51053 - DINWIDDIE' },
        { name: 'ESSEX', fips: '51057', id: '51057 - ESSEX' },
        { name: 'FAIRFAX', fips: '51059', id: '51059 - FAIRFAX' },
        { name: 'FAUQUIER', fips: '51061', id: '51061 - FAUQUIER' },
        { name: 'FLOYD', fips: '51063', id: '51063 - FLOYD' },
        { name: 'FLUVANNA', fips: '51065', id: '51065 - FLUVANNA' },
        { name: 'FRANKLIN', fips: '51067', id: '51067 - FRANKLIN' },
        { name: 'FREDERICK', fips: '51069', id: '51069 - FREDERICK' },
        { name: 'GILES', fips: '51071', id: '51071 - GILES' },
        { name: 'GLOUCESTER', fips: '51073', id: '51073 - GLOUCESTER' },
        { name: 'GOOCHLAND', fips: '51075', id: '51075 - GOOCHLAND' },
        { name: 'GRAYSON', fips: '51077', id: '51077 - GRAYSON' },
        { name: 'GREENE', fips: '51079', id: '51079 - GREENE' },
        { name: 'GREENSVILLE', fips: '51081', id: '51081 - GREENSVILLE' },
        { name: 'HALIFAX', fips: '51083', id: '51083 - HALIFAX' },
        { name: 'HANOVER', fips: '51085', id: '51085 - HANOVER' },
        { name: 'HENRICO', fips: '51087', id: '51087 - HENRICO' },
        { name: 'HENRY', fips: '51089', id: '51089 - HENRY' },
        { name: 'HIGHLAND', fips: '51091', id: '51091 - HIGHLAND' },
        { name: 'ISLE OF WIGHT', fips: '51093', id: '51093 - ISLE OF WIGHT' },
        { name: 'JAMES CITY', fips: '51095', id: '51095 - JAMES CITY' },
        { name: 'KING AND QUEEN', fips: '51097', id: '51097 - KING AND QUEEN' },
        { name: 'KING GEORGE', fips: '51099', id: '51099 - KING GEORGE' },
        { name: 'KING WILLIAM', fips: '51101', id: '51101 - KING WILLIAM' },
        { name: 'LANCASTER', fips: '51103', id: '51103 - LANCASTER' },
        { name: 'LEE', fips: '51105', id: '51105 - LEE' },
        { name: 'LOUDOUN', fips: '51107', id: '51107 - LOUDOUN' },
        { name: 'LOUISA', fips: '51109', id: '51109 - LOUISA' },
        { name: 'LUNENBURG', fips: '51111', id: '51111 - LUNENBURG' },
        { name: 'MADISON', fips: '51113', id: '51113 - MADISON' },
        { name: 'MATHEWS', fips: '51115', id: '51115 - MATHEWS' },
        { name: 'MECKLENBURG', fips: '51117', id: '51117 - MECKLENBURG' },
        { name: 'MIDDLESEX', fips: '51119', id: '51119 - MIDDLESEX' },
        { name: 'MONTGOMERY', fips: '51121', id: '51121 - MONTGOMERY' },
        { name: 'NELSON', fips: '51125', id: '51125 - NELSON' },
        { name: 'NEW KENT', fips: '51127', id: '51127 - NEW KENT' },
        { name: 'NORTHAMPTON', fips: '51131', id: '51131 - NORTHAMPTON' },
        { name: 'NORTHUMBERLAND', fips: '51133', id: '51133 - NORTHUMBERLAND' },
        { name: 'NOTTOWAY', fips: '51135', id: '51135 - NOTTOWAY' },
        { name: 'ORANGE', fips: '51137', id: '51137 - ORANGE' },
        { name: 'PAGE', fips: '51139', id: '51139 - PAGE' },
        { name: 'PATRICK', fips: '51141', id: '51141 - PATRICK' },
        { name: 'PITTSYLVANIA', fips: '51143', id: '51143 - PITTSYLVANIA' },
        { name: 'POWHATAN', fips: '51145', id: '51145 - POWHATAN' },
        { name: 'PRINCE EDWARD', fips: '51147', id: '51147 - PRINCE EDWARD' },
        { name: 'PRINCE GEORGE', fips: '51149', id: '51149 - PRINCE GEORGE' },
        { name: 'PRINCE WILLIAM', fips: '51153', id: '51153 - PRINCE WILLIAM' },
        { name: 'PULASKI', fips: '51155', id: '51155 - PULASKI' },
        { name: 'RAPPAHANNOCK', fips: '51157', id: '51157 - RAPPAHANNOCK' },
        { name: 'RICHMOND', fips: '51159', id: '51159 - RICHMOND' },
        { name: 'ROANOKE', fips: '51161', id: '51161 - ROANOKE' },
        { name: 'ROCKBRIDGE', fips: '51163', id: '51163 - ROCKBRIDGE' },
        { name: 'ROCKINGHAM', fips: '51165', id: '51165 - ROCKINGHAM' },
        { name: 'RUSSELL', fips: '51167', id: '51167 - RUSSELL' },
        { name: 'SCOTT', fips: '51169', id: '51169 - SCOTT' },
        { name: 'SHENANDOAH', fips: '51171', id: '51171 - SHENANDOAH' },
        { name: 'SMYTH', fips: '51173', id: '51173 - SMYTH' },
        { name: 'SOUTHAMPTON', fips: '51175', id: '51175 - SOUTHAMPTON' },
        { name: 'SPOTSYLVANIA', fips: '51177', id: '51177 - SPOTSYLVANIA' },
        { name: 'STAFFORD', fips: '51179', id: '51179 - STAFFORD' },
        { name: 'SURRY', fips: '51181', id: '51181 - SURRY' },
        { name: 'SUSSEX', fips: '51183', id: '51183 - SUSSEX' },
        { name: 'TAZEWELL', fips: '51185', id: '51185 - TAZEWELL' },
        { name: 'WARREN', fips: '51187', id: '51187 - WARREN' },
        { name: 'WASHINGTON', fips: '51191', id: '51191 - WASHINGTON' },
        { name: 'WESTMORELAND', fips: '51193', id: '51193 - WESTMORELAND' },
        { name: 'WISE', fips: '51195', id: '51195 - WISE' },
        { name: 'WYTHE', fips: '51197', id: '51197 - WYTHE' },
        { name: 'YORK', fips: '51199', id: '51199 - YORK' },
        { name: 'ALEXANDRIA CITY', fips: '51510', id: '51510 - ALEXANDRIA CITY' },
        { name: 'BEDFORD CITY', fips: '51515', id: '51515 - BEDFORD CITY' },
        { name: 'BRISTOL CITY', fips: '51520', id: '51520 - BRISTOL CITY' },
        { name: 'BUENA VISTA CITY', fips: '51530', id: '51530 - BUENA VISTA CITY' },
        { name: 'CHARLOTTESVILLE  CITY', fips: '51540', id: '51540 - CHARLOTTESVILLE  CITY' },
        { name: 'CHESAPEAKE  CITY', fips: '51550', id: '51550 - CHESAPEAKE  CITY' },
        { name: 'CLIFTON FORGE CITY', fips: '51560', id: '51560 - CLIFTON FORGE CITY' },
        { name: 'COLONIAL HEIGHTS CITY', fips: '51570', id: '51570 - COLONIAL HEIGHTS CITY' },
        { name: 'COVINGTON CITY', fips: '51580', id: '51580 - COVINGTON CITY' },
        { name: 'DANVILLE CITY', fips: '51590', id: '51590 - DANVILLE CITY' },
        { name: 'EMPORIA CITY', fips: '51595', id: '51595 - EMPORIA CITY' },
        { name: 'FAIRFAX CITY', fips: '51600', id: '51600 - FAIRFAX CITY' },
        { name: 'FALLS CHURCH CITY', fips: '51610', id: '51610 - FALLS CHURCH CITY' },
        { name: 'FRANKLIN CITY', fips: '51620', id: '51620 - FRANKLIN CITY' },
        { name: 'FREDERICKSBURG CITY', fips: '51630', id: '51630 - FREDERICKSBURG CITY' },
        { name: 'GALAX CITY', fips: '51640', id: '51640 - GALAX CITY' },
        { name: 'HAMPTON CITY', fips: '51650', id: '51650 - HAMPTON CITY' },
        { name: 'HARRISONBURG CITY', fips: '51660', id: '51660 - HARRISONBURG CITY' },
        { name: 'HOPEWELL CITY', fips: '51670', id: '51670 - HOPEWELL CITY' },
        { name: 'LEXINGTON CITY', fips: '51678', id: '51678 - LEXINGTON CITY' },
        { name: 'LYNCHBURG CITY', fips: '51680', id: '51680 - LYNCHBURG CITY' },
        { name: 'MANASSAS CITY', fips: '51683', id: '51683 - MANASSAS CITY' },
        { name: 'MANASSAS PARK CITY', fips: '51685', id: '51685 - MANASSAS PARK CITY' },
        { name: 'MARTINSVILLE CITY', fips: '51690', id: '51690 - MARTINSVILLE CITY' },
        { name: 'NEWPORT NEWS CITY', fips: '51700', id: '51700 - NEWPORT NEWS CITY' },
        { name: 'NORFOLK', fips: '51710', id: '51710 - NORFOLK' },
        { name: 'NORTON CITY', fips: '51720', id: '51720 - NORTON CITY' },
        { name: 'PETERSBURG CITY', fips: '51730', id: '51730 - PETERSBURG CITY' },
        { name: 'POQUOSON CITY', fips: '51735', id: '51735 - POQUOSON CITY' },
        { name: 'PORTSMOUTH CITY', fips: '51740', id: '51740 - PORTSMOUTH CITY' },
        { name: 'RADFORD CITY', fips: '51750', id: '51750 - RADFORD CITY' },
        { name: 'RICHMOND CITY', fips: '51760', id: '51760 - RICHMOND CITY' },
        { name: 'ROANOKE CITY', fips: '51770', id: '51770 - ROANOKE CITY' },
        { name: 'SALEM CITY', fips: '51775', id: '51775 - SALEM CITY' },
        { name: 'STAUNTON CITY', fips: '51790', id: '51790 - STAUNTON CITY' },
        { name: 'SUFFOLK CITY', fips: '51800', id: '51800 - SUFFOLK CITY' },
        { name: 'VIRGINIA BEACH CITY', fips: '51810', id: '51810 - VIRGINIA BEACH CITY' },
        { name: 'WAYNESBORO CITY', fips: '51820', id: '51820 - WAYNESBORO CITY' },
        { name: 'WILLIAMSBURG CITY', fips: '51830', id: '51830 - WILLIAMSBURG CITY' },
        { name: 'WINCHESTER CITY', fips: '51840', id: '51840 - WINCHESTER CITY' },
    ],
    WA: [
        { name: 'ADAMS', fips: '53001', id: '53001 - ADAMS' },
        { name: 'ASOTIN', fips: '53003', id: '53003 - ASOTIN' },
        { name: 'BENTON', fips: '53005', id: '53005 - BENTON' },
        { name: 'CHELAN', fips: '53007', id: '53007 - CHELAN' },
        { name: 'CLALLAM', fips: '53009', id: '53009 - CLALLAM' },
        { name: 'CLARK', fips: '53011', id: '53011 - CLARK' },
        { name: 'COLUMBIA', fips: '53013', id: '53013 - COLUMBIA' },
        { name: 'COWLITZ', fips: '53015', id: '53015 - COWLITZ' },
        { name: 'DOUGLAS', fips: '53017', id: '53017 - DOUGLAS' },
        { name: 'FERRY', fips: '53019', id: '53019 - FERRY' },
        { name: 'FRANKLIN', fips: '53021', id: '53021 - FRANKLIN' },
        { name: 'GARFIELD', fips: '53023', id: '53023 - GARFIELD' },
        { name: 'GRANT', fips: '53025', id: '53025 - GRANT' },
        { name: 'GRAYS HARBOR', fips: '53027', id: '53027 - GRAYS HARBOR' },
        { name: 'ISLAND', fips: '53029', id: '53029 - ISLAND' },
        { name: 'JEFFERSON', fips: '53031', id: '53031 - JEFFERSON' },
        { name: 'KING', fips: '53033', id: '53033 - KING' },
        { name: 'KITSAP', fips: '53035', id: '53035 - KITSAP' },
        { name: 'KITTITAS', fips: '53037', id: '53037 - KITTITAS' },
        { name: 'KLICKITAT', fips: '53039', id: '53039 - KLICKITAT' },
        { name: 'LEWIS', fips: '53041', id: '53041 - LEWIS' },
        { name: 'LINCOLN', fips: '53043', id: '53043 - LINCOLN' },
        { name: 'MASON', fips: '53045', id: '53045 - MASON' },
        { name: 'OKANOGAN', fips: '53047', id: '53047 - OKANOGAN' },
        { name: 'PACIFIC', fips: '53049', id: '53049 - PACIFIC' },
        { name: 'PEND OREILLE', fips: '53051', id: '53051 - PEND OREILLE' },
        { name: 'PIERCE', fips: '53053', id: '53053 - PIERCE' },
        { name: 'SAN JUAN', fips: '53055', id: '53055 - SAN JUAN' },
        { name: 'SKAGIT', fips: '53057', id: '53057 - SKAGIT' },
        { name: 'SKAMANIA', fips: '53059', id: '53059 - SKAMANIA' },
        { name: 'SNOHOMISH', fips: '53061', id: '53061 - SNOHOMISH' },
        { name: 'SPOKANE', fips: '53063', id: '53063 - SPOKANE' },
        { name: 'STEVENS', fips: '53065', id: '53065 - STEVENS' },
        { name: 'THURSTON', fips: '53067', id: '53067 - THURSTON' },
        { name: 'WAHKIAKUM', fips: '53069', id: '53069 - WAHKIAKUM' },
        { name: 'WALLA WALLA', fips: '53071', id: '53071 - WALLA WALLA' },
        { name: 'WHATCOM', fips: '53073', id: '53073 - WHATCOM' },
        { name: 'WHITMAN', fips: '53075', id: '53075 - WHITMAN' },
        { name: 'YAKIMA', fips: '53077', id: '53077 - YAKIMA' },
    ],
    WV: [
        { name: 'BARBOUR', fips: '54001', id: '54001 - BARBOUR' },
        { name: 'BERKELEY', fips: '54003', id: '54003 - BERKELEY' },
        { name: 'BOONE', fips: '54005', id: '54005 - BOONE' },
        { name: 'BRAXTON', fips: '54007', id: '54007 - BRAXTON' },
        { name: 'BROOKE', fips: '54009', id: '54009 - BROOKE' },
        { name: 'CABELL', fips: '54011', id: '54011 - CABELL' },
        { name: 'CALHOUN', fips: '54013', id: '54013 - CALHOUN' },
        { name: 'CLAY', fips: '54015', id: '54015 - CLAY' },
        { name: 'DODDRIDGE', fips: '54017', id: '54017 - DODDRIDGE' },
        { name: 'FAYETTE', fips: '54019', id: '54019 - FAYETTE' },
        { name: 'GILMER', fips: '54021', id: '54021 - GILMER' },
        { name: 'GRANT', fips: '54023', id: '54023 - GRANT' },
        { name: 'GREENBRIER', fips: '54025', id: '54025 - GREENBRIER' },
        { name: 'HAMPSHIRE', fips: '54027', id: '54027 - HAMPSHIRE' },
        { name: 'HANCOCK', fips: '54029', id: '54029 - HANCOCK' },
        { name: 'HARDY', fips: '54031', id: '54031 - HARDY' },
        { name: 'HARRISON', fips: '54033', id: '54033 - HARRISON' },
        { name: 'JACKSON', fips: '54035', id: '54035 - JACKSON' },
        { name: 'JEFFERSON', fips: '54037', id: '54037 - JEFFERSON' },
        { name: 'KANAWHA', fips: '54039', id: '54039 - KANAWHA' },
        { name: 'LEWIS', fips: '54041', id: '54041 - LEWIS' },
        { name: 'LINCOLN', fips: '54043', id: '54043 - LINCOLN' },
        { name: 'LOGAN', fips: '54045', id: '54045 - LOGAN' },
        { name: 'MCDOWELL', fips: '54047', id: '54047 - MCDOWELL' },
        { name: 'MARION', fips: '54049', id: '54049 - MARION' },
        { name: 'MARSHALL', fips: '54051', id: '54051 - MARSHALL' },
        { name: 'MASON', fips: '54053', id: '54053 - MASON' },
        { name: 'MERCER', fips: '54055', id: '54055 - MERCER' },
        { name: 'MINERAL', fips: '54057', id: '54057 - MINERAL' },
        { name: 'MINGO', fips: '54059', id: '54059 - MINGO' },
        { name: 'MONONGALIA', fips: '54061', id: '54061 - MONONGALIA' },
        { name: 'MONROE', fips: '54063', id: '54063 - MONROE' },
        { name: 'MORGAN', fips: '54065', id: '54065 - MORGAN' },
        { name: 'NICHOLAS', fips: '54067', id: '54067 - NICHOLAS' },
        { name: 'OHIO', fips: '54069', id: '54069 - OHIO' },
        { name: 'PENDLETON', fips: '54071', id: '54071 - PENDLETON' },
        { name: 'PLEASANTS', fips: '54073', id: '54073 - PLEASANTS' },
        { name: 'POCAHONTAS', fips: '54075', id: '54075 - POCAHONTAS' },
        { name: 'PRESTON', fips: '54077', id: '54077 - PRESTON' },
        { name: 'PUTNAM', fips: '54079', id: '54079 - PUTNAM' },
        { name: 'RALEIGH', fips: '54081', id: '54081 - RALEIGH' },
        { name: 'RANDOLPH', fips: '54083', id: '54083 - RANDOLPH' },
        { name: 'RITCHIE', fips: '54085', id: '54085 - RITCHIE' },
        { name: 'ROANE', fips: '54087', id: '54087 - ROANE' },
        { name: 'SUMMERS', fips: '54089', id: '54089 - SUMMERS' },
        { name: 'TAYLOR', fips: '54091', id: '54091 - TAYLOR' },
        { name: 'TUCKER', fips: '54093', id: '54093 - TUCKER' },
        { name: 'TYLER', fips: '54095', id: '54095 - TYLER' },
        { name: 'UPSHUR', fips: '54097', id: '54097 - UPSHUR' },
        { name: 'WAYNE', fips: '54099', id: '54099 - WAYNE' },
        { name: 'WEBSTER', fips: '54101', id: '54101 - WEBSTER' },
        { name: 'WETZEL', fips: '54103', id: '54103 - WETZEL' },
        { name: 'WIRT', fips: '54105', id: '54105 - WIRT' },
        { name: 'WOOD', fips: '54107', id: '54107 - WOOD' },
        { name: 'WYOMING', fips: '54109', id: '54109 - WYOMING' },
    ],
    WI: [
        { name: 'ADAMS', fips: '55001', id: '55001 - ADAMS' },
        { name: 'ASHLAND', fips: '55003', id: '55003 - ASHLAND' },
        { name: 'BARRON', fips: '55005', id: '55005 - BARRON' },
        { name: 'BAYFIELD', fips: '55007', id: '55007 - BAYFIELD' },
        { name: 'BROWN', fips: '55009', id: '55009 - BROWN' },
        { name: 'BUFFALO', fips: '55011', id: '55011 - BUFFALO' },
        { name: 'BURNETT', fips: '55013', id: '55013 - BURNETT' },
        { name: 'CALUMET', fips: '55015', id: '55015 - CALUMET' },
        { name: 'CHIPPEWA', fips: '55017', id: '55017 - CHIPPEWA' },
        { name: 'CLARK', fips: '55019', id: '55019 - CLARK' },
        { name: 'COLUMBIA', fips: '55021', id: '55021 - COLUMBIA' },
        { name: 'CRAWFORD', fips: '55023', id: '55023 - CRAWFORD' },
        { name: 'DANE', fips: '55025', id: '55025 - DANE' },
        { name: 'DODGE', fips: '55027', id: '55027 - DODGE' },
        { name: 'DOOR', fips: '55029', id: '55029 - DOOR' },
        { name: 'DOUGLAS', fips: '55031', id: '55031 - DOUGLAS' },
        { name: 'DUNN', fips: '55033', id: '55033 - DUNN' },
        { name: 'EAU CLAIRE', fips: '55035', id: '55035 - EAU CLAIRE' },
        { name: 'FLORENCE', fips: '55037', id: '55037 - FLORENCE' },
        { name: 'FOND DU LAC', fips: '55039', id: '55039 - FOND DU LAC' },
        { name: 'FOREST', fips: '55041', id: '55041 - FOREST' },
        { name: 'GRANT', fips: '55043', id: '55043 - GRANT' },
        { name: 'GREEN', fips: '55045', id: '55045 - GREEN' },
        { name: 'GREEN LAKE', fips: '55047', id: '55047 - GREEN LAKE' },
        { name: 'IOWA', fips: '55049', id: '55049 - IOWA' },
        { name: 'IRON', fips: '55051', id: '55051 - IRON' },
        { name: 'JACKSON', fips: '55053', id: '55053 - JACKSON' },
        { name: 'JEFFERSON', fips: '55055', id: '55055 - JEFFERSON' },
        { name: 'JUNEAU', fips: '55057', id: '55057 - JUNEAU' },
        { name: 'KENOSHA', fips: '55059', id: '55059 - KENOSHA' },
        { name: 'KEWAUNEE', fips: '55061', id: '55061 - KEWAUNEE' },
        { name: 'LA CROSSE', fips: '55063', id: '55063 - LA CROSSE' },
        { name: 'LAFAYETTE', fips: '55065', id: '55065 - LAFAYETTE' },
        { name: 'LANGLADE', fips: '55067', id: '55067 - LANGLADE' },
        { name: 'LINCOLN', fips: '55069', id: '55069 - LINCOLN' },
        { name: 'MANITOWOC', fips: '55071', id: '55071 - MANITOWOC' },
        { name: 'MARATHON', fips: '55073', id: '55073 - MARATHON' },
        { name: 'MARINETTE', fips: '55075', id: '55075 - MARINETTE' },
        { name: 'MARQUETTE', fips: '55077', id: '55077 - MARQUETTE' },
        { name: 'MENOMINEE', fips: '55078', id: '55078 - MENOMINEE' },
        { name: 'MILWAUKEE', fips: '55079', id: '55079 - MILWAUKEE' },
        { name: 'MONROE', fips: '55081', id: '55081 - MONROE' },
        { name: 'OCONTO', fips: '55083', id: '55083 - OCONTO' },
        { name: 'ONEIDA', fips: '55085', id: '55085 - ONEIDA' },
        { name: 'OUTAGAMIE', fips: '55087', id: '55087 - OUTAGAMIE' },
        { name: 'OZAUKEE', fips: '55089', id: '55089 - OZAUKEE' },
        { name: 'PEPIN', fips: '55091', id: '55091 - PEPIN' },
        { name: 'PIERCE', fips: '55093', id: '55093 - PIERCE' },
        { name: 'POLK', fips: '55095', id: '55095 - POLK' },
        { name: 'PORTAGE', fips: '55097', id: '55097 - PORTAGE' },
        { name: 'PRICE', fips: '55099', id: '55099 - PRICE' },
        { name: 'RACINE', fips: '55101', id: '55101 - RACINE' },
        { name: 'RICHLAND', fips: '55103', id: '55103 - RICHLAND' },
        { name: 'ROCK', fips: '55105', id: '55105 - ROCK' },
        { name: 'RUSK', fips: '55107', id: '55107 - RUSK' },
        { name: 'ST. CROIX', fips: '55109', id: '55109 - ST. CROIX' },
        { name: 'SAUK', fips: '55111', id: '55111 - SAUK' },
        { name: 'SAWYER', fips: '55113', id: '55113 - SAWYER' },
        { name: 'SHAWANO', fips: '55115', id: '55115 - SHAWANO' },
        { name: 'SHEBOYGAN', fips: '55117', id: '55117 - SHEBOYGAN' },
        { name: 'TAYLOR', fips: '55119', id: '55119 - TAYLOR' },
        { name: 'TREMPEALEAU', fips: '55121', id: '55121 - TREMPEALEAU' },
        { name: 'VERNON', fips: '55123', id: '55123 - VERNON' },
        { name: 'VILAS', fips: '55125', id: '55125 - VILAS' },
        { name: 'WALWORTH', fips: '55127', id: '55127 - WALWORTH' },
        { name: 'WASHBURN', fips: '55129', id: '55129 - WASHBURN' },
        { name: 'WASHINGTON', fips: '55131', id: '55131 - WASHINGTON' },
        { name: 'WAUKESHA', fips: '55133', id: '55133 - WAUKESHA' },
        { name: 'WAUPACA', fips: '55135', id: '55135 - WAUPACA' },
        { name: 'WAUSHARA', fips: '55137', id: '55137 - WAUSHARA' },
        { name: 'WINNEBAGO', fips: '55139', id: '55139 - WINNEBAGO' },
        { name: 'WOOD', fips: '55141', id: '55141 - WOOD' },
    ],
    WY: [
        { name: 'ALBANY', fips: '56001', id: '56001 - ALBANY' },
        { name: 'BIG HORN', fips: '56003', id: '56003 - BIG HORN' },
        { name: 'CAMPBELL', fips: '56005', id: '56005 - CAMPBELL' },
        { name: 'CARBON', fips: '56007', id: '56007 - CARBON' },
        { name: 'CONVERSE', fips: '56009', id: '56009 - CONVERSE' },
        { name: 'CROOK', fips: '56011', id: '56011 - CROOK' },
        { name: 'FREMONT', fips: '56013', id: '56013 - FREMONT' },
        { name: 'GOSHEN', fips: '56015', id: '56015 - GOSHEN' },
        { name: 'HOT SPRINGS', fips: '56017', id: '56017 - HOT SPRINGS' },
        { name: 'JOHNSON', fips: '56019', id: '56019 - JOHNSON' },
        { name: 'LARAMIE', fips: '56021', id: '56021 - LARAMIE' },
        { name: 'LINCOLN', fips: '56023', id: '56023 - LINCOLN' },
        { name: 'NATRONA', fips: '56025', id: '56025 - NATRONA' },
        { name: 'NIOBRARA', fips: '56027', id: '56027 - NIOBRARA' },
        { name: 'PARK', fips: '56029', id: '56029 - PARK' },
        { name: 'PLATTE', fips: '56031', id: '56031 - PLATTE' },
        { name: 'SHERIDAN', fips: '56033', id: '56033 - SHERIDAN' },
        { name: 'SUBLETTE', fips: '56035', id: '56035 - SUBLETTE' },
        { name: 'SWEETWATER', fips: '56037', id: '56037 - SWEETWATER' },
        { name: 'TETON', fips: '56039', id: '56039 - TETON' },
        { name: 'UINTA', fips: '56041', id: '56041 - UINTA' },
        { name: 'WASHAKIE', fips: '56043', id: '56043 - WASHAKIE' },
        { name: 'WESTON', fips: '56045', id: '56045 - WESTON' },
    ],
};

export default countyData;
