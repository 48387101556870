import axios from 'axios';
import { createLoanQuery } from '../queries';
import parseError from '../errors/utils';

const createLoan = async (model) => {
    try {
        const query = createLoanQuery({ model });
        const request = await axios.post(query.url, { ...query.body });
        return request.data;
    } catch (error) {
        return { error: parseError({ error }) };
    }
};

export default createLoan;
