// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
/* eslint-disable-next-line */
import classNames from 'classnames';
import { fade, withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Add from '@material-ui/icons/Add';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import { FormGroup } from '@material-ui/core';
import moment from 'moment-timezone';
import store from 'app/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { TableSelectionDialog } from './dialogs';
import { MMSplashScreen } from '../custom-components';
import { getUserRegion, numberFormat, secondsToTimeConverter } from '../../utils';
import * as Actions from '../store/actions';
import jwtService from '../services/jwtService';
import * as userActions from '../auth/store/actions';

const toolbarStyles = (theme) => ({
    root: { paddingRight: theme.spacing(1) },
    highlight: theme.palette.type === 'light' ? { color: theme.palette.secondary.main, backgroundColor: lighten(theme.palette.secondary.light, 0.85) } : { color: theme.palette.text.primary, backgroundColor: theme.palette.secondary.dark },
    spacer: { flex: '1 1 25%%' },
    actions: {
        align: 'right',
        flex: '1 1 inherit',
        color: theme.palette.text.secondary,
    },
    title: { flex: '1 1 25%', marginRight: theme.spacing(2) },
    search: {
        flex: '1 1 50%',
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': { backgroundColor: fade(theme.palette.common.white, 0.25) },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    searchIcon: { display: 'none', [theme.breakpoints.up('sm')]: { display: 'block' } },
    inputInput: {
        padding: theme.spacing(0),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: { width: '20ch' },
    },
});

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const EnhancedTableSearch = ({ value, onChange, filterLabel, classes }) => (
    <Grid container spacing={1} alignItems="center">
        <Grid item>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
        </Grid>
        <Grid item>
            <Input id="search-input" placeholder={`Search by ${filterLabel}`} value={value} onChange={onChange} />
        </Grid>
    </Grid>
);

class EnhancedTableHead extends Component {
    createSortHandler = (property) => (event) => {
        const { onRequestSort } = this.props;
        onRequestSort(event, property);
    };

    render() {
        // eslint-disable-next-line global-require
        const uuidv4 = require('uuid/v4');
        const { order, orderBy, columnSettings } = this.props;

        return (
            <TableHead>
                <TableRow>
                    <TableCell padding="checkbox" />
                    {columnSettings.map((row) => (
                        <TableCell key={uuidv4()} align="justify" padding="default" sortDirection={orderBy === row.id ? order : false}>
                            <Tooltip title="Sort" placement="bottom-end" enterDelay={300}>
                                <TableSortLabel active={orderBy === row.id} direction={order} onClick={this.createSortHandler(row.id)}>
                                    {row.label}
                                </TableSortLabel>
                            </Tooltip>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        );
    }
}

let EnhancedTableToolbar = (props) => {
    const { numSelected, classes, tableTitle, handleAdd, addActionButton, headerComponent, filter, onFilterChange, filterLabel } = props;

    return (
        <Toolbar className={classNames(classes.root, { [classes.highlight]: numSelected > 0 })}>
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="body1">
                        {numSelected} selected
                    </Typography>
                ) : (
                    <Typography className="text-16 font-bold" id="tableTitle">
                        {tableTitle}
                    </Typography>
                )}
            </div>
            <div className={classes.search}>{filterLabel && <EnhancedTableSearch value={filter} onChange={onFilterChange} filterLabel={filterLabel} classes={classes} />}</div>
            <div className={classes.spacer} />
            {headerComponent && <div>{headerComponent}</div>}
            <div className={classes.actions}>
                <FormGroup row>
                    {numSelected === 0 && addActionButton && (
                        <Tooltip title={addActionButton.title}>
                            <IconButton onClick={() => handleAdd(addActionButton)} aria-label="Add">
                                <Add />
                            </IconButton>
                        </Tooltip>
                    )}
                </FormGroup>
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = (theme) => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        height: '100vh',
    },
    table: { minWidth: '50%', maxWidth: '100%' },
    tableWrapper: { overflow: 'auto' },
    row: { '&:nth-of-type(odd)': { backgroundColor: '#f6f6f6' }, fontSize: '6px' },
});

class EnhancedTable extends Component {
    state = {
        order: 'asc',
        orderBy: null,
        selected: [],
        page: 0,
        rowsPerPage: 5,
        // data: {},
        displayedData: [],
        filter: '',
        region: getUserRegion(),
    };

    componentDidMount() {
        const { passedData, transformFunction, defaultRowAmount, orderBy, order } = this.props;
        this.setState({
            orderBy,
            order,
            displayedData: transformFunction(passedData),
            rowsPerPage: defaultRowAmount || 25,
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { passedData, transformFunction } = this.props;
        if (prevProps !== this.props) {
            this.setState({ displayedData: transformFunction(passedData), selected: [] });
        }
    }

    handleRequestSort = (event, property) => {
        const { orderBy, order } = this.state;

        this.setState({ order: orderBy === property && order === 'asc' ? 'desc' : 'asc', orderBy: property });
    };

    handleSelectionClick = (event, id) => {
        const { selected, displayedData } = this.state;
        const { passedData } = this.props;
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        this.setState({ selected: passedData.filter((v) => displayedData[newSelected[0] - 1].selected_id === v.id) });
    };

    handleAdd = (actionButton) => {
        const { history } = this.props;

        history.push({ pathname: actionButton.redirectURL, state: { model: actionButton.selected ? actionButton.selected.model : null } });
    };

    handleDownload = (type, selected) => {
        const { downloadReport } = this.props;
        downloadReport(type, selected);
    };

    handleEdit = (actionButton, selected) => {
        const { history } = this.props;

        store.dispatch(Actions.setEditReducer(selected));
        history.push({ pathname: `${actionButton.redirectURL}/${selected?.id}` });
    };

    handleInspect = (actionButton, selected) => {
        const { history } = this.props;
        if (actionButton.title === 'Inspect Route Session') {
            store.dispatch(Actions.setInspectRouteSessionReducer(selected));
        } else {
            store.dispatch(Actions.setInspectReducer(selected));
        }

        history.push({ pathname: `${actionButton.redirectURL}/${selected?.id}` });
    };

    handleLoginAsUser = async (actionButton, selected) => {
        const { setUserData, history } = this.props;
        const user = await jwtService.signInAsImpersonatedUser(selected.id);
        setUserData(user);
        history.push({ pathname: '/dashboard' });
        window.location.reload();
    };

    handleSelectionClose = () => {
        this.setState({ selected: [] });
    };

    handleChangePage = (event, page) => {
        this.setState({ page });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: event.target.value });
    };

    handleFilterChange = (event) => {
        this.setState({ filter: event.target.value });
    };

    isSelected = (id, selected) => selected.indexOf(id) !== -1;

    getTotal = (items) =>
        items
            .filter(({ volume }) => volume > 0)
            .map(({ volume }) => parseFloat(volume))
            .reduce((sum, i) => sum + i, 0);

    getFormatted = (value, formatter) => {
        let returnVal = value;

        switch (formatter[0]) {
            case 'Number':
                returnVal = numberFormat(parseFloat(value), formatter[1], '.', ',') + (formatter[2] || '');
                break;

            case 'Date':
                returnVal = value ? moment(value).format(formatter[1]) : '';
                break;

            case 'Time from Seconds':
                returnVal = secondsToTimeConverter(parseFloat(value));
                break;

            case 'Time from Milliseconds':
                returnVal = Math.floor(moment.duration(value).asHours()) + moment.utc(value).format(':mm:ss');
                break;

            default:
                break;
        }

        return returnVal;
    };

    render() {
        // eslint-disable-next-line global-require
        const uuidv4 = require('uuid/v4');
        const {
            classes,
            highlightFlaggedRow,
            columnSettings,
            tableTitle,
            loading,
            editActionButton,
            addActionButton,
            inspectActionButton,
            inspectRouteSessionActionButton,
            deleteActionButton,
            loginActionButton,
            inspectLeaseActionButton,
            slipActionButton,
            downloadActionButton,
            reportActionButton,
            spannedTotal,
            autoOpen,
            forceFetch,
            headerComponent,
            disableRowsPerPage,
            highlightYellow,
            filterBy,
            customDialog,
        } = this.props;
        const { order, orderBy, region, selected, rowsPerPage, page, displayedData, filter } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, displayedData.length - page * rowsPerPage);
        const enabledCheckBox = addActionButton || editActionButton || inspectActionButton || loginActionButton || deleteActionButton || slipActionButton || reportActionButton || downloadActionButton || inspectLeaseActionButton || customDialog;
        const { getFormatted } = this;
        const filterLabel = filterBy && columnSettings.filter((row) => row.id === filterBy).length > 0 ? columnSettings.filter((row) => row.id === filterBy)[0].label : null;

        const dialog = customDialog ? (
            React.cloneElement(customDialog, { handleSelectionClose: this.handleSelectionClose, selected })
        ) : (
            <div className={classes.actions}>
                <TableSelectionDialog
                    selected={selected}
                    displayedData={displayedData}
                    handleAdd={this.handleAdd}
                    handleDownload={this.handleDownload}
                    handleEdit={this.handleEdit}
                    handleInspect={this.handleInspect}
                    handleLogin={this.handleLoginAsUser}
                    addActionButton={addActionButton}
                    editActionButton={editActionButton}
                    inspectActionButton={inspectActionButton}
                    inspectRouteSessionActionButton={inspectRouteSessionActionButton}
                    deleteActionButton={deleteActionButton}
                    slipActionButton={slipActionButton}
                    reportActionButton={reportActionButton}
                    downloadActionButton={downloadActionButton}
                    inspectLeaseActionButton={inspectLeaseActionButton}
                    loginActionButton={loginActionButton}
                    forceFetch={forceFetch}
                    handleSelectionClose={this.handleSelectionClose}
                    autoOpen={autoOpen}
                />
            </div>
        );

        return (
            <div>
                <Paper className={`${classes.root}table-responsive rounded-8`}>
                    {selected.length > 0 && dialog}

                    <EnhancedTableToolbar numSelected={selected.length} tableTitle={tableTitle} handleAdd={this.handleAdd} addActionButton={addActionButton} headerComponent={headerComponent} filter={filter} onFilterChange={this.handleFilterChange} filterLabel={filterLabel} />

                    <div className={window.innerWidth > 1165 ? `${classes.tableWrapper}table-responsive` : `${classes.tableWrapper}`}>
                        {loading && <MMSplashScreen />}

                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <EnhancedTableHead
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                // onSelectAllClick={this.handleSelectAllClick}
                                onRequestSort={(event, property) => this.handleRequestSort(event, property)}
                                rowCount={displayedData.length}
                                columnSettings={columnSettings}
                            />
                            <TableBody className="height=inherit">
                                {stableSort(displayedData, getSorting(order, orderBy))
                                    .filter((row) => (filterBy && row[filterBy] ? row[filterBy].toLowerCase().includes(filter.toLowerCase()) : true))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        const isSelected = this.isSelected(row.id, selected);
                                        return (
                                            <TableRow
                                                hover
                                                className={classes.row}
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={uuidv4()}
                                                selected={isSelected}
                                                style={row.flagged && highlightFlaggedRow ? { backgroundColor: 'rgba(192,10,0,0.50)' } : row.flagged_yellow && highlightYellow ? { backgroundColor: 'rgba(255,214,9,0.50)' } : null}
                                            >
                                                <TableCell padding="none">
                                                    <Checkbox onClick={(event) => (!selected.length || isSelected ? this.handleSelectionClick(event, row.id) : null)} checked={isSelected} disabled={(!!selected.length && !isSelected) || !enabledCheckBox} />
                                                </TableCell>

                                                {Object.values(row)
                                                    .slice(2)
                                                    .map((value, index) => {
                                                        if (Object.keys(row).find((key) => row[key] === value) === 'flagged' || Object.keys(row).find((key) => row[key] === value) === 'flagged_yellow') {
                                                            return null;
                                                        }
                                                        return !index ? (
                                                            <TableCell key={uuidv4()} component="th" scope="row" padding="default">
                                                                {columnSettings[index] && columnSettings[index].formatter ? getFormatted(value, columnSettings[index].formatter) : value}
                                                            </TableCell>
                                                        ) : (
                                                            <TableCell key={uuidv4()} align="left" padding="default" className={columnSettings[index] && columnSettings[index].flaggable && row.flagged ? 'text-red font-bold' : 'text-black'}>
                                                                {columnSettings[index] && columnSettings[index].formatter ? getFormatted(value, columnSettings[index].formatter) : value}
                                                            </TableCell>
                                                        );
                                                    })}
                                            </TableRow>
                                        );
                                    })}
                                {spannedTotal && (
                                    <TableRow>
                                        <TableCell rowSpan={1} />
                                        <TableCell colSpan={5 + !!(tableTitle === 'Pickups')} />
                                        <TableCell>Total</TableCell>
                                        <TableCell padding="default" colSpan={3}>
                                            {['MMPA'].includes(region) ? getFormatted(this.getTotal(displayedData), ['Number', 0]) : getFormatted(this.getTotal(displayedData), ['Number', 2])}
                                        </TableCell>
                                    </TableRow>
                                )}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 49 * (emptyRows - 1) }}>
                                        <TableCell colSpan={12} />
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                    <TablePagination
                        rowsPerPageOptions={!disableRowsPerPage ? [5, 10, 25, 50, 100, 250] : []}
                        component="div"
                        count={displayedData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        className={'fs-ignore-rage-clicks'}
                    />
                </Paper>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setUserData: userActions.setUserData }, dispatch);
}

export default withRouter(withStyles(styles)(connect(null, mapDispatchToProps)(EnhancedTable)));
