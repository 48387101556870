import axios from 'axios';
import { editLoanQuery } from '../queries';
import parseError from '../errors/utils';

const editLoan = async (model, id) => {
    try {
        const query = editLoanQuery({ model, id });
        const request = await axios.put(query.url, { ...query.body });
        return request.data;
    } catch (error) {
        return { error: parseError({ error }) };
    }
};

export default editLoan;
