import axios from 'axios';
import { createMerchandisePurchaseQuery } from './queries';

const addMerchandisePurchase = async (model) => {
    try {
        const query = createMerchandisePurchaseQuery({ model });
        const request = axios.post(query.url, { ...query.body });
        const { data } = await request;
        return { data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to add purchase');
    }
};

export default addMerchandisePurchase;
