import axios from 'axios';
import { resetCustomTableConfigurationQuery } from './queries';

const resetCustomTableConfiguration = ({ tableName, mode }) =>
    new Promise((resolve, reject) => {
        const query = resetCustomTableConfigurationQuery({ tableName, mode });
        const request = axios.put(query.url, { ...query.body });

        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default resetCustomTableConfiguration;
