import React from 'react';
import { Grid } from '@material-ui/core';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';

const PrairieFarmsFederalOrder7 = () => (
    <>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Skim ($/cwt)" name={'milk_prices.pay_group_71.skim_milk_price'} type={'number'} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="Butterfat ($/lb)" name={'milk_prices.pay_group_71.butter_fat_price'} type={'number'} />
        </Grid>
        <Grid item xs={12} sm={3}>
            <ReactHookFormInput label="3.5%" name={'milk_prices.pay_group_71.milk_at_3percent'} type={'number'} />
        </Grid>
        <Grid item xs={12} sm={3}></Grid>
    </>
);

export default PrairieFarmsFederalOrder7;
