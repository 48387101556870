import axios from 'axios';
import moment from 'moment-timezone';
import _ from 'lodash';
import { getDaysInMonth, getStandardDeviation, getTwoStringOfMonth, combinePickups } from '../../utils';
// eslint-disable-next-line no-unused-vars
import { getAlertData, getTimeSinceLastPickup } from './utils';
import { getLabReportsQuery, getPickupsQuery, getProducersQuery, getDropoffsQuery } from './queries';

const getEmptyObjectMonths = (month, year) => {
    let currentMonth = parseInt(month);
    let currentYear = parseInt(year);
    const monthObject = {};
    for (let i = 0; i < 24; i += 1) {
        monthObject[`${currentYear}-${currentMonth < 10 ? '0' : ''}${currentMonth}`] = [];
        if (currentMonth > 1) {
            currentMonth -= 1;
        } else {
            currentMonth = 12;
            currentYear -= 1;
        }
    }
    return monthObject;
};

const calculateDropoffTotals = (dropoffs, year, month) => {
    const dropoffsByMonth = _.groupBy(dropoffs, (d) => moment(d.created_at).format('YYYY-MM'));

    const lastMonthDateKey = moment(`${year}-${month}`).subtract(1, 'month').format('YYYY-MM');
    const thisMonthDateKey = `${year}-${month}`;

    const lastMonthDropoffs = dropoffsByMonth[lastMonthDateKey] ? dropoffsByMonth[lastMonthDateKey] : [];
    const thisMonthDropoffs = dropoffsByMonth[thisMonthDateKey] ? dropoffsByMonth[thisMonthDateKey] : [];

    const lastMonthDropoffsTotal = lastMonthDropoffs.length ? _.sumBy(lastMonthDropoffs, 'volume') : 0;
    const thisMonthDropoffsTotal = thisMonthDropoffs.length ? _.sumBy(thisMonthDropoffs, 'volume') : 0;

    const dropoffTotals = [lastMonthDropoffsTotal, thisMonthDropoffsTotal];
    return dropoffTotals;
};

const getAdminDashboardDetails = (month, year, region) =>
    new Promise((resolve, reject) => {
        const startDate = moment(`${parseInt(month) < 12 ? parseInt(year) - 2 : parseInt(year) - 1}-${getTwoStringOfMonth(parseInt(month) + 1)}-01`)
            .startOf('month')
            .format();
        const endDate = moment(`${year}-${getTwoStringOfMonth(month)}-${getDaysInMonth(parseInt(month), parseInt(year))}`)
            .endOf('month')
            .format();
        const dropoffStartDate = moment(`${year}-${month}`).subtract(1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss');

        const pickupsQuery = getPickupsQuery({
            start: startDate,
            end: endDate,
            order: 'created_at ASC',
            filterDeleted: true,
            filterType: 'milk',
        });
        const labsQuery = getLabReportsQuery({
            start: startDate,
            end: endDate,
            order: 'date ASC',
            bySampleDate: true,
            filterOutliers: true,
            filterDeleted: true,
        });
        // TODO: make EST fluid sales method for NL dashboard
        // const quotaQuery = getMonthlyQuotaQuery({ start: moment(`${year}-${month}-01 00:00:00`).format('YYYY-MM-DD HH:mm:ss'), end: endDate });
        const producerQuery = getProducersQuery({
            filterDeleted: true,
            includeSubUser: false,
            orderBy: 'order',
        });

        const dropoffsQuery = getDropoffsQuery({
            fields: ['id', 'created_at', 'volume'],
            start: dropoffStartDate,
            end: endDate,
            order: 'created_at ASC',
            filterDeleted: true,
        });

        const pickups = axios.get(pickupsQuery);
        const labs = axios.get(labsQuery);
        const producers = axios.get(producerQuery);
        const dropoffs = axios.get(dropoffsQuery);
        // const quotaTotals = axios.get(quotaQuery);
        const producerRequest = axios.get(getProducersQuery({ filterDeleted: true }));

        producerRequest
            .then((producerResponse) => {
                const producersResponse = producerResponse.data;

                const producerIds = _.uniq(_.map(producersResponse, 'id'));

                const fields = ['producer_id', 'created_at'];

                const pickupRequests = _.map(producerIds, (id) =>
                    axios.get(
                        getPickupsQuery({
                            producerId: id,
                            fields,
                            order: 'created_at DSC',
                            limit: 1,
                        })
                    )
                );

                /* eslint-disable */
                const data = {
                    fluidSales: { thisMonth: '0', lastMonth: '0' },
                    fluidSalesChange: 0,
                    graphDetails: {
                        lastYear: {
                            production: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            fat: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            scc: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            bactoscan: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                        thisYear: {
                            production: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            fat: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            scc: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            bactoscan: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            projection: 0,
                        },
                        routes: {
                            DL: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            SJ: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            BSG: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                            MGT: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        },
                    },
                    industryDetails: {
                        previousMonth: {
                            fluid_production: 0,
                            dropoffs_total: 0,
                            fat: 0,
                            scc: 0,
                            bacto_scan: 0,
                        },
                        thisMonth: {
                            fluid_production: 0,
                            dropoffs_total: 0,
                            fat: 0,
                            scc: 0,
                            bacto_scan: 0,
                        },
                    },
                    std_plot: {
                        bacto: {
                            outliers: {},
                            cutoff_low: 0,
                            cutoff_high: 0,
                            data: [],
                        },
                        scc: {
                            outliers: {},
                            cutoff_low: 0,
                            cutoff_high: 0,
                            data: [],
                        },
                    },
                    tableDetails: [],
                };

                axios.all([pickups, labs, producers, dropoffs, ...pickupRequests]).then(
                    axios.spread((...responses) => {
                        const isNL = region === 'NL';
                        const producerSet = responses[2].data;
                        const dropoffs = responses[3].data;

                        const pickupResponses = responses.slice(4);
                        const pickups = _.flatten(_.map(pickupResponses, (response) => response.data));
                        const pIds = _.uniq(_.map(pickups, 'producer_id'));
                        const results = _.flatten(
                            _.map(pIds, (id) => {
                                const producer = _.find(producerSet, { id: id });
                                const pickup = _.find(pickups, { producer_id: id });
                                const ms = pickup ? getTimeSinceLastPickup(pickup) : 0;
                                if (producer) {
                                    return {
                                        id: producer.id,
                                        time_since_last_pickup: ms,
                                    };
                                }
                            })
                        );

                        const [lastMonthDropoffsTotal, thisMonthDropoffsTotal] = calculateDropoffTotals(dropoffs, year, month);

                        data.tableDetails = producerSet;

                        let monthlyPickupObject = getEmptyObjectMonths(month, year);
                        let monthlyLabObject = getEmptyObjectMonths(month, year);

                        const dateGroupedPickupSet = _.groupBy(
                            _.filter(responses[0].data, (pickup) => !pickup.deleted_at),
                            (value) => value.created_at.substr(0, 7)
                        );
                        const dateGroupedLabSet = _.groupBy(
                            _.filter(responses[1].data, (lab) => !lab.deleted_at),
                            (value) => value.date.substr(0, 7)
                        );
                        const routeRegionGroupedProducerIds = _.mapValues(
                            _.groupBy(responses[2].data, (value) => value.route_region),
                            (v) => _.mapValues(v, 'id')
                        );

                        _.keys(dateGroupedPickupSet).forEach((key) => (monthlyPickupObject[key] = dateGroupedPickupSet[key]));
                        _.keys(dateGroupedLabSet).forEach((key) => (monthlyLabObject[key] = dateGroupedLabSet[key]));

                        // graph details -- Production && Routes
                        _.sortBy(_.keys(monthlyPickupObject)).forEach((key, index) => {
                            if (index < 12) {
                                data.graphDetails.lastYear.production[index] = _.round(
                                    _.sumBy(monthlyPickupObject[key], (v) => v.volume),
                                    2
                                );
                            } else if (index < 24) {
                                const i = index % 12;

                                data.graphDetails.thisYear.production[i] = _.round(
                                    _.sumBy(monthlyPickupObject[key], (v) => v.volume),
                                    2
                                );

                                // sum route_region totals

                                data.graphDetails.routes.DL[i] = _.round(
                                    _.sumBy(
                                        monthlyPickupObject[key].filter((v) => _.includes(_.values(routeRegionGroupedProducerIds.DL), v.producer_id)),
                                        (v) => v.volume
                                    ),
                                    2
                                );
                                data.graphDetails.routes.BSG[i] = _.round(
                                    _.sumBy(
                                        monthlyPickupObject[key].filter((v) => _.includes(_.values(routeRegionGroupedProducerIds.BSG), v.producer_id)),
                                        (v) => v.volume
                                    ),
                                    2
                                );
                                data.graphDetails.routes.MGT[i] = _.round(
                                    _.sumBy(
                                        monthlyPickupObject[key].filter((v) => _.includes(_.values(routeRegionGroupedProducerIds.MGT), v.producer_id)),
                                        (v) => v.volume
                                    ),
                                    2
                                );
                                data.graphDetails.routes.SJ[i] = _.round(
                                    _.sumBy(
                                        monthlyPickupObject[key].filter((v) => _.includes(_.values(routeRegionGroupedProducerIds.SJ), v.producer_id)),
                                        (v) => v.volume
                                    ),
                                    2
                                );

                                if (index === 22) {
                                    // if totalling previous month value for current month, show what production was at end of day in month rather than at end of month
                                    if (moment({ month: parseInt(key.substring(5)) }).format('YYYY-MM') === moment().format('YYYY-MM')) {
                                        data.industryDetails.previousMonth.fluid_production = _.round((data.graphDetails.thisYear.production[i] / moment().daysInMonth()) * moment().date(), 2);
                                        data.industryDetails.previousMonth.dropoffs_total = _.round((lastMonthDropoffsTotal / moment().daysInMonth()) * moment().date(), 2);
                                    } else {
                                        data.industryDetails.previousMonth.fluid_production = data.graphDetails.thisYear.production[i];
                                        data.industryDetails.previousMonth.dropoffs_total = lastMonthDropoffsTotal;
                                    }
                                } else if (index === 23) {
                                    // if current month, re-calculate this month for graph as projection
                                    if (moment().format('YYYY-MM') === key) {
                                        data.graphDetails.thisYear.projection = _.round((data.graphDetails.thisYear.production[i] / moment().date()) * moment().daysInMonth(), 2);
                                    }

                                    data.industryDetails.thisMonth.fluid_production = data.graphDetails.thisYear.production[i];
                                    data.industryDetails.thisMonth.dropoffs_total = thisMonthDropoffsTotal;

                                    const pickupMonthGroupedByProducer = _.groupBy(monthlyPickupObject[key], (v) => v.producer_id);
                                    const pickupMonthGroupedByProducerKeys = new Set(_.keys(pickupMonthGroupedByProducer));

                                    _.forEach(data.tableDetails, (value, key) => {
                                        if (pickupMonthGroupedByProducerKeys.has(value.id)) {
                                            const pickupsGroupedByDay = _.groupBy(combinePickups(pickupMonthGroupedByProducer[value.id]), (pickup) => moment(pickup.created_at).format('YYYY-MM-DD'));
                                            data.tableDetails[key] = {
                                                ...value,
                                                num_pickups: combinePickups(pickupMonthGroupedByProducer[value.id]).length,
                                                volume: _.round(
                                                    _.sumBy(pickupMonthGroupedByProducer[value.id], (v) => v.volume),
                                                    2
                                                ),
                                                last_pickup_time: _.find(results, { id: value.id }).time_since_last_pickup,
                                                num_days_pickup: Object.keys(pickupsGroupedByDay).length,
                                            };
                                        }
                                    });
                                }
                            }
                        });

                        _.sortBy(_.keys(monthlyLabObject)).forEach((key, index) => {
                            let fatCutoffs = null;
                            let sccCutoffs = null;
                            let bactoCutoffs = null;

                            const fatLabValues = monthlyLabObject[key].reduce((arr, value) => {
                                if (!!value.fat) {
                                    arr.push(value.fat);
                                }
                                return arr;
                            }, []);

                            const sccLabValues = monthlyLabObject[key].reduce((arr, value) => {
                                if (!!value.somatic_cell_count) {
                                    arr.push(value.somatic_cell_count);
                                }
                                return arr;
                            }, []);

                            const bactoLabValues = monthlyLabObject[key].reduce((arr, value) => {
                                if (!!value.bacto_scan) {
                                    arr.push(value.bacto_scan);
                                }
                                return arr;
                            }, []);

                            if (isNL || index === 23) {
                                // get outlier data before removing values for NL
                                const sccAverage = _.mean(sccLabValues);
                                const bactoAverage = _.mean(bactoLabValues);
                                const sccSTD = getStandardDeviation(sccLabValues);
                                const bactoSTD = getStandardDeviation(bactoLabValues);

                                sccCutoffs = { cutoff_high: sccAverage + sccSTD + sccSTD, cutoff_low: sccAverage - sccSTD - sccSTD < 0 ? 0 : sccAverage - sccSTD - sccSTD };
                                bactoCutoffs = { cutoff_high: bactoAverage + bactoSTD + bactoSTD, cutoff_low: bactoAverage - bactoSTD - bactoSTD < 0 ? 0 : bactoAverage - bactoSTD - bactoSTD };

                                if (index === 23) {
                                    data.std_plot.bacto.cutoff_low = bactoCutoffs.cutoff_low;
                                    data.std_plot.bacto.cutoff_high = bactoCutoffs.cutoff_high;
                                    data.std_plot.bacto.data = bactoLabValues;

                                    data.std_plot.bacto.outliers = monthlyLabObject[key].reduce((arr, value) => {
                                        if (!!value.bacto_scan && (value.bacto_scan < bactoCutoffs.cutoff_low || value.bacto_scan > bactoCutoffs.cutoff_high)) {
                                            const producerName = _.find(producerSet, { id: value.producer_id }).name;
                                            if (_.includes(_.keys(arr), producerName)) {
                                                arr[producerName].push({ value: value.bacto_scan, id: value.id });
                                            } else {
                                                arr[producerName] = [{ value: value.bacto_scan, id: value.id }];
                                            }
                                        }
                                        return arr;
                                    }, {});

                                    data.std_plot.scc.cutoff_low = sccCutoffs.cutoff_low;
                                    data.std_plot.scc.cutoff_high = sccCutoffs.cutoff_high;
                                    data.std_plot.scc.data = sccLabValues;

                                    data.std_plot.scc.outliers = monthlyLabObject[key].reduce((arr, value) => {
                                        if (!!value.somatic_cell_count && (value.somatic_cell_count < sccCutoffs.cutoff_low || value.somatic_cell_count > sccCutoffs.cutoff_high)) {
                                            const producerName = _.find(producerSet, { id: value.producer_id }).name;
                                            if (_.includes(_.keys(arr), producerName)) {
                                                arr[producerName].push({ value: value.somatic_cell_count, id: value.id });
                                            } else {
                                                arr[producerName] = [{ value: value.somatic_cell_count, id: value.id }];
                                            }
                                        }
                                        return arr;
                                    }, {});
                                }
                            }

                            const sccAVG = sccCutoffs && isNL ? _.mean(sccLabValues.filter((v) => v >= sccCutoffs.cutoff_low && v <= sccCutoffs.cutoff_high)) : _.mean(sccLabValues);
                            const bactoAVG = bactoCutoffs && isNL ? _.mean(bactoLabValues.filter((v) => v >= bactoCutoffs.cutoff_low && v <= bactoCutoffs.cutoff_high)) : _.mean(bactoLabValues);

                            if (index < 12) {
                                data.graphDetails.lastYear.fat[index] = _.round(_.mean(fatLabValues), 2);
                                data.graphDetails.lastYear.scc[index] = _.round(sccAVG, 2);
                                data.graphDetails.lastYear.bactoscan[index] = _.round(bactoAVG, 2);
                            } else if (index < 24) {
                                const i = index % 12;

                                data.graphDetails.thisYear.fat[i] = _.round(_.mean(fatLabValues), 2);
                                data.graphDetails.thisYear.scc[i] = _.round(sccAVG, 2);
                                data.graphDetails.thisYear.bactoscan[i] = _.round(bactoAVG, 2);

                                if (index === 22) {
                                    data.industryDetails.previousMonth.fat = data.graphDetails.thisYear.fat[i];
                                    data.industryDetails.previousMonth.scc = data.graphDetails.thisYear.scc[i];
                                    data.industryDetails.previousMonth.bacto_scan = data.graphDetails.thisYear.bactoscan[i];
                                } else if (index === 23) {
                                    data.industryDetails.thisMonth.fat = data.graphDetails.thisYear.fat[i];
                                    data.industryDetails.thisMonth.scc = data.graphDetails.thisYear.scc[i];
                                    data.industryDetails.thisMonth.bacto_scan = data.graphDetails.thisYear.bactoscan[i];

                                    const labMonthGroupedByProducer = _.groupBy(monthlyLabObject[key], (v) => v.producer_id);
                                    const labMonthGroupedByProducerKeys = new Set(_.keys(labMonthGroupedByProducer));

                                    _.forEach(data.tableDetails, (value, key) => {
                                        if (labMonthGroupedByProducerKeys.has(value.id)) {
                                            data.tableDetails[key] = {
                                                ...value,
                                                bacto_plc: 0.0,
                                                bacto_scan: _.round(
                                                    _.meanBy(
                                                        labMonthGroupedByProducer[value.id].filter((value) => !!value.bacto_scan),
                                                        (v) => v.bacto_scan
                                                    ),
                                                    2
                                                ),
                                                butter_fat: _.round(
                                                    _.meanBy(
                                                        labMonthGroupedByProducer[value.id].filter((value) => !!value.fat),
                                                        (v) => v.fat
                                                    ),
                                                    2
                                                ),
                                                scc: _.round(
                                                    _.meanBy(
                                                        labMonthGroupedByProducer[value.id].filter((value) => !!value.somatic_cell_count),
                                                        (v) => v.somatic_cell_count
                                                    ),
                                                    2
                                                ),
                                                los: _.round(
                                                    _.meanBy(
                                                        labMonthGroupedByProducer[value.id].filter((value) => !!value.LOS),
                                                        (v) => v.LOS
                                                    ),
                                                    2
                                                ),
                                                protein: _.round(
                                                    _.meanBy(
                                                        labMonthGroupedByProducer[value.id].filter((value) => !!value.protein),
                                                        (v) => v.protein
                                                    ),
                                                    2
                                                ),
                                            };
                                        }
                                    });

                                    const dateRange = {
                                        start: moment(endDate).add(1, 'seconds').subtract(3, 'months'),
                                        end: moment(endDate),
                                    };

                                    const groupedLabReports = _.groupBy(responses[1].data, 'producer_id');

                                    _.forEach(data.tableDetails, (detail) => {
                                        const labReports = groupedLabReports[detail.id] ? groupedLabReports[detail.id] : [];
                                        const alertDetails = getAlertData(dateRange, labReports, region);
                                        const penalties =
                                            region === 'NL'
                                                ? {
                                                      bacto_penalty: alertDetails.bacto_percentage,
                                                      scc_penalty: alertDetails.scc_percentage,
                                                      freezing_penalty: null,
                                                  }
                                                : {
                                                      bacto_penalty: alertDetails.bacto_scan_count_over_standard,
                                                      scc_penalty: alertDetails.scc_count_over_standard,
                                                      freezing_penalty: alertDetails.freezing_point_count_over_standard,
                                                  };

                                        _.assign(detail, penalties);
                                    });
                                }
                            }
                        });
                        // TODO: get penalty information for each producer (NL - bacto and scc percentages, PEI - infraction count for bacto, scc and freezing)
                        resolve(data);
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getAdminDashboardDetails;
