/**
 * Returns name available for user
 * @param {*} user
 * @returns {string}
 */
const getUsersName = (user) => user?.full_name || user?.name;

/**
 *
 * @param {*} user
 * @returns {string | null}
 */
export const getUserLicenseNumber = (user) => {
    if (user.license_number) return `${user.license_number}`;
    if (user.hauler_number) return `${user.hauler_number}`;
    return null;
};

/**
 *
 * @param {string} name
 * @param {string | null} license_number
 * @param {boolean} prefix
 */
export const getUserDisplayName = (name, license_number, prefix) => {
    if (license_number && prefix) return `${license_number} - ${name}`;
    if (license_number) return `${name} - ${license_number}`;
    return name;
};

export default getUsersName;
