import { FuseLoadable } from '@fuse';
import store from '../../../store';

const TestPageConfig = process.env.NODE_ENV === 'development' ? { routes: [{ path: '/testpage', component: FuseLoadable({ loader: () => import('./TestPage') }) }] } : null;

const StylePageConfig = {
    routes: [
        {
            path: '/styles', //
            component: FuseLoadable({
                loader: () => import('./style/Styles'),
            }),
        },
    ],
};

export default [TestPageConfig, StylePageConfig];
