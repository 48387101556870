import axios from 'axios';
import { getExpressAPIHost } from '../../utils';

const uploadClearedPaymentStatus = async (formData) => {
    try {
        const response = await axios.post(`${getExpressAPIHost()}/payment-status/import-cleared-payment-status`, formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        return response;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to upload file. Please contact support.');
    }
};
export default uploadClearedPaymentStatus;
