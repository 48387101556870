import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, TextField, Menu, MenuItem, ListItemIcon, InputAdornment, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

const ProducerFormCountySelect = ({ name, label, options }) => {
    const { control } = useFormContext();
    const [parentOpen, setParentOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isHovered, setIsHovered] = useState(false);

    const [selectedState, setSelectedState] = useState(null);
    const [searchState, setSearchState] = useState('');
    const [searchCounty, setSearchCounty] = useState('');

    const menuPaperProps = {
        style: {
            maxHeight: 250,
            width: 355,
        },
    };

    const handleOpenParent = (event) => {
        setParentOpen(true);
        setAnchorEl(event.currentTarget);
    };

    const handleCloseParent = () => {
        setParentOpen(false);
        setSelectedState(null);
        setAnchorEl(null);
        setSearchState('');
    };

    const handleStateClick = (event, stateAbbr) => {
        event.stopPropagation();
        setSelectedState(stateAbbr);
        setSearchCounty('');
    };

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => {
                return (
                    <Box sx={{ margin: '8px 0' }}>
                        <TextField
                            fullWidth
                            id={'county_id'}
                            variant="standard"
                            value={value ? value.id : ''}
                            onClick={handleOpenParent}
                            label={label}
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            InputProps={{
                                readOnly: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isHovered && (
                                            <IconButton
                                                size="small"
                                                onClick={() => {
                                                    onChange(null);
                                                    handleCloseParent();
                                                }}
                                            >
                                                <ClearIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                        <IconButton size="small">
                                            <ArrowDropDownIcon />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            InputLabelProps={{ shrink: true }}
                        />
                        <Menu anchorEl={anchorEl} open={parentOpen} onClose={handleCloseParent} PaperProps={menuPaperProps}>
                            <TextField
                                fullWidth
                                id={'search_state'}
                                placeholder={'Search State...'}
                                variant="standard"
                                onChange={(e) => setSearchState(e.target.value)}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment sx={{ mx: '8px' }} position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {Object.entries(options)
                                .filter(([stateAbbr]) => stateAbbr.toLowerCase().includes(searchState.toLowerCase()))
                                .sort(([a], [b]) => a.localeCompare(b))
                                .map(([stateAbbr, counties]) => (
                                    <Box>
                                        <MenuItem key={stateAbbr} onClick={(event) => handleStateClick(event, stateAbbr)}>
                                            {stateAbbr}
                                            <ListItemIcon style={{ minWidth: 0, marginLeft: 'auto' }}>
                                                <ArrowForwardIosIcon fontSize="small" />
                                            </ListItemIcon>
                                        </MenuItem>
                                        {selectedState === stateAbbr && (
                                            <Menu anchorEl={anchorEl} open PaperProps={menuPaperProps} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
                                                <TextField
                                                    fullWidth
                                                    id={'search_county'}
                                                    placeholder={'Search County...'}
                                                    variant="standard"
                                                    onChange={(e) => setSearchCounty(e.target.value)}
                                                    onClick={(e) => e.stopPropagation()}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment sx={{ mx: '8px' }} position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                                {counties
                                                    .filter((county) => county.name.toLowerCase().includes(searchCounty.toLowerCase()))
                                                    .sort((a, b) => a.name.localeCompare(b.name))
                                                    .map((county) => (
                                                        <MenuItem
                                                            key={county.fips}
                                                            onClick={() => {
                                                                onChange(county);
                                                                handleCloseParent();
                                                            }}
                                                        >
                                                            {county.id}
                                                        </MenuItem>
                                                    ))}
                                            </Menu>
                                        )}
                                    </Box>
                                ))}
                        </Menu>
                    </Box>
                );
            }}
        />
    );
};

export default ProducerFormCountySelect;
