/* eslint-disable no-param-reassign */
import React from 'react';
import ActionAddIcon from '../../icons/ActionAddIcon';
import ActionChangeIcon from '../../icons/ActionChangeIcon';
import ActionRemoveIcon from '../../icons/ActionRemoveIcon';
import EmptyIcon from '../../icons/EmptyIcon';

const formatIndex = (index, formatted, prevValue, currValue, icon = null) => {
    formatted[0].push(prevValue);
    formatted._0.push(currValue);
    formatted.changes[index] = icon;
};

const evaluateByLongest = (data, delta, formatted, prev, curr) => {
    data.forEach((e, index) => {
        if (delta[index] && delta[`_${index}`]) {
            // Change made at index
            formatIndex(index, formatted, prev[index], curr[index], <ActionChangeIcon />);
        } else if (delta[index]) {
            // Data added at index
            formatIndex(index, formatted, null, curr[index], <ActionAddIcon />);
        } else if (delta[`_${index}`]) {
            // Data removed at index
            formatIndex(index, formatted, prev[index], curr.splice(index, 0, null), <ActionRemoveIcon />);
        } else {
            // No change made at index
            formatIndex(index, formatted, prev[index], curr[index], <EmptyIcon />);
        }
    });
};

const FormatWithDeltas = (data, delta) => {
    // 0 represents previous, _0 represents current, changes is the type of change at each index (add, remove, change, none)
    const formatted = { 0: [], _0: [], changes: [] };

    const prev = [...data?.[0]];
    const curr = [...data?._0];

    const longerPrev = prev?.length > curr?.length;

    if (longerPrev) {
        evaluateByLongest(data[0], delta, formatted, prev, curr);
        return { formatted, length: prev.length };
    }

    evaluateByLongest(data._0, delta, formatted, prev, curr);
    return { formatted, length: curr.length };
};

export default FormatWithDeltas;
