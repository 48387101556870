import React from 'react';
import Typography from '@material-ui/core/Typography';

const puns = [
    'Great things coming moomentarily...',
    'We know you cownot wait...',
    'Cow-llecting your data...',
    "It won't take an udderly long time...",
    "You won't be waiting dairy long...",
    'The cows are coming home...',
    'Moooving things around...',
    'Skimming through our records...',
    'Emptying your data silo...',
    'Pasteurizing your info...',
    'Don’t Moove you’re almost there...',
    'Wheyt just a minute...',
    'Loading legen-dairy content...',
    "You won't be waiting until the cows come home...",
    "Hope you're in a good moood...",
    'Grazing our fields...',
    'See any good mooovies?',
    'Performing cow-culations...',
    'Where do cows eat lunch? \n The calf-etiria!',
    'Why did the cow jump over the moon? \n To get to the Milky Way!',
    'Getting there is hoof the fun...',
    'That silly cow will never learn, things go in one ear and out the udder.',
    'All cows love to celebrate on Moo Years Day...',
];

const Pun = ({ title, subtitle }) => {
    // eslint-disable-next-line global-require
    const uuidv4 = require('uuid/v4');
    const pun = title ?? 'Loading ...';
    // puns[Math.floor(Math.random() * puns.length)];

    return pun.split('\n').map((item, index) => (
        <div key={uuidv4()} className="flex flex-col justify-center align-middle">
            <Typography key={item.toString()} variant="h5" align="center" className="text-white" style={{ fontStyle: 'italic' }}>
                {item}
            </Typography>
            {subtitle?.trim()?.length > 0 && (
                <Typography variant="h6" align="center" className="text-grey" style={{ fontStyle: 'italic' }}>
                    {subtitle}
                </Typography>
            )}
        </div>
    ));
};

export default Pun;
