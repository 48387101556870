import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { connect } from 'react-redux';
import * as FuseActions from 'app/store/actions';
import { bindActionCreators } from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { getAPIHost } from '../../../utils';

const styles = {
    dialogPaper: {
        minWidth: '70vw',
        maxWidth: '70vw',
        minHeight: '90vh',
        maxHeight: '90vh',
    },
};

class MonthlyReportDialog extends React.Component {
    state = {
        url: '',
        model: {},
        generatedName: '',
        leaseId: null,
        open: false,
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { url, model, generatedName, leaseId } = this.props;

        if (!!url && prevProps.url !== url) {
            this.setState({ url });
        }
        if (!!model && prevProps.model !== model) {
            this.setState({ model });
        }
        if (!!generatedName && prevProps.generatedName !== generatedName) {
            this.setState({ generatedName });
        }
        if (!!leaseId && prevProps.leaseId !== leaseId) {
            this.setState({ leaseId });
        }
    }

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleSubmit = (model, url, generatedName, leaseId, { showMessage, history }) => {
        axios
            .put(`${getAPIHost()}/upload-lease/submit`, {
                leaseId,
                lease_type: model.lease_type,
                amount: model.leased_amount,
                leasedTo_id: model.leasee_id,
                leasedBy_id: model.leaser_id,
                duration: model.duration_in_months,
                file_address: generatedName,
                model_info: JSON.stringify(model),
            })
            .then((response) => {
                showMessage({ message: 'Your agreement has been sent in for approval.' });
                this.handleClose();
                history.push({ pathname: '/quota/lease' });
            })
            .catch((err) => {
                showMessage({ message: 'Your agreement could not be fulfilled at this time. You may be trying to lease out more quota than you have to give.' });
            });
    };

    render() {
        const { classes, canSubmit } = this.props;
        const { open, url, model, generatedName, leaseId } = this.state;

        return (
            <div>
                <Button type="submit" variant="contained" color="primary" aria-label="REVIEW AGREEMENT" disabled={!canSubmit} onClick={this.handleClickOpen}>
                    Review Agreement
                </Button>

                <Dialog classes={{ paper: classes.dialogPaper }} open={open} onClose={this.handleClose} fullWidth maxWidth="sm" aria-labelledby="dialog-title">
                    <DialogTitle id="dialog-title">Agreement</DialogTitle>
                    <DialogContent id="dialog-content">
                        <iframe
                            className="iframe"
                            name="pdf"
                            id="reportLoader"
                            title="pdf"
                            src={`https://drive.google.com/viewerng/viewer?url=${url}?pid=explorer&efh=false&a=v&chrome=false&embedded=true`}
                            style={{
                                minWidth: '100%',
                                maxWidth: '100%',
                                minHeight: '70vh',
                                maxHeight: '70vh',
                                border: 'none',
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleSubmit(model, url, generatedName, leaseId, { ...this.props, ...this.state })} variant="contained" color="secondary">
                            Submit
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: FuseActions.showMessage }, dispatch);
}

export default withRouter(withStyles(styles)(connect(null, mapDispatchToProps)(MonthlyReportDialog)));
