import React, { useEffect, useState } from 'react';
import { Button, Card } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment-timezone';

import MemoizedDocumentHistoryItem from './DocumentHistoryItem';
import DocumentHistoryCollapseEventHeader from './DocumentHistoryCollapseEventHeader';

const useStyles = makeStyles({
    root: {
        borderRadius: '10px',
        margin: '15px 15px',
        padding: '15px 0px',
        border: '1px solid',
    },
    header: {
        margin: '8px 0px 8px 30px',
    },
    content: {
        backgroundColor: '#FAFAFA',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        borderTopColor: 'rgb(0, 0, 0, 0.12)',
        paddingTop: '20px',
        paddingBottom: '20px',
    },
    card: {
        marginBottom: '10px',
    },
});

const DocumentHistoryCollapse = ({ index, item }) => {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);
    const hideData = ['lock', 'unlock', 'delete', 'restore'].includes(item.event);

    const handleSetVisible = () => {
        setVisible(!visible);
    };

    useEffect(() => {
        setVisible(item.collapse);
    }, [item]);

    if (!item.show) {
        return null;
    }

    return (
        <Card className={classes.card} key={index} variant="outlined">
            <Box className={classes.header} display="flex" alignItems="center" justifyContent="space-between">
                <DocumentHistoryCollapseEventHeader event={item.event} user={item.user} count={Object.keys(item.content).length} />
                <Box display="flex">
                    <Typography style={{ marginTop: 'auto', marginBottom: 'auto' }} variant="p">
                        {moment(item.created_at).format('MMM DD, YYYY - hh:mm A')}
                    </Typography>
                    <Button disabled={hideData} onClick={handleSetVisible}>
                        {visible ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </Button>
                </Box>
            </Box>
            {!hideData && (
                <Collapse in={visible}>
                    <Box className={classes.content}>
                        {Object.keys(item.content).map((key, i) => {
                            return <MemoizedDocumentHistoryItem key={`DocumentHistoryItem${i + 1}`} name={key} data={item.content[key]} delta={item.deltas[key]} event={item.event} index={i} />;
                        })}
                    </Box>
                </Collapse>
            )}
        </Card>
    );
};

export default DocumentHistoryCollapse;
