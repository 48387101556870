import React, { useState, useCallback, useEffect } from 'react';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import MapArtist from '../shapes/MapArtist';
import { Container } from '@mui/material';
import getZoomFromRadius from './utils';

const defaultMapStyle = {
    width: '100%',
    height: '100vh%',
};

const defaultOptions = {
    hide: [
        {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }],
        },
    ],
};

const apiConfig = {
    id: 'script-loader',
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAPS_KEY}&loading=async`,
    language: 'en',
    region: 'us',
    libraries: ['drawing', 'places'],
};

/**
 * Map component is the component that contains the Google Map instance
 *

 * @param {Object} props
 * @param {Object[]} props.data array of shapes
 * @param {Object} props.center {lat: number, lng: number}
 * @param {function} props.onClick callback function for map click event
 * @param {Object} props.options map options
 * @param {string} props.mapTypeId map type
 * @param {function} props.handleEditCircleFromMap callback function for circle change events
 * @param {string} props.selectedShape selected shape id
 * @param {string} props.height - size of the map container
 * @param {Object} props.conditionalControls conditional controls
 * @returns Map component
 */
const Map = ({
    data,
    center,
    onClick,
    options = defaultOptions,
    mapTypeId,
    handleEditCircleFromMap = () => {},
    selectedShape,
    conditionalControls,
    mapStyle = defaultMapStyle,
    producerIds,
    processorIds,
    zoom = 12,
    height,
    calculateZoomBasedOnPrimaryGeofence = false,
    handleViewChange = () => {},
    handleMarkerClick,
    polylineStroke,
    children,
    // Force Line Wrap Comment
}) => {
    const [centerPoint, setCenterPoint] = useState(center);
    const [zoomAdjusted, setZoomAdjusted] = useState(zoom);
    /**
     * Manage map instance to handle drag events
     */
    const [mapInstance, setMapInstance] = useState(null);

    /**
     * Manage shape instances to prevent re-rendering issues/crashes
     */
    const [circleInstances, setCircleInstances] = useState({});
    const [mapMarkerInstance, setMapMarkerInstances] = useState({});
    const [polylineInstances, setPolylineInstance] = useState({});
    const [markerInstances, setMarkerInstances] = useState({});

    useEffect(() => {
        if (mapInstance) {
            mapInstance.panTo(center);
            mapInstance.setZoom(zoomAdjusted);
        }
    }, [center]);

    useEffect(() => {
        if (mapInstance) {
            setZoomAdjusted(zoom);
        }
    }, [zoom]);

    const handleCircleLoad = useCallback((circle, shape) => {
        setCircleInstances((prevInstances) => {
            return { ...prevInstances, [shape._id]: circle };
        });
    });

    const handleMapMarkerLoad = useCallback((marker, shape) => {
        setMapMarkerInstances((prevInstances) => {
            return { ...prevInstances, [shape._id]: marker };
        });
    });

    const handlePolylineLoad = useCallback((polyline, shape) => {
        setPolylineInstance((prevInstances) => {
            return { ...prevInstances, [shape._id]: polyline };
        });
    });

    const handleMarkerLoad = useCallback((marker, shape) => {
        setMarkerInstances((prevInstances) => {
            return { ...prevInstances, [shape.id]: marker };
        });
    });

    const handleDragEnd = () => {
        if (mapInstance) {
            const newCenter = {
                lat: mapInstance.getCenter().lat(),
                lng: mapInstance.getCenter().lng(),
            };
            setCenterPoint(newCenter);
            handleViewChange(mapInstance);
        }
    };

    const handleZoomChanged = () => {
        if (mapInstance) {
            handleViewChange(mapInstance);
        }
    };

    const onMapLoad = useCallback((map) => {
        setMapInstance(map);
        if (calculateZoomBasedOnPrimaryGeofence) {
            const shape = data[0];
            if (!shape) return;
            const { radius_meters } = shape;
            setZoomAdjusted(getZoomFromRadius(radius_meters));
        }
    });

    const { isLoaded } = useJsApiLoader(apiConfig);

    if (!isLoaded) return null;

    return (
        <GoogleMap
            mapContainerStyle={height ? { ...mapStyle, height } : mapStyle}
            center={centerPoint} // center should pull from the region location or Primary geofence
            zoom={zoomAdjusted}
            clickableIcons={false}
            onClick={onClick} // change cursor based on clickFunction
            mapTypeId={mapTypeId} // roadmap, satellite, hybrid, terrain
            onDragEnd={handleDragEnd}
            onZoomChanged={handleZoomChanged}
            onLoad={onMapLoad}
        >
            <Container style={{ position: 'absolute' }}>
                {selectedShape && conditionalControls ? conditionalControls : null}
                {/* Force Line Wrap Comment */}
            </Container>
            <MapArtist
                shapes={data}
                selectedShape={selectedShape}
                handleEditCircleFromMap={handleEditCircleFromMap}
                handleCircleLoad={handleCircleLoad}
                handleMarkerLoad={handleMarkerLoad}
                handleMarkerClick={handleMarkerClick}
                circleInstances={circleInstances}
                handlePolylineLoad={handlePolylineLoad}
                handleMapMarkerLoad={handleMapMarkerLoad}
                producerIds={producerIds}
                processorIds={processorIds}
                markerInstances={markerInstances}
                polylineStroke={polylineStroke}
                // Force Line Wrap Comment
            />
            {children}
        </GoogleMap>
    );
};

export default Map;
