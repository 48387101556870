import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Divider, InputAdornment } from '@material-ui/core';
import Formsy from 'formsy-react';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles/index';
import * as FuseActions from 'app/store/actions';
import axios from 'axios/index';
import { InitialLeaseDialog } from 'app/custom-widgets/dialogs';
import moment from 'moment-timezone';
import MMSplashScreen from '../MMSplashScreen';
import { SelectSearchFormsy } from '../../../@fuse/components/formsy';
import { TextFieldFormsy } from '@fuse';
import { getAPIHost } from '../../../utils';

const styles = (theme) => ({ root: { display: 'flex' }, formControl: { margin: theme.spacing(3) } });

class LeaseForm extends Component {
    state = {
        dateToday: moment().format('YYYY-MM-DD'),
        leaseStartYear: moment().format('YYYY'),
        leaseStartMonth: parseInt(moment().format('DD')) < 16 ? moment().format('M') : (parseInt(moment().format('M')) + 1).toString(),
        leaseMonthDuration: 1,
        generatedName: '',
        initialURL: '',
        leaseId: null,
        model: {},
        canSubmit: false,
    };

    disableButton = () => {
        this.setState({ canSubmit: false });
    };

    enableButton = () => {
        this.setState({ canSubmit: true });
    };

    onChangeDuration = (event) => {
        this.setState({ leaseMonthDuration: parseInt(event.target.value) });
    };

    changeStartMonth = (event) => {
        this.setState({ leaseStartMonth: event.target.value });
    };

    changeStartYear = (event) => {
        this.setState({ leaseStartYear: event.target.value });
    };

    fillDurationSelector = () => {
        const returnValue = [];

        for (let i = 1; i <= 24; i++) {
            returnValue.push({ value: i.toString(), name: i.toString() });
        }

        return returnValue;
    };

    fillStartYearSelector = (startYear, numYears) => {
        const returnValue = [];

        for (let i = 0; i < numYears; i++) {
            returnValue.push({ value: i.toString(), name: i.toString() });
        }

        return returnValue;
    };

    fillStartMonthSelector = (selectedYear) => {
        const shouldIncludeThisMonth = parseInt(moment().format('DD')) < 16;
        const thisMonth = parseInt(moment().format('MM'));

        const returnValue = [];

        for (let i = 1; i <= 12; i++) {
            if (!(selectedYear === moment().format('YYYY') && (i < thisMonth || (i === thisMonth && !shouldIncludeThisMonth)))) {
                returnValue.push({ value: i.toString(), name: moment(`01-${i}-2019`, 'DD-MM-YYYY').format('MMMM') });
            }
        }

        return returnValue;
    };

    getLastDay = (month, year) => {
        let days;
        let leapYear;

        switch (month) {
            case '2':
                leapYear = year % 100 === 0 ? year % 400 === 0 : year % 4 === 0;

                days = leapYear ? '29' : '28';
                break;

            case '1':
            case '3':
            case '5':
            case '7':
            case '8':
            case '10':
            case '12':
                days = '31';
                break;

            default:
                days = '30';
                break;
        }

        return days;
    };

    getLeaserInfo = () => {
        const { producerDetails, producerName } = this.props;

        return producerDetails.filter((value) => value.name === producerName)[0];
    };

    getLeaseeInfo = (producerID) => {
        const { producerDetails } = this.props;

        return producerDetails.filter((value) => value.id === producerID)[0];
    };

    onSubmit = (model) => {
        const submitModel = { ...model };

        submitModel.lease_day = moment().format('Do');
        submitModel.lease_month = moment().format('MMMM');
        submitModel.lease_year = moment().format('YY');

        const leaserInfo = this.getLeaserInfo();
        const leaseeInfo = this.getLeaseeInfo(submitModel.leasee_id);

        submitModel.leaser_id = leaserInfo.id;
        submitModel.leaser_name = leaserInfo.full_name || '____________________';
        submitModel.leaser_farm_name = leaserInfo.name;
        submitModel.leaser_licence_number = leaserInfo.CMTNumber;

        submitModel.leaser_quota = submitModel.lease_type === 'daily' ? leaserInfo.daily_fluid_milk_quota : leaserInfo.industrial_MSQ;

        submitModel.leasee_name = leaseeInfo.full_name || '____________________';
        submitModel.leasee_farm_name = leaseeInfo.name;
        submitModel.leasee_licence_number = leaseeInfo.CMTNumber;

        submitModel.lease_start_day = '1st';
        submitModel.lease_start_month = moment(`${submitModel.lease_start_month}-10-2019`).format('MMMM');
        submitModel.lease_start_year = submitModel.lease_start_year.slice(-2);

        submitModel.lease_end_day = moment(`01-${this.getLastDay(submitModel.lease_end_month)}-2019`).format('Do');
        submitModel.lease_end_month = moment(submitModel.lease_end_month).format('MMMM');
        submitModel.lease_end_year = submitModel.lease_end_year.slice(-2);

        submitModel.leaser_signature = submitModel.leaser_farm_name;
        submitModel.leasee_signature = submitModel.leasee_farm_name;

        axios
            .put(`${getAPIHost()}/upload-lease/initial`, {
                ...submitModel,
                type: 'Initial',
                oldAddress: false,
            })
            .then((response) => {
                this.setState({
                    initialURL: `${process.env.REACT_APP_BUCKET_LOCATION}/Leases/${response.data.generatedName}`,
                    generatedName: response.data.generatedName,
                    leaseId: response.data.leaseId,
                    model: submitModel,
                });
            })
            .catch(() => {
                // console.warn('ERROR: ', err);
            });
    };

    render() {
        const { canSubmit, dateToday, leaseStartYear, leaseStartMonth, leaseMonthDuration, initialURL, model, generatedName, leaseId } = this.state;
        const { producers, region } = this.props;

        if (!producers) {
            return <MMSplashScreen />;
        }

        return (
            <div className="min-w-3/4 max-w-3/4">
                <Divider />

                <Formsy
                    onValidSubmit={this.onSubmit}
                    onValid={this.enableButton}
                    onInvalid={this.disableButton}
                    /* eslint-disable-next-line no-return-assign */
                    ref={(form) => (this.form = form)}
                    className="flex flex-col justify-center"
                >
                    <SelectSearchFormsy
                        className="my-16"
                        name="lease_type"
                        label="Lease Type"
                        variant="standard"
                        options={[
                            { value: 'daily', name: region === 'NL' ? 'Daily Fluid Milk Quota' : 'Quota' },
                            { value: 'market', name: 'Industrial Market Share Quota' },
                        ]}
                        value=" "
                        validations="minLength:2"
                        validationError="Please select a Lease Type"
                    />

                    <TextFieldFormsy
                        className="my-16"
                        type="text"
                        name="lease_agreement_location"
                        placeholder="Agreement Location"
                        label="Agreement Location"
                        validations={{ minLength: 2 }}
                        validationErrors={{ minLength: 'Min character length is 2' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        my_location
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        required
                        variant="outlined"
                    />

                    <SelectSearchFormsy className="my-16" name="leasee_id" label="Producer (Leasee)" value=" " options={[{ value: ' ', name: 'None' }, ...producers.map((entry) => ({ value: entry.value, name: entry.name }))]} variant="standard" validations="minLength:2" validationError="Please select a Leasee" onChange={this.onChangeProducer} />

                    <TextFieldFormsy
                        className="my-16"
                        type="text"
                        name="leased_amount"
                        placeholder="Lease Amount"
                        label="Lease Amount"
                        validations={{ isNumeric: true, isInt: true }}
                        validationErrors={{ isNumeric: 'You must enter a whole number amount to lease', isInt: 'You must enter a whole number amount to lease' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <i className="text-20 material-icons" color="action">
                                        account_balance
                                    </i>
                                </InputAdornment>
                            ),
                        }}
                        required
                        variant="outlined"
                    />

                    <div className="flex flex-row flex-auto">
                        <SelectSearchFormsy className="pr-32" name="lease_start_year" label="Start Year" options={this.fillStartYearSelector(parseInt(moment(dateToday).format('YYYY')), 5)} variant="standard" value="2019" required onChange={this.changeStartYear} />

                        <SelectSearchFormsy className="pr-32" name="lease_start_month" label="Start Month" options={this.fillStartMonthSelector(leaseStartYear)} value={leaseStartMonth} variant="standard" validations="isNumeric" validationError="Please select a start month for the lease" required onChange={this.changeStartMonth} />
                    </div>

                    <SelectSearchFormsy className="my-16" name="duration_in_months" label="Duration in Months" variant="standard" options={this.fillDurationSelector()} value="1" required onChange={this.onChangeDuration} />

                    <div className="flex flex-row flex-auto">
                        <SelectSearchFormsy
                            className="pr-32"
                            name="lease_end_year"
                            label="End Year"
                            variant="standard"
                            options={this.fillStartYearSelector(parseInt(moment(dateToday).format('YYYY')), 7)}
                            value={parseInt(leaseStartMonth) + leaseMonthDuration - 1 > 12 ? (parseInt(leaseStartYear) + Math.floor((parseInt(leaseStartMonth) + leaseMonthDuration - 1) / 12)).toString() : leaseStartYear.toString()}
                            disabled
                            required
                            onChange={this.changeStartYear}
                        />

                        <SelectSearchFormsy
                            className="pr-32"
                            name="lease_end_month"
                            label="End Month"
                            variant="standard"
                            options={this.fillStartMonthSelector(leaseStartYear)}
                            value={parseInt(leaseStartMonth) + leaseMonthDuration - 1 > 12 ? ((parseInt(leaseStartMonth) + leaseMonthDuration - 1) % 12).toString() : (parseInt(leaseStartMonth) + leaseMonthDuration - 1).toString()}
                            disabled
                            required
                        />
                    </div>

                    <div className="mx-auto my-16">
                        <InitialLeaseDialog canSubmit={canSubmit} url={initialURL} model={model} generatedName={generatedName} leaseId={leaseId} />
                    </div>
                </Formsy>
            </div>
        );
    }
}

function mapStateToProps({ mainReducer, persisted }) {
    return {
        producerDetails: mainReducer.producerListDetails.data.producers,
        producerName: persisted.auth.user.data.name,
        region: persisted.auth.user.data.region,
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ showMessage: FuseActions.showMessage }, dispatch);
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LeaseForm)));
