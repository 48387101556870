import axios from 'axios';
import { getAssignmentQuery } from './queries';

const getAssignmentListDetails = async (month, year, filters) => {
    try {
        const assignmentResponse = await axios.get(getAssignmentQuery({ month, year, filters }));
        const assignments = assignmentResponse.data;
        return { assignments };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getAssignmentListDetails;
