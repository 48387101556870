import Divider from '@material-ui/core/Divider';
import Icon from '@material-ui/core/Icon';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import moment from 'moment-timezone';
import { numberFormat } from '../../utils';

function getWeatherIcon(icon) {
    switch (icon) {
        case 'clear-day':
            return 'sun';
        case 'clear-night':
            return 'moon';
        case 'rain':
            return 'rainy2';
        case 'snow':
            return 'snowy3';
        case 'sleet':
            return 'weather4';
        case 'wind':
            return 'windy2';
        case 'fog':
            return 'cloudy2';
        case 'cloudy':
            return 'cloud2';
        case 'partly-cloudy-day':
            return 'cloudy';
        case 'partly-cloudy-night':
            return 'cloud4';
        default:
            return 'none';
    }
}

/**
 * @deprecated Dark Sky, which powers this widget, is no longer in service. This widget will soon be replaced with a modern alternative. Please do not use.
 */
function WidgetWeather({ locationInfo, tempUnit, speedUnit, region }) {
    return (
        <Card className="w-full rounded-8 shadow-none border-1 h-full">
            <div className="flex items-center justify-between px-16 py-16">
                <Typography className="text-20 font-bold">Local Weather</Typography>
            </div>
            <div className="flex items-center justify-between">
                <Typography className="h3 px-16">{moment().format('MMMM Do, YYYY')}</Typography>
            </div>
            <div className="flex items-center justify-center p-8 py-16">
                <Icon className="meteocons text-40 ltr:mr-8 rtl:ml-8" color="action">
                    {getWeatherIcon(locationInfo.icon)}
                </Icon>
                <Typography className="text-44 mx-8 ml-8" color="textSecondary">
                    {['BORDEN', 'UDA', 'CDI', 'LEGACY', 'PRAIRIE'].includes(region) ? numberFormat(locationInfo.temperature * (9 / 5) + 32, 0) : numberFormat(locationInfo.temperature, 0)}
                </Typography>
                <Typography className="text-48 font-300" color="textSecondary">
                    °
                </Typography>
                <Typography className="text-44 font-300" color="textSecondary">
                    {tempUnit}
                </Typography>
            </div>
            <div className="flex items-center justify-center p-8">
                <Typography className="text-22 mx-8" color="textSecondary">
                    Feels Like:
                </Typography>
                <Typography className="text-22" color="textSecondary">
                    {['BORDEN', 'UDA', 'CDI', 'LEGACY', 'PRAIRIE'].includes(region) ? numberFormat(locationInfo.apparentTemperature * (9 / 5) + 32, 0) : numberFormat(locationInfo.apparentTemperature, 0)}
                </Typography>
                <Typography className="text-24 font-300" color="textSecondary">
                    °
                </Typography>
                <Typography className="text-22 font-300" color="textSecondary">
                    {tempUnit}
                </Typography>
            </div>
            <Divider />
            <div className="flex justify-between items-center p-16 py-24">
                <div className="flex items-center">
                    <Icon className="meteocons text-14" color="action">
                        windy
                    </Icon>
                    <Typography className="mx-4">{['BORDEN', 'UDA', 'CDI', 'LEGACY', 'PRAIRIE'].includes(region) ? numberFormat(locationInfo.windSpeed * 1.62, 0) : numberFormat(locationInfo.windSpeed, 0)}</Typography>
                    <Typography color="textSecondary">{speedUnit}</Typography>
                </div>

                <div className="flex items-center">
                    <Icon className="meteocons text-14" color="action">
                        compass
                    </Icon>
                    <Typography className="mx-4">{locationInfo.windDirection}</Typography>
                </div>

                <div className="flex items-center">
                    <Icon className="meteocons text-14" color="action">
                        rainy
                    </Icon>
                    <Typography className="mx-4">{`${(locationInfo.precipProbability * 100).toFixed(0)}%`}</Typography>
                </div>
            </div>
            <Divider />
            <div className="w-full py-24">
                <Typography className="text-12 text-center justify-center w-full py-3 pb-8">Daily Forecast </Typography>
                {locationInfo.forecast.map((day) => (
                    <div className="flex items-center justify-between w-full py-12 px-24" key={day.time}>
                        <Typography className="text-15">{moment.unix(day.time).utc().format('dddd')}</Typography>
                        <div className="flex items-center">
                            <Icon className="meteocons text-24 mr-16 rtl:ml-16" color="action">
                                {getWeatherIcon(day.icon)}
                            </Icon>
                            <Typography className="text-20">{['BORDEN', 'UDA', 'CDI', 'LEGACY', 'PRAIRIE'].includes(region) ? numberFormat(day.temperatureHigh * (9 / 5) + 32, 0) : numberFormat(day.temperatureHigh, 0)}</Typography>
                            <Typography className="text-20">&nbsp;/&nbsp;</Typography>
                            <Typography className="text-20">{['BORDEN', 'UDA', 'CDI', 'LEGACY', 'PRAIRIE'].includes(region) ? numberFormat(day.temperatureLow * (9 / 5) + 32, 0) : numberFormat(day.temperatureLow, 0)}</Typography>
                            <Typography className="text-20" color="textSecondary">
                                &deg;
                            </Typography>
                            <Typography className="text-20" color="textSecondary">
                                {tempUnit}
                            </Typography>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex items-center justify-center pt-8" style={{ paddingBottom: 10 }}>
                <a href="https://darksky.net/poweredby/">
                    <img width="120" src="assets/MMAssets/icons/poweredby-oneline-grey.png" alt="logo" />
                </a>
            </div>
        </Card>
    );
}

export default React.memo(WidgetWeather);
