import axios from 'axios';
import { resolveId } from '../../utils';
import { getUserGroupQuery, getGroupedUsers } from './queries';

const getUserGroups = async () => {
    try {
        const groupedUserQuery = getGroupedUsers();

        const populate = { users: ['role', 'name', 'deleted_at'] };
        const userGroupQuery = getUserGroupQuery({ populate });

        const groupedUserRequest = axios.get(groupedUserQuery);
        const userGroupsRequest = axios.get(userGroupQuery);

        const [groupedUserResponse, userGroupResponse] = await Promise.all([groupedUserRequest, userGroupsRequest]);

        const groupedUsers = { ...groupedUserResponse.data[0] };
        const roles = Object.keys(groupedUserResponse.data[0]);
        roles.forEach((role) => {
            groupedUsers[role] = groupedUsers[role].map((user) => resolveId(user)).sort((a, b) => a.name.localeCompare(b.name));
        });

        // Change all _id keys to id
        // Need to change userGroup and each user in the group
        const userGroups = userGroupResponse.data.map((userGroup) => {
            let resolvedIdGroup = { ...userGroup };
            resolvedIdGroup = resolveId(resolvedIdGroup);
            resolvedIdGroup.users = userGroup.users?.map((user) => resolveId(user));
            return resolvedIdGroup;
        });

        return {
            groupedUsers,
            userGroups,
        };
    } catch (errors) {
        // eslint-disable-next-line no-console
        console.log(errors);
        throw new Error('Unable to process request.');
    }
};

export default getUserGroups;
