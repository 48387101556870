import axios from 'axios';
import Split from '../../Split';
import { editLabReportQuery } from './queries';
import { isLabAlreadyExistsMsg, isPickupLabAlreadyExistsMsg, getUserRegion } from '../../utils';
import { buildExpressQuery } from './queries/builders';

// Revised editLabReport function utilizing the updateLabFieldsBasedOnComponentOutlier function
const editLabReport = (model, key) =>
    new Promise((resolve, reject) => {
        const useExpressEndpoint = Split.getBooleanTreatment('use_express_lab_endpoint');
        const query = editLabReportQuery({ key, model, ...(useExpressEndpoint && { queryBuilder: buildExpressQuery }) });
        // Get user region to pass into the updateLabFieldsBasedOnComponentOutlier function
        const region = getUserRegion();

        let requestData; // Variable to store the response from the first PATCH request

        const request = axios.patch(query.url, { ...query.body, ...model });

        request
            .then((response) => {
                requestData = response.data; // Store the response data
            })
            .then(() => {
                resolve({ data: requestData }); // Resolve with the response data from the first request
            })
            .catch((error) => {
                // eslint-disable-next-line no-console
                console.error(error);
                if (error.response && error.response.status === 405) {
                    const { data } = error.response;
                    if (isPickupLabAlreadyExistsMsg(data.message) || isLabAlreadyExistsMsg(data.message)) {
                        reject(new Error(data.message));
                    }
                } else {
                    reject(new Error('Unable to edit lab report'));
                }
            });
    });

export default editLabReport;
