import axios from 'axios';
import moment from 'moment-timezone';
import { getEnvironment, getId, getUser } from '../../../../utils';
import { buildExpressQuery, buildLoopbackQuery } from '../../../repository/queries/builders';
import { buildFieldFilter } from '../../../repository/queries';
import { fetchAllPages, getPayCalculationsUrl, getPayProcessorsUrl, getPayUrl, getPricesServicesUrl, getStatementsUrl } from '../../../repository/prices/utils';

export const formatConstraints = ({ roleConstraints, user }) => {
    const where = {};
    const roleWithConstraint = roleConstraints[user.role];
    if (roleWithConstraint) {
        roleWithConstraint.forEach(({ constraint }) => {
            where[constraint] = getId();
        });
    }
    return where;
};

// TODO: Pass in `query` from config and use helper to build query from query object props
export const fetchOptions = async ({
    endpoint,
    where,
    fields,
    order,
    filterDeleted,
    useLoopback,
    roleConstraints,
    populate,
    user,
    interval,
    includeDeleted,
    ...rest
    // wrap
}) => {
    const params = { ...rest };
    if (!includeDeleted) {
        params.where = { deleted_at: { eq: null } };
    }
    if (where) {
        params.where = { ...params.where, ...where };
    }
    if (fields) {
        params.fields = buildFieldFilter(fields);
    }
    if (interval) {
        // eg: { created_at: [24, 'hours'] } => { created_at: { gte: moment().subtract(24, 'hours') } }
        const intervalWhere = Object.keys(interval).reduce((acc, key) => {
            const [amount, unit] = interval[key];
            const intervalStart = moment().subtract(amount, unit).toISOString();
            return { ...acc, [key]: { between: [intervalStart, moment().toISOString()] } };
        }, {});
        params.where = { ...params.where, ...intervalWhere };
    }
    if (populate) {
        params.populate = { ...params.populate, ...populate };
    }
    if (order) {
        params.order = order;
    }
    if (roleConstraints) {
        const constraints = formatConstraints({ roleConstraints, user });
        params.where = { ...params.where, ...constraints };
    }
    if (populate) {
        params.populate = populate;
    }
    if (filterDeleted === false) {
        delete params.where.deleted_at;
    }
    const query = useLoopback ? buildLoopbackQuery(endpoint, { params }) : buildExpressQuery(endpoint, { params });

    try {
        const options = await axios.get(query);
        return options.data;
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return { error };
    }
};

const endpointMap = {
    prices: getPricesServicesUrl,
    processorProfile: getPayProcessorsUrl,
    pay: getPayUrl,
    payCalculations: getPayCalculationsUrl,
    statements: getStatementsUrl,
};

const formatPathParams = (pathParams) => {
    let baseUrl = '';
    pathParams.forEach((param) => {
        if (typeof param === 'string') {
            baseUrl = `${baseUrl}/${param}`;
        } else if (param.type === 'string') {
            baseUrl = `${baseUrl}/${param.value}`;
        }
    });
    return baseUrl;
};

const formatQueryParams = (queryParams) => {
    let url = '?';
    queryParams.forEach((param, i) => {
        const andOrEmpty = i === 0 ? '' : '&';
        if (typeof param === 'string') {
            url = `${url}${andOrEmpty}${param}`;
        } else if (param.type === 'region') {
            const { region } = getUser();
            url = `${url}${andOrEmpty}${param.key}=${region}`;
        } else {
            url = `${url}${andOrEmpty}${param.key}=${param.value}`;
        }
        return param;
    });
    return url;
};

export const fetchOptionsMicroservice = async ({ endpoint, resourceKey, paginatedLoop, useHardcoded = true }) => {
    let url = '';
    // TODO: Solidify out base url handling
    if (useHardcoded) {
        const env = getEnvironment(window.location.hostname);
        if (endpoint.direct) {
            url = endpoint[env];
        } else {
            const getUrl = endpointMap[endpoint.microservice];

            if (!getUrl) {
                return { error: `No url found for microservice ${endpoint.microservice}` };
            }

            url += getUrl(env);
            if (endpoint.pathParams) url += formatPathParams(endpoint.pathParams);

            if (endpoint.queryParams) url += formatQueryParams(endpoint.queryParams);
        }
    } else {
        url = buildExpressQuery(endpoint, {});
    }
    try {
        const request = paginatedLoop ? () => fetchAllPages(url, resourceKey) : axios.get(url);
        const response = await request();
        return response;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        return { error: err };
    }
};
