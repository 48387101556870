import { AbilityBuilder, PureAbility } from '@casl/ability';
import { HELPFUL_LINKS_REGIONS } from '../../../../constants/bulkRegion';

function HelpfulLinkPermissions(user) {
    const { can, cannot, rules } = new AbilityBuilder(PureAbility);
    const {
        role,
        data: { region, id, processor_id },
    } = user;

    // Helpful Links is enabled for all newer regions post the feature release
    if (HELPFUL_LINKS_REGIONS.includes(region) && ['admin', 'producer'].includes(role)) {
        can('view', 'helpful_links');
    }

    return rules;
}

export default HelpfulLinkPermissions;
