const FieldLocalizer = (value) => {
    switch (value) {
        case 'BOL':
            return 'LT Number';
        case 'Volume':
            return 'Amount';
        case 'unlock':
            return 'Unlocked';
        case 'lock':
            return 'Locked';
        case 'delete':
            return 'Deleted';
        default:
            return value;
    }
};

export default FieldLocalizer;
