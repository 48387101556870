import React, { useEffect, useState } from 'react';
import { Circle, Polygon } from '@react-google-maps/api';
import listGeofences from '../../repository/geofences/listGeofences';
import { processorShapeOptions, producerShapeOptions } from './shapes/MapShapes.helpers';

const roleMap = {
    producer: producerShapeOptions,
    processor: processorShapeOptions,
};

const RadialGeofence = ({ shape }) => {
    const center = {
        lat: shape.center.coordinates[1],
        lng: shape.center.coordinates[0],
    };
    const options = roleMap[shape.user_id?.role] ?? producerShapeOptions;
    return <Circle key={shape._id} center={center} radius={shape.radius_meters} options={options} />;
};
const PolygonalGeofence = ({ shape }) => {
    const paths = shape.geometry.coordinates[0].map((point) => ({
        lat: point[1],
        lng: point[0],
    }));
    const options = roleMap[shape.user_id?.role] ?? producerShapeOptions;
    return <Polygon key={shape._id} paths={paths} options={{ ...options }} />;
};

const shapeMap = {
    radialGeofence: RadialGeofence,
    polygonalGeofence: PolygonalGeofence,
};

const useGeofences = ({ user_id, refetch }) => {
    const [loadingGeofences, setLoadingGeofences] = useState(false);
    const [errorGeofences, setErrorGeofences] = useState(null);
    const [geofences, setGeofences] = useState([]);
    const [shapes, setShapes] = useState([]);

    useEffect(() => {
        const getGeofences = async () => {
            setLoadingGeofences(true);
            const fences = await listGeofences({ ids: [user_id] });
            if (fences.error) {
                setGeofences([]);
                setErrorGeofences(fences.error);
            } else {
                setGeofences(fences);
                const shapesArray = fences.map((fence) => {
                    const Shape = shapeMap[fence.type];
                    const styles = roleMap[fence.user_id?.role];
                    return <Shape key={fence._id} shape={fence} />;
                });
                setShapes(shapesArray);
                setErrorGeofences(null);
            }
            setLoadingGeofences(false);
        };

        if (user_id) getGeofences();
    }, [user_id, refetch]);

    return { loadingGeofences, geofences, setGeofences, shapes, errorGeofences };
};

export default useGeofences;
