import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import MMSplashScreen from '../custom-components/MMSplashScreen';

function getTitle(value, date) {
    const snfUpperLimit = moment(date).isBefore('2022-08-01') ? '2.30' : '2.25';
    if (value === 'protein_prem_low_range') {
        return 'Protein (< 2.000)';
    }
    if (value === 'protein_market_mid_range') {
        return `Market Protein (2.000 - ${snfUpperLimit}00)`;
    }
    if (value === 'protein_market_top_range') {
        return `Protein (> ${snfUpperLimit}0)`;
    }
    if (value === 'other_solids_prem_low_range') {
        return 'Other Solids (< 2.000)';
    }
    if (value === 'other_solids_market_mid_range') {
        return `Market Other Solids (2.000 - ${snfUpperLimit}00)`;
    }
    if (value === 'other_solids_top_range') {
        return `Other Solids (> ${snfUpperLimit}0)`;
    }
    const title = value.replace(/_/g, ' ');
    const splitTitle = title.split(' ');
    for (let i = 0; i < splitTitle.length; i += 1) {
        splitTitle[i] = splitTitle[i].charAt(0).toUpperCase() + splitTitle[i].substring(1);
    }
    return splitTitle.join(' ');
}

function instance(title, data, date) {
    // eslint-disable-next-line global-require
    const uuidv4 = require('uuid/v4');
    const numPerLine = window.innerWidth < 611 ? 4 : window.innerWidth < 768 ? 5 : window.innerWidth < 894 ? 6 : 7;

    if (title === 'Production And SNF') {
        return (
            <div className="flex flex-col w-full items-center" style={{ padding: 10, minWidth: 530 }} key={uuidv4()}>
                <Paper className={window.innerWidth > 1462 ? 'flex flex-row w-5/6 border-3' : window.innerWidth > 638 ? 'flex flex-col w-5/6 border-3' : 'flex flex-col w-full border-3'}>
                    <div className={window.innerWidth > 1462 ? 'w-3/5' : 'w-full'} style={{ paddingRight: 10 }}>
                        <Typography variant="h5" style={{ padding: 10 }}>
                            Production
                        </Typography>
                        <Table aria-label="simple table" key={uuidv4()}>
                            <TableHead>
                                <TableRow key={uuidv4()}>
                                    <TableCell align="center" key={uuidv4()} />
                                    {Object.keys(data.production[Object.keys(data.production)[0]]).map((value) => (
                                        <TableCell align="center" key={uuidv4()}>
                                            {getTitle(value)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody key={uuidv4()}>
                                {Object.keys(data.production).map((value) => (
                                    <TableRow key={uuidv4()}>
                                        <TableCell align="left" key={uuidv4()}>
                                            {getTitle(value)}
                                        </TableCell>
                                        {Object.keys(data.production[value]).map((thing = value) => (
                                            <TableCell component="th" scope="row" key={uuidv4()} align="center">
                                                {data.production[value][thing]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                    <div className={window.innerWidth > 1462 ? 'w-2/5' : 'w-full'}>
                        <Typography variant="h5" style={{ padding: 10 }}>
                            Payable & Non-Payable SNF
                        </Typography>
                        <Table aria-label="simple table" key={uuidv4()}>
                            <TableHead key={uuidv4()}>
                                <TableRow key={uuidv4()}>
                                    <TableCell align="center" key={uuidv4()} />
                                    {Object.keys(data.payable_and_nonpayable_SNF[Object.keys(data.payable_and_nonpayable_SNF)[0]]).map((value) => (
                                        <TableCell align="center" key={uuidv4()}>
                                            {getTitle(value)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Object.keys(data.payable_and_nonpayable_SNF).map((value) => (
                                    <TableRow key={uuidv4()}>
                                        <TableCell align="center" key={uuidv4()}>
                                            {getTitle(value)}
                                        </TableCell>
                                        {Object.keys(data.payable_and_nonpayable_SNF[value]).map((thing = value) => (
                                            <TableCell component="th" scope="row" key={uuidv4()} align="center">
                                                {data.payable_and_nonpayable_SNF[value][thing]}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
            </div>
        );
    }
    if (title === 'Producer Quota Position' || title === 'Production Quota' || title === 'Flexibility') {
        return (
            <div className="flex flex-col w-full items-center" style={{ padding: 10, minWidth: 530 }} key={uuidv4()}>
                <Paper className={window.innerWidth > 638 ? 'w-5/6 border-3' : 'w-full border-3'}>
                    <Typography variant="h5" style={{ padding: 10 }}>
                        {title}
                    </Typography>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow key={uuidv4()}>
                                {Object.keys(data).map(
                                    (value, index) =>
                                        index < numPerLine && (
                                            <TableCell align="center" key={uuidv4()}>
                                                {getTitle(value)}
                                            </TableCell>
                                        )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow className="flex-row" key={uuidv4()}>
                                {Object.keys(data).map(
                                    (value, index) =>
                                        index < numPerLine && (
                                            <TableCell align="center" component="th" scope="row" key={uuidv4()}>
                                                {data[value]}
                                            </TableCell>
                                        )
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table className="border-t-4" aria-label="simple table">
                        <TableHead>
                            <TableRow key={uuidv4()}>
                                {Object.keys(data).map(
                                    (value, index) =>
                                        index >= numPerLine &&
                                        index < 2 * numPerLine && (
                                            <TableCell align="center" key={uuidv4()}>
                                                {getTitle(value)}
                                            </TableCell>
                                        )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow className="flex-row" key={uuidv4()}>
                                {Object.keys(data).map(
                                    (value, index) =>
                                        index >= numPerLine &&
                                        index < 2 * numPerLine && (
                                            <TableCell component="th" scope="row" key={uuidv4()} align="center">
                                                {data[value]}
                                            </TableCell>
                                        )
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                    <Table className="border-t-4" aria-label="simple table">
                        <TableHead>
                            <TableRow key={uuidv4()}>
                                {Object.keys(data).map(
                                    (value, index) =>
                                        index >= 2 * numPerLine && (
                                            <TableCell align="center" key={uuidv4()}>
                                                {getTitle(value)}
                                            </TableCell>
                                        )
                                )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow className="flex-row" key={uuidv4()}>
                                {Object.keys(data).map(
                                    (value, index) =>
                                        index >= 2 * numPerLine && (
                                            <TableCell component="th" scope="row" key={uuidv4()} align="center">
                                                {data[value]}
                                            </TableCell>
                                        )
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
    if (title === 'Component Tests' || title === 'In Quota Revenue' || title === 'Over Quota Revenue' || title === 'Snf Revenue Above Ratio' || title === 'Net Revenue' || title === 'Advances Paid By Plants' || title === 'Payout Adjustments' || title === 'Deductions') {
        return (
            <div className="flex flex-col w-full items-center" style={{ padding: 10, minWidth: 530 }} key={uuidv4()}>
                <Paper className={window.innerWidth > 638 ? 'w-5/6 border-3' : 'w-full border-3'}>
                    <Typography variant="h5" style={{ padding: 10 }}>
                        {title === 'Payout Adjustments' ? 'Net Payout' : title}
                    </Typography>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow key={uuidv4()}>
                                <TableCell align="center" key={uuidv4()} />
                                {Object.keys(data[Object.keys(data)[0]]).map((value) => (
                                    <TableCell align="right" position="fixed" key={uuidv4()}>
                                        {getTitle(value)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(data).map((value) => (
                                <TableRow key={uuidv4()}>
                                    <TableCell align="left" key={uuidv4()}>
                                        {getTitle(value, date)}
                                    </TableCell>
                                    {Object.keys(data[value]).map((thing = value) => (
                                        <TableCell component="th" scope="row" key={uuidv4()} align="right" position="fixed" width="15%">
                                            {data[value][thing]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
    return (
        <div className="flex flex-col w-full items-center" style={{ padding: 10, minWidth: 530 }} key={uuidv4()}>
            <Paper className={window.innerWidth > 638 ? 'flex flex-row w-5/6 border-3' : 'w-full border-3'}>
                <div className="flex w-full">
                    <Typography
                        variant="h6"
                        className="w-1/3"
                        style={{
                            textAlign: 'left',
                            float: 'left',
                            padding: 10,
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {title === 'Overpayment' ? 'Overpayment of Overquota Advances Deducted and Paid to the Board: ' : 'Net Adjustment payed by DFPEI - Date: '}
                    </Typography>
                    {typeof data === 'string' && (
                        <Typography
                            variant="h6"
                            className="w-full"
                            style={{
                                textAlign: 'right',
                                float: 'right',
                                padding: 10,
                            }}
                        >
                            {data}
                        </Typography>
                    )}
                    {typeof data !== 'string' &&
                        Object.keys(data).map((value) => (
                            <Typography
                                variant="h6"
                                className="w-full"
                                style={{
                                    textAlign: 'right',
                                    float: 'right',
                                    padding: 10,
                                }}
                            >
                                {data[value]}
                            </Typography>
                        ))}
                </div>
            </Paper>
        </div>
    );
}

export default function SimpleTable(data) {
    if (!data.data) {
        return <MMSplashScreen />;
    }

    return (
        <div className="w-full" style={{ padding: 10 }}>
            {data.producerName && (
                <div className="w-full flex flex-row justify-center text-center items-center" style={{ padding: 10 }}>
                    <Typography
                        variant="h3"
                        className="w-full"
                        style={{
                            textAlign: 'center',
                            float: 'center',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {`${data.producerName} - ${moment(data.date).format('MMMM, YYYY')}`}
                    </Typography>
                </div>
            )}
            {Object.keys(data.data).map((value) => instance(getTitle(value), data.data[value], data.date))}
        </div>
    );
}
