import Environment from '../../Environment';
import MockSplit from '../../MockSplit';
import { HAULING_CHARGE_REGIONS, TCJ_REGIONS } from '../../../app/constants/bulkRegion';
import Region from '../../../app/constants/region';
import stages from '../stages';

/**
 * Define all of your environment configs here
 */

const local = new Environment({
    name: stages.LOCAL,
    regions: HAULING_CHARGE_REGIONS,
});

const dev = new Environment({
    name: stages.DEV,
    regions: HAULING_CHARGE_REGIONS,
});

const stg = new Environment({
    name: stages.STAGING,
    regions: [Region.MMPA, Region.PRAIRIE, ...TCJ_REGIONS, Region.UNC, Region.UDA, Region.CACIQUE],
});

const prod = new Environment({
    name: stages.PRODUCTION,
    regions: [Region.MMPA, Region.PRAIRIE, ...TCJ_REGIONS, Region.UNC, Region.UDA, Region.CACIQUE],
});

/**
 * Create the new MockSplit, be sure to create the env dictionary with util
 */
const can_access_hauler_rates = new MockSplit({
    name: 'can_access_hauler_rates',
    environments: [local, dev, stg, prod],
});

export default can_access_hauler_rates;
