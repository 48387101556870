import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import moment from 'moment-timezone';

function ProducerDemeritBox({ monthlyReport, demeritPoints, lastUpdated, currentDate, numPickups }) {
    const isCurrentMonth = moment().isSame(currentDate, 'month');
    const currentDateReportExists = isCurrentMonth ? false : monthlyReport[0] ? moment(currentDate).isSame(moment(monthlyReport[0].report_date), 'month') : false;

    return (
        <Card className="w-full rounded-8 shadow-none border-1 h-full">
            <div className="flex items-center justify-between px-16 pt-16">
                <Typography className="text-20 font-bold">Point Status</Typography>
            </div>
            <div className="flex flex-row items-center justify-between py-6">
                <Typography className="px-16 text-center items-center text-12" color="inherit">
                    {`Updated as of: ${moment(lastUpdated).format('MMM Do, YYYY')}`}
                </Typography>
            </div>
            <div className="flex flex-row container justify-center items-left py-10 pt-16">
                <div className="flex items-left justify-between px-16 py-8">
                    <Typography className="text-14">{`Monthly Pickups: ${numPickups || 0}`}</Typography>
                </div>
            </div>
            {(isCurrentMonth || !currentDateReportExists) && (
                <div>
                    <div className="flex flex-row container justify-center items-left py-10">
                        {demeritPoints !== 0 && (
                            <div className="flex items-left justify-between px-16 py-8">
                                <Typography className="text-14">{`Monthly Total Demerit Points: ${demeritPoints || 0}`}</Typography>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row container justify-center items-left py-10">
                        {monthlyReport[0] && monthlyReport[0].merits_available !== 0 && (
                            <div className="flex items-left justify-between px-16 py-8">
                                <Typography className="text-14">{`Merits carried forward: ${monthlyReport[0].merits_available || 0}`}</Typography>
                            </div>
                        )}
                    </div>
                </div>
            )}
            {!isCurrentMonth && currentDateReportExists && (
                <div>
                    <div className="flex flex-row container justify-center items-left py-10">
                        {demeritPoints !== 0 && (
                            <div className="flex items-left justify-between px-16 py-8">
                                <Typography className="text-14">{`Monthly Total Demerit Points: ${demeritPoints || 0}`}</Typography>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row container justify-center items-left py-10">
                        {monthlyReport[0] && monthlyReport[0].merit_points_carried_forward !== 0 && (
                            <div className="flex items-left justify-between px-16 py-8">
                                <Typography className="text-14">{`Merits carried forward: ${monthlyReport[0].merit_points_carried_forward || 0}`}</Typography>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row container justify-center items-left py-10">
                        {monthlyReport[0] && monthlyReport[0].merit_points_carried_forward < monthlyReport[0].merits_available && (
                            <div className="flex items-left justify-between px-16 py-8">
                                <Typography className="text-14">{`Merits earned this month: ${monthlyReport[0].merits_gained_or_lost || 0}`}</Typography>
                            </div>
                        )}
                        {monthlyReport[0] && monthlyReport[0].merit_points_carried_forward > monthlyReport[0].merits_available && (
                            <div className="flex items-left justify-between px-16 py-8">
                                <Typography className="text-14">{`Merits drawn down this month: ${monthlyReport[0].merits_gained_or_lost || 0}`}</Typography>
                            </div>
                        )}
                    </div>
                    {monthlyReport[0] && !!monthlyReport[0].merits_available > 0 && (
                        <div className="flex flex-row container justify-center items-left py-10">
                            <div className="flex items-left justify-between px-16 py-8">
                                <Typography className="text-14">{`Merits available: ${monthlyReport[0].merits_available}`}</Typography>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-row container justify-center items-left py-10">
                        {monthlyReport[0] && !!monthlyReport[0].scaled_demerit_points && (
                            <div className="flex items-left justify-between px-16 py-8">
                                <Typography className="text-14">{`Monthly Adj’d Demerit Points: ${monthlyReport[0].scaled_demerit_points}`}</Typography>
                            </div>
                        )}
                    </div>
                    <div className="flex flex-row container justify-center items-left py-10">
                        {monthlyReport[0] && !!monthlyReport[0].monthly_quality_adjustment_percentage && (
                            <div className="flex items-left justify-between px-16 py-8">
                                <Typography className="text-14">{`Payment Adjustment Percentage: -${monthlyReport[0] && monthlyReport[0].monthly_quality_adjustment_percentage ? monthlyReport[0].monthly_quality_adjustment_percentage : ''}%`}</Typography>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </Card>
    );
}

export default React.memo(ProducerDemeritBox);
