import React, { useCallback, useState } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import ReactHookFormInput from '../../form-components/ReactHookFormInput';
import { showMessage } from 'app/store/actions';
import { addMerchandise } from '../../../repository';
import { Box, Button, Grid, debounce, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ReactHookFormSearchableSelect from '../../form-components/ReactHookFormSearchableSelect';
import { productType } from '../../form-components/MerchandiseSelect';
import _ from 'lodash';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment-timezone';
import { yupResolver } from '@hookform/resolvers/yup';
import merchandiseProductValidationSchema from '../../form-components/MerchandiseProductValidationSchema';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(() => ({
    root: {
        width: '75%',
    },
    date: {
        marginTop: '8px',
    },
}));

const AddMerchandiseForm = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const [addMerchandiseProduct, setAddMerchandiseProduct] = useState();
    const reactHookFormMethods = useForm({
        mode: 'all',
        resolver: yupResolver(merchandiseProductValidationSchema),
    });

    const { handleSubmit, control, errors, watch } = reactHookFormMethods;

    const productTypeWatch = watch('product_type');

    const post = async (submitModel) => {
        try {
            const response = await addMerchandise(submitModel);
            setAddMerchandiseProduct(response);
            showMessage({ message: 'Successfully Added Product' });
            history.replace({ pathname: '/merchandise' });
        } catch ({ message, code }) {
            if (message?.startsWith('E11000 duplicate key error collection')) {
                return {
                    message: 'Duplicate Product Number',
                };
            }
            dispatch(showMessage({ message: 'Product Number already exists, please enter a new one.' }));
        }
    };

    const onSubmit = (model) => {
        let submitModel = { ...model };
        // This function applies to the searchable select components and passes the value of the selection to the model
        Object.keys(submitModel).forEach((key) => {
            if (submitModel[key]?.value) {
                submitModel[key] = submitModel[key].value;
            }
        });

        submitModel = _.omitBy(submitModel, (field) => _.isUndefined(field) || _.isNull(field) || field === '');

        // The query has been updated to only include fields that apply to the form data, these fields are coming from editDetails somehow
        delete submitModel?._id;
        delete submitModel.tableData;
        delete submitModel.index;

        submitModel.historic_rates = submitModel.product_type === 'farm' ? [{ price_per_unit: submitModel?.price_per_unit, price_start_date: moment(submitModel?.price_effective_date).format(), price_end_date: null }] : [];
        post(submitModel);
    };

    const debounceSubmit = useCallback(debounce(onSubmit, 500), []);

    return (
        <div className={classes.root}>
            <FormProvider {...reactHookFormMethods}>
                <form onSubmit={handleSubmit(debounceSubmit, errors)}>
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12}>
                            <ReactHookFormInput label={'Product Number'} name={'product_number'} type={'text'} fullWidth variant="outlined" required />
                        </Grid>

                        <Grid item xs={12}>
                            <ReactHookFormInput label={'Product Name'} name={'product_name'} type={'text'} fullWidth variant="outlined" required />
                        </Grid>

                        <Grid item xs={12}>
                            <ReactHookFormSearchableSelect name={'product_type'} label={'Product Type'} options={productType} variant="outlined" required />
                        </Grid>

                        {productTypeWatch?.value === 'farm' && (
                            <>
                                <Grid item xs={12}>
                                    <ReactHookFormInput label={'Price Per Unit'} type={'number'} name={'price_per_unit'} fullWidth variant="outlined" required />
                                </Grid>
                                <Grid item xs={12} className={classes.date}>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Controller
                                            name={'price_effective_date'}
                                            control={control}
                                            defaultValue={moment()}
                                            render={({ field: { onChange, value } }) => {
                                                return <DatePicker className={classes.date} openTo="year" fullWidth onChange={onChange} views={['year', 'month']} format={'MMM YYYY'} value={value || null} label="Price Effective Date" inputVariant="outlined" required />;
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </>
                        )}
                        <Box display="flex" justifyContent="center" gridGap="30px" paddingTop={'15px'}>
                            <Button variant="contained" color="primary" margin="normal" className="mx-auto my-16" sx={{ paddingTop: 2 }} type="submit">
                                Save
                            </Button>
                        </Box>
                    </Grid>
                </form>
            </FormProvider>
        </div>
    );
};

export default AddMerchandiseForm;
