import axios from 'axios';
import { editHaulingContractQuery } from './queries';

const editHaulingContract = (model, key) =>
    new Promise((resolve, reject) => {
        const query = editHaulingContractQuery({ model, key });
        const request = axios.put(query.url, { ...query.body }, { params: { useNewPutRoute: true } });
        request
            .then((response) => {
                resolve({ data: response.data });
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to edit hauling contract'));
            });
    });

export default editHaulingContract;
