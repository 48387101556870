import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import { arrayToMap, renameKey } from '../../utils';
import { getTrailersQuery, getHaulingCompaniesQuery, getWashesQuery, bulkQuery } from './queries';

const getTrailerListDetails = (includeDeleted, hId) =>
    new Promise((resolve, reject) => {
        const query = getTrailersQuery({ ...(!includeDeleted && { filterDeleted: true }), ...(hId && { haulingId: hId }) });
        const request = axios.get(query);

        request
            .then((response) => {
                const trailers = response.data;
                const haulingIds = _.filter(_.uniq(_.map(trailers, 'hauling_id')), (id) => !_.isNull(id));

                const haulingCompanyQuery = bulkQuery(getHaulingCompaniesQuery, {
                    fields: ['id', 'name'],
                    ids: haulingIds,
                    filterDeleted: true,
                });

                const haulingCompanyRequest = axios.post(haulingCompanyQuery.url, haulingCompanyQuery.body);

                const washesQuery = bulkQuery(getWashesQuery, {
                    fields: ['id', 'trailer', 'wash_time'],
                    start: moment().subtract(1, 'months').startOf('day').toISOString(),
                    end: moment().endOf('day').toISOString(),
                });
                const washRequest = axios.get(washesQuery);

                const requests = [haulingCompanyRequest, washRequest];

                axios.all(requests).then(
                    axios.spread((...responses) => {
                        const haulingCompanyResponse = responses[0].data;
                        renameKey(haulingCompanyResponse, '_id', 'id');
                        const washResponse = responses[1].data;
                        const washMap = arrayToMap(washResponse, 'trailer');

                        // const haulingCompanies = _.reduce(haulingCompanyResponse, (acc, val) => [...acc, ...val.data], []);

                        const trailerResponse = _.map(trailers, (trailer) => {
                            const trailerHauler = trailer.hauling_id || '';
                            const hauler = _.find(haulingCompanyResponse, { id: trailerHauler });
                            const haulingCompany = trailerHauler && hauler && hauler.name ? hauler.name : '';
                            const washes = washMap[trailer.id];
                            const wash_date = washes ? moment(washes.wash_time).format('MMMM DD') : null;
                            const wash_time = washes ? moment(washes.wash_time).format('hh:mm A') : null;

                            return { ...trailer, hauling_company: haulingCompany, wash_date, wash_time };
                        });
                        resolve({ trailers: trailerResponse });
                    })
                );
            })
            .catch((errors) => {
                // eslint-disable-next-line no-console
                console.log(errors);
                reject(new Error('Unable to process request'));
            });
    });

export default getTrailerListDetails;
