import axios from 'axios';
import { buildMembershipQuery } from './queries/builders';

const purchaseEquity = async (equityMemberId, subuserId, equityType, equityValue, dateOfPurchase) => {
    try {
        const membershipQuery = `/v1/equityMembers/${equityMemberId}/purchaseEquity`;

        const equityMembershipQuery = buildMembershipQuery(membershipQuery, {
            headers: {},
            data: {
                ...equityValue,
                type: equityType,
                to_subuser_id: subuserId,
                date_of_purchase: dateOfPurchase,
            },
        });

        const { data } = await axios({ ...equityMembershipQuery, method: 'post' });

        return data;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        throw new Error(err instanceof Error ? err.message : 'Unable to process request.');
    }
};

export default purchaseEquity;
