import axios from 'axios';
import { getVendorsWithCompletePayReportsQuery } from './queries';
import _ from 'lodash';

const getVendorMonthlyPayReports = async (month, year) => {
    try {
        const vendorMonthlyPayQuery = getVendorsWithCompletePayReportsQuery({
            report_month: `${year}-${month}`,
        });
        const vendorMonthlyPayResponse = await axios.get(vendorMonthlyPayQuery);
        return { data: vendorMonthlyPayResponse.data };
    } catch (err) {
        // eslint-disable-next-line no-console
        console.log(err);
        throw new Error('Unable to process request.');
    }
};

export default getVendorMonthlyPayReports;
